import {KTSVG} from "../../../../../_metronic/helpers";

interface ICampaignGroupItem {
  value: number | string
  label: number | string
  img: string
  color: string
}

const CampaignGroupItem: React.FC<ICampaignGroupItem> = ({value, label, img, color}) => {
  return (
      <div className='col-xl-4 col'>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-xl-50px symbol-40px me-2'>
            <div className={`symbol-label bg-light-${color}`}>
              <KTSVG path={img} className={`svg-icon-1 svg-icon-${color}`} />
            </div>
          </div>
          <div>
            <div className='fs-xl-4 fs-6 text-dark fw-bolder'>{value}</div>
            <div className='fs-xl-7 fs-8 text-muted fw-bold'>{label}</div>
          </div>
        </div>
      </div>
  )
}

export default CampaignGroupItem;