var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.8312, 35.0074],
            [33.8562, 34.9982],
            [33.8577, 34.9834],
            [33.8503, 34.97],
            [33.8794, 34.9699],
            [33.9023, 34.9642],
            [33.8974, 34.9547],
            [33.8747, 34.9416],
            [33.8614, 34.938],
            [33.8481, 34.9431],
            [33.8307, 34.9584],
            [33.8134, 34.9681],
            [33.758, 34.9735],
            [33.7452, 34.9735],
            [33.6999, 34.9719],
            [33.7004, 34.9923],
            [33.7125, 34.9914],
            [33.714, 35.0045],
            [33.6971, 35.0131],
            [33.7027, 35.0253],
            [33.6715, 35.039],
            [33.6728, 35.0543],
            [33.7014, 35.0739],
            [33.7151, 35.0685],
            [33.7124, 35.0593],
            [33.7045, 35.0425],
            [33.7179, 35.0425],
            [33.7426, 35.0519],
            [33.7613, 35.0391],
            [33.7827, 35.0495],
            [33.797, 35.0665],
            [33.8169, 35.0751],
            [33.847, 35.0656],
            [33.8705, 35.09],
            [33.8672, 35.1185],
            [33.8794, 35.1236],
            [33.9103, 35.0904],
            [33.905, 35.0761],
            [33.8934, 35.0663],
            [33.8764, 35.0782],
            [33.8511, 35.0571],
            [33.8339, 35.0565],
            [33.8158, 35.0648],
            [33.7967, 35.0467],
            [33.8214, 35.0389],
            [33.8271, 35.03],
            [33.8312, 35.0074],
          ],
        ],
      },
      properties: {
        name: 'Dhekelia',
        id: 'GB-DX',
        CNTRY: 'Akrotiri and Dhekeli',
        TYPE: 'British Sovereign Base Area',
      },
      id: 'GB-DX',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [33.7124, 35.0593],
              [33.7357, 35.0578],
              [33.7426, 35.0519],
              [33.7179, 35.0425],
              [33.7045, 35.0425],
              [33.7124, 35.0593],
            ],
          ],
          [
            [
              [33.6728, 35.0543],
              [33.6715, 35.039],
              [33.7027, 35.0253],
              [33.6971, 35.0131],
              [33.714, 35.0045],
              [33.7125, 34.9914],
              [33.7004, 34.9923],
              [33.6999, 34.9719],
              [33.6823, 34.9693],
              [33.6662, 34.9591],
              [33.6515, 34.9433],
              [33.6422, 34.9212],
              [33.6405, 34.8688],
              [33.6133, 34.8332],
              [33.6064, 34.8168],
              [33.5628, 34.8191],
              [33.5398, 34.8111],
              [33.5287, 34.7964],
              [33.5031, 34.7861],
              [33.4917, 34.7759],
              [33.4696, 34.7749],
              [33.4413, 34.7677],
              [33.4268, 34.7563],
              [33.4009, 34.746],
              [33.3859, 34.7435],
              [33.3655, 34.7304],
              [33.3406, 34.7224],
              [33.3156, 34.718],
              [33.2871, 34.7227],
              [33.2749, 34.7183],
              [33.2726, 34.7386],
              [33.2767, 34.7525],
              [33.2748, 34.7877],
              [33.2613, 34.8014],
              [33.2455, 34.81],
              [33.2206, 34.8363],
              [33.2059, 34.845],
              [33.1523, 34.8541],
              [33.1342, 34.8602],
              [33.1341, 34.8841],
              [33.1502, 34.8974],
              [33.1623, 34.8992],
              [33.178, 34.9121],
              [33.2144, 34.9028],
              [33.2369, 34.9096],
              [33.2505, 34.9017],
              [33.2558, 34.8896],
              [33.2733, 34.9024],
              [33.2926, 34.9056],
              [33.3022, 34.9131],
              [33.3004, 34.926],
              [33.3126, 34.9367],
              [33.3301, 34.9421],
              [33.3477, 34.9281],
              [33.3688, 34.9356],
              [33.387, 34.9317],
              [33.4052, 34.936],
              [33.4184, 34.9706],
              [33.4345, 34.9681],
              [33.4448, 34.9588],
              [33.4584, 34.962],
              [33.4641, 34.9724],
              [33.4983, 34.9667],
              [33.5158, 34.986],
              [33.5201, 35.0102],
              [33.5012, 35.0227],
              [33.5005, 35.032],
              [33.4823, 35.0484],
              [33.4841, 35.0673],
              [33.5076, 35.0673],
              [33.5087, 35.0937],
              [33.5305, 35.103],
              [33.5747, 35.0887],
              [33.5952, 35.1129],
              [33.6223, 35.1143],
              [33.6327, 35.1016],
              [33.6511, 35.0944],
              [33.6484, 35.0831],
              [33.6535, 35.054],
              [33.6728, 35.0543],
            ],
          ],
        ],
      },
      properties: {name: 'Larnaca', id: 'CY-03', CNTRY: 'Cyprus', TYPE: 'District'},
      id: 'CY-03',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.9023, 34.9642],
            [33.8794, 34.9699],
            [33.8678, 34.9908],
            [33.8651, 35.0074],
            [33.8312, 35.0074],
            [33.8271, 35.03],
            [33.8214, 35.0389],
            [33.7967, 35.0467],
            [33.8158, 35.0648],
            [33.8339, 35.0565],
            [33.8511, 35.0571],
            [33.8764, 35.0782],
            [33.8934, 35.0663],
            [33.905, 35.0761],
            [33.9103, 35.0904],
            [33.8794, 35.1236],
            [33.8672, 35.1185],
            [33.8705, 35.09],
            [33.847, 35.0656],
            [33.8169, 35.0751],
            [33.797, 35.0665],
            [33.7827, 35.0495],
            [33.7613, 35.0391],
            [33.7426, 35.0519],
            [33.7357, 35.0578],
            [33.7124, 35.0593],
            [33.7151, 35.0685],
            [33.7014, 35.0739],
            [33.6728, 35.0543],
            [33.6535, 35.054],
            [33.6484, 35.0831],
            [33.6511, 35.0944],
            [33.6327, 35.1016],
            [33.6223, 35.1143],
            [33.5952, 35.1129],
            [33.583, 35.1233],
            [33.5631, 35.1236],
            [33.5452, 35.1361],
            [33.5548, 35.1572],
            [33.569, 35.1682],
            [33.5711, 35.1813],
            [33.564, 35.1991],
            [33.5491, 35.2164],
            [33.5485, 35.2247],
            [33.5711, 35.2286],
            [33.5548, 35.2476],
            [33.5524, 35.2696],
            [33.5458, 35.2799],
            [33.5468, 35.2899],
            [33.5722, 35.2951],
            [33.5832, 35.3049],
            [33.6243, 35.3213],
            [33.6403, 35.3225],
            [33.6633, 35.3394],
            [33.6514, 35.3439],
            [33.6554, 35.3557],
            [33.6939, 35.3694],
            [33.7085, 35.3801],
            [33.7457, 35.3974],
            [33.774, 35.406],
            [33.8216, 35.4047],
            [33.8517, 35.4064],
            [33.8722, 35.4135],
            [33.9233, 35.4259],
            [33.9501, 35.4348],
            [33.9651, 35.4361],
            [34.0034, 35.4495],
            [34.0321, 35.4639],
            [34.0719, 35.4805],
            [34.0868, 35.4911],
            [34.125, 35.504],
            [34.1782, 35.559],
            [34.1975, 35.5522],
            [34.2315, 35.5531],
            [34.246, 35.5662],
            [34.2641, 35.5668],
            [34.2985, 35.5756],
            [34.317, 35.5864],
            [34.3247, 35.5964],
            [34.3356, 35.597],
            [34.3557, 35.6212],
            [34.3706, 35.6217],
            [34.3818, 35.6293],
            [34.4111, 35.6335],
            [34.4343, 35.6505],
            [34.455, 35.647],
            [34.4952, 35.6566],
            [34.5085, 35.6731],
            [34.5194, 35.6707],
            [34.5426, 35.6855],
            [34.5822, 35.695],
            [34.5727, 35.6718],
            [34.57, 35.6484],
            [34.5618, 35.6417],
            [34.5462, 35.6417],
            [34.5323, 35.6322],
            [34.5176, 35.632],
            [34.4268, 35.5896],
            [34.4151, 35.5757],
            [34.4066, 35.5547],
            [34.3849, 35.5395],
            [34.3424, 35.5232],
            [34.2967, 35.4836],
            [34.2743, 35.4756],
            [34.2244, 35.453],
            [34.201, 35.4461],
            [34.1663, 35.4277],
            [34.1144, 35.3924],
            [34.0794, 35.3586],
            [34.0665, 35.3251],
            [34.036, 35.3148],
            [33.9991, 35.319],
            [33.9709, 35.3159],
            [33.947, 35.2997],
            [33.9341, 35.2792],
            [33.9139, 35.255],
            [33.9053, 35.2286],
            [33.902, 35.1967],
            [33.9097, 35.1679],
            [33.9198, 35.1497],
            [33.9599, 35.1114],
            [33.9674, 35.0942],
            [33.9941, 35.0675],
            [34.0374, 35.0366],
            [34.0496, 35.0191],
            [34.0778, 34.9864],
            [34.0714, 34.9766],
            [34.0769, 34.9661],
            [34.0661, 34.9587],
            [34.0228, 34.978],
            [34.0014, 34.9771],
            [33.9601, 34.9808],
            [33.9468, 34.9739],
            [33.922, 34.9712],
            [33.9023, 34.9642],
          ],
        ],
      },
      properties: {name: 'Famagusta', id: 'CY-04', CNTRY: 'Cyprus', TYPE: 'District'},
      id: 'CY-04',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.6554, 35.3557],
            [33.6514, 35.3439],
            [33.6633, 35.3394],
            [33.6403, 35.3225],
            [33.6243, 35.3213],
            [33.5832, 35.3049],
            [33.5722, 35.2951],
            [33.5468, 35.2899],
            [33.5458, 35.2799],
            [33.5156, 35.2735],
            [33.4737, 35.2799],
            [33.4563, 35.2754],
            [33.4147, 35.2385],
            [33.3408, 35.2373],
            [33.2882, 35.238],
            [33.272, 35.2485],
            [33.2556, 35.2463],
            [33.2321, 35.2325],
            [33.2023, 35.2468],
            [33.1879, 35.2478],
            [33.1741, 35.2582],
            [33.167, 35.2499],
            [33.1337, 35.2444],
            [33.0873, 35.2504],
            [33.0706, 35.2437],
            [33.0537, 35.2535],
            [33.0468, 35.2635],
            [33.0297, 35.2578],
            [33.0138, 35.2618],
            [32.9881, 35.2561],
            [32.9707, 35.263],
            [32.9373, 35.2638],
            [32.9402, 35.2999],
            [32.9373, 35.3394],
            [32.9247, 35.3516],
            [32.9238, 35.3828],
            [32.92, 35.398],
            [32.9509, 35.3892],
            [33.0094, 35.3592],
            [33.0409, 35.3573],
            [33.0544, 35.3516],
            [33.0851, 35.349],
            [33.111, 35.3578],
            [33.1296, 35.3573],
            [33.1698, 35.3475],
            [33.1959, 35.3516],
            [33.2187, 35.3513],
            [33.248, 35.3404],
            [33.2635, 35.343],
            [33.2825, 35.3385],
            [33.2996, 35.3397],
            [33.3593, 35.329],
            [33.4636, 35.3287],
            [33.5065, 35.3308],
            [33.5288, 35.3379],
            [33.5694, 35.3407],
            [33.6095, 35.353],
            [33.6133, 35.3506],
            [33.6554, 35.3557],
          ],
        ],
      },
      properties: {name: 'Kyrenia', id: 'CY-06', CNTRY: 'Cyprus', TYPE: 'District'},
      id: 'CY-06',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.576, 35.1698],
            [32.6166, 35.1367],
            [32.619, 35.122],
            [32.6422, 35.1084],
            [32.6425, 35.0837],
            [32.6207, 35.0721],
            [32.6216, 35.0586],
            [32.6412, 35.0365],
            [32.6602, 35.0315],
            [32.6633, 35.0164],
            [32.6782, 35.0083],
            [32.6775, 34.9888],
            [32.6829, 34.978],
            [32.6805, 34.9462],
            [32.6713, 34.9412],
            [32.6695, 34.9159],
            [32.6965, 34.8978],
            [32.7048, 34.9101],
            [32.723, 34.8952],
            [32.7408, 34.9175],
            [32.7574, 34.9162],
            [32.7765, 34.9082],
            [32.7784, 34.8928],
            [32.7898, 34.888],
            [32.7938, 34.875],
            [32.7533, 34.8417],
            [32.7045, 34.7935],
            [32.7087, 34.7821],
            [32.727, 34.7796],
            [32.7289, 34.7695],
            [32.7141, 34.7488],
            [32.6884, 34.7406],
            [32.6767, 34.7244],
            [32.6592, 34.7232],
            [32.6459, 34.7038],
            [32.6459, 34.661],
            [32.6236, 34.6664],
            [32.6034, 34.6752],
            [32.5698, 34.6954],
            [32.5233, 34.7044],
            [32.5083, 34.711],
            [32.495, 34.7037],
            [32.4479, 34.7379],
            [32.4343, 34.7391],
            [32.4217, 34.7546],
            [32.4005, 34.762],
            [32.4029, 34.7815],
            [32.3953, 34.8079],
            [32.3879, 34.8211],
            [32.3851, 34.8404],
            [32.3703, 34.8537],
            [32.3449, 34.8592],
            [32.3349, 34.8796],
            [32.3137, 34.8946],
            [32.3265, 34.9203],
            [32.3039, 34.951],
            [32.3096, 34.9589],
            [32.3127, 34.9834],
            [32.2867, 35.0271],
            [32.2703, 35.0476],
            [32.2681, 35.0612],
            [32.2748, 35.0971],
            [32.2869, 35.0966],
            [32.3013, 35.0883],
            [32.3204, 35.0683],
            [32.3294, 35.0659],
            [32.3596, 35.045],
            [32.406, 35.0402],
            [32.4255, 35.0433],
            [32.456, 35.0578],
            [32.4852, 35.0797],
            [32.4967, 35.0933],
            [32.5277, 35.1447],
            [32.5437, 35.1563],
            [32.5482, 35.1718],
            [32.576, 35.1698],
          ],
        ],
      },
      properties: {name: 'Paphos', id: 'CY-05', CNTRY: 'Cyprus', TYPE: 'District'},
      id: 'CY-05',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.2749, 34.7183],
            [33.2672, 34.7024],
            [33.1937, 34.7066],
            [33.1405, 34.7108],
            [33.0784, 34.6926],
            [33.0552, 34.6804],
            [33.0391, 34.6651],
            [33.0312, 34.6634],
            [33.0132, 34.6477],
            [32.987, 34.6475],
            [32.9525, 34.6392],
            [32.9525, 34.6541],
            [32.9656, 34.6621],
            [32.987, 34.6597],
            [32.9832, 34.6802],
            [32.957, 34.685],
            [32.9338, 34.671],
            [32.913, 34.6761],
            [32.9026, 34.6701],
            [32.8883, 34.6722],
            [32.863, 34.6912],
            [32.8586, 34.7043],
            [32.8422, 34.7121],
            [32.8249, 34.7115],
            [32.8139, 34.7007],
            [32.8029, 34.6987],
            [32.7848, 34.6868],
            [32.7592, 34.6891],
            [32.7575, 34.6554],
            [32.7375, 34.6467],
            [32.7228, 34.6486],
            [32.7019, 34.6424],
            [32.6459, 34.661],
            [32.6459, 34.7038],
            [32.6592, 34.7232],
            [32.6767, 34.7244],
            [32.6884, 34.7406],
            [32.7141, 34.7488],
            [32.7289, 34.7695],
            [32.727, 34.7796],
            [32.7087, 34.7821],
            [32.7045, 34.7935],
            [32.7533, 34.8417],
            [32.7938, 34.875],
            [32.7898, 34.888],
            [32.7784, 34.8928],
            [32.7765, 34.9082],
            [32.7765, 34.9261],
            [32.7841, 34.9512],
            [32.7969, 34.9528],
            [32.8155, 34.9636],
            [32.8786, 34.9358],
            [32.9247, 34.9379],
            [32.9538, 34.9548],
            [32.9963, 34.9556],
            [33.0048, 34.9394],
            [33.0418, 34.916],
            [33.0676, 34.8907],
            [33.0987, 34.8995],
            [33.1341, 34.8841],
            [33.1342, 34.8602],
            [33.1523, 34.8541],
            [33.2059, 34.845],
            [33.2206, 34.8363],
            [33.2455, 34.81],
            [33.2613, 34.8014],
            [33.2748, 34.7877],
            [33.2767, 34.7525],
            [33.2726, 34.7386],
            [33.2749, 34.7183],
          ],
        ],
      },
      properties: {name: 'Limassol', id: 'CY-02', CNTRY: 'Cyprus', TYPE: 'District'},
      id: 'CY-02',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.0132, 34.6477],
            [33.0059, 34.6346],
            [33.0049, 34.6063],
            [33.0253, 34.5791],
            [33.0221, 34.5644],
            [33.0043, 34.5723],
            [32.9732, 34.5759],
            [32.9672, 34.5691],
            [32.9411, 34.5691],
            [32.9371, 34.593],
            [32.9225, 34.621],
            [32.9091, 34.6398],
            [32.881, 34.665],
            [32.8545, 34.6695],
            [32.8441, 34.6657],
            [32.8269, 34.6688],
            [32.8022, 34.664],
            [32.7672, 34.6624],
            [32.7575, 34.6554],
            [32.7592, 34.6891],
            [32.7848, 34.6868],
            [32.8029, 34.6987],
            [32.8139, 34.7007],
            [32.8249, 34.7115],
            [32.8422, 34.7121],
            [32.8586, 34.7043],
            [32.863, 34.6912],
            [32.8883, 34.6722],
            [32.9026, 34.6701],
            [32.913, 34.6761],
            [32.9338, 34.671],
            [32.957, 34.685],
            [32.9832, 34.6802],
            [32.987, 34.6597],
            [32.9656, 34.6621],
            [32.9525, 34.6541],
            [32.9525, 34.6392],
            [32.987, 34.6475],
            [33.0132, 34.6477],
          ],
        ],
      },
      properties: {
        name: 'Akrotiri',
        id: 'GB-AX',
        CNTRY: 'Akrotiri and Dhekeli',
        TYPE: 'British Sovereign Base Area',
      },
      id: 'GB-AX',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.5952, 35.1129],
            [33.5747, 35.0887],
            [33.5305, 35.103],
            [33.5087, 35.0937],
            [33.5076, 35.0673],
            [33.4841, 35.0673],
            [33.4823, 35.0484],
            [33.5005, 35.032],
            [33.5012, 35.0227],
            [33.5201, 35.0102],
            [33.5158, 34.986],
            [33.4983, 34.9667],
            [33.4641, 34.9724],
            [33.4584, 34.962],
            [33.4448, 34.9588],
            [33.4345, 34.9681],
            [33.4184, 34.9706],
            [33.4052, 34.936],
            [33.387, 34.9317],
            [33.3688, 34.9356],
            [33.3477, 34.9281],
            [33.3301, 34.9421],
            [33.3126, 34.9367],
            [33.3004, 34.926],
            [33.3022, 34.9131],
            [33.2926, 34.9056],
            [33.2733, 34.9024],
            [33.2558, 34.8896],
            [33.2505, 34.9017],
            [33.2369, 34.9096],
            [33.2144, 34.9028],
            [33.178, 34.9121],
            [33.1623, 34.8992],
            [33.1502, 34.8974],
            [33.1341, 34.8841],
            [33.0987, 34.8995],
            [33.0676, 34.8907],
            [33.0418, 34.916],
            [33.0048, 34.9394],
            [32.9963, 34.9556],
            [32.9538, 34.9548],
            [32.9247, 34.9379],
            [32.8786, 34.9358],
            [32.8155, 34.9636],
            [32.7969, 34.9528],
            [32.7841, 34.9512],
            [32.7765, 34.9261],
            [32.7765, 34.9082],
            [32.7574, 34.9162],
            [32.7408, 34.9175],
            [32.723, 34.8952],
            [32.7048, 34.9101],
            [32.6965, 34.8978],
            [32.6695, 34.9159],
            [32.6713, 34.9412],
            [32.6805, 34.9462],
            [32.6829, 34.978],
            [32.6775, 34.9888],
            [32.6782, 35.0083],
            [32.6633, 35.0164],
            [32.6602, 35.0315],
            [32.6412, 35.0365],
            [32.6216, 35.0586],
            [32.6207, 35.0721],
            [32.6425, 35.0837],
            [32.6422, 35.1084],
            [32.619, 35.122],
            [32.6166, 35.1367],
            [32.576, 35.1698],
            [32.6065, 35.1756],
            [32.6191, 35.1889],
            [32.6357, 35.1868],
            [32.6474, 35.1928],
            [32.6743, 35.1908],
            [32.6883, 35.1842],
            [32.7069, 35.1832],
            [32.7404, 35.1751],
            [32.7633, 35.1635],
            [32.7835, 35.1573],
            [32.8191, 35.1411],
            [32.8434, 35.1428],
            [32.8764, 35.1573],
            [32.9057, 35.1737],
            [32.9121, 35.1835],
            [32.9262, 35.2194],
            [32.9373, 35.2638],
            [32.9707, 35.263],
            [32.9881, 35.2561],
            [33.0138, 35.2618],
            [33.0297, 35.2578],
            [33.0468, 35.2635],
            [33.0537, 35.2535],
            [33.0706, 35.2437],
            [33.0873, 35.2504],
            [33.1337, 35.2444],
            [33.167, 35.2499],
            [33.1741, 35.2582],
            [33.1879, 35.2478],
            [33.2023, 35.2468],
            [33.2321, 35.2325],
            [33.2556, 35.2463],
            [33.272, 35.2485],
            [33.2882, 35.238],
            [33.3408, 35.2373],
            [33.4147, 35.2385],
            [33.4563, 35.2754],
            [33.4737, 35.2799],
            [33.5156, 35.2735],
            [33.5458, 35.2799],
            [33.5524, 35.2696],
            [33.5548, 35.2476],
            [33.5711, 35.2286],
            [33.5485, 35.2247],
            [33.5491, 35.2164],
            [33.564, 35.1991],
            [33.5711, 35.1813],
            [33.569, 35.1682],
            [33.5548, 35.1572],
            [33.5452, 35.1361],
            [33.5631, 35.1236],
            [33.583, 35.1233],
            [33.5952, 35.1129],
          ],
        ],
      },
      properties: {name: 'Nicosia', id: 'CY-01', CNTRY: 'Cyprus', TYPE: 'District'},
      id: 'CY-01',
    },
  ],
}
export default map
