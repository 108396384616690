var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.4855, 42.085],
            [23.5449, 42.0868],
            [23.5846, 42.1191],
            [23.5992, 42.1789],
            [23.6113, 42.1904],
            [23.6784, 42.1904],
            [23.698, 42.1613],
            [23.7482, 42.1491],
            [23.7502, 42.1836],
            [23.778, 42.1836],
            [23.7706, 42.1457],
            [23.7932, 42.1356],
            [23.7902, 42.1081],
            [23.8074, 42.0696],
            [23.7969, 42.0233],
            [23.7549, 41.9921],
            [23.7511, 41.9659],
            [23.7674, 41.921],
            [23.7766, 41.851],
            [23.8006, 41.8308],
            [23.9074, 41.7964],
            [23.9971, 41.7453],
            [24.0444, 41.7055],
            [24.1038, 41.6705],
            [24.1147, 41.6531],
            [24.0823, 41.619],
            [24.0859, 41.5938],
            [24.0607, 41.5473],
            [24.0621, 41.5253],
            [24.049, 41.4531],
            [24.0043, 41.4555],
            [23.9723, 41.4383],
            [23.9305, 41.4727],
            [23.891, 41.4505],
            [23.7995, 41.4369],
            [23.7652, 41.3962],
            [23.729, 41.4077],
            [23.6734, 41.4101],
            [23.6365, 41.3755],
            [23.5333, 41.4016],
            [23.485, 41.3986],
            [23.4371, 41.406],
            [23.3653, 41.3802],
            [23.3417, 41.3647],
            [23.3214, 41.3997],
            [23.2826, 41.4002],
            [23.2319, 41.375],
            [23.2305, 41.3482],
            [23.2063, 41.3239],
            [23.1385, 41.3125],
            [23.0845, 41.3135],
            [23.0279, 41.3338],
            [23.0068, 41.3286],
            [22.9652, 41.3543],
            [22.9671, 41.3939],
            [22.9526, 41.4176],
            [22.9792, 41.4462],
            [22.959, 41.4946],
            [22.9589, 41.5356],
            [22.9732, 41.5616],
            [22.9527, 41.5874],
            [22.9484, 41.6329],
            [22.9818, 41.6561],
            [23.0337, 41.7111],
            [23.01, 41.7671],
            [22.9646, 41.7742],
            [22.9412, 41.8173],
            [22.9369, 41.8473],
            [22.9027, 41.8771],
            [22.8951, 41.916],
            [22.8751, 41.9361],
            [22.8783, 41.9761],
            [22.8695, 42.0145],
            [22.9153, 42.0362],
            [22.9252, 42.0896],
            [22.96, 42.1042],
            [22.9755, 42.0669],
            [23.0138, 42.0682],
            [23.0368, 42.0458],
            [23.1335, 42.0639],
            [23.1953, 42.0997],
            [23.3209, 42.0684],
            [23.3552, 42.0664],
            [23.3863, 42.0497],
            [23.4545, 42.0635],
            [23.4855, 42.085],
          ],
        ],
      },
      properties: {name: 'Blagoevgrad', id: 'BG-01', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-01',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.1035, 42.9329],
            [27.1646, 42.9579],
            [27.2372, 42.9542],
            [27.2969, 42.9418],
            [27.3612, 42.901],
            [27.413, 42.8761],
            [27.4699, 42.8813],
            [27.4939, 42.8693],
            [27.5254, 42.889],
            [27.5814, 42.8893],
            [27.6409, 42.88],
            [27.6887, 42.882],
            [27.7091, 42.8564],
            [27.7596, 42.8346],
            [27.8015, 42.8269],
            [27.8459, 42.8385],
            [27.8823, 42.8369],
            [27.9003, 42.7718],
            [27.8887, 42.7503],
            [27.8977, 42.7],
            [27.8087, 42.7015],
            [27.732, 42.7091],
            [27.7151, 42.6945],
            [27.72, 42.6573],
            [27.6343, 42.6227],
            [27.6337, 42.5779],
            [27.5999, 42.5581],
            [27.5589, 42.5505],
            [27.5182, 42.5552],
            [27.4983, 42.539],
            [27.4806, 42.488],
            [27.4477, 42.4626],
            [27.5213, 42.4304],
            [27.5463, 42.4557],
            [27.5871, 42.4586],
            [27.6094, 42.4449],
            [27.6513, 42.4506],
            [27.6453, 42.4232],
            [27.7049, 42.4098],
            [27.7121, 42.363],
            [27.7377, 42.3292],
            [27.7856, 42.3362],
            [27.7861, 42.3069],
            [27.7554, 42.2872],
            [27.7532, 42.2482],
            [27.792, 42.216],
            [27.8393, 42.1927],
            [27.8529, 42.1685],
            [27.8958, 42.1418],
            [27.9146, 42.1102],
            [27.9371, 42.104],
            [28.0132, 42.028],
            [28.0352, 41.9839],
            [27.9768, 41.9844],
            [27.9131, 41.9721],
            [27.8767, 41.9993],
            [27.8232, 41.9908],
            [27.823, 41.9517],
            [27.707, 41.9772],
            [27.6173, 41.9405],
            [27.5772, 41.9372],
            [27.5429, 41.9165],
            [27.5119, 41.9592],
            [27.4922, 41.9527],
            [27.4496, 41.968],
            [27.433, 41.9912],
            [27.3937, 42.0051],
            [27.3674, 42.0559],
            [27.2699, 42.1032],
            [27.2218, 42.0982],
            [27.1984, 42.0731],
            [27.1321, 42.067],
            [27.1091, 42.0861],
            [27.0681, 42.093],
            [27.0376, 42.0828],
            [27.0149, 42.1055],
            [26.9626, 42.1283],
            [26.9315, 42.172],
            [26.96, 42.197],
            [26.935, 42.2566],
            [26.9509, 42.2824],
            [26.9254, 42.2966],
            [26.9069, 42.3264],
            [26.8707, 42.3494],
            [26.8608, 42.3815],
            [26.8739, 42.393],
            [26.9475, 42.4023],
            [26.9699, 42.4314],
            [26.9584, 42.4448],
            [26.895, 42.4426],
            [26.8553, 42.4951],
            [26.839, 42.5284],
            [26.8049, 42.5602],
            [26.7654, 42.6277],
            [26.7152, 42.6639],
            [26.6814, 42.6982],
            [26.6852, 42.7255],
            [26.6415, 42.7768],
            [26.6008, 42.7802],
            [26.5708, 42.8128],
            [26.5841, 42.85],
            [26.5639, 42.903],
            [26.5366, 42.9228],
            [26.6005, 42.9246],
            [26.6057, 42.895],
            [26.6987, 42.8996],
            [26.748, 42.9291],
            [26.8301, 42.9318],
            [26.87, 42.9195],
            [26.9393, 42.9253],
            [26.9577, 42.9036],
            [26.988, 42.9013],
            [27.0522, 42.9285],
            [27.1035, 42.9329],
          ],
        ],
      },
      properties: {name: 'Burgas', id: 'BG-02', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-02',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.4254, 43.5887],
            [27.4834, 43.5475],
            [27.4919, 43.5052],
            [27.5248, 43.4991],
            [27.5841, 43.5342],
            [27.6187, 43.53],
            [27.6244, 43.4858],
            [27.6773, 43.4664],
            [27.7377, 43.4625],
            [27.7523, 43.4381],
            [27.8176, 43.3889],
            [27.8566, 43.3504],
            [27.9152, 43.3927],
            [27.9724, 43.3867],
            [28.0047, 43.3611],
            [28.0321, 43.3233],
            [28.0551, 43.3093],
            [28.0367, 43.2633],
            [28.0124, 43.2272],
            [27.9103, 43.1964],
            [27.915, 43.1734],
            [27.9466, 43.1677],
            [27.9128, 43.0618],
            [27.889, 43.0383],
            [27.8891, 43.0006],
            [27.9053, 42.9294],
            [27.8957, 42.9159],
            [27.9018, 42.8551],
            [27.8823, 42.8369],
            [27.8459, 42.8385],
            [27.8015, 42.8269],
            [27.7596, 42.8346],
            [27.7091, 42.8564],
            [27.6887, 42.882],
            [27.6409, 42.88],
            [27.5814, 42.8893],
            [27.5254, 42.889],
            [27.4939, 42.8693],
            [27.4699, 42.8813],
            [27.413, 42.8761],
            [27.3612, 42.901],
            [27.2969, 42.9418],
            [27.2372, 42.9542],
            [27.1646, 42.9579],
            [27.1035, 42.9329],
            [27.1063, 42.9622],
            [27.0829, 42.9894],
            [27.0775, 43.0214],
            [27.1178, 43.0231],
            [27.2206, 43.0498],
            [27.2097, 43.0601],
            [27.2047, 43.1103],
            [27.1831, 43.1249],
            [27.1897, 43.159],
            [27.2777, 43.1944],
            [27.2625, 43.2291],
            [27.2453, 43.3032],
            [27.286, 43.3078],
            [27.2995, 43.324],
            [27.2846, 43.3743],
            [27.2873, 43.3991],
            [27.3331, 43.4452],
            [27.335, 43.4993],
            [27.3047, 43.532],
            [27.3153, 43.5512],
            [27.4054, 43.5679],
            [27.4254, 43.5887],
          ],
        ],
      },
      properties: {name: 'Varna', id: 'BG-03', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-03',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.4448, 43.6277],
            [25.498, 43.6045],
            [25.4804, 43.5875],
            [25.4856, 43.5579],
            [25.5156, 43.5077],
            [25.5683, 43.4713],
            [25.5534, 43.4248],
            [25.6477, 43.3857],
            [25.7072, 43.4006],
            [25.7747, 43.3648],
            [25.8212, 43.3666],
            [25.8798, 43.3498],
            [25.9333, 43.3602],
            [25.9459, 43.3304],
            [25.9968, 43.322],
            [26.0456, 43.2919],
            [26.0545, 43.2579],
            [26.1049, 43.2107],
            [26.1087, 43.1878],
            [26.083, 43.1766],
            [26.0573, 43.1256],
            [26.0535, 43.0754],
            [26.0684, 43.0389],
            [26.1055, 43.0158],
            [26.0729, 42.9941],
            [26.071, 42.9759],
            [26.1814, 42.9503],
            [26.1545, 42.9201],
            [26.0375, 42.8744],
            [26.0343, 42.8264],
            [25.971, 42.7916],
            [25.9021, 42.8019],
            [25.8541, 42.7918],
            [25.8004, 42.7991],
            [25.7327, 42.8023],
            [25.6368, 42.7695],
            [25.6068, 42.787],
            [25.5841, 42.8336],
            [25.6238, 42.8604],
            [25.5951, 42.9139],
            [25.5421, 42.9307],
            [25.5431, 42.9662],
            [25.5858, 43.0056],
            [25.5102, 43.0408],
            [25.4462, 43.0509],
            [25.4126, 43.0456],
            [25.3876, 43.0614],
            [25.3158, 43.0588],
            [25.3219, 43.1057],
            [25.2836, 43.115],
            [25.2325, 43.0978],
            [25.1688, 43.1099],
            [25.1063, 43.1551],
            [25.101, 43.1842],
            [25.1359, 43.1887],
            [25.137, 43.2174],
            [25.1658, 43.2487],
            [25.1683, 43.2746],
            [25.2418, 43.3292],
            [25.2444, 43.3712],
            [25.2047, 43.3768],
            [25.2156, 43.4262],
            [25.1449, 43.4206],
            [25.143, 43.4497],
            [25.21, 43.4721],
            [25.2236, 43.5342],
            [25.1987, 43.5641],
            [25.2128, 43.5842],
            [25.2157, 43.6558],
            [25.2626, 43.677],
            [25.345, 43.626],
            [25.383, 43.6178],
            [25.4448, 43.6277],
          ],
        ],
      },
      properties: {name: 'Veliko Tarnovo', id: 'BG-04', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-04',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.0541, 43.794],
            [23.0142, 43.7742],
            [22.9628, 43.7739],
            [22.9652, 43.7452],
            [23.044, 43.7249],
            [23.0555, 43.6993],
            [22.9249, 43.6567],
            [22.9062, 43.6379],
            [22.8799, 43.5732],
            [22.847, 43.5512],
            [22.8162, 43.4985],
            [22.7719, 43.4774],
            [22.7672, 43.4314],
            [22.7301, 43.3922],
            [22.6795, 43.3943],
            [22.6599, 43.4269],
            [22.5993, 43.4358],
            [22.5715, 43.4641],
            [22.5353, 43.4704],
            [22.5071, 43.5181],
            [22.4881, 43.5674],
            [22.4908, 43.6393],
            [22.424, 43.6787],
            [22.4074, 43.697],
            [22.3943, 43.7599],
            [22.3569, 43.8113],
            [22.379, 43.8471],
            [22.4072, 43.9393],
            [22.4093, 44.004],
            [22.4837, 44.0202],
            [22.5289, 44.0203],
            [22.5333, 44.0514],
            [22.6209, 44.0636],
            [22.6105, 44.1074],
            [22.6275, 44.1843],
            [22.6737, 44.2162],
            [22.7384, 44.2006],
            [22.8073, 44.1559],
            [22.9398, 44.0996],
            [23.0254, 44.0908],
            [23.0447, 44.0529],
            [23.0102, 44.0109],
            [22.9054, 43.9938],
            [22.8814, 43.9776],
            [22.8409, 43.8812],
            [22.8563, 43.8467],
            [22.8775, 43.8325],
            [22.9689, 43.8091],
            [23.0541, 43.794],
          ],
        ],
      },
      properties: {name: 'Vidin', id: 'BG-05', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-05',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.3447, 43.6965],
            [24.3231, 43.6258],
            [24.2661, 43.6369],
            [24.2079, 43.5831],
            [24.1609, 43.5868],
            [24.174, 43.5614],
            [24.1843, 43.4871],
            [24.25, 43.4688],
            [24.2334, 43.4179],
            [24.2017, 43.4202],
            [24.1371, 43.4082],
            [24.1093, 43.3692],
            [23.9837, 43.3585],
            [23.9546, 43.2352],
            [23.968, 43.221],
            [24.017, 43.2296],
            [24.0374, 43.2117],
            [24.0473, 43.1174],
            [23.9883, 43.1104],
            [23.9928, 43.0676],
            [23.9706, 43.0394],
            [23.9337, 43.0559],
            [23.8359, 43.0578],
            [23.8098, 43.072],
            [23.7708, 43.0659],
            [23.7143, 43.0398],
            [23.6691, 43.0573],
            [23.6184, 43.0326],
            [23.5732, 42.9958],
            [23.5511, 42.9958],
            [23.4755, 43.0736],
            [23.4668, 43.112],
            [23.5053, 43.1516],
            [23.479, 43.168],
            [23.4153, 43.1722],
            [23.4042, 43.2115],
            [23.443, 43.2212],
            [23.4587, 43.2499],
            [23.4248, 43.2666],
            [23.3737, 43.272],
            [23.331, 43.2905],
            [23.3411, 43.3136],
            [23.3845, 43.3413],
            [23.4235, 43.3964],
            [23.4175, 43.4472],
            [23.457, 43.4939],
            [23.5609, 43.4876],
            [23.5884, 43.5187],
            [23.6356, 43.5348],
            [23.5873, 43.5684],
            [23.5805, 43.5852],
            [23.6183, 43.6183],
            [23.6449, 43.6642],
            [23.6482, 43.723],
            [23.6337, 43.7515],
            [23.6378, 43.7915],
            [23.7304, 43.8025],
            [23.766, 43.7829],
            [23.8653, 43.7529],
            [23.973, 43.7424],
            [24.058, 43.7179],
            [24.0891, 43.7],
            [24.1738, 43.6811],
            [24.3043, 43.6968],
            [24.3447, 43.6965],
          ],
        ],
      },
      properties: {name: 'Vratsa', id: 'BG-06', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-06',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.101, 43.1842],
            [25.1063, 43.1551],
            [25.1688, 43.1099],
            [25.2325, 43.0978],
            [25.2836, 43.115],
            [25.3219, 43.1057],
            [25.3158, 43.0588],
            [25.3876, 43.0614],
            [25.4126, 43.0456],
            [25.4462, 43.0509],
            [25.5102, 43.0408],
            [25.5858, 43.0056],
            [25.5431, 42.9662],
            [25.5421, 42.9307],
            [25.5951, 42.9139],
            [25.6238, 42.8604],
            [25.5841, 42.8336],
            [25.6068, 42.787],
            [25.6368, 42.7695],
            [25.611, 42.7498],
            [25.5839, 42.769],
            [25.5483, 42.7542],
            [25.4933, 42.7589],
            [25.4703, 42.7786],
            [25.4318, 42.7827],
            [25.4243, 42.7477],
            [25.3025, 42.7725],
            [25.2647, 42.7683],
            [25.201, 42.7967],
            [25.172, 42.7802],
            [25.136, 42.7782],
            [25.0929, 42.7593],
            [25.0194, 42.7661],
            [25.0215, 42.8055],
            [24.9496, 42.8844],
            [24.8992, 42.9024],
            [24.8817, 42.9461],
            [24.8817, 42.9754],
            [24.9116, 43.0228],
            [24.9573, 43.0347],
            [24.9473, 43.1075],
            [24.9874, 43.1455],
            [24.9795, 43.1959],
            [24.9895, 43.2064],
            [25.0415, 43.1905],
            [25.101, 43.1842],
          ],
        ],
      },
      properties: {name: 'Gabrovo', id: 'BG-07', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-07',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.0551, 43.3093],
            [28.0321, 43.3233],
            [28.0047, 43.3611],
            [27.9724, 43.3867],
            [27.9152, 43.3927],
            [27.8566, 43.3504],
            [27.8176, 43.3889],
            [27.7523, 43.4381],
            [27.7377, 43.4625],
            [27.6773, 43.4664],
            [27.6244, 43.4858],
            [27.6187, 43.53],
            [27.5841, 43.5342],
            [27.5248, 43.4991],
            [27.4919, 43.5052],
            [27.4834, 43.5475],
            [27.4254, 43.5887],
            [27.3876, 43.6235],
            [27.3279, 43.5939],
            [27.2691, 43.6422],
            [27.2229, 43.6469],
            [27.1975, 43.6689],
            [27.2174, 43.6871],
            [27.206, 43.7222],
            [27.2771, 43.7811],
            [27.3354, 43.79],
            [27.4346, 43.8577],
            [27.4878, 43.8451],
            [27.5189, 43.8721],
            [27.5387, 43.9084],
            [27.5638, 43.8889],
            [27.5951, 43.9066],
            [27.6332, 43.9461],
            [27.7043, 43.9709],
            [27.7547, 43.9584],
            [27.8444, 43.9666],
            [27.9171, 44.0082],
            [27.9441, 43.984],
            [27.9534, 43.942],
            [27.9773, 43.8977],
            [27.9937, 43.8432],
            [28.1116, 43.7988],
            [28.2363, 43.7584],
            [28.3469, 43.7523],
            [28.4462, 43.7336],
            [28.5791, 43.7388],
            [28.5641, 43.6883],
            [28.5794, 43.639],
            [28.5691, 43.6011],
            [28.6026, 43.5547],
            [28.6029, 43.5217],
            [28.5445, 43.4367],
            [28.4976, 43.4108],
            [28.4596, 43.3746],
            [28.3865, 43.4057],
            [28.3092, 43.4189],
            [28.2243, 43.4009],
            [28.1556, 43.4068],
            [28.0903, 43.3749],
            [28.0551, 43.3093],
          ],
        ],
      },
      properties: {name: 'Dobrich', id: 'BG-08', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-08',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.2849, 41.9373],
            [25.2714, 41.9035],
            [25.2999, 41.8132],
            [25.3596, 41.8489],
            [25.3836, 41.8058],
            [25.459, 41.7739],
            [25.5302, 41.7548],
            [25.6061, 41.7673],
            [25.6336, 41.7359],
            [25.6379, 41.7007],
            [25.659, 41.669],
            [25.6449, 41.6344],
            [25.718, 41.6136],
            [25.7542, 41.6451],
            [25.7894, 41.6535],
            [25.8338, 41.6457],
            [25.8507, 41.6048],
            [25.8079, 41.5797],
            [25.7882, 41.5303],
            [25.8438, 41.4725],
            [25.8358, 41.4288],
            [25.9067, 41.3795],
            [25.9326, 41.3531],
            [25.9375, 41.3207],
            [25.8845, 41.3054],
            [25.8245, 41.3473],
            [25.7792, 41.3218],
            [25.7208, 41.3182],
            [25.7078, 41.292],
            [25.6584, 41.3145],
            [25.6239, 41.3047],
            [25.5614, 41.3178],
            [25.5309, 41.2779],
            [25.4755, 41.2884],
            [25.4275, 41.2658],
            [25.3671, 41.2608],
            [25.3261, 41.2418],
            [25.2328, 41.2482],
            [25.2217, 41.281],
            [25.1696, 41.3136],
            [25.1522, 41.3394],
            [25.1401, 41.3837],
            [25.1736, 41.4136],
            [25.1475, 41.4308],
            [25.1609, 41.4662],
            [25.1442, 41.4954],
            [25.1157, 41.505],
            [25.0591, 41.4846],
            [25.0043, 41.5026],
            [24.9886, 41.5615],
            [24.9971, 41.5862],
            [25.0702, 41.6063],
            [25.0917, 41.6251],
            [25.0889, 41.672],
            [25.1021, 41.7085],
            [25.0801, 41.7535],
            [25.0766, 41.7865],
            [25.1357, 41.8221],
            [25.1531, 41.8564],
            [25.1901, 41.8953],
            [25.2849, 41.9373],
          ],
        ],
      },
      properties: {name: 'Kardzhali', id: 'BG-09', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-09',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.4744, 42.6786],
            [22.5154, 42.6533],
            [22.567, 42.6442],
            [22.6129, 42.5668],
            [22.6702, 42.5444],
            [22.6745, 42.4966],
            [22.7175, 42.4464],
            [22.7301, 42.4118],
            [22.7884, 42.4041],
            [22.8099, 42.3802],
            [22.8874, 42.3702],
            [22.9165, 42.3539],
            [22.938, 42.3859],
            [22.9787, 42.4098],
            [23.0551, 42.3788],
            [23.0869, 42.3828],
            [23.1102, 42.4112],
            [23.1292, 42.3793],
            [23.1875, 42.3546],
            [23.2192, 42.3513],
            [23.2497, 42.3684],
            [23.3232, 42.3605],
            [23.3482, 42.3377],
            [23.3592, 42.3024],
            [23.4261, 42.2518],
            [23.3761, 42.238],
            [23.3525, 42.2005],
            [23.3699, 42.1809],
            [23.4296, 42.1983],
            [23.4546, 42.1546],
            [23.5273, 42.1314],
            [23.4855, 42.085],
            [23.4545, 42.0635],
            [23.3863, 42.0497],
            [23.3552, 42.0664],
            [23.3209, 42.0684],
            [23.1953, 42.0997],
            [23.1335, 42.0639],
            [23.0368, 42.0458],
            [23.0138, 42.0682],
            [22.9755, 42.0669],
            [22.96, 42.1042],
            [22.9252, 42.0896],
            [22.9153, 42.0362],
            [22.8695, 42.0145],
            [22.8256, 42.0218],
            [22.8005, 42.0452],
            [22.7774, 42.0377],
            [22.7353, 42.0459],
            [22.7152, 42.0668],
            [22.6757, 42.0645],
            [22.6276, 42.0945],
            [22.6004, 42.0982],
            [22.5132, 42.1518],
            [22.5027, 42.1932],
            [22.4708, 42.2014],
            [22.3659, 42.3218],
            [22.4251, 42.3313],
            [22.4621, 42.3451],
            [22.4782, 42.3936],
            [22.5193, 42.3987],
            [22.543, 42.4629],
            [22.5578, 42.4799],
            [22.5455, 42.5081],
            [22.4971, 42.5359],
            [22.4396, 42.5878],
            [22.4658, 42.6389],
            [22.4744, 42.6786],
          ],
        ],
      },
      properties: {name: 'Kyustendil', id: 'BG-10', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-10',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.0374, 43.2117],
            [24.109, 43.2216],
            [24.1754, 43.2471],
            [24.2404, 43.2347],
            [24.3027, 43.2393],
            [24.3628, 43.2529],
            [24.3972, 43.2798],
            [24.4724, 43.2345],
            [24.651, 43.2178],
            [24.6567, 43.2627],
            [24.6997, 43.2481],
            [24.7193, 43.2779],
            [24.7902, 43.3152],
            [24.8528, 43.3159],
            [24.9272, 43.2898],
            [24.9847, 43.3044],
            [25.0227, 43.3361],
            [25.0331, 43.3633],
            [25.0572, 43.3664],
            [25.0888, 43.3088],
            [25.1683, 43.2746],
            [25.1658, 43.2487],
            [25.137, 43.2174],
            [25.1359, 43.1887],
            [25.101, 43.1842],
            [25.0415, 43.1905],
            [24.9895, 43.2064],
            [24.9795, 43.1959],
            [24.9874, 43.1455],
            [24.9473, 43.1075],
            [24.9573, 43.0347],
            [24.9116, 43.0228],
            [24.8817, 42.9754],
            [24.8817, 42.9461],
            [24.8992, 42.9024],
            [24.9496, 42.8844],
            [25.0215, 42.8055],
            [25.0194, 42.7661],
            [24.9392, 42.7379],
            [24.8979, 42.7344],
            [24.857, 42.748],
            [24.7632, 42.7365],
            [24.7361, 42.7423],
            [24.7125, 42.7752],
            [24.6694, 42.8002],
            [24.5389, 42.8116],
            [24.4899, 42.7759],
            [24.4063, 42.776],
            [24.3052, 42.7974],
            [24.2501, 42.7942],
            [24.1871, 42.8009],
            [24.2201, 42.8553],
            [24.1899, 42.8807],
            [24.1896, 42.9056],
            [24.1576, 42.9263],
            [24.1877, 42.9481],
            [24.1248, 42.9692],
            [24.1109, 43.0205],
            [24.0695, 43.0329],
            [24.055, 43.0653],
            [23.9928, 43.0676],
            [23.9883, 43.1104],
            [24.0473, 43.1174],
            [24.0374, 43.2117],
          ],
        ],
      },
      properties: {name: 'Lovech', id: 'BG-11', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-11',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.6378, 43.7915],
            [23.6337, 43.7515],
            [23.6482, 43.723],
            [23.6449, 43.6642],
            [23.6183, 43.6183],
            [23.5805, 43.5852],
            [23.5873, 43.5684],
            [23.6356, 43.5348],
            [23.5884, 43.5187],
            [23.5609, 43.4876],
            [23.457, 43.4939],
            [23.4175, 43.4472],
            [23.4235, 43.3964],
            [23.3845, 43.3413],
            [23.3411, 43.3136],
            [23.331, 43.2905],
            [23.3737, 43.272],
            [23.4248, 43.2666],
            [23.4587, 43.2499],
            [23.443, 43.2212],
            [23.4042, 43.2115],
            [23.4153, 43.1722],
            [23.3499, 43.175],
            [23.3051, 43.1394],
            [23.2199, 43.1289],
            [23.106, 43.1294],
            [23.0514, 43.173],
            [23.0111, 43.1894],
            [22.9756, 43.216],
            [22.9391, 43.204],
            [22.8972, 43.2259],
            [22.8489, 43.2752],
            [22.8268, 43.3302],
            [22.7961, 43.3429],
            [22.7674, 43.3769],
            [22.7301, 43.3922],
            [22.7672, 43.4314],
            [22.7719, 43.4774],
            [22.8162, 43.4985],
            [22.847, 43.5512],
            [22.8799, 43.5732],
            [22.9062, 43.6379],
            [22.9249, 43.6567],
            [23.0555, 43.6993],
            [23.044, 43.7249],
            [22.9652, 43.7452],
            [22.9628, 43.7739],
            [23.0142, 43.7742],
            [23.0541, 43.794],
            [23.1439, 43.8057],
            [23.2198, 43.8332],
            [23.2725, 43.8446],
            [23.4144, 43.8503],
            [23.5218, 43.8296],
            [23.5834, 43.7951],
            [23.6378, 43.7915],
          ],
        ],
      },
      properties: {name: 'Montana', id: 'BG-12', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-12',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.778, 42.1836],
            [23.8125, 42.2094],
            [23.8573, 42.229],
            [23.9032, 42.2757],
            [23.9408, 42.2599],
            [23.9676, 42.2821],
            [23.9502, 42.3039],
            [23.9701, 42.3552],
            [24.0238, 42.3654],
            [24.0432, 42.378],
            [24.0809, 42.4366],
            [24.0659, 42.4609],
            [23.9932, 42.4439],
            [23.9399, 42.4972],
            [23.9317, 42.566],
            [23.9908, 42.5757],
            [24.0561, 42.5534],
            [24.1241, 42.6038],
            [24.1664, 42.6021],
            [24.1867, 42.643],
            [24.2212, 42.6421],
            [24.2679, 42.657],
            [24.3222, 42.643],
            [24.3503, 42.5826],
            [24.3923, 42.5785],
            [24.4183, 42.5605],
            [24.4122, 42.5253],
            [24.3792, 42.4996],
            [24.3736, 42.4795],
            [24.4123, 42.4089],
            [24.4164, 42.3801],
            [24.4628, 42.3427],
            [24.4647, 42.3123],
            [24.4456, 42.3007],
            [24.4617, 42.2652],
            [24.467, 42.2051],
            [24.5119, 42.1617],
            [24.5153, 42.1433],
            [24.4575, 42.131],
            [24.4418, 42.0554],
            [24.4517, 42.0255],
            [24.4483, 41.9896],
            [24.4223, 41.9292],
            [24.4166, 41.887],
            [24.3969, 41.8645],
            [24.3487, 41.849],
            [24.3194, 41.8154],
            [24.3284, 41.7641],
            [24.2726, 41.7312],
            [24.2067, 41.741],
            [24.1162, 41.7317],
            [24.1038, 41.6705],
            [24.0444, 41.7055],
            [23.9971, 41.7453],
            [23.9074, 41.7964],
            [23.8006, 41.8308],
            [23.7766, 41.851],
            [23.7674, 41.921],
            [23.7511, 41.9659],
            [23.7549, 41.9921],
            [23.7969, 42.0233],
            [23.8074, 42.0696],
            [23.7902, 42.1081],
            [23.7932, 42.1356],
            [23.7706, 42.1457],
            [23.778, 42.1836],
          ],
        ],
      },
      properties: {name: 'Pazardzhik', id: 'BG-13', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-13',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.7539, 42.8943],
            [22.772, 42.8607],
            [22.813, 42.8628],
            [22.8368, 42.8313],
            [22.8942, 42.818],
            [22.9237, 42.7981],
            [22.9673, 42.7923],
            [22.9994, 42.7628],
            [23.0421, 42.7514],
            [23.0559, 42.7353],
            [23.0951, 42.7091],
            [23.1204, 42.6795],
            [23.1612, 42.6611],
            [23.1744, 42.5922],
            [23.2096, 42.59],
            [23.2484, 42.5471],
            [23.2774, 42.5388],
            [23.2577, 42.5192],
            [23.238, 42.4694],
            [23.2405, 42.4076],
            [23.2497, 42.3684],
            [23.2192, 42.3513],
            [23.1875, 42.3546],
            [23.1292, 42.3793],
            [23.1102, 42.4112],
            [23.0869, 42.3828],
            [23.0551, 42.3788],
            [22.9787, 42.4098],
            [22.938, 42.3859],
            [22.9165, 42.3539],
            [22.8874, 42.3702],
            [22.8099, 42.3802],
            [22.7884, 42.4041],
            [22.7301, 42.4118],
            [22.7175, 42.4464],
            [22.6745, 42.4966],
            [22.6702, 42.5444],
            [22.6129, 42.5668],
            [22.567, 42.6442],
            [22.5154, 42.6533],
            [22.4744, 42.6786],
            [22.4762, 42.7084],
            [22.501, 42.7403],
            [22.4616, 42.7772],
            [22.4415, 42.8234],
            [22.4756, 42.8315],
            [22.534, 42.8803],
            [22.574, 42.864],
            [22.5839, 42.8916],
            [22.6183, 42.8955],
            [22.6402, 42.8777],
            [22.6834, 42.8728],
            [22.7539, 42.8943],
          ],
        ],
      },
      properties: {name: 'Pernik', id: 'BG-14', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-14',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.2626, 43.677],
            [25.2157, 43.6558],
            [25.2128, 43.5842],
            [25.1987, 43.5641],
            [25.2236, 43.5342],
            [25.21, 43.4721],
            [25.143, 43.4497],
            [25.1449, 43.4206],
            [25.2156, 43.4262],
            [25.2047, 43.3768],
            [25.2444, 43.3712],
            [25.2418, 43.3292],
            [25.1683, 43.2746],
            [25.0888, 43.3088],
            [25.0572, 43.3664],
            [25.0331, 43.3633],
            [25.0227, 43.3361],
            [24.9847, 43.3044],
            [24.9272, 43.2898],
            [24.8528, 43.3159],
            [24.7902, 43.3152],
            [24.7193, 43.2779],
            [24.6997, 43.2481],
            [24.6567, 43.2627],
            [24.651, 43.2178],
            [24.4724, 43.2345],
            [24.3972, 43.2798],
            [24.3628, 43.2529],
            [24.3027, 43.2393],
            [24.2404, 43.2347],
            [24.1754, 43.2471],
            [24.109, 43.2216],
            [24.0374, 43.2117],
            [24.017, 43.2296],
            [23.968, 43.221],
            [23.9546, 43.2352],
            [23.9837, 43.3585],
            [24.1093, 43.3692],
            [24.1371, 43.4082],
            [24.2017, 43.4202],
            [24.2334, 43.4179],
            [24.25, 43.4688],
            [24.1843, 43.4871],
            [24.174, 43.5614],
            [24.1609, 43.5868],
            [24.2079, 43.5831],
            [24.2661, 43.6369],
            [24.3231, 43.6258],
            [24.3447, 43.6965],
            [24.4055, 43.7344],
            [24.5107, 43.7613],
            [24.6289, 43.7407],
            [24.7149, 43.6865],
            [24.744, 43.6826],
            [24.8066, 43.7087],
            [24.8863, 43.7047],
            [24.9612, 43.7279],
            [24.9998, 43.7254],
            [25.0572, 43.6907],
            [25.1011, 43.683],
            [25.184, 43.6973],
            [25.2626, 43.677],
          ],
        ],
      },
      properties: {name: 'Pleven', id: 'BG-15', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-15',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.4063, 42.776],
            [24.4899, 42.7759],
            [24.5389, 42.8116],
            [24.6694, 42.8002],
            [24.7125, 42.7752],
            [24.7361, 42.7423],
            [24.7632, 42.7365],
            [24.857, 42.748],
            [24.8979, 42.7344],
            [24.9392, 42.7379],
            [25.0194, 42.7661],
            [25.009, 42.7484],
            [25.0147, 42.6793],
            [25.0085, 42.6484],
            [24.981, 42.5976],
            [24.9992, 42.5498],
            [25.0297, 42.51],
            [25.0862, 42.4996],
            [25.1716, 42.5266],
            [25.1947, 42.4865],
            [25.1504, 42.4389],
            [25.1786, 42.4153],
            [25.1697, 42.3983],
            [25.1289, 42.3964],
            [25.1288, 42.3039],
            [25.1691, 42.2894],
            [25.1718, 42.2743],
            [25.0981, 42.2571],
            [25.0745, 42.2453],
            [25.0637, 42.2075],
            [25.0759, 42.1725],
            [25.1405, 42.1503],
            [25.1923, 42.1625],
            [25.2368, 42.1621],
            [25.2799, 42.1799],
            [25.3046, 42.1484],
            [25.3474, 42.1198],
            [25.3067, 42.0411],
            [25.311, 41.9614],
            [25.2849, 41.9373],
            [25.1901, 41.8953],
            [25.1531, 41.8564],
            [25.1357, 41.8221],
            [25.0766, 41.7865],
            [25.0219, 41.8325],
            [24.9972, 41.8183],
            [24.9941, 41.7679],
            [24.9556, 41.7345],
            [24.8853, 41.7361],
            [24.8419, 41.7065],
            [24.8291, 41.6853],
            [24.7912, 41.7332],
            [24.8053, 41.7686],
            [24.7763, 41.812],
            [24.7857, 41.8511],
            [24.7536, 41.8874],
            [24.7154, 41.8838],
            [24.6581, 41.8945],
            [24.5821, 41.8723],
            [24.528, 41.8766],
            [24.4934, 41.917],
            [24.466, 41.9148],
            [24.4223, 41.9292],
            [24.4483, 41.9896],
            [24.4517, 42.0255],
            [24.4418, 42.0554],
            [24.4575, 42.131],
            [24.5153, 42.1433],
            [24.5119, 42.1617],
            [24.467, 42.2051],
            [24.4617, 42.2652],
            [24.4456, 42.3007],
            [24.4647, 42.3123],
            [24.4628, 42.3427],
            [24.4164, 42.3801],
            [24.4123, 42.4089],
            [24.3736, 42.4795],
            [24.3792, 42.4996],
            [24.4122, 42.5253],
            [24.4183, 42.5605],
            [24.4694, 42.5642],
            [24.5014, 42.6142],
            [24.4496, 42.6522],
            [24.3872, 42.671],
            [24.3661, 42.7082],
            [24.4063, 42.776],
          ],
        ],
      },
      properties: {name: 'Plovdiv', id: 'BG-16', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-16',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.3666, 43.9379],
            [26.4607, 43.9338],
            [26.4847, 43.9059],
            [26.5535, 43.9118],
            [26.579, 43.8873],
            [26.5871, 43.8553],
            [26.7652, 43.8138],
            [26.8014, 43.8409],
            [26.852, 43.8024],
            [26.885, 43.7967],
            [26.9054, 43.7649],
            [26.9562, 43.7484],
            [26.9639, 43.7242],
            [27.0065, 43.6954],
            [27.0144, 43.657],
            [26.9571, 43.6291],
            [26.9444, 43.6074],
            [26.8976, 43.5986],
            [26.8607, 43.6064],
            [26.8563, 43.5795],
            [26.8048, 43.5509],
            [26.7934, 43.5277],
            [26.8039, 43.5046],
            [26.7393, 43.4586],
            [26.7377, 43.4254],
            [26.7862, 43.4024],
            [26.784, 43.3846],
            [26.7439, 43.3616],
            [26.6854, 43.3593],
            [26.6556, 43.3431],
            [26.5889, 43.3419],
            [26.528, 43.3591],
            [26.4845, 43.3433],
            [26.4592, 43.3478],
            [26.4167, 43.2918],
            [26.4149, 43.2723],
            [26.3726, 43.2575],
            [26.3745, 43.2914],
            [26.3291, 43.3149],
            [26.3249, 43.337],
            [26.393, 43.3688],
            [26.4222, 43.3914],
            [26.416, 43.444],
            [26.3881, 43.4422],
            [26.3621, 43.4602],
            [26.3486, 43.4953],
            [26.3073, 43.5018],
            [26.2787, 43.5292],
            [26.2396, 43.5503],
            [26.2061, 43.5455],
            [26.1829, 43.522],
            [26.1029, 43.5224],
            [26.0908, 43.5302],
            [26.0546, 43.5817],
            [26.0587, 43.6064],
            [26.1264, 43.6192],
            [26.1653, 43.6559],
            [26.2274, 43.6438],
            [26.2799, 43.6126],
            [26.3676, 43.6338],
            [26.4239, 43.6245],
            [26.4842, 43.6299],
            [26.5174, 43.6669],
            [26.5173, 43.7174],
            [26.4806, 43.76],
            [26.4399, 43.7654],
            [26.3911, 43.7907],
            [26.3397, 43.7827],
            [26.2656, 43.7947],
            [26.2743, 43.8261],
            [26.3037, 43.8381],
            [26.3333, 43.9033],
            [26.3666, 43.9379],
          ],
        ],
      },
      properties: {name: 'Razgrad', id: 'BG-17', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-17',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.3332, 44.0314],
            [26.3385, 44.0079],
            [26.3856, 43.9602],
            [26.3666, 43.9379],
            [26.3333, 43.9033],
            [26.3037, 43.8381],
            [26.2743, 43.8261],
            [26.2656, 43.7947],
            [26.3397, 43.7827],
            [26.3911, 43.7907],
            [26.4399, 43.7654],
            [26.4806, 43.76],
            [26.5173, 43.7174],
            [26.5174, 43.6669],
            [26.4842, 43.6299],
            [26.4239, 43.6245],
            [26.3676, 43.6338],
            [26.2799, 43.6126],
            [26.2274, 43.6438],
            [26.1653, 43.6559],
            [26.1264, 43.6192],
            [26.0587, 43.6064],
            [26.0546, 43.5817],
            [26.0908, 43.5302],
            [26.0262, 43.5077],
            [26.0139, 43.4547],
            [25.9739, 43.4023],
            [25.9507, 43.3897],
            [26.0013, 43.3583],
            [25.9968, 43.322],
            [25.9459, 43.3304],
            [25.9333, 43.3602],
            [25.8798, 43.3498],
            [25.8212, 43.3666],
            [25.7747, 43.3648],
            [25.7072, 43.4006],
            [25.6477, 43.3857],
            [25.5534, 43.4248],
            [25.5683, 43.4713],
            [25.5156, 43.5077],
            [25.4856, 43.5579],
            [25.4804, 43.5875],
            [25.498, 43.6045],
            [25.4448, 43.6277],
            [25.4838, 43.6419],
            [25.5584, 43.6428],
            [25.6597, 43.6875],
            [25.7308, 43.6892],
            [25.783, 43.7094],
            [25.8115, 43.7459],
            [25.8503, 43.7585],
            [25.9533, 43.8613],
            [26.0571, 43.9074],
            [26.0818, 43.9458],
            [26.1493, 43.9855],
            [26.2019, 43.9856],
            [26.3332, 44.0314],
          ],
        ],
      },
      properties: {name: 'Ruse', id: 'BG-18', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-18',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.7043, 43.9709],
            [27.6332, 43.9461],
            [27.5951, 43.9066],
            [27.5638, 43.8889],
            [27.5387, 43.9084],
            [27.5189, 43.8721],
            [27.4878, 43.8451],
            [27.4346, 43.8577],
            [27.3354, 43.79],
            [27.2771, 43.7811],
            [27.206, 43.7222],
            [27.2174, 43.6871],
            [27.1975, 43.6689],
            [27.1536, 43.6676],
            [27.0867, 43.7193],
            [27.0065, 43.6954],
            [26.9639, 43.7242],
            [26.9562, 43.7484],
            [26.9054, 43.7649],
            [26.885, 43.7967],
            [26.852, 43.8024],
            [26.8014, 43.8409],
            [26.7652, 43.8138],
            [26.5871, 43.8553],
            [26.579, 43.8873],
            [26.5535, 43.9118],
            [26.4847, 43.9059],
            [26.4607, 43.9338],
            [26.3666, 43.9379],
            [26.3856, 43.9602],
            [26.3385, 44.0079],
            [26.3332, 44.0314],
            [26.3826, 44.0412],
            [26.4426, 44.0327],
            [26.5356, 44.0512],
            [26.5993, 44.0484],
            [26.684, 44.0704],
            [26.7576, 44.0735],
            [26.8513, 44.1125],
            [26.9073, 44.1289],
            [26.9908, 44.1306],
            [27.0636, 44.1463],
            [27.1294, 44.1369],
            [27.1829, 44.118],
            [27.2361, 44.1128],
            [27.2721, 44.1221],
            [27.2928, 44.07],
            [27.3585, 44.0528],
            [27.4019, 44.0078],
            [27.474, 44.0179],
            [27.6134, 44.0082],
            [27.6454, 44.0438],
            [27.6748, 44.0261],
            [27.7043, 43.9709],
          ],
        ],
      },
      properties: {name: 'Silistra', id: 'BG-19', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-19',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.1814, 42.9503],
            [26.2323, 42.9822],
            [26.2524, 43.0259],
            [26.313, 43.0298],
            [26.3912, 42.9934],
            [26.5149, 43.0091],
            [26.5955, 42.9975],
            [26.53, 42.9549],
            [26.5366, 42.9228],
            [26.5639, 42.903],
            [26.5841, 42.85],
            [26.5708, 42.8128],
            [26.6008, 42.7802],
            [26.6415, 42.7768],
            [26.6852, 42.7255],
            [26.6814, 42.6982],
            [26.6739, 42.6432],
            [26.6444, 42.616],
            [26.6161, 42.6277],
            [26.5939, 42.599],
            [26.5445, 42.5953],
            [26.4726, 42.5603],
            [26.4451, 42.5535],
            [26.399, 42.5607],
            [26.3781, 42.5443],
            [26.3813, 42.5097],
            [26.335, 42.4724],
            [26.2931, 42.4684],
            [26.2719, 42.4532],
            [26.2091, 42.4674],
            [26.2018, 42.4291],
            [26.2305, 42.405],
            [26.2241, 42.3439],
            [26.1892, 42.3004],
            [26.165, 42.246],
            [26.0602, 42.2927],
            [26.0222, 42.289],
            [25.9366, 42.3306],
            [25.9079, 42.4039],
            [25.8695, 42.4385],
            [25.894, 42.4829],
            [25.8581, 42.5408],
            [25.8883, 42.5857],
            [25.8575, 42.6333],
            [25.8454, 42.6868],
            [25.8043, 42.7472],
            [25.8004, 42.7991],
            [25.8541, 42.7918],
            [25.9021, 42.8019],
            [25.971, 42.7916],
            [26.0343, 42.8264],
            [26.0375, 42.8744],
            [26.1545, 42.9201],
            [26.1814, 42.9503],
          ],
        ],
      },
      properties: {name: 'Sliven', id: 'BG-20', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-20',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.4223, 41.9292],
            [24.466, 41.9148],
            [24.4934, 41.917],
            [24.528, 41.8766],
            [24.5821, 41.8723],
            [24.6581, 41.8945],
            [24.7154, 41.8838],
            [24.7536, 41.8874],
            [24.7857, 41.8511],
            [24.7763, 41.812],
            [24.8053, 41.7686],
            [24.7912, 41.7332],
            [24.8291, 41.6853],
            [24.8419, 41.7065],
            [24.8853, 41.7361],
            [24.9556, 41.7345],
            [24.9941, 41.7679],
            [24.9972, 41.8183],
            [25.0219, 41.8325],
            [25.0766, 41.7865],
            [25.0801, 41.7535],
            [25.1021, 41.7085],
            [25.0889, 41.672],
            [25.0917, 41.6251],
            [25.0702, 41.6063],
            [24.9971, 41.5862],
            [24.9886, 41.5615],
            [25.0043, 41.5026],
            [25.0591, 41.4846],
            [25.1157, 41.505],
            [25.1442, 41.4954],
            [25.1609, 41.4662],
            [25.1475, 41.4308],
            [25.1736, 41.4136],
            [25.1401, 41.3837],
            [25.1522, 41.3394],
            [25.1696, 41.3136],
            [25.116, 41.3402],
            [25.0782, 41.3412],
            [25.0352, 41.365],
            [24.9549, 41.3862],
            [24.9134, 41.4078],
            [24.8817, 41.3922],
            [24.8185, 41.4006],
            [24.8159, 41.355],
            [24.8021, 41.3468],
            [24.7457, 41.3813],
            [24.7353, 41.4104],
            [24.6545, 41.4349],
            [24.6152, 41.4241],
            [24.5964, 41.4642],
            [24.5728, 41.4758],
            [24.5534, 41.5501],
            [24.5214, 41.567],
            [24.4631, 41.5432],
            [24.4619, 41.5276],
            [24.3843, 41.5361],
            [24.3659, 41.5168],
            [24.3019, 41.5377],
            [24.2702, 41.5578],
            [24.2123, 41.5518],
            [24.1857, 41.5151],
            [24.1505, 41.5386],
            [24.0778, 41.5419],
            [24.0621, 41.5253],
            [24.0607, 41.5473],
            [24.0859, 41.5938],
            [24.0823, 41.619],
            [24.1147, 41.6531],
            [24.1038, 41.6705],
            [24.1162, 41.7317],
            [24.2067, 41.741],
            [24.2726, 41.7312],
            [24.3284, 41.7641],
            [24.3194, 41.8154],
            [24.3487, 41.849],
            [24.3969, 41.8645],
            [24.4166, 41.887],
            [24.4223, 41.9292],
          ],
        ],
      },
      properties: {name: 'Smolyan', id: 'BG-21', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-21',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.9391, 43.204],
            [22.9756, 43.216],
            [23.0111, 43.1894],
            [23.0514, 43.173],
            [23.106, 43.1294],
            [23.2199, 43.1289],
            [23.3051, 43.1394],
            [23.3499, 43.175],
            [23.4153, 43.1722],
            [23.479, 43.168],
            [23.5053, 43.1516],
            [23.4668, 43.112],
            [23.4755, 43.0736],
            [23.5511, 42.9958],
            [23.5732, 42.9958],
            [23.6184, 43.0326],
            [23.6691, 43.0573],
            [23.7143, 43.0398],
            [23.7708, 43.0659],
            [23.8098, 43.072],
            [23.8359, 43.0578],
            [23.9337, 43.0559],
            [23.9706, 43.0394],
            [23.9928, 43.0676],
            [24.055, 43.0653],
            [24.0695, 43.0329],
            [24.1109, 43.0205],
            [24.1248, 42.9692],
            [24.1877, 42.9481],
            [24.1576, 42.9263],
            [24.1896, 42.9056],
            [24.1899, 42.8807],
            [24.2201, 42.8553],
            [24.1871, 42.8009],
            [24.2501, 42.7942],
            [24.3052, 42.7974],
            [24.4063, 42.776],
            [24.3661, 42.7082],
            [24.3872, 42.671],
            [24.4496, 42.6522],
            [24.5014, 42.6142],
            [24.4694, 42.5642],
            [24.4183, 42.5605],
            [24.3923, 42.5785],
            [24.3503, 42.5826],
            [24.3222, 42.643],
            [24.2679, 42.657],
            [24.2212, 42.6421],
            [24.1867, 42.643],
            [24.1664, 42.6021],
            [24.1241, 42.6038],
            [24.0561, 42.5534],
            [23.9908, 42.5757],
            [23.9317, 42.566],
            [23.9399, 42.4972],
            [23.9932, 42.4439],
            [24.0659, 42.4609],
            [24.0809, 42.4366],
            [24.0432, 42.378],
            [24.0238, 42.3654],
            [23.9701, 42.3552],
            [23.9502, 42.3039],
            [23.9676, 42.2821],
            [23.9408, 42.2599],
            [23.9032, 42.2757],
            [23.8573, 42.229],
            [23.8125, 42.2094],
            [23.778, 42.1836],
            [23.7502, 42.1836],
            [23.7482, 42.1491],
            [23.698, 42.1613],
            [23.6784, 42.1904],
            [23.6113, 42.1904],
            [23.5992, 42.1789],
            [23.5846, 42.1191],
            [23.5449, 42.0868],
            [23.4855, 42.085],
            [23.5273, 42.1314],
            [23.4546, 42.1546],
            [23.4296, 42.1983],
            [23.3699, 42.1809],
            [23.3525, 42.2005],
            [23.3761, 42.238],
            [23.4261, 42.2518],
            [23.3592, 42.3024],
            [23.3482, 42.3377],
            [23.3232, 42.3605],
            [23.2497, 42.3684],
            [23.2405, 42.4076],
            [23.238, 42.4694],
            [23.2577, 42.5192],
            [23.2774, 42.5388],
            [23.3198, 42.528],
            [23.3678, 42.4985],
            [23.4122, 42.4512],
            [23.4416, 42.4558],
            [23.4647, 42.5233],
            [23.501, 42.517],
            [23.5294, 42.4687],
            [23.5328, 42.4078],
            [23.6025, 42.3964],
            [23.6634, 42.4127],
            [23.6346, 42.4779],
            [23.5705, 42.5578],
            [23.5738, 42.6214],
            [23.5604, 42.6457],
            [23.5274, 42.6518],
            [23.5607, 42.7135],
            [23.6102, 42.7323],
            [23.639, 42.8127],
            [23.6261, 42.8498],
            [23.5682, 42.8331],
            [23.4966, 42.8697],
            [23.4532, 42.8672],
            [23.3716, 42.8497],
            [23.3376, 42.8638],
            [23.3219, 42.8935],
            [23.288, 42.9043],
            [23.2785, 42.8757],
            [23.2211, 42.7758],
            [23.2359, 42.758],
            [23.176, 42.7374],
            [23.0559, 42.7353],
            [23.0421, 42.7514],
            [22.9994, 42.7628],
            [22.9673, 42.7923],
            [22.9237, 42.7981],
            [22.8942, 42.818],
            [22.8368, 42.8313],
            [22.813, 42.8628],
            [22.772, 42.8607],
            [22.7539, 42.8943],
            [22.7796, 42.9312],
            [22.784, 42.9816],
            [22.8478, 43.0031],
            [22.8965, 43.0345],
            [22.9205, 43.0809],
            [22.9824, 43.1106],
            [23.006, 43.1911],
            [22.9391, 43.204],
          ],
        ],
      },
      properties: {name: 'Sofia-Grad', id: 'BG-22', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-22',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.0559, 42.7353],
            [23.176, 42.7374],
            [23.2359, 42.758],
            [23.2211, 42.7758],
            [23.2785, 42.8757],
            [23.288, 42.9043],
            [23.3219, 42.8935],
            [23.3376, 42.8638],
            [23.3716, 42.8497],
            [23.4532, 42.8672],
            [23.4966, 42.8697],
            [23.5682, 42.8331],
            [23.6261, 42.8498],
            [23.639, 42.8127],
            [23.6102, 42.7323],
            [23.5607, 42.7135],
            [23.5274, 42.6518],
            [23.5604, 42.6457],
            [23.5738, 42.6214],
            [23.5705, 42.5578],
            [23.6346, 42.4779],
            [23.6634, 42.4127],
            [23.6025, 42.3964],
            [23.5328, 42.4078],
            [23.5294, 42.4687],
            [23.501, 42.517],
            [23.4647, 42.5233],
            [23.4416, 42.4558],
            [23.4122, 42.4512],
            [23.3678, 42.4985],
            [23.3198, 42.528],
            [23.2774, 42.5388],
            [23.2484, 42.5471],
            [23.2096, 42.59],
            [23.1744, 42.5922],
            [23.1612, 42.6611],
            [23.1204, 42.6795],
            [23.0951, 42.7091],
            [23.0559, 42.7353],
          ],
        ],
      },
      properties: {name: 'Sofia', id: 'BG-23', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-23',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.6368, 42.7695],
            [25.7327, 42.8023],
            [25.8004, 42.7991],
            [25.8043, 42.7472],
            [25.8454, 42.6868],
            [25.8575, 42.6333],
            [25.8883, 42.5857],
            [25.8581, 42.5408],
            [25.894, 42.4829],
            [25.8695, 42.4385],
            [25.9079, 42.4039],
            [25.9366, 42.3306],
            [26.0222, 42.289],
            [26.0602, 42.2927],
            [26.165, 42.246],
            [26.2018, 42.229],
            [26.1251, 42.1769],
            [26.1177, 42.1459],
            [26.1366, 42.1137],
            [26.1538, 42.0589],
            [26.0931, 42.0478],
            [26.0683, 42.0241],
            [26.0385, 42.026],
            [25.9958, 42.091],
            [25.9523, 42.0951],
            [25.9283, 42.1123],
            [25.8515, 42.1222],
            [25.8359, 42.0784],
            [25.7948, 42.0609],
            [25.7379, 42.0518],
            [25.7208, 42.0941],
            [25.6964, 42.1132],
            [25.6834, 42.1426],
            [25.6582, 42.1468],
            [25.6201, 42.1209],
            [25.6048, 42.1323],
            [25.609, 42.1732],
            [25.5884, 42.206],
            [25.5453, 42.169],
            [25.5316, 42.1415],
            [25.4874, 42.1576],
            [25.4348, 42.0958],
            [25.3657, 42.1202],
            [25.3474, 42.1198],
            [25.3046, 42.1484],
            [25.2799, 42.1799],
            [25.2368, 42.1621],
            [25.1923, 42.1625],
            [25.1405, 42.1503],
            [25.0759, 42.1725],
            [25.0637, 42.2075],
            [25.0745, 42.2453],
            [25.0981, 42.2571],
            [25.1718, 42.2743],
            [25.1691, 42.2894],
            [25.1288, 42.3039],
            [25.1289, 42.3964],
            [25.1697, 42.3983],
            [25.1786, 42.4153],
            [25.1504, 42.4389],
            [25.1947, 42.4865],
            [25.1716, 42.5266],
            [25.0862, 42.4996],
            [25.0297, 42.51],
            [24.9992, 42.5498],
            [24.981, 42.5976],
            [25.0085, 42.6484],
            [25.0147, 42.6793],
            [25.009, 42.7484],
            [25.0194, 42.7661],
            [25.0929, 42.7593],
            [25.136, 42.7782],
            [25.172, 42.7802],
            [25.201, 42.7967],
            [25.2647, 42.7683],
            [25.3025, 42.7725],
            [25.4243, 42.7477],
            [25.4318, 42.7827],
            [25.4703, 42.7786],
            [25.4933, 42.7589],
            [25.5483, 42.7542],
            [25.5839, 42.769],
            [25.611, 42.7498],
            [25.6368, 42.7695],
          ],
        ],
      },
      properties: {name: 'Stara Zagora', id: 'BG-24', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-24',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.0908, 43.5302],
            [26.1029, 43.5224],
            [26.1829, 43.522],
            [26.2061, 43.5455],
            [26.2396, 43.5503],
            [26.2787, 43.5292],
            [26.3073, 43.5018],
            [26.3486, 43.4953],
            [26.3621, 43.4602],
            [26.3881, 43.4422],
            [26.416, 43.444],
            [26.4222, 43.3914],
            [26.393, 43.3688],
            [26.3249, 43.337],
            [26.3291, 43.3149],
            [26.3745, 43.2914],
            [26.3726, 43.2575],
            [26.4149, 43.2723],
            [26.4167, 43.2918],
            [26.4592, 43.3478],
            [26.4845, 43.3433],
            [26.528, 43.3591],
            [26.5889, 43.3419],
            [26.6556, 43.3431],
            [26.6854, 43.3593],
            [26.7439, 43.3616],
            [26.7313, 43.3498],
            [26.7381, 43.2911],
            [26.7776, 43.2601],
            [26.7722, 43.243],
            [26.717, 43.2043],
            [26.695, 43.1589],
            [26.6627, 43.1276],
            [26.6824, 43.0966],
            [26.6562, 43.0573],
            [26.6279, 43.0438],
            [26.6283, 43.0095],
            [26.5955, 42.9975],
            [26.5149, 43.0091],
            [26.3912, 42.9934],
            [26.313, 43.0298],
            [26.2524, 43.0259],
            [26.2323, 42.9822],
            [26.1814, 42.9503],
            [26.071, 42.9759],
            [26.0729, 42.9941],
            [26.1055, 43.0158],
            [26.0684, 43.0389],
            [26.0535, 43.0754],
            [26.0573, 43.1256],
            [26.083, 43.1766],
            [26.1087, 43.1878],
            [26.1049, 43.2107],
            [26.0545, 43.2579],
            [26.0456, 43.2919],
            [25.9968, 43.322],
            [26.0013, 43.3583],
            [25.9507, 43.3897],
            [25.9739, 43.4023],
            [26.0139, 43.4547],
            [26.0262, 43.5077],
            [26.0908, 43.5302],
          ],
        ],
      },
      properties: {name: 'Targovishte', id: 'BG-25', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-25',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.2018, 42.229],
            [26.2325, 42.2127],
            [26.2767, 42.2055],
            [26.3102, 42.1828],
            [26.3712, 42.171],
            [26.3963, 42.1817],
            [26.422, 42.1665],
            [26.4666, 42.1709],
            [26.4776, 42.1525],
            [26.5179, 42.1488],
            [26.504, 42.0986],
            [26.5101, 42.0508],
            [26.4852, 42.0328],
            [26.5211, 41.962],
            [26.5609, 41.9259],
            [26.5826, 41.9037],
            [26.5642, 41.8546],
            [26.5389, 41.8234],
            [26.3857, 41.823],
            [26.3654, 41.8173],
            [26.331, 41.758],
            [26.329, 41.7158],
            [26.3068, 41.7092],
            [26.1807, 41.7405],
            [26.1231, 41.7308],
            [26.112, 41.7135],
            [26.0692, 41.7095],
            [26.061, 41.6891],
            [26.0725, 41.6479],
            [26.0958, 41.6275],
            [26.1536, 41.6061],
            [26.1477, 41.5563],
            [26.1776, 41.5538],
            [26.1856, 41.5189],
            [26.1587, 41.4697],
            [26.1698, 41.4326],
            [26.164, 41.4083],
            [26.1308, 41.3557],
            [26.0877, 41.3466],
            [26.0411, 41.3508],
            [25.9986, 41.3258],
            [25.9375, 41.3207],
            [25.9326, 41.3531],
            [25.9067, 41.3795],
            [25.8358, 41.4288],
            [25.8438, 41.4725],
            [25.7882, 41.5303],
            [25.8079, 41.5797],
            [25.8507, 41.6048],
            [25.8338, 41.6457],
            [25.7894, 41.6535],
            [25.7542, 41.6451],
            [25.718, 41.6136],
            [25.6449, 41.6344],
            [25.659, 41.669],
            [25.6379, 41.7007],
            [25.6336, 41.7359],
            [25.6061, 41.7673],
            [25.5302, 41.7548],
            [25.459, 41.7739],
            [25.3836, 41.8058],
            [25.3596, 41.8489],
            [25.2999, 41.8132],
            [25.2714, 41.9035],
            [25.2849, 41.9373],
            [25.311, 41.9614],
            [25.3067, 42.0411],
            [25.3474, 42.1198],
            [25.3657, 42.1202],
            [25.4348, 42.0958],
            [25.4874, 42.1576],
            [25.5316, 42.1415],
            [25.5453, 42.169],
            [25.5884, 42.206],
            [25.609, 42.1732],
            [25.6048, 42.1323],
            [25.6201, 42.1209],
            [25.6582, 42.1468],
            [25.6834, 42.1426],
            [25.6964, 42.1132],
            [25.7208, 42.0941],
            [25.7379, 42.0518],
            [25.7948, 42.0609],
            [25.8359, 42.0784],
            [25.8515, 42.1222],
            [25.9283, 42.1123],
            [25.9523, 42.0951],
            [25.9958, 42.091],
            [26.0385, 42.026],
            [26.0683, 42.0241],
            [26.0931, 42.0478],
            [26.1538, 42.0589],
            [26.1366, 42.1137],
            [26.1177, 42.1459],
            [26.1251, 42.1769],
            [26.2018, 42.229],
          ],
        ],
      },
      properties: {name: 'Haskovo', id: 'BG-26', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-26',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.0065, 43.6954],
            [27.0867, 43.7193],
            [27.1536, 43.6676],
            [27.1975, 43.6689],
            [27.2229, 43.6469],
            [27.2691, 43.6422],
            [27.3279, 43.5939],
            [27.3876, 43.6235],
            [27.4254, 43.5887],
            [27.4054, 43.5679],
            [27.3153, 43.5512],
            [27.3047, 43.532],
            [27.335, 43.4993],
            [27.3331, 43.4452],
            [27.2873, 43.3991],
            [27.2846, 43.3743],
            [27.2995, 43.324],
            [27.286, 43.3078],
            [27.2453, 43.3032],
            [27.2625, 43.2291],
            [27.2777, 43.1944],
            [27.1897, 43.159],
            [27.1831, 43.1249],
            [27.2047, 43.1103],
            [27.2097, 43.0601],
            [27.2206, 43.0498],
            [27.1178, 43.0231],
            [27.0775, 43.0214],
            [27.0829, 42.9894],
            [27.1063, 42.9622],
            [27.1035, 42.9329],
            [27.0522, 42.9285],
            [26.988, 42.9013],
            [26.9577, 42.9036],
            [26.9393, 42.9253],
            [26.87, 42.9195],
            [26.8301, 42.9318],
            [26.748, 42.9291],
            [26.6987, 42.8996],
            [26.6057, 42.895],
            [26.6005, 42.9246],
            [26.5366, 42.9228],
            [26.53, 42.9549],
            [26.5955, 42.9975],
            [26.6283, 43.0095],
            [26.6279, 43.0438],
            [26.6562, 43.0573],
            [26.6824, 43.0966],
            [26.6627, 43.1276],
            [26.695, 43.1589],
            [26.717, 43.2043],
            [26.7722, 43.243],
            [26.7776, 43.2601],
            [26.7381, 43.2911],
            [26.7313, 43.3498],
            [26.7439, 43.3616],
            [26.784, 43.3846],
            [26.7862, 43.4024],
            [26.7377, 43.4254],
            [26.7393, 43.4586],
            [26.8039, 43.5046],
            [26.7934, 43.5277],
            [26.8048, 43.5509],
            [26.8563, 43.5795],
            [26.8607, 43.6064],
            [26.8976, 43.5986],
            [26.9444, 43.6074],
            [26.9571, 43.6291],
            [27.0144, 43.657],
            [27.0065, 43.6954],
          ],
        ],
      },
      properties: {name: 'Shumen', id: 'BG-27', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-27',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.6814, 42.6982],
            [26.7152, 42.6639],
            [26.7654, 42.6277],
            [26.8049, 42.5602],
            [26.839, 42.5284],
            [26.8553, 42.4951],
            [26.895, 42.4426],
            [26.9584, 42.4448],
            [26.9699, 42.4314],
            [26.9475, 42.4023],
            [26.8739, 42.393],
            [26.8608, 42.3815],
            [26.8707, 42.3494],
            [26.9069, 42.3264],
            [26.9254, 42.2966],
            [26.9509, 42.2824],
            [26.935, 42.2566],
            [26.96, 42.197],
            [26.9315, 42.172],
            [26.9626, 42.1283],
            [27.0149, 42.1055],
            [27.0376, 42.0828],
            [27.008, 42.0396],
            [26.9622, 41.9972],
            [26.9286, 42.0012],
            [26.9057, 41.9873],
            [26.8702, 41.99],
            [26.843, 41.9688],
            [26.7889, 41.9892],
            [26.7528, 41.9576],
            [26.7127, 41.9676],
            [26.6178, 41.9647],
            [26.5609, 41.9259],
            [26.5211, 41.962],
            [26.4852, 42.0328],
            [26.5101, 42.0508],
            [26.504, 42.0986],
            [26.5179, 42.1488],
            [26.4776, 42.1525],
            [26.4666, 42.1709],
            [26.422, 42.1665],
            [26.3963, 42.1817],
            [26.3712, 42.171],
            [26.3102, 42.1828],
            [26.2767, 42.2055],
            [26.2325, 42.2127],
            [26.2018, 42.229],
            [26.165, 42.246],
            [26.1892, 42.3004],
            [26.2241, 42.3439],
            [26.2305, 42.405],
            [26.2018, 42.4291],
            [26.2091, 42.4674],
            [26.2719, 42.4532],
            [26.2931, 42.4684],
            [26.335, 42.4724],
            [26.3813, 42.5097],
            [26.3781, 42.5443],
            [26.399, 42.5607],
            [26.4451, 42.5535],
            [26.4726, 42.5603],
            [26.5445, 42.5953],
            [26.5939, 42.599],
            [26.6161, 42.6277],
            [26.6444, 42.616],
            [26.6739, 42.6432],
            [26.6814, 42.6982],
          ],
        ],
      },
      properties: {name: 'Yambol', id: 'BG-28', CNTRY: 'Bulgaria', TYPE: 'Region'},
      id: 'BG-28',
    },
  ],
}
export default map
