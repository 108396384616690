import React, {useEffect} from 'react';
import { SEOReportDeatils } from '../../models/SEOReport';
import CommentTab from "../SeoStatistic/Tabs/CommentTab";
import {IReportDetails, TAB_NAME} from '../../models/Reports'
import { useIntl} from "react-intl";
import {enableSplashScreen} from "../../../../../_metronic/layout/core";
import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";
import {printPDF} from "./Functions/PdfUtils";

interface PDFGadsPrint {
    analyticDataMonth?: SEOReportDeatils;
    closePopup: () => void;
}

const LinkedinPrintView: React.FC<PDFGadsPrint> = ({ closePopup }) => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails

    useEffect(() => {
        enableSplashScreen();
        setTimeout(() => {
            printPDF(document.getElementById('reportPrint'), reportData, closePopup);
        }, 1000);
    });


    return (
      <>
          <div id='print_overlay' className='print_overlay'>
          </div>
          <div className="popup-container">
              <div className="popup-body py-5 px-10">
                  <div id="reportPrint">
                      <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5 text-left'>
                          <h3 className='fw-bolder text-dark px-5'>
                              {intl.formatMessage({id: TAB_NAME.COMMENT})}
                          </h3>
                          <CommentTab />
                      </div>
                  </div>
              </div>
          </div>
      </>
    );
};

export default LinkedinPrintView;