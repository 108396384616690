import React, {useCallback, useRef} from 'react'
import clsx from 'clsx'
import {IReportDetails, SEM_TAB_NAME, SEO_TAB_NAME} from '../../models/Reports'
import DataStatisticTab from './Tabs/DataStatisticTab'
import ReactToPrint from 'react-to-print'
import {KTSVG} from '../../../../../_metronic/helpers'
import GoogleSearchConsoleTab from './Tabs/GoogleSearchConsoleTab'
import CommentTab from "./Tabs/CommentTab";
import CommentGroup from "./SeoWidgetsGroup/CommentGroup";
import ASPS from "./SeoWidgetsGroup/ASPS";
import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";
import LinksWidget from "./SeoWidgets/LinksWidget";

import SeoDataStatisticTabProps from "./Tabs/SeoDataStatisticTabProps";
interface TabsContentProps {
  tab: string
}

const SeoTabsContent: React.FC<TabsContentProps> = ({tab}) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const componentRef = useRef<HTMLDivElement>(null)

  const reactToPrintTrigger = useCallback(() => {
    return (
      <button className='print-btn btn btn-icon btn-lg me-1'>
        <KTSVG
          path='/media/sempai-images/icons/Icon-feather-download.svg'
          className='svg-icon-1 svg-icon-white'
        />
      </button>
    )
  }, [])

  return (
    <div className='tab-content'>
      <div className={clsx('tab-pane', {active: tab === SEO_TAB_NAME.ANALYSIS})}>
        <ReactToPrint content={() => componentRef.current} trigger={reactToPrintTrigger} />
        <div className='report-to-pdf' ref={componentRef}>
          {tab === SEO_TAB_NAME.ANALYSIS && <DataStatisticTab />}
        </div>
      </div>

      <div className={clsx('tab-pane', {active: tab === SEO_TAB_NAME.GOOGLE_SEARCH_CONSOLE})}>
        {tab === SEO_TAB_NAME.GOOGLE_SEARCH_CONSOLE && <GoogleSearchConsoleTab />}
      </div>

      {reportData && reportData.details && reportData.details.GA4config && reportData.details.GA4config.status && (
          <div className={clsx('tab-pane', {active: tab === SEO_TAB_NAME.GA4})}>
              {/*<ReactToPrint content={() => componentRef.current} trigger={reactToPrintTrigger} />*/}
              <div className='report-to-pdf' ref={componentRef}>
                  {tab === SEM_TAB_NAME.GA4 && <SeoDataStatisticTabProps />}
              </div>
          </div>
      )}



      <div className={clsx('tab-pane', {active: tab === SEO_TAB_NAME.ASPS})}>
          {tab === SEO_TAB_NAME.ASPS && <ASPS />}
      </div>

        {reportData && reportData.details && reportData.details.links && (
            <div className={clsx('tab-pane', {active: tab === SEO_TAB_NAME.LINKS})}>
                {tab === SEO_TAB_NAME.LINKS && <LinksWidget />}
            </div>
        )}
      <div className={clsx('tab-pane', {active: tab === SEO_TAB_NAME.COMMENT})}>
          <div className='comment-group card-body bg-white px-5 py-5 pt-15 mb-5'>
              <CommentGroup />
          </div>
      </div>
    </div>
  )
}

export default SeoTabsContent
