var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.5928, 55.954],
            [26.6164, 55.92],
            [26.6285, 55.8687],
            [26.5422, 55.8398],
            [26.4987, 55.841],
            [26.4759, 55.8615],
            [26.4937, 55.8759],
            [26.4439, 55.9155],
            [26.4702, 55.9292],
            [26.5222, 55.9116],
            [26.5579, 55.916],
            [26.5928, 55.954],
          ],
        ],
      },
      properties: {name: 'Daugavpils', id: 'LV-DGV', CNTRY: 'Latvia', TYPE: 'Republican City'},
      id: 'LV-DGV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.7818, 56.6452],
            [23.7789, 56.6248],
            [23.6734, 56.6117],
            [23.6791, 56.6374],
            [23.6401, 56.6567],
            [23.7124, 56.6902],
            [23.7265, 56.6946],
            [23.7894, 56.6892],
            [23.7989, 56.6599],
            [23.7818, 56.6452],
          ],
        ],
      },
      properties: {name: 'Jelgava', id: 'LV-JEL', CNTRY: 'Latvia', TYPE: 'Republican City'},
      id: 'LV-JEL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.8249, 56.4836],
            [25.8239, 56.4871],
            [25.8334, 56.5252],
            [25.8839, 56.5301],
            [25.9089, 56.4966],
            [25.8916, 56.4769],
            [25.8249, 56.4836],
          ],
        ],
      },
      properties: {name: 'Jēkabpils', id: 'LV-JKB', CNTRY: 'Latvia', TYPE: 'Republican City'},
      id: 'LV-JKB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.971, 56.9927],
            [23.9182, 56.9612],
            [23.8766, 56.9654],
            [23.8231, 56.9511],
            [23.6977, 56.9417],
            [23.6068, 56.9186],
            [23.4877, 56.9312],
            [23.4723, 56.9384],
            [23.4775, 56.9644],
            [23.5514, 56.9784],
            [23.6209, 56.9642],
            [23.6837, 56.9628],
            [23.7689, 56.971],
            [23.8727, 56.9908],
            [23.92, 57.0046],
            [23.971, 56.9927],
          ],
        ],
      },
      properties: {name: 'Jūrmala', id: 'LV-JUR', CNTRY: 'Latvia', TYPE: 'Republican City'},
      id: 'LV-JUR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.0001, 56.4706],
            [20.9858, 56.5176],
            [20.9951, 56.5558],
            [21.026, 56.6118],
            [21.0792, 56.6071],
            [21.0786, 56.5703],
            [21.1104, 56.5706],
            [21.1104, 56.5393],
            [21.0774, 56.5275],
            [21.0607, 56.489],
            [21.0001, 56.4706],
          ],
        ],
      },
      properties: {name: 'Liepāja', id: 'LV-LPX', CNTRY: 'Latvia', TYPE: 'Republican City'},
      id: 'LV-LPX',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.3484, 56.5338],
            [27.3741, 56.5338],
            [27.3893, 56.5022],
            [27.3427, 56.478],
            [27.317, 56.4801],
            [27.3266, 56.5248],
            [27.3484, 56.5338],
          ],
        ],
      },
      properties: {name: 'Rēzekne', id: 'LV-REZ', CNTRY: 'Latvia', TYPE: 'Republican City'},
      id: 'LV-REZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.3221, 56.9706],
            [24.233, 56.9367],
            [24.233, 56.9005],
            [24.2701, 56.8751],
            [24.2017, 56.8782],
            [24.1774, 56.9059],
            [24.151, 56.8938],
            [24.1068, 56.895],
            [24.0932, 56.879],
            [24.0625, 56.879],
            [24.0715, 56.9017],
            [24.0209, 56.9229],
            [23.9443, 56.9394],
            [24.0091, 56.9699],
            [23.971, 56.9927],
            [23.92, 57.0046],
            [23.9832, 57.0291],
            [24.0293, 57.0645],
            [24.111, 57.0835],
            [24.1761, 57.0547],
            [24.198, 57.0265],
            [24.2682, 57.0074],
            [24.3221, 56.9706],
          ],
        ],
      },
      properties: {name: 'Rīga', id: 'LV-RIX', CNTRY: 'Latvia', TYPE: 'Republican City'},
      id: 'LV-RIX',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.5199, 57.3814],
            [21.5735, 57.4166],
            [21.6272, 57.4737],
            [21.6537, 57.4603],
            [21.6709, 57.4036],
            [21.6139, 57.3905],
            [21.5533, 57.3558],
            [21.5199, 57.3814],
          ],
        ],
      },
      properties: {name: 'Ventspils', id: 'LV-VEN', CNTRY: 'Latvia', TYPE: 'Republican City'},
      id: 'LV-VEN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.4592, 57.5396],
            [25.4353, 57.5053],
            [25.3787, 57.5091],
            [25.3684, 57.5442],
            [25.4397, 57.5524],
            [25.4592, 57.5396],
          ],
        ],
      },
      properties: {name: 'Valmiera', id: 'LV-VMR', CNTRY: 'Latvia', TYPE: 'Republican City'},
      id: 'LV-VMR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.1205, 56.1789],
            [27.1728, 56.2033],
            [27.2018, 56.1954],
            [27.2537, 56.1994],
            [27.3215, 56.195],
            [27.3125, 56.1695],
            [27.3524, 56.1457],
            [27.3182, 56.121],
            [27.2419, 56.1128],
            [27.2618, 56.0798],
            [27.2376, 56.0607],
            [27.1881, 56.0543],
            [27.1834, 56.0106],
            [27.134, 55.9902],
            [27.0541, 55.9987],
            [27.0303, 55.9862],
            [26.9772, 56.0034],
            [26.8895, 56.105],
            [26.8943, 56.1228],
            [26.9464, 56.1488],
            [27.0068, 56.1658],
            [27.1193, 56.1645],
            [27.1205, 56.1789],
          ],
        ],
      },
      properties: {name: 'Aglonas novads', id: 'LV-001', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-001',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.3224, 56.5944],
            [25.2815, 56.5948],
            [25.263, 56.5724],
            [25.2177, 56.5852],
            [25.1321, 56.6243],
            [25.1378, 56.6399],
            [25.1887, 56.652],
            [25.1911, 56.7019],
            [25.2189, 56.7114],
            [25.2842, 56.6977],
            [25.3062, 56.6166],
            [25.3224, 56.5944],
          ],
        ],
      },
      properties: {name: 'Aizkraukles novads', id: 'LV-002', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-002',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.4752, 56.8808],
            [21.5049, 56.8528],
            [21.5648, 56.8402],
            [21.598, 56.844],
            [21.6454, 56.7919],
            [21.6952, 56.7903],
            [21.7486, 56.766],
            [21.7796, 56.7301],
            [21.7621, 56.7033],
            [21.8283, 56.6875],
            [21.8375, 56.6368],
            [21.7748, 56.6142],
            [21.797, 56.572],
            [21.7157, 56.5572],
            [21.6539, 56.5687],
            [21.639, 56.5529],
            [21.5792, 56.5665],
            [21.6188, 56.5792],
            [21.6176, 56.6162],
            [21.5653, 56.6656],
            [21.4869, 56.6578],
            [21.4524, 56.648],
            [21.3954, 56.6781],
            [21.3954, 56.6931],
            [21.3467, 56.7046],
            [21.3021, 56.7278],
            [21.3001, 56.7433],
            [21.3099, 56.7606],
            [21.3022, 56.802],
            [21.3354, 56.8244],
            [21.4413, 56.8345],
            [21.4508, 56.8647],
            [21.4752, 56.8808],
          ],
        ],
      },
      properties: {name: 'Aizputes novads', id: 'LV-003', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-003',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.0107, 56.0868],
            [25.938, 56.0608],
            [25.9027, 56.0944],
            [25.8419, 56.0732],
            [25.8254, 56.0509],
            [25.7657, 56.0617],
            [25.693, 56.0847],
            [25.7035, 56.1182],
            [25.6271, 56.1338],
            [25.6357, 56.1664],
            [25.6778, 56.2075],
            [25.6838, 56.2369],
            [25.7948, 56.2403],
            [25.8452, 56.2334],
            [25.8837, 56.218],
            [25.8836, 56.1894],
            [25.9032, 56.1694],
            [25.9627, 56.155],
            [25.9478, 56.1331],
            [26.0044, 56.1088],
            [26.0107, 56.0868],
          ],
        ],
      },
      properties: {name: 'Aknistes novads', id: 'LV-004', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-004',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.8346, 57.9738],
            [24.8368, 57.9025],
            [24.8683, 57.8917],
            [24.8956, 57.8157],
            [25.002, 57.7922],
            [25.0264, 57.7716],
            [25.073, 57.7659],
            [25.0916, 57.7285],
            [25.0599, 57.6995],
            [25.0942, 57.6542],
            [25.0549, 57.6504],
            [25.0666, 57.6269],
            [25.0221, 57.6181],
            [24.9771, 57.6325],
            [24.9283, 57.6023],
            [24.8976, 57.6063],
            [24.9055, 57.6393],
            [24.8455, 57.6565],
            [24.8366, 57.7068],
            [24.7938, 57.7218],
            [24.7433, 57.7211],
            [24.688, 57.7571],
            [24.6678, 57.7571],
            [24.6301, 57.8167],
            [24.6235, 57.8451],
            [24.5883, 57.8674],
            [24.6206, 57.8877],
            [24.5987, 57.9013],
            [24.5761, 57.9558],
            [24.6263, 57.9408],
            [24.6486, 57.9587],
            [24.7415, 57.9656],
            [24.7425, 57.9833],
            [24.806, 57.9911],
            [24.8346, 57.9738],
          ],
        ],
      },
      properties: {name: 'Alojas novads', id: 'LV-005', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-005',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.5474, 57.0758],
            [21.6016, 57.0511],
            [21.6236, 57.0272],
            [21.6824, 56.9935],
            [21.7044, 56.9542],
            [21.6426, 56.9321],
            [21.6319, 56.9188],
            [21.5755, 56.924],
            [21.5487, 56.8957],
            [21.494, 56.9063],
            [21.4015, 56.9263],
            [21.4009, 56.9469],
            [21.4577, 56.9469],
            [21.4747, 56.9722],
            [21.4652, 57.0283],
            [21.5063, 57.079],
            [21.5474, 57.0758],
          ],
        ],
      },
      properties: {name: 'Alsungas novads', id: 'LV-006', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-006',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.5901, 57.3922],
            [26.627, 57.4198],
            [26.7068, 57.4188],
            [26.7373, 57.4496],
            [26.743, 57.4895],
            [26.838, 57.5028],
            [26.8219, 57.5228],
            [26.8409, 57.5468],
            [26.8316, 57.5816],
            [26.86, 57.5926],
            [26.8656, 57.6154],
            [26.9259, 57.6325],
            [26.948, 57.6018],
            [27.0315, 57.6047],
            [27.037, 57.5854],
            [27.1247, 57.5611],
            [27.2646, 57.5495],
            [27.326, 57.5235],
            [27.4157, 57.5226],
            [27.5039, 57.5398],
            [27.5492, 57.5376],
            [27.5677, 57.5099],
            [27.5306, 57.4263],
            [27.5626, 57.411],
            [27.5978, 57.4158],
            [27.6651, 57.395],
            [27.6923, 57.3697],
            [27.6937, 57.331],
            [27.6389, 57.3093],
            [27.591, 57.305],
            [27.5877, 57.2794],
            [27.5486, 57.2633],
            [27.4966, 57.264],
            [27.4045, 57.2532],
            [27.3518, 57.2851],
            [27.2801, 57.2494],
            [27.2432, 57.2515],
            [27.2282, 57.2312],
            [27.1612, 57.2261],
            [27.0482, 57.2494],
            [27.0307, 57.2819],
            [26.9825, 57.3082],
            [26.9227, 57.3017],
            [26.86, 57.3036],
            [26.782, 57.3233],
            [26.7439, 57.3222],
            [26.7162, 57.3052],
            [26.6828, 57.3346],
            [26.6038, 57.3658],
            [26.5901, 57.3922],
          ],
        ],
      },
      properties: {name: 'Aluksnes novads', id: 'LV-007', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-007',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.5617, 57.1537],
            [25.5252, 57.1278],
            [25.5801, 57.122],
            [25.6135, 57.0931],
            [25.613, 57.0659],
            [25.4961, 57.0156],
            [25.4784, 56.9926],
            [25.4578, 56.965],
            [25.3951, 56.9315],
            [25.3687, 56.9423],
            [25.3566, 56.9751],
            [25.326, 56.98],
            [25.2415, 56.9288],
            [25.1389, 56.9506],
            [25.1472, 56.9693],
            [25.132, 57.0065],
            [25.132, 57.0434],
            [25.1064, 57.071],
            [25.142, 57.0846],
            [25.09, 57.1219],
            [25.1594, 57.1306],
            [25.1979, 57.1567],
            [25.1674, 57.1712],
            [25.1551, 57.2355],
            [25.0843, 57.248],
            [25.0843, 57.2655],
            [25.1084, 57.2769],
            [25.1951, 57.275],
            [25.2149, 57.2962],
            [25.2592, 57.2984],
            [25.3573, 57.2424],
            [25.3484, 57.216],
            [25.3745, 57.196],
            [25.4155, 57.1879],
            [25.4423, 57.1627],
            [25.4868, 57.1702],
            [25.5617, 57.1537],
          ],
        ],
      },
      properties: {name: 'Amatas novads', id: 'LV-008', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-008',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.8316, 57.5816],
            [26.8409, 57.5468],
            [26.8219, 57.5228],
            [26.838, 57.5028],
            [26.743, 57.4895],
            [26.7373, 57.4496],
            [26.7068, 57.4188],
            [26.627, 57.4198],
            [26.5901, 57.3922],
            [26.55, 57.3886],
            [26.5164, 57.4021],
            [26.4715, 57.3928],
            [26.4893, 57.3723],
            [26.4336, 57.3691],
            [26.4374, 57.3285],
            [26.3511, 57.317],
            [26.276, 57.3303],
            [26.2753, 57.3647],
            [26.2895, 57.3915],
            [26.3261, 57.4235],
            [26.3139, 57.4519],
            [26.3105, 57.5048],
            [26.3461, 57.5094],
            [26.3348, 57.5516],
            [26.3657, 57.5809],
            [26.4032, 57.5726],
            [26.4704, 57.5759],
            [26.5008, 57.525],
            [26.56, 57.5128],
            [26.565, 57.5293],
            [26.6151, 57.5293],
            [26.6718, 57.5637],
            [26.7392, 57.5748],
            [26.8316, 57.5816],
          ],
        ],
      },
      properties: {name: 'Apes novads', id: 'LV-009', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-009',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.1817, 56.3685],
            [23.182, 56.3372],
            [23.0927, 56.3042],
            [23.0717, 56.3226],
            [23.0251, 56.3288],
            [23.0033, 56.368],
            [23.0008, 56.4042],
            [22.9444, 56.4138],
            [22.9208, 56.3983],
            [22.8249, 56.4047],
            [22.7873, 56.4211],
            [22.7182, 56.4247],
            [22.7111, 56.4394],
            [22.74, 56.4604],
            [22.7039, 56.4816],
            [22.726, 56.4978],
            [22.7225, 56.521],
            [22.7439, 56.5423],
            [22.7375, 56.5663],
            [22.7595, 56.5578],
            [22.8081, 56.5732],
            [22.8334, 56.5466],
            [22.8976, 56.571],
            [22.9326, 56.573],
            [22.9333, 56.5993],
            [22.9639, 56.6029],
            [22.9917, 56.5844],
            [23.0317, 56.5916],
            [23.0816, 56.5471],
            [23.0973, 56.5088],
            [23.0887, 56.4832],
            [23.1208, 56.4769],
            [23.1479, 56.4473],
            [23.1564, 56.4394],
            [23.1208, 56.4117],
            [23.1564, 56.3955],
            [23.1817, 56.3685],
          ],
        ],
      },
      properties: {name: 'Auces novads', id: 'LV-010', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-010',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.2396, 57.068],
            [24.2681, 57.0889],
            [24.2633, 57.106],
            [24.3275, 57.1205],
            [24.3508, 57.1544],
            [24.3503, 57.19],
            [24.3812, 57.1889],
            [24.4677, 57.181],
            [24.5067, 57.113],
            [24.5017, 57.1007],
            [24.4642, 57.0745],
            [24.3585, 57.0573],
            [24.3282, 57.0333],
            [24.2486, 57.0476],
            [24.2396, 57.068],
          ],
        ],
      },
      properties: {name: 'Ādažu novads', id: 'LV-011', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-011',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.7574, 56.8084],
            [23.7178, 56.8325],
            [23.7076, 56.858],
            [23.5984, 56.8969],
            [23.5008, 56.8978],
            [23.4877, 56.9312],
            [23.6068, 56.9186],
            [23.6977, 56.9417],
            [23.8231, 56.9511],
            [23.8766, 56.9654],
            [23.9182, 56.9612],
            [23.971, 56.9927],
            [24.0091, 56.9699],
            [23.9443, 56.9394],
            [23.8944, 56.9092],
            [23.8302, 56.889],
            [23.8555, 56.8347],
            [23.8337, 56.8069],
            [23.7952, 56.8225],
            [23.7678, 56.8076],
            [23.7574, 56.8084],
          ],
        ],
      },
      properties: {name: 'Babītes novads', id: 'LV-012', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-012',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.5273, 56.754],
            [24.5288, 56.7173],
            [24.4952, 56.7166],
            [24.4362, 56.6832],
            [24.3774, 56.6756],
            [24.3686, 56.6624],
            [24.2871, 56.6849],
            [24.2501, 56.7114],
            [24.2258, 56.7212],
            [24.2117, 56.7434],
            [24.3115, 56.7793],
            [24.3686, 56.7923],
            [24.4143, 56.7881],
            [24.5273, 56.754],
          ],
        ],
      },
      properties: {name: 'Baldones novads', id: 'LV-013', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-013',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.4295, 56.8512],
            [27.4532, 56.8986],
            [27.5203, 56.8929],
            [27.5535, 56.9449],
            [27.6053, 56.9511],
            [27.6648, 56.9779],
            [27.6431, 57.0049],
            [27.678, 56.9999],
            [27.7171, 57.0101],
            [27.7668, 57.0023],
            [27.744, 56.9604],
            [27.7383, 56.9214],
            [27.6889, 56.9167],
            [27.6726, 56.896],
            [27.6593, 56.8365],
            [27.5844, 56.8546],
            [27.4295, 56.8512],
          ],
        ],
      },
      properties: {name: 'Baltinavas novads', id: 'LV-014', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-014',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.1612, 57.2261],
            [27.2282, 57.2312],
            [27.2432, 57.2515],
            [27.2801, 57.2494],
            [27.3518, 57.2851],
            [27.4045, 57.2532],
            [27.4966, 57.264],
            [27.5486, 57.2633],
            [27.5361, 57.2474],
            [27.4876, 57.2377],
            [27.4406, 57.2041],
            [27.437, 57.1707],
            [27.4156, 57.1316],
            [27.5318, 57.0629],
            [27.5518, 57.0295],
            [27.6331, 57.0209],
            [27.6431, 57.0049],
            [27.6648, 56.9779],
            [27.6053, 56.9511],
            [27.5535, 56.9449],
            [27.5203, 56.8929],
            [27.4532, 56.8986],
            [27.4295, 56.8512],
            [27.3837, 56.8158],
            [27.3453, 56.8176],
            [27.3569, 56.783],
            [27.3524, 56.7825],
            [27.3127, 56.7803],
            [27.2713, 56.7913],
            [27.182, 56.7893],
            [27.1623, 56.8105],
            [27.0858, 56.8074],
            [27.0335, 56.814],
            [26.9649, 56.8121],
            [26.9308, 56.8323],
            [26.9461, 56.8428],
            [26.9927, 56.8746],
            [27.0627, 56.8742],
            [27.1236, 56.8653],
            [27.1426, 56.852],
            [27.1901, 56.857],
            [27.2029, 56.8794],
            [27.2823, 56.9142],
            [27.332, 56.9251],
            [27.3253, 56.9576],
            [27.2588, 56.9654],
            [27.2478, 56.98],
            [27.303, 56.9909],
            [27.3291, 57.0461],
            [27.2326, 57.0511],
            [27.1028, 57.0796],
            [27.11, 57.1039],
            [26.9961, 57.1039],
            [27.0397, 57.1321],
            [27.0706, 57.165],
            [27.1366, 57.2022],
            [27.1612, 57.2261],
          ],
        ],
      },
      properties: {name: 'Balvu novads', id: 'LV-015', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-015',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.3978, 56.5393],
            [24.4327, 56.5058],
            [24.4683, 56.4858],
            [24.4907, 56.4966],
            [24.524, 56.4648],
            [24.5392, 56.4337],
            [24.5282, 56.4076],
            [24.4584, 56.3891],
            [24.514, 56.3728],
            [24.4712, 56.344],
            [24.5159, 56.3197],
            [24.5775, 56.3207],
            [24.5794, 56.2873],
            [24.5224, 56.2823],
            [24.4221, 56.261],
            [24.3922, 56.2776],
            [24.3567, 56.2724],
            [24.3375, 56.2964],
            [24.2044, 56.2676],
            [24.1764, 56.2517],
            [24.1214, 56.248],
            [24.0968, 56.2767],
            [24.0477, 56.2794],
            [24.0476, 56.297],
            [24.0144, 56.3291],
            [24.047, 56.343],
            [24.0812, 56.3832],
            [24.1601, 56.3821],
            [24.1563, 56.4042],
            [24.1098, 56.4085],
            [24.0461, 56.4422],
            [24.0622, 56.4596],
            [24.0434, 56.507],
            [24.0799, 56.5462],
            [24.1811, 56.5314],
            [24.2524, 56.5114],
            [24.278, 56.5366],
            [24.3646, 56.5498],
            [24.3978, 56.5393],
          ],
        ],
      },
      properties: {name: 'Bauskas novads', id: 'LV-016', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-016',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.5838, 57.6061],
            [25.6813, 57.6154],
            [25.7706, 57.5626],
            [25.7364, 57.5345],
            [25.7868, 57.5107],
            [25.7811, 57.4925],
            [25.6946, 57.4869],
            [25.6572, 57.4626],
            [25.5939, 57.4669],
            [25.52, 57.4295],
            [25.5018, 57.4533],
            [25.4555, 57.4369],
            [25.3988, 57.4406],
            [25.3806, 57.4543],
            [25.3787, 57.5091],
            [25.4353, 57.5053],
            [25.4592, 57.5396],
            [25.4858, 57.5447],
            [25.5181, 57.5733],
            [25.5647, 57.582],
            [25.5838, 57.6061],
          ],
        ],
      },
      properties: {name: 'Beverīnas novads', id: 'LV-017', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-017',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.493, 56.8562],
            [22.5301, 56.8392],
            [22.5861, 56.8434],
            [22.6388, 56.809],
            [22.6764, 56.8132],
            [22.7076, 56.7982],
            [22.755, 56.8103],
            [22.7914, 56.7981],
            [22.8237, 56.7706],
            [22.8288, 56.7434],
            [22.8188, 56.6947],
            [22.841, 56.6568],
            [22.8351, 56.6067],
            [22.7744, 56.6107],
            [22.8081, 56.5732],
            [22.7595, 56.5578],
            [22.7375, 56.5663],
            [22.6971, 56.6133],
            [22.6294, 56.6326],
            [22.6201, 56.6118],
            [22.5567, 56.5994],
            [22.5609, 56.6383],
            [22.5292, 56.6375],
            [22.5028, 56.656],
            [22.5121, 56.7081],
            [22.469, 56.7384],
            [22.4473, 56.7692],
            [22.4551, 56.8074],
            [22.493, 56.8562],
          ],
        ],
      },
      properties: {name: 'Brocēnu novads', id: 'LV-018', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-018',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.5838, 57.6061],
            [25.5647, 57.582],
            [25.5181, 57.5733],
            [25.4858, 57.5447],
            [25.4592, 57.5396],
            [25.4397, 57.5524],
            [25.3684, 57.5442],
            [25.3389, 57.5741],
            [25.2757, 57.5943],
            [25.2686, 57.6109],
            [25.2047, 57.6499],
            [25.1919, 57.671],
            [25.1291, 57.6512],
            [25.0942, 57.6542],
            [25.0599, 57.6995],
            [25.0916, 57.7285],
            [25.073, 57.7659],
            [25.0264, 57.7716],
            [25.002, 57.7922],
            [25.0371, 57.8093],
            [25.1256, 57.7978],
            [25.1131, 57.8416],
            [25.1505, 57.8441],
            [25.1874, 57.8137],
            [25.2379, 57.7943],
            [25.2551, 57.8029],
            [25.2972, 57.7776],
            [25.3667, 57.7836],
            [25.4623, 57.783],
            [25.5301, 57.7795],
            [25.5872, 57.7697],
            [25.684, 57.7366],
            [25.6953, 57.7264],
            [25.681, 57.7083],
            [25.6276, 57.7083],
            [25.6044, 57.6851],
            [25.6115, 57.659],
            [25.5264, 57.655],
            [25.5134, 57.6221],
            [25.5838, 57.6061],
          ],
        ],
      },
      properties: {name: 'Burtnieku novads', id: 'LV-019', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-019',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.3503, 57.19],
            [24.3508, 57.1544],
            [24.3275, 57.1205],
            [24.2633, 57.106],
            [24.2681, 57.0889],
            [24.2396, 57.068],
            [24.1761, 57.0547],
            [24.111, 57.0835],
            [24.1948, 57.1152],
            [24.2609, 57.1584],
            [24.3398, 57.1948],
            [24.3503, 57.19],
          ],
        ],
      },
      properties: {name: 'Carnikavas novads', id: 'LV-020', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-020',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.3714, 57.043],
            [26.3778, 56.9992],
            [26.4121, 56.987],
            [26.3878, 56.9486],
            [26.3474, 56.9444],
            [26.3427, 56.9205],
            [26.2904, 56.9348],
            [26.2243, 56.9242],
            [26.1783, 57.0002],
            [26.188, 57.0791],
            [26.2215, 57.0906],
            [26.3254, 57.0725],
            [26.3714, 57.043],
          ],
        ],
      },
      properties: {name: 'Cesvaines novads', id: 'LV-021', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-021',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.5557, 57.2256],
            [25.5795, 57.1873],
            [25.5617, 57.1537],
            [25.4868, 57.1702],
            [25.4423, 57.1627],
            [25.4155, 57.1879],
            [25.3745, 57.196],
            [25.3484, 57.216],
            [25.3573, 57.2424],
            [25.2592, 57.2984],
            [25.2149, 57.2962],
            [25.2248, 57.3348],
            [25.2286, 57.3401],
            [25.3109, 57.3264],
            [25.3252, 57.2962],
            [25.3983, 57.3052],
            [25.4648, 57.2861],
            [25.4957, 57.2633],
            [25.4856, 57.2265],
            [25.5254, 57.2156],
            [25.5557, 57.2256],
          ],
        ],
      },
      properties: {name: 'Cēsu novads', id: 'LV-022', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-022',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.5907, 56.5632],
            [27.5675, 56.5696],
            [27.5603, 56.5968],
            [27.5884, 56.6073],
            [27.6181, 56.6132],
            [27.6253, 56.6413],
            [27.6728, 56.6528],
            [27.6918, 56.6695],
            [27.7566, 56.6747],
            [27.8362, 56.6593],
            [27.8724, 56.7156],
            [27.8998, 56.7153],
            [27.9188, 56.6884],
            [27.9779, 56.6939],
            [28.0161, 56.673],
            [28.011, 56.637],
            [28.0583, 56.5836],
            [28.1248, 56.5825],
            [28.1414, 56.5518],
            [28.1044, 56.5415],
            [28.1019, 56.5097],
            [28.1363, 56.5102],
            [28.144, 56.4841],
            [28.0952, 56.4836],
            [28.0382, 56.4962],
            [27.9911, 56.4777],
            [27.9726, 56.4564],
            [27.8506, 56.5289],
            [27.7665, 56.5234],
            [27.7209, 56.5545],
            [27.6909, 56.5435],
            [27.6217, 56.566],
            [27.5907, 56.5632],
          ],
        ],
      },
      properties: {name: 'Ciblas novads', id: 'LV-023', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-023',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.7416, 55.9708],
            [27.6955, 55.9837],
            [27.661, 56.0047],
            [27.6444, 55.9771],
            [27.6171, 55.9741],
            [27.5446, 55.9907],
            [27.4715, 56.0227],
            [27.4234, 56.0347],
            [27.355, 56.0257],
            [27.3824, 56.0636],
            [27.3657, 56.0759],
            [27.2618, 56.0798],
            [27.2419, 56.1128],
            [27.3182, 56.121],
            [27.3524, 56.1457],
            [27.3125, 56.1695],
            [27.3215, 56.195],
            [27.3439, 56.2032],
            [27.4018, 56.2013],
            [27.4375, 56.1913],
            [27.4679, 56.2278],
            [27.5055, 56.2226],
            [27.5697, 56.2345],
            [27.6129, 56.2289],
            [27.6817, 56.2325],
            [27.7209, 56.1953],
            [27.756, 56.186],
            [27.8113, 56.1857],
            [27.8612, 56.1714],
            [27.9164, 56.1701],
            [27.9426, 56.1857],
            [27.9848, 56.1834],
            [28.05, 56.2111],
            [28.088, 56.1978],
            [28.0905, 56.2006],
            [28.1045, 56.19],
            [28.0499, 56.1457],
            [28.0654, 56.1333],
            [28.0444, 56.1166],
            [27.9975, 56.1246],
            [27.9529, 56.1032],
            [27.9362, 56.0718],
            [27.9108, 56.0548],
            [27.8397, 56.0367],
            [27.8133, 55.9885],
            [27.7416, 55.9708],
          ],
        ],
      },
      properties: {name: 'Dagdas novads', id: 'LV-024', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-024',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.9825, 55.8122],
            [26.9508, 55.786],
            [26.9261, 55.7868],
            [26.8746, 55.7119],
            [26.8037, 55.6952],
            [26.7864, 55.6754],
            [26.7471, 55.68],
            [26.7231, 55.6985],
            [26.6605, 55.7046],
            [26.6386, 55.6791],
            [26.5511, 55.6749],
            [26.4951, 55.6857],
            [26.4666, 55.704],
            [26.3859, 55.7035],
            [26.3498, 55.7412],
            [26.3151, 55.7427],
            [26.2711, 55.7666],
            [26.2383, 55.8178],
            [26.3119, 55.816],
            [26.329, 55.8497],
            [26.2705, 55.8753],
            [26.2534, 55.8938],
            [26.2684, 55.9472],
            [26.3326, 55.9788],
            [26.3839, 55.9848],
            [26.3611, 56.0163],
            [26.3639, 56.0363],
            [26.3424, 56.0832],
            [26.3448, 56.1451],
            [26.3017, 56.1765],
            [26.338, 56.1818],
            [26.3873, 56.1965],
            [26.4239, 56.1734],
            [26.4603, 56.1895],
            [26.5014, 56.1654],
            [26.5061, 56.1295],
            [26.5508, 56.1239],
            [26.5541, 56.1528],
            [26.6238, 56.123],
            [26.6539, 56.13],
            [26.6938, 56.1262],
            [26.7069, 56.1002],
            [26.7763, 56.104],
            [26.8214, 56.1257],
            [26.8538, 56.1122],
            [26.8904, 56.1328],
            [26.8943, 56.1228],
            [26.8895, 56.105],
            [26.9772, 56.0034],
            [26.9375, 55.9838],
            [26.9737, 55.939],
            [26.9451, 55.9283],
            [26.9644, 55.909],
            [26.9339, 55.8934],
            [26.9423, 55.8597],
            [26.9861, 55.8611],
            [26.9825, 55.8122],
          ],
          [
            [26.5928, 55.954],
            [26.5579, 55.916],
            [26.5222, 55.9116],
            [26.4702, 55.9292],
            [26.4439, 55.9155],
            [26.4937, 55.8759],
            [26.4759, 55.8615],
            [26.4987, 55.841],
            [26.5422, 55.8398],
            [26.6285, 55.8687],
            [26.6164, 55.92],
            [26.5928, 55.954],
          ],
        ],
      },
      properties: {name: 'Daugavpils novads', id: 'LV-025', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-025',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.4119, 56.4922],
            [23.3758, 56.5506],
            [23.2795, 56.4997],
            [23.2909, 56.4551],
            [23.231, 56.4674],
            [23.1733, 56.4583],
            [23.1514, 56.444],
            [23.1479, 56.4473],
            [23.1208, 56.4769],
            [23.0887, 56.4832],
            [23.0973, 56.5088],
            [23.0816, 56.5471],
            [23.0317, 56.5916],
            [22.9917, 56.5844],
            [22.9639, 56.6029],
            [22.9333, 56.5993],
            [22.9326, 56.573],
            [22.8976, 56.571],
            [22.8334, 56.5466],
            [22.8081, 56.5732],
            [22.7744, 56.6107],
            [22.8351, 56.6067],
            [22.841, 56.6568],
            [22.8188, 56.6947],
            [22.8962, 56.714],
            [23.0174, 56.7177],
            [23.0311, 56.6943],
            [23.1133, 56.691],
            [23.1196, 56.7059],
            [23.1615, 56.6944],
            [23.1991, 56.7253],
            [23.2788, 56.7114],
            [23.3468, 56.752],
            [23.31, 56.7717],
            [23.3785, 56.8075],
            [23.3877, 56.7931],
            [23.4523, 56.7984],
            [23.4894, 56.8153],
            [23.5096, 56.8173],
            [23.5319, 56.7576],
            [23.4602, 56.7398],
            [23.4226, 56.7045],
            [23.4957, 56.6832],
            [23.4828, 56.6575],
            [23.4396, 56.645],
            [23.4385, 56.6265],
            [23.4023, 56.6041],
            [23.4283, 56.5639],
            [23.4727, 56.55],
            [23.4466, 56.5149],
            [23.4119, 56.4922],
          ],
        ],
      },
      properties: {name: 'Dobeles novads', id: 'LV-026', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-026',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.5735, 57.6785],
            [22.5445, 57.6741],
            [22.535, 57.6425],
            [22.5503, 57.6226],
            [22.6035, 57.5916],
            [22.5873, 57.5691],
            [22.6099, 57.5108],
            [22.5607, 57.496],
            [22.5227, 57.5052],
            [22.5084, 57.4724],
            [22.4799, 57.4622],
            [22.4618, 57.4191],
            [22.4057, 57.4212],
            [22.343, 57.4022],
            [22.3344, 57.4258],
            [22.2926, 57.4376],
            [22.2413, 57.4242],
            [22.2459, 57.4062],
            [22.2053, 57.3955],
            [22.1367, 57.4009],
            [22.0777, 57.4225],
            [22.1747, 57.4484],
            [22.1748, 57.4736],
            [22.2815, 57.5057],
            [22.2701, 57.5548],
            [22.2441, 57.585],
            [22.2587, 57.6054],
            [22.1966, 57.6563],
            [22.5111, 57.7436],
            [22.6011, 57.7586],
            [22.5779, 57.7161],
            [22.5735, 57.6785],
          ],
        ],
      },
      properties: {name: 'Dundagas novads', id: 'LV-027', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-027',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.5792, 56.5665],
            [21.5263, 56.57],
            [21.5164, 56.5168],
            [21.4058, 56.5578],
            [21.3987, 56.5239],
            [21.3509, 56.5251],
            [21.3295, 56.5428],
            [21.2568, 56.5397],
            [21.259, 56.5893],
            [21.2789, 56.6164],
            [21.246, 56.653],
            [21.2852, 56.6761],
            [21.2852, 56.6967],
            [21.2445, 56.7292],
            [21.268, 56.7473],
            [21.3001, 56.7433],
            [21.3021, 56.7278],
            [21.3467, 56.7046],
            [21.3954, 56.6931],
            [21.3954, 56.6781],
            [21.4524, 56.648],
            [21.4869, 56.6578],
            [21.5653, 56.6656],
            [21.6176, 56.6162],
            [21.6188, 56.5792],
            [21.5792, 56.5665],
          ],
        ],
      },
      properties: {name: 'Durbes novads', id: 'LV-028', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-028',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.4723, 56.9384],
            [23.3686, 56.9197],
            [23.2835, 56.9288],
            [23.2441, 56.9252],
            [23.2051, 56.9376],
            [23.1386, 56.9278],
            [23.1804, 57.0127],
            [23.2118, 57.0375],
            [23.2313, 57.0732],
            [23.2042, 57.1087],
            [23.15, 57.1322],
            [23.1291, 57.1273],
            [23.0668, 57.1591],
            [23.1091, 57.1689],
            [23.1234, 57.2133],
            [23.1179, 57.2509],
            [23.1023, 57.2987],
            [23.1439, 57.317],
            [23.1771, 57.2622],
            [23.1763, 57.2331],
            [23.231, 57.161],
            [23.2364, 57.1088],
            [23.2984, 57.0655],
            [23.352, 57.0475],
            [23.4182, 57.0367],
            [23.4887, 57.0335],
            [23.5225, 56.9943],
            [23.5514, 56.9784],
            [23.4775, 56.9644],
            [23.4723, 56.9384],
          ],
        ],
      },
      properties: {name: 'Engures novads', id: 'LV-029', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-029',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.699, 56.765],
            [25.6776, 56.7926],
            [25.5913, 56.8141],
            [25.5075, 56.8216],
            [25.4879, 56.8675],
            [25.5274, 56.8713],
            [25.5096, 56.8972],
            [25.5396, 56.9308],
            [25.5204, 56.9679],
            [25.4881, 56.9939],
            [25.6032, 56.9821],
            [25.6367, 56.958],
            [25.7048, 56.9841],
            [25.8227, 56.9834],
            [25.8448, 56.9581],
            [25.8883, 56.9605],
            [25.8889, 56.961],
            [25.9448, 56.929],
            [25.9362, 56.9103],
            [25.8492, 56.9103],
            [25.7615, 56.8951],
            [25.7337, 56.8795],
            [25.7539, 56.825],
            [25.74, 56.7794],
            [25.699, 56.765],
          ],
        ],
      },
      properties: {name: 'Ērgļu novads', id: 'LV-030', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-030',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.3635, 56.9636],
            [24.3221, 56.9706],
            [24.2682, 57.0074],
            [24.198, 57.0265],
            [24.1761, 57.0547],
            [24.2396, 57.068],
            [24.2486, 57.0476],
            [24.3282, 57.0333],
            [24.3585, 57.0573],
            [24.4642, 57.0745],
            [24.5017, 57.1007],
            [24.5177, 57.0774],
            [24.5587, 57.0858],
            [24.5819, 57.0644],
            [24.5415, 57.068],
            [24.5159, 57.0505],
            [24.5314, 57.0223],
            [24.5088, 57.0051],
            [24.3995, 56.9999],
            [24.4007, 56.9789],
            [24.3635, 56.9636],
          ],
        ],
      },
      properties: {name: 'Garkalnes novads', id: 'LV-031', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-031',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.3509, 56.5251],
            [21.3495, 56.4975],
            [21.3923, 56.49],
            [21.3902, 56.4659],
            [21.4272, 56.4375],
            [21.4415, 56.4087],
            [21.4258, 56.3608],
            [21.3916, 56.3295],
            [21.2668, 56.3275],
            [21.2383, 56.3517],
            [21.2283, 56.417],
            [21.177, 56.4213],
            [21.1427, 56.4359],
            [21.1342, 56.4967],
            [21.0607, 56.489],
            [21.0774, 56.5275],
            [21.1104, 56.5393],
            [21.1104, 56.5706],
            [21.0786, 56.5703],
            [21.0792, 56.6071],
            [21.026, 56.6118],
            [21.0377, 56.6346],
            [21.0714, 56.6254],
            [21.1213, 56.6277],
            [21.1192, 56.6623],
            [21.1406, 56.6804],
            [21.1961, 56.6459],
            [21.246, 56.653],
            [21.2789, 56.6164],
            [21.259, 56.5893],
            [21.2568, 56.5397],
            [21.3295, 56.5428],
            [21.3509, 56.5251],
          ],
        ],
      },
      properties: {name: 'Grobiņas novads', id: 'LV-032', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-032',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.276, 57.3303],
            [26.3511, 57.317],
            [26.4374, 57.3285],
            [26.4336, 57.3691],
            [26.4893, 57.3723],
            [26.4715, 57.3928],
            [26.5164, 57.4021],
            [26.55, 57.3886],
            [26.5901, 57.3922],
            [26.6038, 57.3658],
            [26.6828, 57.3346],
            [26.7162, 57.3052],
            [26.7439, 57.3222],
            [26.782, 57.3233],
            [26.86, 57.3036],
            [26.9227, 57.3017],
            [26.9825, 57.3082],
            [27.0307, 57.2819],
            [27.0482, 57.2494],
            [27.1612, 57.2261],
            [27.1366, 57.2022],
            [27.0706, 57.165],
            [27.0397, 57.1321],
            [26.9961, 57.1039],
            [26.9379, 57.0457],
            [26.8594, 57.0232],
            [26.8597, 56.9825],
            [26.7317, 56.9884],
            [26.7079, 56.9717],
            [26.643, 56.9695],
            [26.5672, 56.9494],
            [26.5423, 57.0212],
            [26.5136, 57.0438],
            [26.446, 57.0643],
            [26.3714, 57.043],
            [26.3254, 57.0725],
            [26.2215, 57.0906],
            [26.188, 57.0791],
            [26.1041, 57.0788],
            [26.1662, 57.1408],
            [26.1196, 57.1767],
            [26.1024, 57.205],
            [26.0077, 57.2149],
            [26.004, 57.2508],
            [26.0496, 57.2665],
            [26.0841, 57.2827],
            [26.1382, 57.2718],
            [26.2638, 57.2806],
            [26.2526, 57.305],
            [26.276, 57.3303],
          ],
        ],
      },
      properties: {name: 'Gulbenes novads', id: 'LV-033', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-033',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.3686, 56.6624],
            [24.3826, 56.6248],
            [24.3246, 56.6248],
            [24.3294, 56.5734],
            [24.3978, 56.5629],
            [24.3978, 56.5393],
            [24.3646, 56.5498],
            [24.278, 56.5366],
            [24.2524, 56.5114],
            [24.1811, 56.5314],
            [24.0799, 56.5462],
            [24.1117, 56.5844],
            [24.0438, 56.5817],
            [24.0693, 56.6128],
            [24.0421, 56.6927],
            [24.1063, 56.6846],
            [24.1623, 56.6637],
            [24.1886, 56.6881],
            [24.2476, 56.6957],
            [24.2501, 56.7114],
            [24.2871, 56.6849],
            [24.3686, 56.6624],
          ],
        ],
      },
      properties: {name: 'Iecavas novads', id: 'LV-034', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-034',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.5023, 56.8998],
            [24.5688, 56.9056],
            [24.5817, 56.896],
            [24.6608, 56.896],
            [24.7225, 56.9032],
            [24.7208, 56.8979],
            [24.7653, 56.8606],
            [24.7149, 56.8484],
            [24.6778, 56.8538],
            [24.6436, 56.8257],
            [24.5946, 56.8343],
            [24.5623, 56.821],
            [24.5148, 56.8108],
            [24.4834, 56.818],
            [24.443, 56.8434],
            [24.4411, 56.8553],
            [24.5023, 56.8998],
          ],
        ],
      },
      properties: {name: 'Ikšķiles novads', id: 'LV-035', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-035',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.3424, 56.0832],
            [26.3639, 56.0363],
            [26.3611, 56.0163],
            [26.3839, 55.9848],
            [26.3326, 55.9788],
            [26.2684, 55.9472],
            [26.2534, 55.8938],
            [26.2705, 55.8753],
            [26.329, 55.8497],
            [26.3119, 55.816],
            [26.2383, 55.8178],
            [26.2026, 55.8592],
            [26.1238, 55.8784],
            [26.0536, 55.9387],
            [26.0489, 55.9535],
            [25.978, 55.9789],
            [25.8599, 56.0007],
            [25.8524, 56.0277],
            [25.8254, 56.0509],
            [25.8419, 56.0732],
            [25.9027, 56.0944],
            [25.938, 56.0608],
            [26.0107, 56.0868],
            [26.0359, 56.0667],
            [26.067, 56.069],
            [26.0942, 56.0975],
            [26.1476, 56.102],
            [26.1382, 56.129],
            [26.2276, 56.1725],
            [26.2558, 56.1564],
            [26.317, 56.1528],
            [26.32, 56.0977],
            [26.3424, 56.0832],
          ],
        ],
      },
      properties: {name: 'Ilūkstes novads', id: 'LV-036', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-036',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.7888, 57.1366],
            [24.7696, 57.0812],
            [24.6926, 57.059],
            [24.6372, 57.0637],
            [24.5819, 57.0644],
            [24.5587, 57.0858],
            [24.5177, 57.0774],
            [24.5017, 57.1007],
            [24.5067, 57.113],
            [24.5564, 57.123],
            [24.5749, 57.1433],
            [24.6693, 57.1317],
            [24.7246, 57.1469],
            [24.7888, 57.1366],
          ],
        ],
      },
      properties: {name: 'Inčukalna novads', id: 'LV-037', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-037',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.5514, 56.5751],
            [25.6085, 56.5556],
            [25.5658, 56.5374],
            [25.5485, 56.5121],
            [25.5969, 56.4822],
            [25.5973, 56.4686],
            [25.5541, 56.4428],
            [25.5184, 56.4491],
            [25.4687, 56.4106],
            [25.3887, 56.4071],
            [25.3249, 56.4198],
            [25.3002, 56.4086],
            [25.1599, 56.421],
            [25.1011, 56.4164],
            [25.0737, 56.4661],
            [25.0517, 56.4723],
            [25.0258, 56.507],
            [25.0217, 56.5455],
            [24.9777, 56.5616],
            [24.9694, 56.5809],
            [25.0291, 56.5893],
            [25.0519, 56.6104],
            [25.0897, 56.6294],
            [25.1321, 56.6243],
            [25.2177, 56.5852],
            [25.263, 56.5724],
            [25.2815, 56.5948],
            [25.3224, 56.5944],
            [25.4229, 56.6418],
            [25.5024, 56.6096],
            [25.496, 56.5856],
            [25.5514, 56.5751],
          ],
        ],
      },
      properties: {
        name: 'Jaunjelgavas novads',
        id: 'LV-038',
        CNTRY: 'Latvia',
        TYPE: 'Municipality',
      },
      id: 'LV-038',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.9183, 57.0959],
            [25.8755, 57.0997],
            [25.8384, 57.1296],
            [25.7757, 57.1385],
            [25.7785, 57.1637],
            [25.7578, 57.1773],
            [25.7683, 57.2071],
            [25.8249, 57.2133],
            [25.8513, 57.1881],
            [25.9333, 57.1889],
            [26.0077, 57.2149],
            [26.1024, 57.205],
            [26.1196, 57.1767],
            [26.1662, 57.1408],
            [26.1041, 57.0788],
            [26.0637, 57.0944],
            [26.0438, 57.0796],
            [25.9951, 57.0812],
            [25.9523, 57.1007],
            [25.9183, 57.0959],
          ],
        ],
      },
      properties: {
        name: 'Jaunpiebalgas novads',
        id: 'LV-039',
        CNTRY: 'Latvia',
        TYPE: 'Municipality',
      },
      id: 'LV-039',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.9338, 56.8559],
            [22.8867, 56.8269],
            [22.9206, 56.8051],
            [22.9634, 56.7973],
            [23.0216, 56.8041],
            [23.0501, 56.7907],
            [23.0792, 56.7421],
            [23.1196, 56.7059],
            [23.1133, 56.691],
            [23.0311, 56.6943],
            [23.0174, 56.7177],
            [22.8962, 56.714],
            [22.8188, 56.6947],
            [22.8288, 56.7434],
            [22.8237, 56.7706],
            [22.7914, 56.7981],
            [22.8417, 56.8276],
            [22.8286, 56.8484],
            [22.8619, 56.8614],
            [22.9338, 56.8559],
          ],
        ],
      },
      properties: {name: 'Jaunpils novads', id: 'LV-040', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-040',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.5008, 56.8978],
            [23.5984, 56.8969],
            [23.7076, 56.858],
            [23.7178, 56.8325],
            [23.7574, 56.8084],
            [23.7678, 56.8076],
            [23.7526, 56.7468],
            [23.7086, 56.7378],
            [23.6886, 56.7117],
            [23.7276, 56.695],
            [23.7124, 56.6902],
            [23.6401, 56.6567],
            [23.6791, 56.6374],
            [23.6734, 56.6117],
            [23.7789, 56.6248],
            [23.7818, 56.6452],
            [23.8274, 56.6389],
            [23.8711, 56.6211],
            [23.9253, 56.618],
            [23.9634, 56.5797],
            [23.9805, 56.5356],
            [24.0434, 56.507],
            [23.956, 56.502],
            [23.9254, 56.4787],
            [23.8759, 56.4748],
            [23.8473, 56.4012],
            [23.8641, 56.3904],
            [23.8678, 56.3379],
            [23.8021, 56.3399],
            [23.7641, 56.3724],
            [23.633, 56.36],
            [23.5452, 56.3311],
            [23.4386, 56.3504],
            [23.3891, 56.3762],
            [23.4373, 56.3831],
            [23.4716, 56.4041],
            [23.4388, 56.4274],
            [23.4119, 56.4922],
            [23.4466, 56.5149],
            [23.4727, 56.55],
            [23.4283, 56.5639],
            [23.4023, 56.6041],
            [23.4385, 56.6265],
            [23.4396, 56.645],
            [23.4828, 56.6575],
            [23.4957, 56.6832],
            [23.4226, 56.7045],
            [23.4602, 56.7398],
            [23.5319, 56.7576],
            [23.5096, 56.8173],
            [23.4894, 56.8153],
            [23.4684, 56.8843],
            [23.5008, 56.8978],
          ],
        ],
      },
      properties: {name: 'Jelgavas novads', id: 'LV-041', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-041',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.9089, 56.4966],
            [25.9301, 56.4797],
            [26.0074, 56.4688],
            [26.071, 56.4438],
            [26.0899, 56.4056],
            [26.1347, 56.3968],
            [26.1544, 56.3763],
            [26.1821, 56.2934],
            [26.2014, 56.2715],
            [26.178, 56.2401],
            [26.2017, 56.2194],
            [26.2722, 56.2012],
            [26.3017, 56.1765],
            [26.3448, 56.1451],
            [26.3424, 56.0832],
            [26.32, 56.0977],
            [26.317, 56.1528],
            [26.2558, 56.1564],
            [26.2276, 56.1725],
            [26.1382, 56.129],
            [26.1476, 56.102],
            [26.0942, 56.0975],
            [26.067, 56.069],
            [26.0359, 56.0667],
            [26.0107, 56.0868],
            [26.0044, 56.1088],
            [25.9478, 56.1331],
            [25.9627, 56.155],
            [25.9032, 56.1694],
            [25.8836, 56.1894],
            [25.8837, 56.218],
            [25.8452, 56.2334],
            [25.7948, 56.2403],
            [25.6838, 56.2369],
            [25.6671, 56.2895],
            [25.726, 56.3011],
            [25.7343, 56.3593],
            [25.7924, 56.3603],
            [25.8494, 56.4081],
            [25.8078, 56.4509],
            [25.8257, 56.4806],
            [25.8249, 56.4836],
            [25.8916, 56.4769],
            [25.9089, 56.4966],
          ],
        ],
      },
      properties: {name: 'Jēkabpils novads', id: 'LV-042', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-042',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.493, 56.8562],
            [22.5154, 56.8749],
            [22.5098, 56.9034],
            [22.5355, 56.9234],
            [22.4785, 56.9289],
            [22.4495, 56.9196],
            [22.467, 56.9449],
            [22.4703, 56.9815],
            [22.5035, 57.0169],
            [22.5532, 57.0049],
            [22.5621, 57.0235],
            [22.6391, 57.0147],
            [22.6506, 57.0333],
            [22.633, 57.0688],
            [22.6358, 57.0965],
            [22.7065, 57.1025],
            [22.836, 57.1425],
            [22.9304, 57.1314],
            [22.9171, 57.1064],
            [22.8827, 57.0873],
            [22.8482, 57.085],
            [22.8333, 57.0426],
            [22.866, 57.0323],
            [22.8595, 57.0092],
            [22.8256, 57.0079],
            [22.8096, 56.9739],
            [22.8227, 56.9469],
            [22.8589, 56.9239],
            [22.9082, 56.9239],
            [22.9338, 56.8559],
            [22.8619, 56.8614],
            [22.8286, 56.8484],
            [22.8417, 56.8276],
            [22.7914, 56.7981],
            [22.755, 56.8103],
            [22.7076, 56.7982],
            [22.6764, 56.8132],
            [22.6388, 56.809],
            [22.5861, 56.8434],
            [22.5301, 56.8392],
            [22.493, 56.8562],
          ],
        ],
      },
      properties: {name: 'Kandavas novads', id: 'LV-043', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-043',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.5884, 56.6073],
            [27.5945, 56.6305],
            [27.4634, 56.6729],
            [27.4887, 56.6853],
            [27.498, 56.726],
            [27.5212, 56.7355],
            [27.4903, 56.7799],
            [27.3887, 56.775],
            [27.3613, 56.7835],
            [27.3569, 56.783],
            [27.3453, 56.8176],
            [27.3837, 56.8158],
            [27.4295, 56.8512],
            [27.5844, 56.8546],
            [27.6593, 56.8365],
            [27.6902, 56.8373],
            [27.766, 56.8633],
            [27.8115, 56.8647],
            [27.8548, 56.8793],
            [27.8856, 56.8574],
            [27.9065, 56.8225],
            [27.9666, 56.8277],
            [27.9454, 56.8027],
            [27.9454, 56.7785],
            [27.9026, 56.7608],
            [27.9779, 56.6939],
            [27.9188, 56.6884],
            [27.8998, 56.7153],
            [27.8724, 56.7156],
            [27.8362, 56.6593],
            [27.7566, 56.6747],
            [27.6918, 56.6695],
            [27.6728, 56.6528],
            [27.6253, 56.6413],
            [27.6181, 56.6132],
            [27.5884, 56.6073],
          ],
        ],
      },
      properties: {name: 'Kārsavas novads', id: 'LV-044', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-044',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.0942, 57.6542],
            [25.1291, 57.6512],
            [25.1919, 57.671],
            [25.2047, 57.6499],
            [25.2686, 57.6109],
            [25.2757, 57.5943],
            [25.3389, 57.5741],
            [25.3684, 57.5442],
            [25.3787, 57.5091],
            [25.3806, 57.4543],
            [25.3988, 57.4406],
            [25.4025, 57.4109],
            [25.3805, 57.3817],
            [25.3151, 57.4016],
            [25.2928, 57.3854],
            [25.2433, 57.4103],
            [25.193, 57.4067],
            [25.1706, 57.4393],
            [25.1335, 57.4537],
            [25.1364, 57.4803],
            [25.0639, 57.4952],
            [25.0456, 57.5044],
            [25.0204, 57.5493],
            [24.9994, 57.5623],
            [24.9181, 57.5497],
            [24.8892, 57.5671],
            [24.8976, 57.6063],
            [24.9283, 57.6023],
            [24.9771, 57.6325],
            [25.0221, 57.6181],
            [25.0666, 57.6269],
            [25.0549, 57.6504],
            [25.0942, 57.6542],
          ],
        ],
      },
      properties: {name: 'Kocēnu novads', id: 'LV-045', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-045',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.5075, 56.8216],
            [25.5913, 56.8141],
            [25.6776, 56.7926],
            [25.699, 56.765],
            [25.6355, 56.7453],
            [25.6355, 56.7151],
            [25.5856, 56.7089],
            [25.6027, 56.6841],
            [25.5478, 56.6504],
            [25.5279, 56.6091],
            [25.5024, 56.6096],
            [25.4229, 56.6418],
            [25.3224, 56.5944],
            [25.3062, 56.6166],
            [25.2842, 56.6977],
            [25.2976, 56.7258],
            [25.261, 56.7346],
            [25.2881, 56.7561],
            [25.3266, 56.7655],
            [25.3988, 56.76],
            [25.4801, 56.789],
            [25.5075, 56.8216],
          ],
        ],
      },
      properties: {name: 'Kokneses novads', id: 'LV-046', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-046',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.7416, 55.9708],
            [27.6564, 55.9272],
            [27.6278, 55.8906],
            [27.6429, 55.8443],
            [27.6235, 55.7892],
            [27.5499, 55.7831],
            [27.4347, 55.7929],
            [27.3591, 55.8098],
            [27.3509, 55.7981],
            [27.2847, 55.7842],
            [27.2075, 55.8301],
            [27.1657, 55.8481],
            [27.1084, 55.842],
            [27.0998, 55.8248],
            [27.0175, 55.8269],
            [26.9825, 55.8122],
            [26.9861, 55.8611],
            [26.9423, 55.8597],
            [26.9339, 55.8934],
            [26.9644, 55.909],
            [26.9451, 55.9283],
            [26.9737, 55.939],
            [26.9375, 55.9838],
            [26.9772, 56.0034],
            [27.0303, 55.9862],
            [27.0541, 55.9987],
            [27.134, 55.9902],
            [27.1834, 56.0106],
            [27.1881, 56.0543],
            [27.2376, 56.0607],
            [27.2618, 56.0798],
            [27.3657, 56.0759],
            [27.3824, 56.0636],
            [27.355, 56.0257],
            [27.4234, 56.0347],
            [27.4715, 56.0227],
            [27.5446, 55.9907],
            [27.6171, 55.9741],
            [27.6444, 55.9771],
            [27.661, 56.0047],
            [27.6955, 55.9837],
            [27.7416, 55.9708],
          ],
        ],
      },
      properties: {name: 'Krāslavas novads', id: 'LV-047', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-047',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.6794, 57.2212],
            [24.6949, 57.238],
            [24.6816, 57.2901],
            [24.6403, 57.3086],
            [24.684, 57.3223],
            [24.7116, 57.3446],
            [24.693, 57.3798],
            [24.7292, 57.3901],
            [24.8423, 57.3806],
            [24.8971, 57.308],
            [24.95, 57.2788],
            [24.9356, 57.2512],
            [24.9052, 57.2327],
            [24.9029, 57.2251],
            [24.8898, 57.1947],
            [24.8208, 57.1801],
            [24.7888, 57.1366],
            [24.7246, 57.1469],
            [24.6693, 57.1317],
            [24.6771, 57.1753],
            [24.6224, 57.1805],
            [24.6224, 57.206],
            [24.6794, 57.2212],
          ],
        ],
      },
      properties: {name: 'Krimuldas novads', id: 'LV-048', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-048',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.0899, 56.4056],
            [26.071, 56.4438],
            [26.0074, 56.4688],
            [25.9301, 56.4797],
            [25.9089, 56.4966],
            [25.8839, 56.5301],
            [25.8334, 56.5252],
            [25.7579, 56.5353],
            [25.7365, 56.5681],
            [25.7592, 56.6132],
            [25.7846, 56.6062],
            [25.8405, 56.6113],
            [25.9317, 56.6366],
            [25.9621, 56.6596],
            [26.0156, 56.6368],
            [26.0303, 56.6184],
            [26.136, 56.6558],
            [26.1899, 56.646],
            [26.2181, 56.6017],
            [26.2749, 56.6193],
            [26.2834, 56.5851],
            [26.3143, 56.584],
            [26.3615, 56.6086],
            [26.3797, 56.6444],
            [26.4865, 56.596],
            [26.5959, 56.5958],
            [26.594, 56.5752],
            [26.6253, 56.5386],
            [26.5769, 56.4861],
            [26.5635, 56.4859],
            [26.4842, 56.4996],
            [26.4409, 56.4734],
            [26.3659, 56.4495],
            [26.3661, 56.473],
            [26.3138, 56.4987],
            [26.3096, 56.5159],
            [26.2305, 56.4974],
            [26.1576, 56.4283],
            [26.0899, 56.4056],
          ],
        ],
      },
      properties: {name: 'Krustpils novads', id: 'LV-049', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-049',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.169, 56.7971],
            [22.1118, 56.8069],
            [22.0928, 56.8272],
            [22.0058, 56.8298],
            [21.9574, 56.811],
            [21.9393, 56.7886],
            [21.9688, 56.7308],
            [21.9179, 56.7235],
            [21.8283, 56.6875],
            [21.7621, 56.7033],
            [21.7796, 56.7301],
            [21.7486, 56.766],
            [21.6952, 56.7903],
            [21.6454, 56.7919],
            [21.598, 56.844],
            [21.5648, 56.8402],
            [21.5049, 56.8528],
            [21.4752, 56.8808],
            [21.494, 56.9063],
            [21.5487, 56.8957],
            [21.5755, 56.924],
            [21.6319, 56.9188],
            [21.6426, 56.9321],
            [21.7044, 56.9542],
            [21.6824, 56.9935],
            [21.6236, 57.0272],
            [21.6016, 57.0511],
            [21.5474, 57.0758],
            [21.596, 57.0861],
            [21.7049, 57.1241],
            [21.7809, 57.0888],
            [21.826, 57.0804],
            [21.8452, 57.1038],
            [21.9052, 57.0949],
            [22.0212, 57.1096],
            [22.0666, 57.1007],
            [22.1796, 57.1157],
            [22.2088, 57.1884],
            [22.2508, 57.1737],
            [22.2469, 57.1521],
            [22.2708, 57.1294],
            [22.3571, 57.1131],
            [22.3599, 57.0814],
            [22.384, 57.012],
            [22.4816, 57.0209],
            [22.5035, 57.0169],
            [22.4703, 56.9815],
            [22.467, 56.9449],
            [22.4495, 56.9196],
            [22.4275, 56.889],
            [22.3543, 56.8997],
            [22.3459, 56.9246],
            [22.2995, 56.9332],
            [22.2675, 56.8878],
            [22.3299, 56.8843],
            [22.3533, 56.8608],
            [22.3048, 56.8332],
            [22.252, 56.8307],
            [22.2108, 56.7937],
            [22.169, 56.7971],
          ],
        ],
      },
      properties: {name: 'Kuldīgas novads', id: 'LV-050', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-050',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.0519, 56.6104],
            [25.0291, 56.5893],
            [24.9694, 56.5809],
            [24.8404, 56.576],
            [24.7339, 56.5456],
            [24.6752, 56.5479],
            [24.6626, 56.5694],
            [24.6965, 56.5859],
            [24.6687, 56.6028],
            [24.6174, 56.6029],
            [24.5942, 56.6274],
            [24.5857, 56.6738],
            [24.6382, 56.6752],
            [24.6327, 56.7125],
            [24.5326, 56.7092],
            [24.5288, 56.7173],
            [24.5273, 56.754],
            [24.5422, 56.7695],
            [24.5148, 56.8108],
            [24.5623, 56.821],
            [24.5935, 56.8131],
            [24.6386, 56.7714],
            [24.7034, 56.7518],
            [24.7497, 56.7563],
            [24.7872, 56.7808],
            [24.7783, 56.8167],
            [24.8133, 56.8154],
            [24.8181, 56.846],
            [24.9274, 56.8167],
            [24.9292, 56.8117],
            [24.8793, 56.7755],
            [24.8525, 56.7697],
            [24.8519, 56.7396],
            [24.8002, 56.7128],
            [24.8656, 56.7027],
            [24.8781, 56.6664],
            [24.8989, 56.6473],
            [24.9642, 56.6172],
            [25.0415, 56.6096],
            [25.0519, 56.6104],
          ],
        ],
      },
      properties: {name: 'Ķeguma novads', id: 'LV-051', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-051',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.443, 56.8434],
            [24.4834, 56.818],
            [24.5148, 56.8108],
            [24.5422, 56.7695],
            [24.5273, 56.754],
            [24.4143, 56.7881],
            [24.3686, 56.7923],
            [24.3115, 56.7793],
            [24.2117, 56.7434],
            [24.2258, 56.7212],
            [24.1667, 56.7297],
            [23.995, 56.7256],
            [23.995, 56.7258],
            [23.9967, 56.7297],
            [24.0039, 56.7594],
            [24.0414, 56.775],
            [24.0927, 56.7732],
            [24.1365, 56.8204],
            [24.0932, 56.8582],
            [24.0932, 56.879],
            [24.1068, 56.895],
            [24.151, 56.8938],
            [24.1774, 56.9059],
            [24.2017, 56.8782],
            [24.1938, 56.8649],
            [24.2594, 56.836],
            [24.3193, 56.847],
            [24.4042, 56.8376],
            [24.443, 56.8434],
          ],
        ],
      },
      properties: {name: 'Ķekavas novads', id: 'LV-052', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-052',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.0415, 56.6096],
            [24.9642, 56.6172],
            [24.8989, 56.6473],
            [24.8781, 56.6664],
            [24.8656, 56.7027],
            [24.8002, 56.7128],
            [24.8519, 56.7396],
            [24.8525, 56.7697],
            [24.8793, 56.7755],
            [24.9292, 56.8117],
            [24.928, 56.8151],
            [25.0166, 56.8005],
            [25.0422, 56.8047],
            [25.0998, 56.7395],
            [25.0439, 56.7372],
            [25.0677, 56.7032],
            [25.0231, 56.6924],
            [25.0136, 56.6423],
            [25.0416, 56.6348],
            [25.0415, 56.6096],
          ],
        ],
      },
      properties: {name: 'Lielvārdes novads', id: 'LV-053', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-053',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.6678, 57.7571],
            [24.688, 57.7571],
            [24.7433, 57.7211],
            [24.7938, 57.7218],
            [24.8366, 57.7068],
            [24.8455, 57.6565],
            [24.9055, 57.6393],
            [24.8976, 57.6063],
            [24.8892, 57.5671],
            [24.9181, 57.5497],
            [24.9994, 57.5623],
            [25.0204, 57.5493],
            [25.0456, 57.5044],
            [25.0639, 57.4952],
            [25.048, 57.4749],
            [25.0085, 57.4806],
            [24.9968, 57.4608],
            [24.9101, 57.4214],
            [24.8338, 57.3977],
            [24.8423, 57.3806],
            [24.7292, 57.3901],
            [24.693, 57.3798],
            [24.7116, 57.3446],
            [24.684, 57.3223],
            [24.6403, 57.3086],
            [24.6816, 57.2901],
            [24.6949, 57.238],
            [24.6794, 57.2212],
            [24.6351, 57.2334],
            [24.625, 57.2623],
            [24.5748, 57.2874],
            [24.5709, 57.3159],
            [24.5411, 57.3182],
            [24.4465, 57.295],
            [24.4026, 57.3498],
            [24.3984, 57.3991],
            [24.4965, 57.411],
            [24.5292, 57.4453],
            [24.5649, 57.4459],
            [24.5815, 57.4693],
            [24.5482, 57.4802],
            [24.5447, 57.5355],
            [24.5072, 57.5566],
            [24.4371, 57.5607],
            [24.4371, 57.6635],
            [24.5531, 57.6734],
            [24.5668, 57.7014],
            [24.6268, 57.7065],
            [24.6131, 57.7374],
            [24.6678, 57.7571],
          ],
        ],
      },
      properties: {name: 'Limbažu novads', id: 'LV-054', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-054',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.09, 57.1219],
            [25.075, 57.1308],
            [25.008, 57.1269],
            [24.9782, 57.1426],
            [24.9774, 57.1857],
            [24.9464, 57.2133],
            [24.903, 57.2253],
            [24.9052, 57.2327],
            [24.9356, 57.2512],
            [25.0534, 57.256],
            [25.0843, 57.2655],
            [25.0843, 57.248],
            [25.1551, 57.2355],
            [25.1674, 57.1712],
            [25.1979, 57.1567],
            [25.1594, 57.1306],
            [25.09, 57.1219],
          ],
        ],
      },
      properties: {name: 'Līgatnes novads', id: 'LV-055', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-055',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.0899, 56.4056],
            [26.1576, 56.4283],
            [26.2305, 56.4974],
            [26.3096, 56.5159],
            [26.3138, 56.4987],
            [26.3661, 56.473],
            [26.3659, 56.4495],
            [26.4409, 56.4734],
            [26.4842, 56.4996],
            [26.5635, 56.4859],
            [26.5664, 56.4859],
            [26.5664, 56.4396],
            [26.5931, 56.4333],
            [26.584, 56.3988],
            [26.5569, 56.3742],
            [26.5137, 56.3938],
            [26.4742, 56.3563],
            [26.5664, 56.3299],
            [26.6135, 56.2836],
            [26.579, 56.2632],
            [26.5016, 56.278],
            [26.4246, 56.278],
            [26.3922, 56.3211],
            [26.3704, 56.3145],
            [26.3675, 56.278],
            [26.3, 56.2947],
            [26.32, 56.2009],
            [26.338, 56.1818],
            [26.3017, 56.1765],
            [26.2722, 56.2012],
            [26.2017, 56.2194],
            [26.178, 56.2401],
            [26.2014, 56.2715],
            [26.1821, 56.2934],
            [26.1544, 56.3763],
            [26.1347, 56.3968],
            [26.0899, 56.4056],
          ],
        ],
      },
      properties: {name: 'Līvānu novads', id: 'LV-056', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-056',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.9461, 56.8428],
            [26.895, 56.8584],
            [26.851, 56.8864],
            [26.7654, 56.8734],
            [26.7185, 56.8861],
            [26.6739, 56.863],
            [26.6739, 56.85],
            [26.6032, 56.8161],
            [26.58, 56.7936],
            [26.5046, 56.8073],
            [26.4766, 56.8529],
            [26.564, 56.9241],
            [26.5672, 56.9494],
            [26.643, 56.9695],
            [26.7079, 56.9717],
            [26.7317, 56.9884],
            [26.8597, 56.9825],
            [26.8976, 56.9783],
            [26.9198, 56.9605],
            [26.9022, 56.9377],
            [26.9617, 56.9197],
            [26.9717, 56.8881],
            [26.9927, 56.8746],
            [26.9461, 56.8428],
          ],
        ],
      },
      properties: {name: 'Lubānas novads', id: 'LV-057', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-057',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.1809, 56.397],
            [28.132, 56.4015],
            [28.0898, 56.3887],
            [28.0548, 56.4101],
            [28.0019, 56.4038],
            [28.0274, 56.3662],
            [27.9656, 56.3527],
            [27.8605, 56.2934],
            [27.8836, 56.2597],
            [27.9478, 56.264],
            [27.9413, 56.2858],
            [27.9823, 56.2895],
            [28.0286, 56.3066],
            [28.094, 56.2766],
            [28.1332, 56.2485],
            [28.0922, 56.2024],
            [28.088, 56.1978],
            [28.05, 56.2111],
            [27.9848, 56.1834],
            [27.9426, 56.1857],
            [27.9164, 56.1701],
            [27.8612, 56.1714],
            [27.8113, 56.1857],
            [27.756, 56.186],
            [27.7209, 56.1953],
            [27.6817, 56.2325],
            [27.7116, 56.2456],
            [27.6927, 56.267],
            [27.7291, 56.3021],
            [27.7018, 56.3251],
            [27.7033, 56.3691],
            [27.6424, 56.3766],
            [27.6259, 56.4071],
            [27.5812, 56.4324],
            [27.5516, 56.4625],
            [27.5094, 56.4619],
            [27.4852, 56.4959],
            [27.5071, 56.5349],
            [27.5892, 56.5376],
            [27.5907, 56.5632],
            [27.6217, 56.566],
            [27.6909, 56.5435],
            [27.7209, 56.5545],
            [27.7665, 56.5234],
            [27.8506, 56.5289],
            [27.9726, 56.4564],
            [27.9911, 56.4777],
            [28.0382, 56.4962],
            [28.0952, 56.4836],
            [28.144, 56.4841],
            [28.1936, 56.4485],
            [28.1809, 56.397],
          ],
        ],
      },
      properties: {name: 'Ludzas novads', id: 'LV-058', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-058',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.7695, 56.678],
            [26.7047, 56.6902],
            [26.7047, 56.707],
            [26.6377, 56.7067],
            [26.5136, 56.6658],
            [26.3817, 56.6721],
            [26.3797, 56.6444],
            [26.3615, 56.6086],
            [26.3143, 56.584],
            [26.2834, 56.5851],
            [26.2749, 56.6193],
            [26.2181, 56.6017],
            [26.1899, 56.646],
            [26.136, 56.6558],
            [26.0303, 56.6184],
            [26.0156, 56.6368],
            [25.9621, 56.6596],
            [25.9317, 56.6366],
            [25.9223, 56.6564],
            [25.9483, 56.6824],
            [25.8829, 56.692],
            [25.8293, 56.7156],
            [25.8205, 56.7442],
            [25.8487, 56.764],
            [25.8383, 56.7918],
            [25.7539, 56.825],
            [25.7337, 56.8795],
            [25.7615, 56.8951],
            [25.8492, 56.9103],
            [25.9362, 56.9103],
            [25.9448, 56.929],
            [25.8889, 56.961],
            [25.8891, 56.9611],
            [25.9242, 56.9728],
            [25.9249, 57.0019],
            [25.882, 57.011],
            [25.8999, 57.0712],
            [25.9183, 57.0959],
            [25.9523, 57.1007],
            [25.9951, 57.0812],
            [26.0438, 57.0796],
            [26.0637, 57.0944],
            [26.1041, 57.0788],
            [26.188, 57.0791],
            [26.1783, 57.0002],
            [26.2243, 56.9242],
            [26.2904, 56.9348],
            [26.3427, 56.9205],
            [26.3474, 56.9444],
            [26.3878, 56.9486],
            [26.4121, 56.987],
            [26.3778, 56.9992],
            [26.3714, 57.043],
            [26.446, 57.0643],
            [26.5136, 57.0438],
            [26.5423, 57.0212],
            [26.5672, 56.9494],
            [26.564, 56.9241],
            [26.4766, 56.8529],
            [26.5046, 56.8073],
            [26.58, 56.7936],
            [26.6032, 56.8161],
            [26.6739, 56.85],
            [26.6739, 56.863],
            [26.7185, 56.8861],
            [26.7654, 56.8734],
            [26.851, 56.8864],
            [26.895, 56.8584],
            [26.9461, 56.8428],
            [26.9308, 56.8323],
            [26.7869, 56.7124],
            [26.7695, 56.678],
          ],
        ],
      },
      properties: {name: 'Madonas novads', id: 'LV-059', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-059',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.0987, 58.0656],
            [25.1185, 58.0455],
            [25.0941, 58.0007],
            [25.141, 57.9581],
            [25.1226, 57.9394],
            [25.1434, 57.9204],
            [25.1874, 57.9103],
            [25.2153, 57.8669],
            [25.2563, 57.852],
            [25.2551, 57.8029],
            [25.2379, 57.7943],
            [25.1874, 57.8137],
            [25.1505, 57.8441],
            [25.1131, 57.8416],
            [25.1256, 57.7978],
            [25.0371, 57.8093],
            [25.002, 57.7922],
            [24.8956, 57.8157],
            [24.8683, 57.8917],
            [24.8368, 57.9025],
            [24.8346, 57.9738],
            [24.9519, 58.0126],
            [25.0082, 58.0147],
            [25.0798, 58.066],
            [25.0987, 58.0656],
          ],
        ],
      },
      properties: {name: 'Mazsalacas novads', id: 'LV-060', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-060',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.8195, 56.9832],
            [24.8954, 57.0135],
            [24.8775, 57.0387],
            [24.8954, 57.0725],
            [24.9831, 57.0828],
            [24.9855, 57.059],
            [25.0338, 57.0494],
            [25.0708, 57.0216],
            [25.132, 57.0065],
            [25.1472, 56.9693],
            [25.1389, 56.9506],
            [25.0249, 56.9512],
            [24.9755, 56.9359],
            [24.8615, 56.9283],
            [24.8335, 56.9379],
            [24.7945, 56.9307],
            [24.8191, 56.9586],
            [24.8195, 56.9832],
          ],
        ],
      },
      properties: {name: 'Mālpils novads', id: 'LV-061', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-061',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.0625, 56.879],
            [24.0039, 56.8525],
            [23.9682, 56.8608],
            [23.8555, 56.8347],
            [23.8302, 56.889],
            [23.8944, 56.9092],
            [23.9443, 56.9394],
            [24.0209, 56.9229],
            [24.0715, 56.9017],
            [24.0625, 56.879],
          ],
        ],
      },
      properties: {name: 'Mārupes novads', id: 'LV-062', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-062',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.9927, 57.3924],
            [23.0035, 57.4006],
            [23.0387, 57.3772],
            [23.1225, 57.3657],
            [23.1439, 57.317],
            [23.1023, 57.2987],
            [23.1179, 57.2509],
            [23.0855, 57.236],
            [23.0364, 57.2562],
            [23.0536, 57.2891],
            [23.0174, 57.3061],
            [22.9623, 57.2953],
            [22.9176, 57.319],
            [22.8976, 57.3478],
            [22.9708, 57.3662],
            [22.9927, 57.3924],
          ],
        ],
      },
      properties: {name: 'Mērsraga novads', id: 'LV-063', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-063',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.6939, 57.9036],
            [25.6932, 57.8799],
            [25.6672, 57.8513],
            [25.6291, 57.8501],
            [25.5688, 57.8756],
            [25.528, 57.8731],
            [25.476, 57.8483],
            [25.5527, 57.808],
            [25.5301, 57.7795],
            [25.4623, 57.783],
            [25.4628, 57.8131],
            [25.4329, 57.8243],
            [25.3934, 57.8699],
            [25.2689, 57.9294],
            [25.2812, 57.9524],
            [25.3273, 57.9896],
            [25.3502, 57.96],
            [25.4138, 57.9595],
            [25.4219, 57.9843],
            [25.4491, 57.9924],
            [25.5286, 57.9704],
            [25.5799, 57.9732],
            [25.5852, 57.9438],
            [25.6303, 57.9329],
            [25.6493, 57.9154],
            [25.6939, 57.9036],
          ],
        ],
      },
      properties: {name: 'Naukšēnu novads', id: 'LV-064', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-064',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.3002, 56.4086],
            [25.2978, 56.3636],
            [25.3213, 56.3122],
            [25.3056, 56.2892],
            [25.3795, 56.2579],
            [25.4015, 56.227],
            [25.388, 56.2122],
            [25.4144, 56.1606],
            [25.363, 56.152],
            [25.3183, 56.1428],
            [25.2823, 56.1566],
            [25.2353, 56.1903],
            [25.2022, 56.173],
            [25.1543, 56.173],
            [25.0781, 56.193],
            [25.057, 56.2638],
            [24.978, 56.3099],
            [24.9727, 56.362],
            [24.945, 56.3734],
            [24.8886, 56.4475],
            [24.9337, 56.4962],
            [25.0258, 56.507],
            [25.0517, 56.4723],
            [25.0737, 56.4661],
            [25.1011, 56.4164],
            [25.1599, 56.421],
            [25.3002, 56.4086],
          ],
        ],
      },
      properties: {name: 'Neretas novads', id: 'LV-065', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-065',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.2383, 56.3517],
            [21.1854, 56.3541],
            [21.192, 56.2986],
            [21.173, 56.26],
            [21.1407, 56.2235],
            [21.1036, 56.2256],
            [21.1055, 56.171],
            [21.0703, 56.1662],
            [21.0608, 56.224],
            [21.0371, 56.2653],
            [20.9814, 56.2602],
            [20.9825, 56.3026],
            [20.969, 56.3691],
            [20.9991, 56.4374],
            [21.0001, 56.4706],
            [21.0607, 56.489],
            [21.1342, 56.4967],
            [21.1427, 56.4359],
            [21.177, 56.4213],
            [21.2283, 56.417],
            [21.2383, 56.3517],
          ],
        ],
      },
      properties: {name: 'Nīcas novads', id: 'LV-066', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-066',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.1389, 56.9506],
            [25.2415, 56.9288],
            [25.326, 56.98],
            [25.3566, 56.9751],
            [25.3687, 56.9423],
            [25.3951, 56.9315],
            [25.4578, 56.965],
            [25.4784, 56.9926],
            [25.4881, 56.9939],
            [25.5204, 56.9679],
            [25.5396, 56.9308],
            [25.5096, 56.8972],
            [25.5274, 56.8713],
            [25.4879, 56.8675],
            [25.5075, 56.8216],
            [25.4801, 56.789],
            [25.3988, 56.76],
            [25.3266, 56.7655],
            [25.2881, 56.7561],
            [25.261, 56.7346],
            [25.2976, 56.7258],
            [25.2842, 56.6977],
            [25.2189, 56.7114],
            [25.1911, 56.7019],
            [25.0998, 56.7395],
            [25.0422, 56.8047],
            [25.0166, 56.8005],
            [24.928, 56.8151],
            [24.9274, 56.8167],
            [24.8181, 56.846],
            [24.8133, 56.8154],
            [24.7783, 56.8167],
            [24.7872, 56.7808],
            [24.7497, 56.7563],
            [24.7034, 56.7518],
            [24.6386, 56.7714],
            [24.5935, 56.8131],
            [24.5623, 56.821],
            [24.5946, 56.8343],
            [24.6436, 56.8257],
            [24.6778, 56.8538],
            [24.7149, 56.8484],
            [24.7653, 56.8606],
            [24.7208, 56.8979],
            [24.7232, 56.9054],
            [24.7945, 56.9307],
            [24.8335, 56.9379],
            [24.8615, 56.9283],
            [24.9755, 56.9359],
            [25.0249, 56.9512],
            [25.1389, 56.9506],
          ],
        ],
      },
      properties: {name: 'Ogres novads', id: 'LV-067', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-067',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [24.0625, 56.879],
              [24.0932, 56.879],
              [24.0932, 56.8582],
              [24.1365, 56.8204],
              [24.0927, 56.7732],
              [24.0414, 56.775],
              [24.0039, 56.7594],
              [23.9967, 56.7297],
              [23.9315, 56.7285],
              [23.8848, 56.7459],
              [23.8356, 56.7401],
              [23.7979, 56.7254],
              [23.7526, 56.7468],
              [23.7678, 56.8076],
              [23.7952, 56.8225],
              [23.8337, 56.8069],
              [23.8555, 56.8347],
              [23.9682, 56.8608],
              [24.0039, 56.8525],
              [24.0625, 56.879],
            ],
          ],
          [
            [
              [23.995, 56.7256],
              [24.1667, 56.7297],
              [24.2258, 56.7212],
              [24.2501, 56.7114],
              [24.2476, 56.6957],
              [24.1886, 56.6881],
              [24.1623, 56.6637],
              [24.1063, 56.6846],
              [24.0421, 56.6927],
              [24.0055, 56.6991],
              [23.9948, 56.7249],
              [23.995, 56.7256],
            ],
          ],
        ],
      },
      properties: {name: 'Olaines novads', id: 'LV-068', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-068',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.7818, 56.6452],
            [23.7989, 56.6599],
            [23.7894, 56.6892],
            [23.7265, 56.6946],
            [23.7276, 56.695],
            [23.6886, 56.7117],
            [23.7086, 56.7378],
            [23.7526, 56.7468],
            [23.7979, 56.7254],
            [23.8356, 56.7401],
            [23.8848, 56.7459],
            [23.9315, 56.7285],
            [23.9967, 56.7297],
            [23.995, 56.7258],
            [23.9948, 56.7249],
            [24.0055, 56.6991],
            [24.0421, 56.6927],
            [24.0693, 56.6128],
            [24.0438, 56.5817],
            [24.1117, 56.5844],
            [24.0799, 56.5462],
            [24.0434, 56.507],
            [23.9805, 56.5356],
            [23.9634, 56.5797],
            [23.9253, 56.618],
            [23.8711, 56.6211],
            [23.8274, 56.6389],
            [23.7818, 56.6452],
          ],
        ],
      },
      properties: {name: 'Ozolnieku novads', id: 'LV-069', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-069',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.0639, 57.4952],
            [25.1364, 57.4803],
            [25.1335, 57.4537],
            [25.1706, 57.4393],
            [25.193, 57.4067],
            [25.2433, 57.4103],
            [25.2928, 57.3854],
            [25.3151, 57.4016],
            [25.3805, 57.3817],
            [25.3585, 57.358],
            [25.2724, 57.3666],
            [25.2403, 57.3634],
            [25.2296, 57.3416],
            [25.2248, 57.3348],
            [25.2149, 57.2962],
            [25.1951, 57.275],
            [25.1084, 57.2769],
            [25.0843, 57.2655],
            [25.0534, 57.256],
            [24.9356, 57.2512],
            [24.95, 57.2788],
            [24.8971, 57.308],
            [24.8423, 57.3806],
            [24.8338, 57.3977],
            [24.9101, 57.4214],
            [24.9968, 57.4608],
            [25.0085, 57.4806],
            [25.048, 57.4749],
            [25.0639, 57.4952],
          ],
        ],
      },
      properties: {name: 'Pārgaujas novads', id: 'LV-070', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-070',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.4752, 56.8808],
            [21.4508, 56.8647],
            [21.4413, 56.8345],
            [21.3354, 56.8244],
            [21.3022, 56.802],
            [21.3099, 56.7606],
            [21.3001, 56.7433],
            [21.268, 56.7473],
            [21.2445, 56.7292],
            [21.2852, 56.6967],
            [21.2852, 56.6761],
            [21.246, 56.653],
            [21.1961, 56.6459],
            [21.1406, 56.6804],
            [21.1192, 56.6623],
            [21.1213, 56.6277],
            [21.0714, 56.6254],
            [21.0377, 56.6346],
            [21.0579, 56.6986],
            [21.0591, 56.837],
            [21.122, 56.8705],
            [21.2474, 56.9212],
            [21.2842, 56.9516],
            [21.328, 56.9736],
            [21.3952, 56.9627],
            [21.4009, 56.9469],
            [21.4015, 56.9263],
            [21.494, 56.9063],
            [21.4752, 56.8808],
          ],
        ],
      },
      properties: {name: 'Pāvilostas novads', id: 'LV-071', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-071',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.5024, 56.6096],
            [25.5279, 56.6091],
            [25.5478, 56.6504],
            [25.6027, 56.6841],
            [25.5856, 56.7089],
            [25.6355, 56.7151],
            [25.6355, 56.7453],
            [25.699, 56.765],
            [25.74, 56.7794],
            [25.7539, 56.825],
            [25.8383, 56.7918],
            [25.8487, 56.764],
            [25.8205, 56.7442],
            [25.8293, 56.7156],
            [25.8829, 56.692],
            [25.9483, 56.6824],
            [25.9223, 56.6564],
            [25.9317, 56.6366],
            [25.8405, 56.6113],
            [25.7846, 56.6062],
            [25.7592, 56.6132],
            [25.7271, 56.6136],
            [25.6369, 56.5849],
            [25.5514, 56.5751],
            [25.496, 56.5856],
            [25.5024, 56.6096],
          ],
        ],
      },
      properties: {name: 'Pļaviņu novads', id: 'LV-072', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-072',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.6539, 56.13],
            [26.6116, 56.1482],
            [26.682, 56.1764],
            [26.6791, 56.2177],
            [26.6972, 56.2463],
            [26.6606, 56.2828],
            [26.6135, 56.2836],
            [26.5664, 56.3299],
            [26.4742, 56.3563],
            [26.5137, 56.3938],
            [26.5569, 56.3742],
            [26.584, 56.3988],
            [26.5931, 56.4333],
            [26.6335, 56.4565],
            [26.7176, 56.4254],
            [26.7709, 56.3901],
            [26.72, 56.384],
            [26.6629, 56.3546],
            [26.6572, 56.3322],
            [26.7257, 56.3134],
            [26.7428, 56.2968],
            [26.797, 56.2828],
            [26.8208, 56.2582],
            [26.8288, 56.2257],
            [26.8569, 56.2193],
            [26.8517, 56.1716],
            [26.8902, 56.1491],
            [26.8904, 56.1328],
            [26.8538, 56.1122],
            [26.8214, 56.1257],
            [26.7763, 56.104],
            [26.7069, 56.1002],
            [26.6938, 56.1262],
            [26.6539, 56.13],
          ],
        ],
      },
      properties: {name: 'Preiļu novads', id: 'LV-073', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-073',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.4918, 56.2897],
            [21.4715, 56.3059],
            [21.3916, 56.3295],
            [21.4258, 56.3608],
            [21.4415, 56.4087],
            [21.4272, 56.4375],
            [21.3902, 56.4659],
            [21.3923, 56.49],
            [21.3495, 56.4975],
            [21.3509, 56.5251],
            [21.3987, 56.5239],
            [21.4058, 56.5578],
            [21.5164, 56.5168],
            [21.5263, 56.57],
            [21.5792, 56.5665],
            [21.639, 56.5529],
            [21.6539, 56.5687],
            [21.7157, 56.5572],
            [21.7219, 56.5173],
            [21.744, 56.4846],
            [21.6969, 56.4747],
            [21.6848, 56.4593],
            [21.709, 56.4143],
            [21.7333, 56.3938],
            [21.7297, 56.3664],
            [21.753, 56.3331],
            [21.723, 56.3116],
            [21.6741, 56.3095],
            [21.6354, 56.3216],
            [21.5637, 56.3142],
            [21.5176, 56.288],
            [21.4918, 56.2897],
          ],
        ],
      },
      properties: {name: 'Priekules novads', id: 'LV-074', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-074',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.6043, 57.2705],
            [25.6021, 57.252],
            [25.5557, 57.2256],
            [25.5254, 57.2156],
            [25.4856, 57.2265],
            [25.4957, 57.2633],
            [25.4648, 57.2861],
            [25.3983, 57.3052],
            [25.3252, 57.2962],
            [25.3109, 57.3264],
            [25.2286, 57.3401],
            [25.2296, 57.3416],
            [25.2403, 57.3634],
            [25.2724, 57.3666],
            [25.3585, 57.358],
            [25.3805, 57.3817],
            [25.4025, 57.4109],
            [25.3988, 57.4406],
            [25.4555, 57.4369],
            [25.5018, 57.4533],
            [25.52, 57.4295],
            [25.5939, 57.4669],
            [25.6572, 57.4626],
            [25.6645, 57.442],
            [25.6362, 57.4269],
            [25.6262, 57.4022],
            [25.5881, 57.3854],
            [25.5297, 57.3739],
            [25.513, 57.3528],
            [25.5382, 57.3397],
            [25.5668, 57.3006],
            [25.6043, 57.2705],
          ],
        ],
      },
      properties: {name: 'Priekuļu novads', id: 'LV-075', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-075',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [25.6645, 57.442],
              [25.7103, 57.4006],
              [25.6898, 57.3916],
              [25.7108, 57.3505],
              [25.7568, 57.3344],
              [25.717, 57.2788],
              [25.726, 57.2731],
              [25.7362, 57.2722],
              [25.6461, 57.277],
              [25.6043, 57.2705],
              [25.5668, 57.3006],
              [25.5382, 57.3397],
              [25.513, 57.3528],
              [25.5297, 57.3739],
              [25.5881, 57.3854],
              [25.6262, 57.4022],
              [25.6362, 57.4269],
              [25.6645, 57.442],
            ],
          ],
          [
            [
              [25.7527, 57.2707],
              [25.7608, 57.2802],
              [25.9471, 57.2826],
              [26.0104, 57.2908],
              [26.0496, 57.2665],
              [26.004, 57.2508],
              [26.0077, 57.2149],
              [25.9333, 57.1889],
              [25.8513, 57.1881],
              [25.8249, 57.2133],
              [25.7683, 57.2071],
              [25.7414, 57.2229],
              [25.7527, 57.2707],
            ],
          ],
        ],
      },
      properties: {name: 'Raunas novads', id: 'LV-076', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-076',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.6817, 56.2325],
            [27.6129, 56.2289],
            [27.5697, 56.2345],
            [27.5055, 56.2226],
            [27.4679, 56.2278],
            [27.4375, 56.1913],
            [27.4018, 56.2013],
            [27.3439, 56.2032],
            [27.3215, 56.195],
            [27.2537, 56.1994],
            [27.2018, 56.1954],
            [27.1728, 56.2033],
            [27.1205, 56.1789],
            [27.0479, 56.1794],
            [27.0329, 56.216],
            [26.9971, 56.2434],
            [27.0229, 56.2588],
            [26.9515, 56.2941],
            [26.9441, 56.3215],
            [26.9959, 56.3303],
            [26.9561, 56.3762],
            [26.8951, 56.4092],
            [26.9169, 56.442],
            [26.9518, 56.4566],
            [26.9718, 56.4433],
            [27.011, 56.4535],
            [27.0509, 56.4476],
            [27.0782, 56.4749],
            [27.0491, 56.4884],
            [27.0223, 56.5423],
            [27.0318, 56.5767],
            [27.0099, 56.5918],
            [26.9617, 56.5951],
            [26.8886, 56.6646],
            [26.847, 56.6623],
            [26.831, 56.677],
            [26.7746, 56.6756],
            [26.7695, 56.678],
            [26.7869, 56.7124],
            [26.9308, 56.8323],
            [26.9649, 56.8121],
            [27.0335, 56.814],
            [27.0858, 56.8074],
            [27.1623, 56.8105],
            [27.182, 56.7893],
            [27.2713, 56.7913],
            [27.3127, 56.7803],
            [27.3524, 56.7825],
            [27.3613, 56.7835],
            [27.3887, 56.775],
            [27.4903, 56.7799],
            [27.5212, 56.7355],
            [27.498, 56.726],
            [27.4887, 56.6853],
            [27.4634, 56.6729],
            [27.5945, 56.6305],
            [27.5884, 56.6073],
            [27.5603, 56.5968],
            [27.5675, 56.5696],
            [27.5907, 56.5632],
            [27.5892, 56.5376],
            [27.5071, 56.5349],
            [27.4852, 56.4959],
            [27.5094, 56.4619],
            [27.5516, 56.4625],
            [27.5812, 56.4324],
            [27.6259, 56.4071],
            [27.6424, 56.3766],
            [27.7033, 56.3691],
            [27.7018, 56.3251],
            [27.7291, 56.3021],
            [27.6927, 56.267],
            [27.7116, 56.2456],
            [27.6817, 56.2325],
          ],
          [
            [27.3741, 56.5338],
            [27.3484, 56.5338],
            [27.3266, 56.5248],
            [27.317, 56.4801],
            [27.3427, 56.478],
            [27.3893, 56.5022],
            [27.3741, 56.5338],
          ],
        ],
      },
      properties: {name: 'Rēzeknes novads', id: 'LV-077', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-077',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.9518, 56.4566],
            [26.9169, 56.442],
            [26.8951, 56.4092],
            [26.9561, 56.3762],
            [26.9959, 56.3303],
            [26.9441, 56.3215],
            [26.9515, 56.2941],
            [27.0229, 56.2588],
            [26.9971, 56.2434],
            [27.0329, 56.216],
            [27.0479, 56.1794],
            [27.1205, 56.1789],
            [27.1193, 56.1645],
            [27.0068, 56.1658],
            [26.9464, 56.1488],
            [26.8943, 56.1228],
            [26.8904, 56.1328],
            [26.8902, 56.1491],
            [26.8517, 56.1716],
            [26.8569, 56.2193],
            [26.8288, 56.2257],
            [26.8208, 56.2582],
            [26.797, 56.2828],
            [26.7428, 56.2968],
            [26.7257, 56.3134],
            [26.6572, 56.3322],
            [26.6629, 56.3546],
            [26.72, 56.384],
            [26.7709, 56.3901],
            [26.7176, 56.4254],
            [26.6335, 56.4565],
            [26.5931, 56.4333],
            [26.5664, 56.4396],
            [26.5664, 56.4859],
            [26.5769, 56.4861],
            [26.6253, 56.5386],
            [26.7427, 56.545],
            [26.7491, 56.5595],
            [26.8199, 56.5388],
            [26.8065, 56.4996],
            [26.8473, 56.4996],
            [26.8569, 56.4824],
            [26.956, 56.4761],
            [26.9518, 56.4566],
          ],
        ],
      },
      properties: {name: 'Riebiņu novads', id: 'LV-078', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-078',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.0035, 57.4006],
            [22.9927, 57.3924],
            [22.9224, 57.4109],
            [22.9262, 57.3842],
            [22.8739, 57.3755],
            [22.814, 57.4063],
            [22.794, 57.4325],
            [22.6942, 57.475],
            [22.6533, 57.5093],
            [22.6099, 57.5108],
            [22.5873, 57.5691],
            [22.6035, 57.5916],
            [22.5503, 57.6226],
            [22.535, 57.6425],
            [22.5445, 57.6741],
            [22.5735, 57.6785],
            [22.5993, 57.6189],
            [22.646, 57.5772],
            [22.6949, 57.5492],
            [22.8454, 57.4914],
            [22.891, 57.4539],
            [22.9437, 57.4218],
            [23.0035, 57.4006],
          ],
        ],
      },
      properties: {name: 'Rojas novads', id: 'LV-079', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-079',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.5819, 57.0644],
            [24.6372, 57.0637],
            [24.6616, 57.018],
            [24.7103, 57.0002],
            [24.781, 57.0015],
            [24.8195, 56.9832],
            [24.8191, 56.9586],
            [24.7945, 56.9307],
            [24.7232, 56.9054],
            [24.7225, 56.9032],
            [24.6608, 56.896],
            [24.5817, 56.896],
            [24.5688, 56.9056],
            [24.5023, 56.8998],
            [24.4501, 56.9069],
            [24.4345, 56.9234],
            [24.4241, 56.9414],
            [24.3756, 56.9379],
            [24.3635, 56.9636],
            [24.4007, 56.9789],
            [24.3995, 56.9999],
            [24.5088, 57.0051],
            [24.5314, 57.0223],
            [24.5159, 57.0505],
            [24.5415, 57.068],
            [24.5819, 57.0644],
          ],
        ],
      },
      properties: {name: 'Ropažu novads', id: 'LV-080', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-080',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.3916, 56.3295],
            [21.4715, 56.3059],
            [21.4918, 56.2897],
            [21.4533, 56.2471],
            [21.3289, 56.2174],
            [21.2655, 56.167],
            [21.2309, 56.1617],
            [21.2347, 56.115],
            [21.2012, 56.0765],
            [21.1583, 56.0855],
            [21.0637, 56.069],
            [21.0298, 56.1419],
            [20.9829, 56.2119],
            [20.9814, 56.2602],
            [21.0371, 56.2653],
            [21.0608, 56.224],
            [21.0703, 56.1662],
            [21.1055, 56.171],
            [21.1036, 56.2256],
            [21.1407, 56.2235],
            [21.173, 56.26],
            [21.192, 56.2986],
            [21.1854, 56.3541],
            [21.2383, 56.3517],
            [21.2668, 56.3275],
            [21.3916, 56.3295],
          ],
        ],
      },
      properties: {name: 'Rucavas novads', id: 'LV-081', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-081',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.9927, 56.8746],
            [26.9717, 56.8881],
            [26.9617, 56.9197],
            [26.9022, 56.9377],
            [26.9198, 56.9605],
            [26.8976, 56.9783],
            [26.8597, 56.9825],
            [26.8594, 57.0232],
            [26.9379, 57.0457],
            [26.9961, 57.1039],
            [27.11, 57.1039],
            [27.1028, 57.0796],
            [27.2326, 57.0511],
            [27.3291, 57.0461],
            [27.303, 56.9909],
            [27.2478, 56.98],
            [27.2588, 56.9654],
            [27.3253, 56.9576],
            [27.332, 56.9251],
            [27.2823, 56.9142],
            [27.2029, 56.8794],
            [27.1901, 56.857],
            [27.1426, 56.852],
            [27.1236, 56.8653],
            [27.0627, 56.8742],
            [26.9927, 56.8746],
          ],
        ],
      },
      properties: {name: 'Rugāju novads', id: 'LV-082', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-082',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.0144, 56.3291],
            [23.9278, 56.3289],
            [23.8678, 56.3379],
            [23.8641, 56.3904],
            [23.8473, 56.4012],
            [23.8759, 56.4748],
            [23.9254, 56.4787],
            [23.956, 56.502],
            [24.0434, 56.507],
            [24.0622, 56.4596],
            [24.0461, 56.4422],
            [24.1098, 56.4085],
            [24.1563, 56.4042],
            [24.1601, 56.3821],
            [24.0812, 56.3832],
            [24.047, 56.343],
            [24.0144, 56.3291],
          ],
        ],
      },
      properties: {name: 'Rundāles novads', id: 'LV-083', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-083',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.4491, 57.9924],
            [25.4219, 57.9843],
            [25.4138, 57.9595],
            [25.3502, 57.96],
            [25.3273, 57.9896],
            [25.2812, 57.9524],
            [25.2689, 57.9294],
            [25.3934, 57.8699],
            [25.4329, 57.8243],
            [25.4628, 57.8131],
            [25.4623, 57.783],
            [25.3667, 57.7836],
            [25.2972, 57.7776],
            [25.2551, 57.8029],
            [25.2563, 57.852],
            [25.2153, 57.8669],
            [25.1874, 57.9103],
            [25.1434, 57.9204],
            [25.1226, 57.9394],
            [25.141, 57.9581],
            [25.0941, 58.0007],
            [25.1185, 58.0455],
            [25.0987, 58.0656],
            [25.1325, 58.0803],
            [25.189, 58.0759],
            [25.2122, 58.063],
            [25.2241, 58.0229],
            [25.2589, 57.9951],
            [25.3012, 57.9958],
            [25.3049, 58.0332],
            [25.2636, 58.0641],
            [25.2938, 58.0829],
            [25.3359, 58.0594],
            [25.365, 58.031],
            [25.41, 58.0298],
            [25.4522, 58.0146],
            [25.4491, 57.9924],
          ],
        ],
      },
      properties: {name: 'Rūjienas novads', id: 'LV-084', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-084',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.5514, 56.5751],
            [25.6369, 56.5849],
            [25.7271, 56.6136],
            [25.7592, 56.6132],
            [25.7365, 56.5681],
            [25.7579, 56.5353],
            [25.8334, 56.5252],
            [25.8239, 56.4871],
            [25.8257, 56.4806],
            [25.8078, 56.4509],
            [25.8494, 56.4081],
            [25.7924, 56.3603],
            [25.7343, 56.3593],
            [25.7254, 56.3748],
            [25.6784, 56.3748],
            [25.6576, 56.4005],
            [25.6261, 56.3998],
            [25.6036, 56.4353],
            [25.5973, 56.4686],
            [25.5969, 56.4822],
            [25.5485, 56.5121],
            [25.5658, 56.5374],
            [25.6085, 56.5556],
            [25.5514, 56.5751],
          ],
        ],
      },
      properties: {name: 'Salas novads', id: 'LV-085', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-085',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.6678, 57.7571],
            [24.6131, 57.7374],
            [24.6268, 57.7065],
            [24.5668, 57.7014],
            [24.5531, 57.6734],
            [24.4371, 57.6635],
            [24.4371, 57.5607],
            [24.5072, 57.5566],
            [24.5447, 57.5355],
            [24.5482, 57.4802],
            [24.5815, 57.4693],
            [24.5649, 57.4459],
            [24.5292, 57.4453],
            [24.4965, 57.411],
            [24.3984, 57.3991],
            [24.3836, 57.4489],
            [24.3799, 57.5107],
            [24.362, 57.5402],
            [24.3641, 57.5793],
            [24.3864, 57.6158],
            [24.3645, 57.6728],
            [24.3429, 57.6981],
            [24.3477, 57.7878],
            [24.3371, 57.8371],
            [24.3523, 57.8745],
            [24.4056, 57.8708],
            [24.4598, 57.8785],
            [24.4459, 57.9092],
            [24.4619, 57.9237],
            [24.51, 57.9298],
            [24.5223, 57.9453],
            [24.5761, 57.9558],
            [24.5987, 57.9013],
            [24.6206, 57.8877],
            [24.5883, 57.8674],
            [24.6235, 57.8451],
            [24.6301, 57.8167],
            [24.6678, 57.7571],
          ],
        ],
      },
      properties: {name: 'Salacgrīvas novads', id: 'LV-086', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-086',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.4345, 56.9234],
            [24.4501, 56.9069],
            [24.5023, 56.8998],
            [24.4411, 56.8553],
            [24.443, 56.8434],
            [24.4042, 56.8376],
            [24.3193, 56.847],
            [24.2594, 56.836],
            [24.1938, 56.8649],
            [24.2017, 56.8782],
            [24.2701, 56.8751],
            [24.2537, 56.9184],
            [24.2915, 56.925],
            [24.3714, 56.918],
            [24.4345, 56.9234],
          ],
        ],
      },
      properties: {name: 'Salaspils novads', id: 'LV-087', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-087',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.493, 56.8562],
            [22.4551, 56.8074],
            [22.4473, 56.7692],
            [22.469, 56.7384],
            [22.5121, 56.7081],
            [22.5028, 56.656],
            [22.5292, 56.6375],
            [22.5609, 56.6383],
            [22.5567, 56.5994],
            [22.6201, 56.6118],
            [22.6294, 56.6326],
            [22.6971, 56.6133],
            [22.7375, 56.5663],
            [22.7439, 56.5423],
            [22.7225, 56.521],
            [22.726, 56.4978],
            [22.7039, 56.4816],
            [22.74, 56.4604],
            [22.7111, 56.4394],
            [22.7182, 56.4247],
            [22.7873, 56.4211],
            [22.8249, 56.4047],
            [22.9208, 56.3983],
            [22.9342, 56.3879],
            [22.8581, 56.3657],
            [22.7279, 56.3702],
            [22.6806, 56.3503],
            [22.6149, 56.3815],
            [22.4917, 56.3959],
            [22.4265, 56.409],
            [22.3501, 56.3933],
            [22.3132, 56.4074],
            [22.2431, 56.4012],
            [22.2068, 56.4231],
            [22.1652, 56.4188],
            [22.126, 56.4312],
            [22.0799, 56.4216],
            [22.0165, 56.423],
            [21.9831, 56.3954],
            [21.9486, 56.4214],
            [22.0101, 56.4499],
            [21.9714, 56.4599],
            [21.9482, 56.491],
            [21.9671, 56.5067],
            [22.0178, 56.5104],
            [22.0271, 56.5324],
            [22.012, 56.5565],
            [22.1304, 56.5693],
            [22.1075, 56.6141],
            [22.1192, 56.6446],
            [22.0978, 56.6672],
            [22.1084, 56.6845],
            [22.1654, 56.6997],
            [22.189, 56.7383],
            [22.169, 56.7971],
            [22.2108, 56.7937],
            [22.252, 56.8307],
            [22.3048, 56.8332],
            [22.3533, 56.8608],
            [22.3299, 56.8843],
            [22.2675, 56.8878],
            [22.2995, 56.9332],
            [22.3459, 56.9246],
            [22.3543, 56.8997],
            [22.4275, 56.889],
            [22.4495, 56.9196],
            [22.4785, 56.9289],
            [22.5355, 56.9234],
            [22.5098, 56.9034],
            [22.5154, 56.8749],
            [22.493, 56.8562],
          ],
        ],
      },
      properties: {name: 'Saldus novads', id: 'LV-088', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-088',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.4026, 57.3498],
            [24.4465, 57.295],
            [24.4822, 57.2757],
            [24.4816, 57.2435],
            [24.437, 57.2328],
            [24.3812, 57.1889],
            [24.3503, 57.19],
            [24.3398, 57.1948],
            [24.3924, 57.2359],
            [24.4117, 57.2826],
            [24.4026, 57.3498],
          ],
        ],
      },
      properties: {name: 'Saulkrastu novads', id: 'LV-089', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-089',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.4465, 57.295],
            [24.5411, 57.3182],
            [24.5709, 57.3159],
            [24.5748, 57.2874],
            [24.625, 57.2623],
            [24.6351, 57.2334],
            [24.6794, 57.2212],
            [24.6224, 57.206],
            [24.6224, 57.1805],
            [24.6771, 57.1753],
            [24.6693, 57.1317],
            [24.5749, 57.1433],
            [24.5564, 57.123],
            [24.5067, 57.113],
            [24.4677, 57.181],
            [24.3812, 57.1889],
            [24.437, 57.2328],
            [24.4816, 57.2435],
            [24.4822, 57.2757],
            [24.4465, 57.295],
          ],
        ],
      },
      properties: {name: 'Sējas novads', id: 'LV-090', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-090',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.903, 57.2253],
            [24.9464, 57.2133],
            [24.9774, 57.1857],
            [24.9782, 57.1426],
            [25.008, 57.1269],
            [25.075, 57.1308],
            [25.09, 57.1219],
            [25.142, 57.0846],
            [25.1064, 57.071],
            [25.132, 57.0434],
            [25.132, 57.0065],
            [25.0708, 57.0216],
            [25.0338, 57.0494],
            [24.9855, 57.059],
            [24.9831, 57.0828],
            [24.8954, 57.0725],
            [24.8775, 57.0387],
            [24.8954, 57.0135],
            [24.8195, 56.9832],
            [24.781, 57.0015],
            [24.7103, 57.0002],
            [24.6616, 57.018],
            [24.6372, 57.0637],
            [24.6926, 57.059],
            [24.7696, 57.0812],
            [24.7888, 57.1366],
            [24.8208, 57.1801],
            [24.8898, 57.1947],
            [24.9029, 57.2251],
            [24.903, 57.2253],
          ],
        ],
      },
      properties: {name: 'Siguldas novads', id: 'LV-091', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-091',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.1321, 56.6243],
            [25.0897, 56.6294],
            [25.0519, 56.6104],
            [25.0415, 56.6096],
            [25.0416, 56.6348],
            [25.0136, 56.6423],
            [25.0231, 56.6924],
            [25.0677, 56.7032],
            [25.0439, 56.7372],
            [25.0998, 56.7395],
            [25.1911, 56.7019],
            [25.1887, 56.652],
            [25.1378, 56.6399],
            [25.1321, 56.6243],
          ],
        ],
      },
      properties: {name: 'Skrīveru novads', id: 'LV-092', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-092',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.797, 56.572],
            [21.7748, 56.6142],
            [21.8375, 56.6368],
            [21.8283, 56.6875],
            [21.9179, 56.7235],
            [21.9688, 56.7308],
            [21.9393, 56.7886],
            [21.9574, 56.811],
            [22.0058, 56.8298],
            [22.0928, 56.8272],
            [22.1118, 56.8069],
            [22.169, 56.7971],
            [22.189, 56.7383],
            [22.1654, 56.6997],
            [22.1084, 56.6845],
            [22.0978, 56.6672],
            [22.1192, 56.6446],
            [22.1075, 56.6141],
            [22.1304, 56.5693],
            [22.012, 56.5565],
            [22.0271, 56.5324],
            [22.0178, 56.5104],
            [21.9671, 56.5067],
            [21.9141, 56.509],
            [21.9054, 56.5283],
            [21.8462, 56.5243],
            [21.797, 56.572],
          ],
        ],
      },
      properties: {name: 'Skrundas novads', id: 'LV-093', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-093',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.9318, 57.5318],
            [25.9953, 57.5543],
            [26.1065, 57.5581],
            [26.1514, 57.5416],
            [26.1921, 57.5497],
            [26.2249, 57.5132],
            [26.2998, 57.5236],
            [26.3105, 57.5048],
            [26.3139, 57.4519],
            [26.3261, 57.4235],
            [26.2895, 57.3915],
            [26.2753, 57.3647],
            [26.276, 57.3303],
            [26.2526, 57.305],
            [26.2638, 57.2806],
            [26.1382, 57.2718],
            [26.0841, 57.2827],
            [26.0496, 57.2665],
            [26.0104, 57.2908],
            [25.9471, 57.2826],
            [25.7608, 57.2802],
            [25.7527, 57.2707],
            [25.726, 57.2731],
            [25.717, 57.2788],
            [25.7568, 57.3344],
            [25.7108, 57.3505],
            [25.6898, 57.3916],
            [25.7103, 57.4006],
            [25.6645, 57.442],
            [25.6572, 57.4626],
            [25.6946, 57.4869],
            [25.7811, 57.4925],
            [25.9018, 57.4883],
            [25.9418, 57.4939],
            [25.9318, 57.5318],
          ],
        ],
      },
      properties: {name: 'Smiltenes novads', id: 'LV-094', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-094',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.3635, 56.9636],
            [24.3756, 56.9379],
            [24.4241, 56.9414],
            [24.4345, 56.9234],
            [24.3714, 56.918],
            [24.2915, 56.925],
            [24.2537, 56.9184],
            [24.2701, 56.8751],
            [24.233, 56.9005],
            [24.233, 56.9367],
            [24.3221, 56.9706],
            [24.3635, 56.9636],
          ],
        ],
      },
      properties: {name: 'Stopiņu novads', id: 'LV-095', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-095',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.7811, 57.4925],
            [25.7868, 57.5107],
            [25.7364, 57.5345],
            [25.7706, 57.5626],
            [25.6813, 57.6154],
            [25.5838, 57.6061],
            [25.5134, 57.6221],
            [25.5264, 57.655],
            [25.6115, 57.659],
            [25.6044, 57.6851],
            [25.6276, 57.7083],
            [25.681, 57.7083],
            [25.6953, 57.7264],
            [25.7217, 57.7221],
            [25.8163, 57.7437],
            [25.8182, 57.7091],
            [25.8543, 57.6865],
            [25.9784, 57.6683],
            [25.9132, 57.5668],
            [25.8762, 57.544],
            [25.8914, 57.5238],
            [25.9318, 57.5318],
            [25.9418, 57.4939],
            [25.9018, 57.4883],
            [25.7811, 57.4925],
          ],
        ],
      },
      properties: {name: 'Strenču novads', id: 'LV-096', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-096',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.0855, 57.236],
            [23.0429, 57.2017],
            [22.9897, 57.1942],
            [22.9471, 57.1583],
            [22.9304, 57.1314],
            [22.836, 57.1425],
            [22.7065, 57.1025],
            [22.6358, 57.0965],
            [22.633, 57.0688],
            [22.6506, 57.0333],
            [22.6391, 57.0147],
            [22.5621, 57.0235],
            [22.5532, 57.0049],
            [22.5035, 57.0169],
            [22.4816, 57.0209],
            [22.384, 57.012],
            [22.3599, 57.0814],
            [22.3571, 57.1131],
            [22.2708, 57.1294],
            [22.2469, 57.1521],
            [22.2508, 57.1737],
            [22.2088, 57.1884],
            [22.1797, 57.1978],
            [22.2283, 57.2204],
            [22.2359, 57.2472],
            [22.2673, 57.267],
            [22.3087, 57.2689],
            [22.2766, 57.3809],
            [22.2459, 57.4062],
            [22.2413, 57.4242],
            [22.2926, 57.4376],
            [22.3344, 57.4258],
            [22.343, 57.4022],
            [22.4057, 57.4212],
            [22.4618, 57.4191],
            [22.4799, 57.4622],
            [22.5084, 57.4724],
            [22.5227, 57.5052],
            [22.5607, 57.496],
            [22.6099, 57.5108],
            [22.6533, 57.5093],
            [22.6942, 57.475],
            [22.794, 57.4325],
            [22.814, 57.4063],
            [22.8739, 57.3755],
            [22.9262, 57.3842],
            [22.9224, 57.4109],
            [22.9927, 57.3924],
            [22.9708, 57.3662],
            [22.8976, 57.3478],
            [22.9176, 57.319],
            [22.9623, 57.2953],
            [23.0174, 57.3061],
            [23.0536, 57.2891],
            [23.0364, 57.2562],
            [23.0855, 57.236],
          ],
        ],
      },
      properties: {name: 'Talsu novads', id: 'LV-097', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-097',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.4119, 56.4922],
            [23.4388, 56.4274],
            [23.4716, 56.4041],
            [23.4373, 56.3831],
            [23.3891, 56.3762],
            [23.3118, 56.3819],
            [23.2806, 56.37],
            [23.1817, 56.3685],
            [23.1564, 56.3955],
            [23.1208, 56.4117],
            [23.1564, 56.4394],
            [23.1514, 56.444],
            [23.1733, 56.4583],
            [23.231, 56.4674],
            [23.2909, 56.4551],
            [23.2795, 56.4997],
            [23.3758, 56.5506],
            [23.4119, 56.4922],
          ],
        ],
      },
      properties: {name: 'Tērvetes novads', id: 'LV-098', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-098',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.4723, 56.9384],
            [23.4877, 56.9312],
            [23.5008, 56.8978],
            [23.4684, 56.8843],
            [23.4894, 56.8153],
            [23.4523, 56.7984],
            [23.3877, 56.7931],
            [23.3785, 56.8075],
            [23.31, 56.7717],
            [23.3468, 56.752],
            [23.2788, 56.7114],
            [23.1991, 56.7253],
            [23.1615, 56.6944],
            [23.1196, 56.7059],
            [23.0792, 56.7421],
            [23.0501, 56.7907],
            [23.0216, 56.8041],
            [22.9634, 56.7973],
            [22.9206, 56.8051],
            [22.8867, 56.8269],
            [22.9338, 56.8559],
            [22.9082, 56.9239],
            [22.8589, 56.9239],
            [22.8227, 56.9469],
            [22.8096, 56.9739],
            [22.8256, 57.0079],
            [22.8595, 57.0092],
            [22.866, 57.0323],
            [22.8333, 57.0426],
            [22.8482, 57.085],
            [22.8827, 57.0873],
            [22.9171, 57.1064],
            [22.9304, 57.1314],
            [22.9471, 57.1583],
            [22.9897, 57.1942],
            [23.0429, 57.2017],
            [23.0855, 57.236],
            [23.1179, 57.2509],
            [23.1234, 57.2133],
            [23.1091, 57.1689],
            [23.0668, 57.1591],
            [23.1291, 57.1273],
            [23.15, 57.1322],
            [23.2042, 57.1087],
            [23.2313, 57.0732],
            [23.2118, 57.0375],
            [23.1804, 57.0127],
            [23.1386, 56.9278],
            [23.2051, 56.9376],
            [23.2441, 56.9252],
            [23.2835, 56.9288],
            [23.3686, 56.9197],
            [23.4723, 56.9384],
          ],
        ],
      },
      properties: {name: 'Tukuma novads', id: 'LV-099', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-099',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.753, 56.3331],
            [21.7297, 56.3664],
            [21.7333, 56.3938],
            [21.709, 56.4143],
            [21.6848, 56.4593],
            [21.6969, 56.4747],
            [21.744, 56.4846],
            [21.7219, 56.5173],
            [21.7157, 56.5572],
            [21.797, 56.572],
            [21.8462, 56.5243],
            [21.9054, 56.5283],
            [21.9141, 56.509],
            [21.9671, 56.5067],
            [21.9482, 56.491],
            [21.9714, 56.4599],
            [22.0101, 56.4499],
            [21.9486, 56.4214],
            [21.9831, 56.3954],
            [21.9197, 56.3663],
            [21.8703, 56.3583],
            [21.8366, 56.3698],
            [21.753, 56.3331],
          ],
        ],
      },
      properties: {name: 'Vaiņodes novads', id: 'LV-100', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-100',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.3657, 57.5809],
            [26.3348, 57.5516],
            [26.3461, 57.5094],
            [26.3105, 57.5048],
            [26.2998, 57.5236],
            [26.2249, 57.5132],
            [26.1921, 57.5497],
            [26.1514, 57.5416],
            [26.1065, 57.5581],
            [25.9953, 57.5543],
            [25.9318, 57.5318],
            [25.8914, 57.5238],
            [25.8762, 57.544],
            [25.9132, 57.5668],
            [25.9784, 57.6683],
            [25.8543, 57.6865],
            [25.8182, 57.7091],
            [25.8163, 57.7437],
            [25.7217, 57.7221],
            [25.6953, 57.7264],
            [25.684, 57.7366],
            [25.5872, 57.7697],
            [25.5301, 57.7795],
            [25.5527, 57.808],
            [25.476, 57.8483],
            [25.528, 57.8731],
            [25.5688, 57.8756],
            [25.6291, 57.8501],
            [25.6672, 57.8513],
            [25.6932, 57.8799],
            [25.6939, 57.9036],
            [25.738, 57.9226],
            [25.7791, 57.9116],
            [25.8186, 57.8639],
            [25.9632, 57.8455],
            [26.0018, 57.8601],
            [26.0568, 57.848],
            [26.027, 57.7749],
            [26.1365, 57.7494],
            [26.1456, 57.7315],
            [26.2022, 57.7134],
            [26.1827, 57.6875],
            [26.242, 57.6584],
            [26.2476, 57.6191],
            [26.2713, 57.6058],
            [26.3657, 57.5809],
          ],
        ],
      },
      properties: {name: 'Valkas novads', id: 'LV-101', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-101',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.3797, 56.6444],
            [26.3817, 56.6721],
            [26.5136, 56.6658],
            [26.6377, 56.7067],
            [26.7047, 56.707],
            [26.7047, 56.6902],
            [26.7695, 56.678],
            [26.7746, 56.6756],
            [26.787, 56.6456],
            [26.7696, 56.6263],
            [26.7979, 56.6032],
            [26.7367, 56.5936],
            [26.724, 56.5762],
            [26.7491, 56.5595],
            [26.7427, 56.545],
            [26.6253, 56.5386],
            [26.594, 56.5752],
            [26.5959, 56.5958],
            [26.4865, 56.596],
            [26.3797, 56.6444],
          ],
        ],
      },
      properties: {name: 'Varakļānu novads', id: 'LV-102', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-102',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.338, 56.1818],
            [26.32, 56.2009],
            [26.3, 56.2947],
            [26.3675, 56.278],
            [26.3704, 56.3145],
            [26.3922, 56.3211],
            [26.4246, 56.278],
            [26.5016, 56.278],
            [26.579, 56.2632],
            [26.6135, 56.2836],
            [26.6606, 56.2828],
            [26.6972, 56.2463],
            [26.6791, 56.2177],
            [26.682, 56.1764],
            [26.6116, 56.1482],
            [26.6539, 56.13],
            [26.6238, 56.123],
            [26.5541, 56.1528],
            [26.5508, 56.1239],
            [26.5061, 56.1295],
            [26.5014, 56.1654],
            [26.4603, 56.1895],
            [26.4239, 56.1734],
            [26.3873, 56.1965],
            [26.338, 56.1818],
          ],
        ],
      },
      properties: {name: 'Vārkavas novads', id: 'LV-103', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-103',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.4784, 56.9926],
            [25.4961, 57.0156],
            [25.613, 57.0659],
            [25.6135, 57.0931],
            [25.5801, 57.122],
            [25.5252, 57.1278],
            [25.5617, 57.1537],
            [25.5795, 57.1873],
            [25.5557, 57.2256],
            [25.6021, 57.252],
            [25.6043, 57.2705],
            [25.6461, 57.277],
            [25.7362, 57.2722],
            [25.7527, 57.2707],
            [25.7414, 57.2229],
            [25.7683, 57.2071],
            [25.7578, 57.1773],
            [25.7785, 57.1637],
            [25.7757, 57.1385],
            [25.8384, 57.1296],
            [25.8755, 57.0997],
            [25.9183, 57.0959],
            [25.8999, 57.0712],
            [25.882, 57.011],
            [25.9249, 57.0019],
            [25.9242, 56.9728],
            [25.8891, 56.9611],
            [25.8883, 56.9605],
            [25.8448, 56.9581],
            [25.8227, 56.9834],
            [25.7048, 56.9841],
            [25.6367, 56.958],
            [25.6032, 56.9821],
            [25.4881, 56.9939],
            [25.4784, 56.9926],
          ],
        ],
      },
      properties: {
        name: 'Vecpiebalgas novads',
        id: 'LV-104',
        CNTRY: 'Latvia',
        TYPE: 'Municipality',
      },
      id: 'LV-104',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.5288, 56.7173],
            [24.5326, 56.7092],
            [24.6327, 56.7125],
            [24.6382, 56.6752],
            [24.5857, 56.6738],
            [24.5942, 56.6274],
            [24.6174, 56.6029],
            [24.6687, 56.6028],
            [24.6965, 56.5859],
            [24.6626, 56.5694],
            [24.6752, 56.5479],
            [24.7339, 56.5456],
            [24.8404, 56.576],
            [24.9694, 56.5809],
            [24.9777, 56.5616],
            [25.0217, 56.5455],
            [25.0258, 56.507],
            [24.9337, 56.4962],
            [24.8886, 56.4475],
            [24.8599, 56.4334],
            [24.8625, 56.413],
            [24.7075, 56.3992],
            [24.6927, 56.3737],
            [24.6316, 56.3746],
            [24.6287, 56.3473],
            [24.5775, 56.3207],
            [24.5159, 56.3197],
            [24.4712, 56.344],
            [24.514, 56.3728],
            [24.4584, 56.3891],
            [24.5282, 56.4076],
            [24.5392, 56.4337],
            [24.524, 56.4648],
            [24.4907, 56.4966],
            [24.4683, 56.4858],
            [24.4327, 56.5058],
            [24.3978, 56.5393],
            [24.3978, 56.5629],
            [24.3294, 56.5734],
            [24.3246, 56.6248],
            [24.3826, 56.6248],
            [24.3686, 56.6624],
            [24.3774, 56.6756],
            [24.4362, 56.6832],
            [24.4952, 56.7166],
            [24.5288, 56.7173],
          ],
        ],
      },
      properties: {name: 'Vecumnieku novads', id: 'LV-105', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-105',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.5199, 57.3814],
            [21.5533, 57.3558],
            [21.6139, 57.3905],
            [21.6709, 57.4036],
            [21.6537, 57.4603],
            [21.6272, 57.4737],
            [21.6617, 57.5076],
            [21.6868, 57.5577],
            [21.7163, 57.5746],
            [21.8381, 57.5976],
            [21.9694, 57.6013],
            [22.0285, 57.6096],
            [22.1966, 57.6563],
            [22.2587, 57.6054],
            [22.2441, 57.585],
            [22.2701, 57.5548],
            [22.2815, 57.5057],
            [22.1748, 57.4736],
            [22.1747, 57.4484],
            [22.0777, 57.4225],
            [22.1367, 57.4009],
            [22.2053, 57.3955],
            [22.2459, 57.4062],
            [22.2766, 57.3809],
            [22.3087, 57.2689],
            [22.2673, 57.267],
            [22.2359, 57.2472],
            [22.2283, 57.2204],
            [22.1797, 57.1978],
            [22.2088, 57.1884],
            [22.1796, 57.1157],
            [22.0666, 57.1007],
            [22.0212, 57.1096],
            [21.9052, 57.0949],
            [21.8452, 57.1038],
            [21.826, 57.0804],
            [21.7809, 57.0888],
            [21.7049, 57.1241],
            [21.596, 57.0861],
            [21.5474, 57.0758],
            [21.5063, 57.079],
            [21.4652, 57.0283],
            [21.4747, 56.9722],
            [21.4577, 56.9469],
            [21.4009, 56.9469],
            [21.3952, 56.9627],
            [21.328, 56.9736],
            [21.3712, 57.001],
            [21.4135, 57.0655],
            [21.4152, 57.1163],
            [21.4057, 57.1758],
            [21.4143, 57.2139],
            [21.4106, 57.2706],
            [21.4274, 57.303],
            [21.4929, 57.3528],
            [21.5199, 57.3814],
          ],
        ],
      },
      properties: {name: 'Ventspils novads', id: 'LV-106', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-106',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.6271, 56.1338],
            [25.5694, 56.1386],
            [25.5578, 56.1593],
            [25.5153, 56.1584],
            [25.4799, 56.1419],
            [25.4069, 56.156],
            [25.363, 56.152],
            [25.4144, 56.1606],
            [25.388, 56.2122],
            [25.4015, 56.227],
            [25.3795, 56.2579],
            [25.3056, 56.2892],
            [25.3213, 56.3122],
            [25.2978, 56.3636],
            [25.3002, 56.4086],
            [25.3249, 56.4198],
            [25.3887, 56.4071],
            [25.4687, 56.4106],
            [25.5184, 56.4491],
            [25.5541, 56.4428],
            [25.5973, 56.4686],
            [25.6036, 56.4353],
            [25.6261, 56.3998],
            [25.6576, 56.4005],
            [25.6784, 56.3748],
            [25.7254, 56.3748],
            [25.7343, 56.3593],
            [25.726, 56.3011],
            [25.6671, 56.2895],
            [25.6838, 56.2369],
            [25.6778, 56.2075],
            [25.6357, 56.1664],
            [25.6271, 56.1338],
          ],
        ],
      },
      properties: {name: 'Viesītes novads', id: 'LV-107', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-107',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.7171, 57.0101],
            [27.678, 56.9999],
            [27.6431, 57.0049],
            [27.6331, 57.0209],
            [27.5518, 57.0295],
            [27.5318, 57.0629],
            [27.4156, 57.1316],
            [27.437, 57.1707],
            [27.4406, 57.2041],
            [27.4876, 57.2377],
            [27.5361, 57.2474],
            [27.5486, 57.2633],
            [27.5877, 57.2794],
            [27.591, 57.305],
            [27.6389, 57.3093],
            [27.6937, 57.331],
            [27.6923, 57.3697],
            [27.7797, 57.344],
            [27.7859, 57.3211],
            [27.8303, 57.3229],
            [27.8687, 57.2921],
            [27.87, 57.2493],
            [27.8275, 57.1921],
            [27.8338, 57.1677],
            [27.8181, 57.145],
            [27.7503, 57.1266],
            [27.7406, 57.1038],
            [27.7493, 57.0535],
            [27.7171, 57.0101],
          ],
        ],
      },
      properties: {name: 'Viļakas novads', id: 'LV-108', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-108',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.9518, 56.4566],
            [26.956, 56.4761],
            [26.8569, 56.4824],
            [26.8473, 56.4996],
            [26.8065, 56.4996],
            [26.8199, 56.5388],
            [26.7491, 56.5595],
            [26.724, 56.5762],
            [26.7367, 56.5936],
            [26.7979, 56.6032],
            [26.7696, 56.6263],
            [26.787, 56.6456],
            [26.7746, 56.6756],
            [26.831, 56.677],
            [26.847, 56.6623],
            [26.8886, 56.6646],
            [26.9617, 56.5951],
            [27.0099, 56.5918],
            [27.0318, 56.5767],
            [27.0223, 56.5423],
            [27.0491, 56.4884],
            [27.0782, 56.4749],
            [27.0509, 56.4476],
            [27.011, 56.4535],
            [26.9718, 56.4433],
            [26.9518, 56.4566],
          ],
        ],
      },
      properties: {name: 'Viļānu novads', id: 'LV-109', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-109',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.0922, 56.2024],
            [28.1332, 56.2485],
            [28.094, 56.2766],
            [28.0286, 56.3066],
            [27.9823, 56.2895],
            [27.9413, 56.2858],
            [27.9478, 56.264],
            [27.8836, 56.2597],
            [27.8605, 56.2934],
            [27.9656, 56.3527],
            [28.0274, 56.3662],
            [28.0019, 56.4038],
            [28.0548, 56.4101],
            [28.0898, 56.3887],
            [28.132, 56.4015],
            [28.1809, 56.397],
            [28.1664, 56.3764],
            [28.1925, 56.36],
            [28.208, 56.3224],
            [28.2429, 56.2741],
            [28.1872, 56.2286],
            [28.2034, 56.2191],
            [28.178, 56.1807],
            [28.1382, 56.1722],
            [28.0654, 56.1333],
            [28.0499, 56.1457],
            [28.1045, 56.19],
            [28.0905, 56.2006],
            [28.0922, 56.2024],
          ],
        ],
      },
      properties: {name: 'Zilupes novads', id: 'LV-110', CNTRY: 'Latvia', TYPE: 'Municipality'},
      id: 'LV-110',
    },
  ],
}
export default map
