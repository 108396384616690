import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

type Props = {
  fileData: string[]
  url: string
}

const AttachmentItem: React.FC<Props> = ({fileData, url}) => {
  const [logoPath, setLogoPath] = useState<string>('/media/svg/files/unknow.svg')

  useEffect(() => {
    if (fileData[0].toLowerCase() === 'pdf') {
      setLogoPath('/media/svg/files/pdf.svg')
    } else if (fileData[0].toLowerCase() === 'png') {
      setLogoPath('/media/svg/files/png.svg')
    } else if (
      fileData[0].toLowerCase() === 'doc' ||
      fileData[0].toLowerCase() === 'docx' ||
      fileData[0].toLowerCase() === 'ods'
    ) {
      setLogoPath('/media/svg/files/doc.svg')
    } else if (
      fileData[0].toLowerCase() === 'csv' ||
      fileData[0].toLowerCase() === 'xls' ||
      fileData[0].toLowerCase() === 'xlsx'
    ) {
      setLogoPath('/media/svg/files/csv.svg')
    } else if (fileData[0].toLowerCase() === 'jpg' || fileData[0].toLowerCase() === 'jpeg') {
      setLogoPath('/media/svg/files/jpg.svg')
    } else {
      setLogoPath('/media/svg/files/unknow.svg')
    }
  }, [fileData])

  return (
    <div className='card h-100'>
      <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
        <a
          href={url}
          download
          target='_blank'
          rel='noreferrer'
          className='text-gray-800 text-hover-primary d-flex flex-column'
        >
          <div className='symbol symbol-75px mb-6'>
            <img src={toAbsoluteUrl(logoPath)} alt='file-type' />
          </div>
          <div className='fs-5 fw-bolder mb-2'>{fileData[1]}</div>
        </a>
      </div>
    </div>
  )
}

export default AttachmentItem
