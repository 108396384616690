import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import {useIntl} from "react-intl";


const GA4ForSeoTitle: React.FC = () => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails


    return (
        <>
            <div className='row'>
                <div className='px-10'>
                    <div className='d-flex justify-content-between'>
                        <h3 className='fw-bolder text-dark'>
                            {intl.formatMessage({id: 'SEO_REPORT_TITLE'})}{' '}
                            {reportData.details.GA4config && reportData.details.GA4config.date_text && (
                                <>
                                    {(reportData.details.GA4config.date_text)}
                                </>
                            )}
                        </h3>
                    </div>
                    <div className='text-gray-400 fw-bold fs-6'>
                        {/*{intl.formatMessage({id: 'SEO_REPORT_SUBTITLE'})}*/}
                    </div>
                </div>
            </div>
        </>
    )

}


export default GA4ForSeoTitle
