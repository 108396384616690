import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import {useIntl} from "react-intl";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {numSpaceFormater} from "../../../../../helpers/formater";
import ChartDonutWidget from "../../Widgets/ChartDonutWidget";
import {Ga4deviceDaum} from "../../../models/AbstractReportDetails";

export interface GA4deviceDataInt {
    Devices : Ga4deviceDaum[]
}
export interface CircleChartGA4GADS {
    revenueChart: number[]
    averagePurchaseRevenue: number[]
    averageRevenuePerUser: number[]
    totalUsers: number[]
    sectorName: string[]
}

const GA4ForSeoDevice: React.FC = () => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails

    const [circleCharte, setCircleCharte] = useState<CircleChartGA4GADS>()


    useEffect(() => {
        if(reportData.details.GA4deviceData){

            let chartDatae: CircleChartGA4GADS = {
                revenueChart: [],
                averagePurchaseRevenue: [],
                averageRevenuePerUser: [],
                totalUsers: [],
                sectorName: [],
            }
            reportData.details.GA4deviceData.map((item)=> {

                    chartDatae.revenueChart.push(Number(item.totalRevenue))
                    chartDatae.averagePurchaseRevenue.push(Number(item.averagePurchaseRevenue))
                    chartDatae.averageRevenuePerUser.push(Number(item.averageRevenuePerUser))
                    chartDatae.totalUsers.push(Number(item.totalUsers))
                    chartDatae.sectorName.push(item.deviceCategory)
                    setCircleCharte(chartDatae)
            })
        }

    },[reportData]);


    return (
        <>
            {reportData && reportData.details.GA4deviceData && reportData.details.GA4deviceData.length > 0 ? (
            <div className='px-5 py-5 mb-5'>
                <div className='row'>
                    <div className='pb-5'>
                        <h3 className='fw-boldest text-sempai-green text-uppercase'>
                            {intl.formatMessage({id: 'DEVICES'})}
                        </h3>
                    </div>
                </div>
                    <div
                        className='device-data d-flex justify-content-between flex-column flex-lg-row'
                        data-testid='devices-data'
                    >
                        {reportData.details.GA4deviceData.map((item) => (
                            <div className='d-flex justify-content-xl-start justify-content-center'>
                                <div className='w-100 d-flex justify-content-xl-center justify-content-around'>
                                    <div className='d-flex justify-content-center align-items-center pe-10'>
                                        {item.deviceCategory === 'mobile' && (
                                            <KTSVG
                                                path='/media/sempai-images/icons/Icon-feather-phone.svg'
                                                className='svg-icon-5x text-sempai-green svg-icon-white'
                                                svgClassName='mh-100px'
                                            />
                                        )}
                                        {item.deviceCategory === 'tablet' && (
                                            <KTSVG
                                                path='/media/sempai-images/icons/Icon-feather-tablet.svg'
                                                className='svg-icon-5x text-sempai-green svg-icon-white'
                                                svgClassName='mh-100px'
                                            />
                                        )}
                                        {item.deviceCategory === 'desktop' && (
                                            <KTSVG
                                                path='/media/sempai-images/icons/Icon-feather-monitor.svg'
                                                className='svg-icon-5x svg-icon-sempai-green'
                                                svgClassName='mh-100px'
                                            />
                                        )}
                                        {item.deviceCategory === 'smart tv' && (
                                            <KTSVG
                                                path='/media/sempai-images/icons/Icon-feather-tv.svg'
                                                className='svg-icon-5x svg-icon-sempai-green'
                                                svgClassName='mh-100px'
                                            />
                                        )}
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='mb-1'>
                                              <span className='text-dark fs-7 fw-bold pe-3'>
                                                {intl.formatMessage({id: 'SESSIONS'})}:
                                              </span>
                                            <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.sessions)}</span>
                                        </div>
                                        <div className=''>
                                              <span className='text-dark fs-7 fw-bold pe-3'>
                                                {intl.formatMessage({id: 'USERS'})}:
                                              </span>
                                            <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.totalUsers).toFixed(2))}</span>
                                        </div>
                                        <div className=''>
                                              <span className='text-dark fs-7 fw-bold pe-3'>
                                                {intl.formatMessage({id: 'CONVERSIONS'})}:
                                              </span>
                                            <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.conversions).toFixed(2))}</span>
                                        </div>
                                        <div className=''>
                                              <span className='text-dark fs-7 fw-bold pe-3'>
                                                {intl.formatMessage({id: 'CONVERSION_VALUE'})}:
                                              </span>
                                            <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.eventValue).toFixed(2))} zł</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


            </div>
                ):(
                <></>
            )}



            <div className='row g-3 device-charts d-flex'>
                {circleCharte && circleCharte.revenueChart && (
                    <div className='col-3' data-testid='revenue-donut-chart'>
                        <div className=''>
                            <div className='pb-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                                    {intl.formatMessage({id: 'SESSIONS'})}
                                </h3>
                            </div>
                            <ChartDonutWidget
                                keytabchart={2}
                                className='card-xxl-stretch-50 mb-5'
                                chartHeight='350px'
                                sectorsValue={circleCharte.averagePurchaseRevenue}
                                sectorsName={circleCharte.sectorName}
                            />
                        </div>
                    </div>
                )}
                {circleCharte && circleCharte.totalUsers && (
                    <div className='col-3' data-testid='revenue-donut-chart'>
                        <div className=''>
                            <div className='pb-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                                    {intl.formatMessage({id: 'USERS'})}

                                </h3>
                            </div>
                            <ChartDonutWidget
                                keytabchart={5}
                                className='card-xxl-stretch-50 mb-5'
                                chartHeight='350px'
                                sectorsValue={circleCharte.totalUsers}
                                sectorsName={circleCharte.sectorName}
                            />
                        </div>
                    </div>
                )}
                {circleCharte && circleCharte.averagePurchaseRevenue && (
                    <div className='col-3' data-testid='revenue-donut-chart'>
                        <div className=''>
                            <div className='pb-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                                    {intl.formatMessage({id: 'CONVERSIONS'})}
                                </h3>
                            </div>
                            <ChartDonutWidget
                                keytabchart={3}
                                className='card-xxl-stretch-50 mb-5'
                                chartHeight='350px'
                                sectorsValue={circleCharte.averagePurchaseRevenue}
                                sectorsName={circleCharte.sectorName}
                            />
                        </div>
                    </div>
                )}
                {circleCharte && circleCharte.averageRevenuePerUser && (
                    <div className='col-3' data-testid='revenue-donut-chart'>
                        <div className=''>
                            <div className='pb-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                                    {intl.formatMessage({id: 'CONVERSION_VALUE'})}
                                </h3>
                            </div>
                            <ChartDonutWidget
                                keytabchart={4}
                                className='card-xxl-stretch-50 mb-5'
                                chartHeight='350px'
                                sectorsValue={circleCharte.averageRevenuePerUser}
                                sectorsName={circleCharte.sectorName}
                            />
                        </div>
                    </div>
                )}

            </div>

            <div className='row g-3 device-charts d-flex justify-content-between'>
            </div>

            <div className='row pt-5'>
            </div>
            <div className='page-slice'></div>
        </>
    )

}


export default GA4ForSeoDevice
