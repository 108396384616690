import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import React, {useEffect, useState} from "react";
import AllegroDataWidget from "./AllegroDataWidget";

const AllegroSponsoredSum = () => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [shouldShow, setShouldShow] = useState(false)
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        setCurrency(reportData.details.allegroDaily.campaignInformation.campaigns_currency);
        if(reportData.details.allegroDaily.sponsoredSum.clicks != 0 || reportData.details.allegroDaily.sponsoredSum.totalAttributionValue != 0
            || reportData.details.allegroDaily.sponsoredSum.totalCost != 0 || reportData.details.allegroDaily.sponsoredSum.views != 0) {
            setShouldShow(true);
        }
    }, [reportData]);

    return shouldShow ? (
        <>
            <AllegroDataWidget
                data={reportData.details.allegroDaily.sponsoredSum}
                cardLabel="ALLEGRO_SPONSORED_SUM_TITLE"
                currency={currency}
            />
        </>
    ) : <></>
}

export default AllegroSponsoredSum