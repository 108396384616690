import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {ClicksToRevenuePerDate} from '../../../models/Reports'
import {SEOReportDeatils} from '../../../models/SEOReport'
import {selectors} from '../../../redux/ReportsRedux'
import ChartLinesWidget from '../../Widgets/ChartLinesWidget'

interface SessionGroupProps {}

const SessionGroup: React.FC<SessionGroupProps> = () => {
  const intl = useIntl()
  const {analyticSessions, analyticDataMonth} = useSelector(
    selectors.getReportDetails
  ) as SEOReportDeatils
  const [clicksToRevenueChart, setClicksToRevenueChart] = useState<ClicksToRevenuePerDate>()

  useEffect(() => {
    if (analyticSessions) {
      setClicksToRevenueChart({
        clicks: analyticSessions.sessions,
        dates: analyticSessions.dates,
        revenue: analyticSessions.revenue,
      })
    }
  }, [analyticSessions])

  return (
    <>
      <div className='row g-5 g-xl-8'>
        {clicksToRevenueChart && (
          <ChartLinesWidget
            className='mb-xl-3'
            chartId='seo-SessionGroup'
            dateArray={clicksToRevenueChart.dates}
            firstDataSet={clicksToRevenueChart.clicks}
            secondDataSet={clicksToRevenueChart.revenue}
            firstDataSetTitle={intl.formatMessage({id: 'SESSIONS'})}
            secondDataSetTitle={intl.formatMessage({id: 'TRANSACTION_REVENUE'})}
          />
        )}
      </div>
      {analyticDataMonth && (
        <div className='row'>
          <div className='fs-5'>
            {`Na przestrzeni miesiąca użytkownicy z organicznego źródła wygenerowali ${Number(
              analyticDataMonth.transactions_revenue
            ).toFixed(1)} przychodów i
            ${Number(analyticDataMonth.sessions).toFixed(0)} ruchu w serwisie.`}
          </div>
        </div>
      )}
    </>
  )
}

export default SessionGroup
