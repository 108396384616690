import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../../redux/ReportsRedux';
import { useIntl } from 'react-intl';
import AdPreviewModal from "./AdPreviewModal";
import TikTokTopAdsRow from "./TikTokTopAdsRow";
import SortButton from "../../SortButton";
import { ad, TikTokReportDetails } from "../../../models/TikTokReport";

type SortKeys = any;
type SortOrder = "ascn" | "desc";

interface ITikTokTopAds {
    tabKey: string | number;
}

const TikTokTopAds: React.FC<ITikTokTopAds> = ({ tabKey }) => {
    const intl = useIntl();
    const details = useSelector(selectors.getReportDetails) as TikTokReportDetails;
    const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");
    const [sortKey, setSortKey] = useState<SortKeys>();
    const [showModal, setShowModal] = useState(false);
    const [modalUrl, setModalUrl] = useState('');
    const [tiktokAds, setTiktokAds] = useState<ad[]>([]);

    const handleAdClick = (adUrl: string) => {
        setModalUrl(adUrl);
        setShowModal(true);
    };

    const sortListByParam = (param: keyof ad, isAscending: string = 'ascn') => {
        if (tiktokAds) {
            const sortedList = [...tiktokAds];
            sortedList.sort((a, b) => (a[param] > b[param]) ? 1 : -1);
            if (isAscending !== 'ascn') {
                sortedList.reverse();
            }
            setTiktokAds(sortedList);
        }
    };

    useEffect(() => {
        if (details && details.top10ads) {
            setTiktokAds(details.top10ads);
        }
    }, [tabKey, details]);

    const changeSort = (key: SortKeys) => {
        const newSortOrder = sortOrder === "ascn" ? "desc" : "ascn";
        setSortOrder(newSortOrder);
        setSortKey(key);
        sortListByParam(key, newSortOrder);
    };

    return (
        <>
            {tiktokAds && tiktokAds.length > 0 && (
                <div className='card card-body overlay rounded'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>
                                {intl.formatMessage({ id: 'TOP_ADS_TITLE' })}
                            </span>
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                <thead>
                                <tr className='fw-bolder text-muted'>
                                    <th className='min-w-50px'>
                                        <div className='d-flex'>
                                            {intl.formatMessage({ id: 'PREVIEW' })}
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>
                                        <div className='d-flex align-items-center'>
                                            {intl.formatMessage({ id: 'AD_NAME' })}
                                            <SortButton
                                                columnKey={'ad_name'}
                                                onClick={() => changeSort('ad_name')}
                                                sortOrder={sortOrder}
                                                sortKey={sortKey}
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px text-center'>
                                        <div className='d-flex justify-content-center'>
                                            {intl.formatMessage({ id: 'CLICKS' })}
                                            <SortButton
                                                columnKey={'clicks'}
                                                onClick={() => changeSort('clicks')}
                                                sortOrder={sortOrder}
                                                sortKey={sortKey}
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px text-center'>
                                        <div className='d-flex justify-content-center'>
                                            {intl.formatMessage({ id: 'IMPRESSIONS' })}
                                            <SortButton
                                                columnKey={'impressions'}
                                                onClick={() => changeSort('impressions')}
                                                sortOrder={sortOrder}
                                                sortKey={sortKey}
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px text-center'>
                                        <div className='d-flex justify-content-center'>
                                            {intl.formatMessage({ id: 'TOTAL_COMPLETE_PAYMENT' })}
                                            <SortButton
                                                columnKey={'total_complete_payment'}
                                                onClick={() => changeSort('total_complete_payment')}
                                                sortOrder={sortOrder}
                                                sortKey={sortKey}
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px text-center'>
                                        <div className='d-flex justify-content-center'>
                                            {intl.formatMessage({ id: 'LIKES' })}
                                            <SortButton
                                                columnKey={'likes'}
                                                onClick={() => changeSort('likes')}
                                                sortOrder={sortOrder}
                                                sortKey={sortKey}
                                            />
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {tiktokAds.map((ad) => (
                                    <TikTokTopAdsRow key={ad.id} ad={ad} onAdClick={handleAdClick} />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
            <AdPreviewModal showModal={showModal} modalUrl={modalUrl} onHide={() => setShowModal(false)} />
        </>
    );
};

export default TikTokTopAds;
