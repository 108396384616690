import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../../redux/ReportsRedux';
import { IReportDetails } from '../../../models/Reports';
import { useIntl } from 'react-intl';
import { campaign } from "../../../models/AllegroReport";
import { numSpaceFormater } from "../../../../../helpers/formater";

const AllegroCampaigns = () => {
    const intl = useIntl();
    const reportData = useSelector(selectors.getReportData) as IReportDetails;
    const [campaigns, setCampaigns] = useState<campaign[]>([]);
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        setCurrency(reportData.details.allegroDaily.campaignInformation.campaigns_currency);
        if (reportData?.details?.allegroDaily?.campaigns) {
            setCampaigns(reportData.details.allegroDaily.campaigns);
        }
    }, [reportData]);

    return campaigns?.length > 0 ? (
        <div className='card overlay rounded'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                        {intl.formatMessage({ id: 'CAMPAIGN_LIST_HEADER' })}
                    </span>
                </h3>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                        <thead>
                        <tr className='fw-bolder text-muted'>
                            <th>{intl.formatMessage({ id: 'CAMPAIGN_NAME' })}</th>
                            <th className='text-center'>{intl.formatMessage({ id: 'INTERESTED' })}</th>
                            <th className='text-center'>{intl.formatMessage({ id: 'CLICKS' })}</th>
                            <th className='text-center'>{intl.formatMessage({ id: 'COST' })}</th>
                            <th className='text-center'>{intl.formatMessage({ id: 'VIEWS' })}</th>
                            <th className='text-center'>{intl.formatMessage({ id: 'INCOME' })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {campaigns.map((campaign, index) => (
                            <tr key={index}>
                                <td>{campaign.campain_name}</td>
                                <td className='text-center'>{campaign.total_interest}</td>
                                <td className='text-center'>{campaign.total_clicks}</td>
                                <td className='text-center'>{numSpaceFormater(campaign.total_cost.toFixed(2))} {currency}</td>
                                <td className='text-center'>{campaign.total_views}</td>
                                <td className='text-center'>{numSpaceFormater(campaign.total_attribution_value.toFixed(2))} {currency}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    ) : (
        <span></span>
    );
};

export default AllegroCampaigns;
