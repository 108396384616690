import {
  ActionWithPayload,
  IErrorResponse,
  RootState,
} from '../../../../../../setup/redux/RootReducer'
import {put, select, takeLatest} from 'redux-saga/effects'
import {updateProfile} from './ProfileCRUD'
import { UserModelPasswordEdit } from '../../../../auth/models/UserModel'

export const PUT_PROFILE_UPDATE_REQUEST = 'PUT_PROFILE_UPDATE_REQUEST'
export const PUT_PROFILE_UPDATE_SUCCESS = 'PUT_PROFILE_UPDATE_SUCCESS'
export const PUT_PROFILE_UPDATE_FAIL = 'PUT_PROFILE_UPDATE_FAIL'

export interface IProfileState {
  getUpdateData?: UserModelPasswordEdit
  profileError: boolean
  profileLoading: boolean
  profileIsUpdate: boolean
  profileUpdateErrorMessage?: string[][]
}

const initialProfileState: IProfileState = {
  getUpdateData: undefined,
  profileError: false,
  profileLoading: false,
  profileIsUpdate: false,
  profileUpdateErrorMessage: undefined,
}

export const reducer = (
  state: IProfileState = initialProfileState,
  action: ActionWithPayload<IProfileState>
) => {
  switch (action.type) {
    case PUT_PROFILE_UPDATE_REQUEST:
      const profile = action.payload?.getUpdateData
      return {
        ...state,
        getUpdateData: profile,
        profileLoading: true,
      }
    case PUT_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        profileIsUpdate: true,
      }
    case PUT_PROFILE_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.profileUpdateErrorMessage
      return {
        ...state,
        profileLoading: false,
        profileError: true,
        profileUpdateErrorMessage: errorUpdMessage,
      }
    default:
      return state
  }
}

export const actions = {
  updateProfileRequest: (userData: UserModelPasswordEdit) => ({
    type: PUT_PROFILE_UPDATE_REQUEST,
    payload: {getUpdateData: userData},
  }),

  updateProfileSuccess: () => ({
    type: PUT_PROFILE_UPDATE_SUCCESS,
  }),

  updateProfileFail: (err: any) => ({
    type: PUT_PROFILE_UPDATE_FAIL,
    payload: {profileUpdateErrorMessage: err},
  }),
}

export const selectors = {
  getProfile: (state: RootState) => state.profile.getUpdateData,
  getProfileState: (state: RootState) => state.profile,
}

export function* saga() {
  yield takeLatest(PUT_PROFILE_UPDATE_REQUEST, function* updateUserSaga() {
    let errorResponseData = {}
    const errorCallback = (usefulErrorData: any) => {
      errorResponseData = usefulErrorData
    }
    const profile: UserModelPasswordEdit = yield select(selectors.getProfile)
    const {res, err} = yield updateProfile(profile, errorCallback)
    if (res) {
      yield put(actions.updateProfileSuccess())
    } else if (err) {
      const errorArray: any = Object.values(errorResponseData)
      const errRes: IErrorResponse = errorArray[1]
      const errResArray = Object.values(errRes)
      yield put(actions.updateProfileFail(errResArray))
    }
  })
}
