import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {SEOReportDeatils} from '../../../models/SEOReport'
import {selectors} from '../../../redux/ReportsRedux'
import StatisticMap, {StatisticMapData} from '../../Widgets/StatisticMap'

interface SessionMapProps {}

const SessionMap: React.FC<SessionMapProps> = () => {
  const intl = useIntl()
  const details = useSelector(selectors.getReportDetails) as SEOReportDeatils
  const [regionsReport, setRegionsReport] = useState<StatisticMapData[]>()

  useEffect(() => {
    if (details && details.googleAnalyticRegions) {
      const regionData: StatisticMapData[] = details.googleAnalyticRegions.data.map((item) => ({
        id: item.voivodeship_iso,
        value: item.sessions as number,
      }))
      setRegionsReport(regionData)
    }
  }, [details])

  return (
    <div className='social-group-map'>
      {regionsReport && regionsReport.length > 0 && (
        <div className='border h-100'>
          <div className='px-5 py-5'>
            <h3 className='fw-boldest text-sempai-green text-uppercase mb-0'>
              {intl.formatMessage({id: 'GEOGRAPHY_SESSIONS'})}
            </h3>
          </div>
          <StatisticMap statisticData={regionsReport} />
        </div>
      )}
    </div>
  )
}

export default SessionMap
