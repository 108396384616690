import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {percentCalc} from '../../../../../helpers/formater'

interface TimeStatisticWidgetProps {
  label: string
  value: string | number
  currentValue: number | null
  prevValue: number | null
  type: string | null
}

const TimeStatisticWidget: React.FC<TimeStatisticWidgetProps> = ({
  label,
  value,
  currentValue,
  prevValue,
  type
}) => {
  const [changePercent, setChangePercet] = useState<number | string>()
  const [changeValue, setChangeValue] = useState<number | string>()

  useEffect(() => {
    if (currentValue && prevValue)
      setChangePercet(percentCalc(currentValue - prevValue, prevValue))
    else setChangePercet('-')
  }, [currentValue, prevValue])

  useEffect(() => {
    if (type === 'number' && currentValue && prevValue)
      setChangeValue(Number(currentValue - prevValue))
    else setChangeValue('-')
  }, [currentValue, prevValue])


  return (
    <div className='d-flex flex-column'>
      <div className='fs-6 fs-bold'>{label}</div>
      <div className='fs-7 fw-boldest'>{value}</div>
      <div
        className={`fs-6 ${
          String(changePercent) !== '-' && Number(changePercent) > 0
            ? 'text-sempai-green-text'
            : 'text-overdue'
        }`}
      >
        <KTSVG
          path={`/media/icons/duotone/Navigation/${
            String(changePercent) !== '-' && Number(changePercent) > 0
              ? 'Arrow-up.svg'
              : 'Arrow-down.svg'
          }`}
          className={`svg-icon ${
            String(changePercent) !== '-' && Number(changePercent) > 0
              ? 'svg-icon-sempai-green-text'
              : 'svg-icon-overdue'
          }`}
        />
        {typeof changePercent === 'string' && changePercent}
        {type === null && typeof changePercent === 'number' ? Number(changePercent).toFixed(2) : ''}
        {type === 'number' && typeof changePercent === 'number' ? Number(changeValue).toFixed(1) : ''}
        {type === null && typeof changePercent === 'number' ? '%' : ''}
      </div>
    </div>
  )
}

export default TimeStatisticWidget
