import { useSelector } from "react-redux";
import { selectors } from "../../redux/ReportsRedux";
import { TAB_NAME, IReportDetails } from "../../models/Reports";
import React, { useRef } from "react";
import clsx from "clsx";
import CommentTab from "../SeoStatistic/Tabs/CommentTab";
import TiktokStatisticTab from "./Tabs/TiktokStatisticTab";

interface ITiktokTabsContent {
    tab: string;
}

const TiktokTabsContent: React.FC<ITiktokTabsContent> = ({ tab }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails;
    const componentRef = useRef<HTMLDivElement>(null);

  const shouldShowAnalysisTab =
    reportData &&
    reportData.type === 'tiktok' &&
    reportData.details &&
    reportData.details.monthly &&
    reportData.details.monthly.length > 0 &&
    reportData.details.top10ads &&
    reportData.details.top10ads.length > 0;

  return (
        <div className='tab-content border'>
            <div className={clsx('tab-pane', { active: tab === TAB_NAME.COMMENT })}>
                <CommentTab />
            </div>
            {shouldShowAnalysisTab && (
                <div className={clsx('tab-pane', { active: tab === TAB_NAME.ANALYSIS })}>
                    <TiktokStatisticTab/>
                </div>
            )}
        </div>
    );
};

export default TiktokTabsContent;
