import React from 'react'
import GoogleAdsGroup from '../SemWidgetsGroup/GoogleAdsGroup'
import GoogleAdsList from '../SemWidgetsGroup/GoogleAdsList'
import MonthAdsGroup from '../SemWidgetsGroup/MonthAdsGroup'
import SocialGroup from '../../WidgetsGroup/SocialGroup'
import DevicesGroup from '../../WidgetsGroup/DevicesGroup'
import {useSelector} from 'react-redux'
import {selectors} from '../../../redux/ReportsRedux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import YearAdsGroup from '../SemWidgetsGroup/YearAdsGroup'
import EfficiencyList from '../SemWidgetsGroup/EfficiencyList'
import CategoryList from '../SemWidgetsGroup/CategoryList'
import BrandList from '../SemWidgetsGroup/BrandList'
import {SEMReportDetails} from "../../../models/SEMReports";
import GoogleAdsSumList from "../SemWidgetsGroup/GoogleAdsSumList";

interface DataStatisticTabProps {
  tabKey: string
}


const GadsDataStatisticTab: React.FC<DataStatisticTabProps> = ({tabKey}) => {

  const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)
  const details = useSelector(selectors.getReportDetails) as SEMReportDetails
  return !reportsError ? (
    <div data-testid="sem-report-basic-data" className={reportsLoading ? 'overlay overlay-block rounded' : ''}>
      {!reportsLoading ? (
        <>

            {(tabKey == '1' ? (
                    <>
                      <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5'>
                        {tabKey && (
                            <GoogleAdsGroup key={tabKey} tabKey={tabKey} />
                        )}
                      </div>
                      <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5'>
                        {tabKey && (
                            <GoogleAdsSumList key={tabKey} tabKey={tabKey}/>
                        )}
                      </div>

                    </>
              ) : (
                    <>
                      <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5'>
                        {tabKey && (
                            <GoogleAdsGroup key={tabKey} tabKey={tabKey} />
                        )}
                      </div>

                      <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5'>
                        {tabKey && (
                            <GoogleAdsList key={tabKey} tabKey={tabKey}/>
                        )}
                      </div>

                      {/*<div className='compare-data card-body bg-white px-5 py-5 pb-0 mb-5'>*/}
                      {/*<MonthAdsGroup />*/}
                      {/*</div>*/}

                      {/*<div className='compare-data compare-data-year card-body bg-white px-5 py-5 pb-0 mb-5'>*/}
                      {/*<YearAdsGroup />*/}
                      {/*</div>*/}

                      {/*<div className='card-body bg-white px-5 py-5 pb-0 mb-5'>*/}
                      {/*<SocialGroup />*/}
                      {/*</div>*/}

                      <div className='card-body bg-white px-5 py-5 pb-0 mb-5'>
                        {tabKey && (
                            <DevicesGroup key={tabKey} tabKey={tabKey} />
                        )}
                      </div>

                      {/*<div className='card-body bg-white px-5 py-5 mb-5 d-print-none'>*/}
                      {/*<EfficiencyList />*/}
                      {/*</div>*/}

                      {/*<div className='card-body bg-white px-5 py-5 mb-5 d-print-none'>*/}
                      {/*<CategoryList />*/}
                      {/*</div>*/}

                      {/*<div className='card-body bg-white px-5 py-5 mb-5 d-print-none'>*/}
                      {/*<BrandList />*/}
                      {/*</div>*/}
                    </>
                )
            )}
        </>
      ) : (
        <div style={{height: '350px'}}>
          <Spinner />
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default GadsDataStatisticTab
