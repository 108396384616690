var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.2553, 46.0679],
            [16.2955, 46.0125],
            [16.3356, 46.0268],
            [16.4237, 46.0054],
            [16.4638, 45.9766],
            [16.5397, 45.9543],
            [16.593, 45.9157],
            [16.6595, 45.9239],
            [16.6966, 45.9178],
            [16.7025, 45.8653],
            [16.6855, 45.8386],
            [16.6002, 45.8147],
            [16.5767, 45.7999],
            [16.5062, 45.7951],
            [16.5213, 45.7618],
            [16.5021, 45.7285],
            [16.5715, 45.7075],
            [16.6239, 45.6663],
            [16.5967, 45.6207],
            [16.5505, 45.6127],
            [16.4794, 45.5605],
            [16.462, 45.587],
            [16.4208, 45.5953],
            [16.3982, 45.6262],
            [16.3554, 45.6187],
            [16.3019, 45.6286],
            [16.2442, 45.5942],
            [16.2104, 45.6],
            [16.1406, 45.6326],
            [16.0887, 45.5951],
            [16.0659, 45.5638],
            [16.1038, 45.537],
            [16.1034, 45.4647],
            [16.0786, 45.4503],
            [16.0423, 45.4848],
            [15.9871, 45.4715],
            [15.9314, 45.4739],
            [15.9224, 45.5148],
            [15.8407, 45.548],
            [15.7951, 45.5211],
            [15.7018, 45.6],
            [15.67, 45.5985],
            [15.6184, 45.6212],
            [15.5362, 45.5732],
            [15.5113, 45.605],
            [15.5167, 45.6372],
            [15.4759, 45.658],
            [15.445, 45.6903],
            [15.3592, 45.7363],
            [15.3472, 45.773],
            [15.4126, 45.7956],
            [15.4746, 45.7982],
            [15.4924, 45.8321],
            [15.5742, 45.8527],
            [15.6303, 45.8325],
            [15.7068, 45.8443],
            [15.6819, 45.9027],
            [15.7092, 45.9223],
            [15.7097, 45.9703],
            [15.7635, 45.9707],
            [15.8181, 45.9848],
            [15.9039, 45.955],
            [15.946, 45.9275],
            [15.9491, 45.9051],
            [15.8366, 45.8535],
            [15.8198, 45.8309],
            [15.837, 45.7903],
            [15.8168, 45.7729],
            [15.8692, 45.7487],
            [15.8622, 45.7283],
            [15.7832, 45.7339],
            [15.7759, 45.6862],
            [15.8651, 45.6565],
            [15.901, 45.6316],
            [15.954, 45.621],
            [15.9884, 45.6724],
            [15.9972, 45.7125],
            [16.0325, 45.7434],
            [16.0854, 45.7625],
            [16.1456, 45.8042],
            [16.1991, 45.7935],
            [16.204, 45.8267],
            [16.2337, 45.8374],
            [16.2031, 45.8869],
            [16.1787, 45.8947],
            [16.1771, 45.9346],
            [16.1547, 45.9669],
            [16.1559, 45.9877],
            [16.2166, 46.0203],
            [16.2198, 46.0501],
            [16.2553, 46.0679],
          ],
        ],
      },
      properties: {name: 'Zagreb County', id: 'HR-01', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-01',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.8645, 46.2733],
            [15.8788, 46.2313],
            [15.9393, 46.2014],
            [16.0215, 46.1863],
            [16.0874, 46.1962],
            [16.1481, 46.1802],
            [16.1677, 46.2003],
            [16.2494, 46.188],
            [16.2545, 46.1529],
            [16.2425, 46.0938],
            [16.2553, 46.0679],
            [16.2198, 46.0501],
            [16.2166, 46.0203],
            [16.1559, 45.9877],
            [16.1547, 45.9669],
            [16.1162, 45.9675],
            [16.0459, 45.9357],
            [15.9491, 45.9051],
            [15.946, 45.9275],
            [15.9039, 45.955],
            [15.8181, 45.9848],
            [15.7635, 45.9707],
            [15.7097, 45.9703],
            [15.7164, 46.0591],
            [15.6491, 46.0869],
            [15.6064, 46.1151],
            [15.6064, 46.1629],
            [15.6471, 46.1875],
            [15.6506, 46.2173],
            [15.6817, 46.2279],
            [15.7879, 46.2168],
            [15.8256, 46.259],
            [15.8645, 46.2733],
          ],
        ],
      },
      properties: {name: 'Krapina-Zagorje', id: 'HR-02', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-02',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.8403, 45.2254],
            [15.7962, 45.2534],
            [15.8275, 45.2899],
            [15.7873, 45.3465],
            [15.7928, 45.3628],
            [15.8468, 45.3837],
            [15.8354, 45.4277],
            [15.8649, 45.4864],
            [15.9314, 45.4739],
            [15.9871, 45.4715],
            [16.0423, 45.4848],
            [16.0786, 45.4503],
            [16.1034, 45.4647],
            [16.1038, 45.537],
            [16.0659, 45.5638],
            [16.0887, 45.5951],
            [16.1406, 45.6326],
            [16.2104, 45.6],
            [16.2442, 45.5942],
            [16.3019, 45.6286],
            [16.3554, 45.6187],
            [16.3982, 45.6262],
            [16.4208, 45.5953],
            [16.462, 45.587],
            [16.4794, 45.5605],
            [16.5505, 45.6127],
            [16.5967, 45.6207],
            [16.6239, 45.6663],
            [16.6623, 45.6711],
            [16.7025, 45.6569],
            [16.7365, 45.6265],
            [16.8004, 45.623],
            [16.8189, 45.5709],
            [16.8732, 45.5299],
            [16.8555, 45.4947],
            [16.9383, 45.4826],
            [16.9598, 45.4573],
            [16.9994, 45.4576],
            [17.0536, 45.4005],
            [17.1179, 45.3799],
            [17.1389, 45.3517],
            [17.1816, 45.3566],
            [17.1871, 45.3334],
            [17.1616, 45.2952],
            [17.0706, 45.2307],
            [17.113, 45.207],
            [17.1459, 45.1621],
            [16.9939, 45.2213],
            [16.9347, 45.2757],
            [16.9136, 45.2539],
            [16.8365, 45.215],
            [16.8214, 45.1841],
            [16.7327, 45.2063],
            [16.6962, 45.1957],
            [16.6026, 45.2294],
            [16.5331, 45.2263],
            [16.4933, 45.2085],
            [16.4634, 45.1408],
            [16.3971, 45.1117],
            [16.4051, 45.0899],
            [16.3588, 45.0305],
            [16.3561, 45.002],
            [16.3116, 44.9984],
            [16.2286, 45.0093],
            [16.1487, 45.0775],
            [16.0828, 45.0996],
            [16.0166, 45.2082],
            [15.9712, 45.224],
            [15.9344, 45.2044],
            [15.8403, 45.2254],
          ],
        ],
      },
      properties: {name: 'Sisak-Moslavina', id: 'HR-03', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-03',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.3472, 45.773],
            [15.3592, 45.7363],
            [15.445, 45.6903],
            [15.4759, 45.658],
            [15.5167, 45.6372],
            [15.5113, 45.605],
            [15.5362, 45.5732],
            [15.6184, 45.6212],
            [15.67, 45.5985],
            [15.7018, 45.6],
            [15.7951, 45.5211],
            [15.8407, 45.548],
            [15.9224, 45.5148],
            [15.9314, 45.4739],
            [15.8649, 45.4864],
            [15.8354, 45.4277],
            [15.8468, 45.3837],
            [15.7928, 45.3628],
            [15.7873, 45.3465],
            [15.8275, 45.2899],
            [15.7962, 45.2534],
            [15.8403, 45.2254],
            [15.7614, 45.1665],
            [15.7823, 45.1134],
            [15.7459, 45.0632],
            [15.7846, 45.0045],
            [15.7864, 44.9725],
            [15.7528, 44.9681],
            [15.7387, 44.9286],
            [15.7117, 44.9132],
            [15.6604, 44.9378],
            [15.6098, 44.9232],
            [15.5666, 44.9558],
            [15.5184, 44.9149],
            [15.4427, 44.9262],
            [15.366, 45.0065],
            [15.303, 45.0224],
            [15.288, 45.0418],
            [15.2372, 45.0614],
            [15.1963, 45.1068],
            [15.1383, 45.074],
            [15.0914, 45.0849],
            [15.0676, 45.108],
            [15.0113, 45.1244],
            [14.9705, 45.1675],
            [14.9936, 45.3111],
            [15.0813, 45.2948],
            [15.1784, 45.3097],
            [15.1741, 45.3276],
            [15.2285, 45.3946],
            [15.2244, 45.4266],
            [15.2738, 45.4657],
            [15.3322, 45.4528],
            [15.3882, 45.4875],
            [15.3032, 45.5366],
            [15.3005, 45.5834],
            [15.2789, 45.6041],
            [15.3043, 45.6313],
            [15.3995, 45.6533],
            [15.3598, 45.7164],
            [15.3004, 45.6899],
            [15.2644, 45.7299],
            [15.3472, 45.773],
          ],
        ],
      },
      properties: {name: 'Karlovac', id: 'HR-04', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-04',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.2904, 46.3728],
            [16.3309, 46.3581],
            [16.3619, 46.3226],
            [16.4103, 46.3131],
            [16.5392, 46.3119],
            [16.5573, 46.3291],
            [16.6012, 46.3059],
            [16.6781, 46.3198],
            [16.6991, 46.3062],
            [16.7656, 46.2996],
            [16.7615, 46.2543],
            [16.6837, 46.1977],
            [16.6077, 46.2042],
            [16.5389, 46.1785],
            [16.5446, 46.1626],
            [16.4314, 46.1582],
            [16.3768, 46.1205],
            [16.3356, 46.0268],
            [16.2955, 46.0125],
            [16.2553, 46.0679],
            [16.2425, 46.0938],
            [16.2545, 46.1529],
            [16.2494, 46.188],
            [16.1677, 46.2003],
            [16.1481, 46.1802],
            [16.0874, 46.1962],
            [16.0215, 46.1863],
            [15.9393, 46.2014],
            [15.8788, 46.2313],
            [15.8645, 46.2733],
            [15.9003, 46.2922],
            [15.9463, 46.292],
            [16.0744, 46.3393],
            [16.0828, 46.381],
            [16.1453, 46.4063],
            [16.1943, 46.3761],
            [16.2596, 46.3867],
            [16.2904, 46.3728],
          ],
        ],
      },
      properties: {name: 'Varaždin', id: 'HR-05', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-05',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.2959, 45.9839],
            [17.2133, 45.9821],
            [17.1938, 45.9536],
            [17.1314, 45.8976],
            [17.1233, 45.8775],
            [17.0666, 45.9031],
            [17.0504, 45.9343],
            [17.0017, 45.9328],
            [16.9871, 45.9665],
            [16.9403, 45.9846],
            [16.8913, 45.9819],
            [16.9008, 46.0186],
            [16.8698, 46.0559],
            [16.823, 46.0476],
            [16.7722, 46.0534],
            [16.7186, 46.0307],
            [16.7023, 45.9947],
            [16.6966, 45.9178],
            [16.6595, 45.9239],
            [16.593, 45.9157],
            [16.5397, 45.9543],
            [16.4638, 45.9766],
            [16.4237, 46.0054],
            [16.3356, 46.0268],
            [16.3768, 46.1205],
            [16.4314, 46.1582],
            [16.5446, 46.1626],
            [16.5389, 46.1785],
            [16.6077, 46.2042],
            [16.6837, 46.1977],
            [16.7615, 46.2543],
            [16.7656, 46.2996],
            [16.8025, 46.3093],
            [16.8308, 46.2864],
            [16.8545, 46.3532],
            [16.8781, 46.3344],
            [16.8844, 46.2755],
            [16.9751, 46.2241],
            [17.0105, 46.2203],
            [17.1028, 46.1874],
            [17.182, 46.1506],
            [17.1766, 46.108],
            [17.2183, 46.0816],
            [17.2703, 46.0327],
            [17.258, 46.0133],
            [17.2959, 45.9839],
          ],
        ],
      },
      properties: {name: 'Koprivnica-Križevci', id: 'HR-06', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-06',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.6966, 45.9178],
            [16.7023, 45.9947],
            [16.7186, 46.0307],
            [16.7722, 46.0534],
            [16.823, 46.0476],
            [16.8698, 46.0559],
            [16.9008, 46.0186],
            [16.8913, 45.9819],
            [16.9403, 45.9846],
            [16.9871, 45.9665],
            [17.0017, 45.9328],
            [17.0504, 45.9343],
            [17.0666, 45.9031],
            [17.1233, 45.8775],
            [17.184, 45.8562],
            [17.1991, 45.8318],
            [17.2758, 45.8045],
            [17.2867, 45.7718],
            [17.344, 45.7615],
            [17.3888, 45.6958],
            [17.4423, 45.7209],
            [17.4984, 45.6913],
            [17.4492, 45.6295],
            [17.4442, 45.5767],
            [17.4401, 45.5284],
            [17.4101, 45.5106],
            [17.3599, 45.5257],
            [17.2871, 45.5114],
            [17.228, 45.5126],
            [17.1853, 45.5381],
            [17.1635, 45.5205],
            [17.1238, 45.5285],
            [17.0831, 45.5099],
            [17.0566, 45.5261],
            [17.0203, 45.5109],
            [16.9611, 45.5302],
            [16.9336, 45.5157],
            [16.9383, 45.4826],
            [16.8555, 45.4947],
            [16.8732, 45.5299],
            [16.8189, 45.5709],
            [16.8004, 45.623],
            [16.7365, 45.6265],
            [16.7025, 45.6569],
            [16.6623, 45.6711],
            [16.6239, 45.6663],
            [16.5715, 45.7075],
            [16.5021, 45.7285],
            [16.5213, 45.7618],
            [16.5062, 45.7951],
            [16.5767, 45.7999],
            [16.6002, 45.8147],
            [16.6855, 45.8386],
            [16.7025, 45.8653],
            [16.6966, 45.9178],
          ],
        ],
      },
      properties: {name: 'Bjelovar-Bilogora', id: 'HR-07', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-07',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [14.5654, 45.2423],
              [14.6375, 45.1618],
              [14.6685, 45.1477],
              [14.6632, 45.092],
              [14.8207, 44.9798],
              [14.8083, 44.9637],
              [14.7542, 44.9698],
              [14.7348, 44.9357],
              [14.6526, 44.9644],
              [14.6081, 45.0275],
              [14.562, 45.0129],
              [14.4897, 45.0264],
              [14.4364, 45.0682],
              [14.4358, 45.0922],
              [14.5277, 45.1241],
              [14.5468, 45.1671],
              [14.5357, 45.1996],
              [14.5654, 45.2423],
            ],
          ],
          [
            [
              [15.2244, 45.4266],
              [15.2285, 45.3946],
              [15.1741, 45.3276],
              [15.1784, 45.3097],
              [15.0813, 45.2948],
              [14.9936, 45.3111],
              [14.9705, 45.1675],
              [15.0113, 45.1244],
              [15.0259, 45.0877],
              [15.0056, 45.0645],
              [14.8916, 45.0544],
              [14.8822, 45.0327],
              [14.8441, 45.0962],
              [14.8152, 45.1148],
              [14.7141, 45.1505],
              [14.6546, 45.2022],
              [14.6124, 45.2185],
              [14.5432, 45.2787],
              [14.4591, 45.3213],
              [14.3294, 45.3565],
              [14.2829, 45.3059],
              [14.227, 45.1524],
              [14.2025, 45.1638],
              [14.2095, 45.2111],
              [14.194, 45.2561],
              [14.2003, 45.301],
              [14.2207, 45.3175],
              [14.1909, 45.3856],
              [14.2092, 45.3977],
              [14.1328, 45.4319],
              [14.1159, 45.4821],
              [14.2033, 45.4869],
              [14.2433, 45.5103],
              [14.3439, 45.487],
              [14.4295, 45.5081],
              [14.4858, 45.5389],
              [14.5041, 45.5888],
              [14.5596, 45.6405],
              [14.5728, 45.6728],
              [14.6163, 45.6636],
              [14.598, 45.6273],
              [14.6542, 45.5921],
              [14.6814, 45.5894],
              [14.7199, 45.5349],
              [14.7981, 45.5032],
              [14.8181, 45.4608],
              [14.9089, 45.4777],
              [14.9326, 45.5297],
              [14.9837, 45.4991],
              [15.0282, 45.4857],
              [15.0563, 45.4941],
              [15.0883, 45.4664],
              [15.1603, 45.4245],
              [15.2244, 45.4266],
            ],
          ],
          [
            [
              [14.3426, 44.7114],
              [14.3947, 44.6921],
              [14.4029, 44.6593],
              [14.3996, 44.6183],
              [14.435, 44.5657],
              [14.3722, 44.5614],
              [14.396, 44.6034],
              [14.3538, 44.6449],
              [14.3426, 44.7114],
            ],
          ],
          [
            [
              [14.728, 44.8513],
              [14.7414, 44.8185],
              [14.8304, 44.7574],
              [14.8616, 44.7174],
              [14.8387, 44.7014],
              [14.7812, 44.7491],
              [14.7104, 44.7834],
              [14.6799, 44.8441],
              [14.728, 44.8513],
            ],
          ],
          [
            [
              [14.3275, 45.1719],
              [14.359, 45.1626],
              [14.3549, 45.1149],
              [14.401, 45.0115],
              [14.435, 44.9796],
              [14.4711, 44.9825],
              [14.461, 44.9043],
              [14.4475, 44.8762],
              [14.4803, 44.7453],
              [14.4646, 44.7272],
              [14.4789, 44.6942],
              [14.5144, 44.6629],
              [14.5293, 44.6178],
              [14.5001, 44.6022],
              [14.4598, 44.6242],
              [14.4396, 44.6561],
              [14.4172, 44.675],
              [14.3926, 44.6937],
              [14.3917, 44.737],
              [14.3716, 44.7552],
              [14.3679, 44.8028],
              [14.3373, 44.8145],
              [14.3345, 44.8542],
              [14.3056, 44.9129],
              [14.3162, 44.9525],
              [14.3636, 44.9036],
              [14.4032, 44.9122],
              [14.3793, 44.9906],
              [14.3523, 45.0025],
              [14.3498, 45.053],
              [14.3066, 45.07],
              [14.2731, 45.1211],
              [14.3019, 45.1634],
              [14.3275, 45.1719],
            ],
          ],
        ],
      },
      properties: {name: 'Primorje-Gorski Kotar', id: 'HR-08', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-08',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [15.0113, 45.1244],
              [15.0676, 45.108],
              [15.0914, 45.0849],
              [15.1383, 45.074],
              [15.1963, 45.1068],
              [15.2372, 45.0614],
              [15.288, 45.0418],
              [15.303, 45.0224],
              [15.366, 45.0065],
              [15.4427, 44.9262],
              [15.5184, 44.9149],
              [15.5666, 44.9558],
              [15.6098, 44.9232],
              [15.6604, 44.9378],
              [15.7117, 44.9132],
              [15.7387, 44.9286],
              [15.7507, 44.8701],
              [15.7843, 44.8465],
              [15.7149, 44.817],
              [15.7768, 44.748],
              [15.8169, 44.7218],
              [15.8976, 44.747],
              [15.9568, 44.7119],
              [15.9761, 44.6729],
              [16.0315, 44.6538],
              [16.0401, 44.5911],
              [16.0309, 44.5541],
              [16.1101, 44.5205],
              [16.1445, 44.4846],
              [16.1382, 44.4537],
              [16.0585, 44.4229],
              [16.0395, 44.436],
              [16.0416, 44.4568],
              [16.0098, 44.4859],
              [15.9475, 44.4966],
              [15.8684, 44.4486],
              [15.7905, 44.4224],
              [15.7973, 44.3817],
              [15.7723, 44.3603],
              [15.8271, 44.3336],
              [15.823, 44.2914],
              [15.7919, 44.267],
              [15.7066, 44.2651],
              [15.6505, 44.2841],
              [15.5706, 44.2938],
              [15.547, 44.3293],
              [15.5063, 44.3515],
              [15.4015, 44.3807],
              [15.3241, 44.4124],
              [15.2893, 44.3621],
              [15.278, 44.3647],
              [15.1852, 44.4467],
              [15.1357, 44.4722],
              [15.1006, 44.5135],
              [15.0287, 44.5609],
              [14.9902, 44.5742],
              [14.9625, 44.6308],
              [14.9297, 44.6533],
              [14.8975, 44.6969],
              [14.8993, 44.7441],
              [14.8811, 44.7925],
              [14.8855, 44.8604],
              [14.9276, 44.9517],
              [14.8822, 45.0327],
              [14.8916, 45.0544],
              [15.0056, 45.0645],
              [15.0259, 45.0877],
              [15.0113, 45.1244],
            ],
          ],
          [
            [
              [14.8034, 44.6446],
              [14.873, 44.6059],
              [14.9113, 44.6118],
              [14.9596, 44.578],
              [14.9863, 44.5343],
              [15.0313, 44.5262],
              [15.0231, 44.5024],
              [14.9364, 44.5344],
              [15.0444, 44.4424],
              [15.0432, 44.4801],
              [15.1395, 44.4121],
              [15.1762, 44.3983],
              [15.2476, 44.3502],
              [15.2568, 44.3232],
              [15.2135, 44.3119],
              [15.1359, 44.3332],
              [15.0878, 44.362],
              [15.1159, 44.3791],
              [15.0373, 44.4101],
              [14.9655, 44.4648],
              [14.9094, 44.4838],
              [14.9178, 44.5137],
              [14.8835, 44.5572],
              [14.7903, 44.6287],
              [14.8034, 44.6446],
            ],
          ],
        ],
      },
      properties: {name: 'Lika-Senj', id: 'HR-09', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-09',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.9154, 45.7872],
            [17.9367, 45.7309],
            [18.0012, 45.7144],
            [18.0376, 45.6109],
            [17.9942, 45.5712],
            [17.937, 45.4989],
            [17.8818, 45.4839],
            [17.8648, 45.4883],
            [17.8366, 45.4767],
            [17.7797, 45.4978],
            [17.6303, 45.515],
            [17.5935, 45.5489],
            [17.5303, 45.5762],
            [17.4987, 45.5593],
            [17.4442, 45.5767],
            [17.4492, 45.6295],
            [17.4984, 45.6913],
            [17.4423, 45.7209],
            [17.3888, 45.6958],
            [17.344, 45.7615],
            [17.2867, 45.7718],
            [17.2758, 45.8045],
            [17.1991, 45.8318],
            [17.184, 45.8562],
            [17.1233, 45.8775],
            [17.1314, 45.8976],
            [17.1938, 45.9536],
            [17.2133, 45.9821],
            [17.2959, 45.9839],
            [17.3363, 45.9802],
            [17.39, 45.9305],
            [17.4345, 45.9495],
            [17.5274, 45.9335],
            [17.566, 45.9361],
            [17.6217, 45.9027],
            [17.6518, 45.8465],
            [17.7786, 45.8154],
            [17.8499, 45.7804],
            [17.9154, 45.7872],
          ],
        ],
      },
      properties: {name: 'Virovitica-Podravina', id: 'HR-10', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-10',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.8818, 45.4839],
            [17.8839, 45.4578],
            [17.9374, 45.4474],
            [17.9794, 45.419],
            [18.037, 45.3958],
            [18.0856, 45.3981],
            [18.1079, 45.3784],
            [18.1031, 45.3345],
            [18.1196, 45.3006],
            [18.0779, 45.268],
            [17.9886, 45.285],
            [17.8984, 45.2835],
            [17.8791, 45.2458],
            [17.8398, 45.2107],
            [17.7538, 45.1871],
            [17.7265, 45.2171],
            [17.7066, 45.2682],
            [17.6138, 45.2692],
            [17.5106, 45.3133],
            [17.4946, 45.3443],
            [17.4095, 45.3917],
            [17.3491, 45.3872],
            [17.3244, 45.3678],
            [17.2487, 45.393],
            [17.1816, 45.3566],
            [17.1389, 45.3517],
            [17.1179, 45.3799],
            [17.0536, 45.4005],
            [16.9994, 45.4576],
            [16.9598, 45.4573],
            [16.9383, 45.4826],
            [16.9336, 45.5157],
            [16.9611, 45.5302],
            [17.0203, 45.5109],
            [17.0566, 45.5261],
            [17.0831, 45.5099],
            [17.1238, 45.5285],
            [17.1635, 45.5205],
            [17.1853, 45.5381],
            [17.228, 45.5126],
            [17.2871, 45.5114],
            [17.3599, 45.5257],
            [17.4101, 45.5106],
            [17.4401, 45.5284],
            [17.4442, 45.5767],
            [17.4987, 45.5593],
            [17.5303, 45.5762],
            [17.5935, 45.5489],
            [17.6303, 45.515],
            [17.7797, 45.4978],
            [17.8366, 45.4767],
            [17.8648, 45.4883],
            [17.8818, 45.4839],
          ],
        ],
      },
      properties: {name: 'Požega-Slavonia', id: 'HR-11', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-11',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.1816, 45.3566],
            [17.2487, 45.393],
            [17.3244, 45.3678],
            [17.3491, 45.3872],
            [17.4095, 45.3917],
            [17.4946, 45.3443],
            [17.5106, 45.3133],
            [17.6138, 45.2692],
            [17.7066, 45.2682],
            [17.7265, 45.2171],
            [17.7538, 45.1871],
            [17.8398, 45.2107],
            [17.8791, 45.2458],
            [17.8984, 45.2835],
            [17.9886, 45.285],
            [18.0779, 45.268],
            [18.1911, 45.2404],
            [18.219, 45.2207],
            [18.2697, 45.2265],
            [18.3005, 45.2437],
            [18.3921, 45.2427],
            [18.4374, 45.2182],
            [18.5162, 45.2092],
            [18.5483, 45.1925],
            [18.4955, 45.1251],
            [18.5343, 45.0929],
            [18.5035, 45.0582],
            [18.4704, 45.0653],
            [18.4223, 45.1094],
            [18.3196, 45.1026],
            [18.2555, 45.14],
            [18.2117, 45.1239],
            [18.2214, 45.0977],
            [18.1825, 45.0766],
            [18.121, 45.0806],
            [18.0718, 45.1078],
            [18.0704, 45.1418],
            [17.987, 45.1456],
            [17.9379, 45.0816],
            [17.849, 45.0446],
            [17.7857, 45.0824],
            [17.7564, 45.0865],
            [17.67, 45.1346],
            [17.5983, 45.1066],
            [17.5136, 45.1085],
            [17.4932, 45.1247],
            [17.3628, 45.1381],
            [17.3099, 45.1782],
            [17.2742, 45.1885],
            [17.2446, 45.1453],
            [17.1777, 45.1468],
            [17.1459, 45.1621],
            [17.113, 45.207],
            [17.0706, 45.2307],
            [17.1616, 45.2952],
            [17.1871, 45.3334],
            [17.1816, 45.3566],
          ],
        ],
      },
      properties: {name: 'Brod-Posavina', id: 'HR-12', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-12',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [15.0364, 44.3241],
              [15.095, 44.2993],
              [15.051, 44.2848],
              [15.0364, 44.3241],
            ],
          ],
          [
            [
              [16.0585, 44.4229],
              [16.1382, 44.4537],
              [16.1638, 44.4052],
              [16.1204, 44.3834],
              [16.2041, 44.3622],
              [16.2097, 44.2859],
              [16.2292, 44.2167],
              [16.1498, 44.2031],
              [16.137, 44.1359],
              [16.0916, 44.1134],
              [15.9962, 44.1451],
              [15.9577, 44.1778],
              [15.9164, 44.1276],
              [15.8196, 44.0622],
              [15.8541, 44.0457],
              [15.8467, 43.9973],
              [15.7939, 43.9734],
              [15.8199, 43.9464],
              [15.7535, 43.8841],
              [15.7427, 43.8541],
              [15.6789, 43.8769],
              [15.6362, 43.8623],
              [15.5836, 43.8882],
              [15.5576, 43.8717],
              [15.5227, 43.8991],
              [15.4555, 43.9237],
              [15.3442, 44.0286],
              [15.284, 44.0616],
              [15.2656, 44.0942],
              [15.2249, 44.112],
              [15.2095, 44.1467],
              [15.1431, 44.1957],
              [15.1688, 44.2289],
              [15.2145, 44.2577],
              [15.2091, 44.2932],
              [15.2847, 44.2467],
              [15.2821, 44.2813],
              [15.3288, 44.2932],
              [15.4019, 44.2596],
              [15.4444, 44.2638],
              [15.505, 44.2515],
              [15.5138, 44.2104],
              [15.4746, 44.1992],
              [15.5185, 44.1705],
              [15.574, 44.1802],
              [15.5294, 44.225],
              [15.5373, 44.2606],
              [15.4533, 44.2798],
              [15.4187, 44.3119],
              [15.3407, 44.3369],
              [15.2893, 44.3621],
              [15.3241, 44.4124],
              [15.4015, 44.3807],
              [15.5063, 44.3515],
              [15.547, 44.3293],
              [15.5706, 44.2938],
              [15.6505, 44.2841],
              [15.7066, 44.2651],
              [15.7919, 44.267],
              [15.823, 44.2914],
              [15.8271, 44.3336],
              [15.7723, 44.3603],
              [15.7973, 44.3817],
              [15.7905, 44.4224],
              [15.8684, 44.4486],
              [15.9475, 44.4966],
              [16.0098, 44.4859],
              [16.0416, 44.4568],
              [16.0395, 44.436],
              [16.0585, 44.4229],
            ],
          ],
          [
            [
              [14.7972, 44.4171],
              [14.7959, 44.3836],
              [14.8228, 44.3544],
              [14.7967, 44.3417],
              [14.7618, 44.4161],
              [14.7972, 44.4171],
            ],
          ],
          [
            [
              [14.8072, 44.2626],
              [14.8668, 44.2306],
              [14.83, 44.2],
              [14.7882, 44.257],
              [14.8072, 44.2626],
            ],
          ],
          [
            [
              [14.8639, 44.1705],
              [14.911, 44.1422],
              [14.9479, 44.102],
              [15.0242, 44.064],
              [15.0608, 44.0014],
              [15.0981, 43.9909],
              [15.1736, 43.9256],
              [15.2236, 43.9046],
              [15.1816, 43.8836],
              [15.1035, 43.927],
              [14.9929, 44.0553],
              [14.9384, 44.0855],
              [14.8568, 44.151],
              [14.8639, 44.1705],
            ],
          ],
          [
            [
              [15.269, 44.015],
              [15.3251, 43.9943],
              [15.3901, 43.9377],
              [15.4269, 43.8897],
              [15.3085, 43.9548],
              [15.2657, 43.9998],
              [15.269, 44.015],
            ],
          ],
          [
            [
              [15.0989, 44.1372],
              [15.1872, 44.0933],
              [15.1992, 44.032],
              [15.066, 44.1313],
              [15.0989, 44.1372],
            ],
          ],
        ],
      },
      properties: {name: 'Zadar', id: 'HR-13', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-13',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.9984, 45.4399],
            [18.9489, 45.4365],
            [18.8823, 45.4835],
            [18.7966, 45.4645],
            [18.8161, 45.4209],
            [18.7702, 45.4028],
            [18.751, 45.4151],
            [18.6465, 45.3907],
            [18.6463, 45.3553],
            [18.6268, 45.3176],
            [18.57, 45.2967],
            [18.5143, 45.2883],
            [18.5142, 45.2435],
            [18.5564, 45.2256],
            [18.5483, 45.1925],
            [18.5162, 45.2092],
            [18.4374, 45.2182],
            [18.3921, 45.2427],
            [18.3005, 45.2437],
            [18.2697, 45.2265],
            [18.219, 45.2207],
            [18.1911, 45.2404],
            [18.0779, 45.268],
            [18.1196, 45.3006],
            [18.1031, 45.3345],
            [18.1079, 45.3784],
            [18.0856, 45.3981],
            [18.037, 45.3958],
            [17.9794, 45.419],
            [17.9374, 45.4474],
            [17.8839, 45.4578],
            [17.8818, 45.4839],
            [17.937, 45.4989],
            [17.9942, 45.5712],
            [18.0376, 45.6109],
            [18.0012, 45.7144],
            [17.9367, 45.7309],
            [17.9154, 45.7872],
            [17.9984, 45.7943],
            [18.0827, 45.765],
            [18.1229, 45.7892],
            [18.2303, 45.779],
            [18.2445, 45.762],
            [18.3343, 45.7533],
            [18.3648, 45.7732],
            [18.4074, 45.7405],
            [18.5745, 45.8003],
            [18.5744, 45.8145],
            [18.6545, 45.8749],
            [18.6719, 45.9106],
            [18.7096, 45.9124],
            [18.8589, 45.857],
            [18.853, 45.8021],
            [18.8873, 45.7751],
            [18.9105, 45.7062],
            [18.9681, 45.6771],
            [18.9405, 45.6238],
            [18.8971, 45.5648],
            [18.938, 45.5379],
            [18.9909, 45.542],
            [19.0676, 45.5317],
            [19.1017, 45.5144],
            [19.0836, 45.487],
            [18.9995, 45.4936],
            [18.9984, 45.4399],
          ],
        ],
      },
      properties: {name: 'Osijek-Baranja', id: 'HR-14', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-14',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [15.6072, 43.827],
              [15.6059, 43.815],
              [15.6453, 43.7978],
              [15.6266, 43.7832],
              [15.5797, 43.8111],
              [15.6072, 43.827],
            ],
          ],
          [
            [
              [16.5365, 43.9622],
              [16.4941, 43.9639],
              [16.4164, 43.9148],
              [16.3542, 43.9206],
              [16.3382, 43.8831],
              [16.4366, 43.8157],
              [16.3467, 43.765],
              [16.3393, 43.7298],
              [16.3592, 43.7109],
              [16.3298, 43.6869],
              [16.2532, 43.691],
              [16.2348, 43.6722],
              [16.1899, 43.6749],
              [16.1781, 43.6517],
              [16.1351, 43.6508],
              [16.1047, 43.6155],
              [16.0468, 43.5876],
              [16.0868, 43.5607],
              [16.0933, 43.5345],
              [16.0491, 43.5266],
              [16.0188, 43.5013],
              [15.975, 43.5101],
              [15.9809, 43.5368],
              [15.9348, 43.5453],
              [15.9328, 43.5862],
              [15.9136, 43.6273],
              [15.9478, 43.6591],
              [15.8834, 43.6949],
              [15.8558, 43.7203],
              [15.7842, 43.7576],
              [15.6954, 43.7653],
              [15.6621, 43.7995],
              [15.6401, 43.8008],
              [15.6256, 43.8439],
              [15.5582, 43.8697],
              [15.5836, 43.8882],
              [15.6362, 43.8623],
              [15.6789, 43.8769],
              [15.7427, 43.8541],
              [15.7535, 43.8841],
              [15.8199, 43.9464],
              [15.7939, 43.9734],
              [15.8467, 43.9973],
              [15.8541, 44.0457],
              [15.8196, 44.0622],
              [15.9164, 44.1276],
              [15.9577, 44.1778],
              [15.9962, 44.1451],
              [16.0916, 44.1134],
              [16.137, 44.1359],
              [16.1498, 44.2031],
              [16.2292, 44.2167],
              [16.2661, 44.1746],
              [16.3202, 44.1418],
              [16.3647, 44.0849],
              [16.4224, 44.0858],
              [16.4378, 44.033],
              [16.4969, 44.0296],
              [16.5365, 43.9622],
            ],
          ],
        ],
      },
      properties: {name: 'Šibenik-Knin', id: 'HR-15', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-15',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.5483, 45.1925],
            [18.5564, 45.2256],
            [18.5142, 45.2435],
            [18.5143, 45.2883],
            [18.57, 45.2967],
            [18.6268, 45.3176],
            [18.6463, 45.3553],
            [18.6465, 45.3907],
            [18.751, 45.4151],
            [18.7702, 45.4028],
            [18.8161, 45.4209],
            [18.7966, 45.4645],
            [18.8823, 45.4835],
            [18.9489, 45.4365],
            [18.9984, 45.4399],
            [19.0225, 45.4258],
            [18.9748, 45.3767],
            [19.025, 45.3448],
            [19.0934, 45.3343],
            [19.1127, 45.2953],
            [19.252, 45.2439],
            [19.3999, 45.2328],
            [19.3986, 45.1721],
            [19.3581, 45.168],
            [19.3189, 45.1999],
            [19.2651, 45.1713],
            [19.18, 45.2019],
            [19.1868, 45.1715],
            [19.1462, 45.1294],
            [19.0924, 45.1282],
            [19.1165, 45.0662],
            [19.0983, 45.037],
            [19.1054, 45.0083],
            [19.0545, 44.9773],
            [19.1555, 44.9553],
            [19.0805, 44.9105],
            [19.0117, 44.9035],
            [19.0256, 44.8569],
            [18.9716, 44.8512],
            [18.8528, 44.8557],
            [18.765, 44.9073],
            [18.8065, 44.9432],
            [18.7585, 45.001],
            [18.7158, 45.0375],
            [18.6068, 45.0651],
            [18.5712, 45.0944],
            [18.5343, 45.0929],
            [18.4955, 45.1251],
            [18.5483, 45.1925],
          ],
        ],
      },
      properties: {name: 'Vukovar-Srijem', id: 'HR-16', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-16',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [16.5654, 43.2318],
              [16.583, 43.2058],
              [16.6676, 43.1974],
              [16.728, 43.1565],
              [16.8804, 43.1621],
              [17.108, 43.1335],
              [17.1163, 43.1138],
              [16.9393, 43.112],
              [16.7764, 43.1277],
              [16.6665, 43.1216],
              [16.5691, 43.142],
              [16.5443, 43.1404],
              [16.4816, 43.1633],
              [16.4421, 43.1641],
              [16.4202, 43.1939],
              [16.4744, 43.2108],
              [16.5284, 43.2035],
              [16.5654, 43.2318],
            ],
          ],
          [
            [
              [16.2454, 43.4144],
              [16.3295, 43.3956],
              [16.3787, 43.3343],
              [16.319, 43.3491],
              [16.2317, 43.3863],
              [16.2454, 43.4144],
            ],
          ],
          [
            [
              [16.4466, 43.3911],
              [16.5508, 43.3901],
              [16.7996, 43.3559],
              [16.8912, 43.3202],
              [16.8832, 43.2824],
              [16.7932, 43.2622],
              [16.7562, 43.2653],
              [16.637, 43.2556],
              [16.5314, 43.27],
              [16.4112, 43.3165],
              [16.4355, 43.3304],
              [16.4466, 43.3911],
            ],
          ],
          [
            [
              [16.0188, 43.5013],
              [16.0491, 43.5266],
              [16.0933, 43.5345],
              [16.0868, 43.5607],
              [16.0468, 43.5876],
              [16.1047, 43.6155],
              [16.1351, 43.6508],
              [16.1781, 43.6517],
              [16.1899, 43.6749],
              [16.2348, 43.6722],
              [16.2532, 43.691],
              [16.3298, 43.6869],
              [16.3592, 43.7109],
              [16.3393, 43.7298],
              [16.3467, 43.765],
              [16.4366, 43.8157],
              [16.3382, 43.8831],
              [16.3542, 43.9206],
              [16.4164, 43.9148],
              [16.4941, 43.9639],
              [16.5365, 43.9622],
              [16.5844, 43.9388],
              [16.6177, 43.9048],
              [16.6991, 43.8627],
              [16.7076, 43.8212],
              [16.7501, 43.7779],
              [16.794, 43.7707],
              [16.8215, 43.7358],
              [16.9003, 43.6855],
              [17.0378, 43.5556],
              [17.0681, 43.5571],
              [17.1427, 43.5049],
              [17.2238, 43.5072],
              [17.2774, 43.4698],
              [17.2784, 43.4303],
              [17.2531, 43.3901],
              [17.3294, 43.2862],
              [17.3375, 43.2546],
              [17.4157, 43.2326],
              [17.4474, 43.1791],
              [17.4129, 43.1555],
              [17.3621, 43.1647],
              [17.3766, 43.1142],
              [17.3508, 43.093],
              [17.2604, 43.1515],
              [17.1752, 43.1742],
              [17.08, 43.2336],
              [17.0527, 43.2704],
              [17.0116, 43.2938],
              [16.948, 43.3601],
              [16.8897, 43.4012],
              [16.7925, 43.4038],
              [16.7259, 43.4139],
              [16.6919, 43.4442],
              [16.6442, 43.439],
              [16.5812, 43.4643],
              [16.5273, 43.4978],
              [16.427, 43.5005],
              [16.4694, 43.5369],
              [16.3523, 43.552],
              [16.2942, 43.5261],
              [16.1845, 43.5078],
              [16.159, 43.4829],
              [16.094, 43.473],
              [16.0188, 43.5013],
            ],
          ],
          [
            [
              [16.1859, 43.0819],
              [16.2561, 43.0741],
              [16.2552, 43.0528],
              [16.2131, 43.022],
              [16.1139, 43.0076],
              [16.0838, 43.0489],
              [16.0987, 43.0752],
              [16.1859, 43.0819],
            ],
          ],
        ],
      },
      properties: {name: 'Split-Dalmatia', id: 'HR-17', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-17',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.1159, 45.4821],
            [14.1328, 45.4319],
            [14.2092, 45.3977],
            [14.1909, 45.3856],
            [14.2207, 45.3175],
            [14.2003, 45.301],
            [14.194, 45.2561],
            [14.2095, 45.2111],
            [14.2025, 45.1638],
            [14.227, 45.1524],
            [14.2252, 45.1372],
            [14.1598, 45.0591],
            [14.1561, 45.0282],
            [14.1729, 44.9864],
            [14.1499, 44.9579],
            [14.1084, 44.9707],
            [14.051, 44.9384],
            [14.0284, 44.9055],
            [13.9819, 44.8933],
            [14.0024, 44.8638],
            [13.98, 44.8406],
            [14.0039, 44.8037],
            [13.9501, 44.8036],
            [13.8571, 44.8157],
            [13.8289, 44.8375],
            [13.8044, 44.9298],
            [13.7651, 44.9859],
            [13.7376, 44.9859],
            [13.6911, 45.051],
            [13.6395, 45.0715],
            [13.5986, 45.1414],
            [13.6128, 45.1589],
            [13.5823, 45.1986],
            [13.5968, 45.2547],
            [13.5751, 45.2647],
            [13.5974, 45.3164],
            [13.5411, 45.3296],
            [13.5445, 45.3781],
            [13.5175, 45.4156],
            [13.5113, 45.4725],
            [13.5286, 45.5004],
            [13.6077, 45.465],
            [13.6767, 45.4423],
            [13.7519, 45.4705],
            [13.7968, 45.4576],
            [13.8091, 45.4326],
            [13.8728, 45.4242],
            [13.9049, 45.4514],
            [13.9825, 45.4564],
            [14.0013, 45.5204],
            [14.0695, 45.4851],
            [14.1159, 45.4821],
          ],
        ],
      },
      properties: {name: 'Istria', id: 'HR-18', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-18',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [17.3729, 42.8011],
              [17.3808, 42.7869],
              [17.4508, 42.7796],
              [17.5981, 42.7455],
              [17.6699, 42.7366],
              [17.7487, 42.7097],
              [17.739, 42.6893],
              [17.6478, 42.7171],
              [17.5112, 42.7329],
              [17.4596, 42.754],
              [17.3895, 42.7569],
              [17.332, 42.7753],
              [17.3729, 42.8011],
            ],
          ],
          [
            [
              [17.0111, 43.0465],
              [17.0731, 43.0244],
              [17.1709, 43.026],
              [17.3033, 43.0031],
              [17.391, 42.9665],
              [17.446, 42.952],
              [17.4523, 42.9284],
              [17.4927, 42.9098],
              [17.5291, 42.9245],
              [17.5865, 42.8995],
              [17.6535, 42.8867],
              [17.6952, 42.9245],
              [17.7294, 42.9202],
              [17.7917, 42.8898],
              [17.8163, 42.9184],
              [17.8515, 42.8968],
              [17.8536, 42.8663],
              [17.9098, 42.8092],
              [17.9942, 42.7764],
              [18.1042, 42.7135],
              [18.1031, 42.6892],
              [18.1834, 42.66],
              [18.2538, 42.5979],
              [18.3253, 42.6223],
              [18.3689, 42.6181],
              [18.4377, 42.5662],
              [18.4555, 42.5087],
              [18.4469, 42.48],
              [18.5149, 42.4225],
              [18.3555, 42.4993],
              [18.2902, 42.5241],
              [18.2361, 42.5585],
              [18.2306, 42.6],
              [18.1267, 42.6405],
              [18.0614, 42.6513],
              [18.0727, 42.6694],
              [17.9684, 42.7127],
              [17.9041, 42.7463],
              [17.8481, 42.7885],
              [17.8449, 42.8037],
              [17.7649, 42.8046],
              [17.7364, 42.7915],
              [17.4495, 42.8678],
              [17.4538, 42.8925],
              [17.421, 42.9111],
              [17.3651, 42.9137],
              [17.3382, 42.9313],
              [17.247, 42.9614],
              [17.2198, 42.9814],
              [17.1363, 42.9741],
              [17.0711, 42.9846],
              [17.0182, 43.0068],
              [17.0111, 43.0465],
            ],
          ],
          [
            [
              [16.8698, 42.7712],
              [16.9359, 42.77],
              [16.9058, 42.7275],
              [16.8453, 42.7317],
              [16.8263, 42.7664],
              [16.8698, 42.7712],
            ],
          ],
          [
            [
              [17.3508, 43.093],
              [17.3766, 43.1142],
              [17.3621, 43.1647],
              [17.4129, 43.1555],
              [17.4474, 43.1791],
              [17.4795, 43.1608],
              [17.6419, 43.0881],
              [17.6668, 43.0376],
              [17.7139, 42.9717],
              [17.6155, 42.9407],
              [17.5388, 42.9411],
              [17.4346, 43.0152],
              [17.3508, 43.093],
            ],
          ],
          [
            [
              [16.6977, 42.9922],
              [16.7847, 42.9664],
              [16.8525, 42.9573],
              [16.9113, 42.9713],
              [17.0506, 42.981],
              [17.1128, 42.9593],
              [17.1386, 42.964],
              [17.1662, 42.9353],
              [17.1217, 42.9073],
              [17.049, 42.9243],
              [16.9753, 42.9274],
              [16.8963, 42.8961],
              [16.8534, 42.9059],
              [16.7749, 42.9037],
              [16.6409, 42.9362],
              [16.7044, 42.9677],
              [16.6977, 42.9922],
            ],
          ],
        ],
      },
      properties: {name: 'Dubrovnik-Neretva', id: 'HR-19', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-19',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.8545, 46.3532],
            [16.8308, 46.2864],
            [16.8025, 46.3093],
            [16.7656, 46.2996],
            [16.6991, 46.3062],
            [16.6781, 46.3198],
            [16.6012, 46.3059],
            [16.5573, 46.3291],
            [16.5392, 46.3119],
            [16.4103, 46.3131],
            [16.3619, 46.3226],
            [16.3309, 46.3581],
            [16.2904, 46.3728],
            [16.3077, 46.3983],
            [16.2711, 46.4295],
            [16.2506, 46.4911],
            [16.3448, 46.5389],
            [16.4301, 46.5295],
            [16.5806, 46.4658],
            [16.6666, 46.4561],
            [16.7185, 46.3928],
            [16.7604, 46.3796],
            [16.7987, 46.3857],
            [16.8545, 46.3532],
          ],
        ],
      },
      properties: {name: 'Medimurje', id: 'HR-20', CNTRY: 'Croatia', TYPE: 'County'},
      id: 'HR-20',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.1547, 45.9669],
            [16.1771, 45.9346],
            [16.1787, 45.8947],
            [16.2031, 45.8869],
            [16.2337, 45.8374],
            [16.204, 45.8267],
            [16.1991, 45.7935],
            [16.1456, 45.8042],
            [16.0854, 45.7625],
            [16.0325, 45.7434],
            [15.9972, 45.7125],
            [15.9884, 45.6724],
            [15.954, 45.621],
            [15.901, 45.6316],
            [15.8651, 45.6565],
            [15.7759, 45.6862],
            [15.7832, 45.7339],
            [15.8622, 45.7283],
            [15.8692, 45.7487],
            [15.8168, 45.7729],
            [15.837, 45.7903],
            [15.8198, 45.8309],
            [15.8366, 45.8535],
            [15.9491, 45.9051],
            [16.0459, 45.9357],
            [16.1162, 45.9675],
            [16.1547, 45.9669],
          ],
        ],
      },
      properties: {name: 'Grad Zagreb', id: 'HR-21', CNTRY: 'Croatia', TYPE: 'City'},
      id: 'HR-21',
    },
  ],
}
export default map
