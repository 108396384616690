import React, {useLayoutEffect, useRef} from 'react'
import {MapChart} from "@amcharts/amcharts4/maps";
import {countriesMaps} from "../../../../../setup/utils/countriesMaps";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";

export interface StatisticMapData {
  id: string
  value: number
}

interface StatisticMapProps {
  statisticData: StatisticMapData[]
  country?: string
}

interface CountriesMaps {
  [key: string]: any
}

const StatisticWorldMap: React.FC<StatisticMapProps> = ({statisticData, country}) => {
  const chart = useRef<MapChart>()
  const countriesMapObj = countriesMaps as CountriesMaps

  useLayoutEffect(() => {
    let mapWorld = am4core.create('chartdivWorldMap', am4maps.MapChart)
    mapWorld.chartContainer.wheelable = false
    mapWorld.tapToActivate = true
    mapWorld.dragGrip.disabled = true
    mapWorld.mouseWheelBehavior = 'none'
    mapWorld.draggable = false
    mapWorld.background.fill = am4core.color('#F3F3F3')
    mapWorld.background.fillOpacity = 1
    mapWorld.geodata = countriesMapObj.WORLD


    let polygonSeries = mapWorld.series.push(new am4maps.MapPolygonSeries())
    polygonSeries.useGeodata = true

    mapWorld.projection = new am4maps.projections.Mercator()

    // code from https://en.wikipedia.org/wiki/ISO_3166-2:PLF
    // code for any https://pl.wikipedia.org/wiki/ISO_3166-1
    polygonSeries.data = statisticData

    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: am4core.color('#DAECD5'),
      max: am4core.color('#5DAE4D'),
    })

    let polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.tooltipText = '{name}: {value}'
    polygonTemplate.nonScalingStroke = true
    polygonTemplate.strokeWidth = 0.5

    let hs = polygonTemplate.states.create('hover')
    hs.properties.fill = am4core.color('#80FF67')

    chart.current = mapWorld

    return () => {
      mapWorld.dispose()
    }
  }, [statisticData, country, countriesMapObj])

  return (
      <div className=''>
        <div
            data-testid='geography-chart'
            id='chartdivWorldMap'
            style={{width: '100%', height: '280px'}}
        ></div>
      </div>
  )
}


export default StatisticWorldMap
