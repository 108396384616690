import {useSelector} from 'react-redux'
import {selectors} from '../../../redux/ReportsRedux'
import {useEffect, useState,MouseEventHandler, useCallback} from 'react'
import React, { FC } from 'react';
import {numSpaceFormater} from '../../../../../helpers/formater'
import {useIntl} from 'react-intl'
import {bingAdsCampaignList, BINGReportDetails} from "../../../models/BINGReport";
import SortButton from "../../SortButton";

type SortKeys = any
type SortOrder = "ascn" | "desc";
interface IBingCampaignEffectiveness {
    tabKey: string | number
}

const BingCampaignEffectiveness: FC<IBingCampaignEffectiveness> = ({tabKey}) => {
    const intl = useIntl()
    const details = useSelector(selectors.getReportDetails) as BINGReportDetails
    const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");
    const [sortKey, setSortKey] = useState<SortKeys>();

    const [bingCampaignList, setBingCampaignList] = useState<bingAdsCampaignList[]>()

    function sortListByParam(this: any, param: keyof bingAdsCampaignList, isAscending: string = 'ascn') {

        if(bingCampaignList){
            const sortedList = [...bingCampaignList];

            sortedList.sort((a, b) => (a[param] > b[param]) ? 1 : -1)
            if(isAscending !== 'ascn'){
                sortedList.reverse();
            }
            setBingCampaignList(sortedList);
        }
    }

    useEffect(() => {
        if (details.bingAdsCampaignList) {
            setBingCampaignList(details.bingAdsCampaignList);
        }
    }, [tabKey]);

    const changeSort = (key: SortKeys) => {
        const newSortOrder = sortOrder === "ascn" ? "desc" : "ascn";
        setSortOrder(newSortOrder);
        setSortKey(key);
        sortListByParam(key, newSortOrder);
    };

    return (

        <>
            {bingCampaignList ? (
                <div className='card card-body overlay rounded'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>
                      {intl.formatMessage({id: 'CAMPAIGNS_REPORT_EFFICIENCY_TITLE'})}
                  </span>
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            {bingCampaignList ? (
                                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                    <thead>
                                    <tr className='fw-bolder text-muted'>
                                        <th className='min-w-50px'>
                                            <div className='d-flex'>
                                                {intl.formatMessage({id: 'CAMPAIGN_NAME'})}
                                                <SortButton
                                                    columnKey={'id'}
                                                    onClick={() => changeSort('campaign_name')}
                                                    {...{
                                                        sortOrder,
                                                        sortKey,
                                                    }}
                                                />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({id: 'CPC'})}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            <div className='d-flex justify-content-center'>
                                                {intl.formatMessage({id: 'CLICKS'})}
                                                <SortButton
                                                    columnKey={'clicks'}
                                                    onClick={() => changeSort('clicks')}
                                                    {...{
                                                        sortOrder,
                                                        sortKey,
                                                    }}
                                                />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            <div className='d-flex justify-content-center'>
                                                {intl.formatMessage({id: 'COST'})}
                                                <SortButton
                                                    columnKey={'cost'}
                                                    onClick={() => changeSort('cost')}
                                                    {...{
                                                        sortOrder,
                                                        sortKey,
                                                    }}
                                                />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({id: 'CONVERSION_VALUE'})}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({id: 'ROAS'})}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {bingCampaignList &&
                                        bingCampaignList.map((single) => {
                                            return <>
                                                <tr>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='d-flex justify-content-start flex-column'>
                                                                <div className='text-dark fw-bolder fs-8'>{single.campaign_name}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <div
                                                                className='text-dark fs-8'>{numSpaceFormater(Number(single.cpc).toFixed(2))}zł
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <div
                                                                className='text-dark fs-8'>{numSpaceFormater(Number(single.clicks).toFixed(2))}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <div
                                                                className='text-dark fs-8'>{numSpaceFormater(Number(single.cost).toFixed(2))} zł</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <div
                                                                className='text-dark fs-8'>{numSpaceFormater(single.conversion_value)} zł</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <div
                                                                className='text-dark fs-8'>{numSpaceFormater(single.roas)}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        })
                                    }

                                    </tbody>
                                </table>
                            ) : ''}
                        </div>
                    </div>
                </div>
            ) : ''}
        </>
    )
}

export default BingCampaignEffectiveness
