import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {KTSVG} from '../../../../../_metronic/helpers'
import {GoogleAnalyticDevices} from '../../models/Reports'
import {selectors} from '../../redux/ReportsRedux'
import ChartDonutWidget from '../Widgets/ChartDonutWidget'
import {numSpaceFormater, percentCalc} from '../../../../helpers/formater'
import {GoogleAdsDevice, GoogleCampaignsList, SEMReportDetails} from "../../models/SEMReports";

interface DevicesGroupProps {
  tabKey: string | number
}

interface CircleChartData {
  sectorName: string[]
  transactionChart: number[]
  clicksCart: number[]
  costChart: number[]
  revenueChart: number[]
  userChart: number[]
  revenuePerUserChart: number[]
}

interface CircleChartDatae {
  sectorName: string[]
  conversionValChart: number[]
  clicksCart: number[]
  costChart: number[]
  // revenueChart: number[]
  conversionsChart: number[]
  avg_conversions_value: number[]
}

const DevicesGroup: React.FC<DevicesGroupProps> = ({tabKey}) => {
  const intl = useIntl()
  const details = useSelector(selectors.getReportDetails) as SEMReportDetails

  const [deviceData, setDeviceData] = useState<GoogleAnalyticDevices[]>()
  const [circleChart, setCircleChart] = useState<CircleChartData>()
  const [circleCharte, setCircleCharte] = useState<CircleChartDatae>()

  // const [googleAdsDevice, setGoogleAdsDevice] = useState<GoogleCampaignsList>()
  const [googleAdsDevice, setGoogleAdsDevice] = useState<GoogleAdsDevice>()





  useEffect(() => {
    if(details.googleAdsDevice){
      Object.values(details.googleAdsDevice).map((key)=> {
        if(tabKey === key.id){
          setGoogleAdsDevice(key.data);
        }

      })
    }

  },[tabKey]);



  useEffect(() => {
    if(googleAdsDevice){

      let chartDatae: CircleChartDatae = {
        conversionValChart: [],
        clicksCart: [],
        costChart: [],
        // revenueChart: [],
        sectorName: [],
        conversionsChart: [],
        avg_conversions_value: [],
      }
      googleAdsDevice.map((item)=> {
        if(tabKey === item.customer_id){

          chartDatae.conversionValChart.push(Number(item.conversion_value))
          chartDatae.clicksCart.push(Number(item.clicks))
          chartDatae.costChart.push(Number(item.cost_micros))
          chartDatae.conversionsChart.push(Number(item.conversions))
          chartDatae.avg_conversions_value.push(Number(item.avg_conversions_value))
          chartDatae.sectorName.push(item.device)
          setCircleCharte(chartDatae)


        }

      })
    }


  },[googleAdsDevice]);

  // details.googleAdsDevice.map((item) => {

    // console.log(item)

    // chartData.sectorName.push(item.device_category)
    // item.transaction_revenue &&
    // chartData.transactionChart.push(Number(item.transaction_revenue))
    // item.ad_clicks && chartData.clicksCart.push(Number(item.ad_clicks))
    // item.ad_cost && chartData.costChart.push(Number(item.ad_cost))
    // item.revenue_per_transaction &&
    // chartData.revenueChart.push(Number(item.revenue_per_transaction))
    // item.users && chartData.userChart.push(Number(item.users))
    // item.revenue_per_user && chartData.revenuePerUserChart.push(Number(item.revenue_per_user))
  // })



  // useEffect(() => {
  //   if (details && details.googleAnalyticDevices) {
  //     const chartData: CircleChartData = {
  //       transactionChart: [],
  //       clicksCart: [],
  //       costChart: [],
  //       revenueChart: [],
  //       sectorName: [],
  //       userChart: [],
  //       revenuePerUserChart: [],
  //     }
  //
  //
  //     // eslint-disable-next-line array-callback-return
  //     details.googleAnalyticDevices.data.map((item) => {
  //       chartData.sectorName.push(item.device_category)
  //       item.transaction_revenue &&
  //         chartData.transactionChart.push(Number(item.transaction_revenue))
  //       item.ad_clicks && chartData.clicksCart.push(Number(item.ad_clicks))
  //       item.ad_cost && chartData.costChart.push(Number(item.ad_cost))
  //       item.revenue_per_transaction &&
  //         chartData.revenueChart.push(Number(item.revenue_per_transaction))
  //       item.users && chartData.userChart.push(Number(item.users))
  //       item.revenue_per_user && chartData.revenuePerUserChart.push(Number(item.revenue_per_user))
  //     })
  //     setCircleChart(chartData)
  //     setDeviceData(details.googleAnalyticDevices.data)
  //   }
  // }, [details])

  // const getFinalText = () => {
  //   if (deviceData && deviceData.length > 0) {
  //     const deviceMaxSession = deviceData.reduce((prev, current) => {
  //       return prev.sessions > current.sessions ? prev : current
  //     })
  //     const deviceMaxTransaction = deviceData.reduce((prev, current) => {
  //       return prev.transactions > current.transactions ? prev : current
  //     })
  //     return `Typem urządzeń generującym największy wolumen ruchu jest ${deviceMaxSession.device_category} -
  //     ${deviceMaxSession.sessions}
  //     sesji, przy czym największą ilość transakcji wygenerował ${deviceMaxTransaction.device_category} -
  //     ${deviceMaxTransaction.transactions}
  //     transakcji.`
  //   }
  // }

  return (
    <>
      <div className='border px-5 py-5 mb-5'>
        <div className='row'>
          <div className='pb-5'>
            <h3 className='fw-boldest text-sempai-green text-uppercase'>
              {intl.formatMessage({id: 'DEVICES'})}
            </h3>
          </div>
        </div>
        {tabKey && googleAdsDevice && googleAdsDevice.length > 0 ? (
          <div
            className='device-data d-flex justify-content-between flex-column flex-lg-row'
            data-testid='devices-data'
          >
            {googleAdsDevice.map((item, index) => (
              <div key={index} className='d-flex justify-content-xl-start justify-content-center'>
                <div className='w-100 d-flex justify-content-xl-center justify-content-around'>
                  <div className='d-flex justify-content-center align-items-center pe-10'>
                    {item.device === 'MOBILE' && (
                      <KTSVG
                        path='/media/sempai-images/icons/Icon-feather-phone.svg'
                        className='svg-icon-5x text-sempai-green svg-icon-white'
                        svgClassName='mh-100px'
                      />
                    )}
                    {item.device === 'TABLET' && (
                      <KTSVG
                        path='/media/sempai-images/icons/Icon-feather-tablet.svg'
                        className='svg-icon-5x text-sempai-green svg-icon-white'
                        svgClassName='mh-100px'
                      />
                    )}
                    {item.device === 'DESKTOP' && (
                      <KTSVG
                        path='/media/sempai-images/icons/Icon-feather-monitor.svg'
                        className='svg-icon-5x svg-icon-sempai-green'
                        svgClassName='mh-100px'
                      />
                    )}
                    {item.device === 'CONNECTED_TV' && (
                        <KTSVG
                            path='/media/sempai-images/icons/Icon-feather-tv.svg'
                            className='svg-icon-5x svg-icon-sempai-green'
                            svgClassName='mh-100px'
                        />
                    )}
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='mb-1'>
                      <span className='text-dark fs-7 fw-bold pe-3'>
                        {intl.formatMessage({id: 'CLICKS'})}:
                      </span>
                      <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.clicks)}</span>
                    </div>
                    <div className='mb-1'>
                      <span className='text-dark fs-7 fw-bold pe-3'>
                        {intl.formatMessage({id: 'CONVERSIONS'})}:
                      </span>
                      <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.conversions).toFixed(2))}</span>
                    </div>
                    <div className=''>
                      <span className='text-dark fs-7 fw-bold pe-3'>
                        {intl.formatMessage({id: 'COST'})}:
                      </span>
                      <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.cost_micros).toFixed(2))}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
            <div className='d-flex flex-column'>
              <div className='mb-1'>
                <h3 className='fw-boldest text-uppercase ms-5 mt-5 fs-4'>
                  {intl.formatMessage({id: 'NODATA'})}
                </h3>
              </div>
            </div>
        )}

      </div>
      {/*{(Number(single.conversions_rate)*100).toFixed(2)} %*/}

      <div className='row g-3 device-charts d-flex justify-content-between'>
      {tabKey && circleCharte && circleCharte.conversionValChart && (
          <div className='col-3' data-testid='revenue-donut-chart'>
            <div className='border'>
              <div className='pb-5'>
                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                  {intl.formatMessage({id: 'CONVERSION_VALUE'})}

                </h3>
              </div>
              <ChartDonutWidget
                  keytabchart={tabKey}
                  className='card-xxl-stretch-50 mb-5'
                  chartHeight='350px'
                  sectorsValue={circleCharte.conversionValChart}
                  sectorsName={circleCharte.sectorName}
              />
            </div>
          </div>
      )}
      {tabKey && circleCharte && circleCharte.clicksCart && (
          <div className='col-3' data-testid='revenue-donut-chart'>
            <div className='border'>
              <div className='pb-5'>
                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                  {intl.formatMessage({id: 'CLICKS'})}
                </h3>
              </div>
              <ChartDonutWidget
                  keytabchart={tabKey}
                  className='card-xxl-stretch-50 mb-5'
                  chartHeight='350px'
                  sectorsValue={circleCharte.clicksCart}
                  sectorsName={circleCharte.sectorName}
              />
            </div>
          </div>
      )}
      {tabKey && circleCharte && circleCharte.costChart && (
          <div className='col-3' data-testid='revenue-donut-chart'>
            <div className='border'>
              <div className='pb-5'>
                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                  {intl.formatMessage({id: 'COST'})}
                </h3>
              </div>
              <ChartDonutWidget
                  keytabchart={tabKey}
                  className='card-xxl-stretch-50 mb-5'
                  chartHeight='350px'
                  sectorsValue={circleCharte.costChart}
                  sectorsName={circleCharte.sectorName}
              />
            </div>
          </div>
      )}
      {tabKey && circleCharte && circleCharte.conversionsChart && (
          <div className='col-3' data-testid='revenue-donut-chart'>
            <div className='border'>
              <div className='pb-5'>
                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                  {intl.formatMessage({id: 'CONVERSIONS'})}
                </h3>
              </div>
              <ChartDonutWidget
                  keytabchart={tabKey}
                  className='card-xxl-stretch-50 mb-5'
                  chartHeight='350px'
                  sectorsValue={circleCharte.conversionsChart}
                  sectorsName={circleCharte.sectorName}
              />
            </div>
          </div>
      )}
      {tabKey && circleCharte && circleCharte.avg_conversions_value && (
          <div className='col-3' data-testid='revenue-donut-chart'>
            <div className='border'>
              <div className='pb-5'>
                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                  {intl.formatMessage({id: 'AVG_CONVERSION_VALUE'})}

                </h3>
              </div>
              <ChartDonutWidget
                  keytabchart={tabKey}
                  className='card-xxl-stretch-50 mb-5'
                  chartHeight='350px'
                  sectorsValue={circleCharte.avg_conversions_value}
                  sectorsName={circleCharte.sectorName}
              />
            </div>
          </div>
      )}
      </div>











      <div className='row g-3 device-charts d-flex justify-content-between'>
        {/*{circleChart && circleChart.transactionChart.length > 0 && (*/}
        {/*  <div className='col-3' data-testid='revenue-donut-chart'>*/}
        {/*    <div className='border'>*/}
        {/*      <div className='pb-5'>*/}
        {/*        <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>*/}
        {/*          {intl.formatMessage({id: 'TRANSACTION_REVENUE'})}*/}
        {/*        </h3>*/}
        {/*      </div>*/}
        {/*      <ChartDonutWidget*/}
        {/*        className='card-xxl-stretch-50 mb-5'*/}
        {/*        chartHeight='350px'*/}
        {/*        sectorsValue={circleChart.transactionChart}*/}
        {/*        sectorsName={circleChart.sectorName}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{circleChart && circleChart.clicksCart.length > 0 && (*/}
        {/*  <div className='col-3' data-testid='clicks-donut-chart'>*/}
        {/*    <div className='border'>*/}
        {/*      <div className='pb-5'>*/}
        {/*        <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>*/}
        {/*          {intl.formatMessage({id: 'CLICKS'})}*/}
        {/*        </h3>*/}
        {/*      </div>*/}
        {/*      <ChartDonutWidget*/}
        {/*        className='card-xxl-stretch-50 mb-5'*/}
        {/*        chartHeight='350px'*/}
        {/*        sectorsValue={circleChart.clicksCart}*/}
        {/*        sectorsName={circleChart.sectorName}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{circleChart && circleChart.costChart.length > 0 && (*/}
        {/*  <div className='col-3' data-testid='spend-donut-chart'>*/}
        {/*    <div className='border'>*/}
        {/*      <div className='pb-5'>*/}
        {/*        <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>*/}
        {/*          {intl.formatMessage({id: 'SPEND'})}*/}
        {/*        </h3>*/}
        {/*      </div>*/}
        {/*      <ChartDonutWidget*/}
        {/*        className='card-xxl-stretch-50 mb-5'*/}
        {/*        chartHeight='350px'*/}
        {/*        sectorsValue={circleChart.costChart}*/}
        {/*        sectorsName={circleChart.sectorName}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{circleChart && circleChart.revenuePerUserChart.length > 0 && (*/}
        {/*  <div className='col-3' data-testid='spend-donut-chart'>*/}
        {/*    <div className='border'>*/}
        {/*      <div className='pb-5'>*/}
        {/*        <h3 className='fw-boldest text-sempai-green text-uppercase ms-3 mt-5 fs-4'>*/}
        {/*          PRZYCHODY NA UŻYTKOWNIKA*/}
        {/*        </h3>*/}
        {/*      </div>*/}
        {/*      <ChartDonutWidget*/}
        {/*        className='card-xxl-stretch-50 mb-5'*/}
        {/*        chartHeight='350px'*/}
        {/*        sectorsValue={circleChart.revenuePerUserChart}*/}
        {/*        sectorsName={circleChart.sectorName}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{circleChart && circleChart.userChart.length > 0 && (*/}
        {/*  <div className='col-3' data-testid='spend-donut-chart'>*/}
        {/*    <div className='border'>*/}
        {/*      <div className='pb-5'>*/}
        {/*        <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>*/}
        {/*          {intl.formatMessage({id: 'USERS'})}*/}
        {/*        </h3>*/}
        {/*      </div>*/}
        {/*      <ChartDonutWidget*/}
        {/*        className='card-xxl-stretch-50 mb-5'*/}
        {/*        chartHeight='350px'*/}
        {/*        sectorsValue={circleChart.userChart}*/}
        {/*        sectorsName={circleChart.sectorName}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{circleChart && circleChart.revenueChart.length > 0 && (*/}
        {/*  <div className='col-3' data-testid='avg-order-donut-chart'>*/}
        {/*    <div className='border'>*/}
        {/*      <div className='pb-5'>*/}
        {/*        <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>*/}
        {/*          {intl.formatMessage({id: 'AVG_ORDER_VALUE'})}*/}
        {/*        </h3>*/}
        {/*      </div>*/}
        {/*      <ChartDonutWidget*/}
        {/*        className='card-xxl-stretch-50 mb-5'*/}
        {/*        chartHeight='350px'*/}
        {/*        sectorsValue={circleChart.revenueChart}*/}
        {/*        sectorsName={circleChart.sectorName}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      <div className='row pt-5'>
        {/*<div className='fs-5'>{getFinalText()}</div>*/}
      </div>
      <div className='page-slice'></div>
    </>
  )
}

export default DevicesGroup
