var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.094, 47.0069],
            [20.1305, 46.9929],
            [20.1608, 46.9171],
            [20.0883, 46.8768],
            [20.007, 46.8501],
            [20.0054, 46.8103],
            [20.0617, 46.8042],
            [19.968, 46.7279],
            [20.0113, 46.6957],
            [19.9806, 46.637],
            [19.994, 46.5913],
            [19.9419, 46.5493],
            [19.8897, 46.5791],
            [19.7788, 46.5309],
            [19.8482, 46.511],
            [19.8937, 46.4804],
            [19.9003, 46.42],
            [19.8566, 46.3716],
            [19.8185, 46.3894],
            [19.7431, 46.3491],
            [19.721, 46.361],
            [19.655, 46.3464],
            [19.6904, 46.3046],
            [19.6357, 46.2639],
            [19.6965, 46.1873],
            [19.6338, 46.1701],
            [19.5657, 46.1777],
            [19.5056, 46.144],
            [19.5257, 46.1191],
            [19.4661, 46.0951],
            [19.4163, 46.046],
            [19.3655, 46.0511],
            [19.2841, 46.015],
            [19.2866, 45.997],
            [19.149, 45.9968],
            [19.1075, 46.0393],
            [19.0669, 46.0001],
            [19.0808, 45.9645],
            [19.0143, 45.9636],
            [18.9873, 45.9321],
            [18.9074, 45.9351],
            [18.868, 45.9103],
            [18.8135, 45.9086],
            [18.8057, 45.9311],
            [18.8671, 46.0175],
            [18.7797, 46.0275],
            [18.7445, 46.0627],
            [18.7845, 46.1088],
            [18.8158, 46.1099],
            [18.8493, 46.1879],
            [18.8213, 46.2079],
            [18.9106, 46.2608],
            [18.9216, 46.3062],
            [18.8981, 46.3337],
            [18.8847, 46.3969],
            [18.9207, 46.4498],
            [18.892, 46.5649],
            [18.8595, 46.6113],
            [18.8826, 46.636],
            [18.9667, 46.6558],
            [19.0027, 46.6937],
            [18.9717, 46.774],
            [18.925, 46.8167],
            [18.9233, 46.8579],
            [18.9658, 46.9356],
            [18.9504, 46.9799],
            [18.9669, 47.0284],
            [18.9562, 47.0641],
            [19.0676, 47.034],
            [19.1625, 47.0968],
            [19.2868, 47.1341],
            [19.3463, 47.0934],
            [19.2948, 47.036],
            [19.3359, 46.996],
            [19.4271, 47.0636],
            [19.4757, 47.0539],
            [19.5053, 47.1202],
            [19.619, 47.0823],
            [19.6421, 47.0313],
            [19.6868, 46.9967],
            [19.78, 46.9493],
            [19.84, 46.9439],
            [19.8758, 46.9959],
            [19.9834, 46.9623],
            [19.9977, 46.9989],
            [20.0686, 47.0183],
            [20.094, 47.0069],
          ],
        ],
      },
      properties: {name: 'Bács-Kiskun', id: 'HU-BK', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-BK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.7845, 46.1088],
            [18.7445, 46.0627],
            [18.7797, 46.0275],
            [18.8671, 46.0175],
            [18.8057, 45.9311],
            [18.8135, 45.9086],
            [18.7621, 45.8889],
            [18.7059, 45.9168],
            [18.6719, 45.9106],
            [18.6545, 45.8749],
            [18.5744, 45.8145],
            [18.4821, 45.7658],
            [18.4074, 45.7405],
            [18.3648, 45.7732],
            [18.3343, 45.7533],
            [18.2445, 45.762],
            [18.2303, 45.779],
            [18.1229, 45.7892],
            [18.0827, 45.765],
            [17.9984, 45.7943],
            [17.8499, 45.7804],
            [17.7786, 45.8154],
            [17.6518, 45.8465],
            [17.6857, 45.872],
            [17.7339, 45.8764],
            [17.6981, 45.9386],
            [17.6441, 45.9359],
            [17.6244, 45.9851],
            [17.6554, 46.006],
            [17.6291, 46.044],
            [17.6738, 46.0621],
            [17.687, 46.1572],
            [17.7253, 46.2071],
            [17.8438, 46.2082],
            [17.9298, 46.2295],
            [17.9501, 46.3052],
            [18.0525, 46.2973],
            [18.1996, 46.3381],
            [18.1839, 46.3954],
            [18.2664, 46.4013],
            [18.3135, 46.4167],
            [18.3448, 46.3976],
            [18.35, 46.3603],
            [18.3863, 46.3473],
            [18.3747, 46.2934],
            [18.4199, 46.2788],
            [18.4452, 46.2447],
            [18.5068, 46.2903],
            [18.5412, 46.2516],
            [18.5302, 46.2296],
            [18.5892, 46.182],
            [18.6458, 46.1629],
            [18.7501, 46.1082],
            [18.7845, 46.1088],
          ],
        ],
      },
      properties: {name: 'Baranya', id: 'HU-BA', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-BA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.658, 47.0234],
            [21.676, 46.9961],
            [21.637, 46.9329],
            [21.5992, 46.9269],
            [21.6007, 46.8637],
            [21.5208, 46.8364],
            [21.5195, 46.8003],
            [21.4848, 46.7581],
            [21.528, 46.7191],
            [21.4925, 46.6847],
            [21.4353, 46.6761],
            [21.4101, 46.6217],
            [21.3269, 46.628],
            [21.3198, 46.5812],
            [21.2752, 46.541],
            [21.2605, 46.5003],
            [21.3159, 46.4513],
            [21.2889, 46.4144],
            [21.2057, 46.4013],
            [21.1801, 46.304],
            [21.1134, 46.2997],
            [21.1016, 46.2618],
            [21.0625, 46.2432],
            [21.0224, 46.2657],
            [20.9162, 46.2614],
            [20.8726, 46.2864],
            [20.7761, 46.2741],
            [20.7491, 46.2885],
            [20.7586, 46.3497],
            [20.7392, 46.3837],
            [20.6926, 46.3787],
            [20.6825, 46.3503],
            [20.5773, 46.3659],
            [20.5812, 46.4525],
            [20.6149, 46.527],
            [20.5798, 46.5656],
            [20.5804, 46.7238],
            [20.5967, 46.7571],
            [20.5636, 46.7749],
            [20.5067, 46.7745],
            [20.4519, 46.8111],
            [20.4216, 46.8029],
            [20.4064, 46.8579],
            [20.4566, 46.8963],
            [20.5123, 46.8947],
            [20.5494, 46.9168],
            [20.6403, 46.9],
            [20.702, 46.9163],
            [20.7237, 46.9393],
            [20.7077, 47.0325],
            [20.8069, 47.0361],
            [20.8531, 47.0897],
            [20.846, 47.1482],
            [20.9445, 47.1871],
            [20.9755, 47.2302],
            [21.019, 47.225],
            [21.1267, 47.1733],
            [21.1987, 47.1668],
            [21.2287, 47.1491],
            [21.3013, 47.1517],
            [21.2916, 47.0874],
            [21.2336, 47.0579],
            [21.2472, 47.0081],
            [21.355, 46.9781],
            [21.4111, 46.9777],
            [21.4707, 46.9481],
            [21.5661, 46.96],
            [21.6023, 46.9966],
            [21.658, 47.0234],
          ],
        ],
      },
      properties: {name: 'Békés', id: 'HU-BE', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-BE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.1201, 48.3784],
            [22.0679, 48.2995],
            [21.983, 48.2916],
            [21.9192, 48.2391],
            [21.8959, 48.2455],
            [21.7996, 48.2247],
            [21.7512, 48.1907],
            [21.612, 48.1952],
            [21.5771, 48.2089],
            [21.516, 48.1855],
            [21.4419, 48.1784],
            [21.4134, 48.1223],
            [21.4494, 48.091],
            [21.4357, 48.0476],
            [21.4063, 48.0335],
            [21.2951, 48.0391],
            [21.128, 48.0385],
            [21.0779, 48.0052],
            [21.121, 47.9614],
            [21.1244, 47.913],
            [21.0827, 47.891],
            [21.0551, 47.853],
            [21.0784, 47.8198],
            [21.0426, 47.7525],
            [20.9986, 47.7218],
            [20.9488, 47.7148],
            [20.8896, 47.6709],
            [20.8277, 47.6682],
            [20.7701, 47.6541],
            [20.705, 47.6888],
            [20.6391, 47.6867],
            [20.5718, 47.7185],
            [20.5947, 47.7393],
            [20.5044, 47.8013],
            [20.5041, 47.8623],
            [20.4478, 47.8944],
            [20.5186, 47.9285],
            [20.45, 47.969],
            [20.4897, 48.0228],
            [20.4792, 48.1162],
            [20.4501, 48.1506],
            [20.3877, 48.1494],
            [20.358, 48.0921],
            [20.3178, 48.1227],
            [20.2791, 48.0975],
            [20.2133, 48.094],
            [20.1628, 48.1352],
            [20.0919, 48.1431],
            [20.0505, 48.1684],
            [20.1321, 48.2232],
            [20.1408, 48.2435],
            [20.208, 48.2516],
            [20.2416, 48.2807],
            [20.2869, 48.262],
            [20.4118, 48.3665],
            [20.4189, 48.4206],
            [20.5095, 48.4896],
            [20.5039, 48.5303],
            [20.5874, 48.5346],
            [20.659, 48.5609],
            [20.8465, 48.5841],
            [20.8675, 48.5521],
            [20.9191, 48.5583],
            [20.9587, 48.5231],
            [21.0153, 48.5328],
            [21.1506, 48.5168],
            [21.2403, 48.5399],
            [21.3066, 48.5234],
            [21.322, 48.5618],
            [21.4163, 48.5596],
            [21.4508, 48.5756],
            [21.5165, 48.5502],
            [21.5435, 48.5082],
            [21.6137, 48.5105],
            [21.6233, 48.4689],
            [21.7144, 48.3549],
            [21.8351, 48.3357],
            [21.8805, 48.3625],
            [22.0296, 48.3925],
            [22.1201, 48.3784],
          ],
        ],
      },
      properties: {name: 'Borsod-Abaúji-Zemplén', id: 'HU-BZ', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-BZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.1095, 47.6105],
            [19.1419, 47.5973],
            [19.1926, 47.5395],
            [19.2462, 47.537],
            [19.2649, 47.5084],
            [19.3199, 47.5153],
            [19.3354, 47.4635],
            [19.2664, 47.4378],
            [19.1802, 47.3609],
            [19.1403, 47.3493],
            [19.0833, 47.4043],
            [18.9577, 47.3752],
            [18.9574, 47.4196],
            [18.9824, 47.4613],
            [18.936, 47.4916],
            [18.9295, 47.5719],
            [19.0343, 47.587],
            [19.0426, 47.6105],
            [19.1095, 47.6105],
          ],
        ],
      },
      properties: {name: 'Budapest', id: 'HU-BU', CNTRY: 'Hungary', TYPE: 'Capital City'},
      id: 'HU-BU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.7761, 46.2741],
            [20.751, 46.2469],
            [20.7142, 46.1646],
            [20.6397, 46.1256],
            [20.5453, 46.1785],
            [20.4642, 46.1449],
            [20.3544, 46.169],
            [20.2885, 46.1452],
            [20.2552, 46.1159],
            [20.1813, 46.1587],
            [20.1399, 46.1445],
            [20.0923, 46.1727],
            [20.0648, 46.1451],
            [20.0244, 46.1702],
            [19.9321, 46.1752],
            [19.8178, 46.1279],
            [19.7604, 46.146],
            [19.6965, 46.1873],
            [19.6357, 46.2639],
            [19.6904, 46.3046],
            [19.655, 46.3464],
            [19.721, 46.361],
            [19.7431, 46.3491],
            [19.8185, 46.3894],
            [19.8566, 46.3716],
            [19.9003, 46.42],
            [19.8937, 46.4804],
            [19.8482, 46.511],
            [19.7788, 46.5309],
            [19.8897, 46.5791],
            [19.9419, 46.5493],
            [19.994, 46.5913],
            [19.9806, 46.637],
            [20.0113, 46.6957],
            [19.968, 46.7279],
            [20.0617, 46.8042],
            [20.1742, 46.753],
            [20.2332, 46.7983],
            [20.2922, 46.7674],
            [20.4216, 46.8029],
            [20.4519, 46.8111],
            [20.5067, 46.7745],
            [20.5636, 46.7749],
            [20.5967, 46.7571],
            [20.5804, 46.7238],
            [20.5798, 46.5656],
            [20.6149, 46.527],
            [20.5812, 46.4525],
            [20.5773, 46.3659],
            [20.6825, 46.3503],
            [20.6926, 46.3787],
            [20.7392, 46.3837],
            [20.7586, 46.3497],
            [20.7491, 46.2885],
            [20.7761, 46.2741],
          ],
        ],
      },
      properties: {name: 'Csongrád', id: 'HU-CS', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-CS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.9233, 46.8579],
            [18.86, 46.848],
            [18.8619, 46.8213],
            [18.7171, 46.7513],
            [18.7311, 46.7282],
            [18.6779, 46.6949],
            [18.6314, 46.7066],
            [18.5551, 46.7833],
            [18.4657, 46.7413],
            [18.4046, 46.7701],
            [18.3057, 46.7971],
            [18.2097, 46.7781],
            [18.2131, 46.8875],
            [18.161, 46.9584],
            [18.2331, 47.0073],
            [18.2032, 47.0784],
            [18.2337, 47.0949],
            [18.2115, 47.1566],
            [18.2235, 47.2156],
            [18.1573, 47.2652],
            [18.1066, 47.2488],
            [18.0572, 47.2773],
            [18.0363, 47.3343],
            [18.1067, 47.3994],
            [18.1153, 47.445],
            [18.1528, 47.4401],
            [18.2422, 47.4585],
            [18.2477, 47.4356],
            [18.3121, 47.4006],
            [18.3759, 47.4179],
            [18.4257, 47.4848],
            [18.4506, 47.4926],
            [18.4871, 47.5121],
            [18.4848, 47.5503],
            [18.5743, 47.5744],
            [18.6149, 47.5645],
            [18.6888, 47.5781],
            [18.6948, 47.5319],
            [18.7274, 47.4919],
            [18.7849, 47.4578],
            [18.7573, 47.403],
            [18.8619, 47.3204],
            [18.8802, 47.2789],
            [18.9197, 47.2735],
            [18.8823, 47.1974],
            [18.8737, 47.1514],
            [18.8999, 47.0744],
            [18.9669, 47.0284],
            [18.9504, 46.9799],
            [18.9658, 46.9356],
            [18.9233, 46.8579],
          ],
        ],
      },
      properties: {name: 'Fejér', id: 'HU-FE', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-FE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.1285, 47.5969],
            [22.0955, 47.56],
            [22.02, 47.5159],
            [22.0064, 47.4758],
            [22.0333, 47.4118],
            [22.0087, 47.3764],
            [21.9388, 47.373],
            [21.8504, 47.2369],
            [21.8573, 47.1876],
            [21.8162, 47.1713],
            [21.7901, 47.1229],
            [21.7251, 47.0978],
            [21.658, 47.0234],
            [21.6023, 46.9966],
            [21.5661, 46.96],
            [21.4707, 46.9481],
            [21.4111, 46.9777],
            [21.355, 46.9781],
            [21.2472, 47.0081],
            [21.2336, 47.0579],
            [21.2916, 47.0874],
            [21.3013, 47.1517],
            [21.2287, 47.1491],
            [21.1987, 47.1668],
            [21.1267, 47.1733],
            [21.019, 47.225],
            [20.9755, 47.2302],
            [21.0196, 47.2517],
            [21.0312, 47.3424],
            [20.993, 47.4712],
            [20.989, 47.5661],
            [20.9431, 47.5955],
            [20.8901, 47.5811],
            [20.8413, 47.5961],
            [20.8277, 47.6682],
            [20.8896, 47.6709],
            [20.9488, 47.7148],
            [20.9986, 47.7218],
            [21.0426, 47.7525],
            [21.0784, 47.8198],
            [21.0551, 47.853],
            [21.0827, 47.891],
            [21.1244, 47.913],
            [21.121, 47.9614],
            [21.1938, 47.9575],
            [21.2592, 47.9256],
            [21.374, 47.9164],
            [21.4291, 47.9276],
            [21.564, 47.929],
            [21.5643, 47.8876],
            [21.5419, 47.7911],
            [21.5999, 47.7724],
            [21.6376, 47.7277],
            [21.7052, 47.7308],
            [21.7186, 47.7063],
            [21.8313, 47.6664],
            [21.924, 47.7314],
            [21.9852, 47.7025],
            [22.0014, 47.6438],
            [22.0406, 47.6329],
            [22.0633, 47.6693],
            [22.102, 47.6586],
            [22.1285, 47.5969],
          ],
        ],
      },
      properties: {name: 'Hajdú-Bihar', id: 'HU-HB', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-HB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.7701, 47.6541],
            [20.7146, 47.6325],
            [20.7027, 47.5744],
            [20.5762, 47.5133],
            [20.5206, 47.5002],
            [20.4835, 47.4621],
            [20.4005, 47.422],
            [20.3343, 47.4413],
            [20.2883, 47.4855],
            [20.2879, 47.539],
            [20.2286, 47.5618],
            [20.2058, 47.608],
            [20.1003, 47.5895],
            [20.1099, 47.5533],
            [20.0065, 47.5936],
            [19.9957, 47.673],
            [19.9144, 47.655],
            [19.8176, 47.5949],
            [19.7526, 47.6208],
            [19.6661, 47.5882],
            [19.6385, 47.6462],
            [19.5907, 47.688],
            [19.5711, 47.7363],
            [19.6464, 47.7803],
            [19.6318, 47.8062],
            [19.714, 47.8248],
            [19.7611, 47.8653],
            [19.7766, 47.9044],
            [19.828, 47.8733],
            [19.8723, 47.9304],
            [19.9264, 47.9178],
            [19.9672, 47.9515],
            [19.9815, 47.9978],
            [20.0134, 48.021],
            [19.9685, 48.0895],
            [20.0372, 48.1056],
            [20.0919, 48.1431],
            [20.1628, 48.1352],
            [20.2133, 48.094],
            [20.2791, 48.0975],
            [20.3178, 48.1227],
            [20.358, 48.0921],
            [20.3877, 48.1494],
            [20.4501, 48.1506],
            [20.4792, 48.1162],
            [20.4897, 48.0228],
            [20.45, 47.969],
            [20.5186, 47.9285],
            [20.4478, 47.8944],
            [20.5041, 47.8623],
            [20.5044, 47.8013],
            [20.5947, 47.7393],
            [20.5718, 47.7185],
            [20.6391, 47.6867],
            [20.705, 47.6888],
            [20.7701, 47.6541],
          ],
        ],
      },
      properties: {name: 'Heves', id: 'HU-HE', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-HE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.8277, 47.6682],
            [20.8413, 47.5961],
            [20.8901, 47.5811],
            [20.9431, 47.5955],
            [20.989, 47.5661],
            [20.993, 47.4712],
            [21.0312, 47.3424],
            [21.0196, 47.2517],
            [20.9755, 47.2302],
            [20.9445, 47.1871],
            [20.846, 47.1482],
            [20.8531, 47.0897],
            [20.8069, 47.0361],
            [20.7077, 47.0325],
            [20.7237, 46.9393],
            [20.702, 46.9163],
            [20.6403, 46.9],
            [20.5494, 46.9168],
            [20.5123, 46.8947],
            [20.4566, 46.8963],
            [20.4064, 46.8579],
            [20.4216, 46.8029],
            [20.2922, 46.7674],
            [20.2332, 46.7983],
            [20.1742, 46.753],
            [20.0617, 46.8042],
            [20.0054, 46.8103],
            [20.007, 46.8501],
            [20.0883, 46.8768],
            [20.1608, 46.9171],
            [20.1305, 46.9929],
            [20.094, 47.0069],
            [20.0794, 47.0759],
            [20.048, 47.1254],
            [20.1005, 47.1811],
            [20.0797, 47.2203],
            [19.9878, 47.252],
            [20.018, 47.3029],
            [19.989, 47.3472],
            [19.8298, 47.4278],
            [19.7483, 47.4897],
            [19.6517, 47.547],
            [19.6661, 47.5882],
            [19.7526, 47.6208],
            [19.8176, 47.5949],
            [19.9144, 47.655],
            [19.9957, 47.673],
            [20.0065, 47.5936],
            [20.1099, 47.5533],
            [20.1003, 47.5895],
            [20.2058, 47.608],
            [20.2286, 47.5618],
            [20.2879, 47.539],
            [20.2883, 47.4855],
            [20.3343, 47.4413],
            [20.4005, 47.422],
            [20.4835, 47.4621],
            [20.5206, 47.5002],
            [20.5762, 47.5133],
            [20.7027, 47.5744],
            [20.7146, 47.6325],
            [20.7701, 47.6541],
            [20.8277, 47.6682],
          ],
        ],
      },
      properties: {name: 'Jász-Nagykun-Szolnok', id: 'HU-JN', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-JN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.4506, 47.4926],
            [18.4257, 47.4848],
            [18.3759, 47.4179],
            [18.3121, 47.4006],
            [18.2477, 47.4356],
            [18.2422, 47.4585],
            [18.1528, 47.4401],
            [18.1153, 47.445],
            [18.1067, 47.3994],
            [18.0363, 47.3343],
            [17.9616, 47.3337],
            [17.9341, 47.3865],
            [17.8788, 47.3922],
            [17.9009, 47.4252],
            [17.8714, 47.4567],
            [17.9109, 47.5047],
            [17.883, 47.5486],
            [17.9204, 47.5806],
            [17.9313, 47.6169],
            [17.8886, 47.6372],
            [17.9046, 47.7014],
            [17.8953, 47.7398],
            [18.076, 47.7568],
            [18.2927, 47.7315],
            [18.4534, 47.7648],
            [18.4975, 47.7524],
            [18.5424, 47.7648],
            [18.6471, 47.7591],
            [18.7225, 47.7854],
            [18.7397, 47.8129],
            [18.8438, 47.8175],
            [18.9011, 47.8007],
            [18.941, 47.7252],
            [18.851, 47.7133],
            [18.8622, 47.6522],
            [18.7808, 47.6393],
            [18.6888, 47.5781],
            [18.6149, 47.5645],
            [18.5743, 47.5744],
            [18.4848, 47.5503],
            [18.4871, 47.5121],
            [18.4506, 47.4926],
          ],
        ],
      },
      properties: {name: 'Komárom-Esztergom', id: 'HU-KE', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-KE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.0505, 48.1684],
            [20.0919, 48.1431],
            [20.0372, 48.1056],
            [19.9685, 48.0895],
            [20.0134, 48.021],
            [19.9815, 47.9978],
            [19.9672, 47.9515],
            [19.9264, 47.9178],
            [19.8723, 47.9304],
            [19.828, 47.8733],
            [19.7766, 47.9044],
            [19.7611, 47.8653],
            [19.714, 47.8248],
            [19.6318, 47.8062],
            [19.6464, 47.7803],
            [19.5711, 47.7363],
            [19.5407, 47.7467],
            [19.512, 47.6979],
            [19.4795, 47.6882],
            [19.4501, 47.7264],
            [19.4241, 47.817],
            [19.3238, 47.8322],
            [19.2301, 47.8251],
            [19.1753, 47.8506],
            [19.0803, 47.8425],
            [19.0424, 47.8799],
            [18.9425, 47.9383],
            [18.9921, 47.9778],
            [19.0081, 48.0162],
            [18.9585, 48.0298],
            [18.9308, 48.0578],
            [18.9839, 48.0562],
            [19.0179, 48.0775],
            [19.0606, 48.0589],
            [19.1353, 48.0733],
            [19.1891, 48.0591],
            [19.3001, 48.0873],
            [19.4241, 48.0875],
            [19.4916, 48.1001],
            [19.5282, 48.203],
            [19.5825, 48.2177],
            [19.6333, 48.2501],
            [19.6827, 48.2141],
            [19.7639, 48.2141],
            [19.8232, 48.1704],
            [19.9011, 48.1668],
            [19.941, 48.1338],
            [19.9782, 48.166],
            [20.0505, 48.1684],
          ],
        ],
      },
      properties: {name: 'Nógrád', id: 'HU-NO', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-NO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.9308, 48.0578],
            [18.9585, 48.0298],
            [19.0081, 48.0162],
            [18.9921, 47.9778],
            [18.9425, 47.9383],
            [19.0424, 47.8799],
            [19.0803, 47.8425],
            [19.1753, 47.8506],
            [19.2301, 47.8251],
            [19.3238, 47.8322],
            [19.4241, 47.817],
            [19.4501, 47.7264],
            [19.4795, 47.6882],
            [19.512, 47.6979],
            [19.5407, 47.7467],
            [19.5711, 47.7363],
            [19.5907, 47.688],
            [19.6385, 47.6462],
            [19.6661, 47.5882],
            [19.6517, 47.547],
            [19.7483, 47.4897],
            [19.8298, 47.4278],
            [19.989, 47.3472],
            [20.018, 47.3029],
            [19.9878, 47.252],
            [20.0797, 47.2203],
            [20.1005, 47.1811],
            [20.048, 47.1254],
            [20.0794, 47.0759],
            [20.094, 47.0069],
            [20.0686, 47.0183],
            [19.9977, 46.9989],
            [19.9834, 46.9623],
            [19.8758, 46.9959],
            [19.84, 46.9439],
            [19.78, 46.9493],
            [19.6868, 46.9967],
            [19.6421, 47.0313],
            [19.619, 47.0823],
            [19.5053, 47.1202],
            [19.4757, 47.0539],
            [19.4271, 47.0636],
            [19.3359, 46.996],
            [19.2948, 47.036],
            [19.3463, 47.0934],
            [19.2868, 47.1341],
            [19.1625, 47.0968],
            [19.0676, 47.034],
            [18.9562, 47.0641],
            [18.9669, 47.0284],
            [18.8999, 47.0744],
            [18.8737, 47.1514],
            [18.8823, 47.1974],
            [18.9197, 47.2735],
            [18.8802, 47.2789],
            [18.8619, 47.3204],
            [18.7573, 47.403],
            [18.7849, 47.4578],
            [18.7274, 47.4919],
            [18.6948, 47.5319],
            [18.6888, 47.5781],
            [18.7808, 47.6393],
            [18.8622, 47.6522],
            [18.851, 47.7133],
            [18.941, 47.7252],
            [18.9011, 47.8007],
            [18.8438, 47.8175],
            [18.8238, 47.8505],
            [18.7641, 47.8704],
            [18.7769, 47.932],
            [18.7597, 47.9737],
            [18.8182, 47.9935],
            [18.8241, 48.0377],
            [18.9308, 48.0578],
          ],
          [
            [19.1095, 47.6105],
            [19.0426, 47.6105],
            [19.0343, 47.587],
            [18.9295, 47.5719],
            [18.936, 47.4916],
            [18.9824, 47.4613],
            [18.9574, 47.4196],
            [18.9577, 47.3752],
            [19.0833, 47.4043],
            [19.1403, 47.3493],
            [19.1802, 47.3609],
            [19.2664, 47.4378],
            [19.3354, 47.4635],
            [19.3199, 47.5153],
            [19.2649, 47.5084],
            [19.2462, 47.537],
            [19.1926, 47.5395],
            [19.1419, 47.5973],
            [19.1095, 47.6105],
          ],
        ],
      },
      properties: {name: 'Pest', id: 'HU-PE', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-PE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.161, 46.9584],
            [18.2131, 46.8875],
            [18.2097, 46.7781],
            [18.1668, 46.7672],
            [18.1121, 46.7304],
            [18.127, 46.6766],
            [18.0716, 46.6213],
            [18.0721, 46.5644],
            [18.0374, 46.5597],
            [18.0394, 46.4953],
            [18.0686, 46.4349],
            [18.021, 46.4039],
            [18.0804, 46.3679],
            [18.0861, 46.3298],
            [18.0525, 46.2973],
            [17.9501, 46.3052],
            [17.9298, 46.2295],
            [17.8438, 46.2082],
            [17.7253, 46.2071],
            [17.687, 46.1572],
            [17.6738, 46.0621],
            [17.6291, 46.044],
            [17.6554, 46.006],
            [17.6244, 45.9851],
            [17.6441, 45.9359],
            [17.6981, 45.9386],
            [17.7339, 45.8764],
            [17.6857, 45.872],
            [17.6518, 45.8465],
            [17.6217, 45.9027],
            [17.566, 45.9361],
            [17.4345, 45.9495],
            [17.3859, 45.9321],
            [17.3371, 45.9937],
            [17.2939, 45.9908],
            [17.258, 46.0133],
            [17.2572, 46.0457],
            [17.1774, 46.1072],
            [17.1822, 46.1499],
            [17.0105, 46.2203],
            [16.9751, 46.2241],
            [16.8844, 46.2755],
            [16.8735, 46.3259],
            [16.9011, 46.3111],
            [17.0133, 46.3047],
            [17.0584, 46.3493],
            [17.0431, 46.3879],
            [17.083, 46.4193],
            [17.1395, 46.4088],
            [17.1862, 46.4306],
            [17.1892, 46.4853],
            [17.2247, 46.5114],
            [17.222, 46.6659],
            [17.2569, 46.6885],
            [17.2687, 46.7338],
            [17.3634, 46.7308],
            [17.4188, 46.7501],
            [17.4886, 46.7525],
            [17.6713, 46.8136],
            [17.7109, 46.8339],
            [17.9097, 46.8916],
            [17.9091, 46.9172],
            [18.0603, 46.965],
            [18.161, 46.9584],
          ],
        ],
      },
      properties: {name: 'Somogy', id: 'HU-SO', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-SO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.8953, 47.7398],
            [17.9046, 47.7014],
            [17.8886, 47.6372],
            [17.9313, 47.6169],
            [17.9204, 47.5806],
            [17.883, 47.5486],
            [17.9109, 47.5047],
            [17.8714, 47.4567],
            [17.9009, 47.4252],
            [17.8788, 47.3922],
            [17.8156, 47.3635],
            [17.8061, 47.3218],
            [17.7192, 47.3218],
            [17.7145, 47.3551],
            [17.7549, 47.373],
            [17.7799, 47.4099],
            [17.7537, 47.4566],
            [17.7019, 47.4711],
            [17.6405, 47.4517],
            [17.5598, 47.4622],
            [17.4932, 47.4527],
            [17.4694, 47.4218],
            [17.4301, 47.4313],
            [17.406, 47.4797],
            [17.3646, 47.4425],
            [17.2872, 47.4318],
            [17.1949, 47.4436],
            [17.174, 47.4317],
            [17.0675, 47.4051],
            [17.0529, 47.4601],
            [16.9432, 47.395],
            [16.8831, 47.4134],
            [16.8483, 47.4484],
            [16.7823, 47.4427],
            [16.7137, 47.4112],
            [16.6456, 47.4458],
            [16.7151, 47.5393],
            [16.6531, 47.622],
            [16.5906, 47.6172],
            [16.5153, 47.6456],
            [16.4235, 47.6651],
            [16.552, 47.7217],
            [16.5484, 47.7508],
            [16.6076, 47.7607],
            [16.7202, 47.7325],
            [16.747, 47.684],
            [16.8282, 47.6825],
            [17.0917, 47.708],
            [17.0715, 47.727],
            [17.0534, 47.8377],
            [17.0121, 47.8589],
            [17.1021, 47.8938],
            [17.118, 47.9601],
            [17.1733, 48.0119],
            [17.2438, 48.0222],
            [17.2672, 47.9964],
            [17.3349, 47.994],
            [17.4284, 47.9262],
            [17.4574, 47.8841],
            [17.5317, 47.8629],
            [17.5657, 47.8181],
            [17.6098, 47.8223],
            [17.7127, 47.757],
            [17.7837, 47.7399],
            [17.8953, 47.7398],
          ],
        ],
      },
      properties: {name: 'Győr-Moson-Sopron', id: 'HU-GS', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-GS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.1285, 47.5969],
            [22.102, 47.6586],
            [22.0633, 47.6693],
            [22.0406, 47.6329],
            [22.0014, 47.6438],
            [21.9852, 47.7025],
            [21.924, 47.7314],
            [21.8313, 47.6664],
            [21.7186, 47.7063],
            [21.7052, 47.7308],
            [21.6376, 47.7277],
            [21.5999, 47.7724],
            [21.5419, 47.7911],
            [21.5643, 47.8876],
            [21.564, 47.929],
            [21.4291, 47.9276],
            [21.374, 47.9164],
            [21.2592, 47.9256],
            [21.1938, 47.9575],
            [21.121, 47.9614],
            [21.0779, 48.0052],
            [21.128, 48.0385],
            [21.2951, 48.0391],
            [21.4063, 48.0335],
            [21.4357, 48.0476],
            [21.4494, 48.091],
            [21.4134, 48.1223],
            [21.4419, 48.1784],
            [21.516, 48.1855],
            [21.5771, 48.2089],
            [21.612, 48.1952],
            [21.7512, 48.1907],
            [21.7996, 48.2247],
            [21.8959, 48.2455],
            [21.9192, 48.2391],
            [21.983, 48.2916],
            [22.0679, 48.2995],
            [22.1201, 48.3784],
            [22.2048, 48.4261],
            [22.2374, 48.4109],
            [22.2678, 48.3602],
            [22.3131, 48.3257],
            [22.3394, 48.2805],
            [22.4012, 48.2511],
            [22.4978, 48.241],
            [22.571, 48.1976],
            [22.5992, 48.1184],
            [22.6716, 48.0921],
            [22.7376, 48.1204],
            [22.8033, 48.1226],
            [22.8829, 48.0532],
            [22.838, 47.9893],
            [22.8908, 47.9688],
            [22.8433, 47.9077],
            [22.7565, 47.8916],
            [22.7748, 47.842],
            [22.7114, 47.8346],
            [22.6797, 47.7875],
            [22.6096, 47.7716],
            [22.5489, 47.7721],
            [22.4814, 47.8103],
            [22.4479, 47.8034],
            [22.4256, 47.7503],
            [22.3548, 47.7493],
            [22.2637, 47.7304],
            [22.2272, 47.6904],
            [22.1739, 47.5929],
            [22.1285, 47.5969],
          ],
        ],
      },
      properties: {name: 'Szalbolcs-Szatmár-Bereg', id: 'HU-SZ', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-SZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.174, 47.4317],
            [17.188, 47.3814],
            [17.294, 47.3271],
            [17.2249, 47.3087],
            [17.2005, 47.2733],
            [17.2083, 47.1765],
            [17.1958, 47.1435],
            [17.2153, 47.0809],
            [17.0751, 47.0658],
            [17.0729, 47.0473],
            [17.0033, 47.0355],
            [17.0172, 47.0013],
            [16.9808, 46.9616],
            [16.846, 46.9786],
            [16.7783, 46.9253],
            [16.6694, 46.9355],
            [16.631, 46.9254],
            [16.5676, 46.8562],
            [16.5022, 46.8331],
            [16.5018, 46.8036],
            [16.4444, 46.7807],
            [16.4036, 46.7874],
            [16.3694, 46.7224],
            [16.3182, 46.7558],
            [16.3471, 46.8383],
            [16.294, 46.872],
            [16.2373, 46.8758],
            [16.1565, 46.8545],
            [16.1327, 46.8753],
            [16.2742, 46.962],
            [16.2766, 46.9864],
            [16.3493, 47.0091],
            [16.3724, 46.9976],
            [16.449, 47.0045],
            [16.5204, 47.0567],
            [16.4623, 47.0932],
            [16.5295, 47.1259],
            [16.5164, 47.1474],
            [16.4281, 47.1843],
            [16.441, 47.2438],
            [16.4895, 47.2799],
            [16.4528, 47.3563],
            [16.4471, 47.4072],
            [16.5739, 47.4051],
            [16.6456, 47.4458],
            [16.7137, 47.4112],
            [16.7823, 47.4427],
            [16.8483, 47.4484],
            [16.8831, 47.4134],
            [16.9432, 47.395],
            [17.0529, 47.4601],
            [17.0675, 47.4051],
            [17.174, 47.4317],
          ],
        ],
      },
      properties: {name: 'Vas', id: 'HU-VA', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-VA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.2097, 46.7781],
            [18.3057, 46.7971],
            [18.4046, 46.7701],
            [18.4657, 46.7413],
            [18.5551, 46.7833],
            [18.6314, 46.7066],
            [18.6779, 46.6949],
            [18.7311, 46.7282],
            [18.7171, 46.7513],
            [18.8619, 46.8213],
            [18.86, 46.848],
            [18.9233, 46.8579],
            [18.925, 46.8167],
            [18.9717, 46.774],
            [19.0027, 46.6937],
            [18.9667, 46.6558],
            [18.8826, 46.636],
            [18.8595, 46.6113],
            [18.892, 46.5649],
            [18.9207, 46.4498],
            [18.8847, 46.3969],
            [18.8981, 46.3337],
            [18.9216, 46.3062],
            [18.9106, 46.2608],
            [18.8213, 46.2079],
            [18.8493, 46.1879],
            [18.8158, 46.1099],
            [18.7845, 46.1088],
            [18.7501, 46.1082],
            [18.6458, 46.1629],
            [18.5892, 46.182],
            [18.5302, 46.2296],
            [18.5412, 46.2516],
            [18.5068, 46.2903],
            [18.4452, 46.2447],
            [18.4199, 46.2788],
            [18.3747, 46.2934],
            [18.3863, 46.3473],
            [18.35, 46.3603],
            [18.3448, 46.3976],
            [18.3135, 46.4167],
            [18.2664, 46.4013],
            [18.1839, 46.3954],
            [18.1996, 46.3381],
            [18.0525, 46.2973],
            [18.0861, 46.3298],
            [18.0804, 46.3679],
            [18.021, 46.4039],
            [18.0686, 46.4349],
            [18.0394, 46.4953],
            [18.0374, 46.5597],
            [18.0721, 46.5644],
            [18.0716, 46.6213],
            [18.127, 46.6766],
            [18.1121, 46.7304],
            [18.1668, 46.7672],
            [18.2097, 46.7781],
          ],
        ],
      },
      properties: {name: 'Tolna', id: 'HU-TO', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-TO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.8788, 47.3922],
            [17.9341, 47.3865],
            [17.9616, 47.3337],
            [18.0363, 47.3343],
            [18.0572, 47.2773],
            [18.1066, 47.2488],
            [18.1573, 47.2652],
            [18.2235, 47.2156],
            [18.2115, 47.1566],
            [18.2337, 47.0949],
            [18.2032, 47.0784],
            [18.2331, 47.0073],
            [18.161, 46.9584],
            [18.0603, 46.965],
            [17.9091, 46.9172],
            [17.9097, 46.8916],
            [17.7109, 46.8339],
            [17.6713, 46.8136],
            [17.4886, 46.7525],
            [17.4188, 46.7501],
            [17.3592, 46.7926],
            [17.3241, 46.8336],
            [17.3255, 46.8827],
            [17.2825, 46.9191],
            [17.227, 46.923],
            [17.2486, 46.9691],
            [17.205, 47.0023],
            [17.1516, 46.9997],
            [17.0729, 47.0473],
            [17.0751, 47.0658],
            [17.2153, 47.0809],
            [17.1958, 47.1435],
            [17.2083, 47.1765],
            [17.2005, 47.2733],
            [17.2249, 47.3087],
            [17.294, 47.3271],
            [17.188, 47.3814],
            [17.174, 47.4317],
            [17.1949, 47.4436],
            [17.2872, 47.4318],
            [17.3646, 47.4425],
            [17.406, 47.4797],
            [17.4301, 47.4313],
            [17.4694, 47.4218],
            [17.4932, 47.4527],
            [17.5598, 47.4622],
            [17.6405, 47.4517],
            [17.7019, 47.4711],
            [17.7537, 47.4566],
            [17.7799, 47.4099],
            [17.7549, 47.373],
            [17.7145, 47.3551],
            [17.7192, 47.3218],
            [17.8061, 47.3218],
            [17.8156, 47.3635],
            [17.8788, 47.3922],
          ],
        ],
      },
      properties: {name: 'Veszprém', id: 'HU-VE', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-VE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.0729, 47.0473],
            [17.1516, 46.9997],
            [17.205, 47.0023],
            [17.2486, 46.9691],
            [17.227, 46.923],
            [17.2825, 46.9191],
            [17.3255, 46.8827],
            [17.3241, 46.8336],
            [17.3592, 46.7926],
            [17.4188, 46.7501],
            [17.3634, 46.7308],
            [17.2687, 46.7338],
            [17.2569, 46.6885],
            [17.222, 46.6659],
            [17.2247, 46.5114],
            [17.1892, 46.4853],
            [17.1862, 46.4306],
            [17.1395, 46.4088],
            [17.083, 46.4193],
            [17.0431, 46.3879],
            [17.0584, 46.3493],
            [17.0133, 46.3047],
            [16.9011, 46.3111],
            [16.8735, 46.3259],
            [16.7945, 46.3862],
            [16.7191, 46.3925],
            [16.6645, 46.4583],
            [16.5966, 46.4644],
            [16.5223, 46.5036],
            [16.5115, 46.5602],
            [16.3852, 46.6452],
            [16.4187, 46.6589],
            [16.4288, 46.6952],
            [16.3694, 46.7224],
            [16.4036, 46.7874],
            [16.4444, 46.7807],
            [16.5018, 46.8036],
            [16.5022, 46.8331],
            [16.5676, 46.8562],
            [16.631, 46.9254],
            [16.6694, 46.9355],
            [16.7783, 46.9253],
            [16.846, 46.9786],
            [16.9808, 46.9616],
            [17.0172, 47.0013],
            [17.0033, 47.0355],
            [17.0729, 47.0473],
          ],
        ],
      },
      properties: {name: 'Zala', id: 'HU-ZA', CNTRY: 'Hungary', TYPE: 'County'},
      id: 'HU-ZA',
    },
  ],
}
export default map
