import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {selectors} from '../../../redux/ReportsRedux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import {GadsAccounts, IReportDetails, SEM_TAB_NAME} from "../../../models/Reports";
import clsx from "clsx";
import GadsDataStatisticTab from "./GadsDataStatisticTab";

interface GadsStatisticTabProps {
}

let accountsArray: string[] = [];

interface TabsNavProps {
    tab: string
    setTab: (data: string) => void
}

const GadsStatisticTab: React.FC<GadsStatisticTabProps> = () => {
    const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)
    const [gadsAccounts, setGadsAccounts] = useState<GadsAccounts>()
    const intl = useIntl()

// @ts-ignore
    function showAccountData(account) {
        setCurrentTab(account)

    }

    const [currentTab, setCurrentTab] = useState('string');
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    useEffect(() => {
        if (reportData && reportData.details.googleAdsCampaignList) {
            {

                accountsArray = [];
                Object.values(reportData.details.googleAdsCampaignList).map((value, index) => {

                    accountsArray.push(value.id)
                })
                setCurrentTab('1')

            }
        }
    }, [reportData])


    return !reportsError ? (
        <div data-testid="sem-report-basic-data" className={reportsLoading ? 'overlay overlay-block rounded' : ''}>
            {!reportsLoading ? (
                <>
                    <ul
                        className='nav nav-tabs-report nav-stretch nav-fixed-top nav-line-tabs fw-bold fs-6 flex-nowrap'
                        role='tablist'>

                        <li className='nav-item action-btn'>
                            <div
                                className={clsx(`nav-link nav-link-report-small fw-bolder fs-xl-7 fs-6 px-5 me-5`, {
                                    active: '1' == currentTab,
                                })}
                                onClick={() => showAccountData(1)}
                                role='tab'
                            >
                                {intl.formatMessage({id: 'FULL_REPORT'})}

                            </div>
                        </li>

                        {accountsArray.map((account) =>
                            <li className='nav-item action-btn'>
                                <div
                                    className={clsx(`nav-link nav-link-report fw-bolder fs-xl-7 fs-6 px-5 me-5`, {
                                        active: account == currentTab,
                                    })}
                                    onClick={() => showAccountData(account)}
                                    role='tab'
                                >
                                    {account}
                                </div>
                            </li>
                        )}

                    </ul>

                    <GadsDataStatisticTab tabKey={currentTab}/>
                </>
            ) : (
                <div style={{height: '350px'}}>
                    <Spinner/>
                </div>
            )}
        </div>
    ) : (
        <ErrorView/>
    )
}

export default GadsStatisticTab
