import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import React, {useEffect, useState} from "react";
import ErrorView from "../../../../common/ErrorView/ErrorView";
import Spinner from "../../../../common/Spinner/Spinner";
import BingTitle from "../BingWidgets/BingTitle";
import BingDailyChart from "../BingWidgets/BingDailyChart";
import BingBasicData from "../BingWidgets/BingBasicData";
import BingDevice from "../BingWidgets/BingDevice";
import BingGeo from "../BingWidgets/BingGeo";
import BingConversionCampaignGroup from "../BingWidgets/BingConversionCampaignGroup";
import BingCampaignEffectiveness from "../BingWidgets/BingCampaignEffectiveness";
import {IReportDetails} from "../../../models/Reports";

interface IBingDataStatisticTab {
    isPdf?: boolean;
    onLoad?: () => void;
}

const BingDataStatisticTab: React.FC<IBingDataStatisticTab> = ({ isPdf, onLoad }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        setLoading(reportsLoading)
    }, [reportsLoading])

    useEffect(() => {
        if(onLoad){
            onLoad();
        }
    }, [onLoad]);

    const canShowDaily = reportData && reportData.details && reportData.details.bingAdsDaily && reportData.details.bingAdsDaily.length > 0;
    const canShowMonthly = reportData && reportData.details && reportData.details.bingAdsMonthly && reportData.details.bingAdsMonthly.length > 0;
    const canShowCampaignEffectiveness = reportData && reportData.details && reportData.details.bingAdsCampaignList && reportData.details.bingAdsCampaignList.length > 0;
    const canShowGeo = reportData && reportData.details && reportData.details.bingAdsGeoData && reportData.details.bingAdsGeoData.length > 0;
    const canShowDevice = reportData && reportData.details && reportData.details.bingAdsDevice && reportData.details.bingAdsDevice.length > 0;

    return !reportsError ? (
        <div
            data-testid="bing-report-basic-data"
            className={reportsLoading ? 'overlay overlay-block rounded' : ''}
        >
            {!loading ? (
                <>
                    {canShowDaily && (
                        <div className="clicks-group card-body bg-white">
                            <BingTitle />
                            <BingDailyChart />
                        </div>
                    )}
                    {canShowMonthly && (
                        <div className='clicks-group bg-white'>
                            <BingBasicData />
                        </div>
                    )}
                    {canShowCampaignEffectiveness && (
                        <div className='clicks-group bg-white'>
                            <BingCampaignEffectiveness tabKey={1} />
                        </div>
                    )}
                    {canShowGeo && (
                        <div className='clicks-group bg-white'>
                            <BingGeo />
                        </div>
                    )}
                    {canShowDevice && (
                        <div className='bg-white'>
                            <BingDevice />
                        </div>
                    )}
                </>
            ) : (
                <div style={{ height: '350px' }}>
                    <Spinner />
                </div>
            )}
        </div>
    ) : (
        <ErrorView />
    );
};

export default BingDataStatisticTab