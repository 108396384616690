import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import {selectors as reportSelectors} from '../../../redux/ReportsRedux'
import SessionGroup from '../SeoWidgetsGroup/SessionGroup'
import TimeStatisticGroup from '../SeoWidgetsGroup/TimeStatisticGroup'
import DevicesGroup from '../../WidgetsGroup/DevicesGroup'
import CommentGroup from '../SeoWidgetsGroup/CommentGroup'
import BasicDataGroup from '../SeoWidgetsGroup/BasicDataGroup'
import LinkWidget from '../SeoWidgets/LinksWidget'
import ProductParamLists from '../SeoWidgetsGroup/ProductsParamList'
import {actions as seoListsActions} from '../../../redux/SeoReportListsRedux'
import {selectors as authSelectors} from '../../../../auth'
import {selectors as seoReportsListSelectors} from '../../../redux/SeoReportListsRedux'
import SessionMap from '../SeoWidgets/SessionMap'
import FacilitiesWidget from '../SeoWidgets/FacilitiesWidget'
import {useIntl} from 'react-intl'

interface DataStatisticTabProps {}

const DataStatisticTab: React.FC<DataStatisticTabProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {reportsLoading, reportsError, reportId} = useSelector(reportSelectors.getReportsState)
  const companyId = useSelector(authSelectors.getCompanyId)
  const {
    efficiencyList,
    efficiencyLoading,
    efficiencyError,
    efficiencyTotalPages,
    categoryList,
    categoryLoading,
    categoryError,
    categoryTotalPages,
    brandList,
    brandLoading,
    brandError,
    brandTotalPages,
  } = useSelector(seoReportsListSelectors.getReportListsState)

  const [efficiencyPage, setEfficientyPage] = useState<number>(1)
  const [categoryPage, setCategoryPage] = useState<number>(1)
  const [brandPage, setBrandPage] = useState<number>(1)

  useEffect(() => {
    if (reportId && companyId) {
      dispatch(seoListsActions.requestEfficiencyList(reportId, companyId, efficiencyPage))
    }
  }, [dispatch, reportId, companyId, efficiencyPage])

  useEffect(() => {
    if (reportId && companyId) {
      dispatch(seoListsActions.requestCategoryList(reportId, companyId, 1))
    }
  }, [dispatch, reportId, companyId, categoryPage])

  useEffect(() => {
    if (reportId && companyId) {
      dispatch(seoListsActions.requestBrandList(reportId, companyId, 1))
    }
  }, [dispatch, reportId, companyId, brandPage])

  return !reportsError ? (
    <div className={reportsLoading ? 'overlay overlay-block rounded' : ''}>
      {!reportsLoading ? (
        <>
          {/*<div className='comment-group card-body bg-white px-5 py-5 pt-15 mb-5'>*/}
          {/*  <CommentGroup />*/}
          {/*</div>*/}
          {/*<div className='page-slice'></div>*/}

          <div className='basic-data-group card-body bg-white px-5 py-5 mb-5'>
            <BasicDataGroup />
          </div>

          <div className='session-group card-body bg-white px-5 py-5'>
            <SessionGroup />
          </div>

          <div className='time-statistic card-body bg-white px-5 py-5'>
            <TimeStatisticGroup />
          </div>
          <div className='page-slice'></div>

          {efficiencyList && efficiencyList.length > 0 && efficiencyTotalPages && (
            <div className='card-body bg-white px-5 py-5'>
              <ProductParamLists
                title={intl.formatMessage({id: 'EFFICIENCY_PRODUCT'})}
                currentPage={efficiencyPage}
                setCurrentPage={setEfficientyPage}
                loading={efficiencyLoading}
                error={efficiencyError}
                pages={efficiencyTotalPages}
                productsArray={efficiencyList}
              />
            </div>
          )}

          {categoryList && categoryList.length > 0 && categoryTotalPages && (
            <div className='card-body bg-white px-5 py-5'>
              <ProductParamLists
                title={intl.formatMessage({id: 'CATEGORY_PRODUCT'})}
                currentPage={categoryPage}
                setCurrentPage={setCategoryPage}
                loading={categoryLoading}
                error={categoryError}
                pages={categoryTotalPages}
                productsArray={categoryList}
              />
              <div className='page-slice'></div>
            </div>
          )}

          {brandList && brandList.length > 0 && brandTotalPages && (
            <div className='card-body bg-white px-5 py-5'>
              <ProductParamLists
                title={intl.formatMessage({id: 'BRAND_PRODUCT'})}
                currentPage={brandPage}
                setCurrentPage={setBrandPage}
                loading={brandLoading}
                error={brandError}
                pages={brandTotalPages}
                productsArray={brandList}
              />
            </div>
          )}

          {/*<div className='card-body bg-white px-5 py-5'>*/}
          {/*  <DevicesGroup />*/}
          {/*</div>*/}

          <div className='bg-white px-5 py-5'>
            <div className='row'>
              <div className='col-4'>
                <SessionMap />
              </div>
              <div className='col-8'>
                <LinkWidget />
              </div>
            </div>
          </div>

          <div className='bg-white px-5 py-5 mb-5'>
            <FacilitiesWidget />
            <div className='page-slice'></div>
          </div>
        </>
      ) : (
        <div style={{height: '350px'}}>
          <Spinner />
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default DataStatisticTab
