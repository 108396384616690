var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [11.2669, 53.1224],
            [11.3049, 53.1158],
            [11.3145, 53.1197],
            [11.326, 53.1193],
            [11.3345, 53.1133],
            [11.3563, 53.1161],
            [11.3696, 53.1112],
            [11.3759, 53.1137],
            [11.3927, 53.1112],
            [11.3978, 53.1145],
            [11.3931, 53.1275],
            [11.3971, 53.1395],
            [11.4131, 53.1365],
            [11.4209, 53.141],
            [11.4403, 53.135],
            [11.4502, 53.1391],
            [11.4628, 53.1403],
            [11.4741, 53.1334],
            [11.4713, 53.1268],
            [11.4873, 53.1285],
            [11.5052, 53.1207],
            [11.5109, 53.125],
            [11.5319, 53.1253],
            [11.5384, 53.1306],
            [11.5462, 53.1312],
            [11.556, 53.138],
            [11.5502, 53.1486],
            [11.555, 53.1506],
            [11.5666, 53.1655],
            [11.5627, 53.1802],
            [11.5577, 53.1858],
            [11.5584, 53.204],
            [11.5507, 53.2098],
            [11.5645, 53.2141],
            [11.5767, 53.2115],
            [11.5866, 53.2142],
            [11.5981, 53.2266],
            [11.6155, 53.2312],
            [11.6262, 53.2403],
            [11.6387, 53.2377],
            [11.6519, 53.2381],
            [11.66, 53.2444],
            [11.6724, 53.2452],
            [11.6752, 53.2412],
            [11.6975, 53.2438],
            [11.7064, 53.2382],
            [11.7057, 53.2333],
            [11.7275, 53.2328],
            [11.7305, 53.2184],
            [11.7521, 53.2195],
            [11.7587, 53.2237],
            [11.7753, 53.228],
            [11.7961, 53.2249],
            [11.8174, 53.2255],
            [11.8316, 53.2329],
            [11.8298, 53.237],
            [11.8126, 53.2448],
            [11.8034, 53.246],
            [11.7957, 53.2539],
            [11.8103, 53.2518],
            [11.8374, 53.2508],
            [11.8445, 53.2527],
            [11.8527, 53.2496],
            [11.8633, 53.2503],
            [11.8724, 53.2606],
            [11.8851, 53.267],
            [11.8916, 53.2776],
            [11.9036, 53.2731],
            [11.9189, 53.2715],
            [11.9416, 53.2737],
            [11.9538, 53.2732],
            [11.9529, 53.2782],
            [11.9646, 53.285],
            [11.973, 53.2987],
            [11.9937, 53.2926],
            [12.0027, 53.3002],
            [12.0203, 53.3007],
            [12.0235, 53.3058],
            [12.0197, 53.3138],
            [12.0218, 53.3206],
            [12.0164, 53.3351],
            [12.0397, 53.3465],
            [12.0593, 53.3518],
            [12.0491, 53.3625],
            [12.0521, 53.3687],
            [12.0802, 53.369],
            [12.0826, 53.3482],
            [12.1121, 53.3456],
            [12.1119, 53.3512],
            [12.1344, 53.3503],
            [12.1404, 53.3589],
            [12.1518, 53.3624],
            [12.1591, 53.3503],
            [12.1698, 53.3407],
            [12.1863, 53.3442],
            [12.1866, 53.3535],
            [12.1913, 53.3551],
            [12.2132, 53.3555],
            [12.2306, 53.3599],
            [12.2364, 53.3441],
            [12.2419, 53.3352],
            [12.259, 53.328],
            [12.2716, 53.3247],
            [12.2839, 53.3283],
            [12.3043, 53.3275],
            [12.3255, 53.3233],
            [12.3337, 53.3196],
            [12.3507, 53.3159],
            [12.3607, 53.3075],
            [12.378, 53.3019],
            [12.3926, 53.3012],
            [12.3957, 53.2953],
            [12.3933, 53.2852],
            [12.4019, 53.2804],
            [12.4106, 53.2802],
            [12.4278, 53.2755],
            [12.4308, 53.2718],
            [12.4336, 53.2576],
            [12.4462, 53.2498],
            [12.4618, 53.2553],
            [12.4959, 53.2546],
            [12.5043, 53.2567],
            [12.5051, 53.2631],
            [12.5312, 53.2645],
            [12.5472, 53.262],
            [12.5666, 53.2555],
            [12.5976, 53.2484],
            [12.6064, 53.2444],
            [12.6164, 53.2503],
            [12.6307, 53.2536],
            [12.6614, 53.2542],
            [12.6747, 53.2455],
            [12.6667, 53.2421],
            [12.6654, 53.2352],
            [12.6717, 53.231],
            [12.6903, 53.2258],
            [12.7047, 53.2272],
            [12.7204, 53.2221],
            [12.7558, 53.2241],
            [12.7535, 53.2136],
            [12.7387, 53.1998],
            [12.7554, 53.1961],
            [12.7604, 53.1909],
            [12.782, 53.1887],
            [12.7947, 53.1897],
            [12.8053, 53.1947],
            [12.8143, 53.1917],
            [12.8246, 53.1963],
            [12.8382, 53.1975],
            [12.8473, 53.2018],
            [12.8585, 53.1875],
            [12.8773, 53.1806],
            [12.8877, 53.1833],
            [12.8894, 53.1889],
            [12.9028, 53.1948],
            [12.9214, 53.197],
            [12.9365, 53.1952],
            [12.9533, 53.2012],
            [12.9648, 53.2012],
            [12.9764, 53.1985],
            [12.9807, 53.1925],
            [12.9632, 53.186],
            [12.9597, 53.1789],
            [12.965, 53.1668],
            [12.9819, 53.1639],
            [12.9925, 53.1699],
            [13.0026, 53.1701],
            [13.0191, 53.1753],
            [13.0183, 53.1827],
            [13.0328, 53.1923],
            [13.0544, 53.2007],
            [13.069, 53.204],
            [13.074, 53.2002],
            [13.0842, 53.2122],
            [13.0901, 53.2154],
            [13.105, 53.2147],
            [13.1091, 53.2242],
            [13.1041, 53.2329],
            [13.1124, 53.2388],
            [13.1299, 53.2356],
            [13.1351, 53.2427],
            [13.1726, 53.2503],
            [13.1789, 53.2453],
            [13.187, 53.2476],
            [13.1913, 53.2342],
            [13.2038, 53.2223],
            [13.2313, 53.216],
            [13.2401, 53.2243],
            [13.2414, 53.2357],
            [13.2477, 53.245],
            [13.2459, 53.256],
            [13.2523, 53.2609],
            [13.2715, 53.2643],
            [13.2826, 53.2759],
            [13.2906, 53.2752],
            [13.3006, 53.2802],
            [13.3391, 53.2727],
            [13.3411, 53.278],
            [13.3613, 53.2772],
            [13.3685, 53.2699],
            [13.3685, 53.2626],
            [13.3762, 53.2586],
            [13.3868, 53.242],
            [13.3946, 53.2417],
            [13.4046, 53.2498],
            [13.4064, 53.2611],
            [13.4141, 53.2618],
            [13.4265, 53.2802],
            [13.4315, 53.2838],
            [13.4353, 53.2968],
            [13.4548, 53.2987],
            [13.4626, 53.2891],
            [13.4846, 53.2925],
            [13.4966, 53.3011],
            [13.497, 53.3101],
            [13.5057, 53.3194],
            [13.5221, 53.3198],
            [13.5216, 53.3305],
            [13.5175, 53.3409],
            [13.5238, 53.3492],
            [13.5156, 53.3511],
            [13.524, 53.3665],
            [13.5425, 53.3651],
            [13.5454, 53.3718],
            [13.5567, 53.3779],
            [13.5529, 53.3823],
            [13.557, 53.3924],
            [13.5506, 53.3988],
            [13.5694, 53.4039],
            [13.5736, 53.4101],
            [13.5875, 53.4134],
            [13.5923, 53.4099],
            [13.6111, 53.4129],
            [13.6182, 53.4086],
            [13.6262, 53.4104],
            [13.6334, 53.4195],
            [13.6286, 53.4284],
            [13.6355, 53.4329],
            [13.6332, 53.4419],
            [13.6397, 53.447],
            [13.6517, 53.4483],
            [13.6599, 53.4586],
            [13.6716, 53.4615],
            [13.6769, 53.4662],
            [13.6986, 53.4722],
            [13.7139, 53.4817],
            [13.7342, 53.4832],
            [13.7395, 53.486],
            [13.755, 53.4778],
            [13.7756, 53.474],
            [13.8063, 53.4817],
            [13.8126, 53.4857],
            [13.8227, 53.4977],
            [13.807, 53.5025],
            [13.8008, 53.4994],
            [13.7928, 53.5105],
            [13.7745, 53.5149],
            [13.7791, 53.5235],
            [13.7758, 53.5284],
            [13.7783, 53.5427],
            [13.7849, 53.5557],
            [13.7977, 53.5589],
            [13.7973, 53.5454],
            [13.8188, 53.5263],
            [13.8226, 53.5189],
            [13.8528, 53.5136],
            [13.8771, 53.5055],
            [13.8731, 53.4956],
            [13.8748, 53.4885],
            [13.8823, 53.4841],
            [13.8756, 53.4742],
            [13.9117, 53.4574],
            [13.9148, 53.4494],
            [13.9064, 53.4464],
            [13.9074, 53.4367],
            [13.9003, 53.4327],
            [13.9161, 53.4226],
            [13.9368, 53.43],
            [13.9529, 53.4323],
            [13.9629, 53.4314],
            [13.9912, 53.4353],
            [14.0056, 53.4349],
            [14.0101, 53.431],
            [14.0278, 53.4296],
            [14.0466, 53.4301],
            [14.0513, 53.4139],
            [14.0759, 53.4153],
            [14.0919, 53.4224],
            [14.1038, 53.4231],
            [14.1216, 53.4416],
            [14.1308, 53.438],
            [14.1486, 53.4368],
            [14.1633, 53.4274],
            [14.174, 53.424],
            [14.1924, 53.4222],
            [14.2084, 53.4244],
            [14.2229, 53.4353],
            [14.2335, 53.4273],
            [14.2437, 53.4228],
            [14.2356, 53.4144],
            [14.2406, 53.4071],
            [14.2414, 53.3907],
            [14.2447, 53.3888],
            [14.2344, 53.3705],
            [14.226, 53.3596],
            [14.2209, 53.359],
            [14.2086, 53.3483],
            [14.1914, 53.3361],
            [14.1925, 53.3332],
            [14.1635, 53.3142],
            [14.1594, 53.3146],
            [14.1301, 53.2983],
            [14.1063, 53.2782],
            [14.0997, 53.2695],
            [14.0979, 53.2623],
            [14.1284, 53.2608],
            [14.1425, 53.2651],
            [14.1593, 53.267],
            [14.1857, 53.2606],
            [14.2009, 53.261],
            [14.2118, 53.2545],
            [14.2449, 53.2571],
            [14.2657, 53.2607],
            [14.2631, 53.2766],
            [14.3017, 53.286],
            [14.3022, 53.2897],
            [14.3202, 53.3117],
            [14.3481, 53.3099],
            [14.3513, 53.3144],
            [14.378, 53.3186],
            [14.3853, 53.3266],
            [14.4012, 53.3312],
            [14.4119, 53.3306],
            [14.4141, 53.3231],
            [14.4077, 53.3095],
            [14.4159, 53.3008],
            [14.421, 53.2782],
            [14.4395, 53.2757],
            [14.4471, 53.2627],
            [14.4354, 53.2493],
            [14.4326, 53.2413],
            [14.4085, 53.2234],
            [14.406, 53.2124],
            [14.3975, 53.2069],
            [14.3865, 53.2053],
            [14.3765, 53.1993],
            [14.3747, 53.1878],
            [14.3697, 53.1807],
            [14.3675, 53.161],
            [14.3869, 53.1408],
            [14.3818, 53.1292],
            [14.3759, 53.1086],
            [14.3688, 53.1017],
            [14.3689, 53.0902],
            [14.3637, 53.0783],
            [14.3523, 53.0611],
            [14.3443, 53.0521],
            [14.3349, 53.0458],
            [14.3091, 53.0329],
            [14.3019, 53.0266],
            [14.2595, 53.0038],
            [14.2339, 52.9933],
            [14.2193, 52.9887],
            [14.2025, 52.9855],
            [14.169, 52.9728],
            [14.1637, 52.9654],
            [14.1445, 52.9611],
            [14.1394, 52.9482],
            [14.1428, 52.9446],
            [14.142, 52.9341],
            [14.153, 52.8999],
            [14.1615, 52.8888],
            [14.1555, 52.8719],
            [14.1351, 52.8563],
            [14.1246, 52.8451],
            [14.123, 52.8382],
            [14.1293, 52.8292],
            [14.1421, 52.8244],
            [14.2103, 52.8181],
            [14.2239, 52.813],
            [14.2331, 52.8032],
            [14.2691, 52.7799],
            [14.2848, 52.7723],
            [14.3062, 52.7649],
            [14.3257, 52.7607],
            [14.3516, 52.7507],
            [14.3628, 52.7397],
            [14.3756, 52.7345],
            [14.3876, 52.7222],
            [14.4063, 52.7102],
            [14.4153, 52.7],
            [14.4263, 52.6939],
            [14.4343, 52.6809],
            [14.4532, 52.676],
            [14.4642, 52.6616],
            [14.505, 52.6433],
            [14.5708, 52.6215],
            [14.6007, 52.6067],
            [14.6073, 52.6],
            [14.6102, 52.5904],
            [14.6224, 52.5809],
            [14.6356, 52.5758],
            [14.6378, 52.5687],
            [14.6193, 52.558],
            [14.6116, 52.5431],
            [14.606, 52.5377],
            [14.6042, 52.5297],
            [14.6154, 52.5089],
            [14.6318, 52.5017],
            [14.6353, 52.4951],
            [14.6292, 52.4864],
            [14.6128, 52.4775],
            [14.6098, 52.4665],
            [14.5936, 52.4531],
            [14.5862, 52.4495],
            [14.5798, 52.4421],
            [14.5619, 52.4398],
            [14.5491, 52.4336],
            [14.5438, 52.4222],
            [14.5445, 52.4102],
            [14.5344, 52.3989],
            [14.5353, 52.3919],
            [14.5514, 52.3757],
            [14.5527, 52.3542],
            [14.5622, 52.3382],
            [14.5613, 52.3291],
            [14.5742, 52.3228],
            [14.5844, 52.3038],
            [14.5745, 52.2933],
            [14.5768, 52.2882],
            [14.5858, 52.2841],
            [14.5904, 52.276],
            [14.5967, 52.2724],
            [14.6198, 52.2706],
            [14.6437, 52.2634],
            [14.6595, 52.2625],
            [14.688, 52.2567],
            [14.6995, 52.2506],
            [14.7146, 52.2346],
            [14.711, 52.219],
            [14.7061, 52.2092],
            [14.6865, 52.1985],
            [14.6866, 52.19],
            [14.703, 52.1781],
            [14.7038, 52.1666],
            [14.6909, 52.1538],
            [14.6827, 52.1483],
            [14.6796, 52.1405],
            [14.6835, 52.1243],
            [14.6817, 52.1165],
            [14.6915, 52.1035],
            [14.7048, 52.0967],
            [14.7199, 52.0947],
            [14.7433, 52.0838],
            [14.7586, 52.0634],
            [14.7451, 52.0545],
            [14.7498, 52.0437],
            [14.7482, 52.033],
            [14.7405, 52.0296],
            [14.7405, 52.0237],
            [14.7253, 52.0134],
            [14.7228, 52.0071],
            [14.7143, 52.0029],
            [14.7213, 51.9983],
            [14.717, 51.9829],
            [14.7053, 51.9763],
            [14.7081, 51.9658],
            [14.713, 51.9581],
            [14.7206, 51.9549],
            [14.7207, 51.9427],
            [14.7083, 51.9352],
            [14.7058, 51.9231],
            [14.6999, 51.913],
            [14.6937, 51.9088],
            [14.6949, 51.9016],
            [14.6645, 51.8898],
            [14.6562, 51.8846],
            [14.6518, 51.8732],
            [14.6435, 51.8667],
            [14.6353, 51.8649],
            [14.6262, 51.8581],
            [14.613, 51.8569],
            [14.6074, 51.8517],
            [14.611, 51.844],
            [14.6015, 51.8396],
            [14.5925, 51.8391],
            [14.5914, 51.821],
            [14.5986, 51.8168],
            [14.6032, 51.8073],
            [14.624, 51.8013],
            [14.6334, 51.8015],
            [14.6463, 51.7954],
            [14.6531, 51.784],
            [14.6536, 51.7604],
            [14.6629, 51.7583],
            [14.6589, 51.7527],
            [14.6576, 51.7428],
            [14.6691, 51.7257],
            [14.6777, 51.7225],
            [14.691, 51.7082],
            [14.7033, 51.7039],
            [14.7277, 51.689],
            [14.7394, 51.6846],
            [14.7381, 51.6785],
            [14.7476, 51.6754],
            [14.7575, 51.66],
            [14.7517, 51.651],
            [14.7561, 51.6448],
            [14.754, 51.6278],
            [14.7624, 51.6203],
            [14.765, 51.6072],
            [14.7503, 51.595],
            [14.7422, 51.5923],
            [14.7304, 51.5821],
            [14.7231, 51.5849],
            [14.7134, 51.5939],
            [14.7043, 51.5979],
            [14.6944, 51.5958],
            [14.6974, 51.5858],
            [14.7034, 51.5807],
            [14.6954, 51.5764],
            [14.6884, 51.5601],
            [14.6796, 51.5532],
            [14.6719, 51.5511],
            [14.6536, 51.5544],
            [14.6368, 51.5535],
            [14.6321, 51.551],
            [14.6097, 51.5504],
            [14.6063, 51.5534],
            [14.5993, 51.5692],
            [14.5934, 51.5729],
            [14.582, 51.5742],
            [14.5768, 51.581],
            [14.5679, 51.5805],
            [14.5563, 51.5725],
            [14.5444, 51.5714],
            [14.5466, 51.5636],
            [14.5382, 51.5594],
            [14.5284, 51.5588],
            [14.5161, 51.5545],
            [14.4991, 51.5594],
            [14.4868, 51.5582],
            [14.4546, 51.5576],
            [14.449, 51.5553],
            [14.4419, 51.5446],
            [14.426, 51.5428],
            [14.4111, 51.5375],
            [14.3988, 51.5418],
            [14.3887, 51.5421],
            [14.3842, 51.5344],
            [14.377, 51.5329],
            [14.3426, 51.5188],
            [14.3337, 51.5036],
            [14.3254, 51.5073],
            [14.3245, 51.5164],
            [14.3177, 51.521],
            [14.2933, 51.5252],
            [14.2705, 51.5325],
            [14.2429, 51.5318],
            [14.2262, 51.5335],
            [14.2193, 51.5383],
            [14.1883, 51.5407],
            [14.1736, 51.5389],
            [14.1653, 51.5413],
            [14.1482, 51.5411],
            [14.138, 51.5435],
            [14.1298, 51.5323],
            [14.1401, 51.5249],
            [14.1351, 51.5215],
            [14.1142, 51.523],
            [14.1059, 51.5211],
            [14.1007, 51.5129],
            [14.095, 51.4981],
            [14.075, 51.4906],
            [14.0878, 51.4803],
            [14.0757, 51.4729],
            [14.0696, 51.4729],
            [14.0399, 51.4801],
            [14.0339, 51.4761],
            [14.0563, 51.4599],
            [14.0537, 51.4501],
            [14.0622, 51.4464],
            [14.0374, 51.4356],
            [14.0448, 51.4318],
            [14.0396, 51.4269],
            [14.0454, 51.4223],
            [14.0291, 51.4091],
            [14.0164, 51.4053],
            [14.0158, 51.4005],
            [14.0037, 51.3933],
            [14.0006, 51.3859],
            [14.0003, 51.3745],
            [13.9707, 51.377],
            [13.9715, 51.394],
            [13.9534, 51.3967],
            [13.9061, 51.3804],
            [13.866, 51.3776],
            [13.8593, 51.382],
            [13.8347, 51.3851],
            [13.8349, 51.3778],
            [13.8262, 51.3754],
            [13.8195, 51.37],
            [13.8084, 51.3728],
            [13.7832, 51.371],
            [13.7743, 51.3636],
            [13.7685, 51.3639],
            [13.7626, 51.3712],
            [13.7532, 51.3646],
            [13.7408, 51.3625],
            [13.7037, 51.3672],
            [13.6893, 51.3743],
            [13.6823, 51.3684],
            [13.6666, 51.3772],
            [13.6578, 51.37],
            [13.6371, 51.3688],
            [13.6181, 51.3714],
            [13.5956, 51.3694],
            [13.5921, 51.3798],
            [13.5874, 51.3842],
            [13.5684, 51.3856],
            [13.5598, 51.3808],
            [13.5485, 51.3804],
            [13.5383, 51.375],
            [13.5369, 51.3822],
            [13.5229, 51.3827],
            [13.5235, 51.39],
            [13.5195, 51.4025],
            [13.5123, 51.4027],
            [13.4882, 51.4089],
            [13.4626, 51.4126],
            [13.4613, 51.4236],
            [13.45, 51.4242],
            [13.4472, 51.4283],
            [13.4331, 51.4271],
            [13.4289, 51.4217],
            [13.4204, 51.4226],
            [13.4276, 51.4298],
            [13.4198, 51.4388],
            [13.412, 51.4407],
            [13.407, 51.4498],
            [13.3993, 51.4537],
            [13.3874, 51.449],
            [13.3881, 51.4399],
            [13.3762, 51.4347],
            [13.3841, 51.4293],
            [13.3754, 51.426],
            [13.3579, 51.4348],
            [13.348, 51.4345],
            [13.3289, 51.4263],
            [13.316, 51.4253],
            [13.3086, 51.4187],
            [13.2865, 51.4116],
            [13.2858, 51.3988],
            [13.2609, 51.4006],
            [13.2554, 51.3923],
            [13.2355, 51.3956],
            [13.2147, 51.3964],
            [13.2014, 51.417],
            [13.204, 51.4255],
            [13.2012, 51.4326],
            [13.1933, 51.4365],
            [13.1869, 51.4242],
            [13.1753, 51.4282],
            [13.1756, 51.4333],
            [13.1914, 51.4412],
            [13.2031, 51.4508],
            [13.2028, 51.4593],
            [13.1876, 51.4661],
            [13.1824, 51.475],
            [13.1865, 51.4844],
            [13.2039, 51.4995],
            [13.2084, 51.5132],
            [13.2075, 51.5242],
            [13.2012, 51.5262],
            [13.188, 51.5442],
            [13.1854, 51.5574],
            [13.1598, 51.5596],
            [13.1478, 51.5643],
            [13.1436, 51.5721],
            [13.147, 51.5787],
            [13.1613, 51.5939],
            [13.1552, 51.6],
            [13.148, 51.5984],
            [13.133, 51.6076],
            [13.1222, 51.6207],
            [13.1073, 51.6126],
            [13.0932, 51.6082],
            [13.0839, 51.6115],
            [13.0764, 51.6217],
            [13.0532, 51.6466],
            [13.059, 51.6506],
            [13.1265, 51.6749],
            [13.1488, 51.685],
            [13.1609, 51.6918],
            [13.1609, 51.6982],
            [13.1533, 51.7102],
            [13.1618, 51.7129],
            [13.168, 51.7189],
            [13.1863, 51.717],
            [13.1847, 51.7229],
            [13.1642, 51.7412],
            [13.1558, 51.7423],
            [13.165, 51.7559],
            [13.1505, 51.7594],
            [13.1573, 51.7662],
            [13.1565, 51.7734],
            [13.1619, 51.7925],
            [13.1634, 51.8063],
            [13.1542, 51.8162],
            [13.1441, 51.8193],
            [13.1446, 51.8236],
            [13.1294, 51.8435],
            [13.1227, 51.8569],
            [13.1529, 51.8609],
            [13.1509, 51.8713],
            [13.1428, 51.8719],
            [13.1394, 51.8798],
            [13.1314, 51.8824],
            [13.1206, 51.8819],
            [13.1199, 51.8772],
            [13.1004, 51.8731],
            [13.0851, 51.8736],
            [13.0827, 51.8684],
            [13.0421, 51.8712],
            [13.0289, 51.8803],
            [13.0399, 51.8802],
            [13.038, 51.8917],
            [13.0486, 51.8922],
            [13.0461, 51.9],
            [13.0319, 51.901],
            [13.0227, 51.9039],
            [12.9893, 51.9032],
            [12.9736, 51.8995],
            [12.9754, 51.9208],
            [12.9564, 51.9228],
            [12.9584, 51.9353],
            [12.9426, 51.9359],
            [12.9198, 51.9394],
            [12.9174, 51.9331],
            [12.8937, 51.9279],
            [12.8872, 51.9341],
            [12.8766, 51.9335],
            [12.8525, 51.9353],
            [12.8504, 51.9552],
            [12.8451, 51.9681],
            [12.8239, 51.9631],
            [12.7862, 51.962],
            [12.7762, 51.9669],
            [12.775, 51.9763],
            [12.7626, 51.9808],
            [12.7558, 51.9869],
            [12.7292, 51.9892],
            [12.7001, 52.0022],
            [12.684, 52.0026],
            [12.6715, 52.0122],
            [12.6563, 52.0129],
            [12.6486, 52.0066],
            [12.6449, 51.9946],
            [12.6148, 51.992],
            [12.6127, 51.9823],
            [12.565, 51.9837],
            [12.542, 51.988],
            [12.5363, 52.0027],
            [12.504, 52.0076],
            [12.487, 52.0164],
            [12.4807, 52.033],
            [12.4616, 52.0345],
            [12.4532, 52.0259],
            [12.4505, 52.0172],
            [12.4352, 52.0164],
            [12.4263, 52.0201],
            [12.418, 52.0288],
            [12.4094, 52.0289],
            [12.3926, 52.0416],
            [12.3749, 52.0458],
            [12.3591, 52.0476],
            [12.3425, 52.0619],
            [12.3291, 52.0686],
            [12.3187, 52.0865],
            [12.3133, 52.0921],
            [12.3049, 52.0943],
            [12.2869, 52.1031],
            [12.2756, 52.1042],
            [12.2706, 52.1105],
            [12.2696, 52.1228],
            [12.2619, 52.1322],
            [12.2459, 52.1384],
            [12.2298, 52.1573],
            [12.2329, 52.1628],
            [12.222, 52.166],
            [12.2187, 52.1742],
            [12.2273, 52.1843],
            [12.2469, 52.1841],
            [12.2416, 52.1966],
            [12.2489, 52.1992],
            [12.2533, 52.2055],
            [12.2477, 52.2114],
            [12.2599, 52.2165],
            [12.2756, 52.2155],
            [12.289, 52.2255],
            [12.2837, 52.2344],
            [12.2721, 52.2361],
            [12.2702, 52.2414],
            [12.2565, 52.2427],
            [12.249, 52.2477],
            [12.2464, 52.2537],
            [12.2546, 52.2625],
            [12.2503, 52.2658],
            [12.2511, 52.2746],
            [12.263, 52.2915],
            [12.277, 52.3161],
            [12.2882, 52.3225],
            [12.3075, 52.3452],
            [12.2978, 52.3505],
            [12.2829, 52.3654],
            [12.2895, 52.3698],
            [12.3003, 52.3711],
            [12.3049, 52.3777],
            [12.2968, 52.3798],
            [12.2939, 52.3909],
            [12.3023, 52.3964],
            [12.299, 52.4088],
            [12.2914, 52.4074],
            [12.2746, 52.4121],
            [12.2755, 52.4164],
            [12.2967, 52.4241],
            [12.2888, 52.4303],
            [12.3031, 52.436],
            [12.3008, 52.4454],
            [12.3033, 52.4515],
            [12.3151, 52.4509],
            [12.3137, 52.4617],
            [12.3227, 52.4643],
            [12.3313, 52.4788],
            [12.3218, 52.4766],
            [12.3091, 52.4882],
            [12.3265, 52.4907],
            [12.3308, 52.4967],
            [12.3083, 52.4917],
            [12.3033, 52.4948],
            [12.2712, 52.4865],
            [12.2703, 52.5006],
            [12.261, 52.5041],
            [12.2562, 52.5179],
            [12.2396, 52.5221],
            [12.2242, 52.5042],
            [12.2184, 52.5013],
            [12.2088, 52.5038],
            [12.2005, 52.4963],
            [12.1834, 52.4993],
            [12.1704, 52.5079],
            [12.1672, 52.5143],
            [12.1777, 52.5233],
            [12.186, 52.5267],
            [12.1901, 52.5331],
            [12.1767, 52.536],
            [12.1729, 52.5397],
            [12.144, 52.5308],
            [12.1622, 52.5634],
            [12.18, 52.5753],
            [12.1746, 52.579],
            [12.1735, 52.5987],
            [12.1779, 52.6065],
            [12.1708, 52.6076],
            [12.1687, 52.617],
            [12.1717, 52.627],
            [12.1924, 52.6208],
            [12.2024, 52.6197],
            [12.212, 52.6255],
            [12.2295, 52.6301],
            [12.2313, 52.6396],
            [12.2383, 52.6441],
            [12.2349, 52.6601],
            [12.2362, 52.6715],
            [12.2398, 52.6748],
            [12.2375, 52.6821],
            [12.2312, 52.6843],
            [12.2306, 52.6914],
            [12.2202, 52.6901],
            [12.2175, 52.6988],
            [12.2253, 52.7049],
            [12.2119, 52.7093],
            [12.2107, 52.7211],
            [12.2067, 52.7288],
            [12.2081, 52.7357],
            [12.2181, 52.7372],
            [12.2219, 52.7453],
            [12.2218, 52.7533],
            [12.2135, 52.7526],
            [12.2088, 52.7598],
            [12.2147, 52.7753],
            [12.224, 52.776],
            [12.2255, 52.7816],
            [12.2212, 52.7894],
            [12.2414, 52.7889],
            [12.2513, 52.7925],
            [12.2483, 52.7984],
            [12.2564, 52.8075],
            [12.25, 52.8122],
            [12.2446, 52.8272],
            [12.2389, 52.8322],
            [12.2415, 52.8459],
            [12.2318, 52.8487],
            [12.2295, 52.8555],
            [12.2341, 52.8615],
            [12.217, 52.8667],
            [12.2138, 52.864],
            [12.2004, 52.8687],
            [12.1958, 52.8785],
            [12.1767, 52.8676],
            [12.1363, 52.8589],
            [12.1227, 52.8543],
            [12.1315, 52.8663],
            [12.1112, 52.8766],
            [12.1006, 52.8789],
            [12.0813, 52.8766],
            [12.0774, 52.8862],
            [12.0559, 52.8892],
            [12.0494, 52.8863],
            [12.0418, 52.8898],
            [12.0245, 52.8901],
            [12.0076, 52.8874],
            [12.0066, 52.8824],
            [11.9822, 52.8803],
            [11.9784, 52.8776],
            [11.9558, 52.8809],
            [11.9394, 52.8891],
            [11.9234, 52.8918],
            [11.8924, 52.8938],
            [11.8849, 52.8973],
            [11.8757, 52.9057],
            [11.867, 52.9094],
            [11.8319, 52.9097],
            [11.8224, 52.9154],
            [11.8198, 52.9215],
            [11.8286, 52.9321],
            [11.8461, 52.941],
            [11.8438, 52.9513],
            [11.8227, 52.958],
            [11.7983, 52.9579],
            [11.7825, 52.9613],
            [11.7671, 52.978],
            [11.7534, 52.9857],
            [11.7398, 52.9893],
            [11.7262, 52.9873],
            [11.7023, 52.9775],
            [11.6922, 52.9777],
            [11.683, 52.9861],
            [11.6826, 52.9996],
            [11.675, 53.0085],
            [11.6649, 53.0095],
            [11.6494, 53.0057],
            [11.6319, 53.0076],
            [11.6228, 53.0149],
            [11.623, 53.0198],
            [11.6391, 53.0327],
            [11.6382, 53.0387],
            [11.6205, 53.0417],
            [11.5986, 53.0364],
            [11.5884, 53.0353],
            [11.568, 53.0407],
            [11.5506, 53.0528],
            [11.5414, 53.0537],
            [11.5274, 53.0475],
            [11.5175, 53.047],
            [11.5011, 53.0513],
            [11.4632, 53.0758],
            [11.4527, 53.0793],
            [11.4402, 53.0794],
            [11.4257, 53.0762],
            [11.4002, 53.0733],
            [11.378, 53.0646],
            [11.353, 53.0565],
            [11.3346, 53.0578],
            [11.3105, 53.0783],
            [11.2957, 53.0837],
            [11.2791, 53.0935],
            [11.2696, 53.1052],
            [11.2669, 53.1224],
          ],
          [
            [13.4662, 52.6679],
            [13.4595, 52.6696],
            [13.4507, 52.6629],
            [13.471, 52.6513],
            [13.4614, 52.6492],
            [13.4501, 52.6509],
            [13.4338, 52.6444],
            [13.4349, 52.6389],
            [13.4236, 52.6361],
            [13.4144, 52.6429],
            [13.4069, 52.6433],
            [13.3948, 52.6481],
            [13.3871, 52.6382],
            [13.3766, 52.6323],
            [13.3759, 52.6285],
            [13.3601, 52.6237],
            [13.336, 52.6234],
            [13.3099, 52.6289],
            [13.3048, 52.6372],
            [13.3092, 52.6442],
            [13.3015, 52.6531],
            [13.3074, 52.6599],
            [13.2843, 52.6611],
            [13.2832, 52.642],
            [13.2616, 52.6402],
            [13.2638, 52.6288],
            [13.2212, 52.6279],
            [13.2079, 52.6095],
            [13.2021, 52.6067],
            [13.2086, 52.5984],
            [13.2191, 52.5921],
            [13.2162, 52.5875],
            [13.2062, 52.5867],
            [13.1834, 52.5941],
            [13.1669, 52.5974],
            [13.1575, 52.5973],
            [13.1497, 52.5913],
            [13.1337, 52.5864],
            [13.133, 52.5794],
            [13.1501, 52.5827],
            [13.1532, 52.5712],
            [13.1463, 52.5605],
            [13.1463, 52.5526],
            [13.1309, 52.555],
            [13.1243, 52.5434],
            [13.1186, 52.5275],
            [13.1176, 52.5169],
            [13.1436, 52.5197],
            [13.1675, 52.5094],
            [13.1499, 52.4976],
            [13.1284, 52.481],
            [13.1184, 52.4782],
            [13.1119, 52.4665],
            [13.113, 52.4562],
            [13.1101, 52.4493],
            [13.1231, 52.438],
            [13.1128, 52.432],
            [13.1055, 52.4245],
            [13.0996, 52.4249],
            [13.089, 52.419],
            [13.0904, 52.4118],
            [13.1083, 52.4064],
            [13.1239, 52.3969],
            [13.1354, 52.3973],
            [13.1594, 52.394],
            [13.159, 52.4005],
            [13.1667, 52.4061],
            [13.2248, 52.4211],
            [13.2451, 52.4199],
            [13.2484, 52.4043],
            [13.2573, 52.4061],
            [13.2674, 52.4037],
            [13.2776, 52.4054],
            [13.2972, 52.4127],
            [13.3119, 52.3993],
            [13.3425, 52.41],
            [13.3442, 52.4056],
            [13.3706, 52.3935],
            [13.3695, 52.388],
            [13.3854, 52.3877],
            [13.3878, 52.3773],
            [13.4189, 52.3754],
            [13.4271, 52.3862],
            [13.419, 52.4046],
            [13.4192, 52.4095],
            [13.4576, 52.4196],
            [13.4676, 52.418],
            [13.479, 52.3954],
            [13.5154, 52.4011],
            [13.5286, 52.3975],
            [13.5369, 52.3983],
            [13.536, 52.3889],
            [13.5673, 52.3883],
            [13.5934, 52.3921],
            [13.5956, 52.3856],
            [13.606, 52.378],
            [13.6059, 52.3745],
            [13.6284, 52.3822],
            [13.6455, 52.3654],
            [13.6364, 52.3572],
            [13.6358, 52.3453],
            [13.6446, 52.3387],
            [13.6505, 52.3396],
            [13.6582, 52.3515],
            [13.6674, 52.3615],
            [13.6787, 52.3686],
            [13.6881, 52.3665],
            [13.6987, 52.3743],
            [13.697, 52.3812],
            [13.6886, 52.383],
            [13.698, 52.3923],
            [13.7158, 52.3991],
            [13.7326, 52.3999],
            [13.7391, 52.4068],
            [13.7301, 52.4156],
            [13.7444, 52.4387],
            [13.7539, 52.446],
            [13.7302, 52.4494],
            [13.7163, 52.4614],
            [13.7034, 52.4641],
            [13.7061, 52.4565],
            [13.6971, 52.455],
            [13.6956, 52.4634],
            [13.6813, 52.4658],
            [13.6682, 52.4736],
            [13.6598, 52.4739],
            [13.6491, 52.478],
            [13.6399, 52.4784],
            [13.6201, 52.4709],
            [13.6123, 52.4736],
            [13.6136, 52.4794],
            [13.6296, 52.4928],
            [13.6238, 52.4948],
            [13.6285, 52.5057],
            [13.6383, 52.5168],
            [13.6561, 52.5253],
            [13.6557, 52.5295],
            [13.6272, 52.5306],
            [13.6261, 52.5373],
            [13.6184, 52.5431],
            [13.5844, 52.5486],
            [13.5879, 52.5568],
            [13.583, 52.5619],
            [13.5827, 52.5706],
            [13.5694, 52.573],
            [13.5611, 52.5813],
            [13.5504, 52.5875],
            [13.5229, 52.5926],
            [13.5084, 52.5921],
            [13.4976, 52.6076],
            [13.5064, 52.6215],
            [13.5075, 52.6274],
            [13.5178, 52.6302],
            [13.523, 52.6446],
            [13.5133, 52.6459],
            [13.4873, 52.658],
            [13.4883, 52.6713],
            [13.4738, 52.6746],
            [13.4662, 52.6679],
          ],
        ],
      },
      properties: {name: 'Brandenburg', id: 'DE-BB', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-BB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.4662, 52.6679],
            [13.4738, 52.6746],
            [13.4883, 52.6713],
            [13.4873, 52.658],
            [13.5133, 52.6459],
            [13.523, 52.6446],
            [13.5178, 52.6302],
            [13.5075, 52.6274],
            [13.5064, 52.6215],
            [13.4976, 52.6076],
            [13.5084, 52.5921],
            [13.5229, 52.5926],
            [13.5504, 52.5875],
            [13.5611, 52.5813],
            [13.5694, 52.573],
            [13.5827, 52.5706],
            [13.583, 52.5619],
            [13.5879, 52.5568],
            [13.5844, 52.5486],
            [13.6184, 52.5431],
            [13.6261, 52.5373],
            [13.6272, 52.5306],
            [13.6557, 52.5295],
            [13.6561, 52.5253],
            [13.6383, 52.5168],
            [13.6285, 52.5057],
            [13.6238, 52.4948],
            [13.6296, 52.4928],
            [13.6136, 52.4794],
            [13.6123, 52.4736],
            [13.6201, 52.4709],
            [13.6399, 52.4784],
            [13.6491, 52.478],
            [13.6598, 52.4739],
            [13.6682, 52.4736],
            [13.6813, 52.4658],
            [13.6956, 52.4634],
            [13.6971, 52.455],
            [13.7061, 52.4565],
            [13.7034, 52.4641],
            [13.7163, 52.4614],
            [13.7302, 52.4494],
            [13.7539, 52.446],
            [13.7444, 52.4387],
            [13.7301, 52.4156],
            [13.7391, 52.4068],
            [13.7326, 52.3999],
            [13.7158, 52.3991],
            [13.698, 52.3923],
            [13.6886, 52.383],
            [13.697, 52.3812],
            [13.6987, 52.3743],
            [13.6881, 52.3665],
            [13.6787, 52.3686],
            [13.6674, 52.3615],
            [13.6582, 52.3515],
            [13.6505, 52.3396],
            [13.6446, 52.3387],
            [13.6358, 52.3453],
            [13.6364, 52.3572],
            [13.6455, 52.3654],
            [13.6284, 52.3822],
            [13.6059, 52.3745],
            [13.606, 52.378],
            [13.5956, 52.3856],
            [13.5934, 52.3921],
            [13.5673, 52.3883],
            [13.536, 52.3889],
            [13.5369, 52.3983],
            [13.5286, 52.3975],
            [13.5154, 52.4011],
            [13.479, 52.3954],
            [13.4676, 52.418],
            [13.4576, 52.4196],
            [13.4192, 52.4095],
            [13.419, 52.4046],
            [13.4271, 52.3862],
            [13.4189, 52.3754],
            [13.3878, 52.3773],
            [13.3854, 52.3877],
            [13.3695, 52.388],
            [13.3706, 52.3935],
            [13.3442, 52.4056],
            [13.3425, 52.41],
            [13.3119, 52.3993],
            [13.2972, 52.4127],
            [13.2776, 52.4054],
            [13.2674, 52.4037],
            [13.2573, 52.4061],
            [13.2484, 52.4043],
            [13.2451, 52.4199],
            [13.2248, 52.4211],
            [13.1667, 52.4061],
            [13.159, 52.4005],
            [13.1594, 52.394],
            [13.1354, 52.3973],
            [13.1239, 52.3969],
            [13.1083, 52.4064],
            [13.0904, 52.4118],
            [13.089, 52.419],
            [13.0996, 52.4249],
            [13.1055, 52.4245],
            [13.1128, 52.432],
            [13.1231, 52.438],
            [13.1101, 52.4493],
            [13.113, 52.4562],
            [13.1119, 52.4665],
            [13.1184, 52.4782],
            [13.1284, 52.481],
            [13.1499, 52.4976],
            [13.1675, 52.5094],
            [13.1436, 52.5197],
            [13.1176, 52.5169],
            [13.1186, 52.5275],
            [13.1243, 52.5434],
            [13.1309, 52.555],
            [13.1463, 52.5526],
            [13.1463, 52.5605],
            [13.1532, 52.5712],
            [13.1501, 52.5827],
            [13.133, 52.5794],
            [13.1337, 52.5864],
            [13.1497, 52.5913],
            [13.1575, 52.5973],
            [13.1669, 52.5974],
            [13.1834, 52.5941],
            [13.2062, 52.5867],
            [13.2162, 52.5875],
            [13.2191, 52.5921],
            [13.2086, 52.5984],
            [13.2021, 52.6067],
            [13.2079, 52.6095],
            [13.2212, 52.6279],
            [13.2638, 52.6288],
            [13.2616, 52.6402],
            [13.2832, 52.642],
            [13.2843, 52.6611],
            [13.3074, 52.6599],
            [13.3015, 52.6531],
            [13.3092, 52.6442],
            [13.3048, 52.6372],
            [13.3099, 52.6289],
            [13.336, 52.6234],
            [13.3601, 52.6237],
            [13.3759, 52.6285],
            [13.3766, 52.6323],
            [13.3871, 52.6382],
            [13.3948, 52.6481],
            [13.4069, 52.6433],
            [13.4144, 52.6429],
            [13.4236, 52.6361],
            [13.4349, 52.6389],
            [13.4338, 52.6444],
            [13.4501, 52.6509],
            [13.4614, 52.6492],
            [13.471, 52.6513],
            [13.4507, 52.6629],
            [13.4595, 52.6696],
            [13.4662, 52.6679],
          ],
        ],
      },
      properties: {name: 'Berlin', id: 'DE-BE', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-BE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [9.1034, 49.5776],
              [9.1094, 49.5791],
              [9.1218, 49.5734],
              [9.1388, 49.5758],
              [9.1459, 49.5787],
              [9.1588, 49.5794],
              [9.166, 49.5757],
              [9.1739, 49.5777],
              [9.1817, 49.5736],
              [9.1946, 49.5741],
              [9.2392, 49.581],
              [9.2487, 49.5847],
              [9.2503, 49.5938],
              [9.2598, 49.5938],
              [9.263, 49.6052],
              [9.2684, 49.609],
              [9.2843, 49.6144],
              [9.2755, 49.6241],
              [9.2703, 49.6334],
              [9.2781, 49.6376],
              [9.2857, 49.6361],
              [9.2908, 49.6437],
              [9.2999, 49.6488],
              [9.3134, 49.6531],
              [9.3339, 49.6447],
              [9.3452, 49.6441],
              [9.3538, 49.6505],
              [9.3672, 49.6572],
              [9.3721, 49.6512],
              [9.3825, 49.6443],
              [9.4065, 49.6452],
              [9.4171, 49.6534],
              [9.4201, 49.6588],
              [9.4046, 49.6662],
              [9.4005, 49.6724],
              [9.4218, 49.6901],
              [9.4226, 49.6957],
              [9.4176, 49.7022],
              [9.4196, 49.7114],
              [9.4084, 49.721],
              [9.402, 49.722],
              [9.4007, 49.7286],
              [9.3872, 49.7307],
              [9.3823, 49.7259],
              [9.3832, 49.7093],
              [9.3765, 49.705],
              [9.3584, 49.7041],
              [9.3535, 49.7081],
              [9.3504, 49.7213],
              [9.372, 49.7261],
              [9.3662, 49.7313],
              [9.3569, 49.7312],
              [9.3406, 49.7344],
              [9.3214, 49.7319],
              [9.3165, 49.74],
              [9.3235, 49.7461],
              [9.3132, 49.7604],
              [9.3153, 49.7682],
              [9.3315, 49.7695],
              [9.3432, 49.7681],
              [9.3627, 49.7725],
              [9.3717, 49.7789],
              [9.3784, 49.7774],
              [9.3918, 49.7698],
              [9.4044, 49.7764],
              [9.4193, 49.7878],
              [9.4295, 49.7893],
              [9.4375, 49.785],
              [9.4443, 49.7744],
              [9.4577, 49.7694],
              [9.4691, 49.7742],
              [9.4722, 49.7813],
              [9.4795, 49.787],
              [9.5024, 49.7888],
              [9.5129, 49.782],
              [9.5106, 49.7674],
              [9.5187, 49.7616],
              [9.5284, 49.7628],
              [9.5375, 49.7678],
              [9.5457, 49.7668],
              [9.5524, 49.76],
              [9.5544, 49.7483],
              [9.566, 49.7454],
              [9.5668, 49.7538],
              [9.5586, 49.7636],
              [9.5569, 49.7749],
              [9.5678, 49.7829],
              [9.5774, 49.779],
              [9.6079, 49.7831],
              [9.6199, 49.7835],
              [9.6499, 49.7916],
              [9.6507, 49.7797],
              [9.6463, 49.7775],
              [9.647, 49.77],
              [9.6438, 49.7573],
              [9.6388, 49.7541],
              [9.6456, 49.7467],
              [9.6399, 49.7429],
              [9.6356, 49.732],
              [9.6427, 49.7237],
              [9.6333, 49.7185],
              [9.6301, 49.7024],
              [9.6328, 49.6937],
              [9.6424, 49.6936],
              [9.6539, 49.6873],
              [9.6766, 49.6935],
              [9.675, 49.6974],
              [9.6783, 49.7166],
              [9.6949, 49.7194],
              [9.7118, 49.727],
              [9.7137, 49.7123],
              [9.7196, 49.7022],
              [9.729, 49.6982],
              [9.7278, 49.6881],
              [9.737, 49.6881],
              [9.7496, 49.6954],
              [9.7536, 49.7102],
              [9.7636, 49.7167],
              [9.7795, 49.7225],
              [9.7949, 49.7213],
              [9.8169, 49.7115],
              [9.8233, 49.706],
              [9.833, 49.7028],
              [9.8314, 49.6879],
              [9.8393, 49.6813],
              [9.8308, 49.679],
              [9.823, 49.6729],
              [9.8222, 49.6666],
              [9.8324, 49.6572],
              [9.8296, 49.6542],
              [9.8367, 49.6458],
              [9.8533, 49.6436],
              [9.8583, 49.6328],
              [9.8631, 49.6293],
              [9.8669, 49.6202],
              [9.8668, 49.6123],
              [9.8592, 49.608],
              [9.8585, 49.6029],
              [9.8417, 49.5944],
              [9.8409, 49.5867],
              [9.8462, 49.5848],
              [9.8476, 49.5772],
              [9.8365, 49.5683],
              [9.8179, 49.5577],
              [9.8191, 49.5542],
              [9.8393, 49.5496],
              [9.8499, 49.5449],
              [9.8585, 49.5451],
              [9.8604, 49.5524],
              [9.8656, 49.555],
              [9.864, 49.5671],
              [9.873, 49.5714],
              [9.8746, 49.5774],
              [9.9011, 49.585],
              [9.9145, 49.5825],
              [9.9008, 49.5709],
              [9.8975, 49.5619],
              [9.9132, 49.5604],
              [9.9193, 49.5624],
              [9.9258, 49.549],
              [9.9199, 49.5441],
              [9.9255, 49.5361],
              [9.9229, 49.5233],
              [9.9159, 49.5185],
              [9.9234, 49.5073],
              [9.915, 49.4992],
              [9.9143, 49.4915],
              [9.9267, 49.4844],
              [9.9534, 49.4798],
              [9.9584, 49.4831],
              [9.9714, 49.485],
              [9.9779, 49.483],
              [9.9987, 49.4852],
              [10.0127, 49.4834],
              [10.0224, 49.4891],
              [10.0289, 49.5037],
              [10.0375, 49.5049],
              [10.0522, 49.5109],
              [10.0607, 49.519],
              [10.0528, 49.5204],
              [10.0421, 49.5297],
              [10.0621, 49.539],
              [10.0617, 49.5457],
              [10.0838, 49.5436],
              [10.092, 49.5317],
              [10.0813, 49.528],
              [10.0781, 49.5203],
              [10.0842, 49.5121],
              [10.1066, 49.5145],
              [10.1091, 49.5113],
              [10.1205, 49.511],
              [10.1166, 49.5033],
              [10.1099, 49.5017],
              [10.1013, 49.4944],
              [10.1013, 49.4859],
              [10.1084, 49.4823],
              [10.1159, 49.4733],
              [10.1163, 49.4617],
              [10.1049, 49.4626],
              [10.0974, 49.4569],
              [10.0942, 49.4477],
              [10.1074, 49.4419],
              [10.1171, 49.445],
              [10.1337, 49.4377],
              [10.1339, 49.4317],
              [10.1452, 49.4191],
              [10.1522, 49.4016],
              [10.1608, 49.3901],
              [10.1443, 49.3846],
              [10.1232, 49.3876],
              [10.1116, 49.3843],
              [10.1072, 49.379],
              [10.1175, 49.3677],
              [10.1131, 49.3597],
              [10.1235, 49.3569],
              [10.1234, 49.3504],
              [10.1087, 49.3521],
              [10.1022, 49.3456],
              [10.1049, 49.3384],
              [10.1129, 49.3356],
              [10.1201, 49.3252],
              [10.1327, 49.3281],
              [10.1422, 49.3228],
              [10.1449, 49.3166],
              [10.1319, 49.3053],
              [10.1321, 49.3003],
              [10.1416, 49.2876],
              [10.1544, 49.2852],
              [10.1433, 49.2711],
              [10.1344, 49.2625],
              [10.1168, 49.2594],
              [10.1256, 49.2556],
              [10.1293, 49.25],
              [10.1233, 49.2447],
              [10.1254, 49.2292],
              [10.1308, 49.2186],
              [10.1385, 49.2165],
              [10.1392, 49.2099],
              [10.1264, 49.2114],
              [10.1301, 49.2031],
              [10.1431, 49.195],
              [10.1556, 49.1929],
              [10.1596, 49.1832],
              [10.1702, 49.178],
              [10.1798, 49.1827],
              [10.1802, 49.1721],
              [10.1927, 49.1677],
              [10.1987, 49.1613],
              [10.1992, 49.155],
              [10.216, 49.1631],
              [10.2248, 49.1608],
              [10.2376, 49.1494],
              [10.248, 49.1489],
              [10.2539, 49.1404],
              [10.2553, 49.1296],
              [10.2412, 49.1246],
              [10.238, 49.1185],
              [10.2302, 49.1133],
              [10.2135, 49.111],
              [10.2147, 49.1046],
              [10.2094, 49.1011],
              [10.2138, 49.0955],
              [10.2227, 49.0981],
              [10.2324, 49.0895],
              [10.2505, 49.0918],
              [10.2467, 49.0837],
              [10.2407, 49.0821],
              [10.2358, 49.074],
              [10.245, 49.0739],
              [10.2578, 49.0696],
              [10.262, 49.0629],
              [10.2517, 49.0482],
              [10.2499, 49.0421],
              [10.257, 49.0387],
              [10.265, 49.0448],
              [10.2835, 49.037],
              [10.2921, 49.0427],
              [10.3017, 49.0386],
              [10.3085, 49.032],
              [10.3215, 49.0263],
              [10.3335, 49.0315],
              [10.3358, 49.0277],
              [10.3482, 49.0279],
              [10.347, 49.0172],
              [10.3551, 49.0128],
              [10.356, 49.0043],
              [10.3678, 49.0038],
              [10.3809, 49.0006],
              [10.3827, 48.992],
              [10.3921, 48.9899],
              [10.4017, 48.9831],
              [10.4097, 48.9809],
              [10.4022, 48.9745],
              [10.4045, 48.9665],
              [10.4176, 48.9686],
              [10.4245, 48.9638],
              [10.43, 48.947],
              [10.4414, 48.9458],
              [10.4512, 48.9385],
              [10.4381, 48.9336],
              [10.4519, 48.9265],
              [10.4571, 48.9167],
              [10.444, 48.9086],
              [10.4316, 48.9082],
              [10.423, 48.9054],
              [10.4303, 48.9007],
              [10.4403, 48.8987],
              [10.4537, 48.8885],
              [10.4468, 48.877],
              [10.4522, 48.8737],
              [10.4499, 48.8632],
              [10.4535, 48.8585],
              [10.4526, 48.8516],
              [10.4277, 48.8431],
              [10.4309, 48.8377],
              [10.4469, 48.8326],
              [10.45, 48.8246],
              [10.4473, 48.813],
              [10.4348, 48.8011],
              [10.4221, 48.7999],
              [10.4203, 48.7958],
              [10.4296, 48.7931],
              [10.4263, 48.7722],
              [10.4155, 48.7716],
              [10.417, 48.7651],
              [10.4233, 48.7579],
              [10.4233, 48.7441],
              [10.4385, 48.7372],
              [10.4434, 48.7295],
              [10.4652, 48.7269],
              [10.4684, 48.7143],
              [10.4813, 48.7113],
              [10.4772, 48.7041],
              [10.4862, 48.6903],
              [10.4805, 48.6804],
              [10.4647, 48.6704],
              [10.4541, 48.6704],
              [10.4491, 48.6658],
              [10.4193, 48.6638],
              [10.4108, 48.6613],
              [10.4082, 48.6673],
              [10.4226, 48.6702],
              [10.4264, 48.6862],
              [10.4346, 48.6875],
              [10.4377, 48.694],
              [10.4306, 48.6988],
              [10.4258, 48.6946],
              [10.4136, 48.698],
              [10.3922, 48.6869],
              [10.3917, 48.6775],
              [10.3875, 48.6732],
              [10.3887, 48.6672],
              [10.3682, 48.6634],
              [10.3607, 48.6533],
              [10.352, 48.654],
              [10.3511, 48.6651],
              [10.3457, 48.6788],
              [10.3349, 48.6891],
              [10.3254, 48.6904],
              [10.3177, 48.6834],
              [10.292, 48.6921],
              [10.2826, 48.6995],
              [10.2685, 48.7027],
              [10.2729, 48.6858],
              [10.2574, 48.6804],
              [10.2631, 48.6721],
              [10.2546, 48.6667],
              [10.2678, 48.6479],
              [10.2802, 48.6398],
              [10.295, 48.6327],
              [10.2981, 48.6228],
              [10.3221, 48.6086],
              [10.3195, 48.6033],
              [10.2976, 48.6034],
              [10.2931, 48.5895],
              [10.3056, 48.5824],
              [10.3129, 48.5644],
              [10.3105, 48.5525],
              [10.3049, 48.5507],
              [10.3012, 48.5429],
              [10.303, 48.5296],
              [10.3104, 48.523],
              [10.2967, 48.5165],
              [10.2741, 48.516],
              [10.2638, 48.5198],
              [10.2545, 48.5186],
              [10.2412, 48.5057],
              [10.2374, 48.4995],
              [10.2408, 48.4918],
              [10.235, 48.4881],
              [10.2168, 48.4877],
              [10.1832, 48.4756],
              [10.1736, 48.468],
              [10.1774, 48.4634],
              [10.1703, 48.458],
              [10.1598, 48.4583],
              [10.1349, 48.455],
              [10.127, 48.457],
              [10.1251, 48.4638],
              [10.1174, 48.4681],
              [10.1169, 48.4754],
              [10.1059, 48.4776],
              [10.0994, 48.47],
              [10.0708, 48.4582],
              [10.0474, 48.457],
              [10.037, 48.4591],
              [10.0307, 48.4562],
              [10.0358, 48.4506],
              [10.0278, 48.4376],
              [10.0314, 48.4336],
              [10.041, 48.4346],
              [10.0416, 48.4292],
              [10.0327, 48.4262],
              [10.0178, 48.4178],
              [10.0166, 48.4069],
              [10.0098, 48.4009],
              [9.9861, 48.3916],
              [9.9839, 48.3859],
              [9.9705, 48.3783],
              [9.9684, 48.3711],
              [9.975, 48.3638],
              [9.9826, 48.366],
              [9.9956, 48.3632],
              [9.9978, 48.3473],
              [10.0106, 48.3434],
              [10.0102, 48.3358],
              [10.0153, 48.3282],
              [10.0249, 48.3226],
              [10.0299, 48.3131],
              [10.0436, 48.2995],
              [10.0462, 48.2915],
              [10.0649, 48.2818],
              [10.062, 48.2717],
              [10.0655, 48.2619],
              [10.062, 48.2573],
              [10.0683, 48.2524],
              [10.0625, 48.2444],
              [10.064, 48.2338],
              [10.0693, 48.2301],
              [10.0699, 48.2221],
              [10.0782, 48.2214],
              [10.0789, 48.2088],
              [10.0847, 48.2073],
              [10.0838, 48.1935],
              [10.0858, 48.1822],
              [10.0911, 48.1776],
              [10.0915, 48.1699],
              [10.0962, 48.1578],
              [10.1056, 48.1447],
              [10.1045, 48.1405],
              [10.1133, 48.1245],
              [10.1252, 48.116],
              [10.1359, 48.1122],
              [10.1377, 48.0946],
              [10.1402, 48.0901],
              [10.1344, 48.0817],
              [10.1376, 48.0741],
              [10.1443, 48.0741],
              [10.1411, 48.0654],
              [10.1443, 48.0587],
              [10.1395, 48.0521],
              [10.1413, 48.0466],
              [10.1297, 48.0381],
              [10.1271, 48.0324],
              [10.134, 48.0254],
              [10.1343, 48.0183],
              [10.1201, 48.0038],
              [10.1216, 47.9966],
              [10.1149, 47.9889],
              [10.1152, 47.9751],
              [10.1048, 47.9742],
              [10.0877, 47.9674],
              [10.0857, 47.9531],
              [10.092, 47.945],
              [10.1085, 47.9381],
              [10.1105, 47.9323],
              [10.1045, 47.9228],
              [10.1095, 47.9195],
              [10.1086, 47.9091],
              [10.101, 47.904],
              [10.1047, 47.8834],
              [10.1031, 47.8775],
              [10.0929, 47.8687],
              [10.0829, 47.8698],
              [10.0775, 47.8668],
              [10.0846, 47.8562],
              [10.1008, 47.865],
              [10.1047, 47.8638],
              [10.1018, 47.8437],
              [10.1093, 47.8393],
              [10.1252, 47.8232],
              [10.1311, 47.8223],
              [10.1342, 47.8134],
              [10.1193, 47.8125],
              [10.1109, 47.8064],
              [10.1182, 47.8016],
              [10.1005, 47.8011],
              [10.0899, 47.8035],
              [10.0886, 47.7983],
              [10.0743, 47.7903],
              [10.0665, 47.7884],
              [10.0661, 47.7826],
              [10.0804, 47.7803],
              [10.0822, 47.7721],
              [10.0957, 47.7664],
              [10.1161, 47.7614],
              [10.1172, 47.7507],
              [10.1135, 47.7417],
              [10.1191, 47.7348],
              [10.1157, 47.7197],
              [10.1208, 47.7123],
              [10.1347, 47.7058],
              [10.1393, 47.6981],
              [10.1305, 47.6919],
              [10.1333, 47.6885],
              [10.1303, 47.6777],
              [10.1071, 47.6675],
              [10.0945, 47.668],
              [10.0892, 47.6595],
              [10.0817, 47.6549],
              [10.0798, 47.6447],
              [10.0721, 47.6504],
              [10.0747, 47.6585],
              [10.0695, 47.6635],
              [10.0647, 47.6746],
              [10.0592, 47.6799],
              [10.0481, 47.681],
              [10.0397, 47.6765],
              [10.0304, 47.6749],
              [10.0299, 47.6839],
              [10.0213, 47.6849],
              [10.0014, 47.6819],
              [9.9874, 47.6742],
              [9.9767, 47.6649],
              [9.9779, 47.6612],
              [9.969, 47.6531],
              [9.9514, 47.6554],
              [9.9351, 47.6557],
              [9.9306, 47.6537],
              [9.9181, 47.6591],
              [9.9161, 47.6632],
              [9.896, 47.6625],
              [9.8796, 47.6687],
              [9.8755, 47.6747],
              [9.8565, 47.6771],
              [9.8489, 47.6754],
              [9.8378, 47.6767],
              [9.8374, 47.6701],
              [9.8308, 47.668],
              [9.83, 47.6635],
              [9.8284, 47.6575],
              [9.815, 47.649],
              [9.7988, 47.6495],
              [9.7993, 47.6442],
              [9.7934, 47.6345],
              [9.7782, 47.6308],
              [9.7644, 47.6182],
              [9.7521, 47.6119],
              [9.7452, 47.6166],
              [9.7307, 47.6123],
              [9.7267, 47.6067],
              [9.7175, 47.6075],
              [9.7063, 47.6017],
              [9.6993, 47.6023],
              [9.691, 47.6093],
              [9.6779, 47.6024],
              [9.6727, 47.6104],
              [9.6571, 47.6114],
              [9.6469, 47.605],
              [9.6328, 47.5935],
              [9.6219, 47.5894],
              [9.6201, 47.5836],
              [9.6117, 47.5819],
              [9.6071, 47.5747],
              [9.599, 47.5479],
              [9.5023, 47.5603],
              [9.4645, 47.5646],
              [9.1858, 47.6573],
              [9.171, 47.6557],
              [9.1551, 47.6665],
              [9.1405, 47.6641],
              [9.1206, 47.668],
              [9.1139, 47.6717],
              [9.0893, 47.6799],
              [9.0661, 47.6797],
              [9.0488, 47.683],
              [9.0222, 47.6865],
              [9.0154, 47.6853],
              [8.9491, 47.6598],
              [8.9407, 47.6572],
              [8.903, 47.6489],
              [8.8949, 47.6484],
              [8.8765, 47.655],
              [8.8757, 47.6642],
              [8.8675, 47.6763],
              [8.8508, 47.6837],
              [8.8635, 47.6981],
              [8.8682, 47.6946],
              [8.8759, 47.6971],
              [8.8677, 47.7049],
              [8.8501, 47.7037],
              [8.8441, 47.7124],
              [8.8325, 47.7143],
              [8.8254, 47.7116],
              [8.813, 47.7243],
              [8.8093, 47.7381],
              [8.7975, 47.735],
              [8.7932, 47.7292],
              [8.7811, 47.7223],
              [8.7708, 47.7187],
              [8.7734, 47.7142],
              [8.7702, 47.7072],
              [8.7989, 47.7024],
              [8.7989, 47.6973],
              [8.8073, 47.692],
              [8.7985, 47.6897],
              [8.7928, 47.6746],
              [8.7844, 47.6768],
              [8.7714, 47.6845],
              [8.7586, 47.6891],
              [8.7274, 47.6936],
              [8.7269, 47.6998],
              [8.7306, 47.7108],
              [8.7367, 47.7162],
              [8.7184, 47.7216],
              [8.7131, 47.7282],
              [8.7169, 47.7394],
              [8.7264, 47.7463],
              [8.739, 47.7464],
              [8.7399, 47.7529],
              [8.7235, 47.7648],
              [8.7138, 47.7657],
              [8.6997, 47.7604],
              [8.6943, 47.7561],
              [8.6849, 47.7712],
              [8.6843, 47.7847],
              [8.6657, 47.7884],
              [8.6589, 47.7928],
              [8.6546, 47.8],
              [8.6471, 47.7943],
              [8.6447, 47.7881],
              [8.6499, 47.7837],
              [8.6459, 47.7694],
              [8.632, 47.7586],
              [8.6199, 47.7666],
              [8.6233, 47.7763],
              [8.6174, 47.7854],
              [8.6216, 47.7942],
              [8.6133, 47.8013],
              [8.5884, 47.8026],
              [8.5765, 47.7997],
              [8.5716, 47.8064],
              [8.5629, 47.8046],
              [8.5619, 47.7937],
              [8.5739, 47.7898],
              [8.5752, 47.7806],
              [8.5642, 47.7769],
              [8.5522, 47.7845],
              [8.5256, 47.7782],
              [8.5204, 47.7705],
              [8.5109, 47.7755],
              [8.4939, 47.7698],
              [8.4885, 47.7715],
              [8.4735, 47.7627],
              [8.4652, 47.753],
              [8.456, 47.748],
              [8.4492, 47.7375],
              [8.4564, 47.7295],
              [8.4543, 47.7223],
              [8.4433, 47.7224],
              [8.4275, 47.7112],
              [8.4182, 47.7089],
              [8.4065, 47.6984],
              [8.4212, 47.6835],
              [8.4084, 47.6779],
              [8.406, 47.6735],
              [8.4125, 47.6657],
              [8.4242, 47.6659],
              [8.4366, 47.6568],
              [8.4567, 47.6524],
              [8.4636, 47.649],
              [8.4669, 47.6411],
              [8.4939, 47.6464],
              [8.5128, 47.6472],
              [8.5226, 47.6443],
              [8.5315, 47.6463],
              [8.5318, 47.6524],
              [8.5403, 47.6568],
              [8.5394, 47.6635],
              [8.5442, 47.6668],
              [8.5585, 47.6698],
              [8.5746, 47.6626],
              [8.5817, 47.6628],
              [8.5927, 47.6671],
              [8.599, 47.6721],
              [8.6057, 47.6717],
              [8.6076, 47.663],
              [8.6153, 47.661],
              [8.6274, 47.6534],
              [8.6241, 47.6415],
              [8.6154, 47.6374],
              [8.6128, 47.6494],
              [8.6043, 47.6511],
              [8.5954, 47.6408],
              [8.5972, 47.6288],
              [8.6047, 47.6131],
              [8.5962, 47.6055],
              [8.5741, 47.5949],
              [8.561, 47.6015],
              [8.571, 47.6128],
              [8.5628, 47.6163],
              [8.5558, 47.6242],
              [8.539, 47.6258],
              [8.5384, 47.6302],
              [8.5192, 47.6328],
              [8.5082, 47.6179],
              [8.4952, 47.6147],
              [8.4801, 47.6144],
              [8.4768, 47.6087],
              [8.4653, 47.6021],
              [8.4573, 47.6018],
              [8.4606, 47.5886],
              [8.4672, 47.5838],
              [8.4829, 47.5854],
              [8.4886, 47.5884],
              [8.4939, 47.5814],
              [8.4866, 47.5771],
              [8.4758, 47.5777],
              [8.4657, 47.5725],
              [8.4536, 47.572],
              [8.4312, 47.566],
              [8.4169, 47.5698],
              [8.3974, 47.577],
              [8.3885, 47.5726],
              [8.3826, 47.5658],
              [8.3599, 47.5698],
              [8.332, 47.5706],
              [8.3204, 47.5748],
              [8.3103, 47.5834],
              [8.3017, 47.586],
              [8.2948, 47.5939],
              [8.2976, 47.6028],
              [8.2884, 47.6095],
              [8.2777, 47.611],
              [8.2643, 47.609],
              [8.2566, 47.6151],
              [8.2376, 47.612],
              [8.2295, 47.6059],
              [8.2233, 47.6061],
              [8.2208, 47.6147],
              [8.2136, 47.6194],
              [8.2048, 47.6206],
              [8.1957, 47.6177],
              [8.1848, 47.6052],
              [8.174, 47.6013],
              [8.1602, 47.5934],
              [8.1478, 47.5957],
              [8.1381, 47.5898],
              [8.1354, 47.5839],
              [8.1153, 47.5836],
              [8.1031, 47.5761],
              [8.1006, 47.5657],
              [8.0894, 47.5575],
              [8.0814, 47.5579],
              [8.0724, 47.5633],
              [8.0617, 47.5629],
              [8.0398, 47.5532],
              [8.0195, 47.5504],
              [7.9978, 47.5562],
              [7.9689, 47.5555],
              [7.9616, 47.5574],
              [7.9526, 47.5543],
              [7.9478, 47.5451],
              [7.9188, 47.5472],
              [7.9124, 47.5501],
              [7.9072, 47.5593],
              [7.9106, 47.5665],
              [7.9029, 47.5804],
              [7.8917, 47.5871],
              [7.8683, 47.5883],
              [7.8475, 47.5821],
              [7.8379, 47.5828],
              [7.8279, 47.5873],
              [7.8193, 47.5867],
              [7.81, 47.5677],
              [7.8004, 47.5633],
              [7.7961, 47.5573],
              [7.7827, 47.5528],
              [7.7572, 47.5477],
              [7.7519, 47.5447],
              [7.7362, 47.5417],
              [7.7203, 47.5418],
              [7.6976, 47.5328],
              [7.6794, 47.5325],
              [7.6672, 47.5361],
              [7.6613, 47.544],
              [7.6496, 47.5474],
              [7.6344, 47.5611],
              [7.6369, 47.5644],
              [7.6487, 47.5598],
              [7.662, 47.565],
              [7.6773, 47.5643],
              [7.6835, 47.5744],
              [7.6802, 47.5825],
              [7.6729, 47.5845],
              [7.6738, 47.5914],
              [7.6474, 47.5951],
              [7.6222, 47.5779],
              [7.6066, 47.579],
              [7.6053, 47.5856],
              [7.5904, 47.5895],
              [7.5931, 47.5981],
              [7.5885, 47.6075],
              [7.5743, 47.6155],
              [7.57, 47.6276],
              [7.561, 47.6366],
              [7.5382, 47.6483],
              [7.5226, 47.6619],
              [7.5188, 47.6703],
              [7.5168, 47.6886],
              [7.5125, 47.6948],
              [7.5146, 47.7028],
              [7.537, 47.7169],
              [7.545, 47.724],
              [7.5482, 47.7334],
              [7.5476, 47.7403],
              [7.5312, 47.7704],
              [7.5317, 47.7866],
              [7.5458, 47.8119],
              [7.5508, 47.8252],
              [7.5593, 47.8349],
              [7.5632, 47.8489],
              [7.5559, 47.8678],
              [7.5569, 47.8788],
              [7.5612, 47.8835],
              [7.5783, 47.8925],
              [7.5837, 47.903],
              [7.5808, 47.9209],
              [7.5876, 47.9361],
              [7.6105, 47.9545],
              [7.6226, 47.9718],
              [7.6191, 47.9852],
              [7.6083, 48.0011],
              [7.5725, 48.0303],
              [7.5699, 48.0386],
              [7.5744, 48.0536],
              [7.5697, 48.0774],
              [7.5717, 48.0876],
              [7.5784, 48.0974],
              [7.5794, 48.1072],
              [7.5767, 48.1162],
              [7.5807, 48.1251],
              [7.5933, 48.1311],
              [7.6006, 48.1404],
              [7.5987, 48.1518],
              [7.601, 48.1585],
              [7.628, 48.1828],
              [7.6435, 48.2067],
              [7.6614, 48.2165],
              [7.6668, 48.2217],
              [7.689, 48.2787],
              [7.6896, 48.2938],
              [7.6934, 48.3019],
              [7.7053, 48.3097],
              [7.731, 48.3168],
              [7.7417, 48.323],
              [7.746, 48.334],
              [7.7326, 48.3736],
              [7.7313, 48.3863],
              [7.7366, 48.4052],
              [7.7597, 48.4441],
              [7.7642, 48.4537],
              [7.7671, 48.4662],
              [7.7675, 48.4859],
              [7.7734, 48.4935],
              [7.7963, 48.5022],
              [7.8024, 48.5077],
              [7.8078, 48.5213],
              [7.8033, 48.5364],
              [7.8054, 48.5608],
              [7.8005, 48.5752],
              [7.8003, 48.5842],
              [7.8044, 48.5918],
              [7.8311, 48.6206],
              [7.8393, 48.6412],
              [7.8465, 48.6464],
              [7.8584, 48.6496],
              [7.8845, 48.6599],
              [7.8973, 48.668],
              [7.9641, 48.7222],
              [7.9678, 48.7296],
              [7.9669, 48.7462],
              [7.9688, 48.7534],
              [7.9757, 48.7591],
              [7.9867, 48.7603],
              [8.0045, 48.7579],
              [8.0158, 48.7606],
              [8.0242, 48.7687],
              [8.0265, 48.7815],
              [8.0298, 48.7861],
              [8.0417, 48.7903],
              [8.0604, 48.7885],
              [8.072, 48.7911],
              [8.0848, 48.7988],
              [8.1, 48.813],
              [8.1047, 48.8205],
              [8.1094, 48.8379],
              [8.1231, 48.8672],
              [8.1332, 48.8841],
              [8.158, 48.9145],
              [8.1799, 48.9358],
              [8.1933, 48.9541],
              [8.2012, 48.9592],
              [8.2324, 48.9662],
              [8.271, 48.9863],
              [8.2842, 48.9954],
              [8.2974, 49.0141],
              [8.3016, 49.0345],
              [8.3082, 49.0478],
              [8.3176, 49.0612],
              [8.327, 49.0711],
              [8.3394, 49.0796],
              [8.3528, 49.091],
              [8.3588, 49.1009],
              [8.368, 49.1335],
              [8.3717, 49.1503],
              [8.3667, 49.1635],
              [8.3679, 49.1691],
              [8.3811, 49.1867],
              [8.3839, 49.1962],
              [8.3983, 49.207],
              [8.4041, 49.2168],
              [8.4034, 49.2236],
              [8.3858, 49.226],
              [8.3905, 49.2347],
              [8.3979, 49.242],
              [8.4348, 49.2586],
              [8.4451, 49.2654],
              [8.4576, 49.2777],
              [8.4683, 49.2826],
              [8.4827, 49.2863],
              [8.4883, 49.295],
              [8.4838, 49.3033],
              [8.4746, 49.3082],
              [8.4583, 49.3115],
              [8.4506, 49.3182],
              [8.4502, 49.3247],
              [8.4566, 49.3327],
              [8.4745, 49.3425],
              [8.4872, 49.3516],
              [8.4977, 49.3664],
              [8.5003, 49.3778],
              [8.4997, 49.3882],
              [8.4919, 49.3858],
              [8.4734, 49.3733],
              [8.463, 49.3767],
              [8.464, 49.386],
              [8.4951, 49.4011],
              [8.4989, 49.4174],
              [8.5078, 49.4313],
              [8.5049, 49.4384],
              [8.4863, 49.4435],
              [8.4624, 49.4429],
              [8.4485, 49.4447],
              [8.4426, 49.45],
              [8.4426, 49.4555],
              [8.45, 49.4613],
              [8.4593, 49.4637],
              [8.4633, 49.4706],
              [8.4597, 49.4767],
              [8.4433, 49.4917],
              [8.4311, 49.5239],
              [8.4227, 49.5418],
              [8.4167, 49.5501],
              [8.4162, 49.5645],
              [8.4237, 49.5743],
              [8.4224, 49.5836],
              [8.438, 49.583],
              [8.4413, 49.5884],
              [8.4635, 49.5885],
              [8.4753, 49.5833],
              [8.4806, 49.5736],
              [8.4928, 49.5727],
              [8.5243, 49.5476],
              [8.5306, 49.5358],
              [8.5488, 49.5201],
              [8.561, 49.5142],
              [8.5729, 49.5194],
              [8.591, 49.5231],
              [8.5962, 49.5311],
              [8.6072, 49.5336],
              [8.6159, 49.5408],
              [8.6156, 49.5549],
              [8.6075, 49.5718],
              [8.6031, 49.5924],
              [8.5909, 49.5956],
              [8.5915, 49.6049],
              [8.6026, 49.6124],
              [8.6797, 49.6253],
              [8.6922, 49.6201],
              [8.6869, 49.6169],
              [8.6799, 49.6222],
              [8.6698, 49.6168],
              [8.6718, 49.6093],
              [8.6812, 49.6032],
              [8.6912, 49.6071],
              [8.6972, 49.5974],
              [8.6964, 49.5925],
              [8.6835, 49.5893],
              [8.6813, 49.5833],
              [8.684, 49.5729],
              [8.693, 49.562],
              [8.6931, 49.5534],
              [8.7051, 49.5489],
              [8.7019, 49.5358],
              [8.7111, 49.5314],
              [8.7176, 49.5325],
              [8.7253, 49.5256],
              [8.7508, 49.5217],
              [8.7568, 49.516],
              [8.7672, 49.5152],
              [8.7738, 49.5177],
              [8.7921, 49.5137],
              [8.8101, 49.5256],
              [8.8174, 49.5245],
              [8.8214, 49.5136],
              [8.8177, 49.506],
              [8.8323, 49.4919],
              [8.8391, 49.4939],
              [8.847, 49.5011],
              [8.8584, 49.5011],
              [8.879, 49.5055],
              [8.8991, 49.5029],
              [8.9005, 49.4848],
              [8.8916, 49.4799],
              [8.8902, 49.4756],
              [8.8771, 49.467],
              [8.8625, 49.4663],
              [8.8665, 49.4747],
              [8.8599, 49.4812],
              [8.8529, 49.4836],
              [8.847, 49.4789],
              [8.8281, 49.4709],
              [8.8255, 49.4638],
              [8.8348, 49.4503],
              [8.8339, 49.4381],
              [8.838, 49.4338],
              [8.8301, 49.4232],
              [8.8163, 49.4231],
              [8.807, 49.4196],
              [8.8088, 49.4111],
              [8.8044, 49.4039],
              [8.8152, 49.3968],
              [8.8238, 49.3974],
              [8.8274, 49.406],
              [8.8387, 49.4053],
              [8.843, 49.3983],
              [8.8525, 49.3955],
              [8.8605, 49.3998],
              [8.8744, 49.4131],
              [8.8872, 49.4168],
              [8.8941, 49.4214],
              [8.9009, 49.4427],
              [8.9131, 49.4377],
              [8.9251, 49.4418],
              [8.9295, 49.4505],
              [8.9424, 49.4514],
              [8.9501, 49.4556],
              [8.9313, 49.47],
              [8.9404, 49.4847],
              [8.9509, 49.4865],
              [8.9565, 49.4939],
              [8.9514, 49.502],
              [8.9694, 49.5015],
              [8.9768, 49.5034],
              [8.9855, 49.5102],
              [8.9934, 49.5037],
              [9.0053, 49.5038],
              [9.0131, 49.4977],
              [9.031, 49.4977],
              [9.0418, 49.5001],
              [9.0467, 49.5083],
              [9.0508, 49.5132],
              [9.0521, 49.52],
              [9.0629, 49.5289],
              [9.0819, 49.5252],
              [9.0999, 49.5259],
              [9.1066, 49.5157],
              [9.1068, 49.5096],
              [9.1205, 49.5123],
              [9.1262, 49.5214],
              [9.1118, 49.5354],
              [9.1051, 49.5358],
              [9.0951, 49.5417],
              [9.0875, 49.5423],
              [9.0886, 49.5506],
              [9.0728, 49.565],
              [9.0731, 49.5746],
              [9.079, 49.5745],
              [9.0837, 49.5614],
              [9.1006, 49.5604],
              [9.1034, 49.5776],
            ],
          ],
          [
            [
              [8.7049, 47.7118],
              [8.7112, 47.7014],
              [8.714, 47.6897],
              [8.7052, 47.69],
              [8.6915, 47.6959],
              [8.6807, 47.6882],
              [8.6642, 47.6857],
              [8.6591, 47.6934],
              [8.6744, 47.6969],
              [8.6749, 47.7043],
              [8.6657, 47.706],
              [8.6655, 47.714],
              [8.6775, 47.7127],
              [8.6835, 47.7091],
              [8.7013, 47.7144],
              [8.7049, 47.7118],
            ],
          ],
        ],
      },
      properties: {name: 'Baden-Württemberg', id: 'DE-BW', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-BW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.042, 50.5163],
            [10.0521, 50.5192],
            [10.0684, 50.5298],
            [10.074, 50.5388],
            [10.0829, 50.5415],
            [10.0988, 50.552],
            [10.1007, 50.5581],
            [10.1088, 50.5637],
            [10.1204, 50.5639],
            [10.1218, 50.5558],
            [10.1371, 50.544],
            [10.1472, 50.5432],
            [10.1542, 50.5494],
            [10.1612, 50.5489],
            [10.1758, 50.5427],
            [10.1772, 50.5495],
            [10.1875, 50.5537],
            [10.2041, 50.5541],
            [10.2037, 50.5456],
            [10.2089, 50.5403],
            [10.2218, 50.5383],
            [10.2252, 50.5296],
            [10.231, 50.5245],
            [10.227, 50.5209],
            [10.2313, 50.5117],
            [10.2564, 50.513],
            [10.2671, 50.5041],
            [10.2761, 50.5053],
            [10.2915, 50.4968],
            [10.3074, 50.4924],
            [10.3307, 50.4939],
            [10.3348, 50.4863],
            [10.3419, 50.4828],
            [10.3377, 50.4729],
            [10.3377, 50.4654],
            [10.3489, 50.4625],
            [10.3581, 50.455],
            [10.3708, 50.4353],
            [10.3852, 50.4284],
            [10.4031, 50.4257],
            [10.4021, 50.4198],
            [10.3955, 50.4172],
            [10.3849, 50.4031],
            [10.389, 50.3988],
            [10.4002, 50.3958],
            [10.4237, 50.3924],
            [10.438, 50.3949],
            [10.4384, 50.401],
            [10.4537, 50.4012],
            [10.4538, 50.3933],
            [10.4596, 50.3817],
            [10.4737, 50.3736],
            [10.488, 50.3751],
            [10.4963, 50.3658],
            [10.4913, 50.3623],
            [10.4918, 50.3553],
            [10.5129, 50.3589],
            [10.5131, 50.3509],
            [10.5236, 50.3551],
            [10.5326, 50.3657],
            [10.5447, 50.364],
            [10.554, 50.3565],
            [10.5523, 50.3462],
            [10.5618, 50.3379],
            [10.5818, 50.3339],
            [10.6, 50.3337],
            [10.5924, 50.3171],
            [10.5928, 50.3069],
            [10.5971, 50.3064],
            [10.6044, 50.2928],
            [10.5968, 50.2886],
            [10.6, 50.2822],
            [10.5929, 50.2781],
            [10.5915, 50.2715],
            [10.5995, 50.2666],
            [10.6099, 50.2481],
            [10.6027, 50.2419],
            [10.6078, 50.2304],
            [10.6135, 50.2244],
            [10.6378, 50.2235],
            [10.6579, 50.2303],
            [10.6626, 50.2217],
            [10.685, 50.2244],
            [10.6868, 50.2192],
            [10.6951, 50.2122],
            [10.71, 50.21],
            [10.7223, 50.2053],
            [10.731, 50.2129],
            [10.727, 50.2315],
            [10.7267, 50.2408],
            [10.7319, 50.2515],
            [10.7474, 50.2484],
            [10.7469, 50.2441],
            [10.7696, 50.2417],
            [10.7835, 50.2481],
            [10.8016, 50.2519],
            [10.8075, 50.2409],
            [10.8229, 50.2379],
            [10.8355, 50.2392],
            [10.8469, 50.2446],
            [10.8508, 50.2514],
            [10.8504, 50.2648],
            [10.8463, 50.2746],
            [10.8333, 50.2736],
            [10.8271, 50.2781],
            [10.8194, 50.2772],
            [10.8047, 50.2803],
            [10.8036, 50.2916],
            [10.7944, 50.2951],
            [10.7712, 50.2932],
            [10.7603, 50.3032],
            [10.7424, 50.3156],
            [10.7215, 50.3185],
            [10.7149, 50.3248],
            [10.7194, 50.3327],
            [10.7283, 50.3376],
            [10.7281, 50.3487],
            [10.7207, 50.349],
            [10.7161, 50.3621],
            [10.7197, 50.3646],
            [10.7351, 50.3638],
            [10.749, 50.3582],
            [10.7616, 50.363],
            [10.7655, 50.3715],
            [10.775, 50.372],
            [10.7833, 50.382],
            [10.8057, 50.3794],
            [10.8326, 50.3924],
            [10.8627, 50.3879],
            [10.8727, 50.3963],
            [10.8857, 50.3922],
            [10.8918, 50.3804],
            [10.8988, 50.3845],
            [10.9362, 50.3923],
            [10.9552, 50.3782],
            [10.9614, 50.3672],
            [10.9798, 50.3545],
            [10.9851, 50.3478],
            [10.998, 50.3494],
            [10.9925, 50.3611],
            [10.9939, 50.3653],
            [11.0089, 50.3647],
            [11.0152, 50.3612],
            [11.0372, 50.36],
            [11.0334, 50.3551],
            [11.037, 50.3456],
            [11.0444, 50.344],
            [11.0522, 50.3478],
            [11.0646, 50.3495],
            [11.0732, 50.3536],
            [11.0814, 50.3517],
            [11.0891, 50.3653],
            [11.1154, 50.3666],
            [11.1197, 50.3594],
            [11.138, 50.3531],
            [11.1371, 50.3492],
            [11.158, 50.3296],
            [11.1577, 50.3242],
            [11.1419, 50.3183],
            [11.1348, 50.3136],
            [11.1335, 50.2999],
            [11.1462, 50.2857],
            [11.1554, 50.2884],
            [11.1655, 50.2827],
            [11.1721, 50.2836],
            [11.1826, 50.28],
            [11.1759, 50.2699],
            [11.1867, 50.2692],
            [11.1889, 50.2799],
            [11.1952, 50.2931],
            [11.2196, 50.2804],
            [11.2339, 50.2751],
            [11.2453, 50.2685],
            [11.2515, 50.2686],
            [11.2591, 50.2742],
            [11.2578, 50.283],
            [11.2607, 50.2889],
            [11.2516, 50.293],
            [11.2693, 50.3063],
            [11.257, 50.3224],
            [11.2607, 50.3248],
            [11.2614, 50.3371],
            [11.2763, 50.3363],
            [11.2663, 50.351],
            [11.2782, 50.3552],
            [11.2845, 50.3604],
            [11.2801, 50.3665],
            [11.2772, 50.3789],
            [11.2726, 50.3847],
            [11.2635, 50.3872],
            [11.2705, 50.3938],
            [11.258, 50.4004],
            [11.268, 50.4191],
            [11.267, 50.4308],
            [11.2611, 50.4445],
            [11.2521, 50.4468],
            [11.249, 50.453],
            [11.2544, 50.4561],
            [11.2529, 50.4634],
            [11.2451, 50.4707],
            [11.2491, 50.4806],
            [11.2679, 50.4802],
            [11.2898, 50.4851],
            [11.2976, 50.4816],
            [11.3021, 50.4897],
            [11.3208, 50.4901],
            [11.3249, 50.4978],
            [11.3216, 50.5088],
            [11.3472, 50.511],
            [11.3443, 50.5184],
            [11.3725, 50.5225],
            [11.3872, 50.5153],
            [11.4266, 50.5143],
            [11.4353, 50.5075],
            [11.441, 50.4996],
            [11.4375, 50.493],
            [11.4282, 50.492],
            [11.4194, 50.481],
            [11.4209, 50.4774],
            [11.4164, 50.4678],
            [11.4241, 50.4593],
            [11.4188, 50.4525],
            [11.4205, 50.4446],
            [11.4255, 50.4411],
            [11.4417, 50.4383],
            [11.4597, 50.4274],
            [11.465, 50.4321],
            [11.4831, 50.4303],
            [11.4822, 50.4248],
            [11.489, 50.4159],
            [11.4796, 50.4068],
            [11.4794, 50.4008],
            [11.4875, 50.3972],
            [11.4976, 50.3997],
            [11.5079, 50.3962],
            [11.5209, 50.3959],
            [11.5206, 50.3883],
            [11.5256, 50.3754],
            [11.5319, 50.3803],
            [11.5494, 50.3857],
            [11.5604, 50.3854],
            [11.573, 50.4003],
            [11.5824, 50.3986],
            [11.5939, 50.403],
            [11.6055, 50.3988],
            [11.6217, 50.388],
            [11.6465, 50.3908],
            [11.6699, 50.3948],
            [11.6919, 50.3947],
            [11.7083, 50.4025],
            [11.7169, 50.3996],
            [11.7257, 50.4006],
            [11.7392, 50.4091],
            [11.7591, 50.4055],
            [11.7548, 50.4152],
            [11.7703, 50.4105],
            [11.776, 50.418],
            [11.7976, 50.4183],
            [11.8036, 50.4097],
            [11.8195, 50.3997],
            [11.8184, 50.3921],
            [11.8251, 50.3894],
            [11.8508, 50.4016],
            [11.8585, 50.3987],
            [11.8839, 50.4151],
            [11.9052, 50.4172],
            [11.909, 50.4213],
            [11.9236, 50.4233],
            [11.9352, 50.4229],
            [11.9444, 50.4165],
            [11.9458, 50.4102],
            [11.9537, 50.4078],
            [11.9488, 50.3962],
            [11.9616, 50.396],
            [11.9646, 50.3997],
            [11.9763, 50.3875],
            [11.9837, 50.3767],
            [11.9771, 50.362],
            [11.9851, 50.3596],
            [11.9757, 50.352],
            [11.9798, 50.3499],
            [11.995, 50.3543],
            [11.9959, 50.3474],
            [12.0157, 50.3466],
            [12.0217, 50.3402],
            [12.028, 50.3415],
            [12.0325, 50.3305],
            [12.0492, 50.3316],
            [12.0821, 50.3279],
            [12.0882, 50.333],
            [12.101, 50.318],
            [12.113, 50.314],
            [12.1254, 50.3064],
            [12.1203, 50.3014],
            [12.1211, 50.2934],
            [12.1402, 50.2773],
            [12.1248, 50.2687],
            [12.1155, 50.2679],
            [12.1078, 50.2634],
            [12.0977, 50.2619],
            [12.1016, 50.2551],
            [12.0917, 50.2494],
            [12.106, 50.247],
            [12.1094, 50.2375],
            [12.1272, 50.2329],
            [12.1467, 50.2322],
            [12.158, 50.2263],
            [12.1639, 50.2175],
            [12.1614, 50.2109],
            [12.1745, 50.2136],
            [12.1768, 50.2048],
            [12.1829, 50.205],
            [12.1963, 50.1973],
            [12.1891, 50.1943],
            [12.2034, 50.1859],
            [12.2088, 50.1732],
            [12.2158, 50.1685],
            [12.2114, 50.1589],
            [12.2038, 50.1548],
            [12.2033, 50.1456],
            [12.1938, 50.1395],
            [12.2004, 50.1119],
            [12.2102, 50.1029],
            [12.2288, 50.1024],
            [12.2337, 50.0939],
            [12.2456, 50.0915],
            [12.2658, 50.0787],
            [12.2751, 50.0763],
            [12.2618, 50.0664],
            [12.2619, 50.0581],
            [12.2717, 50.0612],
            [12.2794, 50.0552],
            [12.2999, 50.0583],
            [12.3176, 50.0527],
            [12.3171, 50.0456],
            [12.3281, 50.0324],
            [12.3359, 50.0391],
            [12.3471, 50.0357],
            [12.349, 50.0307],
            [12.3632, 50.0237],
            [12.3681, 50.0172],
            [12.3783, 50.019],
            [12.3795, 50.0141],
            [12.3969, 50.01],
            [12.4025, 50.0048],
            [12.4126, 50.0054],
            [12.4231, 50.0005],
            [12.4314, 50.0023],
            [12.4352, 49.9937],
            [12.4262, 49.9907],
            [12.4324, 49.9845],
            [12.4607, 49.995],
            [12.4699, 49.991],
            [12.4769, 49.9805],
            [12.4902, 49.9811],
            [12.4994, 49.9719],
            [12.4939, 49.9695],
            [12.4885, 49.9573],
            [12.4798, 49.9576],
            [12.4707, 49.9452],
            [12.4781, 49.9359],
            [12.4937, 49.9369],
            [12.4934, 49.9332],
            [12.5121, 49.9276],
            [12.5394, 49.9246],
            [12.5476, 49.9206],
            [12.5513, 49.908],
            [12.5482, 49.8979],
            [12.5403, 49.8915],
            [12.5215, 49.8813],
            [12.5185, 49.8685],
            [12.5098, 49.858],
            [12.4979, 49.8565],
            [12.4984, 49.8382],
            [12.4861, 49.8415],
            [12.4751, 49.8358],
            [12.4762, 49.8249],
            [12.4733, 49.8151],
            [12.4652, 49.8095],
            [12.4697, 49.8032],
            [12.4661, 49.7951],
            [12.474, 49.7916],
            [12.4728, 49.7866],
            [12.465, 49.7849],
            [12.4117, 49.765],
            [12.402, 49.7608],
            [12.4013, 49.7546],
            [12.4066, 49.7481],
            [12.4037, 49.7426],
            [12.4143, 49.7327],
            [12.4241, 49.7303],
            [12.4293, 49.7254],
            [12.4344, 49.7138],
            [12.442, 49.7036],
            [12.4579, 49.7017],
            [12.4824, 49.6947],
            [12.4844, 49.6884],
            [12.4972, 49.6856],
            [12.5157, 49.6875],
            [12.5217, 49.6865],
            [12.5231, 49.6764],
            [12.5291, 49.6668],
            [12.5221, 49.66],
            [12.5244, 49.652],
            [12.5188, 49.6434],
            [12.5285, 49.6393],
            [12.5231, 49.6344],
            [12.5286, 49.6178],
            [12.5462, 49.6212],
            [12.5591, 49.6203],
            [12.5578, 49.6107],
            [12.5636, 49.6079],
            [12.5637, 49.5973],
            [12.5768, 49.5852],
            [12.5714, 49.569],
            [12.5749, 49.5584],
            [12.5826, 49.5504],
            [12.5941, 49.5422],
            [12.5898, 49.5382],
            [12.6023, 49.5325],
            [12.6287, 49.527],
            [12.6363, 49.5327],
            [12.6441, 49.5238],
            [12.6444, 49.5127],
            [12.6395, 49.5003],
            [12.6445, 49.4951],
            [12.6435, 49.485],
            [12.6336, 49.4773],
            [12.6426, 49.4694],
            [12.6483, 49.4681],
            [12.6568, 49.4535],
            [12.6554, 49.4484],
            [12.6573, 49.4346],
            [12.6784, 49.4265],
            [12.7084, 49.4248],
            [12.7157, 49.414],
            [12.7327, 49.4101],
            [12.7573, 49.3949],
            [12.761, 49.3842],
            [12.7583, 49.3754],
            [12.7682, 49.3655],
            [12.782, 49.3596],
            [12.7787, 49.3548],
            [12.7803, 49.3471],
            [12.8006, 49.3417],
            [12.8157, 49.3407],
            [12.8419, 49.3415],
            [12.8481, 49.3447],
            [12.8534, 49.3381],
            [12.8602, 49.3385],
            [12.8797, 49.33],
            [12.889, 49.3302],
            [12.8815, 49.3433],
            [12.8817, 49.3504],
            [12.9044, 49.3488],
            [12.9207, 49.343],
            [12.9287, 49.3447],
            [12.9372, 49.342],
            [12.95, 49.3427],
            [12.9586, 49.3366],
            [12.9724, 49.3337],
            [12.9884, 49.3212],
            [13.0025, 49.3131],
            [13.0066, 49.3057],
            [13.0288, 49.3041],
            [13.0306, 49.2878],
            [13.026, 49.2779],
            [13.0336, 49.2645],
            [13.052, 49.2642],
            [13.058, 49.2601],
            [13.0566, 49.2553],
            [13.0705, 49.2468],
            [13.079, 49.2472],
            [13.0871, 49.2417],
            [13.0897, 49.2358],
            [13.0872, 49.2296],
            [13.0922, 49.2256],
            [13.1143, 49.218],
            [13.1105, 49.204],
            [13.1186, 49.1988],
            [13.1306, 49.1959],
            [13.1424, 49.1885],
            [13.1486, 49.1818],
            [13.1614, 49.1745],
            [13.1719, 49.1724],
            [13.1768, 49.1624],
            [13.171, 49.1443],
            [13.184, 49.1335],
            [13.2054, 49.122],
            [13.2377, 49.1135],
            [13.2514, 49.1166],
            [13.2788, 49.1203],
            [13.2893, 49.1184],
            [13.3123, 49.1045],
            [13.3365, 49.0937],
            [13.3466, 49.0827],
            [13.3582, 49.0736],
            [13.3714, 49.0665],
            [13.376, 49.058],
            [13.3845, 49.0569],
            [13.3966, 49.0504],
            [13.3926, 49.0413],
            [13.3986, 49.0377],
            [13.4051, 49.0223],
            [13.4016, 49.0125],
            [13.4094, 49.0059],
            [13.4024, 48.9939],
            [13.4027, 48.9871],
            [13.4249, 48.9763],
            [13.4403, 48.967],
            [13.459, 48.962],
            [13.4677, 48.9545],
            [13.4832, 48.9512],
            [13.4846, 48.9473],
            [13.4953, 48.9411],
            [13.5081, 48.9423],
            [13.506, 48.9598],
            [13.5078, 48.9687],
            [13.5158, 48.9686],
            [13.5259, 48.9727],
            [13.5371, 48.9732],
            [13.5436, 48.9678],
            [13.557, 48.9668],
            [13.5637, 48.9702],
            [13.5701, 48.966],
            [13.5807, 48.97],
            [13.5924, 48.9615],
            [13.5918, 48.9527],
            [13.6025, 48.9405],
            [13.6213, 48.9491],
            [13.6308, 48.9468],
            [13.6229, 48.9384],
            [13.6379, 48.9256],
            [13.642, 48.9164],
            [13.6554, 48.8937],
            [13.6682, 48.8911],
            [13.6715, 48.88],
            [13.689, 48.8777],
            [13.7012, 48.8805],
            [13.716, 48.8774],
            [13.7321, 48.8867],
            [13.7384, 48.8852],
            [13.7381, 48.8791],
            [13.75, 48.8658],
            [13.7499, 48.8583],
            [13.7553, 48.8542],
            [13.7639, 48.835],
            [13.7726, 48.83],
            [13.7835, 48.8317],
            [13.7882, 48.8251],
            [13.7987, 48.8186],
            [13.7957, 48.8147],
            [13.8145, 48.7976],
            [13.8084, 48.7791],
            [13.8137, 48.7737],
            [13.8337, 48.7703],
            [13.8376, 48.7636],
            [13.8245, 48.7567],
            [13.8154, 48.7356],
            [13.8153, 48.7295],
            [13.8032, 48.7228],
            [13.7953, 48.7124],
            [13.8023, 48.7076],
            [13.8105, 48.7076],
            [13.8117, 48.7011],
            [13.821, 48.6978],
            [13.8368, 48.699],
            [13.8334, 48.6915],
            [13.8165, 48.6947],
            [13.8122, 48.6909],
            [13.8115, 48.6832],
            [13.8182, 48.6731],
            [13.8195, 48.6605],
            [13.8154, 48.6542],
            [13.817, 48.646],
            [13.8225, 48.6421],
            [13.8238, 48.6341],
            [13.8229, 48.6139],
            [13.8058, 48.6047],
            [13.8068, 48.6001],
            [13.7998, 48.5957],
            [13.8056, 48.5838],
            [13.7991, 48.5734],
            [13.7908, 48.5723],
            [13.7686, 48.5541],
            [13.7603, 48.5633],
            [13.7543, 48.563],
            [13.7452, 48.5533],
            [13.7521, 48.5494],
            [13.7432, 48.5415],
            [13.7479, 48.532],
            [13.7346, 48.5251],
            [13.7329, 48.5159],
            [13.7259, 48.513],
            [13.7056, 48.5186],
            [13.6827, 48.5293],
            [13.6624, 48.5356],
            [13.654, 48.548],
            [13.6485, 48.5507],
            [13.6299, 48.5534],
            [13.6146, 48.5599],
            [13.6063, 48.5663],
            [13.5946, 48.5689],
            [13.5811, 48.5598],
            [13.5736, 48.5598],
            [13.5518, 48.5691],
            [13.536, 48.5808],
            [13.5243, 48.5874],
            [13.5125, 48.5903],
            [13.5011, 48.5816],
            [13.5063, 48.5752],
            [13.4861, 48.5618],
            [13.4748, 48.5619],
            [13.476, 48.5559],
            [13.4697, 48.554],
            [13.4572, 48.5579],
            [13.4505, 48.5639],
            [13.4388, 48.558],
            [13.4373, 48.553],
            [13.442, 48.5442],
            [13.4507, 48.5358],
            [13.4454, 48.5261],
            [13.4574, 48.5116],
            [13.4477, 48.4954],
            [13.4444, 48.4844],
            [13.4385, 48.4793],
            [13.4261, 48.4565],
            [13.4283, 48.4507],
            [13.4395, 48.436],
            [13.4352, 48.4243],
            [13.4235, 48.4096],
            [13.4162, 48.3821],
            [13.4106, 48.3743],
            [13.3732, 48.3553],
            [13.3645, 48.3501],
            [13.351, 48.3379],
            [13.3341, 48.3256],
            [13.3153, 48.316],
            [13.27, 48.2975],
            [13.2553, 48.2925],
            [13.1888, 48.295],
            [13.1679, 48.2913],
            [13.1322, 48.2785],
            [13.1249, 48.2771],
            [13.0923, 48.2778],
            [13.0764, 48.2752],
            [13.0536, 48.2648],
            [13.0212, 48.2575],
            [13.0105, 48.2502],
            [13.0066, 48.2435],
            [12.9975, 48.2355],
            [12.9627, 48.2122],
            [12.9521, 48.2076],
            [12.9244, 48.2066],
            [12.9069, 48.2042],
            [12.8744, 48.2033],
            [12.8659, 48.1985],
            [12.8626, 48.188],
            [12.8541, 48.1789],
            [12.8479, 48.1672],
            [12.8371, 48.1654],
            [12.8358, 48.159],
            [12.8271, 48.1522],
            [12.8127, 48.1512],
            [12.8018, 48.1474],
            [12.7986, 48.1396],
            [12.7884, 48.1246],
            [12.7826, 48.1229],
            [12.7729, 48.1277],
            [12.7637, 48.1288],
            [12.7582, 48.124],
            [12.7598, 48.1191],
            [12.7496, 48.1087],
            [12.7532, 48.0867],
            [12.7625, 48.0723],
            [12.7714, 48.0653],
            [12.8361, 48.0269],
            [12.8516, 48.015],
            [12.8562, 48.0094],
            [12.8644, 47.9865],
            [12.8701, 47.9662],
            [12.8789, 47.9605],
            [12.8915, 47.9577],
            [12.914, 47.9558],
            [12.9196, 47.9515],
            [12.9188, 47.9419],
            [12.9321, 47.9389],
            [12.9383, 47.9427],
            [12.9417, 47.9287],
            [12.9481, 47.9196],
            [12.9664, 47.9003],
            [12.9884, 47.8663],
            [13.0012, 47.8544],
            [13.0042, 47.8464],
            [12.9999, 47.8389],
            [12.989, 47.8325],
            [12.9632, 47.7987],
            [12.9468, 47.7864],
            [12.9398, 47.7859],
            [12.9391, 47.7709],
            [12.943, 47.7638],
            [12.9353, 47.755],
            [12.935, 47.7486],
            [12.9179, 47.743],
            [12.9082, 47.7231],
            [12.9161, 47.7167],
            [12.9325, 47.7104],
            [12.9438, 47.7124],
            [12.9555, 47.7076],
            [12.9606, 47.7095],
            [12.9719, 47.7074],
            [12.9898, 47.7083],
            [13.0015, 47.7135],
            [13.0079, 47.7239],
            [13.0172, 47.7231],
            [13.0277, 47.7179],
            [13.0455, 47.7127],
            [13.0481, 47.7085],
            [13.0627, 47.6961],
            [13.0739, 47.6919],
            [13.0808, 47.6866],
            [13.0772, 47.6696],
            [13.0805, 47.6622],
            [13.0894, 47.6539],
            [13.0891, 47.6489],
            [13.0966, 47.642],
            [13.0967, 47.6295],
            [13.081, 47.6159],
            [13.0735, 47.6131],
            [13.0697, 47.6049],
            [13.0627, 47.6016],
            [13.0707, 47.5868],
            [13.0461, 47.5839],
            [13.0431, 47.576],
            [13.0584, 47.5595],
            [13.0324, 47.5349],
            [13.0391, 47.5277],
            [13.0459, 47.526],
            [13.0486, 47.4995],
            [13.0467, 47.4915],
            [13.0267, 47.4865],
            [13.0218, 47.4779],
            [13.0144, 47.4705],
            [13.0031, 47.4633],
            [12.994, 47.4709],
            [12.9947, 47.4783],
            [12.974, 47.4849],
            [12.9723, 47.4737],
            [12.9557, 47.478],
            [12.937, 47.4868],
            [12.9092, 47.4969],
            [12.8873, 47.5121],
            [12.8855, 47.5189],
            [12.8776, 47.5232],
            [12.8586, 47.5269],
            [12.8514, 47.5328],
            [12.8504, 47.5427],
            [12.8439, 47.5469],
            [12.8281, 47.5441],
            [12.8125, 47.5471],
            [12.795, 47.5582],
            [12.794, 47.5641],
            [12.7828, 47.5716],
            [12.7812, 47.5812],
            [12.7871, 47.5875],
            [12.796, 47.5921],
            [12.7922, 47.5997],
            [12.8079, 47.6106],
            [12.8251, 47.6122],
            [12.8149, 47.6194],
            [12.802, 47.6251],
            [12.7929, 47.632],
            [12.7808, 47.6328],
            [12.765, 47.6438],
            [12.7596, 47.6509],
            [12.7794, 47.6605],
            [12.7727, 47.6658],
            [12.7575, 47.6643],
            [12.7519, 47.6714],
            [12.7374, 47.6785],
            [12.7292, 47.6801],
            [12.718, 47.6768],
            [12.7055, 47.6812],
            [12.6779, 47.6824],
            [12.6653, 47.6806],
            [12.6494, 47.67],
            [12.6391, 47.6703],
            [12.6308, 47.6754],
            [12.6192, 47.6716],
            [12.6053, 47.6724],
            [12.5959, 47.6644],
            [12.5865, 47.6525],
            [12.5784, 47.6381],
            [12.5727, 47.6317],
            [12.5376, 47.6356],
            [12.5142, 47.6259],
            [12.4979, 47.6246],
            [12.4889, 47.6372],
            [12.4653, 47.6489],
            [12.4517, 47.6665],
            [12.4383, 47.6749],
            [12.4419, 47.691],
            [12.4271, 47.6969],
            [12.4204, 47.6945],
            [12.4007, 47.692],
            [12.3668, 47.6835],
            [12.3575, 47.6898],
            [12.3353, 47.6962],
            [12.2966, 47.69],
            [12.2848, 47.6891],
            [12.2744, 47.6858],
            [12.259, 47.6775],
            [12.2431, 47.6887],
            [12.2405, 47.6935],
            [12.2462, 47.7104],
            [12.2558, 47.7204],
            [12.2637, 47.7323],
            [12.2553, 47.7424],
            [12.2474, 47.7407],
            [12.2279, 47.7203],
            [12.2136, 47.7114],
            [12.1959, 47.7075],
            [12.1827, 47.6996],
            [12.1625, 47.6995],
            [12.1654, 47.6854],
            [12.1721, 47.6755],
            [12.1814, 47.6681],
            [12.1851, 47.657],
            [12.1993, 47.6375],
            [12.2068, 47.6141],
            [12.2022, 47.6058],
            [12.1822, 47.604],
            [12.1773, 47.6063],
            [12.177, 47.6137],
            [12.1619, 47.6127],
            [12.1439, 47.6061],
            [12.1289, 47.6058],
            [12.1133, 47.6112],
            [12.0833, 47.609],
            [12.0602, 47.6185],
            [12.0503, 47.6148],
            [12.0345, 47.6171],
            [12.0292, 47.6118],
            [12.0192, 47.6102],
            [12.0173, 47.6166],
            [12.0087, 47.6249],
            [12.0008, 47.6214],
            [11.9833, 47.6195],
            [11.9738, 47.6153],
            [11.9641, 47.6167],
            [11.9362, 47.6128],
            [11.9138, 47.6137],
            [11.9047, 47.6098],
            [11.8636, 47.6012],
            [11.8525, 47.5973],
            [11.8478, 47.5827],
            [11.8311, 47.5813],
            [11.8274, 47.5857],
            [11.8166, 47.5844],
            [11.7916, 47.5877],
            [11.7828, 47.5911],
            [11.7454, 47.588],
            [11.7259, 47.5887],
            [11.6933, 47.5877],
            [11.6827, 47.5843],
            [11.6724, 47.5859],
            [11.6585, 47.5845],
            [11.6402, 47.594],
            [11.6341, 47.5927],
            [11.6358, 47.5813],
            [11.6225, 47.5836],
            [11.6149, 47.5795],
            [11.6063, 47.581],
            [11.6003, 47.566],
            [11.5912, 47.5595],
            [11.586, 47.5432],
            [11.5883, 47.5254],
            [11.5768, 47.52],
            [11.5703, 47.5138],
            [11.5521, 47.5129],
            [11.536, 47.5089],
            [11.5189, 47.511],
            [11.5119, 47.5062],
            [11.4903, 47.5066],
            [11.4833, 47.5091],
            [11.4515, 47.5078],
            [11.441, 47.516],
            [11.433, 47.5133],
            [11.4127, 47.4977],
            [11.4085, 47.491],
            [11.3876, 47.4784],
            [11.3837, 47.4724],
            [11.3909, 47.4676],
            [11.4073, 47.4678],
            [11.4124, 47.4562],
            [11.4212, 47.4456],
            [11.4019, 47.4478],
            [11.3884, 47.4468],
            [11.3811, 47.4489],
            [11.3674, 47.4476],
            [11.3402, 47.45],
            [11.3298, 47.4435],
            [11.3283, 47.438],
            [11.3203, 47.4367],
            [11.3154, 47.4319],
            [11.2997, 47.431],
            [11.2903, 47.4271],
            [11.2927, 47.418],
            [11.2889, 47.4075],
            [11.2831, 47.4023],
            [11.2713, 47.3975],
            [11.2499, 47.3985],
            [11.2295, 47.397],
            [11.2206, 47.398],
            [11.2381, 47.4188],
            [11.2523, 47.4279],
            [11.2469, 47.4328],
            [11.2186, 47.4311],
            [11.2024, 47.4331],
            [11.1976, 47.4285],
            [11.1771, 47.426],
            [11.1685, 47.4227],
            [11.1547, 47.421],
            [11.1343, 47.4126],
            [11.1268, 47.4121],
            [11.118, 47.3972],
            [11.0996, 47.3948],
            [11.0697, 47.3956],
            [11.0619, 47.3969],
            [11.046, 47.3941],
            [11.0364, 47.3962],
            [11.03, 47.3934],
            [11.0228, 47.3968],
            [11.0082, 47.3942],
            [10.9875, 47.3959],
            [10.9697, 47.3998],
            [10.9682, 47.4109],
            [10.9716, 47.4176],
            [10.9817, 47.4205],
            [10.9826, 47.43],
            [10.9489, 47.4571],
            [10.9279, 47.4708],
            [10.9342, 47.4805],
            [10.9133, 47.4841],
            [10.8971, 47.4825],
            [10.8811, 47.4781],
            [10.868, 47.482],
            [10.8686, 47.4994],
            [10.8885, 47.5076],
            [10.9164, 47.5128],
            [10.9133, 47.5192],
            [10.9041, 47.5173],
            [10.8911, 47.53],
            [10.8865, 47.5367],
            [10.8528, 47.5338],
            [10.8482, 47.5296],
            [10.8345, 47.5271],
            [10.827, 47.5281],
            [10.8118, 47.5255],
            [10.8122, 47.5196],
            [10.7933, 47.5202],
            [10.7774, 47.5139],
            [10.7647, 47.5187],
            [10.7631, 47.5282],
            [10.7585, 47.5339],
            [10.7393, 47.5386],
            [10.7191, 47.5378],
            [10.7105, 47.5427],
            [10.6949, 47.5443],
            [10.6902, 47.5542],
            [10.6758, 47.5585],
            [10.6346, 47.5588],
            [10.6266, 47.5608],
            [10.6162, 47.5669],
            [10.6095, 47.5653],
            [10.5987, 47.5684],
            [10.5915, 47.5611],
            [10.5782, 47.5543],
            [10.5813, 47.548],
            [10.574, 47.5346],
            [10.5669, 47.5338],
            [10.5559, 47.5372],
            [10.5358, 47.5366],
            [10.5239, 47.5347],
            [10.4893, 47.5396],
            [10.4724, 47.5508],
            [10.4604, 47.5523],
            [10.4559, 47.5567],
            [10.4725, 47.5703],
            [10.4708, 47.5789],
            [10.4792, 47.5813],
            [10.4726, 47.5867],
            [10.4533, 47.5827],
            [10.437, 47.5836],
            [10.4308, 47.5768],
            [10.4374, 47.5683],
            [10.4447, 47.5676],
            [10.4531, 47.5538],
            [10.4392, 47.5227],
            [10.4426, 47.5179],
            [10.4318, 47.503],
            [10.4438, 47.4845],
            [10.4537, 47.485],
            [10.4637, 47.4827],
            [10.4692, 47.4765],
            [10.4699, 47.4677],
            [10.4657, 47.464],
            [10.4667, 47.4528],
            [10.4731, 47.4433],
            [10.4749, 47.4309],
            [10.4596, 47.4276],
            [10.4559, 47.419],
            [10.4386, 47.4129],
            [10.437, 47.4055],
            [10.4301, 47.3911],
            [10.4349, 47.3844],
            [10.4195, 47.3847],
            [10.4141, 47.3785],
            [10.397, 47.3762],
            [10.3907, 47.3714],
            [10.3872, 47.3574],
            [10.3766, 47.3521],
            [10.3674, 47.3427],
            [10.3582, 47.338],
            [10.3568, 47.3332],
            [10.3408, 47.3146],
            [10.3307, 47.3054],
            [10.3149, 47.3065],
            [10.2802, 47.2886],
            [10.266, 47.2891],
            [10.2588, 47.2815],
            [10.2464, 47.2808],
            [10.2386, 47.2718],
            [10.2322, 47.2707],
            [10.2233, 47.2754],
            [10.2014, 47.2768],
            [10.1971, 47.2744],
            [10.1763, 47.2698],
            [10.1719, 47.28],
            [10.1796, 47.296],
            [10.1895, 47.2949],
            [10.2103, 47.3051],
            [10.2148, 47.3123],
            [10.2116, 47.3193],
            [10.201, 47.3254],
            [10.2064, 47.3317],
            [10.205, 47.3383],
            [10.2167, 47.3495],
            [10.2174, 47.3553],
            [10.2232, 47.3582],
            [10.2353, 47.3711],
            [10.2374, 47.3822],
            [10.2275, 47.388],
            [10.2133, 47.3797],
            [10.199, 47.3829],
            [10.1952, 47.3897],
            [10.1832, 47.3929],
            [10.1814, 47.3876],
            [10.1701, 47.3856],
            [10.1694, 47.3719],
            [10.1647, 47.3684],
            [10.1401, 47.3674],
            [10.1182, 47.3747],
            [10.0986, 47.3552],
            [10.0811, 47.394],
            [10.084, 47.3986],
            [10.0713, 47.4073],
            [10.0735, 47.4149],
            [10.0843, 47.4156],
            [10.105, 47.4283],
            [10.0945, 47.4373],
            [10.092, 47.4448],
            [10.0949, 47.4507],
            [10.0899, 47.4586],
            [10.0695, 47.4559],
            [10.0646, 47.4631],
            [10.0546, 47.4659],
            [10.0539, 47.4753],
            [10.0453, 47.484],
            [10.0374, 47.4887],
            [10.0113, 47.482],
            [10.0022, 47.4823],
            [9.9956, 47.4865],
            [9.9867, 47.4977],
            [9.995, 47.5029],
            [9.9807, 47.5105],
            [9.9648, 47.5215],
            [9.9656, 47.5407],
            [9.9504, 47.5346],
            [9.9398, 47.5375],
            [9.9339, 47.533],
            [9.9167, 47.5302],
            [9.9026, 47.5432],
            [9.8885, 47.5439],
            [9.8805, 47.5481],
            [9.8751, 47.5437],
            [9.8779, 47.5393],
            [9.8704, 47.5307],
            [9.8555, 47.5359],
            [9.8459, 47.5419],
            [9.8175, 47.5467],
            [9.8161, 47.5528],
            [9.8249, 47.5621],
            [9.821, 47.5705],
            [9.8261, 47.579],
            [9.8229, 47.5858],
            [9.8, 47.5952],
            [9.7956, 47.5933],
            [9.7788, 47.5934],
            [9.7556, 47.5782],
            [9.7561, 47.5721],
            [9.7491, 47.5704],
            [9.7459, 47.5555],
            [9.7415, 47.554],
            [9.7359, 47.5447],
            [9.735, 47.5335],
            [9.729, 47.5331],
            [9.599, 47.5479],
            [9.6071, 47.5747],
            [9.6117, 47.5819],
            [9.6201, 47.5836],
            [9.6219, 47.5894],
            [9.6328, 47.5935],
            [9.6469, 47.605],
            [9.6571, 47.6114],
            [9.6727, 47.6104],
            [9.6779, 47.6024],
            [9.691, 47.6093],
            [9.6993, 47.6023],
            [9.7063, 47.6017],
            [9.7175, 47.6075],
            [9.7267, 47.6067],
            [9.7307, 47.6123],
            [9.7452, 47.6166],
            [9.7521, 47.6119],
            [9.7644, 47.6182],
            [9.7782, 47.6308],
            [9.7934, 47.6345],
            [9.7993, 47.6442],
            [9.7988, 47.6495],
            [9.815, 47.649],
            [9.8284, 47.6575],
            [9.83, 47.6635],
            [9.8308, 47.668],
            [9.8374, 47.6701],
            [9.8378, 47.6767],
            [9.8489, 47.6754],
            [9.8565, 47.6771],
            [9.8755, 47.6747],
            [9.8796, 47.6687],
            [9.896, 47.6625],
            [9.9161, 47.6632],
            [9.9181, 47.6591],
            [9.9306, 47.6537],
            [9.9351, 47.6557],
            [9.9514, 47.6554],
            [9.969, 47.6531],
            [9.9779, 47.6612],
            [9.9767, 47.6649],
            [9.9874, 47.6742],
            [10.0014, 47.6819],
            [10.0213, 47.6849],
            [10.0299, 47.6839],
            [10.0304, 47.6749],
            [10.0397, 47.6765],
            [10.0481, 47.681],
            [10.0592, 47.6799],
            [10.0647, 47.6746],
            [10.0695, 47.6635],
            [10.0747, 47.6585],
            [10.0721, 47.6504],
            [10.0798, 47.6447],
            [10.0817, 47.6549],
            [10.0892, 47.6595],
            [10.0945, 47.668],
            [10.1071, 47.6675],
            [10.1303, 47.6777],
            [10.1333, 47.6885],
            [10.1305, 47.6919],
            [10.1393, 47.6981],
            [10.1347, 47.7058],
            [10.1208, 47.7123],
            [10.1157, 47.7197],
            [10.1191, 47.7348],
            [10.1135, 47.7417],
            [10.1172, 47.7507],
            [10.1161, 47.7614],
            [10.0957, 47.7664],
            [10.0822, 47.7721],
            [10.0804, 47.7803],
            [10.0661, 47.7826],
            [10.0665, 47.7884],
            [10.0743, 47.7903],
            [10.0886, 47.7983],
            [10.0899, 47.8035],
            [10.1005, 47.8011],
            [10.1182, 47.8016],
            [10.1109, 47.8064],
            [10.1193, 47.8125],
            [10.1342, 47.8134],
            [10.1311, 47.8223],
            [10.1252, 47.8232],
            [10.1093, 47.8393],
            [10.1018, 47.8437],
            [10.1047, 47.8638],
            [10.1008, 47.865],
            [10.0846, 47.8562],
            [10.0775, 47.8668],
            [10.0829, 47.8698],
            [10.0929, 47.8687],
            [10.1031, 47.8775],
            [10.1047, 47.8834],
            [10.101, 47.904],
            [10.1086, 47.9091],
            [10.1095, 47.9195],
            [10.1045, 47.9228],
            [10.1105, 47.9323],
            [10.1085, 47.9381],
            [10.092, 47.945],
            [10.0857, 47.9531],
            [10.0877, 47.9674],
            [10.1048, 47.9742],
            [10.1152, 47.9751],
            [10.1149, 47.9889],
            [10.1216, 47.9966],
            [10.1201, 48.0038],
            [10.1343, 48.0183],
            [10.134, 48.0254],
            [10.1271, 48.0324],
            [10.1297, 48.0381],
            [10.1413, 48.0466],
            [10.1395, 48.0521],
            [10.1443, 48.0587],
            [10.1411, 48.0654],
            [10.1443, 48.0741],
            [10.1376, 48.0741],
            [10.1344, 48.0817],
            [10.1402, 48.0901],
            [10.1377, 48.0946],
            [10.1359, 48.1122],
            [10.1252, 48.116],
            [10.1133, 48.1245],
            [10.1045, 48.1405],
            [10.1056, 48.1447],
            [10.0962, 48.1578],
            [10.0915, 48.1699],
            [10.0911, 48.1776],
            [10.0858, 48.1822],
            [10.0838, 48.1935],
            [10.0847, 48.2073],
            [10.0789, 48.2088],
            [10.0782, 48.2214],
            [10.0699, 48.2221],
            [10.0693, 48.2301],
            [10.064, 48.2338],
            [10.0625, 48.2444],
            [10.0683, 48.2524],
            [10.062, 48.2573],
            [10.0655, 48.2619],
            [10.062, 48.2717],
            [10.0649, 48.2818],
            [10.0462, 48.2915],
            [10.0436, 48.2995],
            [10.0299, 48.3131],
            [10.0249, 48.3226],
            [10.0153, 48.3282],
            [10.0102, 48.3358],
            [10.0106, 48.3434],
            [9.9978, 48.3473],
            [9.9956, 48.3632],
            [9.9826, 48.366],
            [9.975, 48.3638],
            [9.9684, 48.3711],
            [9.9705, 48.3783],
            [9.9839, 48.3859],
            [9.9861, 48.3916],
            [10.0098, 48.4009],
            [10.0166, 48.4069],
            [10.0178, 48.4178],
            [10.0327, 48.4262],
            [10.0416, 48.4292],
            [10.041, 48.4346],
            [10.0314, 48.4336],
            [10.0278, 48.4376],
            [10.0358, 48.4506],
            [10.0307, 48.4562],
            [10.037, 48.4591],
            [10.0474, 48.457],
            [10.0708, 48.4582],
            [10.0994, 48.47],
            [10.1059, 48.4776],
            [10.1169, 48.4754],
            [10.1174, 48.4681],
            [10.1251, 48.4638],
            [10.127, 48.457],
            [10.1349, 48.455],
            [10.1598, 48.4583],
            [10.1703, 48.458],
            [10.1774, 48.4634],
            [10.1736, 48.468],
            [10.1832, 48.4756],
            [10.2168, 48.4877],
            [10.235, 48.4881],
            [10.2408, 48.4918],
            [10.2374, 48.4995],
            [10.2412, 48.5057],
            [10.2545, 48.5186],
            [10.2638, 48.5198],
            [10.2741, 48.516],
            [10.2967, 48.5165],
            [10.3104, 48.523],
            [10.303, 48.5296],
            [10.3012, 48.5429],
            [10.3049, 48.5507],
            [10.3105, 48.5525],
            [10.3129, 48.5644],
            [10.3056, 48.5824],
            [10.2931, 48.5895],
            [10.2976, 48.6034],
            [10.3195, 48.6033],
            [10.3221, 48.6086],
            [10.2981, 48.6228],
            [10.295, 48.6327],
            [10.2802, 48.6398],
            [10.2678, 48.6479],
            [10.2546, 48.6667],
            [10.2631, 48.6721],
            [10.2574, 48.6804],
            [10.2729, 48.6858],
            [10.2685, 48.7027],
            [10.2826, 48.6995],
            [10.292, 48.6921],
            [10.3177, 48.6834],
            [10.3254, 48.6904],
            [10.3349, 48.6891],
            [10.3457, 48.6788],
            [10.3511, 48.6651],
            [10.352, 48.654],
            [10.3607, 48.6533],
            [10.3682, 48.6634],
            [10.3887, 48.6672],
            [10.3875, 48.6732],
            [10.3917, 48.6775],
            [10.3922, 48.6869],
            [10.4136, 48.698],
            [10.4258, 48.6946],
            [10.4306, 48.6988],
            [10.4377, 48.694],
            [10.4346, 48.6875],
            [10.4264, 48.6862],
            [10.4226, 48.6702],
            [10.4082, 48.6673],
            [10.4108, 48.6613],
            [10.4193, 48.6638],
            [10.4491, 48.6658],
            [10.4541, 48.6704],
            [10.4647, 48.6704],
            [10.4805, 48.6804],
            [10.4862, 48.6903],
            [10.4772, 48.7041],
            [10.4813, 48.7113],
            [10.4684, 48.7143],
            [10.4652, 48.7269],
            [10.4434, 48.7295],
            [10.4385, 48.7372],
            [10.4233, 48.7441],
            [10.4233, 48.7579],
            [10.417, 48.7651],
            [10.4155, 48.7716],
            [10.4263, 48.7722],
            [10.4296, 48.7931],
            [10.4203, 48.7958],
            [10.4221, 48.7999],
            [10.4348, 48.8011],
            [10.4473, 48.813],
            [10.45, 48.8246],
            [10.4469, 48.8326],
            [10.4309, 48.8377],
            [10.4277, 48.8431],
            [10.4526, 48.8516],
            [10.4535, 48.8585],
            [10.4499, 48.8632],
            [10.4522, 48.8737],
            [10.4468, 48.877],
            [10.4537, 48.8885],
            [10.4403, 48.8987],
            [10.4303, 48.9007],
            [10.423, 48.9054],
            [10.4316, 48.9082],
            [10.444, 48.9086],
            [10.4571, 48.9167],
            [10.4519, 48.9265],
            [10.4381, 48.9336],
            [10.4512, 48.9385],
            [10.4414, 48.9458],
            [10.43, 48.947],
            [10.4245, 48.9638],
            [10.4176, 48.9686],
            [10.4045, 48.9665],
            [10.4022, 48.9745],
            [10.4097, 48.9809],
            [10.4017, 48.9831],
            [10.3921, 48.9899],
            [10.3827, 48.992],
            [10.3809, 49.0006],
            [10.3678, 49.0038],
            [10.356, 49.0043],
            [10.3551, 49.0128],
            [10.347, 49.0172],
            [10.3482, 49.0279],
            [10.3358, 49.0277],
            [10.3335, 49.0315],
            [10.3215, 49.0263],
            [10.3085, 49.032],
            [10.3017, 49.0386],
            [10.2921, 49.0427],
            [10.2835, 49.037],
            [10.265, 49.0448],
            [10.257, 49.0387],
            [10.2499, 49.0421],
            [10.2517, 49.0482],
            [10.262, 49.0629],
            [10.2578, 49.0696],
            [10.245, 49.0739],
            [10.2358, 49.074],
            [10.2407, 49.0821],
            [10.2467, 49.0837],
            [10.2505, 49.0918],
            [10.2324, 49.0895],
            [10.2227, 49.0981],
            [10.2138, 49.0955],
            [10.2094, 49.1011],
            [10.2147, 49.1046],
            [10.2135, 49.111],
            [10.2302, 49.1133],
            [10.238, 49.1185],
            [10.2412, 49.1246],
            [10.2553, 49.1296],
            [10.2539, 49.1404],
            [10.248, 49.1489],
            [10.2376, 49.1494],
            [10.2248, 49.1608],
            [10.216, 49.1631],
            [10.1992, 49.155],
            [10.1987, 49.1613],
            [10.1927, 49.1677],
            [10.1802, 49.1721],
            [10.1798, 49.1827],
            [10.1702, 49.178],
            [10.1596, 49.1832],
            [10.1556, 49.1929],
            [10.1431, 49.195],
            [10.1301, 49.2031],
            [10.1264, 49.2114],
            [10.1392, 49.2099],
            [10.1385, 49.2165],
            [10.1308, 49.2186],
            [10.1254, 49.2292],
            [10.1233, 49.2447],
            [10.1293, 49.25],
            [10.1256, 49.2556],
            [10.1168, 49.2594],
            [10.1344, 49.2625],
            [10.1433, 49.2711],
            [10.1544, 49.2852],
            [10.1416, 49.2876],
            [10.1321, 49.3003],
            [10.1319, 49.3053],
            [10.1449, 49.3166],
            [10.1422, 49.3228],
            [10.1327, 49.3281],
            [10.1201, 49.3252],
            [10.1129, 49.3356],
            [10.1049, 49.3384],
            [10.1022, 49.3456],
            [10.1087, 49.3521],
            [10.1234, 49.3504],
            [10.1235, 49.3569],
            [10.1131, 49.3597],
            [10.1175, 49.3677],
            [10.1072, 49.379],
            [10.1116, 49.3843],
            [10.1232, 49.3876],
            [10.1443, 49.3846],
            [10.1608, 49.3901],
            [10.1522, 49.4016],
            [10.1452, 49.4191],
            [10.1339, 49.4317],
            [10.1337, 49.4377],
            [10.1171, 49.445],
            [10.1074, 49.4419],
            [10.0942, 49.4477],
            [10.0974, 49.4569],
            [10.1049, 49.4626],
            [10.1163, 49.4617],
            [10.1159, 49.4733],
            [10.1084, 49.4823],
            [10.1013, 49.4859],
            [10.1013, 49.4944],
            [10.1099, 49.5017],
            [10.1166, 49.5033],
            [10.1205, 49.511],
            [10.1091, 49.5113],
            [10.1066, 49.5145],
            [10.0842, 49.5121],
            [10.0781, 49.5203],
            [10.0813, 49.528],
            [10.092, 49.5317],
            [10.0838, 49.5436],
            [10.0617, 49.5457],
            [10.0621, 49.539],
            [10.0421, 49.5297],
            [10.0528, 49.5204],
            [10.0607, 49.519],
            [10.0522, 49.5109],
            [10.0375, 49.5049],
            [10.0289, 49.5037],
            [10.0224, 49.4891],
            [10.0127, 49.4834],
            [9.9987, 49.4852],
            [9.9779, 49.483],
            [9.9714, 49.485],
            [9.9584, 49.4831],
            [9.9534, 49.4798],
            [9.9267, 49.4844],
            [9.9143, 49.4915],
            [9.915, 49.4992],
            [9.9234, 49.5073],
            [9.9159, 49.5185],
            [9.9229, 49.5233],
            [9.9255, 49.5361],
            [9.9199, 49.5441],
            [9.9258, 49.549],
            [9.9193, 49.5624],
            [9.9132, 49.5604],
            [9.8975, 49.5619],
            [9.9008, 49.5709],
            [9.9145, 49.5825],
            [9.9011, 49.585],
            [9.8746, 49.5774],
            [9.873, 49.5714],
            [9.864, 49.5671],
            [9.8656, 49.555],
            [9.8604, 49.5524],
            [9.8585, 49.5451],
            [9.8499, 49.5449],
            [9.8393, 49.5496],
            [9.8191, 49.5542],
            [9.8179, 49.5577],
            [9.8365, 49.5683],
            [9.8476, 49.5772],
            [9.8462, 49.5848],
            [9.8409, 49.5867],
            [9.8417, 49.5944],
            [9.8585, 49.6029],
            [9.8592, 49.608],
            [9.8668, 49.6123],
            [9.8669, 49.6202],
            [9.8631, 49.6293],
            [9.8583, 49.6328],
            [9.8533, 49.6436],
            [9.8367, 49.6458],
            [9.8296, 49.6542],
            [9.8324, 49.6572],
            [9.8222, 49.6666],
            [9.823, 49.6729],
            [9.8308, 49.679],
            [9.8393, 49.6813],
            [9.8314, 49.6879],
            [9.833, 49.7028],
            [9.8233, 49.706],
            [9.8169, 49.7115],
            [9.7949, 49.7213],
            [9.7795, 49.7225],
            [9.7636, 49.7167],
            [9.7536, 49.7102],
            [9.7496, 49.6954],
            [9.737, 49.6881],
            [9.7278, 49.6881],
            [9.729, 49.6982],
            [9.7196, 49.7022],
            [9.7137, 49.7123],
            [9.7118, 49.727],
            [9.6949, 49.7194],
            [9.6783, 49.7166],
            [9.675, 49.6974],
            [9.6766, 49.6935],
            [9.6539, 49.6873],
            [9.6424, 49.6936],
            [9.6328, 49.6937],
            [9.6301, 49.7024],
            [9.6333, 49.7185],
            [9.6427, 49.7237],
            [9.6356, 49.732],
            [9.6399, 49.7429],
            [9.6456, 49.7467],
            [9.6388, 49.7541],
            [9.6438, 49.7573],
            [9.647, 49.77],
            [9.6463, 49.7775],
            [9.6507, 49.7797],
            [9.6499, 49.7916],
            [9.6199, 49.7835],
            [9.6079, 49.7831],
            [9.5774, 49.779],
            [9.5678, 49.7829],
            [9.5569, 49.7749],
            [9.5586, 49.7636],
            [9.5668, 49.7538],
            [9.566, 49.7454],
            [9.5544, 49.7483],
            [9.5524, 49.76],
            [9.5457, 49.7668],
            [9.5375, 49.7678],
            [9.5284, 49.7628],
            [9.5187, 49.7616],
            [9.5106, 49.7674],
            [9.5129, 49.782],
            [9.5024, 49.7888],
            [9.4795, 49.787],
            [9.4722, 49.7813],
            [9.4691, 49.7742],
            [9.4577, 49.7694],
            [9.4443, 49.7744],
            [9.4375, 49.785],
            [9.4295, 49.7893],
            [9.4193, 49.7878],
            [9.4044, 49.7764],
            [9.3918, 49.7698],
            [9.3784, 49.7774],
            [9.3717, 49.7789],
            [9.3627, 49.7725],
            [9.3432, 49.7681],
            [9.3315, 49.7695],
            [9.3153, 49.7682],
            [9.3132, 49.7604],
            [9.3235, 49.7461],
            [9.3165, 49.74],
            [9.3214, 49.7319],
            [9.3406, 49.7344],
            [9.3569, 49.7312],
            [9.3662, 49.7313],
            [9.372, 49.7261],
            [9.3504, 49.7213],
            [9.3535, 49.7081],
            [9.3584, 49.7041],
            [9.3765, 49.705],
            [9.3832, 49.7093],
            [9.3823, 49.7259],
            [9.3872, 49.7307],
            [9.4007, 49.7286],
            [9.402, 49.722],
            [9.4084, 49.721],
            [9.4196, 49.7114],
            [9.4176, 49.7022],
            [9.4226, 49.6957],
            [9.4218, 49.6901],
            [9.4005, 49.6724],
            [9.4046, 49.6662],
            [9.4201, 49.6588],
            [9.4171, 49.6534],
            [9.4065, 49.6452],
            [9.3825, 49.6443],
            [9.3721, 49.6512],
            [9.3672, 49.6572],
            [9.3538, 49.6505],
            [9.3452, 49.6441],
            [9.3339, 49.6447],
            [9.3134, 49.6531],
            [9.2999, 49.6488],
            [9.2908, 49.6437],
            [9.2857, 49.6361],
            [9.2781, 49.6376],
            [9.2703, 49.6334],
            [9.2755, 49.6241],
            [9.2843, 49.6144],
            [9.2684, 49.609],
            [9.263, 49.6052],
            [9.2598, 49.5938],
            [9.2503, 49.5938],
            [9.2487, 49.5847],
            [9.2392, 49.581],
            [9.1946, 49.5741],
            [9.1817, 49.5736],
            [9.1739, 49.5777],
            [9.166, 49.5757],
            [9.1588, 49.5794],
            [9.1459, 49.5787],
            [9.1388, 49.5758],
            [9.1218, 49.5734],
            [9.1094, 49.5791],
            [9.1034, 49.5776],
            [9.0919, 49.5887],
            [9.084, 49.6007],
            [9.0682, 49.602],
            [9.0668, 49.6137],
            [9.0691, 49.6227],
            [9.0771, 49.6265],
            [9.0865, 49.6356],
            [9.0924, 49.6353],
            [9.1012, 49.6404],
            [9.1003, 49.6526],
            [9.1035, 49.6596],
            [9.0895, 49.6702],
            [9.0887, 49.6855],
            [9.0904, 49.6935],
            [9.095, 49.6959],
            [9.1179, 49.6978],
            [9.1287, 49.7011],
            [9.1229, 49.7142],
            [9.1342, 49.716],
            [9.1378, 49.7254],
            [9.1482, 49.7411],
            [9.1447, 49.7488],
            [9.1279, 49.7555],
            [9.1077, 49.759],
            [9.1138, 49.7671],
            [9.1143, 49.7739],
            [9.1273, 49.7764],
            [9.1348, 49.7824],
            [9.1301, 49.7869],
            [9.128, 49.7962],
            [9.1088, 49.7978],
            [9.1044, 49.7943],
            [9.0906, 49.7915],
            [9.0863, 49.8063],
            [9.078, 49.8167],
            [9.0874, 49.826],
            [9.0821, 49.8382],
            [9.0901, 49.841],
            [9.0862, 49.8467],
            [9.0779, 49.8419],
            [9.0691, 49.8326],
            [9.0591, 49.8337],
            [9.0597, 49.8465],
            [9.0482, 49.842],
            [9.0364, 49.8463],
            [9.0432, 49.8497],
            [9.0433, 49.8554],
            [9.0339, 49.8604],
            [9.0333, 49.8667],
            [9.0495, 49.866],
            [9.0378, 49.8774],
            [9.0371, 49.8917],
            [9.0311, 49.9012],
            [9.0307, 49.9096],
            [9.0231, 49.9106],
            [9.031, 49.9239],
            [9.0247, 49.9368],
            [9.0252, 49.9451],
            [9.0197, 49.9471],
            [9.0265, 49.9553],
            [9.0237, 49.959],
            [9.0185, 49.978],
            [9.0198, 49.9856],
            [9.0312, 49.9892],
            [9.0438, 49.9886],
            [9.0468, 49.9964],
            [9.0383, 49.9998],
            [9.0348, 50.0061],
            [9.0399, 50.0094],
            [9.0337, 50.0162],
            [9.0301, 50.0258],
            [9.0323, 50.0357],
            [9.0238, 50.043],
            [9.0086, 50.0457],
            [8.9915, 50.0419],
            [8.9796, 50.044],
            [8.9779, 50.053],
            [8.9906, 50.0666],
            [8.9989, 50.0691],
            [8.998, 50.0917],
            [9.0125, 50.1069],
            [9.0194, 50.1113],
            [9.038, 50.1144],
            [9.0642, 50.1162],
            [9.0724, 50.1189],
            [9.0793, 50.1158],
            [9.0903, 50.1175],
            [9.0987, 50.1257],
            [9.1225, 50.1258],
            [9.1445, 50.114],
            [9.1541, 50.1171],
            [9.1539, 50.1057],
            [9.1441, 50.0968],
            [9.1519, 50.0916],
            [9.1692, 50.1097],
            [9.1748, 50.1183],
            [9.1914, 50.1267],
            [9.1862, 50.1379],
            [9.2098, 50.1429],
            [9.2215, 50.149],
            [9.2341, 50.1459],
            [9.2547, 50.1384],
            [9.2705, 50.142],
            [9.2898, 50.1402],
            [9.3072, 50.1335],
            [9.3155, 50.1347],
            [9.3239, 50.1399],
            [9.3338, 50.1383],
            [9.3413, 50.1316],
            [9.3734, 50.1294],
            [9.3829, 50.119],
            [9.3959, 50.0965],
            [9.4127, 50.0845],
            [9.4338, 50.0844],
            [9.4448, 50.0893],
            [9.4594, 50.0897],
            [9.4791, 50.0946],
            [9.4953, 50.0944],
            [9.5002, 50.0969],
            [9.5122, 50.0946],
            [9.5175, 50.1033],
            [9.5174, 50.115],
            [9.511, 50.1269],
            [9.5076, 50.1454],
            [9.5204, 50.1496],
            [9.5294, 50.1597],
            [9.5268, 50.1672],
            [9.5046, 50.1692],
            [9.498, 50.1765],
            [9.5, 50.1928],
            [9.5128, 50.1944],
            [9.5144, 50.2006],
            [9.5034, 50.212],
            [9.5029, 50.2211],
            [9.4949, 50.2263],
            [9.5034, 50.2422],
            [9.5115, 50.2354],
            [9.5204, 50.2333],
            [9.5309, 50.2343],
            [9.5448, 50.225],
            [9.5503, 50.2282],
            [9.576, 50.2324],
            [9.5807, 50.2235],
            [9.5945, 50.2247],
            [9.6051, 50.2216],
            [9.6226, 50.2231],
            [9.6216, 50.2285],
            [9.6369, 50.235],
            [9.6551, 50.2321],
            [9.6642, 50.2333],
            [9.6652, 50.2408],
            [9.647, 50.2471],
            [9.6377, 50.2523],
            [9.6438, 50.2557],
            [9.6549, 50.2714],
            [9.6651, 50.2713],
            [9.6816, 50.2785],
            [9.6897, 50.2779],
            [9.7014, 50.2809],
            [9.7125, 50.2919],
            [9.7278, 50.2955],
            [9.7378, 50.3022],
            [9.7479, 50.3129],
            [9.7437, 50.3202],
            [9.7477, 50.3255],
            [9.744, 50.3298],
            [9.7466, 50.3414],
            [9.7411, 50.3483],
            [9.7339, 50.3518],
            [9.7343, 50.3576],
            [9.7449, 50.3648],
            [9.7446, 50.372],
            [9.7513, 50.3805],
            [9.7569, 50.399],
            [9.7612, 50.4033],
            [9.7526, 50.4145],
            [9.7626, 50.4255],
            [9.7744, 50.4245],
            [9.7808, 50.4188],
            [9.7897, 50.426],
            [9.802, 50.4252],
            [9.811, 50.4196],
            [9.8085, 50.4133],
            [9.8219, 50.4084],
            [9.8388, 50.4055],
            [9.8482, 50.4],
            [9.8611, 50.3974],
            [9.8708, 50.4102],
            [9.8786, 50.4011],
            [9.8875, 50.4001],
            [9.9016, 50.4039],
            [9.9201, 50.411],
            [9.9281, 50.4162],
            [9.9461, 50.4239],
            [9.9545, 50.4227],
            [9.9741, 50.4391],
            [9.9885, 50.4455],
            [9.9987, 50.462],
            [10.0186, 50.4669],
            [10.0236, 50.4762],
            [10.0338, 50.4805],
            [10.0324, 50.4864],
            [10.0408, 50.4943],
            [10.0372, 50.4985],
            [10.042, 50.5163],
          ],
        ],
      },
      properties: {name: 'Bavaria', id: 'DE-BY', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-BY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [8.521, 53.6057],
              [8.5392, 53.6071],
              [8.5811, 53.598],
              [8.5931, 53.593],
              [8.6229, 53.606],
              [8.6412, 53.6035],
              [8.6444, 53.6103],
              [8.6606, 53.6098],
              [8.6582, 53.6027],
              [8.6517, 53.6021],
              [8.6398, 53.5957],
              [8.6394, 53.591],
              [8.6222, 53.5832],
              [8.6241, 53.5701],
              [8.6289, 53.557],
              [8.6352, 53.5537],
              [8.6457, 53.5551],
              [8.6439, 53.5393],
              [8.6392, 53.5331],
              [8.6397, 53.526],
              [8.6495, 53.5193],
              [8.651, 53.5145],
              [8.6317, 53.5005],
              [8.6331, 53.4955],
              [8.6099, 53.487],
              [8.5776, 53.4854],
              [8.5696, 53.4896],
              [8.5738, 53.4978],
              [8.5479, 53.5201],
              [8.5562, 53.5234],
              [8.5697, 53.5223],
              [8.5754, 53.538],
              [8.5619, 53.5519],
              [8.5606, 53.5589],
              [8.5674, 53.5651],
              [8.5621, 53.5677],
              [8.5479, 53.565],
              [8.5324, 53.5828],
              [8.5318, 53.5868],
              [8.521, 53.6057],
            ],
          ],
          [
            [
              [8.8616, 53.1344],
              [8.8634, 53.1313],
              [8.8747, 53.1334],
              [8.898, 53.1336],
              [8.905, 53.1374],
              [8.9136, 53.1323],
              [8.9217, 53.1441],
              [8.9445, 53.152],
              [8.9557, 53.148],
              [8.9745, 53.131],
              [8.9831, 53.1262],
              [8.978, 53.1223],
              [8.964, 53.1213],
              [8.9479, 53.1159],
              [8.958, 53.1114],
              [8.9629, 53.0994],
              [8.9732, 53.0999],
              [8.9825, 53.0921],
              [8.9645, 53.0891],
              [8.9647, 53.0594],
              [8.9712, 53.0561],
              [8.9807, 53.0458],
              [8.97, 53.0382],
              [8.9624, 53.0371],
              [8.9502, 53.0307],
              [8.9478, 53.0246],
              [8.937, 53.0238],
              [8.9347, 53.0135],
              [8.921, 53.0149],
              [8.916, 53.0108],
              [8.8966, 53.0129],
              [8.8835, 53.0236],
              [8.8692, 53.0313],
              [8.8648, 53.0213],
              [8.8479, 53.0188],
              [8.8306, 53.0215],
              [8.8216, 53.0199],
              [8.8103, 53.0308],
              [8.796, 53.0355],
              [8.7771, 53.039],
              [8.772, 53.044],
              [8.7716, 53.053],
              [8.7596, 53.0489],
              [8.7622, 53.0452],
              [8.7508, 53.0379],
              [8.7426, 53.0405],
              [8.7178, 53.0391],
              [8.7093, 53.045],
              [8.7049, 53.0543],
              [8.7115, 53.0661],
              [8.71, 53.0757],
              [8.704, 53.08],
              [8.6891, 53.0841],
              [8.674, 53.0849],
              [8.6626, 53.0946],
              [8.6691, 53.1027],
              [8.6524, 53.1097],
              [8.6489, 53.1253],
              [8.6299, 53.1383],
              [8.6257, 53.1464],
              [8.6237, 53.1616],
              [8.6162, 53.1684],
              [8.6089, 53.1705],
              [8.5789, 53.174],
              [8.529, 53.1897],
              [8.515, 53.1976],
              [8.5102, 53.1974],
              [8.506, 53.2006],
              [8.4925, 53.2124],
              [8.4815, 53.2263],
              [8.5177, 53.2277],
              [8.53, 53.2159],
              [8.5419, 53.215],
              [8.5538, 53.2084],
              [8.5655, 53.2128],
              [8.5714, 53.2106],
              [8.5782, 53.2167],
              [8.5988, 53.2117],
              [8.5934, 53.2055],
              [8.5819, 53.2002],
              [8.5799, 53.1907],
              [8.6012, 53.1877],
              [8.6106, 53.1908],
              [8.6128, 53.1751],
              [8.6233, 53.1719],
              [8.6559, 53.1721],
              [8.6734, 53.1786],
              [8.6841, 53.1789],
              [8.6962, 53.1822],
              [8.7054, 53.1768],
              [8.7126, 53.1832],
              [8.7283, 53.1801],
              [8.7307, 53.1738],
              [8.743, 53.1713],
              [8.7403, 53.1664],
              [8.7555, 53.1634],
              [8.7621, 53.1647],
              [8.7715, 53.1594],
              [8.7836, 53.1634],
              [8.8185, 53.1618],
              [8.8226, 53.1634],
              [8.8405, 53.1564],
              [8.8426, 53.1484],
              [8.8532, 53.1431],
              [8.8616, 53.1344],
            ],
          ],
        ],
      },
      properties: {name: 'Bremen', id: 'DE-HB', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-HB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.4513, 51.6464],
            [9.4619, 51.6531],
            [9.489, 51.6568],
            [9.4986, 51.6509],
            [9.4918, 51.6357],
            [9.4979, 51.6302],
            [9.5098, 51.6264],
            [9.5223, 51.6258],
            [9.5467, 51.6398],
            [9.5557, 51.6358],
            [9.565, 51.6254],
            [9.5849, 51.6253],
            [9.6112, 51.6295],
            [9.624, 51.6365],
            [9.6264, 51.6302],
            [9.6363, 51.6201],
            [9.6363, 51.6114],
            [9.64, 51.6071],
            [9.6497, 51.6051],
            [9.66, 51.5934],
            [9.6798, 51.5869],
            [9.6897, 51.577],
            [9.6843, 51.5674],
            [9.6718, 51.5686],
            [9.6573, 51.5744],
            [9.6496, 51.5797],
            [9.6326, 51.5846],
            [9.6262, 51.5813],
            [9.6247, 51.5727],
            [9.6341, 51.5592],
            [9.6429, 51.5596],
            [9.6465, 51.5537],
            [9.6229, 51.5485],
            [9.6208, 51.5332],
            [9.6149, 51.5259],
            [9.6058, 51.5213],
            [9.5945, 51.5233],
            [9.5856, 51.5168],
            [9.5962, 51.509],
            [9.6108, 51.4895],
            [9.6266, 51.476],
            [9.6386, 51.4735],
            [9.6435, 51.4684],
            [9.6299, 51.459],
            [9.6274, 51.444],
            [9.6321, 51.4295],
            [9.6439, 51.4183],
            [9.6295, 51.4111],
            [9.6286, 51.4053],
            [9.6156, 51.4066],
            [9.6064, 51.4047],
            [9.5978, 51.3994],
            [9.5875, 51.4025],
            [9.578, 51.4014],
            [9.574, 51.3929],
            [9.5636, 51.3869],
            [9.559, 51.3795],
            [9.5606, 51.3747],
            [9.5759, 51.3722],
            [9.5704, 51.3633],
            [9.5531, 51.3609],
            [9.5371, 51.3687],
            [9.5321, 51.3637],
            [9.5493, 51.3589],
            [9.5591, 51.3471],
            [9.5698, 51.3406],
            [9.5784, 51.3403],
            [9.5943, 51.3351],
            [9.5977, 51.3299],
            [9.608, 51.325],
            [9.6181, 51.3275],
            [9.6299, 51.3264],
            [9.6289, 51.3128],
            [9.6342, 51.3118],
            [9.6439, 51.3178],
            [9.6562, 51.3125],
            [9.6661, 51.3123],
            [9.6764, 51.3155],
            [9.6793, 51.3102],
            [9.7245, 51.299],
            [9.7339, 51.2954],
            [9.7565, 51.3041],
            [9.7681, 51.3067],
            [9.7736, 51.3282],
            [9.7661, 51.3319],
            [9.7553, 51.3277],
            [9.7469, 51.3201],
            [9.7365, 51.3219],
            [9.736, 51.3293],
            [9.7126, 51.3556],
            [9.702, 51.3608],
            [9.7034, 51.3652],
            [9.7215, 51.3706],
            [9.7372, 51.369],
            [9.751, 51.3751],
            [9.7566, 51.3824],
            [9.7711, 51.3881],
            [9.7927, 51.3893],
            [9.8014, 51.3879],
            [9.7999, 51.4003],
            [9.7957, 51.408],
            [9.8187, 51.4118],
            [9.8292, 51.4051],
            [9.8391, 51.4024],
            [9.8401, 51.3836],
            [9.8522, 51.3727],
            [9.8618, 51.3713],
            [9.855, 51.3857],
            [9.8599, 51.3947],
            [9.8503, 51.4023],
            [9.8519, 51.4148],
            [9.8718, 51.4118],
            [9.8803, 51.4057],
            [9.8824, 51.4148],
            [9.8973, 51.4178],
            [9.9132, 51.4123],
            [9.9086, 51.409],
            [9.9148, 51.402],
            [9.9225, 51.4008],
            [9.9305, 51.3929],
            [9.931, 51.3805],
            [9.928, 51.3753],
            [9.9236, 51.3685],
            [9.9249, 51.3544],
            [9.9315, 51.3461],
            [9.9319, 51.3383],
            [9.9428, 51.3354],
            [9.9468, 51.3268],
            [9.9473, 51.3162],
            [9.9392, 51.3089],
            [9.9464, 51.3029],
            [9.9584, 51.3051],
            [9.9697, 51.2995],
            [9.9758, 51.2917],
            [9.9722, 51.2846],
            [9.9953, 51.2841],
            [10.0035, 51.2873],
            [10.0122, 51.2822],
            [10.0363, 51.2794],
            [10.0526, 51.2795],
            [10.0593, 51.2766],
            [10.0587, 51.2708],
            [10.0462, 51.2651],
            [10.0504, 51.2549],
            [10.0619, 51.2529],
            [10.0755, 51.2439],
            [10.071, 51.2391],
            [10.0673, 51.2277],
            [10.0795, 51.2243],
            [10.0857, 51.2269],
            [10.0965, 51.2205],
            [10.1046, 51.2222],
            [10.1128, 51.2156],
            [10.1257, 51.2192],
            [10.143, 51.217],
            [10.1468, 51.2088],
            [10.1593, 51.2079],
            [10.1636, 51.2122],
            [10.1757, 51.2091],
            [10.1969, 51.1968],
            [10.1957, 51.1926],
            [10.2118, 51.1901],
            [10.2216, 51.1854],
            [10.2369, 51.1858],
            [10.2297, 51.174],
            [10.2133, 51.1678],
            [10.2083, 51.163],
            [10.2068, 51.1538],
            [10.2015, 51.1484],
            [10.2114, 51.1425],
            [10.2046, 51.1357],
            [10.2044, 51.1307],
            [10.2115, 51.1258],
            [10.2102, 51.1183],
            [10.1988, 51.1129],
            [10.1902, 51.1167],
            [10.1895, 51.1217],
            [10.1778, 51.1276],
            [10.1841, 51.1327],
            [10.1743, 51.1468],
            [10.1654, 51.1514],
            [10.1471, 51.1447],
            [10.1284, 51.1437],
            [10.1195, 51.1393],
            [10.1312, 51.1354],
            [10.1405, 51.1264],
            [10.1465, 51.1255],
            [10.1544, 51.1181],
            [10.1725, 51.1189],
            [10.1758, 51.107],
            [10.1615, 51.1078],
            [10.166, 51.1003],
            [10.1453, 51.0847],
            [10.152, 51.0771],
            [10.1519, 51.0691],
            [10.1447, 51.066],
            [10.1471, 51.0547],
            [10.1585, 51.054],
            [10.1721, 51.0491],
            [10.1813, 51.0482],
            [10.1958, 51.0424],
            [10.203, 51.0372],
            [10.2034, 51.0315],
            [10.2153, 51.0261],
            [10.2089, 51.0191],
            [10.1957, 51.021],
            [10.2047, 51.0079],
            [10.1969, 51.0061],
            [10.1887, 50.998],
            [10.1647, 50.998],
            [10.1426, 50.9932],
            [10.1367, 50.9975],
            [10.126, 51.0002],
            [10.1286, 51.0068],
            [10.1133, 51.0109],
            [10.0999, 51.0062],
            [10.0731, 51.0111],
            [10.0396, 51.0132],
            [10.0318, 51.0098],
            [10.0251, 50.9972],
            [10.0212, 50.9947],
            [10.0235, 50.9856],
            [10.0198, 50.9816],
            [10.0255, 50.9759],
            [10.0411, 50.9676],
            [10.034, 50.9607],
            [10.0361, 50.9561],
            [10.0601, 50.9427],
            [10.0491, 50.9393],
            [10.0306, 50.9387],
            [10.027, 50.9436],
            [10.0103, 50.9347],
            [10.0032, 50.9401],
            [9.9919, 50.9427],
            [9.9832, 50.9394],
            [9.9617, 50.9435],
            [9.9552, 50.9381],
            [9.9547, 50.9292],
            [9.9849, 50.933],
            [9.9827, 50.9209],
            [9.9723, 50.9162],
            [9.9689, 50.9115],
            [9.9785, 50.9057],
            [9.9986, 50.9198],
            [10.0113, 50.9219],
            [10.035, 50.9092],
            [10.0412, 50.9018],
            [10.0513, 50.8988],
            [10.0425, 50.8932],
            [10.0572, 50.8875],
            [10.0566, 50.8799],
            [10.0248, 50.8676],
            [10.019, 50.8587],
            [10.0218, 50.8485],
            [10.0351, 50.8534],
            [10.0376, 50.8453],
            [10.0282, 50.8409],
            [10.0229, 50.8321],
            [10.0065, 50.8372],
            [10.0016, 50.8284],
            [9.9957, 50.826],
            [9.9817, 50.8304],
            [9.9752, 50.8319],
            [9.9666, 50.8264],
            [9.953, 50.8212],
            [9.9505, 50.8152],
            [9.9565, 50.809],
            [9.9438, 50.7996],
            [9.9439, 50.7945],
            [9.9539, 50.7839],
            [9.9536, 50.7738],
            [9.929, 50.7764],
            [9.928, 50.7689],
            [9.9219, 50.7591],
            [9.9387, 50.7557],
            [9.9403, 50.739],
            [9.9361, 50.7336],
            [9.9344, 50.7236],
            [9.9131, 50.7107],
            [9.9094, 50.7014],
            [9.9202, 50.6971],
            [9.9114, 50.6878],
            [9.9041, 50.6839],
            [9.8929, 50.6831],
            [9.8793, 50.6703],
            [9.8843, 50.6541],
            [9.8765, 50.6405],
            [9.8786, 50.6349],
            [9.9095, 50.6368],
            [9.9217, 50.634],
            [9.9275, 50.6296],
            [9.946, 50.6272],
            [9.9605, 50.6298],
            [9.9608, 50.6364],
            [9.9678, 50.6415],
            [9.949, 50.6568],
            [9.9489, 50.6635],
            [9.9538, 50.6674],
            [9.9767, 50.6684],
            [9.989, 50.6744],
            [10.0006, 50.6765],
            [10.0216, 50.673],
            [10.0319, 50.6752],
            [10.0484, 50.6738],
            [10.0542, 50.6712],
            [10.0636, 50.6593],
            [10.0703, 50.6561],
            [10.0721, 50.6476],
            [10.0787, 50.6345],
            [10.0828, 50.6328],
            [10.0821, 50.6192],
            [10.0686, 50.6211],
            [10.0598, 50.6261],
            [10.0495, 50.6236],
            [10.0374, 50.6108],
            [10.0414, 50.6034],
            [10.049, 50.5986],
            [10.0498, 50.5843],
            [10.046, 50.5793],
            [10.0463, 50.5712],
            [10.0571, 50.554],
            [10.0477, 50.5454],
            [10.0486, 50.5401],
            [10.0389, 50.5294],
            [10.042, 50.5163],
            [10.0372, 50.4985],
            [10.0408, 50.4943],
            [10.0324, 50.4864],
            [10.0338, 50.4805],
            [10.0236, 50.4762],
            [10.0186, 50.4669],
            [9.9987, 50.462],
            [9.9885, 50.4455],
            [9.9741, 50.4391],
            [9.9545, 50.4227],
            [9.9461, 50.4239],
            [9.9281, 50.4162],
            [9.9201, 50.411],
            [9.9016, 50.4039],
            [9.8875, 50.4001],
            [9.8786, 50.4011],
            [9.8708, 50.4102],
            [9.8611, 50.3974],
            [9.8482, 50.4],
            [9.8388, 50.4055],
            [9.8219, 50.4084],
            [9.8085, 50.4133],
            [9.811, 50.4196],
            [9.802, 50.4252],
            [9.7897, 50.426],
            [9.7808, 50.4188],
            [9.7744, 50.4245],
            [9.7626, 50.4255],
            [9.7526, 50.4145],
            [9.7612, 50.4033],
            [9.7569, 50.399],
            [9.7513, 50.3805],
            [9.7446, 50.372],
            [9.7449, 50.3648],
            [9.7343, 50.3576],
            [9.7339, 50.3518],
            [9.7411, 50.3483],
            [9.7466, 50.3414],
            [9.744, 50.3298],
            [9.7477, 50.3255],
            [9.7437, 50.3202],
            [9.7479, 50.3129],
            [9.7378, 50.3022],
            [9.7278, 50.2955],
            [9.7125, 50.2919],
            [9.7014, 50.2809],
            [9.6897, 50.2779],
            [9.6816, 50.2785],
            [9.6651, 50.2713],
            [9.6549, 50.2714],
            [9.6438, 50.2557],
            [9.6377, 50.2523],
            [9.647, 50.2471],
            [9.6652, 50.2408],
            [9.6642, 50.2333],
            [9.6551, 50.2321],
            [9.6369, 50.235],
            [9.6216, 50.2285],
            [9.6226, 50.2231],
            [9.6051, 50.2216],
            [9.5945, 50.2247],
            [9.5807, 50.2235],
            [9.576, 50.2324],
            [9.5503, 50.2282],
            [9.5448, 50.225],
            [9.5309, 50.2343],
            [9.5204, 50.2333],
            [9.5115, 50.2354],
            [9.5034, 50.2422],
            [9.4949, 50.2263],
            [9.5029, 50.2211],
            [9.5034, 50.212],
            [9.5144, 50.2006],
            [9.5128, 50.1944],
            [9.5, 50.1928],
            [9.498, 50.1765],
            [9.5046, 50.1692],
            [9.5268, 50.1672],
            [9.5294, 50.1597],
            [9.5204, 50.1496],
            [9.5076, 50.1454],
            [9.511, 50.1269],
            [9.5174, 50.115],
            [9.5175, 50.1033],
            [9.5122, 50.0946],
            [9.5002, 50.0969],
            [9.4953, 50.0944],
            [9.4791, 50.0946],
            [9.4594, 50.0897],
            [9.4448, 50.0893],
            [9.4338, 50.0844],
            [9.4127, 50.0845],
            [9.3959, 50.0965],
            [9.3829, 50.119],
            [9.3734, 50.1294],
            [9.3413, 50.1316],
            [9.3338, 50.1383],
            [9.3239, 50.1399],
            [9.3155, 50.1347],
            [9.3072, 50.1335],
            [9.2898, 50.1402],
            [9.2705, 50.142],
            [9.2547, 50.1384],
            [9.2341, 50.1459],
            [9.2215, 50.149],
            [9.2098, 50.1429],
            [9.1862, 50.1379],
            [9.1914, 50.1267],
            [9.1748, 50.1183],
            [9.1692, 50.1097],
            [9.1519, 50.0916],
            [9.1441, 50.0968],
            [9.1539, 50.1057],
            [9.1541, 50.1171],
            [9.1445, 50.114],
            [9.1225, 50.1258],
            [9.0987, 50.1257],
            [9.0903, 50.1175],
            [9.0793, 50.1158],
            [9.0724, 50.1189],
            [9.0642, 50.1162],
            [9.038, 50.1144],
            [9.0194, 50.1113],
            [9.0125, 50.1069],
            [8.998, 50.0917],
            [8.9989, 50.0691],
            [8.9906, 50.0666],
            [8.9779, 50.053],
            [8.9796, 50.044],
            [8.9915, 50.0419],
            [9.0086, 50.0457],
            [9.0238, 50.043],
            [9.0323, 50.0357],
            [9.0301, 50.0258],
            [9.0337, 50.0162],
            [9.0399, 50.0094],
            [9.0348, 50.0061],
            [9.0383, 49.9998],
            [9.0468, 49.9964],
            [9.0438, 49.9886],
            [9.0312, 49.9892],
            [9.0198, 49.9856],
            [9.0185, 49.978],
            [9.0237, 49.959],
            [9.0265, 49.9553],
            [9.0197, 49.9471],
            [9.0252, 49.9451],
            [9.0247, 49.9368],
            [9.031, 49.9239],
            [9.0231, 49.9106],
            [9.0307, 49.9096],
            [9.0311, 49.9012],
            [9.0371, 49.8917],
            [9.0378, 49.8774],
            [9.0495, 49.866],
            [9.0333, 49.8667],
            [9.0339, 49.8604],
            [9.0433, 49.8554],
            [9.0432, 49.8497],
            [9.0364, 49.8463],
            [9.0482, 49.842],
            [9.0597, 49.8465],
            [9.0591, 49.8337],
            [9.0691, 49.8326],
            [9.0779, 49.8419],
            [9.0862, 49.8467],
            [9.0901, 49.841],
            [9.0821, 49.8382],
            [9.0874, 49.826],
            [9.078, 49.8167],
            [9.0863, 49.8063],
            [9.0906, 49.7915],
            [9.1044, 49.7943],
            [9.1088, 49.7978],
            [9.128, 49.7962],
            [9.1301, 49.7869],
            [9.1348, 49.7824],
            [9.1273, 49.7764],
            [9.1143, 49.7739],
            [9.1138, 49.7671],
            [9.1077, 49.759],
            [9.1279, 49.7555],
            [9.1447, 49.7488],
            [9.1482, 49.7411],
            [9.1378, 49.7254],
            [9.1342, 49.716],
            [9.1229, 49.7142],
            [9.1287, 49.7011],
            [9.1179, 49.6978],
            [9.095, 49.6959],
            [9.0904, 49.6935],
            [9.0887, 49.6855],
            [9.0895, 49.6702],
            [9.1035, 49.6596],
            [9.1003, 49.6526],
            [9.1012, 49.6404],
            [9.0924, 49.6353],
            [9.0865, 49.6356],
            [9.0771, 49.6265],
            [9.0691, 49.6227],
            [9.0668, 49.6137],
            [9.0682, 49.602],
            [9.084, 49.6007],
            [9.0919, 49.5887],
            [9.1034, 49.5776],
            [9.1006, 49.5604],
            [9.0837, 49.5614],
            [9.079, 49.5745],
            [9.0731, 49.5746],
            [9.0728, 49.565],
            [9.0886, 49.5506],
            [9.0875, 49.5423],
            [9.0951, 49.5417],
            [9.1051, 49.5358],
            [9.1118, 49.5354],
            [9.1262, 49.5214],
            [9.1205, 49.5123],
            [9.1068, 49.5096],
            [9.1066, 49.5157],
            [9.0999, 49.5259],
            [9.0819, 49.5252],
            [9.0629, 49.5289],
            [9.0521, 49.52],
            [9.0508, 49.5132],
            [9.0467, 49.5083],
            [9.0418, 49.5001],
            [9.031, 49.4977],
            [9.0131, 49.4977],
            [9.0053, 49.5038],
            [8.9934, 49.5037],
            [8.9855, 49.5102],
            [8.9768, 49.5034],
            [8.9694, 49.5015],
            [8.9514, 49.502],
            [8.9565, 49.4939],
            [8.9509, 49.4865],
            [8.9404, 49.4847],
            [8.9313, 49.47],
            [8.9501, 49.4556],
            [8.9424, 49.4514],
            [8.9295, 49.4505],
            [8.9251, 49.4418],
            [8.9131, 49.4377],
            [8.9009, 49.4427],
            [8.8941, 49.4214],
            [8.8872, 49.4168],
            [8.8744, 49.4131],
            [8.8605, 49.3998],
            [8.8525, 49.3955],
            [8.843, 49.3983],
            [8.8387, 49.4053],
            [8.8274, 49.406],
            [8.8238, 49.3974],
            [8.8152, 49.3968],
            [8.8044, 49.4039],
            [8.8088, 49.4111],
            [8.807, 49.4196],
            [8.8163, 49.4231],
            [8.8301, 49.4232],
            [8.838, 49.4338],
            [8.8339, 49.4381],
            [8.8348, 49.4503],
            [8.8255, 49.4638],
            [8.8281, 49.4709],
            [8.847, 49.4789],
            [8.8529, 49.4836],
            [8.8599, 49.4812],
            [8.8665, 49.4747],
            [8.8625, 49.4663],
            [8.8771, 49.467],
            [8.8902, 49.4756],
            [8.8916, 49.4799],
            [8.9005, 49.4848],
            [8.8991, 49.5029],
            [8.879, 49.5055],
            [8.8584, 49.5011],
            [8.847, 49.5011],
            [8.8391, 49.4939],
            [8.8323, 49.4919],
            [8.8177, 49.506],
            [8.8214, 49.5136],
            [8.8174, 49.5245],
            [8.8101, 49.5256],
            [8.7921, 49.5137],
            [8.7738, 49.5177],
            [8.7672, 49.5152],
            [8.7568, 49.516],
            [8.7508, 49.5217],
            [8.7253, 49.5256],
            [8.7176, 49.5325],
            [8.7111, 49.5314],
            [8.7019, 49.5358],
            [8.7051, 49.5489],
            [8.6931, 49.5534],
            [8.693, 49.562],
            [8.684, 49.5729],
            [8.6813, 49.5833],
            [8.6835, 49.5893],
            [8.6964, 49.5925],
            [8.6972, 49.5974],
            [8.6912, 49.6071],
            [8.6812, 49.6032],
            [8.6718, 49.6093],
            [8.6698, 49.6168],
            [8.6799, 49.6222],
            [8.6869, 49.6169],
            [8.6922, 49.6201],
            [8.6797, 49.6253],
            [8.6026, 49.6124],
            [8.5915, 49.6049],
            [8.5909, 49.5956],
            [8.6031, 49.5924],
            [8.6075, 49.5718],
            [8.6156, 49.5549],
            [8.6159, 49.5408],
            [8.6072, 49.5336],
            [8.5962, 49.5311],
            [8.591, 49.5231],
            [8.5729, 49.5194],
            [8.561, 49.5142],
            [8.5488, 49.5201],
            [8.5306, 49.5358],
            [8.5243, 49.5476],
            [8.4928, 49.5727],
            [8.4806, 49.5736],
            [8.4753, 49.5833],
            [8.4635, 49.5885],
            [8.4413, 49.5884],
            [8.438, 49.583],
            [8.4224, 49.5836],
            [8.4071, 49.6075],
            [8.3944, 49.615],
            [8.3877, 49.6212],
            [8.3792, 49.6362],
            [8.3717, 49.6592],
            [8.3624, 49.6685],
            [8.3571, 49.6796],
            [8.3592, 49.692],
            [8.3649, 49.6983],
            [8.3775, 49.705],
            [8.3939, 49.7103],
            [8.4237, 49.7163],
            [8.438, 49.7219],
            [8.4548, 49.7412],
            [8.4787, 49.7598],
            [8.4776, 49.7655],
            [8.4663, 49.7691],
            [8.4359, 49.7656],
            [8.4237, 49.7685],
            [8.4152, 49.776],
            [8.4136, 49.7856],
            [8.4086, 49.7939],
            [8.3954, 49.8092],
            [8.38, 49.8228],
            [8.3781, 49.8316],
            [8.3858, 49.8502],
            [8.3816, 49.8594],
            [8.3581, 49.8649],
            [8.3488, 49.8727],
            [8.3427, 49.8835],
            [8.3458, 49.8972],
            [8.3538, 49.9074],
            [8.3555, 49.9175],
            [8.3448, 49.9395],
            [8.3412, 49.9529],
            [8.3225, 49.9762],
            [8.3038, 49.9882],
            [8.2897, 49.9937],
            [8.2763, 50.0085],
            [8.2449, 50.027],
            [8.2369, 50.0298],
            [8.1997, 50.034],
            [8.1844, 50.0349],
            [8.1729, 50.0333],
            [8.1492, 50.0264],
            [8.128, 50.023],
            [8.1104, 50.018],
            [8.0946, 50.0106],
            [8.0734, 50.0055],
            [8.0406, 50.0034],
            [8.0128, 49.9925],
            [7.9696, 49.9776],
            [7.9377, 49.9733],
            [7.9187, 49.9742],
            [7.8937, 49.9716],
            [7.8755, 49.9736],
            [7.8645, 49.9863],
            [7.8589, 50.0067],
            [7.8537, 50.0126],
            [7.8364, 50.019],
            [7.8082, 50.0373],
            [7.7811, 50.0506],
            [7.7757, 50.0551],
            [7.7754, 50.067],
            [7.7828, 50.067],
            [7.7937, 50.0738],
            [7.8036, 50.0832],
            [7.8172, 50.0799],
            [7.8385, 50.0835],
            [7.8349, 50.0952],
            [7.838, 50.1083],
            [7.8446, 50.112],
            [7.8473, 50.1244],
            [7.8558, 50.1275],
            [7.8766, 50.1187],
            [7.8842, 50.1141],
            [7.8991, 50.1214],
            [7.9102, 50.1168],
            [7.9254, 50.1046],
            [7.9277, 50.1199],
            [7.9215, 50.1339],
            [7.9325, 50.1423],
            [7.9139, 50.1504],
            [7.9079, 50.1556],
            [7.8911, 50.1619],
            [7.8856, 50.1664],
            [7.8889, 50.1729],
            [7.8834, 50.1815],
            [7.9118, 50.1956],
            [7.9106, 50.2031],
            [7.9218, 50.2078],
            [7.9467, 50.2095],
            [7.9535, 50.2198],
            [7.9634, 50.2203],
            [7.9863, 50.2275],
            [7.993, 50.2318],
            [8.0032, 50.2243],
            [8.0333, 50.2157],
            [8.037, 50.2224],
            [8.0488, 50.2293],
            [8.0534, 50.2369],
            [8.0391, 50.2452],
            [8.0326, 50.255],
            [8.0226, 50.2573],
            [8.0352, 50.2649],
            [8.037, 50.2707],
            [8.0538, 50.2735],
            [8.0687, 50.271],
            [8.0747, 50.267],
            [8.0917, 50.2648],
            [8.104, 50.2594],
            [8.1124, 50.2705],
            [8.1218, 50.2768],
            [8.1234, 50.2901],
            [8.1162, 50.3028],
            [8.1034, 50.3093],
            [8.107, 50.3218],
            [8.1026, 50.3262],
            [8.0824, 50.3297],
            [8.076, 50.3289],
            [8.0774, 50.3473],
            [8.0677, 50.3588],
            [8.0678, 50.3651],
            [8.0473, 50.3784],
            [8.0371, 50.3818],
            [8.022, 50.3817],
            [8.0202, 50.3856],
            [8.0227, 50.4],
            [7.9951, 50.3975],
            [7.9817, 50.4079],
            [7.9676, 50.4058],
            [7.9655, 50.4138],
            [7.9752, 50.414],
            [7.9861, 50.4224],
            [7.987, 50.439],
            [7.9904, 50.4439],
            [8.001, 50.4454],
            [8.0111, 50.4531],
            [8.0096, 50.4613],
            [8.0004, 50.4638],
            [7.9946, 50.4708],
            [7.9877, 50.4726],
            [7.9874, 50.4792],
            [7.9813, 50.4828],
            [7.9791, 50.4943],
            [7.9889, 50.4982],
            [7.9827, 50.5107],
            [7.9941, 50.5147],
            [7.9931, 50.5222],
            [8.0055, 50.5303],
            [8.0231, 50.538],
            [8.0302, 50.5511],
            [8.0429, 50.5534],
            [8.064, 50.5494],
            [8.0713, 50.5389],
            [8.0852, 50.5387],
            [8.1036, 50.5334],
            [8.1199, 50.5364],
            [8.1106, 50.5407],
            [8.1228, 50.5487],
            [8.1359, 50.5508],
            [8.1328, 50.559],
            [8.1392, 50.5669],
            [8.1523, 50.5934],
            [8.1471, 50.6026],
            [8.1258, 50.6104],
            [8.1361, 50.6167],
            [8.1413, 50.6275],
            [8.1402, 50.6321],
            [8.1304, 50.6372],
            [8.1273, 50.6437],
            [8.1156, 50.6517],
            [8.11, 50.6594],
            [8.111, 50.6725],
            [8.1236, 50.6807],
            [8.1247, 50.6853],
            [8.1318, 50.6964],
            [8.1425, 50.6948],
            [8.1512, 50.7059],
            [8.1516, 50.7137],
            [8.1675, 50.7339],
            [8.1647, 50.7447],
            [8.1544, 50.75],
            [8.1477, 50.7635],
            [8.1379, 50.7665],
            [8.142, 50.7728],
            [8.13, 50.779],
            [8.134, 50.7892],
            [8.1402, 50.7905],
            [8.1434, 50.7941],
            [8.1658, 50.8041],
            [8.1751, 50.8173],
            [8.1892, 50.829],
            [8.202, 50.8355],
            [8.2097, 50.837],
            [8.2206, 50.8456],
            [8.2293, 50.8487],
            [8.2329, 50.8554],
            [8.2597, 50.8716],
            [8.2669, 50.8804],
            [8.288, 50.8822],
            [8.2926, 50.8744],
            [8.3063, 50.8607],
            [8.3208, 50.8616],
            [8.3363, 50.8653],
            [8.3532, 50.8653],
            [8.359, 50.8607],
            [8.369, 50.864],
            [8.3701, 50.873],
            [8.3858, 50.8756],
            [8.3882, 50.8791],
            [8.3875, 50.8905],
            [8.4003, 50.8951],
            [8.4115, 50.9053],
            [8.4254, 50.9114],
            [8.4323, 50.9174],
            [8.4546, 50.9155],
            [8.4601, 50.9186],
            [8.45, 50.9408],
            [8.4601, 50.9472],
            [8.4613, 50.9589],
            [8.4584, 50.9656],
            [8.4743, 50.9719],
            [8.4748, 50.9761],
            [8.486, 50.9839],
            [8.4978, 50.9895],
            [8.5024, 50.9987],
            [8.5373, 51.0191],
            [8.527, 51.031],
            [8.525, 51.0379],
            [8.5191, 51.0435],
            [8.5106, 51.0377],
            [8.5052, 51.0404],
            [8.5111, 51.048],
            [8.5261, 51.0554],
            [8.5257, 51.0651],
            [8.5071, 51.0728],
            [8.5025, 51.0787],
            [8.5088, 51.0855],
            [8.523, 51.0907],
            [8.5279, 51.0968],
            [8.5426, 51.0953],
            [8.5525, 51.1027],
            [8.574, 51.1013],
            [8.595, 51.1017],
            [8.6197, 51.1002],
            [8.6432, 51.0953],
            [8.6614, 51.0956],
            [8.6843, 51.1076],
            [8.7203, 51.1096],
            [8.7206, 51.1139],
            [8.7104, 51.1177],
            [8.7021, 51.1167],
            [8.7011, 51.123],
            [8.693, 51.129],
            [8.6962, 51.137],
            [8.7191, 51.1516],
            [8.7485, 51.1678],
            [8.7482, 51.1715],
            [8.7583, 51.1768],
            [8.7516, 51.1812],
            [8.7506, 51.1967],
            [8.756, 51.2028],
            [8.765, 51.204],
            [8.7675, 51.2086],
            [8.7412, 51.2186],
            [8.7375, 51.2329],
            [8.732, 51.2384],
            [8.7276, 51.2509],
            [8.7363, 51.2612],
            [8.7266, 51.2652],
            [8.7219, 51.2734],
            [8.7137, 51.2749],
            [8.6865, 51.2733],
            [8.6775, 51.2679],
            [8.6658, 51.2678],
            [8.6519, 51.2635],
            [8.6298, 51.261],
            [8.6253, 51.2535],
            [8.611, 51.2466],
            [8.5957, 51.2468],
            [8.5858, 51.2532],
            [8.5804, 51.2646],
            [8.5668, 51.2707],
            [8.5573, 51.2771],
            [8.5649, 51.2874],
            [8.585, 51.2981],
            [8.6029, 51.3146],
            [8.6036, 51.3231],
            [8.6192, 51.3297],
            [8.6201, 51.3358],
            [8.6369, 51.3434],
            [8.6533, 51.3388],
            [8.6536, 51.3462],
            [8.6589, 51.3546],
            [8.6703, 51.3566],
            [8.6834, 51.362],
            [8.6773, 51.3689],
            [8.6827, 51.3737],
            [8.7003, 51.3769],
            [8.7187, 51.3726],
            [8.7313, 51.3721],
            [8.7375, 51.3745],
            [8.7604, 51.3785],
            [8.7713, 51.3853],
            [8.7807, 51.3858],
            [8.8029, 51.3905],
            [8.8238, 51.3896],
            [8.8284, 51.3857],
            [8.8387, 51.3839],
            [8.8549, 51.3773],
            [8.8641, 51.38],
            [8.8886, 51.392],
            [8.9165, 51.3927],
            [8.9382, 51.389],
            [8.9427, 51.3968],
            [8.9413, 51.4111],
            [8.946, 51.4169],
            [8.9474, 51.4273],
            [8.937, 51.4309],
            [8.9215, 51.4301],
            [8.9195, 51.4482],
            [8.9082, 51.4644],
            [8.8945, 51.4712],
            [8.8913, 51.4816],
            [8.8991, 51.4868],
            [8.9268, 51.4929],
            [8.9446, 51.5009],
            [8.9697, 51.5069],
            [9.0054, 51.5126],
            [9.0188, 51.5178],
            [9.0244, 51.5113],
            [9.0395, 51.5024],
            [9.0581, 51.5019],
            [9.0605, 51.4987],
            [9.0788, 51.5028],
            [9.0916, 51.4951],
            [9.0925, 51.4877],
            [9.0801, 51.4691],
            [9.0864, 51.4622],
            [9.0864, 51.4499],
            [9.0923, 51.4456],
            [9.1053, 51.4436],
            [9.1298, 51.4463],
            [9.1367, 51.4502],
            [9.1464, 51.4428],
            [9.1596, 51.4431],
            [9.165, 51.452],
            [9.177, 51.4634],
            [9.1838, 51.4656],
            [9.2117, 51.4616],
            [9.2185, 51.4712],
            [9.2174, 51.4794],
            [9.2246, 51.4898],
            [9.2374, 51.4917],
            [9.2436, 51.4978],
            [9.264, 51.4985],
            [9.2671, 51.5047],
            [9.2769, 51.5112],
            [9.2878, 51.515],
            [9.3078, 51.5135],
            [9.3152, 51.5225],
            [9.3115, 51.5367],
            [9.3206, 51.5472],
            [9.3157, 51.5522],
            [9.3339, 51.5582],
            [9.346, 51.5705],
            [9.3532, 51.5809],
            [9.3646, 51.5851],
            [9.3701, 51.5933],
            [9.3589, 51.5963],
            [9.3384, 51.61],
            [9.3374, 51.6143],
            [9.3595, 51.6202],
            [9.3704, 51.6199],
            [9.3781, 51.6268],
            [9.401, 51.6269],
            [9.4224, 51.6295],
            [9.4309, 51.6329],
            [9.4399, 51.6413],
            [9.4513, 51.6464],
          ],
        ],
      },
      properties: {name: 'Hesse', id: 'DE-HE', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-HE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.7299, 53.557],
            [9.7351, 53.5643],
            [9.7395, 53.5813],
            [9.7468, 53.5888],
            [9.7432, 53.5974],
            [9.749, 53.6042],
            [9.7589, 53.6065],
            [9.7586, 53.616],
            [9.7645, 53.628],
            [9.7694, 53.6284],
            [9.7723, 53.6172],
            [9.7958, 53.611],
            [9.7927, 53.6036],
            [9.7995, 53.5968],
            [9.8212, 53.5843],
            [9.8359, 53.5867],
            [9.8396, 53.5946],
            [9.8602, 53.5983],
            [9.8603, 53.6026],
            [9.8685, 53.6083],
            [9.8688, 53.6142],
            [9.8955, 53.6307],
            [9.905, 53.6424],
            [9.9062, 53.6525],
            [9.919, 53.656],
            [9.9242, 53.6534],
            [9.962, 53.6496],
            [9.9841, 53.6516],
            [9.9902, 53.6604],
            [9.9894, 53.6713],
            [10.0005, 53.682],
            [10.0406, 53.6824],
            [10.0529, 53.6776],
            [10.0651, 53.678],
            [10.0657, 53.6893],
            [10.0779, 53.7044],
            [10.0707, 53.7059],
            [10.0808, 53.7204],
            [10.0939, 53.7193],
            [10.1037, 53.7149],
            [10.1213, 53.7134],
            [10.1266, 53.7196],
            [10.151, 53.73],
            [10.162, 53.7332],
            [10.1688, 53.7386],
            [10.1874, 53.7312],
            [10.1928, 53.7239],
            [10.1827, 53.7094],
            [10.1748, 53.7122],
            [10.1576, 53.7043],
            [10.1617, 53.6989],
            [10.1601, 53.6907],
            [10.1441, 53.6777],
            [10.1546, 53.6748],
            [10.1571, 53.6701],
            [10.1758, 53.6685],
            [10.185, 53.6638],
            [10.1953, 53.6543],
            [10.1989, 53.6464],
            [10.1912, 53.6389],
            [10.1988, 53.6361],
            [10.221, 53.635],
            [10.218, 53.6262],
            [10.1908, 53.6127],
            [10.1972, 53.6],
            [10.1914, 53.5961],
            [10.1987, 53.5861],
            [10.1924, 53.584],
            [10.1671, 53.5825],
            [10.1561, 53.5795],
            [10.1463, 53.5654],
            [10.1555, 53.5364],
            [10.1641, 53.537],
            [10.1617, 53.5227],
            [10.1873, 53.5123],
            [10.2023, 53.5153],
            [10.2111, 53.521],
            [10.2145, 53.5139],
            [10.2235, 53.5086],
            [10.2379, 53.4962],
            [10.2361, 53.4901],
            [10.2409, 53.4825],
            [10.2657, 53.4748],
            [10.2711, 53.4647],
            [10.2837, 53.4596],
            [10.2924, 53.4522],
            [10.3009, 53.4503],
            [10.3165, 53.4525],
            [10.3262, 53.449],
            [10.3167, 53.4409],
            [10.3182, 53.4353],
            [10.3085, 53.4335],
            [10.2843, 53.4243],
            [10.2638, 53.4201],
            [10.2549, 53.4141],
            [10.2446, 53.3996],
            [10.2343, 53.3949],
            [10.221, 53.3954],
            [10.2004, 53.3986],
            [10.1747, 53.3962],
            [10.1641, 53.3996],
            [10.1522, 53.4077],
            [10.1445, 53.4168],
            [10.1331, 53.4231],
            [10.112, 53.4251],
            [10.1061, 53.4283],
            [10.0982, 53.4399],
            [10.0812, 53.4528],
            [10.0663, 53.4562],
            [10.054, 53.4621],
            [10.044, 53.4561],
            [10.0428, 53.4462],
            [10.0319, 53.4442],
            [10.0201, 53.4467],
            [10.0155, 53.4416],
            [10.0304, 53.4349],
            [10.0162, 53.4281],
            [9.9986, 53.4255],
            [9.985, 53.4162],
            [9.9744, 53.4144],
            [9.9705, 53.4208],
            [9.9529, 53.4274],
            [9.9419, 53.4212],
            [9.9275, 53.4199],
            [9.9154, 53.4139],
            [9.9067, 53.4159],
            [9.9156, 53.4237],
            [9.9142, 53.4299],
            [9.9208, 53.4355],
            [9.9134, 53.4469],
            [9.9036, 53.4561],
            [9.8934, 53.4559],
            [9.8886, 53.4507],
            [9.8737, 53.442],
            [9.8531, 53.4369],
            [9.838, 53.4493],
            [9.8162, 53.4606],
            [9.807, 53.4631],
            [9.8007, 53.469],
            [9.8039, 53.4922],
            [9.7928, 53.4931],
            [9.7827, 53.4906],
            [9.7852, 53.4995],
            [9.7682, 53.5037],
            [9.7629, 53.509],
            [9.7746, 53.5277],
            [9.7652, 53.5409],
            [9.7724, 53.5436],
            [9.7743, 53.5537],
            [9.7299, 53.557],
          ],
        ],
      },
      properties: {name: 'Hamburg', id: 'DE-HH', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-HH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [13.1467, 54.6019],
              [13.1537, 54.5851],
              [13.1296, 54.5898],
              [13.1252, 54.5835],
              [13.1109, 54.5806],
              [13.1175, 54.5733],
              [13.1098, 54.5671],
              [13.1151, 54.5615],
              [13.1177, 54.5518],
              [13.1143, 54.5461],
              [13.1163, 54.5373],
              [13.1058, 54.5289],
              [13.0964, 54.5275],
              [13.0908, 54.519],
              [13.0837, 54.5147],
              [13.081, 54.5031],
              [13.0738, 54.4993],
              [13.0765, 54.4851],
              [13.0709, 54.4621],
              [13.0612, 54.4684],
              [13.0614, 54.4763],
              [13.0662, 54.4927],
              [13.0736, 54.5074],
              [13.0809, 54.5174],
              [13.0867, 54.5363],
              [13.0991, 54.5576],
              [13.1017, 54.5743],
              [13.1004, 54.5899],
              [13.1242, 54.6034],
              [13.1356, 54.6051],
              [13.1467, 54.6019],
            ],
          ],
          [
            [
              [12.7356, 54.4129],
              [12.7298, 54.4047],
              [12.7171, 54.4059],
              [12.7233, 54.4123],
              [12.7356, 54.4129],
            ],
          ],
          [
            [
              [13.0526, 54.4478],
              [13.0427, 54.4425],
              [13.0202, 54.4433],
              [13.0049, 54.4415],
              [12.9889, 54.4334],
              [12.9861, 54.436],
              [12.9972, 54.4457],
              [13.0063, 54.4476],
              [13.0328, 54.4473],
              [13.0465, 54.4543],
              [13.0526, 54.4478],
            ],
          ],
          [
            [
              [12.9468, 54.4426],
              [12.9296, 54.4324],
              [12.9237, 54.4339],
              [12.9284, 54.4429],
              [12.9468, 54.4426],
            ],
          ],
          [
            [
              [13.4319, 54.6831],
              [13.4377, 54.6765],
              [13.4318, 54.6706],
              [13.4303, 54.663],
              [13.4228, 54.6568],
              [13.3925, 54.6511],
              [13.3844, 54.6458],
              [13.3757, 54.6335],
              [13.3767, 54.6261],
              [13.3836, 54.6092],
              [13.3912, 54.5987],
              [13.4031, 54.5887],
              [13.4191, 54.578],
              [13.4282, 54.5743],
              [13.461, 54.57],
              [13.4695, 54.5741],
              [13.4832, 54.5723],
              [13.5078, 54.5734],
              [13.5467, 54.5798],
              [13.6057, 54.5851],
              [13.6395, 54.5835],
              [13.6607, 54.5745],
              [13.6772, 54.5637],
              [13.6763, 54.5535],
              [13.68, 54.5419],
              [13.6775, 54.531],
              [13.6681, 54.5212],
              [13.6497, 54.5136],
              [13.6427, 54.5127],
              [13.6216, 54.5021],
              [13.6088, 54.4983],
              [13.6008, 54.4897],
              [13.5772, 54.4784],
              [13.5705, 54.4707],
              [13.5702, 54.4583],
              [13.5745, 54.4449],
              [13.5901, 54.4228],
              [13.6064, 54.4075],
              [13.6231, 54.3989],
              [13.6575, 54.3995],
              [13.6628, 54.4026],
              [13.6764, 54.3981],
              [13.6883, 54.3892],
              [13.7025, 54.3822],
              [13.7119, 54.3665],
              [13.7362, 54.3492],
              [13.7642, 54.34],
              [13.7396, 54.3357],
              [13.7331, 54.3296],
              [13.7299, 54.3179],
              [13.719, 54.3122],
              [13.7155, 54.2994],
              [13.7171, 54.2844],
              [13.7235, 54.2755],
              [13.7096, 54.2686],
              [13.6962, 54.279],
              [13.6847, 54.2824],
              [13.6897, 54.2863],
              [13.702, 54.2798],
              [13.7085, 54.2799],
              [13.7061, 54.2901],
              [13.7011, 54.2961],
              [13.6894, 54.2907],
              [13.668, 54.2917],
              [13.6551, 54.2882],
              [13.6458, 54.2953],
              [13.6463, 54.299],
              [13.6595, 54.3048],
              [13.6733, 54.3075],
              [13.6852, 54.3144],
              [13.6945, 54.315],
              [13.7033, 54.3209],
              [13.6975, 54.3252],
              [13.6835, 54.3261],
              [13.6745, 54.33],
              [13.6455, 54.3265],
              [13.676, 54.3383],
              [13.6831, 54.3463],
              [13.6805, 54.3501],
              [13.6535, 54.3474],
              [13.6496, 54.3492],
              [13.635, 54.3442],
              [13.6222, 54.333],
              [13.6115, 54.3307],
              [13.5996, 54.3467],
              [13.584, 54.3532],
              [13.5737, 54.3529],
              [13.5652, 54.3459],
              [13.5524, 54.3404],
              [13.5407, 54.3431],
              [13.5346, 54.3413],
              [13.5171, 54.3418],
              [13.5093, 54.3439],
              [13.4949, 54.3369],
              [13.4829, 54.3346],
              [13.4789, 54.3305],
              [13.468, 54.3301],
              [13.4667, 54.322],
              [13.4615, 54.3162],
              [13.4462, 54.3138],
              [13.4209, 54.3027],
              [13.4125, 54.2952],
              [13.4129, 54.2901],
              [13.3948, 54.2722],
              [13.3905, 54.2791],
              [13.3777, 54.2776],
              [13.3702, 54.2712],
              [13.3526, 54.2697],
              [13.3574, 54.2591],
              [13.3556, 54.2506],
              [13.3611, 54.249],
              [13.3691, 54.255],
              [13.3801, 54.2535],
              [13.3907, 54.2586],
              [13.3954, 54.2645],
              [13.4045, 54.2645],
              [13.4183, 54.2539],
              [13.4252, 54.2383],
              [13.4174, 54.2324],
              [13.4014, 54.228],
              [13.3926, 54.2212],
              [13.3882, 54.2268],
              [13.3577, 54.2335],
              [13.3217, 54.2376],
              [13.3268, 54.2584],
              [13.3226, 54.2706],
              [13.3096, 54.2659],
              [13.3041, 54.2585],
              [13.2859, 54.2585],
              [13.2691, 54.2536],
              [13.2473, 54.2595],
              [13.2387, 54.2671],
              [13.2307, 54.2674],
              [13.2193, 54.2722],
              [13.2008, 54.27],
              [13.1968, 54.2841],
              [13.2033, 54.2872],
              [13.2012, 54.2938],
              [13.1946, 54.2964],
              [13.1821, 54.2872],
              [13.1673, 54.2891],
              [13.1434, 54.2812],
              [13.14, 54.2825],
              [13.1415, 54.2958],
              [13.1585, 54.3042],
              [13.1656, 54.3034],
              [13.1796, 54.296],
              [13.1837, 54.3019],
              [13.1723, 54.3086],
              [13.1597, 54.3097],
              [13.1439, 54.3145],
              [13.1346, 54.3241],
              [13.1151, 54.3321],
              [13.1153, 54.344],
              [13.1231, 54.3514],
              [13.1235, 54.3634],
              [13.1281, 54.3707],
              [13.1342, 54.3728],
              [13.1574, 54.375],
              [13.1741, 54.3714],
              [13.1844, 54.3748],
              [13.1967, 54.3756],
              [13.2032, 54.3714],
              [13.2351, 54.3696],
              [13.2386, 54.378],
              [13.2605, 54.3817],
              [13.2595, 54.3856],
              [13.2416, 54.3851],
              [13.2304, 54.3983],
              [13.2379, 54.409],
              [13.2196, 54.4142],
              [13.212, 54.4296],
              [13.2007, 54.425],
              [13.1819, 54.423],
              [13.1691, 54.4246],
              [13.1579, 54.4203],
              [13.1501, 54.4242],
              [13.1514, 54.4292],
              [13.1597, 54.4338],
              [13.1692, 54.4524],
              [13.1572, 54.4479],
              [13.147, 54.4487],
              [13.1326, 54.4428],
              [13.1224, 54.4431],
              [13.1248, 54.4527],
              [13.1313, 54.4574],
              [13.1395, 54.4707],
              [13.1565, 54.4865],
              [13.1698, 54.4919],
              [13.18, 54.4935],
              [13.1946, 54.492],
              [13.1957, 54.4869],
              [13.2061, 54.4846],
              [13.2273, 54.4834],
              [13.224, 54.4653],
              [13.212, 54.4637],
              [13.2055, 54.4683],
              [13.1868, 54.4645],
              [13.1944, 54.4489],
              [13.201, 54.4475],
              [13.206, 54.4597],
              [13.2165, 54.4582],
              [13.2232, 54.4636],
              [13.2358, 54.4621],
              [13.2423, 54.4716],
              [13.2521, 54.471],
              [13.2576, 54.4787],
              [13.2556, 54.4856],
              [13.243, 54.4859],
              [13.2315, 54.4833],
              [13.2269, 54.4868],
              [13.229, 54.5103],
              [13.2129, 54.5076],
              [13.199, 54.5025],
              [13.1702, 54.5074],
              [13.1631, 54.5144],
              [13.1618, 54.5318],
              [13.1491, 54.5462],
              [13.1655, 54.5445],
              [13.1989, 54.5488],
              [13.2151, 54.5497],
              [13.2366, 54.554],
              [13.2556, 54.5508],
              [13.2698, 54.5446],
              [13.2861, 54.5426],
              [13.2952, 54.534],
              [13.2897, 54.5304],
              [13.2878, 54.5213],
              [13.2939, 54.5206],
              [13.3007, 54.5139],
              [13.3106, 54.519],
              [13.3104, 54.5251],
              [13.3037, 54.5283],
              [13.3081, 54.5394],
              [13.2972, 54.5524],
              [13.3212, 54.5658],
              [13.3372, 54.5697],
              [13.3453, 54.5763],
              [13.3546, 54.5797],
              [13.3691, 54.5758],
              [13.3608, 54.5689],
              [13.3589, 54.5629],
              [13.3484, 54.552],
              [13.3537, 54.5478],
              [13.3639, 54.5561],
              [13.3725, 54.5597],
              [13.3788, 54.5513],
              [13.3777, 54.5469],
              [13.4033, 54.5314],
              [13.4137, 54.5189],
              [13.4053, 54.5076],
              [13.4122, 54.4932],
              [13.442, 54.4865],
              [13.4457, 54.4768],
              [13.4528, 54.4709],
              [13.4595, 54.4774],
              [13.4849, 54.4835],
              [13.4932, 54.48],
              [13.5029, 54.4806],
              [13.5003, 54.4921],
              [13.5062, 54.4997],
              [13.507, 54.5071],
              [13.5147, 54.5152],
              [13.5102, 54.5221],
              [13.511, 54.5295],
              [13.4971, 54.5474],
              [13.4942, 54.5563],
              [13.5097, 54.5572],
              [13.5059, 54.5634],
              [13.4927, 54.5628],
              [13.4809, 54.5524],
              [13.4537, 54.5524],
              [13.4395, 54.5541],
              [13.4248, 54.5679],
              [13.406, 54.572],
              [13.3928, 54.5729],
              [13.3837, 54.5808],
              [13.3839, 54.5962],
              [13.3744, 54.606],
              [13.3645, 54.6111],
              [13.3559, 54.6121],
              [13.3466, 54.5992],
              [13.3281, 54.5928],
              [13.3344, 54.5827],
              [13.3167, 54.5795],
              [13.3071, 54.5726],
              [13.2868, 54.5691],
              [13.2734, 54.5629],
              [13.2595, 54.5622],
              [13.2435, 54.5584],
              [13.2547, 54.5851],
              [13.2607, 54.5944],
              [13.2727, 54.6036],
              [13.2836, 54.6161],
              [13.2878, 54.6272],
              [13.2873, 54.6404],
              [13.2846, 54.6447],
              [13.2646, 54.6421],
              [13.2274, 54.624],
              [13.2332, 54.6043],
              [13.2371, 54.599],
              [13.2299, 54.5944],
              [13.2279, 54.5866],
              [13.2144, 54.5844],
              [13.1948, 54.5714],
              [13.181, 54.5672],
              [13.1783, 54.5609],
              [13.1673, 54.5586],
              [13.1613, 54.5648],
              [13.1695, 54.5722],
              [13.1938, 54.5804],
              [13.2101, 54.5896],
              [13.2178, 54.5962],
              [13.2265, 54.6102],
              [13.2186, 54.6343],
              [13.2263, 54.6446],
              [13.2462, 54.6569],
              [13.2898, 54.6737],
              [13.325, 54.6743],
              [13.3489, 54.6776],
              [13.361, 54.6811],
              [13.3809, 54.6836],
              [13.4101, 54.6821],
              [13.4284, 54.6847],
              [13.4319, 54.6831],
            ],
          ],
          [
            [
              [14.4119, 53.3306],
              [14.4012, 53.3312],
              [14.3853, 53.3266],
              [14.378, 53.3186],
              [14.3513, 53.3144],
              [14.3481, 53.3099],
              [14.3202, 53.3117],
              [14.3022, 53.2897],
              [14.3017, 53.286],
              [14.2631, 53.2766],
              [14.2657, 53.2607],
              [14.2449, 53.2571],
              [14.2118, 53.2545],
              [14.2009, 53.261],
              [14.1857, 53.2606],
              [14.1593, 53.267],
              [14.1425, 53.2651],
              [14.1284, 53.2608],
              [14.0979, 53.2623],
              [14.0997, 53.2695],
              [14.1063, 53.2782],
              [14.1301, 53.2983],
              [14.1594, 53.3146],
              [14.1635, 53.3142],
              [14.1925, 53.3332],
              [14.1914, 53.3361],
              [14.2086, 53.3483],
              [14.2209, 53.359],
              [14.226, 53.3596],
              [14.2344, 53.3705],
              [14.2447, 53.3888],
              [14.2414, 53.3907],
              [14.2406, 53.4071],
              [14.2356, 53.4144],
              [14.2437, 53.4228],
              [14.2335, 53.4273],
              [14.2229, 53.4353],
              [14.2084, 53.4244],
              [14.1924, 53.4222],
              [14.174, 53.424],
              [14.1633, 53.4274],
              [14.1486, 53.4368],
              [14.1308, 53.438],
              [14.1216, 53.4416],
              [14.1038, 53.4231],
              [14.0919, 53.4224],
              [14.0759, 53.4153],
              [14.0513, 53.4139],
              [14.0466, 53.4301],
              [14.0278, 53.4296],
              [14.0101, 53.431],
              [14.0056, 53.4349],
              [13.9912, 53.4353],
              [13.9629, 53.4314],
              [13.9529, 53.4323],
              [13.9368, 53.43],
              [13.9161, 53.4226],
              [13.9003, 53.4327],
              [13.9074, 53.4367],
              [13.9064, 53.4464],
              [13.9148, 53.4494],
              [13.9117, 53.4574],
              [13.8756, 53.4742],
              [13.8823, 53.4841],
              [13.8748, 53.4885],
              [13.8731, 53.4956],
              [13.8771, 53.5055],
              [13.8528, 53.5136],
              [13.8226, 53.5189],
              [13.8188, 53.5263],
              [13.7973, 53.5454],
              [13.7977, 53.5589],
              [13.7849, 53.5557],
              [13.7783, 53.5427],
              [13.7758, 53.5284],
              [13.7791, 53.5235],
              [13.7745, 53.5149],
              [13.7928, 53.5105],
              [13.8008, 53.4994],
              [13.807, 53.5025],
              [13.8227, 53.4977],
              [13.8126, 53.4857],
              [13.8063, 53.4817],
              [13.7756, 53.474],
              [13.755, 53.4778],
              [13.7395, 53.486],
              [13.7342, 53.4832],
              [13.7139, 53.4817],
              [13.6986, 53.4722],
              [13.6769, 53.4662],
              [13.6716, 53.4615],
              [13.6599, 53.4586],
              [13.6517, 53.4483],
              [13.6397, 53.447],
              [13.6332, 53.4419],
              [13.6355, 53.4329],
              [13.6286, 53.4284],
              [13.6334, 53.4195],
              [13.6262, 53.4104],
              [13.6182, 53.4086],
              [13.6111, 53.4129],
              [13.5923, 53.4099],
              [13.5875, 53.4134],
              [13.5736, 53.4101],
              [13.5694, 53.4039],
              [13.5506, 53.3988],
              [13.557, 53.3924],
              [13.5529, 53.3823],
              [13.5567, 53.3779],
              [13.5454, 53.3718],
              [13.5425, 53.3651],
              [13.524, 53.3665],
              [13.5156, 53.3511],
              [13.5238, 53.3492],
              [13.5175, 53.3409],
              [13.5216, 53.3305],
              [13.5221, 53.3198],
              [13.5057, 53.3194],
              [13.497, 53.3101],
              [13.4966, 53.3011],
              [13.4846, 53.2925],
              [13.4626, 53.2891],
              [13.4548, 53.2987],
              [13.4353, 53.2968],
              [13.4315, 53.2838],
              [13.4265, 53.2802],
              [13.4141, 53.2618],
              [13.4064, 53.2611],
              [13.4046, 53.2498],
              [13.3946, 53.2417],
              [13.3868, 53.242],
              [13.3762, 53.2586],
              [13.3685, 53.2626],
              [13.3685, 53.2699],
              [13.3613, 53.2772],
              [13.3411, 53.278],
              [13.3391, 53.2727],
              [13.3006, 53.2802],
              [13.2906, 53.2752],
              [13.2826, 53.2759],
              [13.2715, 53.2643],
              [13.2523, 53.2609],
              [13.2459, 53.256],
              [13.2477, 53.245],
              [13.2414, 53.2357],
              [13.2401, 53.2243],
              [13.2313, 53.216],
              [13.2038, 53.2223],
              [13.1913, 53.2342],
              [13.187, 53.2476],
              [13.1789, 53.2453],
              [13.1726, 53.2503],
              [13.1351, 53.2427],
              [13.1299, 53.2356],
              [13.1124, 53.2388],
              [13.1041, 53.2329],
              [13.1091, 53.2242],
              [13.105, 53.2147],
              [13.0901, 53.2154],
              [13.0842, 53.2122],
              [13.074, 53.2002],
              [13.069, 53.204],
              [13.0544, 53.2007],
              [13.0328, 53.1923],
              [13.0183, 53.1827],
              [13.0191, 53.1753],
              [13.0026, 53.1701],
              [12.9925, 53.1699],
              [12.9819, 53.1639],
              [12.965, 53.1668],
              [12.9597, 53.1789],
              [12.9632, 53.186],
              [12.9807, 53.1925],
              [12.9764, 53.1985],
              [12.9648, 53.2012],
              [12.9533, 53.2012],
              [12.9365, 53.1952],
              [12.9214, 53.197],
              [12.9028, 53.1948],
              [12.8894, 53.1889],
              [12.8877, 53.1833],
              [12.8773, 53.1806],
              [12.8585, 53.1875],
              [12.8473, 53.2018],
              [12.8382, 53.1975],
              [12.8246, 53.1963],
              [12.8143, 53.1917],
              [12.8053, 53.1947],
              [12.7947, 53.1897],
              [12.782, 53.1887],
              [12.7604, 53.1909],
              [12.7554, 53.1961],
              [12.7387, 53.1998],
              [12.7535, 53.2136],
              [12.7558, 53.2241],
              [12.7204, 53.2221],
              [12.7047, 53.2272],
              [12.6903, 53.2258],
              [12.6717, 53.231],
              [12.6654, 53.2352],
              [12.6667, 53.2421],
              [12.6747, 53.2455],
              [12.6614, 53.2542],
              [12.6307, 53.2536],
              [12.6164, 53.2503],
              [12.6064, 53.2444],
              [12.5976, 53.2484],
              [12.5666, 53.2555],
              [12.5472, 53.262],
              [12.5312, 53.2645],
              [12.5051, 53.2631],
              [12.5043, 53.2567],
              [12.4959, 53.2546],
              [12.4618, 53.2553],
              [12.4462, 53.2498],
              [12.4336, 53.2576],
              [12.4308, 53.2718],
              [12.4278, 53.2755],
              [12.4106, 53.2802],
              [12.4019, 53.2804],
              [12.3933, 53.2852],
              [12.3957, 53.2953],
              [12.3926, 53.3012],
              [12.378, 53.3019],
              [12.3607, 53.3075],
              [12.3507, 53.3159],
              [12.3337, 53.3196],
              [12.3255, 53.3233],
              [12.3043, 53.3275],
              [12.2839, 53.3283],
              [12.2716, 53.3247],
              [12.259, 53.328],
              [12.2419, 53.3352],
              [12.2364, 53.3441],
              [12.2306, 53.3599],
              [12.2132, 53.3555],
              [12.1913, 53.3551],
              [12.1866, 53.3535],
              [12.1863, 53.3442],
              [12.1698, 53.3407],
              [12.1591, 53.3503],
              [12.1518, 53.3624],
              [12.1404, 53.3589],
              [12.1344, 53.3503],
              [12.1119, 53.3512],
              [12.1121, 53.3456],
              [12.0826, 53.3482],
              [12.0802, 53.369],
              [12.0521, 53.3687],
              [12.0491, 53.3625],
              [12.0593, 53.3518],
              [12.0397, 53.3465],
              [12.0164, 53.3351],
              [12.0218, 53.3206],
              [12.0197, 53.3138],
              [12.0235, 53.3058],
              [12.0203, 53.3007],
              [12.0027, 53.3002],
              [11.9937, 53.2926],
              [11.973, 53.2987],
              [11.9646, 53.285],
              [11.9529, 53.2782],
              [11.9538, 53.2732],
              [11.9416, 53.2737],
              [11.9189, 53.2715],
              [11.9036, 53.2731],
              [11.8916, 53.2776],
              [11.8851, 53.267],
              [11.8724, 53.2606],
              [11.8633, 53.2503],
              [11.8527, 53.2496],
              [11.8445, 53.2527],
              [11.8374, 53.2508],
              [11.8103, 53.2518],
              [11.7957, 53.2539],
              [11.8034, 53.246],
              [11.8126, 53.2448],
              [11.8298, 53.237],
              [11.8316, 53.2329],
              [11.8174, 53.2255],
              [11.7961, 53.2249],
              [11.7753, 53.228],
              [11.7587, 53.2237],
              [11.7521, 53.2195],
              [11.7305, 53.2184],
              [11.7275, 53.2328],
              [11.7057, 53.2333],
              [11.7064, 53.2382],
              [11.6975, 53.2438],
              [11.6752, 53.2412],
              [11.6724, 53.2452],
              [11.66, 53.2444],
              [11.6519, 53.2381],
              [11.6387, 53.2377],
              [11.6262, 53.2403],
              [11.6155, 53.2312],
              [11.5981, 53.2266],
              [11.5866, 53.2142],
              [11.5767, 53.2115],
              [11.5645, 53.2141],
              [11.5507, 53.2098],
              [11.5584, 53.204],
              [11.5577, 53.1858],
              [11.5627, 53.1802],
              [11.5666, 53.1655],
              [11.555, 53.1506],
              [11.5502, 53.1486],
              [11.556, 53.138],
              [11.5462, 53.1312],
              [11.5384, 53.1306],
              [11.5319, 53.1253],
              [11.5109, 53.125],
              [11.5052, 53.1207],
              [11.4873, 53.1285],
              [11.4713, 53.1268],
              [11.4741, 53.1334],
              [11.4628, 53.1403],
              [11.4502, 53.1391],
              [11.4403, 53.135],
              [11.4209, 53.141],
              [11.4131, 53.1365],
              [11.3971, 53.1395],
              [11.3931, 53.1275],
              [11.3978, 53.1145],
              [11.3927, 53.1112],
              [11.3759, 53.1137],
              [11.3696, 53.1112],
              [11.3563, 53.1161],
              [11.3345, 53.1133],
              [11.326, 53.1193],
              [11.3145, 53.1197],
              [11.3049, 53.1158],
              [11.2669, 53.1224],
              [11.2442, 53.1386],
              [11.2251, 53.1441],
              [11.2111, 53.144],
              [11.1932, 53.1355],
              [11.1836, 53.1368],
              [11.1799, 53.1482],
              [11.174, 53.1577],
              [11.1895, 53.1671],
              [11.1975, 53.1785],
              [11.19, 53.1844],
              [11.1778, 53.1882],
              [11.1711, 53.1864],
              [11.1375, 53.1929],
              [11.1465, 53.1974],
              [11.1392, 53.2013],
              [11.1199, 53.2039],
              [11.0893, 53.2139],
              [11.0873, 53.2186],
              [11.0737, 53.2255],
              [11.0609, 53.2378],
              [11.0531, 53.2515],
              [11.0387, 53.2615],
              [11.0356, 53.2713],
              [11.0276, 53.2795],
              [11.013, 53.2799],
              [11.0081, 53.2842],
              [11.0085, 53.2992],
              [11.0022, 53.3049],
              [11.0036, 53.3103],
              [10.9874, 53.3336],
              [10.9594, 53.3376],
              [10.9557, 53.3333],
              [10.9369, 53.3394],
              [10.9278, 53.3482],
              [10.9181, 53.3485],
              [10.909, 53.3374],
              [10.8949, 53.3361],
              [10.8819, 53.3321],
              [10.888, 53.3257],
              [10.882, 53.3224],
              [10.8726, 53.3237],
              [10.8709, 53.3163],
              [10.8573, 53.3155],
              [10.8522, 53.3093],
              [10.8314, 53.3052],
              [10.8281, 53.312],
              [10.7971, 53.314],
              [10.7816, 53.3217],
              [10.758, 53.3294],
              [10.7647, 53.3379],
              [10.7371, 53.3447],
              [10.7273, 53.358],
              [10.6997, 53.3706],
              [10.6818, 53.3677],
              [10.6583, 53.3677],
              [10.6381, 53.3709],
              [10.6077, 53.368],
              [10.5953, 53.3642],
              [10.5979, 53.3752],
              [10.6161, 53.3823],
              [10.6216, 53.403],
              [10.6186, 53.4107],
              [10.6276, 53.4444],
              [10.6355, 53.4551],
              [10.6413, 53.4549],
              [10.6537, 53.4607],
              [10.6928, 53.4554],
              [10.7023, 53.4779],
              [10.721, 53.4761],
              [10.7277, 53.4797],
              [10.7568, 53.4827],
              [10.7652, 53.4902],
              [10.7788, 53.4951],
              [10.7876, 53.5101],
              [10.8119, 53.5156],
              [10.8254, 53.5132],
              [10.8208, 53.5332],
              [10.8342, 53.542],
              [10.8297, 53.5524],
              [10.8282, 53.5661],
              [10.8246, 53.5734],
              [10.839, 53.5761],
              [10.8506, 53.5643],
              [10.8791, 53.5676],
              [10.8949, 53.5728],
              [10.9108, 53.571],
              [10.9234, 53.583],
              [10.9171, 53.6],
              [10.9238, 53.6072],
              [10.9295, 53.629],
              [10.9426, 53.6375],
              [10.9524, 53.6483],
              [10.9548, 53.6589],
              [10.9448, 53.6599],
              [10.9395, 53.6669],
              [10.9444, 53.6747],
              [10.9422, 53.6847],
              [10.9302, 53.687],
              [10.9232, 53.6993],
              [10.8863, 53.7057],
              [10.8724, 53.7019],
              [10.8635, 53.7048],
              [10.8521, 53.7032],
              [10.8249, 53.7123],
              [10.8107, 53.7217],
              [10.8091, 53.7351],
              [10.7917, 53.7445],
              [10.7587, 53.748],
              [10.7588, 53.7642],
              [10.7675, 53.7723],
              [10.7675, 53.7807],
              [10.7736, 53.7928],
              [10.7669, 53.8014],
              [10.763, 53.8226],
              [10.7488, 53.8328],
              [10.7503, 53.8448],
              [10.7609, 53.8614],
              [10.772, 53.8728],
              [10.8014, 53.8764],
              [10.8134, 53.8872],
              [10.8169, 53.8941],
              [10.8464, 53.8995],
              [10.8454, 53.9044],
              [10.8716, 53.9146],
              [10.8807, 53.9249],
              [10.8879, 53.9243],
              [10.8967, 53.9178],
              [10.9126, 53.9154],
              [10.903, 53.9072],
              [10.9038, 53.9018],
              [10.9134, 53.8983],
              [10.947, 53.905],
              [10.9485, 53.9124],
              [10.9365, 53.9184],
              [10.9116, 53.9227],
              [10.8935, 53.9228],
              [10.8929, 53.9322],
              [10.8979, 53.939],
              [10.9086, 53.946],
              [10.9038, 53.9565],
              [10.9287, 53.9637],
              [10.9835, 53.9888],
              [10.9991, 53.992],
              [11.0298, 54.0038],
              [11.0553, 54.0088],
              [11.0746, 54.0097],
              [11.0906, 54.0136],
              [11.1161, 54.011],
              [11.1454, 54.0096],
              [11.18, 54.015],
              [11.1927, 54.0094],
              [11.1949, 53.9993],
              [11.2012, 53.9913],
              [11.2183, 53.9843],
              [11.2372, 53.9819],
              [11.2541, 53.9874],
              [11.2597, 53.9814],
              [11.2535, 53.974],
              [11.2468, 53.9729],
              [11.2427, 53.9504],
              [11.2465, 53.9425],
              [11.2535, 53.9371],
              [11.276, 53.9308],
              [11.2886, 53.9314],
              [11.2981, 53.937],
              [11.3155, 53.9429],
              [11.3268, 53.9585],
              [11.34, 53.9571],
              [11.3474, 53.9458],
              [11.3683, 53.9341],
              [11.3909, 53.9382],
              [11.4057, 53.9354],
              [11.4014, 53.9273],
              [11.4036, 53.9211],
              [11.4215, 53.9141],
              [11.4311, 53.9124],
              [11.4364, 53.9066],
              [11.4346, 53.9004],
              [11.4522, 53.8963],
              [11.4457, 53.9063],
              [11.4486, 53.9142],
              [11.4594, 53.9169],
              [11.4743, 53.9282],
              [11.482, 53.9456],
              [11.479, 53.9643],
              [11.4679, 53.9703],
              [11.4538, 53.9567],
              [11.4456, 53.9588],
              [11.4416, 53.9648],
              [11.4468, 53.9747],
              [11.4448, 53.985],
              [11.4469, 53.9962],
              [11.4329, 53.9897],
              [11.4272, 53.9807],
              [11.4293, 53.9714],
              [11.4257, 53.961],
              [11.4103, 53.9685],
              [11.4022, 53.9682],
              [11.3952, 53.9738],
              [11.3877, 53.9713],
              [11.3793, 53.9736],
              [11.3758, 53.9809],
              [11.3761, 53.9939],
              [11.3863, 54.0022],
              [11.3984, 54.0076],
              [11.4264, 54.0152],
              [11.4477, 54.023],
              [11.4692, 54.0253],
              [11.4908, 54.0225],
              [11.495, 54.0174],
              [11.4903, 54.0026],
              [11.4805, 54.0002],
              [11.4783, 53.9865],
              [11.4799, 53.9822],
              [11.4681, 53.9735],
              [11.4731, 53.9716],
              [11.4942, 53.971],
              [11.4914, 53.975],
              [11.4987, 53.9807],
              [11.5001, 53.9863],
              [11.4911, 53.9932],
              [11.5058, 54.0043],
              [11.5105, 54.0135],
              [11.5328, 54.0187],
              [11.5342, 54.0247],
              [11.519, 54.0262],
              [11.52, 54.0318],
              [11.5279, 54.0338],
              [11.5353, 54.0276],
              [11.5457, 54.0257],
              [11.5623, 54.0283],
              [11.5742, 54.0341],
              [11.5826, 54.0413],
              [11.5865, 54.0508],
              [11.5817, 54.0635],
              [11.5956, 54.0679],
              [11.61, 54.07],
              [11.622, 54.0768],
              [11.6256, 54.0907],
              [11.6118, 54.1021],
              [11.6016, 54.102],
              [11.5993, 54.096],
              [11.5883, 54.094],
              [11.5709, 54.0793],
              [11.547, 54.0616],
              [11.5368, 54.058],
              [11.5335, 54.0677],
              [11.527, 54.0739],
              [11.5545, 54.0937],
              [11.5658, 54.0981],
              [11.5836, 54.0993],
              [11.5975, 54.1025],
              [11.6084, 54.1074],
              [11.6366, 54.127],
              [11.6757, 54.1511],
              [11.6964, 54.1545],
              [11.7317, 54.1531],
              [11.7569, 54.1547],
              [11.7834, 54.1492],
              [11.8165, 54.1452],
              [11.8408, 54.1449],
              [11.8658, 54.1473],
              [11.9047, 54.1555],
              [11.9435, 54.165],
              [11.9616, 54.1666],
              [12.0105, 54.1781],
              [12.032, 54.1762],
              [12.0571, 54.177],
              [12.0739, 54.1792],
              [12.0887, 54.184],
              [12.1035, 54.178],
              [12.1293, 54.1861],
              [12.1455, 54.1983],
              [12.1592, 54.2111],
              [12.1712, 54.2265],
              [12.185, 54.2377],
              [12.2068, 54.248],
              [12.2396, 54.258],
              [12.2788, 54.2733],
              [12.3053, 54.2811],
              [12.3293, 54.2919],
              [12.3434, 54.3023],
              [12.3649, 54.3251],
              [12.3825, 54.3488],
              [12.3922, 54.3584],
              [12.4073, 54.3777],
              [12.4279, 54.3873],
              [12.4524, 54.4042],
              [12.4654, 54.4179],
              [12.4915, 54.4636],
              [12.4989, 54.4729],
              [12.5124, 54.4817],
              [12.5358, 54.4626],
              [12.5532, 54.4551],
              [12.5778, 54.4522],
              [12.6204, 54.4499],
              [12.6568, 54.4429],
              [12.7374, 54.4423],
              [12.7786, 54.4426],
              [12.7866, 54.4418],
              [12.8519, 54.4413],
              [12.9164, 54.4454],
              [12.9187, 54.4335],
              [12.9244, 54.4248],
              [12.9146, 54.4212],
              [12.9007, 54.4203],
              [12.8729, 54.4143],
              [12.8546, 54.4208],
              [12.8319, 54.4221],
              [12.8199, 54.4202],
              [12.7986, 54.4092],
              [12.7913, 54.4151],
              [12.7791, 54.4162],
              [12.7399, 54.415],
              [12.7342, 54.4173],
              [12.7248, 54.4303],
              [12.7092, 54.4283],
              [12.6976, 54.432],
              [12.6894, 54.4313],
              [12.669, 54.4214],
              [12.665, 54.4086],
              [12.6514, 54.4112],
              [12.6328, 54.4213],
              [12.6209, 54.4188],
              [12.6173, 54.4147],
              [12.5961, 54.4082],
              [12.5861, 54.4078],
              [12.5761, 54.4036],
              [12.5779, 54.3965],
              [12.5857, 54.3932],
              [12.5772, 54.3884],
              [12.5528, 54.3901],
              [12.5253, 54.3808],
              [12.5199, 54.3742],
              [12.5358, 54.3648],
              [12.555, 54.3719],
              [12.5811, 54.3732],
              [12.5976, 54.3712],
              [12.6199, 54.373],
              [12.6394, 54.3796],
              [12.6513, 54.3818],
              [12.667, 54.3895],
              [12.6703, 54.395],
              [12.6607, 54.4027],
              [12.6704, 54.4077],
              [12.6842, 54.4062],
              [12.7037, 54.4116],
              [12.7136, 54.407],
              [12.712, 54.4022],
              [12.6919, 54.4017],
              [12.6845, 54.3944],
              [12.6925, 54.3861],
              [12.7001, 54.3988],
              [12.7142, 54.3989],
              [12.7143, 54.3869],
              [12.7194, 54.3722],
              [12.7287, 54.371],
              [12.741, 54.3732],
              [12.7435, 54.3817],
              [12.755, 54.3711],
              [12.7703, 54.3746],
              [12.7754, 54.3897],
              [12.7843, 54.3855],
              [12.7874, 54.3794],
              [12.7946, 54.3787],
              [12.8032, 54.3722],
              [12.81, 54.3584],
              [12.8077, 54.3487],
              [12.8249, 54.3525],
              [12.8431, 54.3509],
              [12.8514, 54.3536],
              [12.8614, 54.3611],
              [12.8759, 54.3653],
              [12.883, 54.3715],
              [12.8875, 54.3868],
              [12.8968, 54.4011],
              [12.9116, 54.4066],
              [12.9238, 54.4134],
              [12.9547, 54.4177],
              [12.9584, 54.4232],
              [12.9844, 54.4253],
              [12.9948, 54.4291],
              [12.9998, 54.4358],
              [13.0174, 54.439],
              [13.0347, 54.4324],
              [13.0348, 54.4232],
              [13.0251, 54.4196],
              [13.0229, 54.4138],
              [13.0264, 54.3972],
              [13.0374, 54.3909],
              [13.0485, 54.381],
              [13.0621, 54.3805],
              [13.0742, 54.3831],
              [13.0847, 54.3786],
              [13.0932, 54.3682],
              [13.0908, 54.361],
              [13.0763, 54.3477],
              [13.0799, 54.3362],
              [13.0884, 54.32],
              [13.0994, 54.3165],
              [13.1093, 54.3076],
              [13.1098, 54.3017],
              [13.1035, 54.2909],
              [13.1052, 54.2821],
              [13.1173, 54.2752],
              [13.1343, 54.2684],
              [13.1563, 54.2697],
              [13.1637, 54.2766],
              [13.1718, 54.2753],
              [13.1799, 54.2699],
              [13.1775, 54.2662],
              [13.1601, 54.2668],
              [13.1431, 54.2565],
              [13.1601, 54.2587],
              [13.1636, 54.2639],
              [13.19, 54.2647],
              [13.1913, 54.2586],
              [13.2065, 54.2553],
              [13.2168, 54.2476],
              [13.218, 54.2399],
              [13.2593, 54.241],
              [13.2894, 54.2348],
              [13.2968, 54.2251],
              [13.308, 54.2018],
              [13.3286, 54.183],
              [13.3563, 54.1821],
              [13.3399, 54.1776],
              [13.3293, 54.1672],
              [13.3444, 54.1649],
              [13.356, 54.1678],
              [13.3729, 54.1758],
              [13.3825, 54.1764],
              [13.3918, 54.1712],
              [13.3992, 54.1772],
              [13.4111, 54.1768],
              [13.422, 54.1709],
              [13.4205, 54.1662],
              [13.3963, 54.1598],
              [13.3799, 54.1519],
              [13.3817, 54.1447],
              [13.3876, 54.1429],
              [13.4069, 54.1468],
              [13.426, 54.1392],
              [13.4396, 54.1228],
              [13.4398, 54.111],
              [13.4491, 54.1017],
              [13.4574, 54.0973],
              [13.4584, 54.0921],
              [13.4704, 54.0865],
              [13.4898, 54.0834],
              [13.5006, 54.0863],
              [13.4999, 54.0905],
              [13.4864, 54.101],
              [13.484, 54.1109],
              [13.4776, 54.119],
              [13.4906, 54.1247],
              [13.5287, 54.1312],
              [13.562, 54.1307],
              [13.5781, 54.1324],
              [13.6249, 54.1413],
              [13.6327, 54.1439],
              [13.6637, 54.1592],
              [13.6968, 54.1729],
              [13.7053, 54.1725],
              [13.7168, 54.1648],
              [13.7089, 54.1599],
              [13.696, 54.1581],
              [13.7003, 54.1485],
              [13.7223, 54.1428],
              [13.7329, 54.1366],
              [13.7455, 54.135],
              [13.7577, 54.1298],
              [13.7522, 54.1239],
              [13.7619, 54.1199],
              [13.7746, 54.1101],
              [13.7841, 54.1066],
              [13.7931, 54.1068],
              [13.8021, 54.0989],
              [13.7945, 54.0899],
              [13.7938, 54.0783],
              [13.7889, 54.0699],
              [13.7743, 54.0595],
              [13.7802, 54.0537],
              [13.7802, 54.0473],
              [13.7593, 54.0351],
              [13.7428, 54.0321],
              [13.7502, 54.0259],
              [13.7565, 54.0283],
              [13.7603, 54.0155],
              [13.7752, 54.0093],
              [13.7831, 54.0022],
              [13.7985, 53.9951],
              [13.8105, 53.9953],
              [13.8222, 53.9881],
              [13.8331, 53.985],
              [13.8444, 53.9715],
              [13.8537, 53.9642],
              [13.8518, 53.9551],
              [13.8567, 53.9497],
              [13.8723, 53.9523],
              [13.8837, 53.9451],
              [13.8945, 53.9427],
              [13.8982, 53.9368],
              [13.9077, 53.9308],
              [13.9136, 53.9191],
              [13.902, 53.91],
              [13.8906, 53.91],
              [13.8646, 53.9001],
              [13.8505, 53.8873],
              [13.8432, 53.8777],
              [13.8243, 53.877],
              [13.8253, 53.8663],
              [13.815, 53.8635],
              [13.8073, 53.8554],
              [13.8155, 53.8526],
              [13.8138, 53.8479],
              [13.8214, 53.8432],
              [13.8358, 53.8446],
              [13.8521, 53.8424],
              [13.8722, 53.8312],
              [13.8734, 53.8222],
              [13.8881, 53.8139],
              [13.8991, 53.8034],
              [13.9067, 53.8019],
              [13.9142, 53.8059],
              [13.9224, 53.7968],
              [13.9337, 53.7969],
              [13.9367, 53.7915],
              [13.9692, 53.7729],
              [13.9969, 53.7665],
              [14.0049, 53.7671],
              [14.0496, 53.7529],
              [14.0604, 53.7553],
              [14.074, 53.748],
              [14.1238, 53.7379],
              [14.1474, 53.7415],
              [14.1669, 53.7425],
              [14.1727, 53.7371],
              [14.1822, 53.7359],
              [14.192, 53.739],
              [14.22, 53.7535],
              [14.2253, 53.7588],
              [14.2367, 53.7616],
              [14.2507, 53.758],
              [14.2669, 53.749],
              [14.2712, 53.7405],
              [14.2659, 53.731],
              [14.2588, 53.7293],
              [14.2493, 53.7193],
              [14.2275, 53.7107],
              [14.2184, 53.7091],
              [14.2176, 53.701],
              [14.2456, 53.6944],
              [14.259, 53.6981],
              [14.2722, 53.6968],
              [14.28, 53.6873],
              [14.2812, 53.6771],
              [14.2699, 53.6687],
              [14.2841, 53.6575],
              [14.284, 53.6349],
              [14.3084, 53.6204],
              [14.3168, 53.6178],
              [14.3114, 53.5805],
              [14.3149, 53.5668],
              [14.3039, 53.5546],
              [14.3073, 53.5442],
              [14.319, 53.5316],
              [14.3172, 53.5235],
              [14.3297, 53.5031],
              [14.3501, 53.4972],
              [14.3537, 53.4921],
              [14.3579, 53.4603],
              [14.3693, 53.455],
              [14.374, 53.4451],
              [14.3705, 53.428],
              [14.3751, 53.4192],
              [14.3742, 53.4101],
              [14.3795, 53.4004],
              [14.3882, 53.3903],
              [14.3957, 53.3746],
              [14.3944, 53.3526],
              [14.4061, 53.347],
              [14.4119, 53.3306],
            ],
          ],
          [
            [
              [13.8089, 54.1672],
              [13.8147, 54.1644],
              [13.817, 54.1541],
              [13.8277, 54.1378],
              [13.8394, 54.1247],
              [13.8573, 54.1108],
              [13.8742, 54.1],
              [13.8959, 54.0891],
              [13.9377, 54.0748],
              [13.9669, 54.0705],
              [13.9906, 54.0632],
              [14.0177, 54.0521],
              [14.047, 54.0317],
              [14.0765, 54.0141],
              [14.1147, 53.9939],
              [14.1382, 53.9799],
              [14.1764, 53.9522],
              [14.1904, 53.9436],
              [14.2143, 53.9325],
              [14.2273, 53.9282],
              [14.2071, 53.916],
              [14.2044, 53.9108],
              [14.2146, 53.9007],
              [14.2125, 53.874],
              [14.2065, 53.8674],
              [14.1884, 53.8732],
              [14.1356, 53.8709],
              [14.1269, 53.872],
              [14.1143, 53.8696],
              [14.1007, 53.8707],
              [14.0845, 53.875],
              [14.0655, 53.8696],
              [14.043, 53.8741],
              [14.0325, 53.8729],
              [14.0169, 53.8677],
              [14.0053, 53.8575],
              [13.9973, 53.8547],
              [13.9914, 53.8486],
              [13.9731, 53.8495],
              [13.9605, 53.8461],
              [13.9405, 53.8439],
              [13.9237, 53.8457],
              [13.9518, 53.8523],
              [13.954, 53.8588],
              [13.9482, 53.8641],
              [13.9276, 53.8696],
              [13.9268, 53.8616],
              [13.9152, 53.8445],
              [13.8998, 53.8406],
              [13.8644, 53.8435],
              [13.8479, 53.8504],
              [13.8264, 53.8505],
              [13.8214, 53.8563],
              [13.8296, 53.8583],
              [13.8298, 53.8666],
              [13.8367, 53.8719],
              [13.8555, 53.8707],
              [13.8742, 53.878],
              [13.8809, 53.8767],
              [13.8978, 53.8788],
              [13.9028, 53.8857],
              [13.9223, 53.8888],
              [13.9303, 53.8981],
              [13.9379, 53.9139],
              [13.9325, 53.9198],
              [13.9312, 53.9362],
              [13.9243, 53.9423],
              [13.9179, 53.9534],
              [13.8992, 53.9619],
              [13.8963, 53.9695],
              [13.9026, 53.9847],
              [13.9135, 53.9904],
              [13.9328, 53.9902],
              [13.9444, 53.9939],
              [13.9626, 53.99],
              [13.9655, 53.9779],
              [13.9701, 53.9713],
              [13.9536, 53.9598],
              [13.9567, 53.9514],
              [13.9529, 53.9481],
              [13.9542, 53.9363],
              [13.9676, 53.9488],
              [13.9684, 53.9581],
              [13.983, 53.9618],
              [13.9982, 53.9619],
              [14.0073, 53.9595],
              [14.0105, 53.9511],
              [14.0197, 53.9461],
              [14.0403, 53.9423],
              [14.0353, 53.9506],
              [14.0426, 53.9624],
              [14.0417, 53.9668],
              [14.047, 53.9795],
              [14.0463, 53.9973],
              [14.0399, 54.0119],
              [14.0311, 54.0173],
              [14.0195, 54.0179],
              [14.0114, 54.0098],
              [14.0045, 54.0139],
              [14.0056, 54.0222],
              [13.995, 54.0432],
              [13.9813, 54.0502],
              [13.9683, 54.06],
              [13.9469, 54.0596],
              [13.942, 54.0617],
              [13.9168, 54.063],
              [13.9094, 54.0481],
              [13.9284, 54.0344],
              [13.919, 54.034],
              [13.9072, 54.0246],
              [13.8971, 54.0121],
              [13.8755, 54.0097],
              [13.8553, 54.0017],
              [13.851, 54.0082],
              [13.8527, 54.0143],
              [13.8792, 54.0389],
              [13.856, 54.0484],
              [13.8469, 54.0436],
              [13.8228, 54.038],
              [13.8111, 54.0319],
              [13.7988, 54.0191],
              [13.7868, 54.0182],
              [13.7683, 54.0198],
              [13.765, 54.031],
              [13.7756, 54.0413],
              [13.7834, 54.0452],
              [13.7967, 54.0619],
              [13.8005, 54.0735],
              [13.8075, 54.0811],
              [13.8071, 54.0884],
              [13.8118, 54.0983],
              [13.8062, 54.1085],
              [13.7888, 54.1128],
              [13.7757, 54.1214],
              [13.7706, 54.1336],
              [13.7549, 54.1377],
              [13.7506, 54.1517],
              [13.752, 54.1586],
              [13.7584, 54.1668],
              [13.7727, 54.1716],
              [13.7962, 54.1744],
              [13.8089, 54.1672],
            ],
          ],
          [
            [
              [12.7039, 54.4286],
              [12.7149, 54.4248],
              [12.7239, 54.4251],
              [12.7291, 54.4154],
              [12.691, 54.4182],
              [12.6815, 54.4147],
              [12.6772, 54.4224],
              [12.6904, 54.4254],
              [12.6917, 54.4309],
              [12.7039, 54.4286],
            ],
          ],
          [
            [
              [14.2676, 53.7094],
              [14.268, 53.7056],
              [14.2511, 53.7036],
              [14.251, 53.7099],
              [14.2676, 53.7094],
            ],
          ],
          [
            [
              [13.5265, 54.3162],
              [13.5305, 54.3263],
              [13.5416, 54.3303],
              [13.544, 54.3227],
              [13.5265, 54.3162],
            ],
          ],
          [
            [
              [13.1305, 54.3078],
              [13.1205, 54.302],
              [13.1127, 54.3071],
              [13.1125, 54.3122],
              [13.1251, 54.3158],
              [13.1305, 54.3078],
            ],
          ],
        ],
      },
      properties: {
        name: 'Mecklenburg-Western Pomerania',
        id: 'DE-MV',
        CNTRY: 'Germany',
        TYPE: 'State',
      },
      id: 'DE-MV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [8.3744, 53.8281],
              [8.3662, 53.8252],
              [8.3438, 53.8249],
              [8.3443, 53.8328],
              [8.3493, 53.837],
              [8.3671, 53.8413],
              [8.3799, 53.8408],
              [8.3744, 53.8281],
            ],
          ],
          [
            [
              [8.1706, 53.7315],
              [8.1882, 53.7287],
              [8.1922, 53.7244],
              [8.1664, 53.7228],
              [8.1537, 53.7113],
              [8.1384, 53.7144],
              [8.1296, 53.7088],
              [8.1195, 53.7145],
              [8.124, 53.7217],
              [8.1416, 53.7298],
              [8.1706, 53.7315],
            ],
          ],
          [
            [
              [6.9078, 53.6497],
              [6.8959, 53.6467],
              [6.9032, 53.6399],
              [6.8998, 53.6345],
              [6.8897, 53.634],
              [6.8874, 53.6248],
              [6.8743, 53.6292],
              [6.8644, 53.6409],
              [6.8695, 53.6467],
              [6.9043, 53.6525],
              [6.9078, 53.6497],
            ],
          ],
          [
            [
              [7.4009, 53.7353],
              [7.4331, 53.7318],
              [7.4315, 53.7216],
              [7.4158, 53.719],
              [7.3883, 53.7195],
              [7.3665, 53.7219],
              [7.3599, 53.7272],
              [7.3851, 53.7347],
              [7.4009, 53.7353],
            ],
          ],
          [
            [
              [7.079, 53.6869],
              [7.0938, 53.6852],
              [7.0981, 53.6808],
              [7.0794, 53.679],
              [7.0214, 53.6761],
              [7.0029, 53.6728],
              [6.9798, 53.6742],
              [6.9647, 53.6694],
              [6.9566, 53.6726],
              [6.9493, 53.6686],
              [6.9104, 53.6665],
              [6.9005, 53.6644],
              [6.8689, 53.6644],
              [6.8673, 53.6706],
              [6.8815, 53.6707],
              [6.9167, 53.6751],
              [6.9735, 53.6797],
              [7.031, 53.6852],
              [7.0604, 53.687],
              [7.079, 53.6869],
            ],
          ],
          [
            [
              [8.5029, 53.9279],
              [8.5187, 53.9224],
              [8.517, 53.9162],
              [8.5006, 53.9116],
              [8.4868, 53.913],
              [8.4889, 53.9263],
              [8.4977, 53.9316],
              [8.5029, 53.9279],
            ],
          ],
          [
            [
              [8.0116, 53.7613],
              [8.0227, 53.757],
              [8.0231, 53.7464],
              [8.0186, 53.7444],
              [8.005, 53.7513],
              [8.0055, 53.7583],
              [8.0116, 53.7613],
            ],
          ],
          [
            [
              [7.8956, 53.795],
              [7.9362, 53.7891],
              [7.9522, 53.7825],
              [7.9359, 53.7796],
              [7.9074, 53.7811],
              [7.8761, 53.7879],
              [7.8698, 53.7829],
              [7.8688, 53.7738],
              [7.8485, 53.7838],
              [7.8468, 53.7889],
              [7.8626, 53.7941],
              [7.8956, 53.795],
            ],
          ],
          [
            [
              [9.0042, 53.8744],
              [9.0292, 53.8801],
              [9.0516, 53.8809],
              [9.0931, 53.8799],
              [9.1449, 53.8776],
              [9.1669, 53.8797],
              [9.2091, 53.8796],
              [9.2301, 53.8773],
              [9.2339, 53.875],
              [9.2595, 53.871],
              [9.2939, 53.8619],
              [9.3197, 53.8506],
              [9.3364, 53.8349],
              [9.3547, 53.8212],
              [9.3716, 53.8043],
              [9.4056, 53.7606],
              [9.4199, 53.7439],
              [9.4429, 53.7269],
              [9.4747, 53.713],
              [9.4979, 53.7006],
              [9.5144, 53.6836],
              [9.5309, 53.6553],
              [9.5321, 53.6432],
              [9.537, 53.6317],
              [9.5498, 53.6173],
              [9.5783, 53.6047],
              [9.6041, 53.5922],
              [9.6337, 53.5817],
              [9.6729, 53.5653],
              [9.7013, 53.5587],
              [9.7299, 53.557],
              [9.7743, 53.5537],
              [9.7724, 53.5436],
              [9.7652, 53.5409],
              [9.7746, 53.5277],
              [9.7629, 53.509],
              [9.7682, 53.5037],
              [9.7852, 53.4995],
              [9.7827, 53.4906],
              [9.7928, 53.4931],
              [9.8039, 53.4922],
              [9.8007, 53.469],
              [9.807, 53.4631],
              [9.8162, 53.4606],
              [9.838, 53.4493],
              [9.8531, 53.4369],
              [9.8737, 53.442],
              [9.8886, 53.4507],
              [9.8934, 53.4559],
              [9.9036, 53.4561],
              [9.9134, 53.4469],
              [9.9208, 53.4355],
              [9.9142, 53.4299],
              [9.9156, 53.4237],
              [9.9067, 53.4159],
              [9.9154, 53.4139],
              [9.9275, 53.4199],
              [9.9419, 53.4212],
              [9.9529, 53.4274],
              [9.9705, 53.4208],
              [9.9744, 53.4144],
              [9.985, 53.4162],
              [9.9986, 53.4255],
              [10.0162, 53.4281],
              [10.0304, 53.4349],
              [10.0155, 53.4416],
              [10.0201, 53.4467],
              [10.0319, 53.4442],
              [10.0428, 53.4462],
              [10.044, 53.4561],
              [10.054, 53.4621],
              [10.0663, 53.4562],
              [10.0812, 53.4528],
              [10.0982, 53.4399],
              [10.1061, 53.4283],
              [10.112, 53.4251],
              [10.1331, 53.4231],
              [10.1445, 53.4168],
              [10.1522, 53.4077],
              [10.1641, 53.3996],
              [10.1747, 53.3962],
              [10.2004, 53.3986],
              [10.221, 53.3954],
              [10.2343, 53.3949],
              [10.2446, 53.3996],
              [10.2549, 53.4141],
              [10.2638, 53.4201],
              [10.2843, 53.4243],
              [10.3085, 53.4335],
              [10.3311, 53.4249],
              [10.3658, 53.4266],
              [10.3781, 53.424],
              [10.3959, 53.4122],
              [10.4384, 53.3941],
              [10.4642, 53.3874],
              [10.4941, 53.3758],
              [10.522, 53.3703],
              [10.5561, 53.3687],
              [10.5663, 53.3626],
              [10.58, 53.3599],
              [10.5953, 53.3642],
              [10.6077, 53.368],
              [10.6381, 53.3709],
              [10.6583, 53.3677],
              [10.6818, 53.3677],
              [10.6997, 53.3706],
              [10.7273, 53.358],
              [10.7371, 53.3447],
              [10.7647, 53.3379],
              [10.758, 53.3294],
              [10.7816, 53.3217],
              [10.7971, 53.314],
              [10.8281, 53.312],
              [10.8314, 53.3052],
              [10.8522, 53.3093],
              [10.8573, 53.3155],
              [10.8709, 53.3163],
              [10.8726, 53.3237],
              [10.882, 53.3224],
              [10.888, 53.3257],
              [10.8819, 53.3321],
              [10.8949, 53.3361],
              [10.909, 53.3374],
              [10.9181, 53.3485],
              [10.9278, 53.3482],
              [10.9369, 53.3394],
              [10.9557, 53.3333],
              [10.9594, 53.3376],
              [10.9874, 53.3336],
              [11.0036, 53.3103],
              [11.0022, 53.3049],
              [11.0085, 53.2992],
              [11.0081, 53.2842],
              [11.013, 53.2799],
              [11.0276, 53.2795],
              [11.0356, 53.2713],
              [11.0387, 53.2615],
              [11.0531, 53.2515],
              [11.0609, 53.2378],
              [11.0737, 53.2255],
              [11.0873, 53.2186],
              [11.0893, 53.2139],
              [11.1199, 53.2039],
              [11.1392, 53.2013],
              [11.1465, 53.1974],
              [11.1375, 53.1929],
              [11.1711, 53.1864],
              [11.1778, 53.1882],
              [11.19, 53.1844],
              [11.1975, 53.1785],
              [11.1895, 53.1671],
              [11.174, 53.1577],
              [11.1799, 53.1482],
              [11.1836, 53.1368],
              [11.1932, 53.1355],
              [11.2111, 53.144],
              [11.2251, 53.1441],
              [11.2442, 53.1386],
              [11.2669, 53.1224],
              [11.2696, 53.1052],
              [11.2791, 53.0935],
              [11.2957, 53.0837],
              [11.3105, 53.0783],
              [11.3346, 53.0578],
              [11.353, 53.0565],
              [11.378, 53.0646],
              [11.4002, 53.0733],
              [11.4257, 53.0762],
              [11.4402, 53.0794],
              [11.4527, 53.0793],
              [11.4632, 53.0758],
              [11.5011, 53.0513],
              [11.5175, 53.047],
              [11.5274, 53.0475],
              [11.5414, 53.0537],
              [11.5506, 53.0528],
              [11.568, 53.0407],
              [11.5884, 53.0353],
              [11.5986, 53.0364],
              [11.5971, 53.0338],
              [11.5595, 53.0126],
              [11.5535, 53.0064],
              [11.5562, 52.9996],
              [11.5442, 53],
              [11.5257, 53.0085],
              [11.5159, 53.0073],
              [11.509, 53.0027],
              [11.503, 52.9906],
              [11.5008, 52.9809],
              [11.4927, 52.9599],
              [11.503, 52.9433],
              [11.4985, 52.9402],
              [11.4861, 52.9403],
              [11.4674, 52.9378],
              [11.4512, 52.9323],
              [11.4309, 52.9199],
              [11.4245, 52.9195],
              [11.4049, 52.905],
              [11.3747, 52.8993],
              [11.3467, 52.8898],
              [11.3356, 52.8891],
              [11.3331, 52.8853],
              [11.3134, 52.8784],
              [11.2953, 52.875],
              [11.2913, 52.8896],
              [11.2767, 52.888],
              [11.2669, 52.8796],
              [11.2396, 52.8801],
              [11.235, 52.8898],
              [11.2219, 52.894],
              [11.2215, 52.8977],
              [11.1966, 52.9004],
              [11.1857, 52.8999],
              [11.1565, 52.9049],
              [11.1523, 52.9018],
              [11.1338, 52.8983],
              [11.1113, 52.8964],
              [11.0951, 52.8993],
              [11.0969, 52.9095],
              [11.0906, 52.9137],
              [11.0777, 52.9129],
              [11.0673, 52.9089],
              [11.0455, 52.9124],
              [11.0311, 52.9131],
              [11.012, 52.9101],
              [11.0058, 52.9115],
              [10.9921, 52.9077],
              [10.9878, 52.8846],
              [10.9757, 52.8771],
              [10.9675, 52.8784],
              [10.9641, 52.8717],
              [10.9517, 52.8638],
              [10.9447, 52.8558],
              [10.9135, 52.8527],
              [10.8976, 52.8559],
              [10.8923, 52.8467],
              [10.8819, 52.8459],
              [10.873, 52.8544],
              [10.8604, 52.8519],
              [10.8443, 52.8519],
              [10.832, 52.847],
              [10.8012, 52.8506],
              [10.7974, 52.8442],
              [10.7676, 52.8424],
              [10.7722, 52.8345],
              [10.7644, 52.8303],
              [10.7673, 52.8255],
              [10.7586, 52.8199],
              [10.7627, 52.8011],
              [10.7566, 52.7836],
              [10.7659, 52.7712],
              [10.7771, 52.7621],
              [10.7784, 52.7539],
              [10.7882, 52.7526],
              [10.7933, 52.7475],
              [10.7888, 52.7327],
              [10.7936, 52.7279],
              [10.7987, 52.7137],
              [10.8231, 52.7171],
              [10.829, 52.7162],
              [10.8367, 52.7077],
              [10.8355, 52.6983],
              [10.8496, 52.6864],
              [10.8525, 52.6807],
              [10.8777, 52.6644],
              [10.8839, 52.6493],
              [10.8962, 52.6444],
              [10.903, 52.6356],
              [10.9051, 52.6276],
              [10.9275, 52.6088],
              [10.9428, 52.6151],
              [10.9466, 52.6205],
              [10.9627, 52.6256],
              [10.9765, 52.6233],
              [10.9745, 52.6063],
              [10.9552, 52.5948],
              [10.9399, 52.589],
              [10.9459, 52.5836],
              [10.9411, 52.5758],
              [10.9436, 52.5698],
              [10.9378, 52.5671],
              [10.9426, 52.5542],
              [10.9483, 52.5466],
              [10.9742, 52.5226],
              [11.0095, 52.4957],
              [10.9848, 52.4957],
              [10.9766, 52.5006],
              [10.9442, 52.4922],
              [10.9404, 52.476],
              [10.9326, 52.4757],
              [10.9357, 52.4592],
              [10.9464, 52.4544],
              [10.9496, 52.4483],
              [10.9568, 52.4446],
              [10.9629, 52.4342],
              [10.9731, 52.4308],
              [10.9868, 52.4227],
              [10.9932, 52.4137],
              [11.0047, 52.4096],
              [11.0072, 52.404],
              [11.0158, 52.3998],
              [11.0208, 52.3918],
              [11.0337, 52.3884],
              [11.0474, 52.3872],
              [11.0534, 52.3817],
              [11.0644, 52.3777],
              [11.0692, 52.357],
              [11.0565, 52.3493],
              [11.0444, 52.3487],
              [11.0365, 52.3438],
              [11.0209, 52.3471],
              [10.9973, 52.3421],
              [10.9836, 52.3411],
              [10.9879, 52.3349],
              [11.002, 52.3359],
              [11.0069, 52.327],
              [11.0127, 52.3257],
              [11.0212, 52.318],
              [11.0363, 52.3099],
              [11.0369, 52.3013],
              [11.0226, 52.2966],
              [11.012, 52.2879],
              [11.0214, 52.2807],
              [11.0281, 52.2704],
              [11.0414, 52.2722],
              [11.0525, 52.2665],
              [11.0557, 52.2569],
              [11.0527, 52.2526],
              [11.0587, 52.2412],
              [11.0737, 52.2426],
              [11.0855, 52.2286],
              [11.0755, 52.2196],
              [11.0294, 52.2114],
              [11.0218, 52.2069],
              [11.0227, 52.2001],
              [11.0135, 52.1982],
              [11.0142, 52.1814],
              [11.0342, 52.1721],
              [11.0601, 52.1728],
              [11.0622, 52.1643],
              [11.0568, 52.1601],
              [11.0594, 52.1529],
              [11.0456, 52.144],
              [11.045, 52.1341],
              [11.0363, 52.1341],
              [11.0258, 52.1287],
              [11.0164, 52.1276],
              [11.0107, 52.1218],
              [10.9874, 52.1122],
              [10.9781, 52.1054],
              [10.9592, 52.1059],
              [10.9435, 52.1031],
              [10.9391, 52.0957],
              [10.9428, 52.0928],
              [10.9719, 52.0859],
              [10.9744, 52.073],
              [10.9659, 52.0568],
              [10.9386, 52.0604],
              [10.8995, 52.0589],
              [10.8835, 52.0599],
              [10.8562, 52.05],
              [10.8295, 52.0467],
              [10.8208, 52.049],
              [10.8022, 52.0484],
              [10.7789, 52.0508],
              [10.7609, 52.0452],
              [10.7586, 52.0476],
              [10.7054, 52.0505],
              [10.666, 52.0431],
              [10.6489, 52.0412],
              [10.6562, 52.026],
              [10.6413, 52.0226],
              [10.6362, 52.0142],
              [10.6021, 52.007],
              [10.5969, 52.0134],
              [10.5758, 52.0113],
              [10.5625, 52.0031],
              [10.5762, 51.9926],
              [10.5859, 51.9827],
              [10.5853, 51.9746],
              [10.6063, 51.9773],
              [10.6074, 51.9689],
              [10.6199, 51.967],
              [10.6216, 51.9579],
              [10.6426, 51.9624],
              [10.6502, 51.9586],
              [10.6477, 51.9537],
              [10.6243, 51.9488],
              [10.6138, 51.9353],
              [10.6164, 51.9282],
              [10.6145, 51.921],
              [10.6403, 51.9151],
              [10.649, 51.9087],
              [10.6533, 51.8981],
              [10.6485, 51.8886],
              [10.6347, 51.8761],
              [10.6333, 51.8712],
              [10.6168, 51.8695],
              [10.6053, 51.8612],
              [10.6025, 51.8546],
              [10.5822, 51.852],
              [10.5746, 51.8405],
              [10.585, 51.8407],
              [10.5866, 51.8344],
              [10.5783, 51.8294],
              [10.5747, 51.8112],
              [10.5811, 51.803],
              [10.5867, 51.7871],
              [10.5797, 51.7821],
              [10.591, 51.7688],
              [10.6048, 51.7627],
              [10.6234, 51.7596],
              [10.6318, 51.751],
              [10.6332, 51.735],
              [10.6419, 51.7327],
              [10.6427, 51.7253],
              [10.6495, 51.719],
              [10.6568, 51.7186],
              [10.6694, 51.71],
              [10.6729, 51.6994],
              [10.6637, 51.6913],
              [10.67, 51.6681],
              [10.6822, 51.6606],
              [10.6917, 51.646],
              [10.7007, 51.6417],
              [10.6955, 51.6392],
              [10.6754, 51.6376],
              [10.671, 51.6289],
              [10.647, 51.622],
              [10.6395, 51.6157],
              [10.6447, 51.6075],
              [10.6354, 51.6059],
              [10.6326, 51.5965],
              [10.6386, 51.5845],
              [10.6558, 51.5822],
              [10.6653, 51.5759],
              [10.6582, 51.5674],
              [10.6564, 51.5587],
              [10.6365, 51.5619],
              [10.6254, 51.568],
              [10.6275, 51.5725],
              [10.6117, 51.5792],
              [10.6003, 51.579],
              [10.5869, 51.5663],
              [10.5778, 51.5655],
              [10.5607, 51.5563],
              [10.5468, 51.5553],
              [10.5336, 51.5597],
              [10.5189, 51.5541],
              [10.51, 51.5567],
              [10.4947, 51.5731],
              [10.4725, 51.582],
              [10.4505, 51.5845],
              [10.4456, 51.5892],
              [10.4328, 51.5923],
              [10.4054, 51.5868],
              [10.3893, 51.5862],
              [10.3776, 51.5888],
              [10.3692, 51.5866],
              [10.3784, 51.5792],
              [10.3801, 51.563],
              [10.3729, 51.5572],
              [10.3664, 51.5471],
              [10.3676, 51.5432],
              [10.3524, 51.5351],
              [10.3449, 51.5263],
              [10.3425, 51.5189],
              [10.3183, 51.5136],
              [10.3073, 51.5145],
              [10.3003, 51.5057],
              [10.3005, 51.4959],
              [10.2729, 51.4911],
              [10.2736, 51.488],
              [10.2458, 51.4834],
              [10.2379, 51.4777],
              [10.2354, 51.4715],
              [10.216, 51.4728],
              [10.2167, 51.4781],
              [10.2066, 51.4872],
              [10.1901, 51.4807],
              [10.1795, 51.4674],
              [10.1866, 51.4634],
              [10.1787, 51.458],
              [10.1747, 51.4471],
              [10.1434, 51.4453],
              [10.1421, 51.4377],
              [10.1536, 51.4324],
              [10.1411, 51.4299],
              [10.131, 51.4348],
              [10.1109, 51.4308],
              [10.1055, 51.4339],
              [10.0909, 51.4361],
              [10.0803, 51.435],
              [10.0784, 51.4248],
              [10.072, 51.4183],
              [10.0597, 51.4273],
              [10.0593, 51.4354],
              [10.0486, 51.4356],
              [10.0383, 51.4208],
              [10.0104, 51.417],
              [10.0082, 51.4103],
              [10.0006, 51.4048],
              [9.9902, 51.4026],
              [9.987, 51.3983],
              [9.9665, 51.3962],
              [9.9543, 51.3898],
              [9.9438, 51.3789],
              [9.928, 51.3753],
              [9.931, 51.3805],
              [9.9305, 51.3929],
              [9.9225, 51.4008],
              [9.9148, 51.402],
              [9.9086, 51.409],
              [9.9132, 51.4123],
              [9.8973, 51.4178],
              [9.8824, 51.4148],
              [9.8803, 51.4057],
              [9.8718, 51.4118],
              [9.8519, 51.4148],
              [9.8503, 51.4023],
              [9.8599, 51.3947],
              [9.855, 51.3857],
              [9.8618, 51.3713],
              [9.8522, 51.3727],
              [9.8401, 51.3836],
              [9.8391, 51.4024],
              [9.8292, 51.4051],
              [9.8187, 51.4118],
              [9.7957, 51.408],
              [9.7999, 51.4003],
              [9.8014, 51.3879],
              [9.7927, 51.3893],
              [9.7711, 51.3881],
              [9.7566, 51.3824],
              [9.751, 51.3751],
              [9.7372, 51.369],
              [9.7215, 51.3706],
              [9.7034, 51.3652],
              [9.702, 51.3608],
              [9.7126, 51.3556],
              [9.736, 51.3293],
              [9.7365, 51.3219],
              [9.7469, 51.3201],
              [9.7553, 51.3277],
              [9.7661, 51.3319],
              [9.7736, 51.3282],
              [9.7681, 51.3067],
              [9.7565, 51.3041],
              [9.7339, 51.2954],
              [9.7245, 51.299],
              [9.6793, 51.3102],
              [9.6764, 51.3155],
              [9.6661, 51.3123],
              [9.6562, 51.3125],
              [9.6439, 51.3178],
              [9.6342, 51.3118],
              [9.6289, 51.3128],
              [9.6299, 51.3264],
              [9.6181, 51.3275],
              [9.608, 51.325],
              [9.5977, 51.3299],
              [9.5943, 51.3351],
              [9.5784, 51.3403],
              [9.5698, 51.3406],
              [9.5591, 51.3471],
              [9.5493, 51.3589],
              [9.5321, 51.3637],
              [9.5371, 51.3687],
              [9.5531, 51.3609],
              [9.5704, 51.3633],
              [9.5759, 51.3722],
              [9.5606, 51.3747],
              [9.559, 51.3795],
              [9.5636, 51.3869],
              [9.574, 51.3929],
              [9.578, 51.4014],
              [9.5875, 51.4025],
              [9.5978, 51.3994],
              [9.6064, 51.4047],
              [9.6156, 51.4066],
              [9.6286, 51.4053],
              [9.6295, 51.4111],
              [9.6439, 51.4183],
              [9.6321, 51.4295],
              [9.6274, 51.444],
              [9.6299, 51.459],
              [9.6435, 51.4684],
              [9.6386, 51.4735],
              [9.6266, 51.476],
              [9.6108, 51.4895],
              [9.5962, 51.509],
              [9.5856, 51.5168],
              [9.5945, 51.5233],
              [9.6058, 51.5213],
              [9.6149, 51.5259],
              [9.6208, 51.5332],
              [9.6229, 51.5485],
              [9.6465, 51.5537],
              [9.6429, 51.5596],
              [9.6341, 51.5592],
              [9.6247, 51.5727],
              [9.6262, 51.5813],
              [9.6326, 51.5846],
              [9.6496, 51.5797],
              [9.6573, 51.5744],
              [9.6718, 51.5686],
              [9.6843, 51.5674],
              [9.6897, 51.577],
              [9.6798, 51.5869],
              [9.66, 51.5934],
              [9.6497, 51.6051],
              [9.64, 51.6071],
              [9.6363, 51.6114],
              [9.6363, 51.6201],
              [9.6264, 51.6302],
              [9.624, 51.6365],
              [9.6112, 51.6295],
              [9.5849, 51.6253],
              [9.565, 51.6254],
              [9.5557, 51.6358],
              [9.5467, 51.6398],
              [9.5223, 51.6258],
              [9.5098, 51.6264],
              [9.4979, 51.6302],
              [9.4918, 51.6357],
              [9.4986, 51.6509],
              [9.489, 51.6568],
              [9.4619, 51.6531],
              [9.4513, 51.6464],
              [9.4388, 51.6509],
              [9.4249, 51.6498],
              [9.4187, 51.6465],
              [9.399, 51.6475],
              [9.3852, 51.6464],
              [9.375, 51.6498],
              [9.3781, 51.663],
              [9.3835, 51.6691],
              [9.3955, 51.6734],
              [9.3953, 51.679],
              [9.3868, 51.6898],
              [9.3991, 51.6969],
              [9.401, 51.7044],
              [9.3928, 51.7114],
              [9.3872, 51.7224],
              [9.3938, 51.7287],
              [9.3917, 51.7339],
              [9.3788, 51.738],
              [9.3758, 51.7449],
              [9.3844, 51.752],
              [9.3849, 51.7581],
              [9.3959, 51.7583],
              [9.4055, 51.7623],
              [9.4101, 51.7711],
              [9.4466, 51.7949],
              [9.4374, 51.7978],
              [9.4408, 51.8038],
              [9.4305, 51.8055],
              [9.4275, 51.8146],
              [9.44, 51.8242],
              [9.4412, 51.8318],
              [9.4335, 51.841],
              [9.4547, 51.8506],
              [9.4615, 51.8561],
              [9.4589, 51.8614],
              [9.4384, 51.8567],
              [9.4031, 51.8555],
              [9.3936, 51.8597],
              [9.362, 51.8644],
              [9.3506, 51.8617],
              [9.3414, 51.8557],
              [9.338, 51.8615],
              [9.34, 51.8745],
              [9.3441, 51.8806],
              [9.3448, 51.8956],
              [9.3412, 51.9072],
              [9.3313, 51.916],
              [9.3234, 51.9164],
              [9.3152, 51.9209],
              [9.2941, 51.9185],
              [9.2737, 51.9313],
              [9.278, 51.9393],
              [9.2676, 51.9446],
              [9.2713, 51.9549],
              [9.2846, 51.9563],
              [9.2741, 51.9662],
              [9.2746, 51.9722],
              [9.2636, 51.9762],
              [9.2457, 51.9744],
              [9.2381, 51.976],
              [9.2262, 51.9726],
              [9.219, 51.9733],
              [9.2138, 51.9676],
              [9.1966, 51.9615],
              [9.1856, 51.9664],
              [9.187, 51.9719],
              [9.1754, 51.98],
              [9.1825, 51.9914],
              [9.1957, 51.9956],
              [9.1808, 52.0028],
              [9.1961, 52.012],
              [9.1884, 52.0268],
              [9.1755, 52.0345],
              [9.1744, 52.061],
              [9.1881, 52.0713],
              [9.1853, 52.077],
              [9.1755, 52.086],
              [9.155, 52.0914],
              [9.155, 52.0972],
              [9.143, 52.0984],
              [9.136, 52.1052],
              [9.1558, 52.1138],
              [9.1594, 52.1192],
              [9.1564, 52.1255],
              [9.1452, 52.1347],
              [9.1303, 52.1347],
              [9.1166, 52.1376],
              [9.1077, 52.1327],
              [9.0895, 52.1345],
              [9.0827, 52.1411],
              [9.0717, 52.1424],
              [9.0706, 52.1477],
              [9.055, 52.1478],
              [9.0441, 52.1395],
              [9.038, 52.1393],
              [9.0164, 52.1331],
              [9.011, 52.1389],
              [9.0168, 52.1474],
              [9.0125, 52.1568],
              [9.0151, 52.1687],
              [9.0079, 52.1807],
              [8.9926, 52.1884],
              [8.9987, 52.191],
              [9.0174, 52.1872],
              [9.0233, 52.1832],
              [9.0437, 52.1837],
              [9.0424, 52.1902],
              [9.0481, 52.1951],
              [9.042, 52.2026],
              [9.0436, 52.2114],
              [9.0343, 52.2187],
              [9.04, 52.2255],
              [9.0479, 52.2206],
              [9.0737, 52.2281],
              [9.0772, 52.2331],
              [9.0592, 52.2379],
              [9.0486, 52.2437],
              [9.0233, 52.251],
              [9.0062, 52.2526],
              [8.9995, 52.2569],
              [8.9788, 52.2614],
              [8.9728, 52.2667],
              [8.9775, 52.27],
              [8.9659, 52.2798],
              [8.9861, 52.2859],
              [8.9849, 52.2948],
              [8.9902, 52.3037],
              [8.9877, 52.3102],
              [8.9908, 52.3161],
              [8.998, 52.3184],
              [8.9984, 52.3262],
              [9.0056, 52.3259],
              [9.0124, 52.3314],
              [9.0188, 52.3308],
              [9.0253, 52.337],
              [9.0276, 52.3452],
              [9.0433, 52.341],
              [9.0592, 52.3394],
              [9.0593, 52.3468],
              [9.0731, 52.3486],
              [9.0843, 52.3551],
              [9.0907, 52.3674],
              [9.0987, 52.3716],
              [9.1014, 52.3807],
              [9.0954, 52.3855],
              [9.1135, 52.3951],
              [9.1233, 52.4041],
              [9.1257, 52.4128],
              [9.1242, 52.4219],
              [9.1134, 52.421],
              [9.1105, 52.4353],
              [9.0946, 52.4435],
              [9.1025, 52.4561],
              [9.1249, 52.4676],
              [9.1254, 52.4755],
              [9.1371, 52.4754],
              [9.1326, 52.4842],
              [9.1139, 52.4849],
              [9.1003, 52.4971],
              [9.0907, 52.4953],
              [9.0735, 52.4981],
              [9.0657, 52.4967],
              [9.0544, 52.5004],
              [9.046, 52.4943],
              [9.0433, 52.4823],
              [9.0252, 52.4789],
              [9.0219, 52.4694],
              [9.0171, 52.4672],
              [9.0133, 52.4571],
              [9.0006, 52.4569],
              [8.9913, 52.4512],
              [8.9863, 52.4442],
              [8.9846, 52.4325],
              [8.9775, 52.4266],
              [8.9632, 52.4251],
              [8.9615, 52.4198],
              [8.9482, 52.4159],
              [8.945, 52.4091],
              [8.9329, 52.4012],
              [8.8978, 52.4057],
              [8.891, 52.4041],
              [8.8866, 52.3943],
              [8.8593, 52.3946],
              [8.8553, 52.3893],
              [8.7899, 52.3986],
              [8.7479, 52.3883],
              [8.7253, 52.3992],
              [8.7146, 52.3928],
              [8.7066, 52.3954],
              [8.7103, 52.4072],
              [8.7035, 52.414],
              [8.7083, 52.4241],
              [8.717, 52.4295],
              [8.7057, 52.4372],
              [8.7038, 52.4428],
              [8.7049, 52.4565],
              [8.7107, 52.4621],
              [8.7097, 52.4763],
              [8.6988, 52.4854],
              [8.7026, 52.5007],
              [8.6956, 52.5097],
              [8.6851, 52.5124],
              [8.6892, 52.5173],
              [8.6707, 52.5169],
              [8.6653, 52.5223],
              [8.6507, 52.531],
              [8.6287, 52.5249],
              [8.5567, 52.4998],
              [8.5422, 52.5017],
              [8.5187, 52.5093],
              [8.5093, 52.5142],
              [8.4877, 52.5031],
              [8.4805, 52.4967],
              [8.4709, 52.4982],
              [8.4583, 52.4917],
              [8.459, 52.4841],
              [8.4549, 52.4609],
              [8.4298, 52.4469],
              [8.4248, 52.4505],
              [8.4168, 52.444],
              [8.3992, 52.4499],
              [8.3848, 52.4441],
              [8.3632, 52.4437],
              [8.3491, 52.4509],
              [8.3332, 52.4531],
              [8.3215, 52.4518],
              [8.3116, 52.4573],
              [8.3029, 52.4581],
              [8.2976, 52.4544],
              [8.3091, 52.435],
              [8.3216, 52.4252],
              [8.3215, 52.4193],
              [8.3136, 52.4065],
              [8.3228, 52.3992],
              [8.342, 52.397],
              [8.372, 52.3871],
              [8.3942, 52.3841],
              [8.4023, 52.3771],
              [8.4372, 52.3617],
              [8.4425, 52.3557],
              [8.4506, 52.3403],
              [8.4536, 52.33],
              [8.4612, 52.3177],
              [8.4701, 52.315],
              [8.4605, 52.295],
              [8.4597, 52.2825],
              [8.4658, 52.2743],
              [8.4653, 52.2669],
              [8.4603, 52.2596],
              [8.4669, 52.2453],
              [8.4586, 52.2425],
              [8.4606, 52.2302],
              [8.4501, 52.2187],
              [8.4424, 52.2145],
              [8.4591, 52.1979],
              [8.4685, 52.1938],
              [8.5048, 52.1889],
              [8.5171, 52.1854],
              [8.5024, 52.1761],
              [8.4912, 52.1657],
              [8.4695, 52.1549],
              [8.4543, 52.1549],
              [8.434, 52.1483],
              [8.4328, 52.146],
              [8.4098, 52.1365],
              [8.4052, 52.1236],
              [8.4102, 52.1204],
              [8.4064, 52.1107],
              [8.3885, 52.1084],
              [8.3722, 52.1102],
              [8.3648, 52.1181],
              [8.3487, 52.1208],
              [8.3434, 52.1238],
              [8.3232, 52.1254],
              [8.3106, 52.1187],
              [8.2982, 52.1215],
              [8.284, 52.1272],
              [8.2797, 52.1326],
              [8.268, 52.1317],
              [8.2636, 52.1216],
              [8.2467, 52.1221],
              [8.23, 52.1096],
              [8.2081, 52.0959],
              [8.1923, 52.073],
              [8.1646, 52.0732],
              [8.1511, 52.0754],
              [8.1336, 52.0717],
              [8.1229, 52.0662],
              [8.105, 52.0616],
              [8.0668, 52.0685],
              [8.0554, 52.0649],
              [8.0382, 52.0641],
              [8.0322, 52.0691],
              [8.0199, 52.0629],
              [8.013, 52.055],
              [7.9951, 52.0516],
              [7.9877, 52.0448],
              [7.9863, 52.0389],
              [7.9788, 52.0366],
              [7.9601, 52.0405],
              [7.9192, 52.051],
              [7.9197, 52.0572],
              [7.9101, 52.0638],
              [7.8857, 52.0854],
              [7.901, 52.0927],
              [7.9548, 52.1033],
              [7.9725, 52.1162],
              [7.9894, 52.1167],
              [8.0081, 52.1154],
              [8.0044, 52.144],
              [7.9996, 52.1503],
              [8.0031, 52.1598],
              [8.0177, 52.1591],
              [8.0265, 52.1627],
              [8.0181, 52.1666],
              [8.015, 52.1721],
              [8.0069, 52.176],
              [7.9894, 52.1744],
              [7.9764, 52.1706],
              [7.9556, 52.1769],
              [7.9369, 52.1761],
              [7.9096, 52.1889],
              [7.9101, 52.1954],
              [7.9042, 52.1983],
              [7.9028, 52.208],
              [7.911, 52.2144],
              [7.9135, 52.222],
              [7.9261, 52.224],
              [7.9286, 52.2306],
              [7.9291, 52.2443],
              [7.9236, 52.2491],
              [7.9286, 52.2647],
              [7.958, 52.267],
              [7.9577, 52.2715],
              [7.94, 52.2831],
              [7.9327, 52.2859],
              [7.9288, 52.2923],
              [7.9351, 52.3022],
              [7.9456, 52.3078],
              [7.9647, 52.3046],
              [7.9909, 52.3035],
              [7.9929, 52.3088],
              [7.9799, 52.3125],
              [7.9616, 52.3235],
              [7.9468, 52.3393],
              [7.9524, 52.3481],
              [7.9415, 52.3496],
              [7.9435, 52.3564],
              [7.9379, 52.3644],
              [7.9292, 52.3639],
              [7.8911, 52.3808],
              [7.8896, 52.3779],
              [7.866, 52.3764],
              [7.8459, 52.3695],
              [7.8083, 52.3719],
              [7.7815, 52.3799],
              [7.7591, 52.3892],
              [7.7256, 52.399],
              [7.7134, 52.4007],
              [7.7028, 52.4328],
              [7.6896, 52.4518],
              [7.6833, 52.456],
              [7.6641, 52.4562],
              [7.6547, 52.4608],
              [7.6046, 52.4744],
              [7.5869, 52.4588],
              [7.5755, 52.4417],
              [7.5723, 52.4313],
              [7.6043, 52.4206],
              [7.602, 52.4138],
              [7.5912, 52.4092],
              [7.5829, 52.4016],
              [7.573, 52.3809],
              [7.5624, 52.3734],
              [7.5601, 52.3688],
              [7.5292, 52.3695],
              [7.514, 52.3605],
              [7.5052, 52.3595],
              [7.4806, 52.347],
              [7.4369, 52.3321],
              [7.4264, 52.3231],
              [7.4271, 52.3136],
              [7.4161, 52.3117],
              [7.4057, 52.3129],
              [7.3983, 52.3064],
              [7.3859, 52.309],
              [7.3695, 52.2976],
              [7.3623, 52.2876],
              [7.3487, 52.2827],
              [7.3158, 52.2797],
              [7.2972, 52.264],
              [7.229, 52.2641],
              [7.156, 52.2664],
              [7.1231, 52.2612],
              [7.1134, 52.2566],
              [7.1087, 52.247],
              [7.1014, 52.2431],
              [7.0637, 52.2417],
              [7.0437, 52.2554],
              [7.0334, 52.2699],
              [7.0277, 52.2736],
              [7.0269, 52.293],
              [7.0381, 52.3058],
              [7.0478, 52.3196],
              [7.0471, 52.3245],
              [7.0542, 52.331],
              [7.056, 52.3378],
              [7.0728, 52.3522],
              [7.0735, 52.3735],
              [7.0637, 52.3917],
              [7.0586, 52.3965],
              [7.0359, 52.403],
              [7.0215, 52.423],
              [7.0103, 52.4292],
              [7.0021, 52.4501],
              [6.9874, 52.4686],
              [6.9783, 52.4646],
              [6.9625, 52.4448],
              [6.9484, 52.4364],
              [6.9416, 52.4349],
              [6.9077, 52.4426],
              [6.8635, 52.4504],
              [6.8532, 52.4491],
              [6.8538, 52.4576],
              [6.8358, 52.4597],
              [6.7768, 52.4584],
              [6.752, 52.4635],
              [6.7256, 52.4759],
              [6.7135, 52.4788],
              [6.6971, 52.4878],
              [6.7045, 52.5208],
              [6.6796, 52.5525],
              [6.7144, 52.5482],
              [6.7199, 52.5522],
              [6.725, 52.5625],
              [6.7527, 52.5597],
              [6.7656, 52.5637],
              [6.719, 52.5883],
              [6.7196, 52.5962],
              [6.7258, 52.6151],
              [6.7137, 52.6262],
              [6.7157, 52.6305],
              [6.7251, 52.6333],
              [6.7411, 52.6452],
              [6.7591, 52.6488],
              [6.7682, 52.6468],
              [6.7772, 52.6519],
              [6.8009, 52.6518],
              [6.8069, 52.6493],
              [6.8242, 52.6483],
              [6.8379, 52.6522],
              [6.8511, 52.6488],
              [6.8658, 52.6501],
              [6.8732, 52.6535],
              [6.8803, 52.6499],
              [6.8961, 52.6509],
              [6.9146, 52.647],
              [6.9175, 52.6409],
              [6.9263, 52.641],
              [6.9361, 52.6375],
              [6.953, 52.6385],
              [6.9596, 52.6426],
              [6.9675, 52.6421],
              [6.9744, 52.6465],
              [6.9915, 52.6412],
              [7.0036, 52.6417],
              [7.0113, 52.6372],
              [7.041, 52.6327],
              [7.0549, 52.6427],
              [7.0541, 52.645],
              [7.0639, 52.7239],
              [7.0718, 52.7983],
              [7.0729, 52.8125],
              [7.0926, 52.8383],
              [7.0876, 52.8496],
              [7.1064, 52.8654],
              [7.1778, 52.9376],
              [7.1846, 52.9482],
              [7.2094, 53.0005],
              [7.2175, 53.0066],
              [7.2124, 53.0145],
              [7.2034, 53.0631],
              [7.1994, 53.0806],
              [7.2015, 53.1139],
              [7.1835, 53.1219],
              [7.1795, 53.1386],
              [7.1893, 53.147],
              [7.1897, 53.1566],
              [7.2033, 53.1744],
              [7.2116, 53.1746],
              [7.2269, 53.1811],
              [7.2085, 53.1892],
              [7.2179, 53.1991],
              [7.2166, 53.217],
              [7.2124, 53.2246],
              [7.2094, 53.2432],
              [7.223, 53.2523],
              [7.2285, 53.2617],
              [7.235, 53.2787],
              [7.2334, 53.2904],
              [7.2289, 53.2929],
              [7.2378, 53.3004],
              [7.2442, 53.3184],
              [7.2616, 53.3219],
              [7.2845, 53.3185],
              [7.3067, 53.3181],
              [7.3085, 53.3215],
              [7.2951, 53.3243],
              [7.2688, 53.3256],
              [7.2613, 53.3292],
              [7.24, 53.3313],
              [7.1689, 53.3351],
              [7.0584, 53.3344],
              [7.0494, 53.3378],
              [7.0253, 53.3367],
              [7.0142, 53.3428],
              [6.9973, 53.3625],
              [6.9971, 53.3723],
              [7.01, 53.3842],
              [7.0064, 53.392],
              [7.0129, 53.4057],
              [7.01, 53.4264],
              [7.0264, 53.4772],
              [7.0257, 53.486],
              [7.0481, 53.506],
              [7.0505, 53.5131],
              [7.0585, 53.5231],
              [7.0411, 53.5371],
              [7.0497, 53.5366],
              [7.0617, 53.5271],
              [7.0722, 53.5216],
              [7.085, 53.523],
              [7.0942, 53.5134],
              [7.1005, 53.5154],
              [7.0992, 53.5231],
              [7.1046, 53.5256],
              [7.1232, 53.5255],
              [7.1388, 53.5355],
              [7.1189, 53.5478],
              [7.1193, 53.5514],
              [7.0968, 53.5634],
              [7.0872, 53.5711],
              [7.0914, 53.581],
              [7.1145, 53.5958],
              [7.1371, 53.6059],
              [7.1485, 53.6143],
              [7.1516, 53.6219],
              [7.1596, 53.6296],
              [7.1742, 53.6321],
              [7.1829, 53.6419],
              [7.2164, 53.6552],
              [7.2274, 53.6621],
              [7.2424, 53.6658],
              [7.2448, 53.6702],
              [7.2667, 53.6744],
              [7.2836, 53.6742],
              [7.2878, 53.6791],
              [7.3251, 53.6842],
              [7.3487, 53.6838],
              [7.3605, 53.6864],
              [7.3747, 53.6812],
              [7.3785, 53.683],
              [7.3958, 53.6821],
              [7.431, 53.6841],
              [7.4812, 53.6832],
              [7.5191, 53.671],
              [7.5512, 53.6757],
              [7.5562, 53.6751],
              [7.5723, 53.6807],
              [7.5851, 53.6796],
              [7.6039, 53.6859],
              [7.6311, 53.6894],
              [7.6633, 53.6952],
              [7.6862, 53.7016],
              [7.7036, 53.7033],
              [7.7119, 53.7008],
              [7.7384, 53.6992],
              [7.7536, 53.7005],
              [7.787, 53.7078],
              [7.7893, 53.7095],
              [7.8134, 53.7117],
              [7.8363, 53.7112],
              [7.8691, 53.714],
              [7.8772, 53.7161],
              [7.9123, 53.7185],
              [7.9306, 53.7162],
              [7.966, 53.7159],
              [8.0148, 53.7118],
              [8.0308, 53.7066],
              [8.033, 53.6995],
              [8.0258, 53.6916],
              [8.025, 53.6815],
              [8.0292, 53.6756],
              [8.0332, 53.6569],
              [8.0441, 53.6423],
              [8.0482, 53.6411],
              [8.0726, 53.6462],
              [8.0885, 53.6423],
              [8.1163, 53.6156],
              [8.1238, 53.5998],
              [8.1415, 53.5809],
              [8.1456, 53.5728],
              [8.158, 53.5604],
              [8.1632, 53.5494],
              [8.1692, 53.5454],
              [8.1587, 53.5309],
              [8.1672, 53.5291],
              [8.1632, 53.5199],
              [8.1545, 53.514],
              [8.1365, 53.5105],
              [8.1042, 53.5021],
              [8.0887, 53.5],
              [8.0763, 53.5005],
              [8.0607, 53.5051],
              [8.0601, 53.4911],
              [8.0636, 53.4761],
              [8.0774, 53.4586],
              [8.0989, 53.4451],
              [8.1169, 53.4516],
              [8.1406, 53.4523],
              [8.1561, 53.4455],
              [8.1878, 53.4193],
              [8.2014, 53.4042],
              [8.2139, 53.4009],
              [8.2454, 53.3977],
              [8.2674, 53.4055],
              [8.2807, 53.4153],
              [8.2965, 53.4308],
              [8.296, 53.4424],
              [8.308, 53.4484],
              [8.3134, 53.4571],
              [8.3155, 53.4761],
              [8.3145, 53.4926],
              [8.3165, 53.5023],
              [8.3152, 53.5144],
              [8.3105, 53.5208],
              [8.2986, 53.5252],
              [8.274, 53.519],
              [8.2634, 53.518],
              [8.2313, 53.5205],
              [8.2349, 53.5334],
              [8.2385, 53.5619],
              [8.2427, 53.5813],
              [8.2509, 53.5916],
              [8.2593, 53.5984],
              [8.2919, 53.6129],
              [8.3064, 53.6161],
              [8.3245, 53.6149],
              [8.3374, 53.6119],
              [8.3497, 53.6063],
              [8.369, 53.5872],
              [8.3834, 53.5755],
              [8.3978, 53.5692],
              [8.4141, 53.5653],
              [8.4369, 53.5619],
              [8.464, 53.5526],
              [8.4751, 53.5531],
              [8.5034, 53.5503],
              [8.5138, 53.5535],
              [8.5221, 53.546],
              [8.5352, 53.5404],
              [8.5463, 53.5392],
              [8.5525, 53.5355],
              [8.5562, 53.5234],
              [8.5479, 53.5201],
              [8.5738, 53.4978],
              [8.5696, 53.4896],
              [8.5776, 53.4854],
              [8.6099, 53.487],
              [8.6331, 53.4955],
              [8.6317, 53.5005],
              [8.651, 53.5145],
              [8.6495, 53.5193],
              [8.6397, 53.526],
              [8.6392, 53.5331],
              [8.6439, 53.5393],
              [8.6457, 53.5551],
              [8.6352, 53.5537],
              [8.6289, 53.557],
              [8.6241, 53.5701],
              [8.6222, 53.5832],
              [8.6394, 53.591],
              [8.6398, 53.5957],
              [8.6517, 53.6021],
              [8.6582, 53.6027],
              [8.6606, 53.6098],
              [8.6444, 53.6103],
              [8.6412, 53.6035],
              [8.6229, 53.606],
              [8.5931, 53.593],
              [8.5811, 53.598],
              [8.5392, 53.6071],
              [8.521, 53.6057],
              [8.5106, 53.6201],
              [8.5034, 53.6268],
              [8.4919, 53.6452],
              [8.4834, 53.6553],
              [8.4821, 53.6945],
              [8.4861, 53.6982],
              [8.5013, 53.7235],
              [8.529, 53.767],
              [8.5391, 53.7882],
              [8.5382, 53.7925],
              [8.5427, 53.8062],
              [8.5665, 53.8453],
              [8.5753, 53.8444],
              [8.5857, 53.8578],
              [8.6042, 53.8716],
              [8.5997, 53.8744],
              [8.6117, 53.8812],
              [8.6211, 53.8822],
              [8.635, 53.888],
              [8.6654, 53.893],
              [8.6809, 53.8939],
              [8.6851, 53.8857],
              [8.6928, 53.8781],
              [8.7048, 53.8764],
              [8.7142, 53.8684],
              [8.714, 53.8639],
              [8.7558, 53.8454],
              [8.7857, 53.8346],
              [8.8257, 53.8313],
              [8.8368, 53.8336],
              [8.8592, 53.8301],
              [8.8931, 53.8288],
              [8.9071, 53.8297],
              [8.9741, 53.84],
              [9.0043, 53.8371],
              [9.02, 53.8415],
              [9.0153, 53.8634],
              [9.0042, 53.8744],
            ],
            [
              [8.5102, 53.1974],
              [8.515, 53.1976],
              [8.529, 53.1897],
              [8.5789, 53.174],
              [8.6089, 53.1705],
              [8.6162, 53.1684],
              [8.6237, 53.1616],
              [8.6257, 53.1464],
              [8.6299, 53.1383],
              [8.6489, 53.1253],
              [8.6524, 53.1097],
              [8.6691, 53.1027],
              [8.6626, 53.0946],
              [8.674, 53.0849],
              [8.6891, 53.0841],
              [8.704, 53.08],
              [8.71, 53.0757],
              [8.7115, 53.0661],
              [8.7049, 53.0543],
              [8.7093, 53.045],
              [8.7178, 53.0391],
              [8.7426, 53.0405],
              [8.7508, 53.0379],
              [8.7622, 53.0452],
              [8.7596, 53.0489],
              [8.7716, 53.053],
              [8.772, 53.044],
              [8.7771, 53.039],
              [8.796, 53.0355],
              [8.8103, 53.0308],
              [8.8216, 53.0199],
              [8.8306, 53.0215],
              [8.8479, 53.0188],
              [8.8648, 53.0213],
              [8.8692, 53.0313],
              [8.8835, 53.0236],
              [8.8966, 53.0129],
              [8.916, 53.0108],
              [8.921, 53.0149],
              [8.9347, 53.0135],
              [8.937, 53.0238],
              [8.9478, 53.0246],
              [8.9502, 53.0307],
              [8.9624, 53.0371],
              [8.97, 53.0382],
              [8.9807, 53.0458],
              [8.9712, 53.0561],
              [8.9647, 53.0594],
              [8.9645, 53.0891],
              [8.9825, 53.0921],
              [8.9732, 53.0999],
              [8.9629, 53.0994],
              [8.958, 53.1114],
              [8.9479, 53.1159],
              [8.964, 53.1213],
              [8.978, 53.1223],
              [8.9831, 53.1262],
              [8.9745, 53.131],
              [8.9557, 53.148],
              [8.9445, 53.152],
              [8.9217, 53.1441],
              [8.9136, 53.1323],
              [8.905, 53.1374],
              [8.898, 53.1336],
              [8.8747, 53.1334],
              [8.8634, 53.1313],
              [8.8616, 53.1344],
              [8.8532, 53.1431],
              [8.8426, 53.1484],
              [8.8405, 53.1564],
              [8.8226, 53.1634],
              [8.8185, 53.1618],
              [8.7836, 53.1634],
              [8.7715, 53.1594],
              [8.7621, 53.1647],
              [8.7555, 53.1634],
              [8.7403, 53.1664],
              [8.743, 53.1713],
              [8.7307, 53.1738],
              [8.7283, 53.1801],
              [8.7126, 53.1832],
              [8.7054, 53.1768],
              [8.6962, 53.1822],
              [8.6841, 53.1789],
              [8.6734, 53.1786],
              [8.6559, 53.1721],
              [8.6233, 53.1719],
              [8.6128, 53.1751],
              [8.6106, 53.1908],
              [8.6012, 53.1877],
              [8.5799, 53.1907],
              [8.5819, 53.2002],
              [8.5934, 53.2055],
              [8.5988, 53.2117],
              [8.5782, 53.2167],
              [8.5714, 53.2106],
              [8.5655, 53.2128],
              [8.5538, 53.2084],
              [8.5419, 53.215],
              [8.53, 53.2159],
              [8.5177, 53.2277],
              [8.4815, 53.2263],
              [8.4925, 53.2124],
              [8.506, 53.2006],
              [8.5102, 53.1974],
            ],
          ],
          [
            [
              [7.578, 53.7589],
              [7.6155, 53.7566],
              [7.6264, 53.7545],
              [7.628, 53.7462],
              [7.6148, 53.7448],
              [7.5706, 53.7462],
              [7.551, 53.7448],
              [7.5371, 53.7418],
              [7.5129, 53.7421],
              [7.5047, 53.737],
              [7.5048, 53.7308],
              [7.4909, 53.7236],
              [7.4833, 53.7257],
              [7.474, 53.72],
              [7.465, 53.7234],
              [7.4625, 53.7352],
              [7.466, 53.7423],
              [7.4793, 53.7538],
              [7.4916, 53.7565],
              [7.578, 53.7589],
            ],
          ],
          [
            [
              [6.813, 53.599],
              [6.7856, 53.5961],
              [6.7745, 53.597],
              [6.7556, 53.5957],
              [6.7459, 53.5907],
              [6.7182, 53.5841],
              [6.7369, 53.5761],
              [6.7414, 53.5711],
              [6.7594, 53.5675],
              [6.7596, 53.5613],
              [6.7523, 53.5573],
              [6.7363, 53.5552],
              [6.7382, 53.566],
              [6.7316, 53.5689],
              [6.7174, 53.5665],
              [6.7181, 53.5618],
              [6.7088, 53.559],
              [6.6986, 53.5668],
              [6.6922, 53.5672],
              [6.6762, 53.5743],
              [6.6632, 53.5776],
              [6.6554, 53.5851],
              [6.6547, 53.5969],
              [6.66, 53.6015],
              [6.69, 53.6039],
              [6.7221, 53.612],
              [6.7316, 53.616],
              [6.7504, 53.6204],
              [6.7625, 53.6193],
              [6.8082, 53.6025],
              [6.813, 53.599],
            ],
          ],
          [
            [
              [6.8236, 53.6488],
              [6.8326, 53.6454],
              [6.8464, 53.6448],
              [6.845, 53.64],
              [6.8234, 53.6394],
              [6.8236, 53.6488],
            ],
          ],
          [
            [
              [7.7228, 53.7817],
              [7.7415, 53.7815],
              [7.7441, 53.7793],
              [7.7845, 53.7799],
              [7.8065, 53.7772],
              [7.8165, 53.7727],
              [7.8033, 53.7685],
              [7.7825, 53.769],
              [7.7425, 53.7595],
              [7.6987, 53.7615],
              [7.6835, 53.7516],
              [7.6717, 53.7527],
              [7.6666, 53.759],
              [7.6705, 53.7661],
              [7.6841, 53.7765],
              [7.7028, 53.7809],
              [7.7228, 53.7817],
            ],
          ],
          [
            [
              [7.3349, 53.7257],
              [7.3454, 53.723],
              [7.3467, 53.7191],
              [7.3284, 53.7126],
              [7.3061, 53.7101],
              [7.2894, 53.7065],
              [7.2619, 53.7038],
              [7.2262, 53.7037],
              [7.2075, 53.7061],
              [7.199, 53.6984],
              [7.1773, 53.7004],
              [7.1712, 53.7069],
              [7.1534, 53.6967],
              [7.1424, 53.7019],
              [7.138, 53.707],
              [7.1478, 53.712],
              [7.167, 53.7181],
              [7.1881, 53.723],
              [7.1982, 53.7238],
              [7.2289, 53.7226],
              [7.2676, 53.7231],
              [7.3163, 53.7258],
              [7.3349, 53.7257],
            ],
          ],
          [
            [
              [8.0154, 53.7713],
              [8.0144, 53.766],
              [8.0061, 53.7639],
              [7.9973, 53.7681],
              [8.0098, 53.7803],
              [8.0154, 53.7713],
            ],
          ],
          [
            [
              [8.4528, 53.9612],
              [8.4511, 53.9566],
              [8.4277, 53.9412],
              [8.4156, 53.9446],
              [8.4165, 53.9506],
              [8.4266, 53.9589],
              [8.4381, 53.9645],
              [8.4528, 53.9612],
            ],
          ],
        ],
      },
      properties: {name: 'Lower Saxony', id: 'DE-NI', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-NI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [7.0637, 52.2417],
            [7.1014, 52.2431],
            [7.1087, 52.247],
            [7.1134, 52.2566],
            [7.1231, 52.2612],
            [7.156, 52.2664],
            [7.229, 52.2641],
            [7.2972, 52.264],
            [7.3158, 52.2797],
            [7.3487, 52.2827],
            [7.3623, 52.2876],
            [7.3695, 52.2976],
            [7.3859, 52.309],
            [7.3983, 52.3064],
            [7.4057, 52.3129],
            [7.4161, 52.3117],
            [7.4271, 52.3136],
            [7.4264, 52.3231],
            [7.4369, 52.3321],
            [7.4806, 52.347],
            [7.5052, 52.3595],
            [7.514, 52.3605],
            [7.5292, 52.3695],
            [7.5601, 52.3688],
            [7.5624, 52.3734],
            [7.573, 52.3809],
            [7.5829, 52.4016],
            [7.5912, 52.4092],
            [7.602, 52.4138],
            [7.6043, 52.4206],
            [7.5723, 52.4313],
            [7.5755, 52.4417],
            [7.5869, 52.4588],
            [7.6046, 52.4744],
            [7.6547, 52.4608],
            [7.6641, 52.4562],
            [7.6833, 52.456],
            [7.6896, 52.4518],
            [7.7028, 52.4328],
            [7.7134, 52.4007],
            [7.7256, 52.399],
            [7.7591, 52.3892],
            [7.7815, 52.3799],
            [7.8083, 52.3719],
            [7.8459, 52.3695],
            [7.866, 52.3764],
            [7.8896, 52.3779],
            [7.8911, 52.3808],
            [7.9292, 52.3639],
            [7.9379, 52.3644],
            [7.9435, 52.3564],
            [7.9415, 52.3496],
            [7.9524, 52.3481],
            [7.9468, 52.3393],
            [7.9616, 52.3235],
            [7.9799, 52.3125],
            [7.9929, 52.3088],
            [7.9909, 52.3035],
            [7.9647, 52.3046],
            [7.9456, 52.3078],
            [7.9351, 52.3022],
            [7.9288, 52.2923],
            [7.9327, 52.2859],
            [7.94, 52.2831],
            [7.9577, 52.2715],
            [7.958, 52.267],
            [7.9286, 52.2647],
            [7.9236, 52.2491],
            [7.9291, 52.2443],
            [7.9286, 52.2306],
            [7.9261, 52.224],
            [7.9135, 52.222],
            [7.911, 52.2144],
            [7.9028, 52.208],
            [7.9042, 52.1983],
            [7.9101, 52.1954],
            [7.9096, 52.1889],
            [7.9369, 52.1761],
            [7.9556, 52.1769],
            [7.9764, 52.1706],
            [7.9894, 52.1744],
            [8.0069, 52.176],
            [8.015, 52.1721],
            [8.0181, 52.1666],
            [8.0265, 52.1627],
            [8.0177, 52.1591],
            [8.0031, 52.1598],
            [7.9996, 52.1503],
            [8.0044, 52.144],
            [8.0081, 52.1154],
            [7.9894, 52.1167],
            [7.9725, 52.1162],
            [7.9548, 52.1033],
            [7.901, 52.0927],
            [7.8857, 52.0854],
            [7.9101, 52.0638],
            [7.9197, 52.0572],
            [7.9192, 52.051],
            [7.9601, 52.0405],
            [7.9788, 52.0366],
            [7.9863, 52.0389],
            [7.9877, 52.0448],
            [7.9951, 52.0516],
            [8.013, 52.055],
            [8.0199, 52.0629],
            [8.0322, 52.0691],
            [8.0382, 52.0641],
            [8.0554, 52.0649],
            [8.0668, 52.0685],
            [8.105, 52.0616],
            [8.1229, 52.0662],
            [8.1336, 52.0717],
            [8.1511, 52.0754],
            [8.1646, 52.0732],
            [8.1923, 52.073],
            [8.2081, 52.0959],
            [8.23, 52.1096],
            [8.2467, 52.1221],
            [8.2636, 52.1216],
            [8.268, 52.1317],
            [8.2797, 52.1326],
            [8.284, 52.1272],
            [8.2982, 52.1215],
            [8.3106, 52.1187],
            [8.3232, 52.1254],
            [8.3434, 52.1238],
            [8.3487, 52.1208],
            [8.3648, 52.1181],
            [8.3722, 52.1102],
            [8.3885, 52.1084],
            [8.4064, 52.1107],
            [8.4102, 52.1204],
            [8.4052, 52.1236],
            [8.4098, 52.1365],
            [8.4328, 52.146],
            [8.434, 52.1483],
            [8.4543, 52.1549],
            [8.4695, 52.1549],
            [8.4912, 52.1657],
            [8.5024, 52.1761],
            [8.5171, 52.1854],
            [8.5048, 52.1889],
            [8.4685, 52.1938],
            [8.4591, 52.1979],
            [8.4424, 52.2145],
            [8.4501, 52.2187],
            [8.4606, 52.2302],
            [8.4586, 52.2425],
            [8.4669, 52.2453],
            [8.4603, 52.2596],
            [8.4653, 52.2669],
            [8.4658, 52.2743],
            [8.4597, 52.2825],
            [8.4605, 52.295],
            [8.4701, 52.315],
            [8.4612, 52.3177],
            [8.4536, 52.33],
            [8.4506, 52.3403],
            [8.4425, 52.3557],
            [8.4372, 52.3617],
            [8.4023, 52.3771],
            [8.3942, 52.3841],
            [8.372, 52.3871],
            [8.342, 52.397],
            [8.3228, 52.3992],
            [8.3136, 52.4065],
            [8.3215, 52.4193],
            [8.3216, 52.4252],
            [8.3091, 52.435],
            [8.2976, 52.4544],
            [8.3029, 52.4581],
            [8.3116, 52.4573],
            [8.3215, 52.4518],
            [8.3332, 52.4531],
            [8.3491, 52.4509],
            [8.3632, 52.4437],
            [8.3848, 52.4441],
            [8.3992, 52.4499],
            [8.4168, 52.444],
            [8.4248, 52.4505],
            [8.4298, 52.4469],
            [8.4549, 52.4609],
            [8.459, 52.4841],
            [8.4583, 52.4917],
            [8.4709, 52.4982],
            [8.4805, 52.4967],
            [8.4877, 52.5031],
            [8.5093, 52.5142],
            [8.5187, 52.5093],
            [8.5422, 52.5017],
            [8.5567, 52.4998],
            [8.6287, 52.5249],
            [8.6507, 52.531],
            [8.6653, 52.5223],
            [8.6707, 52.5169],
            [8.6892, 52.5173],
            [8.6851, 52.5124],
            [8.6956, 52.5097],
            [8.7026, 52.5007],
            [8.6988, 52.4854],
            [8.7097, 52.4763],
            [8.7107, 52.4621],
            [8.7049, 52.4565],
            [8.7038, 52.4428],
            [8.7057, 52.4372],
            [8.717, 52.4295],
            [8.7083, 52.4241],
            [8.7035, 52.414],
            [8.7103, 52.4072],
            [8.7066, 52.3954],
            [8.7146, 52.3928],
            [8.7253, 52.3992],
            [8.7479, 52.3883],
            [8.7899, 52.3986],
            [8.8553, 52.3893],
            [8.8593, 52.3946],
            [8.8866, 52.3943],
            [8.891, 52.4041],
            [8.8978, 52.4057],
            [8.9329, 52.4012],
            [8.945, 52.4091],
            [8.9482, 52.4159],
            [8.9615, 52.4198],
            [8.9632, 52.4251],
            [8.9775, 52.4266],
            [8.9846, 52.4325],
            [8.9863, 52.4442],
            [8.9913, 52.4512],
            [9.0006, 52.4569],
            [9.0133, 52.4571],
            [9.0171, 52.4672],
            [9.0219, 52.4694],
            [9.0252, 52.4789],
            [9.0433, 52.4823],
            [9.046, 52.4943],
            [9.0544, 52.5004],
            [9.0657, 52.4967],
            [9.0735, 52.4981],
            [9.0907, 52.4953],
            [9.1003, 52.4971],
            [9.1139, 52.4849],
            [9.1326, 52.4842],
            [9.1371, 52.4754],
            [9.1254, 52.4755],
            [9.1249, 52.4676],
            [9.1025, 52.4561],
            [9.0946, 52.4435],
            [9.1105, 52.4353],
            [9.1134, 52.421],
            [9.1242, 52.4219],
            [9.1257, 52.4128],
            [9.1233, 52.4041],
            [9.1135, 52.3951],
            [9.0954, 52.3855],
            [9.1014, 52.3807],
            [9.0987, 52.3716],
            [9.0907, 52.3674],
            [9.0843, 52.3551],
            [9.0731, 52.3486],
            [9.0593, 52.3468],
            [9.0592, 52.3394],
            [9.0433, 52.341],
            [9.0276, 52.3452],
            [9.0253, 52.337],
            [9.0188, 52.3308],
            [9.0124, 52.3314],
            [9.0056, 52.3259],
            [8.9984, 52.3262],
            [8.998, 52.3184],
            [8.9908, 52.3161],
            [8.9877, 52.3102],
            [8.9902, 52.3037],
            [8.9849, 52.2948],
            [8.9861, 52.2859],
            [8.9659, 52.2798],
            [8.9775, 52.27],
            [8.9728, 52.2667],
            [8.9788, 52.2614],
            [8.9995, 52.2569],
            [9.0062, 52.2526],
            [9.0233, 52.251],
            [9.0486, 52.2437],
            [9.0592, 52.2379],
            [9.0772, 52.2331],
            [9.0737, 52.2281],
            [9.0479, 52.2206],
            [9.04, 52.2255],
            [9.0343, 52.2187],
            [9.0436, 52.2114],
            [9.042, 52.2026],
            [9.0481, 52.1951],
            [9.0424, 52.1902],
            [9.0437, 52.1837],
            [9.0233, 52.1832],
            [9.0174, 52.1872],
            [8.9987, 52.191],
            [8.9926, 52.1884],
            [9.0079, 52.1807],
            [9.0151, 52.1687],
            [9.0125, 52.1568],
            [9.0168, 52.1474],
            [9.011, 52.1389],
            [9.0164, 52.1331],
            [9.038, 52.1393],
            [9.0441, 52.1395],
            [9.055, 52.1478],
            [9.0706, 52.1477],
            [9.0717, 52.1424],
            [9.0827, 52.1411],
            [9.0895, 52.1345],
            [9.1077, 52.1327],
            [9.1166, 52.1376],
            [9.1303, 52.1347],
            [9.1452, 52.1347],
            [9.1564, 52.1255],
            [9.1594, 52.1192],
            [9.1558, 52.1138],
            [9.136, 52.1052],
            [9.143, 52.0984],
            [9.155, 52.0972],
            [9.155, 52.0914],
            [9.1755, 52.086],
            [9.1853, 52.077],
            [9.1881, 52.0713],
            [9.1744, 52.061],
            [9.1755, 52.0345],
            [9.1884, 52.0268],
            [9.1961, 52.012],
            [9.1808, 52.0028],
            [9.1957, 51.9956],
            [9.1825, 51.9914],
            [9.1754, 51.98],
            [9.187, 51.9719],
            [9.1856, 51.9664],
            [9.1966, 51.9615],
            [9.2138, 51.9676],
            [9.219, 51.9733],
            [9.2262, 51.9726],
            [9.2381, 51.976],
            [9.2457, 51.9744],
            [9.2636, 51.9762],
            [9.2746, 51.9722],
            [9.2741, 51.9662],
            [9.2846, 51.9563],
            [9.2713, 51.9549],
            [9.2676, 51.9446],
            [9.278, 51.9393],
            [9.2737, 51.9313],
            [9.2941, 51.9185],
            [9.3152, 51.9209],
            [9.3234, 51.9164],
            [9.3313, 51.916],
            [9.3412, 51.9072],
            [9.3448, 51.8956],
            [9.3441, 51.8806],
            [9.34, 51.8745],
            [9.338, 51.8615],
            [9.3414, 51.8557],
            [9.3506, 51.8617],
            [9.362, 51.8644],
            [9.3936, 51.8597],
            [9.4031, 51.8555],
            [9.4384, 51.8567],
            [9.4589, 51.8614],
            [9.4615, 51.8561],
            [9.4547, 51.8506],
            [9.4335, 51.841],
            [9.4412, 51.8318],
            [9.44, 51.8242],
            [9.4275, 51.8146],
            [9.4305, 51.8055],
            [9.4408, 51.8038],
            [9.4374, 51.7978],
            [9.4466, 51.7949],
            [9.4101, 51.7711],
            [9.4055, 51.7623],
            [9.3959, 51.7583],
            [9.3849, 51.7581],
            [9.3844, 51.752],
            [9.3758, 51.7449],
            [9.3788, 51.738],
            [9.3917, 51.7339],
            [9.3938, 51.7287],
            [9.3872, 51.7224],
            [9.3928, 51.7114],
            [9.401, 51.7044],
            [9.3991, 51.6969],
            [9.3868, 51.6898],
            [9.3953, 51.679],
            [9.3955, 51.6734],
            [9.3835, 51.6691],
            [9.3781, 51.663],
            [9.375, 51.6498],
            [9.3852, 51.6464],
            [9.399, 51.6475],
            [9.4187, 51.6465],
            [9.4249, 51.6498],
            [9.4388, 51.6509],
            [9.4513, 51.6464],
            [9.4399, 51.6413],
            [9.4309, 51.6329],
            [9.4224, 51.6295],
            [9.401, 51.6269],
            [9.3781, 51.6268],
            [9.3704, 51.6199],
            [9.3595, 51.6202],
            [9.3374, 51.6143],
            [9.3384, 51.61],
            [9.3589, 51.5963],
            [9.3701, 51.5933],
            [9.3646, 51.5851],
            [9.3532, 51.5809],
            [9.346, 51.5705],
            [9.3339, 51.5582],
            [9.3157, 51.5522],
            [9.3206, 51.5472],
            [9.3115, 51.5367],
            [9.3152, 51.5225],
            [9.3078, 51.5135],
            [9.2878, 51.515],
            [9.2769, 51.5112],
            [9.2671, 51.5047],
            [9.264, 51.4985],
            [9.2436, 51.4978],
            [9.2374, 51.4917],
            [9.2246, 51.4898],
            [9.2174, 51.4794],
            [9.2185, 51.4712],
            [9.2117, 51.4616],
            [9.1838, 51.4656],
            [9.177, 51.4634],
            [9.165, 51.452],
            [9.1596, 51.4431],
            [9.1464, 51.4428],
            [9.1367, 51.4502],
            [9.1298, 51.4463],
            [9.1053, 51.4436],
            [9.0923, 51.4456],
            [9.0864, 51.4499],
            [9.0864, 51.4622],
            [9.0801, 51.4691],
            [9.0925, 51.4877],
            [9.0916, 51.4951],
            [9.0788, 51.5028],
            [9.0605, 51.4987],
            [9.0581, 51.5019],
            [9.0395, 51.5024],
            [9.0244, 51.5113],
            [9.0188, 51.5178],
            [9.0054, 51.5126],
            [8.9697, 51.5069],
            [8.9446, 51.5009],
            [8.9268, 51.4929],
            [8.8991, 51.4868],
            [8.8913, 51.4816],
            [8.8945, 51.4712],
            [8.9082, 51.4644],
            [8.9195, 51.4482],
            [8.9215, 51.4301],
            [8.937, 51.4309],
            [8.9474, 51.4273],
            [8.946, 51.4169],
            [8.9413, 51.4111],
            [8.9427, 51.3968],
            [8.9382, 51.389],
            [8.9165, 51.3927],
            [8.8886, 51.392],
            [8.8641, 51.38],
            [8.8549, 51.3773],
            [8.8387, 51.3839],
            [8.8284, 51.3857],
            [8.8238, 51.3896],
            [8.8029, 51.3905],
            [8.7807, 51.3858],
            [8.7713, 51.3853],
            [8.7604, 51.3785],
            [8.7375, 51.3745],
            [8.7313, 51.3721],
            [8.7187, 51.3726],
            [8.7003, 51.3769],
            [8.6827, 51.3737],
            [8.6773, 51.3689],
            [8.6834, 51.362],
            [8.6703, 51.3566],
            [8.6589, 51.3546],
            [8.6536, 51.3462],
            [8.6533, 51.3388],
            [8.6369, 51.3434],
            [8.6201, 51.3358],
            [8.6192, 51.3297],
            [8.6036, 51.3231],
            [8.6029, 51.3146],
            [8.585, 51.2981],
            [8.5649, 51.2874],
            [8.5573, 51.2771],
            [8.5668, 51.2707],
            [8.5804, 51.2646],
            [8.5858, 51.2532],
            [8.5957, 51.2468],
            [8.611, 51.2466],
            [8.6253, 51.2535],
            [8.6298, 51.261],
            [8.6519, 51.2635],
            [8.6658, 51.2678],
            [8.6775, 51.2679],
            [8.6865, 51.2733],
            [8.7137, 51.2749],
            [8.7219, 51.2734],
            [8.7266, 51.2652],
            [8.7363, 51.2612],
            [8.7276, 51.2509],
            [8.732, 51.2384],
            [8.7375, 51.2329],
            [8.7412, 51.2186],
            [8.7675, 51.2086],
            [8.765, 51.204],
            [8.756, 51.2028],
            [8.7506, 51.1967],
            [8.7516, 51.1812],
            [8.7583, 51.1768],
            [8.7482, 51.1715],
            [8.7485, 51.1678],
            [8.7191, 51.1516],
            [8.6962, 51.137],
            [8.693, 51.129],
            [8.7011, 51.123],
            [8.7021, 51.1167],
            [8.7104, 51.1177],
            [8.7206, 51.1139],
            [8.7203, 51.1096],
            [8.6843, 51.1076],
            [8.6614, 51.0956],
            [8.6432, 51.0953],
            [8.6197, 51.1002],
            [8.595, 51.1017],
            [8.574, 51.1013],
            [8.5525, 51.1027],
            [8.5426, 51.0953],
            [8.5279, 51.0968],
            [8.523, 51.0907],
            [8.5088, 51.0855],
            [8.5025, 51.0787],
            [8.5071, 51.0728],
            [8.5257, 51.0651],
            [8.5261, 51.0554],
            [8.5111, 51.048],
            [8.5052, 51.0404],
            [8.5106, 51.0377],
            [8.5191, 51.0435],
            [8.525, 51.0379],
            [8.527, 51.031],
            [8.5373, 51.0191],
            [8.5024, 50.9987],
            [8.4978, 50.9895],
            [8.486, 50.9839],
            [8.4748, 50.9761],
            [8.4743, 50.9719],
            [8.4584, 50.9656],
            [8.4613, 50.9589],
            [8.4601, 50.9472],
            [8.45, 50.9408],
            [8.4601, 50.9186],
            [8.4546, 50.9155],
            [8.4323, 50.9174],
            [8.4254, 50.9114],
            [8.4115, 50.9053],
            [8.4003, 50.8951],
            [8.3875, 50.8905],
            [8.3882, 50.8791],
            [8.3858, 50.8756],
            [8.3701, 50.873],
            [8.369, 50.864],
            [8.359, 50.8607],
            [8.3532, 50.8653],
            [8.3363, 50.8653],
            [8.3208, 50.8616],
            [8.3063, 50.8607],
            [8.2926, 50.8744],
            [8.288, 50.8822],
            [8.2669, 50.8804],
            [8.2597, 50.8716],
            [8.2329, 50.8554],
            [8.2293, 50.8487],
            [8.2206, 50.8456],
            [8.2097, 50.837],
            [8.202, 50.8355],
            [8.1892, 50.829],
            [8.1751, 50.8173],
            [8.1658, 50.8041],
            [8.1434, 50.7941],
            [8.1402, 50.7905],
            [8.134, 50.7892],
            [8.13, 50.779],
            [8.142, 50.7728],
            [8.1379, 50.7665],
            [8.1477, 50.7635],
            [8.1544, 50.75],
            [8.1647, 50.7447],
            [8.1675, 50.7339],
            [8.1516, 50.7137],
            [8.1512, 50.7059],
            [8.1425, 50.6948],
            [8.1318, 50.6964],
            [8.1247, 50.6853],
            [8.1134, 50.6951],
            [8.1, 50.7032],
            [8.0843, 50.7034],
            [8.0781, 50.7064],
            [8.0597, 50.6954],
            [8.0399, 50.6985],
            [8.0429, 50.7119],
            [8.0403, 50.7256],
            [8.0218, 50.7399],
            [8.0148, 50.7421],
            [8.002, 50.7541],
            [7.9807, 50.7634],
            [7.9681, 50.7753],
            [7.9669, 50.7966],
            [7.9736, 50.8041],
            [7.9721, 50.8153],
            [7.9774, 50.8246],
            [7.9768, 50.8314],
            [7.9673, 50.8364],
            [7.9685, 50.8456],
            [7.957, 50.8531],
            [7.9523, 50.8464],
            [7.9396, 50.8479],
            [7.9232, 50.8447],
            [7.9079, 50.8471],
            [7.9009, 50.8522],
            [7.8861, 50.8724],
            [7.8706, 50.8775],
            [7.8488, 50.8784],
            [7.828, 50.8824],
            [7.8271, 50.8867],
            [7.8336, 50.8984],
            [7.8336, 50.9081],
            [7.8485, 50.923],
            [7.8208, 50.9324],
            [7.8113, 50.9392],
            [7.7927, 50.9424],
            [7.7851, 50.9394],
            [7.7782, 50.9313],
            [7.766, 50.9239],
            [7.7512, 50.9197],
            [7.739, 50.9196],
            [7.7386, 50.9148],
            [7.7473, 50.912],
            [7.7453, 50.9061],
            [7.7603, 50.9009],
            [7.7534, 50.8884],
            [7.7582, 50.8787],
            [7.7527, 50.8751],
            [7.75, 50.8667],
            [7.765, 50.8569],
            [7.7451, 50.8468],
            [7.7356, 50.8473],
            [7.7292, 50.8509],
            [7.7237, 50.8444],
            [7.7162, 50.8414],
            [7.7138, 50.8342],
            [7.6972, 50.8247],
            [7.6751, 50.8193],
            [7.6659, 50.8146],
            [7.6793, 50.8106],
            [7.6752, 50.8027],
            [7.681, 50.7994],
            [7.6778, 50.7897],
            [7.6696, 50.7859],
            [7.6716, 50.7814],
            [7.6554, 50.7684],
            [7.6465, 50.7701],
            [7.618, 50.7694],
            [7.6003, 50.7656],
            [7.5997, 50.7606],
            [7.5922, 50.7566],
            [7.5959, 50.7512],
            [7.5933, 50.7409],
            [7.5855, 50.7377],
            [7.5504, 50.7463],
            [7.5448, 50.7425],
            [7.5203, 50.7334],
            [7.5209, 50.7289],
            [7.5034, 50.7298],
            [7.4818, 50.7193],
            [7.4577, 50.7185],
            [7.453, 50.7152],
            [7.4345, 50.71],
            [7.4222, 50.7103],
            [7.4074, 50.7182],
            [7.3968, 50.7157],
            [7.3732, 50.7174],
            [7.3637, 50.7006],
            [7.3667, 50.6864],
            [7.372, 50.6825],
            [7.3696, 50.6724],
            [7.3583, 50.6672],
            [7.3544, 50.6593],
            [7.3597, 50.6517],
            [7.3374, 50.6424],
            [7.3357, 50.6392],
            [7.3224, 50.6414],
            [7.3134, 50.6366],
            [7.3043, 50.6376],
            [7.2964, 50.6315],
            [7.2701, 50.6261],
            [7.2394, 50.6292],
            [7.2114, 50.6238],
            [7.2116, 50.6465],
            [7.1997, 50.6477],
            [7.1907, 50.6355],
            [7.1893, 50.6233],
            [7.1741, 50.6185],
            [7.1723, 50.6123],
            [7.155, 50.6141],
            [7.1481, 50.6082],
            [7.1472, 50.6002],
            [7.1275, 50.6051],
            [7.1191, 50.6117],
            [7.1124, 50.6101],
            [7.1095, 50.6012],
            [7.1021, 50.5904],
            [7.0925, 50.5861],
            [7.0694, 50.5929],
            [7.0665, 50.6005],
            [7.0591, 50.604],
            [7.0486, 50.6043],
            [7.0424, 50.5883],
            [7.0283, 50.5803],
            [7.0138, 50.5755],
            [6.9978, 50.5645],
            [6.9839, 50.5655],
            [6.976, 50.5576],
            [6.957, 50.5567],
            [6.9519, 50.5632],
            [6.9302, 50.5603],
            [6.9232, 50.5538],
            [6.9309, 50.5338],
            [6.909, 50.5257],
            [6.8988, 50.5304],
            [6.8831, 50.5284],
            [6.8841, 50.5213],
            [6.8964, 50.5176],
            [6.888, 50.5079],
            [6.9037, 50.5034],
            [6.8988, 50.4933],
            [6.9041, 50.4851],
            [6.8985, 50.4784],
            [6.9003, 50.4689],
            [6.8807, 50.4664],
            [6.865, 50.456],
            [6.8547, 50.4534],
            [6.8519, 50.4575],
            [6.8392, 50.4647],
            [6.8244, 50.4671],
            [6.8129, 50.4723],
            [6.8058, 50.4877],
            [6.8007, 50.492],
            [6.7707, 50.4852],
            [6.7615, 50.4758],
            [6.745, 50.4732],
            [6.7449, 50.4671],
            [6.7573, 50.4709],
            [6.766, 50.4589],
            [6.764, 50.4506],
            [6.7442, 50.4417],
            [6.746, 50.4363],
            [6.7594, 50.4318],
            [6.7697, 50.4318],
            [6.7712, 50.4248],
            [6.7833, 50.4211],
            [6.7786, 50.4131],
            [6.7787, 50.4054],
            [6.7702, 50.3928],
            [6.7919, 50.3701],
            [6.8025, 50.3687],
            [6.7993, 50.3603],
            [6.7788, 50.3637],
            [6.7541, 50.3539],
            [6.7336, 50.3522],
            [6.7274, 50.3474],
            [6.6988, 50.3383],
            [6.697, 50.3456],
            [6.6905, 50.3508],
            [6.6856, 50.3595],
            [6.6695, 50.3703],
            [6.6569, 50.3659],
            [6.654, 50.3503],
            [6.6371, 50.3445],
            [6.6215, 50.3542],
            [6.6275, 50.3679],
            [6.6237, 50.3732],
            [6.6145, 50.3759],
            [6.6045, 50.3746],
            [6.6047, 50.3861],
            [6.5862, 50.382],
            [6.5804, 50.3774],
            [6.5702, 50.3759],
            [6.5554, 50.3677],
            [6.5363, 50.3705],
            [6.5176, 50.3611],
            [6.5074, 50.3595],
            [6.5048, 50.3516],
            [6.4866, 50.3436],
            [6.4842, 50.338],
            [6.4738, 50.3382],
            [6.4595, 50.3438],
            [6.4601, 50.3606],
            [6.4528, 50.3618],
            [6.4507, 50.3707],
            [6.4372, 50.3693],
            [6.4293, 50.3652],
            [6.4172, 50.375],
            [6.4222, 50.3801],
            [6.4133, 50.3886],
            [6.3872, 50.3837],
            [6.3937, 50.3798],
            [6.3899, 50.3708],
            [6.3981, 50.3649],
            [6.3964, 50.3606],
            [6.4036, 50.3522],
            [6.4086, 50.3385],
            [6.4256, 50.3262],
            [6.4194, 50.3235],
            [6.4052, 50.3221],
            [6.4082, 50.3349],
            [6.4013, 50.3373],
            [6.3999, 50.3442],
            [6.3828, 50.3534],
            [6.3704, 50.3568],
            [6.3609, 50.3706],
            [6.3422, 50.3797],
            [6.3475, 50.3863],
            [6.3569, 50.391],
            [6.3584, 50.3959],
            [6.3696, 50.4091],
            [6.3666, 50.4209],
            [6.3774, 50.436],
            [6.3761, 50.4491],
            [6.373, 50.4544],
            [6.3648, 50.4536],
            [6.3513, 50.4569],
            [6.3416, 50.4623],
            [6.3436, 50.4709],
            [6.3389, 50.474],
            [6.3433, 50.4836],
            [6.3517, 50.4876],
            [6.3334, 50.4951],
            [6.3221, 50.4959],
            [6.3129, 50.5017],
            [6.2977, 50.4991],
            [6.2859, 50.5002],
            [6.2813, 50.5046],
            [6.264, 50.5034],
            [6.2526, 50.505],
            [6.2425, 50.5007],
            [6.2229, 50.4983],
            [6.2216, 50.5063],
            [6.2074, 50.5219],
            [6.1947, 50.5219],
            [6.1899, 50.5267],
            [6.1972, 50.5317],
            [6.1975, 50.537],
            [6.1872, 50.5422],
            [6.1798, 50.5422],
            [6.1775, 50.5575],
            [6.1907, 50.5642],
            [6.2054, 50.5691],
            [6.2073, 50.5759],
            [6.2207, 50.5815],
            [6.2267, 50.5901],
            [6.2395, 50.5874],
            [6.2482, 50.5945],
            [6.2468, 50.6023],
            [6.2598, 50.6083],
            [6.2701, 50.6173],
            [6.2703, 50.6264],
            [6.2649, 50.6282],
            [6.2372, 50.6266],
            [6.2226, 50.6318],
            [6.1993, 50.6329],
            [6.1874, 50.6232],
            [6.1787, 50.6263],
            [6.1855, 50.6322],
            [6.1765, 50.6428],
            [6.1836, 50.6469],
            [6.182, 50.6545],
            [6.1678, 50.6623],
            [6.1614, 50.6726],
            [6.1539, 50.6767],
            [6.1367, 50.6987],
            [6.1262, 50.7069],
            [6.1275, 50.7107],
            [6.1183, 50.7223],
            [6.1009, 50.7237],
            [6.0865, 50.7215],
            [6.0639, 50.7223],
            [6.0472, 50.7283],
            [6.036, 50.7271],
            [6.037, 50.7331],
            [6.044, 50.7387],
            [6.0431, 50.7444],
            [6.0215, 50.7554],
            [6.0174, 50.7622],
            [6.0273, 50.772],
            [6.026, 50.7745],
            [5.9993, 50.7844],
            [5.9952, 50.7902],
            [5.9746, 50.7963],
            [5.9754, 50.8023],
            [5.9853, 50.8096],
            [6.004, 50.8021],
            [6.0111, 50.8038],
            [6.0258, 50.815],
            [6.0294, 50.8199],
            [6.0192, 50.8319],
            [6.0199, 50.8458],
            [6.0288, 50.847],
            [6.0567, 50.8559],
            [6.0587, 50.8517],
            [6.075, 50.8465],
            [6.078, 50.86],
            [6.0858, 50.8658],
            [6.09, 50.8731],
            [6.0761, 50.8906],
            [6.0829, 50.9092],
            [6.0899, 50.9173],
            [6.0845, 50.9211],
            [6.0728, 50.9231],
            [6.0553, 50.922],
            [6.0564, 50.9269],
            [6.0203, 50.9343],
            [6.0174, 50.9527],
            [6.0062, 50.9567],
            [6.0136, 50.9611],
            [6.0271, 50.9819],
            [6.022, 50.9841],
            [5.9854, 50.9824],
            [5.9679, 50.979],
            [5.9545, 50.9879],
            [5.9348, 50.9851],
            [5.9218, 50.9786],
            [5.9057, 50.9745],
            [5.8985, 50.9748],
            [5.8938, 50.9806],
            [5.9041, 50.9878],
            [5.9055, 51.002],
            [5.8962, 51.0046],
            [5.8965, 51.0105],
            [5.8859, 51.0136],
            [5.88, 51.0183],
            [5.8736, 51.0285],
            [5.8792, 51.0355],
            [5.8683, 51.0436],
            [5.8661, 51.0513],
            [5.8919, 51.0532],
            [5.9132, 51.0661],
            [5.9195, 51.0623],
            [5.926, 51.0489],
            [5.9357, 51.0403],
            [5.9449, 51.0367],
            [5.9596, 51.0356],
            [5.9577, 51.0412],
            [5.9689, 51.0445],
            [5.9709, 51.0607],
            [5.9894, 51.0743],
            [5.9954, 51.0819],
            [6.0112, 51.0914],
            [6.0327, 51.0946],
            [6.0443, 51.1002],
            [6.0567, 51.1092],
            [6.058, 51.1143],
            [6.0736, 51.1184],
            [6.0869, 51.1244],
            [6.0921, 51.1344],
            [6.1073, 51.1361],
            [6.1287, 51.1449],
            [6.1634, 51.1485],
            [6.1634, 51.1523],
            [6.1749, 51.1592],
            [6.1482, 51.1693],
            [6.1417, 51.1739],
            [6.1828, 51.1859],
            [6.1662, 51.194],
            [6.1234, 51.181],
            [6.1139, 51.1747],
            [6.1006, 51.1698],
            [6.0829, 51.1719],
            [6.0747, 51.1823],
            [6.0684, 51.2189],
            [6.0739, 51.2219],
            [6.0864, 51.223],
            [6.0722, 51.242],
            [6.0855, 51.2471],
            [6.1235, 51.2734],
            [6.1298, 51.285],
            [6.1547, 51.3066],
            [6.1602, 51.3196],
            [6.1692, 51.3313],
            [6.1934, 51.3346],
            [6.1909, 51.3405],
            [6.2224, 51.3585],
            [6.2265, 51.3619],
            [6.2146, 51.3865],
            [6.2264, 51.4003],
            [6.2066, 51.3997],
            [6.2141, 51.4272],
            [6.2145, 51.4453],
            [6.2194, 51.4506],
            [6.2238, 51.4738],
            [6.2139, 51.4913],
            [6.2141, 51.5122],
            [6.1998, 51.5278],
            [6.1784, 51.5383],
            [6.161, 51.5628],
            [6.1278, 51.5838],
            [6.1215, 51.5913],
            [6.0915, 51.6059],
            [6.0943, 51.6144],
            [6.1093, 51.6421],
            [6.1167, 51.6502],
            [6.1169, 51.6563],
            [6.0876, 51.6595],
            [6.0327, 51.6742],
            [6.0286, 51.6881],
            [6.0334, 51.6917],
            [6.0324, 51.6983],
            [6.0267, 51.7059],
            [6.0279, 51.7107],
            [6.0444, 51.7171],
            [6.0354, 51.7191],
            [6.0264, 51.7275],
            [6.005, 51.7332],
            [5.9938, 51.7383],
            [5.9557, 51.7389],
            [5.954, 51.7495],
            [5.99, 51.7659],
            [5.9836, 51.7737],
            [5.9913, 51.7831],
            [5.9749, 51.7863],
            [5.9779, 51.7983],
            [5.9639, 51.8073],
            [5.9491, 51.8117],
            [5.9593, 51.8177],
            [5.946, 51.8241],
            [5.9622, 51.8365],
            [5.9705, 51.8333],
            [5.9875, 51.8307],
            [5.9994, 51.8312],
            [6.0138, 51.8354],
            [6.0189, 51.8417],
            [6.0424, 51.8471],
            [6.0543, 51.8512],
            [6.0649, 51.8647],
            [6.0763, 51.8603],
            [6.092, 51.8516],
            [6.1131, 51.8473],
            [6.1303, 51.8477],
            [6.1667, 51.8409],
            [6.1649, 51.8607],
            [6.1454, 51.8704],
            [6.1393, 51.8854],
            [6.1112, 51.8948],
            [6.1196, 51.9018],
            [6.127, 51.8982],
            [6.1338, 51.9014],
            [6.1566, 51.9038],
            [6.1698, 51.9011],
            [6.1872, 51.8923],
            [6.1864, 51.8822],
            [6.2004, 51.8733],
            [6.2176, 51.8681],
            [6.2359, 51.8695],
            [6.2565, 51.8684],
            [6.2733, 51.8746],
            [6.2809, 51.8739],
            [6.3001, 51.8682],
            [6.3066, 51.8499],
            [6.3251, 51.851],
            [6.3549, 51.8486],
            [6.3621, 51.8446],
            [6.3708, 51.8342],
            [6.3864, 51.8339],
            [6.4032, 51.827],
            [6.4039, 51.8459],
            [6.4115, 51.8525],
            [6.3883, 51.8629],
            [6.398, 51.8714],
            [6.4145, 51.8705],
            [6.4279, 51.8659],
            [6.4329, 51.8587],
            [6.4506, 51.8652],
            [6.4651, 51.8545],
            [6.4994, 51.8598],
            [6.5038, 51.8664],
            [6.5161, 51.8722],
            [6.5374, 51.8791],
            [6.5524, 51.8859],
            [6.5597, 51.8823],
            [6.5791, 51.8918],
            [6.6254, 51.9014],
            [6.6339, 51.9005],
            [6.6403, 51.9046],
            [6.6801, 51.9163],
            [6.6948, 51.9153],
            [6.7067, 51.9041],
            [6.7233, 51.896],
            [6.7327, 51.8988],
            [6.7375, 51.9043],
            [6.7483, 51.9061],
            [6.7545, 51.9123],
            [6.7728, 51.917],
            [6.7887, 51.929],
            [6.7969, 51.9372],
            [6.7995, 51.9585],
            [6.8149, 51.9637],
            [6.8282, 51.964],
            [6.833, 51.9715],
            [6.8275, 51.9929],
            [6.8094, 51.9946],
            [6.8075, 51.9996],
            [6.7735, 52.0168],
            [6.7537, 52.0279],
            [6.7148, 52.0394],
            [6.6875, 52.0394],
            [6.6876, 52.0511],
            [6.6962, 52.0651],
            [6.6943, 52.0704],
            [6.7106, 52.0729],
            [6.7371, 52.0751],
            [6.7495, 52.0846],
            [6.7437, 52.0928],
            [6.756, 52.097],
            [6.7612, 52.1185],
            [6.8197, 52.1183],
            [6.8556, 52.1206],
            [6.8709, 52.1291],
            [6.8819, 52.1567],
            [6.9043, 52.1695],
            [6.9072, 52.1761],
            [6.9486, 52.1804],
            [6.9608, 52.1909],
            [6.9797, 52.2144],
            [6.979, 52.2209],
            [6.9846, 52.2256],
            [7.0028, 52.2283],
            [7.0153, 52.2262],
            [7.04, 52.2274],
            [7.0593, 52.2354],
            [7.0637, 52.2417],
          ],
        ],
      },
      properties: {name: 'North Rhine-Westphalia', id: 'DE-NW', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-NW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.4052, 50.3221],
            [6.4194, 50.3235],
            [6.4256, 50.3262],
            [6.4086, 50.3385],
            [6.4036, 50.3522],
            [6.3964, 50.3606],
            [6.3981, 50.3649],
            [6.3899, 50.3708],
            [6.3937, 50.3798],
            [6.3872, 50.3837],
            [6.4133, 50.3886],
            [6.4222, 50.3801],
            [6.4172, 50.375],
            [6.4293, 50.3652],
            [6.4372, 50.3693],
            [6.4507, 50.3707],
            [6.4528, 50.3618],
            [6.4601, 50.3606],
            [6.4595, 50.3438],
            [6.4738, 50.3382],
            [6.4842, 50.338],
            [6.4866, 50.3436],
            [6.5048, 50.3516],
            [6.5074, 50.3595],
            [6.5176, 50.3611],
            [6.5363, 50.3705],
            [6.5554, 50.3677],
            [6.5702, 50.3759],
            [6.5804, 50.3774],
            [6.5862, 50.382],
            [6.6047, 50.3861],
            [6.6045, 50.3746],
            [6.6145, 50.3759],
            [6.6237, 50.3732],
            [6.6275, 50.3679],
            [6.6215, 50.3542],
            [6.6371, 50.3445],
            [6.654, 50.3503],
            [6.6569, 50.3659],
            [6.6695, 50.3703],
            [6.6856, 50.3595],
            [6.6905, 50.3508],
            [6.697, 50.3456],
            [6.6988, 50.3383],
            [6.7274, 50.3474],
            [6.7336, 50.3522],
            [6.7541, 50.3539],
            [6.7788, 50.3637],
            [6.7993, 50.3603],
            [6.8025, 50.3687],
            [6.7919, 50.3701],
            [6.7702, 50.3928],
            [6.7787, 50.4054],
            [6.7786, 50.4131],
            [6.7833, 50.4211],
            [6.7712, 50.4248],
            [6.7697, 50.4318],
            [6.7594, 50.4318],
            [6.746, 50.4363],
            [6.7442, 50.4417],
            [6.764, 50.4506],
            [6.766, 50.4589],
            [6.7573, 50.4709],
            [6.7449, 50.4671],
            [6.745, 50.4732],
            [6.7615, 50.4758],
            [6.7707, 50.4852],
            [6.8007, 50.492],
            [6.8058, 50.4877],
            [6.8129, 50.4723],
            [6.8244, 50.4671],
            [6.8392, 50.4647],
            [6.8519, 50.4575],
            [6.8547, 50.4534],
            [6.865, 50.456],
            [6.8807, 50.4664],
            [6.9003, 50.4689],
            [6.8985, 50.4784],
            [6.9041, 50.4851],
            [6.8988, 50.4933],
            [6.9037, 50.5034],
            [6.888, 50.5079],
            [6.8964, 50.5176],
            [6.8841, 50.5213],
            [6.8831, 50.5284],
            [6.8988, 50.5304],
            [6.909, 50.5257],
            [6.9309, 50.5338],
            [6.9232, 50.5538],
            [6.9302, 50.5603],
            [6.9519, 50.5632],
            [6.957, 50.5567],
            [6.976, 50.5576],
            [6.9839, 50.5655],
            [6.9978, 50.5645],
            [7.0138, 50.5755],
            [7.0283, 50.5803],
            [7.0424, 50.5883],
            [7.0486, 50.6043],
            [7.0591, 50.604],
            [7.0665, 50.6005],
            [7.0694, 50.5929],
            [7.0925, 50.5861],
            [7.1021, 50.5904],
            [7.1095, 50.6012],
            [7.1124, 50.6101],
            [7.1191, 50.6117],
            [7.1275, 50.6051],
            [7.1472, 50.6002],
            [7.1481, 50.6082],
            [7.155, 50.6141],
            [7.1723, 50.6123],
            [7.1741, 50.6185],
            [7.1893, 50.6233],
            [7.1907, 50.6355],
            [7.1997, 50.6477],
            [7.2116, 50.6465],
            [7.2114, 50.6238],
            [7.2394, 50.6292],
            [7.2701, 50.6261],
            [7.2964, 50.6315],
            [7.3043, 50.6376],
            [7.3134, 50.6366],
            [7.3224, 50.6414],
            [7.3357, 50.6392],
            [7.3374, 50.6424],
            [7.3597, 50.6517],
            [7.3544, 50.6593],
            [7.3583, 50.6672],
            [7.3696, 50.6724],
            [7.372, 50.6825],
            [7.3667, 50.6864],
            [7.3637, 50.7006],
            [7.3732, 50.7174],
            [7.3968, 50.7157],
            [7.4074, 50.7182],
            [7.4222, 50.7103],
            [7.4345, 50.71],
            [7.453, 50.7152],
            [7.4577, 50.7185],
            [7.4818, 50.7193],
            [7.5034, 50.7298],
            [7.5209, 50.7289],
            [7.5203, 50.7334],
            [7.5448, 50.7425],
            [7.5504, 50.7463],
            [7.5855, 50.7377],
            [7.5933, 50.7409],
            [7.5959, 50.7512],
            [7.5922, 50.7566],
            [7.5997, 50.7606],
            [7.6003, 50.7656],
            [7.618, 50.7694],
            [7.6465, 50.7701],
            [7.6554, 50.7684],
            [7.6716, 50.7814],
            [7.6696, 50.7859],
            [7.6778, 50.7897],
            [7.681, 50.7994],
            [7.6752, 50.8027],
            [7.6793, 50.8106],
            [7.6659, 50.8146],
            [7.6751, 50.8193],
            [7.6972, 50.8247],
            [7.7138, 50.8342],
            [7.7162, 50.8414],
            [7.7237, 50.8444],
            [7.7292, 50.8509],
            [7.7356, 50.8473],
            [7.7451, 50.8468],
            [7.765, 50.8569],
            [7.75, 50.8667],
            [7.7527, 50.8751],
            [7.7582, 50.8787],
            [7.7534, 50.8884],
            [7.7603, 50.9009],
            [7.7453, 50.9061],
            [7.7473, 50.912],
            [7.7386, 50.9148],
            [7.739, 50.9196],
            [7.7512, 50.9197],
            [7.766, 50.9239],
            [7.7782, 50.9313],
            [7.7851, 50.9394],
            [7.7927, 50.9424],
            [7.8113, 50.9392],
            [7.8208, 50.9324],
            [7.8485, 50.923],
            [7.8336, 50.9081],
            [7.8336, 50.8984],
            [7.8271, 50.8867],
            [7.828, 50.8824],
            [7.8488, 50.8784],
            [7.8706, 50.8775],
            [7.8861, 50.8724],
            [7.9009, 50.8522],
            [7.9079, 50.8471],
            [7.9232, 50.8447],
            [7.9396, 50.8479],
            [7.9523, 50.8464],
            [7.957, 50.8531],
            [7.9685, 50.8456],
            [7.9673, 50.8364],
            [7.9768, 50.8314],
            [7.9774, 50.8246],
            [7.9721, 50.8153],
            [7.9736, 50.8041],
            [7.9669, 50.7966],
            [7.9681, 50.7753],
            [7.9807, 50.7634],
            [8.002, 50.7541],
            [8.0148, 50.7421],
            [8.0218, 50.7399],
            [8.0403, 50.7256],
            [8.0429, 50.7119],
            [8.0399, 50.6985],
            [8.0597, 50.6954],
            [8.0781, 50.7064],
            [8.0843, 50.7034],
            [8.1, 50.7032],
            [8.1134, 50.6951],
            [8.1247, 50.6853],
            [8.1236, 50.6807],
            [8.111, 50.6725],
            [8.11, 50.6594],
            [8.1156, 50.6517],
            [8.1273, 50.6437],
            [8.1304, 50.6372],
            [8.1402, 50.6321],
            [8.1413, 50.6275],
            [8.1361, 50.6167],
            [8.1258, 50.6104],
            [8.1471, 50.6026],
            [8.1523, 50.5934],
            [8.1392, 50.5669],
            [8.1328, 50.559],
            [8.1359, 50.5508],
            [8.1228, 50.5487],
            [8.1106, 50.5407],
            [8.1199, 50.5364],
            [8.1036, 50.5334],
            [8.0852, 50.5387],
            [8.0713, 50.5389],
            [8.064, 50.5494],
            [8.0429, 50.5534],
            [8.0302, 50.5511],
            [8.0231, 50.538],
            [8.0055, 50.5303],
            [7.9931, 50.5222],
            [7.9941, 50.5147],
            [7.9827, 50.5107],
            [7.9889, 50.4982],
            [7.9791, 50.4943],
            [7.9813, 50.4828],
            [7.9874, 50.4792],
            [7.9877, 50.4726],
            [7.9946, 50.4708],
            [8.0004, 50.4638],
            [8.0096, 50.4613],
            [8.0111, 50.4531],
            [8.001, 50.4454],
            [7.9904, 50.4439],
            [7.987, 50.439],
            [7.9861, 50.4224],
            [7.9752, 50.414],
            [7.9655, 50.4138],
            [7.9676, 50.4058],
            [7.9817, 50.4079],
            [7.9951, 50.3975],
            [8.0227, 50.4],
            [8.0202, 50.3856],
            [8.022, 50.3817],
            [8.0371, 50.3818],
            [8.0473, 50.3784],
            [8.0678, 50.3651],
            [8.0677, 50.3588],
            [8.0774, 50.3473],
            [8.076, 50.3289],
            [8.0824, 50.3297],
            [8.1026, 50.3262],
            [8.107, 50.3218],
            [8.1034, 50.3093],
            [8.1162, 50.3028],
            [8.1234, 50.2901],
            [8.1218, 50.2768],
            [8.1124, 50.2705],
            [8.104, 50.2594],
            [8.0917, 50.2648],
            [8.0747, 50.267],
            [8.0687, 50.271],
            [8.0538, 50.2735],
            [8.037, 50.2707],
            [8.0352, 50.2649],
            [8.0226, 50.2573],
            [8.0326, 50.255],
            [8.0391, 50.2452],
            [8.0534, 50.2369],
            [8.0488, 50.2293],
            [8.037, 50.2224],
            [8.0333, 50.2157],
            [8.0032, 50.2243],
            [7.993, 50.2318],
            [7.9863, 50.2275],
            [7.9634, 50.2203],
            [7.9535, 50.2198],
            [7.9467, 50.2095],
            [7.9218, 50.2078],
            [7.9106, 50.2031],
            [7.9118, 50.1956],
            [7.8834, 50.1815],
            [7.8889, 50.1729],
            [7.8856, 50.1664],
            [7.8911, 50.1619],
            [7.9079, 50.1556],
            [7.9139, 50.1504],
            [7.9325, 50.1423],
            [7.9215, 50.1339],
            [7.9277, 50.1199],
            [7.9254, 50.1046],
            [7.9102, 50.1168],
            [7.8991, 50.1214],
            [7.8842, 50.1141],
            [7.8766, 50.1187],
            [7.8558, 50.1275],
            [7.8473, 50.1244],
            [7.8446, 50.112],
            [7.838, 50.1083],
            [7.8349, 50.0952],
            [7.8385, 50.0835],
            [7.8172, 50.0799],
            [7.8036, 50.0832],
            [7.7937, 50.0738],
            [7.7828, 50.067],
            [7.7754, 50.067],
            [7.7757, 50.0551],
            [7.7811, 50.0506],
            [7.8082, 50.0373],
            [7.8364, 50.019],
            [7.8537, 50.0126],
            [7.8589, 50.0067],
            [7.8645, 49.9863],
            [7.8755, 49.9736],
            [7.8937, 49.9716],
            [7.9187, 49.9742],
            [7.9377, 49.9733],
            [7.9696, 49.9776],
            [8.0128, 49.9925],
            [8.0406, 50.0034],
            [8.0734, 50.0055],
            [8.0946, 50.0106],
            [8.1104, 50.018],
            [8.128, 50.023],
            [8.1492, 50.0264],
            [8.1729, 50.0333],
            [8.1844, 50.0349],
            [8.1997, 50.034],
            [8.2369, 50.0298],
            [8.2449, 50.027],
            [8.2763, 50.0085],
            [8.2897, 49.9937],
            [8.3038, 49.9882],
            [8.3225, 49.9762],
            [8.3412, 49.9529],
            [8.3448, 49.9395],
            [8.3555, 49.9175],
            [8.3538, 49.9074],
            [8.3458, 49.8972],
            [8.3427, 49.8835],
            [8.3488, 49.8727],
            [8.3581, 49.8649],
            [8.3816, 49.8594],
            [8.3858, 49.8502],
            [8.3781, 49.8316],
            [8.38, 49.8228],
            [8.3954, 49.8092],
            [8.4086, 49.7939],
            [8.4136, 49.7856],
            [8.4152, 49.776],
            [8.4237, 49.7685],
            [8.4359, 49.7656],
            [8.4663, 49.7691],
            [8.4776, 49.7655],
            [8.4787, 49.7598],
            [8.4548, 49.7412],
            [8.438, 49.7219],
            [8.4237, 49.7163],
            [8.3939, 49.7103],
            [8.3775, 49.705],
            [8.3649, 49.6983],
            [8.3592, 49.692],
            [8.3571, 49.6796],
            [8.3624, 49.6685],
            [8.3717, 49.6592],
            [8.3792, 49.6362],
            [8.3877, 49.6212],
            [8.3944, 49.615],
            [8.4071, 49.6075],
            [8.4224, 49.5836],
            [8.4237, 49.5743],
            [8.4162, 49.5645],
            [8.4167, 49.5501],
            [8.4227, 49.5418],
            [8.4311, 49.5239],
            [8.4433, 49.4917],
            [8.4597, 49.4767],
            [8.4633, 49.4706],
            [8.4593, 49.4637],
            [8.45, 49.4613],
            [8.4426, 49.4555],
            [8.4426, 49.45],
            [8.4485, 49.4447],
            [8.4624, 49.4429],
            [8.4863, 49.4435],
            [8.5049, 49.4384],
            [8.5078, 49.4313],
            [8.4989, 49.4174],
            [8.4951, 49.4011],
            [8.464, 49.386],
            [8.463, 49.3767],
            [8.4734, 49.3733],
            [8.4919, 49.3858],
            [8.4997, 49.3882],
            [8.5003, 49.3778],
            [8.4977, 49.3664],
            [8.4872, 49.3516],
            [8.4745, 49.3425],
            [8.4566, 49.3327],
            [8.4502, 49.3247],
            [8.4506, 49.3182],
            [8.4583, 49.3115],
            [8.4746, 49.3082],
            [8.4838, 49.3033],
            [8.4883, 49.295],
            [8.4827, 49.2863],
            [8.4683, 49.2826],
            [8.4576, 49.2777],
            [8.4451, 49.2654],
            [8.4348, 49.2586],
            [8.3979, 49.242],
            [8.3905, 49.2347],
            [8.3858, 49.226],
            [8.4034, 49.2236],
            [8.4041, 49.2168],
            [8.3983, 49.207],
            [8.3839, 49.1962],
            [8.3811, 49.1867],
            [8.3679, 49.1691],
            [8.3667, 49.1635],
            [8.3717, 49.1503],
            [8.368, 49.1335],
            [8.3588, 49.1009],
            [8.3528, 49.091],
            [8.3394, 49.0796],
            [8.327, 49.0711],
            [8.3176, 49.0612],
            [8.3082, 49.0478],
            [8.3016, 49.0345],
            [8.2974, 49.0141],
            [8.2842, 48.9954],
            [8.271, 48.9863],
            [8.2324, 48.9662],
            [8.2255, 48.9766],
            [8.2178, 48.9768],
            [8.1999, 48.9678],
            [8.1949, 48.9781],
            [8.1811, 48.976],
            [8.1591, 48.9755],
            [8.1402, 48.9792],
            [8.1243, 48.9861],
            [8.0928, 48.9901],
            [8.0659, 49.0008],
            [8.0641, 49.0061],
            [8.0515, 49.0144],
            [8.0378, 49.0164],
            [8.0329, 49.0194],
            [8.023, 49.0189],
            [8.0135, 49.0247],
            [7.9938, 49.0296],
            [7.9764, 49.0278],
            [7.9736, 49.0375],
            [7.9477, 49.0503],
            [7.9481, 49.0555],
            [7.9337, 49.055],
            [7.9254, 49.0446],
            [7.9138, 49.0408],
            [7.9034, 49.0472],
            [7.8883, 49.0489],
            [7.8803, 49.0456],
            [7.8662, 49.0337],
            [7.8536, 49.0366],
            [7.8374, 49.0472],
            [7.8292, 49.0479],
            [7.8248, 49.0528],
            [7.813, 49.0562],
            [7.8088, 49.0614],
            [7.794, 49.0659],
            [7.7903, 49.0593],
            [7.7802, 49.0582],
            [7.7664, 49.0466],
            [7.7419, 49.0469],
            [7.7329, 49.0441],
            [7.7322, 49.0566],
            [7.709, 49.0529],
            [7.7017, 49.0572],
            [7.6925, 49.0563],
            [7.6966, 49.0494],
            [7.6806, 49.0488],
            [7.6734, 49.0446],
            [7.6577, 49.0495],
            [7.6331, 49.0546],
            [7.6341, 49.0627],
            [7.6314, 49.0729],
            [7.6154, 49.0759],
            [7.6, 49.0831],
            [7.5675, 49.0794],
            [7.5431, 49.0881],
            [7.5279, 49.0971],
            [7.5291, 49.1029],
            [7.5213, 49.1075],
            [7.5198, 49.1144],
            [7.5125, 49.1222],
            [7.5063, 49.1223],
            [7.4999, 49.1306],
            [7.5007, 49.1546],
            [7.4956, 49.1548],
            [7.4923, 49.1638],
            [7.4938, 49.1684],
            [7.4809, 49.169],
            [7.468, 49.167],
            [7.4593, 49.1625],
            [7.4552, 49.1663],
            [7.4448, 49.1677],
            [7.4372, 49.1793],
            [7.4426, 49.1833],
            [7.3868, 49.1754],
            [7.3646, 49.1709],
            [7.366, 49.1602],
            [7.3628, 49.1578],
            [7.3474, 49.165],
            [7.343, 49.1724],
            [7.3353, 49.1755],
            [7.3384, 49.1839],
            [7.3183, 49.1903],
            [7.3052, 49.2025],
            [7.2986, 49.2136],
            [7.3039, 49.223],
            [7.2933, 49.2272],
            [7.2908, 49.239],
            [7.3031, 49.2447],
            [7.3072, 49.2565],
            [7.3236, 49.2474],
            [7.3275, 49.2553],
            [7.3255, 49.2603],
            [7.3391, 49.2638],
            [7.3435, 49.2735],
            [7.3428, 49.281],
            [7.3525, 49.2829],
            [7.3617, 49.278],
            [7.3717, 49.2806],
            [7.386, 49.2938],
            [7.3804, 49.2986],
            [7.3811, 49.3068],
            [7.3948, 49.3146],
            [7.3858, 49.3231],
            [7.3921, 49.3272],
            [7.3976, 49.3357],
            [7.4025, 49.3365],
            [7.4031, 49.3461],
            [7.3939, 49.3494],
            [7.3999, 49.3571],
            [7.3925, 49.3633],
            [7.4001, 49.3667],
            [7.3958, 49.3714],
            [7.38, 49.3661],
            [7.3671, 49.364],
            [7.3693, 49.3734],
            [7.3567, 49.3735],
            [7.355, 49.3794],
            [7.3437, 49.3803],
            [7.3326, 49.3786],
            [7.3208, 49.3806],
            [7.3036, 49.3867],
            [7.2899, 49.3896],
            [7.2967, 49.4046],
            [7.2895, 49.4164],
            [7.2822, 49.4177],
            [7.2725, 49.4241],
            [7.2607, 49.4241],
            [7.2516, 49.4333],
            [7.255, 49.4401],
            [7.2514, 49.4478],
            [7.2671, 49.453],
            [7.2867, 49.4575],
            [7.2898, 49.4627],
            [7.3018, 49.4673],
            [7.3054, 49.4748],
            [7.2966, 49.4833],
            [7.2813, 49.4837],
            [7.2836, 49.4968],
            [7.2873, 49.5005],
            [7.2814, 49.5064],
            [7.2883, 49.5137],
            [7.2877, 49.5178],
            [7.2961, 49.5234],
            [7.303, 49.5204],
            [7.308, 49.5249],
            [7.2995, 49.53],
            [7.2765, 49.5364],
            [7.2765, 49.5481],
            [7.2733, 49.5521],
            [7.2734, 49.5612],
            [7.2627, 49.5741],
            [7.2423, 49.5738],
            [7.2282, 49.5655],
            [7.2217, 49.5645],
            [7.211, 49.5678],
            [7.2145, 49.5738],
            [7.2101, 49.5773],
            [7.1864, 49.576],
            [7.1827, 49.5826],
            [7.1745, 49.5852],
            [7.1742, 49.5976],
            [7.1607, 49.6009],
            [7.1432, 49.6013],
            [7.139, 49.5975],
            [7.1226, 49.5952],
            [7.1008, 49.6027],
            [7.0851, 49.6034],
            [7.0808, 49.6123],
            [7.069, 49.6238],
            [7.0582, 49.6312],
            [7.0337, 49.6367],
            [7.0285, 49.6399],
            [7.0131, 49.629],
            [7.0036, 49.6321],
            [6.988, 49.6253],
            [6.9881, 49.6335],
            [6.9777, 49.6364],
            [6.9654, 49.6304],
            [6.9497, 49.6266],
            [6.9479, 49.6348],
            [6.941, 49.6348],
            [6.9335, 49.6298],
            [6.9376, 49.6262],
            [6.9363, 49.6181],
            [6.9257, 49.6149],
            [6.9144, 49.6167],
            [6.9059, 49.6143],
            [6.8958, 49.6165],
            [6.8865, 49.613],
            [6.8806, 49.6073],
            [6.8713, 49.6089],
            [6.8585, 49.6055],
            [6.8459, 49.591],
            [6.8361, 49.5834],
            [6.8216, 49.5872],
            [6.8143, 49.5917],
            [6.7984, 49.5862],
            [6.7794, 49.5699],
            [6.7662, 49.5682],
            [6.7569, 49.5609],
            [6.7481, 49.5575],
            [6.7346, 49.5601],
            [6.7229, 49.5558],
            [6.7106, 49.5537],
            [6.6998, 49.5411],
            [6.6881, 49.5382],
            [6.6848, 49.5478],
            [6.6661, 49.5431],
            [6.6522, 49.5429],
            [6.6472, 49.5385],
            [6.6342, 49.5415],
            [6.6174, 49.5351],
            [6.6185, 49.5307],
            [6.6066, 49.5281],
            [6.6078, 49.5186],
            [6.5813, 49.5211],
            [6.5782, 49.5298],
            [6.5633, 49.5407],
            [6.5555, 49.5361],
            [6.54, 49.5388],
            [6.5272, 49.5304],
            [6.5152, 49.5279],
            [6.5132, 49.5349],
            [6.4994, 49.5302],
            [6.4866, 49.5336],
            [6.4752, 49.5435],
            [6.4644, 49.544],
            [6.4527, 49.5383],
            [6.4299, 49.5486],
            [6.417, 49.5432],
            [6.4134, 49.5469],
            [6.3966, 49.5471],
            [6.3798, 49.5512],
            [6.3818, 49.5575],
            [6.3613, 49.5678],
            [6.3591, 49.5745],
            [6.3678, 49.5788],
            [6.38, 49.5767],
            [6.3809, 49.5818],
            [6.3753, 49.5862],
            [6.3769, 49.5935],
            [6.3874, 49.6002],
            [6.3974, 49.6016],
            [6.4233, 49.6223],
            [6.4293, 49.6359],
            [6.4393, 49.6507],
            [6.4409, 49.6582],
            [6.4281, 49.6604],
            [6.4304, 49.6688],
            [6.4492, 49.6791],
            [6.4602, 49.6916],
            [6.4793, 49.696],
            [6.5068, 49.7167],
            [6.4957, 49.7233],
            [6.5025, 49.7269],
            [6.5108, 49.7214],
            [6.5167, 49.7249],
            [6.5028, 49.7321],
            [6.5014, 49.7527],
            [6.5167, 49.7601],
            [6.5173, 49.7685],
            [6.5114, 49.7737],
            [6.5173, 49.782],
            [6.5063, 49.7908],
            [6.5191, 49.796],
            [6.5303, 49.8059],
            [6.5235, 49.8125],
            [6.5151, 49.8027],
            [6.5061, 49.8088],
            [6.4845, 49.8141],
            [6.4715, 49.8227],
            [6.4543, 49.8115],
            [6.4416, 49.8141],
            [6.4327, 49.8107],
            [6.4255, 49.816],
            [6.4126, 49.8174],
            [6.3978, 49.8216],
            [6.3823, 49.8362],
            [6.362, 49.8508],
            [6.3483, 49.8503],
            [6.3395, 49.8469],
            [6.3377, 49.8397],
            [6.3285, 49.8363],
            [6.3203, 49.8407],
            [6.322, 49.8527],
            [6.314, 49.8603],
            [6.3122, 49.8687],
            [6.2994, 49.8661],
            [6.2913, 49.8744],
            [6.282, 49.8783],
            [6.2745, 49.8764],
            [6.2609, 49.8807],
            [6.2473, 49.8958],
            [6.234, 49.8995],
            [6.2307, 49.9075],
            [6.234, 49.9131],
            [6.2302, 49.9201],
            [6.2201, 49.9217],
            [6.2263, 49.9284],
            [6.2275, 49.9368],
            [6.2061, 49.9548],
            [6.1942, 49.9558],
            [6.1926, 49.967],
            [6.1826, 49.9637],
            [6.1871, 49.9534],
            [6.1773, 49.9533],
            [6.1754, 49.9615],
            [6.1693, 49.963],
            [6.1649, 49.9698],
            [6.1695, 49.9841],
            [6.157, 49.9869],
            [6.15, 49.9942],
            [6.1427, 49.9946],
            [6.1525, 50.0062],
            [6.1523, 50.0093],
            [6.1398, 50.0123],
            [6.1484, 50.0213],
            [6.1469, 50.0242],
            [6.1327, 50.0299],
            [6.1363, 50.0408],
            [6.1232, 50.0523],
            [6.1137, 50.056],
            [6.1136, 50.0657],
            [6.1315, 50.0726],
            [6.1244, 50.0774],
            [6.1215, 50.0863],
            [6.1238, 50.0923],
            [6.1356, 50.1015],
            [6.1333, 50.1072],
            [6.137, 50.1137],
            [6.1272, 50.1151],
            [6.1296, 50.122],
            [6.1359, 50.1241],
            [6.1408, 50.1355],
            [6.1491, 50.1369],
            [6.155, 50.1421],
            [6.1525, 50.1499],
            [6.1439, 50.1485],
            [6.1348, 50.1548],
            [6.1443, 50.1569],
            [6.148, 50.1619],
            [6.1422, 50.1686],
            [6.1497, 50.1779],
            [6.1571, 50.1723],
            [6.166, 50.1786],
            [6.1866, 50.1795],
            [6.1918, 50.1828],
            [6.186, 50.1922],
            [6.188, 50.201],
            [6.1786, 50.2175],
            [6.1685, 50.2201],
            [6.1789, 50.2291],
            [6.1751, 50.2328],
            [6.1844, 50.2374],
            [6.1935, 50.2365],
            [6.201, 50.2397],
            [6.2092, 50.253],
            [6.2378, 50.2627],
            [6.248, 50.2625],
            [6.2561, 50.2653],
            [6.2808, 50.267],
            [6.2927, 50.2786],
            [6.2866, 50.2829],
            [6.2921, 50.2959],
            [6.2988, 50.3037],
            [6.2963, 50.308],
            [6.309, 50.3104],
            [6.3074, 50.318],
            [6.3199, 50.319],
            [6.3291, 50.3232],
            [6.3433, 50.3174],
            [6.362, 50.3037],
            [6.3606, 50.311],
            [6.3731, 50.312],
            [6.3841, 50.3207],
            [6.4052, 50.3221],
          ],
        ],
      },
      properties: {name: 'Rhineland-Palatinate', id: 'DE-RP', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-RP',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [8.5252, 54.756],
              [8.5579, 54.7546],
              [8.5722, 54.7519],
              [8.5866, 54.7455],
              [8.5881, 54.7395],
              [8.5851, 54.7266],
              [8.599, 54.7184],
              [8.586, 54.7125],
              [8.5769, 54.7022],
              [8.5754, 54.6911],
              [8.567, 54.6792],
              [8.555, 54.6783],
              [8.5277, 54.6807],
              [8.5017, 54.6798],
              [8.4808, 54.6807],
              [8.4624, 54.6852],
              [8.4513, 54.6923],
              [8.4244, 54.6946],
              [8.3961, 54.7038],
              [8.3932, 54.7111],
              [8.3952, 54.719],
              [8.4088, 54.7366],
              [8.4274, 54.7469],
              [8.451, 54.749],
              [8.4675, 54.7528],
              [8.4835, 54.7499],
              [8.5054, 54.7522],
              [8.5252, 54.756],
            ],
          ],
          [
            [
              [10.9038, 53.9565],
              [10.9086, 53.946],
              [10.8979, 53.939],
              [10.8929, 53.9322],
              [10.8935, 53.9228],
              [10.9116, 53.9227],
              [10.9365, 53.9184],
              [10.9485, 53.9124],
              [10.947, 53.905],
              [10.9134, 53.8983],
              [10.9038, 53.9018],
              [10.903, 53.9072],
              [10.9126, 53.9154],
              [10.8967, 53.9178],
              [10.8879, 53.9243],
              [10.8807, 53.9249],
              [10.8716, 53.9146],
              [10.8454, 53.9044],
              [10.8464, 53.8995],
              [10.8169, 53.8941],
              [10.8134, 53.8872],
              [10.8014, 53.8764],
              [10.772, 53.8728],
              [10.7609, 53.8614],
              [10.7503, 53.8448],
              [10.7488, 53.8328],
              [10.763, 53.8226],
              [10.7669, 53.8014],
              [10.7736, 53.7928],
              [10.7675, 53.7807],
              [10.7675, 53.7723],
              [10.7588, 53.7642],
              [10.7587, 53.748],
              [10.7917, 53.7445],
              [10.8091, 53.7351],
              [10.8107, 53.7217],
              [10.8249, 53.7123],
              [10.8521, 53.7032],
              [10.8635, 53.7048],
              [10.8724, 53.7019],
              [10.8863, 53.7057],
              [10.9232, 53.6993],
              [10.9302, 53.687],
              [10.9422, 53.6847],
              [10.9444, 53.6747],
              [10.9395, 53.6669],
              [10.9448, 53.6599],
              [10.9548, 53.6589],
              [10.9524, 53.6483],
              [10.9426, 53.6375],
              [10.9295, 53.629],
              [10.9238, 53.6072],
              [10.9171, 53.6],
              [10.9234, 53.583],
              [10.9108, 53.571],
              [10.8949, 53.5728],
              [10.8791, 53.5676],
              [10.8506, 53.5643],
              [10.839, 53.5761],
              [10.8246, 53.5734],
              [10.8282, 53.5661],
              [10.8297, 53.5524],
              [10.8342, 53.542],
              [10.8208, 53.5332],
              [10.8254, 53.5132],
              [10.8119, 53.5156],
              [10.7876, 53.5101],
              [10.7788, 53.4951],
              [10.7652, 53.4902],
              [10.7568, 53.4827],
              [10.7277, 53.4797],
              [10.721, 53.4761],
              [10.7023, 53.4779],
              [10.6928, 53.4554],
              [10.6537, 53.4607],
              [10.6413, 53.4549],
              [10.6355, 53.4551],
              [10.6276, 53.4444],
              [10.6186, 53.4107],
              [10.6216, 53.403],
              [10.6161, 53.3823],
              [10.5979, 53.3752],
              [10.5953, 53.3642],
              [10.58, 53.3599],
              [10.5663, 53.3626],
              [10.5561, 53.3687],
              [10.522, 53.3703],
              [10.4941, 53.3758],
              [10.4642, 53.3874],
              [10.4384, 53.3941],
              [10.3959, 53.4122],
              [10.3781, 53.424],
              [10.3658, 53.4266],
              [10.3311, 53.4249],
              [10.3085, 53.4335],
              [10.3182, 53.4353],
              [10.3167, 53.4409],
              [10.3262, 53.449],
              [10.3165, 53.4525],
              [10.3009, 53.4503],
              [10.2924, 53.4522],
              [10.2837, 53.4596],
              [10.2711, 53.4647],
              [10.2657, 53.4748],
              [10.2409, 53.4825],
              [10.2361, 53.4901],
              [10.2379, 53.4962],
              [10.2235, 53.5086],
              [10.2145, 53.5139],
              [10.2111, 53.521],
              [10.2023, 53.5153],
              [10.1873, 53.5123],
              [10.1617, 53.5227],
              [10.1641, 53.537],
              [10.1555, 53.5364],
              [10.1463, 53.5654],
              [10.1561, 53.5795],
              [10.1671, 53.5825],
              [10.1924, 53.584],
              [10.1987, 53.5861],
              [10.1914, 53.5961],
              [10.1972, 53.6],
              [10.1908, 53.6127],
              [10.218, 53.6262],
              [10.221, 53.635],
              [10.1988, 53.6361],
              [10.1912, 53.6389],
              [10.1989, 53.6464],
              [10.1953, 53.6543],
              [10.185, 53.6638],
              [10.1758, 53.6685],
              [10.1571, 53.6701],
              [10.1546, 53.6748],
              [10.1441, 53.6777],
              [10.1601, 53.6907],
              [10.1617, 53.6989],
              [10.1576, 53.7043],
              [10.1748, 53.7122],
              [10.1827, 53.7094],
              [10.1928, 53.7239],
              [10.1874, 53.7312],
              [10.1688, 53.7386],
              [10.162, 53.7332],
              [10.151, 53.73],
              [10.1266, 53.7196],
              [10.1213, 53.7134],
              [10.1037, 53.7149],
              [10.0939, 53.7193],
              [10.0808, 53.7204],
              [10.0707, 53.7059],
              [10.0779, 53.7044],
              [10.0657, 53.6893],
              [10.0651, 53.678],
              [10.0529, 53.6776],
              [10.0406, 53.6824],
              [10.0005, 53.682],
              [9.9894, 53.6713],
              [9.9902, 53.6604],
              [9.9841, 53.6516],
              [9.962, 53.6496],
              [9.9242, 53.6534],
              [9.919, 53.656],
              [9.9062, 53.6525],
              [9.905, 53.6424],
              [9.8955, 53.6307],
              [9.8688, 53.6142],
              [9.8685, 53.6083],
              [9.8603, 53.6026],
              [9.8602, 53.5983],
              [9.8396, 53.5946],
              [9.8359, 53.5867],
              [9.8212, 53.5843],
              [9.7995, 53.5968],
              [9.7927, 53.6036],
              [9.7958, 53.611],
              [9.7723, 53.6172],
              [9.7694, 53.6284],
              [9.7645, 53.628],
              [9.7586, 53.616],
              [9.7589, 53.6065],
              [9.749, 53.6042],
              [9.7432, 53.5974],
              [9.7468, 53.5888],
              [9.7395, 53.5813],
              [9.7351, 53.5643],
              [9.7299, 53.557],
              [9.7013, 53.5587],
              [9.6729, 53.5653],
              [9.6337, 53.5817],
              [9.6041, 53.5922],
              [9.5783, 53.6047],
              [9.5498, 53.6173],
              [9.537, 53.6317],
              [9.5321, 53.6432],
              [9.5309, 53.6553],
              [9.5144, 53.6836],
              [9.4979, 53.7006],
              [9.4747, 53.713],
              [9.4429, 53.7269],
              [9.4199, 53.7439],
              [9.4056, 53.7606],
              [9.3716, 53.8043],
              [9.3547, 53.8212],
              [9.3364, 53.8349],
              [9.3197, 53.8506],
              [9.2939, 53.8619],
              [9.2595, 53.871],
              [9.2339, 53.875],
              [9.2301, 53.8773],
              [9.2091, 53.8796],
              [9.1669, 53.8797],
              [9.1449, 53.8776],
              [9.0931, 53.8799],
              [9.0516, 53.8809],
              [9.0292, 53.8801],
              [9.0042, 53.8744],
              [8.9887, 53.8916],
              [8.977, 53.891],
              [8.962, 53.8981],
              [8.9428, 53.9094],
              [8.9369, 53.9151],
              [8.9227, 53.9207],
              [8.9059, 53.9346],
              [8.8953, 53.9492],
              [8.8932, 53.9592],
              [8.8781, 53.9724],
              [8.8829, 53.9786],
              [8.8737, 53.9821],
              [8.8511, 54.0066],
              [8.8337, 54.0232],
              [8.8438, 54.0376],
              [8.8521, 54.0407],
              [8.8799, 54.0456],
              [8.9213, 54.0407],
              [8.9335, 54.031],
              [8.9687, 54.0371],
              [8.9797, 54.0447],
              [8.9852, 54.0567],
              [8.9786, 54.0692],
              [8.9485, 54.0907],
              [8.9465, 54.0961],
              [8.9281, 54.1304],
              [8.9103, 54.1343],
              [8.8919, 54.1322],
              [8.8604, 54.1187],
              [8.8523, 54.1284],
              [8.8411, 54.1313],
              [8.8279, 54.1429],
              [8.8165, 54.1673],
              [8.809, 54.1707],
              [8.806, 54.1773],
              [8.8147, 54.1981],
              [8.8215, 54.2077],
              [8.8334, 54.2152],
              [8.8392, 54.2304],
              [8.8336, 54.2398],
              [8.8391, 54.2521],
              [8.8443, 54.2572],
              [8.8428, 54.2777],
              [8.8317, 54.2889],
              [8.8229, 54.2909],
              [8.802, 54.283],
              [8.7834, 54.2803],
              [8.7701, 54.2832],
              [8.7687, 54.2882],
              [8.7434, 54.2912],
              [8.7193, 54.2862],
              [8.7033, 54.2855],
              [8.6964, 54.2765],
              [8.6777, 54.2658],
              [8.6448, 54.2748],
              [8.6347, 54.2841],
              [8.6221, 54.2918],
              [8.6095, 54.296],
              [8.6019, 54.2879],
              [8.5899, 54.2937],
              [8.5795, 54.3077],
              [8.5852, 54.3288],
              [8.5926, 54.3448],
              [8.6063, 54.3541],
              [8.6154, 54.3506],
              [8.6173, 54.3452],
              [8.6314, 54.3419],
              [8.6381, 54.347],
              [8.6493, 54.3482],
              [8.6536, 54.3532],
              [8.6733, 54.3532],
              [8.6772, 54.3598],
              [8.6758, 54.3661],
              [8.6642, 54.3699],
              [8.6478, 54.3653],
              [8.6162, 54.3669],
              [8.6048, 54.3699],
              [8.6009, 54.378],
              [8.6062, 54.3841],
              [8.6396, 54.397],
              [8.6646, 54.3968],
              [8.6706, 54.4005],
              [8.7236, 54.403],
              [8.7261, 54.4068],
              [8.8204, 54.4147],
              [8.8265, 54.4108],
              [8.8532, 54.4095],
              [8.8681, 54.4073],
              [8.8777, 54.4112],
              [8.8914, 54.4128],
              [8.9201, 54.4275],
              [8.9182, 54.4292],
              [8.9383, 54.4392],
              [8.9355, 54.4416],
              [8.9559, 54.4534],
              [9.0006, 54.463],
              [9.0008, 54.4698],
              [9.0064, 54.4766],
              [9.0018, 54.4794],
              [9.0098, 54.4855],
              [8.989, 54.515],
              [8.9837, 54.5196],
              [8.9637, 54.5153],
              [8.9584, 54.5109],
              [8.9617, 54.502],
              [8.9536, 54.5007],
              [8.9558, 54.4945],
              [8.9404, 54.491],
              [8.9408, 54.4801],
              [8.935, 54.4773],
              [8.9297, 54.4691],
              [8.8993, 54.4612],
              [8.8962, 54.4591],
              [8.8436, 54.4597],
              [8.8256, 54.4624],
              [8.8052, 54.4698],
              [8.8056, 54.4742],
              [8.817, 54.4855],
              [8.8075, 54.4897],
              [8.8041, 54.4958],
              [8.8131, 54.5041],
              [8.8367, 54.5077],
              [8.8511, 54.5148],
              [8.8681, 54.5258],
              [8.8754, 54.5364],
              [8.8768, 54.5452],
              [8.8692, 54.5635],
              [8.8871, 54.589],
              [8.8684, 54.5993],
              [8.8511, 54.5974],
              [8.8475, 54.6],
              [8.8335, 54.5993],
              [8.8222, 54.5946],
              [8.8126, 54.5969],
              [8.814, 54.602],
              [8.8338, 54.6083],
              [8.8405, 54.6178],
              [8.8279, 54.6275],
              [8.8257, 54.6409],
              [8.8163, 54.6527],
              [8.7965, 54.6608],
              [8.7756, 54.6672],
              [8.7515, 54.6805],
              [8.7485, 54.6887],
              [8.7501, 54.6991],
              [8.7441, 54.7067],
              [8.7303, 54.7131],
              [8.7103, 54.7177],
              [8.7099, 54.7228],
              [8.6928, 54.7238],
              [8.6908, 54.7278],
              [8.7054, 54.7379],
              [8.7002, 54.7522],
              [8.6931, 54.7606],
              [8.6927, 54.7686],
              [8.6786, 54.7824],
              [8.6751, 54.7893],
              [8.6606, 54.7893],
              [8.645, 54.8108],
              [8.6355, 54.818],
              [8.6155, 54.8291],
              [8.6042, 54.8394],
              [8.6073, 54.863],
              [8.6026, 54.8663],
              [8.6027, 54.8793],
              [8.5974, 54.8824],
              [8.5808, 54.884],
              [8.5212, 54.8823],
              [8.5013, 54.8786],
              [8.4836, 54.8723],
              [8.4547, 54.8568],
              [8.4361, 54.8512],
              [8.4132, 54.846],
              [8.4013, 54.8475],
              [8.3764, 54.8579],
              [8.3633, 54.8747],
              [8.3473, 54.8749],
              [8.3393, 54.8784],
              [8.3339, 54.8722],
              [8.3307, 54.8613],
              [8.3096, 54.8583],
              [8.2995, 54.8494],
              [8.2962, 54.8417],
              [8.2971, 54.8325],
              [8.2941, 54.8259],
              [8.3011, 54.8182],
              [8.2976, 54.8108],
              [8.2998, 54.8017],
              [8.2948, 54.798],
              [8.2922, 54.7884],
              [8.2964, 54.7759],
              [8.3013, 54.7764],
              [8.2991, 54.7582],
              [8.2944, 54.7548],
              [8.2989, 54.741],
              [8.2932, 54.7391],
              [8.2774, 54.7528],
              [8.281, 54.795],
              [8.2803, 54.8055],
              [8.2835, 54.8294],
              [8.2841, 54.8484],
              [8.2884, 54.8879],
              [8.2963, 54.91],
              [8.3046, 54.9258],
              [8.3214, 54.9504],
              [8.3247, 54.9572],
              [8.3451, 54.9822],
              [8.3511, 54.9928],
              [8.3629, 55.0077],
              [8.3687, 55.0178],
              [8.3882, 55.045],
              [8.3965, 55.0533],
              [8.4126, 55.0573],
              [8.431, 55.0549],
              [8.4383, 55.0504],
              [8.4647, 55.0468],
              [8.464, 55.0443],
              [8.4443, 55.0421],
              [8.4357, 55.0472],
              [8.3984, 55.0458],
              [8.4006, 55.035],
              [8.4199, 55.0355],
              [8.4283, 55.0321],
              [8.4311, 55.0259],
              [8.442, 55.0228],
              [8.4402, 55.0144],
              [8.4332, 55.0138],
              [8.4181, 55.0067],
              [8.3975, 55.0038],
              [8.3822, 54.9971],
              [8.3719, 54.9896],
              [8.3641, 54.9775],
              [8.353, 54.9674],
              [8.3602, 54.9583],
              [8.3589, 54.9503],
              [8.3635, 54.943],
              [8.361, 54.9368],
              [8.363, 54.9268],
              [8.3632, 54.9095],
              [8.3758, 54.8942],
              [8.3938, 54.8892],
              [8.4148, 54.881],
              [8.4352, 54.8769],
              [8.4708, 54.8785],
              [8.4884, 54.877],
              [8.5031, 54.8819],
              [8.5171, 54.8844],
              [8.6001, 54.8874],
              [8.6016, 54.8896],
              [8.627, 54.8946],
              [8.6344, 54.8988],
              [8.6344, 54.9114],
              [8.6632, 54.91],
              [8.6711, 54.912],
              [8.6873, 54.9105],
              [8.7101, 54.9],
              [8.7259, 54.8946],
              [8.7497, 54.8973],
              [8.7741, 54.8931],
              [8.7775, 54.8968],
              [8.7898, 54.8989],
              [8.7947, 54.9032],
              [8.8059, 54.9062],
              [8.8149, 54.9045],
              [8.829, 54.9069],
              [8.8394, 54.905],
              [8.8465, 54.8995],
              [8.8584, 54.8973],
              [8.8654, 54.9004],
              [8.8971, 54.905],
              [8.946, 54.9027],
              [8.9635, 54.8973],
              [8.9777, 54.8956],
              [8.9848, 54.8919],
              [9.0097, 54.8865],
              [9.0306, 54.8774],
              [9.0473, 54.8723],
              [9.0546, 54.8736],
              [9.0654, 54.8707],
              [9.0899, 54.8695],
              [9.1088, 54.875],
              [9.1206, 54.8732],
              [9.135, 54.8742],
              [9.1557, 54.871],
              [9.1846, 54.8624],
              [9.1935, 54.8584],
              [9.2103, 54.8576],
              [9.2342, 54.8508],
              [9.2428, 54.8428],
              [9.2357, 54.8367],
              [9.236, 54.8286],
              [9.2468, 54.8133],
              [9.2517, 54.81],
              [9.2715, 54.8123],
              [9.293, 54.8076],
              [9.3288, 54.8056],
              [9.3424, 54.8003],
              [9.3551, 54.8133],
              [9.3725, 54.8192],
              [9.3732, 54.8276],
              [9.3813, 54.8377],
              [9.4082, 54.842],
              [9.4175, 54.8383],
              [9.417, 54.8329],
              [9.4274, 54.8147],
              [9.4367, 54.8079],
              [9.4383, 54.8032],
              [9.4522, 54.8076],
              [9.4568, 54.8164],
              [9.4661, 54.823],
              [9.4926, 54.8221],
              [9.5014, 54.8251],
              [9.5058, 54.8322],
              [9.5151, 54.8378],
              [9.5265, 54.8399],
              [9.5409, 54.8485],
              [9.5626, 54.8533],
              [9.5799, 54.8631],
              [9.588, 54.876],
              [9.6101, 54.8751],
              [9.6009, 54.867],
              [9.5911, 54.8548],
              [9.5898, 54.8456],
              [9.6049, 54.8306],
              [9.6217, 54.8315],
              [9.6526, 54.8206],
              [9.6624, 54.8231],
              [9.6832, 54.8177],
              [9.7105, 54.808],
              [9.7602, 54.8004],
              [9.7719, 54.7954],
              [9.7945, 54.7959],
              [9.8003, 54.7861],
              [9.8344, 54.7591],
              [9.8531, 54.7538],
              [9.8737, 54.7517],
              [9.8783, 54.7531],
              [9.882, 54.7627],
              [9.8887, 54.7679],
              [9.9035, 54.7663],
              [9.9004, 54.7723],
              [9.9054, 54.7824],
              [9.9028, 54.7882],
              [9.9063, 54.7968],
              [9.9336, 54.7885],
              [9.9497, 54.7805],
              [9.9576, 54.779],
              [9.9663, 54.7672],
              [9.9747, 54.7604],
              [9.979, 54.7411],
              [9.9841, 54.7256],
              [9.9892, 54.718],
              [9.9985, 54.7105],
              [10.0073, 54.708],
              [10.0156, 54.7012],
              [10.0336, 54.6913],
              [10.0381, 54.6857],
              [10.0339, 54.6746],
              [10.0276, 54.6779],
              [10.0355, 54.6841],
              [10.021, 54.6921],
              [9.9991, 54.6865],
              [9.999, 54.6715],
              [10.0103, 54.6614],
              [10.0264, 54.6576],
              [10.0333, 54.6598],
              [10.0327, 54.6342],
              [10.0351, 54.6191],
              [10.0284, 54.6051],
              [10.0263, 54.5816],
              [10.0263, 54.5652],
              [10.0282, 54.5508],
              [10.0171, 54.545],
              [9.9998, 54.5213],
              [9.9764, 54.5113],
              [9.9696, 54.5053],
              [9.955, 54.5012],
              [9.9388, 54.4934],
              [9.9171, 54.4891],
              [9.8826, 54.4767],
              [9.8648, 54.4728],
              [9.8558, 54.4763],
              [9.8416, 54.4749],
              [9.842, 54.4669],
              [9.8483, 54.4588],
              [9.859, 54.4504],
              [9.867, 54.4479],
              [9.8899, 54.4509],
              [9.9149, 54.4523],
              [9.9327, 54.4622],
              [9.9734, 54.4682],
              [9.9989, 54.4684],
              [10.0089, 54.4723],
              [10.0258, 54.475],
              [10.0506, 54.4766],
              [10.069, 54.4796],
              [10.0858, 54.4797],
              [10.1063, 54.483],
              [10.1269, 54.4846],
              [10.1382, 54.4831],
              [10.1629, 54.4734],
              [10.1898, 54.4571],
              [10.2015, 54.4553],
              [10.1966, 54.4489],
              [10.1849, 54.4456],
              [10.1712, 54.4307],
              [10.1903, 54.411],
              [10.1935, 54.4025],
              [10.1895, 54.3887],
              [10.1779, 54.389],
              [10.1627, 54.3842],
              [10.1676, 54.3774],
              [10.1608, 54.3751],
              [10.1562, 54.3677],
              [10.1442, 54.3687],
              [10.1464, 54.3601],
              [10.1428, 54.3527],
              [10.1548, 54.3459],
              [10.1586, 54.3371],
              [10.1464, 54.3244],
              [10.1596, 54.3239],
              [10.1716, 54.3292],
              [10.1748, 54.3352],
              [10.1789, 54.3609],
              [10.194, 54.3649],
              [10.2004, 54.3803],
              [10.2166, 54.3985],
              [10.2159, 54.4038],
              [10.2279, 54.4137],
              [10.2447, 54.4159],
              [10.2813, 54.4178],
              [10.2902, 54.4201],
              [10.3047, 54.433],
              [10.3253, 54.4354],
              [10.354, 54.4324],
              [10.3987, 54.4237],
              [10.4196, 54.4129],
              [10.4815, 54.3896],
              [10.5109, 54.3853],
              [10.5444, 54.3787],
              [10.575, 54.3691],
              [10.5949, 54.3654],
              [10.6312, 54.3517],
              [10.6444, 54.3429],
              [10.6515, 54.3312],
              [10.668, 54.3241],
              [10.6789, 54.322],
              [10.6794, 54.3156],
              [10.6854, 54.3103],
              [10.7042, 54.3046],
              [10.7185, 54.3058],
              [10.7377, 54.31],
              [10.754, 54.3064],
              [10.764, 54.3067],
              [10.7903, 54.3124],
              [10.8169, 54.3238],
              [10.8378, 54.3352],
              [10.8474, 54.3431],
              [10.8697, 54.3573],
              [10.8862, 54.3634],
              [10.8986, 54.3645],
              [10.9126, 54.3701],
              [10.9313, 54.3819],
              [10.9462, 54.3834],
              [10.9776, 54.3804],
              [11.019, 54.3799],
              [11.0155, 54.3758],
              [11.0004, 54.3743],
              [11.0142, 54.3684],
              [11.0256, 54.3681],
              [11.0593, 54.3752],
              [11.0732, 54.3758],
              [11.0884, 54.3912],
              [11.1047, 54.3943],
              [11.1094, 54.3885],
              [11.1202, 54.3916],
              [11.1321, 54.3875],
              [11.1261, 54.3722],
              [11.1129, 54.3676],
              [11.1044, 54.3614],
              [11.0914, 54.3581],
              [11.0818, 54.3524],
              [11.0687, 54.3575],
              [11.0576, 54.3529],
              [11.0621, 54.3443],
              [11.0713, 54.3407],
              [11.0756, 54.3053],
              [11.0848, 54.2792],
              [11.0821, 54.2528],
              [11.0829, 54.2412],
              [11.0912, 54.2187],
              [11.0914, 54.1968],
              [11.0831, 54.1969],
              [11.0646, 54.19],
              [11.0491, 54.1781],
              [11.0198, 54.1682],
              [10.9976, 54.1591],
              [10.9806, 54.1506],
              [10.9523, 54.1401],
              [10.9267, 54.1185],
              [10.9031, 54.1041],
              [10.8904, 54.0931],
              [10.879, 54.0877],
              [10.8633, 54.0841],
              [10.8544, 54.0885],
              [10.824, 54.0882],
              [10.8115, 54.0968],
              [10.8033, 54.0938],
              [10.8002, 54.0838],
              [10.7913, 54.0744],
              [10.7632, 54.0604],
              [10.7518, 54.0483],
              [10.7514, 54.0366],
              [10.7724, 54.0091],
              [10.7851, 53.9983],
              [10.7977, 53.993],
              [10.8226, 53.9949],
              [10.8453, 53.9924],
              [10.8608, 53.994],
              [10.8754, 53.9893],
              [10.8831, 53.9831],
              [10.8851, 53.9754],
              [10.8825, 53.9589],
              [10.9038, 53.9565],
            ],
          ],
          [
            [
              [8.3828, 54.6393],
              [8.3795, 54.6331],
              [8.3852, 54.6287],
              [8.3982, 54.6284],
              [8.3856, 54.621],
              [8.3925, 54.6114],
              [8.3777, 54.6094],
              [8.3595, 54.6105],
              [8.3386, 54.6236],
              [8.3254, 54.6352],
              [8.3004, 54.6544],
              [8.2897, 54.6669],
              [8.3029, 54.6759],
              [8.3297, 54.6981],
              [8.3599, 54.7137],
              [8.3612, 54.7099],
              [8.3424, 54.6982],
              [8.3384, 54.6889],
              [8.3503, 54.6762],
              [8.3597, 54.6589],
              [8.3614, 54.6496],
              [8.3712, 54.6467],
              [8.3828, 54.6393],
            ],
          ],
          [
            [
              [8.4912, 54.5335],
              [8.4882, 54.5139],
              [8.4892, 54.508],
              [8.4853, 54.4966],
              [8.4713, 54.4983],
              [8.4608, 54.5179],
              [8.4583, 54.526],
              [8.4586, 54.5423],
              [8.4721, 54.5434],
              [8.482, 54.5349],
              [8.4912, 54.5335],
            ],
          ],
          [
            [
              [8.7023, 54.0693],
              [8.6917, 54.0673],
              [8.6887, 54.0603],
              [8.6928, 54.056],
              [8.6906, 54.0441],
              [8.6732, 54.053],
              [8.6726, 54.0594],
              [8.6803, 54.0687],
              [8.6981, 54.0738],
              [8.7023, 54.0693],
            ],
          ],
          [
            [
              [8.4826, 54.4531],
              [8.503, 54.4512],
              [8.5057, 54.4403],
              [8.5158, 54.4261],
              [8.489, 54.4154],
              [8.4787, 54.4165],
              [8.4633, 54.4291],
              [8.4479, 54.4487],
              [8.4501, 54.4609],
              [8.465, 54.4686],
              [8.4775, 54.4602],
              [8.4826, 54.4531],
            ],
          ],
          [
            [
              [8.7048, 54.5577],
              [8.7089, 54.556],
              [8.7034, 54.5446],
              [8.7064, 54.5382],
              [8.6842, 54.5021],
              [8.6698, 54.4937],
              [8.6335, 54.4883],
              [8.6227, 54.4885],
              [8.6092, 54.495],
              [8.6049, 54.5009],
              [8.5921, 54.5078],
              [8.5876, 54.5147],
              [8.5865, 54.5287],
              [8.5985, 54.5356],
              [8.6257, 54.5367],
              [8.6338, 54.5399],
              [8.6378, 54.5457],
              [8.6693, 54.5503],
              [8.6865, 54.5577],
              [8.7048, 54.5577],
            ],
          ],
          [
            [
              [11.0815, 54.5308],
              [11.1165, 54.5282],
              [11.1318, 54.5244],
              [11.1696, 54.5212],
              [11.1876, 54.5149],
              [11.1905, 54.5105],
              [11.2153, 54.5049],
              [11.2253, 54.5052],
              [11.2421, 54.4941],
              [11.2528, 54.4721],
              [11.2674, 54.464],
              [11.2752, 54.4569],
              [11.2788, 54.4468],
              [11.288, 54.4377],
              [11.2977, 54.4315],
              [11.3022, 54.4201],
              [11.3126, 54.409],
              [11.3134, 54.4022],
              [11.2937, 54.4035],
              [11.2472, 54.4121],
              [11.2188, 54.4119],
              [11.2007, 54.4094],
              [11.2057, 54.4172],
              [11.191, 54.4207],
              [11.1741, 54.4201],
              [11.1673, 54.412],
              [11.1819, 54.4039],
              [11.1679, 54.4007],
              [11.1267, 54.4033],
              [11.0958, 54.4092],
              [11.0966, 54.4253],
              [11.1011, 54.4291],
              [11.097, 54.442],
              [11.0871, 54.4479],
              [11.0645, 54.4523],
              [11.0391, 54.4468],
              [11.0221, 54.4379],
              [11.0081, 54.4424],
              [11.0044, 54.4489],
              [11.0096, 54.4834],
              [11.0186, 54.4968],
              [11.0401, 54.5206],
              [11.0601, 54.533],
              [11.0687, 54.5358],
              [11.0815, 54.5308],
            ],
          ],
          [
            [
              [8.8161, 54.5525],
              [8.8304, 54.5539],
              [8.8359, 54.5466],
              [8.8216, 54.5425],
              [8.8043, 54.5484],
              [8.8028, 54.5553],
              [8.8161, 54.5525],
            ],
          ],
          [
            [
              [8.7366, 54.6398],
              [8.7382, 54.635],
              [8.728, 54.628],
              [8.7194, 54.6268],
              [8.7104, 54.631],
              [8.7155, 54.6396],
              [8.734, 54.643],
              [8.7366, 54.6398],
            ],
          ],
          [
            [
              [8.555, 54.5786],
              [8.5678, 54.5695],
              [8.577, 54.5661],
              [8.5769, 54.5604],
              [8.5642, 54.5572],
              [8.5506, 54.5568],
              [8.5357, 54.5658],
              [8.5179, 54.5699],
              [8.5131, 54.5785],
              [8.532, 54.5751],
              [8.555, 54.5786],
            ],
          ],
          [
            [
              [8.7205, 54.6851],
              [8.7083, 54.6715],
              [8.6842, 54.6714],
              [8.7009, 54.683],
              [8.7205, 54.6851],
            ],
          ],
          [
            [
              [8.6633, 54.6581],
              [8.6597, 54.6466],
              [8.6473, 54.6396],
              [8.6313, 54.6385],
              [8.6152, 54.6341],
              [8.603, 54.6329],
              [8.5974, 54.6359],
              [8.5845, 54.6341],
              [8.5695, 54.6269],
              [8.5628, 54.6271],
              [8.5496, 54.6203],
              [8.53, 54.6257],
              [8.5312, 54.631],
              [8.5418, 54.6362],
              [8.5547, 54.6356],
              [8.6027, 54.6421],
              [8.6372, 54.6494],
              [8.6517, 54.6577],
              [8.6633, 54.6581],
            ],
          ],
        ],
      },
      properties: {name: 'Schleswig-Holstein', id: 'DE-SH', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-SH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.3798, 49.5512],
            [6.3966, 49.5471],
            [6.4134, 49.5469],
            [6.417, 49.5432],
            [6.4299, 49.5486],
            [6.4527, 49.5383],
            [6.4644, 49.544],
            [6.4752, 49.5435],
            [6.4866, 49.5336],
            [6.4994, 49.5302],
            [6.5132, 49.5349],
            [6.5152, 49.5279],
            [6.5272, 49.5304],
            [6.54, 49.5388],
            [6.5555, 49.5361],
            [6.5633, 49.5407],
            [6.5782, 49.5298],
            [6.5813, 49.5211],
            [6.6078, 49.5186],
            [6.6066, 49.5281],
            [6.6185, 49.5307],
            [6.6174, 49.5351],
            [6.6342, 49.5415],
            [6.6472, 49.5385],
            [6.6522, 49.5429],
            [6.6661, 49.5431],
            [6.6848, 49.5478],
            [6.6881, 49.5382],
            [6.6998, 49.5411],
            [6.7106, 49.5537],
            [6.7229, 49.5558],
            [6.7346, 49.5601],
            [6.7481, 49.5575],
            [6.7569, 49.5609],
            [6.7662, 49.5682],
            [6.7794, 49.5699],
            [6.7984, 49.5862],
            [6.8143, 49.5917],
            [6.8216, 49.5872],
            [6.8361, 49.5834],
            [6.8459, 49.591],
            [6.8585, 49.6055],
            [6.8713, 49.6089],
            [6.8806, 49.6073],
            [6.8865, 49.613],
            [6.8958, 49.6165],
            [6.9059, 49.6143],
            [6.9144, 49.6167],
            [6.9257, 49.6149],
            [6.9363, 49.6181],
            [6.9376, 49.6262],
            [6.9335, 49.6298],
            [6.941, 49.6348],
            [6.9479, 49.6348],
            [6.9497, 49.6266],
            [6.9654, 49.6304],
            [6.9777, 49.6364],
            [6.9881, 49.6335],
            [6.988, 49.6253],
            [7.0036, 49.6321],
            [7.0131, 49.629],
            [7.0285, 49.6399],
            [7.0337, 49.6367],
            [7.0582, 49.6312],
            [7.069, 49.6238],
            [7.0808, 49.6123],
            [7.0851, 49.6034],
            [7.1008, 49.6027],
            [7.1226, 49.5952],
            [7.139, 49.5975],
            [7.1432, 49.6013],
            [7.1607, 49.6009],
            [7.1742, 49.5976],
            [7.1745, 49.5852],
            [7.1827, 49.5826],
            [7.1864, 49.576],
            [7.2101, 49.5773],
            [7.2145, 49.5738],
            [7.211, 49.5678],
            [7.2217, 49.5645],
            [7.2282, 49.5655],
            [7.2423, 49.5738],
            [7.2627, 49.5741],
            [7.2734, 49.5612],
            [7.2733, 49.5521],
            [7.2765, 49.5481],
            [7.2765, 49.5364],
            [7.2995, 49.53],
            [7.308, 49.5249],
            [7.303, 49.5204],
            [7.2961, 49.5234],
            [7.2877, 49.5178],
            [7.2883, 49.5137],
            [7.2814, 49.5064],
            [7.2873, 49.5005],
            [7.2836, 49.4968],
            [7.2813, 49.4837],
            [7.2966, 49.4833],
            [7.3054, 49.4748],
            [7.3018, 49.4673],
            [7.2898, 49.4627],
            [7.2867, 49.4575],
            [7.2671, 49.453],
            [7.2514, 49.4478],
            [7.255, 49.4401],
            [7.2516, 49.4333],
            [7.2607, 49.4241],
            [7.2725, 49.4241],
            [7.2822, 49.4177],
            [7.2895, 49.4164],
            [7.2967, 49.4046],
            [7.2899, 49.3896],
            [7.3036, 49.3867],
            [7.3208, 49.3806],
            [7.3326, 49.3786],
            [7.3437, 49.3803],
            [7.355, 49.3794],
            [7.3567, 49.3735],
            [7.3693, 49.3734],
            [7.3671, 49.364],
            [7.38, 49.3661],
            [7.3958, 49.3714],
            [7.4001, 49.3667],
            [7.3925, 49.3633],
            [7.3999, 49.3571],
            [7.3939, 49.3494],
            [7.4031, 49.3461],
            [7.4025, 49.3365],
            [7.3976, 49.3357],
            [7.3921, 49.3272],
            [7.3858, 49.3231],
            [7.3948, 49.3146],
            [7.3811, 49.3068],
            [7.3804, 49.2986],
            [7.386, 49.2938],
            [7.3717, 49.2806],
            [7.3617, 49.278],
            [7.3525, 49.2829],
            [7.3428, 49.281],
            [7.3435, 49.2735],
            [7.3391, 49.2638],
            [7.3255, 49.2603],
            [7.3275, 49.2553],
            [7.3236, 49.2474],
            [7.3072, 49.2565],
            [7.3031, 49.2447],
            [7.2908, 49.239],
            [7.2933, 49.2272],
            [7.3039, 49.223],
            [7.2986, 49.2136],
            [7.3052, 49.2025],
            [7.3183, 49.1903],
            [7.3384, 49.1839],
            [7.3353, 49.1755],
            [7.343, 49.1724],
            [7.3474, 49.165],
            [7.3628, 49.1578],
            [7.3602, 49.1522],
            [7.3622, 49.1428],
            [7.3292, 49.1442],
            [7.3148, 49.1373],
            [7.3106, 49.1291],
            [7.2954, 49.115],
            [7.283, 49.1169],
            [7.2844, 49.1217],
            [7.2743, 49.1239],
            [7.2663, 49.1225],
            [7.2456, 49.1295],
            [7.2253, 49.125],
            [7.2033, 49.1221],
            [7.2007, 49.1168],
            [7.1836, 49.1299],
            [7.1672, 49.1268],
            [7.1584, 49.1207],
            [7.1416, 49.1261],
            [7.1317, 49.1315],
            [7.1252, 49.1417],
            [7.1061, 49.1397],
            [7.1035, 49.1449],
            [7.1124, 49.1503],
            [7.1061, 49.1546],
            [7.0847, 49.1532],
            [7.0804, 49.1467],
            [7.0891, 49.1321],
            [7.0873, 49.1295],
            [7.0712, 49.1245],
            [7.0671, 49.1156],
            [7.06, 49.1129],
            [7.0487, 49.1149],
            [7.0443, 49.1229],
            [7.0466, 49.1362],
            [7.0415, 49.1462],
            [7.0316, 49.1557],
            [7.0322, 49.1639],
            [7.0273, 49.1738],
            [7.0327, 49.1807],
            [7.034, 49.1907],
            [7.0213, 49.1894],
            [7.0095, 49.1936],
            [7.0004, 49.1942],
            [6.9925, 49.2013],
            [6.9734, 49.2098],
            [6.9602, 49.2028],
            [6.941, 49.2163],
            [6.9388, 49.2229],
            [6.9185, 49.2232],
            [6.9121, 49.2152],
            [6.8984, 49.2127],
            [6.8934, 49.2093],
            [6.8744, 49.2154],
            [6.8607, 49.2229],
            [6.8385, 49.2128],
            [6.8527, 49.2004],
            [6.8508, 49.1944],
            [6.8621, 49.1832],
            [6.8594, 49.1757],
            [6.8453, 49.1729],
            [6.8455, 49.1574],
            [6.8335, 49.1525],
            [6.8192, 49.1577],
            [6.8082, 49.1593],
            [6.7803, 49.1687],
            [6.7607, 49.165],
            [6.7513, 49.1671],
            [6.7392, 49.1643],
            [6.7303, 49.1714],
            [6.7211, 49.1753],
            [6.712, 49.1882],
            [6.7318, 49.2061],
            [6.7243, 49.2184],
            [6.7188, 49.2208],
            [6.7077, 49.2167],
            [6.6959, 49.216],
            [6.6892, 49.2233],
            [6.6901, 49.2306],
            [6.6853, 49.2434],
            [6.6901, 49.2487],
            [6.6748, 49.2575],
            [6.6679, 49.2553],
            [6.6609, 49.2589],
            [6.6675, 49.2709],
            [6.6665, 49.2772],
            [6.6597, 49.2836],
            [6.6531, 49.2813],
            [6.6493, 49.2896],
            [6.6384, 49.2963],
            [6.6215, 49.303],
            [6.6141, 49.3016],
            [6.6013, 49.3145],
            [6.5881, 49.3212],
            [6.5955, 49.3295],
            [6.5845, 49.3373],
            [6.5671, 49.347],
            [6.5641, 49.356],
            [6.5705, 49.3583],
            [6.58, 49.3563],
            [6.5899, 49.3503],
            [6.5922, 49.358],
            [6.6008, 49.3657],
            [6.5946, 49.3713],
            [6.583, 49.3687],
            [6.5868, 49.3848],
            [6.5787, 49.3888],
            [6.5641, 49.3887],
            [6.5407, 49.4012],
            [6.5377, 49.4107],
            [6.5542, 49.4205],
            [6.5521, 49.425],
            [6.5327, 49.4365],
            [6.5249, 49.4363],
            [6.5025, 49.4498],
            [6.4837, 49.4529],
            [6.4634, 49.4666],
            [6.4565, 49.4633],
            [6.4433, 49.4681],
            [6.4384, 49.4673],
            [6.4287, 49.4763],
            [6.4198, 49.4761],
            [6.4084, 49.4681],
            [6.3974, 49.4647],
            [6.3838, 49.4664],
            [6.3742, 49.4644],
            [6.3672, 49.4707],
            [6.369, 49.4888],
            [6.3669, 49.5062],
            [6.3565, 49.5288],
            [6.3619, 49.5373],
            [6.3798, 49.5512],
          ],
        ],
      },
      properties: {name: 'Saarland', id: 'DE-SL', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-SL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.2828, 51.0893],
            [12.2817, 51.0947],
            [12.275, 51.0992],
            [12.2643, 51.1007],
            [12.26, 51.0977],
            [12.2491, 51.0981],
            [12.2415, 51.1023],
            [12.2306, 51.1036],
            [12.2227, 51.1107],
            [12.2238, 51.1184],
            [12.237, 51.1228],
            [12.2394, 51.1317],
            [12.2254, 51.1372],
            [12.1957, 51.1418],
            [12.1969, 51.1583],
            [12.2016, 51.1603],
            [12.2123, 51.1831],
            [12.1895, 51.1848],
            [12.1742, 51.1814],
            [12.1739, 51.1896],
            [12.1874, 51.1894],
            [12.1914, 51.1959],
            [12.1895, 51.2041],
            [12.199, 51.2119],
            [12.1922, 51.2343],
            [12.1938, 51.2395],
            [12.1897, 51.2578],
            [12.1754, 51.2583],
            [12.17, 51.2691],
            [12.172, 51.2778],
            [12.1692, 51.2911],
            [12.1574, 51.2926],
            [12.1569, 51.302],
            [12.147, 51.321],
            [12.1629, 51.3193],
            [12.1648, 51.3245],
            [12.1964, 51.3295],
            [12.1923, 51.3539],
            [12.1951, 51.3636],
            [12.188, 51.3673],
            [12.1727, 51.3842],
            [12.1761, 51.392],
            [12.175, 51.4105],
            [12.1832, 51.4109],
            [12.1857, 51.4214],
            [12.1819, 51.4333],
            [12.1692, 51.4308],
            [12.1573, 51.4331],
            [12.1592, 51.4526],
            [12.1484, 51.4625],
            [12.1593, 51.4664],
            [12.17, 51.4666],
            [12.1767, 51.4705],
            [12.1773, 51.4817],
            [12.2013, 51.4853],
            [12.2063, 51.4912],
            [12.2077, 51.5025],
            [12.1938, 51.5069],
            [12.1908, 51.5241],
            [12.2063, 51.5349],
            [12.206, 51.5396],
            [12.23, 51.5475],
            [12.236, 51.5527],
            [12.2324, 51.5636],
            [12.2425, 51.5644],
            [12.2498, 51.5604],
            [12.269, 51.56],
            [12.2907, 51.5613],
            [12.2929, 51.5646],
            [12.3137, 51.5685],
            [12.3218, 51.5784],
            [12.3438, 51.5811],
            [12.3515, 51.5863],
            [12.3679, 51.5837],
            [12.3704, 51.5858],
            [12.3881, 51.586],
            [12.4244, 51.5821],
            [12.4259, 51.5965],
            [12.4378, 51.6018],
            [12.4332, 51.6059],
            [12.4446, 51.6092],
            [12.4593, 51.6016],
            [12.4714, 51.6063],
            [12.4862, 51.6026],
            [12.4822, 51.5952],
            [12.4941, 51.5961],
            [12.496, 51.6059],
            [12.5075, 51.6085],
            [12.5141, 51.6048],
            [12.5432, 51.6034],
            [12.5491, 51.6095],
            [12.5606, 51.6087],
            [12.5681, 51.6158],
            [12.5736, 51.6156],
            [12.5791, 51.6252],
            [12.5935, 51.6182],
            [12.6069, 51.6256],
            [12.6195, 51.6267],
            [12.6231, 51.6218],
            [12.6213, 51.6143],
            [12.6487, 51.6219],
            [12.6547, 51.6305],
            [12.6628, 51.6296],
            [12.6656, 51.6433],
            [12.6751, 51.6502],
            [12.6771, 51.6563],
            [12.6908, 51.6647],
            [12.6963, 51.6605],
            [12.7069, 51.6618],
            [12.7237, 51.6601],
            [12.7629, 51.6491],
            [12.7731, 51.648],
            [12.7804, 51.6529],
            [12.7934, 51.6657],
            [12.8078, 51.6674],
            [12.8228, 51.6753],
            [12.8321, 51.678],
            [12.8365, 51.6834],
            [12.8467, 51.6817],
            [12.8628, 51.6748],
            [12.8613, 51.6647],
            [12.8644, 51.6592],
            [12.877, 51.6578],
            [12.8879, 51.667],
            [12.8959, 51.6629],
            [12.8944, 51.6509],
            [12.9013, 51.6462],
            [12.913, 51.6456],
            [12.9171, 51.6489],
            [12.9308, 51.6491],
            [12.9423, 51.6384],
            [12.9505, 51.6435],
            [12.9485, 51.6497],
            [12.9697, 51.6508],
            [12.9643, 51.6646],
            [12.9745, 51.6678],
            [12.9951, 51.67],
            [13.0034, 51.6613],
            [13.0123, 51.6646],
            [13.019, 51.6547],
            [13.0179, 51.6435],
            [13.0259, 51.6377],
            [13.0532, 51.6466],
            [13.0764, 51.6217],
            [13.0839, 51.6115],
            [13.0932, 51.6082],
            [13.1073, 51.6126],
            [13.1222, 51.6207],
            [13.133, 51.6076],
            [13.148, 51.5984],
            [13.1552, 51.6],
            [13.1613, 51.5939],
            [13.147, 51.5787],
            [13.1436, 51.5721],
            [13.1478, 51.5643],
            [13.1598, 51.5596],
            [13.1854, 51.5574],
            [13.188, 51.5442],
            [13.2012, 51.5262],
            [13.2075, 51.5242],
            [13.2084, 51.5132],
            [13.2039, 51.4995],
            [13.1865, 51.4844],
            [13.1824, 51.475],
            [13.1876, 51.4661],
            [13.2028, 51.4593],
            [13.2031, 51.4508],
            [13.1914, 51.4412],
            [13.1756, 51.4333],
            [13.1753, 51.4282],
            [13.1869, 51.4242],
            [13.1933, 51.4365],
            [13.2012, 51.4326],
            [13.204, 51.4255],
            [13.2014, 51.417],
            [13.2147, 51.3964],
            [13.2355, 51.3956],
            [13.2554, 51.3923],
            [13.2609, 51.4006],
            [13.2858, 51.3988],
            [13.2865, 51.4116],
            [13.3086, 51.4187],
            [13.316, 51.4253],
            [13.3289, 51.4263],
            [13.348, 51.4345],
            [13.3579, 51.4348],
            [13.3754, 51.426],
            [13.3841, 51.4293],
            [13.3762, 51.4347],
            [13.3881, 51.4399],
            [13.3874, 51.449],
            [13.3993, 51.4537],
            [13.407, 51.4498],
            [13.412, 51.4407],
            [13.4198, 51.4388],
            [13.4276, 51.4298],
            [13.4204, 51.4226],
            [13.4289, 51.4217],
            [13.4331, 51.4271],
            [13.4472, 51.4283],
            [13.45, 51.4242],
            [13.4613, 51.4236],
            [13.4626, 51.4126],
            [13.4882, 51.4089],
            [13.5123, 51.4027],
            [13.5195, 51.4025],
            [13.5235, 51.39],
            [13.5229, 51.3827],
            [13.5369, 51.3822],
            [13.5383, 51.375],
            [13.5485, 51.3804],
            [13.5598, 51.3808],
            [13.5684, 51.3856],
            [13.5874, 51.3842],
            [13.5921, 51.3798],
            [13.5956, 51.3694],
            [13.6181, 51.3714],
            [13.6371, 51.3688],
            [13.6578, 51.37],
            [13.6666, 51.3772],
            [13.6823, 51.3684],
            [13.6893, 51.3743],
            [13.7037, 51.3672],
            [13.7408, 51.3625],
            [13.7532, 51.3646],
            [13.7626, 51.3712],
            [13.7685, 51.3639],
            [13.7743, 51.3636],
            [13.7832, 51.371],
            [13.8084, 51.3728],
            [13.8195, 51.37],
            [13.8262, 51.3754],
            [13.8349, 51.3778],
            [13.8347, 51.3851],
            [13.8593, 51.382],
            [13.866, 51.3776],
            [13.9061, 51.3804],
            [13.9534, 51.3967],
            [13.9715, 51.394],
            [13.9707, 51.377],
            [14.0003, 51.3745],
            [14.0006, 51.3859],
            [14.0037, 51.3933],
            [14.0158, 51.4005],
            [14.0164, 51.4053],
            [14.0291, 51.4091],
            [14.0454, 51.4223],
            [14.0396, 51.4269],
            [14.0448, 51.4318],
            [14.0374, 51.4356],
            [14.0622, 51.4464],
            [14.0537, 51.4501],
            [14.0563, 51.4599],
            [14.0339, 51.4761],
            [14.0399, 51.4801],
            [14.0696, 51.4729],
            [14.0757, 51.4729],
            [14.0878, 51.4803],
            [14.075, 51.4906],
            [14.095, 51.4981],
            [14.1007, 51.5129],
            [14.1059, 51.5211],
            [14.1142, 51.523],
            [14.1351, 51.5215],
            [14.1401, 51.5249],
            [14.1298, 51.5323],
            [14.138, 51.5435],
            [14.1482, 51.5411],
            [14.1653, 51.5413],
            [14.1736, 51.5389],
            [14.1883, 51.5407],
            [14.2193, 51.5383],
            [14.2262, 51.5335],
            [14.2429, 51.5318],
            [14.2705, 51.5325],
            [14.2933, 51.5252],
            [14.3177, 51.521],
            [14.3245, 51.5164],
            [14.3254, 51.5073],
            [14.3337, 51.5036],
            [14.3426, 51.5188],
            [14.377, 51.5329],
            [14.3842, 51.5344],
            [14.3887, 51.5421],
            [14.3988, 51.5418],
            [14.4111, 51.5375],
            [14.426, 51.5428],
            [14.4419, 51.5446],
            [14.449, 51.5553],
            [14.4546, 51.5576],
            [14.4868, 51.5582],
            [14.4991, 51.5594],
            [14.5161, 51.5545],
            [14.5284, 51.5588],
            [14.5382, 51.5594],
            [14.5466, 51.5636],
            [14.5444, 51.5714],
            [14.5563, 51.5725],
            [14.5679, 51.5805],
            [14.5768, 51.581],
            [14.582, 51.5742],
            [14.5934, 51.5729],
            [14.5993, 51.5692],
            [14.6063, 51.5534],
            [14.6097, 51.5504],
            [14.6321, 51.551],
            [14.6368, 51.5535],
            [14.6536, 51.5544],
            [14.6719, 51.5511],
            [14.6796, 51.5532],
            [14.6884, 51.5601],
            [14.6954, 51.5764],
            [14.7034, 51.5807],
            [14.6974, 51.5858],
            [14.6944, 51.5958],
            [14.7043, 51.5979],
            [14.7134, 51.5939],
            [14.7231, 51.5849],
            [14.7304, 51.5821],
            [14.7268, 51.5757],
            [14.7119, 51.56],
            [14.7276, 51.5518],
            [14.7305, 51.5463],
            [14.7277, 51.5393],
            [14.7299, 51.531],
            [14.7364, 51.5265],
            [14.7516, 51.5228],
            [14.7588, 51.5239],
            [14.7674, 51.5192],
            [14.7947, 51.5186],
            [14.8057, 51.5147],
            [14.8128, 51.5077],
            [14.8181, 51.5086],
            [14.8327, 51.5042],
            [14.8355, 51.5],
            [14.8522, 51.4905],
            [14.8658, 51.4907],
            [14.8722, 51.4868],
            [14.8885, 51.4879],
            [14.8976, 51.4837],
            [14.919, 51.4837],
            [14.9272, 51.4742],
            [14.9465, 51.4728],
            [14.9565, 51.4633],
            [14.9564, 51.4599],
            [14.9655, 51.4485],
            [14.9731, 51.4424],
            [14.9725, 51.4353],
            [14.9634, 51.4337],
            [14.9668, 51.4175],
            [14.9588, 51.4105],
            [14.9672, 51.4024],
            [14.9631, 51.3941],
            [14.968, 51.3802],
            [14.9823, 51.3746],
            [14.973, 51.3611],
            [14.9781, 51.3426],
            [14.9827, 51.3345],
            [14.9928, 51.3291],
            [15.0099, 51.3168],
            [15.0151, 51.3062],
            [15.0347, 51.2916],
            [15.0329, 51.2867],
            [15.0383, 51.2695],
            [15.0382, 51.2574],
            [15.0272, 51.2537],
            [15.0388, 51.246],
            [15.0303, 51.2409],
            [15.0264, 51.2314],
            [15.0174, 51.226],
            [15.0181, 51.2153],
            [15.0085, 51.2041],
            [15.0132, 51.1954],
            [15.0045, 51.1899],
            [15.0088, 51.1824],
            [15.0029, 51.1725],
            [15.0043, 51.1674],
            [14.9928, 51.1593],
            [14.9995, 51.149],
            [14.99, 51.1414],
            [14.9961, 51.1329],
            [14.9963, 51.1218],
            [14.9869, 51.1161],
            [14.9815, 51.1173],
            [14.9776, 51.1077],
            [14.9827, 51.1019],
            [14.9825, 51.0919],
            [14.9761, 51.0869],
            [14.978, 51.0767],
            [14.9702, 51.0724],
            [14.9697, 51.0653],
            [14.9625, 51.0581],
            [14.9635, 51.0506],
            [14.948, 51.043],
            [14.9502, 51.0375],
            [14.9455, 51.0273],
            [14.9343, 51.0204],
            [14.9364, 51.011],
            [14.9274, 50.9973],
            [14.9174, 50.9965],
            [14.916, 50.9918],
            [14.9182, 50.9739],
            [14.9042, 50.9706],
            [14.9031, 50.9611],
            [14.897, 50.9583],
            [14.8947, 50.9473],
            [14.8964, 50.9415],
            [14.8904, 50.9353],
            [14.8755, 50.9302],
            [14.8691, 50.9202],
            [14.8513, 50.9096],
            [14.8443, 50.901],
            [14.8197, 50.886],
            [14.8183, 50.8763],
            [14.8244, 50.8687],
            [14.8202, 50.8589],
            [14.8108, 50.8508],
            [14.8021, 50.8364],
            [14.8009, 50.8243],
            [14.7947, 50.8199],
            [14.7883, 50.8233],
            [14.778, 50.8194],
            [14.7667, 50.819],
            [14.7585, 50.8241],
            [14.7493, 50.8241],
            [14.7396, 50.8286],
            [14.7262, 50.822],
            [14.7177, 50.8229],
            [14.7184, 50.8344],
            [14.7074, 50.8411],
            [14.6891, 50.8381],
            [14.6621, 50.8497],
            [14.6475, 50.8487],
            [14.633, 50.8552],
            [14.6196, 50.8579],
            [14.6223, 50.8668],
            [14.6324, 50.8838],
            [14.6354, 50.8948],
            [14.6405, 50.9004],
            [14.6526, 50.9052],
            [14.6521, 50.9247],
            [14.6439, 50.931],
            [14.6277, 50.9253],
            [14.6162, 50.9251],
            [14.5832, 50.9132],
            [14.5657, 50.9183],
            [14.5612, 50.9256],
            [14.5697, 50.936],
            [14.5814, 50.9422],
            [14.5843, 50.9493],
            [14.5957, 50.9622],
            [14.6006, 50.9799],
            [14.5986, 50.986],
            [14.5806, 50.993],
            [14.5777, 50.9996],
            [14.5594, 51.0069],
            [14.5346, 51.0033],
            [14.5394, 51.0104],
            [14.5246, 51.0186],
            [14.4984, 51.0219],
            [14.5078, 51.0431],
            [14.4982, 51.0463],
            [14.4915, 51.0435],
            [14.4939, 51.0354],
            [14.4928, 51.0238],
            [14.4756, 51.025],
            [14.4754, 51.0288],
            [14.4656, 51.0349],
            [14.4544, 51.0358],
            [14.4343, 51.0263],
            [14.4314, 51.0224],
            [14.4202, 51.0188],
            [14.4098, 51.0185],
            [14.3912, 51.0237],
            [14.3835, 51.0283],
            [14.3829, 51.0372],
            [14.3747, 51.0387],
            [14.3622, 51.0448],
            [14.3346, 51.04],
            [14.3262, 51.0493],
            [14.3161, 51.055],
            [14.3028, 51.0548],
            [14.2921, 51.0462],
            [14.288, 51.0389],
            [14.2757, 51.0393],
            [14.286, 51.0311],
            [14.285, 51.0247],
            [14.2785, 51.0193],
            [14.2812, 51.0142],
            [14.2662, 51.0083],
            [14.2633, 51.0027],
            [14.2684, 50.9992],
            [14.259, 50.9921],
            [14.26, 50.9871],
            [14.2744, 50.9832],
            [14.2843, 50.9766],
            [14.2982, 50.9781],
            [14.3185, 50.9854],
            [14.3254, 50.9791],
            [14.3249, 50.9721],
            [14.3154, 50.9728],
            [14.3035, 50.9655],
            [14.3174, 50.9596],
            [14.3137, 50.9531],
            [14.326, 50.9491],
            [14.3393, 50.9496],
            [14.3484, 50.9453],
            [14.3693, 50.9418],
            [14.3757, 50.938],
            [14.3872, 50.9413],
            [14.4012, 50.9322],
            [14.4024, 50.9247],
            [14.391, 50.9207],
            [14.3861, 50.9126],
            [14.3872, 50.8978],
            [14.3743, 50.8961],
            [14.3502, 50.8997],
            [14.3494, 50.8923],
            [14.3187, 50.8885],
            [14.3038, 50.8836],
            [14.2909, 50.8852],
            [14.281, 50.8927],
            [14.2668, 50.8947],
            [14.2583, 50.8899],
            [14.2445, 50.8861],
            [14.2368, 50.8812],
            [14.2359, 50.8751],
            [14.2241, 50.8598],
            [14.2142, 50.8591],
            [14.2011, 50.8512],
            [14.1961, 50.852],
            [14.1708, 50.8473],
            [14.1605, 50.847],
            [14.1333, 50.8338],
            [14.1272, 50.834],
            [14.1004, 50.8259],
            [14.0887, 50.8258],
            [14.0786, 50.8185],
            [14.0771, 50.8118],
            [14.0621, 50.81],
            [14.0402, 50.8105],
            [14.032, 50.8037],
            [14.0197, 50.8103],
            [14.0041, 50.8102],
            [14.0029, 50.8156],
            [13.9897, 50.8195],
            [13.9703, 50.8107],
            [13.9544, 50.8075],
            [13.9556, 50.8002],
            [13.9464, 50.7941],
            [13.9298, 50.7884],
            [13.9183, 50.7891],
            [13.9035, 50.794],
            [13.8974, 50.7916],
            [13.9, 50.7849],
            [13.8919, 50.7772],
            [13.887, 50.7687],
            [13.8892, 50.7641],
            [13.9023, 50.7527],
            [13.8983, 50.7444],
            [13.8812, 50.7378],
            [13.8621, 50.7425],
            [13.8565, 50.7274],
            [13.8474, 50.7256],
            [13.8334, 50.7273],
            [13.8259, 50.7251],
            [13.8158, 50.7276],
            [13.8074, 50.7332],
            [13.7899, 50.7351],
            [13.7842, 50.7333],
            [13.7578, 50.7355],
            [13.7522, 50.7303],
            [13.7409, 50.7269],
            [13.7272, 50.7336],
            [13.7076, 50.7171],
            [13.686, 50.7195],
            [13.6814, 50.7248],
            [13.667, 50.7313],
            [13.6427, 50.7285],
            [13.6251, 50.7215],
            [13.6263, 50.7155],
            [13.6092, 50.7128],
            [13.6029, 50.71],
            [13.5906, 50.7129],
            [13.5796, 50.7117],
            [13.5586, 50.7145],
            [13.5506, 50.713],
            [13.5382, 50.706],
            [13.5267, 50.7054],
            [13.5264, 50.701],
            [13.5402, 50.6904],
            [13.5431, 50.6771],
            [13.5386, 50.6673],
            [13.5306, 50.6681],
            [13.5179, 50.6589],
            [13.5136, 50.6535],
            [13.5186, 50.6481],
            [13.526, 50.6489],
            [13.5249, 50.6392],
            [13.518, 50.6358],
            [13.5045, 50.6337],
            [13.4789, 50.6157],
            [13.4629, 50.6014],
            [13.4406, 50.6097],
            [13.4296, 50.6099],
            [13.4251, 50.6161],
            [13.4114, 50.6186],
            [13.4055, 50.6335],
            [13.3935, 50.641],
            [13.3894, 50.6464],
            [13.3775, 50.6463],
            [13.374, 50.6409],
            [13.3768, 50.6273],
            [13.3662, 50.6181],
            [13.3519, 50.6137],
            [13.3391, 50.6119],
            [13.3361, 50.6056],
            [13.327, 50.6076],
            [13.3209, 50.602],
            [13.3228, 50.5797],
            [13.3032, 50.5791],
            [13.2901, 50.5751],
            [13.2813, 50.5908],
            [13.2703, 50.5931],
            [13.2611, 50.5917],
            [13.255, 50.5945],
            [13.2465, 50.5911],
            [13.2355, 50.5773],
            [13.237, 50.5716],
            [13.2224, 50.5631],
            [13.2276, 50.5527],
            [13.2063, 50.5222],
            [13.1946, 50.5159],
            [13.1972, 50.5053],
            [13.1773, 50.5033],
            [13.1546, 50.5083],
            [13.1383, 50.5068],
            [13.1384, 50.5117],
            [13.1318, 50.5186],
            [13.1201, 50.5139],
            [13.1034, 50.5035],
            [13.0857, 50.4998],
            [13.0608, 50.5005],
            [13.0532, 50.5028],
            [13.0444, 50.5109],
            [13.0313, 50.5088],
            [13.0309, 50.4999],
            [13.0232, 50.4873],
            [13.0199, 50.4762],
            [13.0196, 50.4613],
            [13.0238, 50.4537],
            [13.0155, 50.4429],
            [12.9972, 50.4341],
            [12.9859, 50.4222],
            [12.9645, 50.4138],
            [12.9363, 50.412],
            [12.9133, 50.4239],
            [12.9044, 50.4217],
            [12.8932, 50.4305],
            [12.8826, 50.4353],
            [12.8738, 50.4365],
            [12.8605, 50.4434],
            [12.8255, 50.4577],
            [12.8168, 50.4477],
            [12.8058, 50.4443],
            [12.795, 50.4492],
            [12.7876, 50.4462],
            [12.7663, 50.4418],
            [12.7494, 50.4355],
            [12.7352, 50.4323],
            [12.73, 50.4219],
            [12.7177, 50.4166],
            [12.7089, 50.4082],
            [12.7077, 50.3992],
            [12.6957, 50.4004],
            [12.6947, 50.405],
            [12.6812, 50.4095],
            [12.6727, 50.4167],
            [12.6632, 50.4115],
            [12.6498, 50.4095],
            [12.6272, 50.416],
            [12.6133, 50.4141],
            [12.6081, 50.4083],
            [12.5986, 50.4056],
            [12.5811, 50.4067],
            [12.5764, 50.4034],
            [12.5582, 50.3992],
            [12.5338, 50.3992],
            [12.5276, 50.3961],
            [12.5124, 50.3967],
            [12.5107, 50.3928],
            [12.4871, 50.3707],
            [12.4882, 50.3626],
            [12.4931, 50.355],
            [12.4897, 50.3493],
            [12.4815, 50.3475],
            [12.4689, 50.3547],
            [12.4586, 50.3484],
            [12.4469, 50.3449],
            [12.4352, 50.3325],
            [12.4376, 50.3279],
            [12.4315, 50.3225],
            [12.4026, 50.3234],
            [12.3979, 50.3101],
            [12.4029, 50.3004],
            [12.3941, 50.2891],
            [12.3824, 50.289],
            [12.3709, 50.2846],
            [12.3592, 50.2746],
            [12.3613, 50.2694],
            [12.3527, 50.2621],
            [12.3516, 50.2498],
            [12.3579, 50.2451],
            [12.3481, 50.2361],
            [12.3346, 50.2424],
            [12.3287, 50.2347],
            [12.3295, 50.2248],
            [12.3256, 50.2181],
            [12.3278, 50.2121],
            [12.3233, 50.2067],
            [12.3386, 50.1919],
            [12.329, 50.1854],
            [12.3271, 50.1732],
            [12.3109, 50.1725],
            [12.2898, 50.1764],
            [12.2896, 50.1858],
            [12.2778, 50.1913],
            [12.2748, 50.1963],
            [12.2863, 50.2011],
            [12.2854, 50.2158],
            [12.2905, 50.2195],
            [12.2749, 50.2329],
            [12.2543, 50.2366],
            [12.2529, 50.2406],
            [12.2395, 50.2456],
            [12.2414, 50.2503],
            [12.266, 50.2502],
            [12.2654, 50.2582],
            [12.2561, 50.268],
            [12.2356, 50.269],
            [12.2313, 50.2715],
            [12.2141, 50.2698],
            [12.2022, 50.2718],
            [12.2019, 50.2883],
            [12.195, 50.2927],
            [12.1997, 50.3059],
            [12.1865, 50.3114],
            [12.1844, 50.3221],
            [12.1402, 50.321],
            [12.1221, 50.3146],
            [12.1156, 50.3201],
            [12.101, 50.318],
            [12.0882, 50.333],
            [12.0821, 50.3279],
            [12.0492, 50.3316],
            [12.0325, 50.3305],
            [12.028, 50.3415],
            [12.0217, 50.3402],
            [12.0157, 50.3466],
            [11.9959, 50.3474],
            [11.995, 50.3543],
            [11.9798, 50.3499],
            [11.9757, 50.352],
            [11.9851, 50.3596],
            [11.9771, 50.362],
            [11.9837, 50.3767],
            [11.9763, 50.3875],
            [11.9646, 50.3997],
            [11.9616, 50.396],
            [11.9488, 50.3962],
            [11.9537, 50.4078],
            [11.9458, 50.4102],
            [11.9444, 50.4165],
            [11.9352, 50.4229],
            [11.9236, 50.4233],
            [11.9283, 50.4322],
            [11.8997, 50.4357],
            [11.8923, 50.4431],
            [11.8955, 50.4484],
            [11.9067, 50.4538],
            [11.9185, 50.4536],
            [11.9223, 50.4593],
            [11.9401, 50.4585],
            [11.9433, 50.465],
            [11.9424, 50.4758],
            [11.9658, 50.4867],
            [11.9593, 50.4936],
            [11.9537, 50.4931],
            [11.9448, 50.5048],
            [11.9402, 50.5028],
            [11.9315, 50.5115],
            [11.932, 50.5232],
            [11.915, 50.5213],
            [11.9186, 50.517],
            [11.9031, 50.5149],
            [11.8955, 50.5188],
            [11.888, 50.5175],
            [11.8705, 50.5376],
            [11.8833, 50.546],
            [11.8877, 50.5526],
            [11.9001, 50.5517],
            [11.9137, 50.5545],
            [11.9207, 50.5581],
            [11.9338, 50.5707],
            [11.9324, 50.5782],
            [11.9261, 50.5847],
            [11.9434, 50.5894],
            [11.9548, 50.5953],
            [11.96, 50.6025],
            [11.9667, 50.5992],
            [11.987, 50.6092],
            [11.9825, 50.621],
            [12.0014, 50.6283],
            [12.0087, 50.6351],
            [12.02, 50.6244],
            [12.0031, 50.6145],
            [12.0157, 50.6052],
            [12.0372, 50.5933],
            [12.033, 50.5807],
            [12.0189, 50.5674],
            [12.0313, 50.5574],
            [12.034, 50.5523],
            [12.0474, 50.5536],
            [12.0565, 50.5508],
            [12.0662, 50.5553],
            [12.0779, 50.5575],
            [12.0803, 50.5762],
            [12.1023, 50.5785],
            [12.1148, 50.568],
            [12.1246, 50.5673],
            [12.1287, 50.5736],
            [12.1549, 50.5728],
            [12.1632, 50.5767],
            [12.1611, 50.5841],
            [12.144, 50.5861],
            [12.143, 50.5987],
            [12.147, 50.6041],
            [12.144, 50.61],
            [12.1488, 50.6163],
            [12.1451, 50.6233],
            [12.1497, 50.6294],
            [12.1711, 50.6257],
            [12.1774, 50.6136],
            [12.1905, 50.6131],
            [12.189, 50.6236],
            [12.1989, 50.6319],
            [12.1999, 50.6384],
            [12.2246, 50.6322],
            [12.2485, 50.634],
            [12.2637, 50.6299],
            [12.2636, 50.6424],
            [12.279, 50.6443],
            [12.2918, 50.6478],
            [12.3016, 50.655],
            [12.3045, 50.6622],
            [12.317, 50.6633],
            [12.3125, 50.6717],
            [12.3206, 50.6807],
            [12.3144, 50.6866],
            [12.3071, 50.6841],
            [12.2962, 50.6874],
            [12.2826, 50.6865],
            [12.2698, 50.6895],
            [12.2618, 50.6943],
            [12.2636, 50.7009],
            [12.2538, 50.7164],
            [12.2731, 50.7279],
            [12.2638, 50.7379],
            [12.2374, 50.7341],
            [12.2254, 50.7428],
            [12.231, 50.7605],
            [12.2426, 50.7681],
            [12.2644, 50.7607],
            [12.2795, 50.7705],
            [12.2869, 50.7776],
            [12.287, 50.7908],
            [12.2665, 50.7881],
            [12.2537, 50.7892],
            [12.2509, 50.801],
            [12.2454, 50.806],
            [12.2458, 50.8116],
            [12.2533, 50.8186],
            [12.2591, 50.8117],
            [12.2745, 50.8144],
            [12.2846, 50.8109],
            [12.2857, 50.8201],
            [12.2958, 50.8255],
            [12.3044, 50.8201],
            [12.3469, 50.8232],
            [12.3481, 50.8375],
            [12.3605, 50.8419],
            [12.3654, 50.8469],
            [12.384, 50.8506],
            [12.4027, 50.8486],
            [12.4066, 50.8567],
            [12.4181, 50.8551],
            [12.4108, 50.8463],
            [12.4196, 50.8394],
            [12.4311, 50.8436],
            [12.4406, 50.843],
            [12.44, 50.8496],
            [12.4477, 50.8548],
            [12.4441, 50.8645],
            [12.459, 50.8679],
            [12.463, 50.8733],
            [12.4599, 50.8813],
            [12.4726, 50.8861],
            [12.4794, 50.8863],
            [12.4916, 50.8932],
            [12.5101, 50.8926],
            [12.5075, 50.9031],
            [12.5203, 50.9071],
            [12.5252, 50.9021],
            [12.5374, 50.8999],
            [12.5589, 50.9058],
            [12.565, 50.9016],
            [12.5849, 50.9035],
            [12.5894, 50.9017],
            [12.6011, 50.9051],
            [12.6155, 50.9053],
            [12.6394, 50.9035],
            [12.639, 50.9089],
            [12.6526, 50.9233],
            [12.6436, 50.9273],
            [12.6304, 50.9399],
            [12.6215, 50.9671],
            [12.6219, 50.9746],
            [12.6163, 50.9856],
            [12.6064, 50.9915],
            [12.571, 50.9909],
            [12.5513, 50.9974],
            [12.537, 50.996],
            [12.5278, 51.0025],
            [12.541, 51.0065],
            [12.535, 51.0142],
            [12.5389, 51.0238],
            [12.5198, 51.0231],
            [12.5149, 51.0172],
            [12.5065, 51.0178],
            [12.5112, 51.0268],
            [12.503, 51.0333],
            [12.5028, 51.0495],
            [12.5057, 51.0507],
            [12.5018, 51.0638],
            [12.4929, 51.0659],
            [12.4692, 51.0653],
            [12.4591, 51.0721],
            [12.4574, 51.0821],
            [12.4479, 51.0771],
            [12.4422, 51.0778],
            [12.4029, 51.0759],
            [12.3934, 51.0838],
            [12.3737, 51.0768],
            [12.3589, 51.0803],
            [12.3538, 51.0845],
            [12.3566, 51.0948],
            [12.3367, 51.1025],
            [12.3286, 51.1032],
            [12.3143, 51.0924],
            [12.3024, 51.0981],
            [12.2828, 51.0893],
          ],
        ],
      },
      properties: {name: 'Saxony', id: 'DE-SN', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-SN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [11.5986, 53.0364],
            [11.6205, 53.0417],
            [11.6382, 53.0387],
            [11.6391, 53.0327],
            [11.623, 53.0198],
            [11.6228, 53.0149],
            [11.6319, 53.0076],
            [11.6494, 53.0057],
            [11.6649, 53.0095],
            [11.675, 53.0085],
            [11.6826, 52.9996],
            [11.683, 52.9861],
            [11.6922, 52.9777],
            [11.7023, 52.9775],
            [11.7262, 52.9873],
            [11.7398, 52.9893],
            [11.7534, 52.9857],
            [11.7671, 52.978],
            [11.7825, 52.9613],
            [11.7983, 52.9579],
            [11.8227, 52.958],
            [11.8438, 52.9513],
            [11.8461, 52.941],
            [11.8286, 52.9321],
            [11.8198, 52.9215],
            [11.8224, 52.9154],
            [11.8319, 52.9097],
            [11.867, 52.9094],
            [11.8757, 52.9057],
            [11.8849, 52.8973],
            [11.8924, 52.8938],
            [11.9234, 52.8918],
            [11.9394, 52.8891],
            [11.9558, 52.8809],
            [11.9784, 52.8776],
            [11.9822, 52.8803],
            [12.0066, 52.8824],
            [12.0076, 52.8874],
            [12.0245, 52.8901],
            [12.0418, 52.8898],
            [12.0494, 52.8863],
            [12.0559, 52.8892],
            [12.0774, 52.8862],
            [12.0813, 52.8766],
            [12.1006, 52.8789],
            [12.1112, 52.8766],
            [12.1315, 52.8663],
            [12.1227, 52.8543],
            [12.1363, 52.8589],
            [12.1767, 52.8676],
            [12.1958, 52.8785],
            [12.2004, 52.8687],
            [12.2138, 52.864],
            [12.217, 52.8667],
            [12.2341, 52.8615],
            [12.2295, 52.8555],
            [12.2318, 52.8487],
            [12.2415, 52.8459],
            [12.2389, 52.8322],
            [12.2446, 52.8272],
            [12.25, 52.8122],
            [12.2564, 52.8075],
            [12.2483, 52.7984],
            [12.2513, 52.7925],
            [12.2414, 52.7889],
            [12.2212, 52.7894],
            [12.2255, 52.7816],
            [12.224, 52.776],
            [12.2147, 52.7753],
            [12.2088, 52.7598],
            [12.2135, 52.7526],
            [12.2218, 52.7533],
            [12.2219, 52.7453],
            [12.2181, 52.7372],
            [12.2081, 52.7357],
            [12.2067, 52.7288],
            [12.2107, 52.7211],
            [12.2119, 52.7093],
            [12.2253, 52.7049],
            [12.2175, 52.6988],
            [12.2202, 52.6901],
            [12.2306, 52.6914],
            [12.2312, 52.6843],
            [12.2375, 52.6821],
            [12.2398, 52.6748],
            [12.2362, 52.6715],
            [12.2349, 52.6601],
            [12.2383, 52.6441],
            [12.2313, 52.6396],
            [12.2295, 52.6301],
            [12.212, 52.6255],
            [12.2024, 52.6197],
            [12.1924, 52.6208],
            [12.1717, 52.627],
            [12.1687, 52.617],
            [12.1708, 52.6076],
            [12.1779, 52.6065],
            [12.1735, 52.5987],
            [12.1746, 52.579],
            [12.18, 52.5753],
            [12.1622, 52.5634],
            [12.144, 52.5308],
            [12.1729, 52.5397],
            [12.1767, 52.536],
            [12.1901, 52.5331],
            [12.186, 52.5267],
            [12.1777, 52.5233],
            [12.1672, 52.5143],
            [12.1704, 52.5079],
            [12.1834, 52.4993],
            [12.2005, 52.4963],
            [12.2088, 52.5038],
            [12.2184, 52.5013],
            [12.2242, 52.5042],
            [12.2396, 52.5221],
            [12.2562, 52.5179],
            [12.261, 52.5041],
            [12.2703, 52.5006],
            [12.2712, 52.4865],
            [12.3033, 52.4948],
            [12.3083, 52.4917],
            [12.3308, 52.4967],
            [12.3265, 52.4907],
            [12.3091, 52.4882],
            [12.3218, 52.4766],
            [12.3313, 52.4788],
            [12.3227, 52.4643],
            [12.3137, 52.4617],
            [12.3151, 52.4509],
            [12.3033, 52.4515],
            [12.3008, 52.4454],
            [12.3031, 52.436],
            [12.2888, 52.4303],
            [12.2967, 52.4241],
            [12.2755, 52.4164],
            [12.2746, 52.4121],
            [12.2914, 52.4074],
            [12.299, 52.4088],
            [12.3023, 52.3964],
            [12.2939, 52.3909],
            [12.2968, 52.3798],
            [12.3049, 52.3777],
            [12.3003, 52.3711],
            [12.2895, 52.3698],
            [12.2829, 52.3654],
            [12.2978, 52.3505],
            [12.3075, 52.3452],
            [12.2882, 52.3225],
            [12.277, 52.3161],
            [12.263, 52.2915],
            [12.2511, 52.2746],
            [12.2503, 52.2658],
            [12.2546, 52.2625],
            [12.2464, 52.2537],
            [12.249, 52.2477],
            [12.2565, 52.2427],
            [12.2702, 52.2414],
            [12.2721, 52.2361],
            [12.2837, 52.2344],
            [12.289, 52.2255],
            [12.2756, 52.2155],
            [12.2599, 52.2165],
            [12.2477, 52.2114],
            [12.2533, 52.2055],
            [12.2489, 52.1992],
            [12.2416, 52.1966],
            [12.2469, 52.1841],
            [12.2273, 52.1843],
            [12.2187, 52.1742],
            [12.222, 52.166],
            [12.2329, 52.1628],
            [12.2298, 52.1573],
            [12.2459, 52.1384],
            [12.2619, 52.1322],
            [12.2696, 52.1228],
            [12.2706, 52.1105],
            [12.2756, 52.1042],
            [12.2869, 52.1031],
            [12.3049, 52.0943],
            [12.3133, 52.0921],
            [12.3187, 52.0865],
            [12.3291, 52.0686],
            [12.3425, 52.0619],
            [12.3591, 52.0476],
            [12.3749, 52.0458],
            [12.3926, 52.0416],
            [12.4094, 52.0289],
            [12.418, 52.0288],
            [12.4263, 52.0201],
            [12.4352, 52.0164],
            [12.4505, 52.0172],
            [12.4532, 52.0259],
            [12.4616, 52.0345],
            [12.4807, 52.033],
            [12.487, 52.0164],
            [12.504, 52.0076],
            [12.5363, 52.0027],
            [12.542, 51.988],
            [12.565, 51.9837],
            [12.6127, 51.9823],
            [12.6148, 51.992],
            [12.6449, 51.9946],
            [12.6486, 52.0066],
            [12.6563, 52.0129],
            [12.6715, 52.0122],
            [12.684, 52.0026],
            [12.7001, 52.0022],
            [12.7292, 51.9892],
            [12.7558, 51.9869],
            [12.7626, 51.9808],
            [12.775, 51.9763],
            [12.7762, 51.9669],
            [12.7862, 51.962],
            [12.8239, 51.9631],
            [12.8451, 51.9681],
            [12.8504, 51.9552],
            [12.8525, 51.9353],
            [12.8766, 51.9335],
            [12.8872, 51.9341],
            [12.8937, 51.9279],
            [12.9174, 51.9331],
            [12.9198, 51.9394],
            [12.9426, 51.9359],
            [12.9584, 51.9353],
            [12.9564, 51.9228],
            [12.9754, 51.9208],
            [12.9736, 51.8995],
            [12.9893, 51.9032],
            [13.0227, 51.9039],
            [13.0319, 51.901],
            [13.0461, 51.9],
            [13.0486, 51.8922],
            [13.038, 51.8917],
            [13.0399, 51.8802],
            [13.0289, 51.8803],
            [13.0421, 51.8712],
            [13.0827, 51.8684],
            [13.0851, 51.8736],
            [13.1004, 51.8731],
            [13.1199, 51.8772],
            [13.1206, 51.8819],
            [13.1314, 51.8824],
            [13.1394, 51.8798],
            [13.1428, 51.8719],
            [13.1509, 51.8713],
            [13.1529, 51.8609],
            [13.1227, 51.8569],
            [13.1294, 51.8435],
            [13.1446, 51.8236],
            [13.1441, 51.8193],
            [13.1542, 51.8162],
            [13.1634, 51.8063],
            [13.1619, 51.7925],
            [13.1565, 51.7734],
            [13.1573, 51.7662],
            [13.1505, 51.7594],
            [13.165, 51.7559],
            [13.1558, 51.7423],
            [13.1642, 51.7412],
            [13.1847, 51.7229],
            [13.1863, 51.717],
            [13.168, 51.7189],
            [13.1618, 51.7129],
            [13.1533, 51.7102],
            [13.1609, 51.6982],
            [13.1609, 51.6918],
            [13.1488, 51.685],
            [13.1265, 51.6749],
            [13.059, 51.6506],
            [13.0532, 51.6466],
            [13.0259, 51.6377],
            [13.0179, 51.6435],
            [13.019, 51.6547],
            [13.0123, 51.6646],
            [13.0034, 51.6613],
            [12.9951, 51.67],
            [12.9745, 51.6678],
            [12.9643, 51.6646],
            [12.9697, 51.6508],
            [12.9485, 51.6497],
            [12.9505, 51.6435],
            [12.9423, 51.6384],
            [12.9308, 51.6491],
            [12.9171, 51.6489],
            [12.913, 51.6456],
            [12.9013, 51.6462],
            [12.8944, 51.6509],
            [12.8959, 51.6629],
            [12.8879, 51.667],
            [12.877, 51.6578],
            [12.8644, 51.6592],
            [12.8613, 51.6647],
            [12.8628, 51.6748],
            [12.8467, 51.6817],
            [12.8365, 51.6834],
            [12.8321, 51.678],
            [12.8228, 51.6753],
            [12.8078, 51.6674],
            [12.7934, 51.6657],
            [12.7804, 51.6529],
            [12.7731, 51.648],
            [12.7629, 51.6491],
            [12.7237, 51.6601],
            [12.7069, 51.6618],
            [12.6963, 51.6605],
            [12.6908, 51.6647],
            [12.6771, 51.6563],
            [12.6751, 51.6502],
            [12.6656, 51.6433],
            [12.6628, 51.6296],
            [12.6547, 51.6305],
            [12.6487, 51.6219],
            [12.6213, 51.6143],
            [12.6231, 51.6218],
            [12.6195, 51.6267],
            [12.6069, 51.6256],
            [12.5935, 51.6182],
            [12.5791, 51.6252],
            [12.5736, 51.6156],
            [12.5681, 51.6158],
            [12.5606, 51.6087],
            [12.5491, 51.6095],
            [12.5432, 51.6034],
            [12.5141, 51.6048],
            [12.5075, 51.6085],
            [12.496, 51.6059],
            [12.4941, 51.5961],
            [12.4822, 51.5952],
            [12.4862, 51.6026],
            [12.4714, 51.6063],
            [12.4593, 51.6016],
            [12.4446, 51.6092],
            [12.4332, 51.6059],
            [12.4378, 51.6018],
            [12.4259, 51.5965],
            [12.4244, 51.5821],
            [12.3881, 51.586],
            [12.3704, 51.5858],
            [12.3679, 51.5837],
            [12.3515, 51.5863],
            [12.3438, 51.5811],
            [12.3218, 51.5784],
            [12.3137, 51.5685],
            [12.2929, 51.5646],
            [12.2907, 51.5613],
            [12.269, 51.56],
            [12.2498, 51.5604],
            [12.2425, 51.5644],
            [12.2324, 51.5636],
            [12.236, 51.5527],
            [12.23, 51.5475],
            [12.206, 51.5396],
            [12.2063, 51.5349],
            [12.1908, 51.5241],
            [12.1938, 51.5069],
            [12.2077, 51.5025],
            [12.2063, 51.4912],
            [12.2013, 51.4853],
            [12.1773, 51.4817],
            [12.1767, 51.4705],
            [12.17, 51.4666],
            [12.1593, 51.4664],
            [12.1484, 51.4625],
            [12.1592, 51.4526],
            [12.1573, 51.4331],
            [12.1692, 51.4308],
            [12.1819, 51.4333],
            [12.1857, 51.4214],
            [12.1832, 51.4109],
            [12.175, 51.4105],
            [12.1761, 51.392],
            [12.1727, 51.3842],
            [12.188, 51.3673],
            [12.1951, 51.3636],
            [12.1923, 51.3539],
            [12.1964, 51.3295],
            [12.1648, 51.3245],
            [12.1629, 51.3193],
            [12.147, 51.321],
            [12.1569, 51.302],
            [12.1574, 51.2926],
            [12.1692, 51.2911],
            [12.172, 51.2778],
            [12.17, 51.2691],
            [12.1754, 51.2583],
            [12.1897, 51.2578],
            [12.1938, 51.2395],
            [12.1922, 51.2343],
            [12.199, 51.2119],
            [12.1895, 51.2041],
            [12.1914, 51.1959],
            [12.1874, 51.1894],
            [12.1739, 51.1896],
            [12.1742, 51.1814],
            [12.1895, 51.1848],
            [12.2123, 51.1831],
            [12.2016, 51.1603],
            [12.1969, 51.1583],
            [12.1957, 51.1418],
            [12.2254, 51.1372],
            [12.2394, 51.1317],
            [12.237, 51.1228],
            [12.2238, 51.1184],
            [12.2227, 51.1107],
            [12.2306, 51.1036],
            [12.2415, 51.1023],
            [12.2491, 51.0981],
            [12.26, 51.0977],
            [12.2643, 51.1007],
            [12.275, 51.0992],
            [12.2817, 51.0947],
            [12.2828, 51.0893],
            [12.2799, 51.079],
            [12.2675, 51.0786],
            [12.2679, 51.0732],
            [12.2591, 51.0669],
            [12.2613, 51.0503],
            [12.2495, 51.0441],
            [12.2495, 51.0373],
            [12.278, 51.0417],
            [12.2864, 51.0389],
            [12.2937, 51.0313],
            [12.2906, 51.0196],
            [12.2697, 51.0013],
            [12.2729, 50.9996],
            [12.2639, 50.9892],
            [12.2526, 50.9843],
            [12.2412, 50.9758],
            [12.2395, 50.9706],
            [12.2416, 50.9569],
            [12.2358, 50.951],
            [12.2137, 50.9386],
            [12.2099, 50.9498],
            [12.2145, 50.955],
            [12.2105, 50.9596],
            [12.1992, 50.9559],
            [12.1842, 50.9751],
            [12.1756, 50.9721],
            [12.1605, 50.9627],
            [12.1365, 50.9625],
            [12.1048, 50.9739],
            [12.0916, 50.9769],
            [12.0483, 50.9688],
            [12.0399, 50.9737],
            [12.0177, 50.9666],
            [12.0107, 50.9737],
            [12.017, 50.9764],
            [12.0073, 50.9903],
            [11.9976, 50.9927],
            [11.9755, 50.9901],
            [11.975, 50.9981],
            [11.9783, 51.0129],
            [11.9656, 51.0203],
            [11.9596, 51.0208],
            [11.9444, 51.0319],
            [11.9251, 51.0263],
            [11.9148, 51.027],
            [11.9143, 51.0345],
            [11.9065, 51.0458],
            [11.8975, 51.0447],
            [11.8912, 51.0542],
            [11.8775, 51.0566],
            [11.8555, 51.0511],
            [11.8459, 51.0526],
            [11.7988, 51.0469],
            [11.7756, 51.0527],
            [11.7649, 51.0444],
            [11.7516, 51.0487],
            [11.7511, 51.0565],
            [11.733, 51.0669],
            [11.7158, 51.07],
            [11.7013, 51.0702],
            [11.6991, 51.0922],
            [11.6881, 51.0989],
            [11.6736, 51.1027],
            [11.6693, 51.1105],
            [11.6475, 51.1065],
            [11.638, 51.1114],
            [11.6352, 51.1048],
            [11.6222, 51.1023],
            [11.6028, 51.1129],
            [11.5791, 51.1174],
            [11.5686, 51.1177],
            [11.5631, 51.1098],
            [11.549, 51.1113],
            [11.5441, 51.1023],
            [11.5288, 51.1035],
            [11.5128, 51.1072],
            [11.5039, 51.1013],
            [11.4858, 51.1019],
            [11.484, 51.108],
            [11.4651, 51.1115],
            [11.4671, 51.1282],
            [11.4586, 51.13],
            [11.4591, 51.1372],
            [11.4519, 51.1464],
            [11.4672, 51.1474],
            [11.4795, 51.158],
            [11.4776, 51.1652],
            [11.4717, 51.1697],
            [11.4709, 51.1921],
            [11.4514, 51.1936],
            [11.4455, 51.2034],
            [11.4269, 51.211],
            [11.3966, 51.2063],
            [11.3893, 51.2071],
            [11.3918, 51.2169],
            [11.3832, 51.2194],
            [11.3674, 51.2201],
            [11.3693, 51.229],
            [11.3765, 51.2327],
            [11.3881, 51.2438],
            [11.3984, 51.2426],
            [11.4053, 51.238],
            [11.4178, 51.2431],
            [11.4219, 51.2523],
            [11.4317, 51.2568],
            [11.4381, 51.2629],
            [11.4383, 51.2688],
            [11.453, 51.2736],
            [11.46, 51.2906],
            [11.467, 51.2878],
            [11.4769, 51.2956],
            [11.4647, 51.3066],
            [11.4403, 51.3161],
            [11.4281, 51.3249],
            [11.4208, 51.3349],
            [11.4303, 51.3362],
            [11.4287, 51.3415],
            [11.4171, 51.3426],
            [11.4085, 51.3408],
            [11.4006, 51.3444],
            [11.4043, 51.3534],
            [11.4018, 51.3611],
            [11.3935, 51.3596],
            [11.3915, 51.3706],
            [11.3986, 51.3729],
            [11.3975, 51.3809],
            [11.3802, 51.387],
            [11.369, 51.3863],
            [11.3597, 51.3893],
            [11.3452, 51.3854],
            [11.3385, 51.3903],
            [11.3301, 51.3915],
            [11.3287, 51.401],
            [11.3211, 51.4101],
            [11.2738, 51.4012],
            [11.2525, 51.401],
            [11.2473, 51.4059],
            [11.2302, 51.4059],
            [11.2209, 51.4134],
            [11.2095, 51.4122],
            [11.2085, 51.408],
            [11.1905, 51.4033],
            [11.1844, 51.4068],
            [11.1535, 51.402],
            [11.1329, 51.405],
            [11.1282, 51.4134],
            [11.1036, 51.4169],
            [11.0944, 51.4213],
            [11.0707, 51.4286],
            [11.0502, 51.4296],
            [11.0355, 51.4222],
            [11.0244, 51.421],
            [11.0184, 51.4174],
            [11.0048, 51.4169],
            [10.9938, 51.4198],
            [10.9895, 51.4258],
            [10.9776, 51.4268],
            [10.9783, 51.4319],
            [10.9663, 51.4325],
            [10.97, 51.4418],
            [10.9659, 51.4459],
            [10.9714, 51.4514],
            [10.9685, 51.4586],
            [10.9621, 51.4605],
            [10.9602, 51.4712],
            [10.9727, 51.4824],
            [10.9662, 51.4848],
            [10.9575, 51.4927],
            [10.9391, 51.5004],
            [10.9437, 51.5041],
            [10.9313, 51.5188],
            [10.9333, 51.5319],
            [10.9459, 51.5375],
            [10.936, 51.5425],
            [10.8961, 51.5542],
            [10.8996, 51.5646],
            [10.8949, 51.5672],
            [10.8912, 51.5782],
            [10.8809, 51.5796],
            [10.8866, 51.5884],
            [10.8881, 51.5962],
            [10.8947, 51.5986],
            [10.9017, 51.5936],
            [10.9249, 51.5927],
            [10.9335, 51.5972],
            [10.9331, 51.6051],
            [10.915, 51.6095],
            [10.921, 51.6179],
            [10.8942, 51.6099],
            [10.8853, 51.6118],
            [10.8658, 51.628],
            [10.8578, 51.6325],
            [10.8325, 51.6305],
            [10.8249, 51.6229],
            [10.8156, 51.6246],
            [10.8163, 51.6322],
            [10.8111, 51.6392],
            [10.7892, 51.6414],
            [10.7708, 51.6446],
            [10.7646, 51.649],
            [10.7546, 51.6417],
            [10.7414, 51.6422],
            [10.7269, 51.6455],
            [10.7106, 51.6408],
            [10.7007, 51.6417],
            [10.6917, 51.646],
            [10.6822, 51.6606],
            [10.67, 51.6681],
            [10.6637, 51.6913],
            [10.6729, 51.6994],
            [10.6694, 51.71],
            [10.6568, 51.7186],
            [10.6495, 51.719],
            [10.6427, 51.7253],
            [10.6419, 51.7327],
            [10.6332, 51.735],
            [10.6318, 51.751],
            [10.6234, 51.7596],
            [10.6048, 51.7627],
            [10.591, 51.7688],
            [10.5797, 51.7821],
            [10.5867, 51.7871],
            [10.5811, 51.803],
            [10.5747, 51.8112],
            [10.5783, 51.8294],
            [10.5866, 51.8344],
            [10.585, 51.8407],
            [10.5746, 51.8405],
            [10.5822, 51.852],
            [10.6025, 51.8546],
            [10.6053, 51.8612],
            [10.6168, 51.8695],
            [10.6333, 51.8712],
            [10.6347, 51.8761],
            [10.6485, 51.8886],
            [10.6533, 51.8981],
            [10.649, 51.9087],
            [10.6403, 51.9151],
            [10.6145, 51.921],
            [10.6164, 51.9282],
            [10.6138, 51.9353],
            [10.6243, 51.9488],
            [10.6477, 51.9537],
            [10.6502, 51.9586],
            [10.6426, 51.9624],
            [10.6216, 51.9579],
            [10.6199, 51.967],
            [10.6074, 51.9689],
            [10.6063, 51.9773],
            [10.5853, 51.9746],
            [10.5859, 51.9827],
            [10.5762, 51.9926],
            [10.5625, 52.0031],
            [10.5758, 52.0113],
            [10.5969, 52.0134],
            [10.6021, 52.007],
            [10.6362, 52.0142],
            [10.6413, 52.0226],
            [10.6562, 52.026],
            [10.6489, 52.0412],
            [10.666, 52.0431],
            [10.7054, 52.0505],
            [10.7586, 52.0476],
            [10.7609, 52.0452],
            [10.7789, 52.0508],
            [10.8022, 52.0484],
            [10.8208, 52.049],
            [10.8295, 52.0467],
            [10.8562, 52.05],
            [10.8835, 52.0599],
            [10.8995, 52.0589],
            [10.9386, 52.0604],
            [10.9659, 52.0568],
            [10.9744, 52.073],
            [10.9719, 52.0859],
            [10.9428, 52.0928],
            [10.9391, 52.0957],
            [10.9435, 52.1031],
            [10.9592, 52.1059],
            [10.9781, 52.1054],
            [10.9874, 52.1122],
            [11.0107, 52.1218],
            [11.0164, 52.1276],
            [11.0258, 52.1287],
            [11.0363, 52.1341],
            [11.045, 52.1341],
            [11.0456, 52.144],
            [11.0594, 52.1529],
            [11.0568, 52.1601],
            [11.0622, 52.1643],
            [11.0601, 52.1728],
            [11.0342, 52.1721],
            [11.0142, 52.1814],
            [11.0135, 52.1982],
            [11.0227, 52.2001],
            [11.0218, 52.2069],
            [11.0294, 52.2114],
            [11.0755, 52.2196],
            [11.0855, 52.2286],
            [11.0737, 52.2426],
            [11.0587, 52.2412],
            [11.0527, 52.2526],
            [11.0557, 52.2569],
            [11.0525, 52.2665],
            [11.0414, 52.2722],
            [11.0281, 52.2704],
            [11.0214, 52.2807],
            [11.012, 52.2879],
            [11.0226, 52.2966],
            [11.0369, 52.3013],
            [11.0363, 52.3099],
            [11.0212, 52.318],
            [11.0127, 52.3257],
            [11.0069, 52.327],
            [11.002, 52.3359],
            [10.9879, 52.3349],
            [10.9836, 52.3411],
            [10.9973, 52.3421],
            [11.0209, 52.3471],
            [11.0365, 52.3438],
            [11.0444, 52.3487],
            [11.0565, 52.3493],
            [11.0692, 52.357],
            [11.0644, 52.3777],
            [11.0534, 52.3817],
            [11.0474, 52.3872],
            [11.0337, 52.3884],
            [11.0208, 52.3918],
            [11.0158, 52.3998],
            [11.0072, 52.404],
            [11.0047, 52.4096],
            [10.9932, 52.4137],
            [10.9868, 52.4227],
            [10.9731, 52.4308],
            [10.9629, 52.4342],
            [10.9568, 52.4446],
            [10.9496, 52.4483],
            [10.9464, 52.4544],
            [10.9357, 52.4592],
            [10.9326, 52.4757],
            [10.9404, 52.476],
            [10.9442, 52.4922],
            [10.9766, 52.5006],
            [10.9848, 52.4957],
            [11.0095, 52.4957],
            [10.9742, 52.5226],
            [10.9483, 52.5466],
            [10.9426, 52.5542],
            [10.9378, 52.5671],
            [10.9436, 52.5698],
            [10.9411, 52.5758],
            [10.9459, 52.5836],
            [10.9399, 52.589],
            [10.9552, 52.5948],
            [10.9745, 52.6063],
            [10.9765, 52.6233],
            [10.9627, 52.6256],
            [10.9466, 52.6205],
            [10.9428, 52.6151],
            [10.9275, 52.6088],
            [10.9051, 52.6276],
            [10.903, 52.6356],
            [10.8962, 52.6444],
            [10.8839, 52.6493],
            [10.8777, 52.6644],
            [10.8525, 52.6807],
            [10.8496, 52.6864],
            [10.8355, 52.6983],
            [10.8367, 52.7077],
            [10.829, 52.7162],
            [10.8231, 52.7171],
            [10.7987, 52.7137],
            [10.7936, 52.7279],
            [10.7888, 52.7327],
            [10.7933, 52.7475],
            [10.7882, 52.7526],
            [10.7784, 52.7539],
            [10.7771, 52.7621],
            [10.7659, 52.7712],
            [10.7566, 52.7836],
            [10.7627, 52.8011],
            [10.7586, 52.8199],
            [10.7673, 52.8255],
            [10.7644, 52.8303],
            [10.7722, 52.8345],
            [10.7676, 52.8424],
            [10.7974, 52.8442],
            [10.8012, 52.8506],
            [10.832, 52.847],
            [10.8443, 52.8519],
            [10.8604, 52.8519],
            [10.873, 52.8544],
            [10.8819, 52.8459],
            [10.8923, 52.8467],
            [10.8976, 52.8559],
            [10.9135, 52.8527],
            [10.9447, 52.8558],
            [10.9517, 52.8638],
            [10.9641, 52.8717],
            [10.9675, 52.8784],
            [10.9757, 52.8771],
            [10.9878, 52.8846],
            [10.9921, 52.9077],
            [11.0058, 52.9115],
            [11.012, 52.9101],
            [11.0311, 52.9131],
            [11.0455, 52.9124],
            [11.0673, 52.9089],
            [11.0777, 52.9129],
            [11.0906, 52.9137],
            [11.0969, 52.9095],
            [11.0951, 52.8993],
            [11.1113, 52.8964],
            [11.1338, 52.8983],
            [11.1523, 52.9018],
            [11.1565, 52.9049],
            [11.1857, 52.8999],
            [11.1966, 52.9004],
            [11.2215, 52.8977],
            [11.2219, 52.894],
            [11.235, 52.8898],
            [11.2396, 52.8801],
            [11.2669, 52.8796],
            [11.2767, 52.888],
            [11.2913, 52.8896],
            [11.2953, 52.875],
            [11.3134, 52.8784],
            [11.3331, 52.8853],
            [11.3356, 52.8891],
            [11.3467, 52.8898],
            [11.3747, 52.8993],
            [11.4049, 52.905],
            [11.4245, 52.9195],
            [11.4309, 52.9199],
            [11.4512, 52.9323],
            [11.4674, 52.9378],
            [11.4861, 52.9403],
            [11.4985, 52.9402],
            [11.503, 52.9433],
            [11.4927, 52.9599],
            [11.5008, 52.9809],
            [11.503, 52.9906],
            [11.509, 53.0027],
            [11.5159, 53.0073],
            [11.5257, 53.0085],
            [11.5442, 53],
            [11.5562, 52.9996],
            [11.5535, 53.0064],
            [11.5595, 53.0126],
            [11.5971, 53.0338],
            [11.5986, 53.0364],
          ],
        ],
      },
      properties: {name: 'Sachsen-Anhalt', id: 'DE-ST', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-ST',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.7007, 51.6417],
            [10.7106, 51.6408],
            [10.7269, 51.6455],
            [10.7414, 51.6422],
            [10.7546, 51.6417],
            [10.7646, 51.649],
            [10.7708, 51.6446],
            [10.7892, 51.6414],
            [10.8111, 51.6392],
            [10.8163, 51.6322],
            [10.8156, 51.6246],
            [10.8249, 51.6229],
            [10.8325, 51.6305],
            [10.8578, 51.6325],
            [10.8658, 51.628],
            [10.8853, 51.6118],
            [10.8942, 51.6099],
            [10.921, 51.6179],
            [10.915, 51.6095],
            [10.9331, 51.6051],
            [10.9335, 51.5972],
            [10.9249, 51.5927],
            [10.9017, 51.5936],
            [10.8947, 51.5986],
            [10.8881, 51.5962],
            [10.8866, 51.5884],
            [10.8809, 51.5796],
            [10.8912, 51.5782],
            [10.8949, 51.5672],
            [10.8996, 51.5646],
            [10.8961, 51.5542],
            [10.936, 51.5425],
            [10.9459, 51.5375],
            [10.9333, 51.5319],
            [10.9313, 51.5188],
            [10.9437, 51.5041],
            [10.9391, 51.5004],
            [10.9575, 51.4927],
            [10.9662, 51.4848],
            [10.9727, 51.4824],
            [10.9602, 51.4712],
            [10.9621, 51.4605],
            [10.9685, 51.4586],
            [10.9714, 51.4514],
            [10.9659, 51.4459],
            [10.97, 51.4418],
            [10.9663, 51.4325],
            [10.9783, 51.4319],
            [10.9776, 51.4268],
            [10.9895, 51.4258],
            [10.9938, 51.4198],
            [11.0048, 51.4169],
            [11.0184, 51.4174],
            [11.0244, 51.421],
            [11.0355, 51.4222],
            [11.0502, 51.4296],
            [11.0707, 51.4286],
            [11.0944, 51.4213],
            [11.1036, 51.4169],
            [11.1282, 51.4134],
            [11.1329, 51.405],
            [11.1535, 51.402],
            [11.1844, 51.4068],
            [11.1905, 51.4033],
            [11.2085, 51.408],
            [11.2095, 51.4122],
            [11.2209, 51.4134],
            [11.2302, 51.4059],
            [11.2473, 51.4059],
            [11.2525, 51.401],
            [11.2738, 51.4012],
            [11.3211, 51.4101],
            [11.3287, 51.401],
            [11.3301, 51.3915],
            [11.3385, 51.3903],
            [11.3452, 51.3854],
            [11.3597, 51.3893],
            [11.369, 51.3863],
            [11.3802, 51.387],
            [11.3975, 51.3809],
            [11.3986, 51.3729],
            [11.3915, 51.3706],
            [11.3935, 51.3596],
            [11.4018, 51.3611],
            [11.4043, 51.3534],
            [11.4006, 51.3444],
            [11.4085, 51.3408],
            [11.4171, 51.3426],
            [11.4287, 51.3415],
            [11.4303, 51.3362],
            [11.4208, 51.3349],
            [11.4281, 51.3249],
            [11.4403, 51.3161],
            [11.4647, 51.3066],
            [11.4769, 51.2956],
            [11.467, 51.2878],
            [11.46, 51.2906],
            [11.453, 51.2736],
            [11.4383, 51.2688],
            [11.4381, 51.2629],
            [11.4317, 51.2568],
            [11.4219, 51.2523],
            [11.4178, 51.2431],
            [11.4053, 51.238],
            [11.3984, 51.2426],
            [11.3881, 51.2438],
            [11.3765, 51.2327],
            [11.3693, 51.229],
            [11.3674, 51.2201],
            [11.3832, 51.2194],
            [11.3918, 51.2169],
            [11.3893, 51.2071],
            [11.3966, 51.2063],
            [11.4269, 51.211],
            [11.4455, 51.2034],
            [11.4514, 51.1936],
            [11.4709, 51.1921],
            [11.4717, 51.1697],
            [11.4776, 51.1652],
            [11.4795, 51.158],
            [11.4672, 51.1474],
            [11.4519, 51.1464],
            [11.4591, 51.1372],
            [11.4586, 51.13],
            [11.4671, 51.1282],
            [11.4651, 51.1115],
            [11.484, 51.108],
            [11.4858, 51.1019],
            [11.5039, 51.1013],
            [11.5128, 51.1072],
            [11.5288, 51.1035],
            [11.5441, 51.1023],
            [11.549, 51.1113],
            [11.5631, 51.1098],
            [11.5686, 51.1177],
            [11.5791, 51.1174],
            [11.6028, 51.1129],
            [11.6222, 51.1023],
            [11.6352, 51.1048],
            [11.638, 51.1114],
            [11.6475, 51.1065],
            [11.6693, 51.1105],
            [11.6736, 51.1027],
            [11.6881, 51.0989],
            [11.6991, 51.0922],
            [11.7013, 51.0702],
            [11.7158, 51.07],
            [11.733, 51.0669],
            [11.7511, 51.0565],
            [11.7516, 51.0487],
            [11.7649, 51.0444],
            [11.7756, 51.0527],
            [11.7988, 51.0469],
            [11.8459, 51.0526],
            [11.8555, 51.0511],
            [11.8775, 51.0566],
            [11.8912, 51.0542],
            [11.8975, 51.0447],
            [11.9065, 51.0458],
            [11.9143, 51.0345],
            [11.9148, 51.027],
            [11.9251, 51.0263],
            [11.9444, 51.0319],
            [11.9596, 51.0208],
            [11.9656, 51.0203],
            [11.9783, 51.0129],
            [11.975, 50.9981],
            [11.9755, 50.9901],
            [11.9976, 50.9927],
            [12.0073, 50.9903],
            [12.017, 50.9764],
            [12.0107, 50.9737],
            [12.0177, 50.9666],
            [12.0399, 50.9737],
            [12.0483, 50.9688],
            [12.0916, 50.9769],
            [12.1048, 50.9739],
            [12.1365, 50.9625],
            [12.1605, 50.9627],
            [12.1756, 50.9721],
            [12.1842, 50.9751],
            [12.1992, 50.9559],
            [12.2105, 50.9596],
            [12.2145, 50.955],
            [12.2099, 50.9498],
            [12.2137, 50.9386],
            [12.2358, 50.951],
            [12.2416, 50.9569],
            [12.2395, 50.9706],
            [12.2412, 50.9758],
            [12.2526, 50.9843],
            [12.2639, 50.9892],
            [12.2729, 50.9996],
            [12.2697, 51.0013],
            [12.2906, 51.0196],
            [12.2937, 51.0313],
            [12.2864, 51.0389],
            [12.278, 51.0417],
            [12.2495, 51.0373],
            [12.2495, 51.0441],
            [12.2613, 51.0503],
            [12.2591, 51.0669],
            [12.2679, 51.0732],
            [12.2675, 51.0786],
            [12.2799, 51.079],
            [12.2828, 51.0893],
            [12.3024, 51.0981],
            [12.3143, 51.0924],
            [12.3286, 51.1032],
            [12.3367, 51.1025],
            [12.3566, 51.0948],
            [12.3538, 51.0845],
            [12.3589, 51.0803],
            [12.3737, 51.0768],
            [12.3934, 51.0838],
            [12.4029, 51.0759],
            [12.4422, 51.0778],
            [12.4479, 51.0771],
            [12.4574, 51.0821],
            [12.4591, 51.0721],
            [12.4692, 51.0653],
            [12.4929, 51.0659],
            [12.5018, 51.0638],
            [12.5057, 51.0507],
            [12.5028, 51.0495],
            [12.503, 51.0333],
            [12.5112, 51.0268],
            [12.5065, 51.0178],
            [12.5149, 51.0172],
            [12.5198, 51.0231],
            [12.5389, 51.0238],
            [12.535, 51.0142],
            [12.541, 51.0065],
            [12.5278, 51.0025],
            [12.537, 50.996],
            [12.5513, 50.9974],
            [12.571, 50.9909],
            [12.6064, 50.9915],
            [12.6163, 50.9856],
            [12.6219, 50.9746],
            [12.6215, 50.9671],
            [12.6304, 50.9399],
            [12.6436, 50.9273],
            [12.6526, 50.9233],
            [12.639, 50.9089],
            [12.6394, 50.9035],
            [12.6155, 50.9053],
            [12.6011, 50.9051],
            [12.5894, 50.9017],
            [12.5849, 50.9035],
            [12.565, 50.9016],
            [12.5589, 50.9058],
            [12.5374, 50.8999],
            [12.5252, 50.9021],
            [12.5203, 50.9071],
            [12.5075, 50.9031],
            [12.5101, 50.8926],
            [12.4916, 50.8932],
            [12.4794, 50.8863],
            [12.4726, 50.8861],
            [12.4599, 50.8813],
            [12.463, 50.8733],
            [12.459, 50.8679],
            [12.4441, 50.8645],
            [12.4477, 50.8548],
            [12.44, 50.8496],
            [12.4406, 50.843],
            [12.4311, 50.8436],
            [12.4196, 50.8394],
            [12.4108, 50.8463],
            [12.4181, 50.8551],
            [12.4066, 50.8567],
            [12.4027, 50.8486],
            [12.384, 50.8506],
            [12.3654, 50.8469],
            [12.3605, 50.8419],
            [12.3481, 50.8375],
            [12.3469, 50.8232],
            [12.3044, 50.8201],
            [12.2958, 50.8255],
            [12.2857, 50.8201],
            [12.2846, 50.8109],
            [12.2745, 50.8144],
            [12.2591, 50.8117],
            [12.2533, 50.8186],
            [12.2458, 50.8116],
            [12.2454, 50.806],
            [12.2509, 50.801],
            [12.2537, 50.7892],
            [12.2665, 50.7881],
            [12.287, 50.7908],
            [12.2869, 50.7776],
            [12.2795, 50.7705],
            [12.2644, 50.7607],
            [12.2426, 50.7681],
            [12.231, 50.7605],
            [12.2254, 50.7428],
            [12.2374, 50.7341],
            [12.2638, 50.7379],
            [12.2731, 50.7279],
            [12.2538, 50.7164],
            [12.2636, 50.7009],
            [12.2618, 50.6943],
            [12.2698, 50.6895],
            [12.2826, 50.6865],
            [12.2962, 50.6874],
            [12.3071, 50.6841],
            [12.3144, 50.6866],
            [12.3206, 50.6807],
            [12.3125, 50.6717],
            [12.317, 50.6633],
            [12.3045, 50.6622],
            [12.3016, 50.655],
            [12.2918, 50.6478],
            [12.279, 50.6443],
            [12.2636, 50.6424],
            [12.2637, 50.6299],
            [12.2485, 50.634],
            [12.2246, 50.6322],
            [12.1999, 50.6384],
            [12.1989, 50.6319],
            [12.189, 50.6236],
            [12.1905, 50.6131],
            [12.1774, 50.6136],
            [12.1711, 50.6257],
            [12.1497, 50.6294],
            [12.1451, 50.6233],
            [12.1488, 50.6163],
            [12.144, 50.61],
            [12.147, 50.6041],
            [12.143, 50.5987],
            [12.144, 50.5861],
            [12.1611, 50.5841],
            [12.1632, 50.5767],
            [12.1549, 50.5728],
            [12.1287, 50.5736],
            [12.1246, 50.5673],
            [12.1148, 50.568],
            [12.1023, 50.5785],
            [12.0803, 50.5762],
            [12.0779, 50.5575],
            [12.0662, 50.5553],
            [12.0565, 50.5508],
            [12.0474, 50.5536],
            [12.034, 50.5523],
            [12.0313, 50.5574],
            [12.0189, 50.5674],
            [12.033, 50.5807],
            [12.0372, 50.5933],
            [12.0157, 50.6052],
            [12.0031, 50.6145],
            [12.02, 50.6244],
            [12.0087, 50.6351],
            [12.0014, 50.6283],
            [11.9825, 50.621],
            [11.987, 50.6092],
            [11.9667, 50.5992],
            [11.96, 50.6025],
            [11.9548, 50.5953],
            [11.9434, 50.5894],
            [11.9261, 50.5847],
            [11.9324, 50.5782],
            [11.9338, 50.5707],
            [11.9207, 50.5581],
            [11.9137, 50.5545],
            [11.9001, 50.5517],
            [11.8877, 50.5526],
            [11.8833, 50.546],
            [11.8705, 50.5376],
            [11.888, 50.5175],
            [11.8955, 50.5188],
            [11.9031, 50.5149],
            [11.9186, 50.517],
            [11.915, 50.5213],
            [11.932, 50.5232],
            [11.9315, 50.5115],
            [11.9402, 50.5028],
            [11.9448, 50.5048],
            [11.9537, 50.4931],
            [11.9593, 50.4936],
            [11.9658, 50.4867],
            [11.9424, 50.4758],
            [11.9433, 50.465],
            [11.9401, 50.4585],
            [11.9223, 50.4593],
            [11.9185, 50.4536],
            [11.9067, 50.4538],
            [11.8955, 50.4484],
            [11.8923, 50.4431],
            [11.8997, 50.4357],
            [11.9283, 50.4322],
            [11.9236, 50.4233],
            [11.909, 50.4213],
            [11.9052, 50.4172],
            [11.8839, 50.4151],
            [11.8585, 50.3987],
            [11.8508, 50.4016],
            [11.8251, 50.3894],
            [11.8184, 50.3921],
            [11.8195, 50.3997],
            [11.8036, 50.4097],
            [11.7976, 50.4183],
            [11.776, 50.418],
            [11.7703, 50.4105],
            [11.7548, 50.4152],
            [11.7591, 50.4055],
            [11.7392, 50.4091],
            [11.7257, 50.4006],
            [11.7169, 50.3996],
            [11.7083, 50.4025],
            [11.6919, 50.3947],
            [11.6699, 50.3948],
            [11.6465, 50.3908],
            [11.6217, 50.388],
            [11.6055, 50.3988],
            [11.5939, 50.403],
            [11.5824, 50.3986],
            [11.573, 50.4003],
            [11.5604, 50.3854],
            [11.5494, 50.3857],
            [11.5319, 50.3803],
            [11.5256, 50.3754],
            [11.5206, 50.3883],
            [11.5209, 50.3959],
            [11.5079, 50.3962],
            [11.4976, 50.3997],
            [11.4875, 50.3972],
            [11.4794, 50.4008],
            [11.4796, 50.4068],
            [11.489, 50.4159],
            [11.4822, 50.4248],
            [11.4831, 50.4303],
            [11.465, 50.4321],
            [11.4597, 50.4274],
            [11.4417, 50.4383],
            [11.4255, 50.4411],
            [11.4205, 50.4446],
            [11.4188, 50.4525],
            [11.4241, 50.4593],
            [11.4164, 50.4678],
            [11.4209, 50.4774],
            [11.4194, 50.481],
            [11.4282, 50.492],
            [11.4375, 50.493],
            [11.441, 50.4996],
            [11.4353, 50.5075],
            [11.4266, 50.5143],
            [11.3872, 50.5153],
            [11.3725, 50.5225],
            [11.3443, 50.5184],
            [11.3472, 50.511],
            [11.3216, 50.5088],
            [11.3249, 50.4978],
            [11.3208, 50.4901],
            [11.3021, 50.4897],
            [11.2976, 50.4816],
            [11.2898, 50.4851],
            [11.2679, 50.4802],
            [11.2491, 50.4806],
            [11.2451, 50.4707],
            [11.2529, 50.4634],
            [11.2544, 50.4561],
            [11.249, 50.453],
            [11.2521, 50.4468],
            [11.2611, 50.4445],
            [11.267, 50.4308],
            [11.268, 50.4191],
            [11.258, 50.4004],
            [11.2705, 50.3938],
            [11.2635, 50.3872],
            [11.2726, 50.3847],
            [11.2772, 50.3789],
            [11.2801, 50.3665],
            [11.2845, 50.3604],
            [11.2782, 50.3552],
            [11.2663, 50.351],
            [11.2763, 50.3363],
            [11.2614, 50.3371],
            [11.2607, 50.3248],
            [11.257, 50.3224],
            [11.2693, 50.3063],
            [11.2516, 50.293],
            [11.2607, 50.2889],
            [11.2578, 50.283],
            [11.2591, 50.2742],
            [11.2515, 50.2686],
            [11.2453, 50.2685],
            [11.2339, 50.2751],
            [11.2196, 50.2804],
            [11.1952, 50.2931],
            [11.1889, 50.2799],
            [11.1867, 50.2692],
            [11.1759, 50.2699],
            [11.1826, 50.28],
            [11.1721, 50.2836],
            [11.1655, 50.2827],
            [11.1554, 50.2884],
            [11.1462, 50.2857],
            [11.1335, 50.2999],
            [11.1348, 50.3136],
            [11.1419, 50.3183],
            [11.1577, 50.3242],
            [11.158, 50.3296],
            [11.1371, 50.3492],
            [11.138, 50.3531],
            [11.1197, 50.3594],
            [11.1154, 50.3666],
            [11.0891, 50.3653],
            [11.0814, 50.3517],
            [11.0732, 50.3536],
            [11.0646, 50.3495],
            [11.0522, 50.3478],
            [11.0444, 50.344],
            [11.037, 50.3456],
            [11.0334, 50.3551],
            [11.0372, 50.36],
            [11.0152, 50.3612],
            [11.0089, 50.3647],
            [10.9939, 50.3653],
            [10.9925, 50.3611],
            [10.998, 50.3494],
            [10.9851, 50.3478],
            [10.9798, 50.3545],
            [10.9614, 50.3672],
            [10.9552, 50.3782],
            [10.9362, 50.3923],
            [10.8988, 50.3845],
            [10.8918, 50.3804],
            [10.8857, 50.3922],
            [10.8727, 50.3963],
            [10.8627, 50.3879],
            [10.8326, 50.3924],
            [10.8057, 50.3794],
            [10.7833, 50.382],
            [10.775, 50.372],
            [10.7655, 50.3715],
            [10.7616, 50.363],
            [10.749, 50.3582],
            [10.7351, 50.3638],
            [10.7197, 50.3646],
            [10.7161, 50.3621],
            [10.7207, 50.349],
            [10.7281, 50.3487],
            [10.7283, 50.3376],
            [10.7194, 50.3327],
            [10.7149, 50.3248],
            [10.7215, 50.3185],
            [10.7424, 50.3156],
            [10.7603, 50.3032],
            [10.7712, 50.2932],
            [10.7944, 50.2951],
            [10.8036, 50.2916],
            [10.8047, 50.2803],
            [10.8194, 50.2772],
            [10.8271, 50.2781],
            [10.8333, 50.2736],
            [10.8463, 50.2746],
            [10.8504, 50.2648],
            [10.8508, 50.2514],
            [10.8469, 50.2446],
            [10.8355, 50.2392],
            [10.8229, 50.2379],
            [10.8075, 50.2409],
            [10.8016, 50.2519],
            [10.7835, 50.2481],
            [10.7696, 50.2417],
            [10.7469, 50.2441],
            [10.7474, 50.2484],
            [10.7319, 50.2515],
            [10.7267, 50.2408],
            [10.727, 50.2315],
            [10.731, 50.2129],
            [10.7223, 50.2053],
            [10.71, 50.21],
            [10.6951, 50.2122],
            [10.6868, 50.2192],
            [10.685, 50.2244],
            [10.6626, 50.2217],
            [10.6579, 50.2303],
            [10.6378, 50.2235],
            [10.6135, 50.2244],
            [10.6078, 50.2304],
            [10.6027, 50.2419],
            [10.6099, 50.2481],
            [10.5995, 50.2666],
            [10.5915, 50.2715],
            [10.5929, 50.2781],
            [10.6, 50.2822],
            [10.5968, 50.2886],
            [10.6044, 50.2928],
            [10.5971, 50.3064],
            [10.5928, 50.3069],
            [10.5924, 50.3171],
            [10.6, 50.3337],
            [10.5818, 50.3339],
            [10.5618, 50.3379],
            [10.5523, 50.3462],
            [10.554, 50.3565],
            [10.5447, 50.364],
            [10.5326, 50.3657],
            [10.5236, 50.3551],
            [10.5131, 50.3509],
            [10.5129, 50.3589],
            [10.4918, 50.3553],
            [10.4913, 50.3623],
            [10.4963, 50.3658],
            [10.488, 50.3751],
            [10.4737, 50.3736],
            [10.4596, 50.3817],
            [10.4538, 50.3933],
            [10.4537, 50.4012],
            [10.4384, 50.401],
            [10.438, 50.3949],
            [10.4237, 50.3924],
            [10.4002, 50.3958],
            [10.389, 50.3988],
            [10.3849, 50.4031],
            [10.3955, 50.4172],
            [10.4021, 50.4198],
            [10.4031, 50.4257],
            [10.3852, 50.4284],
            [10.3708, 50.4353],
            [10.3581, 50.455],
            [10.3489, 50.4625],
            [10.3377, 50.4654],
            [10.3377, 50.4729],
            [10.3419, 50.4828],
            [10.3348, 50.4863],
            [10.3307, 50.4939],
            [10.3074, 50.4924],
            [10.2915, 50.4968],
            [10.2761, 50.5053],
            [10.2671, 50.5041],
            [10.2564, 50.513],
            [10.2313, 50.5117],
            [10.227, 50.5209],
            [10.231, 50.5245],
            [10.2252, 50.5296],
            [10.2218, 50.5383],
            [10.2089, 50.5403],
            [10.2037, 50.5456],
            [10.2041, 50.5541],
            [10.1875, 50.5537],
            [10.1772, 50.5495],
            [10.1758, 50.5427],
            [10.1612, 50.5489],
            [10.1542, 50.5494],
            [10.1472, 50.5432],
            [10.1371, 50.544],
            [10.1218, 50.5558],
            [10.1204, 50.5639],
            [10.1088, 50.5637],
            [10.1007, 50.5581],
            [10.0988, 50.552],
            [10.0829, 50.5415],
            [10.074, 50.5388],
            [10.0684, 50.5298],
            [10.0521, 50.5192],
            [10.042, 50.5163],
            [10.0389, 50.5294],
            [10.0486, 50.5401],
            [10.0477, 50.5454],
            [10.0571, 50.554],
            [10.0463, 50.5712],
            [10.046, 50.5793],
            [10.0498, 50.5843],
            [10.049, 50.5986],
            [10.0414, 50.6034],
            [10.0374, 50.6108],
            [10.0495, 50.6236],
            [10.0598, 50.6261],
            [10.0686, 50.6211],
            [10.0821, 50.6192],
            [10.0828, 50.6328],
            [10.0787, 50.6345],
            [10.0721, 50.6476],
            [10.0703, 50.6561],
            [10.0636, 50.6593],
            [10.0542, 50.6712],
            [10.0484, 50.6738],
            [10.0319, 50.6752],
            [10.0216, 50.673],
            [10.0006, 50.6765],
            [9.989, 50.6744],
            [9.9767, 50.6684],
            [9.9538, 50.6674],
            [9.9489, 50.6635],
            [9.949, 50.6568],
            [9.9678, 50.6415],
            [9.9608, 50.6364],
            [9.9605, 50.6298],
            [9.946, 50.6272],
            [9.9275, 50.6296],
            [9.9217, 50.634],
            [9.9095, 50.6368],
            [9.8786, 50.6349],
            [9.8765, 50.6405],
            [9.8843, 50.6541],
            [9.8793, 50.6703],
            [9.8929, 50.6831],
            [9.9041, 50.6839],
            [9.9114, 50.6878],
            [9.9202, 50.6971],
            [9.9094, 50.7014],
            [9.9131, 50.7107],
            [9.9344, 50.7236],
            [9.9361, 50.7336],
            [9.9403, 50.739],
            [9.9387, 50.7557],
            [9.9219, 50.7591],
            [9.928, 50.7689],
            [9.929, 50.7764],
            [9.9536, 50.7738],
            [9.9539, 50.7839],
            [9.9439, 50.7945],
            [9.9438, 50.7996],
            [9.9565, 50.809],
            [9.9505, 50.8152],
            [9.953, 50.8212],
            [9.9666, 50.8264],
            [9.9752, 50.8319],
            [9.9817, 50.8304],
            [9.9957, 50.826],
            [10.0016, 50.8284],
            [10.0065, 50.8372],
            [10.0229, 50.8321],
            [10.0282, 50.8409],
            [10.0376, 50.8453],
            [10.0351, 50.8534],
            [10.0218, 50.8485],
            [10.019, 50.8587],
            [10.0248, 50.8676],
            [10.0566, 50.8799],
            [10.0572, 50.8875],
            [10.0425, 50.8932],
            [10.0513, 50.8988],
            [10.0412, 50.9018],
            [10.035, 50.9092],
            [10.0113, 50.9219],
            [9.9986, 50.9198],
            [9.9785, 50.9057],
            [9.9689, 50.9115],
            [9.9723, 50.9162],
            [9.9827, 50.9209],
            [9.9849, 50.933],
            [9.9547, 50.9292],
            [9.9552, 50.9381],
            [9.9617, 50.9435],
            [9.9832, 50.9394],
            [9.9919, 50.9427],
            [10.0032, 50.9401],
            [10.0103, 50.9347],
            [10.027, 50.9436],
            [10.0306, 50.9387],
            [10.0491, 50.9393],
            [10.0601, 50.9427],
            [10.0361, 50.9561],
            [10.034, 50.9607],
            [10.0411, 50.9676],
            [10.0255, 50.9759],
            [10.0198, 50.9816],
            [10.0235, 50.9856],
            [10.0212, 50.9947],
            [10.0251, 50.9972],
            [10.0318, 51.0098],
            [10.0396, 51.0132],
            [10.0731, 51.0111],
            [10.0999, 51.0062],
            [10.1133, 51.0109],
            [10.1286, 51.0068],
            [10.126, 51.0002],
            [10.1367, 50.9975],
            [10.1426, 50.9932],
            [10.1647, 50.998],
            [10.1887, 50.998],
            [10.1969, 51.0061],
            [10.2047, 51.0079],
            [10.1957, 51.021],
            [10.2089, 51.0191],
            [10.2153, 51.0261],
            [10.2034, 51.0315],
            [10.203, 51.0372],
            [10.1958, 51.0424],
            [10.1813, 51.0482],
            [10.1721, 51.0491],
            [10.1585, 51.054],
            [10.1471, 51.0547],
            [10.1447, 51.066],
            [10.1519, 51.0691],
            [10.152, 51.0771],
            [10.1453, 51.0847],
            [10.166, 51.1003],
            [10.1615, 51.1078],
            [10.1758, 51.107],
            [10.1725, 51.1189],
            [10.1544, 51.1181],
            [10.1465, 51.1255],
            [10.1405, 51.1264],
            [10.1312, 51.1354],
            [10.1195, 51.1393],
            [10.1284, 51.1437],
            [10.1471, 51.1447],
            [10.1654, 51.1514],
            [10.1743, 51.1468],
            [10.1841, 51.1327],
            [10.1778, 51.1276],
            [10.1895, 51.1217],
            [10.1902, 51.1167],
            [10.1988, 51.1129],
            [10.2102, 51.1183],
            [10.2115, 51.1258],
            [10.2044, 51.1307],
            [10.2046, 51.1357],
            [10.2114, 51.1425],
            [10.2015, 51.1484],
            [10.2068, 51.1538],
            [10.2083, 51.163],
            [10.2133, 51.1678],
            [10.2297, 51.174],
            [10.2369, 51.1858],
            [10.2216, 51.1854],
            [10.2118, 51.1901],
            [10.1957, 51.1926],
            [10.1969, 51.1968],
            [10.1757, 51.2091],
            [10.1636, 51.2122],
            [10.1593, 51.2079],
            [10.1468, 51.2088],
            [10.143, 51.217],
            [10.1257, 51.2192],
            [10.1128, 51.2156],
            [10.1046, 51.2222],
            [10.0965, 51.2205],
            [10.0857, 51.2269],
            [10.0795, 51.2243],
            [10.0673, 51.2277],
            [10.071, 51.2391],
            [10.0755, 51.2439],
            [10.0619, 51.2529],
            [10.0504, 51.2549],
            [10.0462, 51.2651],
            [10.0587, 51.2708],
            [10.0593, 51.2766],
            [10.0526, 51.2795],
            [10.0363, 51.2794],
            [10.0122, 51.2822],
            [10.0035, 51.2873],
            [9.9953, 51.2841],
            [9.9722, 51.2846],
            [9.9758, 51.2917],
            [9.9697, 51.2995],
            [9.9584, 51.3051],
            [9.9464, 51.3029],
            [9.9392, 51.3089],
            [9.9473, 51.3162],
            [9.9468, 51.3268],
            [9.9428, 51.3354],
            [9.9319, 51.3383],
            [9.9315, 51.3461],
            [9.9249, 51.3544],
            [9.9236, 51.3685],
            [9.928, 51.3753],
            [9.9438, 51.3789],
            [9.9543, 51.3898],
            [9.9665, 51.3962],
            [9.987, 51.3983],
            [9.9902, 51.4026],
            [10.0006, 51.4048],
            [10.0082, 51.4103],
            [10.0104, 51.417],
            [10.0383, 51.4208],
            [10.0486, 51.4356],
            [10.0593, 51.4354],
            [10.0597, 51.4273],
            [10.072, 51.4183],
            [10.0784, 51.4248],
            [10.0803, 51.435],
            [10.0909, 51.4361],
            [10.1055, 51.4339],
            [10.1109, 51.4308],
            [10.131, 51.4348],
            [10.1411, 51.4299],
            [10.1536, 51.4324],
            [10.1421, 51.4377],
            [10.1434, 51.4453],
            [10.1747, 51.4471],
            [10.1787, 51.458],
            [10.1866, 51.4634],
            [10.1795, 51.4674],
            [10.1901, 51.4807],
            [10.2066, 51.4872],
            [10.2167, 51.4781],
            [10.216, 51.4728],
            [10.2354, 51.4715],
            [10.2379, 51.4777],
            [10.2458, 51.4834],
            [10.2736, 51.488],
            [10.2729, 51.4911],
            [10.3005, 51.4959],
            [10.3003, 51.5057],
            [10.3073, 51.5145],
            [10.3183, 51.5136],
            [10.3425, 51.5189],
            [10.3449, 51.5263],
            [10.3524, 51.5351],
            [10.3676, 51.5432],
            [10.3664, 51.5471],
            [10.3729, 51.5572],
            [10.3801, 51.563],
            [10.3784, 51.5792],
            [10.3692, 51.5866],
            [10.3776, 51.5888],
            [10.3893, 51.5862],
            [10.4054, 51.5868],
            [10.4328, 51.5923],
            [10.4456, 51.5892],
            [10.4505, 51.5845],
            [10.4725, 51.582],
            [10.4947, 51.5731],
            [10.51, 51.5567],
            [10.5189, 51.5541],
            [10.5336, 51.5597],
            [10.5468, 51.5553],
            [10.5607, 51.5563],
            [10.5778, 51.5655],
            [10.5869, 51.5663],
            [10.6003, 51.579],
            [10.6117, 51.5792],
            [10.6275, 51.5725],
            [10.6254, 51.568],
            [10.6365, 51.5619],
            [10.6564, 51.5587],
            [10.6582, 51.5674],
            [10.6653, 51.5759],
            [10.6558, 51.5822],
            [10.6386, 51.5845],
            [10.6326, 51.5965],
            [10.6354, 51.6059],
            [10.6447, 51.6075],
            [10.6395, 51.6157],
            [10.647, 51.622],
            [10.671, 51.6289],
            [10.6754, 51.6376],
            [10.6955, 51.6392],
            [10.7007, 51.6417],
          ],
        ],
      },
      properties: {name: 'Thuringia', id: 'DE-TH', CNTRY: 'Germany', TYPE: 'State'},
      id: 'DE-TH',
    },
  ],
}
export default map
