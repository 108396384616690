import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import {useIntl} from "react-intl";
import {GA4productsDataByBrandDaum} from "../../../models/AbstractReportDetails";
import Pagination from "../../../../common/Pagination/Pagination";
import SeoTable from "../../SeoStatistic/SeoWidgets/SeoTable";


interface SeoBrandData {
    sessions: string
    itemRevenue: number
    itemName: string
    itemUsers: number
    itemsPurchased: number
}

const GA4ForSeoBrandTable: React.FC = () => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [brandDataArray, setBrandDataArray] = useState<SeoBrandData[]>()
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number>();
    const itemsPerPage = 10;


    useEffect(() => {
        if (reportData.details.GA4productsDataByBrand) {
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const slicedData = reportData.details.GA4productsDataByBrand.slice(startIndex, endIndex);

            const mappedData = slicedData.map((item: GA4productsDataByBrandDaum) => ({
                sessions: item.sessions,
                itemRevenue: item.itemRevenue,
                itemName: item.itemBrand === '' ? intl.formatMessage({id: 'DIFFERENT'}) : item.itemBrand,
                itemUsers: item.totalUsers,
                itemsPurchased: item.itemsPurchased,
            }));
            setPages(Math.ceil(reportData.details.GA4productsDataByBrand.length / itemsPerPage));
            setBrandDataArray(mappedData);
        }
    }, [reportData.details.GA4productsDataByBrand, currentPage]);

    return (
        <>
            {brandDataArray && brandDataArray?.length > 0 && (
                    <div className='px-5 py-3'>
                         <SeoTable
                             dataArray={brandDataArray}
                             title={intl.formatMessage({id: 'BRAND_PRODUCT'})}
                             subtitle={intl.formatMessage({id: 'CATEGORIES'})}
                         />
                        <div className='d-flex flex-stack flex-wrap justify-content-start justify-content-xl-center pt-5'>
                            <Pagination {...{currentPage, setCurrentPage}} pages={pages} />
                        </div>
                    </div>
            )}
        </>
    )

}


export default GA4ForSeoBrandTable
