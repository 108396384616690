var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.0238, 49.9425],
            [6.0213, 49.9357],
            [6.0257, 49.9293],
            [6.0339, 49.9236],
            [6.0249, 49.9212],
            [6.0275, 49.9171],
            [6.0074, 49.9096],
            [6.0083, 49.8969],
            [6.0023, 49.8932],
            [6.0039, 49.8793],
            [5.9875, 49.8747],
            [5.9836, 49.8711],
            [5.9889, 49.8628],
            [5.9777, 49.8602],
            [5.9747, 49.8505],
            [5.9672, 49.8572],
            [5.956, 49.8582],
            [5.9539, 49.8623],
            [5.9319, 49.8671],
            [5.9176, 49.8641],
            [5.9084, 49.8546],
            [5.8919, 49.8546],
            [5.8942, 49.8597],
            [5.9014, 49.8603],
            [5.8805, 49.8807],
            [5.8717, 49.8754],
            [5.8719, 49.8698],
            [5.8773, 49.8658],
            [5.8677, 49.8623],
            [5.8643, 49.8582],
            [5.8573, 49.8563],
            [5.8534, 49.8638],
            [5.8478, 49.8665],
            [5.8471, 49.8715],
            [5.8388, 49.8718],
            [5.8389, 49.878],
            [5.8312, 49.8783],
            [5.8279, 49.8722],
            [5.8118, 49.871],
            [5.8181, 49.8659],
            [5.8043, 49.8567],
            [5.7922, 49.8525],
            [5.7866, 49.8538],
            [5.7662, 49.8546],
            [5.7619, 49.8563],
            [5.7484, 49.856],
            [5.7474, 49.866],
            [5.7231, 49.8769],
            [5.7124, 49.8798],
            [5.7086, 49.8848],
            [5.72, 49.8938],
            [5.725, 49.895],
            [5.7372, 49.9017],
            [5.7431, 49.916],
            [5.7531, 49.9243],
            [5.7531, 49.9281],
            [5.7452, 49.9293],
            [5.7505, 49.9476],
            [5.7636, 49.9471],
            [5.7764, 49.954],
            [5.7833, 49.9507],
            [5.7857, 49.9583],
            [5.7951, 49.962],
            [5.8094, 49.9703],
            [5.8122, 49.9801],
            [5.8338, 49.9806],
            [5.8349, 49.978],
            [5.8481, 49.9819],
            [5.8475, 49.9913],
            [5.8662, 49.9827],
            [5.8762, 49.9858],
            [5.8822, 49.9849],
            [5.8876, 49.9908],
            [5.8968, 49.9943],
            [5.9, 49.9989],
            [5.9062, 50],
            [5.9024, 50.0051],
            [5.9072, 50.0098],
            [5.9185, 50.0127],
            [5.9252, 50.0172],
            [5.9329, 50.0167],
            [5.9469, 50.0222],
            [5.9602, 50.0089],
            [5.9775, 50.0023],
            [5.9856, 50.0014],
            [5.992, 49.9948],
            [6.0107, 49.9912],
            [6.0238, 49.9952],
            [6.0254, 49.9903],
            [6.0408, 49.9946],
            [6.0416, 49.9924],
            [6.0322, 49.9775],
            [6.0171, 49.9758],
            [6.0087, 49.9684],
            [6.0052, 49.9607],
            [6.0245, 49.9556],
            [6.0196, 49.9515],
            [6.025, 49.9466],
            [6.0238, 49.9425],
          ],
        ],
      },
      properties: {id: 'LU-WI', name: 'Wiltz', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-WI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.9747, 49.8505],
            [5.9776, 49.8442],
            [5.9666, 49.8457],
            [5.962, 49.8414],
            [5.9702, 49.8352],
            [5.9687, 49.8303],
            [5.977, 49.8263],
            [5.9736, 49.8204],
            [5.9755, 49.8175],
            [5.9904, 49.8183],
            [6.0186, 49.8278],
            [6.0312, 49.8266],
            [6.0356, 49.8288],
            [6.0449, 49.8278],
            [6.0351, 49.8227],
            [6.0296, 49.8129],
            [6.0357, 49.8076],
            [6.0313, 49.8041],
            [6.0189, 49.8016],
            [6.0079, 49.7903],
            [6.0013, 49.7907],
            [5.9929, 49.7853],
            [5.9945, 49.7779],
            [5.9903, 49.7738],
            [5.9923, 49.7665],
            [5.982, 49.7593],
            [5.9764, 49.7528],
            [5.9791, 49.7473],
            [5.9984, 49.7425],
            [5.9908, 49.7262],
            [5.989, 49.7248],
            [5.9799, 49.7246],
            [5.978, 49.7295],
            [5.9641, 49.7239],
            [5.9532, 49.7243],
            [5.9466, 49.7183],
            [5.94, 49.7164],
            [5.9368, 49.712],
            [5.9301, 49.7103],
            [5.9211, 49.7037],
            [5.9074, 49.7028],
            [5.8905, 49.7041],
            [5.8856, 49.7125],
            [5.8693, 49.7142],
            [5.8555, 49.7092],
            [5.8471, 49.7146],
            [5.8461, 49.7187],
            [5.8366, 49.7213],
            [5.8231, 49.7149],
            [5.8024, 49.7184],
            [5.8054, 49.7263],
            [5.8045, 49.7308],
            [5.8069, 49.7391],
            [5.7954, 49.7436],
            [5.7914, 49.7539],
            [5.7878, 49.7554],
            [5.7721, 49.7728],
            [5.769, 49.7811],
            [5.7648, 49.7854],
            [5.7588, 49.7823],
            [5.746, 49.7847],
            [5.7322, 49.7792],
            [5.7279, 49.7807],
            [5.7243, 49.7957],
            [5.725, 49.8004],
            [5.7186, 49.8035],
            [5.721, 49.8073],
            [5.72, 49.8142],
            [5.7141, 49.8237],
            [5.7248, 49.8261],
            [5.7207, 49.8299],
            [5.72, 49.8406],
            [5.7322, 49.8463],
            [5.7319, 49.8515],
            [5.736, 49.8539],
            [5.7484, 49.856],
            [5.7619, 49.8563],
            [5.7662, 49.8546],
            [5.7866, 49.8538],
            [5.7922, 49.8525],
            [5.8043, 49.8567],
            [5.8181, 49.8659],
            [5.8118, 49.871],
            [5.8279, 49.8722],
            [5.8312, 49.8783],
            [5.8389, 49.878],
            [5.8388, 49.8718],
            [5.8471, 49.8715],
            [5.8478, 49.8665],
            [5.8534, 49.8638],
            [5.8573, 49.8563],
            [5.8643, 49.8582],
            [5.8677, 49.8623],
            [5.8773, 49.8658],
            [5.8719, 49.8698],
            [5.8717, 49.8754],
            [5.8805, 49.8807],
            [5.9014, 49.8603],
            [5.8942, 49.8597],
            [5.8919, 49.8546],
            [5.9084, 49.8546],
            [5.9176, 49.8641],
            [5.9319, 49.8671],
            [5.9539, 49.8623],
            [5.956, 49.8582],
            [5.9672, 49.8572],
            [5.9747, 49.8505],
          ],
        ],
      },
      properties: {id: 'LU-RD', name: 'Redange', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-RD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.0782, 49.9752],
            [6.0839, 49.9696],
            [6.0807, 49.9607],
            [6.0816, 49.956],
            [6.0758, 49.9485],
            [6.0773, 49.9449],
            [6.0727, 49.93],
            [6.0801, 49.9259],
            [6.0813, 49.9217],
            [6.094, 49.9165],
            [6.1012, 49.9117],
            [6.1073, 49.9007],
            [6.1187, 49.8971],
            [6.1186, 49.8894],
            [6.1269, 49.8932],
            [6.1371, 49.8899],
            [6.1495, 49.8783],
            [6.1661, 49.8737],
            [6.1742, 49.8687],
            [6.1793, 49.8726],
            [6.1814, 49.8815],
            [6.1885, 49.8804],
            [6.2041, 49.8869],
            [6.2137, 49.8878],
            [6.2175, 49.8919],
            [6.2261, 49.8895],
            [6.2325, 49.8836],
            [6.2378, 49.8822],
            [6.2454, 49.876],
            [6.2556, 49.8718],
            [6.2699, 49.8715],
            [6.2787, 49.8606],
            [6.2891, 49.8646],
            [6.2957, 49.8634],
            [6.2963, 49.8558],
            [6.29, 49.852],
            [6.2912, 49.8428],
            [6.2815, 49.8444],
            [6.27, 49.8441],
            [6.262, 49.8459],
            [6.2561, 49.8416],
            [6.2642, 49.8376],
            [6.2574, 49.83],
            [6.259, 49.821],
            [6.2642, 49.817],
            [6.2531, 49.8132],
            [6.2538, 49.8062],
            [6.2436, 49.8031],
            [6.2454, 49.795],
            [6.2366, 49.7927],
            [6.2331, 49.7995],
            [6.2259, 49.8008],
            [6.2187, 49.7934],
            [6.2115, 49.7945],
            [6.2071, 49.7989],
            [6.1895, 49.8058],
            [6.1901, 49.8136],
            [6.1841, 49.8219],
            [6.1756, 49.8206],
            [6.1616, 49.8225],
            [6.156, 49.8191],
            [6.1507, 49.8249],
            [6.1382, 49.8237],
            [6.1266, 49.8168],
            [6.1141, 49.8121],
            [6.1084, 49.816],
            [6.099, 49.8166],
            [6.0976, 49.8236],
            [6.0872, 49.8246],
            [6.0791, 49.8289],
            [6.0809, 49.8314],
            [6.0651, 49.8322],
            [6.0449, 49.8278],
            [6.0356, 49.8288],
            [6.0312, 49.8266],
            [6.0186, 49.8278],
            [5.9904, 49.8183],
            [5.9755, 49.8175],
            [5.9736, 49.8204],
            [5.977, 49.8263],
            [5.9687, 49.8303],
            [5.9702, 49.8352],
            [5.962, 49.8414],
            [5.9666, 49.8457],
            [5.9776, 49.8442],
            [5.9747, 49.8505],
            [5.9777, 49.8602],
            [5.9889, 49.8628],
            [5.9836, 49.8711],
            [5.9875, 49.8747],
            [6.0039, 49.8793],
            [6.0023, 49.8932],
            [6.0083, 49.8969],
            [6.0074, 49.9096],
            [6.0275, 49.9171],
            [6.0249, 49.9212],
            [6.0339, 49.9236],
            [6.0257, 49.9293],
            [6.0213, 49.9357],
            [6.0238, 49.9425],
            [6.0349, 49.9414],
            [6.0374, 49.947],
            [6.0442, 49.9483],
            [6.0484, 49.9523],
            [6.0588, 49.9662],
            [6.0602, 49.97],
            [6.0701, 49.9701],
            [6.0686, 49.9756],
            [6.0782, 49.9752],
          ],
        ],
      },
      properties: {id: 'LU-DI', name: 'Diekirch', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-DI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.1439, 49.9659],
            [6.1437, 49.96],
            [6.1504, 49.9509],
            [6.1549, 49.9488],
            [6.1561, 49.9431],
            [6.1663, 49.9452],
            [6.1608, 49.9512],
            [6.1632, 49.9562],
            [6.1711, 49.9547],
            [6.1715, 49.9457],
            [6.1799, 49.944],
            [6.188, 49.945],
            [6.1953, 49.9405],
            [6.1976, 49.9347],
            [6.2056, 49.9309],
            [6.2064, 49.9212],
            [6.1985, 49.9169],
            [6.2085, 49.9133],
            [6.2147, 49.9088],
            [6.2087, 49.9038],
            [6.2175, 49.8919],
            [6.2137, 49.8878],
            [6.2041, 49.8869],
            [6.1885, 49.8804],
            [6.1814, 49.8815],
            [6.1793, 49.8726],
            [6.1742, 49.8687],
            [6.1661, 49.8737],
            [6.1495, 49.8783],
            [6.1371, 49.8899],
            [6.1269, 49.8932],
            [6.1186, 49.8894],
            [6.1187, 49.8971],
            [6.1073, 49.9007],
            [6.1012, 49.9117],
            [6.094, 49.9165],
            [6.0813, 49.9217],
            [6.0801, 49.9259],
            [6.0727, 49.93],
            [6.0773, 49.9449],
            [6.0758, 49.9485],
            [6.0816, 49.956],
            [6.0807, 49.9607],
            [6.0839, 49.9696],
            [6.0782, 49.9752],
            [6.094, 49.9737],
            [6.0982, 49.9713],
            [6.0999, 49.9651],
            [6.1071, 49.9621],
            [6.115, 49.9651],
            [6.1198, 49.9637],
            [6.1356, 49.9687],
            [6.1439, 49.9659],
          ],
        ],
      },
      properties: {id: 'LU-VD', name: 'Vianden', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-VD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.989, 49.7248],
            [5.9886, 49.7246],
            [5.9889, 49.7172],
            [5.9957, 49.7075],
            [5.9901, 49.7027],
            [6.006, 49.6985],
            [6.0165, 49.6999],
            [6.02, 49.7021],
            [6.0348, 49.6962],
            [6.0559, 49.7024],
            [6.0603, 49.7084],
            [6.0652, 49.7087],
            [6.0746, 49.6994],
            [6.091, 49.7044],
            [6.0925, 49.6959],
            [6.0909, 49.6905],
            [6.0875, 49.6829],
            [6.0887, 49.6786],
            [6.0981, 49.6729],
            [6.1041, 49.6597],
            [6.0988, 49.655],
            [6.1, 49.6504],
            [6.0809, 49.6499],
            [6.0743, 49.6598],
            [6.0683, 49.6624],
            [6.0442, 49.644],
            [6.0451, 49.6396],
            [6.0423, 49.6314],
            [6.0312, 49.6234],
            [6.0288, 49.6155],
            [6.0254, 49.6118],
            [6.0167, 49.6098],
            [6.0061, 49.6105],
            [5.9984, 49.603],
            [5.9892, 49.5965],
            [5.9912, 49.5925],
            [5.9991, 49.5922],
            [6.0007, 49.5886],
            [5.9925, 49.5853],
            [5.998, 49.5817],
            [6.0052, 49.581],
            [6.0025, 49.5778],
            [5.9917, 49.5739],
            [5.9865, 49.5665],
            [5.9778, 49.5606],
            [5.9654, 49.5599],
            [5.9659, 49.5633],
            [5.945, 49.5656],
            [5.9349, 49.5546],
            [5.9259, 49.5579],
            [5.9211, 49.5535],
            [5.9099, 49.5492],
            [5.901, 49.5481],
            [5.8867, 49.5443],
            [5.877, 49.5383],
            [5.8663, 49.5386],
            [5.8692, 49.5478],
            [5.8665, 49.555],
            [5.8587, 49.5549],
            [5.8491, 49.5643],
            [5.8561, 49.5666],
            [5.852, 49.5749],
            [5.8553, 49.5823],
            [5.8377, 49.5788],
            [5.832, 49.5837],
            [5.8334, 49.5895],
            [5.8375, 49.5923],
            [5.8563, 49.5991],
            [5.8611, 49.6032],
            [5.8618, 49.6141],
            [5.8701, 49.6201],
            [5.8691, 49.627],
            [5.8782, 49.6312],
            [5.8891, 49.6301],
            [5.891, 49.6377],
            [5.8879, 49.6431],
            [5.8887, 49.6541],
            [5.8951, 49.6597],
            [5.883, 49.6613],
            [5.8635, 49.6656],
            [5.8507, 49.6661],
            [5.8466, 49.6694],
            [5.844, 49.6794],
            [5.8495, 49.6958],
            [5.8545, 49.7013],
            [5.8555, 49.7092],
            [5.8693, 49.7142],
            [5.8856, 49.7125],
            [5.8905, 49.7041],
            [5.9074, 49.7028],
            [5.9211, 49.7037],
            [5.9301, 49.7103],
            [5.9368, 49.712],
            [5.94, 49.7164],
            [5.9466, 49.7183],
            [5.9532, 49.7243],
            [5.9641, 49.7239],
            [5.978, 49.7295],
            [5.9799, 49.7246],
            [5.989, 49.7248],
          ],
        ],
      },
      properties: {id: 'LU-CA', name: 'Capellen', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-CA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.2454, 49.795],
            [6.2461, 49.7886],
            [6.2525, 49.7827],
            [6.2633, 49.7827],
            [6.2626, 49.7789],
            [6.2674, 49.7707],
            [6.2755, 49.7714],
            [6.2809, 49.7686],
            [6.2822, 49.7632],
            [6.2767, 49.7568],
            [6.2735, 49.7438],
            [6.2467, 49.7487],
            [6.2393, 49.7491],
            [6.2399, 49.7442],
            [6.2361, 49.7372],
            [6.2108, 49.7232],
            [6.2062, 49.7226],
            [6.1955, 49.7172],
            [6.1967, 49.7105],
            [6.1947, 49.7022],
            [6.1894, 49.7009],
            [6.1899, 49.694],
            [6.1748, 49.6912],
            [6.1627, 49.6861],
            [6.1389, 49.6885],
            [6.1346, 49.696],
            [6.1202, 49.698],
            [6.0969, 49.6908],
            [6.0909, 49.6905],
            [6.0925, 49.6959],
            [6.091, 49.7044],
            [6.0746, 49.6994],
            [6.0652, 49.7087],
            [6.0603, 49.7084],
            [6.0559, 49.7024],
            [6.0348, 49.6962],
            [6.02, 49.7021],
            [6.0165, 49.6999],
            [6.006, 49.6985],
            [5.9901, 49.7027],
            [5.9957, 49.7075],
            [5.9889, 49.7172],
            [5.9886, 49.7246],
            [5.9908, 49.7262],
            [5.9984, 49.7425],
            [5.9791, 49.7473],
            [5.9764, 49.7528],
            [5.982, 49.7593],
            [5.9923, 49.7665],
            [5.9903, 49.7738],
            [5.9945, 49.7779],
            [5.9929, 49.7853],
            [6.0013, 49.7907],
            [6.0079, 49.7903],
            [6.0189, 49.8016],
            [6.0313, 49.8041],
            [6.0357, 49.8076],
            [6.0296, 49.8129],
            [6.0351, 49.8227],
            [6.0449, 49.8278],
            [6.0651, 49.8322],
            [6.0809, 49.8314],
            [6.0791, 49.8289],
            [6.0872, 49.8246],
            [6.0976, 49.8236],
            [6.099, 49.8166],
            [6.1084, 49.816],
            [6.1141, 49.8121],
            [6.1266, 49.8168],
            [6.1382, 49.8237],
            [6.1507, 49.8249],
            [6.156, 49.8191],
            [6.1616, 49.8225],
            [6.1756, 49.8206],
            [6.1841, 49.8219],
            [6.1901, 49.8136],
            [6.1895, 49.8058],
            [6.2071, 49.7989],
            [6.2115, 49.7945],
            [6.2187, 49.7934],
            [6.2259, 49.8008],
            [6.2331, 49.7995],
            [6.2366, 49.7927],
            [6.2454, 49.795],
          ],
        ],
      },
      properties: {id: 'LU-ME', name: 'Mersch', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-ME',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.1748, 49.6912],
            [6.1855, 49.6909],
            [6.2033, 49.6867],
            [6.2059, 49.6926],
            [6.2237, 49.6926],
            [6.2339, 49.6878],
            [6.2413, 49.6885],
            [6.2672, 49.687],
            [6.2722, 49.6848],
            [6.2797, 49.6763],
            [6.2849, 49.6786],
            [6.2843, 49.6673],
            [6.2894, 49.666],
            [6.2886, 49.655],
            [6.2933, 49.652],
            [6.2957, 49.6422],
            [6.3085, 49.6373],
            [6.3125, 49.6294],
            [6.3051, 49.625],
            [6.2984, 49.6181],
            [6.2886, 49.6111],
            [6.2868, 49.6074],
            [6.2986, 49.6059],
            [6.3037, 49.6007],
            [6.3084, 49.5917],
            [6.3079, 49.5881],
            [6.2899, 49.5911],
            [6.277, 49.5875],
            [6.2723, 49.5829],
            [6.267, 49.5828],
            [6.2616, 49.5734],
            [6.2518, 49.5698],
            [6.2434, 49.573],
            [6.236, 49.5727],
            [6.2367, 49.5664],
            [6.2316, 49.5555],
            [6.2259, 49.559],
            [6.2214, 49.5564],
            [6.2259, 49.5476],
            [6.23, 49.545],
            [6.2286, 49.5397],
            [6.2288, 49.5391],
            [6.2265, 49.5363],
            [6.2143, 49.5396],
            [6.2129, 49.5337],
            [6.2006, 49.5301],
            [6.196, 49.5256],
            [6.1891, 49.5233],
            [6.1786, 49.5244],
            [6.1652, 49.5203],
            [6.161, 49.5122],
            [6.1527, 49.5117],
            [6.1471, 49.5164],
            [6.1584, 49.5224],
            [6.1516, 49.5254],
            [6.169, 49.5334],
            [6.1691, 49.5437],
            [6.1714, 49.5486],
            [6.1586, 49.5533],
            [6.1402, 49.553],
            [6.123, 49.5565],
            [6.1042, 49.5616],
            [6.0974, 49.5646],
            [6.0983, 49.572],
            [6.0925, 49.5725],
            [6.0851, 49.5767],
            [6.0726, 49.5781],
            [6.0753, 49.5852],
            [6.0726, 49.5888],
            [6.0622, 49.5896],
            [6.0481, 49.5876],
            [6.0348, 49.584],
            [6.0254, 49.5856],
            [6.0052, 49.581],
            [5.998, 49.5817],
            [5.9925, 49.5853],
            [6.0007, 49.5886],
            [5.9991, 49.5922],
            [5.9912, 49.5925],
            [5.9892, 49.5965],
            [5.9984, 49.603],
            [6.0061, 49.6105],
            [6.0167, 49.6098],
            [6.0254, 49.6118],
            [6.0288, 49.6155],
            [6.0312, 49.6234],
            [6.0423, 49.6314],
            [6.0451, 49.6396],
            [6.0442, 49.644],
            [6.0683, 49.6624],
            [6.0743, 49.6598],
            [6.0809, 49.6499],
            [6.1, 49.6504],
            [6.0988, 49.655],
            [6.1041, 49.6597],
            [6.0981, 49.6729],
            [6.0887, 49.6786],
            [6.0875, 49.6829],
            [6.0909, 49.6905],
            [6.0969, 49.6908],
            [6.1202, 49.698],
            [6.1346, 49.696],
            [6.1389, 49.6885],
            [6.1627, 49.6861],
            [6.1748, 49.6912],
          ],
        ],
      },
      properties: {id: 'LU-LU', name: 'Luxembourg', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-LU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.2735, 49.7438],
            [6.2825, 49.7436],
            [6.3051, 49.7357],
            [6.3108, 49.7328],
            [6.3245, 49.7326],
            [6.3291, 49.7287],
            [6.3405, 49.7277],
            [6.3457, 49.7333],
            [6.3512, 49.7305],
            [6.3617, 49.729],
            [6.3725, 49.7299],
            [6.3676, 49.7399],
            [6.3689, 49.7454],
            [6.376, 49.7491],
            [6.3836, 49.7475],
            [6.3871, 49.7403],
            [6.3962, 49.7381],
            [6.3931, 49.7347],
            [6.4038, 49.7298],
            [6.4087, 49.7241],
            [6.4202, 49.7229],
            [6.4301, 49.7316],
            [6.4567, 49.7296],
            [6.463, 49.7235],
            [6.4733, 49.7191],
            [6.4823, 49.7179],
            [6.4916, 49.7102],
            [6.4835, 49.7102],
            [6.4773, 49.7079],
            [6.4911, 49.6986],
            [6.4916, 49.6936],
            [6.473, 49.6852],
            [6.4701, 49.6816],
            [6.4571, 49.6794],
            [6.4544, 49.6773],
            [6.4406, 49.6764],
            [6.4337, 49.6692],
            [6.4237, 49.6626],
            [6.4045, 49.6578],
            [6.4004, 49.6554],
            [6.4021, 49.6502],
            [6.4157, 49.6464],
            [6.4185, 49.6378],
            [6.4061, 49.6166],
            [6.4073, 49.6107],
            [6.3891, 49.6012],
            [6.3829, 49.594],
            [6.3737, 49.5947],
            [6.3675, 49.5893],
            [6.3657, 49.5918],
            [6.3723, 49.5973],
            [6.3699, 49.603],
            [6.3615, 49.6122],
            [6.3429, 49.6148],
            [6.3294, 49.6261],
            [6.3125, 49.6294],
            [6.3085, 49.6373],
            [6.2957, 49.6422],
            [6.2933, 49.652],
            [6.2886, 49.655],
            [6.2894, 49.666],
            [6.2843, 49.6673],
            [6.2849, 49.6786],
            [6.2797, 49.6763],
            [6.2722, 49.6848],
            [6.2672, 49.687],
            [6.2413, 49.6885],
            [6.2339, 49.6878],
            [6.2237, 49.6926],
            [6.2059, 49.6926],
            [6.2033, 49.6867],
            [6.1855, 49.6909],
            [6.1748, 49.6912],
            [6.1899, 49.694],
            [6.1894, 49.7009],
            [6.1947, 49.7022],
            [6.1967, 49.7105],
            [6.1955, 49.7172],
            [6.2062, 49.7226],
            [6.2108, 49.7232],
            [6.2361, 49.7372],
            [6.2399, 49.7442],
            [6.2393, 49.7491],
            [6.2467, 49.7487],
            [6.2735, 49.7438],
          ],
        ],
      },
      properties: {id: 'LU-GR', name: 'Grevenmacher', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-GR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.4823, 49.7179],
            [6.4733, 49.7191],
            [6.463, 49.7235],
            [6.4567, 49.7296],
            [6.4301, 49.7316],
            [6.4202, 49.7229],
            [6.4087, 49.7241],
            [6.4038, 49.7298],
            [6.3931, 49.7347],
            [6.3962, 49.7381],
            [6.3871, 49.7403],
            [6.3836, 49.7475],
            [6.376, 49.7491],
            [6.3689, 49.7454],
            [6.3676, 49.7399],
            [6.3725, 49.7299],
            [6.3617, 49.729],
            [6.3512, 49.7305],
            [6.3457, 49.7333],
            [6.3405, 49.7277],
            [6.3291, 49.7287],
            [6.3245, 49.7326],
            [6.3108, 49.7328],
            [6.3051, 49.7357],
            [6.2825, 49.7436],
            [6.2735, 49.7438],
            [6.2767, 49.7568],
            [6.2822, 49.7632],
            [6.2809, 49.7686],
            [6.2755, 49.7714],
            [6.2674, 49.7707],
            [6.2626, 49.7789],
            [6.2633, 49.7827],
            [6.2525, 49.7827],
            [6.2461, 49.7886],
            [6.2454, 49.795],
            [6.2436, 49.8031],
            [6.2538, 49.8062],
            [6.2531, 49.8132],
            [6.2642, 49.817],
            [6.259, 49.821],
            [6.2574, 49.83],
            [6.2642, 49.8376],
            [6.2561, 49.8416],
            [6.262, 49.8459],
            [6.27, 49.8441],
            [6.2815, 49.8444],
            [6.2912, 49.8428],
            [6.29, 49.852],
            [6.2963, 49.8558],
            [6.3036, 49.8463],
            [6.2998, 49.8413],
            [6.3, 49.8365],
            [6.306, 49.8315],
            [6.3181, 49.8351],
            [6.3214, 49.843],
            [6.3309, 49.8468],
            [6.3469, 49.8453],
            [6.3543, 49.8365],
            [6.3707, 49.8246],
            [6.3757, 49.8194],
            [6.3876, 49.8127],
            [6.4026, 49.8108],
            [6.4076, 49.8044],
            [6.4176, 49.8077],
            [6.4268, 49.8044],
            [6.4347, 49.8049],
            [6.4383, 49.8097],
            [6.448, 49.8158],
            [6.4578, 49.8097],
            [6.4592, 49.8063],
            [6.4725, 49.8035],
            [6.4806, 49.7997],
            [6.4847, 49.7916],
            [6.4904, 49.7963],
            [6.4908, 49.8023],
            [6.4963, 49.8044],
            [6.5023, 49.7985],
            [6.493, 49.7859],
            [6.4832, 49.7832],
            [6.4806, 49.7771],
            [6.4892, 49.7725],
            [6.4844, 49.7668],
            [6.4842, 49.7606],
            [6.4906, 49.7554],
            [6.4889, 49.7452],
            [6.4766, 49.74],
            [6.4804, 49.7288],
            [6.4823, 49.7179],
          ],
        ],
      },
      properties: {id: 'LU-EC', name: 'Echternach', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-EC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.2288, 49.5391],
            [6.2297, 49.5369],
            [6.233, 49.5289],
            [6.2369, 49.5251],
            [6.231, 49.5191],
            [6.2352, 49.5139],
            [6.2281, 49.5063],
            [6.2138, 49.5068],
            [6.2062, 49.5034],
            [6.1998, 49.5037],
            [6.18, 49.5006],
            [6.1674, 49.5018],
            [6.1645, 49.5053],
            [6.1543, 49.4992],
            [6.1402, 49.4937],
            [6.139, 49.487],
            [6.1295, 49.4832],
            [6.1257, 49.4858],
            [6.1119, 49.4854],
            [6.1086, 49.4739],
            [6.1057, 49.4697],
            [6.0984, 49.4666],
            [6.0912, 49.4687],
            [6.0853, 49.4666],
            [6.0781, 49.457],
            [6.0791, 49.4468],
            [6.0715, 49.4456],
            [6.0631, 49.449],
            [6.0567, 49.4582],
            [6.0374, 49.4606],
            [6.031, 49.4582],
            [6.0191, 49.4411],
            [6.0151, 49.4387],
            [6.0003, 49.4404],
            [6.0006, 49.4459],
            [5.9826, 49.4418],
            [5.9738, 49.4456],
            [5.9558, 49.4397],
            [5.9627, 49.4513],
            [5.9586, 49.4539],
            [5.951, 49.4504],
            [5.9474, 49.463],
            [5.9524, 49.4725],
            [5.9455, 49.477],
            [5.9474, 49.4799],
            [5.9277, 49.4804],
            [5.9146, 49.4865],
            [5.8998, 49.4904],
            [5.8875, 49.4918],
            [5.882, 49.488],
            [5.8682, 49.4835],
            [5.8549, 49.4851],
            [5.8473, 49.483],
            [5.8401, 49.4835],
            [5.8294, 49.4901],
            [5.8242, 49.4908],
            [5.8213, 49.498],
            [5.8132, 49.5014],
            [5.808, 49.5076],
            [5.8094, 49.5119],
            [5.8168, 49.5136],
            [5.818, 49.5195],
            [5.8225, 49.5245],
            [5.8175, 49.5352],
            [5.813, 49.5335],
            [5.8099, 49.5271],
            [5.8004, 49.525],
            [5.7913, 49.5207],
            [5.783, 49.5281],
            [5.7918, 49.5295],
            [5.7942, 49.5347],
            [5.7992, 49.5364],
            [5.8073, 49.5435],
            [5.8239, 49.5447],
            [5.8277, 49.5528],
            [5.8346, 49.5573],
            [5.8406, 49.558],
            [5.8491, 49.5643],
            [5.8587, 49.5549],
            [5.8665, 49.555],
            [5.8692, 49.5478],
            [5.8663, 49.5386],
            [5.877, 49.5383],
            [5.8867, 49.5443],
            [5.901, 49.5481],
            [5.9099, 49.5492],
            [5.9211, 49.5535],
            [5.9259, 49.5579],
            [5.9349, 49.5546],
            [5.945, 49.5656],
            [5.9659, 49.5633],
            [5.9654, 49.5599],
            [5.9778, 49.5606],
            [5.9865, 49.5665],
            [5.9917, 49.5739],
            [6.0025, 49.5778],
            [6.0052, 49.581],
            [6.0254, 49.5856],
            [6.0348, 49.584],
            [6.0481, 49.5876],
            [6.0622, 49.5896],
            [6.0726, 49.5888],
            [6.0753, 49.5852],
            [6.0726, 49.5781],
            [6.0851, 49.5767],
            [6.0925, 49.5725],
            [6.0983, 49.572],
            [6.0974, 49.5646],
            [6.1042, 49.5616],
            [6.123, 49.5565],
            [6.1402, 49.553],
            [6.1586, 49.5533],
            [6.1714, 49.5486],
            [6.1691, 49.5437],
            [6.169, 49.5334],
            [6.1516, 49.5254],
            [6.1584, 49.5224],
            [6.1471, 49.5164],
            [6.1527, 49.5117],
            [6.161, 49.5122],
            [6.1652, 49.5203],
            [6.1786, 49.5244],
            [6.1891, 49.5233],
            [6.196, 49.5256],
            [6.2006, 49.5301],
            [6.2129, 49.5337],
            [6.2143, 49.5396],
            [6.2265, 49.5363],
            [6.2288, 49.5391],
          ],
        ],
      },
      properties: {id: 'LU-ES', name: 'Esch-sur-Alzette', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-ES',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.3675, 49.5893],
            [6.3653, 49.5838],
            [6.371, 49.5726],
            [6.3582, 49.5759],
            [6.3513, 49.5726],
            [6.3546, 49.5648],
            [6.3689, 49.5551],
            [6.367, 49.5467],
            [6.3584, 49.5424],
            [6.3544, 49.537],
            [6.347, 49.5332],
            [6.3446, 49.5279],
            [6.3513, 49.5053],
            [6.3477, 49.4741],
            [6.3451, 49.4708],
            [6.3449, 49.4586],
            [6.3417, 49.4511],
            [6.336, 49.4478],
            [6.3303, 49.4573],
            [6.3393, 49.4563],
            [6.3326, 49.4654],
            [6.3278, 49.4628],
            [6.3228, 49.467],
            [6.3152, 49.4642],
            [6.3064, 49.473],
            [6.2945, 49.4777],
            [6.2895, 49.4763],
            [6.2762, 49.4832],
            [6.2688, 49.4954],
            [6.2686, 49.5037],
            [6.2559, 49.5032],
            [6.2521, 49.5077],
            [6.2426, 49.5068],
            [6.2352, 49.5139],
            [6.231, 49.5191],
            [6.2369, 49.5251],
            [6.233, 49.5289],
            [6.2297, 49.5369],
            [6.2286, 49.5397],
            [6.23, 49.545],
            [6.2259, 49.5476],
            [6.2214, 49.5564],
            [6.2259, 49.559],
            [6.2316, 49.5555],
            [6.2367, 49.5664],
            [6.236, 49.5727],
            [6.2434, 49.573],
            [6.2518, 49.5698],
            [6.2616, 49.5734],
            [6.267, 49.5828],
            [6.2723, 49.5829],
            [6.277, 49.5875],
            [6.2899, 49.5911],
            [6.3079, 49.5881],
            [6.3084, 49.5917],
            [6.3037, 49.6007],
            [6.2986, 49.6059],
            [6.2868, 49.6074],
            [6.2886, 49.6111],
            [6.2984, 49.6181],
            [6.3051, 49.625],
            [6.3125, 49.6294],
            [6.3294, 49.6261],
            [6.3429, 49.6148],
            [6.3615, 49.6122],
            [6.3699, 49.603],
            [6.3723, 49.5973],
            [6.3657, 49.5918],
            [6.3675, 49.5893],
          ],
        ],
      },
      properties: {id: 'LU-RM', name: 'Remich', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-RM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.0238, 49.9425],
            [6.025, 49.9466],
            [6.0196, 49.9515],
            [6.0245, 49.9556],
            [6.0052, 49.9607],
            [6.0087, 49.9684],
            [6.0171, 49.9758],
            [6.0322, 49.9775],
            [6.0416, 49.9924],
            [6.0408, 49.9946],
            [6.0254, 49.9903],
            [6.0238, 49.9952],
            [6.0107, 49.9912],
            [5.992, 49.9948],
            [5.9856, 50.0014],
            [5.9775, 50.0023],
            [5.9602, 50.0089],
            [5.9469, 50.0222],
            [5.9329, 50.0167],
            [5.9252, 50.0172],
            [5.9185, 50.0127],
            [5.9072, 50.0098],
            [5.9024, 50.0051],
            [5.9062, 50],
            [5.9, 49.9989],
            [5.8968, 49.9943],
            [5.8876, 49.9908],
            [5.8822, 49.9849],
            [5.8762, 49.9858],
            [5.8662, 49.9827],
            [5.8475, 49.9913],
            [5.8481, 49.9819],
            [5.8349, 49.978],
            [5.8338, 49.9806],
            [5.8122, 49.9801],
            [5.8084, 49.9808],
            [5.8027, 49.9887],
            [5.8065, 49.9975],
            [5.8056, 50.002],
            [5.8211, 50.0079],
            [5.8282, 50.0089],
            [5.8391, 50.0177],
            [5.8379, 50.0227],
            [5.8432, 50.0248],
            [5.8487, 50.0309],
            [5.8463, 50.0352],
            [5.831, 50.0433],
            [5.8344, 50.053],
            [5.8391, 50.0504],
            [5.8544, 50.0588],
            [5.8646, 50.0611],
            [5.8634, 50.0702],
            [5.8701, 50.0826],
            [5.8724, 50.0928],
            [5.8839, 50.1006],
            [5.9299, 50.1237],
            [5.9416, 50.132],
            [5.9475, 50.1435],
            [5.9525, 50.1461],
            [5.9487, 50.1532],
            [5.9487, 50.1611],
            [5.9587, 50.1639],
            [5.9699, 50.163],
            [5.982, 50.167],
            [5.9944, 50.1732],
            [6.0075, 50.1663],
            [6.0125, 50.1563],
            [6.0223, 50.1506],
            [6.0264, 50.1501],
            [6.0511, 50.1418],
            [6.0716, 50.1475],
            [6.073, 50.1556],
            [6.0797, 50.1594],
            [6.0868, 50.1596],
            [6.0997, 50.1549],
            [6.1028, 50.1494],
            [6.0992, 50.1449],
            [6.1066, 50.1385],
            [6.1004, 50.1335],
            [6.1004, 50.1273],
            [6.1166, 50.1206],
            [6.1118, 50.1132],
            [6.1094, 50.1052],
            [6.1142, 50.0912],
            [6.1068, 50.0893],
            [6.1028, 50.0808],
            [6.104, 50.0696],
            [6.0978, 50.0624],
            [6.1056, 50.0627],
            [6.1009, 50.0525],
            [6.0952, 50.0482],
            [6.1073, 50.0401],
            [6.114, 50.0308],
            [6.1104, 50.0206],
            [6.1163, 50.0146],
            [6.1251, 50.0122],
            [6.1247, 50.0077],
            [6.1171, 50.0051],
            [6.1192, 49.9989],
            [6.1261, 49.9992],
            [6.1287, 49.9946],
            [6.1261, 49.9878],
            [6.1351, 49.9769],
            [6.1411, 49.9769],
            [6.1487, 49.9731],
            [6.1439, 49.9659],
            [6.1356, 49.9687],
            [6.1198, 49.9637],
            [6.115, 49.9651],
            [6.1071, 49.9621],
            [6.0999, 49.9651],
            [6.0982, 49.9713],
            [6.094, 49.9737],
            [6.0782, 49.9752],
            [6.0686, 49.9756],
            [6.0701, 49.9701],
            [6.0602, 49.97],
            [6.0588, 49.9662],
            [6.0484, 49.9523],
            [6.0442, 49.9483],
            [6.0374, 49.947],
            [6.0349, 49.9414],
            [6.0238, 49.9425],
          ],
        ],
      },
      properties: {id: 'LU-CL', name: 'Clervaux', TYPE: 'District', CNTRY: 'Luxembourg'},
      id: 'LU-CL',
    },
  ],
}
export default map
