import React, { MouseEventHandler } from 'react';

type SortOrder = "ascn" | "desc";
type SortKeys = any;

interface ISortButtonProps {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

const SortButton: React.FC<ISortButtonProps> = ({ sortOrder, columnKey, sortKey, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={`${
                sortKey === columnKey && sortOrder === "ascn"
                    ? "sort-button sort-reverse"
                    : "sort-button"
            }`}
        >
            ▲
        </button>
    );
};

export default SortButton;
