var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [7.0923, 52.839],
            [7.072, 52.8109],
            [7.0628, 52.7107],
            [7.0547, 52.6444],
            [7.0419, 52.6327],
            [7.0128, 52.6367],
            [7.0044, 52.6409],
            [6.9934, 52.6413],
            [6.9758, 52.6463],
            [6.9672, 52.6417],
            [6.94, 52.638],
            [6.9197, 52.6398],
            [6.914, 52.6467],
            [6.8982, 52.6511],
            [6.85, 52.6488],
            [6.8325, 52.6517],
            [6.8227, 52.6478],
            [6.7835, 52.6525],
            [6.768, 52.6469],
            [6.7589, 52.6488],
            [6.742, 52.6453],
            [6.7262, 52.6333],
            [6.7113, 52.6283],
            [6.7181, 52.6489],
            [6.696, 52.6536],
            [6.6238, 52.674],
            [6.6134, 52.6732],
            [6.5613, 52.6657],
            [6.5237, 52.6471],
            [6.5275, 52.6193],
            [6.5141, 52.6176],
            [6.4889, 52.6187],
            [6.4742, 52.6242],
            [6.4696, 52.6162],
            [6.4621, 52.6145],
            [6.4445, 52.62],
            [6.4402, 52.629],
            [6.4297, 52.6228],
            [6.4178, 52.622],
            [6.4118, 52.6167],
            [6.4029, 52.6176],
            [6.3939, 52.6132],
            [6.3851, 52.6169],
            [6.3838, 52.6277],
            [6.3722, 52.6385],
            [6.3723, 52.643],
            [6.3341, 52.6594],
            [6.3343, 52.6702],
            [6.3179, 52.6667],
            [6.306, 52.6668],
            [6.297, 52.6633],
            [6.2912, 52.6687],
            [6.2807, 52.6652],
            [6.2719, 52.6698],
            [6.26, 52.6698],
            [6.2513, 52.6789],
            [6.2454, 52.678],
            [6.2262, 52.6844],
            [6.2098, 52.6845],
            [6.2008, 52.6765],
            [6.1904, 52.6748],
            [6.1711, 52.6794],
            [6.1772, 52.6865],
            [6.1639, 52.6929],
            [6.1408, 52.7316],
            [6.1277, 52.7505],
            [6.1338, 52.7568],
            [6.1636, 52.7629],
            [6.1756, 52.7673],
            [6.1801, 52.7727],
            [6.2012, 52.786],
            [6.2088, 52.794],
            [6.1883, 52.8111],
            [6.1793, 52.8148],
            [6.1381, 52.8438],
            [6.1263, 52.8537],
            [6.1684, 52.8723],
            [6.2149, 52.8909],
            [6.2392, 52.9132],
            [6.2648, 52.9274],
            [6.3111, 52.9253],
            [6.342, 52.9062],
            [6.3767, 52.9221],
            [6.4023, 52.9327],
            [6.436, 52.9719],
            [6.4156, 52.9918],
            [6.3701, 53.0334],
            [6.3753, 53.0666],
            [6.3126, 53.0805],
            [6.3234, 53.0939],
            [6.3517, 53.0865],
            [6.3642, 53.1071],
            [6.3717, 53.1071],
            [6.3735, 53.1222],
            [6.3891, 53.1473],
            [6.3891, 53.1526],
            [6.412, 53.1677],
            [6.4152, 53.1776],
            [6.4515, 53.1872],
            [6.4562, 53.1961],
            [6.4924, 53.2039],
            [6.5043, 53.1975],
            [6.5149, 53.2001],
            [6.5373, 53.1945],
            [6.543, 53.1837],
            [6.5564, 53.18],
            [6.5667, 53.17],
            [6.5737, 53.1574],
            [6.5905, 53.1635],
            [6.5961, 53.1455],
            [6.6095, 53.1445],
            [6.6229, 53.1353],
            [6.6315, 53.1245],
            [6.6447, 53.1136],
            [6.6446, 53.1056],
            [6.6853, 53.1177],
            [6.7034, 53.1211],
            [6.7468, 53.1189],
            [6.8247, 53.0705],
            [6.8468, 53.0558],
            [6.8718, 53.0412],
            [6.9318, 53.0019],
            [6.9479, 52.9927],
            [6.9581, 52.9827],
            [6.9806, 52.9638],
            [7.0165, 52.9237],
            [7.0272, 52.9155],
            [7.0419, 52.9115],
            [7.0217, 52.8721],
            [7.0439, 52.8711],
            [7.0516, 52.8635],
            [7.077, 52.8452],
            [7.0784, 52.8391],
            [7.0923, 52.839],
          ],
        ],
      },
      properties: {
        name: 'Drenthe',
        id: 'NL-DR',
        CNTRY: 'Netherlands',
        NAME_ENG: 'Drenthe',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-DR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [5.8547, 52.4941],
              [5.8293, 52.4664],
              [5.8234, 52.4583],
              [5.816, 52.4548],
              [5.81, 52.444],
              [5.7951, 52.4342],
              [5.7522, 52.4136],
              [5.7345, 52.4173],
              [5.7271, 52.4164],
              [5.665, 52.3977],
              [5.634, 52.3816],
              [5.6207, 52.3708],
              [5.6118, 52.3663],
              [5.5897, 52.3628],
              [5.5691, 52.3673],
              [5.5484, 52.3538],
              [5.5425, 52.3475],
              [5.5469, 52.3377],
              [5.5587, 52.3323],
              [5.5542, 52.3278],
              [5.5572, 52.3215],
              [5.5542, 52.3134],
              [5.5409, 52.291],
              [5.535, 52.2748],
              [5.5041, 52.2695],
              [5.4585, 52.2596],
              [5.4173, 52.2533],
              [5.3997, 52.256],
              [5.3908, 52.2641],
              [5.3216, 52.3035],
              [5.3039, 52.3125],
              [5.2671, 52.3241],
              [5.245, 52.3294],
              [5.207, 52.3368],
              [5.1642, 52.3376],
              [5.147, 52.3472],
              [5.1442, 52.3722],
              [5.141, 52.3775],
              [5.1433, 52.3868],
              [5.1606, 52.393],
              [5.1649, 52.3993],
              [5.2135, 52.4164],
              [5.2327, 52.4201],
              [5.2371, 52.4255],
              [5.2622, 52.4354],
              [5.392, 52.4903],
              [5.4147, 52.4944],
              [5.4528, 52.5103],
              [5.4606, 52.5279],
              [5.4742, 52.5375],
              [5.4894, 52.5529],
              [5.506, 52.5558],
              [5.5622, 52.5917],
              [5.5786, 52.5961],
              [5.6334, 52.605],
              [5.6453, 52.6077],
              [5.6615, 52.5969],
              [5.7696, 52.5796],
              [5.8081, 52.5795],
              [5.817, 52.5786],
              [5.8567, 52.5525],
              [5.8626, 52.5435],
              [5.8638, 52.5201],
              [5.8607, 52.5058],
              [5.8547, 52.4941],
            ],
          ],
          [
            [
              [5.8011, 52.8058],
              [5.81, 52.7968],
              [5.8247, 52.7878],
              [5.8247, 52.7842],
              [5.856, 52.7841],
              [5.8855, 52.7733],
              [5.9052, 52.7621],
              [5.9122, 52.7615],
              [5.9287, 52.7515],
              [5.9483, 52.7186],
              [5.9589, 52.712],
              [5.9726, 52.6984],
              [5.9694, 52.6903],
              [5.9529, 52.684],
              [5.9403, 52.6743],
              [5.9475, 52.6701],
              [5.9738, 52.6668],
              [5.9913, 52.6563],
              [5.9702, 52.6391],
              [5.85, 52.6135],
              [5.8382, 52.6126],
              [5.6691, 52.6131],
              [5.6581, 52.6159],
              [5.6219, 52.6509],
              [5.5981, 52.6599],
              [5.5997, 52.702],
              [5.6, 52.7621],
              [5.6014, 52.7657],
              [5.6656, 52.8277],
              [5.6776, 52.8321],
              [5.7163, 52.8347],
              [5.7193, 52.8374],
              [5.7327, 52.8355],
              [5.73, 52.8436],
              [5.7551, 52.8391],
              [5.7907, 52.8174],
              [5.8011, 52.8058],
            ],
          ],
        ],
      },
      properties: {
        name: 'Flevoland',
        id: 'NL-FL',
        CNTRY: 'Netherlands',
        NAME_ENG: 'Flevoland',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-FL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [5.8011, 52.8058],
              [5.7907, 52.8174],
              [5.7551, 52.8391],
              [5.73, 52.8436],
              [5.7327, 52.8355],
              [5.7193, 52.8374],
              [5.7089, 52.8374],
              [5.7045, 52.8437],
              [5.691, 52.8455],
              [5.6627, 52.8437],
              [5.6628, 52.8536],
              [5.6494, 52.8599],
              [5.6285, 52.8537],
              [5.624, 52.8501],
              [5.6061, 52.8519],
              [5.5897, 52.8492],
              [5.5852, 52.8394],
              [5.5793, 52.8358],
              [5.5495, 52.8322],
              [5.4839, 52.8511],
              [5.4586, 52.8547],
              [5.4541, 52.8511],
              [5.4198, 52.8493],
              [5.4019, 52.8565],
              [5.3885, 52.8654],
              [5.3646, 52.8744],
              [5.3586, 52.8851],
              [5.3735, 52.8977],
              [5.3914, 52.904],
              [5.4049, 52.9049],
              [5.4108, 52.9094],
              [5.4138, 52.9211],
              [5.4048, 52.9381],
              [5.4003, 52.9417],
              [5.4138, 52.9471],
              [5.4108, 52.9597],
              [5.4168, 52.9624],
              [5.4033, 52.9902],
              [5.4033, 53.0009],
              [5.4122, 53.0198],
              [5.4017, 53.0225],
              [5.4002, 53.0297],
              [5.3927, 53.0404],
              [5.3838, 53.0467],
              [5.3672, 53.0736],
              [5.3942, 53.0682],
              [5.3942, 53.0754],
              [5.3867, 53.0844],
              [5.3819, 53.0957],
              [5.3853, 53.106],
              [5.4008, 53.1157],
              [5.4103, 53.1307],
              [5.4169, 53.1688],
              [5.4092, 53.1724],
              [5.4247, 53.1868],
              [5.4403, 53.2107],
              [5.4481, 53.2188],
              [5.4808, 53.2379],
              [5.5331, 53.2599],
              [5.5369, 53.2649],
              [5.5547, 53.2738],
              [5.5597, 53.2824],
              [5.5819, 53.2971],
              [5.5992, 53.3043],
              [5.6258, 53.3104],
              [5.6431, 53.3193],
              [5.6614, 53.3237],
              [5.6892, 53.3285],
              [5.7197, 53.3363],
              [5.7653, 53.3515],
              [5.8347, 53.3721],
              [5.8436, 53.376],
              [5.8692, 53.3821],
              [5.8836, 53.3887],
              [5.9397, 53.389],
              [5.9819, 53.3971],
              [5.9953, 53.3982],
              [6.0147, 53.4026],
              [6.0308, 53.4029],
              [6.0686, 53.4071],
              [6.0836, 53.4043],
              [6.0903, 53.4082],
              [6.1436, 53.4043],
              [6.1675, 53.4126],
              [6.1786, 53.4149],
              [6.1961, 53.4118],
              [6.1985, 53.4097],
              [6.174, 53.3901],
              [6.1768, 53.3758],
              [6.1826, 53.3667],
              [6.1946, 53.3613],
              [6.2217, 53.3575],
              [6.2322, 53.3539],
              [6.238, 53.344],
              [6.247, 53.3421],
              [6.2607, 53.3474],
              [6.2937, 53.3409],
              [6.2872, 53.3122],
              [6.3007, 53.3095],
              [6.2871, 53.3024],
              [6.2747, 53.2899],
              [6.2611, 53.2882],
              [6.2594, 53.2765],
              [6.2622, 53.2675],
              [6.2532, 53.2676],
              [6.2455, 53.2605],
              [6.2377, 53.2444],
              [6.221, 53.2328],
              [6.2314, 53.2291],
              [6.2372, 53.2174],
              [6.2265, 53.2049],
              [6.2068, 53.1979],
              [6.1838, 53.1666],
              [6.1832, 53.1343],
              [6.2129, 53.1153],
              [6.2473, 53.1133],
              [6.2698, 53.114],
              [6.2907, 53.1067],
              [6.298, 53.0995],
              [6.3234, 53.0939],
              [6.3126, 53.0805],
              [6.3753, 53.0666],
              [6.3701, 53.0334],
              [6.4156, 52.9918],
              [6.436, 52.9719],
              [6.4023, 52.9327],
              [6.3767, 52.9221],
              [6.342, 52.9062],
              [6.3111, 52.9253],
              [6.2648, 52.9274],
              [6.2392, 52.9132],
              [6.2149, 52.8909],
              [6.1684, 52.8723],
              [6.1263, 52.8537],
              [6.0874, 52.8387],
              [6.059, 52.837],
              [6.0663, 52.8262],
              [6.0378, 52.8148],
              [6.032, 52.8219],
              [6.0156, 52.8202],
              [6.0036, 52.8167],
              [5.9933, 52.8221],
              [5.9788, 52.8417],
              [5.9383, 52.8349],
              [5.9293, 52.8304],
              [5.9306, 52.8232],
              [5.9187, 52.8152],
              [5.9068, 52.8117],
              [5.8993, 52.8054],
              [5.8828, 52.801],
              [5.8665, 52.8046],
              [5.8427, 52.8056],
              [5.8427, 52.8083],
              [5.8264, 52.8173],
              [5.8011, 52.8058],
            ],
          ],
          [
            [
              [5.0703, 53.3071],
              [5.0981, 53.304],
              [5.0925, 53.2946],
              [5.0786, 53.2965],
              [5.0636, 53.2938],
              [5.0447, 53.2837],
              [5.0308, 53.2801],
              [4.9897, 53.266],
              [4.9875, 53.2615],
              [5.0025, 53.2565],
              [4.9675, 53.2382],
              [4.9564, 53.2368],
              [4.9375, 53.2274],
              [4.9231, 53.2174],
              [4.9164, 53.2157],
              [4.8808, 53.2165],
              [4.8586, 53.2054],
              [4.8503, 53.2126],
              [4.8503, 53.2212],
              [4.8725, 53.2293],
              [4.9253, 53.2524],
              [4.9603, 53.2718],
              [4.9997, 53.2885],
              [5.0281, 53.2985],
              [5.0619, 53.3071],
              [5.0703, 53.3071],
            ],
          ],
          [
            [
              [5.7125, 53.4335],
              [5.7036, 53.429],
              [5.6908, 53.429],
              [5.6869, 53.4257],
              [5.6569, 53.4249],
              [5.6325, 53.4296],
              [5.6153, 53.441],
              [5.6158, 53.4538],
              [5.6297, 53.4649],
              [5.6503, 53.4712],
              [5.6736, 53.4721],
              [5.6925, 53.4671],
              [5.7014, 53.4626],
              [5.7214, 53.4593],
              [5.7492, 53.4593],
              [5.8703, 53.4654],
              [5.9025, 53.4676],
              [5.9586, 53.4682],
              [5.9747, 53.4651],
              [5.9725, 53.4593],
              [5.9486, 53.4571],
              [5.9075, 53.4568],
              [5.8914, 53.4507],
              [5.8686, 53.4487],
              [5.8631, 53.4465],
              [5.8064, 53.4374],
              [5.7864, 53.4385],
              [5.7742, 53.4374],
              [5.7664, 53.4407],
              [5.7497, 53.4401],
              [5.7419, 53.4421],
              [5.7225, 53.4421],
              [5.7125, 53.4335],
            ],
          ],
          [
            [
              [6.0469, 53.4807],
              [6.0669, 53.4726],
              [6.0658, 53.4649],
              [6.0536, 53.4668],
              [6.0375, 53.4615],
              [6.0153, 53.464],
              [6.0214, 53.4701],
              [6.0431, 53.4807],
              [6.0469, 53.4807],
            ],
          ],
          [
            [
              [6.3592, 53.5096],
              [6.3414, 53.5026],
              [6.3331, 53.5026],
              [6.2814, 53.4893],
              [6.2603, 53.4787],
              [6.2419, 53.4757],
              [6.2231, 53.4763],
              [6.1992, 53.4721],
              [6.1619, 53.4729],
              [6.1603, 53.4635],
              [6.1369, 53.4524],
              [6.1308, 53.4549],
              [6.1281, 53.4657],
              [6.1325, 53.4668],
              [6.1297, 53.4793],
              [6.1419, 53.494],
              [6.1653, 53.5029],
              [6.1869, 53.5026],
              [6.2058, 53.504],
              [6.2731, 53.5054],
              [6.3347, 53.5093],
              [6.3592, 53.5096],
            ],
          ],
          [
            [
              [6.0625, 53.4585],
              [6.0664, 53.4526],
              [6.0497, 53.449],
              [6.0503, 53.4549],
              [6.0625, 53.4585],
            ],
          ],
          [
            [
              [5.1375, 53.2993],
              [5.1575, 53.2957],
              [5.1547, 53.2926],
              [5.1297, 53.2799],
              [5.1119, 53.2815],
              [5.1125, 53.289],
              [5.1286, 53.2993],
              [5.1375, 53.2993],
            ],
          ],
          [
            [
              [5.1525, 53.3543],
              [5.1525, 53.3593],
              [5.1664, 53.371],
              [5.1842, 53.3826],
              [5.1958, 53.3949],
              [5.2864, 53.409],
              [5.3281, 53.4143],
              [5.3681, 53.4204],
              [5.3886, 53.4249],
              [5.4214, 53.4301],
              [5.4864, 53.4446],
              [5.5047, 53.4474],
              [5.5397, 53.4474],
              [5.5469, 53.4415],
              [5.5419, 53.431],
              [5.5108, 53.4268],
              [5.4808, 53.4179],
              [5.4775, 53.4079],
              [5.4569, 53.4035],
              [5.3981, 53.4032],
              [5.3847, 53.4018],
              [5.3458, 53.3829],
              [5.3258, 53.3774],
              [5.3131, 53.3799],
              [5.2958, 53.3701],
              [5.2775, 53.3732],
              [5.2581, 53.3726],
              [5.2442, 53.366],
              [5.2231, 53.3657],
              [5.2169, 53.3557],
              [5.2058, 53.3565],
              [5.1886, 53.3462],
              [5.1747, 53.3465],
              [5.1525, 53.3543],
            ],
          ],
        ],
      },
      properties: {
        name: 'Fryslân',
        id: 'NL-FR',
        CNTRY: 'Netherlands',
        NAME_ENG: 'Friesland',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-FR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.9525, 51.7428],
            [5.9425, 51.7425],
            [5.9411, 51.7497],
            [5.9237, 51.7534],
            [5.9195, 51.7632],
            [5.9005, 51.7784],
            [5.8774, 51.7769],
            [5.8729, 51.7589],
            [5.8613, 51.7608],
            [5.8176, 51.7573],
            [5.787, 51.7529],
            [5.7667, 51.7548],
            [5.7522, 51.7602],
            [5.7434, 51.7701],
            [5.729, 51.7755],
            [5.713, 51.7773],
            [5.7, 51.7899],
            [5.6927, 51.7926],
            [5.6695, 51.7954],
            [5.6564, 51.8071],
            [5.6535, 51.8142],
            [5.6434, 51.8205],
            [5.6244, 51.8206],
            [5.6128, 51.826],
            [5.5954, 51.8305],
            [5.5633, 51.8278],
            [5.5516, 51.818],
            [5.5424, 51.8171],
            [5.5225, 51.8207],
            [5.5109, 51.8287],
            [5.4963, 51.8314],
            [5.4876, 51.827],
            [5.4832, 51.818],
            [5.4715, 51.8117],
            [5.4351, 51.8117],
            [5.4206, 51.8225],
            [5.4118, 51.8234],
            [5.39, 51.8063],
            [5.374, 51.7901],
            [5.3682, 51.7793],
            [5.3654, 51.7623],
            [5.3596, 51.756],
            [5.3261, 51.747],
            [5.3058, 51.738],
            [5.2884, 51.7407],
            [5.2739, 51.7406],
            [5.2549, 51.7343],
            [5.236, 51.7424],
            [5.2258, 51.7441],
            [5.1997, 51.7414],
            [5.1677, 51.7431],
            [5.1358, 51.7385],
            [5.1299, 51.7439],
            [5.1473, 51.7556],
            [5.14, 51.7619],
            [5.1428, 51.7736],
            [5.1238, 51.778],
            [5.1048, 51.7878],
            [5.0961, 51.7878],
            [5.0816, 51.7797],
            [5.0758, 51.7797],
            [5.0641, 51.7868],
            [5.0524, 51.7984],
            [5.0276, 51.8055],
            [5.0159, 51.8108],
            [5.0143, 51.8171],
            [5.0056, 51.8216],
            [5.0303, 51.819],
            [5.036, 51.8415],
            [5.0039, 51.8449],
            [4.9993, 51.862],
            [5.0096, 51.8584],
            [5.0198, 51.8593],
            [5.0314, 51.8594],
            [5.0577, 51.8586],
            [5.0561, 51.8667],
            [5.0604, 51.8739],
            [5.0779, 51.8748],
            [5.091, 51.8811],
            [5.0953, 51.8892],
            [5.1172, 51.8884],
            [5.1229, 51.8992],
            [5.1403, 51.919],
            [5.1534, 51.9298],
            [5.1853, 51.9685],
            [5.1955, 51.9631],
            [5.2101, 51.9595],
            [5.2189, 51.9614],
            [5.2291, 51.9677],
            [5.2349, 51.9766],
            [5.2495, 51.9785],
            [5.2744, 51.9659],
            [5.3051, 51.9624],
            [5.3226, 51.9552],
            [5.3387, 51.958],
            [5.3533, 51.9687],
            [5.3693, 51.9714],
            [5.3883, 51.9697],
            [5.4044, 51.9742],
            [5.4234, 51.9769],
            [5.4365, 51.984],
            [5.4482, 51.9858],
            [5.4599, 51.984],
            [5.4935, 51.9831],
            [5.5169, 51.9742],
            [5.5446, 51.9688],
            [5.5534, 51.9607],
            [5.568, 51.9553],
            [5.5957, 51.9508],
            [5.6118, 51.9436],
            [5.6337, 51.9579],
            [5.6265, 51.9669],
            [5.6323, 51.9749],
            [5.6207, 51.983],
            [5.6207, 51.9911],
            [5.5988, 52.0019],
            [5.593, 52.0119],
            [5.5931, 52.0315],
            [5.596, 52.0351],
            [5.5741, 52.0414],
            [5.577, 52.0486],
            [5.5638, 52.0495],
            [5.5683, 52.0791],
            [5.561, 52.0881],
            [5.561, 52.1007],
            [5.5567, 52.1061],
            [5.5376, 52.1025],
            [5.5449, 52.098],
            [5.5434, 52.089],
            [5.5288, 52.0783],
            [5.5156, 52.0792],
            [5.5112, 52.0738],
            [5.4892, 52.0702],
            [5.4731, 52.0756],
            [5.4643, 52.081],
            [5.4804, 52.0909],
            [5.4922, 52.0945],
            [5.4907, 52.1061],
            [5.4995, 52.1115],
            [5.5039, 52.1304],
            [5.5186, 52.1321],
            [5.5171, 52.1384],
            [5.5025, 52.142],
            [5.5054, 52.1474],
            [5.4878, 52.151],
            [5.4893, 52.1582],
            [5.479, 52.1645],
            [5.4526, 52.1681],
            [5.4453, 52.1716],
            [5.4438, 52.1842],
            [5.4453, 52.2049],
            [5.4394, 52.2058],
            [5.4144, 52.2192],
            [5.4129, 52.2139],
            [5.3982, 52.221],
            [5.4085, 52.247],
            [5.4203, 52.2452],
            [5.4408, 52.2524],
            [5.4673, 52.256],
            [5.4776, 52.2614],
            [5.4835, 52.2578],
            [5.4938, 52.2632],
            [5.507, 52.265],
            [5.5335, 52.2641],
            [5.5468, 52.2775],
            [5.5586, 52.3027],
            [5.566, 52.3152],
            [5.5822, 52.3269],
            [5.6074, 52.3385],
            [5.6176, 52.3475],
            [5.6383, 52.3619],
            [5.6501, 52.3673],
            [5.6602, 52.3659],
            [5.682, 52.3763],
            [5.7062, 52.3814],
            [5.7136, 52.3868],
            [5.7225, 52.3877],
            [5.7713, 52.41],
            [5.7965, 52.4225],
            [5.8173, 52.435],
            [5.8292, 52.4457],
            [5.8337, 52.4574],
            [5.8486, 52.4708],
            [5.8651, 52.5004],
            [5.8727, 52.5192],
            [5.8831, 52.5219],
            [5.8904, 52.5191],
            [5.9154, 52.4984],
            [5.9328, 52.4741],
            [5.9447, 52.4767],
            [5.9581, 52.4839],
            [5.9655, 52.4838],
            [5.9728, 52.4766],
            [6.0085, 52.5007],
            [6.019, 52.506],
            [6.0308, 52.506],
            [6.0353, 52.5096],
            [6.0471, 52.5068],
            [6.0647, 52.4977],
            [6.0778, 52.4833],
            [6.0969, 52.4769],
            [6.1101, 52.4661],
            [6.1097, 52.4454],
            [6.1273, 52.4328],
            [6.1181, 52.4086],
            [6.1268, 52.4032],
            [6.1385, 52.4004],
            [6.1326, 52.3924],
            [6.1339, 52.3861],
            [6.1294, 52.3798],
            [6.088, 52.372],
            [6.085, 52.3648],
            [6.1025, 52.3575],
            [6.0833, 52.3513],
            [6.0846, 52.3406],
            [6.0785, 52.3226],
            [6.0872, 52.3181],
            [6.0914, 52.3019],
            [6.1076, 52.3009],
            [6.1192, 52.2892],
            [6.1205, 52.2793],
            [6.1293, 52.273],
            [6.135, 52.2621],
            [6.1319, 52.2523],
            [6.1626, 52.245],
            [6.1508, 52.2388],
            [6.155, 52.2289],
            [6.1712, 52.2279],
            [6.1742, 52.2315],
            [6.1904, 52.2349],
            [6.1977, 52.2295],
            [6.2109, 52.2303],
            [6.2255, 52.2267],
            [6.2315, 52.2293],
            [6.2476, 52.2283],
            [6.2697, 52.229],
            [6.2784, 52.2272],
            [6.302, 52.2315],
            [6.3063, 52.2261],
            [6.3475, 52.2276],
            [6.3506, 52.2366],
            [6.3596, 52.2428],
            [6.3758, 52.2426],
            [6.3861, 52.2453],
            [6.3993, 52.2434],
            [6.4257, 52.2432],
            [6.4256, 52.2378],
            [6.4329, 52.2332],
            [6.4386, 52.2242],
            [6.4559, 52.2133],
            [6.466, 52.2024],
            [6.4791, 52.1996],
            [6.479, 52.1933],
            [6.489, 52.1834],
            [6.5051, 52.1779],
            [6.5213, 52.1822],
            [6.5652, 52.1782],
            [6.6006, 52.1833],
            [6.6165, 52.1741],
            [6.6178, 52.1651],
            [6.628, 52.1659],
            [6.64, 52.1739],
            [6.6501, 52.1684],
            [6.6547, 52.1755],
            [6.6824, 52.1663],
            [6.6846, 52.142],
            [6.6726, 52.1314],
            [6.6886, 52.1267],
            [6.7076, 52.1247],
            [6.7265, 52.1192],
            [6.7685, 52.1187],
            [6.7606, 52.1187],
            [6.7576, 52.0994],
            [6.7445, 52.0931],
            [6.7512, 52.0855],
            [6.7356, 52.0747],
            [6.7143, 52.0729],
            [6.6968, 52.0673],
            [6.6879, 52.0524],
            [6.6877, 52.0399],
            [6.7147, 52.0399],
            [6.7526, 52.0286],
            [6.7723, 52.0179],
            [6.8115, 51.9981],
            [6.8266, 51.9935],
            [6.8304, 51.9862],
            [6.8324, 51.9703],
            [6.8285, 51.9641],
            [6.8136, 51.9635],
            [6.7986, 51.9588],
            [6.794, 51.9351],
            [6.7889, 51.9296],
            [6.77, 51.9162],
            [6.7541, 51.9126],
            [6.7512, 51.9072],
            [6.7371, 51.9046],
            [6.7326, 51.8987],
            [6.7217, 51.8962],
            [6.6975, 51.9099],
            [6.6923, 51.9146],
            [6.6745, 51.9159],
            [6.6376, 51.9044],
            [6.5856, 51.8939],
            [6.5561, 51.8818],
            [6.5524, 51.8862],
            [6.5244, 51.8742],
            [6.5154, 51.8735],
            [6.501, 51.867],
            [6.5008, 51.8625],
            [6.4779, 51.8569],
            [6.4726, 51.8538],
            [6.46, 51.8573],
            [6.4511, 51.8653],
            [6.4324, 51.8594],
            [6.4286, 51.8651],
            [6.4125, 51.8708],
            [6.4028, 51.8693],
            [6.3921, 51.874],
            [6.3881, 51.862],
            [6.4092, 51.8523],
            [6.4024, 51.8433],
            [6.4078, 51.8281],
            [6.4018, 51.8273],
            [6.3895, 51.8333],
            [6.3629, 51.8362],
            [6.3578, 51.8473],
            [6.3468, 51.8506],
            [6.3201, 51.8514],
            [6.3066, 51.8492],
            [6.2957, 51.8683],
            [6.2795, 51.8741],
            [6.2712, 51.8745],
            [6.2618, 51.8681],
            [6.2341, 51.8701],
            [6.2154, 51.8678],
            [6.1959, 51.8746],
            [6.1819, 51.8859],
            [6.1858, 51.8927],
            [6.1696, 51.9013],
            [6.1572, 51.9052],
            [6.1255, 51.8983],
            [6.112, 51.8991],
            [6.1143, 51.8926],
            [6.1372, 51.8856],
            [6.1445, 51.8699],
            [6.1665, 51.8614],
            [6.1637, 51.8535],
            [6.1664, 51.8408],
            [6.1436, 51.8461],
            [6.1145, 51.8477],
            [6.0934, 51.8509],
            [6.0634, 51.8654],
            [6.0553, 51.8526],
            [6.0326, 51.8423],
            [6.029, 51.8451],
            [6.0026, 51.8326],
            [5.987, 51.8306],
            [5.9629, 51.8368],
            [5.945, 51.8237],
            [5.9578, 51.8172],
            [5.9558, 51.8101],
            [5.9782, 51.7972],
            [5.9726, 51.7895],
            [5.9877, 51.783],
            [5.9823, 51.7737],
            [5.9905, 51.7662],
            [5.9514, 51.7487],
            [5.9525, 51.7428],
          ],
        ],
      },
      properties: {
        name: 'Gelderland',
        id: 'NL-GE',
        CNTRY: 'Netherlands',
        NAME_ENG: 'Gelderland',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-GE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [6.4742, 53.5545],
              [6.4858, 53.546],
              [6.5075, 53.5351],
              [6.4947, 53.5321],
              [6.4864, 53.5243],
              [6.4703, 53.5229],
              [6.4608, 53.5371],
              [6.4586, 53.5471],
              [6.4619, 53.5529],
              [6.4742, 53.5545],
            ],
          ],
          [
            [
              [6.1961, 53.4118],
              [6.2214, 53.4104],
              [6.2419, 53.4149],
              [6.2531, 53.4154],
              [6.2653, 53.4126],
              [6.2892, 53.4043],
              [6.3042, 53.3965],
              [6.3108, 53.3965],
              [6.3336, 53.4046],
              [6.3375, 53.411],
              [6.3475, 53.4107],
              [6.3803, 53.4193],
              [6.4075, 53.4207],
              [6.4131, 53.4226],
              [6.4547, 53.4243],
              [6.4936, 53.4296],
              [6.5203, 53.4307],
              [6.5675, 53.4357],
              [6.6186, 53.4463],
              [6.6525, 53.456],
              [6.6742, 53.4599],
              [6.7281, 53.4646],
              [6.7536, 53.4654],
              [6.7647, 53.4637],
              [6.7986, 53.4543],
              [6.8136, 53.4635],
              [6.8592, 53.4526],
              [6.8842, 53.4382],
              [6.8842, 53.4335],
              [6.8736, 53.4226],
              [6.8731, 53.4076],
              [6.8875, 53.3954],
              [6.8886, 53.3793],
              [6.8947, 53.3696],
              [6.8981, 53.3501],
              [6.9069, 53.3474],
              [6.9258, 53.3371],
              [6.9442, 53.3301],
              [6.9403, 53.3257],
              [6.9703, 53.3185],
              [7.0247, 53.3024],
              [7.0375, 53.3035],
              [7.0469, 53.2996],
              [7.0764, 53.2999],
              [7.0858, 53.2982],
              [7.0825, 53.2912],
              [7.0847, 53.2815],
              [7.0781, 53.2713],
              [7.0803, 53.2637],
              [7.0954, 53.2557],
              [7.1114, 53.251],
              [7.1342, 53.2501],
              [7.1425, 53.246],
              [7.1636, 53.2457],
              [7.1719, 53.2415],
              [7.1908, 53.2382],
              [7.2106, 53.2369],
              [7.212, 53.2245],
              [7.2169, 53.2145],
              [7.2176, 53.199],
              [7.208, 53.1885],
              [7.227, 53.1799],
              [7.2037, 53.1765],
              [7.1905, 53.1618],
              [7.1896, 53.148],
              [7.1789, 53.1381],
              [7.1829, 53.1217],
              [7.2028, 53.1133],
              [7.1993, 53.081],
              [7.2128, 53.011],
              [7.2173, 53.007],
              [7.2093, 53.0002],
              [7.1816, 52.9413],
              [7.1566, 52.9172],
              [7.1047, 52.8642],
              [7.0873, 52.8499],
              [7.0923, 52.839],
              [7.0784, 52.8391],
              [7.077, 52.8452],
              [7.0516, 52.8635],
              [7.0439, 52.8711],
              [7.0217, 52.8721],
              [7.0419, 52.9115],
              [7.0272, 52.9155],
              [7.0165, 52.9237],
              [6.9806, 52.9638],
              [6.9581, 52.9827],
              [6.9479, 52.9927],
              [6.9318, 53.0019],
              [6.8718, 53.0412],
              [6.8468, 53.0558],
              [6.8247, 53.0705],
              [6.7468, 53.1189],
              [6.7034, 53.1211],
              [6.6853, 53.1177],
              [6.6446, 53.1056],
              [6.6447, 53.1136],
              [6.6315, 53.1245],
              [6.6229, 53.1353],
              [6.6095, 53.1445],
              [6.5961, 53.1455],
              [6.5905, 53.1635],
              [6.5737, 53.1574],
              [6.5667, 53.17],
              [6.5564, 53.18],
              [6.543, 53.1837],
              [6.5373, 53.1945],
              [6.5149, 53.2001],
              [6.5043, 53.1975],
              [6.4924, 53.2039],
              [6.4562, 53.1961],
              [6.4515, 53.1872],
              [6.4152, 53.1776],
              [6.412, 53.1677],
              [6.3891, 53.1526],
              [6.3891, 53.1473],
              [6.3735, 53.1222],
              [6.3717, 53.1071],
              [6.3642, 53.1071],
              [6.3517, 53.0865],
              [6.3234, 53.0939],
              [6.298, 53.0995],
              [6.2907, 53.1067],
              [6.2698, 53.114],
              [6.2473, 53.1133],
              [6.2129, 53.1153],
              [6.1832, 53.1343],
              [6.1838, 53.1666],
              [6.2068, 53.1979],
              [6.2265, 53.2049],
              [6.2372, 53.2174],
              [6.2314, 53.2291],
              [6.221, 53.2328],
              [6.2377, 53.2444],
              [6.2455, 53.2605],
              [6.2532, 53.2676],
              [6.2622, 53.2675],
              [6.2594, 53.2765],
              [6.2611, 53.2882],
              [6.2747, 53.2899],
              [6.2871, 53.3024],
              [6.3007, 53.3095],
              [6.2872, 53.3122],
              [6.2937, 53.3409],
              [6.2607, 53.3474],
              [6.247, 53.3421],
              [6.238, 53.344],
              [6.2322, 53.3539],
              [6.2217, 53.3575],
              [6.1946, 53.3613],
              [6.1826, 53.3667],
              [6.1768, 53.3758],
              [6.174, 53.3901],
              [6.1985, 53.4097],
              [6.1961, 53.4118],
            ],
          ],
        ],
      },
      properties: {
        name: 'Groningen',
        id: 'NL-GR',
        CNTRY: 'Netherlands',
        NAME_ENG: 'Groningen',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-GR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.5706, 51.2215],
            [5.6266, 51.2308],
            [5.631, 51.2505],
            [5.6325, 51.2748],
            [5.6802, 51.3169],
            [5.7537, 51.3311],
            [5.8793, 51.354],
            [5.8865, 51.3567],
            [5.9401, 51.3861],
            [5.9116, 51.4186],
            [5.883, 51.4474],
            [5.8802, 51.4519],
            [5.869, 51.4932],
            [5.8604, 51.5058],
            [5.8465, 51.5678],
            [5.9, 51.5613],
            [5.9144, 51.5532],
            [5.9434, 51.5549],
            [5.9826, 51.5628],
            [6.0131, 51.5716],
            [6.0418, 51.5571],
            [6.0564, 51.5598],
            [6.0492, 51.5652],
            [6.0464, 51.5742],
            [6.048, 51.5849],
            [6.0351, 51.5967],
            [6.0323, 51.6039],
            [6.0325, 51.6173],
            [6.0282, 51.6236],
            [6.0066, 51.6372],
            [5.9807, 51.6472],
            [5.9735, 51.6544],
            [5.9723, 51.6768],
            [5.9638, 51.7011],
            [5.9639, 51.711],
            [5.9422, 51.7174],
            [5.9132, 51.7202],
            [5.8944, 51.7265],
            [5.8844, 51.7526],
            [5.8729, 51.7589],
            [5.8774, 51.7769],
            [5.9005, 51.7784],
            [5.9195, 51.7632],
            [5.9237, 51.7534],
            [5.9411, 51.7497],
            [5.9425, 51.7425],
            [5.9525, 51.7428],
            [5.9591, 51.7383],
            [5.9736, 51.7393],
            [5.9942, 51.7383],
            [6.0039, 51.7336],
            [6.0291, 51.7257],
            [6.0354, 51.7177],
            [6.0262, 51.7087],
            [6.0318, 51.6923],
            [6.0298, 51.6782],
            [6.0369, 51.6728],
            [6.088, 51.6598],
            [6.1017, 51.6602],
            [6.1182, 51.6561],
            [6.1095, 51.6468],
            [6.0912, 51.6059],
            [6.1213, 51.5928],
            [6.1306, 51.5811],
            [6.1461, 51.5711],
            [6.157, 51.5667],
            [6.177, 51.5387],
            [6.1993, 51.528],
            [6.2121, 51.5134],
            [6.2143, 51.5033],
            [6.2128, 51.4923],
            [6.2234, 51.4749],
            [6.22, 51.4553],
            [6.2205, 51.4469],
            [6.2139, 51.4463],
            [6.2149, 51.4344],
            [6.2052, 51.3995],
            [6.226, 51.4004],
            [6.2144, 51.3896],
            [6.2261, 51.3605],
            [6.1898, 51.3394],
            [6.1882, 51.335],
            [6.1686, 51.333],
            [6.1693, 51.3296],
            [6.1593, 51.3195],
            [6.1541, 51.3074],
            [6.1444, 51.2976],
            [6.1298, 51.2866],
            [6.1245, 51.2746],
            [6.085, 51.2474],
            [6.0727, 51.2426],
            [6.0858, 51.2226],
            [6.068, 51.2205],
            [6.0733, 51.183],
            [6.0823, 51.1716],
            [6.0897, 51.1705],
            [6.11, 51.1734],
            [6.1163, 51.1784],
            [6.131, 51.1841],
            [6.165, 51.1944],
            [6.1807, 51.1864],
            [6.1388, 51.1733],
            [6.1754, 51.1585],
            [6.16, 51.1492],
            [6.126, 51.1451],
            [6.1212, 51.1409],
            [6.0919, 51.135],
            [6.0835, 51.1239],
            [6.0758, 51.1192],
            [6.0624, 51.1168],
            [6.0565, 51.1096],
            [6.0359, 51.0962],
            [6.0099, 51.091],
            [5.9978, 51.0843],
            [5.971, 51.0578],
            [5.9672, 51.0443],
            [5.9579, 51.041],
            [5.9578, 51.0355],
            [5.9381, 51.0351],
            [5.9264, 51.048],
            [5.919, 51.0638],
            [5.9132, 51.0667],
            [5.8868, 51.0521],
            [5.8663, 51.0512],
            [5.8671, 51.0467],
            [5.878, 51.0375],
            [5.8746, 51.0293],
            [5.8792, 51.0176],
            [5.8843, 51.0134],
            [5.8958, 51.011],
            [5.8957, 51.0044],
            [5.9055, 51.0022],
            [5.9038, 50.9875],
            [5.8927, 50.9803],
            [5.9047, 50.9745],
            [5.9231, 50.9796],
            [5.9336, 50.9853],
            [5.9552, 50.9884],
            [5.9682, 50.9795],
            [5.9846, 50.9821],
            [6.0174, 50.9834],
            [6.0269, 50.9816],
            [6.0156, 50.9627],
            [6.0053, 50.9568],
            [6.0168, 50.9525],
            [6.0151, 50.9478],
            [6.0182, 50.9344],
            [6.056, 50.9271],
            [6.0539, 50.9223],
            [6.0681, 50.9207],
            [6.0717, 50.9231],
            [6.093, 50.9185],
            [6.081, 50.9087],
            [6.0753, 50.891],
            [6.0873, 50.878],
            [6.0882, 50.8724],
            [6.0773, 50.8607],
            [6.074, 50.8468],
            [6.0621, 50.8507],
            [6.0572, 50.8556],
            [6.0189, 50.8463],
            [6.0159, 50.8388],
            [6.0188, 50.8293],
            [6.0248, 50.828],
            [6.0251, 50.8142],
            [6.0035, 50.8013],
            [5.9855, 50.8106],
            [5.9758, 50.8024],
            [5.9749, 50.798],
            [5.988, 50.7941],
            [6.0106, 50.7801],
            [6.0278, 50.7737],
            [6.0183, 50.7632],
            [6.0214, 50.7531],
            [6.0405, 50.7455],
            [6.0398, 50.7376],
            [6.0286, 50.7252],
            [6.0121, 50.7543],
            [5.9596, 50.7621],
            [5.9047, 50.7552],
            [5.8812, 50.7693],
            [5.8559, 50.7612],
            [5.8326, 50.764],
            [5.8134, 50.7601],
            [5.7934, 50.7698],
            [5.7832, 50.7799],
            [5.7734, 50.785],
            [5.7546, 50.7785],
            [5.7324, 50.7627],
            [5.7068, 50.7628],
            [5.6954, 50.7583],
            [5.6911, 50.7611],
            [5.7052, 50.7859],
            [5.7011, 50.8054],
            [5.6919, 50.8087],
            [5.6725, 50.8196],
            [5.6605, 50.822],
            [5.6525, 50.8296],
            [5.6497, 50.8369],
            [5.6479, 50.8601],
            [5.652, 50.8725],
            [5.6702, 50.8803],
            [5.6877, 50.8821],
            [5.7035, 50.9061],
            [5.7235, 50.9091],
            [5.7322, 50.9245],
            [5.7573, 50.953],
            [5.7519, 50.9594],
            [5.7377, 50.9562],
            [5.725, 50.96],
            [5.7258, 50.964],
            [5.7631, 50.9924],
            [5.7707, 51.0111],
            [5.7706, 51.0204],
            [5.7745, 51.0252],
            [5.7636, 51.0327],
            [5.7641, 51.0375],
            [5.7827, 51.0651],
            [5.7963, 51.0621],
            [5.8019, 51.0639],
            [5.7998, 51.0728],
            [5.8061, 51.0808],
            [5.8007, 51.0891],
            [5.8113, 51.0959],
            [5.8229, 51.0946],
            [5.8347, 51.1004],
            [5.8331, 51.1092],
            [5.8193, 51.1121],
            [5.8171, 51.1208],
            [5.825, 51.1293],
            [5.8436, 51.135],
            [5.8588, 51.146],
            [5.8433, 51.154],
            [5.8341, 51.1685],
            [5.8217, 51.1626],
            [5.7827, 51.1599],
            [5.7861, 51.1655],
            [5.7806, 51.1696],
            [5.7827, 51.1813],
            [5.7631, 51.1876],
            [5.7492, 51.19],
            [5.7227, 51.182],
            [5.7096, 51.1813],
            [5.6554, 51.1864],
            [5.6516, 51.1999],
            [5.6151, 51.2091],
            [5.5769, 51.2169],
            [5.5706, 51.2215],
          ],
        ],
      },
      properties: {
        name: 'Limburg',
        id: 'NL-LI',
        CNTRY: 'Netherlands',
        NAME_ENG: 'Limburg',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-LI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.2408, 51.5146],
            [4.2472, 51.5303],
            [4.2462, 51.5389],
            [4.2412, 51.5464],
            [4.2115, 51.5671],
            [4.2012, 51.5756],
            [4.1966, 51.587],
            [4.1973, 51.5981],
            [4.2055, 51.6099],
            [4.2158, 51.6185],
            [4.2312, 51.6263],
            [4.2348, 51.6329],
            [4.2548, 51.6323],
            [4.2735, 51.6379],
            [4.2937, 51.6408],
            [4.3183, 51.6464],
            [4.3357, 51.6448],
            [4.353, 51.6485],
            [4.3789, 51.6595],
            [4.3902, 51.6695],
            [4.3958, 51.6812],
            [4.4274, 51.6968],
            [4.4419, 51.6969],
            [4.4638, 51.689],
            [4.493, 51.6839],
            [4.522, 51.6841],
            [4.5727, 51.6926],
            [4.5802, 51.6765],
            [4.5846, 51.6765],
            [4.577, 51.6926],
            [4.6045, 51.6973],
            [4.6232, 51.7073],
            [4.6492, 51.7147],
            [4.6447, 51.7236],
            [4.6534, 51.7272],
            [4.678, 51.731],
            [4.691, 51.7347],
            [4.7141, 51.7438],
            [4.7415, 51.7619],
            [4.7586, 51.7827],
            [4.7846, 51.7963],
            [4.8107, 51.8009],
            [4.8399, 51.7984],
            [4.8631, 51.803],
            [4.8891, 51.8166],
            [4.908, 51.8248],
            [4.9312, 51.8285],
            [4.9633, 51.8259],
            [4.9925, 51.8215],
            [5.0056, 51.8216],
            [5.0143, 51.8171],
            [5.0159, 51.8108],
            [5.0276, 51.8055],
            [5.0524, 51.7984],
            [5.0641, 51.7868],
            [5.0758, 51.7797],
            [5.0816, 51.7797],
            [5.0961, 51.7878],
            [5.1048, 51.7878],
            [5.1238, 51.778],
            [5.1428, 51.7736],
            [5.14, 51.7619],
            [5.1473, 51.7556],
            [5.1299, 51.7439],
            [5.1358, 51.7385],
            [5.1677, 51.7431],
            [5.1997, 51.7414],
            [5.2258, 51.7441],
            [5.236, 51.7424],
            [5.2549, 51.7343],
            [5.2739, 51.7406],
            [5.2884, 51.7407],
            [5.3058, 51.738],
            [5.3261, 51.747],
            [5.3596, 51.756],
            [5.3654, 51.7623],
            [5.3682, 51.7793],
            [5.374, 51.7901],
            [5.39, 51.8063],
            [5.4118, 51.8234],
            [5.4206, 51.8225],
            [5.4351, 51.8117],
            [5.4715, 51.8117],
            [5.4832, 51.818],
            [5.4876, 51.827],
            [5.4963, 51.8314],
            [5.5109, 51.8287],
            [5.5225, 51.8207],
            [5.5424, 51.8171],
            [5.5516, 51.818],
            [5.5633, 51.8278],
            [5.5954, 51.8305],
            [5.6128, 51.826],
            [5.6244, 51.8206],
            [5.6434, 51.8205],
            [5.6535, 51.8142],
            [5.6564, 51.8071],
            [5.6695, 51.7954],
            [5.6927, 51.7926],
            [5.7, 51.7899],
            [5.713, 51.7773],
            [5.729, 51.7755],
            [5.7434, 51.7701],
            [5.7522, 51.7602],
            [5.7667, 51.7548],
            [5.787, 51.7529],
            [5.8176, 51.7573],
            [5.8613, 51.7608],
            [5.8729, 51.7589],
            [5.8844, 51.7526],
            [5.8944, 51.7265],
            [5.9132, 51.7202],
            [5.9422, 51.7174],
            [5.9639, 51.711],
            [5.9638, 51.7011],
            [5.9723, 51.6768],
            [5.9735, 51.6544],
            [5.9807, 51.6472],
            [6.0066, 51.6372],
            [6.0282, 51.6236],
            [6.0325, 51.6173],
            [6.0323, 51.6039],
            [6.0351, 51.5967],
            [6.048, 51.5849],
            [6.0464, 51.5742],
            [6.0492, 51.5652],
            [6.0564, 51.5598],
            [6.0418, 51.5571],
            [6.0131, 51.5716],
            [5.9826, 51.5628],
            [5.9434, 51.5549],
            [5.9144, 51.5532],
            [5.9, 51.5613],
            [5.8465, 51.5678],
            [5.8604, 51.5058],
            [5.869, 51.4932],
            [5.8802, 51.4519],
            [5.883, 51.4474],
            [5.9116, 51.4186],
            [5.9401, 51.3861],
            [5.8865, 51.3567],
            [5.8793, 51.354],
            [5.7537, 51.3311],
            [5.6802, 51.3169],
            [5.6325, 51.2748],
            [5.631, 51.2505],
            [5.6266, 51.2308],
            [5.5706, 51.2215],
            [5.5603, 51.2288],
            [5.5515, 51.2665],
            [5.5129, 51.293],
            [5.4961, 51.2977],
            [5.4895, 51.2974],
            [5.4807, 51.288],
            [5.4569, 51.2821],
            [5.433, 51.2713],
            [5.4207, 51.2611],
            [5.3576, 51.2729],
            [5.3341, 51.2638],
            [5.2794, 51.2646],
            [5.2649, 51.2669],
            [5.241, 51.2617],
            [5.2302, 51.2671],
            [5.2284, 51.2731],
            [5.2376, 51.2879],
            [5.2457, 51.3053],
            [5.233, 51.3131],
            [5.21, 51.3235],
            [5.1813, 51.3206],
            [5.1775, 51.3186],
            [5.1367, 51.3155],
            [5.1224, 51.3601],
            [5.0758, 51.3951],
            [5.0766, 51.4047],
            [5.0975, 51.4285],
            [5.0948, 51.4489],
            [5.0821, 51.4669],
            [5.051, 51.4715],
            [5.0372, 51.4854],
            [5.0224, 51.4757],
            [5.0149, 51.4642],
            [5.017, 51.4551],
            [5.0066, 51.4391],
            [4.971, 51.42],
            [4.945, 51.4019],
            [4.9361, 51.3975],
            [4.9257, 51.3986],
            [4.8847, 51.4156],
            [4.8329, 51.4093],
            [4.7975, 51.4095],
            [4.7826, 51.4154],
            [4.7733, 51.4289],
            [4.786, 51.432],
            [4.8107, 51.4255],
            [4.835, 51.4224],
            [4.8292, 51.4396],
            [4.8399, 51.4581],
            [4.8386, 51.4798],
            [4.8049, 51.4971],
            [4.7793, 51.5038],
            [4.7521, 51.4975],
            [4.7369, 51.4866],
            [4.7283, 51.4744],
            [4.7193, 51.4675],
            [4.7121, 51.4658],
            [4.7075, 51.4558],
            [4.6788, 51.4445],
            [4.6791, 51.4254],
            [4.6628, 51.4273],
            [4.6474, 51.4236],
            [4.6326, 51.4268],
            [4.6143, 51.4277],
            [4.5909, 51.4314],
            [4.5372, 51.4236],
            [4.5305, 51.4455],
            [4.5335, 51.452],
            [4.552, 51.4721],
            [4.5378, 51.4848],
            [4.4895, 51.48],
            [4.4786, 51.481],
            [4.4668, 51.4765],
            [4.4428, 51.4711],
            [4.409, 51.4588],
            [4.3954, 51.4484],
            [4.3923, 51.4172],
            [4.3999, 51.4036],
            [4.4246, 51.386],
            [4.4275, 51.3762],
            [4.4227, 51.3678],
            [4.3967, 51.36],
            [4.3664, 51.3612],
            [4.3464, 51.3607],
            [4.3374, 51.3813],
            [4.2781, 51.3786],
            [4.27, 51.3838],
            [4.271, 51.4008],
            [4.2648, 51.4169],
            [4.2776, 51.426],
            [4.2611, 51.4501],
            [4.2807, 51.4488],
            [4.2996, 51.4538],
            [4.3035, 51.4631],
            [4.2978, 51.4788],
            [4.2874, 51.4934],
            [4.2589, 51.5095],
            [4.2408, 51.5146],
          ],
          [
            [4.9611, 51.454],
            [4.9279, 51.4477],
            [4.929, 51.4322],
            [4.9452, 51.4289],
            [4.9521, 51.4312],
            [4.9532, 51.4445],
            [4.9676, 51.4485],
            [4.9611, 51.454],
          ],
        ],
      },
      properties: {
        name: 'Noord-Brabant',
        id: 'NL-NB',
        CNTRY: 'Netherlands',
        NAME_ENG: 'North Brabante',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-NB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [5.1173, 52.462],
              [5.1365, 52.4593],
              [5.1144, 52.4539],
              [5.11, 52.4503],
              [5.0967, 52.4511],
              [5.104, 52.4592],
              [5.1069, 52.4682],
              [5.1173, 52.462],
            ],
          ],
          [
            [
              [5.3114, 52.2775],
              [5.2702, 52.2819],
              [5.2424, 52.246],
              [5.2293, 52.2253],
              [5.2206, 52.2046],
              [5.1972, 52.1776],
              [5.1282, 52.1811],
              [5.0814, 52.1729],
              [5.0505, 52.1656],
              [5.046, 52.1835],
              [5.0372, 52.1844],
              [5.0414, 52.1978],
              [5.0384, 52.2131],
              [5.0441, 52.2302],
              [5.0617, 52.2356],
              [5.0498, 52.2436],
              [5.0468, 52.2562],
              [5.0365, 52.2544],
              [5.0275, 52.2705],
              [5.0627, 52.285],
              [5.0538, 52.2876],
              [5.0465, 52.2822],
              [5.0332, 52.2876],
              [5.0259, 52.2831],
              [5.0257, 52.3028],
              [5.0154, 52.3037],
              [5.0066, 52.2982],
              [5.0009, 52.2892],
              [4.9876, 52.2901],
              [4.9642, 52.2783],
              [4.9289, 52.28],
              [4.9319, 52.2701],
              [4.9145, 52.253],
              [4.9013, 52.2511],
              [4.8851, 52.2537],
              [4.8601, 52.2473],
              [4.8573, 52.2419],
              [4.831, 52.2301],
              [4.8119, 52.2264],
              [4.7972, 52.2263],
              [4.7884, 52.2281],
              [4.765, 52.2181],
              [4.746, 52.2144],
              [4.7373, 52.2062],
              [4.7254, 52.216],
              [4.7266, 52.2322],
              [4.706, 52.2339],
              [4.6885, 52.2266],
              [4.6797, 52.2256],
              [4.6722, 52.231],
              [4.6416, 52.2182],
              [4.6211, 52.2145],
              [4.605, 52.2143],
              [4.5858, 52.2187],
              [4.5755, 52.2168],
              [4.5584, 52.2187],
              [4.5518, 52.2265],
              [4.5649, 52.2338],
              [4.5705, 52.25],
              [4.5701, 52.2679],
              [4.5905, 52.2798],
              [4.6047, 52.3032],
              [4.6134, 52.3131],
              [4.6015, 52.3167],
              [4.5853, 52.3183],
              [4.5649, 52.3092],
              [4.4942, 52.3277],
              [4.5169, 52.3601],
              [4.5214, 52.3704],
              [4.5353, 52.3893],
              [4.5358, 52.394],
              [4.5464, 52.4082],
              [4.5603, 52.4315],
              [4.5619, 52.4437],
              [4.5692, 52.4579],
              [4.5836, 52.4637],
              [4.6025, 52.4668],
              [4.5992, 52.4733],
              [4.5731, 52.4682],
              [4.5719, 52.4726],
              [4.5919, 52.5051],
              [4.5981, 52.5199],
              [4.5981, 52.529],
              [4.6036, 52.5529],
              [4.6147, 52.5921],
              [4.6192, 52.6162],
              [4.6275, 52.6538],
              [4.6275, 52.6654],
              [4.6336, 52.6832],
              [4.6364, 52.711],
              [4.6392, 52.7163],
              [4.6403, 52.7343],
              [4.6531, 52.7657],
              [4.6758, 52.7965],
              [4.6842, 52.8218],
              [4.7031, 52.8624],
              [4.7119, 52.889],
              [4.7147, 52.9243],
              [4.7147, 52.9368],
              [4.7192, 52.9515],
              [4.7247, 52.9579],
              [4.7392, 52.9637],
              [4.7769, 52.9649],
              [4.8031, 52.9571],
              [4.7914, 52.9432],
              [4.7914, 52.9354],
              [4.8019, 52.9185],
              [4.8153, 52.9087],
              [4.8386, 52.8996],
              [4.8486, 52.8982],
              [4.8647, 52.8907],
              [4.8769, 52.8885],
              [4.9075, 52.8926],
              [4.9097, 52.896],
              [4.9286, 52.9015],
              [4.9458, 52.9087],
              [4.9553, 52.9165],
              [4.9597, 52.9265],
              [4.9686, 52.9301],
              [5.0047, 52.936],
              [5.0208, 52.9346],
              [5.0292, 52.9408],
              [5.0437, 52.9354],
              [5.0406, 52.9221],
              [5.051, 52.9231],
              [5.1173, 52.8452],
              [5.1075, 52.7734],
              [5.1164, 52.7734],
              [5.1253, 52.768],
              [5.1269, 52.7546],
              [5.1389, 52.751],
              [5.1523, 52.743],
              [5.1597, 52.7412],
              [5.1835, 52.7404],
              [5.1968, 52.7548],
              [5.2296, 52.7521],
              [5.2623, 52.7531],
              [5.2861, 52.7415],
              [5.2921, 52.7146],
              [5.3085, 52.7047],
              [5.2981, 52.6993],
              [5.2833, 52.6957],
              [5.2833, 52.6921],
              [5.27, 52.6876],
              [5.2507, 52.6849],
              [5.2404, 52.657],
              [5.2345, 52.6525],
              [5.2108, 52.6444],
              [5.202, 52.6363],
              [5.1812, 52.6336],
              [5.1635, 52.6228],
              [5.1531, 52.6236],
              [5.1309, 52.6191],
              [5.1323, 52.6254],
              [5.1249, 52.6325],
              [5.1101, 52.6307],
              [5.0966, 52.6396],
              [5.0848, 52.6414],
              [5.067, 52.6323],
              [5.0551, 52.6413],
              [5.0492, 52.6413],
              [5.0226, 52.6295],
              [5.0197, 52.6178],
              [5.0243, 52.6017],
              [5.0229, 52.5954],
              [5.0319, 52.5847],
              [5.0364, 52.573],
              [5.0335, 52.5667],
              [5.0455, 52.5605],
              [5.0574, 52.5453],
              [5.0575, 52.5381],
              [5.0679, 52.5282],
              [5.0695, 52.522],
              [5.0843, 52.513],
              [5.0889, 52.5032],
              [5.0801, 52.4924],
              [5.0668, 52.4905],
              [5.0624, 52.4851],
              [5.064, 52.4762],
              [5.0729, 52.4708],
              [5.0655, 52.4672],
              [5.0537, 52.4689],
              [5.0419, 52.4644],
              [5.0582, 52.46],
              [5.0523, 52.4537],
              [5.0525, 52.4393],
              [5.0702, 52.4385],
              [5.0894, 52.435],
              [5.0762, 52.4241],
              [5.0704, 52.4134],
              [5.0557, 52.4133],
              [5.0498, 52.407],
              [5.0351, 52.4007],
              [5.0293, 52.389],
              [5.0161, 52.3835],
              [5.0147, 52.3737],
              [5.0044, 52.3745],
              [5.0014, 52.379],
              [4.9617, 52.3681],
              [4.9647, 52.3636],
              [4.9854, 52.3574],
              [5.0002, 52.3494],
              [5.0047, 52.344],
              [5.018, 52.3441],
              [5.0297, 52.3486],
              [5.0372, 52.3405],
              [5.0475, 52.3352],
              [5.0593, 52.3379],
              [5.0711, 52.3362],
              [5.0814, 52.3398],
              [5.0976, 52.3389],
              [5.1153, 52.33],
              [5.1271, 52.3292],
              [5.1419, 52.3247],
              [5.1478, 52.3175],
              [5.1656, 52.3059],
              [5.1773, 52.3042],
              [5.1965, 52.3087],
              [5.2332, 52.3115],
              [5.2509, 52.3115],
              [5.2612, 52.307],
              [5.2789, 52.3044],
              [5.2966, 52.2963],
              [5.2996, 52.29],
              [5.3114, 52.2775],
            ],
          ],
          [
            [
              [4.6603, 52.9807],
              [4.6731, 52.976],
              [4.6886, 52.9801],
              [4.7075, 52.9804],
              [4.6969, 52.9715],
              [4.6792, 52.9665],
              [4.6664, 52.9604],
              [4.6508, 52.9637],
              [4.6486, 52.9738],
              [4.6603, 52.9807],
            ],
          ],
          [
            [
              [4.7603, 53.0085],
              [4.7542, 53.0037],
              [4.7658, 53.0004],
              [4.7675, 52.996],
              [4.7469, 52.9935],
              [4.7336, 52.9863],
              [4.7181, 52.9863],
              [4.7158, 52.9996],
              [4.7114, 53.0051],
              [4.7092, 53.0163],
              [4.7119, 53.041],
              [4.7214, 53.0621],
              [4.7408, 53.0885],
              [4.7653, 53.1129],
              [4.8231, 53.1649],
              [4.8403, 53.1768],
              [4.8553, 53.1832],
              [4.8614, 53.1818],
              [4.8742, 53.1715],
              [4.8758, 53.1601],
              [4.8864, 53.1565],
              [4.8975, 53.1476],
              [4.9081, 53.136],
              [4.8997, 53.1237],
              [4.8969, 53.1004],
              [4.8981, 53.0799],
              [4.8853, 53.0715],
              [4.8736, 53.0701],
              [4.8719, 53.0551],
              [4.8625, 53.0513],
              [4.8625, 53.0462],
              [4.8464, 53.0335],
              [4.8353, 53.0301],
              [4.8197, 53.0299],
              [4.7947, 53.0099],
              [4.7897, 53.0018],
              [4.7603, 53.0085],
            ],
          ],
        ],
      },
      properties: {
        name: 'Noord-Holland',
        id: 'NL-NH',
        CNTRY: 'Netherlands',
        NAME_ENG: 'North Holland',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-NH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.7113, 52.6283],
            [6.7271, 52.6148],
            [6.7187, 52.5887],
            [6.7667, 52.5635],
            [6.751, 52.5592],
            [6.7262, 52.5632],
            [6.7161, 52.5486],
            [6.6809, 52.5533],
            [6.7044, 52.5221],
            [6.6977, 52.4863],
            [6.7239, 52.477],
            [6.7531, 52.4638],
            [6.7746, 52.4596],
            [6.8318, 52.4601],
            [6.8543, 52.4596],
            [6.8526, 52.4499],
            [6.8619, 52.4514],
            [6.8886, 52.4466],
            [6.9068, 52.4419],
            [6.9417, 52.4355],
            [6.9622, 52.4446],
            [6.9776, 52.4655],
            [6.9879, 52.4695],
            [6.994, 52.4655],
            [7.0056, 52.4441],
            [7.0109, 52.4291],
            [7.0221, 52.4227],
            [7.0358, 52.4025],
            [7.0588, 52.3993],
            [7.0722, 52.3734],
            [7.0722, 52.3523],
            [7.0569, 52.3386],
            [7.0556, 52.3322],
            [7.0471, 52.3239],
            [7.0468, 52.3162],
            [7.0267, 52.2922],
            [7.0275, 52.2776],
            [7.0424, 52.2561],
            [7.0659, 52.2413],
            [7.061, 52.2348],
            [7.039, 52.2276],
            [7.0192, 52.225],
            [7.0035, 52.2285],
            [6.985, 52.225],
            [6.9742, 52.2052],
            [6.9623, 52.1943],
            [6.9513, 52.181],
            [6.934, 52.1784],
            [6.9121, 52.1772],
            [6.9058, 52.17],
            [6.882, 52.1559],
            [6.8728, 52.1321],
            [6.8554, 52.1204],
            [6.8213, 52.1182],
            [6.7685, 52.1187],
            [6.7265, 52.1192],
            [6.7076, 52.1247],
            [6.6886, 52.1267],
            [6.6726, 52.1314],
            [6.6846, 52.142],
            [6.6824, 52.1663],
            [6.6547, 52.1755],
            [6.6501, 52.1684],
            [6.64, 52.1739],
            [6.628, 52.1659],
            [6.6178, 52.1651],
            [6.6165, 52.1741],
            [6.6006, 52.1833],
            [6.5652, 52.1782],
            [6.5213, 52.1822],
            [6.5051, 52.1779],
            [6.489, 52.1834],
            [6.479, 52.1933],
            [6.4791, 52.1996],
            [6.466, 52.2024],
            [6.4559, 52.2133],
            [6.4386, 52.2242],
            [6.4329, 52.2332],
            [6.4256, 52.2378],
            [6.4257, 52.2432],
            [6.3993, 52.2434],
            [6.3861, 52.2453],
            [6.3758, 52.2426],
            [6.3596, 52.2428],
            [6.3506, 52.2366],
            [6.3475, 52.2276],
            [6.3063, 52.2261],
            [6.302, 52.2315],
            [6.2784, 52.2272],
            [6.2697, 52.229],
            [6.2476, 52.2283],
            [6.2315, 52.2293],
            [6.2255, 52.2267],
            [6.2109, 52.2303],
            [6.1977, 52.2295],
            [6.1904, 52.2349],
            [6.1742, 52.2315],
            [6.1712, 52.2279],
            [6.155, 52.2289],
            [6.1508, 52.2388],
            [6.1626, 52.245],
            [6.1319, 52.2523],
            [6.135, 52.2621],
            [6.1293, 52.273],
            [6.1205, 52.2793],
            [6.1192, 52.2892],
            [6.1076, 52.3009],
            [6.0914, 52.3019],
            [6.0872, 52.3181],
            [6.0785, 52.3226],
            [6.0846, 52.3406],
            [6.0833, 52.3513],
            [6.1025, 52.3575],
            [6.085, 52.3648],
            [6.088, 52.372],
            [6.1294, 52.3798],
            [6.1339, 52.3861],
            [6.1326, 52.3924],
            [6.1385, 52.4004],
            [6.1268, 52.4032],
            [6.1181, 52.4086],
            [6.1273, 52.4328],
            [6.1097, 52.4454],
            [6.1101, 52.4661],
            [6.0969, 52.4769],
            [6.0778, 52.4833],
            [6.0647, 52.4977],
            [6.0471, 52.5068],
            [6.0353, 52.5096],
            [6.0308, 52.506],
            [6.019, 52.506],
            [6.0085, 52.5007],
            [5.9728, 52.4766],
            [5.9655, 52.4838],
            [5.9581, 52.4839],
            [5.9447, 52.4767],
            [5.9328, 52.4741],
            [5.9154, 52.4984],
            [5.8904, 52.5191],
            [5.8831, 52.5219],
            [5.8727, 52.5192],
            [5.8734, 52.533],
            [5.87, 52.5448],
            [5.8421, 52.575],
            [5.8394, 52.601],
            [5.85, 52.6135],
            [5.9702, 52.6391],
            [5.9913, 52.6563],
            [5.9738, 52.6668],
            [5.9475, 52.6701],
            [5.9403, 52.6743],
            [5.9529, 52.684],
            [5.9694, 52.6903],
            [5.9726, 52.6984],
            [5.9589, 52.712],
            [5.9483, 52.7186],
            [5.9287, 52.7515],
            [5.9122, 52.7615],
            [5.9052, 52.7621],
            [5.8855, 52.7733],
            [5.856, 52.7841],
            [5.8247, 52.7842],
            [5.8247, 52.7878],
            [5.81, 52.7968],
            [5.8011, 52.8058],
            [5.8264, 52.8173],
            [5.8427, 52.8083],
            [5.8427, 52.8056],
            [5.8665, 52.8046],
            [5.8828, 52.801],
            [5.8993, 52.8054],
            [5.9068, 52.8117],
            [5.9187, 52.8152],
            [5.9306, 52.8232],
            [5.9293, 52.8304],
            [5.9383, 52.8349],
            [5.9788, 52.8417],
            [5.9933, 52.8221],
            [6.0036, 52.8167],
            [6.0156, 52.8202],
            [6.032, 52.8219],
            [6.0378, 52.8148],
            [6.0663, 52.8262],
            [6.059, 52.837],
            [6.0874, 52.8387],
            [6.1263, 52.8537],
            [6.1381, 52.8438],
            [6.1793, 52.8148],
            [6.1883, 52.8111],
            [6.2088, 52.794],
            [6.2012, 52.786],
            [6.1801, 52.7727],
            [6.1756, 52.7673],
            [6.1636, 52.7629],
            [6.1338, 52.7568],
            [6.1277, 52.7505],
            [6.1408, 52.7316],
            [6.1639, 52.6929],
            [6.1772, 52.6865],
            [6.1711, 52.6794],
            [6.1904, 52.6748],
            [6.2008, 52.6765],
            [6.2098, 52.6845],
            [6.2262, 52.6844],
            [6.2454, 52.678],
            [6.2513, 52.6789],
            [6.26, 52.6698],
            [6.2719, 52.6698],
            [6.2807, 52.6652],
            [6.2912, 52.6687],
            [6.297, 52.6633],
            [6.306, 52.6668],
            [6.3179, 52.6667],
            [6.3343, 52.6702],
            [6.3341, 52.6594],
            [6.3723, 52.643],
            [6.3722, 52.6385],
            [6.3838, 52.6277],
            [6.3851, 52.6169],
            [6.3939, 52.6132],
            [6.4029, 52.6176],
            [6.4118, 52.6167],
            [6.4178, 52.622],
            [6.4297, 52.6228],
            [6.4402, 52.629],
            [6.4445, 52.62],
            [6.4621, 52.6145],
            [6.4696, 52.6162],
            [6.4742, 52.6242],
            [6.4889, 52.6187],
            [6.5141, 52.6176],
            [6.5275, 52.6193],
            [6.5237, 52.6471],
            [6.5613, 52.6657],
            [6.6134, 52.6732],
            [6.6238, 52.674],
            [6.696, 52.6536],
            [6.7181, 52.6489],
            [6.7113, 52.6283],
          ],
        ],
      },
      properties: {
        name: 'Overijssel',
        id: 'NL-OV',
        CNTRY: 'Netherlands',
        NAME_ENG: 'Overijssel',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-OV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.3114, 52.2775],
            [5.3246, 52.273],
            [5.3364, 52.2739],
            [5.3438, 52.2703],
            [5.3688, 52.2686],
            [5.3791, 52.2596],
            [5.4085, 52.247],
            [5.3982, 52.221],
            [5.4129, 52.2139],
            [5.4144, 52.2192],
            [5.4394, 52.2058],
            [5.4453, 52.2049],
            [5.4438, 52.1842],
            [5.4453, 52.1716],
            [5.4526, 52.1681],
            [5.479, 52.1645],
            [5.4893, 52.1582],
            [5.4878, 52.151],
            [5.5054, 52.1474],
            [5.5025, 52.142],
            [5.5171, 52.1384],
            [5.5186, 52.1321],
            [5.5039, 52.1304],
            [5.4995, 52.1115],
            [5.4907, 52.1061],
            [5.4922, 52.0945],
            [5.4804, 52.0909],
            [5.4643, 52.081],
            [5.4731, 52.0756],
            [5.4892, 52.0702],
            [5.5112, 52.0738],
            [5.5156, 52.0792],
            [5.5288, 52.0783],
            [5.5434, 52.089],
            [5.5449, 52.098],
            [5.5376, 52.1025],
            [5.5567, 52.1061],
            [5.561, 52.1007],
            [5.561, 52.0881],
            [5.5683, 52.0791],
            [5.5638, 52.0495],
            [5.577, 52.0486],
            [5.5741, 52.0414],
            [5.596, 52.0351],
            [5.5931, 52.0315],
            [5.593, 52.0119],
            [5.5988, 52.0019],
            [5.6207, 51.9911],
            [5.6207, 51.983],
            [5.6323, 51.9749],
            [5.6265, 51.9669],
            [5.6337, 51.9579],
            [5.6118, 51.9436],
            [5.5957, 51.9508],
            [5.568, 51.9553],
            [5.5534, 51.9607],
            [5.5446, 51.9688],
            [5.5169, 51.9742],
            [5.4935, 51.9831],
            [5.4599, 51.984],
            [5.4482, 51.9858],
            [5.4365, 51.984],
            [5.4234, 51.9769],
            [5.4044, 51.9742],
            [5.3883, 51.9697],
            [5.3693, 51.9714],
            [5.3533, 51.9687],
            [5.3387, 51.958],
            [5.3226, 51.9552],
            [5.3051, 51.9624],
            [5.2744, 51.9659],
            [5.2495, 51.9785],
            [5.2349, 51.9766],
            [5.2291, 51.9677],
            [5.2189, 51.9614],
            [5.2101, 51.9595],
            [5.1955, 51.9631],
            [5.1853, 51.9685],
            [5.1534, 51.9298],
            [5.1403, 51.919],
            [5.1229, 51.8992],
            [5.1172, 51.8884],
            [5.0953, 51.8892],
            [5.091, 51.8811],
            [5.0779, 51.8748],
            [5.0604, 51.8739],
            [5.0561, 51.8667],
            [5.0577, 51.8586],
            [5.0314, 51.8594],
            [5.0313, 51.8594],
            [5.0248, 51.8765],
            [5.0046, 51.8705],
            [4.9999, 51.8967],
            [4.979, 51.8931],
            [4.9725, 51.9056],
            [4.9654, 51.9104],
            [4.9529, 51.9264],
            [4.9427, 51.9276],
            [4.9374, 51.9443],
            [4.9297, 51.9478],
            [4.9153, 51.9424],
            [4.8818, 51.9377],
            [4.8757, 51.9539],
            [4.8637, 51.9682],
            [4.8491, 51.9753],
            [4.8388, 51.986],
            [4.821, 52.0003],
            [4.8604, 52.0059],
            [4.85, 52.0184],
            [4.8281, 52.0174],
            [4.8194, 52.0137],
            [4.8062, 52.0146],
            [4.8017, 52.0226],
            [4.8119, 52.0263],
            [4.8147, 52.0343],
            [4.8205, 52.0371],
            [4.832, 52.0497],
            [4.8509, 52.0579],
            [4.8698, 52.0624],
            [4.8772, 52.0688],
            [4.8464, 52.0695],
            [4.8287, 52.0748],
            [4.8253, 52.1089],
            [4.7958, 52.1222],
            [4.8104, 52.1286],
            [4.8103, 52.1349],
            [4.8161, 52.1403],
            [4.8366, 52.144],
            [4.841, 52.1404],
            [4.8587, 52.1369],
            [4.8777, 52.1388],
            [4.8775, 52.1559],
            [4.895, 52.1622],
            [4.8581, 52.1791],
            [4.8404, 52.1835],
            [4.8167, 52.1995],
            [4.8049, 52.2013],
            [4.8047, 52.2174],
            [4.7972, 52.2263],
            [4.8119, 52.2264],
            [4.831, 52.2301],
            [4.8573, 52.2419],
            [4.8601, 52.2473],
            [4.8851, 52.2537],
            [4.9013, 52.2511],
            [4.9145, 52.253],
            [4.9319, 52.2701],
            [4.9289, 52.28],
            [4.9642, 52.2783],
            [4.9876, 52.2901],
            [5.0009, 52.2892],
            [5.0066, 52.2982],
            [5.0154, 52.3037],
            [5.0257, 52.3028],
            [5.0259, 52.2831],
            [5.0332, 52.2876],
            [5.0465, 52.2822],
            [5.0538, 52.2876],
            [5.0627, 52.285],
            [5.0275, 52.2705],
            [5.0365, 52.2544],
            [5.0468, 52.2562],
            [5.0498, 52.2436],
            [5.0617, 52.2356],
            [5.0441, 52.2302],
            [5.0384, 52.2131],
            [5.0414, 52.1978],
            [5.0372, 52.1844],
            [5.046, 52.1835],
            [5.0505, 52.1656],
            [5.0814, 52.1729],
            [5.1282, 52.1811],
            [5.1972, 52.1776],
            [5.2206, 52.2046],
            [5.2293, 52.2253],
            [5.2424, 52.246],
            [5.2702, 52.2819],
            [5.3114, 52.2775],
          ],
        ],
      },
      properties: {
        name: 'Utrecht',
        id: 'NL-UT',
        CNTRY: 'Netherlands',
        NAME_ENG: 'Utrecht',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-UT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [3.8942, 51.743],
              [3.9062, 51.7332],
              [3.9266, 51.7317],
              [3.9439, 51.7346],
              [3.9701, 51.7341],
              [3.9834, 51.7262],
              [3.9911, 51.7137],
              [4.0074, 51.7031],
              [4.0152, 51.687],
              [4.0211, 51.6853],
              [4.0458, 51.6847],
              [4.0649, 51.6778],
              [4.0767, 51.6716],
              [4.0897, 51.6709],
              [4.1031, 51.6612],
              [4.0977, 51.6476],
              [4.0834, 51.6421],
              [4.072, 51.633],
              [4.0577, 51.6283],
              [4.0503, 51.6318],
              [4.0102, 51.6179],
              [3.9927, 51.6185],
              [3.9856, 51.6149],
              [3.9682, 51.6146],
              [3.949, 51.6252],
              [3.9356, 51.6358],
              [3.9286, 51.6294],
              [3.917, 51.6292],
              [3.8893, 51.6325],
              [3.8904, 51.6433],
              [3.8856, 51.6558],
              [3.8752, 51.6628],
              [3.8765, 51.6673],
              [3.8573, 51.6778],
              [3.8442, 51.6776],
              [3.8236, 51.6837],
              [3.8248, 51.6908],
              [3.8144, 51.6979],
              [3.7928, 51.6958],
              [3.7843, 51.6867],
              [3.7543, 51.6755],
              [3.7341, 51.6716],
              [3.7212, 51.6662],
              [3.7092, 51.6671],
              [3.6869, 51.6801],
              [3.6786, 51.6946],
              [3.6786, 51.7046],
              [3.6903, 51.721],
              [3.7047, 51.7287],
              [3.7186, 51.7301],
              [3.7558, 51.7404],
              [3.7703, 51.7429],
              [3.7914, 51.7432],
              [3.826, 51.7381],
              [3.8551, 51.7388],
              [3.8869, 51.7438],
              [3.8942, 51.743],
            ],
          ],
          [
            [
              [4.2348, 51.6329],
              [4.2312, 51.6263],
              [4.2158, 51.6185],
              [4.2055, 51.6099],
              [4.1973, 51.5981],
              [4.1966, 51.587],
              [4.2012, 51.5756],
              [4.2115, 51.5671],
              [4.2412, 51.5464],
              [4.2462, 51.5389],
              [4.2472, 51.5303],
              [4.2408, 51.5146],
              [4.2141, 51.5066],
              [4.1808, 51.5206],
              [4.1421, 51.5245],
              [4.1251, 51.5209],
              [4.0874, 51.523],
              [4.075, 51.5319],
              [4.0577, 51.5477],
              [4.0381, 51.5515],
              [4.0286, 51.5649],
              [3.9926, 51.5783],
              [3.9935, 51.5896],
              [4.0197, 51.595],
              [4.0345, 51.5952],
              [4.0464, 51.5982],
              [4.0723, 51.6101],
              [4.0961, 51.6119],
              [4.1228, 51.6033],
              [4.1767, 51.6036],
              [4.1847, 51.6051],
              [4.1823, 51.6119],
              [4.1731, 51.6134],
              [4.1543, 51.6122],
              [4.1353, 51.6131],
              [4.1288, 51.6211],
              [4.1103, 51.6306],
              [4.1106, 51.6399],
              [4.1195, 51.6464],
              [4.1356, 51.6521],
              [4.1579, 51.6506],
              [4.1749, 51.6419],
              [4.1981, 51.6354],
              [4.2348, 51.6329],
            ],
          ],
          [
            [
              [3.8607, 51.5976],
              [3.8727, 51.5919],
              [3.8883, 51.5776],
              [3.9064, 51.5641],
              [3.9033, 51.5557],
              [3.885, 51.5533],
              [3.8727, 51.5443],
              [3.8597, 51.5436],
              [3.8441, 51.5527],
              [3.8296, 51.5546],
              [3.8123, 51.546],
              [3.7984, 51.5472],
              [3.7756, 51.554],
              [3.7489, 51.5495],
              [3.7371, 51.5417],
              [3.7226, 51.5365],
              [3.7079, 51.5289],
              [3.7055, 51.5437],
              [3.6902, 51.5557],
              [3.664, 51.5658],
              [3.6508, 51.5767],
              [3.6554, 51.5875],
              [3.6893, 51.5938],
              [3.7058, 51.5934],
              [3.7228, 51.5875],
              [3.7338, 51.5875],
              [3.7673, 51.5934],
              [3.7933, 51.5944],
              [3.8262, 51.5999],
              [3.8458, 51.6007],
              [3.8607, 51.5976],
            ],
          ],
          [
            [
              [4.2611, 51.4501],
              [4.2776, 51.426],
              [4.2648, 51.4169],
              [4.271, 51.4008],
              [4.27, 51.3838],
              [4.2781, 51.3786],
              [4.2571, 51.3766],
              [4.2532, 51.3788],
              [4.2542, 51.3902],
              [4.246, 51.4027],
              [4.2121, 51.4095],
              [4.2014, 51.4099],
              [4.1731, 51.4015],
              [4.1558, 51.3949],
              [4.1397, 51.3963],
              [4.1336, 51.399],
              [4.1019, 51.4029],
              [4.0897, 51.4071],
              [4.0769, 51.4082],
              [4.0625, 51.4137],
              [4.0519, 51.4201],
              [4.0469, 51.4265],
              [3.9853, 51.4543],
              [3.9736, 51.4618],
              [3.9631, 51.4562],
              [3.9503, 51.4562],
              [3.9403, 51.4512],
              [3.9236, 51.4507],
              [3.9219, 51.4446],
              [3.9253, 51.4354],
              [3.9119, 51.4168],
              [3.8992, 51.3949],
              [3.8725, 51.3971],
              [3.8586, 51.3912],
              [3.8358, 51.3874],
              [3.8119, 51.3865],
              [3.8003, 51.394],
              [3.7769, 51.4049],
              [3.7775, 51.4107],
              [3.7658, 51.4104],
              [3.7569, 51.4154],
              [3.7381, 51.4115],
              [3.7264, 51.4151],
              [3.7242, 51.4201],
              [3.7103, 51.4343],
              [3.6847, 51.4468],
              [3.6797, 51.4535],
              [3.6658, 51.4593],
              [3.6558, 51.4515],
              [3.6431, 51.4485],
              [3.6314, 51.4415],
              [3.6186, 51.4432],
              [3.6075, 51.4401],
              [3.5831, 51.4407],
              [3.5764, 51.4385],
              [3.5564, 51.4499],
              [3.5514, 51.4496],
              [3.5308, 51.4596],
              [3.5164, 51.4724],
              [3.5131, 51.4782],
              [3.4958, 51.4946],
              [3.4836, 51.4985],
              [3.4536, 51.5126],
              [3.4436, 51.5213],
              [3.4336, 51.526],
              [3.4381, 51.5421],
              [3.4708, 51.556],
              [3.5431, 51.5843],
              [3.5747, 51.5938],
              [3.6047, 51.5932],
              [3.6211, 51.5879],
              [3.6293, 51.5713],
              [3.6397, 51.5624],
              [3.6722, 51.5477],
              [3.6831, 51.5281],
              [3.7014, 51.512],
              [3.7153, 51.5108],
              [3.726, 51.516],
              [3.7387, 51.5325],
              [3.746, 51.5382],
              [3.7606, 51.5401],
              [3.8044, 51.5396],
              [3.8278, 51.5457],
              [3.8432, 51.5437],
              [3.8491, 51.5395],
              [3.8593, 51.5387],
              [3.8837, 51.5426],
              [3.8983, 51.541],
              [3.9126, 51.5457],
              [3.9271, 51.5441],
              [3.9288, 51.5369],
              [3.9408, 51.5254],
              [3.9579, 51.531],
              [3.9739, 51.5285],
              [3.9945, 51.5225],
              [4.0177, 51.5183],
              [4.0222, 51.513],
              [4.0442, 51.5025],
              [4.0543, 51.5035],
              [4.0564, 51.4828],
              [4.0802, 51.4598],
              [4.0846, 51.4589],
              [4.0951, 51.4465],
              [4.1052, 51.4448],
              [4.1345, 51.4317],
              [4.1518, 51.4328],
              [4.1818, 51.4439],
              [4.2221, 51.4366],
              [4.2469, 51.4395],
              [4.2611, 51.4501],
            ],
          ],
          [
            [
              [4.0526, 51.3703],
              [4.0607, 51.366],
              [4.0812, 51.3679],
              [4.0955, 51.3669],
              [4.1226, 51.3536],
              [4.1488, 51.3341],
              [4.1559, 51.3417],
              [4.144, 51.3536],
              [4.1321, 51.3627],
              [4.1378, 51.365],
              [4.1502, 51.3617],
              [4.1592, 51.3469],
              [4.1673, 51.3446],
              [4.1924, 51.3465],
              [4.202, 51.3503],
              [4.2043, 51.3569],
              [4.1982, 51.3617],
              [4.1943, 51.3717],
              [4.201, 51.3774],
              [4.2181, 51.3769],
              [4.2296, 51.3693],
              [4.2334, 51.3588],
              [4.2467, 51.3531],
              [4.2447, 51.3474],
              [4.219, 51.3189],
              [4.1705, 51.2894],
              [4.1248, 51.2713],
              [4.0934, 51.2599],
              [4.0715, 51.2503],
              [4.0439, 51.2484],
              [4.0267, 51.2456],
              [4.0067, 51.2323],
              [3.9839, 51.2227],
              [3.9639, 51.2104],
              [3.9439, 51.2104],
              [3.9316, 51.2132],
              [3.9182, 51.2037],
              [3.9011, 51.1999],
              [3.8783, 51.2132],
              [3.8592, 51.2094],
              [3.8288, 51.2094],
              [3.8059, 51.2123],
              [3.7964, 51.2237],
              [3.8031, 51.2418],
              [3.7983, 51.2541],
              [3.7659, 51.2637],
              [3.7536, 51.2684],
              [3.709, 51.2675],
              [3.6748, 51.2846],
              [3.6481, 51.2846],
              [3.6262, 51.2951],
              [3.6043, 51.297],
              [3.5853, 51.2894],
              [3.572, 51.2894],
              [3.5539, 51.2808],
              [3.5349, 51.2808],
              [3.5263, 51.2751],
              [3.5291, 51.2418],
              [3.5158, 51.2361],
              [3.4901, 51.2361],
              [3.4568, 51.2313],
              [3.4225, 51.2465],
              [3.3911, 51.2627],
              [3.3835, 51.2684],
              [3.3759, 51.2875],
              [3.3769, 51.2989],
              [3.3616, 51.3094],
              [3.3854, 51.3293],
              [3.375, 51.3693],
              [3.3788, 51.376],
              [3.4064, 51.3826],
              [3.433, 51.3874],
              [3.4701, 51.3893],
              [3.4882, 51.3988],
              [3.5139, 51.4036],
              [3.5358, 51.4017],
              [3.5615, 51.3969],
              [3.5882, 51.3874],
              [3.6148, 51.3817],
              [3.6357, 51.3741],
              [3.6691, 51.3693],
              [3.69, 51.3703],
              [3.7033, 51.3598],
              [3.7281, 51.3512],
              [3.7709, 51.3403],
              [3.7837, 51.3484],
              [3.8294, 51.3441],
              [3.8456, 51.3374],
              [3.8566, 51.336],
              [3.8765, 51.3384],
              [3.8994, 51.3479],
              [3.9218, 51.3612],
              [3.9436, 51.3612],
              [3.9574, 51.365],
              [3.9651, 51.3722],
              [3.9798, 51.4007],
              [3.9889, 51.4041],
              [4.0084, 51.405],
              [4.0317, 51.3979],
              [4.0474, 51.3822],
              [4.0526, 51.3703],
            ],
          ],
        ],
      },
      properties: {
        name: 'Zeeland',
        id: 'NL-ZE',
        CNTRY: 'Netherlands',
        NAME_ENG: 'Zeeland',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-ZE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [4.338, 51.7265],
              [4.3031, 51.7297],
              [4.2854, 51.7385],
              [4.2604, 51.7481],
              [4.2632, 51.7545],
              [4.2807, 51.7528],
              [4.3157, 51.7469],
              [4.3362, 51.7408],
              [4.3552, 51.7374],
              [4.3597, 51.7339],
              [4.338, 51.7265],
            ],
          ],
          [
            [
              [4.0014, 51.8468],
              [4.0164, 51.8343],
              [4.0325, 51.8265],
              [4.0443, 51.8239],
              [4.0636, 51.8133],
              [4.0944, 51.8047],
              [4.121, 51.7933],
              [4.1444, 51.7882],
              [4.165, 51.7795],
              [4.1943, 51.7744],
              [4.1958, 51.7708],
              [4.218, 51.7576],
              [4.246, 51.7444],
              [4.2475, 51.7417],
              [4.2742, 51.7223],
              [4.3005, 51.7171],
              [4.3211, 51.7057],
              [4.3314, 51.7031],
              [4.388, 51.7027],
              [4.3995, 51.7082],
              [4.4056, 51.6948],
              [4.3668, 51.6783],
              [4.3583, 51.671],
              [4.3208, 51.6608],
              [4.3005, 51.6597],
              [4.2818, 51.655],
              [4.2381, 51.659],
              [4.2218, 51.6705],
              [4.2217, 51.6741],
              [4.1955, 51.6792],
              [4.191, 51.6828],
              [4.1692, 51.6834],
              [4.1528, 51.6985],
              [4.1119, 51.7079],
              [4.0872, 51.7068],
              [4.0507, 51.7117],
              [4.0505, 51.718],
              [4.0384, 51.7349],
              [4.0236, 51.7428],
              [4.0189, 51.7526],
              [4.0187, 51.7607],
              [4.0227, 51.7706],
              [4.0195, 51.7805],
              [4.0046, 51.7911],
              [4.0015, 51.7964],
              [3.9882, 51.8025],
              [3.9708, 51.8005],
              [3.9678, 51.8032],
              [3.9489, 51.8029],
              [3.9345, 51.7974],
              [3.9157, 51.7953],
              [3.9027, 51.7916],
              [3.891, 51.7923],
              [3.8928, 51.7824],
              [3.8827, 51.7796],
              [3.8723, 51.7813],
              [3.8564, 51.8004],
              [3.8581, 51.8115],
              [3.8675, 51.8185],
              [3.8953, 51.8243],
              [3.9053, 51.8246],
              [3.9736, 51.8426],
              [4.0014, 51.8468],
            ],
          ],
          [
            [
              [4.7972, 52.2263],
              [4.8047, 52.2174],
              [4.8049, 52.2013],
              [4.8167, 52.1995],
              [4.8404, 52.1835],
              [4.8581, 52.1791],
              [4.895, 52.1622],
              [4.8775, 52.1559],
              [4.8777, 52.1388],
              [4.8587, 52.1369],
              [4.841, 52.1404],
              [4.8366, 52.144],
              [4.8161, 52.1403],
              [4.8103, 52.1349],
              [4.8104, 52.1286],
              [4.7958, 52.1222],
              [4.8253, 52.1089],
              [4.8287, 52.0748],
              [4.8464, 52.0695],
              [4.8772, 52.0688],
              [4.8698, 52.0624],
              [4.8509, 52.0579],
              [4.832, 52.0497],
              [4.8205, 52.0371],
              [4.8147, 52.0343],
              [4.8119, 52.0263],
              [4.8017, 52.0226],
              [4.8062, 52.0146],
              [4.8194, 52.0137],
              [4.8281, 52.0174],
              [4.85, 52.0184],
              [4.8604, 52.0059],
              [4.821, 52.0003],
              [4.8388, 51.986],
              [4.8491, 51.9753],
              [4.8637, 51.9682],
              [4.8757, 51.9539],
              [4.8818, 51.9377],
              [4.9153, 51.9424],
              [4.9297, 51.9478],
              [4.9374, 51.9443],
              [4.9427, 51.9276],
              [4.9529, 51.9264],
              [4.9654, 51.9104],
              [4.9725, 51.9056],
              [4.979, 51.8931],
              [4.9999, 51.8967],
              [5.0046, 51.8705],
              [5.0248, 51.8765],
              [5.0313, 51.8594],
              [5.0198, 51.8593],
              [5.0096, 51.8584],
              [4.9993, 51.862],
              [5.0039, 51.8449],
              [5.036, 51.8415],
              [5.0303, 51.819],
              [5.0056, 51.8216],
              [4.9925, 51.8215],
              [4.9633, 51.8259],
              [4.9312, 51.8285],
              [4.908, 51.8248],
              [4.8891, 51.8166],
              [4.8631, 51.803],
              [4.8399, 51.7984],
              [4.8107, 51.8009],
              [4.7846, 51.7963],
              [4.7586, 51.7827],
              [4.7415, 51.7619],
              [4.7141, 51.7438],
              [4.691, 51.7347],
              [4.678, 51.731],
              [4.6534, 51.7272],
              [4.6447, 51.7236],
              [4.6288, 51.7208],
              [4.6315, 51.7289],
              [4.6085, 51.7171],
              [4.5912, 51.7115],
              [4.5825, 51.7124],
              [4.5608, 51.705],
              [4.5318, 51.7012],
              [4.5041, 51.7073],
              [4.4909, 51.7117],
              [4.4691, 51.7151],
              [4.4415, 51.7157],
              [4.4152, 51.7209],
              [4.3654, 51.7375],
              [4.3507, 51.7436],
              [4.2995, 51.7566],
              [4.2688, 51.7608],
              [4.2498, 51.766],
              [4.232, 51.7793],
              [4.2317, 51.7883],
              [4.2139, 51.7997],
              [4.2022, 51.8041],
              [4.1832, 51.8048],
              [4.1669, 51.8172],
              [4.1565, 51.8225],
              [4.1289, 51.8195],
              [4.1127, 51.8274],
              [4.1025, 51.8263],
              [4.0681, 51.844],
              [4.0653, 51.8551],
              [4.0492, 51.8713],
              [4.0308, 51.8868],
              [4.0331, 51.8935],
              [4.0519, 51.9071],
              [4.0514, 51.919],
              [4.0408, 51.9238],
              [3.9986, 51.9162],
              [3.9908, 51.9193],
              [3.9931, 51.9287],
              [4.0064, 51.9349],
              [4.0047, 51.9393],
              [4.0175, 51.9515],
              [4.0114, 51.9626],
              [4.0119, 51.9718],
              [4.0186, 51.9838],
              [4.0264, 51.9871],
              [4.0369, 51.9838],
              [4.0592, 51.981],
              [4.0803, 51.9734],
              [4.0882, 51.9731],
              [4.1119, 51.9907],
              [4.1336, 52.0024],
              [4.1508, 52.0168],
              [4.1564, 52.0235],
              [4.1814, 52.0432],
              [4.1881, 52.046],
              [4.2158, 52.066],
              [4.2336, 52.0807],
              [4.2414, 52.0843],
              [4.2769, 52.1121],
              [4.3081, 52.1307],
              [4.3508, 52.1665],
              [4.3636, 52.1763],
              [4.4053, 52.2182],
              [4.4281, 52.2429],
              [4.4569, 52.2776],
              [4.4636, 52.2879],
              [4.4803, 52.3085],
              [4.4942, 52.3277],
              [4.5649, 52.3092],
              [4.5853, 52.3183],
              [4.6015, 52.3167],
              [4.6134, 52.3131],
              [4.6047, 52.3032],
              [4.5905, 52.2798],
              [4.5701, 52.2679],
              [4.5705, 52.25],
              [4.5649, 52.2338],
              [4.5518, 52.2265],
              [4.5584, 52.2187],
              [4.5755, 52.2168],
              [4.5858, 52.2187],
              [4.605, 52.2143],
              [4.6211, 52.2145],
              [4.6416, 52.2182],
              [4.6722, 52.231],
              [4.6797, 52.2256],
              [4.6885, 52.2266],
              [4.706, 52.2339],
              [4.7266, 52.2322],
              [4.7254, 52.216],
              [4.7373, 52.2062],
              [4.746, 52.2144],
              [4.765, 52.2181],
              [4.7884, 52.2281],
              [4.7972, 52.2263],
            ],
          ],
        ],
      },
      properties: {
        name: 'Zuid-Holland',
        id: 'NL-ZH',
        CNTRY: 'Netherlands',
        NAME_ENG: 'South Holland',
        TYPE: 'Provincie',
        TYPE_ENG: 'Province',
      },
      id: 'NL-ZH',
    },
  ],
}
export default map
