import {put, select, takeLatest} from 'redux-saga/effects'
import {ActionWithPayload, RootState} from '../../../../setup'
import {MediaInvoice} from '../models/MediaInvoice'
import {selectors as authSelectors} from '../../auth/redux/AuthRedux'
import {getMediaInvoicesList} from './MediaInvoicesCRUD'

export const MEDIA_INVOICE_REQUEST = 'MEDIA_INVOICE_REQUEST'
export const MEDIA_INVOICE_REQUEST_SUCCESS = 'MEDIA_INVOICE_REQUEST_SUCCESS'
export const MEDIA_INVOICE_REQUEST_FAIL = 'MEDIA_INVOICE_REQUEST_FAIL'

export const INVOICE_PDF_FAIL = 'INVOICE_PDF_FAIL'

export const CHANGE_INVOICE_TYPE = 'CHANGE_INVOICE_TYPE'
export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IMediaInvoiceState {
  mediaInvliceList?: MediaInvoice[]
  loading: boolean
  error: boolean

  invoicePdfError?: string[][] | null

  invoiceType?: string
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
}

const initailState: IMediaInvoiceState = {
  mediaInvliceList: [],
  loading: false,
  error: false,

  invoicePdfError: undefined,

  invoiceType: 'proforma',
  currentPage: 1,
  totalPages: undefined,
  perPage: 20,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,
}

export const reducer = (
  state: IMediaInvoiceState = initailState,
  action: ActionWithPayload<IMediaInvoiceState>
) => {
  switch (action.type) {
    case MEDIA_INVOICE_REQUEST:
      return {...state, invoiceLoading: true}
    case MEDIA_INVOICE_REQUEST_SUCCESS:
      const mediaInvliceList = action.payload?.mediaInvliceList
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        mediaInvliceList,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        invoiceLoading: false,
        invoiceError: false,
      }
    case MEDIA_INVOICE_REQUEST_FAIL:
      return {...state, invoiceLoading: false, invoiceError: true}

    case INVOICE_PDF_FAIL:
      const invoicePdfError = action.payload?.invoicePdfError
      return {...state, invoicePdfError}

    case CHANGE_INVOICE_TYPE:
      const invoiceType = action.payload?.invoiceType
      return {...state, invoiceType}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    default:
      return state
  }
}

export const actions = {
  requestInvoices: () => ({type: MEDIA_INVOICE_REQUEST}),
  requestInvoicesSuccess: (
    mediaInvliceList: MediaInvoice[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: MEDIA_INVOICE_REQUEST_SUCCESS,
    payload: {mediaInvliceList, currentPage, totalPages, perPage, totalItem},
  }),
  requestInvoicesFail: () => ({type: MEDIA_INVOICE_REQUEST_FAIL}),

  getInvoicePdfFail: (errorMsg: string[][]) => ({
    type: INVOICE_PDF_FAIL,
    payload: {invoicePdfError: errorMsg},
  }),

  changeInvoiceType: (invoiceType: string) => ({
    type: CHANGE_INVOICE_TYPE,
    payload: {invoiceType},
  }),
  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),
  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),
  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),
  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),
  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getInvoicesState: (state: RootState) => state.mediaInvoices,
  getCurrentPage: (state: RootState) => state.mediaInvoices.currentPage,
  getLimitPerPage: (state: RootState) => state.mediaInvoices.perPage,
  getFilter: (state: RootState) => state.mediaInvoices.filter,
  getTotalItem: (state: RootState) => state.mediaInvoices.totalItem,
  getInvoiceType: (state: RootState) => state.mediaInvoices.invoiceType,
  getSearchText: (state: RootState) => state.mediaInvoices.searchText,
}

function* listUpdate() {
  const companyId: number = yield select(authSelectors.getCompanyId)
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const invoiceType: string = yield select(selectors.getInvoiceType)
  const {data} = yield getMediaInvoicesList(
    companyId,
    page,
    limit,
    filter,
    invoiceType,
    searchValue
  )
  return {data}
}

export function* saga() {
  yield takeLatest(MEDIA_INVOICE_REQUEST, function* getMediaInvoicesSaga() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestInvoicesSuccess(
          data.items,
          data.currentPage,
          data.lastPage,
          data.perPage,
          data.total
        )
      )
    } catch (err) {
      yield put(actions.requestInvoicesFail())
    }
  })
}
