import {FBAnalyticTimeComprasion, FBReportsDetails} from './FBReport'
import {SEMReportDetails} from './SEMReports'
import {SEOReportDeatils} from './SEOReport'
import {Gender} from "./AbstractReportDetails";
import {BINGReportDetails} from "./BINGReport";
import {bingAdsCampaignList} from "./BINGReport";
import {TikTokData, TikTokReportDetails} from "./TikTokReport";
import {monthly} from "./TikTokReport";
import {allegroData, AllegroReportDetails} from "./AllegroReport";

export interface IReports {
  id: number
  date: Date
  domain: string
  name: string
  type?: string
}

export type ReportType = 'fb' | 'sem' | 'seo' | 'allegro' | 'bing' | 'tiktok' | 'dat' | 'linkedin'

export interface IReportDetails {
  campaign_currency: string;
  allegroData: allegroData
  monthly: monthly[]
  tiktokData: TikTokData
  bingAdsCampaignList: bingAdsCampaignList[]
  agreement_id: number
  attachments?: string[]
  comment: string
  client_plan?: string
  client_todo?: string
  usefull_links?: string
  date: Date
  details: SEOReportDeatils | FBReportsDetails | SEMReportDetails | BINGReportDetails | TikTokReportDetails | AllegroReportDetails
  domain: string
  id: number
  status: number
  type_id: number
  type: ReportType
}

export interface GoogleAnalyticCampaign {
  ad_cost: number | string
  name: string
  roas: number | string
  sessions: number | string
  transactions: number | string
  transactions_revenue: number | string
}

export interface GoogleAnalyticSummary extends FBAnalyticTimeComprasion {
  cpc: number
  cpc_month: number
  cpc_year: number
  goal_completions_all: number
  goal_completions_all_month: number
  goal_completions_all_year: number
  goal_conversion_rate_all: number
  goal_conversion_rate_all_month: number
  goal_conversion_rate_all_year: number
  type: number
  new_users: number
  new_users_month: number
  new_users_year: number
  revenue_per_transaction: number
  revenue_per_transaction_month: number
  revenue_per_transaction_year: number
  users: number
  users_month: number
  users_year: number
}

export interface GoogleAnalyticCampaignData {
  data: GoogleAnalyticCampaign[]
  titles: GoogleAnalyticCampaign
}

export interface ClicksPerDate {
  clicks: number[]
  dates: Date[]
}

export interface ClicksToRevenuePerDate {
  clicks: number[]
  revenue: number[]
  dates: Date[]
}
export interface GadsAccounts {
  account: any[]
}
export interface GadsClicksToRevenuePerDate {
  clicks: number[]
  conversions: number[]
  dates: Date[]
}
export interface SEMAdwordsSummary {
  number: string
  value: {
    clicks: number
    conversion: number
    conversion_cost: number
    conversion_cost_total: number
    conversion_rate: string
    cost: number
    cpc: number
    ctr: string
  } | null
}

export interface SEMAdwordsNetwork {
  number: string
  value: {
    avgPosition: string | null
    clicks: number
    conversionRate: string
    cost: number
    costPerConversion: number
    cpc: number
    ctr: string
    name: string
    totalConversion: number
  }[]
}

export interface SEMAdwordsClicks {
  number: string
  value: ClicksPerDate
}

export interface SEMAdwordsCampaigns {
  number: string
  value: {
    avg_position: string
    clicks: number
    conversion: number
    conversion_cost: number
    conversion_cost_total: number
    conversion_rate: string
    cost: number
    cpc: number
    ctr: string
    name: string
    network: string
  }[]
}

export interface SEMGoogleAdwordsAccounts {
  additionals: string[]
  default: string
}

export interface GoogleAnalyticGender {
  gender: string
  goal_conversion_rate_all: number | string
  new_users: number | string
  sessions: number | string
  transactions_revenue: number | string
}
export interface FBGenderBracketsData {
  data: Gender[]
}

export interface GoogleAnalyticRegions {
  voivodeship_iso: string
  voivodeship_name: string
  sessions: number | string
}

export interface GoogleAnalyticAgeBracket {
  age_bracket: string
  sessions: number | string
  transactions_revenue: number | string
}

export interface GoogleAnalyticDevices {
  ad_clicks: number | string
  ad_cost: number | string
  device_category: string
  revenue_per_transaction: number | string
  sessions: number | string
  transactions: number | string
  transaction_revenue: number | string | null
  users: number | string
  revenue_per_user: number | string
}

export interface fbCampaignPlatform {
  ad_clicks: number | string
  ad_cost: number | string
  device_category: string
  revenue_per_transaction: number | string
  sessions: number | string
  transactions: number | string
  transaction_revenue: number | string | null
  users: number | string
  revenue_per_user: number | string
}

export const LIST_FILTERS = [
  {value: 'sort=-date', label: 'INVOICE.SORT.DATE_DOWN'},
  {value: 'sort=date', label: 'INVOICE.SORT.DATE_UP'},
  {value: 'sort=name', label: 'LIST_FILTER.NAME_UP'},
  {value: 'sort=-name', label: 'LIST_FILTER.NAME_DOWN'},
  {value: 'sort=domain', label: 'INVOICE.SORT.DOMAIN_UP'},
  {value: 'sort=-domain', label: 'INVOICE.SORT.DOMAIN_DOWN'},
]

export const EFFICIENCY_LIST_FILTERS = [
  {value: 'sort=-item_revenue', label: 'REPORT_EFFICIENCY_REVENUE_FILTER_DOWN'},
  {value: 'sort=item_revenue', label: 'REPORT_EFFICIENCY_REVENUE_FILTER_UP'},
  {value: 'sort=-product_name', label: 'REPORT_EFFICIENCY_NAME_FILTER_DOWN'},
  {value: 'sort=product_name', label: 'REPORT_EFFICIENCY_NAME_FILTER_UP'},
  {value: 'sort=item_quantity', label: 'REPORT_EFFICIENCY_QUANTITY_FILTER_UP'},
  {value: 'sort=-item_quantity', label: 'REPORT_EFFICIENCY_QUANTITY_FILTER_DOWN'},
  {value: 'sort=unique_purchases', label: 'REPORT_EFFICIENCY_UNIQUE_FILTER_UP'},
  {value: 'sort=-unique_purchases', label: 'REPORT_EFFICIENCY_UNIQUE_FILTER_DOWN'},
  {value: 'sort=revenue_per_item', label: 'REPORT_EFFICIENCY_REVENIUE_PER_ITEM_FILTER_UP'},
  {value: 'sort=-revenue_per_item', label: 'REPORT_EFFICIENCY_REVENIUE_PER_ITEM_FILTER_DOWN'},
  {value: 'sort=items_per_purchase', label: 'REPORT_EFFICIENCY_ITEMS_PER_PURCHASE_FILTER_UP'},
  {value: 'sort=-items_per_purchase', label: 'REPORT_EFFICIENCY_ITEMS_PER_PURCHASE_FILTER_DOWN'},
]

export const CATEGORY_LIST_FILTERS = [
  {value: 'sort=-item_revenue', label: 'REPORT_EFFICIENCY_REVENUE_FILTER_DOWN'},
  {value: 'sort=item_revenue', label: 'REPORT_EFFICIENCY_REVENUE_FILTER_UP'},
  {value: 'sort=-product_category_hierarchy', label: 'REPORT_EFFICIENCY_NAME_FILTER_DOWN'},
  {value: 'sort=product_category_hierarchy', label: 'REPORT_EFFICIENCY_NAME_FILTER_UP'},
  {value: 'sort=item_quantity', label: 'REPORT_EFFICIENCY_QUANTITY_FILTER_UP'},
  {value: 'sort=-item_quantity', label: 'REPORT_EFFICIENCY_QUANTITY_FILTER_DOWN'},
  {value: 'sort=unique_purchases', label: 'REPORT_EFFICIENCY_UNIQUE_FILTER_UP'},
  {value: 'sort=-unique_purchases', label: 'REPORT_EFFICIENCY_UNIQUE_FILTER_DOWN'},
  {value: 'sort=revenue_per_item', label: 'REPORT_EFFICIENCY_REVENIUE_PER_ITEM_FILTER_UP'},
  {value: 'sort=-revenue_per_item', label: 'REPORT_EFFICIENCY_REVENIUE_PER_ITEM_FILTER_DOWN'},
  {value: 'sort=items_per_purchase', label: 'REPORT_EFFICIENCY_ITEMS_PER_PURCHASE_FILTER_UP'},
  {value: 'sort=-items_per_purchase', label: 'REPORT_EFFICIENCY_ITEMS_PER_PURCHASE_FILTER_DOWN'},
]

export const BRAND_LIST_FILTERS = [
  {value: 'sort=-item_revenue', label: 'REPORT_EFFICIENCY_REVENUE_FILTER_DOWN'},
  {value: 'sort=item_revenue', label: 'REPORT_EFFICIENCY_REVENUE_FILTER_UP'},
  {value: 'sort=-product_brand', label: 'REPORT_EFFICIENCY_NAME_FILTER_DOWN'},
  {value: 'sort=product_brand', label: 'REPORT_EFFICIENCY_NAME_FILTER_UP'},
  {value: 'sort=item_quantity', label: 'REPORT_EFFICIENCY_QUANTITY_FILTER_UP'},
  {value: 'sort=-item_quantity', label: 'REPORT_EFFICIENCY_QUANTITY_FILTER_DOWN'},
  {value: 'sort=unique_purchases', label: 'REPORT_EFFICIENCY_UNIQUE_FILTER_UP'},
  {value: 'sort=-unique_purchases', label: 'REPORT_EFFICIENCY_UNIQUE_FILTER_DOWN'},
  {value: 'sort=revenue_per_item', label: 'REPORT_EFFICIENCY_REVENIUE_PER_ITEM_FILTER_UP'},
  {value: 'sort=-revenue_per_item', label: 'REPORT_EFFICIENCY_REVENIUE_PER_ITEM_FILTER_DOWN'},
  {value: 'sort=items_per_purchase', label: 'REPORT_EFFICIENCY_ITEMS_PER_PURCHASE_FILTER_UP'},
  {value: 'sort=-items_per_purchase', label: 'REPORT_EFFICIENCY_ITEMS_PER_PURCHASE_FILTER_DOWN'},
]

export enum SEM_TAB_NAME {
  COMMENT = 'COMMENT',
  DATA = 'BASIC_DATA',
  PRINT = 'PRINT',
  PDF = 'PDF',
  GADS = 'GADS',
  GA4 = 'GA4 (BETA)',
  SAIL_CAMPAIGN = 'Kampanie sprzedażowe',
  REACH_CAMPAIGN = 'Kampanie zasięgowe',
  ATTACHMENTS = 'ATTACHMENTS',
}

export enum SEO_TAB_NAME {
  ANALYSIS = 'ANALYSIS',
  ASPS = 'ASPS',
  LINKS = 'LINKI',
  PRINT = 'PRINT',
  PDF = 'PDF',
  GA4 = 'GA4 (BETA)',
  COMMENT = 'COMMENT',
  GOOGLE_SEARCH_CONSOLE = 'GOOGLE_SEARCH_CONSOLE',
}

export enum FB_TAB_NAME {
  COMMENT = 'COMMENT',
  ANALYSIS = 'ANALYSIS',
  ATTACHMENTS = 'ATTACHMENTS',
}

export enum TAB_NAME {
  COMMENT = 'COMMENT',
  ANALYSIS = 'ANALYSIS',
  ATTACHMENTS = 'ATTACHMENTS',
  PRINT = 'PDF'
}
