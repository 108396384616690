import am4geodata_polandLow from './polandLow'
import am4geodata_austriaLow from './austriaLow'
import am4geodata_belgiumLow from './belgiumLow'
import am4geodata_bulgariaLow from './bulgariaLow'
import am4geodata_canadaLow from './canadaLow'
import am4geodata_croatiaLow from './croatiaLow'
import am4geodata_cyprusLow from './cyprusLow'
import am4geodata_czechRepublickLow from './czechRepublicLow'
import am4geodata_denmark from './denmarkLow'
import am4geodata_estoniaLow from './estoniaLow'
import am4geodata_finlandLow from './finlandLow'
import am4geodata_franceLow from './franceLow'
import am4geodata_germanyLow from './germanyLow'
import am4geodata_hungaryLow from './hungaryLow'
import am4geodata_irelandLow from './irelandLow'
import am4geodata_italyLow from './italyLow'
import am4geodata_latviaLow from './latviaLow'
import am4geodata_lithuaniaLow from './lithuaniaLow'
import am4geodata_luxembourgLow from './luxembourgLow'
import am4geodata_netherlandsLow from './netherlandsLow'
import am4geodata_portugalLow from './portugalLow'
import am4geodata_romaniaLow from './romaniaLow'
import am4geodata_slovakiaLow from './slovakiaLow'
import am4geodata_sloveniaLow from './sloveniaLow'
import am4geodata_spainLow from './spainLow'
import am4geodata_swedenLow from './swedenLow'
import am4geodata_switzerlandLow from './switzerlandLow'
import am4geodata_ukraineLow from './ukraineLow'
import am4geodata_worldLow from './worldLow'

export const countriesMaps = {
  PL: am4geodata_polandLow,
  AT: am4geodata_austriaLow,
  BE: am4geodata_belgiumLow,
  BG: am4geodata_bulgariaLow,
  CA: am4geodata_canadaLow,
  HR: am4geodata_croatiaLow,
  CY: am4geodata_cyprusLow,
  CZ: am4geodata_czechRepublickLow,
  DK: am4geodata_denmark,
  EE: am4geodata_estoniaLow,
  FI: am4geodata_finlandLow,
  FR: am4geodata_franceLow,
  DE: am4geodata_germanyLow,
  HU: am4geodata_hungaryLow,
  IE: am4geodata_irelandLow,
  IT: am4geodata_italyLow,
  LV: am4geodata_latviaLow,
  LT: am4geodata_lithuaniaLow,
  LU: am4geodata_luxembourgLow,
  NL: am4geodata_netherlandsLow,
  PT: am4geodata_portugalLow,
  RO: am4geodata_romaniaLow,
  SK: am4geodata_slovakiaLow,
  SI: am4geodata_sloveniaLow,
  ES: am4geodata_spainLow,
  SE: am4geodata_swedenLow,
  CH: am4geodata_switzerlandLow,
  UA: am4geodata_ukraineLow,
  WORLD: am4geodata_worldLow,
}
