import {useIntl} from 'react-intl'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {selectors} from '../../invoices/redux/InvoiceRedux'
import {KTSVG} from '../../../../_metronic/helpers'

interface OverdueInvoicesAlertProps {}

const OverdueInvoicesAlert: React.FC<OverdueInvoicesAlertProps> = () => {
  const intl = useIntl()
  const {overdueInvoices} = useSelector(selectors.getInvoicesState)
  const [oveddueMessage, setOverdueMessage] = useState<string[][]>()
  const [showList, setShowList] = useState<boolean>(false)

  useEffect(() => {
    if (overdueInvoices && overdueInvoices.length > 0) {
      let overdueItem: string[][] = []
      // eslint-disable-next-line array-callback-return
      overdueInvoices.map((item) => {
        const overdue =
          (new Date().getTime() - new Date(item.payment_time).getTime()) / (1000 * 3600 * 24)
        overdueItem.push([
          `${intl.formatMessage({id: 'UNPAYMENT.MESSAGE'})} ${item.number}. ${intl.formatMessage({
            id: 'UNPAYMENT.MESSAGE.DAYS',
          })} ${Math.floor(overdue)} ${intl.formatMessage({id: 'DAYS'})}`,
        ])
      })
      setOverdueMessage(overdueItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overdueInvoices])

  return (
    <>
      {overdueInvoices && overdueInvoices.length > 0 && (
        <div className='overdue-alert d-flex justify-content-center pt-4 pb-2 bg-light-overdue-light shadow-sm'>
          <div className='d-flex align-items-center'>
            <div className='d-flex flex-row align-items-center'>
              <div className='overdue-alert-img me-5'>
                <KTSVG
                  className='svg-icon-3x svg-icon-overdue-light'
                  path='/media/sempai-images/icons/Icon-feather-alert-triangle.svg'
                />
              </div>
              <div className='fs-4 fw-bolder text-overdue'>{intl.formatMessage({id: 'UNPAYMENT.ALERT'})}</div>
            </div>
            <div className='d-flex flex-column ms-10'>
              {oveddueMessage &&
                // eslint-disable-next-line array-callback-return
                oveddueMessage.map((item, index, array) => {
                  if (array.length <= 2) {
                    return (
                      <div
                        key={`small-${index}`}
                        className={`fs-6 fw-bold text-overdue-active ${
                          index !== array.length - 1 && 'pb-2'
                        }`}
                      >
                        {item}
                      </div>
                    )
                  } else if (!showList && index < 2) {
                    return (
                      <div key={`close-${index}`}>
                        <div className='fs-6 pb-2 fw-bold text-overdue-active'>{item}</div>
                        {index === 1 && (
                          <div
                            key={'btn-open'}
                            className='d-flex justify-content-center'
                            onClick={() => setShowList(true)}
                          >
                            <KTSVG
                              className='svg-icon-1 svg-icon-overdue-active'
                              path='/media/icons/duotone/Navigation/Angle-down.svg'
                            />
                          </div>
                        )}
                      </div>
                    )
                  } else if (showList) {
                    return (
                      <div key={`open-${index}`}>
                        <div className='fs-6 pb-2 fw-bold text-overdue-active'>{item}</div>
                        {index === array.length - 1 && (
                          <div
                            key={'btn-close'}
                            className='d-flex justify-content-center'
                            onClick={() => setShowList(false)}
                          >
                            <KTSVG
                              className='svg-icon-1 svg-icon-overdue-active'
                              path='/media/icons/duotone/Navigation/Angle-up.svg'
                            />
                          </div>
                        )}
                      </div>
                    )
                  }
                })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OverdueInvoicesAlert
