import React from 'react'
import {useState} from 'react'
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {SEOFacilities} from '../../../models/SEOReport'

interface FacilitiesWidgetItemProps {
  satellite: SEOFacilities
  className?: string
}

const FacilitiesWidgetItem: React.FC<FacilitiesWidgetItemProps> = ({satellite, className}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyClick = (copyText: string) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const renderTooltip = (props: TooltipProps) => (
    <Tooltip id='button-tooltip' {...props}>
      {isCopied ? (
        <span className='text-sempai-green fw-bold'>Copied!</span>
      ) : (
        <span className='text-dark fw-bold'>Click to copy</span>
      )}
    </Tooltip>
  )

  return (
    <tr className={className}>
      <td>
        <div className='fs-6 fw-bolder'>
          <a
            href={satellite.url.match('https?://') ? satellite.url : 'http://' + satellite.url}
            target='_blank'
            rel='noreferrer'
            className='text-lite-primary text-hover fw-bolder fs-6'
          >
            {satellite.url}
          </a>
        </div>
      </td>
      <td>
        <div className='fs-6 fw-bolder'>{satellite.login}</div>
      </td>
      <td className='d-flex justify-content-end'>
        {showPassword ? (
          <div className='fs-6 fw-bolder text-center'>
            <span onClick={() => setShowPassword(false)}>
              <KTSVG
                className='svg-icon svg-icon-white me-2 cursor-pointer'
                path={'/media/sempai-images/icons/Icon-feather-eye-off.svg'}
              />
            </span>
            <OverlayTrigger placement='top' delay={{show: 250, hide: 300}} overlay={renderTooltip}>
              <span className='cursor-pointer' onClick={() => handleCopyClick(satellite.password)}>
                {satellite.password}
              </span>
            </OverlayTrigger>
          </div>
        ) : (
          <div
            className='fs-6 fw-bolder text-end cursor-pointer'
            onClick={() => setShowPassword(true)}
          >
            <KTSVG
              className='svg-icon svg-icon-white me-2 cursor-pointer'
              path={'/media/sempai-images/icons/Icon-feather-eye.svg'}
            />
            <KTSVG
              className='svg-icon-dark'
              svgClassName='h-10px w-100px'
              path={'/media/sempai-images/icons/Group-119.svg'}
            />
          </div>
        )}
      </td>
    </tr>
  )
}

export default FacilitiesWidgetItem
