import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import React, {useEffect, useState} from "react";
import ErrorView from "../../../../common/ErrorView/ErrorView";
import Spinner from "../../../../common/Spinner/Spinner";
import AllegroTitle from "../AllegroWidgets/AllegroTitle";
import AllegroGraphicSum from "../AllegroWidgets/AllegroGraphicSum";
import AllegroSponsoredSum from "../AllegroWidgets/AllegroSponsoredSum";
import AllegroBasicData from "../AllegroWidgets/AllegroBasicData";
import AllegroCampaigns from "../AllegroWidgets/AllegroCampaigns";
import {IReportDetails} from "../../../models/Reports";

interface IBingDataStatisticTab {
    isPdf?: boolean;
    onLoad?: () => void;
}

const AllegroDataStatisticTab: React.FC<IBingDataStatisticTab> = ({ isPdf, onLoad }) => {
    const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)
    const [loading, setLoading] = useState<boolean>(true)
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [sameData, setSameData] = useState<boolean>(false);

    useEffect(() => {
        setLoading(reportsLoading)
    }, [reportsLoading])

    useEffect(() => {
        if(onLoad){
            onLoad();
        }
    }, [onLoad]);

    useEffect(() => {
        if(reportData && reportData.details.allegroDaily && reportData.details.allegroDaily.graphicalSum.clicks == 0 && reportData.details.allegroDaily.graphicalSum.totalAttributionValue == 0 &&
            reportData.details.allegroDaily.graphicalSum.totalCost == 0 && reportData.details.allegroDaily.graphicalSum.views == 0) {
            setSameData(true);
        }
        if(reportData && reportData.details.allegroDaily && reportData.details.allegroDaily.sponsoredSum.clicks == 0 && reportData.details.allegroDaily.sponsoredSum.totalAttributionValue == 0 &&
            reportData.details.allegroDaily.sponsoredSum.totalCost == 0 && reportData.details.allegroDaily.sponsoredSum.views == 0) {
            setSameData(true);
        }
    }, [reportData]);

    return !reportsError ? (
        <div
            data-testid="bing-report-basic-data"
            className={reportsLoading ? 'overlay overlay-block rounded' : ''}
        >
            {!loading ? (
                <>
                    <div className="clicks-group card-body bg-white">
                        <AllegroTitle/>
                    </div>
                    <div className="clicks-group bg-white">
                        <AllegroBasicData/>
                    </div>
                    {!sameData ? (
                        <>
                            <div className='clicks-group bg-white'>
                                <AllegroGraphicSum/>
                            </div>
                            <div className='clicks-group bg-white'>
                                <AllegroSponsoredSum/>
                            </div>
                        </>
                    ) : <p></p>
                    }
                    <div className='clicks-group bg-white'>
                        <AllegroCampaigns/>
                    </div>
                </>

            ) : (
                <div style={{height: '350px'}}>
                    <Spinner />
                </div>
            )}
        </div>
    ) : (
        <ErrorView />
    )
}

export default AllegroDataStatisticTab