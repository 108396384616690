import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import {useIntl} from "react-intl";
import StatisticMap, {StatisticMapData} from "../../Widgets/StatisticMap";
import ChartLineWithColumnWidget, {Ga4GAdsAgeBracketsData} from "../../Widgets/ChartLineWithColumnWidget";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {numSpaceFormater} from "../../../../../helpers/formater";
import StatisticWorldMap from "../../Widgets/StatisticWorldMap";
import {bingAdsGender} from "../../../models/BINGReport";
import {countriesMaps} from "../../../../../../setup/utils/countriesMaps";

export interface Ga4ageDaumChart {
    ageBracket: string[]
    sessions: number[]
    transaction: number[]
}

const BingGeo: React.FC = () => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [regionsReport, setRegionsReport] = useState<StatisticMapData[]>()
    const [worldReport, setWorldReport] = useState<StatisticMapData[]>()

    const [ageReport, setAgeReport] = useState<Ga4GAdsAgeBracketsData>()
    const [genderReport, setGenderReport] = useState<bingAdsGender[]>()

    useEffect(() => {
        if (reportData.details && reportData.details.bingAdsGeoData) {
            const regionData: StatisticMapData[] = [];

            reportData.details.bingAdsGeoData.forEach((item) => {
                const code = countriesMaps.PL.features.find(entry => entry.properties.name.toLowerCase() == item.state.toLowerCase());

                if(code) {
                    const existingItem = regionData.find((entry) => entry.id.toLowerCase() == code.id.toLowerCase());
                    if (typeof(existingItem) != 'undefined') {
                        existingItem.value += item.clicks as number;
                    } else {
                        regionData.push({
                            id: code.id,
                            value: item.clicks as number,
                        });
                    }
                }

            });

            setRegionsReport(regionData);
        }


        if (reportData.details && reportData.details.bingAdsGeoData) {
            const worldReport: StatisticMapData[] = [];

            reportData.details.bingAdsGeoData.forEach((item) => {
                const existingItem = worldReport.find((entry) => entry.id == item.countryId);

                if (existingItem) {
                    existingItem.value += item.clicks as number;
                } else {
                    worldReport.push({
                        id: item.countryId,
                        value: item.clicks as number,
                    });
                }
            });

            setWorldReport(worldReport);
        }


        if (reportData.details && reportData.details.bingAdsGender) {
            setGenderReport(reportData.details.bingAdsGender)
        }
        if (reportData.details && reportData.details.bingAdsAge) {
            const chartData: Ga4ageDaumChart = {
                ageBracket: [],
                sessions: [],
                transaction: [],
            }
            reportData.details.bingAdsAge.map((item) => {
                chartData.ageBracket.push(item.age_range)
                chartData.sessions.push(Number(item.impressions))
                chartData.transaction.push(Number(item.conversion_value))
            })

            setAgeReport(chartData)
        }
    }, [reportData])

    return (
        <>
            {genderReport && genderReport.length > 0 && ageReport && ageReport.ageBracket && ageReport.ageBracket.length > 0 &&(
                <div className='row card-body social-group d-flex justify-content-between align-items-stretch flex-column flex-xl-row mb-5'>
                    {genderReport && genderReport.length > 0 ? (
                        <div className='col-6 social-group-genders'>
                            <div className='border h-100'>
                                <div className='px-5 py-5'>
                                    <h3 className='fw-boldest text-sempai-green text-uppercase mb-0'>
                                        {intl.formatMessage({id: 'DEMOGRAPHY_GENDER'})}
                                    </h3>
                                </div>
                                <div className='row g-0' data-testid='gender-data'>
                                    {genderReport.map((item: any) => (

                                        <div className='col-4'>
                                            <div className='d-flex flex-row align-items-start'>
                                                <div className='d-flex justify-content-center align-items-center mt-3'>
                                                    {(() => {
                                                        if (item.gender == 'Female') {
                                                            return (
                                                                <KTSVG
                                                                    path='/media/sempai-images/icons/female.svg'
                                                                    className='svg-icon-5x svg-icon-sempai-green'
                                                                />
                                                            )
                                                        } else if (item.gender == 'Male') {
                                                            return (
                                                                <KTSVG
                                                                    path='/media/sempai-images/icons/male.svg'
                                                                    className='svg-icon-5x svg-icon-sempai-green'
                                                                />
                                                            )
                                                        }
                                                        else if (item.gender == 'Unknown') {
                                                            return (
                                                                <KTSVG
                                                                    path='/media/sempai-images/icons/unknown.svg'
                                                                    className='svg-icon-5x svg-icon-sempai-green'
                                                                />
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <div className='d-flex flex-column mb-3'>
                                                        <div className='text-dark fs-6 fw-bold'>
                                                            {intl.formatMessage({id: 'IMPRESSIONS'})}:
                                                        </div>
                                                        <div
                                                            className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.impressions)}</div>
                                                    </div>
                                                    <div className='d-flex flex-column mb-3'>
                                                        <div className='text-dark fs-6 fw-bold'>
                                                            {intl.formatMessage({id: 'CLICKS'})}:
                                                        </div>
                                                        <div
                                                            className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.clicks)}</div>
                                                    </div>
                                                    <div className='d-flex flex-column mb-3'>
                                                        <div className='text-dark fs-6 fw-bold'>
                                                            {intl.formatMessage({id: 'COST'})}:
                                                        </div>
                                                        <div
                                                            className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.cost).toFixed(2)) + ' zł'}</div>
                                                    </div>
                                                    <div className='d-flex flex-column mb-3'>
                                                        <div className='text-dark fs-6 fw-bold'>
                                                            {intl.formatMessage({id: 'CONVERSIONS'})}:
                                                        </div>
                                                        <div
                                                            className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.conversions)}</div>
                                                    </div>
                                                    <div className='d-flex flex-column mb-3'>
                                                        <div className='text-dark fs-6 fw-bold'>
                                                            {intl.formatMessage({id: 'CONVERSION_VALUE'})}:
                                                        </div>
                                                        <div
                                                            className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.conversion_value).toFixed(4))}</div>
                                                    </div>
                                                    <div className='d-flex flex-column mb-3'>
                                                        <div className='text-dark fs-6 fw-bold'>
                                                            {intl.formatMessage({id: 'CONVERSION_RATE'})}:
                                                        </div>
                                                        <div
                                                            className='text-dark fs-3 fw-boldest'>{item.conversion_rate}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='col-6 social-group-genders'>
                            <div className='border h-100 d-flex flex-column'>
                                <div className='px-5 py-5'>
                                    <h3 className='fw-boldest text-sempai-green text-uppercase mb-0'>
                                        {intl.formatMessage({id: 'DEMOGRAPHY_GENDER'})}
                                    </h3>
                                </div>
                                <div className='d-flex h-100  justify-content-center align-items-center'>
                                    <div className='text-uppercase mb-5 fs-4'>{intl.formatMessage({id: 'NODATA'})}</div>
                                </div>

                            </div>
                        </div>
                    )}



                    {ageReport && ageReport.ageBracket && ageReport.ageBracket.length > 0 ? (
                        <div className='col-6 social-group-age-bracket'>
                            <div className='border h-100'>
                                <div className='px-5 py-5'>
                                    <h3 className='fw-boldest text-sempai-green text-uppercase'>
                                        {intl.formatMessage({id: 'DEMPGRAPHY_AGE'})}
                                    </h3>
                                </div>
                                <div className='' data-testid='age-chart'>
                                    <ChartLineWithColumnWidget className=''
                                                               name_one={intl.formatMessage({id: 'SESSIONS'})}
                                                               name_two={intl.formatMessage({id: 'REVENUE'})}
                                                               chartData={ageReport}
                                                               var_width={'100%'}
                                    />
                                </div>
                            </div>
                        </div>
                    ): (
                        <div className='col-6 social-group-age-bracket'>
                            <div className='border h-100 d-flex flex-column'>
                                <div className='px-5 py-5'>
                                    <h3 className='fw-boldest text-sempai-green text-uppercase mb-0'>
                                        {intl.formatMessage({id: 'DEMOGRAPHY_AGE'})}
                                    </h3>
                                </div>
                                <div className='d-flex h-100  justify-content-center align-items-center'>
                                    <div className='text-uppercase mb-5 fs-4'>{intl.formatMessage({id: 'NODATA'})}</div>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className='row social-group d-flex justify-content-between align-items-stretch flex-column flex-xl-row mb-5'>
                <div className='col-4 social-group-map'>
                    {regionsReport && regionsReport.length > 0 && (
                        <div className='border h-100'>
                            <div className='px-5 py-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase mb-0'>
                                    {intl.formatMessage({id: 'GEOGRAPHY_SESSIONS'})}
                                </h3>
                            </div>
                            <StatisticMap statisticData={regionsReport}/>
                        </div>
                    )}
                </div>
                <div className='col-8 social-group-map'>
                    {worldReport && worldReport.length > 0 && (

                        <div className='border h-100'>
                            <div className='px-5 py-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase mb-0'>
                                    {intl.formatMessage({id: 'GEOGRAPHY_SESSIONS'})}
                                </h3>
                            </div>
                            <StatisticWorldMap statisticData={worldReport}/>
                        </div>
                    )}
                </div>
            </div>


        </>
    )

}


export default BingGeo
