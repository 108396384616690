import React, { useState } from 'react';
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import { IReportDetails } from "../../../models/Reports";

import {BINGReportDetails} from "../../../models/BINGReport";
import CommonPrintView, {ReportType} from "../../Common/CommonPrintView";
import {AllegroReportDetails} from "../../../models/AllegroReport";

interface PDFFbPrint {
    analyticDataMonth?: AllegroReportDetails;
    closePopup: () => void;
}

const AllegroPrintView: React.FC<PDFFbPrint> = ({ closePopup }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails;
    const [allegroAdsLoaded, setAllegroAdsLoaded] = useState(false);

    return (
        <CommonPrintView
            reportType={ReportType.Allegro}
            reportData={reportData}
            setLoaded={setAllegroAdsLoaded}
            closePopup={closePopup}
        >
        </CommonPrintView>
    );
};

export default AllegroPrintView;