import React, {useCallback, useRef} from 'react'
import clsx from 'clsx'
import {FB_TAB_NAME, IReportDetails} from '../../models/Reports'
import DataStatisticTab from './Tabs/DataStatisticTab'
import CommentTab from '../SeoStatistic/Tabs/CommentTab'
import AttachemntsTab from './Tabs/AttachmantsTab'
import {useSelector} from 'react-redux'
import {selectors} from '../../redux/ReportsRedux'
import ReactToPrint from 'react-to-print'
import {KTSVG} from '../../../../../_metronic/helpers'

interface TabsContentProps {
  tab: string
}

const FbTabsContent: React.FC<TabsContentProps> = ({tab}) => {
  const reportData = useSelector(selectors.getReportData) as IReportDetails
  const componentRef = useRef<HTMLDivElement | null>(null)

  // const reactToPrintTrigger = useCallback(() => {
  //   // return (
  //   //   <button className='print-btn btn btn-icon btn-lg me-1'>
  //   //     <KTSVG
  //   //       path='/media/sempai-images/icons/Icon-feather-download.svg'
  //   //       className='svg-icon-1 svg-icon-white'
  //   //     />
  //   //   </button>
  //   // )
  // }, [])

  return (
    <>
      <div className='tab-content border'>
        <div className={clsx('tab-pane', {active: tab === FB_TAB_NAME.COMMENT})}>
          {tab === FB_TAB_NAME.COMMENT && <CommentTab />}
        </div>

        <div id='fb-statistictab' className={clsx('tab-pane d-print-none', {active: tab === FB_TAB_NAME.ANALYSIS})}>
          {/*<ReactToPrint content={() => componentRef.current} trigger={reactToPrintTrigger} />*/}
          <div className='report-to-pdf' ref={componentRef}>
            {tab === FB_TAB_NAME.ANALYSIS && <DataStatisticTab/>}
          </div>
        </div>

        {reportData && reportData.attachments && (
          <div className={clsx('tab-pane', {active: tab === FB_TAB_NAME.ATTACHMENTS})}>
            {tab === FB_TAB_NAME.ATTACHMENTS && <AttachemntsTab />}
          </div>
        )}

      </div>
    </>
  )
}

export default FbTabsContent
