import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {actions} from '../../../redux/SeoReportListsRedux'
import TableWidget from '../../Widgets/TableWidget'
import {selectors as authSelectors} from '../../../../auth'
import {selectors as reportSelectors} from '../../../redux/ReportsRedux'
import {useIntl} from 'react-intl'
import {selectors as seoReportsListSelectors} from '../../../redux/SeoReportListsRedux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import {numSpaceFormater} from "../../../../../helpers/formater";

interface GSCTopListsProps {
  print?: boolean
}

const GSCTopLists: React.FC<GSCTopListsProps> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {reportsLoading, reportsError, reportId} = useSelector(reportSelectors.getReportsState)
  const companyId = useSelector(authSelectors.getCompanyId)
  const [phrasesPage, setPhrasesPage] = useState<number>(1)
  const [pagesPage, setPagesPage] = useState<number>(1)
  const {
    topPhrasesList,
    topPhrasesTotalPages,
    topPhrasesLoading,
    topPhrasesError,
    topPagesList,
    topPagesTotalPages,
    topPagesError,
    topPagesLoading,
  } = useSelector(seoReportsListSelectors.getReportListsState)
  const [shouldDisplayPhrases, setShouldDisplayPhrases] = useState<boolean>(false)
  const [shouldDisplayPages, setShouldDisplayPages] = useState<boolean>(false)

  useEffect(() => {
    if (reportId && companyId)
      dispatch(actions.requestTopPhrasesList(reportId, companyId, phrasesPage))
  }, [dispatch, reportId, companyId, phrasesPage])

  useEffect(() => {
    if (reportId && companyId)
      dispatch(actions.requestTopPagesList(reportId, companyId, pagesPage));
  }, [dispatch, reportId, companyId, pagesPage]);

  useEffect(() => {
    if(topPhrasesList && topPhrasesList.length > 0) {
      setShouldDisplayPhrases(true);
    }
    if(topPagesList && topPagesList.length > 0) {
      setShouldDisplayPages(true);
    }
  }, [topPagesList, topPhrasesList]);

  return !reportsError ? (
    <div className={reportsLoading ? 'overlay overlay-block rounded' : ''}>
      {!reportsLoading ? (
        <div className='row'>
          {shouldDisplayPhrases &&
          <div className='col-12 mb-5'>
            <div className={props.print ? '' : 'border'}>
              <div className='row'>
                <div className='px-5 py-5 pb-0'>
                  <h3 className='fs-3 fw-boldest text-sempai-green'>TOP frazy</h3>
                </div>
              </div>
              <div className='card-body bg-white px-0 py-0 pb-0'>
                {topPhrasesList &&
                    topPhrasesList.length > 0 &&
                    topPhrasesTotalPages &&
                    !topPhrasesError && (
                        <div className={topPhrasesLoading ? 'overlay overlay-block rounded' : ''}>
                          {!topPhrasesLoading ? (
                              <TableWidget
                                  title=''
                                  pagination={props.print ? false : true}
                                  pages={topPhrasesTotalPages}
                                  currentPage={phrasesPage}
                                  setCurrentPage={setPhrasesPage}
                                  paginationPosition={'end'}
                              >
                                <>
                                  <thead>
                                  <tr className='fw-boldest text-dark fs-6'>
                                    <th className='min-w-100px'>Fraza</th>
                                    <th className='min-w-50px text-end crop-text'>
                                      {intl.formatMessage({id: 'AVG_POSITION'})}
                                    </th>
                                    <th className='min-w-50px text-end'>
                                      {intl.formatMessage({id: 'CLICKS'})}
                                    </th>
                                    <th className='min-w-50px text-end'>
                                      {intl.formatMessage({id: 'VIEWS'})}
                                    </th>
                                    <th className='min-w-25px text-end'>CTR</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {topPhrasesList.map((item, index) => (
                                      <tr key={index}>
                                        <td className='crop-text mw-150px fs-8 fw-bolder'>
                                          {item.query_phrase}
                                        </td>
                                        <td className='text-end fs-8 fw-bolder'>
                                          {Number(item.position).toFixed(1)}
                                        </td>
                                        <td className='text-end fs-8 fw-bolder'>{numSpaceFormater(item.url_clicks)}</td>
                                        <td className='text-end fs-8 fw-bolder'>{numSpaceFormater(item.impressions)}</td>
                                        <td className='text-end fs-8 fw-bolder'>
                                          {Number(item.ctr * 100).toFixed(1)}%
                                        </td>
                                      </tr>
                                  ))}
                                  </tbody>
                                </>
                              </TableWidget>
                          ) : (
                              <div style={{height: '450px'}}>
                                <Spinner color='white'/>
                              </div>
                          )}
                        </div>
                    )}
              </div>
            </div>
          </div>}
          {shouldDisplayPages &&
          <div className='col-12'>
            <div className={props.print ? '' : 'border'}>
              <div className='row'>
                <div className='px-5 py-5 pb-0'>
                  <h3 className='fs-3 fw-boldest text-sempai-green'>TOP podstrony</h3>
                </div>
              </div>
              <div className='card-body bg-white px-0 py-0 pb-0'>
                {topPagesList && topPagesList.length > 0 && topPagesTotalPages && !topPagesError && (
                    <div className={topPagesLoading ? 'overlay overlay-block rounded' : ''}>
                      {!topPagesLoading ? (
                          <TableWidget
                              title=''
                              pagination={props.print ? false : true}
                              pages={topPagesTotalPages}
                              currentPage={pagesPage}
                              setCurrentPage={setPagesPage}
                              paginationPosition={'end'}
                          >
                            <>
                              <thead>
                              <tr className='fw-boldest text-dark fs-6'>
                                <th className='min-w-100px'>Fraza</th>
                                <th className='min-w-50px text-end'>
                                  {intl.formatMessage({id: 'CLICKS'})}
                                </th>
                                <th className='min-w-50px text-end'>
                                  {intl.formatMessage({id: 'VIEWS'})}
                                </th>
                                <th className='min-w-50px text-end'>CTR</th>
                              </tr>
                              </thead>
                              <tbody>
                              {topPagesList.map((item, index) => (
                                  <tr key={index}>
                                    <td className='crop-text mw-225px fs-8 fw-bolder'>
                                      {props.print ? item.url : <a href={item.url}>{item.url}</a>}
                                    </td>
                                    <td className='text-end fs-8 fw-bolder'>{numSpaceFormater(item.url_clicks)}</td>
                                    <td className='text-end fs-8 fw-bolder'>{numSpaceFormater(item.impressions)}</td>
                                    <td className='text-end fs-8 fw-bolder'>{Number(item.ctr * 100).toFixed(1)}%</td>
                                  </tr>
                              ))}
                              </tbody>
                            </>
                          </TableWidget>
                      ) : (
                          <div style={{height: '450px'}}>
                            <Spinner color='white'/>
                          </div>
                      )}
                    </div>
                )}
              </div>
            </div>
          </div>}
        </div>
      ) : (
        <div style={{height: '350px'}}>
          <Spinner />
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default GSCTopLists
