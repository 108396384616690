import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TAB_NAME, IReportDetails } from '../../models/Reports';
import { enableSplashScreen } from '../../../../../_metronic/layout/core';
import CommentTab from '../SeoStatistic/Tabs/CommentTab';
import { printPDF } from '../Print/Functions/PdfUtils';
import TiktokStatisticTab from "../TiktokStatistic/Tabs/TiktokStatisticTab";
import BingDataStatisticTab from "../BingStatistic/Tabs/BingDataStatisticTab";
import DataStatisticTab from "../FbStatistic/Tabs/DataStatisticTab";
import AllegroDataStatisticTab from "../AllegroStatistic/Tabs/AllegroDataStatisticTab";

export enum ReportType {
    TikTok = 'tiktok',
    Bing = 'bing',
    Facebook = 'fb',
    Allegro= 'allegro'
}

interface CommonPrintViewProps {
    reportType: ReportType;
    reportData: IReportDetails;
    setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    closePopup: () => void;
}

const CommonPrintView: React.FC<CommonPrintViewProps> = ({ reportType, reportData,
                                                             setLoaded,
                                                             closePopup
                                                         }) => {
    const intl = useIntl();
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        enableSplashScreen();
        if (dataLoaded) {
            setTimeout(() => {
                printPDF(document.getElementById('reportPrint'), reportData, closePopup);
            }, 5000);
        }
    }, [dataLoaded, reportData, closePopup]);

    return (
        <>
            <div id="print_overlay" className="print_overlay"></div>
            <div className="popup-container">
                <div className="popup-body py-5 px-10">
                    <div id="reportPrint">
                        <div className="sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5">
                            {reportType === ReportType.TikTok && (
                                <TiktokStatisticTab isPdf={true} onLoad={() => setDataLoaded(true)} />
                            )}
                            {reportType === ReportType.Bing && (
                                <BingDataStatisticTab isPdf={true} onLoad={() => setDataLoaded(true)} />
                            )}
                            {reportType === ReportType.Facebook && (
                                <DataStatisticTab isPdf={true} onLoad={() => setDataLoaded(true)} />
                            )}
                            {reportType === ReportType.Allegro && (
                                <AllegroDataStatisticTab isPdf={true} onLoad={() => setDataLoaded(true)} />
                            )}
                        </div>
                        <div className="sem-basic-data card-body px-5 py-5 pb-0 mb-5 text-left">
                            <h3 className="fw-bolder text-dark px-5">{intl.formatMessage({ id: TAB_NAME.COMMENT })}</h3>
                            <CommentTab />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommonPrintView;
