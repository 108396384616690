var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-31.2089, 39.5235],
              [-31.1527, 39.4964],
              [-31.1267, 39.4479],
              [-31.1684, 39.3767],
              [-31.2559, 39.3829],
              [-31.2572, 39.4882],
              [-31.2089, 39.5235],
            ],
          ],
          [
            [
              [-28.0305, 39.0964],
              [-27.9895, 39.08],
              [-27.9492, 39.0181],
              [-28.0365, 39.0227],
              [-28.0721, 39.0709],
              [-28.0305, 39.0964],
            ],
          ],
          [
            [
              [-27.2591, 38.8031],
              [-27.2012, 38.8007],
              [-27.063, 38.761],
              [-27.0782, 38.6436],
              [-27.1704, 38.6546],
              [-27.278, 38.6524],
              [-27.3522, 38.6868],
              [-27.3805, 38.747],
              [-27.3633, 38.7814],
              [-27.2591, 38.8031],
            ],
          ],
          [
            [
              [-28.3178, 38.756],
              [-27.9926, 38.6576],
              [-27.7792, 38.5652],
              [-27.8301, 38.5338],
              [-27.9438, 38.5896],
              [-28.0257, 38.6015],
              [-28.1919, 38.6671],
              [-28.3178, 38.756],
            ],
          ],
          [
            [
              [-28.7151, 38.6438],
              [-28.5993, 38.5941],
              [-28.6251, 38.5229],
              [-28.7268, 38.5181],
              [-28.7618, 38.5627],
              [-28.8167, 38.5815],
              [-28.7151, 38.6438],
            ],
          ],
          [
            [
              [-28.4155, 38.5614],
              [-28.2441, 38.4995],
              [-28.1924, 38.4626],
              [-28.0581, 38.443],
              [-28.0949, 38.3998],
              [-28.1786, 38.4096],
              [-28.241, 38.3822],
              [-28.2736, 38.4126],
              [-28.4459, 38.4203],
              [-28.5179, 38.4523],
              [-28.536, 38.534],
              [-28.478, 38.5606],
              [-28.4155, 38.5614],
            ],
          ],
          [
            [
              [-25.4387, 37.8253],
              [-25.3294, 37.8526],
              [-25.1944, 37.8587],
              [-25.1494, 37.8494],
              [-25.1346, 37.8067],
              [-25.1503, 37.7573],
              [-25.2179, 37.7358],
              [-25.2679, 37.7449],
              [-25.3684, 37.7127],
              [-25.4828, 37.7176],
              [-25.5101, 37.7055],
              [-25.5673, 37.7426],
              [-25.6182, 37.7493],
              [-25.6864, 37.7317],
              [-25.841, 37.827],
              [-25.8552, 37.8587],
              [-25.7817, 37.9097],
              [-25.7193, 37.8864],
              [-25.6934, 37.8437],
              [-25.5867, 37.8129],
              [-25.4883, 37.8467],
              [-25.4387, 37.8253],
            ],
          ],
          [
            [
              [-25.0891, 37.0156],
              [-25.0156, 36.9586],
              [-25.1728, 36.9505],
              [-25.1707, 37.0032],
              [-25.0891, 37.0156],
            ],
          ],
        ],
      },
      properties: {
        name: 'Azores',
        id: 'PT-20',
        CNTRY: 'Portugal',
        TYPE: 'Região Autónoma',
        TYPE_ENG: 'Autonomous Region',
      },
      id: 'PT-20',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-17.1656, 32.8684],
            [-17.1253, 32.8274],
            [-17.0517, 32.8083],
            [-16.9898, 32.8302],
            [-16.9039, 32.8375],
            [-16.8225, 32.7679],
            [-16.7461, 32.7345],
            [-16.7736, 32.6907],
            [-16.85, 32.6361],
            [-16.9465, 32.6329],
            [-17.0167, 32.6522],
            [-17.2127, 32.7368],
            [-17.2659, 32.8148],
            [-17.1997, 32.8685],
            [-17.1656, 32.8684],
          ],
        ],
      },
      properties: {
        name: 'Madeira',
        id: 'PT-30',
        CNTRY: 'Portugal',
        TYPE: 'Região Autónoma',
        TYPE_ENG: 'Autonomous Region',
      },
      id: 'PT-30',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.6449, 41.016],
            [-8.5572, 41.0156],
            [-8.4862, 41.0527],
            [-8.3833, 41.023],
            [-8.2941, 41.0801],
            [-8.264, 41.0659],
            [-8.2347, 41.0118],
            [-8.1334, 40.9786],
            [-8.1279, 40.8981],
            [-8.2369, 40.8254],
            [-8.2727, 40.7569],
            [-8.3324, 40.7398],
            [-8.2971, 40.6931],
            [-8.3084, 40.5964],
            [-8.2849, 40.5391],
            [-8.2501, 40.5111],
            [-8.3356, 40.486],
            [-8.3176, 40.4412],
            [-8.3549, 40.4089],
            [-8.3562, 40.3672],
            [-8.3753, 40.3413],
            [-8.485, 40.3153],
            [-8.4813, 40.3857],
            [-8.5048, 40.4223],
            [-8.5724, 40.4003],
            [-8.5748, 40.4648],
            [-8.6148, 40.4856],
            [-8.6627, 40.4285],
            [-8.784, 40.5204],
            [-8.752, 40.6222],
            [-8.7635, 40.6435],
            [-8.6818, 40.8504],
            [-8.6449, 41.016],
          ],
        ],
      },
      properties: {
        name: 'Aveiro',
        id: 'PT-01',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-01',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.1644, 38.3198],
            [-8.0551, 38.3283],
            [-7.9814, 38.3181],
            [-7.8391, 38.2517],
            [-7.7591, 38.2465],
            [-7.6696, 38.2002],
            [-7.4979, 38.1885],
            [-7.3969, 38.2901],
            [-7.2873, 38.3311],
            [-7.2442, 38.2631],
            [-7.1991, 38.2241],
            [-7.1596, 38.1599],
            [-7.1078, 38.188],
            [-7.0845, 38.1692],
            [-7.0112, 38.2045],
            [-6.9338, 38.2019],
            [-6.9878, 38.102],
            [-7.002, 38.0224],
            [-7.0468, 38.0143],
            [-7.0987, 38.0438],
            [-7.1207, 38.0046],
            [-7.2023, 38.0034],
            [-7.2625, 37.9799],
            [-7.2551, 37.9227],
            [-7.3243, 37.8158],
            [-7.4214, 37.759],
            [-7.4436, 37.6646],
            [-7.5038, 37.6048],
            [-7.5125, 37.5263],
            [-7.5741, 37.5291],
            [-7.6183, 37.4919],
            [-7.6754, 37.506],
            [-7.7633, 37.479],
            [-7.9078, 37.4043],
            [-7.9822, 37.4069],
            [-8.0657, 37.319],
            [-8.1884, 37.3416],
            [-8.2776, 37.425],
            [-8.3199, 37.4386],
            [-8.418, 37.4193],
            [-8.451, 37.3765],
            [-8.5151, 37.3787],
            [-8.57, 37.4112],
            [-8.7334, 37.3952],
            [-8.7497, 37.4319],
            [-8.7963, 37.443],
            [-8.7857, 37.5328],
            [-8.8195, 37.5993],
            [-8.786, 37.714],
            [-8.8125, 37.7489],
            [-8.793, 37.8196],
            [-8.7355, 37.8148],
            [-8.6834, 37.7533],
            [-8.5871, 37.7568],
            [-8.5838, 37.7994],
            [-8.5375, 37.8534],
            [-8.4347, 37.8709],
            [-8.3308, 37.847],
            [-8.2936, 37.9933],
            [-8.3639, 38.022],
            [-8.4097, 38.0728],
            [-8.3556, 38.1384],
            [-8.2912, 38.1391],
            [-8.2843, 38.1866],
            [-8.1298, 38.224],
            [-8.1297, 38.2663],
            [-8.1644, 38.3198],
          ],
        ],
      },
      properties: {
        name: 'Beja',
        id: 'PT-02',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-02',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.0512, 41.8195],
            [-8.0882, 41.7127],
            [-7.9891, 41.6849],
            [-7.9972, 41.6035],
            [-7.8107, 41.5733],
            [-7.837, 41.5365],
            [-7.938, 41.4778],
            [-7.9412, 41.4307],
            [-7.9742, 41.3752],
            [-8.031, 41.3189],
            [-8.1464, 41.3603],
            [-8.1658, 41.4003],
            [-8.2134, 41.4118],
            [-8.3164, 41.3513],
            [-8.4556, 41.3756],
            [-8.5184, 41.3457],
            [-8.6106, 41.3411],
            [-8.6501, 41.4197],
            [-8.7751, 41.4712],
            [-8.8099, 41.6121],
            [-8.7568, 41.6144],
            [-8.6433, 41.6544],
            [-8.6041, 41.6343],
            [-8.5217, 41.6796],
            [-8.5186, 41.7259],
            [-8.4243, 41.7574],
            [-8.2716, 41.7712],
            [-8.165, 41.8181],
            [-8.0512, 41.8195],
          ],
        ],
      },
      properties: {
        name: 'Braga',
        id: 'PT-03',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-03',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.9313, 41.0294],
            [-7.0435, 41.0443],
            [-7.1017, 41.0803],
            [-7.0838, 41.126],
            [-7.1146, 41.1508],
            [-7.1639, 41.1261],
            [-7.2495, 41.145],
            [-7.3045, 41.1395],
            [-7.3575, 41.1479],
            [-7.3905, 41.1953],
            [-7.4319, 41.2108],
            [-7.3914, 41.2858],
            [-7.3621, 41.3041],
            [-7.3526, 41.3766],
            [-7.3653, 41.4404],
            [-7.2983, 41.4646],
            [-7.284, 41.5156],
            [-7.2291, 41.5228],
            [-7.2587, 41.5914],
            [-7.2087, 41.7121],
            [-7.172, 41.7448],
            [-7.2103, 41.8138],
            [-7.2005, 41.8795],
            [-7.1735, 41.9191],
            [-7.1854, 41.9723],
            [-7.1414, 41.9907],
            [-7.0719, 41.9497],
            [-6.8216, 41.946],
            [-6.7686, 41.9841],
            [-6.7265, 41.9371],
            [-6.5494, 41.9445],
            [-6.5712, 41.8835],
            [-6.5155, 41.8716],
            [-6.556, 41.7375],
            [-6.5417, 41.6805],
            [-6.4996, 41.6571],
            [-6.4443, 41.6848],
            [-6.3023, 41.6618],
            [-6.1891, 41.5748],
            [-6.3053, 41.4497],
            [-6.3525, 41.3783],
            [-6.4153, 41.3479],
            [-6.4376, 41.3053],
            [-6.5226, 41.2756],
            [-6.5713, 41.239],
            [-6.6484, 41.2475],
            [-6.7091, 41.1726],
            [-6.7679, 41.1348],
            [-6.7542, 41.1041],
            [-6.8089, 41.0365],
            [-6.9313, 41.0294],
          ],
        ],
      },
      properties: {
        name: 'Bragança',
        id: 'PT-04',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-04',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.7824, 40.2299],
            [-7.656, 40.2736],
            [-7.6187, 40.2997],
            [-7.5372, 40.3178],
            [-7.5151, 40.3688],
            [-7.4616, 40.4018],
            [-7.3945, 40.3807],
            [-7.3276, 40.4108],
            [-7.2704, 40.4081],
            [-7.2736, 40.3475],
            [-7.3191, 40.2807],
            [-7.2402, 40.2585],
            [-7.2126, 40.286],
            [-7.1614, 40.2686],
            [-7.1119, 40.3246],
            [-7.0838, 40.2786],
            [-7.0141, 40.2902],
            [-6.9514, 40.2576],
            [-7.0127, 40.2254],
            [-7.03, 40.1864],
            [-6.993, 40.1125],
            [-6.9409, 40.1125],
            [-6.8638, 40.012],
            [-6.9066, 39.9238],
            [-6.9036, 39.8707],
            [-6.9872, 39.81],
            [-6.9768, 39.7724],
            [-7.0155, 39.6704],
            [-7.1514, 39.6531],
            [-7.2515, 39.6668],
            [-7.3337, 39.6408],
            [-7.4555, 39.6623],
            [-7.5417, 39.6637],
            [-7.6374, 39.6227],
            [-7.7035, 39.6365],
            [-7.7224, 39.5931],
            [-7.8251, 39.5374],
            [-7.8104, 39.5705],
            [-7.8644, 39.6382],
            [-7.9147, 39.6684],
            [-7.9577, 39.6614],
            [-7.9701, 39.7454],
            [-8.0547, 39.7357],
            [-8.0552, 39.6173],
            [-8.1346, 39.6113],
            [-8.2362, 39.645],
            [-8.2289, 39.7531],
            [-8.285, 39.7949],
            [-8.2929, 39.8087],
            [-8.2753, 39.8423],
            [-8.1492, 39.8853],
            [-8.1187, 39.9341],
            [-8.0331, 39.9607],
            [-7.8642, 40.0581],
            [-7.7733, 40.064],
            [-7.7364, 40.1244],
            [-7.809, 40.1818],
            [-7.7824, 40.2299],
          ],
        ],
      },
      properties: {
        name: 'Castelo Branco',
        id: 'PT-05',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-05',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.784, 40.5204],
            [-8.6627, 40.4285],
            [-8.6148, 40.4856],
            [-8.5748, 40.4648],
            [-8.5724, 40.4003],
            [-8.5048, 40.4223],
            [-8.4813, 40.3857],
            [-8.485, 40.3153],
            [-8.3753, 40.3413],
            [-8.3562, 40.3672],
            [-8.2997, 40.3582],
            [-8.2509, 40.3232],
            [-8.193, 40.3433],
            [-8.1342, 40.3331],
            [-8.0426, 40.3855],
            [-7.9739, 40.4052],
            [-7.8842, 40.4732],
            [-7.8283, 40.4985],
            [-7.7969, 40.4574],
            [-7.8044, 40.3076],
            [-7.8258, 40.2658],
            [-7.7824, 40.2299],
            [-7.809, 40.1818],
            [-7.7364, 40.1244],
            [-7.7733, 40.064],
            [-7.8642, 40.0581],
            [-8.0331, 39.9607],
            [-8.1187, 39.9341],
            [-8.11, 39.9715],
            [-8.156, 40.022],
            [-8.1437, 40.0728],
            [-8.2758, 40.0454],
            [-8.3138, 39.9887],
            [-8.3799, 39.9554],
            [-8.426, 40.0266],
            [-8.5222, 39.9845],
            [-8.5617, 40.028],
            [-8.615, 39.9923],
            [-8.6755, 40.0301],
            [-8.7665, 40.0409],
            [-8.8005, 40.0126],
            [-8.894, 40.0443],
            [-8.8618, 40.1376],
            [-8.9096, 40.1876],
            [-8.8447, 40.3307],
            [-8.784, 40.5204],
          ],
        ],
      },
      properties: {
        name: 'Coimbra',
        id: 'PT-06',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-06',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.2279, 38.9874],
            [-8.152, 39.0269],
            [-8.0705, 39.0156],
            [-7.987, 38.9442],
            [-7.9248, 38.9747],
            [-7.8759, 38.9083],
            [-7.7899, 38.8958],
            [-7.7329, 38.9392],
            [-7.6377, 38.9115],
            [-7.6043, 38.9538],
            [-7.5646, 38.9524],
            [-7.5126, 38.986],
            [-7.4529, 38.9644],
            [-7.4661, 38.926],
            [-7.4229, 38.9035],
            [-7.3916, 38.8014],
            [-7.3282, 38.8039],
            [-7.2449, 38.8478],
            [-7.2074, 38.8311],
            [-7.2325, 38.7827],
            [-7.2037, 38.7515],
            [-7.2598, 38.7235],
            [-7.2725, 38.6384],
            [-7.2436, 38.624],
            [-7.3146, 38.5139],
            [-7.3171, 38.4403],
            [-7.1697, 38.2793],
            [-7.1506, 38.2758],
            [-7.1078, 38.188],
            [-7.1596, 38.1599],
            [-7.1991, 38.2241],
            [-7.2442, 38.2631],
            [-7.2873, 38.3311],
            [-7.3969, 38.2901],
            [-7.4979, 38.1885],
            [-7.6696, 38.2002],
            [-7.7591, 38.2465],
            [-7.8391, 38.2517],
            [-7.9814, 38.3181],
            [-8.0551, 38.3283],
            [-8.1644, 38.3198],
            [-8.1977, 38.3426],
            [-8.3226, 38.3722],
            [-8.2837, 38.4121],
            [-8.3691, 38.5457],
            [-8.4759, 38.5168],
            [-8.5752, 38.5479],
            [-8.6391, 38.5495],
            [-8.6535, 38.6123],
            [-8.501, 38.7155],
            [-8.491, 38.7613],
            [-8.5468, 38.7634],
            [-8.5004, 38.8075],
            [-8.3775, 38.8564],
            [-8.3011, 38.8679],
            [-8.2225, 38.8296],
            [-8.1587, 38.8664],
            [-8.2722, 38.9074],
            [-8.2279, 38.9874],
          ],
        ],
      },
      properties: {
        name: 'Évora',
        id: 'PT-07',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-07',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.7963, 37.443],
            [-8.7497, 37.4319],
            [-8.7334, 37.3952],
            [-8.57, 37.4112],
            [-8.5151, 37.3787],
            [-8.451, 37.3765],
            [-8.418, 37.4193],
            [-8.3199, 37.4386],
            [-8.2776, 37.425],
            [-8.1884, 37.3416],
            [-8.0657, 37.319],
            [-7.9822, 37.4069],
            [-7.9078, 37.4043],
            [-7.7633, 37.479],
            [-7.6754, 37.506],
            [-7.6183, 37.4919],
            [-7.5741, 37.5291],
            [-7.5125, 37.5263],
            [-7.4922, 37.5222],
            [-7.4455, 37.4194],
            [-7.4422, 37.3113],
            [-7.4217, 37.2738],
            [-7.4074, 37.1718],
            [-7.4593, 37.1774],
            [-7.5607, 37.1518],
            [-7.794, 37.0151],
            [-7.8263, 37.0274],
            [-7.8843, 36.9813],
            [-7.9407, 37.0099],
            [-7.9829, 37.0035],
            [-8.1108, 37.07],
            [-8.1828, 37.0904],
            [-8.298, 37.0721],
            [-8.3656, 37.1015],
            [-8.4367, 37.0853],
            [-8.5354, 37.1177],
            [-8.6163, 37.1229],
            [-8.7882, 37.0628],
            [-8.8945, 37.0424],
            [-8.948, 36.9935],
            [-8.9969, 37.0224],
            [-8.9034, 37.1654],
            [-8.8602, 37.269],
            [-8.878, 37.3211],
            [-8.8447, 37.352],
            [-8.7963, 37.443],
          ],
        ],
      },
      properties: {
        name: 'Faro',
        id: 'PT-08',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-08',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.3045, 41.1395],
            [-7.2495, 41.145],
            [-7.1639, 41.1261],
            [-7.1146, 41.1508],
            [-7.0838, 41.126],
            [-7.1017, 41.0803],
            [-7.0435, 41.0443],
            [-6.9313, 41.0294],
            [-6.8591, 40.9497],
            [-6.8491, 40.9028],
            [-6.809, 40.8824],
            [-6.8269, 40.8403],
            [-6.8312, 40.7528],
            [-6.794, 40.6613],
            [-6.8454, 40.566],
            [-6.8009, 40.5503],
            [-6.7953, 40.5113],
            [-6.8497, 40.4515],
            [-6.8344, 40.4077],
            [-6.781, 40.3646],
            [-6.7941, 40.3284],
            [-6.8614, 40.2996],
            [-6.8709, 40.2625],
            [-6.9514, 40.2576],
            [-7.0141, 40.2902],
            [-7.0838, 40.2786],
            [-7.1119, 40.3246],
            [-7.1614, 40.2686],
            [-7.2126, 40.286],
            [-7.2402, 40.2585],
            [-7.3191, 40.2807],
            [-7.2736, 40.3475],
            [-7.2704, 40.4081],
            [-7.3276, 40.4108],
            [-7.3945, 40.3807],
            [-7.4616, 40.4018],
            [-7.5151, 40.3688],
            [-7.5372, 40.3178],
            [-7.6187, 40.2997],
            [-7.656, 40.2736],
            [-7.7824, 40.2299],
            [-7.8258, 40.2658],
            [-7.8044, 40.3076],
            [-7.7969, 40.4574],
            [-7.8283, 40.4985],
            [-7.5891, 40.5856],
            [-7.5351, 40.6903],
            [-7.591, 40.7138],
            [-7.5709, 40.7491],
            [-7.6281, 40.7815],
            [-7.586, 40.8526],
            [-7.5141, 40.8693],
            [-7.4536, 40.8124],
            [-7.3253, 40.972],
            [-7.3457, 41.0464],
            [-7.3045, 41.1395],
          ],
        ],
      },
      properties: {
        name: 'Guarda',
        id: 'PT-09',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-09',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.9945, 39.2967],
            [-9.0394, 39.3103],
            [-9.1085, 39.2761],
            [-9.1428, 39.2294],
            [-9.2024, 39.2477],
            [-9.2005, 39.3023],
            [-9.2969, 39.2834],
            [-9.3412, 39.2919],
            [-9.3654, 39.349],
            [-9.2279, 39.4363],
            [-9.1718, 39.501],
            [-9.0762, 39.5879],
            [-9.0868, 39.6043],
            [-9.0507, 39.7201],
            [-8.894, 40.0443],
            [-8.8005, 40.0126],
            [-8.7665, 40.0409],
            [-8.6755, 40.0301],
            [-8.615, 39.9923],
            [-8.5617, 40.028],
            [-8.5222, 39.9845],
            [-8.426, 40.0266],
            [-8.3799, 39.9554],
            [-8.3138, 39.9887],
            [-8.2758, 40.0454],
            [-8.1437, 40.0728],
            [-8.156, 40.022],
            [-8.11, 39.9715],
            [-8.1187, 39.9341],
            [-8.1492, 39.8853],
            [-8.2753, 39.8423],
            [-8.2929, 39.8087],
            [-8.285, 39.7949],
            [-8.4039, 39.7603],
            [-8.4526, 39.731],
            [-8.4771, 39.8382],
            [-8.5926, 39.777],
            [-8.6763, 39.7739],
            [-8.6792, 39.7089],
            [-8.6495, 39.6606],
            [-8.7022, 39.6381],
            [-8.6711, 39.5635],
            [-8.7293, 39.4801],
            [-8.8024, 39.483],
            [-8.9034, 39.466],
            [-8.9457, 39.391],
            [-8.9909, 39.3767],
            [-8.9945, 39.2967],
          ],
        ],
      },
      properties: {
        name: 'Leiria',
        id: 'PT-10',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-10',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-9.3412, 39.2919],
            [-9.2969, 39.2834],
            [-9.2005, 39.3023],
            [-9.2024, 39.2477],
            [-9.1428, 39.2294],
            [-9.1085, 39.2761],
            [-9.0394, 39.3103],
            [-8.9945, 39.2967],
            [-8.9627, 39.2555],
            [-8.8328, 39.2232],
            [-8.8265, 39.186],
            [-8.9131, 39.172],
            [-8.8675, 39.1283],
            [-8.7981, 39.0963],
            [-8.7819, 39.0584],
            [-8.8416, 39.017],
            [-8.8914, 38.9445],
            [-8.9114, 38.8883],
            [-8.9691, 38.8265],
            [-8.9785, 38.8674],
            [-9.0201, 38.8885],
            [-9.0851, 38.8202],
            [-9.1032, 38.7326],
            [-9.1449, 38.705],
            [-9.2159, 38.6914],
            [-9.2809, 38.6987],
            [-9.3238, 38.6731],
            [-9.3922, 38.702],
            [-9.4862, 38.7082],
            [-9.4719, 38.75],
            [-9.5005, 38.7807],
            [-9.4182, 38.9426],
            [-9.422, 39.0747],
            [-9.3457, 39.2032],
            [-9.3412, 39.2919],
          ],
        ],
      },
      properties: {
        name: 'Lisboa',
        id: 'PT-11',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-11',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.2037, 38.7515],
            [-7.2325, 38.7827],
            [-7.2074, 38.8311],
            [-7.2449, 38.8478],
            [-7.3282, 38.8039],
            [-7.3916, 38.8014],
            [-7.4229, 38.9035],
            [-7.4661, 38.926],
            [-7.4529, 38.9644],
            [-7.5126, 38.986],
            [-7.5646, 38.9524],
            [-7.6043, 38.9538],
            [-7.6377, 38.9115],
            [-7.7329, 38.9392],
            [-7.7899, 38.8958],
            [-7.8759, 38.9083],
            [-7.9248, 38.9747],
            [-7.987, 38.9442],
            [-8.0705, 39.0156],
            [-8.152, 39.0269],
            [-8.2279, 38.9874],
            [-8.2909, 39.0804],
            [-8.3435, 39.1099],
            [-8.2983, 39.1631],
            [-8.2383, 39.1812],
            [-8.1511, 39.2499],
            [-8.1354, 39.2864],
            [-8.0786, 39.2779],
            [-8.0265, 39.3415],
            [-7.9395, 39.41],
            [-8.0089, 39.4732],
            [-7.9555, 39.5645],
            [-7.8514, 39.5108],
            [-7.8251, 39.5374],
            [-7.7224, 39.5931],
            [-7.7035, 39.6365],
            [-7.6374, 39.6227],
            [-7.5417, 39.6637],
            [-7.4994, 39.5896],
            [-7.3819, 39.4912],
            [-7.2943, 39.4568],
            [-7.3228, 39.3813],
            [-7.3075, 39.3361],
            [-7.2315, 39.2782],
            [-7.2415, 39.2082],
            [-7.1347, 39.1712],
            [-7.144, 39.1086],
            [-7.1053, 39.0992],
            [-7.0326, 39.1173],
            [-6.9809, 39.0878],
            [-6.9512, 39.0236],
            [-7.0522, 38.907],
            [-7.033, 38.8788],
            [-7.0964, 38.816],
            [-7.1403, 38.8059],
            [-7.2037, 38.7515],
          ],
        ],
      },
      properties: {
        name: 'Portalegre',
        id: 'PT-12',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-12',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.7751, 41.4712],
            [-8.6501, 41.4197],
            [-8.6106, 41.3411],
            [-8.5184, 41.3457],
            [-8.4556, 41.3756],
            [-8.3164, 41.3513],
            [-8.2134, 41.4118],
            [-8.1658, 41.4003],
            [-8.1464, 41.3603],
            [-8.031, 41.3189],
            [-7.9742, 41.3752],
            [-7.964, 41.3296],
            [-7.9076, 41.3027],
            [-7.8756, 41.242],
            [-7.9118, 41.1199],
            [-7.9516, 41.1227],
            [-8.0528, 41.0897],
            [-8.2045, 41.0941],
            [-8.264, 41.0659],
            [-8.2941, 41.0801],
            [-8.3833, 41.023],
            [-8.4862, 41.0527],
            [-8.5572, 41.0156],
            [-8.6449, 41.016],
            [-8.6682, 41.1396],
            [-8.7171, 41.204],
            [-8.7393, 41.3324],
            [-8.7868, 41.4151],
            [-8.7751, 41.4712],
          ],
        ],
      },
      properties: {
        name: 'Porto',
        id: 'PT-13',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-13',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.9945, 39.2967],
            [-8.9909, 39.3767],
            [-8.9457, 39.391],
            [-8.9034, 39.466],
            [-8.8024, 39.483],
            [-8.7293, 39.4801],
            [-8.6711, 39.5635],
            [-8.7022, 39.6381],
            [-8.6495, 39.6606],
            [-8.6792, 39.7089],
            [-8.6763, 39.7739],
            [-8.5926, 39.777],
            [-8.4771, 39.8382],
            [-8.4526, 39.731],
            [-8.4039, 39.7603],
            [-8.285, 39.7949],
            [-8.2289, 39.7531],
            [-8.2362, 39.645],
            [-8.1346, 39.6113],
            [-8.0552, 39.6173],
            [-8.0547, 39.7357],
            [-7.9701, 39.7454],
            [-7.9577, 39.6614],
            [-7.9147, 39.6684],
            [-7.8644, 39.6382],
            [-7.8104, 39.5705],
            [-7.8251, 39.5374],
            [-7.8514, 39.5108],
            [-7.9555, 39.5645],
            [-8.0089, 39.4732],
            [-7.9395, 39.41],
            [-8.0265, 39.3415],
            [-8.0786, 39.2779],
            [-8.1354, 39.2864],
            [-8.1511, 39.2499],
            [-8.2383, 39.1812],
            [-8.2983, 39.1631],
            [-8.3435, 39.1099],
            [-8.2909, 39.0804],
            [-8.2279, 38.9874],
            [-8.2722, 38.9074],
            [-8.1587, 38.8664],
            [-8.2225, 38.8296],
            [-8.3011, 38.8679],
            [-8.3775, 38.8564],
            [-8.5004, 38.8075],
            [-8.5468, 38.7634],
            [-8.6131, 38.7831],
            [-8.708, 38.8454],
            [-8.7536, 38.7428],
            [-8.8389, 38.7618],
            [-8.9075, 38.7363],
            [-8.926, 38.7599],
            [-8.9691, 38.8265],
            [-8.9114, 38.8883],
            [-8.8914, 38.9445],
            [-8.8416, 39.017],
            [-8.7819, 39.0584],
            [-8.7981, 39.0963],
            [-8.8675, 39.1283],
            [-8.9131, 39.172],
            [-8.8265, 39.186],
            [-8.8328, 39.2232],
            [-8.9627, 39.2555],
            [-8.9945, 39.2967],
          ],
        ],
      },
      properties: {
        name: 'Santarém',
        id: 'PT-14',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-14',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.926, 38.7599],
            [-8.9075, 38.7363],
            [-8.8389, 38.7618],
            [-8.7536, 38.7428],
            [-8.708, 38.8454],
            [-8.6131, 38.7831],
            [-8.5468, 38.7634],
            [-8.491, 38.7613],
            [-8.501, 38.7155],
            [-8.6535, 38.6123],
            [-8.6391, 38.5495],
            [-8.5752, 38.5479],
            [-8.4759, 38.5168],
            [-8.3691, 38.5457],
            [-8.2837, 38.4121],
            [-8.3226, 38.3722],
            [-8.1977, 38.3426],
            [-8.1644, 38.3198],
            [-8.1297, 38.2663],
            [-8.1298, 38.224],
            [-8.2843, 38.1866],
            [-8.2912, 38.1391],
            [-8.3556, 38.1384],
            [-8.4097, 38.0728],
            [-8.3639, 38.022],
            [-8.2936, 37.9933],
            [-8.3308, 37.847],
            [-8.4347, 37.8709],
            [-8.5375, 37.8534],
            [-8.5838, 37.7994],
            [-8.5871, 37.7568],
            [-8.6834, 37.7533],
            [-8.7355, 37.8148],
            [-8.793, 37.8196],
            [-8.8082, 37.9277],
            [-8.8879, 37.952],
            [-8.8379, 38.0212],
            [-8.7885, 38.1462],
            [-8.7746, 38.2393],
            [-8.7846, 38.3376],
            [-8.826, 38.4215],
            [-8.7218, 38.4412],
            [-8.7554, 38.4868],
            [-8.7946, 38.486],
            [-8.8986, 38.5203],
            [-9.0642, 38.4348],
            [-9.1082, 38.4429],
            [-9.2229, 38.4139],
            [-9.1818, 38.5057],
            [-9.2116, 38.6052],
            [-9.263, 38.6627],
            [-9.1463, 38.6884],
            [-9.0918, 38.6596],
            [-9.0099, 38.6829],
            [-9.0448, 38.7157],
            [-8.926, 38.7599],
          ],
        ],
      },
      properties: {
        name: 'Setúbal',
        id: 'PT-15',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-15',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.165, 41.8181],
            [-8.2716, 41.7712],
            [-8.4243, 41.7574],
            [-8.5186, 41.7259],
            [-8.5217, 41.6796],
            [-8.6041, 41.6343],
            [-8.6433, 41.6544],
            [-8.7568, 41.6144],
            [-8.8099, 41.6121],
            [-8.8804, 41.7499],
            [-8.873, 41.8702],
            [-8.8184, 41.9113],
            [-8.7563, 41.9331],
            [-8.7441, 41.9659],
            [-8.6622, 42.0026],
            [-8.6359, 42.0479],
            [-8.546, 42.0542],
            [-8.5198, 42.0796],
            [-8.4289, 42.0723],
            [-8.3319, 42.0838],
            [-8.3171, 42.1045],
            [-8.1912, 42.1426],
            [-8.1821, 42.0651],
            [-8.1187, 42.0823],
            [-8.0827, 42.0283],
            [-8.163, 41.9827],
            [-8.2168, 41.9134],
            [-8.1693, 41.8765],
            [-8.165, 41.8181],
          ],
        ],
      },
      properties: {
        name: 'Viana do Castelo',
        id: 'PT-16',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-16',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.2005, 41.8795],
            [-7.2103, 41.8138],
            [-7.172, 41.7448],
            [-7.2087, 41.7121],
            [-7.2587, 41.5914],
            [-7.2291, 41.5228],
            [-7.284, 41.5156],
            [-7.2983, 41.4646],
            [-7.3653, 41.4404],
            [-7.3526, 41.3766],
            [-7.3621, 41.3041],
            [-7.3914, 41.2858],
            [-7.4319, 41.2108],
            [-7.5479, 41.1884],
            [-7.5901, 41.1563],
            [-7.6646, 41.1627],
            [-7.6979, 41.1415],
            [-7.797, 41.1618],
            [-7.874, 41.157],
            [-7.9118, 41.1199],
            [-7.8756, 41.242],
            [-7.9076, 41.3027],
            [-7.964, 41.3296],
            [-7.9742, 41.3752],
            [-7.9412, 41.4307],
            [-7.938, 41.4778],
            [-7.837, 41.5365],
            [-7.8107, 41.5733],
            [-7.9972, 41.6035],
            [-7.9891, 41.6849],
            [-8.0882, 41.7127],
            [-8.0512, 41.8195],
            [-7.9745, 41.8753],
            [-7.8922, 41.8595],
            [-7.7635, 41.899],
            [-7.7008, 41.9077],
            [-7.6539, 41.8812],
            [-7.5859, 41.8819],
            [-7.6114, 41.8292],
            [-7.4525, 41.8651],
            [-7.4258, 41.8302],
            [-7.3658, 41.8528],
            [-7.3153, 41.8425],
            [-7.2005, 41.8795],
          ],
        ],
      },
      properties: {
        name: 'Vila Real',
        id: 'PT-17',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-17',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.4319, 41.2108],
            [-7.3905, 41.1953],
            [-7.3575, 41.1479],
            [-7.3045, 41.1395],
            [-7.3457, 41.0464],
            [-7.3253, 40.972],
            [-7.4536, 40.8124],
            [-7.5141, 40.8693],
            [-7.586, 40.8526],
            [-7.6281, 40.7815],
            [-7.5709, 40.7491],
            [-7.591, 40.7138],
            [-7.5351, 40.6903],
            [-7.5891, 40.5856],
            [-7.8283, 40.4985],
            [-7.8842, 40.4732],
            [-7.9739, 40.4052],
            [-8.0426, 40.3855],
            [-8.1342, 40.3331],
            [-8.193, 40.3433],
            [-8.2509, 40.3232],
            [-8.2997, 40.3582],
            [-8.3562, 40.3672],
            [-8.3549, 40.4089],
            [-8.3176, 40.4412],
            [-8.3356, 40.486],
            [-8.2501, 40.5111],
            [-8.2849, 40.5391],
            [-8.3084, 40.5964],
            [-8.2971, 40.6931],
            [-8.3324, 40.7398],
            [-8.2727, 40.7569],
            [-8.2369, 40.8254],
            [-8.1279, 40.8981],
            [-8.1334, 40.9786],
            [-8.2347, 41.0118],
            [-8.264, 41.0659],
            [-8.2045, 41.0941],
            [-8.0528, 41.0897],
            [-7.9516, 41.1227],
            [-7.9118, 41.1199],
            [-7.874, 41.157],
            [-7.797, 41.1618],
            [-7.6979, 41.1415],
            [-7.6646, 41.1627],
            [-7.5901, 41.1563],
            [-7.5479, 41.1884],
            [-7.4319, 41.2108],
          ],
        ],
      },
      properties: {
        name: 'Viseu',
        id: 'PT-18',
        CNTRY: 'Portugal',
        TYPE: 'Distrito',
        TYPE_ENG: 'District',
      },
      id: 'PT-18',
    },
  ],
}
export default map
