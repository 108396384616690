import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {numSpaceFormater} from '../../../../../helpers/formater'
import {selectors} from '../../../redux/ReportsRedux'
import '../../../ReportGeneral.scss';
import {campaign, TikTokReportDetails} from '../../../models/TikTokReport'
import CampaignGroupItem from "../../Widgets/CampaignGroupItem";

interface ITiktokCampaignGroup {}

const TiktokCampaignGroup: React.FC<ITiktokCampaignGroup> = () => {
  const intl = useIntl()
  const details = useSelector(selectors.getReportDetails) as TikTokReportDetails
  const [campaignData, setCampaignData] = useState<campaign[]>([])

  const [campaignCol, setCampaignCol] = useState<number>(4)
  const [hasCampaignData, setHasCampaignData] = useState(false);

  useEffect(() => {
    if (details && details.campaigns) {
      setCampaignData(details.campaigns)
    }
  }, [details])

  useEffect(() => {
    if (campaignData && campaignData.length % 2 === 0) {
      setCampaignCol(6)
    }
    if(campaignData && campaignData.length > 0) {
      setHasCampaignData(true);
    }
  }, [campaignData])

  function hasCost() {
    campaignData.forEach(item => {
      if(item.cost > 0) {
        return true;
      }
    });
    return false;
  }

  return hasCampaignData ? (
    <>
      {hasCost() && (<div className="row card-body page-break px-5 py-5 mb-5">
        <div className="pb-10">
          <h3 className="fw-bolder text-dark text-center">{`${intl.formatMessage({
            id: 'REPORT.CAMPAIGN_MAIN',
          })} Tiktok Ads`}</h3>
        </div>
      </div>)}

      {campaignData && (
        <div className='container row' data-testid='campaign-grid'>
          {campaignData.map((item, index) => {
            if(item.cost > 0) {
              const isPageBreak = (index) % 4 === 0 && index != 0;
              const colClass = `col-xl-${campaignCol} col-12 mb-5 mb-xl-0 mt-2 ${isPageBreak ? 'page-break' : ''}`;
              return (
                  <div key={index} className={colClass}>
                    <div className='card card-xl-stretch mb-xl-8 bg-white shadow-sm'>
                      <div className='card-header border-0 py-5 px-7 bg-light-primary campaign-card'>
                        <div className='d-flex justify-content-center align-items-center'>
                      <span className='symbol-label me-3'>
                        <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/tiktok-2.svg')}
                            className='h-25px h-xl-35px align-self-center'
                            alt=''
                        />
                      </span>
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder mb-1'>{item.campaign_name}</span>
                            <span className='text-muted fw-bold fs-xl-7 fs-8'>
                          {intl.formatMessage({id: 'CAMPAIGN'})}
                        </span>
                          </h3>
                        </div>
                      </div>

                      <div className='card-body p-0 d-flex flex-column'>
                        <div className='card pt-5 pb-5 px-5 flex-grow-1'>
                          <div className='px-2 py-3 mb-5 border border-gray-200 rounded shadow-sm'>
                            <div className='fw-bolder fs-6 mb-3 text-center'>
                              {intl.formatMessage({id: 'BASIC.DATA'})}
                            </div>
                            <div className='col-12'>
                              <div className='row p-2'>
                                <CampaignGroupItem
                                    value={numSpaceFormater(item.cost)}
                                    label={intl.formatMessage({id: 'COST'})}
                                    img='/media/icons/duotone/Shopping/Credit-card.svg'
                                    color='info'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(item.impressions)}
                                    label={intl.formatMessage({id: 'IMPRESSIONS'})}
                                    img='/media/icons/duotone/General/Visible.svg'
                                    color='danger'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.reach).toFixed(2))}
                                    label={intl.formatMessage({id: 'REACH'})}
                                    img='/media/icons/duotone/Shopping/Cart3.svg'
                                    color='success'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.clicks).toFixed(4))}
                                    label={intl.formatMessage({id: 'CLICKS'})}
                                    img='/media/icons/duotone/General/Cursor.svg'
                                    color='info'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.ctr).toFixed(4))}
                                    label={intl.formatMessage({id: 'CTR'})}
                                    img='/media/icons/duotone/Shopping/Credit-card.svg'
                                    color='dark'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.cpm).toFixed(4))}
                                    label={intl.formatMessage({id: 'CPM'})}
                                    img='/media/icons/duotone/Shopping/Barcode-read.svg'
                                    color='primary'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.likes).toFixed(4))}
                                    label={intl.formatMessage({id: 'LIKES'})}
                                    img='/media/icons/duotone/General/Like.svg'
                                    color='primary'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.comments).toFixed(4))}
                                    label={intl.formatMessage({id: 'COMMENTS'})}
                                    img='/media/icons/duotone/Communication/Chat2.svg'
                                    color='danger'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.followers).toFixed(4))}
                                    label={intl.formatMessage({id: 'FOLLOWS'})}
                                    img='/media/icons/duotone/Communication/Contact1.svg'
                                    color='warning'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='px-2 py-3 mb-5 border border-gray-200 rounded shadow-sm'>
                            <div className='fw-bolder fs-6 mb-3 text-center'>
                              {intl.formatMessage({id: 'REALISATION'})}
                            </div>
                            <div className='col-12'>
                              <div className='row p-2'>
                                <CampaignGroupItem
                                    value={numSpaceFormater(item.total_add_to_cart)}
                                    label={intl.formatMessage({id: 'TOTAL_ADD_TO_CART'})}
                                    img='/media/icons/duotone/Shopping/Cart1.svg'
                                    color='success'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.view_content).toFixed(2))}
                                    label={intl.formatMessage({id: 'TOTAL_VIEW_CONTENT'})}
                                    img='/media/icons/duotone/General/Visible.svg'
                                    color='danger'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.total_complete_payment).toFixed(2))}
                                    label={intl.formatMessage({id: 'TOTAL_COMPLETE_PAYMENT'})}
                                    img='/media/icons/duotone/Shopping/Credit-card.svg'
                                    color='warning'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.cost_per_complete_payment).toFixed(2))}
                                    label={intl.formatMessage({id: 'COST_PER_COMPLETE_PAYMENT'})}
                                    img='/media/icons/duotone/Shopping/Price1.svg'
                                    color='primary'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.roas).toFixed(2))}
                                    label={intl.formatMessage({id: 'ROAS'})}
                                    img='/media/icons/duotone/General/Star.svg'
                                    color='dark'
                                />
                                <CampaignGroupItem
                                    value={numSpaceFormater(Number(item.total_complete_payment_value).toFixed(2))}
                                    label={intl.formatMessage({id: 'TOTAL_COMPLETE_PAYMENT_VALUE'})}
                                    img='/media/icons/duotone/Shopping/Dollar.svg'
                                    color='primary'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              )
            }

          })}
        </div>
      )}
    </>
  ) : <></>
}

export default TiktokCampaignGroup
