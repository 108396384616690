import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const INV_API = `${API_URL}/invoices`

export async function getInvoicesList(
  companyId: number,
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${INV_API}?company_id=${companyId}&search=${search}&page=${page}&perPage=${perPage}`
    )
  return await axios.get(
    `${INV_API}?company_id=${companyId}&page=${page}&perPage=${perPage}&${filter}`
  )
}

export async function getInvoicePdf(id: number, companyId: number) {
  return await axios.get(`${INV_API}/${id}/pdf?company_id=${companyId}`, {responseType: 'blob'})
}

export async function getInvoicesOverdue(companyId: number) {
  return await axios.get(`${INV_API}/overdue?company_id=${companyId}&perPage=1000`)
}