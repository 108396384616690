import React, {useState} from 'react';
import { numSpaceFormater } from '../../../../../helpers/formater';
import { useIntl } from 'react-intl';
import {ad} from "../../../models/TikTokReport";

interface TikTokAdRowProps {
    ad: ad; // Use the 'ad' type imported from your models
    onAdClick: (url: string) => void; // Define the type for your onAdClick function
}

const TikTokTopAdsRow: React.FC<TikTokAdRowProps> = ({ ad, onAdClick }) => {
    const intl = useIntl();

    return (
        <tr>
            <td>
                <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                        <div className='text-dark fw-bolder fs-8'>
                            {ad.url ? (
                                <img
                                    src='/media/icons/duotone/Media/DVD.svg'
                                    className="svg-icon-xl-2x svg-icon-5x"
                                    style={{
                                        backgroundColor: '#68cf46',
                                        borderRadius: '50%',
                                        cursor: 'pointer'
                                    }}
                                    alt="Preview"
                                    title={intl.formatMessage({id: 'PREVIEW'})}
                                    onClick={() => onAdClick(ad.url)}
                                />
                            ) : (
                                <img src='/media/icons/duotone/Media/DVD.svg'
                                     className="svg-icon-xl-2x svg-icon-5x"
                                     style={{
                                         backgroundColor: '#aaa8a8',
                                         borderRadius: '50%'
                                     }} alt=""
                                     title={intl.formatMessage({id: 'PREVIEW_UNAVAILABLE'})}/>
                            )}
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                        <div className='text-dark fw-bolder fs-8'>{ad.ad_name}</div>
                    </div>
                </div>
            </td>
            <td>
                <div className='d-flex justify-content-center'>
                    <div className='text-dark fs-8'>{numSpaceFormater(Number(ad.clicks).toFixed(2))}</div>
                </div>
            </td>
            <td>
                <div className='d-flex justify-content-center'>
                    <div className='text-dark fs-8'>{numSpaceFormater(Number(ad.impressions).toFixed(2))}</div>
                </div>
            </td>
            <td>
                <div className='d-flex justify-content-center'>
                    <div className='text-dark fs-8'>{numSpaceFormater(Number(ad.total_complete_payment).toFixed(2))}</div>
                </div>
            </td>
            <td>
                <div className='d-flex justify-content-center'>
                    <div className='text-dark fs-8'>{numSpaceFormater(Number(ad.likes).toFixed(2))}</div>
                </div>
            </td>
        </tr>
    );
};

export default TikTokTopAdsRow;
