var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.5333, 50.1678],
            [14.5504, 50.1648],
            [14.5611, 50.1613],
            [14.5628, 50.1562],
            [14.5445, 50.1491],
            [14.5466, 50.1458],
            [14.5646, 50.1508],
            [14.5789, 50.1497],
            [14.5881, 50.1543],
            [14.5938, 50.1526],
            [14.5869, 50.1444],
            [14.5947, 50.1327],
            [14.6043, 50.128],
            [14.617, 50.1296],
            [14.6211, 50.1232],
            [14.6438, 50.1245],
            [14.656, 50.1188],
            [14.6547, 50.1067],
            [14.6739, 50.1003],
            [14.6936, 50.0996],
            [14.6944, 50.0954],
            [14.7032, 50.0919],
            [14.705, 50.0876],
            [14.6983, 50.0716],
            [14.6591, 50.0639],
            [14.6389, 50.0563],
            [14.6389, 50.0484],
            [14.6424, 50.0418],
            [14.6537, 50.0485],
            [14.6645, 50.0379],
            [14.6543, 50.0368],
            [14.6566, 50.0254],
            [14.6665, 50.018],
            [14.6651, 50.0121],
            [14.6556, 50.0043],
            [14.6443, 50.0092],
            [14.6388, 50.005],
            [14.6456, 49.9986],
            [14.639, 49.9929],
            [14.6266, 49.9947],
            [14.6037, 50.0005],
            [14.6006, 50.009],
            [14.5874, 50.0081],
            [14.5752, 50.0125],
            [14.5574, 50.0134],
            [14.5508, 50.0068],
            [14.5382, 50.0055],
            [14.5269, 50.0083],
            [14.5299, 50.0006],
            [14.5097, 49.9934],
            [14.5069, 49.9985],
            [14.4819, 49.992],
            [14.4698, 49.9797],
            [14.4631, 49.9801],
            [14.4604, 49.9716],
            [14.4432, 49.9723],
            [14.4378, 49.968],
            [14.42, 49.9636],
            [14.3999, 49.9686],
            [14.3949, 49.9561],
            [14.397, 49.9445],
            [14.3806, 49.9509],
            [14.3748, 49.9462],
            [14.3663, 49.9506],
            [14.3546, 49.9481],
            [14.3361, 49.9473],
            [14.3254, 49.9547],
            [14.3255, 49.9627],
            [14.3332, 49.9671],
            [14.3419, 49.9667],
            [14.3444, 49.9726],
            [14.3321, 49.9727],
            [14.3303, 49.9763],
            [14.339, 49.9809],
            [14.3421, 49.9907],
            [14.3312, 49.996],
            [14.3099, 49.9936],
            [14.3007, 50.0041],
            [14.3037, 50.0123],
            [14.3128, 50.0162],
            [14.3028, 50.0295],
            [14.2926, 50.0332],
            [14.276, 50.031],
            [14.2719, 50.0401],
            [14.2667, 50.0406],
            [14.269, 50.0506],
            [14.256, 50.0543],
            [14.2473, 50.0602],
            [14.258, 50.0656],
            [14.2576, 50.0709],
            [14.2902, 50.0731],
            [14.2851, 50.0811],
            [14.2743, 50.0811],
            [14.2743, 50.0855],
            [14.259, 50.0883],
            [14.2583, 50.0934],
            [14.2475, 50.101],
            [14.2345, 50.0995],
            [14.2339, 50.106],
            [14.2758, 50.1181],
            [14.2825, 50.1136],
            [14.296, 50.1213],
            [14.2947, 50.1247],
            [14.304, 50.1293],
            [14.3158, 50.1284],
            [14.3179, 50.1143],
            [14.3287, 50.1169],
            [14.3593, 50.116],
            [14.3554, 50.1247],
            [14.3573, 50.13],
            [14.3559, 50.1411],
            [14.366, 50.1482],
            [14.3862, 50.1465],
            [14.3941, 50.1412],
            [14.3992, 50.1482],
            [14.4056, 50.1469],
            [14.4218, 50.1498],
            [14.4287, 50.1535],
            [14.4275, 50.1576],
            [14.4604, 50.1589],
            [14.467, 50.1696],
            [14.478, 50.172],
            [14.5079, 50.1697],
            [14.5086, 50.1735],
            [14.5288, 50.1768],
            [14.5302, 50.1717],
            [14.5333, 50.1678],
          ],
        ],
      },
      properties: {name: 'Praha', id: 'CZ-10', CNTRY: 'Czechia', TYPE: 'City', NAME_ENG: 'Prague'},
      id: 'CZ-10',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.4835, 50.5054],
            [14.5006, 50.4924],
            [14.505, 50.5051],
            [14.5103, 50.5096],
            [14.5188, 50.5099],
            [14.5299, 50.5027],
            [14.5327, 50.4984],
            [14.5468, 50.4961],
            [14.5488, 50.5032],
            [14.564, 50.5061],
            [14.5731, 50.5101],
            [14.5789, 50.5055],
            [14.5795, 50.4991],
            [14.5753, 50.493],
            [14.5806, 50.4878],
            [14.5811, 50.4809],
            [14.6011, 50.4751],
            [14.6125, 50.4736],
            [14.6143, 50.4807],
            [14.6304, 50.481],
            [14.6545, 50.4855],
            [14.6666, 50.4839],
            [14.6805, 50.485],
            [14.69, 50.4811],
            [14.6888, 50.49],
            [14.6998, 50.4954],
            [14.7089, 50.4953],
            [14.7179, 50.4995],
            [14.7252, 50.5192],
            [14.7523, 50.5154],
            [14.773, 50.5151],
            [14.7852, 50.5102],
            [14.7856, 50.5175],
            [14.7934, 50.5186],
            [14.7952, 50.5279],
            [14.8002, 50.5313],
            [14.8084, 50.526],
            [14.8184, 50.5333],
            [14.8265, 50.5348],
            [14.8282, 50.5432],
            [14.8336, 50.5508],
            [14.8419, 50.5529],
            [14.8469, 50.5581],
            [14.8452, 50.5678],
            [14.8519, 50.5716],
            [14.8694, 50.5762],
            [14.8861, 50.5866],
            [14.9039, 50.5889],
            [14.8945, 50.5955],
            [14.8959, 50.6019],
            [14.9051, 50.6062],
            [14.915, 50.6071],
            [14.9075, 50.6168],
            [14.924, 50.6187],
            [14.9318, 50.6144],
            [14.9303, 50.6071],
            [14.9431, 50.6071],
            [14.9518, 50.605],
            [14.9585, 50.5938],
            [14.9689, 50.5928],
            [14.9751, 50.6022],
            [14.9814, 50.6029],
            [14.9928, 50.5991],
            [15.0013, 50.6002],
            [15.005, 50.6043],
            [15.018, 50.5867],
            [15.0254, 50.5851],
            [15.0311, 50.5782],
            [15.0388, 50.5741],
            [15.0423, 50.5654],
            [15.0602, 50.571],
            [15.0658, 50.5702],
            [15.0746, 50.5609],
            [15.0944, 50.5543],
            [15.0943, 50.5449],
            [15.0996, 50.5329],
            [15.1128, 50.5324],
            [15.1168, 50.529],
            [15.1282, 50.5312],
            [15.1446, 50.5227],
            [15.1273, 50.5089],
            [15.12, 50.51],
            [15.1136, 50.5028],
            [15.1231, 50.4949],
            [15.1333, 50.49],
            [15.1345, 50.4836],
            [15.1304, 50.4799],
            [15.1448, 50.4744],
            [15.1372, 50.4712],
            [15.1379, 50.462],
            [15.131, 50.4508],
            [15.1347, 50.4464],
            [15.1457, 50.4477],
            [15.1542, 50.4361],
            [15.1508, 50.4316],
            [15.1609, 50.4266],
            [15.1572, 50.4084],
            [15.1516, 50.4043],
            [15.1368, 50.4036],
            [15.1369, 50.3951],
            [15.1421, 50.3878],
            [15.1297, 50.3857],
            [15.1174, 50.3896],
            [15.1091, 50.3795],
            [15.1099, 50.3738],
            [15.1188, 50.3659],
            [15.1273, 50.3626],
            [15.1226, 50.3549],
            [15.131, 50.3453],
            [15.1534, 50.3388],
            [15.1672, 50.3462],
            [15.1798, 50.3399],
            [15.1818, 50.3284],
            [15.1948, 50.3304],
            [15.1995, 50.3245],
            [15.2176, 50.3171],
            [15.2058, 50.3129],
            [15.2027, 50.3071],
            [15.2095, 50.3053],
            [15.207, 50.2955],
            [15.2383, 50.2864],
            [15.2419, 50.2896],
            [15.2554, 50.2907],
            [15.258, 50.2936],
            [15.2815, 50.2894],
            [15.2851, 50.2912],
            [15.3035, 50.2845],
            [15.3066, 50.2816],
            [15.3173, 50.2855],
            [15.3199, 50.2918],
            [15.3269, 50.2909],
            [15.3354, 50.2952],
            [15.3536, 50.298],
            [15.3597, 50.291],
            [15.3666, 50.2897],
            [15.3785, 50.2812],
            [15.3757, 50.2696],
            [15.3768, 50.2616],
            [15.3845, 50.2547],
            [15.373, 50.25],
            [15.3753, 50.2333],
            [15.372, 50.2273],
            [15.3914, 50.2174],
            [15.3915, 50.2115],
            [15.3979, 50.2044],
            [15.3965, 50.1952],
            [15.3897, 50.1921],
            [15.3893, 50.1816],
            [15.3779, 50.1768],
            [15.3657, 50.1677],
            [15.3564, 50.1634],
            [15.3751, 50.1587],
            [15.3742, 50.1555],
            [15.3585, 50.1479],
            [15.3645, 50.1407],
            [15.3765, 50.1452],
            [15.3824, 50.145],
            [15.3904, 50.1385],
            [15.404, 50.1345],
            [15.4062, 50.1388],
            [15.4242, 50.1296],
            [15.4363, 50.1275],
            [15.4459, 50.1236],
            [15.4362, 50.1148],
            [15.4426, 50.1055],
            [15.4257, 50.0989],
            [15.4151, 50.1073],
            [15.4076, 50.1048],
            [15.4001, 50.096],
            [15.4038, 50.0936],
            [15.4165, 50.0935],
            [15.4208, 50.0895],
            [15.404, 50.0872],
            [15.4047, 50.0818],
            [15.4128, 50.0751],
            [15.4141, 50.0627],
            [15.403, 50.063],
            [15.3947, 50.0591],
            [15.3812, 50.06],
            [15.3804, 50.0533],
            [15.4011, 50.0503],
            [15.395, 50.0453],
            [15.3797, 50.0476],
            [15.3687, 50.0426],
            [15.3692, 50.0383],
            [15.3769, 50.0283],
            [15.4015, 50.0181],
            [15.4185, 50.0209],
            [15.4277, 50.0082],
            [15.4371, 50.0091],
            [15.448, 50.0055],
            [15.4548, 50.0097],
            [15.4604, 50.0051],
            [15.4752, 50.0016],
            [15.4697, 49.9939],
            [15.4608, 49.9886],
            [15.4921, 49.9721],
            [15.5052, 49.9669],
            [15.5097, 49.97],
            [15.5192, 49.9631],
            [15.5316, 49.9621],
            [15.5329, 49.9538],
            [15.5301, 49.9488],
            [15.5167, 49.9419],
            [15.5245, 49.9328],
            [15.5364, 49.9295],
            [15.5341, 49.9231],
            [15.5162, 49.9041],
            [15.514, 49.8988],
            [15.4982, 49.8983],
            [15.5056, 49.8833],
            [15.4973, 49.8611],
            [15.4902, 49.8496],
            [15.4861, 49.8533],
            [15.4781, 49.852],
            [15.4735, 49.8441],
            [15.4645, 49.8426],
            [15.4459, 49.8456],
            [15.4328, 49.8372],
            [15.4311, 49.8315],
            [15.4207, 49.8321],
            [15.4155, 49.8199],
            [15.4255, 49.8175],
            [15.4318, 49.8109],
            [15.4364, 49.799],
            [15.4237, 49.7925],
            [15.4071, 49.7935],
            [15.3983, 49.7957],
            [15.3911, 49.7928],
            [15.3825, 49.7996],
            [15.3704, 49.789],
            [15.3719, 49.7852],
            [15.3661, 49.7762],
            [15.3591, 49.7788],
            [15.3477, 49.769],
            [15.3387, 49.7743],
            [15.3282, 49.7729],
            [15.3151, 49.766],
            [15.3013, 49.7665],
            [15.2844, 49.7647],
            [15.2839, 49.7592],
            [15.2756, 49.7553],
            [15.2512, 49.7543],
            [15.2553, 49.7627],
            [15.2483, 49.7632],
            [15.2384, 49.7568],
            [15.2283, 49.7561],
            [15.2217, 49.7524],
            [15.216, 49.7378],
            [15.2172, 49.728],
            [15.2077, 49.7251],
            [15.1985, 49.7168],
            [15.1803, 49.7037],
            [15.1783, 49.6878],
            [15.1826, 49.6803],
            [15.1803, 49.6762],
            [15.186, 49.668],
            [15.1973, 49.6726],
            [15.2073, 49.6628],
            [15.2184, 49.6647],
            [15.2335, 49.6646],
            [15.2369, 49.6543],
            [15.249, 49.6438],
            [15.2447, 49.6364],
            [15.2498, 49.6305],
            [15.2442, 49.6242],
            [15.2365, 49.6228],
            [15.2302, 49.6155],
            [15.2114, 49.6123],
            [15.2002, 49.6135],
            [15.1938, 49.6186],
            [15.1829, 49.6177],
            [15.1838, 49.6108],
            [15.1733, 49.6097],
            [15.1719, 49.6028],
            [15.1663, 49.6019],
            [15.1619, 49.5945],
            [15.1527, 49.5966],
            [15.1462, 49.6017],
            [15.129, 49.6008],
            [15.1101, 49.6073],
            [15.1037, 49.6004],
            [15.0951, 49.5984],
            [15.0827, 49.6013],
            [15.0694, 49.5962],
            [15.0553, 49.5939],
            [15.0457, 49.6006],
            [15.0403, 49.5963],
            [15.018, 49.597],
            [15.0026, 49.6053],
            [14.9909, 49.6069],
            [14.9742, 49.6038],
            [14.969, 49.5956],
            [14.9805, 49.5917],
            [14.9859, 49.5799],
            [14.9715, 49.5778],
            [14.9397, 49.5791],
            [14.9344, 49.5693],
            [14.9419, 49.5627],
            [14.9313, 49.5555],
            [14.9325, 49.5502],
            [14.9272, 49.5461],
            [14.9097, 49.5429],
            [14.9029, 49.5444],
            [14.894, 49.553],
            [14.8956, 49.5562],
            [14.8879, 49.5613],
            [14.8834, 49.5689],
            [14.8777, 49.5726],
            [14.8742, 49.5814],
            [14.8772, 49.5868],
            [14.8634, 49.586],
            [14.856, 49.5883],
            [14.8481, 49.5779],
            [14.8381, 49.5838],
            [14.8277, 49.5873],
            [14.8208, 49.5924],
            [14.8136, 49.6024],
            [14.8038, 49.6014],
            [14.7873, 49.6053],
            [14.7795, 49.6053],
            [14.7673, 49.6173],
            [14.7619, 49.6115],
            [14.7428, 49.6052],
            [14.7465, 49.5952],
            [14.7361, 49.5957],
            [14.7259, 49.5919],
            [14.7327, 49.588],
            [14.7289, 49.5781],
            [14.7234, 49.5778],
            [14.7223, 49.5668],
            [14.7253, 49.5603],
            [14.7372, 49.5524],
            [14.736, 49.5486],
            [14.7241, 49.5465],
            [14.7103, 49.5406],
            [14.6947, 49.5431],
            [14.691, 49.5381],
            [14.6728, 49.5282],
            [14.6571, 49.5251],
            [14.6551, 49.5153],
            [14.6494, 49.504],
            [14.6395, 49.5054],
            [14.6344, 49.5194],
            [14.6222, 49.5187],
            [14.6102, 49.5224],
            [14.5945, 49.5186],
            [14.5826, 49.5182],
            [14.5821, 49.5264],
            [14.5901, 49.5331],
            [14.5912, 49.5418],
            [14.5732, 49.5335],
            [14.5608, 49.5311],
            [14.5343, 49.5367],
            [14.5284, 49.5333],
            [14.5154, 49.5347],
            [14.5091, 49.544],
            [14.4946, 49.5401],
            [14.4778, 49.5454],
            [14.4751, 49.5369],
            [14.456, 49.5342],
            [14.4439, 49.5488],
            [14.4302, 49.5466],
            [14.4101, 49.5532],
            [14.4033, 49.5406],
            [14.3935, 49.5431],
            [14.3841, 49.5413],
            [14.3802, 49.5367],
            [14.3473, 49.528],
            [14.3428, 49.5297],
            [14.3296, 49.527],
            [14.3265, 49.5395],
            [14.3063, 49.5493],
            [14.2995, 49.5442],
            [14.2925, 49.5534],
            [14.2927, 49.5592],
            [14.272, 49.5642],
            [14.2613, 49.5603],
            [14.2506, 49.5615],
            [14.2403, 49.5578],
            [14.2324, 49.565],
            [14.2226, 49.5584],
            [14.2262, 49.5488],
            [14.2149, 49.5415],
            [14.2097, 49.5316],
            [14.2044, 49.5361],
            [14.1962, 49.5372],
            [14.1499, 49.5313],
            [14.1412, 49.5341],
            [14.1333, 49.5335],
            [14.1214, 49.538],
            [14.1226, 49.5311],
            [14.1125, 49.5283],
            [14.0726, 49.5286],
            [14.0654, 49.534],
            [14.0683, 49.5543],
            [14.0591, 49.5614],
            [14.0436, 49.5582],
            [14.0393, 49.5598],
            [14.0237, 49.5483],
            [14.0108, 49.551],
            [14.0098, 49.5469],
            [14.0148, 49.5391],
            [14.0092, 49.5362],
            [13.998, 49.5184],
            [13.9937, 49.5242],
            [13.9825, 49.5239],
            [13.9772, 49.5116],
            [13.9552, 49.5139],
            [13.9505, 49.5178],
            [13.937, 49.5118],
            [13.9378, 49.5043],
            [13.918, 49.5019],
            [13.9063, 49.5057],
            [13.8996, 49.5131],
            [13.8811, 49.5127],
            [13.8812, 49.523],
            [13.8652, 49.5249],
            [13.853, 49.5138],
            [13.8339, 49.511],
            [13.8326, 49.5183],
            [13.8268, 49.5237],
            [13.8147, 49.5244],
            [13.8082, 49.5162],
            [13.7973, 49.5184],
            [13.7829, 49.5082],
            [13.7702, 49.5104],
            [13.7659, 49.5136],
            [13.7723, 49.5319],
            [13.7706, 49.5369],
            [13.762, 49.5393],
            [13.7641, 49.5455],
            [13.7444, 49.5514],
            [13.7446, 49.5594],
            [13.7405, 49.5681],
            [13.7439, 49.5764],
            [13.7399, 49.5856],
            [13.7516, 49.5967],
            [13.7469, 49.609],
            [13.7537, 49.6143],
            [13.7538, 49.6204],
            [13.7292, 49.6214],
            [13.7208, 49.6256],
            [13.7079, 49.6287],
            [13.7142, 49.6361],
            [13.7068, 49.641],
            [13.7155, 49.6462],
            [13.7075, 49.6571],
            [13.6872, 49.662],
            [13.6899, 49.6661],
            [13.7133, 49.6749],
            [13.7227, 49.6746],
            [13.7277, 49.6788],
            [13.7004, 49.6856],
            [13.688, 49.6838],
            [13.6821, 49.6759],
            [13.6759, 49.6779],
            [13.6723, 49.6962],
            [13.6771, 49.7011],
            [13.6881, 49.7048],
            [13.6961, 49.717],
            [13.7042, 49.7208],
            [13.7368, 49.7316],
            [13.7409, 49.7277],
            [13.7613, 49.7181],
            [13.7734, 49.7267],
            [13.7741, 49.7342],
            [13.7846, 49.7415],
            [13.8016, 49.7467],
            [13.819, 49.7543],
            [13.813, 49.7601],
            [13.8015, 49.7649],
            [13.7828, 49.7608],
            [13.7832, 49.7659],
            [13.794, 49.7681],
            [13.7993, 49.7802],
            [13.7901, 49.8005],
            [13.8042, 49.8046],
            [13.8069, 49.8207],
            [13.7989, 49.8229],
            [13.7981, 49.8308],
            [13.8062, 49.8365],
            [13.8033, 49.8447],
            [13.8114, 49.8523],
            [13.8115, 49.8595],
            [13.8079, 49.8676],
            [13.8341, 49.8678],
            [13.8273, 49.8799],
            [13.8266, 49.901],
            [13.818, 49.9104],
            [13.8209, 49.9226],
            [13.7994, 49.9301],
            [13.783, 49.9411],
            [13.7786, 49.9394],
            [13.7619, 49.9432],
            [13.7493, 49.9428],
            [13.7504, 49.9535],
            [13.7475, 49.9649],
            [13.7397, 49.9634],
            [13.7266, 49.9671],
            [13.7006, 49.964],
            [13.6998, 49.9701],
            [13.6915, 49.9781],
            [13.6797, 49.9771],
            [13.6687, 49.988],
            [13.6676, 49.9966],
            [13.66, 49.9972],
            [13.6551, 50.0018],
            [13.6301, 49.9998],
            [13.631, 49.9954],
            [13.6181, 49.9919],
            [13.5905, 49.9922],
            [13.5695, 49.9944],
            [13.5633, 50.0017],
            [13.556, 50.0039],
            [13.5529, 50.0092],
            [13.5335, 50.0153],
            [13.5197, 50.0139],
            [13.5144, 50.021],
            [13.5023, 50.0211],
            [13.5022, 50.0268],
            [13.5086, 50.0306],
            [13.5227, 50.0326],
            [13.5147, 50.0415],
            [13.5008, 50.0398],
            [13.4872, 50.0317],
            [13.4782, 50.0354],
            [13.4798, 50.0422],
            [13.4653, 50.0396],
            [13.4467, 50.0413],
            [13.4348, 50.0276],
            [13.4244, 50.0285],
            [13.4233, 50.0334],
            [13.4143, 50.0341],
            [13.4021, 50.0321],
            [13.4005, 50.0429],
            [13.4098, 50.0487],
            [13.4139, 50.0613],
            [13.4306, 50.0622],
            [13.4299, 50.0682],
            [13.4246, 50.0756],
            [13.4189, 50.0765],
            [13.4162, 50.0834],
            [13.4098, 50.0895],
            [13.411, 50.0952],
            [13.4262, 50.1043],
            [13.4254, 50.1082],
            [13.4373, 50.1093],
            [13.4577, 50.1046],
            [13.4701, 50.1129],
            [13.4698, 50.1311],
            [13.4774, 50.1266],
            [13.4904, 50.1268],
            [13.49, 50.1347],
            [13.4859, 50.1406],
            [13.4933, 50.1495],
            [13.4884, 50.1565],
            [13.4945, 50.1643],
            [13.5046, 50.1652],
            [13.5165, 50.1722],
            [13.517, 50.1777],
            [13.5351, 50.1837],
            [13.5358, 50.188],
            [13.559, 50.1931],
            [13.5695, 50.1849],
            [13.5746, 50.1877],
            [13.589, 50.1882],
            [13.6003, 50.194],
            [13.6148, 50.1979],
            [13.6215, 50.2024],
            [13.6179, 50.2102],
            [13.6308, 50.209],
            [13.6387, 50.2167],
            [13.6491, 50.2188],
            [13.6704, 50.2332],
            [13.6729, 50.2228],
            [13.6816, 50.2251],
            [13.697, 50.2207],
            [13.7182, 50.2253],
            [13.7499, 50.2236],
            [13.7532, 50.2341],
            [13.7708, 50.2317],
            [13.7963, 50.2532],
            [13.7998, 50.2593],
            [13.8168, 50.2622],
            [13.827, 50.26],
            [13.8366, 50.2491],
            [13.8413, 50.2403],
            [13.8652, 50.2427],
            [13.8654, 50.2594],
            [13.8777, 50.2696],
            [13.8929, 50.2707],
            [13.9127, 50.2752],
            [13.9193, 50.2724],
            [13.9292, 50.2775],
            [13.9397, 50.2799],
            [13.9423, 50.2868],
            [13.956, 50.2911],
            [13.9568, 50.3009],
            [13.9918, 50.303],
            [13.9888, 50.3066],
            [13.9914, 50.3165],
            [13.9836, 50.3218],
            [13.9823, 50.3332],
            [13.9958, 50.3378],
            [13.9973, 50.3418],
            [14.0189, 50.3485],
            [14.0264, 50.3414],
            [14.0397, 50.3447],
            [14.0538, 50.3401],
            [14.0664, 50.3402],
            [14.0753, 50.3346],
            [14.1159, 50.3346],
            [14.117, 50.3321],
            [14.1459, 50.3321],
            [14.1798, 50.333],
            [14.1833, 50.3379],
            [14.1823, 50.3446],
            [14.197, 50.3422],
            [14.1943, 50.339],
            [14.2103, 50.3347],
            [14.2151, 50.3397],
            [14.2288, 50.3348],
            [14.2391, 50.3341],
            [14.2479, 50.3308],
            [14.2627, 50.3398],
            [14.2683, 50.3499],
            [14.2744, 50.354],
            [14.2821, 50.3505],
            [14.2946, 50.3487],
            [14.3057, 50.3549],
            [14.3209, 50.354],
            [14.3281, 50.3573],
            [14.3432, 50.3543],
            [14.3508, 50.3487],
            [14.3654, 50.351],
            [14.3724, 50.3601],
            [14.3667, 50.3676],
            [14.3674, 50.3769],
            [14.3773, 50.3761],
            [14.3758, 50.3895],
            [14.3783, 50.3964],
            [14.3694, 50.3982],
            [14.3696, 50.4036],
            [14.3635, 50.4083],
            [14.3713, 50.4131],
            [14.3677, 50.4174],
            [14.3649, 50.4306],
            [14.3781, 50.4354],
            [14.3943, 50.4253],
            [14.4147, 50.421],
            [14.4223, 50.4269],
            [14.4097, 50.4309],
            [14.4254, 50.4397],
            [14.4225, 50.4529],
            [14.4473, 50.4491],
            [14.4529, 50.4526],
            [14.4608, 50.4487],
            [14.472, 50.4491],
            [14.4736, 50.4605],
            [14.471, 50.4749],
            [14.4591, 50.4969],
            [14.4712, 50.4983],
            [14.4728, 50.5093],
            [14.4835, 50.5054],
          ],
          [
            [14.5333, 50.1678],
            [14.5302, 50.1717],
            [14.5288, 50.1768],
            [14.5086, 50.1735],
            [14.5079, 50.1697],
            [14.478, 50.172],
            [14.467, 50.1696],
            [14.4604, 50.1589],
            [14.4275, 50.1576],
            [14.4287, 50.1535],
            [14.4218, 50.1498],
            [14.4056, 50.1469],
            [14.3992, 50.1482],
            [14.3941, 50.1412],
            [14.3862, 50.1465],
            [14.366, 50.1482],
            [14.3559, 50.1411],
            [14.3573, 50.13],
            [14.3554, 50.1247],
            [14.3593, 50.116],
            [14.3287, 50.1169],
            [14.3179, 50.1143],
            [14.3158, 50.1284],
            [14.304, 50.1293],
            [14.2947, 50.1247],
            [14.296, 50.1213],
            [14.2825, 50.1136],
            [14.2758, 50.1181],
            [14.2339, 50.106],
            [14.2345, 50.0995],
            [14.2475, 50.101],
            [14.2583, 50.0934],
            [14.259, 50.0883],
            [14.2743, 50.0855],
            [14.2743, 50.0811],
            [14.2851, 50.0811],
            [14.2902, 50.0731],
            [14.2576, 50.0709],
            [14.258, 50.0656],
            [14.2473, 50.0602],
            [14.256, 50.0543],
            [14.269, 50.0506],
            [14.2667, 50.0406],
            [14.2719, 50.0401],
            [14.276, 50.031],
            [14.2926, 50.0332],
            [14.3028, 50.0295],
            [14.3128, 50.0162],
            [14.3037, 50.0123],
            [14.3007, 50.0041],
            [14.3099, 49.9936],
            [14.3312, 49.996],
            [14.3421, 49.9907],
            [14.339, 49.9809],
            [14.3303, 49.9763],
            [14.3321, 49.9727],
            [14.3444, 49.9726],
            [14.3419, 49.9667],
            [14.3332, 49.9671],
            [14.3255, 49.9627],
            [14.3254, 49.9547],
            [14.3361, 49.9473],
            [14.3546, 49.9481],
            [14.3663, 49.9506],
            [14.3748, 49.9462],
            [14.3806, 49.9509],
            [14.397, 49.9445],
            [14.3949, 49.9561],
            [14.3999, 49.9686],
            [14.42, 49.9636],
            [14.4378, 49.968],
            [14.4432, 49.9723],
            [14.4604, 49.9716],
            [14.4631, 49.9801],
            [14.4698, 49.9797],
            [14.4819, 49.992],
            [14.5069, 49.9985],
            [14.5097, 49.9934],
            [14.5299, 50.0006],
            [14.5269, 50.0083],
            [14.5382, 50.0055],
            [14.5508, 50.0068],
            [14.5574, 50.0134],
            [14.5752, 50.0125],
            [14.5874, 50.0081],
            [14.6006, 50.009],
            [14.6037, 50.0005],
            [14.6266, 49.9947],
            [14.639, 49.9929],
            [14.6456, 49.9986],
            [14.6388, 50.005],
            [14.6443, 50.0092],
            [14.6556, 50.0043],
            [14.6651, 50.0121],
            [14.6665, 50.018],
            [14.6566, 50.0254],
            [14.6543, 50.0368],
            [14.6645, 50.0379],
            [14.6537, 50.0485],
            [14.6424, 50.0418],
            [14.6389, 50.0484],
            [14.6389, 50.0563],
            [14.6591, 50.0639],
            [14.6983, 50.0716],
            [14.705, 50.0876],
            [14.7032, 50.0919],
            [14.6944, 50.0954],
            [14.6936, 50.0996],
            [14.6739, 50.1003],
            [14.6547, 50.1067],
            [14.656, 50.1188],
            [14.6438, 50.1245],
            [14.6211, 50.1232],
            [14.617, 50.1296],
            [14.6043, 50.128],
            [14.5947, 50.1327],
            [14.5869, 50.1444],
            [14.5938, 50.1526],
            [14.5881, 50.1543],
            [14.5789, 50.1497],
            [14.5646, 50.1508],
            [14.5466, 50.1458],
            [14.5445, 50.1491],
            [14.5628, 50.1562],
            [14.5611, 50.1613],
            [14.5504, 50.1648],
            [14.5333, 50.1678],
          ],
        ],
      },
      properties: {
        name: 'Středočeský',
        id: 'CZ-20',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Central Bohemia',
      },
      id: 'CZ-20',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.7659, 49.5136],
            [13.7702, 49.5104],
            [13.7829, 49.5082],
            [13.7973, 49.5184],
            [13.8082, 49.5162],
            [13.8147, 49.5244],
            [13.8268, 49.5237],
            [13.8326, 49.5183],
            [13.8339, 49.511],
            [13.853, 49.5138],
            [13.8652, 49.5249],
            [13.8812, 49.523],
            [13.8811, 49.5127],
            [13.8996, 49.5131],
            [13.9063, 49.5057],
            [13.918, 49.5019],
            [13.9378, 49.5043],
            [13.937, 49.5118],
            [13.9505, 49.5178],
            [13.9552, 49.5139],
            [13.9772, 49.5116],
            [13.9825, 49.5239],
            [13.9937, 49.5242],
            [13.998, 49.5184],
            [14.0092, 49.5362],
            [14.0148, 49.5391],
            [14.0098, 49.5469],
            [14.0108, 49.551],
            [14.0237, 49.5483],
            [14.0393, 49.5598],
            [14.0436, 49.5582],
            [14.0591, 49.5614],
            [14.0683, 49.5543],
            [14.0654, 49.534],
            [14.0726, 49.5286],
            [14.1125, 49.5283],
            [14.1226, 49.5311],
            [14.1214, 49.538],
            [14.1333, 49.5335],
            [14.1412, 49.5341],
            [14.1499, 49.5313],
            [14.1962, 49.5372],
            [14.2044, 49.5361],
            [14.2097, 49.5316],
            [14.2149, 49.5415],
            [14.2262, 49.5488],
            [14.2226, 49.5584],
            [14.2324, 49.565],
            [14.2403, 49.5578],
            [14.2506, 49.5615],
            [14.2613, 49.5603],
            [14.272, 49.5642],
            [14.2927, 49.5592],
            [14.2925, 49.5534],
            [14.2995, 49.5442],
            [14.3063, 49.5493],
            [14.3265, 49.5395],
            [14.3296, 49.527],
            [14.3428, 49.5297],
            [14.3473, 49.528],
            [14.3802, 49.5367],
            [14.3841, 49.5413],
            [14.3935, 49.5431],
            [14.4033, 49.5406],
            [14.4101, 49.5532],
            [14.4302, 49.5466],
            [14.4439, 49.5488],
            [14.456, 49.5342],
            [14.4751, 49.5369],
            [14.4778, 49.5454],
            [14.4946, 49.5401],
            [14.5091, 49.544],
            [14.5154, 49.5347],
            [14.5284, 49.5333],
            [14.5343, 49.5367],
            [14.5608, 49.5311],
            [14.5732, 49.5335],
            [14.5912, 49.5418],
            [14.5901, 49.5331],
            [14.5821, 49.5264],
            [14.5826, 49.5182],
            [14.5945, 49.5186],
            [14.6102, 49.5224],
            [14.6222, 49.5187],
            [14.6344, 49.5194],
            [14.6395, 49.5054],
            [14.6494, 49.504],
            [14.6551, 49.5153],
            [14.6571, 49.5251],
            [14.6728, 49.5282],
            [14.691, 49.5381],
            [14.6947, 49.5431],
            [14.7103, 49.5406],
            [14.7241, 49.5465],
            [14.736, 49.5486],
            [14.7372, 49.5524],
            [14.7253, 49.5603],
            [14.7223, 49.5668],
            [14.7234, 49.5778],
            [14.7289, 49.5781],
            [14.7327, 49.588],
            [14.7259, 49.5919],
            [14.7361, 49.5957],
            [14.7465, 49.5952],
            [14.7428, 49.6052],
            [14.7619, 49.6115],
            [14.7673, 49.6173],
            [14.7795, 49.6053],
            [14.7873, 49.6053],
            [14.8038, 49.6014],
            [14.8136, 49.6024],
            [14.8208, 49.5924],
            [14.8277, 49.5873],
            [14.8381, 49.5838],
            [14.8481, 49.5779],
            [14.856, 49.5883],
            [14.8634, 49.586],
            [14.8772, 49.5868],
            [14.8742, 49.5814],
            [14.8777, 49.5726],
            [14.8834, 49.5689],
            [14.8879, 49.5613],
            [14.8956, 49.5562],
            [14.894, 49.553],
            [14.9029, 49.5444],
            [14.9097, 49.5429],
            [14.9272, 49.5461],
            [14.9325, 49.5502],
            [14.93, 49.5411],
            [14.9342, 49.538],
            [14.9427, 49.5244],
            [14.9311, 49.521],
            [14.9269, 49.5232],
            [14.9104, 49.523],
            [14.9019, 49.5174],
            [14.9016, 49.513],
            [14.9127, 49.5112],
            [14.9251, 49.4998],
            [14.9279, 49.4878],
            [14.9211, 49.4704],
            [14.9289, 49.4674],
            [14.9237, 49.4517],
            [14.9152, 49.4462],
            [14.9122, 49.4403],
            [14.9124, 49.4307],
            [14.9006, 49.4281],
            [14.9026, 49.4232],
            [14.9144, 49.4259],
            [14.9016, 49.4104],
            [14.9062, 49.4049],
            [14.901, 49.3955],
            [14.8936, 49.3944],
            [14.8908, 49.3738],
            [14.9001, 49.3722],
            [14.9118, 49.3669],
            [14.9175, 49.3677],
            [14.9253, 49.3591],
            [14.9263, 49.3532],
            [14.9043, 49.3329],
            [14.9157, 49.3267],
            [14.9295, 49.3166],
            [14.9251, 49.3106],
            [14.9392, 49.307],
            [14.9506, 49.3019],
            [14.9497, 49.2959],
            [14.9619, 49.2928],
            [14.9771, 49.2816],
            [14.9792, 49.2749],
            [14.9918, 49.2732],
            [15.002, 49.2846],
            [15.0302, 49.2963],
            [15.0424, 49.2901],
            [15.0448, 49.2834],
            [15.0583, 49.2746],
            [15.0856, 49.2669],
            [15.0857, 49.2719],
            [15.1046, 49.2692],
            [15.1029, 49.2613],
            [15.098, 49.2598],
            [15.1008, 49.2487],
            [15.0918, 49.2456],
            [15.0992, 49.2406],
            [15.1105, 49.2469],
            [15.1203, 49.2479],
            [15.1312, 49.243],
            [15.1403, 49.224],
            [15.1551, 49.2178],
            [15.1659, 49.2161],
            [15.1759, 49.2212],
            [15.1968, 49.2353],
            [15.2053, 49.226],
            [15.2167, 49.228],
            [15.2237, 49.2247],
            [15.2375, 49.2228],
            [15.2563, 49.2179],
            [15.2549, 49.2106],
            [15.27, 49.215],
            [15.2785, 49.2117],
            [15.2818, 49.2168],
            [15.2963, 49.2162],
            [15.3044, 49.2133],
            [15.3117, 49.2179],
            [15.3333, 49.2184],
            [15.3442, 49.2155],
            [15.3433, 49.209],
            [15.3501, 49.1898],
            [15.3426, 49.1789],
            [15.3445, 49.1666],
            [15.3529, 49.1528],
            [15.3651, 49.148],
            [15.3726, 49.1507],
            [15.3861, 49.1344],
            [15.4058, 49.1313],
            [15.4183, 49.1262],
            [15.4242, 49.1177],
            [15.4298, 49.1286],
            [15.4414, 49.1327],
            [15.4558, 49.1214],
            [15.4678, 49.1191],
            [15.4695, 49.1226],
            [15.4912, 49.119],
            [15.5029, 49.1189],
            [15.5109, 49.1291],
            [15.517, 49.1308],
            [15.5356, 49.1249],
            [15.5373, 49.1344],
            [15.544, 49.1353],
            [15.5662, 49.1259],
            [15.5688, 49.1178],
            [15.5593, 49.1169],
            [15.5603, 49.1119],
            [15.5746, 49.1033],
            [15.58, 49.1077],
            [15.5887, 49.1036],
            [15.5832, 49.0989],
            [15.602, 49.087],
            [15.5948, 49.0814],
            [15.5973, 49.0772],
            [15.5917, 49.0718],
            [15.571, 49.0709],
            [15.5533, 49.0766],
            [15.5514, 49.0715],
            [15.5563, 49.0666],
            [15.5543, 49.0608],
            [15.5418, 49.0604],
            [15.5434, 49.0517],
            [15.5506, 49.0497],
            [15.5485, 49.0429],
            [15.5313, 49.0346],
            [15.5287, 49.0245],
            [15.5179, 49.0164],
            [15.5092, 49.0174],
            [15.508, 49.0119],
            [15.4916, 49.0036],
            [15.4879, 48.9974],
            [15.49, 48.9926],
            [15.5032, 48.9931],
            [15.5087, 48.9983],
            [15.5242, 48.9941],
            [15.5242, 48.9827],
            [15.5219, 48.9786],
            [15.5322, 48.9741],
            [15.5579, 48.9722],
            [15.5717, 48.9664],
            [15.5801, 48.9659],
            [15.5771, 48.9555],
            [15.5863, 48.9486],
            [15.5793, 48.9402],
            [15.5626, 48.9314],
            [15.5593, 48.9242],
            [15.5531, 48.9245],
            [15.541, 48.908],
            [15.515, 48.9131],
            [15.5073, 48.9198],
            [15.5015, 48.9362],
            [15.4853, 48.9432],
            [15.4776, 48.9446],
            [15.4688, 48.9517],
            [15.4564, 48.9451],
            [15.4519, 48.949],
            [15.4257, 48.9513],
            [15.4125, 48.9566],
            [15.4032, 48.9636],
            [15.3913, 48.9667],
            [15.3889, 48.9725],
            [15.3672, 48.9816],
            [15.3485, 48.9846],
            [15.3327, 48.9846],
            [15.322, 48.9761],
            [15.3128, 48.9805],
            [15.307, 48.9784],
            [15.2915, 48.9841],
            [15.2895, 48.9925],
            [15.2776, 48.994],
            [15.2758, 48.9874],
            [15.2817, 48.9807],
            [15.2723, 48.9667],
            [15.2687, 48.9574],
            [15.262, 48.9535],
            [15.2409, 48.9595],
            [15.2378, 48.9574],
            [15.225, 48.9601],
            [15.2166, 48.9584],
            [15.1999, 48.9519],
            [15.1899, 48.9431],
            [15.1774, 48.9472],
            [15.1701, 48.9428],
            [15.161, 48.9424],
            [15.1562, 48.948],
            [15.163, 48.9575],
            [15.1697, 48.9614],
            [15.1655, 48.9698],
            [15.1596, 48.972],
            [15.1608, 48.9778],
            [15.157, 48.9845],
            [15.1564, 48.9931],
            [15.142, 48.9971],
            [15.1218, 48.9972],
            [15.1077, 48.9986],
            [15.1028, 48.9937],
            [15.0901, 48.9972],
            [15.0677, 48.9993],
            [15.0649, 49.0149],
            [15.0586, 49.0158],
            [15.0413, 49.0111],
            [15.0209, 49.0202],
            [15.0111, 49.0137],
            [14.9911, 49.0092],
            [14.9998, 49.0043],
            [14.9945, 48.9895],
            [14.9854, 48.987],
            [14.9784, 48.98],
            [14.9763, 48.9693],
            [14.9768, 48.9537],
            [14.9804, 48.9437],
            [14.9855, 48.9407],
            [14.9842, 48.9324],
            [14.9788, 48.9272],
            [14.9908, 48.9148],
            [14.9919, 48.9043],
            [14.9829, 48.8901],
            [14.9841, 48.8748],
            [14.972, 48.8744],
            [14.9728, 48.8535],
            [14.9776, 48.8531],
            [14.9845, 48.8424],
            [14.9721, 48.842],
            [14.9683, 48.8362],
            [14.9689, 48.8233],
            [14.9591, 48.8156],
            [14.9569, 48.8078],
            [14.9594, 48.8039],
            [14.9534, 48.7978],
            [14.9549, 48.7883],
            [14.9694, 48.7847],
            [14.9802, 48.7789],
            [14.9797, 48.7718],
            [14.9556, 48.7581],
            [14.9482, 48.7629],
            [14.9422, 48.7612],
            [14.932, 48.7664],
            [14.9162, 48.7694],
            [14.9062, 48.767],
            [14.8959, 48.7724],
            [14.888, 48.7701],
            [14.8857, 48.777],
            [14.8806, 48.7803],
            [14.8684, 48.781],
            [14.8636, 48.7774],
            [14.8511, 48.7773],
            [14.8426, 48.7838],
            [14.8305, 48.7805],
            [14.8259, 48.7834],
            [14.8095, 48.7789],
            [14.8065, 48.7716],
            [14.8121, 48.7653],
            [14.801, 48.7473],
            [14.8082, 48.7337],
            [14.7936, 48.7292],
            [14.7843, 48.7172],
            [14.7733, 48.7113],
            [14.7574, 48.7093],
            [14.7552, 48.703],
            [14.7485, 48.7032],
            [14.7355, 48.6982],
            [14.7264, 48.6855],
            [14.7263, 48.6782],
            [14.719, 48.6678],
            [14.7123, 48.6504],
            [14.7186, 48.6482],
            [14.7214, 48.6362],
            [14.7143, 48.617],
            [14.721, 48.601],
            [14.7118, 48.5967],
            [14.7064, 48.5911],
            [14.7076, 48.5864],
            [14.7013, 48.5821],
            [14.6948, 48.5849],
            [14.6712, 48.5813],
            [14.663, 48.583],
            [14.6583, 48.5963],
            [14.6534, 48.6048],
            [14.6448, 48.61],
            [14.6313, 48.6042],
            [14.6241, 48.6063],
            [14.6201, 48.6189],
            [14.6141, 48.6274],
            [14.605, 48.628],
            [14.591, 48.6188],
            [14.5801, 48.6164],
            [14.5649, 48.6086],
            [14.5534, 48.6064],
            [14.5512, 48.612],
            [14.5399, 48.6153],
            [14.5273, 48.6157],
            [14.5171, 48.6194],
            [14.5026, 48.6168],
            [14.4949, 48.6224],
            [14.4926, 48.6326],
            [14.4803, 48.637],
            [14.4681, 48.6461],
            [14.4595, 48.6439],
            [14.4443, 48.6432],
            [14.4448, 48.6382],
            [14.4524, 48.6323],
            [14.4555, 48.6246],
            [14.4477, 48.6197],
            [14.4481, 48.6127],
            [14.4419, 48.5998],
            [14.4303, 48.5883],
            [14.4246, 48.5881],
            [14.4138, 48.5932],
            [14.4085, 48.5914],
            [14.3871, 48.593],
            [14.3876, 48.5848],
            [14.3946, 48.5779],
            [14.3835, 48.5697],
            [14.3694, 48.5696],
            [14.3667, 48.5754],
            [14.3577, 48.5744],
            [14.3515, 48.5644],
            [14.3412, 48.5542],
            [14.3279, 48.5534],
            [14.3245, 48.5637],
            [14.31, 48.564],
            [14.2944, 48.5683],
            [14.2808, 48.5737],
            [14.2718, 48.5811],
            [14.2529, 48.5812],
            [14.242, 48.5772],
            [14.23, 48.5841],
            [14.1835, 48.5933],
            [14.1658, 48.5906],
            [14.154, 48.5952],
            [14.141, 48.5949],
            [14.1303, 48.5973],
            [14.116, 48.5891],
            [14.1043, 48.5898],
            [14.0958, 48.5932],
            [14.0965, 48.5997],
            [14.0725, 48.5999],
            [14.0687, 48.6066],
            [14.0543, 48.6043],
            [14.0506, 48.6101],
            [14.0418, 48.6129],
            [14.048, 48.6261],
            [14.0234, 48.6306],
            [14.0177, 48.6365],
            [14.0111, 48.6384],
            [14.0235, 48.6461],
            [14.0249, 48.6529],
            [14.0342, 48.6551],
            [14.053, 48.6528],
            [14.0574, 48.6596],
            [14.0597, 48.6741],
            [14.0391, 48.6759],
            [14.0247, 48.6834],
            [14.0254, 48.689],
            [14.0163, 48.6915],
            [14.0181, 48.6961],
            [14.0033, 48.7083],
            [13.9893, 48.7103],
            [13.9796, 48.7097],
            [13.9684, 48.7132],
            [13.9564, 48.7134],
            [13.9531, 48.7205],
            [13.9414, 48.7215],
            [13.9198, 48.7405],
            [13.9045, 48.7497],
            [13.8958, 48.7567],
            [13.8894, 48.7582],
            [13.8741, 48.7673],
            [13.8442, 48.7723],
            [13.8337, 48.7703],
            [13.8126, 48.7741],
            [13.8054, 48.7818],
            [13.8093, 48.7847],
            [13.8145, 48.7976],
            [13.7957, 48.8147],
            [13.7987, 48.8186],
            [13.7882, 48.8251],
            [13.7835, 48.8317],
            [13.7726, 48.83],
            [13.7639, 48.835],
            [13.7553, 48.8542],
            [13.7499, 48.8583],
            [13.75, 48.8658],
            [13.7381, 48.8791],
            [13.7381, 48.8857],
            [13.7321, 48.8867],
            [13.716, 48.8774],
            [13.7012, 48.8805],
            [13.689, 48.8777],
            [13.6719, 48.8797],
            [13.6682, 48.8911],
            [13.6554, 48.8937],
            [13.642, 48.9164],
            [13.6379, 48.9256],
            [13.6229, 48.9384],
            [13.6308, 48.9468],
            [13.6217, 48.9492],
            [13.6025, 48.9405],
            [13.5918, 48.9527],
            [13.5924, 48.9615],
            [13.5807, 48.97],
            [13.5701, 48.966],
            [13.5637, 48.9702],
            [13.5511, 48.9674],
            [13.5509, 48.9784],
            [13.5369, 48.9854],
            [13.5405, 48.9926],
            [13.5391, 48.9975],
            [13.5458, 49.005],
            [13.5445, 49.019],
            [13.5575, 49.0318],
            [13.5588, 49.0384],
            [13.5722, 49.0502],
            [13.5885, 49.0444],
            [13.5944, 49.0503],
            [13.6045, 49.0556],
            [13.606, 49.0701],
            [13.5997, 49.0778],
            [13.5942, 49.0915],
            [13.5847, 49.0974],
            [13.5804, 49.1053],
            [13.5966, 49.1091],
            [13.594, 49.1209],
            [13.5894, 49.1267],
            [13.5998, 49.139],
            [13.5888, 49.1462],
            [13.5972, 49.1481],
            [13.6086, 49.1453],
            [13.6182, 49.1405],
            [13.6335, 49.137],
            [13.6458, 49.1456],
            [13.6555, 49.1503],
            [13.6641, 49.1593],
            [13.6929, 49.1687],
            [13.6912, 49.1847],
            [13.6989, 49.1925],
            [13.7141, 49.1922],
            [13.7194, 49.1955],
            [13.7146, 49.2017],
            [13.7148, 49.2082],
            [13.6978, 49.2143],
            [13.6889, 49.2249],
            [13.6918, 49.2324],
            [13.7047, 49.2377],
            [13.7132, 49.2458],
            [13.7198, 49.2483],
            [13.7289, 49.2577],
            [13.7072, 49.258],
            [13.7023, 49.2686],
            [13.7032, 49.2767],
            [13.7139, 49.279],
            [13.726, 49.2774],
            [13.7307, 49.2821],
            [13.7352, 49.2923],
            [13.7246, 49.2932],
            [13.72, 49.2987],
            [13.7341, 49.3083],
            [13.7372, 49.3169],
            [13.7485, 49.3183],
            [13.7552, 49.3247],
            [13.7698, 49.3232],
            [13.7719, 49.3292],
            [13.7682, 49.3339],
            [13.7693, 49.3416],
            [13.7767, 49.3496],
            [13.7792, 49.3559],
            [13.7907, 49.3697],
            [13.7716, 49.3795],
            [13.7649, 49.3933],
            [13.7585, 49.4016],
            [13.7653, 49.4075],
            [13.7621, 49.4114],
            [13.7679, 49.4246],
            [13.7821, 49.4356],
            [13.7651, 49.4523],
            [13.7682, 49.4588],
            [13.7635, 49.4798],
            [13.7491, 49.4864],
            [13.7476, 49.4928],
            [13.7573, 49.4967],
            [13.7571, 49.5015],
            [13.7503, 49.5044],
            [13.7659, 49.5136],
          ],
        ],
      },
      properties: {
        name: 'Jihočeský',
        id: 'CZ-31',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'South Bohemia',
      },
      id: 'CZ-31',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.3018, 50.0991],
            [13.3216, 50.1008],
            [13.3267, 50.0978],
            [13.3248, 50.088],
            [13.3346, 50.0871],
            [13.3454, 50.0995],
            [13.357, 50.1008],
            [13.3579, 50.0915],
            [13.3707, 50.0901],
            [13.3685, 50.0801],
            [13.3816, 50.0782],
            [13.3898, 50.0819],
            [13.4052, 50.0846],
            [13.4098, 50.0895],
            [13.4162, 50.0834],
            [13.4189, 50.0765],
            [13.4246, 50.0756],
            [13.4299, 50.0682],
            [13.4306, 50.0622],
            [13.4139, 50.0613],
            [13.4098, 50.0487],
            [13.4005, 50.0429],
            [13.4021, 50.0321],
            [13.4143, 50.0341],
            [13.4233, 50.0334],
            [13.4244, 50.0285],
            [13.4348, 50.0276],
            [13.4467, 50.0413],
            [13.4653, 50.0396],
            [13.4798, 50.0422],
            [13.4782, 50.0354],
            [13.4872, 50.0317],
            [13.5008, 50.0398],
            [13.5147, 50.0415],
            [13.5227, 50.0326],
            [13.5086, 50.0306],
            [13.5022, 50.0268],
            [13.5023, 50.0211],
            [13.5144, 50.021],
            [13.5197, 50.0139],
            [13.5335, 50.0153],
            [13.5529, 50.0092],
            [13.556, 50.0039],
            [13.5633, 50.0017],
            [13.5695, 49.9944],
            [13.5905, 49.9922],
            [13.6181, 49.9919],
            [13.631, 49.9954],
            [13.6301, 49.9998],
            [13.6551, 50.0018],
            [13.66, 49.9972],
            [13.6676, 49.9966],
            [13.6687, 49.988],
            [13.6797, 49.9771],
            [13.6915, 49.9781],
            [13.6998, 49.9701],
            [13.7006, 49.964],
            [13.7266, 49.9671],
            [13.7397, 49.9634],
            [13.7475, 49.9649],
            [13.7504, 49.9535],
            [13.7493, 49.9428],
            [13.7619, 49.9432],
            [13.7786, 49.9394],
            [13.783, 49.9411],
            [13.7994, 49.9301],
            [13.8209, 49.9226],
            [13.818, 49.9104],
            [13.8266, 49.901],
            [13.8273, 49.8799],
            [13.8341, 49.8678],
            [13.8079, 49.8676],
            [13.8115, 49.8595],
            [13.8114, 49.8523],
            [13.8033, 49.8447],
            [13.8062, 49.8365],
            [13.7981, 49.8308],
            [13.7989, 49.8229],
            [13.8069, 49.8207],
            [13.8042, 49.8046],
            [13.7901, 49.8005],
            [13.7993, 49.7802],
            [13.794, 49.7681],
            [13.7832, 49.7659],
            [13.7828, 49.7608],
            [13.8015, 49.7649],
            [13.813, 49.7601],
            [13.819, 49.7543],
            [13.8016, 49.7467],
            [13.7846, 49.7415],
            [13.7741, 49.7342],
            [13.7734, 49.7267],
            [13.7613, 49.7181],
            [13.7409, 49.7277],
            [13.7368, 49.7316],
            [13.7042, 49.7208],
            [13.6961, 49.717],
            [13.6881, 49.7048],
            [13.6771, 49.7011],
            [13.6723, 49.6962],
            [13.6759, 49.6779],
            [13.6821, 49.6759],
            [13.688, 49.6838],
            [13.7004, 49.6856],
            [13.7277, 49.6788],
            [13.7227, 49.6746],
            [13.7133, 49.6749],
            [13.6899, 49.6661],
            [13.6872, 49.662],
            [13.7075, 49.6571],
            [13.7155, 49.6462],
            [13.7068, 49.641],
            [13.7142, 49.6361],
            [13.7079, 49.6287],
            [13.7208, 49.6256],
            [13.7292, 49.6214],
            [13.7538, 49.6204],
            [13.7537, 49.6143],
            [13.7469, 49.609],
            [13.7516, 49.5967],
            [13.7399, 49.5856],
            [13.7439, 49.5764],
            [13.7405, 49.5681],
            [13.7446, 49.5594],
            [13.7444, 49.5514],
            [13.7641, 49.5455],
            [13.762, 49.5393],
            [13.7706, 49.5369],
            [13.7723, 49.5319],
            [13.7659, 49.5136],
            [13.7503, 49.5044],
            [13.7571, 49.5015],
            [13.7573, 49.4967],
            [13.7476, 49.4928],
            [13.7491, 49.4864],
            [13.7635, 49.4798],
            [13.7682, 49.4588],
            [13.7651, 49.4523],
            [13.7821, 49.4356],
            [13.7679, 49.4246],
            [13.7621, 49.4114],
            [13.7653, 49.4075],
            [13.7585, 49.4016],
            [13.7649, 49.3933],
            [13.7716, 49.3795],
            [13.7907, 49.3697],
            [13.7792, 49.3559],
            [13.7767, 49.3496],
            [13.7693, 49.3416],
            [13.7682, 49.3339],
            [13.7719, 49.3292],
            [13.7698, 49.3232],
            [13.7552, 49.3247],
            [13.7485, 49.3183],
            [13.7372, 49.3169],
            [13.7341, 49.3083],
            [13.72, 49.2987],
            [13.7246, 49.2932],
            [13.7352, 49.2923],
            [13.7307, 49.2821],
            [13.726, 49.2774],
            [13.7139, 49.279],
            [13.7032, 49.2767],
            [13.7023, 49.2686],
            [13.7072, 49.258],
            [13.7289, 49.2577],
            [13.7198, 49.2483],
            [13.7132, 49.2458],
            [13.7047, 49.2377],
            [13.6918, 49.2324],
            [13.6889, 49.2249],
            [13.6978, 49.2143],
            [13.7148, 49.2082],
            [13.7146, 49.2017],
            [13.7194, 49.1955],
            [13.7141, 49.1922],
            [13.6989, 49.1925],
            [13.6912, 49.1847],
            [13.6929, 49.1687],
            [13.6641, 49.1593],
            [13.6555, 49.1503],
            [13.6458, 49.1456],
            [13.6335, 49.137],
            [13.6182, 49.1405],
            [13.6086, 49.1453],
            [13.5972, 49.1481],
            [13.5888, 49.1462],
            [13.5998, 49.139],
            [13.5894, 49.1267],
            [13.594, 49.1209],
            [13.5966, 49.1091],
            [13.5804, 49.1053],
            [13.5847, 49.0974],
            [13.5942, 49.0915],
            [13.5997, 49.0778],
            [13.606, 49.0701],
            [13.6045, 49.0556],
            [13.5944, 49.0503],
            [13.5885, 49.0444],
            [13.5722, 49.0502],
            [13.5588, 49.0384],
            [13.5575, 49.0318],
            [13.5445, 49.019],
            [13.5458, 49.005],
            [13.5391, 48.9975],
            [13.5405, 48.9926],
            [13.5369, 48.9854],
            [13.5509, 48.9784],
            [13.5511, 48.9674],
            [13.5445, 48.9674],
            [13.5371, 48.9732],
            [13.5259, 48.9727],
            [13.5158, 48.9686],
            [13.5081, 48.9689],
            [13.506, 48.9598],
            [13.5081, 48.9423],
            [13.4946, 48.9413],
            [13.4868, 48.9455],
            [13.4832, 48.9512],
            [13.4677, 48.9545],
            [13.459, 48.962],
            [13.4403, 48.967],
            [13.4249, 48.9763],
            [13.4027, 48.9871],
            [13.4024, 48.9939],
            [13.4094, 49.0059],
            [13.4016, 49.0125],
            [13.4051, 49.0223],
            [13.3986, 49.0377],
            [13.3926, 49.0413],
            [13.3966, 49.0504],
            [13.3845, 49.0569],
            [13.376, 49.058],
            [13.3714, 49.0665],
            [13.3582, 49.0736],
            [13.3466, 49.0827],
            [13.3365, 49.0937],
            [13.3123, 49.1045],
            [13.2893, 49.1184],
            [13.2788, 49.1203],
            [13.2514, 49.1166],
            [13.2377, 49.1135],
            [13.2054, 49.122],
            [13.184, 49.1335],
            [13.171, 49.1443],
            [13.1768, 49.1624],
            [13.1719, 49.1724],
            [13.1614, 49.1745],
            [13.1486, 49.1818],
            [13.1424, 49.1885],
            [13.1306, 49.1959],
            [13.1186, 49.1988],
            [13.1105, 49.204],
            [13.1143, 49.218],
            [13.0922, 49.2256],
            [13.0872, 49.2296],
            [13.0897, 49.2358],
            [13.0871, 49.2417],
            [13.079, 49.2472],
            [13.0705, 49.2468],
            [13.0566, 49.2553],
            [13.058, 49.2601],
            [13.052, 49.2642],
            [13.0336, 49.2645],
            [13.026, 49.2779],
            [13.0306, 49.2878],
            [13.0288, 49.3041],
            [13.0062, 49.3059],
            [13.0025, 49.3131],
            [12.9884, 49.3212],
            [12.9719, 49.334],
            [12.9586, 49.3366],
            [12.95, 49.3427],
            [12.9372, 49.342],
            [12.9287, 49.3447],
            [12.9199, 49.3431],
            [12.9044, 49.3488],
            [12.8813, 49.3502],
            [12.8815, 49.3433],
            [12.889, 49.3302],
            [12.8797, 49.33],
            [12.8602, 49.3385],
            [12.8534, 49.3381],
            [12.8481, 49.3447],
            [12.8419, 49.3415],
            [12.8157, 49.3407],
            [12.8006, 49.3417],
            [12.7812, 49.3468],
            [12.7789, 49.3552],
            [12.782, 49.3596],
            [12.7762, 49.3641],
            [12.7682, 49.3655],
            [12.7583, 49.3754],
            [12.761, 49.3842],
            [12.7573, 49.3949],
            [12.7327, 49.4101],
            [12.7157, 49.414],
            [12.7084, 49.4248],
            [12.6784, 49.4265],
            [12.6573, 49.4346],
            [12.6553, 49.447],
            [12.6568, 49.4535],
            [12.6483, 49.4681],
            [12.6426, 49.4694],
            [12.6336, 49.4773],
            [12.6435, 49.485],
            [12.6449, 49.4943],
            [12.6395, 49.5003],
            [12.6444, 49.5127],
            [12.6441, 49.5238],
            [12.6363, 49.5327],
            [12.6287, 49.527],
            [12.6023, 49.5325],
            [12.5898, 49.5382],
            [12.5941, 49.5422],
            [12.5826, 49.5504],
            [12.5744, 49.559],
            [12.5714, 49.569],
            [12.5768, 49.5852],
            [12.5637, 49.5973],
            [12.5636, 49.6079],
            [12.5578, 49.6107],
            [12.5591, 49.6203],
            [12.5462, 49.6212],
            [12.5286, 49.6178],
            [12.5231, 49.6344],
            [12.5285, 49.6393],
            [12.5188, 49.6434],
            [12.5244, 49.652],
            [12.5221, 49.66],
            [12.5291, 49.6668],
            [12.5231, 49.6764],
            [12.5217, 49.6865],
            [12.5052, 49.6854],
            [12.4846, 49.6881],
            [12.4824, 49.6947],
            [12.4579, 49.7017],
            [12.442, 49.7036],
            [12.4344, 49.7138],
            [12.4293, 49.7254],
            [12.4241, 49.7303],
            [12.4143, 49.7327],
            [12.4037, 49.7426],
            [12.4066, 49.7481],
            [12.4013, 49.7546],
            [12.402, 49.7608],
            [12.4117, 49.765],
            [12.465, 49.7849],
            [12.4728, 49.7866],
            [12.474, 49.7916],
            [12.4661, 49.7951],
            [12.4697, 49.8032],
            [12.4652, 49.8095],
            [12.4733, 49.8151],
            [12.4762, 49.8249],
            [12.4751, 49.8358],
            [12.4861, 49.8415],
            [12.4984, 49.8382],
            [12.4979, 49.8565],
            [12.5098, 49.858],
            [12.5185, 49.8685],
            [12.5215, 49.8813],
            [12.53, 49.8874],
            [12.5403, 49.8915],
            [12.5505, 49.9007],
            [12.5515, 49.9055],
            [12.5658, 49.9023],
            [12.5817, 49.903],
            [12.5974, 49.8972],
            [12.611, 49.8992],
            [12.6104, 49.906],
            [12.6213, 49.9129],
            [12.6465, 49.9164],
            [12.6574, 49.9148],
            [12.6643, 49.9109],
            [12.6698, 49.8966],
            [12.6747, 49.8909],
            [12.682, 49.8926],
            [12.6807, 49.9027],
            [12.6731, 49.9087],
            [12.6965, 49.9125],
            [12.7127, 49.9118],
            [12.7103, 49.9199],
            [12.7301, 49.9228],
            [12.7451, 49.9323],
            [12.75, 49.9298],
            [12.7614, 49.9317],
            [12.7732, 49.9288],
            [12.7936, 49.9405],
            [12.8001, 49.9365],
            [12.8099, 49.9393],
            [12.8151, 49.9339],
            [12.8415, 49.9316],
            [12.8501, 49.9286],
            [12.8525, 49.9236],
            [12.8683, 49.9269],
            [12.8776, 49.9246],
            [12.8798, 49.9295],
            [12.8885, 49.934],
            [12.9053, 49.9326],
            [12.9068, 49.9284],
            [12.9249, 49.9204],
            [12.9322, 49.9263],
            [12.9418, 49.9295],
            [12.966, 49.9471],
            [12.9677, 49.9528],
            [12.9601, 49.9736],
            [12.9745, 49.977],
            [13.0001, 49.9784],
            [13.0003, 49.9882],
            [13.0129, 49.9909],
            [13.0175, 49.9865],
            [13.0272, 49.9952],
            [13.0484, 50.0023],
            [13.0552, 50.0103],
            [13.0693, 50.01],
            [13.0693, 50.0186],
            [13.0844, 50.0193],
            [13.0826, 50.0263],
            [13.0976, 50.0347],
            [13.1024, 50.027],
            [13.1194, 50.0226],
            [13.1269, 50.0263],
            [13.1428, 50.0237],
            [13.1503, 50.0253],
            [13.1623, 50.0229],
            [13.1798, 50.0158],
            [13.1839, 50.012],
            [13.1972, 50.0068],
            [13.2022, 50.0022],
            [13.2156, 49.9974],
            [13.2284, 49.996],
            [13.2314, 50.0028],
            [13.2313, 50.0182],
            [13.2411, 50.024],
            [13.239, 50.0383],
            [13.2301, 50.042],
            [13.2349, 50.0475],
            [13.2532, 50.0457],
            [13.2552, 50.051],
            [13.2725, 50.0531],
            [13.2822, 50.0526],
            [13.284, 50.0577],
            [13.2929, 50.0602],
            [13.2986, 50.0701],
            [13.2985, 50.0749],
            [13.279, 50.076],
            [13.2838, 50.0876],
            [13.2934, 50.0963],
            [13.3018, 50.0991],
          ],
        ],
      },
      properties: {
        name: 'Plzeňský',
        id: 'CZ-32',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Plzeň',
      },
      id: 'CZ-32',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.9483, 50.4047],
            [12.9658, 50.3999],
            [12.9751, 50.3903],
            [12.9891, 50.3936],
            [12.9985, 50.3882],
            [13.0209, 50.3947],
            [13.0267, 50.39],
            [13.0445, 50.3843],
            [13.059, 50.3874],
            [13.0642, 50.3911],
            [13.0792, 50.3813],
            [13.0896, 50.3802],
            [13.0986, 50.3825],
            [13.1062, 50.3711],
            [13.1001, 50.3674],
            [13.1, 50.3557],
            [13.1159, 50.3473],
            [13.1197, 50.3541],
            [13.132, 50.3592],
            [13.15, 50.3572],
            [13.1579, 50.3529],
            [13.1872, 50.3534],
            [13.2097, 50.3611],
            [13.2087, 50.3547],
            [13.2208, 50.3522],
            [13.2286, 50.3382],
            [13.2369, 50.327],
            [13.2209, 50.3155],
            [13.2208, 50.3125],
            [13.2333, 50.3026],
            [13.2334, 50.2973],
            [13.2243, 50.294],
            [13.2253, 50.2871],
            [13.2193, 50.2774],
            [13.2307, 50.2733],
            [13.2327, 50.2564],
            [13.2361, 50.2526],
            [13.234, 50.2461],
            [13.2221, 50.242],
            [13.227, 50.2345],
            [13.2329, 50.2318],
            [13.2451, 50.2336],
            [13.2543, 50.2279],
            [13.2503, 50.2225],
            [13.2481, 50.2129],
            [13.2342, 50.2107],
            [13.2342, 50.2067],
            [13.2567, 50.2068],
            [13.2615, 50.1997],
            [13.27, 50.1951],
            [13.2709, 50.1862],
            [13.2808, 50.1756],
            [13.2754, 50.1693],
            [13.2872, 50.1678],
            [13.2853, 50.1605],
            [13.2677, 50.1564],
            [13.2675, 50.1534],
            [13.2801, 50.1487],
            [13.2833, 50.1382],
            [13.2507, 50.1459],
            [13.237, 50.1421],
            [13.2306, 50.134],
            [13.2454, 50.1253],
            [13.2674, 50.1284],
            [13.2737, 50.1275],
            [13.2795, 50.1199],
            [13.2942, 50.1137],
            [13.2956, 50.0999],
            [13.3018, 50.0991],
            [13.2934, 50.0963],
            [13.2838, 50.0876],
            [13.279, 50.076],
            [13.2985, 50.0749],
            [13.2986, 50.0701],
            [13.2929, 50.0602],
            [13.284, 50.0577],
            [13.2822, 50.0526],
            [13.2725, 50.0531],
            [13.2552, 50.051],
            [13.2532, 50.0457],
            [13.2349, 50.0475],
            [13.2301, 50.042],
            [13.239, 50.0383],
            [13.2411, 50.024],
            [13.2313, 50.0182],
            [13.2314, 50.0028],
            [13.2284, 49.996],
            [13.2156, 49.9974],
            [13.2022, 50.0022],
            [13.1972, 50.0068],
            [13.1839, 50.012],
            [13.1798, 50.0158],
            [13.1623, 50.0229],
            [13.1503, 50.0253],
            [13.1428, 50.0237],
            [13.1269, 50.0263],
            [13.1194, 50.0226],
            [13.1024, 50.027],
            [13.0976, 50.0347],
            [13.0826, 50.0263],
            [13.0844, 50.0193],
            [13.0693, 50.0186],
            [13.0693, 50.01],
            [13.0552, 50.0103],
            [13.0484, 50.0023],
            [13.0272, 49.9952],
            [13.0175, 49.9865],
            [13.0129, 49.9909],
            [13.0003, 49.9882],
            [13.0001, 49.9784],
            [12.9745, 49.977],
            [12.9601, 49.9736],
            [12.9677, 49.9528],
            [12.966, 49.9471],
            [12.9418, 49.9295],
            [12.9322, 49.9263],
            [12.9249, 49.9204],
            [12.9068, 49.9284],
            [12.9053, 49.9326],
            [12.8885, 49.934],
            [12.8798, 49.9295],
            [12.8776, 49.9246],
            [12.8683, 49.9269],
            [12.8525, 49.9236],
            [12.8501, 49.9286],
            [12.8415, 49.9316],
            [12.8151, 49.9339],
            [12.8099, 49.9393],
            [12.8001, 49.9365],
            [12.7936, 49.9405],
            [12.7732, 49.9288],
            [12.7614, 49.9317],
            [12.75, 49.9298],
            [12.7451, 49.9323],
            [12.7301, 49.9228],
            [12.7103, 49.9199],
            [12.7127, 49.9118],
            [12.6965, 49.9125],
            [12.6731, 49.9087],
            [12.6807, 49.9027],
            [12.682, 49.8926],
            [12.6747, 49.8909],
            [12.6698, 49.8966],
            [12.6643, 49.9109],
            [12.6574, 49.9148],
            [12.6465, 49.9164],
            [12.6213, 49.9129],
            [12.6104, 49.906],
            [12.611, 49.8992],
            [12.5974, 49.8972],
            [12.5817, 49.903],
            [12.5658, 49.9023],
            [12.5515, 49.9055],
            [12.5482, 49.9195],
            [12.5383, 49.9248],
            [12.5127, 49.9275],
            [12.4976, 49.9326],
            [12.4937, 49.9369],
            [12.4788, 49.9354],
            [12.4707, 49.948],
            [12.4788, 49.9571],
            [12.4885, 49.9573],
            [12.4939, 49.9695],
            [12.4994, 49.9719],
            [12.4902, 49.9811],
            [12.4769, 49.9805],
            [12.4695, 49.9874],
            [12.4699, 49.991],
            [12.4607, 49.995],
            [12.4324, 49.9845],
            [12.4262, 49.9907],
            [12.4352, 49.9937],
            [12.4314, 50.0023],
            [12.4231, 50.0005],
            [12.4126, 50.0054],
            [12.4025, 50.0048],
            [12.3971, 50.0102],
            [12.3792, 50.0143],
            [12.3783, 50.019],
            [12.3681, 50.0172],
            [12.3632, 50.0237],
            [12.349, 50.0307],
            [12.3471, 50.0357],
            [12.3359, 50.0391],
            [12.3281, 50.0324],
            [12.3171, 50.0456],
            [12.3176, 50.0527],
            [12.2999, 50.0583],
            [12.2794, 50.0552],
            [12.2717, 50.0612],
            [12.2619, 50.0581],
            [12.2618, 50.0664],
            [12.2751, 50.0763],
            [12.2658, 50.0787],
            [12.2456, 50.0915],
            [12.2425, 50.0956],
            [12.2337, 50.0939],
            [12.2288, 50.1024],
            [12.2102, 50.1029],
            [12.2004, 50.1119],
            [12.1959, 50.1246],
            [12.1969, 50.1323],
            [12.1949, 50.1429],
            [12.2033, 50.1456],
            [12.2038, 50.1548],
            [12.2114, 50.1589],
            [12.2158, 50.1685],
            [12.2076, 50.1691],
            [12.2034, 50.1859],
            [12.1891, 50.1943],
            [12.1963, 50.1973],
            [12.1829, 50.205],
            [12.1768, 50.2048],
            [12.1745, 50.2136],
            [12.1614, 50.2109],
            [12.1639, 50.2175],
            [12.158, 50.2263],
            [12.1467, 50.2322],
            [12.1272, 50.2329],
            [12.1094, 50.2375],
            [12.106, 50.247],
            [12.0917, 50.2494],
            [12.1016, 50.2551],
            [12.0977, 50.2619],
            [12.1078, 50.2634],
            [12.1155, 50.2679],
            [12.1248, 50.2687],
            [12.1402, 50.2773],
            [12.1211, 50.2934],
            [12.1203, 50.3014],
            [12.1254, 50.3064],
            [12.1194, 50.3115],
            [12.1063, 50.3142],
            [12.101, 50.318],
            [12.1156, 50.3201],
            [12.1221, 50.3146],
            [12.1402, 50.321],
            [12.1546, 50.3207],
            [12.1748, 50.3227],
            [12.1844, 50.3221],
            [12.1865, 50.3114],
            [12.1997, 50.3059],
            [12.195, 50.2927],
            [12.2019, 50.2883],
            [12.2022, 50.2718],
            [12.2141, 50.2698],
            [12.2313, 50.2715],
            [12.2356, 50.269],
            [12.2561, 50.268],
            [12.2654, 50.2582],
            [12.266, 50.2502],
            [12.2552, 50.2509],
            [12.253, 50.2546],
            [12.2414, 50.2503],
            [12.2395, 50.2456],
            [12.2529, 50.2406],
            [12.2543, 50.2366],
            [12.2749, 50.2329],
            [12.2905, 50.2195],
            [12.2854, 50.2158],
            [12.2863, 50.2011],
            [12.2748, 50.1963],
            [12.2778, 50.1913],
            [12.2902, 50.1857],
            [12.2943, 50.1747],
            [12.3102, 50.1726],
            [12.3267, 50.1732],
            [12.329, 50.1854],
            [12.3386, 50.1919],
            [12.3233, 50.2067],
            [12.3278, 50.2121],
            [12.3257, 50.2182],
            [12.3295, 50.2248],
            [12.3291, 50.2352],
            [12.3346, 50.2424],
            [12.3425, 50.2408],
            [12.3481, 50.2361],
            [12.3579, 50.2451],
            [12.3516, 50.2498],
            [12.3527, 50.2621],
            [12.3613, 50.2694],
            [12.3592, 50.2746],
            [12.3712, 50.2848],
            [12.3824, 50.289],
            [12.393, 50.2888],
            [12.3992, 50.2926],
            [12.4029, 50.3004],
            [12.3979, 50.3101],
            [12.4026, 50.3234],
            [12.4146, 50.3239],
            [12.4313, 50.3224],
            [12.4376, 50.3279],
            [12.4352, 50.3325],
            [12.4469, 50.3449],
            [12.4586, 50.3484],
            [12.4689, 50.3547],
            [12.4815, 50.3475],
            [12.4897, 50.3493],
            [12.4931, 50.355],
            [12.4882, 50.3626],
            [12.4871, 50.3707],
            [12.4955, 50.3774],
            [12.5107, 50.3928],
            [12.5124, 50.3967],
            [12.5276, 50.3961],
            [12.5338, 50.3992],
            [12.5582, 50.3992],
            [12.5764, 50.4034],
            [12.5811, 50.4067],
            [12.5986, 50.4056],
            [12.6081, 50.4083],
            [12.6133, 50.4141],
            [12.6272, 50.416],
            [12.6498, 50.4095],
            [12.6632, 50.4115],
            [12.6727, 50.4167],
            [12.6812, 50.4095],
            [12.6947, 50.405],
            [12.6957, 50.4004],
            [12.7077, 50.3992],
            [12.7089, 50.4082],
            [12.7177, 50.4166],
            [12.73, 50.4219],
            [12.7352, 50.4323],
            [12.7494, 50.4355],
            [12.7663, 50.4418],
            [12.7876, 50.4462],
            [12.795, 50.4492],
            [12.8049, 50.4351],
            [12.8096, 50.4365],
            [12.8057, 50.444],
            [12.8168, 50.4477],
            [12.8255, 50.4577],
            [12.8605, 50.4434],
            [12.8747, 50.4363],
            [12.8921, 50.4311],
            [12.9011, 50.4225],
            [12.9133, 50.4239],
            [12.9251, 50.4166],
            [12.9363, 50.412],
            [12.9381, 50.4067],
            [12.9483, 50.4047],
          ],
        ],
      },
      properties: {
        name: 'Karlovarský',
        id: 'CZ-41',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Karlovy Vary',
      },
      id: 'CZ-41',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.6196, 50.8579],
            [14.608, 50.8435],
            [14.5909, 50.8373],
            [14.5707, 50.8388],
            [14.5651, 50.8296],
            [14.5652, 50.8201],
            [14.5597, 50.8133],
            [14.5624, 50.8083],
            [14.5599, 50.8001],
            [14.5406, 50.7955],
            [14.5238, 50.7959],
            [14.5107, 50.8004],
            [14.5067, 50.8062],
            [14.4954, 50.8101],
            [14.483, 50.8102],
            [14.4755, 50.8158],
            [14.4661, 50.8136],
            [14.4591, 50.809],
            [14.4525, 50.8135],
            [14.4475, 50.8075],
            [14.4569, 50.791],
            [14.448, 50.7849],
            [14.4573, 50.7799],
            [14.4468, 50.7729],
            [14.4336, 50.7705],
            [14.4276, 50.7655],
            [14.4266, 50.7585],
            [14.4171, 50.7421],
            [14.4298, 50.7359],
            [14.425, 50.7332],
            [14.4094, 50.7323],
            [14.4038, 50.7262],
            [14.3777, 50.7198],
            [14.371, 50.6968],
            [14.3783, 50.6928],
            [14.3745, 50.6876],
            [14.3535, 50.6787],
            [14.3434, 50.6661],
            [14.3425, 50.6574],
            [14.3509, 50.6554],
            [14.3588, 50.657],
            [14.366, 50.6362],
            [14.3597, 50.628],
            [14.3762, 50.6166],
            [14.383, 50.6073],
            [14.3799, 50.602],
            [14.3926, 50.5935],
            [14.4058, 50.588],
            [14.4168, 50.5685],
            [14.4203, 50.5598],
            [14.43, 50.5471],
            [14.4395, 50.5414],
            [14.437, 50.535],
            [14.4408, 50.5295],
            [14.4676, 50.5258],
            [14.4745, 50.5227],
            [14.4835, 50.5054],
            [14.4728, 50.5093],
            [14.4712, 50.4983],
            [14.4591, 50.4969],
            [14.471, 50.4749],
            [14.4736, 50.4605],
            [14.472, 50.4491],
            [14.4608, 50.4487],
            [14.4529, 50.4526],
            [14.4473, 50.4491],
            [14.4225, 50.4529],
            [14.4254, 50.4397],
            [14.4097, 50.4309],
            [14.4223, 50.4269],
            [14.4147, 50.421],
            [14.3943, 50.4253],
            [14.3781, 50.4354],
            [14.3649, 50.4306],
            [14.3677, 50.4174],
            [14.3713, 50.4131],
            [14.3635, 50.4083],
            [14.3696, 50.4036],
            [14.3694, 50.3982],
            [14.3783, 50.3964],
            [14.3758, 50.3895],
            [14.3773, 50.3761],
            [14.3674, 50.3769],
            [14.3667, 50.3676],
            [14.3724, 50.3601],
            [14.3654, 50.351],
            [14.3508, 50.3487],
            [14.3432, 50.3543],
            [14.3281, 50.3573],
            [14.3209, 50.354],
            [14.3057, 50.3549],
            [14.2946, 50.3487],
            [14.2821, 50.3505],
            [14.2744, 50.354],
            [14.2683, 50.3499],
            [14.2627, 50.3398],
            [14.2479, 50.3308],
            [14.2391, 50.3341],
            [14.2288, 50.3348],
            [14.2151, 50.3397],
            [14.2103, 50.3347],
            [14.1943, 50.339],
            [14.197, 50.3422],
            [14.1823, 50.3446],
            [14.1833, 50.3379],
            [14.1798, 50.333],
            [14.1459, 50.3321],
            [14.117, 50.3321],
            [14.1159, 50.3346],
            [14.0753, 50.3346],
            [14.0664, 50.3402],
            [14.0538, 50.3401],
            [14.0397, 50.3447],
            [14.0264, 50.3414],
            [14.0189, 50.3485],
            [13.9973, 50.3418],
            [13.9958, 50.3378],
            [13.9823, 50.3332],
            [13.9836, 50.3218],
            [13.9914, 50.3165],
            [13.9888, 50.3066],
            [13.9918, 50.303],
            [13.9568, 50.3009],
            [13.956, 50.2911],
            [13.9423, 50.2868],
            [13.9397, 50.2799],
            [13.9292, 50.2775],
            [13.9193, 50.2724],
            [13.9127, 50.2752],
            [13.8929, 50.2707],
            [13.8777, 50.2696],
            [13.8654, 50.2594],
            [13.8652, 50.2427],
            [13.8413, 50.2403],
            [13.8366, 50.2491],
            [13.827, 50.26],
            [13.8168, 50.2622],
            [13.7998, 50.2593],
            [13.7963, 50.2532],
            [13.7708, 50.2317],
            [13.7532, 50.2341],
            [13.7499, 50.2236],
            [13.7182, 50.2253],
            [13.697, 50.2207],
            [13.6816, 50.2251],
            [13.6729, 50.2228],
            [13.6704, 50.2332],
            [13.6491, 50.2188],
            [13.6387, 50.2167],
            [13.6308, 50.209],
            [13.6179, 50.2102],
            [13.6215, 50.2024],
            [13.6148, 50.1979],
            [13.6003, 50.194],
            [13.589, 50.1882],
            [13.5746, 50.1877],
            [13.5695, 50.1849],
            [13.559, 50.1931],
            [13.5358, 50.188],
            [13.5351, 50.1837],
            [13.517, 50.1777],
            [13.5165, 50.1722],
            [13.5046, 50.1652],
            [13.4945, 50.1643],
            [13.4884, 50.1565],
            [13.4933, 50.1495],
            [13.4859, 50.1406],
            [13.49, 50.1347],
            [13.4904, 50.1268],
            [13.4774, 50.1266],
            [13.4698, 50.1311],
            [13.4701, 50.1129],
            [13.4577, 50.1046],
            [13.4373, 50.1093],
            [13.4254, 50.1082],
            [13.4262, 50.1043],
            [13.411, 50.0952],
            [13.4098, 50.0895],
            [13.4052, 50.0846],
            [13.3898, 50.0819],
            [13.3816, 50.0782],
            [13.3685, 50.0801],
            [13.3707, 50.0901],
            [13.3579, 50.0915],
            [13.357, 50.1008],
            [13.3454, 50.0995],
            [13.3346, 50.0871],
            [13.3248, 50.088],
            [13.3267, 50.0978],
            [13.3216, 50.1008],
            [13.3018, 50.0991],
            [13.2956, 50.0999],
            [13.2942, 50.1137],
            [13.2795, 50.1199],
            [13.2737, 50.1275],
            [13.2674, 50.1284],
            [13.2454, 50.1253],
            [13.2306, 50.134],
            [13.237, 50.1421],
            [13.2507, 50.1459],
            [13.2833, 50.1382],
            [13.2801, 50.1487],
            [13.2675, 50.1534],
            [13.2677, 50.1564],
            [13.2853, 50.1605],
            [13.2872, 50.1678],
            [13.2754, 50.1693],
            [13.2808, 50.1756],
            [13.2709, 50.1862],
            [13.27, 50.1951],
            [13.2615, 50.1997],
            [13.2567, 50.2068],
            [13.2342, 50.2067],
            [13.2342, 50.2107],
            [13.2481, 50.2129],
            [13.2503, 50.2225],
            [13.2543, 50.2279],
            [13.2451, 50.2336],
            [13.2329, 50.2318],
            [13.227, 50.2345],
            [13.2221, 50.242],
            [13.234, 50.2461],
            [13.2361, 50.2526],
            [13.2327, 50.2564],
            [13.2307, 50.2733],
            [13.2193, 50.2774],
            [13.2253, 50.2871],
            [13.2243, 50.294],
            [13.2334, 50.2973],
            [13.2333, 50.3026],
            [13.2208, 50.3125],
            [13.2209, 50.3155],
            [13.2369, 50.327],
            [13.2286, 50.3382],
            [13.2208, 50.3522],
            [13.2087, 50.3547],
            [13.2097, 50.3611],
            [13.1872, 50.3534],
            [13.1579, 50.3529],
            [13.15, 50.3572],
            [13.132, 50.3592],
            [13.1197, 50.3541],
            [13.1159, 50.3473],
            [13.1, 50.3557],
            [13.1001, 50.3674],
            [13.1062, 50.3711],
            [13.0986, 50.3825],
            [13.0896, 50.3802],
            [13.0792, 50.3813],
            [13.0642, 50.3911],
            [13.059, 50.3874],
            [13.0445, 50.3843],
            [13.0267, 50.39],
            [13.0209, 50.3947],
            [12.9985, 50.3882],
            [12.9891, 50.3936],
            [12.9751, 50.3903],
            [12.9658, 50.3999],
            [12.9483, 50.4047],
            [12.9403, 50.4076],
            [12.9453, 50.4134],
            [12.9645, 50.4138],
            [12.9737, 50.4161],
            [12.9859, 50.4222],
            [12.9972, 50.4341],
            [13.0155, 50.4429],
            [13.0238, 50.4537],
            [13.0196, 50.4613],
            [13.0199, 50.4762],
            [13.0232, 50.4873],
            [13.0309, 50.4999],
            [13.0313, 50.5088],
            [13.0444, 50.5109],
            [13.0532, 50.5028],
            [13.0608, 50.5005],
            [13.0857, 50.4998],
            [13.1034, 50.5035],
            [13.1201, 50.5139],
            [13.1318, 50.5186],
            [13.1384, 50.5117],
            [13.1383, 50.5068],
            [13.1546, 50.5083],
            [13.1773, 50.5033],
            [13.1972, 50.5053],
            [13.1946, 50.5159],
            [13.2063, 50.5222],
            [13.2276, 50.5527],
            [13.2224, 50.5631],
            [13.237, 50.5716],
            [13.2355, 50.5773],
            [13.2465, 50.5911],
            [13.255, 50.5945],
            [13.2611, 50.5917],
            [13.2703, 50.5931],
            [13.2813, 50.5908],
            [13.287, 50.5792],
            [13.2922, 50.5759],
            [13.3032, 50.5791],
            [13.3228, 50.5797],
            [13.3209, 50.602],
            [13.327, 50.6076],
            [13.3361, 50.6056],
            [13.3391, 50.6119],
            [13.3519, 50.6137],
            [13.3662, 50.6181],
            [13.3768, 50.6273],
            [13.374, 50.6409],
            [13.3775, 50.6463],
            [13.3894, 50.6464],
            [13.3935, 50.641],
            [13.4055, 50.6335],
            [13.4114, 50.6186],
            [13.4251, 50.6161],
            [13.4296, 50.6099],
            [13.4406, 50.6097],
            [13.4629, 50.6014],
            [13.4669, 50.6033],
            [13.4789, 50.6157],
            [13.4946, 50.6259],
            [13.5037, 50.6335],
            [13.518, 50.6358],
            [13.5249, 50.6392],
            [13.526, 50.6489],
            [13.5186, 50.6481],
            [13.5136, 50.6535],
            [13.5179, 50.6589],
            [13.5306, 50.6681],
            [13.5386, 50.6673],
            [13.5431, 50.6771],
            [13.5402, 50.6904],
            [13.5264, 50.701],
            [13.5267, 50.7054],
            [13.5382, 50.706],
            [13.5506, 50.713],
            [13.5586, 50.7145],
            [13.5796, 50.7117],
            [13.5926, 50.7127],
            [13.6029, 50.71],
            [13.6092, 50.7128],
            [13.6263, 50.7155],
            [13.6251, 50.7215],
            [13.6427, 50.7285],
            [13.667, 50.7313],
            [13.6814, 50.7248],
            [13.686, 50.7195],
            [13.7076, 50.7171],
            [13.7187, 50.7286],
            [13.7272, 50.7336],
            [13.7409, 50.7269],
            [13.7522, 50.7303],
            [13.7578, 50.7355],
            [13.7842, 50.7333],
            [13.7899, 50.7351],
            [13.8074, 50.7332],
            [13.8158, 50.7276],
            [13.8259, 50.7251],
            [13.8334, 50.7273],
            [13.8474, 50.7256],
            [13.8565, 50.7274],
            [13.8621, 50.7425],
            [13.8812, 50.7378],
            [13.8983, 50.7444],
            [13.9023, 50.7527],
            [13.8892, 50.7641],
            [13.887, 50.7687],
            [13.8919, 50.7772],
            [13.9, 50.7849],
            [13.8974, 50.7916],
            [13.9035, 50.794],
            [13.9183, 50.7891],
            [13.9298, 50.7884],
            [13.9464, 50.7941],
            [13.9556, 50.8002],
            [13.9544, 50.8075],
            [13.9703, 50.8107],
            [13.9897, 50.8195],
            [14.0029, 50.8156],
            [14.0041, 50.8102],
            [14.0197, 50.8103],
            [14.032, 50.8037],
            [14.0402, 50.8105],
            [14.0621, 50.81],
            [14.0771, 50.8118],
            [14.0786, 50.8185],
            [14.0887, 50.8258],
            [14.1004, 50.8259],
            [14.1272, 50.834],
            [14.1329, 50.8337],
            [14.1452, 50.8406],
            [14.1605, 50.847],
            [14.1708, 50.8473],
            [14.1961, 50.852],
            [14.2011, 50.8512],
            [14.2142, 50.8591],
            [14.2241, 50.8598],
            [14.2359, 50.8751],
            [14.2335, 50.887],
            [14.2445, 50.8861],
            [14.2583, 50.8899],
            [14.2668, 50.8947],
            [14.281, 50.8927],
            [14.2909, 50.8852],
            [14.3038, 50.8836],
            [14.3187, 50.8885],
            [14.3494, 50.8923],
            [14.3502, 50.8997],
            [14.3743, 50.8961],
            [14.3872, 50.8978],
            [14.3861, 50.9126],
            [14.391, 50.9207],
            [14.4024, 50.9247],
            [14.4012, 50.9322],
            [14.3872, 50.9413],
            [14.3757, 50.938],
            [14.3693, 50.9418],
            [14.3484, 50.9453],
            [14.3393, 50.9496],
            [14.326, 50.9491],
            [14.3137, 50.9531],
            [14.3174, 50.9596],
            [14.3035, 50.9655],
            [14.3154, 50.9728],
            [14.3249, 50.9721],
            [14.3254, 50.9791],
            [14.3185, 50.9854],
            [14.2982, 50.9781],
            [14.2843, 50.9766],
            [14.2744, 50.9832],
            [14.2598, 50.9876],
            [14.259, 50.9921],
            [14.2684, 50.9992],
            [14.2633, 51.0027],
            [14.2662, 51.0083],
            [14.2812, 51.0142],
            [14.2785, 51.0193],
            [14.285, 51.0247],
            [14.286, 51.0311],
            [14.2757, 51.0393],
            [14.288, 51.0389],
            [14.3021, 51.0546],
            [14.3165, 51.0548],
            [14.3262, 51.0493],
            [14.3355, 51.0394],
            [14.3629, 51.0447],
            [14.3747, 51.0387],
            [14.3821, 51.0382],
            [14.3835, 51.0283],
            [14.3944, 51.0228],
            [14.4098, 51.0185],
            [14.4217, 51.0191],
            [14.4544, 51.0358],
            [14.4656, 51.0349],
            [14.4754, 51.0288],
            [14.4756, 51.025],
            [14.4925, 51.0232],
            [14.4939, 51.0354],
            [14.4914, 51.0432],
            [14.5004, 51.0465],
            [14.5078, 51.0431],
            [14.501, 51.0306],
            [14.4984, 51.0219],
            [14.5246, 51.0186],
            [14.5394, 51.0104],
            [14.5346, 51.0033],
            [14.5594, 51.0069],
            [14.5777, 50.9996],
            [14.5806, 50.993],
            [14.5985, 50.9861],
            [14.6006, 50.9799],
            [14.5957, 50.9622],
            [14.5843, 50.9493],
            [14.5814, 50.9422],
            [14.5697, 50.936],
            [14.5612, 50.9256],
            [14.5657, 50.9183],
            [14.5832, 50.9132],
            [14.6162, 50.9251],
            [14.6277, 50.9253],
            [14.6439, 50.931],
            [14.65, 50.9313],
            [14.6522, 50.9237],
            [14.6526, 50.9052],
            [14.6405, 50.9004],
            [14.6354, 50.8948],
            [14.6324, 50.8838],
            [14.6223, 50.8668],
            [14.6196, 50.8579],
          ],
        ],
      },
      properties: {
        name: 'Ústecký',
        id: 'CZ-42',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Ústí nad Labem',
      },
      id: 'CZ-42',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.5338, 50.7795],
            [15.5363, 50.7733],
            [15.5344, 50.7669],
            [15.5425, 50.7553],
            [15.5681, 50.7448],
            [15.5738, 50.7357],
            [15.5633, 50.723],
            [15.5576, 50.7122],
            [15.5638, 50.7101],
            [15.5641, 50.6988],
            [15.5671, 50.6936],
            [15.5618, 50.6822],
            [15.5733, 50.657],
            [15.5821, 50.6528],
            [15.5742, 50.6493],
            [15.5651, 50.6373],
            [15.5829, 50.6308],
            [15.5894, 50.6202],
            [15.602, 50.6144],
            [15.5948, 50.6113],
            [15.5828, 50.6018],
            [15.5821, 50.5932],
            [15.5752, 50.5842],
            [15.5815, 50.575],
            [15.5937, 50.5742],
            [15.5864, 50.5679],
            [15.5971, 50.562],
            [15.5911, 50.5593],
            [15.5931, 50.554],
            [15.6104, 50.55],
            [15.6161, 50.5412],
            [15.6279, 50.5342],
            [15.6306, 50.5222],
            [15.589, 50.5197],
            [15.5823, 50.5163],
            [15.5705, 50.5249],
            [15.5469, 50.5208],
            [15.5441, 50.515],
            [15.536, 50.5135],
            [15.514, 50.519],
            [15.505, 50.5268],
            [15.4978, 50.5294],
            [15.4958, 50.5388],
            [15.4858, 50.5447],
            [15.4774, 50.5462],
            [15.4781, 50.5328],
            [15.4649, 50.5337],
            [15.4627, 50.5254],
            [15.4368, 50.5214],
            [15.4412, 50.5144],
            [15.4415, 50.5038],
            [15.45, 50.5018],
            [15.4435, 50.4905],
            [15.4363, 50.4865],
            [15.4319, 50.4911],
            [15.4244, 50.4894],
            [15.4238, 50.4818],
            [15.398, 50.48],
            [15.3907, 50.4809],
            [15.391, 50.4911],
            [15.3801, 50.5081],
            [15.3713, 50.5033],
            [15.3705, 50.4955],
            [15.36, 50.5008],
            [15.3426, 50.5046],
            [15.3384, 50.5121],
            [15.329, 50.5149],
            [15.3152, 50.5111],
            [15.3012, 50.5142],
            [15.301, 50.5178],
            [15.2841, 50.5238],
            [15.2623, 50.5204],
            [15.2536, 50.5154],
            [15.2423, 50.5038],
            [15.2374, 50.4946],
            [15.2198, 50.4956],
            [15.2144, 50.4993],
            [15.205, 50.5002],
            [15.1858, 50.5075],
            [15.1618, 50.5142],
            [15.1446, 50.5227],
            [15.1282, 50.5312],
            [15.1168, 50.529],
            [15.1128, 50.5324],
            [15.0996, 50.5329],
            [15.0943, 50.5449],
            [15.0944, 50.5543],
            [15.0746, 50.5609],
            [15.0658, 50.5702],
            [15.0602, 50.571],
            [15.0423, 50.5654],
            [15.0388, 50.5741],
            [15.0311, 50.5782],
            [15.0254, 50.5851],
            [15.018, 50.5867],
            [15.005, 50.6043],
            [15.0013, 50.6002],
            [14.9928, 50.5991],
            [14.9814, 50.6029],
            [14.9751, 50.6022],
            [14.9689, 50.5928],
            [14.9585, 50.5938],
            [14.9518, 50.605],
            [14.9431, 50.6071],
            [14.9303, 50.6071],
            [14.9318, 50.6144],
            [14.924, 50.6187],
            [14.9075, 50.6168],
            [14.915, 50.6071],
            [14.9051, 50.6062],
            [14.8959, 50.6019],
            [14.8945, 50.5955],
            [14.9039, 50.5889],
            [14.8861, 50.5866],
            [14.8694, 50.5762],
            [14.8519, 50.5716],
            [14.8452, 50.5678],
            [14.8469, 50.5581],
            [14.8419, 50.5529],
            [14.8336, 50.5508],
            [14.8282, 50.5432],
            [14.8265, 50.5348],
            [14.8184, 50.5333],
            [14.8084, 50.526],
            [14.8002, 50.5313],
            [14.7952, 50.5279],
            [14.7934, 50.5186],
            [14.7856, 50.5175],
            [14.7852, 50.5102],
            [14.773, 50.5151],
            [14.7523, 50.5154],
            [14.7252, 50.5192],
            [14.7179, 50.4995],
            [14.7089, 50.4953],
            [14.6998, 50.4954],
            [14.6888, 50.49],
            [14.69, 50.4811],
            [14.6805, 50.485],
            [14.6666, 50.4839],
            [14.6545, 50.4855],
            [14.6304, 50.481],
            [14.6143, 50.4807],
            [14.6125, 50.4736],
            [14.6011, 50.4751],
            [14.5811, 50.4809],
            [14.5806, 50.4878],
            [14.5753, 50.493],
            [14.5795, 50.4991],
            [14.5789, 50.5055],
            [14.5731, 50.5101],
            [14.564, 50.5061],
            [14.5488, 50.5032],
            [14.5468, 50.4961],
            [14.5327, 50.4984],
            [14.5299, 50.5027],
            [14.5188, 50.5099],
            [14.5103, 50.5096],
            [14.505, 50.5051],
            [14.5006, 50.4924],
            [14.4835, 50.5054],
            [14.4745, 50.5227],
            [14.4676, 50.5258],
            [14.4408, 50.5295],
            [14.437, 50.535],
            [14.4395, 50.5414],
            [14.43, 50.5471],
            [14.4203, 50.5598],
            [14.4168, 50.5685],
            [14.4058, 50.588],
            [14.3926, 50.5935],
            [14.3799, 50.602],
            [14.383, 50.6073],
            [14.3762, 50.6166],
            [14.3597, 50.628],
            [14.366, 50.6362],
            [14.3588, 50.657],
            [14.3509, 50.6554],
            [14.3425, 50.6574],
            [14.3434, 50.6661],
            [14.3535, 50.6787],
            [14.3745, 50.6876],
            [14.3783, 50.6928],
            [14.371, 50.6968],
            [14.3777, 50.7198],
            [14.4038, 50.7262],
            [14.4094, 50.7323],
            [14.425, 50.7332],
            [14.4298, 50.7359],
            [14.4171, 50.7421],
            [14.4266, 50.7585],
            [14.4276, 50.7655],
            [14.4336, 50.7705],
            [14.4468, 50.7729],
            [14.4573, 50.7799],
            [14.448, 50.7849],
            [14.4569, 50.791],
            [14.4475, 50.8075],
            [14.4525, 50.8135],
            [14.4591, 50.809],
            [14.4661, 50.8136],
            [14.4755, 50.8158],
            [14.483, 50.8102],
            [14.4954, 50.8101],
            [14.5067, 50.8062],
            [14.5107, 50.8004],
            [14.5238, 50.7959],
            [14.5406, 50.7955],
            [14.5599, 50.8001],
            [14.5624, 50.8083],
            [14.5597, 50.8133],
            [14.5652, 50.8201],
            [14.5651, 50.8296],
            [14.5707, 50.8388],
            [14.5909, 50.8373],
            [14.608, 50.8435],
            [14.6196, 50.8579],
            [14.633, 50.8552],
            [14.6475, 50.8487],
            [14.6621, 50.8497],
            [14.6891, 50.8381],
            [14.7074, 50.8411],
            [14.7192, 50.8332],
            [14.7169, 50.8244],
            [14.7262, 50.822],
            [14.7396, 50.8286],
            [14.7493, 50.8241],
            [14.7585, 50.8241],
            [14.7656, 50.8194],
            [14.778, 50.8194],
            [14.7883, 50.8233],
            [14.7947, 50.8199],
            [14.8009, 50.8243],
            [14.8021, 50.8364],
            [14.8108, 50.8508],
            [14.8202, 50.8589],
            [14.8235, 50.8704],
            [14.8321, 50.8729],
            [14.8418, 50.8687],
            [14.8612, 50.871],
            [14.8715, 50.8776],
            [14.8969, 50.8672],
            [14.9004, 50.8755],
            [14.9334, 50.87],
            [14.9477, 50.8642],
            [14.9591, 50.8618],
            [14.9783, 50.8677],
            [15.002, 50.8709],
            [14.9982, 50.8874],
            [14.9942, 50.8915],
            [14.9993, 50.9001],
            [14.9985, 50.9119],
            [14.9914, 50.9178],
            [14.9905, 50.9236],
            [15.0012, 50.9269],
            [15.0141, 50.9385],
            [15.0151, 50.949],
            [15.0124, 50.9615],
            [15.0218, 50.9661],
            [15.0156, 50.9709],
            [15.0071, 50.985],
            [14.9883, 50.9776],
            [14.9685, 50.9899],
            [14.9868, 51.0109],
            [14.9976, 51.0092],
            [15.0146, 51.0169],
            [15.0204, 51.0236],
            [15.0329, 51.0217],
            [15.0398, 51.0119],
            [15.0558, 51.0121],
            [15.0572, 51.0216],
            [15.0677, 51.0206],
            [15.0758, 51.0148],
            [15.105, 51.0105],
            [15.0983, 51.0031],
            [15.0979, 50.9976],
            [15.1049, 50.989],
            [15.1236, 50.9912],
            [15.1287, 50.9895],
            [15.1466, 51.013],
            [15.1692, 51.0194],
            [15.174, 51.0127],
            [15.1756, 50.9885],
            [15.1713, 50.9859],
            [15.191, 50.9797],
            [15.1996, 50.9831],
            [15.1965, 50.9877],
            [15.2058, 50.9914],
            [15.2386, 50.9983],
            [15.2426, 50.9881],
            [15.2403, 50.9834],
            [15.2523, 50.9804],
            [15.274, 50.9789],
            [15.277, 50.9722],
            [15.2738, 50.9631],
            [15.285, 50.9588],
            [15.2919, 50.9531],
            [15.2897, 50.9462],
            [15.279, 50.9411],
            [15.268, 50.9313],
            [15.2674, 50.9172],
            [15.2763, 50.8989],
            [15.2772, 50.8894],
            [15.2888, 50.8805],
            [15.2939, 50.8728],
            [15.3092, 50.8605],
            [15.3389, 50.8554],
            [15.3542, 50.8498],
            [15.3603, 50.8403],
            [15.3664, 50.836],
            [15.3725, 50.8217],
            [15.3701, 50.8143],
            [15.3738, 50.8103],
            [15.3668, 50.8008],
            [15.3735, 50.7897],
            [15.3708, 50.7799],
            [15.3851, 50.7747],
            [15.3948, 50.7752],
            [15.4035, 50.7805],
            [15.4148, 50.7911],
            [15.4195, 50.8003],
            [15.4266, 50.8055],
            [15.4373, 50.8076],
            [15.4496, 50.8056],
            [15.4518, 50.8021],
            [15.4643, 50.7993],
            [15.4805, 50.7898],
            [15.496, 50.787],
            [15.5106, 50.791],
            [15.5241, 50.7773],
            [15.5338, 50.7795],
          ],
        ],
      },
      properties: {
        name: 'Liberecký',
        id: 'CZ-51',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Liberec',
      },
      id: 'CZ-51',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.5828, 50.1432],
            [16.5705, 50.1386],
            [16.5421, 50.1512],
            [16.536, 50.1484],
            [16.5243, 50.1521],
            [16.5212, 50.1596],
            [16.5019, 50.166],
            [16.4892, 50.1657],
            [16.4782, 50.1602],
            [16.4802, 50.1498],
            [16.4743, 50.1396],
            [16.4626, 50.1328],
            [16.4551, 50.1316],
            [16.45, 50.1267],
            [16.4357, 50.127],
            [16.4244, 50.1189],
            [16.3995, 50.1161],
            [16.3942, 50.1179],
            [16.3883, 50.1097],
            [16.3773, 50.1017],
            [16.3525, 50.1005],
            [16.3534, 50.0967],
            [16.3427, 50.0937],
            [16.3401, 50.0871],
            [16.3506, 50.079],
            [16.3448, 50.073],
            [16.3162, 50.0679],
            [16.3203, 50.0572],
            [16.3305, 50.048],
            [16.3323, 50.0404],
            [16.3142, 50.0438],
            [16.3101, 50.0427],
            [16.2993, 50.0479],
            [16.2818, 50.051],
            [16.2715, 50.0455],
            [16.2481, 50.0426],
            [16.2399, 50.0461],
            [16.2301, 50.0431],
            [16.2232, 50.0468],
            [16.2102, 50.043],
            [16.206, 50.0396],
            [16.1948, 50.0395],
            [16.1863, 50.05],
            [16.18, 50.0511],
            [16.1681, 50.0617],
            [16.1564, 50.0603],
            [16.1492, 50.0679],
            [16.1384, 50.0625],
            [16.1282, 50.0677],
            [16.1228, 50.0778],
            [16.1112, 50.0838],
            [16.0944, 50.0834],
            [16.0902, 50.0862],
            [16.0796, 50.0858],
            [16.073, 50.0892],
            [16.0709, 50.0973],
            [16.0643, 50.1095],
            [16.0509, 50.1116],
            [16.0109, 50.112],
            [16.011, 50.145],
            [15.9918, 50.1459],
            [15.9917, 50.1575],
            [15.9632, 50.1574],
            [15.9406, 50.1598],
            [15.9216, 50.1701],
            [15.9179, 50.1643],
            [15.896, 50.1625],
            [15.8873, 50.1605],
            [15.8866, 50.147],
            [15.8545, 50.1474],
            [15.8522, 50.1397],
            [15.8335, 50.1346],
            [15.8141, 50.136],
            [15.806, 50.1394],
            [15.8037, 50.1519],
            [15.811, 50.1599],
            [15.8014, 50.162],
            [15.8027, 50.1666],
            [15.7829, 50.1707],
            [15.7755, 50.1748],
            [15.7657, 50.1668],
            [15.7553, 50.1672],
            [15.7402, 50.1592],
            [15.7456, 50.1525],
            [15.7406, 50.1425],
            [15.7314, 50.1413],
            [15.7272, 50.1353],
            [15.72, 50.1378],
            [15.7056, 50.1391],
            [15.6855, 50.1279],
            [15.673, 50.1298],
            [15.6591, 50.1341],
            [15.6484, 50.1405],
            [15.64, 50.1336],
            [15.6165, 50.1379],
            [15.6007, 50.1369],
            [15.5967, 50.1333],
            [15.5814, 50.1359],
            [15.5802, 50.1463],
            [15.566, 50.149],
            [15.5626, 50.1433],
            [15.5503, 50.135],
            [15.5475, 50.1387],
            [15.5378, 50.141],
            [15.531, 50.136],
            [15.5225, 50.1356],
            [15.5193, 50.1243],
            [15.525, 50.1188],
            [15.5145, 50.1172],
            [15.5078, 50.1136],
            [15.4891, 50.1143],
            [15.4891, 50.1098],
            [15.4688, 50.0997],
            [15.4538, 50.0962],
            [15.4426, 50.1055],
            [15.4362, 50.1148],
            [15.4459, 50.1236],
            [15.4363, 50.1275],
            [15.4242, 50.1296],
            [15.4062, 50.1388],
            [15.404, 50.1345],
            [15.3904, 50.1385],
            [15.3824, 50.145],
            [15.3765, 50.1452],
            [15.3645, 50.1407],
            [15.3585, 50.1479],
            [15.3742, 50.1555],
            [15.3751, 50.1587],
            [15.3564, 50.1634],
            [15.3657, 50.1677],
            [15.3779, 50.1768],
            [15.3893, 50.1816],
            [15.3897, 50.1921],
            [15.3965, 50.1952],
            [15.3979, 50.2044],
            [15.3915, 50.2115],
            [15.3914, 50.2174],
            [15.372, 50.2273],
            [15.3753, 50.2333],
            [15.373, 50.25],
            [15.3845, 50.2547],
            [15.3768, 50.2616],
            [15.3757, 50.2696],
            [15.3785, 50.2812],
            [15.3666, 50.2897],
            [15.3597, 50.291],
            [15.3536, 50.298],
            [15.3354, 50.2952],
            [15.3269, 50.2909],
            [15.3199, 50.2918],
            [15.3173, 50.2855],
            [15.3066, 50.2816],
            [15.3035, 50.2845],
            [15.2851, 50.2912],
            [15.2815, 50.2894],
            [15.258, 50.2936],
            [15.2554, 50.2907],
            [15.2419, 50.2896],
            [15.2383, 50.2864],
            [15.207, 50.2955],
            [15.2095, 50.3053],
            [15.2027, 50.3071],
            [15.2058, 50.3129],
            [15.2176, 50.3171],
            [15.1995, 50.3245],
            [15.1948, 50.3304],
            [15.1818, 50.3284],
            [15.1798, 50.3399],
            [15.1672, 50.3462],
            [15.1534, 50.3388],
            [15.131, 50.3453],
            [15.1226, 50.3549],
            [15.1273, 50.3626],
            [15.1188, 50.3659],
            [15.1099, 50.3738],
            [15.1091, 50.3795],
            [15.1174, 50.3896],
            [15.1297, 50.3857],
            [15.1421, 50.3878],
            [15.1369, 50.3951],
            [15.1368, 50.4036],
            [15.1516, 50.4043],
            [15.1572, 50.4084],
            [15.1609, 50.4266],
            [15.1508, 50.4316],
            [15.1542, 50.4361],
            [15.1457, 50.4477],
            [15.1347, 50.4464],
            [15.131, 50.4508],
            [15.1379, 50.462],
            [15.1372, 50.4712],
            [15.1448, 50.4744],
            [15.1304, 50.4799],
            [15.1345, 50.4836],
            [15.1333, 50.49],
            [15.1231, 50.4949],
            [15.1136, 50.5028],
            [15.12, 50.51],
            [15.1273, 50.5089],
            [15.1446, 50.5227],
            [15.1618, 50.5142],
            [15.1858, 50.5075],
            [15.205, 50.5002],
            [15.2144, 50.4993],
            [15.2198, 50.4956],
            [15.2374, 50.4946],
            [15.2423, 50.5038],
            [15.2536, 50.5154],
            [15.2623, 50.5204],
            [15.2841, 50.5238],
            [15.301, 50.5178],
            [15.3012, 50.5142],
            [15.3152, 50.5111],
            [15.329, 50.5149],
            [15.3384, 50.5121],
            [15.3426, 50.5046],
            [15.36, 50.5008],
            [15.3705, 50.4955],
            [15.3713, 50.5033],
            [15.3801, 50.5081],
            [15.391, 50.4911],
            [15.3907, 50.4809],
            [15.398, 50.48],
            [15.4238, 50.4818],
            [15.4244, 50.4894],
            [15.4319, 50.4911],
            [15.4363, 50.4865],
            [15.4435, 50.4905],
            [15.45, 50.5018],
            [15.4415, 50.5038],
            [15.4412, 50.5144],
            [15.4368, 50.5214],
            [15.4627, 50.5254],
            [15.4649, 50.5337],
            [15.4781, 50.5328],
            [15.4774, 50.5462],
            [15.4858, 50.5447],
            [15.4958, 50.5388],
            [15.4978, 50.5294],
            [15.505, 50.5268],
            [15.514, 50.519],
            [15.536, 50.5135],
            [15.5441, 50.515],
            [15.5469, 50.5208],
            [15.5705, 50.5249],
            [15.5823, 50.5163],
            [15.589, 50.5197],
            [15.6306, 50.5222],
            [15.6279, 50.5342],
            [15.6161, 50.5412],
            [15.6104, 50.55],
            [15.5931, 50.554],
            [15.5911, 50.5593],
            [15.5971, 50.562],
            [15.5864, 50.5679],
            [15.5937, 50.5742],
            [15.5815, 50.575],
            [15.5752, 50.5842],
            [15.5821, 50.5932],
            [15.5828, 50.6018],
            [15.5948, 50.6113],
            [15.602, 50.6144],
            [15.5894, 50.6202],
            [15.5829, 50.6308],
            [15.5651, 50.6373],
            [15.5742, 50.6493],
            [15.5821, 50.6528],
            [15.5733, 50.657],
            [15.5618, 50.6822],
            [15.5671, 50.6936],
            [15.5641, 50.6988],
            [15.5638, 50.7101],
            [15.5576, 50.7122],
            [15.5633, 50.723],
            [15.5738, 50.7357],
            [15.5681, 50.7448],
            [15.5425, 50.7553],
            [15.5344, 50.7669],
            [15.5363, 50.7733],
            [15.5338, 50.7795],
            [15.5447, 50.7807],
            [15.5606, 50.7766],
            [15.5796, 50.7789],
            [15.6004, 50.777],
            [15.6131, 50.7736],
            [15.6223, 50.7652],
            [15.6458, 50.7597],
            [15.6572, 50.7596],
            [15.6798, 50.7547],
            [15.6888, 50.7421],
            [15.7057, 50.7367],
            [15.7223, 50.7397],
            [15.7377, 50.7359],
            [15.7624, 50.7433],
            [15.7787, 50.7422],
            [15.7883, 50.7464],
            [15.7917, 50.7525],
            [15.8116, 50.7551],
            [15.832, 50.7423],
            [15.8314, 50.7243],
            [15.8435, 50.712],
            [15.8519, 50.7083],
            [15.8616, 50.683],
            [15.8611, 50.6742],
            [15.8843, 50.6707],
            [15.8858, 50.6752],
            [15.9061, 50.6793],
            [15.9198, 50.6846],
            [15.9272, 50.6817],
            [15.9323, 50.6863],
            [15.9424, 50.6865],
            [15.9507, 50.6899],
            [15.9666, 50.6915],
            [15.9901, 50.684],
            [15.9899, 50.6773],
            [15.9953, 50.6729],
            [16.0048, 50.6571],
            [16.0029, 50.6521],
            [16.0087, 50.634],
            [16.0217, 50.6299],
            [15.9969, 50.622],
            [15.9873, 50.6157],
            [15.9908, 50.6103],
            [16.0074, 50.6048],
            [16.0116, 50.6067],
            [16.0229, 50.5987],
            [16.0427, 50.6137],
            [16.0556, 50.6094],
            [16.0631, 50.6326],
            [16.0681, 50.6398],
            [16.0765, 50.6425],
            [16.0936, 50.6523],
            [16.1008, 50.6622],
            [16.1247, 50.6599],
            [16.1338, 50.6562],
            [16.1391, 50.6508],
            [16.1483, 50.6532],
            [16.1696, 50.6453],
            [16.1762, 50.6397],
            [16.1805, 50.629],
            [16.1867, 50.6274],
            [16.2029, 50.6314],
            [16.2147, 50.632],
            [16.2204, 50.639],
            [16.2222, 50.6511],
            [16.2293, 50.6659],
            [16.234, 50.6712],
            [16.2617, 50.6679],
            [16.2792, 50.6672],
            [16.2847, 50.6603],
            [16.3055, 50.659],
            [16.3198, 50.6661],
            [16.3422, 50.662],
            [16.3454, 50.6548],
            [16.3565, 50.6468],
            [16.3624, 50.6484],
            [16.3792, 50.6325],
            [16.3845, 50.6252],
            [16.3947, 50.6185],
            [16.4033, 50.6176],
            [16.41, 50.6115],
            [16.4213, 50.6069],
            [16.4325, 50.5974],
            [16.4446, 50.5836],
            [16.4441, 50.5787],
            [16.4175, 50.5683],
            [16.4044, 50.5685],
            [16.4106, 50.5566],
            [16.4105, 50.5479],
            [16.3877, 50.5419],
            [16.397, 50.5357],
            [16.4013, 50.5293],
            [16.3862, 50.5196],
            [16.373, 50.5153],
            [16.3606, 50.5013],
            [16.3447, 50.4955],
            [16.3306, 50.5035],
            [16.3202, 50.5072],
            [16.3128, 50.5064],
            [16.3107, 50.4925],
            [16.3018, 50.4911],
            [16.2949, 50.4794],
            [16.284, 50.4798],
            [16.2704, 50.4839],
            [16.2585, 50.4796],
            [16.2493, 50.4737],
            [16.255, 50.467],
            [16.2357, 50.4597],
            [16.2246, 50.4602],
            [16.2215, 50.4569],
            [16.2312, 50.4515],
            [16.2311, 50.4429],
            [16.2239, 50.442],
            [16.2198, 50.4516],
            [16.2132, 50.4515],
            [16.1995, 50.4426],
            [16.1993, 50.429],
            [16.2097, 50.4256],
            [16.2141, 50.4151],
            [16.2112, 50.4106],
            [16.22, 50.4074],
            [16.2379, 50.4138],
            [16.2434, 50.4085],
            [16.2534, 50.4047],
            [16.2508, 50.3929],
            [16.2637, 50.3922],
            [16.2676, 50.3793],
            [16.277, 50.3754],
            [16.2782, 50.3682],
            [16.293, 50.3708],
            [16.2996, 50.3801],
            [16.3051, 50.3826],
            [16.3175, 50.3797],
            [16.3246, 50.3818],
            [16.3405, 50.3776],
            [16.3487, 50.3804],
            [16.3614, 50.3791],
            [16.3676, 50.3665],
            [16.3628, 50.3485],
            [16.3751, 50.3422],
            [16.3835, 50.3287],
            [16.3997, 50.3191],
            [16.4105, 50.3241],
            [16.4297, 50.3242],
            [16.4468, 50.3116],
            [16.4492, 50.3042],
            [16.4638, 50.2949],
            [16.4665, 50.286],
            [16.4789, 50.2766],
            [16.4889, 50.2644],
            [16.4974, 50.2596],
            [16.508, 50.2602],
            [16.5133, 50.2473],
            [16.5269, 50.2383],
            [16.5386, 50.2392],
            [16.5423, 50.2342],
            [16.559, 50.2212],
            [16.5557, 50.2162],
            [16.5626, 50.2084],
            [16.5559, 50.197],
            [16.5652, 50.18],
            [16.5591, 50.1729],
            [16.5613, 50.1654],
            [16.5794, 50.1586],
            [16.5864, 50.1537],
            [16.5828, 50.1432],
          ],
        ],
      },
      properties: {
        name: 'Královéhradecký',
        id: 'CZ-52',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Hradec Králové',
      },
      id: 'CZ-52',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.8659, 50.1986],
            [16.8688, 50.1875],
            [16.8671, 50.1775],
            [16.8501, 50.1655],
            [16.8449, 50.1497],
            [16.839, 50.1434],
            [16.8326, 50.1265],
            [16.8267, 50.1274],
            [16.8276, 50.1163],
            [16.8209, 50.1126],
            [16.8091, 50.1118],
            [16.8057, 50.1081],
            [16.8236, 50.0853],
            [16.8121, 50.0807],
            [16.8036, 50.0739],
            [16.8, 50.067],
            [16.8135, 50.0604],
            [16.809, 50.053],
            [16.8186, 50.045],
            [16.8084, 50.0391],
            [16.8087, 50.0291],
            [16.7947, 50.0299],
            [16.7876, 50.0242],
            [16.7672, 50.015],
            [16.7704, 50.0079],
            [16.7554, 50.0008],
            [16.7264, 50.0025],
            [16.7125, 49.9988],
            [16.7148, 49.9901],
            [16.7195, 49.9843],
            [16.7273, 49.9809],
            [16.73, 49.971],
            [16.735, 49.9675],
            [16.7336, 49.9466],
            [16.7436, 49.9346],
            [16.7518, 49.9321],
            [16.7462, 49.9252],
            [16.7565, 49.9203],
            [16.7561, 49.9123],
            [16.7525, 49.9082],
            [16.7574, 49.9011],
            [16.752, 49.8931],
            [16.7623, 49.8728],
            [16.758, 49.8678],
            [16.7385, 49.8606],
            [16.7358, 49.8351],
            [16.7364, 49.8291],
            [16.758, 49.8188],
            [16.7597, 49.8096],
            [16.7565, 49.8029],
            [16.7606, 49.7967],
            [16.7708, 49.7899],
            [16.7882, 49.7755],
            [16.7982, 49.7737],
            [16.8046, 49.7675],
            [16.797, 49.7651],
            [16.7933, 49.757],
            [16.8121, 49.7477],
            [16.8062, 49.7396],
            [16.7984, 49.7338],
            [16.7889, 49.7309],
            [16.7897, 49.7267],
            [16.8035, 49.7271],
            [16.8059, 49.7317],
            [16.8286, 49.7279],
            [16.8446, 49.7201],
            [16.8478, 49.7159],
            [16.8406, 49.7059],
            [16.8475, 49.7004],
            [16.8432, 49.6853],
            [16.8466, 49.6807],
            [16.8382, 49.6747],
            [16.827, 49.6703],
            [16.81, 49.6607],
            [16.8037, 49.6554],
            [16.8045, 49.6451],
            [16.8013, 49.6379],
            [16.8129, 49.6284],
            [16.808, 49.6219],
            [16.8016, 49.6036],
            [16.8048, 49.5992],
            [16.7826, 49.5957],
            [16.7767, 49.5966],
            [16.7525, 49.607],
            [16.7183, 49.6152],
            [16.7152, 49.6213],
            [16.6929, 49.6238],
            [16.6817, 49.6226],
            [16.6733, 49.6262],
            [16.6609, 49.6253],
            [16.651, 49.6193],
            [16.6348, 49.6288],
            [16.6236, 49.6317],
            [16.6166, 49.6253],
            [16.6056, 49.6207],
            [16.5917, 49.6258],
            [16.586, 49.6231],
            [16.5558, 49.6306],
            [16.5497, 49.6276],
            [16.5482, 49.6101],
            [16.5437, 49.6072],
            [16.5433, 49.5927],
            [16.5343, 49.5904],
            [16.501, 49.5917],
            [16.4921, 49.5997],
            [16.4826, 49.5979],
            [16.4724, 49.5913],
            [16.4637, 49.5924],
            [16.4457, 49.5873],
            [16.4501, 49.5958],
            [16.4364, 49.5988],
            [16.4156, 49.5909],
            [16.4192, 49.5839],
            [16.3995, 49.5759],
            [16.391, 49.5815],
            [16.3784, 49.5896],
            [16.3761, 49.5964],
            [16.3703, 49.6014],
            [16.3586, 49.6021],
            [16.3491, 49.6086],
            [16.3486, 49.6126],
            [16.3287, 49.6145],
            [16.3132, 49.6181],
            [16.3027, 49.624],
            [16.296, 49.6179],
            [16.2828, 49.6261],
            [16.2697, 49.629],
            [16.2731, 49.6381],
            [16.2567, 49.6452],
            [16.2468, 49.6567],
            [16.2403, 49.6606],
            [16.2271, 49.6633],
            [16.2214, 49.6668],
            [16.2064, 49.6671],
            [16.1947, 49.6793],
            [16.1831, 49.6754],
            [16.171, 49.6816],
            [16.1589, 49.6817],
            [16.1414, 49.6896],
            [16.1267, 49.687],
            [16.1186, 49.6885],
            [16.1042, 49.6833],
            [16.0955, 49.6867],
            [16.0959, 49.6913],
            [16.0907, 49.6995],
            [16.0795, 49.7098],
            [16.0587, 49.7157],
            [16.0496, 49.7123],
            [16.0419, 49.7126],
            [16.0326, 49.7168],
            [16.0183, 49.7176],
            [16.0083, 49.7242],
            [16.009, 49.7303],
            [16.0017, 49.7386],
            [15.9871, 49.7279],
            [15.9822, 49.7277],
            [15.9718, 49.72],
            [15.9721, 49.7031],
            [15.9649, 49.7002],
            [15.9541, 49.7001],
            [15.9565, 49.6938],
            [15.9487, 49.6861],
            [15.9349, 49.6882],
            [15.9244, 49.685],
            [15.9155, 49.6864],
            [15.9093, 49.6928],
            [15.899, 49.699],
            [15.8827, 49.7029],
            [15.8701, 49.7026],
            [15.8566, 49.71],
            [15.8485, 49.7177],
            [15.8655, 49.7323],
            [15.8643, 49.744],
            [15.8502, 49.7367],
            [15.8461, 49.7403],
            [15.8326, 49.7411],
            [15.8273, 49.7448],
            [15.8239, 49.7526],
            [15.8164, 49.752],
            [15.8076, 49.7579],
            [15.7964, 49.7591],
            [15.7847, 49.7554],
            [15.7756, 49.7557],
            [15.773, 49.7646],
            [15.7827, 49.7798],
            [15.7656, 49.7775],
            [15.7551, 49.7827],
            [15.742, 49.7809],
            [15.7307, 49.7843],
            [15.7283, 49.7884],
            [15.719, 49.789],
            [15.7126, 49.7995],
            [15.6898, 49.7996],
            [15.6832, 49.8034],
            [15.6829, 49.8121],
            [15.6732, 49.8108],
            [15.6662, 49.8149],
            [15.6596, 49.8142],
            [15.6552, 49.8069],
            [15.6407, 49.8058],
            [15.6388, 49.8157],
            [15.6172, 49.809],
            [15.5934, 49.8136],
            [15.5914, 49.8188],
            [15.5809, 49.818],
            [15.5724, 49.8269],
            [15.5568, 49.8307],
            [15.5414, 49.8415],
            [15.531, 49.8422],
            [15.5222, 49.853],
            [15.5113, 49.8538],
            [15.4973, 49.8611],
            [15.5056, 49.8833],
            [15.4982, 49.8983],
            [15.514, 49.8988],
            [15.5162, 49.9041],
            [15.5341, 49.9231],
            [15.5364, 49.9295],
            [15.5245, 49.9328],
            [15.5167, 49.9419],
            [15.5301, 49.9488],
            [15.5329, 49.9538],
            [15.5316, 49.9621],
            [15.5192, 49.9631],
            [15.5097, 49.97],
            [15.5052, 49.9669],
            [15.4921, 49.9721],
            [15.4608, 49.9886],
            [15.4697, 49.9939],
            [15.4752, 50.0016],
            [15.4604, 50.0051],
            [15.4548, 50.0097],
            [15.448, 50.0055],
            [15.4371, 50.0091],
            [15.4277, 50.0082],
            [15.4185, 50.0209],
            [15.4015, 50.0181],
            [15.3769, 50.0283],
            [15.3692, 50.0383],
            [15.3687, 50.0426],
            [15.3797, 50.0476],
            [15.395, 50.0453],
            [15.4011, 50.0503],
            [15.3804, 50.0533],
            [15.3812, 50.06],
            [15.3947, 50.0591],
            [15.403, 50.063],
            [15.4141, 50.0627],
            [15.4128, 50.0751],
            [15.4047, 50.0818],
            [15.404, 50.0872],
            [15.4208, 50.0895],
            [15.4165, 50.0935],
            [15.4038, 50.0936],
            [15.4001, 50.096],
            [15.4076, 50.1048],
            [15.4151, 50.1073],
            [15.4257, 50.0989],
            [15.4426, 50.1055],
            [15.4538, 50.0962],
            [15.4688, 50.0997],
            [15.4891, 50.1098],
            [15.4891, 50.1143],
            [15.5078, 50.1136],
            [15.5145, 50.1172],
            [15.525, 50.1188],
            [15.5193, 50.1243],
            [15.5225, 50.1356],
            [15.531, 50.136],
            [15.5378, 50.141],
            [15.5475, 50.1387],
            [15.5503, 50.135],
            [15.5626, 50.1433],
            [15.566, 50.149],
            [15.5802, 50.1463],
            [15.5814, 50.1359],
            [15.5967, 50.1333],
            [15.6007, 50.1369],
            [15.6165, 50.1379],
            [15.64, 50.1336],
            [15.6484, 50.1405],
            [15.6591, 50.1341],
            [15.673, 50.1298],
            [15.6855, 50.1279],
            [15.7056, 50.1391],
            [15.72, 50.1378],
            [15.7272, 50.1353],
            [15.7314, 50.1413],
            [15.7406, 50.1425],
            [15.7456, 50.1525],
            [15.7402, 50.1592],
            [15.7553, 50.1672],
            [15.7657, 50.1668],
            [15.7755, 50.1748],
            [15.7829, 50.1707],
            [15.8027, 50.1666],
            [15.8014, 50.162],
            [15.811, 50.1599],
            [15.8037, 50.1519],
            [15.806, 50.1394],
            [15.8141, 50.136],
            [15.8335, 50.1346],
            [15.8522, 50.1397],
            [15.8545, 50.1474],
            [15.8866, 50.147],
            [15.8873, 50.1605],
            [15.896, 50.1625],
            [15.9179, 50.1643],
            [15.9216, 50.1701],
            [15.9406, 50.1598],
            [15.9632, 50.1574],
            [15.9917, 50.1575],
            [15.9918, 50.1459],
            [16.011, 50.145],
            [16.0109, 50.112],
            [16.0509, 50.1116],
            [16.0643, 50.1095],
            [16.0709, 50.0973],
            [16.073, 50.0892],
            [16.0796, 50.0858],
            [16.0902, 50.0862],
            [16.0944, 50.0834],
            [16.1112, 50.0838],
            [16.1228, 50.0778],
            [16.1282, 50.0677],
            [16.1384, 50.0625],
            [16.1492, 50.0679],
            [16.1564, 50.0603],
            [16.1681, 50.0617],
            [16.18, 50.0511],
            [16.1863, 50.05],
            [16.1948, 50.0395],
            [16.206, 50.0396],
            [16.2102, 50.043],
            [16.2232, 50.0468],
            [16.2301, 50.0431],
            [16.2399, 50.0461],
            [16.2481, 50.0426],
            [16.2715, 50.0455],
            [16.2818, 50.051],
            [16.2993, 50.0479],
            [16.3101, 50.0427],
            [16.3142, 50.0438],
            [16.3323, 50.0404],
            [16.3305, 50.048],
            [16.3203, 50.0572],
            [16.3162, 50.0679],
            [16.3448, 50.073],
            [16.3506, 50.079],
            [16.3401, 50.0871],
            [16.3427, 50.0937],
            [16.3534, 50.0967],
            [16.3525, 50.1005],
            [16.3773, 50.1017],
            [16.3883, 50.1097],
            [16.3942, 50.1179],
            [16.3995, 50.1161],
            [16.4244, 50.1189],
            [16.4357, 50.127],
            [16.45, 50.1267],
            [16.4551, 50.1316],
            [16.4626, 50.1328],
            [16.4743, 50.1396],
            [16.4802, 50.1498],
            [16.4782, 50.1602],
            [16.4892, 50.1657],
            [16.5019, 50.166],
            [16.5212, 50.1596],
            [16.5243, 50.1521],
            [16.536, 50.1484],
            [16.5421, 50.1512],
            [16.5705, 50.1386],
            [16.5828, 50.1432],
            [16.6002, 50.1397],
            [16.6044, 50.1345],
            [16.613, 50.1326],
            [16.6152, 50.1252],
            [16.6266, 50.1218],
            [16.6345, 50.112],
            [16.6418, 50.1125],
            [16.6796, 50.1039],
            [16.6913, 50.099],
            [16.7068, 50.097],
            [16.7243, 50.1048],
            [16.7307, 50.1172],
            [16.7476, 50.1353],
            [16.7561, 50.1343],
            [16.7849, 50.1454],
            [16.7834, 50.1518],
            [16.7931, 50.1583],
            [16.7988, 50.1652],
            [16.8017, 50.1798],
            [16.8144, 50.192],
            [16.8246, 50.1891],
            [16.8274, 50.1947],
            [16.8365, 50.1979],
            [16.8368, 50.2026],
            [16.849, 50.2077],
            [16.8659, 50.1986],
          ],
        ],
      },
      properties: {
        name: 'Pardubický',
        id: 'CZ-53',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Pardubice',
      },
      id: 'CZ-53',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.4973, 49.8611],
            [15.5113, 49.8538],
            [15.5222, 49.853],
            [15.531, 49.8422],
            [15.5414, 49.8415],
            [15.5568, 49.8307],
            [15.5724, 49.8269],
            [15.5809, 49.818],
            [15.5914, 49.8188],
            [15.5934, 49.8136],
            [15.6172, 49.809],
            [15.6388, 49.8157],
            [15.6407, 49.8058],
            [15.6552, 49.8069],
            [15.6596, 49.8142],
            [15.6662, 49.8149],
            [15.6732, 49.8108],
            [15.6829, 49.8121],
            [15.6832, 49.8034],
            [15.6898, 49.7996],
            [15.7126, 49.7995],
            [15.719, 49.789],
            [15.7283, 49.7884],
            [15.7307, 49.7843],
            [15.742, 49.7809],
            [15.7551, 49.7827],
            [15.7656, 49.7775],
            [15.7827, 49.7798],
            [15.773, 49.7646],
            [15.7756, 49.7557],
            [15.7847, 49.7554],
            [15.7964, 49.7591],
            [15.8076, 49.7579],
            [15.8164, 49.752],
            [15.8239, 49.7526],
            [15.8273, 49.7448],
            [15.8326, 49.7411],
            [15.8461, 49.7403],
            [15.8502, 49.7367],
            [15.8643, 49.744],
            [15.8655, 49.7323],
            [15.8485, 49.7177],
            [15.8566, 49.71],
            [15.8701, 49.7026],
            [15.8827, 49.7029],
            [15.899, 49.699],
            [15.9093, 49.6928],
            [15.9155, 49.6864],
            [15.9244, 49.685],
            [15.9349, 49.6882],
            [15.9487, 49.6861],
            [15.9565, 49.6938],
            [15.9541, 49.7001],
            [15.9649, 49.7002],
            [15.9721, 49.7031],
            [15.9718, 49.72],
            [15.9822, 49.7277],
            [15.9871, 49.7279],
            [16.0017, 49.7386],
            [16.009, 49.7303],
            [16.0083, 49.7242],
            [16.0183, 49.7176],
            [16.0326, 49.7168],
            [16.0419, 49.7126],
            [16.0496, 49.7123],
            [16.0587, 49.7157],
            [16.0795, 49.7098],
            [16.0907, 49.6995],
            [16.0959, 49.6913],
            [16.0955, 49.6867],
            [16.1042, 49.6833],
            [16.1186, 49.6885],
            [16.1267, 49.687],
            [16.1414, 49.6896],
            [16.1589, 49.6817],
            [16.171, 49.6816],
            [16.1831, 49.6754],
            [16.1947, 49.6793],
            [16.2064, 49.6671],
            [16.2214, 49.6668],
            [16.2271, 49.6633],
            [16.2403, 49.6606],
            [16.2468, 49.6567],
            [16.2567, 49.6452],
            [16.2731, 49.6381],
            [16.2697, 49.629],
            [16.2828, 49.6261],
            [16.296, 49.6179],
            [16.3027, 49.624],
            [16.3132, 49.6181],
            [16.3287, 49.6145],
            [16.3486, 49.6126],
            [16.3491, 49.6086],
            [16.3586, 49.6021],
            [16.3703, 49.6014],
            [16.3761, 49.5964],
            [16.3784, 49.5896],
            [16.391, 49.5815],
            [16.3934, 49.577],
            [16.3916, 49.5629],
            [16.3819, 49.5549],
            [16.3729, 49.5513],
            [16.3877, 49.5386],
            [16.4019, 49.5376],
            [16.4068, 49.5392],
            [16.415, 49.533],
            [16.4187, 49.5247],
            [16.4149, 49.5161],
            [16.4094, 49.5106],
            [16.4022, 49.5096],
            [16.395, 49.5008],
            [16.3872, 49.4975],
            [16.3859, 49.4926],
            [16.3725, 49.4916],
            [16.3678, 49.4972],
            [16.3595, 49.4909],
            [16.3685, 49.4842],
            [16.3762, 49.482],
            [16.3884, 49.4696],
            [16.3736, 49.4644],
            [16.3817, 49.4563],
            [16.3747, 49.4503],
            [16.3682, 49.4489],
            [16.3649, 49.4429],
            [16.3673, 49.4345],
            [16.3741, 49.4289],
            [16.3711, 49.4253],
            [16.3788, 49.4069],
            [16.3805, 49.3995],
            [16.3716, 49.3848],
            [16.3795, 49.3766],
            [16.3739, 49.3714],
            [16.3866, 49.3589],
            [16.376, 49.3546],
            [16.3658, 49.3461],
            [16.357, 49.3461],
            [16.3479, 49.3385],
            [16.3427, 49.344],
            [16.335, 49.3421],
            [16.3295, 49.3355],
            [16.311, 49.3287],
            [16.2893, 49.3244],
            [16.2923, 49.3126],
            [16.2858, 49.3035],
            [16.2794, 49.3026],
            [16.2726, 49.2947],
            [16.2591, 49.2909],
            [16.2565, 49.2811],
            [16.2579, 49.2755],
            [16.2863, 49.2645],
            [16.2808, 49.2546],
            [16.2687, 49.2522],
            [16.2546, 49.2533],
            [16.2434, 49.2402],
            [16.2369, 49.2272],
            [16.2372, 49.2119],
            [16.2602, 49.209],
            [16.2735, 49.2001],
            [16.2671, 49.1956],
            [16.2731, 49.1848],
            [16.2695, 49.1784],
            [16.2625, 49.1806],
            [16.2533, 49.1754],
            [16.2418, 49.1765],
            [16.2319, 49.1741],
            [16.2253, 49.1642],
            [16.2365, 49.158],
            [16.2396, 49.1507],
            [16.2379, 49.1423],
            [16.2466, 49.1373],
            [16.2694, 49.129],
            [16.2667, 49.1157],
            [16.2477, 49.1035],
            [16.2358, 49.0973],
            [16.2246, 49.0981],
            [16.2168, 49.0924],
            [16.2225, 49.0882],
            [16.2195, 49.0822],
            [16.2093, 49.0709],
            [16.201, 49.0741],
            [16.1835, 49.0729],
            [16.1782, 49.0702],
            [16.1602, 49.0717],
            [16.1387, 49.0667],
            [16.129, 49.0586],
            [16.1385, 49.0551],
            [16.1459, 49.0457],
            [16.1331, 49.0425],
            [16.1259, 49.045],
            [16.1182, 49.037],
            [16.1043, 49.0412],
            [16.0902, 49.0396],
            [16.0825, 49.0362],
            [16.0688, 49.039],
            [16.0637, 49.0362],
            [16.055, 49.0425],
            [16.0447, 49.0396],
            [16.0318, 49.0427],
            [16.0215, 49.0311],
            [16.0084, 49.0262],
            [15.9956, 49.0267],
            [15.981, 49.0329],
            [15.9711, 49.0391],
            [15.9678, 49.0467],
            [15.9601, 49.0562],
            [15.9491, 49.0519],
            [15.9435, 49.0473],
            [15.9335, 49.0461],
            [15.9334, 49.0388],
            [15.9234, 49.0349],
            [15.9143, 49.0285],
            [15.9056, 49.0351],
            [15.8929, 49.0389],
            [15.8846, 49.0388],
            [15.8836, 49.0319],
            [15.8768, 49.018],
            [15.8668, 49.0089],
            [15.85, 49.0145],
            [15.8476, 49.0116],
            [15.8343, 49.0136],
            [15.8269, 49.0068],
            [15.8179, 49.0107],
            [15.8144, 49.0046],
            [15.8036, 49.0021],
            [15.7984, 48.9982],
            [15.7956, 48.9861],
            [15.7813, 48.9797],
            [15.7639, 48.9791],
            [15.7584, 48.9805],
            [15.7546, 48.9656],
            [15.7442, 48.9685],
            [15.7389, 48.9594],
            [15.7385, 48.9513],
            [15.73, 48.9484],
            [15.7167, 48.9615],
            [15.7064, 48.9677],
            [15.6962, 48.9671],
            [15.6798, 48.9608],
            [15.6689, 48.9646],
            [15.6616, 48.9637],
            [15.6626, 48.9516],
            [15.6519, 48.947],
            [15.6489, 48.938],
            [15.6393, 48.9428],
            [15.6247, 48.9417],
            [15.6126, 48.9586],
            [15.6016, 48.9573],
            [15.5863, 48.9486],
            [15.5771, 48.9555],
            [15.5801, 48.9659],
            [15.5717, 48.9664],
            [15.5579, 48.9722],
            [15.5322, 48.9741],
            [15.5219, 48.9786],
            [15.5242, 48.9827],
            [15.5242, 48.9941],
            [15.5087, 48.9983],
            [15.5032, 48.9931],
            [15.49, 48.9926],
            [15.4879, 48.9974],
            [15.4916, 49.0036],
            [15.508, 49.0119],
            [15.5092, 49.0174],
            [15.5179, 49.0164],
            [15.5287, 49.0245],
            [15.5313, 49.0346],
            [15.5485, 49.0429],
            [15.5506, 49.0497],
            [15.5434, 49.0517],
            [15.5418, 49.0604],
            [15.5543, 49.0608],
            [15.5563, 49.0666],
            [15.5514, 49.0715],
            [15.5533, 49.0766],
            [15.571, 49.0709],
            [15.5917, 49.0718],
            [15.5973, 49.0772],
            [15.5948, 49.0814],
            [15.602, 49.087],
            [15.5832, 49.0989],
            [15.5887, 49.1036],
            [15.58, 49.1077],
            [15.5746, 49.1033],
            [15.5603, 49.1119],
            [15.5593, 49.1169],
            [15.5688, 49.1178],
            [15.5662, 49.1259],
            [15.544, 49.1353],
            [15.5373, 49.1344],
            [15.5356, 49.1249],
            [15.517, 49.1308],
            [15.5109, 49.1291],
            [15.5029, 49.1189],
            [15.4912, 49.119],
            [15.4695, 49.1226],
            [15.4678, 49.1191],
            [15.4558, 49.1214],
            [15.4414, 49.1327],
            [15.4298, 49.1286],
            [15.4242, 49.1177],
            [15.4183, 49.1262],
            [15.4058, 49.1313],
            [15.3861, 49.1344],
            [15.3726, 49.1507],
            [15.3651, 49.148],
            [15.3529, 49.1528],
            [15.3445, 49.1666],
            [15.3426, 49.1789],
            [15.3501, 49.1898],
            [15.3433, 49.209],
            [15.3442, 49.2155],
            [15.3333, 49.2184],
            [15.3117, 49.2179],
            [15.3044, 49.2133],
            [15.2963, 49.2162],
            [15.2818, 49.2168],
            [15.2785, 49.2117],
            [15.27, 49.215],
            [15.2549, 49.2106],
            [15.2563, 49.2179],
            [15.2375, 49.2228],
            [15.2237, 49.2247],
            [15.2167, 49.228],
            [15.2053, 49.226],
            [15.1968, 49.2353],
            [15.1759, 49.2212],
            [15.1659, 49.2161],
            [15.1551, 49.2178],
            [15.1403, 49.224],
            [15.1312, 49.243],
            [15.1203, 49.2479],
            [15.1105, 49.2469],
            [15.0992, 49.2406],
            [15.0918, 49.2456],
            [15.1008, 49.2487],
            [15.098, 49.2598],
            [15.1029, 49.2613],
            [15.1046, 49.2692],
            [15.0857, 49.2719],
            [15.0856, 49.2669],
            [15.0583, 49.2746],
            [15.0448, 49.2834],
            [15.0424, 49.2901],
            [15.0302, 49.2963],
            [15.002, 49.2846],
            [14.9918, 49.2732],
            [14.9792, 49.2749],
            [14.9771, 49.2816],
            [14.9619, 49.2928],
            [14.9497, 49.2959],
            [14.9506, 49.3019],
            [14.9392, 49.307],
            [14.9251, 49.3106],
            [14.9295, 49.3166],
            [14.9157, 49.3267],
            [14.9043, 49.3329],
            [14.9263, 49.3532],
            [14.9253, 49.3591],
            [14.9175, 49.3677],
            [14.9118, 49.3669],
            [14.9001, 49.3722],
            [14.8908, 49.3738],
            [14.8936, 49.3944],
            [14.901, 49.3955],
            [14.9062, 49.4049],
            [14.9016, 49.4104],
            [14.9144, 49.4259],
            [14.9026, 49.4232],
            [14.9006, 49.4281],
            [14.9124, 49.4307],
            [14.9122, 49.4403],
            [14.9152, 49.4462],
            [14.9237, 49.4517],
            [14.9289, 49.4674],
            [14.9211, 49.4704],
            [14.9279, 49.4878],
            [14.9251, 49.4998],
            [14.9127, 49.5112],
            [14.9016, 49.513],
            [14.9019, 49.5174],
            [14.9104, 49.523],
            [14.9269, 49.5232],
            [14.9311, 49.521],
            [14.9427, 49.5244],
            [14.9342, 49.538],
            [14.93, 49.5411],
            [14.9325, 49.5502],
            [14.9313, 49.5555],
            [14.9419, 49.5627],
            [14.9344, 49.5693],
            [14.9397, 49.5791],
            [14.9715, 49.5778],
            [14.9859, 49.5799],
            [14.9805, 49.5917],
            [14.969, 49.5956],
            [14.9742, 49.6038],
            [14.9909, 49.6069],
            [15.0026, 49.6053],
            [15.018, 49.597],
            [15.0403, 49.5963],
            [15.0457, 49.6006],
            [15.0553, 49.5939],
            [15.0694, 49.5962],
            [15.0827, 49.6013],
            [15.0951, 49.5984],
            [15.1037, 49.6004],
            [15.1101, 49.6073],
            [15.129, 49.6008],
            [15.1462, 49.6017],
            [15.1527, 49.5966],
            [15.1619, 49.5945],
            [15.1663, 49.6019],
            [15.1719, 49.6028],
            [15.1733, 49.6097],
            [15.1838, 49.6108],
            [15.1829, 49.6177],
            [15.1938, 49.6186],
            [15.2002, 49.6135],
            [15.2114, 49.6123],
            [15.2302, 49.6155],
            [15.2365, 49.6228],
            [15.2442, 49.6242],
            [15.2498, 49.6305],
            [15.2447, 49.6364],
            [15.249, 49.6438],
            [15.2369, 49.6543],
            [15.2335, 49.6646],
            [15.2184, 49.6647],
            [15.2073, 49.6628],
            [15.1973, 49.6726],
            [15.186, 49.668],
            [15.1803, 49.6762],
            [15.1826, 49.6803],
            [15.1783, 49.6878],
            [15.1803, 49.7037],
            [15.1985, 49.7168],
            [15.2077, 49.7251],
            [15.2172, 49.728],
            [15.216, 49.7378],
            [15.2217, 49.7524],
            [15.2283, 49.7561],
            [15.2384, 49.7568],
            [15.2483, 49.7632],
            [15.2553, 49.7627],
            [15.2512, 49.7543],
            [15.2756, 49.7553],
            [15.2839, 49.7592],
            [15.2844, 49.7647],
            [15.3013, 49.7665],
            [15.3151, 49.766],
            [15.3282, 49.7729],
            [15.3387, 49.7743],
            [15.3477, 49.769],
            [15.3591, 49.7788],
            [15.3661, 49.7762],
            [15.3719, 49.7852],
            [15.3704, 49.789],
            [15.3825, 49.7996],
            [15.3911, 49.7928],
            [15.3983, 49.7957],
            [15.4071, 49.7935],
            [15.4237, 49.7925],
            [15.4364, 49.799],
            [15.4318, 49.8109],
            [15.4255, 49.8175],
            [15.4155, 49.8199],
            [15.4207, 49.8321],
            [15.4311, 49.8315],
            [15.4328, 49.8372],
            [15.4459, 49.8456],
            [15.4645, 49.8426],
            [15.4735, 49.8441],
            [15.4781, 49.852],
            [15.4861, 49.8533],
            [15.4902, 49.8496],
            [15.4973, 49.8611],
          ],
        ],
      },
      properties: {
        name: 'Vysočina',
        id: 'CZ-63',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Vysočina',
      },
      id: 'CZ-63',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.6486, 48.8519],
            [17.6328, 48.8521],
            [17.6262, 48.8491],
            [17.6187, 48.8387],
            [17.6002, 48.8372],
            [17.5959, 48.8262],
            [17.59, 48.8227],
            [17.5567, 48.8205],
            [17.5474, 48.8138],
            [17.5386, 48.8159],
            [17.5249, 48.8109],
            [17.5168, 48.8126],
            [17.4806, 48.8368],
            [17.4533, 48.8432],
            [17.4436, 48.8346],
            [17.4347, 48.8363],
            [17.4291, 48.8248],
            [17.4174, 48.825],
            [17.3939, 48.8117],
            [17.3773, 48.8167],
            [17.3681, 48.8116],
            [17.3609, 48.8123],
            [17.3548, 48.8194],
            [17.3269, 48.8333],
            [17.3215, 48.842],
            [17.3061, 48.8392],
            [17.2964, 48.8453],
            [17.2849, 48.8498],
            [17.279, 48.857],
            [17.2654, 48.8569],
            [17.2531, 48.8598],
            [17.2461, 48.8645],
            [17.2332, 48.867],
            [17.2203, 48.8667],
            [17.2111, 48.8709],
            [17.1957, 48.8735],
            [17.1908, 48.8709],
            [17.1739, 48.8556],
            [17.1624, 48.851],
            [17.155, 48.8433],
            [17.1428, 48.8402],
            [17.1171, 48.8315],
            [17.1074, 48.8226],
            [17.1084, 48.8142],
            [17.1049, 48.8026],
            [17.0931, 48.7961],
            [17.0911, 48.7861],
            [17.085, 48.782],
            [17.0688, 48.7772],
            [17.0626, 48.7716],
            [17.0447, 48.7639],
            [17.0415, 48.7525],
            [17.0311, 48.7459],
            [17.0276, 48.7393],
            [17.0157, 48.7289],
            [17.0116, 48.7166],
            [17.0009, 48.7101],
            [16.9992, 48.704],
            [17.0106, 48.6951],
            [16.9848, 48.6798],
            [16.9696, 48.6672],
            [16.9705, 48.6512],
            [16.976, 48.6487],
            [16.9645, 48.6382],
            [16.964, 48.6334],
            [16.9574, 48.6248],
            [16.9369, 48.6187],
            [16.9198, 48.6217],
            [16.9193, 48.6268],
            [16.9255, 48.6323],
            [16.9276, 48.6424],
            [16.9209, 48.6453],
            [16.9193, 48.651],
            [16.9233, 48.6583],
            [16.9201, 48.6632],
            [16.9222, 48.6705],
            [16.9097, 48.6769],
            [16.9182, 48.6929],
            [16.9119, 48.7064],
            [16.9031, 48.7111],
            [16.9024, 48.7176],
            [16.8862, 48.7157],
            [16.88, 48.7231],
            [16.8736, 48.715],
            [16.8676, 48.7115],
            [16.8481, 48.7125],
            [16.8409, 48.7111],
            [16.8361, 48.7161],
            [16.8296, 48.7115],
            [16.8181, 48.7101],
            [16.8061, 48.7121],
            [16.7951, 48.7105],
            [16.7899, 48.7138],
            [16.7724, 48.7125],
            [16.7648, 48.7188],
            [16.7539, 48.7202],
            [16.7475, 48.7322],
            [16.7405, 48.7309],
            [16.7254, 48.7377],
            [16.7012, 48.7366],
            [16.6879, 48.729],
            [16.6825, 48.7279],
            [16.6736, 48.7391],
            [16.6813, 48.7505],
            [16.6808, 48.7591],
            [16.6747, 48.7712],
            [16.6649, 48.7754],
            [16.6642, 48.7808],
            [16.6546, 48.7824],
            [16.6483, 48.7862],
            [16.6372, 48.7866],
            [16.6302, 48.7803],
            [16.6191, 48.782],
            [16.6102, 48.7805],
            [16.5943, 48.7819],
            [16.593, 48.7876],
            [16.5772, 48.7935],
            [16.5669, 48.7929],
            [16.5571, 48.8026],
            [16.541, 48.8137],
            [16.5344, 48.8109],
            [16.5287, 48.8034],
            [16.5198, 48.7987],
            [16.5163, 48.8026],
            [16.4923, 48.8012],
            [16.4769, 48.8022],
            [16.4696, 48.8062],
            [16.4497, 48.7997],
            [16.4482, 48.7904],
            [16.4388, 48.7822],
            [16.4357, 48.7756],
            [16.4377, 48.7685],
            [16.433, 48.7643],
            [16.4155, 48.7579],
            [16.4097, 48.7444],
            [16.3957, 48.7367],
            [16.377, 48.7308],
            [16.3604, 48.7276],
            [16.3519, 48.7335],
            [16.3573, 48.7382],
            [16.3505, 48.742],
            [16.3408, 48.7429],
            [16.3364, 48.733],
            [16.3321, 48.7315],
            [16.3117, 48.7333],
            [16.3059, 48.7381],
            [16.2958, 48.7377],
            [16.2774, 48.7414],
            [16.2673, 48.7409],
            [16.263, 48.7373],
            [16.2466, 48.7402],
            [16.1918, 48.7424],
            [16.176, 48.7462],
            [16.1541, 48.7487],
            [16.1477, 48.7437],
            [16.1381, 48.7428],
            [16.1183, 48.7465],
            [16.1033, 48.7452],
            [16.0812, 48.7495],
            [16.0629, 48.7546],
            [16.051, 48.7613],
            [16.0363, 48.7651],
            [16.0262, 48.7707],
            [16.0168, 48.7723],
            [16.0089, 48.7764],
            [15.9938, 48.7787],
            [15.9805, 48.7896],
            [15.9655, 48.7974],
            [15.9596, 48.7982],
            [15.9508, 48.8052],
            [15.9526, 48.8144],
            [15.9591, 48.8212],
            [15.948, 48.8225],
            [15.9331, 48.8194],
            [15.9242, 48.8241],
            [15.9271, 48.8307],
            [15.9171, 48.8385],
            [15.9085, 48.8357],
            [15.898, 48.8483],
            [15.8903, 48.8428],
            [15.8802, 48.8476],
            [15.8811, 48.8533],
            [15.8635, 48.8527],
            [15.857, 48.8576],
            [15.8471, 48.8581],
            [15.8423, 48.862],
            [15.8517, 48.8657],
            [15.8501, 48.8694],
            [15.8395, 48.8773],
            [15.8318, 48.8719],
            [15.8053, 48.8721],
            [15.779, 48.8746],
            [15.7651, 48.8582],
            [15.7522, 48.8514],
            [15.7378, 48.8552],
            [15.716, 48.8578],
            [15.7095, 48.8606],
            [15.6867, 48.8567],
            [15.6832, 48.8616],
            [15.6719, 48.8674],
            [15.6548, 48.8817],
            [15.6424, 48.886],
            [15.6302, 48.8846],
            [15.6192, 48.8955],
            [15.5988, 48.8967],
            [15.5888, 48.895],
            [15.5784, 48.8961],
            [15.5584, 48.9044],
            [15.541, 48.908],
            [15.5531, 48.9245],
            [15.5593, 48.9242],
            [15.5626, 48.9314],
            [15.5793, 48.9402],
            [15.5863, 48.9486],
            [15.6016, 48.9573],
            [15.6126, 48.9586],
            [15.6247, 48.9417],
            [15.6393, 48.9428],
            [15.6489, 48.938],
            [15.6519, 48.947],
            [15.6626, 48.9516],
            [15.6616, 48.9637],
            [15.6689, 48.9646],
            [15.6798, 48.9608],
            [15.6962, 48.9671],
            [15.7064, 48.9677],
            [15.7167, 48.9615],
            [15.73, 48.9484],
            [15.7385, 48.9513],
            [15.7389, 48.9594],
            [15.7442, 48.9685],
            [15.7546, 48.9656],
            [15.7584, 48.9805],
            [15.7639, 48.9791],
            [15.7813, 48.9797],
            [15.7956, 48.9861],
            [15.7984, 48.9982],
            [15.8036, 49.0021],
            [15.8144, 49.0046],
            [15.8179, 49.0107],
            [15.8269, 49.0068],
            [15.8343, 49.0136],
            [15.8476, 49.0116],
            [15.85, 49.0145],
            [15.8668, 49.0089],
            [15.8768, 49.018],
            [15.8836, 49.0319],
            [15.8846, 49.0388],
            [15.8929, 49.0389],
            [15.9056, 49.0351],
            [15.9143, 49.0285],
            [15.9234, 49.0349],
            [15.9334, 49.0388],
            [15.9335, 49.0461],
            [15.9435, 49.0473],
            [15.9491, 49.0519],
            [15.9601, 49.0562],
            [15.9678, 49.0467],
            [15.9711, 49.0391],
            [15.981, 49.0329],
            [15.9956, 49.0267],
            [16.0084, 49.0262],
            [16.0215, 49.0311],
            [16.0318, 49.0427],
            [16.0447, 49.0396],
            [16.055, 49.0425],
            [16.0637, 49.0362],
            [16.0688, 49.039],
            [16.0825, 49.0362],
            [16.0902, 49.0396],
            [16.1043, 49.0412],
            [16.1182, 49.037],
            [16.1259, 49.045],
            [16.1331, 49.0425],
            [16.1459, 49.0457],
            [16.1385, 49.0551],
            [16.129, 49.0586],
            [16.1387, 49.0667],
            [16.1602, 49.0717],
            [16.1782, 49.0702],
            [16.1835, 49.0729],
            [16.201, 49.0741],
            [16.2093, 49.0709],
            [16.2195, 49.0822],
            [16.2225, 49.0882],
            [16.2168, 49.0924],
            [16.2246, 49.0981],
            [16.2358, 49.0973],
            [16.2477, 49.1035],
            [16.2667, 49.1157],
            [16.2694, 49.129],
            [16.2466, 49.1373],
            [16.2379, 49.1423],
            [16.2396, 49.1507],
            [16.2365, 49.158],
            [16.2253, 49.1642],
            [16.2319, 49.1741],
            [16.2418, 49.1765],
            [16.2533, 49.1754],
            [16.2625, 49.1806],
            [16.2695, 49.1784],
            [16.2731, 49.1848],
            [16.2671, 49.1956],
            [16.2735, 49.2001],
            [16.2602, 49.209],
            [16.2372, 49.2119],
            [16.2369, 49.2272],
            [16.2434, 49.2402],
            [16.2546, 49.2533],
            [16.2687, 49.2522],
            [16.2808, 49.2546],
            [16.2863, 49.2645],
            [16.2579, 49.2755],
            [16.2565, 49.2811],
            [16.2591, 49.2909],
            [16.2726, 49.2947],
            [16.2794, 49.3026],
            [16.2858, 49.3035],
            [16.2923, 49.3126],
            [16.2893, 49.3244],
            [16.311, 49.3287],
            [16.3295, 49.3355],
            [16.335, 49.3421],
            [16.3427, 49.344],
            [16.3479, 49.3385],
            [16.357, 49.3461],
            [16.3658, 49.3461],
            [16.376, 49.3546],
            [16.3866, 49.3589],
            [16.3739, 49.3714],
            [16.3795, 49.3766],
            [16.3716, 49.3848],
            [16.3805, 49.3995],
            [16.3788, 49.4069],
            [16.3711, 49.4253],
            [16.3741, 49.4289],
            [16.3673, 49.4345],
            [16.3649, 49.4429],
            [16.3682, 49.4489],
            [16.3747, 49.4503],
            [16.3817, 49.4563],
            [16.3736, 49.4644],
            [16.3884, 49.4696],
            [16.3762, 49.482],
            [16.3685, 49.4842],
            [16.3595, 49.4909],
            [16.3678, 49.4972],
            [16.3725, 49.4916],
            [16.3859, 49.4926],
            [16.3872, 49.4975],
            [16.395, 49.5008],
            [16.4022, 49.5096],
            [16.4094, 49.5106],
            [16.4149, 49.5161],
            [16.4187, 49.5247],
            [16.415, 49.533],
            [16.4068, 49.5392],
            [16.4019, 49.5376],
            [16.3877, 49.5386],
            [16.3729, 49.5513],
            [16.3819, 49.5549],
            [16.3916, 49.5629],
            [16.3934, 49.577],
            [16.391, 49.5815],
            [16.3995, 49.5759],
            [16.4192, 49.5839],
            [16.4156, 49.5909],
            [16.4364, 49.5988],
            [16.4501, 49.5958],
            [16.4457, 49.5873],
            [16.4637, 49.5924],
            [16.4724, 49.5913],
            [16.4826, 49.5979],
            [16.4921, 49.5997],
            [16.501, 49.5917],
            [16.5343, 49.5904],
            [16.5433, 49.5927],
            [16.5437, 49.6072],
            [16.5482, 49.6101],
            [16.5497, 49.6276],
            [16.5558, 49.6306],
            [16.586, 49.6231],
            [16.5917, 49.6258],
            [16.6056, 49.6207],
            [16.6166, 49.6253],
            [16.6236, 49.6317],
            [16.6348, 49.6288],
            [16.651, 49.6193],
            [16.6609, 49.6253],
            [16.6733, 49.6262],
            [16.6817, 49.6226],
            [16.6929, 49.6238],
            [16.7152, 49.6213],
            [16.7183, 49.6152],
            [16.7525, 49.607],
            [16.7767, 49.5966],
            [16.7826, 49.5957],
            [16.8048, 49.5992],
            [16.8001, 49.5943],
            [16.789, 49.5909],
            [16.7972, 49.5801],
            [16.7957, 49.5739],
            [16.7865, 49.5742],
            [16.7772, 49.5791],
            [16.7703, 49.5779],
            [16.7488, 49.5828],
            [16.743, 49.5766],
            [16.7462, 49.5636],
            [16.7498, 49.5599],
            [16.7386, 49.5461],
            [16.7423, 49.5437],
            [16.7716, 49.5424],
            [16.7945, 49.534],
            [16.8082, 49.5332],
            [16.811, 49.5308],
            [16.802, 49.5239],
            [16.8068, 49.5165],
            [16.8053, 49.5123],
            [16.7915, 49.5066],
            [16.7854, 49.4957],
            [16.791, 49.4926],
            [16.7898, 49.4867],
            [16.7976, 49.4758],
            [16.7961, 49.4631],
            [16.7983, 49.4584],
            [16.8076, 49.4525],
            [16.8185, 49.451],
            [16.8162, 49.4445],
            [16.8271, 49.4391],
            [16.8268, 49.4333],
            [16.8112, 49.4283],
            [16.8004, 49.4282],
            [16.8007, 49.4209],
            [16.7946, 49.4147],
            [16.809, 49.404],
            [16.8268, 49.3997],
            [16.8369, 49.3912],
            [16.8528, 49.3869],
            [16.8635, 49.3872],
            [16.8793, 49.3986],
            [16.8921, 49.4023],
            [16.8919, 49.4161],
            [16.9036, 49.4233],
            [16.9117, 49.4337],
            [16.9069, 49.4387],
            [16.9067, 49.4505],
            [16.9016, 49.4588],
            [16.8963, 49.4596],
            [16.886, 49.4664],
            [16.8887, 49.4728],
            [16.8842, 49.4789],
            [16.8893, 49.4816],
            [16.8914, 49.4889],
            [16.9035, 49.4942],
            [16.9322, 49.4877],
            [16.9398, 49.4889],
            [16.9571, 49.4707],
            [16.9658, 49.4644],
            [16.9651, 49.4572],
            [16.9723, 49.444],
            [16.9897, 49.4208],
            [16.9989, 49.4202],
            [16.9991, 49.394],
            [17.0191, 49.3928],
            [17.0282, 49.3869],
            [17.0408, 49.3858],
            [17.0567, 49.3965],
            [17.0583, 49.3916],
            [17.052, 49.3833],
            [17.0339, 49.3792],
            [17.0272, 49.3724],
            [17.0326, 49.3687],
            [17.0316, 49.3626],
            [17.0379, 49.3587],
            [17.0561, 49.353],
            [17.0648, 49.3484],
            [17.0801, 49.3452],
            [17.0924, 49.3397],
            [17.0952, 49.3426],
            [17.1101, 49.3375],
            [17.117, 49.3372],
            [17.1283, 49.3233],
            [17.1359, 49.3253],
            [17.1412, 49.321],
            [17.138, 49.3132],
            [17.1416, 49.3073],
            [17.1451, 49.2944],
            [17.1509, 49.2941],
            [17.1586, 49.2744],
            [17.1448, 49.2551],
            [17.1337, 49.2507],
            [17.1292, 49.2449],
            [17.1371, 49.242],
            [17.1471, 49.2306],
            [17.1466, 49.2258],
            [17.1369, 49.2145],
            [17.1434, 49.2122],
            [17.1516, 49.2012],
            [17.1539, 49.1936],
            [17.1652, 49.1913],
            [17.1533, 49.1831],
            [17.1554, 49.1747],
            [17.17, 49.1765],
            [17.185, 49.1734],
            [17.1901, 49.1806],
            [17.1971, 49.1833],
            [17.2024, 49.1755],
            [17.1971, 49.1667],
            [17.1985, 49.1593],
            [17.1908, 49.1508],
            [17.1869, 49.1378],
            [17.1796, 49.139],
            [17.1732, 49.1334],
            [17.1656, 49.1318],
            [17.154, 49.1338],
            [17.1419, 49.1167],
            [17.1426, 49.1037],
            [17.1341, 49.0997],
            [17.1271, 49.1024],
            [17.1147, 49.1024],
            [17.1136, 49.0935],
            [17.119, 49.0844],
            [17.1139, 49.0784],
            [17.1195, 49.0752],
            [17.1317, 49.0745],
            [17.1383, 49.0771],
            [17.1451, 49.0736],
            [17.1508, 49.0781],
            [17.1733, 49.0839],
            [17.1861, 49.0852],
            [17.1992, 49.0919],
            [17.2149, 49.0921],
            [17.2176, 49.0784],
            [17.2274, 49.0744],
            [17.2324, 49.0648],
            [17.2292, 49.0529],
            [17.2238, 49.0484],
            [17.2204, 49.0399],
            [17.2526, 49.0372],
            [17.252, 49.0309],
            [17.2671, 49.0339],
            [17.2769, 49.0207],
            [17.2764, 49.014],
            [17.2871, 49.0167],
            [17.3083, 49.017],
            [17.3137, 49.0117],
            [17.3248, 49.0116],
            [17.3317, 49.0154],
            [17.3434, 49.012],
            [17.3436, 49.0087],
            [17.3623, 49.0045],
            [17.3568, 48.9976],
            [17.3583, 48.9939],
            [17.3541, 48.9779],
            [17.3771, 48.9742],
            [17.3823, 48.9784],
            [17.3964, 48.9808],
            [17.3989, 48.9733],
            [17.4174, 48.9595],
            [17.4324, 48.9518],
            [17.4505, 48.9597],
            [17.4584, 48.9587],
            [17.4718, 48.9628],
            [17.4751, 48.956],
            [17.483, 48.9568],
            [17.4971, 48.9621],
            [17.5087, 48.9528],
            [17.522, 48.9563],
            [17.5495, 48.9464],
            [17.5529, 48.9425],
            [17.5438, 48.9322],
            [17.5395, 48.9216],
            [17.5471, 48.9158],
            [17.5622, 48.9218],
            [17.583, 48.9114],
            [17.602, 48.9094],
            [17.6134, 48.8959],
            [17.6163, 48.889],
            [17.6235, 48.884],
            [17.6245, 48.8782],
            [17.6351, 48.8738],
            [17.6339, 48.8693],
            [17.6486, 48.8519],
          ],
        ],
      },
      properties: {
        name: 'Jihomoravský',
        id: 'CZ-64',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'South Moravia',
      },
      id: 'CZ-64',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.4318, 50.2543],
            [17.4303, 50.2447],
            [17.425, 50.2402],
            [17.4294, 50.2297],
            [17.4229, 50.2141],
            [17.4171, 50.2108],
            [17.3997, 50.2093],
            [17.3923, 50.2121],
            [17.3872, 50.2178],
            [17.3777, 50.2208],
            [17.3739, 50.2129],
            [17.3591, 50.2038],
            [17.35, 50.1901],
            [17.3409, 50.1845],
            [17.3238, 50.1904],
            [17.3111, 50.1876],
            [17.304, 50.1727],
            [17.2955, 50.1701],
            [17.2882, 50.1729],
            [17.2823, 50.1695],
            [17.2749, 50.1607],
            [17.2601, 50.1475],
            [17.2424, 50.1433],
            [17.2443, 50.1282],
            [17.2512, 50.1221],
            [17.2462, 50.1144],
            [17.2275, 50.1065],
            [17.2225, 50.1018],
            [17.2313, 50.0886],
            [17.23, 50.082],
            [17.2354, 50.0672],
            [17.2303, 50.0556],
            [17.2203, 50.0473],
            [17.2104, 50.0432],
            [17.1909, 50.0321],
            [17.1795, 50.0185],
            [17.1711, 50.0135],
            [17.169, 50.003],
            [17.1739, 49.9939],
            [17.1707, 49.9878],
            [17.1548, 49.9828],
            [17.1607, 49.9781],
            [17.1699, 49.9753],
            [17.1849, 49.9671],
            [17.1924, 49.9648],
            [17.1999, 49.9579],
            [17.2032, 49.9497],
            [17.1903, 49.9328],
            [17.1793, 49.9323],
            [17.1757, 49.9275],
            [17.1617, 49.9207],
            [17.1612, 49.9137],
            [17.1705, 49.9105],
            [17.1698, 49.9012],
            [17.1563, 49.89],
            [17.1481, 49.8873],
            [17.1461, 49.8804],
            [17.1543, 49.8761],
            [17.1593, 49.8681],
            [17.1706, 49.8643],
            [17.178, 49.8493],
            [17.1856, 49.8446],
            [17.1935, 49.8502],
            [17.2165, 49.8522],
            [17.2198, 49.842],
            [17.2139, 49.8373],
            [17.2086, 49.8275],
            [17.2105, 49.8227],
            [17.2256, 49.8209],
            [17.2324, 49.8254],
            [17.2511, 49.8234],
            [17.2735, 49.8181],
            [17.2964, 49.8075],
            [17.3055, 49.809],
            [17.3112, 49.8061],
            [17.3217, 49.8059],
            [17.3227, 49.798],
            [17.3332, 49.8058],
            [17.3474, 49.8069],
            [17.3573, 49.812],
            [17.3718, 49.8041],
            [17.3915, 49.7882],
            [17.4077, 49.7809],
            [17.4112, 49.7748],
            [17.4094, 49.7698],
            [17.4195, 49.7629],
            [17.4128, 49.7601],
            [17.4307, 49.7554],
            [17.448, 49.7617],
            [17.4672, 49.7573],
            [17.4845, 49.7574],
            [17.4943, 49.7439],
            [17.5196, 49.74],
            [17.5312, 49.7526],
            [17.5346, 49.7607],
            [17.5662, 49.7522],
            [17.5864, 49.7347],
            [17.5952, 49.7334],
            [17.6076, 49.7348],
            [17.6109, 49.7409],
            [17.6319, 49.751],
            [17.6561, 49.7451],
            [17.659, 49.74],
            [17.6676, 49.7409],
            [17.6782, 49.7378],
            [17.677, 49.7332],
            [17.6865, 49.7288],
            [17.6981, 49.7318],
            [17.694, 49.7215],
            [17.6856, 49.7145],
            [17.6815, 49.7048],
            [17.6875, 49.6886],
            [17.6866, 49.6814],
            [17.6931, 49.6757],
            [17.7103, 49.6694],
            [17.7342, 49.6672],
            [17.7352, 49.6759],
            [17.7387, 49.6789],
            [17.7514, 49.6711],
            [17.7581, 49.6616],
            [17.769, 49.6531],
            [17.7678, 49.6451],
            [17.7727, 49.6423],
            [17.7778, 49.6319],
            [17.788, 49.6346],
            [17.7951, 49.6283],
            [17.8142, 49.6219],
            [17.8174, 49.616],
            [17.8329, 49.6083],
            [17.8401, 49.5988],
            [17.8443, 49.5988],
            [17.8515, 49.5832],
            [17.8635, 49.5785],
            [17.8423, 49.5634],
            [17.8548, 49.5573],
            [17.854, 49.551],
            [17.8623, 49.5448],
            [17.8721, 49.5595],
            [17.8818, 49.5651],
            [17.8964, 49.564],
            [17.9077, 49.5561],
            [17.9047, 49.5504],
            [17.9122, 49.5463],
            [17.9153, 49.5381],
            [17.9121, 49.5267],
            [17.8995, 49.5171],
            [17.9003, 49.5109],
            [17.8861, 49.5089],
            [17.8782, 49.5135],
            [17.8631, 49.5149],
            [17.8622, 49.5181],
            [17.8482, 49.5181],
            [17.8394, 49.5133],
            [17.8395, 49.5047],
            [17.8274, 49.5002],
            [17.8112, 49.4995],
            [17.8089, 49.4912],
            [17.7904, 49.4864],
            [17.7996, 49.4702],
            [17.7901, 49.4637],
            [17.7773, 49.4584],
            [17.7813, 49.4549],
            [17.7737, 49.4406],
            [17.7654, 49.4382],
            [17.7507, 49.4386],
            [17.7407, 49.4347],
            [17.732, 49.4355],
            [17.7109, 49.4437],
            [17.71, 49.4497],
            [17.6931, 49.4607],
            [17.6867, 49.4588],
            [17.6759, 49.4621],
            [17.6702, 49.4503],
            [17.6718, 49.4423],
            [17.6581, 49.4398],
            [17.6484, 49.451],
            [17.6304, 49.4461],
            [17.6236, 49.4411],
            [17.6366, 49.4344],
            [17.6347, 49.432],
            [17.6482, 49.4215],
            [17.6505, 49.4152],
            [17.635, 49.4069],
            [17.6254, 49.3974],
            [17.5937, 49.3992],
            [17.5909, 49.4049],
            [17.5808, 49.4071],
            [17.5338, 49.4024],
            [17.5282, 49.3961],
            [17.5222, 49.4012],
            [17.5136, 49.3963],
            [17.5155, 49.3832],
            [17.5051, 49.3784],
            [17.4995, 49.3714],
            [17.4736, 49.3547],
            [17.4705, 49.3649],
            [17.4632, 49.3722],
            [17.4481, 49.3785],
            [17.4342, 49.3803],
            [17.4189, 49.3792],
            [17.4147, 49.383],
            [17.391, 49.3875],
            [17.3879, 49.3812],
            [17.3742, 49.3799],
            [17.3781, 49.4017],
            [17.3703, 49.4105],
            [17.3636, 49.4101],
            [17.3547, 49.4044],
            [17.3432, 49.3915],
            [17.3356, 49.3894],
            [17.3334, 49.3831],
            [17.3378, 49.368],
            [17.3457, 49.3584],
            [17.3535, 49.3542],
            [17.3513, 49.3491],
            [17.3557, 49.3432],
            [17.3468, 49.3424],
            [17.3346, 49.3356],
            [17.3218, 49.331],
            [17.3089, 49.3151],
            [17.3041, 49.314],
            [17.2939, 49.3015],
            [17.2778, 49.2953],
            [17.2671, 49.2961],
            [17.2559, 49.3005],
            [17.242, 49.2948],
            [17.2294, 49.298],
            [17.2146, 49.2927],
            [17.2098, 49.2888],
            [17.1935, 49.2907],
            [17.1828, 49.2881],
            [17.1818, 49.2787],
            [17.1776, 49.2676],
            [17.1586, 49.2744],
            [17.1509, 49.2941],
            [17.1451, 49.2944],
            [17.1416, 49.3073],
            [17.138, 49.3132],
            [17.1412, 49.321],
            [17.1359, 49.3253],
            [17.1283, 49.3233],
            [17.117, 49.3372],
            [17.1101, 49.3375],
            [17.0952, 49.3426],
            [17.0924, 49.3397],
            [17.0801, 49.3452],
            [17.0648, 49.3484],
            [17.0561, 49.353],
            [17.0379, 49.3587],
            [17.0316, 49.3626],
            [17.0326, 49.3687],
            [17.0272, 49.3724],
            [17.0339, 49.3792],
            [17.052, 49.3833],
            [17.0583, 49.3916],
            [17.0567, 49.3965],
            [17.0408, 49.3858],
            [17.0282, 49.3869],
            [17.0191, 49.3928],
            [16.9991, 49.394],
            [16.9989, 49.4202],
            [16.9897, 49.4208],
            [16.9723, 49.444],
            [16.9651, 49.4572],
            [16.9658, 49.4644],
            [16.9571, 49.4707],
            [16.9398, 49.4889],
            [16.9322, 49.4877],
            [16.9035, 49.4942],
            [16.8914, 49.4889],
            [16.8893, 49.4816],
            [16.8842, 49.4789],
            [16.8887, 49.4728],
            [16.886, 49.4664],
            [16.8963, 49.4596],
            [16.9016, 49.4588],
            [16.9067, 49.4505],
            [16.9069, 49.4387],
            [16.9117, 49.4337],
            [16.9036, 49.4233],
            [16.8919, 49.4161],
            [16.8921, 49.4023],
            [16.8793, 49.3986],
            [16.8635, 49.3872],
            [16.8528, 49.3869],
            [16.8369, 49.3912],
            [16.8268, 49.3997],
            [16.809, 49.404],
            [16.7946, 49.4147],
            [16.8007, 49.4209],
            [16.8004, 49.4282],
            [16.8112, 49.4283],
            [16.8268, 49.4333],
            [16.8271, 49.4391],
            [16.8162, 49.4445],
            [16.8185, 49.451],
            [16.8076, 49.4525],
            [16.7983, 49.4584],
            [16.7961, 49.4631],
            [16.7976, 49.4758],
            [16.7898, 49.4867],
            [16.791, 49.4926],
            [16.7854, 49.4957],
            [16.7915, 49.5066],
            [16.8053, 49.5123],
            [16.8068, 49.5165],
            [16.802, 49.5239],
            [16.811, 49.5308],
            [16.8082, 49.5332],
            [16.7945, 49.534],
            [16.7716, 49.5424],
            [16.7423, 49.5437],
            [16.7386, 49.5461],
            [16.7498, 49.5599],
            [16.7462, 49.5636],
            [16.743, 49.5766],
            [16.7488, 49.5828],
            [16.7703, 49.5779],
            [16.7772, 49.5791],
            [16.7865, 49.5742],
            [16.7957, 49.5739],
            [16.7972, 49.5801],
            [16.789, 49.5909],
            [16.8001, 49.5943],
            [16.8048, 49.5992],
            [16.8016, 49.6036],
            [16.808, 49.6219],
            [16.8129, 49.6284],
            [16.8013, 49.6379],
            [16.8045, 49.6451],
            [16.8037, 49.6554],
            [16.81, 49.6607],
            [16.827, 49.6703],
            [16.8382, 49.6747],
            [16.8466, 49.6807],
            [16.8432, 49.6853],
            [16.8475, 49.7004],
            [16.8406, 49.7059],
            [16.8478, 49.7159],
            [16.8446, 49.7201],
            [16.8286, 49.7279],
            [16.8059, 49.7317],
            [16.8035, 49.7271],
            [16.7897, 49.7267],
            [16.7889, 49.7309],
            [16.7984, 49.7338],
            [16.8062, 49.7396],
            [16.8121, 49.7477],
            [16.7933, 49.757],
            [16.797, 49.7651],
            [16.8046, 49.7675],
            [16.7982, 49.7737],
            [16.7882, 49.7755],
            [16.7708, 49.7899],
            [16.7606, 49.7967],
            [16.7565, 49.8029],
            [16.7597, 49.8096],
            [16.758, 49.8188],
            [16.7364, 49.8291],
            [16.7358, 49.8351],
            [16.7385, 49.8606],
            [16.758, 49.8678],
            [16.7623, 49.8728],
            [16.752, 49.8931],
            [16.7574, 49.9011],
            [16.7525, 49.9082],
            [16.7561, 49.9123],
            [16.7565, 49.9203],
            [16.7462, 49.9252],
            [16.7518, 49.9321],
            [16.7436, 49.9346],
            [16.7336, 49.9466],
            [16.735, 49.9675],
            [16.73, 49.971],
            [16.7273, 49.9809],
            [16.7195, 49.9843],
            [16.7148, 49.9901],
            [16.7125, 49.9988],
            [16.7264, 50.0025],
            [16.7554, 50.0008],
            [16.7704, 50.0079],
            [16.7672, 50.015],
            [16.7876, 50.0242],
            [16.7947, 50.0299],
            [16.8087, 50.0291],
            [16.8084, 50.0391],
            [16.8186, 50.045],
            [16.809, 50.053],
            [16.8135, 50.0604],
            [16.8, 50.067],
            [16.8036, 50.0739],
            [16.8121, 50.0807],
            [16.8236, 50.0853],
            [16.8057, 50.1081],
            [16.8091, 50.1118],
            [16.8209, 50.1126],
            [16.8276, 50.1163],
            [16.8267, 50.1274],
            [16.8326, 50.1265],
            [16.839, 50.1434],
            [16.8449, 50.1497],
            [16.8501, 50.1655],
            [16.8671, 50.1775],
            [16.8688, 50.1875],
            [16.8659, 50.1986],
            [16.8733, 50.1972],
            [16.8844, 50.2002],
            [16.886, 50.2119],
            [16.89, 50.2166],
            [16.8986, 50.2185],
            [16.9005, 50.2233],
            [16.9187, 50.2268],
            [16.9288, 50.2231],
            [16.9573, 50.2229],
            [16.9659, 50.2298],
            [16.9646, 50.2396],
            [16.9768, 50.2447],
            [16.9821, 50.2413],
            [16.9832, 50.2346],
            [16.9942, 50.2267],
            [17.0015, 50.2162],
            [17.0169, 50.2201],
            [17.0285, 50.2313],
            [17.0129, 50.2419],
            [17.0129, 50.2489],
            [17.0035, 50.2573],
            [17.012, 50.2608],
            [17.0199, 50.2699],
            [17.0212, 50.2777],
            [17.0176, 50.2863],
            [17.0023, 50.3031],
            [16.9929, 50.3028],
            [16.9743, 50.3092],
            [16.9691, 50.3163],
            [16.9602, 50.3126],
            [16.9483, 50.3153],
            [16.942, 50.3201],
            [16.9357, 50.3307],
            [16.9374, 50.3428],
            [16.9297, 50.3455],
            [16.9153, 50.3627],
            [16.9194, 50.368],
            [16.9157, 50.3826],
            [16.9102, 50.3844],
            [16.9065, 50.391],
            [16.8963, 50.3898],
            [16.8918, 50.3952],
            [16.8705, 50.4035],
            [16.8639, 50.4091],
            [16.8682, 50.4204],
            [16.8764, 50.4289],
            [16.8822, 50.4427],
            [16.8934, 50.4432],
            [16.8989, 50.4476],
            [16.9093, 50.4482],
            [16.928, 50.4388],
            [16.9449, 50.435],
            [16.9683, 50.4241],
            [16.9745, 50.4185],
            [17.0015, 50.427],
            [17.0134, 50.4185],
            [17.0236, 50.4214],
            [17.0288, 50.4165],
            [17.0375, 50.4148],
            [17.0524, 50.407],
            [17.0827, 50.4052],
            [17.1033, 50.4063],
            [17.1106, 50.4045],
            [17.1107, 50.3936],
            [17.1234, 50.394],
            [17.1433, 50.3812],
            [17.1571, 50.3802],
            [17.1632, 50.3839],
            [17.178, 50.3857],
            [17.1848, 50.3898],
            [17.2036, 50.386],
            [17.1989, 50.3754],
            [17.2008, 50.3647],
            [17.2106, 50.3597],
            [17.2277, 50.3436],
            [17.2369, 50.3405],
            [17.2486, 50.3327],
            [17.2674, 50.3294],
            [17.2785, 50.3216],
            [17.2925, 50.3181],
            [17.3136, 50.3208],
            [17.3299, 50.3277],
            [17.3502, 50.3283],
            [17.3421, 50.3225],
            [17.3391, 50.3135],
            [17.3494, 50.3121],
            [17.3542, 50.3087],
            [17.3482, 50.2998],
            [17.3463, 50.2923],
            [17.3492, 50.2875],
            [17.3407, 50.284],
            [17.3489, 50.2671],
            [17.353, 50.2646],
            [17.3646, 50.2656],
            [17.3648, 50.2806],
            [17.3736, 50.2829],
            [17.3914, 50.2829],
            [17.4021, 50.2798],
            [17.4085, 50.2743],
            [17.4209, 50.2778],
            [17.4322, 50.2631],
            [17.4318, 50.2543],
          ],
        ],
      },
      properties: {
        name: 'Olomoucký',
        id: 'CZ-71',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Olomouc',
      },
      id: 'CZ-71',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.6486, 48.8519],
            [17.6339, 48.8693],
            [17.6351, 48.8738],
            [17.6245, 48.8782],
            [17.6235, 48.884],
            [17.6163, 48.889],
            [17.6134, 48.8959],
            [17.602, 48.9094],
            [17.583, 48.9114],
            [17.5622, 48.9218],
            [17.5471, 48.9158],
            [17.5395, 48.9216],
            [17.5438, 48.9322],
            [17.5529, 48.9425],
            [17.5495, 48.9464],
            [17.522, 48.9563],
            [17.5087, 48.9528],
            [17.4971, 48.9621],
            [17.483, 48.9568],
            [17.4751, 48.956],
            [17.4718, 48.9628],
            [17.4584, 48.9587],
            [17.4505, 48.9597],
            [17.4324, 48.9518],
            [17.4174, 48.9595],
            [17.3989, 48.9733],
            [17.3964, 48.9808],
            [17.3823, 48.9784],
            [17.3771, 48.9742],
            [17.3541, 48.9779],
            [17.3583, 48.9939],
            [17.3568, 48.9976],
            [17.3623, 49.0045],
            [17.3436, 49.0087],
            [17.3434, 49.012],
            [17.3317, 49.0154],
            [17.3248, 49.0116],
            [17.3137, 49.0117],
            [17.3083, 49.017],
            [17.2871, 49.0167],
            [17.2764, 49.014],
            [17.2769, 49.0207],
            [17.2671, 49.0339],
            [17.252, 49.0309],
            [17.2526, 49.0372],
            [17.2204, 49.0399],
            [17.2238, 49.0484],
            [17.2292, 49.0529],
            [17.2324, 49.0648],
            [17.2274, 49.0744],
            [17.2176, 49.0784],
            [17.2149, 49.0921],
            [17.1992, 49.0919],
            [17.1861, 49.0852],
            [17.1733, 49.0839],
            [17.1508, 49.0781],
            [17.1451, 49.0736],
            [17.1383, 49.0771],
            [17.1317, 49.0745],
            [17.1195, 49.0752],
            [17.1139, 49.0784],
            [17.119, 49.0844],
            [17.1136, 49.0935],
            [17.1147, 49.1024],
            [17.1271, 49.1024],
            [17.1341, 49.0997],
            [17.1426, 49.1037],
            [17.1419, 49.1167],
            [17.154, 49.1338],
            [17.1656, 49.1318],
            [17.1732, 49.1334],
            [17.1796, 49.139],
            [17.1869, 49.1378],
            [17.1908, 49.1508],
            [17.1985, 49.1593],
            [17.1971, 49.1667],
            [17.2024, 49.1755],
            [17.1971, 49.1833],
            [17.1901, 49.1806],
            [17.185, 49.1734],
            [17.17, 49.1765],
            [17.1554, 49.1747],
            [17.1533, 49.1831],
            [17.1652, 49.1913],
            [17.1539, 49.1936],
            [17.1516, 49.2012],
            [17.1434, 49.2122],
            [17.1369, 49.2145],
            [17.1466, 49.2258],
            [17.1471, 49.2306],
            [17.1371, 49.242],
            [17.1292, 49.2449],
            [17.1337, 49.2507],
            [17.1448, 49.2551],
            [17.1586, 49.2744],
            [17.1776, 49.2676],
            [17.1818, 49.2787],
            [17.1828, 49.2881],
            [17.1935, 49.2907],
            [17.2098, 49.2888],
            [17.2146, 49.2927],
            [17.2294, 49.298],
            [17.242, 49.2948],
            [17.2559, 49.3005],
            [17.2671, 49.2961],
            [17.2778, 49.2953],
            [17.2939, 49.3015],
            [17.3041, 49.314],
            [17.3089, 49.3151],
            [17.3218, 49.331],
            [17.3346, 49.3356],
            [17.3468, 49.3424],
            [17.3557, 49.3432],
            [17.3513, 49.3491],
            [17.3535, 49.3542],
            [17.3457, 49.3584],
            [17.3378, 49.368],
            [17.3334, 49.3831],
            [17.3356, 49.3894],
            [17.3432, 49.3915],
            [17.3547, 49.4044],
            [17.3636, 49.4101],
            [17.3703, 49.4105],
            [17.3781, 49.4017],
            [17.3742, 49.3799],
            [17.3879, 49.3812],
            [17.391, 49.3875],
            [17.4147, 49.383],
            [17.4189, 49.3792],
            [17.4342, 49.3803],
            [17.4481, 49.3785],
            [17.4632, 49.3722],
            [17.4705, 49.3649],
            [17.4736, 49.3547],
            [17.4995, 49.3714],
            [17.5051, 49.3784],
            [17.5155, 49.3832],
            [17.5136, 49.3963],
            [17.5222, 49.4012],
            [17.5282, 49.3961],
            [17.5338, 49.4024],
            [17.5808, 49.4071],
            [17.5909, 49.4049],
            [17.5937, 49.3992],
            [17.6254, 49.3974],
            [17.635, 49.4069],
            [17.6505, 49.4152],
            [17.6482, 49.4215],
            [17.6347, 49.432],
            [17.6366, 49.4344],
            [17.6236, 49.4411],
            [17.6304, 49.4461],
            [17.6484, 49.451],
            [17.6581, 49.4398],
            [17.6718, 49.4423],
            [17.6702, 49.4503],
            [17.6759, 49.4621],
            [17.6867, 49.4588],
            [17.6931, 49.4607],
            [17.71, 49.4497],
            [17.7109, 49.4437],
            [17.732, 49.4355],
            [17.7407, 49.4347],
            [17.7507, 49.4386],
            [17.7654, 49.4382],
            [17.7737, 49.4406],
            [17.7813, 49.4549],
            [17.7773, 49.4584],
            [17.7901, 49.4637],
            [17.7996, 49.4702],
            [17.7904, 49.4864],
            [17.8089, 49.4912],
            [17.8112, 49.4995],
            [17.8274, 49.5002],
            [17.8395, 49.5047],
            [17.8394, 49.5133],
            [17.8482, 49.5181],
            [17.8622, 49.5181],
            [17.8631, 49.5149],
            [17.8782, 49.5135],
            [17.8861, 49.5089],
            [17.9003, 49.5109],
            [17.8995, 49.5171],
            [17.9121, 49.5267],
            [17.9153, 49.5381],
            [17.9318, 49.5396],
            [17.9574, 49.539],
            [17.9731, 49.5374],
            [17.978, 49.5316],
            [17.9928, 49.5221],
            [17.9909, 49.5133],
            [17.9975, 49.5033],
            [18.0133, 49.5039],
            [18.0324, 49.5118],
            [18.0765, 49.5149],
            [18.0841, 49.5173],
            [18.1099, 49.5172],
            [18.1269, 49.5201],
            [18.1359, 49.5088],
            [18.1584, 49.513],
            [18.1647, 49.5166],
            [18.1764, 49.5048],
            [18.1915, 49.4938],
            [18.2017, 49.4894],
            [18.2181, 49.4927],
            [18.2255, 49.488],
            [18.2524, 49.4826],
            [18.2593, 49.4844],
            [18.2693, 49.4922],
            [18.2894, 49.492],
            [18.302, 49.4893],
            [18.3035, 49.4783],
            [18.3079, 49.4641],
            [18.3161, 49.4606],
            [18.3241, 49.4506],
            [18.3425, 49.4457],
            [18.3511, 49.4466],
            [18.3591, 49.4377],
            [18.3589, 49.4327],
            [18.3672, 49.4317],
            [18.3719, 49.4242],
            [18.3659, 49.405],
            [18.3726, 49.406],
            [18.3904, 49.3989],
            [18.4055, 49.3958],
            [18.4164, 49.3705],
            [18.4142, 49.3655],
            [18.3997, 49.3557],
            [18.4045, 49.3503],
            [18.3908, 49.3454],
            [18.3792, 49.3309],
            [18.3665, 49.3234],
            [18.3269, 49.3155],
            [18.3024, 49.3077],
            [18.3002, 49.3042],
            [18.2759, 49.2994],
            [18.2661, 49.2957],
            [18.2522, 49.2932],
            [18.239, 49.2947],
            [18.2318, 49.2929],
            [18.1931, 49.2883],
            [18.1857, 49.2858],
            [18.186, 49.2703],
            [18.1782, 49.2637],
            [18.1648, 49.2584],
            [18.1614, 49.2535],
            [18.1492, 49.248],
            [18.1463, 49.2307],
            [18.156, 49.2276],
            [18.1554, 49.2157],
            [18.1498, 49.2122],
            [18.1494, 49.203],
            [18.1433, 49.1954],
            [18.1312, 49.1864],
            [18.1312, 49.1685],
            [18.1362, 49.1602],
            [18.1291, 49.1544],
            [18.118, 49.1501],
            [18.1186, 49.1434],
            [18.1112, 49.133],
            [18.1193, 49.1248],
            [18.1119, 49.1105],
            [18.1168, 49.0921],
            [18.0983, 49.0661],
            [18.0992, 49.0574],
            [18.0921, 49.0537],
            [18.0704, 49.0353],
            [18.0526, 49.0311],
            [18.032, 49.0246],
            [18.0247, 49.0206],
            [18.0101, 49.0208],
            [17.9881, 49.0245],
            [17.975, 49.0198],
            [17.9535, 49.0217],
            [17.9345, 49.0209],
            [17.9246, 49.0163],
            [17.9123, 49.0077],
            [17.9112, 48.9915],
            [17.9013, 48.9784],
            [17.8986, 48.9701],
            [17.8997, 48.9604],
            [17.8961, 48.9506],
            [17.8869, 48.937],
            [17.8841, 48.9262],
            [17.8689, 48.9203],
            [17.8517, 48.9244],
            [17.8199, 48.9241],
            [17.8082, 48.9224],
            [17.7843, 48.9223],
            [17.7798, 48.9122],
            [17.7692, 48.909],
            [17.7574, 48.902],
            [17.737, 48.8798],
            [17.7187, 48.8707],
            [17.7039, 48.8578],
            [17.6856, 48.8591],
            [17.6685, 48.8525],
            [17.6486, 48.8519],
          ],
        ],
      },
      properties: {
        name: 'Zlínský',
        id: 'CZ-72',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Zlín',
      },
      id: 'CZ-72',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.4055, 49.3958],
            [18.3904, 49.3989],
            [18.3726, 49.406],
            [18.3659, 49.405],
            [18.3719, 49.4242],
            [18.3672, 49.4317],
            [18.3589, 49.4327],
            [18.3591, 49.4377],
            [18.3511, 49.4466],
            [18.3425, 49.4457],
            [18.3241, 49.4506],
            [18.3161, 49.4606],
            [18.3079, 49.4641],
            [18.3035, 49.4783],
            [18.302, 49.4893],
            [18.2894, 49.492],
            [18.2693, 49.4922],
            [18.2593, 49.4844],
            [18.2524, 49.4826],
            [18.2255, 49.488],
            [18.2181, 49.4927],
            [18.2017, 49.4894],
            [18.1915, 49.4938],
            [18.1764, 49.5048],
            [18.1647, 49.5166],
            [18.1584, 49.513],
            [18.1359, 49.5088],
            [18.1269, 49.5201],
            [18.1099, 49.5172],
            [18.0841, 49.5173],
            [18.0765, 49.5149],
            [18.0324, 49.5118],
            [18.0133, 49.5039],
            [17.9975, 49.5033],
            [17.9909, 49.5133],
            [17.9928, 49.5221],
            [17.978, 49.5316],
            [17.9731, 49.5374],
            [17.9574, 49.539],
            [17.9318, 49.5396],
            [17.9153, 49.5381],
            [17.9122, 49.5463],
            [17.9047, 49.5504],
            [17.9077, 49.5561],
            [17.8964, 49.564],
            [17.8818, 49.5651],
            [17.8721, 49.5595],
            [17.8623, 49.5448],
            [17.854, 49.551],
            [17.8548, 49.5573],
            [17.8423, 49.5634],
            [17.8635, 49.5785],
            [17.8515, 49.5832],
            [17.8443, 49.5988],
            [17.8401, 49.5988],
            [17.8329, 49.6083],
            [17.8174, 49.616],
            [17.8142, 49.6219],
            [17.7951, 49.6283],
            [17.788, 49.6346],
            [17.7778, 49.6319],
            [17.7727, 49.6423],
            [17.7678, 49.6451],
            [17.769, 49.6531],
            [17.7581, 49.6616],
            [17.7514, 49.6711],
            [17.7387, 49.6789],
            [17.7352, 49.6759],
            [17.7342, 49.6672],
            [17.7103, 49.6694],
            [17.6931, 49.6757],
            [17.6866, 49.6814],
            [17.6875, 49.6886],
            [17.6815, 49.7048],
            [17.6856, 49.7145],
            [17.694, 49.7215],
            [17.6981, 49.7318],
            [17.6865, 49.7288],
            [17.677, 49.7332],
            [17.6782, 49.7378],
            [17.6676, 49.7409],
            [17.659, 49.74],
            [17.6561, 49.7451],
            [17.6319, 49.751],
            [17.6109, 49.7409],
            [17.6076, 49.7348],
            [17.5952, 49.7334],
            [17.5864, 49.7347],
            [17.5662, 49.7522],
            [17.5346, 49.7607],
            [17.5312, 49.7526],
            [17.5196, 49.74],
            [17.4943, 49.7439],
            [17.4845, 49.7574],
            [17.4672, 49.7573],
            [17.448, 49.7617],
            [17.4307, 49.7554],
            [17.4128, 49.7601],
            [17.4195, 49.7629],
            [17.4094, 49.7698],
            [17.4112, 49.7748],
            [17.4077, 49.7809],
            [17.3915, 49.7882],
            [17.3718, 49.8041],
            [17.3573, 49.812],
            [17.3474, 49.8069],
            [17.3332, 49.8058],
            [17.3227, 49.798],
            [17.3217, 49.8059],
            [17.3112, 49.8061],
            [17.3055, 49.809],
            [17.2964, 49.8075],
            [17.2735, 49.8181],
            [17.2511, 49.8234],
            [17.2324, 49.8254],
            [17.2256, 49.8209],
            [17.2105, 49.8227],
            [17.2086, 49.8275],
            [17.2139, 49.8373],
            [17.2198, 49.842],
            [17.2165, 49.8522],
            [17.1935, 49.8502],
            [17.1856, 49.8446],
            [17.178, 49.8493],
            [17.1706, 49.8643],
            [17.1593, 49.8681],
            [17.1543, 49.8761],
            [17.1461, 49.8804],
            [17.1481, 49.8873],
            [17.1563, 49.89],
            [17.1698, 49.9012],
            [17.1705, 49.9105],
            [17.1612, 49.9137],
            [17.1617, 49.9207],
            [17.1757, 49.9275],
            [17.1793, 49.9323],
            [17.1903, 49.9328],
            [17.2032, 49.9497],
            [17.1999, 49.9579],
            [17.1924, 49.9648],
            [17.1849, 49.9671],
            [17.1699, 49.9753],
            [17.1607, 49.9781],
            [17.1548, 49.9828],
            [17.1707, 49.9878],
            [17.1739, 49.9939],
            [17.169, 50.003],
            [17.1711, 50.0135],
            [17.1795, 50.0185],
            [17.1909, 50.0321],
            [17.2104, 50.0432],
            [17.2203, 50.0473],
            [17.2303, 50.0556],
            [17.2354, 50.0672],
            [17.23, 50.082],
            [17.2313, 50.0886],
            [17.2225, 50.1018],
            [17.2275, 50.1065],
            [17.2462, 50.1144],
            [17.2512, 50.1221],
            [17.2443, 50.1282],
            [17.2424, 50.1433],
            [17.2601, 50.1475],
            [17.2749, 50.1607],
            [17.2823, 50.1695],
            [17.2882, 50.1729],
            [17.2955, 50.1701],
            [17.304, 50.1727],
            [17.3111, 50.1876],
            [17.3238, 50.1904],
            [17.3409, 50.1845],
            [17.35, 50.1901],
            [17.3591, 50.2038],
            [17.3739, 50.2129],
            [17.3777, 50.2208],
            [17.3872, 50.2178],
            [17.3923, 50.2121],
            [17.3997, 50.2093],
            [17.4171, 50.2108],
            [17.4229, 50.2141],
            [17.4294, 50.2297],
            [17.425, 50.2402],
            [17.4303, 50.2447],
            [17.4318, 50.2543],
            [17.4419, 50.252],
            [17.4559, 50.2569],
            [17.4598, 50.2702],
            [17.4714, 50.2702],
            [17.4954, 50.2751],
            [17.5268, 50.2732],
            [17.5464, 50.2698],
            [17.5639, 50.2715],
            [17.5806, 50.2782],
            [17.592, 50.2789],
            [17.5968, 50.2711],
            [17.6069, 50.2667],
            [17.6152, 50.2664],
            [17.6517, 50.2784],
            [17.6678, 50.2911],
            [17.6758, 50.2924],
            [17.6882, 50.3008],
            [17.688, 50.328],
            [17.7178, 50.3208],
            [17.723, 50.3172],
            [17.7283, 50.303],
            [17.7522, 50.299],
            [17.7424, 50.2906],
            [17.7343, 50.2774],
            [17.7267, 50.2732],
            [17.7248, 50.2571],
            [17.7393, 50.2482],
            [17.7645, 50.2361],
            [17.7568, 50.2224],
            [17.7596, 50.2168],
            [17.7581, 50.2065],
            [17.7428, 50.202],
            [17.732, 50.2026],
            [17.7187, 50.2058],
            [17.7118, 50.199],
            [17.7115, 50.1918],
            [17.7, 50.185],
            [17.6799, 50.1829],
            [17.665, 50.1782],
            [17.6386, 50.1738],
            [17.6255, 50.1692],
            [17.6196, 50.1708],
            [17.6021, 50.1701],
            [17.5925, 50.1597],
            [17.6033, 50.1512],
            [17.6104, 50.1405],
            [17.6379, 50.1317],
            [17.6434, 50.1276],
            [17.6464, 50.1149],
            [17.6498, 50.1111],
            [17.66, 50.1094],
            [17.6764, 50.1036],
            [17.6829, 50.1166],
            [17.697, 50.1166],
            [17.7049, 50.1141],
            [17.73, 50.0976],
            [17.7303, 50.0881],
            [17.736, 50.082],
            [17.7487, 50.0782],
            [17.7556, 50.0685],
            [17.7574, 50.06],
            [17.7644, 50.0567],
            [17.7717, 50.0417],
            [17.7684, 50.0373],
            [17.7751, 50.0289],
            [17.771, 50.0253],
            [17.7772, 50.0194],
            [17.7926, 50.0172],
            [17.7969, 50.0125],
            [17.8279, 50.0106],
            [17.8359, 50.004],
            [17.8266, 49.9941],
            [17.8362, 49.9922],
            [17.8515, 49.9827],
            [17.8639, 49.9793],
            [17.8694, 49.9724],
            [17.8845, 49.9787],
            [17.8928, 49.9794],
            [17.906, 49.9763],
            [17.9199, 49.9778],
            [17.9534, 50.0046],
            [17.9611, 50.0049],
            [17.9689, 50.0089],
            [17.9826, 50.0049],
            [17.9891, 50.0134],
            [18.0045, 50.0181],
            [18.0078, 50.0102],
            [18.0351, 50.0104],
            [18.0464, 50.0159],
            [18.0428, 50.0218],
            [18.0485, 50.0277],
            [18.0444, 50.036],
            [18.0247, 50.0386],
            [18.0159, 50.0306],
            [18.0086, 50.0306],
            [18.0043, 50.0382],
            [18.005, 50.0528],
            [18.0105, 50.0535],
            [18.0336, 50.0655],
            [18.0466, 50.0614],
            [18.0498, 50.058],
            [18.061, 50.059],
            [18.0674, 50.0473],
            [18.0892, 50.0434],
            [18.0851, 50.0338],
            [18.102, 50.0219],
            [18.0936, 50.0142],
            [18.1061, 49.9998],
            [18.1208, 49.9943],
            [18.1304, 49.9976],
            [18.136, 49.9938],
            [18.147, 49.9911],
            [18.154, 49.9821],
            [18.1669, 49.9856],
            [18.1718, 49.9897],
            [18.1679, 49.9982],
            [18.174, 50],
            [18.1893, 49.9987],
            [18.1931, 49.9944],
            [18.2066, 49.9978],
            [18.2111, 49.9919],
            [18.2141, 49.9725],
            [18.2217, 49.9675],
            [18.2358, 49.9709],
            [18.2523, 49.9693],
            [18.2773, 49.9643],
            [18.2817, 49.9496],
            [18.2797, 49.9408],
            [18.2881, 49.9287],
            [18.2988, 49.929],
            [18.2993, 49.9234],
            [18.316, 49.9189],
            [18.3269, 49.9196],
            [18.3308, 49.9253],
            [18.3419, 49.9262],
            [18.3433, 49.9344],
            [18.3353, 49.9395],
            [18.3398, 49.9449],
            [18.3568, 49.9434],
            [18.3691, 49.9362],
            [18.3962, 49.9341],
            [18.4239, 49.9381],
            [18.43, 49.9378],
            [18.4329, 49.928],
            [18.4449, 49.9224],
            [18.4619, 49.9221],
            [18.4669, 49.9156],
            [18.4822, 49.9099],
            [18.4891, 49.9038],
            [18.4997, 49.9052],
            [18.5082, 49.9018],
            [18.533, 49.901],
            [18.5469, 49.9083],
            [18.5395, 49.912],
            [18.5451, 49.9255],
            [18.5594, 49.9174],
            [18.5715, 49.9213],
            [18.5755, 49.9138],
            [18.5736, 49.9077],
            [18.5793, 49.9047],
            [18.5712, 49.892],
            [18.5736, 49.8888],
            [18.5657, 49.88],
            [18.5695, 49.8732],
            [18.5795, 49.869],
            [18.6014, 49.8651],
            [18.6027, 49.8563],
            [18.5918, 49.8556],
            [18.5834, 49.8513],
            [18.5892, 49.8462],
            [18.5839, 49.8414],
            [18.5695, 49.8334],
            [18.5705, 49.8276],
            [18.5823, 49.8171],
            [18.5854, 49.805],
            [18.5927, 49.8015],
            [18.5992, 49.7896],
            [18.597, 49.7844],
            [18.6103, 49.7706],
            [18.6168, 49.7527],
            [18.6289, 49.747],
            [18.6268, 49.7311],
            [18.6284, 49.7219],
            [18.6425, 49.7121],
            [18.6669, 49.7094],
            [18.6685, 49.7031],
            [18.6897, 49.7062],
            [18.7047, 49.7044],
            [18.7154, 49.6952],
            [18.7182, 49.683],
            [18.7287, 49.6838],
            [18.7372, 49.6817],
            [18.7419, 49.6759],
            [18.7505, 49.6766],
            [18.7639, 49.6841],
            [18.7741, 49.6824],
            [18.7824, 49.6851],
            [18.7953, 49.6788],
            [18.8055, 49.6782],
            [18.8103, 49.6735],
            [18.8067, 49.6659],
            [18.8096, 49.6576],
            [18.8064, 49.6513],
            [18.8133, 49.6395],
            [18.8132, 49.6356],
            [18.8204, 49.6237],
            [18.8192, 49.6176],
            [18.824, 49.6142],
            [18.8227, 49.6037],
            [18.8295, 49.5947],
            [18.8389, 49.5951],
            [18.831, 49.5828],
            [18.8383, 49.561],
            [18.8494, 49.5583],
            [18.8519, 49.5518],
            [18.859, 49.5499],
            [18.857, 49.5376],
            [18.8432, 49.5335],
            [18.8444, 49.5286],
            [18.835, 49.5203],
            [18.8243, 49.5172],
            [18.8103, 49.5158],
            [18.8009, 49.5095],
            [18.7846, 49.5072],
            [18.7668, 49.4978],
            [18.7567, 49.4897],
            [18.7443, 49.4895],
            [18.7327, 49.4954],
            [18.7234, 49.4964],
            [18.7118, 49.5019],
            [18.6907, 49.5005],
            [18.6845, 49.5067],
            [18.6736, 49.5074],
            [18.6624, 49.5039],
            [18.6462, 49.5025],
            [18.6376, 49.4969],
            [18.6312, 49.4988],
            [18.6234, 49.4962],
            [18.6045, 49.4972],
            [18.6025, 49.5079],
            [18.587, 49.5097],
            [18.5788, 49.5072],
            [18.5665, 49.5077],
            [18.5472, 49.5009],
            [18.5458, 49.4952],
            [18.5374, 49.4925],
            [18.5353, 49.4855],
            [18.5434, 49.4768],
            [18.5464, 49.4667],
            [18.536, 49.4624],
            [18.5316, 49.4576],
            [18.5239, 49.4563],
            [18.5214, 49.4488],
            [18.5063, 49.4435],
            [18.5032, 49.4368],
            [18.494, 49.4363],
            [18.4921, 49.4301],
            [18.4836, 49.4267],
            [18.4774, 49.4185],
            [18.4764, 49.4088],
            [18.4551, 49.4014],
            [18.4484, 49.3936],
            [18.4347, 49.3915],
            [18.4227, 49.3975],
            [18.4121, 49.4006],
            [18.4055, 49.3958],
          ],
        ],
      },
      properties: {
        name: 'Moravskoslezský',
        id: 'CZ-80',
        CNTRY: 'Czechia',
        TYPE: 'Region',
        NAME_ENG: 'Moravia-Silesia',
      },
      id: 'CZ-80',
    },
  ],
}
export default map
