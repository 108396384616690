import ChartLinesWidget from "../../Widgets/ChartLinesWidget";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";


export interface ChartData {
    sessionsChart: number[]
    revenueChart: number[]
    datesChart: Date[]
}

const GA4ForGAdsDailyChart: React.FC = () => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [chartData, setChartData] = useState<ChartData>()

    useEffect(() => {
        const sessionsArray: number[] = []
        const revenueArray: number[] = []
        const datesArray: any[] = []

        setChartData({
            sessionsChart: sessionsArray,
            revenueChart: revenueArray,
            datesChart: datesArray,

        })

        reportData.details.GA4dailyData.forEach((item: any) => {

            sessionsArray.push(item.sessions)
            revenueArray.push(item.purchaseRevenue)
            datesArray.push(item.date)

        })
    }, [reportData]);

    return (
        <>
            <div className='row g-5 g-xl-8'>
                <div className='row g-5 g-xl-8'>
                    <div className='col-xl-12'>
                        {chartData && (
                            <ChartLinesWidget
                                className='card-xl-stretch mb-xl-8'
                                chartId='sem-GoogleAdsGroup'
                                dateArray={chartData.datesChart}
                                firstDataSet={chartData.revenueChart}
                                secondDataSet={chartData.sessionsChart}
                                firstDataSetTitle='Przychody'
                                secondDataSetTitle='Sesje'
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )

}



export default GA4ForGAdsDailyChart
