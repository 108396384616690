/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {useIntl} from 'react-intl'

export interface AgeBracketsData {
  ageBracket: string[]
  transaction: number[]
  sessions: number[]
}
export interface FBAgeBracketsData {
  ageBracket: string[]
  transaction: number[]
  sessions: number[]
}
export interface Ga4GAdsAgeBracketsData{
  ageBracket: string[]
  transaction: number[]
  sessions: number[]
}
type Props = {
  className: string
  chartData: AgeBracketsData | FBAgeBracketsData | Ga4GAdsAgeBracketsData
  name_one: string | null
  name_two: string | null
  var_width: string | number
}

const ChartLineWithColumnWidget: React.FC<Props> = ({className,name_one,name_two,var_width, chartData}) => {
  const intl = useIntl()

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const secondaryColor = getCSSVariableValue('--bs-dark')
  const baseColor = getCSSVariableValue('--bs-sempai-green')

  const [chartOptions] = useState<ApexOptions>({
    chart: {
      fontFamily: 'inherit',
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      markers: {
        width: 30,
        height: 2,
        offsetY: -2
      },
    },
    stroke: {
      show: true,
      width: [0, 3],
      curve: 'smooth',
    },
    xaxis: {
      categories: chartData.ageBracket,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    yaxis: [
      {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
          formatter: (val) => {
            if (val > 1000) return (val / 1000).toFixed(0) + 'k'
            return val + ''
          },
        },
      },
      {
        opposite: true,
        labels: {
          formatter: (val) => {
            if (val > 1000) return (val / 1000).toFixed(0) + 'k'
            return val + ''
          },
        },
      },
    ],
    fill: {
      type: 'solid',
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: undefined,
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 250,
            toolbar: {
              show: false,
            },
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
              },
              labels: {
                formatter: (val: number) => {
                  if (val / 1000 > 0) return (val / 1000).toString() + 'k'
                  return val.toString()
                },
              },
            },
            {
              opposite: true,
              axisBorder: {
                show: true,
              },
              labels: {
                formatter: (val: number) => {
                  if (val / 1000 > 0) return (val / 1000).toString() + 'k'
                  return val.toString()
                },
              },
            },
          ],
        },
      },
    ],
  })

  return (
    <div className={`${className} p-0`}>
      <div className='p-0 d-flex justify-content-between flex-column overflow-hidden'>
        <Chart
          series={[
            {
              name: name_one,
              type: 'column',
              data: chartData.sessions,
            },
            {
              name: name_two,
              type: 'line',
              data: chartData.transaction,
            },
          ]}
          height='260'
          width={var_width}
          options={chartOptions}
        />
      </div>
    </div>
  )
}

export default ChartLineWithColumnWidget
