import React, { useState } from 'react';
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import { IReportDetails } from "../../../models/Reports";

import {BINGReportDetails} from "../../../models/BINGReport";
import CommonPrintView, {ReportType} from "../../Common/CommonPrintView";

interface PDFFbPrint {
    analyticDataMonth?: BINGReportDetails;
    closePopup: () => void;
}

const BingPrintView: React.FC<PDFFbPrint> = ({ closePopup }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails;
    const [bingAdsLoaded, setBingAdsLoaded] = useState(false);

    return (
        <CommonPrintView
            reportType={ReportType.Bing}
            reportData={reportData}
            setLoaded={setBingAdsLoaded}
            closePopup={closePopup}
        >
        </CommonPrintView>
    );
};

export default BingPrintView;