import React, {ChangeEvent} from 'react'
import {useIntl} from 'react-intl'
import Pagination from '../../../common/Pagination/Pagination'
import _ from 'lodash'

interface TableWidgetProps {
  pagination?: boolean
  children: JSX.Element
  title: string
  pages?: number
  currentPage?: number
  setCurrentPage?: (data: React.SetStateAction<number>) => void
  handle?: (event: ChangeEvent) => void
  paginationPosition?: 'start' | 'center' | 'end'
}

const TableWidget: React.FC<TableWidgetProps> = ({
  children,
  pagination,
  title,
  pages = 1,
  currentPage = 1,
  setCurrentPage,
  handle,
  paginationPosition = 'center',
}) => {
  const intl = useIntl()
  const debouncedSave = _.debounce((newValue) => handle && handle(newValue), 500)

  return (
    <>
      {title !== '' && (
        <div className='pb-2 pt-5 px-10'>
          <h3 className='fw-bolder text-dark'>{title}</h3>
        </div>
      )}

      <div className='card-body py-xl-5 py-0 px-0'>
        {handle && (
          <div className='mb-5 d-print-none'>
            <input
              onChange={debouncedSave}
              type='text'
              className='form-control'
              placeholder={intl.formatMessage({id: 'SEARCH'})}
            />
          </div>
        )}
        <div className='table-responsive bg-white px-5'>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-xl-3 gy-2'>
            {children}
          </table>
        </div>
        {pagination && setCurrentPage && (
          <div className={`d-flex flex-wrap pt-5 pb-5 justify-content-${paginationPosition}`}>
            <Pagination {...{pages, currentPage, setCurrentPage}} />
          </div>
        )}
      </div>
    </>
  )
}

export default TableWidget
