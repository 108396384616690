import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const REPORT_API = `${API_URL}/reports`

export async function getReportsList(
  companyId: number,
  page: number,
  perPage: number,
  filter: string,
  search?: string
): Promise<AxiosResponse<unknown>> {
  if (search)
    return await axios.get(
      `${REPORT_API}?company_id=${companyId}&search=${search}&page=${page}&perPage=${perPage}`
    )
  return await axios.get(
    `${REPORT_API}?company_id=${companyId}&page=${page}&perPage=${perPage}&${filter}`
  )
}

export async function getRaportById(
  id: number,
  companyId: number
): Promise<AxiosResponse<unknown>> {
  return await axios.get(`${REPORT_API}/${id}?company_id=${companyId}`)
  // return await axios.get(`${REPORT_API}/${id}}`)
}

export async function getReportsPdf(id: number, companyId: number) {
  return await axios.get(`${REPORT_API}/${id}/pdf?company_id=${companyId}`, {responseType: 'blob'})
}

export async function brandListSEM(
  id: number,
  companyId: number,
  page: number,
  perPage: number,
  filters: string,
  search?: string
) {
  if (search) {
    return await axios.get(
      `${REPORT_API}/${id}/sem/products/brand?company_id=${companyId}&search=${search}&page=${page}&perPage=${perPage}`
    )
  } else
    return await axios.get(
      `${REPORT_API}/${id}/sem/products/brand?company_id=${companyId}&page=${page}&perPage=${perPage}&${filters}`
    )
}

export async function categoryListSEM(
  id: number,
  companyId: number,
  page: number,
  perPage: number,
  filters: string,
  search?: string
) {
  if (search) {
    return await axios.get(
      `${REPORT_API}/${id}/sem/products/category?company_id=${companyId}&search=${search}&page=${page}&perPage=${perPage}`
    )
  } else
    return await axios.get(
      `${REPORT_API}/${id}/sem/products/category?company_id=${companyId}&page=${page}&perPage=${perPage}&${filters}`
    )
}

export async function efficiencyListSEM(
  id: number,
  companyId: number,
  page: number,
  perPage: number,
  filters: string,
  search?: string
) {
  if (search) {
    return await axios.get(
      `${REPORT_API}/${id}/sem/products/efficiency?company_id=${companyId}&search=${search}&page=${page}&perPage=${perPage}`
    )
  } else
    return await axios.get(
      `${REPORT_API}/${id}/sem/products/efficiency?company_id=${companyId}&page=${page}&perPage=${perPage}&${filters}`
    )
}

export async function efficiencyListSEO(id: number, companyId: number, page: number) {
  return await axios.get(
    `${REPORT_API}/${id}/1/products/efficiency/10?company_id=${companyId}&page=${page}`
  )
}

export async function categoryListSEO(id: number, companyId: number, page: number) {
  return await axios.get(
    `${REPORT_API}/${id}/1/products/category/10?company_id=${companyId}&page=${page}`
  )
}

export async function brandListSEO(id: number, companyId: number, page: number) {
  return await axios.get(
    `${REPORT_API}/${id}/1/products/brand/10?company_id=${companyId}&page=${page}`
  )
}

export async function topPhrasesList(id: number, companyId: number, page: number) {
  return await axios.get(
    `${REPORT_API}/${id}/searchConsoleDetail/query/10?company_id=${companyId}&page=${page}`
  )
}

export async function topPagesList(id: number, companyId: number, page: number) {
  return await axios.get(
    `${REPORT_API}/${id}/searchConsoleDetail/pages/10?company_id=${companyId}&page=${page}`
  )
}

export async function topPhrasesCompareList(id: number, companyId: number, page: number) {
  return await axios.get(
    `${REPORT_API}/${id}/searchConsoleCompare/10?company_id=${companyId}&page=${page}`
  )
}
