import {useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import {convertToPlain} from '../../../../common/RichEditor/RichEditor'
import Spinner from '../../../../common/Spinner/Spinner'
import {IReportDetails} from '../../../models/Reports'
import {selectors} from '../../../redux/ReportsRedux'
import './CommentTab.scss'

interface CommentTabProps {}

const CommentTab: React.FC<CommentTabProps> = () => {
  const reportData = useSelector(selectors.getReportData) as IReportDetails
  const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)
  const descRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (descRef.current) {
      descRef.current.innerHTML = reportData?.comment
    }
  }, [reportData])

  return !reportsError ? (
    <div data-testid="report-commment" className={reportsLoading ? 'overlay overlay-block rounded' : ''}>
      {!reportsLoading && reportData ? (
        <div className='comment-tab px-5 py-5' ref={descRef}>
          {convertToPlain(reportData.comment)}
        </div>
      ) : (
        <div style={{height: '350px'}}>
          <Spinner color="bg-white"/>
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default CommentTab
