var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.2303, 43.4596],
            [4.2409, 43.499],
            [4.4612, 43.5895],
            [4.4258, 43.6242],
            [4.4854, 43.6974],
            [4.6126, 43.7257],
            [4.6514, 43.782],
            [4.6415, 43.8667],
            [4.8441, 43.9894],
            [4.7861, 44.066],
            [4.7048, 44.1082],
            [4.7213, 44.1877],
            [4.6493, 44.2708],
            [4.6505, 44.3303],
            [4.7885, 44.3136],
            [4.8189, 44.2278],
            [5.0598, 44.308],
            [5.1522, 44.2323],
            [5.3551, 44.2144],
            [5.383, 44.1553],
            [5.5034, 44.1157],
            [5.6017, 44.1913],
            [5.6825, 44.1635],
            [5.6517, 44.1894],
            [5.6292, 44.3336],
            [5.4931, 44.3378],
            [5.4303, 44.3749],
            [5.4576, 44.4991],
            [5.6136, 44.4714],
            [5.5966, 44.5438],
            [5.6475, 44.6116],
            [5.7891, 44.6531],
            [5.8267, 44.7592],
            [5.9508, 44.7602],
            [6.1337, 44.8641],
            [6.3555, 44.8547],
            [6.3236, 44.9986],
            [6.2041, 45.016],
            [6.2303, 45.1018],
            [6.333, 45.1232],
            [6.3645, 45.0702],
            [6.5777, 45.1239],
            [6.6304, 45.1091],
            [6.6727, 45.0186],
            [6.7435, 45.013],
            [6.7502, 44.9041],
            [6.8628, 44.849],
            [6.9349, 44.8607],
            [7.0752, 44.682],
            [6.9613, 44.6759],
            [6.9312, 44.5729],
            [6.8542, 44.5283],
            [6.9216, 44.3506],
            [7.0054, 44.2356],
            [7.1859, 44.2012],
            [7.3556, 44.1153],
            [7.5603, 44.1562],
            [7.665, 44.1312],
            [7.7131, 44.0839],
            [7.6506, 43.974],
            [7.5653, 43.9417],
            [7.4953, 43.8726],
            [7.5301, 43.7822],
            [7.1567, 43.6527],
            [7.1215, 43.5865],
            [6.9564, 43.4968],
            [6.891, 43.4288],
            [6.7891, 43.4086],
            [6.6715, 43.313],
            [6.6812, 43.1997],
            [6.5614, 43.1892],
            [6.4966, 43.1509],
            [6.3847, 43.1444],
            [6.1246, 43.0791],
            [5.9279, 43.102],
            [5.8612, 43.0464],
            [5.8099, 43.1149],
            [5.6967, 43.1433],
            [5.6875, 43.1795],
            [5.5367, 43.2141],
            [5.5, 43.1965],
            [5.3414, 43.2146],
            [5.3753, 43.2564],
            [5.3088, 43.361],
            [5.2282, 43.3283],
            [5.0497, 43.3245],
            [4.9698, 43.4248],
            [4.92, 43.4316],
            [4.7747, 43.348],
            [4.5821, 43.3598],
            [4.5905, 43.4215],
            [4.5332, 43.4516],
            [4.2303, 43.4596],
          ],
        ],
      },
      properties: {
        name: "Provence-Alpes-Côte d'Azur",
        id: 'FR-PAC',
        CNTRY: 'France',
        TYPE: 'metropolitan region',
      },
      id: 'FR-PAC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-1.0702, 48.5083],
            [-0.8459, 48.4995],
            [-0.7604, 48.4362],
            [-0.652, 48.4442],
            [-0.5104, 48.509],
            [-0.3215, 48.5225],
            [-0.2076, 48.5638],
            [-0.1469, 48.4571],
            [-0.0508, 48.4514],
            [-0.0511, 48.3748],
            [0.301, 48.4809],
            [0.3558, 48.4593],
            [0.3921, 48.3218],
            [0.5354, 48.2487],
            [0.6838, 48.2545],
            [0.7976, 48.1941],
            [0.8508, 48.1331],
            [0.8186, 47.9856],
            [0.8489, 47.9396],
            [0.7609, 47.9003],
            [0.7689, 47.8318],
            [0.5862, 47.7177],
            [0.5966, 47.6718],
            [0.4644, 47.6449],
            [0.3842, 47.5702],
            [0.2568, 47.612],
            [0.1943, 47.5392],
            [0.2217, 47.5002],
            [0.1824, 47.3817],
            [0.0774, 47.2817],
            [0.0546, 47.1642],
            [-0.0076, 47.1602],
            [-0.1299, 47.0541],
            [-0.2661, 47.1058],
            [-0.408, 47.0668],
            [-0.495, 47.0836],
            [-0.5991, 46.997],
            [-0.8523, 46.9698],
            [-0.8306, 46.8847],
            [-0.6977, 46.8098],
            [-0.7191, 46.7558],
            [-0.6149, 46.6221],
            [-0.6482, 46.521],
            [-0.5395, 46.3852],
            [-0.6477, 46.317],
            [-0.7221, 46.3022],
            [-0.8276, 46.3376],
            [-0.9321, 46.31],
            [-0.9751, 46.3514],
            [-1.1164, 46.3164],
            [-1.468, 46.342],
            [-1.5147, 46.4035],
            [-1.6492, 46.4196],
            [-1.8138, 46.4944],
            [-1.8577, 46.61],
            [-1.9488, 46.6975],
            [-2.143, 46.8219],
            [-2.1528, 46.8894],
            [-1.9786, 47.0291],
            [-2.166, 47.1687],
            [-2.1711, 47.2644],
            [-2.299, 47.2329],
            [-2.5445, 47.2902],
            [-2.5591, 47.3742],
            [-2.4579, 47.4479],
            [-2.3175, 47.4609],
            [-2.098, 47.5328],
            [-2.0981, 47.6311],
            [-1.973, 47.6937],
            [-1.6598, 47.7095],
            [-1.6219, 47.7635],
            [-1.3881, 47.828],
            [-1.2468, 47.7764],
            [-1.1553, 47.9647],
            [-1.0168, 48.003],
            [-1.1015, 48.2662],
            [-1.0455, 48.3288],
            [-1.0814, 48.4146],
            [-1.0702, 48.5083],
          ],
        ],
      },
      properties: {
        name: 'Pays de la Loire',
        id: 'FR-PDL',
        CNTRY: 'France',
        TYPE: 'metropolitan region',
      },
      id: 'FR-PDL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.6493, 44.2708],
            [4.7213, 44.1877],
            [4.7048, 44.1082],
            [4.7861, 44.066],
            [4.8441, 43.9894],
            [4.6415, 43.8667],
            [4.6514, 43.782],
            [4.6126, 43.7257],
            [4.4854, 43.6974],
            [4.4258, 43.6242],
            [4.4612, 43.5895],
            [4.2409, 43.499],
            [4.2303, 43.4596],
            [4.1349, 43.4854],
            [4.1069, 43.5522],
            [3.9745, 43.5455],
            [3.7244, 43.3997],
            [3.6437, 43.3827],
            [3.5063, 43.2666],
            [3.3516, 43.2731],
            [3.1806, 43.1718],
            [3.1226, 43.1077],
            [3.0391, 42.9397],
            [3.0614, 42.9175],
            [3.0296, 42.7035],
            [3.0474, 42.5411],
            [3.1417, 42.5158],
            [3.1779, 42.4387],
            [3.0863, 42.4253],
            [3.0329, 42.4747],
            [2.87, 42.467],
            [2.7988, 42.418],
            [2.7335, 42.4239],
            [2.5768, 42.358],
            [2.4822, 42.3398],
            [2.4331, 42.393],
            [2.2569, 42.4376],
            [2.1556, 42.4232],
            [2.0855, 42.3619],
            [1.9637, 42.3819],
            [1.9343, 42.4526],
            [1.7287, 42.4948],
            [1.7351, 42.6168],
            [1.4933, 42.6531],
            [1.437, 42.6028],
            [1.3563, 42.7191],
            [1.1694, 42.7085],
            [1.0792, 42.7882],
            [0.9261, 42.789],
            [0.856, 42.8263],
            [0.708, 42.8616],
            [0.5964, 42.7058],
            [0.4215, 42.6909],
            [0.3591, 42.7241],
            [0.1752, 42.7369],
            [-0.003, 42.6848],
            [-0.1131, 42.7258],
            [-0.3136, 42.8493],
            [-0.2881, 43.0052],
            [-0.1989, 43.0667],
            [-0.193, 43.114],
            [-0.0678, 43.1768],
            [0.0297, 43.3463],
            [-0.0711, 43.4352],
            [-0.0401, 43.5125],
            [-0.0963, 43.582],
            [-0.2384, 43.5826],
            [-0.2494, 43.7072],
            [-0.2182, 43.7504],
            [-0.1792, 43.9392],
            [0.0558, 43.9614],
            [0.1919, 44.0152],
            [0.3007, 43.9909],
            [0.5564, 44.0557],
            [0.6642, 44.0249],
            [0.7405, 44.0622],
            [0.7908, 44.1441],
            [0.8957, 44.1384],
            [0.93, 44.2669],
            [0.871, 44.3101],
            [0.898, 44.381],
            [1.0632, 44.3656],
            [1.0197, 44.4446],
            [1.0174, 44.536],
            [1.2254, 44.6825],
            [1.4417, 44.878],
            [1.4103, 45.0045],
            [1.5358, 45.0466],
            [1.6317, 45.0323],
            [1.8024, 44.9223],
            [1.9083, 44.9772],
            [2.0612, 44.9755],
            [2.0909, 44.8733],
            [2.1714, 44.7886],
            [2.1294, 44.6966],
            [2.1715, 44.6369],
            [2.3266, 44.6704],
            [2.3522, 44.6405],
            [2.4838, 44.6507],
            [2.5601, 44.72],
            [2.6046, 44.8457],
            [2.737, 44.9413],
            [2.8595, 44.8744],
            [2.8882, 44.7893],
            [2.9371, 44.7741],
            [2.9367, 44.68],
            [2.9816, 44.6445],
            [3.046, 44.8002],
            [3.1313, 44.9037],
            [3.2232, 44.9094],
            [3.3714, 44.96],
            [3.4862, 44.8065],
            [3.6539, 44.8759],
            [3.7434, 44.838],
            [3.861, 44.7112],
            [3.9032, 44.5943],
            [3.9528, 44.5708],
            [3.9884, 44.4723],
            [4.13, 44.3368],
            [4.2573, 44.2652],
            [4.2879, 44.3153],
            [4.3921, 44.3467],
            [4.5053, 44.344],
            [4.6493, 44.2708],
          ],
        ],
      },
      properties: {name: 'Occitanie', id: 'FR-OCC', CNTRY: 'France', TYPE: 'metropolitan region'},
      id: 'FR-OCC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-1.2617, 45.9901],
              [-1.1874, 45.8843],
              [-1.1951, 45.8283],
              [-1.3867, 45.9532],
              [-1.2617, 45.9901],
            ],
          ],
          [
            [
              [-1.5339, 46.2017],
              [-1.4981, 46.2569],
              [-1.426, 46.2011],
              [-1.2927, 46.1873],
              [-1.3227, 46.1452],
              [-1.4621, 46.2018],
              [-1.5339, 46.2017],
            ],
          ],
          [
            [
              [2.2802, 46.4197],
              [2.3021, 46.3432],
              [2.4823, 46.2802],
              [2.5659, 46.1555],
              [2.5502, 46.0862],
              [2.6018, 46.0351],
              [2.5567, 45.9138],
              [2.3885, 45.8255],
              [2.5237, 45.7103],
              [2.5218, 45.6423],
              [2.4613, 45.5962],
              [2.5177, 45.5543],
              [2.4803, 45.37],
              [2.3866, 45.4117],
              [2.3596, 45.3366],
              [2.1937, 45.2213],
              [2.1427, 45.001],
              [2.0612, 44.9755],
              [1.9083, 44.9772],
              [1.8024, 44.9223],
              [1.6317, 45.0323],
              [1.5358, 45.0466],
              [1.4103, 45.0045],
              [1.4417, 44.878],
              [1.2254, 44.6825],
              [1.0174, 44.536],
              [1.0197, 44.4446],
              [1.0632, 44.3656],
              [0.898, 44.381],
              [0.871, 44.3101],
              [0.93, 44.2669],
              [0.8957, 44.1384],
              [0.7908, 44.1441],
              [0.7405, 44.0622],
              [0.6642, 44.0249],
              [0.5564, 44.0557],
              [0.3007, 43.9909],
              [0.1919, 44.0152],
              [0.0558, 43.9614],
              [-0.1792, 43.9392],
              [-0.2182, 43.7504],
              [-0.2494, 43.7072],
              [-0.2384, 43.5826],
              [-0.0963, 43.582],
              [-0.0401, 43.5125],
              [-0.0711, 43.4352],
              [0.0297, 43.3463],
              [-0.0678, 43.1768],
              [-0.193, 43.114],
              [-0.1989, 43.0667],
              [-0.2881, 43.0052],
              [-0.3136, 42.8493],
              [-0.3951, 42.7988],
              [-0.5067, 42.8272],
              [-0.5525, 42.7778],
              [-0.6789, 42.8848],
              [-0.8091, 42.9512],
              [-0.944, 42.9534],
              [-1.1149, 43.0242],
              [-1.3445, 43.0947],
              [-1.3542, 43.0286],
              [-1.4734, 43.0884],
              [-1.3822, 43.1892],
              [-1.3887, 43.2579],
              [-1.7375, 43.3304],
              [-1.7887, 43.3741],
              [-1.6002, 43.4338],
              [-1.4465, 43.6483],
              [-1.2969, 44.211],
              [-1.257, 44.5434],
              [-1.194, 44.6581],
              [-1.0364, 44.6469],
              [-1.034, 44.6958],
              [-1.1674, 44.7751],
              [-1.262, 44.6322],
              [-1.1626, 45.2981],
              [-1.1574, 45.4755],
              [-1.0672, 45.5117],
              [-0.9326, 45.4402],
              [-0.8048, 45.3462],
              [-0.6667, 45.1248],
              [-0.7158, 45.3278],
              [-0.7832, 45.4606],
              [-1.0238, 45.6225],
              [-1.2058, 45.6964],
              [-1.2377, 45.7857],
              [-1.1341, 45.8026],
              [-1.0529, 46.0024],
              [-1.1295, 46.1267],
              [-1.2077, 46.145],
              [-1.2016, 46.2114],
              [-1.1115, 46.2597],
              [-1.1164, 46.3164],
              [-0.9751, 46.3514],
              [-0.9321, 46.31],
              [-0.8276, 46.3376],
              [-0.7221, 46.3022],
              [-0.6477, 46.317],
              [-0.5395, 46.3852],
              [-0.6482, 46.521],
              [-0.6149, 46.6221],
              [-0.7191, 46.7558],
              [-0.6977, 46.8098],
              [-0.8306, 46.8847],
              [-0.8523, 46.9698],
              [-0.5991, 46.997],
              [-0.495, 47.0836],
              [-0.408, 47.0668],
              [-0.2661, 47.1058],
              [-0.1299, 47.0541],
              [-0.0076, 47.1602],
              [0.0546, 47.1642],
              [0.0808, 47.1194],
              [0.3037, 47.0513],
              [0.2919, 46.9904],
              [0.4389, 46.9293],
              [0.506, 46.9587],
              [0.6919, 46.9736],
              [0.7047, 46.9031],
              [0.7964, 46.8495],
              [0.8153, 46.789],
              [0.9129, 46.7267],
              [0.8923, 46.6292],
              [1.0196, 46.5361],
              [1.1441, 46.5072],
              [1.2118, 46.4337],
              [1.2184, 46.3658],
              [1.3539, 46.3998],
              [1.4152, 46.3461],
              [1.5259, 46.4267],
              [1.6855, 46.4185],
              [1.7829, 46.4565],
              [1.8389, 46.4257],
              [1.9787, 46.443],
              [2.0883, 46.41],
              [2.2802, 46.4197],
            ],
          ],
        ],
      },
      properties: {
        name: 'Nouvelle-Aquitaine',
        id: 'FR-NAQ',
        CNTRY: 'France',
        TYPE: 'metropolitan region',
      },
      id: 'FR-NAQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [1.7037, 49.2311],
            [1.6224, 49.0853],
            [1.4888, 49.0504],
            [1.5015, 48.9401],
            [1.4605, 48.8718],
            [1.3623, 48.8344],
            [1.3327, 48.7611],
            [1.2229, 48.7579],
            [1.1218, 48.7893],
            [1.0351, 48.728],
            [0.8096, 48.6635],
            [0.8515, 48.5825],
            [0.9261, 48.5603],
            [0.9401, 48.4562],
            [0.9141, 48.3726],
            [0.7875, 48.341],
            [0.7553, 48.2994],
            [0.7976, 48.1941],
            [0.6838, 48.2545],
            [0.5354, 48.2487],
            [0.3921, 48.3218],
            [0.3558, 48.4593],
            [0.301, 48.4809],
            [-0.0511, 48.3748],
            [-0.0508, 48.4514],
            [-0.1469, 48.4571],
            [-0.2076, 48.5638],
            [-0.3215, 48.5225],
            [-0.5104, 48.509],
            [-0.652, 48.4442],
            [-0.7604, 48.4362],
            [-0.8459, 48.4995],
            [-1.0702, 48.5083],
            [-1.2541, 48.544],
            [-1.3542, 48.469],
            [-1.4888, 48.4892],
            [-1.5722, 48.6346],
            [-1.4263, 48.643],
            [-1.5684, 48.7438],
            [-1.5843, 48.8487],
            [-1.5578, 49.0264],
            [-1.5927, 49.1358],
            [-1.7079, 49.3286],
            [-1.8104, 49.3726],
            [-1.876, 49.6592],
            [-1.8246, 49.6907],
            [-1.5347, 49.6548],
            [-1.4743, 49.6972],
            [-1.2662, 49.6964],
            [-1.23, 49.6071],
            [-1.3105, 49.5477],
            [-1.1677, 49.4071],
            [-0.9659, 49.3972],
            [-0.7559, 49.3487],
            [-0.3947, 49.3328],
            [-0.2229, 49.2804],
            [-0.0016, 49.3245],
            [0.1267, 49.401],
            [0.1643, 49.4526],
            [0.065, 49.5126],
            [0.2066, 49.7132],
            [0.3365, 49.7469],
            [0.5863, 49.8566],
            [0.7725, 49.8747],
            [0.9597, 49.9259],
            [1.0187, 49.9149],
            [1.1946, 49.9687],
            [1.3778, 50.0641],
            [1.4525, 50.0394],
            [1.7121, 49.8867],
            [1.786, 49.7624],
            [1.7134, 49.7346],
            [1.6964, 49.5824],
            [1.7906, 49.5036],
            [1.7142, 49.4072],
            [1.7725, 49.2912],
            [1.7037, 49.2311],
          ],
        ],
      },
      properties: {name: 'Normandie', id: 'FR-NOR', CNTRY: 'France', TYPE: 'metropolitan region'},
      id: 'FR-NOR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [1.7037, 49.2311],
            [1.7544, 49.1749],
            [1.883, 49.1622],
            [2.0809, 49.2097],
            [2.2328, 49.1506],
            [2.3135, 49.1858],
            [2.5832, 49.0796],
            [2.6327, 49.1082],
            [2.7337, 49.0608],
            [2.9686, 49.0915],
            [3.1643, 49.0985],
            [3.1668, 49.0121],
            [3.3669, 48.9282],
            [3.3822, 48.8718],
            [3.4845, 48.8522],
            [3.408, 48.7841],
            [3.4674, 48.7384],
            [3.4501, 48.6357],
            [3.4798, 48.5423],
            [3.3907, 48.4253],
            [3.4138, 48.3905],
            [3.2539, 48.3649],
            [3.0469, 48.3597],
            [3.047, 48.25],
            [2.9368, 48.1634],
            [2.7988, 48.1691],
            [2.7053, 48.1238],
            [2.5591, 48.1413],
            [2.5169, 48.2261],
            [2.4236, 48.2599],
            [2.404, 48.3159],
            [2.2983, 48.3125],
            [2.2027, 48.3442],
            [2.1631, 48.2987],
            [1.9722, 48.2882],
            [1.9771, 48.4011],
            [1.8013, 48.4661],
            [1.7869, 48.5531],
            [1.709, 48.5765],
            [1.6021, 48.6645],
            [1.5634, 48.8901],
            [1.5015, 48.9401],
            [1.4888, 49.0504],
            [1.6224, 49.0853],
            [1.7037, 49.2311],
          ],
        ],
      },
      properties: {
        name: 'Île-de-France',
        id: 'FR-IDF',
        CNTRY: 'France',
        TYPE: 'metropolitan region',
      },
      id: 'FR-IDF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.232, 49.9591],
            [4.2533, 49.8686],
            [4.2061, 49.7803],
            [4.2499, 49.7573],
            [4.1263, 49.6783],
            [4.0414, 49.5069],
            [4.0365, 49.3601],
            [3.9236, 49.4066],
            [3.6449, 49.3134],
            [3.748, 49.1587],
            [3.6216, 49.1502],
            [3.65, 49.043],
            [3.6202, 48.9662],
            [3.4845, 48.8522],
            [3.3822, 48.8718],
            [3.3669, 48.9282],
            [3.1668, 49.0121],
            [3.1643, 49.0985],
            [2.9686, 49.0915],
            [2.7337, 49.0608],
            [2.6327, 49.1082],
            [2.5832, 49.0796],
            [2.3135, 49.1858],
            [2.2328, 49.1506],
            [2.0809, 49.2097],
            [1.883, 49.1622],
            [1.7544, 49.1749],
            [1.7037, 49.2311],
            [1.7725, 49.2912],
            [1.7142, 49.4072],
            [1.7906, 49.5036],
            [1.6964, 49.5824],
            [1.7134, 49.7346],
            [1.786, 49.7624],
            [1.7121, 49.8867],
            [1.4525, 50.0394],
            [1.3778, 50.0641],
            [1.5905, 50.2559],
            [1.537, 50.2823],
            [1.5862, 50.5379],
            [1.5621, 50.7256],
            [1.6131, 50.8034],
            [1.5806, 50.8708],
            [1.7444, 50.944],
            [2.0997, 51.0044],
            [2.5443, 51.0888],
            [2.6324, 50.947],
            [2.6001, 50.8492],
            [2.7234, 50.8099],
            [2.7897, 50.7261],
            [2.9066, 50.6915],
            [2.9527, 50.7513],
            [3.1511, 50.7894],
            [3.239, 50.6575],
            [3.2912, 50.524],
            [3.3757, 50.4908],
            [3.5185, 50.5232],
            [3.6604, 50.4563],
            [3.6588, 50.3696],
            [3.8509, 50.3521],
            [3.9028, 50.327],
            [4.0254, 50.359],
            [4.0772, 50.3109],
            [4.2094, 50.2721],
            [4.156, 50.1631],
            [4.2314, 50.0691],
            [4.1629, 50.049],
            [4.147, 49.9766],
            [4.232, 49.9591],
          ],
        ],
      },
      properties: {
        name: 'Hauts-de-France',
        id: 'FR-HDF',
        CNTRY: 'France',
        TYPE: 'metropolitan region',
      },
      id: 'FR-HDF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [7.1325, 47.503],
            [7.086, 47.5929],
            [7.0052, 47.6022],
            [7.0481, 47.6819],
            [7.0152, 47.7428],
            [6.8658, 47.7824],
            [6.786, 47.8503],
            [6.6463, 47.9038],
            [6.6074, 47.945],
            [6.4786, 47.8856],
            [6.3895, 47.9606],
            [6.2079, 47.9473],
            [6.1357, 48.0194],
            [5.9333, 47.9775],
            [5.8481, 47.9047],
            [5.6912, 47.8211],
            [5.691, 47.6851],
            [5.4202, 47.6771],
            [5.3554, 47.5909],
            [5.1182, 47.6463],
            [4.9592, 47.7621],
            [4.9926, 47.8081],
            [4.9034, 47.9214],
            [4.7934, 48.0062],
            [4.5789, 48.0294],
            [4.5549, 47.9682],
            [4.1673, 47.9596],
            [4.1102, 47.926],
            [3.8948, 47.9293],
            [3.9135, 47.9797],
            [3.8223, 48.0441],
            [3.7913, 48.1178],
            [3.6672, 48.1401],
            [3.6187, 48.2689],
            [3.5034, 48.3668],
            [3.4138, 48.3905],
            [3.3907, 48.4253],
            [3.4798, 48.5423],
            [3.4501, 48.6357],
            [3.4674, 48.7384],
            [3.408, 48.7841],
            [3.4845, 48.8522],
            [3.6202, 48.9662],
            [3.65, 49.043],
            [3.6216, 49.1502],
            [3.748, 49.1587],
            [3.6449, 49.3134],
            [3.9236, 49.4066],
            [4.0365, 49.3601],
            [4.0414, 49.5069],
            [4.1263, 49.6783],
            [4.2499, 49.7573],
            [4.2061, 49.7803],
            [4.2533, 49.8686],
            [4.232, 49.9591],
            [4.3099, 49.9696],
            [4.4463, 49.9378],
            [4.6924, 49.9959],
            [4.6933, 50.0851],
            [4.8264, 50.1684],
            [4.8408, 50.0381],
            [4.7892, 49.9688],
            [4.8879, 49.9096],
            [4.8468, 49.8668],
            [4.9058, 49.7858],
            [4.9953, 49.8001],
            [5.0941, 49.7628],
            [5.1663, 49.6933],
            [5.2694, 49.6965],
            [5.4296, 49.596],
            [5.4728, 49.4979],
            [5.6425, 49.5501],
            [5.8688, 49.4987],
            [5.98, 49.4514],
            [6.1001, 49.4533],
            [6.2402, 49.5138],
            [6.3646, 49.4597],
            [6.4268, 49.4767],
            [6.5423, 49.4322],
            [6.5671, 49.347],
            [6.6701, 49.28],
            [6.7392, 49.1643],
            [6.8359, 49.1532],
            [6.9388, 49.2229],
            [7.1252, 49.1417],
            [7.2936, 49.1145],
            [7.3646, 49.1709],
            [7.4938, 49.1684],
            [7.5279, 49.0971],
            [7.6734, 49.0446],
            [7.794, 49.0659],
            [8.1999, 48.9678],
            [8.0895, 48.8026],
            [7.8393, 48.6412],
            [7.8003, 48.5842],
            [7.6668, 48.2217],
            [7.5771, 48.1205],
            [7.5698, 48.0341],
            [7.6226, 47.9718],
            [7.5569, 47.8788],
            [7.5234, 47.6609],
            [7.5917, 47.6042],
            [7.4992, 47.5393],
            [7.5112, 47.498],
            [7.3864, 47.4322],
            [7.2432, 47.4213],
            [7.1325, 47.503],
          ],
        ],
      },
      properties: {name: 'Grand Est', id: 'FR-GES', CNTRY: 'France', TYPE: 'metropolitan region'},
      id: 'FR-GES',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [8.7752, 41.5927],
            [8.9159, 41.6873],
            [8.8121, 41.7138],
            [8.7305, 41.7759],
            [8.803, 41.895],
            [8.6418, 41.9099],
            [8.6601, 42.0122],
            [8.719, 42.0632],
            [8.6119, 42.1345],
            [8.5726, 42.2279],
            [8.6039, 42.3054],
            [8.605, 42.4161],
            [8.6547, 42.4151],
            [8.6662, 42.5155],
            [8.7885, 42.5534],
            [8.8018, 42.6022],
            [9.0149, 42.6409],
            [9.1246, 42.7312],
            [9.2217, 42.7349],
            [9.2985, 42.6763],
            [9.3446, 42.7356],
            [9.3092, 42.8327],
            [9.361, 42.9236],
            [9.3399, 42.9935],
            [9.423, 43.0108],
            [9.4929, 42.8063],
            [9.4459, 42.6746],
            [9.5327, 42.5475],
            [9.5322, 42.3699],
            [9.5598, 42.2818],
            [9.5492, 42.1029],
            [9.4119, 41.9529],
            [9.394, 41.7988],
            [9.4064, 41.7135],
            [9.344, 41.559],
            [9.2743, 41.5304],
            [9.279, 41.4642],
            [9.2215, 41.3663],
            [9.121, 41.4375],
            [8.9989, 41.4897],
            [8.9205, 41.4888],
            [8.7752, 41.5927],
          ],
        ],
      },
      properties: {name: 'Corse', id: 'FR-COR', CNTRY: 'France', TYPE: 'metropolitan region'},
      id: 'FR-COR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [1.5015, 48.9401],
            [1.5634, 48.8901],
            [1.6021, 48.6645],
            [1.709, 48.5765],
            [1.7869, 48.5531],
            [1.8013, 48.4661],
            [1.9771, 48.4011],
            [1.9722, 48.2882],
            [2.1631, 48.2987],
            [2.2027, 48.3442],
            [2.2983, 48.3125],
            [2.404, 48.3159],
            [2.4236, 48.2599],
            [2.5169, 48.2261],
            [2.5591, 48.1413],
            [2.7053, 48.1238],
            [2.7988, 48.1691],
            [2.9368, 48.1634],
            [3.0293, 48.1336],
            [3.0495, 48.0736],
            [3.1246, 48.0302],
            [3.1278, 47.9731],
            [3.05, 47.9112],
            [3.0253, 47.7871],
            [2.8517, 47.7576],
            [2.9261, 47.6819],
            [2.9364, 47.5989],
            [2.8481, 47.5381],
            [2.9306, 47.4382],
            [2.8675, 47.3385],
            [2.9814, 47.2618],
            [3.0304, 47.1214],
            [3.0176, 47.0644],
            [3.0775, 47.0239],
            [3.0311, 46.7949],
            [2.9206, 46.7929],
            [2.8494, 46.7457],
            [2.7013, 46.7187],
            [2.5698, 46.6589],
            [2.6144, 46.5532],
            [2.3605, 46.5165],
            [2.2802, 46.4197],
            [2.0883, 46.41],
            [1.9787, 46.443],
            [1.8389, 46.4257],
            [1.7829, 46.4565],
            [1.6855, 46.4185],
            [1.5259, 46.4267],
            [1.4152, 46.3461],
            [1.3539, 46.3998],
            [1.2184, 46.3658],
            [1.2118, 46.4337],
            [1.1441, 46.5072],
            [1.0196, 46.5361],
            [0.8923, 46.6292],
            [0.9129, 46.7267],
            [0.8153, 46.789],
            [0.7964, 46.8495],
            [0.7047, 46.9031],
            [0.6919, 46.9736],
            [0.506, 46.9587],
            [0.4389, 46.9293],
            [0.2919, 46.9904],
            [0.3037, 47.0513],
            [0.0808, 47.1194],
            [0.0546, 47.1642],
            [0.0774, 47.2817],
            [0.1824, 47.3817],
            [0.2217, 47.5002],
            [0.1943, 47.5392],
            [0.2568, 47.612],
            [0.3842, 47.5702],
            [0.4644, 47.6449],
            [0.5966, 47.6718],
            [0.5862, 47.7177],
            [0.7689, 47.8318],
            [0.7609, 47.9003],
            [0.8489, 47.9396],
            [0.8186, 47.9856],
            [0.8508, 48.1331],
            [0.7976, 48.1941],
            [0.7553, 48.2994],
            [0.7875, 48.341],
            [0.9141, 48.3726],
            [0.9401, 48.4562],
            [0.9261, 48.5603],
            [0.8515, 48.5825],
            [0.8096, 48.6635],
            [1.0351, 48.728],
            [1.1218, 48.7893],
            [1.2229, 48.7579],
            [1.3327, 48.7611],
            [1.3623, 48.8344],
            [1.4605, 48.8718],
            [1.5015, 48.9401],
          ],
        ],
      },
      properties: {
        name: 'Centre-Val de Loire',
        id: 'FR-CVL',
        CNTRY: 'France',
        TYPE: 'metropolitan region',
      },
      id: 'FR-CVL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-3.2479, 47.3874],
              [-3.1399, 47.3291],
              [-3.2187, 47.2925],
              [-3.2479, 47.3874],
            ],
          ],
          [
            [
              [-1.5722, 48.6346],
              [-1.4888, 48.4892],
              [-1.3542, 48.469],
              [-1.2541, 48.544],
              [-1.0702, 48.5083],
              [-1.0814, 48.4146],
              [-1.0455, 48.3288],
              [-1.1015, 48.2662],
              [-1.0168, 48.003],
              [-1.1553, 47.9647],
              [-1.2468, 47.7764],
              [-1.3881, 47.828],
              [-1.6219, 47.7635],
              [-1.6598, 47.7095],
              [-1.973, 47.6937],
              [-2.0981, 47.6311],
              [-2.098, 47.5328],
              [-2.3175, 47.4609],
              [-2.4579, 47.4479],
              [-2.5096, 47.5242],
              [-2.8154, 47.4862],
              [-2.8738, 47.5329],
              [-3.0402, 47.5785],
              [-3.1232, 47.5692],
              [-3.286, 47.6851],
              [-3.4535, 47.6956],
              [-3.5271, 47.7636],
              [-3.8549, 47.7906],
              [-3.902, 47.8344],
              [-4.1465, 47.8566],
              [-4.1914, 47.7962],
              [-4.3676, 47.796],
              [-4.3581, 47.8876],
              [-4.421, 47.9622],
              [-4.5689, 47.9996],
              [-4.7144, 48.0644],
              [-4.5523, 48.0761],
              [-4.4165, 48.1069],
              [-4.3051, 48.0892],
              [-4.2672, 48.1299],
              [-4.3156, 48.204],
              [-4.4635, 48.2391],
              [-4.5175, 48.1925],
              [-4.6281, 48.2798],
              [-4.3978, 48.2748],
              [-4.4748, 48.385],
              [-4.6072, 48.3385],
              [-4.7616, 48.3827],
              [-4.7768, 48.5157],
              [-4.7037, 48.5702],
              [-4.6105, 48.5767],
              [-4.346, 48.6775],
              [-4.0011, 48.712],
              [-3.9719, 48.6744],
              [-3.8556, 48.6743],
              [-3.8239, 48.7196],
              [-3.6444, 48.6961],
              [-3.5872, 48.8066],
              [-3.4805, 48.8381],
              [-3.4409, 48.7987],
              [-3.3203, 48.8388],
              [-3.0854, 48.8683],
              [-3.0436, 48.7801],
              [-2.817, 48.6486],
              [-2.8218, 48.5951],
              [-2.633, 48.537],
              [-2.554, 48.5996],
              [-2.4194, 48.6292],
              [-2.1718, 48.5964],
              [-2.152, 48.6334],
              [-2.0307, 48.6507],
              [-1.9349, 48.702],
              [-1.8684, 48.6382],
              [-1.778, 48.6042],
              [-1.5722, 48.6346],
            ],
          ],
        ],
      },
      properties: {name: 'Bretagne', id: 'FR-BRE', CNTRY: 'France', TYPE: 'metropolitan region'},
      id: 'FR-BRE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [7.1325, 47.503],
            [6.9399, 47.4328],
            [7.0162, 47.3727],
            [6.9552, 47.2423],
            [6.8388, 47.1486],
            [6.7027, 47.0817],
            [6.6212, 46.9926],
            [6.4966, 46.9739],
            [6.4519, 46.7748],
            [6.2691, 46.6826],
            [6.1101, 46.5764],
            [6.1565, 46.545],
            [6.0645, 46.4159],
            [5.8692, 46.2647],
            [5.725, 46.2608],
            [5.6462, 46.3241],
            [5.5386, 46.2685],
            [5.4366, 46.3154],
            [5.2358, 46.4579],
            [5.2021, 46.5086],
            [5.057, 46.484],
            [4.9421, 46.5186],
            [4.78, 46.1762],
            [4.6796, 46.2588],
            [4.5041, 46.2675],
            [4.4057, 46.2957],
            [4.3879, 46.2131],
            [4.2811, 46.157],
            [4.2078, 46.1944],
            [4.103, 46.1987],
            [4.0295, 46.1693],
            [3.8906, 46.2143],
            [3.8914, 46.2851],
            [3.9876, 46.3147],
            [3.9998, 46.4618],
            [3.8693, 46.488],
            [3.7422, 46.5385],
            [3.6977, 46.6589],
            [3.5756, 46.7492],
            [3.4861, 46.6524],
            [3.4202, 46.7141],
            [3.3465, 46.6841],
            [3.2689, 46.7171],
            [3.1995, 46.6765],
            [3.0577, 46.7512],
            [3.0311, 46.7949],
            [3.0775, 47.0239],
            [3.0176, 47.0644],
            [3.0304, 47.1214],
            [2.9814, 47.2618],
            [2.8675, 47.3385],
            [2.9306, 47.4382],
            [2.8481, 47.5381],
            [2.9364, 47.5989],
            [2.9261, 47.6819],
            [2.8517, 47.7576],
            [3.0253, 47.7871],
            [3.05, 47.9112],
            [3.1278, 47.9731],
            [3.1246, 48.0302],
            [3.0495, 48.0736],
            [3.0293, 48.1336],
            [2.9368, 48.1634],
            [3.047, 48.25],
            [3.0469, 48.3597],
            [3.2539, 48.3649],
            [3.4138, 48.3905],
            [3.5034, 48.3668],
            [3.6187, 48.2689],
            [3.6672, 48.1401],
            [3.7913, 48.1178],
            [3.8223, 48.0441],
            [3.9135, 47.9797],
            [3.8948, 47.9293],
            [4.1102, 47.926],
            [4.1673, 47.9596],
            [4.5549, 47.9682],
            [4.5789, 48.0294],
            [4.7934, 48.0062],
            [4.9034, 47.9214],
            [4.9926, 47.8081],
            [4.9592, 47.7621],
            [5.1182, 47.6463],
            [5.3554, 47.5909],
            [5.4202, 47.6771],
            [5.691, 47.6851],
            [5.6912, 47.8211],
            [5.8481, 47.9047],
            [5.9333, 47.9775],
            [6.1357, 48.0194],
            [6.2079, 47.9473],
            [6.3895, 47.9606],
            [6.4786, 47.8856],
            [6.6074, 47.945],
            [6.6463, 47.9038],
            [6.786, 47.8503],
            [6.8658, 47.7824],
            [7.0152, 47.7428],
            [7.0481, 47.6819],
            [7.0052, 47.6022],
            [7.086, 47.5929],
            [7.1325, 47.503],
          ],
        ],
      },
      properties: {
        name: 'Bourgogne-Franche-Comté',
        id: 'FR-BFC',
        CNTRY: 'France',
        TYPE: 'metropolitan region',
      },
      id: 'FR-BFC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.6304, 45.1091],
            [6.5777, 45.1239],
            [6.3645, 45.0702],
            [6.333, 45.1232],
            [6.2303, 45.1018],
            [6.2041, 45.016],
            [6.3236, 44.9986],
            [6.3555, 44.8547],
            [6.1337, 44.8641],
            [5.9508, 44.7602],
            [5.8267, 44.7592],
            [5.7891, 44.6531],
            [5.6475, 44.6116],
            [5.5966, 44.5438],
            [5.6136, 44.4714],
            [5.4576, 44.4991],
            [5.4303, 44.3749],
            [5.4931, 44.3378],
            [5.6292, 44.3336],
            [5.6517, 44.1894],
            [5.6825, 44.1635],
            [5.6017, 44.1913],
            [5.5034, 44.1157],
            [5.383, 44.1553],
            [5.3551, 44.2144],
            [5.1522, 44.2323],
            [5.0598, 44.308],
            [4.8189, 44.2278],
            [4.7885, 44.3136],
            [4.6505, 44.3303],
            [4.6493, 44.2708],
            [4.5053, 44.344],
            [4.3921, 44.3467],
            [4.2879, 44.3153],
            [4.2573, 44.2652],
            [4.13, 44.3368],
            [3.9884, 44.4723],
            [3.9528, 44.5708],
            [3.9032, 44.5943],
            [3.861, 44.7112],
            [3.7434, 44.838],
            [3.6539, 44.8759],
            [3.4862, 44.8065],
            [3.3714, 44.96],
            [3.2232, 44.9094],
            [3.1313, 44.9037],
            [3.046, 44.8002],
            [2.9816, 44.6445],
            [2.9367, 44.68],
            [2.9371, 44.7741],
            [2.8882, 44.7893],
            [2.8595, 44.8744],
            [2.737, 44.9413],
            [2.6046, 44.8457],
            [2.5601, 44.72],
            [2.4838, 44.6507],
            [2.3522, 44.6405],
            [2.3266, 44.6704],
            [2.1715, 44.6369],
            [2.1294, 44.6966],
            [2.1714, 44.7886],
            [2.0909, 44.8733],
            [2.0612, 44.9755],
            [2.1427, 45.001],
            [2.1937, 45.2213],
            [2.3596, 45.3366],
            [2.3866, 45.4117],
            [2.4803, 45.37],
            [2.5177, 45.5543],
            [2.4613, 45.5962],
            [2.5218, 45.6423],
            [2.5237, 45.7103],
            [2.3885, 45.8255],
            [2.5567, 45.9138],
            [2.6018, 46.0351],
            [2.5502, 46.0862],
            [2.5659, 46.1555],
            [2.4823, 46.2802],
            [2.3021, 46.3432],
            [2.2802, 46.4197],
            [2.3605, 46.5165],
            [2.6144, 46.5532],
            [2.5698, 46.6589],
            [2.7013, 46.7187],
            [2.8494, 46.7457],
            [2.9206, 46.7929],
            [3.0311, 46.7949],
            [3.0577, 46.7512],
            [3.1995, 46.6765],
            [3.2689, 46.7171],
            [3.3465, 46.6841],
            [3.4202, 46.7141],
            [3.4861, 46.6524],
            [3.5756, 46.7492],
            [3.6977, 46.6589],
            [3.7422, 46.5385],
            [3.8693, 46.488],
            [3.9998, 46.4618],
            [3.9876, 46.3147],
            [3.8914, 46.2851],
            [3.8906, 46.2143],
            [4.0295, 46.1693],
            [4.103, 46.1987],
            [4.2078, 46.1944],
            [4.2811, 46.157],
            [4.3879, 46.2131],
            [4.4057, 46.2957],
            [4.5041, 46.2675],
            [4.6796, 46.2588],
            [4.78, 46.1762],
            [4.9421, 46.5186],
            [5.057, 46.484],
            [5.2021, 46.5086],
            [5.2358, 46.4579],
            [5.4366, 46.3154],
            [5.5386, 46.2685],
            [5.6462, 46.3241],
            [5.725, 46.2608],
            [5.8692, 46.2647],
            [6.0645, 46.4159],
            [6.1698, 46.3667],
            [6.102, 46.2848],
            [5.98, 46.2163],
            [5.9938, 46.1443],
            [6.1283, 46.1393],
            [6.2953, 46.2247],
            [6.2205, 46.3114],
            [6.2526, 46.3596],
            [6.5193, 46.4565],
            [6.6825, 46.4541],
            [6.8205, 46.4266],
            [6.771, 46.3549],
            [6.8649, 46.2804],
            [6.8042, 46.2038],
            [6.7973, 46.1377],
            [6.8986, 46.1247],
            [7.0249, 45.9781],
            [7.0441, 45.9233],
            [6.9408, 45.8467],
            [6.8201, 45.8366],
            [6.8069, 45.7252],
            [6.9151, 45.6512],
            [6.9997, 45.6393],
            [7, 45.5044],
            [7.0996, 45.469],
            [7.1748, 45.4091],
            [7.11, 45.3269],
            [7.1349, 45.2554],
            [7.0013, 45.2195],
            [6.8483, 45.1263],
            [6.7671, 45.1587],
            [6.6304, 45.1091],
          ],
        ],
      },
      properties: {
        name: 'Auvergne-Rhône-Alpes',
        id: 'FR-ARA',
        CNTRY: 'France',
        TYPE: 'metropolitan region',
      },
      id: 'FR-ARA',
    },
  ],
}
export default map
