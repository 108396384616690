import React from 'react'
import {useSelector} from 'react-redux'
import {selectors} from '../../../redux/ReportsRedux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import GA4ForSeoTitle from "../../GoogleAnalytics4/GA4Seo/GA4ForSeoTitle";
import GA4ForSeoBasicData from "../../GoogleAnalytics4/GA4Seo/GA4ForSeoBasicData";
import GA4ForSeoDemographic from "../../GoogleAnalytics4/GA4Seo/GA4ForSeoDemographic";
import GA4ForSeoDevice from "../../GoogleAnalytics4/GA4Seo/GA4ForSeoDevice";
import GA4ForSeoBrandTable from "../../GoogleAnalytics4/GA4Seo/GA4ForSeoBrandTable";
import GA4ForSeoCategoryTable from "../../GoogleAnalytics4/GA4Seo/GA4ForSeoCategoryTable";
import GA4ForSeoProductsTable from "../../GoogleAnalytics4/GA4Seo/GA4ForSeoProductsTable";

interface SeoDataStatisticTabProps {}

const SeoDataStatisticTabProps: React.FC<SeoDataStatisticTabProps> = () => {
  const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)

  return !reportsError ? (
    <div data-testid="sem-report-basic-data" className={reportsLoading ? 'overlay overlay-block rounded' : ''}>
      {!reportsLoading ? (
          <>
            <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5'>
              <GA4ForSeoTitle />
              <br />
              <GA4ForSeoBasicData/>
            </div>

            <div className='compare-data card-body bg-white px-5 py-5 pb-0 mb-5'>
              <GA4ForSeoDemographic />
            </div>
            <div className='compare-data card-body bg-white px-5 py-5 pb-0 mb-5'>
              <GA4ForSeoCategoryTable />
            </div>
            <div className='compare-data card-body bg-white px-5 py-5 pb-0 mb-5'>
              <GA4ForSeoBrandTable />
            </div>

            <div className='compare-data card-body bg-white px-5 py-5 pb-0 mb-5'>
              <GA4ForSeoProductsTable />
            </div>

            <div className='compare-data compare-data-year card-body bg-white px-5 py-5 pb-0 mb-5'>
              <GA4ForSeoDevice />
            </div>
          </>
      ) : (
        <div style={{height: '350px'}}>
          <Spinner />
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default SeoDataStatisticTabProps
