import React, {useEffect, useState} from 'react';
import { SEOReportDeatils } from '../../models/SEOReport';
import {IReportDetails, SEM_TAB_NAME} from "../../models/Reports";
import CommentTab from "../SeoStatistic/Tabs/CommentTab";
import {useIntl} from "react-intl";
import html2pdf from "html2pdf.js";
import {disableSplashScreen, enableSplashScreen} from "../../../../../_metronic/layout/core";
import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";
import GSCBasicOrganicTraffic from "../SeoStatistic/SeoWidgetsGroup/GSCBasicOrganicTraffic";
import GSCTopLists from "../SeoStatistic/SeoWidgetsGroup/GSCTopLists";
import GSCComapreData from "../SeoStatistic/SeoWidgetsGroup/GSCCompareData";
import GSCTopPhrasesCompareLists from "../SeoStatistic/SeoWidgetsGroup/GSCTopPhrasesCompareLists";
import ASPS from "../SeoStatistic/SeoWidgetsGroup/ASPS";
import LinksWidget from "../SeoStatistic/SeoWidgets/LinksWidget";

interface PDFSeoPrint {
    analyticDataMonth?: SEOReportDeatils;
    closePopup: () => void;
}


const SeoPrintView: React.FC<PDFSeoPrint> = ({ closePopup }) => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [gSCBasicCallback, setGSCBasicCallback] = useState(false);

    function printPDF() {
        var element = document.getElementById('reportPrint');


        var opt = {
            margin:       [10, 10, 0, 10],
            // width: element_width,
            filename:     reportData.domain + '_'+ reportData.date +'_' +reportData.id +'.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2, dpi: 150 },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            jsPDF:        { unit: 'mm', format: [340, 481], orientation: 'portrait' }
        };

        html2pdf().set(opt).from(element)
            .toCanvas()
            .toImg()
            .toPdf().then(function() {
                disableSplashScreen();
                closePopup();
        }).save();


    }

    useEffect(() => {
        enableSplashScreen();
        if (gSCBasicCallback) {
            setTimeout(() => {
                printPDF();
            }, 2000);
        }
    }, [gSCBasicCallback]);

    return (
        <>
            <div id='print_overlay' className='print_overlay'>
            </div>
            <div className="popup-container">
                <div className="popup-body py-5 px-10">
                    {/*<button onClick={closePopup}>Close X</button>*/}
                    {/*<div>*/}
                    {/*    <button onClick={printPDF}>drukuj</button>*/}
                    {/*</div>*/}
                    <div id="reportPrint">
                        <div className='basic-data-group card-body bg-white px-5 py-5 mb-5'>
                            <GSCBasicOrganicTraffic
                                print
                                onLoad={() => setGSCBasicCallback(true)}
                            />
                        </div>

                        <div className='basic-data-group card-body bg-white px-5 py-5 mb-5'>
                            <GSCTopLists print />
                        </div>

                        <div className='basic-data-group card-body bg-white px-5 py-5'>
                            <GSCComapreData print />
                            <div className='page-slice'></div>
                        </div>

                        {/*<div className='basic-data-group card-body bg-white px-5 py-5 mb-5'>*/}
                        {/*    <GSCTopPhrasesCompareLists print />*/}
                        {/*</div>*/}

                        <div className='basic-data-group card-body bg-white px-5 mt-15 py-1'>
                            <ASPS print/>
                        </div>

                        <div className='basic-data-group card-body bg-white px-5 py-1'>
                            <LinksWidget print/>
                        </div>
                        <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5 text-left'>
                            <h3 className='fw-bolder text-dark px-5'>
                                {intl.formatMessage({id: SEM_TAB_NAME.COMMENT})}
                            </h3>
                            <CommentTab />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SeoPrintView;