import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {SEOReportDeatils} from '../../../models/SEOReport'
import {selectors} from '../../../redux/ReportsRedux'
import BasicDataWidget from '../SeoWidgets/BasicDataWidget'
import {numSpaceFormater} from "../../../../../helpers/formater";

interface BasicDataGroupProps {}

const BasicDataGroup: React.FC<BasicDataGroupProps> = () => {
  const intl = useIntl()
  const {analyticDataMonth} = useSelector(selectors.getReportDetails) as SEOReportDeatils

  return (
    <>
      <div className='row'>
        <div className='pb-10'>
          <h3 className='fw-boldest text-sempai-green fs-2'>
            {intl.formatMessage({id: 'ORGANIC_TRAFFIC_BASIC_DATA'})}
          </h3>
          <div className='text-gray-400 fw-bold fs-6'>
            {intl.formatMessage({id: 'ORGANIC_TRAFFIC_ABOUT_REPORT'})}
          </div>
        </div>
      </div>
      {analyticDataMonth && (
        <div className='row g-4'>
          <div className='col-2'>
            <BasicDataWidget
              icon='/media/sempai-images/icons/Group-46.svg'
              title={intl.formatMessage({id: 'TRANSACTION_REVENUE'})}
              value={numSpaceFormater(Number(analyticDataMonth.transactions_revenue).toFixed(1)) + 'zł'}
            />
          </div>
          <div className='col-2'>
            <BasicDataWidget
              icon='/media/sempai-images/icons/Group-44.svg'
              title={intl.formatMessage({id: 'TRANSACTIONS'})}
              value={numSpaceFormater(Number(analyticDataMonth.transactions).toFixed(0))}
            />
          </div>
          <div className='col-2'>
            <BasicDataWidget
              icon='/media/sempai-images/icons/Group-50.svg'
              title={intl.formatMessage({id: 'REJ.RATE'})}
              value={Number(analyticDataMonth.bounce_rate).toFixed(2) + '%'}
            />
          </div>
          <div className='col-2'>
            <BasicDataWidget
              icon='/media/sempai-images/icons/Group-52.svg'
              title={intl.formatMessage({id: 'NEW_USERS'})}
              value={numSpaceFormater(Number(analyticDataMonth.new_users).toFixed(0))}
            />
          </div>
          <div className='col-2'>
            <BasicDataWidget
              icon='/media/sempai-images/icons/Icon-feather-monitor.svg'
              title={intl.formatMessage({id: 'USERS'})}
              value={numSpaceFormater(Number(analyticDataMonth.users).toFixed(0))}
            />
          </div>
          <div className='col-2'>
            <BasicDataWidget
              icon='/media/sempai-images/icons/Group-55.svg'
              title={intl.formatMessage({id: 'SESSIONS'})}
              value={numSpaceFormater(Number(analyticDataMonth.sessions).toFixed(0))}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default BasicDataGroup
