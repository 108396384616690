import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import Spinner from '../../../../common/Spinner/Spinner'
import {selectors} from '../../../redux/ReportsRedux'
import {useEffect, useState} from "react";
import {ASPSDates, AspsDetailsData, AspsKeywordsData, ASPSTitles} from "../../../models/SEOReport";
import {numSpaceFormater} from "../../../../../helpers/formater";

interface CommentGroupProps {
    print?: boolean
}

const ASPS: React.FC<CommentGroupProps> = (props) => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData)
    const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)
    const report = useSelector(selectors.getReportDetails)
    const [aspsDataSet, setAspsDataSet] = useState<AspsDetailsData>()


    useEffect(() => {
        if (report) {
            setAspsDataSet(report.asps);
        }

        if (aspsDataSet) {

        }
    }, [report]);
    if (aspsDataSet && aspsDataSet.dates) {
    }

    return !reportsError && reportData && reportData.details && reportData.details.asps && reportData.details.asps.titles ? (

        <div className='row align-items-stretch'>
            {!reportsLoading && reportData ? (
                <>
                    {reportData && reportData.details && reportData.details.asps && reportData.details.asps.titles && !props.print ? (


                    <div className={'card'}>
                        <div className={props.print ? 'card-header border-0 pt-5 px-2' : 'card-header border-0 pt-5'}>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className={props.print ? 'card-label fw-bolder fs-6 mb-1' : 'card-label fw-bolder fs-3 mb-1'}>
                                  {intl.formatMessage({id: 'ASPS_PHRASES_POSITIONS'})}
                                </span>
                            </h3>
                        </div>
                        <div className={props.print ? 'card-body py-3 px-2' : 'card-body py-3'}>
                            <div className='table-responsive'>
                                <table
                                    className={props.print ? 'table table-row-dashed table-row-gray-200 align-middle gs-0 gy-1 gx-1' : 'table table-row-dashed table-row-gray-200 align-middle gs-0 gy-2 gx-2'}>
                                    <thead>
                                    <tr className='fw-bolder text-muted'>
                                        <th className='min-w-100px'>
                                            {intl.formatMessage({id: 'REPORT_EFFICIENCY_NAME'})}
                                        </th>
                                        {aspsDataSet && aspsDataSet.dates && (

                                            aspsDataSet.dates.day.map((item) => {
                                                return (
                                                    <th className={props.print ? 'text-start fs-6' : 'text-start'}>
                                                        {item}
                                                    </th>

                                                )
                                            })

                                        )}

                                    </tr>
                                    </thead>
                                    <tbody>

                                    {aspsDataSet && aspsDataSet.data && (

                                        aspsDataSet.data.map((item, index) => {

                                            return (
                                                <tr>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='d-flex justify-content-start flex-column'>
                                                                <div
                                                                    className={props.print ? 'text-dark fs-9' : 'text-dark fw-bolder fs-6'}>{item.keyword}</div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    {item.positionsByDate.map((date) => {
                                                        return (

                                                            <td>
                                                                <div className='d-flex align-items-center'>
                                                                    <div
                                                                        className='d-flex justify-content-start flex-column'>
                                                                        <div
                                                                            className={props.print ? 'text-dark fs-9' : 'text-dark fw-bolder fs-6'}>{date.position}</div>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        )

                                                    })}

                                                </tr>
                                            )
                                        })
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div className={'card'}>
                            <div className={'card-header border-0 pt-2 px-2'}>
                                <h3 className='card-title align-items-start flex-column'>
                                <span className={'card-label fw-bolder fs-3 mb-1'}>
                                  {'Raport przedstawiający dzienny monitoring wybranych słów kluczowych znajdą Państwo w panelu klienta'}
                                </span>
                                </h3>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div style={{height: '350px'}}>
                    <Spinner color='bg-white'/>
                </div>
            )}
        </div>


    ) : (
        // <ErrorView/>
        <>

        </>
    )
}

export default ASPS
