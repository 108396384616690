import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {FBReportsDetails} from '../../../models/FBReport'
import { IReportDetails} from '../../../models/Reports'
import {selectors} from '../../../redux/ReportsRedux'
import ChartLinesWidget from '../../Widgets/ChartLinesWidget'

interface FbDailyChartProps {
  reportData: IReportDetails
}

interface FBchartData {

  datesArray: Date[]
  clicksArray: number[]
}

const FbDailyChart: React.FC<FbDailyChartProps> = ({reportData}) => {
  const intl = useIntl()
  const details = useSelector(selectors.getReportDetails) as FBReportsDetails
  const [chartData, setChartData] = useState<FBchartData>()


  useEffect(() => {
    const datesArray: any[] = []
    const clicksArray: number[] = []

    if (details && details.fbDaily) {
      details.fbDaily.map((item) => datesArray.push(item.date));
      details.fbDaily.map((item) => clicksArray.push(Number(item.clicks)));


      setChartData({
        datesArray,
        clicksArray
      });
    }
  }, [details]);

  return (
      <>
        {chartData && chartData.clicksArray.length > 0 && (
          <>
            <div className='row card-body px-5 py-1 mb-5'>
              <div className='pb-5 clicks-group-report_title'>
                <h3 className='fw-bolder text-dark text-center'>
                  Facebook Ads - {intl.formatMessage({id: 'REPORT.CLICKS'})}
                </h3>
              </div>
            </div>
            <div className='row g-5 g-xl-8'>
                <div className='col-xl-12'>
                    {chartData &&(
                          <ChartLinesWidget
                            className='card-xl-stretch mb-xl-8'
                            chartId='fb-FBdailyChart'
                            dateArray={chartData.datesArray}
                            firstDataSet={chartData.clicksArray}
                            secondDataSet={[]}
                            firstDataSetTitle={intl.formatMessage({id: 'CLICKS'})}
                            secondDataSetTitle=''
                          />
                    )}
                </div>
            </div>
          </>
       )}
    </>
  )
}

export default FbDailyChart
