var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.9994, 48.9931],
            [-111.1741, 48.9931],
            [-112.4532, 48.9931],
            [-114.0625, 48.9931],
            [-114.1898, 49.1681],
            [-114.3549, 49.2108],
            [-114.588, 49.4203],
            [-114.5775, 49.5436],
            [-114.7167, 49.5685],
            [-114.6429, 49.7303],
            [-114.685, 49.9342],
            [-114.6514, 49.9853],
            [-114.7892, 50.3592],
            [-114.9928, 50.545],
            [-115.2059, 50.5536],
            [-115.3465, 50.7289],
            [-115.6339, 50.8553],
            [-115.6189, 50.9623],
            [-115.7938, 51.0704],
            [-115.9976, 51.1296],
            [-116.035, 51.2177],
            [-116.2788, 51.3478],
            [-116.31, 51.4478],
            [-116.5673, 51.6507],
            [-116.674, 51.7907],
            [-116.8299, 51.7152],
            [-116.9387, 51.7357],
            [-117.0371, 51.8859],
            [-117.2446, 52.047],
            [-117.3201, 52.156],
            [-117.5873, 52.1417],
            [-117.8218, 52.2722],
            [-117.7317, 52.3832],
            [-118.002, 52.4859],
            [-118.1912, 52.3841],
            [-118.3503, 52.6234],
            [-118.3194, 52.6744],
            [-118.5019, 52.8883],
            [-118.6055, 52.8951],
            [-118.8037, 53.1534],
            [-118.9926, 53.2356],
            [-119.0221, 53.1394],
            [-119.2611, 53.2095],
            [-119.3998, 53.3525],
            [-119.7233, 53.3998],
            [-119.8938, 53.5155],
            [-119.8847, 53.708],
            [-120.001, 53.8438],
            [-120.0008, 54.9983],
            [-120.0007, 55.768],
            [-120.0005, 56.9224],
            [-120.0004, 57.692],
            [-120.0002, 58.4618],
            [-120.0001, 59.2314],
            [-120, 60.001],
            [-118.125, 60.001],
            [-116.875, 60.001],
            [-115.625, 60.001],
            [-114.375, 60.001],
            [-113.125, 60.001],
            [-111.875, 60.001],
            [-110, 60.001],
            [-110, 59.3131],
            [-110, 58.6252],
            [-109.9999, 57.5933],
            [-109.9999, 56.9054],
            [-109.9998, 56.2173],
            [-109.9998, 55.5295],
            [-109.9998, 54.8415],
            [-109.9996, 53.8096],
            [-109.9996, 53.1217],
            [-109.9996, 52.0897],
            [-109.9995, 51.0579],
            [-109.9995, 50.3698],
            [-109.9995, 49.682],
            [-109.9994, 48.9931],
          ],
        ],
      },
      properties: {name: 'Alberta', id: 'CA-AB', CNTRY: 'Canada', TYPE: 'Province'},
      id: 'CA-AB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-124.1537, 49.5312],
              [-124.3623, 49.5882],
              [-124.6309, 49.7357],
              [-124.4215, 49.7278],
              [-124.1537, 49.5312],
            ],
          ],
          [
            [
              [-128.9369, 52.51],
              [-129.151, 52.6053],
              [-129.2505, 52.7221],
              [-129.1862, 52.7912],
              [-128.994, 52.6616],
              [-128.9369, 52.51],
            ],
          ],
          [
            [
              [-125.1842, 50.0972],
              [-125.2595, 50.13],
              [-125.3585, 50.3116],
              [-125.196, 50.3898],
              [-125.074, 50.2207],
              [-125.1842, 50.0972],
            ],
          ],
          [
            [
              [-130.2141, 55.0259],
              [-130.2039, 54.947],
              [-130.3494, 54.8146],
              [-130.5753, 54.7697],
              [-130.2141, 55.0259],
            ],
          ],
          [
            [
              [-131.7537, 53.1955],
              [-131.6523, 53.103],
              [-131.6347, 52.9222],
              [-131.9044, 52.8667],
              [-131.7273, 52.7564],
              [-131.4552, 52.7017],
              [-131.5906, 52.5782],
              [-131.383, 52.4157],
              [-131.3271, 52.3175],
              [-131.1426, 52.291],
              [-131.2215, 52.1536],
              [-131.4219, 52.238],
              [-131.562, 52.4],
              [-131.8097, 52.5417],
              [-132.1651, 52.7833],
              [-132.2581, 52.9339],
              [-132.1437, 52.9992],
              [-132.4687, 53.0718],
              [-132.3454, 53.1361],
              [-132.0359, 53.1791],
              [-131.8931, 53.2314],
              [-131.7537, 53.1955],
            ],
          ],
          [
            [
              [-127.1973, 50.6404],
              [-126.7009, 50.5156],
              [-126.2039, 50.4538],
              [-125.4821, 50.3168],
              [-125.2332, 50.0122],
              [-124.9346, 49.7317],
              [-124.9307, 49.6431],
              [-124.8306, 49.5302],
              [-124.6429, 49.4286],
              [-124.1859, 49.3005],
              [-123.9958, 49.224],
              [-123.8201, 49.0835],
              [-123.7523, 48.9513],
              [-123.6266, 48.824],
              [-123.4971, 48.5821],
              [-123.3899, 48.6702],
              [-123.2838, 48.4552],
              [-123.4459, 48.4272],
              [-123.5946, 48.3335],
              [-123.917, 48.3865],
              [-124.6894, 48.5973],
              [-125.1207, 48.7608],
              [-125.1357, 48.8224],
              [-124.9347, 48.9564],
              [-124.9273, 49.0142],
              [-125.3628, 48.9982],
              [-125.4603, 48.941],
              [-125.8285, 49.0918],
              [-125.7023, 49.1392],
              [-125.8354, 49.2767],
              [-125.9516, 49.248],
              [-126.0203, 49.368],
              [-126.2436, 49.4427],
              [-126.5485, 49.4189],
              [-126.5419, 49.5904],
              [-126.3476, 49.6609],
              [-126.5929, 49.7642],
              [-126.6831, 49.8765],
              [-126.9033, 49.9441],
              [-127.1143, 49.8797],
              [-127.2075, 49.9924],
              [-127.3494, 50.052],
              [-127.4671, 50.1635],
              [-127.8639, 50.1278],
              [-127.8509, 50.3137],
              [-127.9629, 50.346],
              [-127.9059, 50.4452],
              [-127.6414, 50.4791],
              [-127.4894, 50.4274],
              [-127.5262, 50.5966],
              [-127.7515, 50.6073],
              [-127.7312, 50.5358],
              [-128.0583, 50.4985],
              [-128.2674, 50.6093],
              [-128.346, 50.7443],
              [-128.2416, 50.8281],
              [-127.9181, 50.8605],
              [-127.7131, 50.8207],
              [-127.1973, 50.6404],
            ],
          ],
          [
            [
              [-130.2141, 55.0259],
              [-130.0366, 55.2979],
              [-130.1465, 55.6545],
              [-130.0251, 55.8882],
              [-130.1225, 55.691],
              [-130.0179, 55.285],
              [-130.2141, 55.0259],
            ],
          ],
          [
            [
              [-129.8486, 53.1679],
              [-129.9344, 53.1767],
              [-130.1514, 53.3457],
              [-130.3057, 53.4073],
              [-130.5175, 53.5443],
              [-130.452, 53.6312],
              [-130.195, 53.5497],
              [-129.9447, 53.4364],
              [-129.7549, 53.2448],
              [-129.8486, 53.1679],
            ],
          ],
          [
            [
              [-123.8192, 60.001],
              [-121.9096, 60.001],
              [-120, 60.001],
              [-120.0001, 59.2314],
              [-120.0002, 58.4618],
              [-120.0004, 57.692],
              [-120.0005, 56.9224],
              [-120.0007, 55.768],
              [-120.0008, 54.9983],
              [-120.001, 53.8438],
              [-119.8847, 53.708],
              [-119.8938, 53.5155],
              [-119.7233, 53.3998],
              [-119.3998, 53.3525],
              [-119.2611, 53.2095],
              [-119.0221, 53.1394],
              [-118.9926, 53.2356],
              [-118.8037, 53.1534],
              [-118.6055, 52.8951],
              [-118.5019, 52.8883],
              [-118.3194, 52.6744],
              [-118.3503, 52.6234],
              [-118.1912, 52.3841],
              [-118.002, 52.4859],
              [-117.7317, 52.3832],
              [-117.8218, 52.2722],
              [-117.5873, 52.1417],
              [-117.3201, 52.156],
              [-117.2446, 52.047],
              [-117.0371, 51.8859],
              [-116.9387, 51.7357],
              [-116.8299, 51.7152],
              [-116.674, 51.7907],
              [-116.5673, 51.6507],
              [-116.31, 51.4478],
              [-116.2788, 51.3478],
              [-116.035, 51.2177],
              [-115.9976, 51.1296],
              [-115.7938, 51.0704],
              [-115.6189, 50.9623],
              [-115.6339, 50.8553],
              [-115.3465, 50.7289],
              [-115.2059, 50.5536],
              [-114.9928, 50.545],
              [-114.7892, 50.3592],
              [-114.6514, 49.9853],
              [-114.685, 49.9342],
              [-114.6429, 49.7303],
              [-114.7167, 49.5685],
              [-114.5775, 49.5436],
              [-114.588, 49.4203],
              [-114.3549, 49.2108],
              [-114.1898, 49.1681],
              [-114.0625, 48.9931],
              [-115.0115, 48.9931],
              [-116.717, 48.9931],
              [-117.9962, 48.9931],
              [-119.7017, 48.9931],
              [-121.4072, 48.9931],
              [-122.7888, 48.9931],
              [-122.9242, 49.0747],
              [-123.0492, 48.9931],
              [-123.1963, 49.1477],
              [-123.2767, 49.344],
              [-123.223, 49.5904],
              [-123.325, 49.5777],
              [-123.3357, 49.4591],
              [-123.5306, 49.3973],
              [-123.8589, 49.4828],
              [-124.0538, 49.6616],
              [-123.8744, 49.7368],
              [-123.9721, 49.892],
              [-124.1416, 49.7927],
              [-124.2813, 49.7721],
              [-124.4833, 49.8082],
              [-124.7824, 50.02],
              [-124.7843, 50.0729],
              [-125.0567, 50.4186],
              [-125.2099, 50.4763],
              [-125.4763, 50.4972],
              [-125.6975, 50.4645],
              [-125.7724, 50.5082],
              [-125.9651, 50.4874],
              [-126.4045, 50.5299],
              [-126.5173, 50.7244],
              [-126.3971, 50.807],
              [-126.6318, 50.9152],
              [-127.0576, 50.8676],
              [-127.4412, 50.9894],
              [-127.7081, 51.1511],
              [-127.7143, 51.2687],
              [-127.6449, 51.4785],
              [-127.819, 51.6039],
              [-127.8691, 51.7752],
              [-127.8588, 51.9903],
              [-127.7954, 52.191],
              [-127.438, 52.3561],
              [-127.2422, 52.3951],
              [-127.1871, 52.5377],
              [-127.5193, 52.3594],
              [-127.7919, 52.2894],
              [-127.9022, 52.151],
              [-128.1022, 51.7884],
              [-128.1936, 51.9983],
              [-128.3576, 52.1589],
              [-128.0375, 52.3182],
              [-128.0603, 52.4275],
              [-128.2752, 52.4355],
              [-128.1088, 52.858],
              [-128.3651, 52.8257],
              [-128.452, 52.8766],
              [-128.5247, 53.1406],
              [-128.6523, 53.2438],
              [-128.8686, 53.3281],
              [-129.0809, 53.3672],
              [-129.1716, 53.5336],
              [-129.1145, 53.6411],
              [-128.9356, 53.7152],
              [-128.833, 53.5494],
              [-128.5421, 53.4207],
              [-128.4696, 53.4709],
              [-128.6756, 53.5546],
              [-128.7679, 53.7102],
              [-128.7048, 53.9187],
              [-128.9594, 53.8414],
              [-129.2081, 53.6415],
              [-129.2843, 53.3932],
              [-129.4624, 53.3465],
              [-129.5637, 53.2515],
              [-129.8218, 53.4128],
              [-129.9119, 53.5513],
              [-130.0744, 53.5756],
              [-130.3352, 53.7239],
              [-130.2328, 53.8675],
              [-130.086, 53.9758],
              [-130.0433, 54.1336],
              [-130.2903, 54.2703],
              [-130.4303, 54.421],
              [-130.37, 54.62],
              [-130.2189, 54.7302],
              [-129.8902, 55.1646],
              [-129.5607, 55.4625],
              [-129.7013, 55.4386],
              [-129.8377, 55.3192],
              [-129.9852, 55.3588],
              [-130.08, 55.563],
              [-130.0947, 55.6948],
              [-130.0251, 55.8882],
              [-130.0229, 56.0145],
              [-130.4132, 56.1226],
              [-130.4771, 56.2305],
              [-130.6491, 56.2636],
              [-130.7417, 56.3409],
              [-131.0829, 56.4048],
              [-131.5751, 56.5988],
              [-131.8243, 56.5899],
              [-131.8662, 56.7929],
              [-132.1043, 56.8568],
              [-132.0315, 57.0265],
              [-132.338, 57.0795],
              [-132.2321, 57.1985],
              [-132.5505, 57.5],
              [-133.2753, 58.2229],
              [-133.4226, 58.337],
              [-133.4011, 58.4109],
              [-133.8208, 58.705],
              [-134.2185, 58.8499],
              [-134.4408, 59.0853],
              [-134.9438, 59.2883],
              [-135.0713, 59.4414],
              [-135.051, 59.5787],
              [-135.4759, 59.7933],
              [-135.9346, 59.6627],
              [-136.3218, 59.6048],
              [-136.2471, 59.5329],
              [-136.4663, 59.4591],
              [-136.4667, 59.2799],
              [-136.5788, 59.1522],
              [-136.8133, 59.15],
              [-137.2775, 58.9883],
              [-137.4386, 58.9031],
              [-137.5933, 59.2263],
              [-138.6323, 59.7783],
              [-138.7055, 59.9014],
              [-139.0565, 60.0016],
              [-138.0955, 60.001],
              [-136.192, 60.001],
              [-135.2402, 60.001],
              [-133.3367, 60.001],
              [-132.3849, 60.001],
              [-130.4814, 60.001],
              [-129.5297, 60.001],
              [-127.6262, 60.001],
              [-125.7227, 60.001],
              [-123.8192, 60.001],
            ],
          ],
          [
            [
              [-127.9246, 51.4738],
              [-128.0918, 51.5111],
              [-128.1488, 51.6268],
              [-128.0317, 51.7085],
              [-127.9164, 51.5854],
              [-127.9246, 51.4738],
            ],
          ],
          [
            [
              [-130.2363, 53.9585],
              [-130.3843, 53.8439],
              [-130.6957, 53.9512],
              [-130.4946, 54.0741],
              [-130.3973, 54.0857],
              [-130.2363, 53.9585],
            ],
          ],
          [
            [
              [-132.6555, 54.1275],
              [-132.5641, 54.0687],
              [-132.3444, 54.106],
              [-132.1661, 53.9552],
              [-132.2145, 53.8147],
              [-132.5649, 53.6876],
              [-132.5347, 53.6518],
              [-132.187, 53.6849],
              [-132.1106, 53.9003],
              [-132.1344, 54.0343],
              [-131.9408, 54.042],
              [-131.6676, 54.1413],
              [-131.7025, 53.9864],
              [-131.8211, 53.8415],
              [-131.9223, 53.5878],
              [-131.928, 53.3792],
              [-132.0113, 53.2652],
              [-132.3473, 53.1892],
              [-132.5204, 53.1941],
              [-132.7475, 53.3105],
              [-132.6702, 53.4586],
              [-132.845, 53.5077],
              [-133.0522, 53.7781],
              [-133.0976, 53.9203],
              [-133.0639, 54.1441],
              [-132.6555, 54.1275],
            ],
          ],
          [
            [
              [-128.3688, 52.401],
              [-128.4359, 52.5603],
              [-128.4398, 52.6964],
              [-128.2484, 52.7412],
              [-128.3238, 52.459],
              [-128.3688, 52.401],
            ],
          ],
          [
            [
              [-126.6412, 49.6058],
              [-126.7434, 49.6135],
              [-126.9385, 49.7184],
              [-126.9258, 49.8377],
              [-126.7381, 49.8437],
              [-126.6499, 49.7457],
              [-126.6412, 49.6058],
            ],
          ],
          [
            [
              [-128.5524, 52.9398],
              [-128.5099, 52.5186],
              [-128.624, 52.3399],
              [-128.7309, 52.3565],
              [-128.7664, 52.5984],
              [-129.0847, 52.8224],
              [-129.1777, 53.0179],
              [-129.0332, 53.28],
              [-128.7404, 53.1789],
              [-128.6327, 53.1125],
              [-128.5524, 52.9398],
            ],
          ],
        ],
      },
      properties: {name: 'British Columbia', id: 'CA-BC', CNTRY: 'Canada', TYPE: 'Province'},
      id: 'CA-BC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9485, 56.8512],
            [-89.9806, 56.2161],
            [-90.6795, 55.7666],
            [-91.3785, 55.317],
            [-91.9894, 54.8988],
            [-92.6002, 54.4807],
            [-93.6203, 53.748],
            [-94.7704, 53.0538],
            [-95.1539, 52.8224],
            [-95.1566, 51.5281],
            [-95.1594, 50.2338],
            [-95.1553, 49.3697],
            [-95.162, 48.9918],
            [-95.8243, 48.9932],
            [-97.1035, 48.9932],
            [-98.809, 48.9931],
            [-99.6617, 48.9931],
            [-101.3673, 48.9931],
            [-101.444, 49.8471],
            [-101.5236, 50.6999],
            [-101.6032, 51.5529],
            [-101.6828, 52.4058],
            [-101.7623, 53.2587],
            [-101.8419, 54.1117],
            [-101.9215, 54.9646],
            [-102.0011, 55.8175],
            [-102.0012, 56.8634],
            [-102.0014, 57.9092],
            [-102.0015, 58.6937],
            [-102.0016, 60.001],
            [-100.6519, 60.001],
            [-99.3021, 60.001],
            [-97.9523, 60.001],
            [-97.0525, 60.001],
            [-95.7027, 60.001],
            [-94.7719, 60.0017],
            [-94.7883, 59.2679],
            [-94.8703, 59.0879],
            [-94.7134, 58.9034],
            [-94.5397, 58.8484],
            [-94.4192, 58.7455],
            [-94.2871, 58.716],
            [-93.7801, 58.7726],
            [-93.4862, 58.7445],
            [-93.2781, 58.7564],
            [-93.1546, 58.6946],
            [-93.1002, 58.4899],
            [-92.8418, 58.0759],
            [-92.7017, 57.7778],
            [-92.4896, 57.4686],
            [-92.4398, 57.275],
            [-92.6141, 57.0391],
            [-92.3033, 57.0459],
            [-92.2491, 57.009],
            [-91.1113, 57.2412],
            [-90.8975, 57.2569],
            [-90.5921, 57.2245],
            [-90.0751, 57.0519],
            [-89.7908, 56.9813],
            [-88.9485, 56.8512],
          ],
        ],
      },
      properties: {name: 'Manitoba', id: 'CA-MB', CNTRY: 'Canada', TYPE: 'Province'},
      id: 'CA-MB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-64.031, 46.0127],
            [-64.1595, 45.9648],
            [-64.3147, 45.8357],
            [-64.4822, 45.8064],
            [-64.5364, 45.8666],
            [-64.7785, 45.6384],
            [-64.8979, 45.626],
            [-65.0573, 45.5443],
            [-65.2824, 45.4731],
            [-65.545, 45.3373],
            [-65.8844, 45.2229],
            [-66.1073, 45.2569],
            [-66.4398, 45.0959],
            [-66.5109, 45.1433],
            [-66.8725, 45.0672],
            [-66.9187, 45.1457],
            [-67.0841, 45.1439],
            [-67.2496, 45.2008],
            [-67.367, 45.1738],
            [-67.4725, 45.2759],
            [-67.428, 45.3779],
            [-67.4937, 45.4741],
            [-67.4327, 45.6031],
            [-67.5957, 45.6207],
            [-67.7846, 45.7017],
            [-67.7958, 46.4983],
            [-67.8067, 47.0828],
            [-68.0968, 47.2748],
            [-68.3109, 47.3545],
            [-68.4804, 47.2857],
            [-68.8287, 47.2033],
            [-69.0031, 47.2365],
            [-69.0536, 47.2946],
            [-68.6285, 47.4207],
            [-68.496, 47.4803],
            [-68.3792, 47.589],
            [-68.3779, 47.9293],
            [-68.1135, 47.9293],
            [-68.1135, 47.9986],
            [-67.6129, 47.9986],
            [-67.5791, 47.9396],
            [-67.3649, 47.8548],
            [-67.0576, 47.9185],
            [-66.7044, 48.0225],
            [-66.4288, 48.0669],
            [-66.2102, 47.9886],
            [-65.8494, 47.911],
            [-65.7557, 47.8597],
            [-65.6665, 47.6962],
            [-65.4836, 47.6871],
            [-65.2282, 47.8112],
            [-64.874, 47.7973],
            [-64.7032, 47.7249],
            [-64.8522, 47.5698],
            [-64.9122, 47.3686],
            [-65.0862, 47.2338],
            [-65.3188, 47.1012],
            [-65.192, 47.0496],
            [-65.0424, 47.0889],
            [-64.8315, 47.0608],
            [-64.9058, 46.8879],
            [-64.8168, 46.6987],
            [-64.7259, 46.6714],
            [-64.6479, 46.356],
            [-64.5415, 46.2404],
            [-64.2118, 46.2202],
            [-63.916, 46.1659],
            [-63.8319, 46.1072],
            [-64.031, 46.0127],
          ],
        ],
      },
      properties: {name: 'New Brunswick', id: 'CA-NB', CNTRY: 'Canada', TYPE: 'Province'},
      id: 'CA-NB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-54.0937, 49.7444],
              [-53.9806, 49.662],
              [-54.2384, 49.5916],
              [-54.2888, 49.6609],
              [-54.0937, 49.7444],
            ],
          ],
          [
            [
              [-57.1001, 51.4433],
              [-57.101, 52.0011],
              [-58.3208, 52.0011],
              [-59.9472, 52.0009],
              [-61.1669, 52.0009],
              [-62.3868, 52.0008],
              [-63.6066, 52.0008],
              [-63.7656, 52.2975],
              [-63.8839, 52.3449],
              [-64.0176, 52.5255],
              [-63.8107, 52.6112],
              [-63.4097, 52.6888],
              [-63.5503, 52.7741],
              [-63.5487, 52.9397],
              [-63.6716, 53.1008],
              [-63.9175, 53.0656],
              [-63.9734, 52.8951],
              [-64.1785, 52.8154],
              [-64.1835, 52.5541],
              [-64.1032, 52.3784],
              [-64.1307, 52.1867],
              [-64.2597, 52.0995],
              [-64.3346, 51.8419],
              [-64.2905, 51.7288],
              [-64.5064, 51.6089],
              [-64.598, 51.6004],
              [-64.6975, 51.7204],
              [-64.6268, 51.8253],
              [-64.9321, 52.055],
              [-65.1371, 52.1547],
              [-65.4743, 52.1963],
              [-65.5516, 52.094],
              [-65.7805, 52.1039],
              [-65.8829, 52.0542],
              [-66.0358, 52.1092],
              [-66.097, 52.2096],
              [-66.2796, 52.3007],
              [-66.4634, 52.3413],
              [-66.3943, 52.4178],
              [-66.4261, 52.6609],
              [-66.3003, 52.8968],
              [-66.3899, 52.9919],
              [-66.6314, 52.9102],
              [-66.6884, 52.7269],
              [-66.8148, 52.6865],
              [-67.068, 52.7549],
              [-67.0537, 52.92],
              [-66.9703, 53.073],
              [-66.9379, 53.3289],
              [-67.0761, 53.4175],
              [-67.1649, 53.5431],
              [-67.4571, 53.6012],
              [-67.471, 53.7964],
              [-67.7573, 54.0336],
              [-67.681, 54.1413],
              [-67.5113, 54.2361],
              [-67.6201, 54.5065],
              [-67.5365, 54.5727],
              [-67.3146, 54.5174],
              [-67.1502, 54.6167],
              [-67.4371, 55.0363],
              [-67.3017, 55.0637],
              [-67.1667, 54.9374],
              [-66.8671, 54.781],
              [-66.6508, 54.7986],
              [-66.7474, 55.1292],
              [-66.7334, 55.3189],
              [-66.2398, 55.0084],
              [-65.8837, 54.9072],
              [-65.6758, 54.7448],
              [-65.1218, 54.7191],
              [-64.8152, 54.7338],
              [-64.341, 54.7263],
              [-63.7513, 54.6122],
              [-63.658, 54.6444],
              [-63.4915, 54.9177],
              [-63.5511, 55.0863],
              [-63.5109, 55.1676],
              [-63.1614, 55.3075],
              [-63.1544, 55.5136],
              [-63.4152, 55.596],
              [-63.7006, 55.8609],
              [-63.5783, 56.0256],
              [-64.0042, 56.0974],
              [-63.9128, 56.229],
              [-64.1215, 56.2995],
              [-64.1361, 56.3946],
              [-63.955, 56.4389],
              [-63.955, 56.5269],
              [-64.1118, 56.6909],
              [-63.9033, 56.8894],
              [-63.8763, 57.023],
              [-63.7579, 57.2276],
              [-63.7437, 57.5809],
              [-63.6146, 57.6694],
              [-64.0455, 57.7853],
              [-64.2266, 58.0175],
              [-64.387, 58.0989],
              [-64.3714, 58.1693],
              [-64.0978, 58.3591],
              [-63.848, 58.4597],
              [-63.8824, 58.5552],
              [-64.0387, 58.5448],
              [-64.0709, 58.6416],
              [-63.9605, 58.6842],
              [-63.5532, 58.7316],
              [-63.5918, 58.8299],
              [-63.7272, 58.8617],
              [-64.1553, 58.7706],
              [-64.2941, 58.866],
              [-64.8086, 58.9293],
              [-64.7702, 59.0487],
              [-64.3935, 58.9985],
              [-64.5195, 59.2258],
              [-64.4448, 59.5279],
              [-64.7146, 59.4663],
              [-64.8181, 59.5402],
              [-64.7908, 59.8155],
              [-64.6656, 59.9055],
              [-64.8804, 60.0496],
              [-64.6499, 60.1101],
              [-64.8159, 60.2675],
              [-64.6142, 60.3061],
              [-64.4364, 60.2281],
              [-64.5278, 60.0945],
              [-64.2835, 60.0641],
              [-63.9288, 59.6449],
              [-63.7502, 59.5126],
              [-63.9455, 59.3802],
              [-63.5399, 59.3328],
              [-63.3255, 59.0816],
              [-63.1853, 58.8577],
              [-63.0504, 58.8781],
              [-62.8739, 58.6724],
              [-63.1023, 58.5458],
              [-63.0756, 58.4148],
              [-62.8374, 58.4794],
              [-62.5939, 58.474],
              [-62.6743, 58.3192],
              [-62.9809, 58.0933],
              [-62.4863, 58.154],
              [-62.3057, 57.9723],
              [-62.1174, 57.9641],
              [-61.8991, 57.8613],
              [-61.9949, 57.7695],
              [-61.968, 57.6119],
              [-62.3386, 57.4845],
              [-62.0881, 57.4529],
              [-61.8511, 57.3813],
              [-61.9774, 57.2479],
              [-61.8608, 57.1976],
              [-61.6285, 57.1832],
              [-61.3337, 57.0105],
              [-61.3905, 56.853],
              [-61.3716, 56.6809],
              [-61.5317, 56.6546],
              [-62.0625, 56.6991],
              [-61.9916, 56.5908],
              [-61.8549, 56.5843],
              [-61.9404, 56.4236],
              [-61.5146, 56.3903],
              [-61.4987, 56.3276],
              [-61.7072, 56.2887],
              [-61.713, 56.231],
              [-61.3647, 56.216],
              [-61.3012, 56.0472],
              [-61.0894, 55.8663],
              [-60.8319, 55.9579],
              [-60.5925, 55.8148],
              [-60.341, 55.7846],
              [-60.4083, 55.6496],
              [-60.1924, 55.4809],
              [-60.3609, 55.3663],
              [-60.4501, 55.2],
              [-59.9303, 55.2595],
              [-59.7588, 55.3096],
              [-59.6891, 55.1964],
              [-59.5178, 55.1974],
              [-59.4285, 55.0555],
              [-59.2596, 55.2],
              [-58.9971, 55.1494],
              [-58.7801, 54.8384],
              [-58.3981, 54.7741],
              [-58.1953, 54.8659],
              [-57.9625, 54.8758],
              [-57.9293, 54.7732],
              [-57.7249, 54.6737],
              [-57.483, 54.6403],
              [-57.4045, 54.5704],
              [-57.5632, 54.4405],
              [-57.6993, 54.3866],
              [-58.1514, 54.3504],
              [-58.2198, 54.2864],
              [-58.4351, 54.2282],
              [-58.6332, 54.0496],
              [-58.8408, 54.0445],
              [-59.0389, 53.9637],
              [-59.4965, 53.8341],
              [-59.823, 53.8345],
              [-60.0142, 53.7616],
              [-60.1449, 53.5962],
              [-60.1003, 53.487],
              [-60.2902, 53.3914],
              [-60.1484, 53.3066],
              [-59.8291, 53.5046],
              [-59.6211, 53.5368],
              [-59.3223, 53.6437],
              [-58.9196, 53.8753],
              [-58.652, 53.9778],
              [-58.3268, 54.0518],
              [-58.31, 54.2016],
              [-58.1921, 54.2282],
              [-57.615, 54.1911],
              [-57.416, 54.1628],
              [-57.1489, 53.8476],
              [-57.157, 53.7569],
              [-57.4895, 53.633],
              [-57.3317, 53.4691],
              [-57.0121, 53.6726],
              [-56.8409, 53.7395],
              [-56.465, 53.7651],
              [-56.354, 53.6244],
              [-56.1102, 53.5876],
              [-55.8593, 53.3439],
              [-55.7979, 53.212],
              [-55.8923, 53.0004],
              [-55.8298, 52.8784],
              [-55.8725, 52.7357],
              [-55.8484, 52.6233],
              [-56.167, 52.5747],
              [-55.8402, 52.5076],
              [-55.706, 52.4283],
              [-55.8336, 52.3104],
              [-55.691, 52.2416],
              [-55.6953, 52.1378],
              [-56.0175, 51.9293],
              [-56.5486, 51.681],
              [-56.976, 51.4577],
              [-57.1001, 51.4433],
            ],
          ],
          [
            [
              [-55.4588, 51.5366],
              [-55.5834, 51.3886],
              [-56.0311, 51.3284],
              [-55.9999, 51.1993],
              [-55.8151, 51.1912],
              [-55.8001, 51.0334],
              [-55.8714, 50.9073],
              [-56.0781, 50.781],
              [-56.1356, 50.6509],
              [-56.3824, 50.4169],
              [-56.484, 50.2708],
              [-56.694, 50.0597],
              [-56.7541, 49.8829],
              [-56.8486, 49.7654],
              [-56.7568, 49.6516],
              [-56.501, 49.8696],
              [-56.3765, 49.9337],
              [-56.247, 50.0901],
              [-56.1222, 50.0629],
              [-56.1641, 49.9573],
              [-55.927, 50.0178],
              [-55.7648, 49.9605],
              [-55.527, 49.9368],
              [-55.7176, 49.829],
              [-56.04, 49.7047],
              [-55.8698, 49.6701],
              [-55.892, 49.5803],
              [-55.6781, 49.4346],
              [-55.376, 49.4897],
              [-55.3554, 49.3808],
              [-55.2003, 49.4085],
              [-55.2445, 49.1998],
              [-55.0262, 49.3053],
              [-54.9826, 49.2681],
              [-54.7177, 49.3885],
              [-54.5022, 49.5273],
              [-54.4634, 49.3417],
              [-54.3561, 49.415],
              [-53.9577, 49.4418],
              [-53.6195, 49.3217],
              [-53.5734, 49.1411],
              [-53.7581, 49.0354],
              [-53.8452, 48.9255],
              [-53.8528, 48.8114],
              [-53.966, 48.7067],
              [-53.698, 48.6798],
              [-53.7947, 48.5264],
              [-53.5521, 48.4818],
              [-53.4113, 48.5621],
              [-53.2202, 48.5778],
              [-53.0573, 48.659],
              [-53.0208, 48.5716],
              [-53.1357, 48.4019],
              [-53.3343, 48.3559],
              [-53.5313, 48.2319],
              [-53.5694, 48.0881],
              [-53.695, 47.9213],
              [-53.8617, 47.7993],
              [-53.7651, 47.6501],
              [-53.6038, 47.6623],
              [-53.5038, 47.7439],
              [-53.2828, 47.9979],
              [-52.921, 48.1471],
              [-52.955, 48.0293],
              [-53.1108, 47.8119],
              [-53.1756, 47.653],
              [-53.1224, 47.4551],
              [-52.9451, 47.5527],
              [-52.7824, 47.7694],
              [-52.7114, 47.7452],
              [-52.6537, 47.5494],
              [-52.6836, 47.4263],
              [-52.9124, 47.1032],
              [-52.8892, 46.9742],
              [-53.0698, 46.6812],
              [-53.167, 46.6465],
              [-53.2914, 46.717],
              [-53.5362, 46.6326],
              [-53.6163, 46.6803],
              [-53.5814, 46.9573],
              [-53.6364, 47.1377],
              [-53.86, 46.9394],
              [-54.076, 46.82],
              [-54.1738, 46.8804],
              [-54.0927, 47.0863],
              [-53.8691, 47.387],
              [-53.989, 47.7562],
              [-54.1918, 47.8598],
              [-54.4047, 47.5559],
              [-54.4346, 47.4623],
              [-54.5424, 47.4251],
              [-54.8567, 47.385],
              [-55.0905, 47.174],
              [-55.1397, 47.046],
              [-55.3157, 46.9058],
              [-55.5307, 46.914],
              [-55.7885, 46.8672],
              [-55.9499, 46.9277],
              [-55.8384, 47.0717],
              [-55.4916, 47.1607],
              [-55.361, 47.2586],
              [-55.1908, 47.449],
              [-54.8695, 47.5709],
              [-55.0746, 47.6576],
              [-55.3663, 47.661],
              [-55.4347, 47.5013],
              [-55.5761, 47.4653],
              [-55.8621, 47.5301],
              [-55.8444, 47.7878],
              [-56.1505, 47.7745],
              [-56.2212, 47.6714],
              [-56.4596, 47.617],
              [-56.9525, 47.5744],
              [-57.4734, 47.6311],
              [-57.6598, 47.6254],
              [-57.9255, 47.6749],
              [-58.3332, 47.6769],
              [-58.9412, 47.5804],
              [-59.117, 47.5707],
              [-59.2598, 47.6342],
              [-59.3624, 47.8657],
              [-59.2721, 47.9956],
              [-58.9609, 48.1594],
              [-58.7106, 48.3251],
              [-58.4922, 48.513],
              [-58.7225, 48.5407],
              [-59.1669, 48.5218],
              [-59.1677, 48.5584],
              [-58.9064, 48.6503],
              [-58.7164, 48.5981],
              [-58.4938, 49.0032],
              [-58.4037, 49.0844],
              [-58.099, 49.0774],
              [-58.0491, 49.18],
              [-58.1909, 49.2587],
              [-58.2133, 49.3866],
              [-58.0158, 49.5426],
              [-57.856, 49.4738],
              [-57.9262, 49.7009],
              [-57.7126, 50.0249],
              [-57.4656, 50.4637],
              [-57.3305, 50.6053],
              [-57.298, 50.6987],
              [-57.1316, 50.7874],
              [-56.9764, 51.0279],
              [-56.8055, 51.1445],
              [-56.7502, 51.2749],
              [-56.518, 51.3993],
              [-56.2074, 51.4886],
              [-56.0256, 51.5684],
              [-55.6905, 51.4713],
              [-55.6664, 51.5789],
              [-55.4588, 51.5366],
            ],
          ],
          [
            [
              [-61.7437, 57.5546],
              [-61.7953, 57.4224],
              [-61.9755, 57.4954],
              [-61.7437, 57.5546],
            ],
          ],
        ],
      },
      properties: {
        name: 'Newfoundland and Labrador',
        id: 'CA-NL',
        CNTRY: 'Canada',
        TYPE: 'Province',
      },
      id: 'CA-NL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-61.1052, 45.9447],
              [-60.9715, 45.8381],
              [-61.0921, 45.7483],
              [-60.8061, 45.7381],
              [-60.6991, 45.7734],
              [-60.4724, 45.9465],
              [-60.7049, 45.9329],
              [-60.5732, 46.0614],
              [-60.5049, 46.2038],
              [-60.298, 46.3113],
              [-60.2265, 46.1956],
              [-60.0925, 46.2061],
              [-59.865, 46.1594],
              [-59.934, 46.0195],
              [-59.8422, 45.9416],
              [-60.0158, 45.8805],
              [-60.205, 45.743],
              [-60.3861, 45.6546],
              [-60.6729, 45.5908],
              [-60.8716, 45.6106],
              [-61.2836, 45.5739],
              [-61.4497, 45.7162],
              [-61.4953, 45.9415],
              [-61.4087, 46.1703],
              [-61.2405, 46.3025],
              [-60.8702, 46.7967],
              [-60.6167, 46.9758],
              [-60.4082, 47.0035],
              [-60.4254, 46.9232],
              [-60.3317, 46.7679],
              [-60.4824, 46.4136],
              [-60.4946, 46.2702],
              [-60.5768, 46.1722],
              [-60.9121, 46.0446],
              [-61.1052, 45.9447],
            ],
          ],
          [
            [
              [-64.3147, 45.8357],
              [-64.1595, 45.9648],
              [-64.031, 46.0127],
              [-63.7029, 45.858],
              [-63.5092, 45.8747],
              [-63.2928, 45.7519],
              [-63.108, 45.7824],
              [-62.7007, 45.7406],
              [-62.483, 45.6218],
              [-62.2177, 45.7309],
              [-61.9555, 45.8681],
              [-61.8772, 45.7142],
              [-61.6569, 45.6421],
              [-61.4923, 45.6871],
              [-61.3505, 45.5737],
              [-61.282, 45.441],
              [-61.461, 45.3667],
              [-61.1067, 45.3486],
              [-61.101, 45.2335],
              [-61.2838, 45.2354],
              [-61.5687, 45.1538],
              [-62.0268, 44.9945],
              [-62.265, 44.9365],
              [-62.5141, 44.8436],
              [-63.0318, 44.7147],
              [-63.1557, 44.7113],
              [-63.3064, 44.6426],
              [-63.5444, 44.6551],
              [-63.6097, 44.48],
              [-63.7612, 44.4864],
              [-63.9997, 44.6449],
              [-64.2861, 44.5503],
              [-64.3385, 44.4449],
              [-64.2757, 44.3341],
              [-64.3782, 44.3036],
              [-64.4688, 44.1852],
              [-64.5785, 44.142],
              [-64.8623, 43.8678],
              [-65.0868, 43.7272],
              [-65.235, 43.7267],
              [-65.3296, 43.6682],
              [-65.3443, 43.5496],
              [-65.4817, 43.5181],
              [-65.7381, 43.5608],
              [-65.887, 43.7952],
              [-66.1257, 43.8138],
              [-66.1931, 44.1439],
              [-66.0996, 44.3675],
              [-65.868, 44.5687],
              [-65.6818, 44.6509],
              [-65.6568, 44.7604],
              [-64.9029, 45.1208],
              [-64.4489, 45.2561],
              [-64.3542, 45.1383],
              [-64.235, 45.1143],
              [-64.0932, 45.217],
              [-63.7483, 45.3108],
              [-64.0872, 45.4109],
              [-64.3365, 45.3895],
              [-64.6002, 45.4101],
              [-64.7467, 45.3243],
              [-64.9128, 45.3749],
              [-64.8274, 45.4755],
              [-64.5601, 45.6255],
              [-64.3147, 45.8357],
            ],
          ],
        ],
      },
      properties: {name: 'Nova Scotia', id: 'CA-NS', CNTRY: 'Canada', TYPE: 'Province'},
      id: 'CA-NS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-110.0027, 75.5391],
              [-110.0028, 74.851],
              [-110.3868, 74.8139],
              [-110.9409, 74.6387],
              [-111.7287, 74.5019],
              [-112.5194, 74.4168],
              [-113.0169, 74.4019],
              [-113.5141, 74.43],
              [-114.2682, 74.6043],
              [-114.3769, 74.6709],
              [-114.1325, 74.7661],
              [-113.3243, 74.8752],
              [-112.836, 74.9756],
              [-111.6711, 75.0195],
              [-111.258, 75.1278],
              [-111.6208, 75.1677],
              [-112.2555, 75.1338],
              [-112.5971, 75.2116],
              [-112.9513, 75.1078],
              [-113.7118, 75.0686],
              [-113.8861, 75.2109],
              [-113.7588, 75.3217],
              [-114.0534, 75.4169],
              [-114.1685, 75.2395],
              [-114.4518, 75.0879],
              [-115.0201, 74.9761],
              [-115.2796, 75.1016],
              [-115.4781, 75.1041],
              [-115.7288, 74.9682],
              [-116.1426, 75.0416],
              [-116.4761, 75.1718],
              [-117.0049, 75.1561],
              [-117.5019, 75.2038],
              [-117.6001, 75.2717],
              [-117.3878, 75.4215],
              [-117.1542, 75.473],
              [-116.0772, 75.4929],
              [-115.3354, 75.6181],
              [-116.4257, 75.5854],
              [-117.0252, 75.6015],
              [-116.9727, 75.7458],
              [-116.3897, 75.8082],
              [-116.6543, 75.9293],
              [-116.6098, 76.0738],
              [-116.2099, 76.1944],
              [-114.9394, 76.1661],
              [-115.0246, 76.2115],
              [-115.7969, 76.2525],
              [-115.8256, 76.3298],
              [-115.5807, 76.4375],
              [-114.9985, 76.4975],
              [-114.5348, 76.5017],
              [-114.194, 76.4515],
              [-114.0588, 76.3007],
              [-113.8235, 76.2068],
              [-113.1713, 76.2578],
              [-112.6976, 76.2017],
              [-111.8652, 75.9393],
              [-112.0567, 75.8342],
              [-111.5491, 75.8221],
              [-111.2757, 75.6125],
              [-111.0527, 75.5485],
              [-110.0027, 75.5391],
            ],
          ],
          [
            [
              [-110.0018, 72.9818],
              [-110.001, 72.1729],
              [-110.0005, 71.087],
              [-110, 70.0012],
              [-112.5, 70.0012],
              [-112.65, 69.8344],
              [-112.8749, 69.8333],
              [-112.8811, 70.0014],
              [-114.4832, 70.0015],
              [-117.0554, 70.0022],
              [-117.0753, 69.889],
              [-116.6937, 69.6955],
              [-116.8561, 69.6497],
              [-117.104, 69.8042],
              [-117.1954, 70.054],
              [-117.1354, 70.1001],
              [-116.5538, 70.1751],
              [-115.5291, 70.2571],
              [-114.5923, 70.3124],
              [-112.6379, 70.2252],
              [-112.1897, 70.2756],
              [-111.7837, 70.2729],
              [-111.7259, 70.3521],
              [-112.1142, 70.4469],
              [-113.1455, 70.6164],
              [-113.7573, 70.6908],
              [-114.3314, 70.6752],
              [-114.8407, 70.6214],
              [-115.9909, 70.5863],
              [-116.3273, 70.6238],
              [-116.9926, 70.6036],
              [-117.5871, 70.6296],
              [-118.2641, 70.8883],
              [-118.2691, 71.0348],
              [-117.8141, 71.1584],
              [-117.3139, 71.212],
              [-116.2282, 71.3592],
              [-115.9803, 71.4693],
              [-117.3371, 71.4346],
              [-117.7234, 71.3907],
              [-118.1882, 71.4359],
              [-118.1483, 71.5257],
              [-117.8784, 71.5609],
              [-117.7424, 71.6593],
              [-118.3715, 71.6399],
              [-118.8684, 71.6868],
              [-118.9877, 71.7642],
              [-118.9447, 71.9855],
              [-118.5898, 72.1675],
              [-118.2135, 72.2628],
              [-118.4813, 72.4277],
              [-118.3745, 72.5339],
              [-117.5517, 72.8312],
              [-116.5733, 73.0549],
              [-115.5522, 73.2135],
              [-114.6382, 73.3727],
              [-114.164, 73.2698],
              [-114.0517, 73.0709],
              [-114.1092, 72.861],
              [-114.4979, 72.6259],
              [-114.4582, 72.5804],
              [-113.5001, 72.6944],
              [-113.4498, 72.8633],
              [-113.208, 72.9811],
              [-112.7536, 72.986],
              [-112.0481, 72.888],
              [-111.2697, 72.7137],
              [-111.3555, 72.5721],
              [-111.6109, 72.4356],
              [-111.8952, 72.3561],
              [-111.6751, 72.3001],
              [-111.3112, 72.4549],
              [-111.1399, 72.3653],
              [-110.7815, 72.5339],
              [-110.2052, 72.6613],
              [-110.1971, 72.7588],
              [-110.5536, 72.8614],
              [-110.6608, 73.0082],
              [-110.0018, 72.9818],
            ],
          ],
          [
            [
              [-110.0026, 76.2443],
              [-110.27, 76.417],
              [-110.0034, 76.4797],
              [-110.0026, 76.2443],
            ],
          ],
          [
            [
              [-120.6819, 69.567],
              [-120.6798, 69.0314],
              [-120.6779, 68.0029],
              [-119.4008, 67.6121],
              [-117.8681, 67.1433],
              [-116.8464, 66.8307],
              [-115.8247, 66.5182],
              [-114.5474, 66.1274],
              [-113.7811, 65.893],
              [-112.5039, 65.5023],
              [-110.6695, 65.4971],
              [-109.5164, 64.903],
              [-109.2151, 64.813],
              [-108.9729, 64.7792],
              [-107.2295, 64.6415],
              [-105.0502, 64.4694],
              [-103.7426, 64.3661],
              [-101.9991, 64.2284],
              [-102.0051, 63.3683],
              [-102.0041, 62.4062],
              [-102.0032, 61.4441],
              [-102.0022, 60.4821],
              [-102.0016, 60.001],
              [-104.0013, 60.001],
              [-105.5009, 60.001],
              [-107.5005, 60.001],
              [-108.5003, 60.001],
              [-110, 60.001],
              [-111.875, 60.001],
              [-113.125, 60.001],
              [-114.375, 60.001],
              [-115.625, 60.001],
              [-116.875, 60.001],
              [-118.125, 60.001],
              [-120, 60.001],
              [-121.9096, 60.001],
              [-123.8192, 60.001],
              [-123.9937, 60.0405],
              [-123.9973, 60.1265],
              [-124.2045, 60.3529],
              [-124.2493, 60.4735],
              [-124.4029, 60.4941],
              [-124.5994, 60.6622],
              [-124.505, 60.7885],
              [-124.5826, 60.9528],
              [-124.8065, 60.961],
              [-124.8805, 60.8603],
              [-125.1516, 60.8443],
              [-125.3787, 60.7917],
              [-125.8638, 60.89],
              [-126.0032, 60.8088],
              [-126.5201, 60.8003],
              [-126.6652, 60.7623],
              [-126.8897, 60.7956],
              [-126.9442, 61.0525],
              [-127.0268, 61.1265],
              [-127.0725, 61.3696],
              [-127.2994, 61.5122],
              [-127.6087, 61.5329],
              [-127.9936, 61.7112],
              [-128.0939, 61.8413],
              [-128.2063, 61.8653],
              [-128.4001, 62.0338],
              [-128.5608, 62.1169],
              [-128.7755, 62.0689],
              [-129.269, 62.1664],
              [-129.2871, 62.4411],
              [-129.2111, 62.5134],
              [-129.4903, 62.5987],
              [-129.7186, 62.8316],
              [-129.6298, 63.0683],
              [-129.8275, 63.0918],
              [-130.1017, 63.3188],
              [-129.845, 63.4858],
              [-130.0631, 63.6356],
              [-130.1451, 63.7619],
              [-130.5129, 63.9201],
              [-130.6606, 63.9505],
              [-130.9413, 64.1405],
              [-130.9308, 64.2192],
              [-131.1067, 64.4009],
              [-131.3588, 64.4585],
              [-131.5859, 64.3803],
              [-131.7637, 64.3805],
              [-131.716, 64.515],
              [-132.0522, 64.6842],
              [-132.3207, 64.7627],
              [-132.5537, 64.7802],
              [-132.497, 64.9574],
              [-132.3538, 65.0385],
              [-132.5161, 65.0942],
              [-132.5969, 65.2802],
              [-132.3459, 65.4434],
              [-132.1947, 65.5977],
              [-132.2953, 65.7162],
              [-132.5549, 65.857],
              [-132.3408, 65.9553],
              [-132.6143, 66.0293],
              [-132.8213, 65.928],
              [-132.938, 66.0286],
              [-133.5889, 65.9626],
              [-133.6772, 66.0956],
              [-133.5687, 66.1767],
              [-133.5832, 66.285],
              [-133.7902, 66.3044],
              [-133.6352, 66.5657],
              [-133.764, 66.651],
              [-133.7941, 66.8186],
              [-134.0556, 67.0045],
              [-136.126, 67.0047],
              [-136.218, 67.1812],
              [-136.1052, 67.3041],
              [-136.1693, 67.5143],
              [-136.4454, 67.7465],
              [-136.4437, 68.8952],
              [-136.1224, 68.8822],
              [-135.3622, 68.6964],
              [-135.4069, 68.829],
              [-135.8763, 68.917],
              [-135.9102, 69.1115],
              [-135.6915, 69.3112],
              [-135.255, 69.3238],
              [-135.1409, 69.4677],
              [-134.4938, 69.4678],
              [-134.4515, 69.6655],
              [-134.242, 69.6688],
              [-133.8798, 69.5077],
              [-134.1651, 69.2806],
              [-133.4759, 69.4053],
              [-133.1631, 69.4339],
              [-132.8403, 69.6506],
              [-132.334, 69.7518],
              [-132.1635, 69.705],
              [-131.9341, 69.7535],
              [-131.4409, 69.9179],
              [-131.1364, 69.9069],
              [-130.9262, 70.0516],
              [-130.4984, 70.1431],
              [-129.945, 70.091],
              [-129.7301, 70.1921],
              [-129.5385, 70.1051],
              [-129.6483, 69.9977],
              [-130.9601, 69.632],
              [-131.8628, 69.5493],
              [-132.3308, 69.3079],
              [-132.6867, 69.2599],
              [-133.3789, 68.8867],
              [-133.304, 68.8475],
              [-132.706, 68.8149],
              [-132.7701, 69.0122],
              [-132.7189, 69.0792],
              [-131.9197, 69.2905],
              [-131.7884, 69.432],
              [-131.5629, 69.4614],
              [-131.0134, 69.4287],
              [-130.9143, 69.2848],
              [-130.6607, 69.4812],
              [-130.3536, 69.6558],
              [-129.5721, 69.8267],
              [-129.2649, 69.8554],
              [-128.9715, 69.7124],
              [-128.3867, 69.9601],
              [-128.2786, 70.1081],
              [-127.765, 70.2219],
              [-128.1701, 70.4184],
              [-127.991, 70.5739],
              [-127.7528, 70.5171],
              [-127.1385, 70.2393],
              [-126.6122, 69.7304],
              [-126.2504, 69.5453],
              [-125.9074, 69.4185],
              [-125.3868, 69.3492],
              [-125.1668, 69.4798],
              [-125.3569, 69.6259],
              [-124.7679, 69.99],
              [-124.9524, 70.0417],
              [-124.5551, 70.1512],
              [-124.4445, 70.1106],
              [-124.4719, 69.9185],
              [-124.407, 69.7674],
              [-124.1385, 69.6532],
              [-124.4814, 69.4251],
              [-124.4261, 69.3794],
              [-123.6092, 69.3774],
              [-123.2137, 69.5415],
              [-123.0257, 69.81],
              [-122.07, 69.8162],
              [-121.5311, 69.7758],
              [-120.9624, 69.6604],
              [-120.6819, 69.567],
            ],
          ],
          [
            [
              [-110.0036, 78.0896],
              [-110.0036, 77.929],
              [-110.7511, 77.8572],
              [-110.7194, 77.7814],
              [-110.1528, 77.7629],
              [-110.1985, 77.5245],
              [-110.6828, 77.4459],
              [-111.2262, 77.4285],
              [-111.952, 77.3442],
              [-112.3727, 77.3641],
              [-113.1644, 77.5303],
              [-113.2713, 77.7784],
              [-113.1871, 77.9123],
              [-112.3046, 78.0067],
              [-111.2066, 78.0882],
              [-110.0036, 78.0896],
            ],
          ],
          [
            [
              [-118.3282, 75.5796],
              [-118.8171, 75.5221],
              [-119.3946, 75.6173],
              [-118.6261, 75.9063],
              [-118.1367, 75.9945],
              [-117.7524, 76.1124],
              [-117.4991, 76.0772],
              [-117.6264, 75.966],
              [-118.3282, 75.5796],
            ],
          ],
          [
            [
              [-110.0044, 78.6867],
              [-110.0037, 78.3217],
              [-110.7551, 78.3107],
              [-111.1692, 78.3863],
              [-111.435, 78.2874],
              [-111.7597, 78.2829],
              [-112.1313, 78.3661],
              [-113.1725, 78.2838],
              [-113.1499, 78.4084],
              [-112.6408, 78.4998],
              [-111.7088, 78.5747],
              [-111.0715, 78.7084],
              [-110.4078, 78.7567],
              [-110.0044, 78.6867],
            ],
          ],
          [
            [
              [-119.7363, 74.1126],
              [-119.4711, 74.2011],
              [-119.2059, 74.198],
              [-119.0257, 74.0447],
              [-118.544, 74.2446],
              [-117.9659, 74.2661],
              [-117.1988, 74.1712],
              [-116.7224, 74.0272],
              [-115.9577, 73.748],
              [-115.5107, 73.6188],
              [-115.4469, 73.4388],
              [-115.9923, 73.3232],
              [-116.4825, 73.2532],
              [-117.0654, 73.1073],
              [-117.4644, 73.0377],
              [-117.9832, 72.9022],
              [-118.9616, 72.6841],
              [-119.1316, 72.6088],
              [-119.4078, 72.3604],
              [-119.7674, 72.2439],
              [-120.1799, 72.2126],
              [-120.3663, 71.888],
              [-120.4432, 71.6308],
              [-120.6193, 71.5057],
              [-121.4722, 71.3891],
              [-121.7494, 71.4448],
              [-122.1566, 71.266],
              [-122.9365, 71.0881],
              [-123.2106, 71.1235],
              [-123.3934, 71.2189],
              [-123.5952, 71.4232],
              [-124.0078, 71.6774],
              [-124.76, 71.8352],
              [-125.2966, 71.973],
              [-125.7669, 71.9609],
              [-125.7626, 72.1376],
              [-125.3828, 72.4238],
              [-124.9871, 72.588],
              [-125.0004, 72.8133],
              [-124.5826, 72.9259],
              [-124.5883, 73.0054],
              [-124.8364, 73.0763],
              [-124.4242, 73.4188],
              [-124.1142, 73.5274],
              [-123.7973, 73.7681],
              [-124.2608, 73.9533],
              [-124.6963, 74.3482],
              [-123.4684, 74.4361],
              [-122.6232, 74.4641],
              [-121.7479, 74.5407],
              [-121.3152, 74.53],
              [-120.5545, 74.3529],
              [-119.9436, 74.2537],
              [-119.5626, 74.2328],
              [-119.7363, 74.1126],
            ],
          ],
          [
            [
              [-115.5513, 77.3633],
              [-115.6239, 77.2659],
              [-116.2137, 77.1782],
              [-116.2857, 77.1016],
              [-115.8568, 76.9692],
              [-116.234, 76.8743],
              [-115.9446, 76.7362],
              [-116.2205, 76.6111],
              [-116.9993, 76.5316],
              [-117.1539, 76.298],
              [-117.4924, 76.2727],
              [-117.8414, 76.3449],
              [-118.0202, 76.4465],
              [-117.7804, 76.7843],
              [-118.3006, 76.7366],
              [-118.4682, 76.5474],
              [-118.8207, 76.4858],
              [-118.6434, 76.3347],
              [-118.9939, 76.1448],
              [-119.3679, 76.2218],
              [-119.7252, 76.1],
              [-119.5497, 76.052],
              [-119.9129, 75.8588],
              [-120.4089, 75.8256],
              [-120.7716, 76.1663],
              [-121.0193, 76.0203],
              [-121.428, 75.9811],
              [-121.9082, 76.0348],
              [-122.4005, 75.9442],
              [-122.774, 76.2277],
              [-122.3654, 76.4012],
              [-121.6138, 76.4414],
              [-120.9976, 76.6914],
              [-120.4853, 76.7932],
              [-120.3577, 76.8869],
              [-119.0902, 77.3051],
              [-118.0052, 77.3812],
              [-117.4186, 77.3174],
              [-117.149, 77.3609],
              [-116.8353, 77.5288],
              [-116.2089, 77.516],
              [-115.5513, 77.3633],
            ],
          ],
          [
            [
              [-113.5607, 76.7433],
              [-113.7125, 76.7106],
              [-114.7515, 76.7589],
              [-114.4199, 76.8754],
              [-113.8916, 76.8949],
              [-113.5165, 76.825],
              [-113.5607, 76.7433],
            ],
          ],
          [
            [
              [-113.8325, 77.7547],
              [-114.2872, 77.7215],
              [-114.6084, 77.7693],
              [-115.0294, 77.9675],
              [-114.2798, 78.0043],
              [-113.768, 77.9036],
              [-113.6194, 77.8135],
              [-113.8325, 77.7547],
            ],
          ],
        ],
      },
      properties: {name: 'Northwest Territories', id: 'CA-NT', CNTRY: 'Canada', TYPE: 'Territory'},
      id: 'CA-NT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.5265, 75.7494],
              [-94.7515, 75.7697],
              [-94.9012, 75.9308],
              [-94.5379, 75.9964],
              [-94.2963, 75.788],
              [-94.5265, 75.7494],
            ],
          ],
          [
            [
              [-104.5582, 77.1417],
              [-105.2151, 77.182],
              [-105.5563, 77.3526],
              [-106.0662, 77.7254],
              [-105.5879, 77.736],
              [-105.0072, 77.5067],
              [-104.9553, 77.4187],
              [-104.5422, 77.3378],
              [-104.5582, 77.1417],
            ],
          ],
          [
            [
              [-110.0036, 77.929],
              [-110.0036, 78.0896],
              [-109.6191, 78.0569],
              [-110.0036, 77.929],
            ],
          ],
          [
            [
              [-101.2261, 76.5793],
              [-100.9622, 76.7342],
              [-100.2692, 76.7341],
              [-101.2261, 76.5793],
            ],
          ],
          [
            [
              [-96.0786, 75.5101],
              [-96.4617, 75.4942],
              [-96.7229, 75.3808],
              [-96.9828, 75.5098],
              [-96.5229, 75.5837],
              [-96.3679, 75.6547],
              [-95.9599, 75.5544],
              [-96.0786, 75.5101],
            ],
          ],
          [
            [
              [-104.0228, 76.5831],
              [-103.0513, 76.4499],
              [-103.4722, 76.3291],
              [-104.2707, 76.3262],
              [-104.4077, 76.3652],
              [-104.5857, 76.6065],
              [-104.2051, 76.6661],
              [-104.0228, 76.5831],
            ],
          ],
          [
            [
              [-110.0037, 78.3217],
              [-110.0044, 78.6867],
              [-109.5041, 78.5825],
              [-109.3905, 78.3367],
              [-110.0037, 78.3217],
            ],
          ],
          [
            [
              [-89.7264, 76.5074],
              [-90.0543, 76.4951],
              [-90.5562, 76.7346],
              [-90.5248, 76.7878],
              [-89.9488, 76.8362],
              [-89.6954, 76.7412],
              [-89.8221, 76.6306],
              [-89.7264, 76.5074],
            ],
          ],
          [
            [
              [-102.2273, 76.0149],
              [-102.0475, 75.9277],
              [-102.4235, 75.8692],
              [-102.5796, 75.7802],
              [-103.3147, 75.7642],
              [-103.0415, 75.9189],
              [-103.2016, 75.9585],
              [-103.7698, 75.8924],
              [-103.9853, 75.9331],
              [-103.8008, 76.037],
              [-104.2425, 76.047],
              [-104.3506, 76.1823],
              [-103.0982, 76.3115],
              [-102.5841, 76.2816],
              [-102.49, 76.0951],
              [-102.2273, 76.0149],
            ],
          ],
          [
            [
              [-110.0028, 74.851],
              [-110.0027, 75.5391],
              [-109.0864, 75.5065],
              [-108.9472, 75.5418],
              [-108.9448, 75.699],
              [-109.796, 75.863],
              [-109.8705, 75.929],
              [-109.4546, 76.0213],
              [-109.4868, 76.1446],
              [-110.0026, 76.2443],
              [-110.0034, 76.4797],
              [-109.3386, 76.76],
              [-108.8316, 76.8212],
              [-108.467, 76.7376],
              [-108.6118, 76.6297],
              [-108.5125, 76.4389],
              [-108.1935, 76.3301],
              [-108.1232, 76.2335],
              [-108.3868, 76.0666],
              [-107.8523, 76.0578],
              [-107.7552, 75.9403],
              [-106.6931, 75.8099],
              [-106.8621, 75.9301],
              [-106.3966, 76.0601],
              [-105.9048, 76.009],
              [-105.6044, 75.9299],
              [-105.4809, 75.7457],
              [-105.8626, 75.1915],
              [-106.0926, 75.0894],
              [-107.1535, 74.9272],
              [-107.82, 75.0001],
              [-108.3544, 74.9426],
              [-108.5942, 74.9596],
              [-108.8313, 75.0649],
              [-109.5031, 74.8828],
              [-110.0028, 74.851],
            ],
          ],
          [
            [
              [-94.295, 76.9125],
              [-93.8109, 76.9142],
              [-93.2119, 76.7547],
              [-93.2005, 76.6691],
              [-92.7163, 76.603],
              [-91.7895, 76.6758],
              [-91.305, 76.6808],
              [-90.7384, 76.5814],
              [-90.6217, 76.4647],
              [-90.8548, 76.4373],
              [-89.2845, 76.3016],
              [-89.4066, 76.1891],
              [-90.3121, 76.158],
              [-91.2604, 76.23],
              [-91.2794, 76.1599],
              [-90.7121, 76.0762],
              [-90.2514, 76.0535],
              [-90.0328, 75.9709],
              [-89.2048, 75.762],
              [-89.3612, 75.6459],
              [-89.2804, 75.5642],
              [-88.5691, 75.6451],
              [-88.2013, 75.512],
              [-87.2569, 75.6178],
              [-86.8144, 75.4914],
              [-86.2363, 75.4063],
              [-86.0688, 75.5023],
              [-84.9868, 75.645],
              [-84.6049, 75.6535],
              [-83.932, 75.819],
              [-83.2372, 75.7509],
              [-82.1537, 75.831],
              [-81.1508, 75.7355],
              [-81.0007, 75.6431],
              [-80.322, 75.6291],
              [-80.0996, 75.4674],
              [-79.6602, 75.4495],
              [-79.5078, 75.2953],
              [-79.6344, 75.1993],
              [-80.3576, 75.0515],
              [-80.0364, 74.9909],
              [-79.5248, 74.9896],
              [-79.508, 74.8801],
              [-79.9445, 74.8336],
              [-80.2127, 74.7494],
              [-80.2777, 74.5816],
              [-81.2262, 74.5666],
              [-81.8088, 74.4766],
              [-82.9311, 74.5655],
              [-83.3413, 74.7646],
              [-83.5319, 74.5857],
              [-84.4256, 74.5081],
              [-85.3393, 74.5433],
              [-85.808, 74.499],
              [-86.1098, 74.5397],
              [-86.6661, 74.4891],
              [-87.3638, 74.5022],
              [-87.5926, 74.4703],
              [-88.423, 74.4942],
              [-88.5376, 74.6088],
              [-88.3396, 74.7848],
              [-88.6821, 74.802],
              [-88.7778, 74.7152],
              [-89.2191, 74.7318],
              [-89.1958, 74.6375],
              [-89.5588, 74.5548],
              [-89.8444, 74.5485],
              [-90.5533, 74.6127],
              [-90.7841, 74.6959],
              [-91.5084, 74.6507],
              [-91.9616, 74.7932],
              [-92.1741, 75.0511],
              [-92.0763, 75.1235],
              [-92.3474, 75.2297],
              [-92.4271, 75.3464],
              [-92.0689, 75.658],
              [-92.1851, 75.8465],
              [-92.8833, 76.214],
              [-93.0917, 76.354],
              [-93.6651, 76.2731],
              [-94.7368, 76.2933],
              [-95.2739, 76.2644],
              [-95.4475, 76.363],
              [-96.0397, 76.4867],
              [-95.7889, 76.5372],
              [-96.6397, 76.7029],
              [-96.4332, 76.8107],
              [-96.8135, 76.9135],
              [-96.7583, 76.9718],
              [-96.0612, 77.05],
              [-95.6382, 77.0638],
              [-94.295, 76.9125],
            ],
          ],
          [
            [
              [-97.7009, 76.4665],
              [-97.7068, 76.3037],
              [-97.5307, 76.1816],
              [-97.6522, 75.9402],
              [-97.6017, 75.851],
              [-97.8628, 75.738],
              [-97.4076, 75.6726],
              [-97.4096, 75.5521],
              [-97.8782, 75.4161],
              [-97.7993, 75.1167],
              [-98.121, 75.0328],
              [-98.5687, 75.0093],
              [-99.4206, 75.0438],
              [-99.6269, 74.9837],
              [-100.2344, 75.0077],
              [-100.4595, 75.2191],
              [-100.3641, 75.2895],
              [-100.7119, 75.4063],
              [-100.2797, 75.461],
              [-99.5912, 75.6553],
              [-99.9152, 75.6813],
              [-101.2068, 75.5904],
              [-101.4613, 75.6078],
              [-102.5414, 75.5136],
              [-102.7975, 75.5997],
              [-102.2521, 75.7777],
              [-102.1447, 75.8751],
              [-101.5996, 75.8327],
              [-101.4313, 75.992],
              [-101.8721, 76.0831],
              [-101.5289, 76.2172],
              [-102.1377, 76.2848],
              [-101.8585, 76.439],
              [-101.3397, 76.4105],
              [-101.0558, 76.2456],
              [-100.0201, 75.9396],
              [-99.9783, 76.0295],
              [-100.3577, 76.2712],
              [-100.1746, 76.3593],
              [-100.6507, 76.3959],
              [-100.8297, 76.5238],
              [-100.3879, 76.6135],
              [-99.6691, 76.6241],
              [-99.1697, 76.4537],
              [-98.8903, 76.4656],
              [-99.0236, 76.6145],
              [-98.7108, 76.6939],
              [-97.7009, 76.4665],
            ],
          ],
          [
            [
              [-93.5426, 75.028],
              [-93.4635, 74.8565],
              [-93.5472, 74.691],
              [-93.9847, 74.6442],
              [-94.6973, 74.6421],
              [-95.2861, 74.7941],
              [-95.8654, 74.8305],
              [-96.2701, 74.9203],
              [-96.5658, 75.0987],
              [-96.1804, 75.2401],
              [-96.1249, 75.3583],
              [-95.6708, 75.5286],
              [-95.0495, 75.6218],
              [-94.4273, 75.5934],
              [-93.7509, 75.349],
              [-93.4976, 75.1369],
              [-93.5426, 75.028],
            ],
          ],
          [
            [
              [-101.6936, 77.6966],
              [-102.0798, 77.6922],
              [-102.4582, 77.7701],
              [-102.4477, 77.8806],
              [-101.6394, 77.8921],
              [-101.1276, 77.8126],
              [-101.002, 77.7351],
              [-101.6936, 77.6966],
            ],
          ],
          [
            [
              [-79.0631, 75.9258],
              [-79.5446, 75.8256],
              [-79.6987, 75.8833],
              [-79.0094, 76.1459],
              [-78.8452, 76.1063],
              [-79.0631, 75.9258],
            ],
          ],
          [
            [
              [-95.4844, 77.792],
              [-95.2331, 77.7538],
              [-94.6668, 77.7762],
              [-93.5829, 77.7708],
              [-93.301, 77.7398],
              [-93.5196, 77.4744],
              [-95.987, 77.4841],
              [-96.2638, 77.5945],
              [-96.143, 77.7144],
              [-95.4844, 77.792],
            ],
          ],
          [
            [
              [-89.8333, 77.2676],
              [-90.2283, 77.2124],
              [-90.9933, 77.3295],
              [-91.1473, 77.3873],
              [-91.1495, 77.6081],
              [-90.8426, 77.655],
              [-90.172, 77.5947],
              [-89.7195, 77.4421],
              [-89.8333, 77.2676],
            ],
          ],
          [
            [
              [-64.407, 60.3671],
              [-64.4419, 60.2979],
              [-64.809, 60.4104],
              [-64.8365, 60.5009],
              [-64.6463, 60.5146],
              [-64.407, 60.3671],
            ],
          ],
          [
            [
              [-67.9147, 69.5409],
              [-68.2024, 69.5804],
              [-67.9891, 69.6788],
              [-67.7547, 69.6315],
              [-67.9147, 69.5409],
            ],
          ],
          [
            [
              [-95.5137, 69.5736],
              [-95.3809, 69.5066],
              [-95.4963, 69.3501],
              [-95.9948, 69.4697],
              [-95.8758, 69.606],
              [-95.5137, 69.5736],
            ],
          ],
          [
            [
              [-100.2172, 68.8067],
              [-100.3657, 68.7288],
              [-100.616, 68.783],
              [-100.6116, 68.9902],
              [-100.414, 69.0281],
              [-100.1785, 68.9039],
              [-100.2172, 68.8067],
            ],
          ],
          [
            [
              [-86.5956, 67.736],
              [-86.8611, 67.8105],
              [-86.847, 68.0103],
              [-86.9598, 68.1003],
              [-86.7021, 68.3056],
              [-86.4212, 68.1835],
              [-86.3824, 67.9273],
              [-86.5956, 67.736],
            ],
          ],
          [
            [
              [-68.2338, 60.2409],
              [-68.3382, 60.3606],
              [-68.1418, 60.562],
              [-67.9142, 60.5398],
              [-67.8442, 60.3917],
              [-68.2338, 60.2409],
            ],
          ],
          [
            [
              [-101.846, 68.5863],
              [-102.2663, 68.6637],
              [-102.0133, 68.8254],
              [-101.7217, 68.7241],
              [-101.846, 68.5863],
            ],
          ],
          [
            [
              [-79.4306, 69.7878],
              [-79.3649, 69.7123],
              [-79.5528, 69.6308],
              [-79.8817, 69.6087],
              [-80.1615, 69.5359],
              [-80.6526, 69.7505],
              [-80.2136, 69.802],
              [-79.9708, 69.739],
              [-79.594, 69.8105],
              [-79.4306, 69.7878],
            ],
          ],
          [
            [
              [-97.4395, 69.6427],
              [-97.2361, 69.6735],
              [-96.8752, 69.51],
              [-96.3, 69.3444],
              [-95.9514, 69.0237],
              [-95.4655, 68.7473],
              [-95.6856, 68.7359],
              [-96.4016, 68.4708],
              [-96.5988, 68.4608],
              [-97.0084, 68.5386],
              [-97.472, 68.5437],
              [-97.8853, 68.6724],
              [-98.8296, 68.8386],
              [-98.9045, 68.9324],
              [-99.254, 68.8631],
              [-99.4409, 68.9176],
              [-99.5641, 69.0342],
              [-99.4557, 69.1312],
              [-98.9122, 69.1676],
              [-98.456, 69.3347],
              [-98.5482, 69.545],
              [-98.2888, 69.629],
              [-98.2682, 69.7545],
              [-98.0808, 69.8331],
              [-97.7907, 69.8617],
              [-97.4114, 69.7384],
              [-97.4395, 69.6427],
            ],
          ],
          [
            [
              [-100.0019, 73.9459],
              [-99.1579, 73.7316],
              [-98.1519, 73.8182],
              [-97.5818, 73.8875],
              [-97.2248, 73.8438],
              [-97.0017, 73.6665],
              [-97.3948, 73.5642],
              [-97.2725, 73.3868],
              [-97.7959, 73.2853],
              [-98.4369, 73.0002],
              [-97.7248, 73.0366],
              [-97.3288, 72.9378],
              [-97.3777, 72.8649],
              [-97.083, 72.7629],
              [-97.0518, 72.6368],
              [-96.5422, 72.6987],
              [-96.4402, 72.4872],
              [-96.5198, 72.3931],
              [-96.7959, 72.3138],
              [-96.5928, 72.2044],
              [-96.7663, 72.0459],
              [-96.6244, 71.9676],
              [-96.6134, 71.8338],
              [-96.9465, 71.7919],
              [-97.2222, 71.6735],
              [-97.5823, 71.6297],
              [-98.1813, 71.6625],
              [-98.1986, 71.4409],
              [-98.6629, 71.3021],
              [-99.1671, 71.3672],
              [-99.7348, 71.7573],
              [-100.3257, 72.0039],
              [-100.5945, 72.1524],
              [-100.9837, 72.21],
              [-101.2086, 72.317],
              [-101.4983, 72.2779],
              [-101.7239, 72.3149],
              [-101.9737, 72.4862],
              [-102.4022, 72.5948],
              [-102.7137, 72.7829],
              [-102.5038, 73.0059],
              [-102.204, 73.0772],
              [-101.9224, 73.057],
              [-101.5436, 72.883],
              [-101.3506, 72.7463],
              [-101.0876, 72.7133],
              [-100.4848, 72.773],
              [-100.3957, 72.977],
              [-100.0968, 72.9632],
              [-100.2827, 73.1203],
              [-100.5314, 73.1383],
              [-100.4388, 73.2546],
              [-100.8894, 73.2753],
              [-101.4508, 73.431],
              [-101.3232, 73.572],
              [-100.9758, 73.5997],
              [-100.9151, 73.8054],
              [-100.4836, 73.8435],
              [-100.0019, 73.9459],
            ],
          ],
          [
            [
              [-98.7916, 79.9811],
              [-98.8406, 79.737],
              [-99.3017, 79.7841],
              [-99.5156, 79.8871],
              [-100.0925, 79.9186],
              [-100.0533, 80.0934],
              [-99.7312, 80.1441],
              [-98.8947, 80.0818],
              [-98.7916, 79.9811],
            ],
          ],
          [
            [
              [-84.9196, 65.261],
              [-84.6125, 65.4473],
              [-84.2664, 65.3673],
              [-84.0849, 65.2178],
              [-83.4071, 65.104],
              [-83.2223, 64.968],
              [-82.6677, 64.7803],
              [-82.2717, 64.7212],
              [-82.05, 64.6442],
              [-81.7872, 64.4259],
              [-81.6761, 64.2126],
              [-81.7161, 64.0219],
              [-81.3357, 64.0758],
              [-81.0051, 64.0333],
              [-80.8289, 64.09],
              [-80.5792, 63.9092],
              [-80.2614, 63.8019],
              [-80.3021, 63.7622],
              [-81.0464, 63.4615],
              [-81.9634, 63.6644],
              [-82.3781, 63.7068],
              [-82.4671, 63.927],
              [-83.0339, 64.0232],
              [-83.0652, 64.159],
              [-83.4944, 64.0992],
              [-83.617, 64.0134],
              [-83.7283, 63.8134],
              [-84.0221, 63.6599],
              [-84.3076, 63.5857],
              [-84.6329, 63.3092],
              [-84.9615, 63.1972],
              [-85.3926, 63.1197],
              [-85.4955, 63.1391],
              [-85.7142, 63.6579],
              [-85.8047, 63.7065],
              [-86.3016, 63.6567],
              [-86.5757, 63.6623],
              [-86.8469, 63.5754],
              [-87.1771, 63.5951],
              [-87.189, 63.6723],
              [-86.886, 63.9237],
              [-86.4217, 64.0515],
              [-86.2521, 64.1369],
              [-86.3545, 64.3766],
              [-86.3742, 64.5658],
              [-86.1883, 65.0103],
              [-86.1142, 65.4173],
              [-85.9617, 65.7043],
              [-85.814, 65.832],
              [-85.5547, 65.9187],
              [-85.2411, 65.7955],
              [-85.1054, 65.6227],
              [-85.24, 65.5103],
              [-85.0561, 65.4374],
              [-84.9196, 65.261],
            ],
          ],
          [
            [
              [-103.426, 79.3156],
              [-102.9144, 79.2311],
              [-102.6523, 79.095],
              [-102.7305, 78.9694],
              [-101.8726, 79.0884],
              [-101.0337, 78.9147],
              [-100.917, 78.7829],
              [-100.4355, 78.8203],
              [-99.6094, 78.583],
              [-99.8478, 78.4382],
              [-99.7513, 78.303],
              [-99.1316, 78.1175],
              [-98.9997, 77.9969],
              [-99.1664, 77.857],
              [-99.9559, 77.7938],
              [-100.586, 77.8918],
              [-101.0741, 78.1938],
              [-102.057, 78.2796],
              [-102.607, 78.2489],
              [-102.7313, 78.371],
              [-104.3242, 78.2695],
              [-104.7636, 78.3516],
              [-104.9955, 78.5185],
              [-104.7271, 78.5794],
              [-103.7644, 78.5196],
              [-103.4826, 78.594],
              [-103.5184, 78.7691],
              [-104.155, 78.814],
              [-103.8757, 78.9027],
              [-104.7353, 78.9911],
              [-104.9014, 79.0511],
              [-105.5357, 79.0325],
              [-105.571, 79.1642],
              [-105.3877, 79.3236],
              [-104.8474, 79.311],
              [-103.7064, 79.3521],
              [-103.426, 79.3156],
            ],
          ],
          [
            [
              [-104.5407, 68.4059],
              [-104.9652, 68.4918],
              [-104.9073, 68.5818],
              [-104.602, 68.5615],
              [-104.4721, 68.5035],
              [-104.5407, 68.4059],
            ],
          ],
          [
            [
              [-75.6759, 68.3225],
              [-75.1539, 68.234],
              [-75.0634, 68.1412],
              [-75.1274, 67.9653],
              [-75.0863, 67.7514],
              [-75.1273, 67.5373],
              [-75.4001, 67.3667],
              [-75.78, 67.2836],
              [-76.6939, 67.2358],
              [-77.0049, 67.2669],
              [-77.1571, 67.4083],
              [-77.3059, 67.7061],
              [-77.2286, 67.8501],
              [-76.7402, 68.2312],
              [-76.3645, 68.3187],
              [-75.6759, 68.3225],
            ],
          ],
          [
            [
              [-105.2889, 72.9199],
              [-105.4341, 72.9379],
              [-106.0711, 73.1963],
              [-106.18, 73.3042],
              [-106.5257, 73.4134],
              [-106.9215, 73.4799],
              [-106.6139, 73.6956],
              [-105.5123, 73.7657],
              [-105.1144, 73.7444],
              [-104.6488, 73.6144],
              [-104.555, 73.5411],
              [-104.5829, 73.3539],
              [-104.791, 73.1677],
              [-105.2889, 72.9199],
            ],
          ],
          [
            [
              [-96.7824, 72.9366],
              [-97.0928, 72.997],
              [-97.0692, 73.1302],
              [-96.8624, 73.1888],
              [-96.5985, 73.0739],
              [-96.7824, 72.9366],
            ],
          ],
          [
            [
              [-79.9776, 56.207],
              [-80.005, 56.3179],
              [-79.6811, 56.404],
              [-79.6879, 56.3268],
              [-79.9776, 56.207],
            ],
          ],
          [
            [
              [-91.8856, 81.1329],
              [-91.2725, 80.8501],
              [-90.6829, 80.6877],
              [-90.643, 80.5937],
              [-89.8618, 80.4984],
              [-89.1669, 80.4797],
              [-89.1983, 80.2631],
              [-88.5376, 80.1311],
              [-88.6436, 80.3869],
              [-88.1253, 80.4295],
              [-87.675, 80.3721],
              [-87.6255, 80.1872],
              [-87.9223, 80.0977],
              [-87.202, 80.0432],
              [-86.9772, 79.8942],
              [-87.1442, 79.6626],
              [-86.6489, 79.6462],
              [-85.5014, 79.5303],
              [-85.0637, 79.3282],
              [-85.2899, 79.2084],
              [-86.0916, 79.1],
              [-87.0164, 78.8987],
              [-87.6174, 78.6763],
              [-87.8614, 78.7069],
              [-88.2845, 78.4966],
              [-88.6065, 78.392],
              [-88.791, 78.1924],
              [-89.6512, 78.193],
              [-90.2973, 78.328],
              [-90.3268, 78.1848],
              [-90.6144, 78.1498],
              [-91.8992, 78.2369],
              [-92.6783, 78.3891],
              [-92.7256, 78.4867],
              [-91.9349, 78.5617],
              [-93.2667, 78.6083],
              [-93.6344, 78.7509],
              [-93.3365, 78.8081],
              [-93.9023, 78.8722],
              [-94.1628, 78.9942],
              [-93.2939, 79.1395],
              [-92.6836, 79.1858],
              [-92.5472, 79.2826],
              [-91.8676, 79.3174],
              [-92.4846, 79.4393],
              [-92.822, 79.4499],
              [-93.9332, 79.2907],
              [-93.9397, 79.3857],
              [-94.4049, 79.3905],
              [-95.1032, 79.2899],
              [-95.733, 79.4182],
              [-95.6629, 79.5273],
              [-95.297, 79.6531],
              [-95.8575, 79.6738],
              [-96.4627, 79.8476],
              [-96.7733, 80.1358],
              [-95.782, 80.0664],
              [-94.6459, 80.0487],
              [-94.5901, 80.2015],
              [-95.4051, 80.135],
              [-96.3684, 80.2931],
              [-96.3344, 80.3527],
              [-95.6145, 80.3962],
              [-96.1518, 80.5535],
              [-96.1329, 80.6914],
              [-95.7137, 80.7255],
              [-94.5962, 80.6406],
              [-94.7885, 80.7512],
              [-95.5148, 80.8381],
              [-95.2698, 81.0008],
              [-94.5194, 81.0312],
              [-93.2356, 81.1289],
              [-93.4065, 81.2091],
              [-94.1945, 81.241],
              [-94.0597, 81.3493],
              [-93.3328, 81.3644],
              [-92.4126, 81.2783],
              [-91.8856, 81.1329],
            ],
          ],
          [
            [
              [-76.9953, 69.1438],
              [-77.2151, 69.1381],
              [-77.3793, 69.2741],
              [-77.3187, 69.4163],
              [-76.7456, 69.404],
              [-76.6875, 69.3278],
              [-76.9953, 69.1438],
            ],
          ],
          [
            [
              [-78.9828, 68.1929],
              [-79.1741, 68.235],
              [-79.1535, 68.3352],
              [-78.9526, 68.353],
              [-78.8285, 68.2681],
              [-78.9828, 68.1929],
            ],
          ],
          [
            [
              [-107.8999, 67.4019],
              [-108.1522, 67.4294],
              [-108.1275, 67.6286],
              [-107.9909, 67.6221],
              [-107.8999, 67.4019],
            ],
          ],
          [
            [
              [-94.7719, 60.0017],
              [-95.7027, 60.001],
              [-97.0525, 60.001],
              [-97.9523, 60.001],
              [-99.3021, 60.001],
              [-100.6519, 60.001],
              [-102.0016, 60.001],
              [-102.0022, 60.4821],
              [-102.0032, 61.4441],
              [-102.0041, 62.4062],
              [-102.0051, 63.3683],
              [-101.9991, 64.2284],
              [-103.7426, 64.3661],
              [-105.0502, 64.4694],
              [-107.2295, 64.6415],
              [-108.9729, 64.7792],
              [-109.2151, 64.813],
              [-109.5164, 64.903],
              [-110.6695, 65.4971],
              [-112.5039, 65.5023],
              [-113.7811, 65.893],
              [-114.5474, 66.1274],
              [-115.8247, 66.5182],
              [-116.8464, 66.8307],
              [-117.8681, 67.1433],
              [-119.4008, 67.6121],
              [-120.6779, 68.0029],
              [-120.6798, 69.0314],
              [-120.6819, 69.567],
              [-120.2925, 69.4206],
              [-119.8529, 69.3424],
              [-118.8687, 69.2572],
              [-118.0952, 69.0429],
              [-117.3113, 68.9349],
              [-116.0652, 68.8554],
              [-116.2516, 68.9579],
              [-115.6311, 68.9725],
              [-114.9937, 68.8501],
              [-114.4139, 68.6595],
              [-113.9644, 68.3991],
              [-114.0959, 68.2668],
              [-114.2748, 68.2479],
              [-114.7653, 68.2702],
              [-114.8522, 68.1953],
              [-115.1271, 68.132],
              [-115.1671, 68.0186],
              [-115.4269, 67.9236],
              [-115.1332, 67.8192],
              [-114.6629, 67.7952],
              [-114.267, 67.7312],
              [-113.6819, 67.6999],
              [-112.8794, 67.6799],
              [-112.4351, 67.6848],
              [-112.2367, 67.7311],
              [-111.5757, 67.7568],
              [-111.1922, 67.8226],
              [-110.99, 67.7908],
              [-110.372, 67.9542],
              [-110.0425, 67.9772],
              [-109.6303, 67.7327],
              [-109.0813, 67.7107],
              [-108.9499, 67.4939],
              [-108.5929, 67.5909],
              [-108.347, 67.4034],
              [-107.9887, 67.2564],
              [-107.9092, 67.1625],
              [-108.0885, 67.0697],
              [-108.4553, 67.063],
              [-107.7609, 66.6838],
              [-107.7103, 66.7401],
              [-107.746, 66.9614],
              [-107.6262, 67.0032],
              [-107.1565, 66.8818],
              [-107.3479, 67.0548],
              [-107.6441, 67.3848],
              [-107.6499, 67.5112],
              [-107.9721, 67.7321],
              [-107.9584, 67.8185],
              [-107.7631, 67.9068],
              [-107.7611, 68.0322],
              [-107.3511, 68.0612],
              [-106.8356, 68.1286],
              [-106.6684, 68.2159],
              [-106.4242, 68.2006],
              [-106.4044, 68.3193],
              [-106.0393, 68.4074],
              [-105.7812, 68.5265],
              [-105.9322, 68.6366],
              [-106.4581, 68.5164],
              [-106.6084, 68.3573],
              [-106.9458, 68.3743],
              [-107.1462, 68.3042],
              [-107.6193, 68.3311],
              [-107.7342, 68.1737],
              [-108.261, 68.1499],
              [-108.6866, 68.2774],
              [-108.6409, 68.3785],
              [-108.3135, 68.6107],
              [-107.4359, 68.6888],
              [-106.1645, 68.9199],
              [-105.798, 68.8648],
              [-105.5399, 68.7187],
              [-105.4286, 68.4582],
              [-105.195, 68.3303],
              [-104.6364, 68.2139],
              [-104.4868, 68.0632],
              [-104.1935, 68.0312],
              [-103.6572, 68.0691],
              [-103.4741, 68.115],
              [-102.692, 67.8116],
              [-102.2097, 67.7327],
              [-101.8836, 67.7453],
              [-101.555, 67.6932],
              [-100.8556, 67.7989],
              [-100.4561, 67.8395],
              [-99.7729, 67.8148],
              [-99.1469, 67.7237],
              [-98.9205, 67.7257],
              [-98.4171, 67.8264],
              [-98.7036, 67.9657],
              [-98.4148, 67.9884],
              [-97.9308, 67.7108],
              [-97.4549, 67.617],
              [-97.1554, 67.7264],
              [-97.2066, 67.855],
              [-97.5467, 67.9607],
              [-97.7391, 67.9781],
              [-98.1105, 67.903],
              [-98.4384, 68.0646],
              [-98.3808, 68.1325],
              [-98.633, 68.3312],
              [-98.4685, 68.3822],
              [-98.2185, 68.3174],
              [-97.7943, 68.3876],
              [-97.9389, 68.5104],
              [-97.2659, 68.4529],
              [-96.9767, 68.2554],
              [-96.4802, 68.2429],
              [-96.4394, 68.1509],
              [-96.0756, 68.2365],
              [-96.036, 68.1578],
              [-96.1989, 67.7178],
              [-96.3713, 67.5538],
              [-96.0126, 67.2709],
              [-95.7825, 67.1938],
              [-95.2588, 67.2625],
              [-95.4634, 67.6102],
              [-95.6505, 67.7374],
              [-95.4607, 68.0214],
              [-95.1259, 68.0833],
              [-94.7444, 68.0708],
              [-94.2548, 68.2968],
              [-93.9278, 68.4738],
              [-93.6517, 68.5431],
              [-93.6815, 68.8873],
              [-93.8961, 68.9822],
              [-93.9916, 68.8207],
              [-94.4784, 68.7428],
              [-94.6005, 68.8032],
              [-94.5626, 68.9116],
              [-94.0837, 69.1231],
              [-94.2554, 69.1515],
              [-94.2547, 69.3137],
              [-93.6126, 69.4028],
              [-93.6498, 69.519],
              [-94.0153, 69.4467],
              [-94.2708, 69.4551],
              [-94.6339, 69.6497],
              [-94.8225, 69.5778],
              [-95.2921, 69.6674],
              [-95.7074, 69.7782],
              [-96.0502, 69.8312],
              [-96.4924, 70.125],
              [-96.5456, 70.3273],
              [-96.2265, 70.5417],
              [-96.5489, 70.8088],
              [-96.4057, 71.2736],
              [-96.1396, 71.3964],
              [-95.5642, 71.3368],
              [-95.4063, 71.4917],
              [-95.8304, 71.5261],
              [-95.8378, 71.5982],
              [-95.2013, 71.9037],
              [-94.5571, 71.9789],
              [-94.3083, 71.7649],
              [-93.8101, 71.7662],
              [-93.7629, 71.6381],
              [-93.2564, 71.4608],
              [-92.9487, 71.2621],
              [-92.8828, 71.0693],
              [-92.9815, 70.8522],
              [-92.3885, 70.6505],
              [-92.0491, 70.3897],
              [-91.8204, 70.3416],
              [-91.5641, 70.1782],
              [-91.995, 70.1433],
              [-92.2904, 70.2399],
              [-92.5118, 70.1038],
              [-91.9767, 70.0387],
              [-92.2848, 69.8922],
              [-92.7509, 69.7139],
              [-92.3117, 69.6729],
              [-91.9119, 69.5313],
              [-91.7241, 69.5456],
              [-91.3842, 69.6494],
              [-91.2881, 69.5432],
              [-90.5549, 69.5045],
              [-90.8221, 69.2905],
              [-91.2373, 69.2855],
              [-90.7448, 69.106],
              [-90.4684, 68.8637],
              [-90.5736, 68.4747],
              [-90.2478, 68.2674],
              [-90.0053, 68.3981],
              [-89.8795, 68.5215],
              [-89.6666, 69.0146],
              [-89.3519, 69.227],
              [-89.0567, 69.2661],
              [-88.6377, 69.0588],
              [-88.3156, 68.9544],
              [-88.0413, 68.8117],
              [-87.8103, 68.4042],
              [-87.8927, 68.2482],
              [-88.347, 68.2883],
              [-88.3138, 67.9504],
              [-88.1959, 67.7658],
              [-87.9972, 67.6257],
              [-87.4991, 67.3553],
              [-87.418, 67.2142],
              [-87.2662, 67.1839],
              [-86.5364, 67.5161],
              [-86.4755, 67.7131],
              [-86.3697, 67.8248],
              [-85.9525, 68.0725],
              [-85.7311, 68.445],
              [-85.7339, 68.6301],
              [-85.5178, 68.7698],
              [-85.2751, 68.7414],
              [-84.9161, 68.9623],
              [-84.8622, 69.0739],
              [-85.3867, 69.2319],
              [-85.416, 69.5477],
              [-85.5024, 69.6515],
              [-85.5073, 69.8452],
              [-85.0198, 69.8048],
              [-84.6451, 69.8497],
              [-84.2417, 69.835],
              [-83.6653, 69.6998],
              [-82.6184, 69.6911],
              [-82.3742, 69.6418],
              [-82.6421, 69.4584],
              [-82.3099, 69.41],
              [-82.1505, 69.2489],
              [-81.9518, 69.2761],
              [-81.4123, 69.1981],
              [-81.3287, 69.1199],
              [-81.6114, 69.003],
              [-81.9516, 68.9091],
              [-81.3809, 68.8501],
              [-81.2635, 68.7807],
              [-81.2816, 68.6571],
              [-81.5269, 68.5559],
              [-82.0065, 68.4626],
              [-82.2101, 68.5063],
              [-82.5527, 68.4465],
              [-82.2224, 68.1452],
              [-82.0134, 68.1734],
              [-82.1004, 67.9898],
              [-81.9765, 67.862],
              [-81.4123, 67.5953],
              [-81.2701, 67.4599],
              [-81.4676, 67.0699],
              [-81.6301, 67.002],
              [-81.8745, 66.988],
              [-82.1984, 66.7647],
              [-82.6415, 66.5876],
              [-82.9489, 66.5509],
              [-83.2984, 66.3921],
              [-83.5231, 66.3688],
              [-83.9202, 66.6791],
              [-84.3244, 66.7818],
              [-84.2726, 66.8392],
              [-84.5384, 66.9729],
              [-84.6385, 66.9024],
              [-84.2231, 66.6824],
              [-84.0942, 66.5262],
              [-83.8259, 66.2899],
              [-83.9051, 66.2117],
              [-84.293, 66.2918],
              [-84.4784, 66.1793],
              [-85.0962, 66.3254],
              [-85.4423, 66.5373],
              [-85.6038, 66.5683],
              [-86.0632, 66.5204],
              [-86.6332, 66.5314],
              [-86.747, 66.4171],
              [-86.5848, 66.322],
              [-86.0008, 66.1868],
              [-86.0428, 66.0225],
              [-86.9532, 65.5283],
              [-87.2915, 65.3548],
              [-87.9699, 65.349],
              [-88.6725, 65.6115],
              [-88.7439, 65.6788],
              [-89.0877, 65.7389],
              [-89.4203, 65.8608],
              [-89.7494, 65.936],
              [-89.8478, 65.8723],
              [-90.1166, 65.8824],
              [-91.0095, 65.9658],
              [-90.9834, 65.9192],
              [-90.0476, 65.8056],
              [-89.788, 65.7367],
              [-89.2418, 65.4465],
              [-88.974, 65.3483],
              [-88.1978, 65.2799],
              [-87.3919, 65.2606],
              [-87.0275, 65.1981],
              [-87.0285, 65.0637],
              [-87.2805, 64.8262],
              [-87.8851, 64.4004],
              [-88.1057, 64.1833],
              [-88.6531, 64.0094],
              [-89.0596, 64.0344],
              [-89.2145, 63.9842],
              [-89.4035, 64.04],
              [-89.6159, 64.0306],
              [-89.7638, 64.0995],
              [-89.9536, 64.0806],
              [-89.8606, 63.9789],
              [-90.1419, 63.982],
              [-90.0135, 63.8042],
              [-90.2453, 63.6419],
              [-90.5964, 63.6613],
              [-90.7069, 63.5969],
              [-90.9456, 63.5879],
              [-91.5388, 63.7256],
              [-92.1951, 63.776],
              [-92.5501, 63.8295],
              [-92.9702, 63.9377],
              [-93.5967, 64.0406],
              [-93.6642, 63.9415],
              [-93.4156, 63.8379],
              [-93.3785, 63.9485],
              [-92.5293, 63.7612],
              [-92.1569, 63.6917],
              [-91.8419, 63.6976],
              [-91.3301, 63.5068],
              [-90.97, 63.4428],
              [-90.7466, 63.3516],
              [-90.6907, 63.1105],
              [-90.8712, 62.9459],
              [-91.1149, 62.9215],
              [-91.449, 62.8041],
              [-92.0343, 62.8635],
              [-92.3613, 62.8194],
              [-92.2432, 62.6836],
              [-91.9558, 62.6447],
              [-92.0079, 62.5405],
              [-92.2072, 62.5853],
              [-92.5514, 62.5467],
              [-92.5949, 62.4701],
              [-92.8659, 62.3062],
              [-93.0162, 62.0927],
              [-93.372, 61.9289],
              [-93.4206, 61.7058],
              [-93.7097, 61.6025],
              [-93.9409, 61.4436],
              [-93.8888, 61.344],
              [-94.0608, 61.3179],
              [-94.0678, 61.1389],
              [-94.5689, 60.542],
              [-94.7617, 60.4982],
              [-94.6468, 60.4164],
              [-94.7719, 60.0017],
            ],
          ],
          [
            [
              [-83.726, 65.7967],
              [-83.2339, 65.6966],
              [-83.3814, 65.63],
              [-83.6363, 65.6915],
              [-83.8136, 65.7875],
              [-84.1183, 65.7718],
              [-84.1432, 65.916],
              [-84.4506, 66.0643],
              [-84.4072, 66.131],
              [-83.9504, 66.0275],
              [-83.7014, 65.9201],
              [-83.726, 65.7967],
            ],
          ],
          [
            [
              [-84.6748, 65.575],
              [-84.783, 65.5701],
              [-85.1363, 65.8209],
              [-85.1497, 66.0154],
              [-84.9386, 66.0086],
              [-84.6917, 65.7932],
              [-84.6026, 65.6574],
              [-84.6748, 65.575],
            ],
          ],
          [
            [
              [-78.0291, 69.7149],
              [-77.9691, 69.639],
              [-78.4701, 69.5025],
              [-78.7893, 69.5232],
              [-78.5786, 69.6388],
              [-78.0291, 69.7149],
            ],
          ],
          [
            [
              [-98.2704, 73.8685],
              [-98.9739, 73.812],
              [-99.3851, 73.8793],
              [-98.9045, 74.0069],
              [-98.0611, 74.1046],
              [-97.6982, 74.1087],
              [-97.7547, 74.0055],
              [-98.2704, 73.8685],
            ],
          ],
          [
            [
              [-77.8767, 63.4706],
              [-77.7037, 63.4308],
              [-77.5385, 63.287],
              [-77.5939, 63.1884],
              [-77.9424, 63.1144],
              [-78.2559, 63.2398],
              [-78.4687, 63.3579],
              [-78.5074, 63.4512],
              [-78.2349, 63.4895],
              [-77.8767, 63.4706],
            ],
          ],
          [
            [
              [-116.8561, 69.6497],
              [-116.6937, 69.6955],
              [-117.0753, 69.889],
              [-117.0554, 70.0022],
              [-114.4832, 70.0015],
              [-112.8811, 70.0014],
              [-112.8749, 69.8333],
              [-112.65, 69.8344],
              [-112.5, 70.0012],
              [-110, 70.0012],
              [-110.0005, 71.087],
              [-110.001, 72.1729],
              [-110.0018, 72.9818],
              [-109.4691, 72.8084],
              [-109.1219, 72.7265],
              [-108.6983, 72.4992],
              [-108.2104, 71.7512],
              [-107.9253, 71.6386],
              [-107.3469, 71.8192],
              [-107.306, 71.8947],
              [-107.6959, 72.1493],
              [-107.8237, 72.4428],
              [-107.9325, 72.5204],
              [-108.2382, 73.1058],
              [-107.9799, 73.2068],
              [-108.0894, 73.3037],
              [-107.72, 73.329],
              [-107.1135, 73.1921],
              [-106.9508, 73.2761],
              [-106.4821, 73.1962],
              [-105.8127, 73.0107],
              [-105.4152, 72.7883],
              [-105.2341, 72.4151],
              [-104.767, 71.8676],
              [-104.3859, 71.577],
              [-104.3496, 71.4339],
              [-104.5696, 71.1041],
              [-104.1669, 70.9272],
              [-103.9534, 70.7627],
              [-103.5846, 70.6309],
              [-103.105, 70.5103],
              [-102.7505, 70.5219],
              [-101.9899, 70.285],
              [-101.6412, 70.2656],
              [-101.5624, 70.135],
              [-101.1485, 70.1476],
              [-100.9733, 70.0294],
              [-100.9351, 69.7154],
              [-101.2162, 69.6796],
              [-101.4001, 69.7492],
              [-101.6476, 69.6986],
              [-102.2343, 69.8423],
              [-102.5959, 69.718],
              [-102.6211, 69.5515],
              [-102.9197, 69.5647],
              [-103.3592, 69.6854],
              [-103.4649, 69.6444],
              [-103.1424, 69.4973],
              [-103.0398, 69.3676],
              [-102.8841, 69.3414],
              [-102.4467, 69.4763],
              [-102.1514, 69.4877],
              [-101.9755, 69.4071],
              [-102.064, 69.2811],
              [-101.8225, 69.2171],
              [-101.8571, 69.0239],
              [-102.4884, 68.889],
              [-102.8951, 68.8236],
              [-103.4682, 68.8086],
              [-104.0673, 68.8655],
              [-104.3527, 68.9282],
              [-104.5714, 68.8721],
              [-105.1059, 68.9204],
              [-105.0196, 69.0812],
              [-105.533, 69.1335],
              [-106.1408, 69.1621],
              [-106.3412, 69.2244],
              [-106.3614, 69.381],
              [-106.659, 69.4395],
              [-106.8558, 69.3473],
              [-107.0335, 69.1807],
              [-107.4399, 69.0021],
              [-107.8634, 68.9543],
              [-108.365, 68.9347],
              [-108.9459, 68.7599],
              [-109.4721, 68.6766],
              [-109.9586, 68.6302],
              [-110.8481, 68.5784],
              [-111.1276, 68.5884],
              [-111.3109, 68.542],
              [-112.305, 68.5163],
              [-112.8642, 68.4771],
              [-113.1277, 68.4942],
              [-113.3381, 68.5988],
              [-113.6168, 68.8385],
              [-113.5925, 68.9599],
              [-113.6941, 69.1951],
              [-114.323, 69.2691],
              [-115.159, 69.2648],
              [-115.6182, 69.283],
              [-116.1015, 69.3371],
              [-116.5135, 69.4246],
              [-116.8561, 69.6497],
            ],
          ],
          [
            [
              [-79.5373, 73.6545],
              [-79.3668, 73.6413],
              [-78.2866, 73.6658],
              [-77.2066, 73.4996],
              [-77.0054, 73.356],
              [-76.7587, 73.31],
              [-76.5698, 73.1593],
              [-76.2895, 73.0811],
              [-76.3096, 72.9979],
              [-76.09, 72.8812],
              [-76.4005, 72.8207],
              [-77.8359, 72.8968],
              [-78.3142, 72.8818],
              [-79.1342, 72.7717],
              [-79.5005, 72.756],
              [-79.9368, 72.8636],
              [-80.1834, 73.2246],
              [-80.6179, 73.2708],
              [-80.8241, 73.3807],
              [-80.8489, 73.7213],
              [-80.4123, 73.7654],
              [-80.1203, 73.7071],
              [-79.5373, 73.6545],
            ],
          ],
          [
            [
              [-79.5453, 62.4117],
              [-79.2865, 62.2476],
              [-79.324, 62.0261],
              [-79.7143, 61.6125],
              [-79.8161, 61.5947],
              [-80.2652, 61.8182],
              [-80.2601, 62.1091],
              [-80.0216, 62.343],
              [-79.868, 62.4044],
              [-79.5453, 62.4117],
            ],
          ],
          [
            [
              [-79.3843, 51.952],
              [-79.6438, 52.0101],
              [-79.3349, 52.0981],
              [-79.3843, 51.952],
            ],
          ],
          [
            [
              [-76.6776, 63.394],
              [-77.0572, 63.4498],
              [-77.3647, 63.5883],
              [-77.1337, 63.6821],
              [-76.7636, 63.5736],
              [-76.6524, 63.5036],
              [-76.6776, 63.394],
            ],
          ],
          [
            [
              [-64.8238, 62.5588],
              [-64.5153, 62.5518],
              [-64.4783, 62.4179],
              [-64.6574, 62.3836],
              [-64.9012, 62.4211],
              [-64.8238, 62.5588],
            ],
          ],
          [
            [
              [-79.2107, 68.8455],
              [-79.4058, 68.9231],
              [-79.2427, 69.0493],
              [-78.9, 69.1354],
              [-78.6621, 69.2623],
              [-78.5967, 69.3706],
              [-78.3326, 69.386],
              [-78.2289, 69.304],
              [-78.533, 69.1461],
              [-78.8527, 68.9156],
              [-79.2107, 68.8455],
            ],
          ],
          [
            [
              [-93.1709, 74.1609],
              [-92.4929, 74.062],
              [-92.2227, 73.9724],
              [-91.6304, 74.0278],
              [-91.088, 74.0093],
              [-90.6274, 73.9517],
              [-90.3547, 73.8687],
              [-90.5656, 73.6864],
              [-90.9337, 73.5277],
              [-91.6211, 73.0259],
              [-92.1179, 72.7538],
              [-92.392, 72.7184],
              [-93.3406, 72.8019],
              [-94.2113, 72.757],
              [-93.7706, 72.6682],
              [-93.5722, 72.5586],
              [-93.5551, 72.4211],
              [-93.8706, 72.2526],
              [-94.0376, 72.0287],
              [-94.4972, 72.0435],
              [-95.193, 72.0274],
              [-95.1668, 72.18],
              [-95.251, 72.502],
              [-95.5476, 72.7816],
              [-95.6133, 72.9416],
              [-95.5893, 73.1742],
              [-95.6329, 73.6955],
              [-95.0595, 73.8051],
              [-95.1526, 73.9327],
              [-94.9736, 74.0414],
              [-94.4826, 74.1131],
              [-93.7846, 74.1184],
              [-93.4103, 74.1788],
              [-93.1709, 74.1609],
            ],
          ],
          [
            [
              [-70.3371, 62.5487],
              [-70.7661, 62.5969],
              [-70.8513, 62.7043],
              [-71.1349, 62.8779],
              [-70.6744, 62.807],
              [-70.4426, 62.7338],
              [-70.2915, 62.616],
              [-70.3371, 62.5487],
            ],
          ],
          [
            [
              [-86.5894, 71.0108],
              [-86.1272, 71.049],
              [-85.6438, 71.1524],
              [-85.0949, 71.1519],
              [-84.7896, 71.0933],
              [-84.6599, 71.5861],
              [-84.8402, 71.6586],
              [-85.2505, 71.6753],
              [-85.5962, 71.8664],
              [-85.9116, 71.9865],
              [-85.5458, 72.1015],
              [-85.4059, 72.2149],
              [-84.643, 72.1895],
              [-84.842, 72.3082],
              [-84.8494, 72.4062],
              [-85.3411, 72.4216],
              [-85.6379, 72.6332],
              [-85.6446, 72.7744],
              [-85.4548, 72.9252],
              [-85.2621, 72.954],
              [-85.4548, 73.1055],
              [-85.0184, 73.3355],
              [-84.4161, 73.4565],
              [-84.089, 73.4594],
              [-83.7298, 73.5759],
              [-82.6596, 73.7296],
              [-81.9462, 73.7298],
              [-81.6053, 73.696],
              [-81.3441, 73.5977],
              [-81.1517, 73.3141],
              [-80.6812, 73.1658],
              [-80.5919, 72.9277],
              [-80.2748, 72.7455],
              [-80.6751, 72.5586],
              [-80.6047, 72.4258],
              [-80.9412, 72.2101],
              [-80.6914, 72.1035],
              [-80.9211, 72.0723],
              [-80.8022, 71.9292],
              [-80.3862, 72.1488],
              [-80.116, 72.214],
              [-80.0419, 72.3942],
              [-79.7779, 72.4388],
              [-79.6538, 72.3321],
              [-79.3233, 72.3908],
              [-79.0002, 72.272],
              [-78.6992, 72.3514],
              [-78.4288, 72.2797],
              [-78.1163, 72.2804],
              [-77.7261, 72.1799],
              [-77.5358, 72.2188],
              [-78.2872, 72.3598],
              [-78.4795, 72.5087],
              [-78.3503, 72.6002],
              [-78.001, 72.6876],
              [-77.5668, 72.7368],
              [-76.8935, 72.7207],
              [-76.1888, 72.5722],
              [-75.7043, 72.5716],
              [-75.1858, 72.4342],
              [-75.0527, 72.2263],
              [-75.3942, 72.0397],
              [-74.9032, 72.1005],
              [-74.2664, 72.0377],
              [-74.3157, 71.8427],
              [-74.893, 71.7256],
              [-74.7008, 71.6756],
              [-74.139, 71.6822],
              [-73.9921, 71.7496],
              [-73.7135, 71.7199],
              [-73.7129, 71.5876],
              [-73.4816, 71.4793],
              [-72.9019, 71.6777],
              [-72.5807, 71.6068],
              [-72.1165, 71.5928],
              [-71.6407, 71.5163],
              [-71.2561, 71.3618],
              [-71.2194, 71.2388],
              [-71.495, 71.1052],
              [-71.7425, 71.0469],
              [-71.1863, 70.978],
              [-70.7925, 71.1033],
              [-70.6365, 71.0066],
              [-70.6552, 70.871],
              [-71.0218, 70.6741],
              [-71.5859, 70.5658],
              [-71.2758, 70.5002],
              [-71.3131, 70.2093],
              [-70.9798, 70.5811],
              [-70.561, 70.7383],
              [-69.9497, 70.845],
              [-69.6955, 70.7859],
              [-69.1687, 70.7642],
              [-68.4958, 70.6103],
              [-68.3583, 70.5229],
              [-68.4826, 70.4148],
              [-68.7937, 70.3244],
              [-69.2987, 70.2768],
              [-69.699, 70.1893],
              [-70.0615, 70.0708],
              [-69.7958, 70.047],
              [-69.483, 70.16],
              [-68.9186, 70.207],
              [-68.7233, 70.1457],
              [-69.0083, 69.9789],
              [-68.744, 69.9414],
              [-68.3186, 70.1606],
              [-68.2035, 70.2815],
              [-67.8553, 70.2818],
              [-67.3637, 70.0344],
              [-67.1726, 69.7995],
              [-67.2593, 69.7212],
              [-67.8062, 69.7774],
              [-68.1139, 69.7542],
              [-68.3721, 69.6443],
              [-68.67, 69.6437],
              [-69.1245, 69.5745],
              [-69.075, 69.5181],
              [-68.513, 69.5773],
              [-68.0582, 69.4758],
              [-67.361, 69.4725],
              [-66.7709, 69.3367],
              [-66.6792, 69.191],
              [-66.8029, 69.1528],
              [-67.4839, 69.167],
              [-67.9384, 69.2481],
              [-68.3039, 69.1664],
              [-67.7517, 69.0386],
              [-67.7511, 68.9338],
              [-67.8832, 68.7839],
              [-68.3242, 68.8441],
              [-68.7253, 68.8102],
              [-68.1525, 68.6811],
              [-68.0379, 68.5507],
              [-67.656, 68.5507],
              [-67.4555, 68.4979],
              [-66.7624, 68.4247],
              [-67.033, 68.3261],
              [-66.729, 68.129],
              [-66.5308, 68.2504],
              [-66.2123, 68.2804],
              [-66.2747, 68.0408],
              [-66.2252, 67.9587],
              [-65.8644, 67.9228],
              [-65.7018, 67.9866],
              [-65.3003, 67.9395],
              [-64.9769, 68.0434],
              [-64.8355, 67.99],
              [-65.026, 67.8921],
              [-65.0211, 67.7876],
              [-64.5275, 67.8127],
              [-64.1563, 67.623],
              [-63.8502, 67.5661],
              [-64.0775, 67.4957],
              [-64.0079, 67.3473],
              [-64.3032, 67.3535],
              [-64.3564, 67.2561],
              [-63.8362, 67.2642],
              [-63.5915, 67.3776],
              [-63.3158, 67.3363],
              [-63.0401, 67.235],
              [-63.2584, 67.0246],
              [-63.7016, 66.8224],
              [-62.9623, 66.9492],
              [-62.3797, 66.9053],
              [-62.1236, 67.0467],
              [-61.9685, 67.0191],
              [-61.2997, 66.6488],
              [-61.5279, 66.5582],
              [-61.9044, 66.6781],
              [-62.0893, 66.6259],
              [-61.6527, 66.5031],
              [-61.5708, 66.3729],
              [-61.8627, 66.3128],
              [-62.1585, 66.338],
              [-62.4057, 66.3159],
              [-62.5336, 66.227],
              [-62.242, 66.1479],
              [-61.9916, 66.0353],
              [-62.4974, 65.974],
              [-62.382, 65.8333],
              [-62.6102, 65.7237],
              [-62.6589, 65.6399],
              [-62.9689, 65.6224],
              [-63.4208, 65.7086],
              [-63.3643, 65.5432],
              [-63.3633, 65.2297],
              [-63.4018, 65.1184],
              [-63.6066, 64.9281],
              [-63.8956, 65.1093],
              [-64.1518, 65.0662],
              [-64.4698, 65.2528],
              [-64.6653, 65.169],
              [-65.1085, 65.4638],
              [-65.4016, 65.764],
              [-65.1849, 65.9399],
              [-64.8537, 66.0159],
              [-65.0045, 66.0777],
              [-65.3053, 66.0085],
              [-65.8257, 65.9969],
              [-65.8572, 66.0865],
              [-66.477, 66.2797],
              [-66.7874, 66.5556],
              [-67.3177, 66.5204],
              [-67.1918, 66.4328],
              [-67.3112, 66.3038],
              [-67.5598, 66.4004],
              [-67.8834, 66.4674],
              [-67.7045, 66.2686],
              [-67.1832, 66.0344],
              [-67.2726, 65.9555],
              [-67.5507, 65.9216],
              [-67.8281, 65.9652],
              [-68.1473, 66.1298],
              [-68.2569, 65.9386],
              [-68.115, 65.8277],
              [-67.8664, 65.7736],
              [-67.9544, 65.623],
              [-67.906, 65.5635],
              [-67.5697, 65.6435],
              [-67.3996, 65.5884],
              [-67.3035, 65.4829],
              [-67.3261, 65.3566],
              [-67.0665, 65.244],
              [-66.9703, 65.0849],
              [-66.8606, 65.0915],
              [-66.2238, 64.8542],
              [-65.9385, 64.8858],
              [-65.7681, 64.8536],
              [-65.5132, 64.7064],
              [-65.2748, 64.6315],
              [-65.5128, 64.526],
              [-65.1786, 64.5097],
              [-65.0746, 64.4367],
              [-65.213, 64.3033],
              [-65.5075, 64.3183],
              [-65.0106, 64.0089],
              [-64.6784, 64.028],
              [-64.411, 63.7063],
              [-64.5616, 63.6797],
              [-64.4987, 63.4628],
              [-64.5144, 63.264],
              [-64.6956, 63.2688],
              [-64.8863, 63.5487],
              [-65.0894, 63.606],
              [-65.0048, 63.3334],
              [-65.0689, 63.2635],
              [-64.6724, 62.922],
              [-64.9232, 62.8892],
              [-65.1627, 62.9326],
              [-65.0466, 62.7015],
              [-65.2658, 62.7151],
              [-65.7404, 62.932],
              [-65.9789, 63.0007],
              [-66.4144, 63.0272],
              [-66.6364, 63.0801],
              [-66.9747, 63.2556],
              [-67.1798, 63.305],
              [-67.495, 63.4815],
              [-67.7092, 63.634],
              [-67.8214, 63.6351],
              [-67.8379, 63.4492],
              [-68.4937, 63.7255],
              [-68.859, 63.7518],
              [-68.9111, 63.7031],
              [-68.6706, 63.5137],
              [-68.374, 63.3521],
              [-68.1413, 63.1723],
              [-67.676, 63.0935],
              [-67.7369, 63.0095],
              [-67.4683, 62.9483],
              [-67.2127, 62.8435],
              [-66.9795, 62.7008],
              [-66.6448, 62.6021],
              [-66.2812, 62.3027],
              [-65.9802, 62.2089],
              [-66.1331, 62.1024],
              [-66.0589, 61.9139],
              [-66.2567, 61.8682],
              [-66.5514, 61.9255],
              [-66.8031, 62.0127],
              [-67.181, 62.0729],
              [-67.4401, 62.1512],
              [-68.3786, 62.2351],
              [-69.0824, 62.4052],
              [-69.3661, 62.5718],
              [-69.5452, 62.7446],
              [-69.7995, 62.7905],
              [-70.2361, 62.7633],
              [-71.2537, 63.0424],
              [-71.6171, 63.1872],
              [-71.973, 63.4298],
              [-71.6965, 63.4303],
              [-71.4558, 63.5123],
              [-71.7253, 63.7062],
              [-72.223, 63.7089],
              [-72.5861, 63.9009],
              [-72.6393, 63.9891],
              [-72.9131, 64.1172],
              [-73.4437, 64.4235],
              [-73.2782, 64.5603],
              [-73.627, 64.6025],
              [-73.9104, 64.5781],
              [-73.9504, 64.4658],
              [-74.0987, 64.4437],
              [-74.1305, 64.6078],
              [-74.5124, 64.6702],
              [-74.6343, 64.8239],
              [-74.9194, 64.7655],
              [-74.64, 64.5571],
              [-74.6947, 64.4965],
              [-75.0674, 64.4567],
              [-75.4878, 64.5408],
              [-75.715, 64.5244],
              [-75.7667, 64.3919],
              [-76.1181, 64.3763],
              [-76.7239, 64.242],
              [-77.2825, 64.2804],
              [-77.6278, 64.3635],
              [-77.7912, 64.3671],
              [-78.0453, 64.4992],
              [-78.1746, 64.6178],
              [-78.1897, 64.7519],
              [-78.0956, 64.9392],
              [-77.8762, 65.0729],
              [-77.4474, 65.1616],
              [-77.3639, 65.2198],
              [-77.4615, 65.3281],
              [-77.3267, 65.4531],
              [-76.779, 65.4139],
              [-76.4817, 65.3697],
              [-75.8283, 65.2271],
              [-75.7086, 65.3157],
              [-75.1663, 65.2839],
              [-74.9817, 65.3814],
              [-74.6655, 65.3669],
              [-74.3908, 65.3975],
              [-74.2368, 65.4839],
              [-73.5608, 65.543],
              [-73.7461, 65.7667],
              [-74.0331, 65.8771],
              [-74.434, 66.139],
              [-74.3749, 66.2081],
              [-73.9337, 66.358],
              [-73.2011, 66.6992],
              [-73.0333, 66.7282],
              [-72.7889, 67.0306],
              [-72.365, 67.1334],
              [-72.22, 67.2543],
              [-72.3529, 67.3418],
              [-72.5764, 67.6586],
              [-73.0635, 68.107],
              [-73.3282, 68.2667],
              [-73.6444, 68.2945],
              [-73.8793, 68.4295],
              [-73.7825, 68.5781],
              [-73.8515, 68.7014],
              [-74.118, 68.7009],
              [-73.966, 68.5788],
              [-74.1828, 68.5355],
              [-74.4225, 68.58],
              [-74.648, 68.7076],
              [-74.7524, 68.892],
              [-74.9173, 68.9829],
              [-75.2133, 68.9093],
              [-75.5227, 68.9527],
              [-75.6231, 68.8878],
              [-76.4034, 68.6923],
              [-76.6195, 68.7214],
              [-76.5572, 69.0094],
              [-76.3809, 69.0525],
              [-75.9537, 69.0308],
              [-75.668, 69.1588],
              [-75.7871, 69.3187],
              [-76.4079, 69.4412],
              [-76.5204, 69.5165],
              [-77.1288, 69.6527],
              [-76.8686, 69.7452],
              [-77.0159, 69.8362],
              [-77.4943, 69.8363],
              [-77.6353, 69.9004],
              [-77.7219, 70.1708],
              [-77.8425, 70.2471],
              [-78.2829, 70.2292],
              [-78.6214, 70.3534],
              [-78.9797, 70.5813],
              [-79.3466, 70.4819],
              [-79.3474, 70.3724],
              [-79.0175, 70.3252],
              [-78.8099, 70.1786],
              [-78.7779, 70.0476],
              [-78.8896, 69.9775],
              [-79.3034, 69.8948],
              [-79.6159, 69.8948],
              [-80.1621, 69.996],
              [-81.0983, 70.0912],
              [-81.1968, 69.9828],
              [-80.9248, 69.8507],
              [-80.9217, 69.7309],
              [-81.5647, 69.9427],
              [-82.1387, 69.8412],
              [-82.4877, 69.866],
              [-83.0912, 70.0039],
              [-83.8591, 69.9627],
              [-84.5219, 70.0052],
              [-84.9091, 70.0782],
              [-85.4324, 70.1113],
              [-85.78, 70.0366],
              [-86.322, 70.1454],
              [-86.6243, 70.4013],
              [-87.1225, 70.412],
              [-87.0633, 70.3251],
              [-87.5025, 70.3256],
              [-87.9008, 70.2519],
              [-88.4021, 70.4425],
              [-88.8484, 70.5229],
              [-89.2083, 70.7597],
              [-89.3655, 71.0671],
              [-88.6957, 71.0456],
              [-87.8449, 70.9444],
              [-87.1816, 70.9875],
              [-87.8724, 71.2085],
              [-88.5895, 71.2404],
              [-89.0793, 71.288],
              [-89.6933, 71.4235],
              [-89.8457, 71.4923],
              [-90.0251, 71.9488],
              [-89.9316, 72.049],
              [-89.6638, 72.1579],
              [-89.8228, 72.2078],
              [-89.8615, 72.412],
              [-89.3577, 72.8041],
              [-89.2632, 73.069],
              [-88.7051, 73.4033],
              [-87.7197, 73.7229],
              [-86.7688, 73.834],
              [-85.9508, 73.8502],
              [-85.1105, 73.8082],
              [-84.9745, 73.6948],
              [-85.6819, 73.4615],
              [-86.0865, 73.2603],
              [-86.6294, 72.8708],
              [-86.5946, 72.6611],
              [-86.3226, 72.4609],
              [-86.3413, 72.1232],
              [-86.2185, 71.8991],
              [-86.0361, 71.771],
              [-85.0234, 71.3532],
              [-85.4053, 71.2267],
              [-85.9455, 71.1627],
              [-86.5894, 71.0108],
            ],
          ],
          [
            [
              [-69.4888, 83.0168],
              [-68.1069, 82.9612],
              [-66.5917, 82.9441],
              [-66.8363, 82.8179],
              [-68.3575, 82.6768],
              [-67.397, 82.6681],
              [-65.7275, 82.8424],
              [-64.7768, 82.8764],
              [-63.473, 82.7712],
              [-63.5927, 82.694],
              [-63.0853, 82.5652],
              [-61.6971, 82.4886],
              [-61.3924, 82.4419],
              [-61.2736, 82.2798],
              [-62.1767, 82.0434],
              [-63.5923, 81.8455],
              [-64.574, 81.7337],
              [-65.2263, 81.7435],
              [-65.4954, 81.6681],
              [-66.6258, 81.6164],
              [-66.9141, 81.4851],
              [-68.6886, 81.2934],
              [-68.3177, 81.2612],
              [-65.7357, 81.4942],
              [-64.78, 81.4929],
              [-65.484, 81.2848],
              [-66.7269, 81.0409],
              [-68.6304, 80.6787],
              [-69.4001, 80.4228],
              [-70.1435, 80.3977],
              [-70.2649, 80.2336],
              [-71.4701, 80.1459],
              [-70.5684, 80.0937],
              [-70.7575, 79.9982],
              [-71.3558, 79.9113],
              [-71.1101, 79.8478],
              [-71.3878, 79.7618],
              [-72.4366, 79.6944],
              [-73.4481, 79.8271],
              [-73.2294, 79.644],
              [-73.466, 79.4951],
              [-75.2595, 79.4211],
              [-76.2956, 79.4136],
              [-76.1163, 79.3261],
              [-75.0936, 79.2039],
              [-74.4812, 79.2295],
              [-74.5323, 79.0527],
              [-75.2332, 79.0355],
              [-75.9118, 79.1178],
              [-77.3981, 79.0573],
              [-78.5817, 79.075],
              [-77.8827, 78.9424],
              [-76.8248, 79.0179],
              [-76.2559, 79.0068],
              [-75.7951, 78.8898],
              [-75.0985, 78.8583],
              [-74.4331, 78.7241],
              [-74.5467, 78.6203],
              [-74.8786, 78.5448],
              [-76.3735, 78.5211],
              [-75.4884, 78.4035],
              [-75.1934, 78.3277],
              [-75.5507, 78.2211],
              [-75.866, 78.0098],
              [-76.974, 77.9272],
              [-78.0126, 77.9461],
              [-78.0842, 77.8461],
              [-78.0762, 77.5191],
              [-78.2837, 77.4131],
              [-78.8696, 77.3325],
              [-79.9063, 77.2995],
              [-80.573, 77.3148],
              [-80.6726, 77.2443],
              [-80.2742, 77.1509],
              [-79.9238, 77.1936],
              [-79.3408, 77.1584],
              [-79.2207, 76.9361],
              [-78.7918, 76.8836],
              [-78.2888, 76.978],
              [-77.9987, 76.852],
              [-77.9833, 76.755],
              [-78.2843, 76.5712],
              [-79.511, 76.3105],
              [-80.6902, 76.1765],
              [-80.9967, 76.215],
              [-80.8348, 76.3692],
              [-81.1707, 76.5127],
              [-81.592, 76.4845],
              [-82.0342, 76.6294],
              [-82.357, 76.636],
              [-82.2332, 76.4658],
              [-83.389, 76.4393],
              [-83.8857, 76.4531],
              [-84.2238, 76.6753],
              [-84.2753, 76.3565],
              [-85.1413, 76.3046],
              [-85.6806, 76.3491],
              [-86.2962, 76.4919],
              [-86.5619, 76.5165],
              [-86.6802, 76.3766],
              [-87.3542, 76.4481],
              [-87.4976, 76.3863],
              [-88.396, 76.4053],
              [-88.8037, 76.4568],
              [-89.5701, 76.4919],
              [-89.4997, 76.8268],
              [-88.3982, 77.1039],
              [-87.3617, 77.1362],
              [-86.8738, 77.2003],
              [-87.1008, 77.3077],
              [-87.4297, 77.3478],
              [-87.7802, 77.4928],
              [-88.0947, 77.7192],
              [-88.017, 77.7847],
              [-87.4968, 77.8719],
              [-87.0179, 77.8922],
              [-86.173, 77.7462],
              [-85.5885, 77.4611],
              [-84.7387, 77.361],
              [-83.9736, 77.3905],
              [-83.6081, 77.4422],
              [-83.7794, 77.5326],
              [-84.4858, 77.562],
              [-84.8606, 77.4995],
              [-85.2893, 77.559],
              [-85.2921, 77.7639],
              [-85.5475, 77.9277],
              [-84.6154, 78.1957],
              [-84.9103, 78.2397],
              [-85.586, 78.1095],
              [-86.2178, 78.0812],
              [-86.4271, 78.1971],
              [-86.9132, 78.1268],
              [-87.5517, 78.1766],
              [-87.4913, 78.4172],
              [-86.9529, 78.6639],
              [-86.8079, 78.7744],
              [-85.691, 78.8437],
              [-85.2296, 78.902],
              [-83.9079, 78.8391],
              [-83.2714, 78.7703],
              [-82.9898, 78.8441],
              [-83.0585, 78.9395],
              [-84.3161, 78.9753],
              [-84.5303, 79.1013],
              [-84.1973, 79.2251],
              [-85.0898, 79.6121],
              [-85.457, 79.6899],
              [-86.0315, 79.7219],
              [-86.4207, 79.8452],
              [-86.6145, 80.1235],
              [-86.3072, 80.3194],
              [-85.1596, 80.2718],
              [-84.0565, 80.2619],
              [-83.7236, 80.2289],
              [-82.377, 79.9082],
              [-81.8558, 79.7226],
              [-81.0102, 79.6931],
              [-82.3324, 80.0664],
              [-82.9611, 80.2779],
              [-82.5362, 80.3756],
              [-80.9797, 80.4453],
              [-80.0511, 80.5286],
              [-79.6293, 80.6479],
              [-78.3861, 80.7844],
              [-76.8503, 80.8781],
              [-78.5509, 80.9214],
              [-78.6293, 81.0435],
              [-79.1984, 81.1176],
              [-79.6067, 80.8818],
              [-80.1336, 80.7639],
              [-81.007, 80.6549],
              [-82.3682, 80.5613],
              [-82.8843, 80.5776],
              [-82.3368, 80.7287],
              [-83.4015, 80.714],
              [-84.0762, 80.5563],
              [-85.3074, 80.526],
              [-85.7263, 80.5811],
              [-86.2504, 80.5658],
              [-86.6031, 80.664],
              [-86.2521, 80.7895],
              [-85.2463, 80.9879],
              [-84.6799, 81.0424],
              [-83.3492, 81.1033],
              [-83.2888, 81.1479],
              [-85.7809, 81.0351],
              [-87.3299, 80.6698],
              [-87.7116, 80.6563],
              [-88.9215, 80.8056],
              [-89.2632, 80.9143],
              [-88.4131, 80.9998],
              [-87.3886, 80.9884],
              [-86.4767, 81.0357],
              [-85.0833, 81.2469],
              [-85.8751, 81.2412],
              [-86.6227, 81.1227],
              [-87.2751, 81.0808],
              [-89.623, 81.0325],
              [-89.9472, 81.1727],
              [-89.2088, 81.2501],
              [-89.6357, 81.3021],
              [-89.4271, 81.3874],
              [-88.6219, 81.5014],
              [-88.9784, 81.5415],
              [-90.3036, 81.4012],
              [-90.5538, 81.4642],
              [-89.8452, 81.6117],
              [-90.6264, 81.656],
              [-91.4027, 81.5782],
              [-91.6475, 81.6838],
              [-91.2195, 81.7877],
              [-90.4902, 81.8772],
              [-89.381, 81.9167],
              [-88.5668, 82.0611],
              [-87.639, 82.0851],
              [-87.2182, 82.0001],
              [-86.6269, 82.051],
              [-85.6457, 81.9533],
              [-85.3106, 82.044],
              [-86.5806, 82.1872],
              [-84.8968, 82.4494],
              [-83.5907, 82.3265],
              [-83.0102, 82.1417],
              [-82.5369, 82.2473],
              [-80.5499, 82.0046],
              [-80.1299, 82.0284],
              [-81.9976, 82.2783],
              [-82.4513, 82.4271],
              [-81.6811, 82.5186],
              [-82.1225, 82.6018],
              [-80.6571, 82.7691],
              [-80.0757, 82.7062],
              [-79.2072, 82.7327],
              [-80.1412, 82.8942],
              [-79.8863, 82.9385],
              [-78.525, 82.8911],
              [-77.4796, 82.8832],
              [-76.421, 82.6709],
              [-76.1465, 82.5499],
              [-75.5656, 82.6086],
              [-76.9084, 82.9194],
              [-77.125, 83.0086],
              [-75.745, 83.0472],
              [-73.3311, 82.9988],
              [-72.8117, 83.0812],
              [-71.9833, 83.1014],
              [-71.4235, 83.0211],
              [-70.8706, 83.0981],
              [-69.9699, 83.1161],
              [-69.4888, 83.0168],
            ],
          ],
          [
            [
              [-86.913, 70.1132],
              [-86.6127, 70.1057],
              [-86.7344, 69.9763],
              [-87.1908, 70.0185],
              [-87.3232, 70.1022],
              [-86.913, 70.1132],
            ],
          ],
          [
            [
              [-78.8266, 56.1453],
              [-78.8568, 56.232],
              [-78.7994, 56.3833],
              [-78.6572, 56.3175],
              [-78.8266, 56.1453],
            ],
          ],
          [
            [
              [-80.7316, 52.7473],
              [-81.0099, 52.7607],
              [-81.8391, 52.958],
              [-82.0393, 53.0499],
              [-81.8473, 53.1863],
              [-81.3354, 53.2242],
              [-81.1356, 53.2058],
              [-80.7654, 52.9232],
              [-80.7316, 52.7473],
            ],
          ],
          [
            [
              [-104.12, 75.0364],
              [-104.6343, 75.0613],
              [-104.8874, 75.1478],
              [-104.6488, 75.3498],
              [-104.3462, 75.43],
              [-103.8041, 75.3455],
              [-103.6422, 75.1629],
              [-104.12, 75.0364],
            ],
          ],
          [
            [
              [-78.9356, 56.2661],
              [-79.0839, 56.0679],
              [-79.1755, 55.8851],
              [-79.2736, 55.9225],
              [-79.1423, 56.1364],
              [-79.2218, 56.1759],
              [-79.4074, 55.9349],
              [-79.7647, 55.8068],
              [-79.4974, 56.0932],
              [-79.5646, 56.1209],
              [-79.9046, 55.871],
              [-80.0008, 55.932],
              [-79.5963, 56.2445],
              [-79.3926, 56.2765],
              [-79.2724, 56.6004],
              [-78.9632, 56.4217],
              [-78.9356, 56.2661],
            ],
          ],
          [
            [
              [-95.3067, 74.5054],
              [-95.7772, 74.5507],
              [-95.5102, 74.6368],
              [-95.3067, 74.5054],
            ],
          ],
          [
            [
              [-73.6218, 67.7838],
              [-74.3741, 67.7896],
              [-74.5733, 67.8287],
              [-74.746, 67.9848],
              [-74.7066, 68.0671],
              [-74.3794, 68.0935],
              [-73.4353, 67.97],
              [-73.4072, 67.7931],
              [-73.6218, 67.7838],
            ],
          ],
          [
            [
              [-64.8327, 61.3661],
              [-65.3939, 61.5629],
              [-65.4321, 61.6495],
              [-65.1297, 61.6857],
              [-64.7896, 61.6622],
              [-64.6696, 61.593],
              [-64.6964, 61.4715],
              [-64.8327, 61.3661],
            ],
          ],
          [
            [
              [-96.2046, 78.5313],
              [-95.5611, 78.5166],
              [-95.0312, 78.4302],
              [-94.8872, 78.3452],
              [-95.3293, 78.225],
              [-94.936, 78.1064],
              [-95.087, 77.9926],
              [-96.0115, 77.8874],
              [-96.9896, 77.806],
              [-97.0933, 77.9335],
              [-97.4267, 77.9823],
              [-97.6582, 78.0906],
              [-97.0409, 78.1169],
              [-97.3231, 78.2033],
              [-97.8191, 78.2306],
              [-98.2549, 78.4293],
              [-98.0604, 78.5583],
              [-98.2899, 78.6924],
              [-98.2121, 78.8045],
              [-97.3823, 78.7829],
              [-96.587, 78.6871],
              [-96.2046, 78.5313],
            ],
          ],
          [
            [
              [-82.0004, 62.9542],
              [-81.9902, 62.7763],
              [-82.1137, 62.6522],
              [-82.5683, 62.4032],
              [-83.0158, 62.2099],
              [-83.3768, 62.2382],
              [-83.6989, 62.1603],
              [-83.8993, 62.4764],
              [-83.7391, 62.5688],
              [-83.3764, 62.905],
              [-82.9657, 62.8739],
              [-82.7064, 62.9445],
              [-82.4597, 62.9361],
              [-82.2347, 62.9774],
              [-82.0004, 62.9542],
            ],
          ],
          [
            [
              [-74.8809, 68.3487],
              [-75.4003, 68.5254],
              [-75.2874, 68.6878],
              [-74.9836, 68.6476],
              [-74.7983, 68.4579],
              [-74.8809, 68.3487],
            ],
          ],
        ],
      },
      properties: {name: 'Nunavut', id: 'CA-NU', CNTRY: 'Canada', TYPE: 'Territory'},
      id: 'CA-NU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-79.5205, 50.9659],
              [-79.7145, 51.1176],
              [-79.6888, 51.3466],
              [-79.5214, 51.5447],
              [-79.5205, 50.9659],
            ],
          ],
          [
            [
              [-79.5196, 50.9186],
              [-79.5184, 50.2584],
              [-79.5165, 49.2909],
              [-79.5145, 48.3235],
              [-79.5133, 47.5511],
              [-79.5467, 47.4074],
              [-79.4419, 47.2277],
              [-79.3649, 47.022],
              [-79.1656, 46.8272],
              [-79.0146, 46.6065],
              [-78.781, 46.3934],
              [-78.4604, 46.3069],
              [-77.6827, 46.1867],
              [-77.3714, 46.0629],
              [-77.2332, 45.9035],
              [-77.0308, 45.8075],
              [-76.8238, 45.8797],
              [-76.6666, 45.6426],
              [-76.5532, 45.5421],
              [-76.2699, 45.4839],
              [-76.1226, 45.5049],
              [-75.904, 45.4202],
              [-75.6261, 45.4508],
              [-74.8642, 45.6421],
              [-74.5773, 45.6322],
              [-74.3965, 45.5677],
              [-74.478, 45.3179],
              [-74.3404, 45.2144],
              [-74.7089, 45.0038],
              [-74.8567, 45.0039],
              [-75.1794, 44.8994],
              [-75.4013, 44.7724],
              [-75.792, 44.4971],
              [-76.0573, 44.3666],
              [-76.4905, 44.2437],
              [-76.787, 44.1822],
              [-77.0402, 44.0486],
              [-77.0545, 43.883],
              [-77.1512, 43.8664],
              [-77.3245, 43.9716],
              [-77.5029, 43.9625],
              [-77.5835, 44.0384],
              [-78.1863, 43.9655],
              [-78.5279, 43.9048],
              [-78.6694, 43.9025],
              [-79.0607, 43.8352],
              [-79.3028, 43.6897],
              [-79.5691, 43.5782],
              [-79.7742, 43.3198],
              [-79.7278, 43.2528],
              [-79.3376, 43.1977],
              [-79.0614, 43.2829],
              [-79.0397, 43.0213],
              [-78.9365, 42.9072],
              [-79.057, 42.8623],
              [-79.3859, 42.8806],
              [-79.7466, 42.8584],
              [-80.2204, 42.7743],
              [-80.4512, 42.6134],
              [-80.6166, 42.5959],
              [-80.8272, 42.6432],
              [-81.2525, 42.6538],
              [-81.495, 42.5911],
              [-81.7299, 42.4466],
              [-81.8867, 42.2737],
              [-82.0443, 42.2572],
              [-82.3114, 42.1582],
              [-82.4618, 42.0576],
              [-82.8338, 41.9972],
              [-83.1079, 42.0709],
              [-83.0731, 42.3004],
              [-82.8678, 42.3852],
              [-82.5453, 42.6247],
              [-82.4884, 42.7395],
              [-82.4172, 43.0174],
              [-82.181, 43.0851],
              [-81.7845, 43.3269],
              [-81.7128, 43.474],
              [-81.732, 44.0604],
              [-81.595, 44.3109],
              [-81.4313, 44.4365],
              [-81.2976, 44.5966],
              [-81.2782, 44.7899],
              [-81.4154, 44.9873],
              [-81.5903, 45.1658],
              [-81.5954, 45.2619],
              [-81.2965, 45.2487],
              [-81.2595, 45.0284],
              [-81.0064, 44.8762],
              [-81.022, 44.8022],
              [-80.7898, 44.7002],
              [-80.6495, 44.7213],
              [-80.5929, 44.6385],
              [-80.4603, 44.5778],
              [-80.0848, 44.4893],
              [-79.9974, 44.6524],
              [-80.1082, 44.8068],
              [-79.9968, 44.8557],
              [-79.8269, 44.768],
              [-79.7716, 44.8193],
              [-79.8283, 44.9363],
              [-80.086, 45.1094],
              [-80.0916, 45.3933],
              [-80.2707, 45.3602],
              [-80.3849, 45.4584],
              [-80.3885, 45.5736],
              [-80.5107, 45.5898],
              [-80.6561, 45.7903],
              [-80.8178, 45.951],
              [-81.097, 45.9416],
              [-81.2368, 45.9741],
              [-81.595, 45.9964],
              [-81.6153, 46.1195],
              [-81.7693, 46.1044],
              [-82.196, 46.1339],
              [-82.3501, 46.1932],
              [-82.6636, 46.1759],
              [-82.7191, 46.2072],
              [-83.0255, 46.1812],
              [-83.4591, 46.2377],
              [-83.8818, 46.333],
              [-84.0919, 46.3609],
              [-84.0871, 46.5069],
              [-84.1653, 46.5626],
              [-84.4324, 46.5221],
              [-84.5686, 46.5767],
              [-84.4381, 46.723],
              [-84.5535, 46.8055],
              [-84.4078, 46.8437],
              [-84.488, 46.9441],
              [-84.6249, 46.9223],
              [-84.7717, 47.0367],
              [-84.5943, 47.3203],
              [-84.7157, 47.4267],
              [-84.9358, 47.5399],
              [-85.0143, 47.6388],
              [-84.8548, 47.9499],
              [-84.9636, 47.9603],
              [-85.6146, 47.9407],
              [-85.9136, 48.0294],
              [-86.1042, 48.2103],
              [-86.257, 48.5485],
              [-86.3961, 48.7313],
              [-86.6429, 48.7946],
              [-86.8199, 48.7726],
              [-87.2236, 48.7758],
              [-87.4071, 48.8348],
              [-87.9657, 48.9587],
              [-88.0226, 48.9987],
              [-88.2636, 48.9737],
              [-88.2243, 48.8672],
              [-88.1088, 48.776],
              [-88.2331, 48.597],
              [-88.5666, 48.528],
              [-88.3336, 48.7091],
              [-88.3953, 48.8448],
              [-88.5026, 48.8331],
              [-88.5653, 48.6398],
              [-88.7057, 48.4761],
              [-88.7345, 48.384],
              [-88.8885, 48.3373],
              [-88.7745, 48.5831],
              [-89.2007, 48.4508],
              [-89.2139, 48.3449],
              [-89.3455, 48.1211],
              [-89.5775, 48.0018],
              [-89.9936, 48.0153],
              [-90.0918, 48.1181],
              [-90.3201, 48.0992],
              [-90.7444, 48.1046],
              [-90.8403, 48.2006],
              [-91.0435, 48.1937],
              [-91.2207, 48.1046],
              [-91.5183, 48.0583],
              [-91.8584, 48.1975],
              [-92.0052, 48.3018],
              [-92.1718, 48.3384],
              [-92.4145, 48.2766],
              [-92.5006, 48.4353],
              [-92.9963, 48.6118],
              [-93.3779, 48.6166],
              [-93.4636, 48.5613],
              [-93.7077, 48.5254],
              [-93.8516, 48.6072],
              [-94.0552, 48.659],
              [-94.6208, 48.7427],
              [-94.705, 48.8085],
              [-94.8426, 49.1191],
              [-94.8543, 49.3045],
              [-94.9394, 49.3494],
              [-95.1553, 49.3697],
              [-95.1594, 50.2338],
              [-95.1566, 51.5281],
              [-95.1539, 52.8224],
              [-94.7704, 53.0538],
              [-93.6203, 53.748],
              [-92.6002, 54.4807],
              [-91.9894, 54.8988],
              [-91.3785, 55.317],
              [-90.6795, 55.7666],
              [-89.9806, 56.2161],
              [-88.9485, 56.8512],
              [-88.8265, 56.8143],
              [-88.4471, 56.6088],
              [-88.0751, 56.4674],
              [-87.8781, 56.3416],
              [-87.5609, 56.0563],
              [-87.2869, 55.9746],
              [-86.9193, 55.9145],
              [-86.377, 55.7732],
              [-85.9845, 55.6959],
              [-85.6767, 55.6011],
              [-85.4073, 55.4311],
              [-85.061, 55.2857],
              [-84.5179, 55.2589],
              [-83.9106, 55.3146],
              [-83.6677, 55.2645],
              [-83.2143, 55.2146],
              [-82.9863, 55.2315],
              [-82.8678, 55.1607],
              [-82.5775, 55.1487],
              [-82.3083, 54.9981],
              [-82.2194, 54.8135],
              [-82.4181, 54.3558],
              [-82.3941, 54.1804],
              [-82.2399, 54.0448],
              [-82.1415, 53.8177],
              [-82.1906, 53.6109],
              [-82.1591, 53.2642],
              [-82.2916, 53.0661],
              [-82.2605, 52.9611],
              [-82.02, 52.8117],
              [-81.5994, 52.4327],
              [-81.6612, 52.2939],
              [-81.285, 52.0892],
              [-80.9685, 51.9723],
              [-80.658, 51.7583],
              [-80.368, 51.3299],
              [-80.1035, 51.2828],
              [-79.8362, 51.1734],
              [-79.5196, 50.9186],
            ],
          ],
          [
            [
              [-88.0309, 48.7175],
              [-88.0866, 48.808],
              [-87.9962, 48.8573],
              [-87.8134, 48.859],
              [-87.8163, 48.7679],
              [-88.0309, 48.7175],
            ],
          ],
          [
            [
              [-82.821, 45.9766],
              [-82.7368, 45.8523],
              [-82.332, 45.982],
              [-82.1754, 45.8712],
              [-81.9874, 45.9771],
              [-81.7631, 45.8212],
              [-81.593, 45.7917],
              [-81.7548, 45.5911],
              [-81.8812, 45.5356],
              [-82.0291, 45.566],
              [-82.3232, 45.6836],
              [-82.7357, 45.7968],
              [-82.9366, 45.811],
              [-83.1601, 45.8752],
              [-83.1245, 45.938],
              [-82.821, 45.9766],
            ],
          ],
          [
            [
              [-83.8083, 46.1666],
              [-83.9786, 46.1094],
              [-84.0783, 46.2365],
              [-84.0612, 46.3098],
              [-83.8222, 46.2535],
              [-83.8083, 46.1666],
            ],
          ],
        ],
      },
      properties: {name: 'Ontario', id: 'CA-ON', CNTRY: 'Canada', TYPE: 'Province'},
      id: 'CA-ON',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-63.8113, 46.4687],
            [-63.6814, 46.562],
            [-63.3687, 46.5083],
            [-63.1293, 46.4222],
            [-62.1636, 46.4872],
            [-62.0237, 46.4215],
            [-62.552, 46.1659],
            [-62.5314, 45.9773],
            [-62.7432, 45.9668],
            [-62.8783, 46.0014],
            [-62.8946, 46.1236],
            [-63.117, 46.2528],
            [-63.2766, 46.1532],
            [-63.641, 46.2305],
            [-63.8605, 46.4081],
            [-64.1109, 46.4255],
            [-64.1066, 46.5622],
            [-64.2356, 46.6315],
            [-64.3881, 46.6408],
            [-64.3547, 46.7692],
            [-64.2233, 46.9012],
            [-63.9936, 47.0617],
            [-63.9815, 46.9131],
            [-64.0879, 46.7755],
            [-63.903, 46.6391],
            [-63.9055, 46.5088],
            [-63.8113, 46.4687],
          ],
        ],
      },
      properties: {name: 'Prince Edward Island', id: 'CA-PE', CNTRY: 'Canada', TYPE: 'Province'},
      id: 'CA-PE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-61.8012, 49.0939],
              [-62.2195, 49.0792],
              [-62.7996, 49.1707],
              [-63.0415, 49.2249],
              [-63.5658, 49.3993],
              [-63.6762, 49.5343],
              [-63.8849, 49.6576],
              [-64.4401, 49.8278],
              [-64.373, 49.9259],
              [-64.1314, 49.9417],
              [-63.7602, 49.8753],
              [-63.292, 49.8169],
              [-62.8585, 49.7055],
              [-61.8172, 49.2835],
              [-61.6962, 49.139],
              [-61.8012, 49.0939],
            ],
          ],
          [
            [
              [-73.5665, 45.4691],
              [-73.7754, 45.4676],
              [-73.5224, 45.7011],
              [-73.5665, 45.4691],
            ],
          ],
          [
            [
              [-64.6142, 60.3061],
              [-64.8159, 60.2675],
              [-64.6499, 60.1101],
              [-64.8804, 60.0496],
              [-64.6656, 59.9055],
              [-64.7908, 59.8155],
              [-64.8181, 59.5402],
              [-64.7146, 59.4663],
              [-64.4448, 59.5279],
              [-64.5195, 59.2258],
              [-64.3935, 58.9985],
              [-64.7702, 59.0487],
              [-64.8086, 58.9293],
              [-64.2941, 58.866],
              [-64.1553, 58.7706],
              [-63.7272, 58.8617],
              [-63.5918, 58.8299],
              [-63.5532, 58.7316],
              [-63.9605, 58.6842],
              [-64.0709, 58.6416],
              [-64.0387, 58.5448],
              [-63.8824, 58.5552],
              [-63.848, 58.4597],
              [-64.0978, 58.3591],
              [-64.3714, 58.1693],
              [-64.387, 58.0989],
              [-64.2266, 58.0175],
              [-64.0455, 57.7853],
              [-63.6146, 57.6694],
              [-63.7437, 57.5809],
              [-63.7579, 57.2276],
              [-63.8763, 57.023],
              [-63.9033, 56.8894],
              [-64.1118, 56.6909],
              [-63.955, 56.5269],
              [-63.955, 56.4389],
              [-64.1361, 56.3946],
              [-64.1215, 56.2995],
              [-63.9128, 56.229],
              [-64.0042, 56.0974],
              [-63.5783, 56.0256],
              [-63.7006, 55.8609],
              [-63.4152, 55.596],
              [-63.1544, 55.5136],
              [-63.1614, 55.3075],
              [-63.5109, 55.1676],
              [-63.5511, 55.0863],
              [-63.4915, 54.9177],
              [-63.658, 54.6444],
              [-63.7513, 54.6122],
              [-64.341, 54.7263],
              [-64.8152, 54.7338],
              [-65.1218, 54.7191],
              [-65.6758, 54.7448],
              [-65.8837, 54.9072],
              [-66.2398, 55.0084],
              [-66.7334, 55.3189],
              [-66.7474, 55.1292],
              [-66.6508, 54.7986],
              [-66.8671, 54.781],
              [-67.1667, 54.9374],
              [-67.3017, 55.0637],
              [-67.4371, 55.0363],
              [-67.1502, 54.6167],
              [-67.3146, 54.5174],
              [-67.5365, 54.5727],
              [-67.6201, 54.5065],
              [-67.5113, 54.2361],
              [-67.681, 54.1413],
              [-67.7573, 54.0336],
              [-67.471, 53.7964],
              [-67.4571, 53.6012],
              [-67.1649, 53.5431],
              [-67.0761, 53.4175],
              [-66.9379, 53.3289],
              [-66.9703, 53.073],
              [-67.0537, 52.92],
              [-67.068, 52.7549],
              [-66.8148, 52.6865],
              [-66.6884, 52.7269],
              [-66.6314, 52.9102],
              [-66.3899, 52.9919],
              [-66.3003, 52.8968],
              [-66.4261, 52.6609],
              [-66.3943, 52.4178],
              [-66.4634, 52.3413],
              [-66.2796, 52.3007],
              [-66.097, 52.2096],
              [-66.0358, 52.1092],
              [-65.8829, 52.0542],
              [-65.7805, 52.1039],
              [-65.5516, 52.094],
              [-65.4743, 52.1963],
              [-65.1371, 52.1547],
              [-64.9321, 52.055],
              [-64.6268, 51.8253],
              [-64.6975, 51.7204],
              [-64.598, 51.6004],
              [-64.5064, 51.6089],
              [-64.2905, 51.7288],
              [-64.3346, 51.8419],
              [-64.2597, 52.0995],
              [-64.1307, 52.1867],
              [-64.1032, 52.3784],
              [-64.1835, 52.5541],
              [-64.1785, 52.8154],
              [-63.9734, 52.8951],
              [-63.9175, 53.0656],
              [-63.6716, 53.1008],
              [-63.5487, 52.9397],
              [-63.5503, 52.7741],
              [-63.4097, 52.6888],
              [-63.8107, 52.6112],
              [-64.0176, 52.5255],
              [-63.8839, 52.3449],
              [-63.7656, 52.2975],
              [-63.6066, 52.0008],
              [-62.3868, 52.0008],
              [-61.1669, 52.0009],
              [-59.9472, 52.0009],
              [-58.3208, 52.0011],
              [-57.101, 52.0011],
              [-57.1001, 51.4433],
              [-57.2993, 51.4783],
              [-57.7695, 51.4259],
              [-58.0227, 51.3221],
              [-58.5104, 51.295],
              [-58.6377, 51.1716],
              [-59.055, 50.8791],
              [-59.1653, 50.7799],
              [-59.3781, 50.6754],
              [-59.6119, 50.4922],
              [-59.8154, 50.4183],
              [-59.8863, 50.3164],
              [-60.0802, 50.2546],
              [-60.6082, 50.2211],
              [-60.8072, 50.2498],
              [-60.9563, 50.2055],
              [-61.2897, 50.202],
              [-61.7248, 50.1041],
              [-61.9195, 50.2328],
              [-62.1652, 50.2389],
              [-62.3617, 50.2772],
              [-62.7155, 50.3016],
              [-63.1356, 50.2938],
              [-63.2386, 50.2426],
              [-63.5866, 50.2582],
              [-63.7336, 50.3046],
              [-64.0159, 50.3039],
              [-64.1704, 50.2694],
              [-64.5089, 50.3089],
              [-64.8679, 50.2755],
              [-65.2686, 50.3201],
              [-65.7624, 50.2593],
              [-65.9554, 50.2941],
              [-66.1255, 50.201],
              [-66.4956, 50.2119],
              [-66.7409, 50.0656],
              [-66.9411, 49.9937],
              [-67.2344, 49.6017],
              [-67.2618, 49.4512],
              [-67.372, 49.3485],
              [-68.0562, 49.2568],
              [-68.2819, 49.1971],
              [-68.4144, 49.0995],
              [-68.6279, 49.0072],
              [-68.6691, 48.9395],
              [-68.929, 48.8289],
              [-69.2308, 48.5737],
              [-69.375, 48.3864],
              [-69.5502, 48.2508],
              [-69.6739, 48.1992],
              [-69.8517, 48.2074],
              [-70.3837, 48.3665],
              [-71.0182, 48.4556],
              [-70.8388, 48.3674],
              [-70.5006, 48.3544],
              [-69.8655, 48.1723],
              [-69.775, 48.0982],
              [-69.9055, 47.8322],
              [-69.9944, 47.7399],
              [-70.3001, 47.5031],
              [-70.448, 47.4234],
              [-70.7059, 47.1398],
              [-71.1157, 46.9249],
              [-71.2678, 46.796],
              [-71.7572, 46.6736],
              [-71.8797, 46.6868],
              [-72.2046, 46.5588],
              [-72.2567, 46.4851],
              [-72.6801, 46.2873],
              [-72.981, 46.2098],
              [-73.022, 46.1202],
              [-73.1454, 46.0663],
              [-73.2835, 45.8998],
              [-73.4766, 45.7383],
              [-73.7119, 45.7112],
              [-73.8974, 45.5642],
              [-74.0378, 45.5019],
              [-73.9739, 45.3451],
              [-74.0982, 45.324],
              [-74.3404, 45.2144],
              [-74.478, 45.3179],
              [-74.3965, 45.5677],
              [-74.5773, 45.6322],
              [-74.8642, 45.6421],
              [-75.6261, 45.4508],
              [-75.904, 45.4202],
              [-76.1226, 45.5049],
              [-76.2699, 45.4839],
              [-76.5532, 45.5421],
              [-76.6666, 45.6426],
              [-76.8238, 45.8797],
              [-77.0308, 45.8075],
              [-77.2332, 45.9035],
              [-77.3714, 46.0629],
              [-77.6827, 46.1867],
              [-78.4604, 46.3069],
              [-78.781, 46.3934],
              [-79.0146, 46.6065],
              [-79.1656, 46.8272],
              [-79.3649, 47.022],
              [-79.4419, 47.2277],
              [-79.5467, 47.4074],
              [-79.5133, 47.5511],
              [-79.5145, 48.3235],
              [-79.5165, 49.2909],
              [-79.5184, 50.2584],
              [-79.5196, 50.9186],
              [-79.5205, 50.9659],
              [-79.5214, 51.5447],
              [-79.3387, 51.6282],
              [-79.2643, 51.552],
              [-79.0405, 51.4638],
              [-78.8975, 51.2716],
              [-78.8274, 51.43],
              [-78.7313, 51.4976],
              [-78.9778, 51.7337],
              [-78.8282, 51.963],
              [-78.7019, 52.0326],
              [-78.4917, 52.2522],
              [-78.557, 52.4919],
              [-78.7238, 52.6278],
              [-78.7658, 52.76],
              [-78.7218, 52.8565],
              [-78.8982, 53.0434],
              [-78.992, 53.4104],
              [-79.1132, 53.7172],
              [-79.0031, 53.8365],
              [-79.0751, 53.9324],
              [-78.9961, 54.0026],
              [-79.2418, 54.0989],
              [-79.2956, 54.2168],
              [-79.4305, 54.3367],
              [-79.6655, 54.6975],
              [-78.9092, 54.8815],
              [-78.3036, 55.0685],
              [-77.8911, 55.2364],
              [-77.325, 55.5556],
              [-76.7618, 55.9964],
              [-76.6504, 56.1072],
              [-76.5464, 56.3589],
              [-76.5196, 56.707],
              [-76.5729, 57.1812],
              [-76.6554, 57.3806],
              [-76.891, 57.7581],
              [-77.1568, 58.0189],
              [-77.6841, 58.2914],
              [-78.0136, 58.3991],
              [-78.5151, 58.6824],
              [-78.4305, 58.9018],
              [-77.9876, 59.2456],
              [-77.7607, 59.3801],
              [-77.859, 59.4759],
              [-77.7335, 59.581],
              [-77.7262, 59.6759],
              [-77.4853, 59.6846],
              [-77.3316, 59.7967],
              [-77.373, 59.9251],
              [-77.3118, 60.0423],
              [-77.5472, 60.0612],
              [-77.4529, 60.1458],
              [-77.6814, 60.4271],
              [-77.5156, 60.5632],
              [-77.7908, 60.6398],
              [-77.6606, 60.7895],
              [-77.8715, 60.7858],
              [-78.1596, 60.8523],
              [-77.8301, 61.0841],
              [-77.7268, 61.2307],
              [-77.7362, 61.4374],
              [-77.5143, 61.5563],
              [-77.9475, 61.7619],
              [-78.0775, 61.9234],
              [-78.1371, 62.1073],
              [-78.1334, 62.2823],
              [-78.0681, 62.3554],
              [-77.604, 62.5313],
              [-77.3725, 62.5725],
              [-76.8794, 62.5253],
              [-75.8169, 62.3158],
              [-75.6756, 62.2495],
              [-75.3412, 62.3121],
              [-74.6899, 62.1835],
              [-74.2055, 62.3214],
              [-73.7639, 62.4688],
              [-73.63, 62.4542],
              [-73.1952, 62.2791],
              [-72.8819, 62.1254],
              [-72.6709, 62.1139],
              [-72.5056, 61.9227],
              [-72.2261, 61.8315],
              [-72.0814, 61.7282],
              [-71.6383, 61.6172],
              [-71.841, 61.466],
              [-71.7435, 61.3373],
              [-71.4227, 61.1589],
              [-71.1752, 61.1465],
              [-70.7232, 61.0552],
              [-70.2793, 61.0687],
              [-70.1872, 61.0405],
              [-70.0953, 60.8803],
              [-69.9092, 60.8601],
              [-69.7085, 60.9146],
              [-69.6237, 61.0496],
              [-69.4719, 61.011],
              [-69.4335, 60.8142],
              [-69.6405, 60.6897],
              [-69.7595, 60.4402],
              [-69.6331, 60.2203],
              [-69.6737, 60.0759],
              [-69.9628, 60.0178],
              [-69.6302, 59.8218],
              [-69.5795, 59.6751],
              [-69.6924, 59.4884],
              [-69.6819, 59.3418],
              [-69.4001, 59.3378],
              [-69.4505, 59.1801],
              [-69.4141, 59.0868],
              [-69.5316, 58.8692],
              [-69.6774, 58.8314],
              [-69.8676, 58.8561],
              [-70.16, 58.7894],
              [-69.8786, 58.697],
              [-69.6506, 58.7282],
              [-69.3819, 58.8507],
              [-69.1735, 58.8966],
              [-68.6982, 58.9045],
              [-68.4749, 58.8235],
              [-68.3265, 58.5954],
              [-68.1111, 58.4733],
              [-67.9812, 58.4612],
              [-67.8559, 58.2726],
              [-67.5696, 58.2135],
              [-67.0195, 58.4329],
              [-66.7222, 58.491],
              [-66.6079, 58.5489],
              [-66.5151, 58.6973],
              [-66.3625, 58.7912],
              [-66.1682, 58.7271],
              [-66.0447, 58.6057],
              [-65.9279, 58.6109],
              [-66.0431, 58.8207],
              [-65.6062, 59.1108],
              [-65.6071, 59.2131],
              [-65.4074, 59.5394],
              [-65.4752, 59.6168],
              [-65.4334, 59.7765],
              [-65.1593, 59.8301],
              [-65.1718, 59.908],
              [-64.9313, 60.2519],
              [-64.8173, 60.331],
              [-64.6142, 60.3061],
            ],
          ],
          [
            [
              [-66.7044, 48.0225],
              [-67.0576, 47.9185],
              [-67.3649, 47.8548],
              [-67.5791, 47.9396],
              [-67.6129, 47.9986],
              [-68.1135, 47.9986],
              [-68.1135, 47.9293],
              [-68.3779, 47.9293],
              [-68.3792, 47.589],
              [-68.496, 47.4803],
              [-68.6285, 47.4207],
              [-69.0536, 47.2946],
              [-69.0503, 47.4266],
              [-69.2429, 47.463],
              [-70.0077, 46.7089],
              [-70.0672, 46.441],
              [-70.2483, 46.2508],
              [-70.3046, 46.0575],
              [-70.2963, 45.9061],
              [-70.4211, 45.7383],
              [-70.7022, 45.5514],
              [-70.6898, 45.4284],
              [-70.7992, 45.4047],
              [-70.898, 45.2625],
              [-70.9999, 45.3372],
              [-71.1346, 45.2629],
              [-71.3272, 45.2901],
              [-71.419, 45.2004],
              [-71.5176, 45.0075],
              [-72.7659, 45.0061],
              [-74.0143, 45.0047],
              [-74.7089, 45.0038],
              [-74.5663, 45.0416],
              [-74.2691, 45.1884],
              [-74.0498, 45.2414],
              [-73.7647, 45.3955],
              [-73.5581, 45.4251],
              [-73.4652, 45.6323],
              [-73.253, 45.8637],
              [-73.1596, 46.01],
              [-72.9899, 46.1036],
              [-72.7334, 46.1819],
              [-72.4962, 46.3527],
              [-72.2401, 46.4421],
              [-72.1872, 46.5115],
              [-71.9009, 46.6319],
              [-71.6713, 46.6538],
              [-71.2611, 46.7563],
              [-70.5195, 47.0326],
              [-70.3881, 47.117],
              [-70.2177, 47.2899],
              [-70.0696, 47.3778],
              [-70.0172, 47.4715],
              [-69.8022, 47.6234],
              [-69.5811, 47.8237],
              [-69.4711, 47.9672],
              [-69.3064, 48.047],
              [-68.9871, 48.275],
              [-68.8157, 48.3661],
              [-68.552, 48.4572],
              [-68.2382, 48.6264],
              [-67.889, 48.7309],
              [-67.5609, 48.8559],
              [-67.1175, 48.9641],
              [-66.5981, 49.1263],
              [-66.1781, 49.2132],
              [-65.3962, 49.2621],
              [-64.8364, 49.1917],
              [-64.5677, 49.1049],
              [-64.2618, 48.9219],
              [-64.2088, 48.8063],
              [-64.2538, 48.5504],
              [-64.3489, 48.4233],
              [-64.6332, 48.3605],
              [-64.7645, 48.228],
              [-65.2594, 48.0213],
              [-65.4759, 48.0315],
              [-65.7547, 48.1117],
              [-65.9267, 48.1888],
              [-66.0831, 48.1028],
              [-66.449, 48.1196],
              [-66.7044, 48.0225],
            ],
          ],
          [
            [
              [-61.9141, 47.2845],
              [-61.9247, 47.4252],
              [-61.6279, 47.5938],
              [-61.5822, 47.56],
              [-61.8866, 47.3446],
              [-61.9141, 47.2845],
            ],
          ],
        ],
      },
      properties: {name: 'Quebec', id: 'CA-QC', CNTRY: 'Canada', TYPE: 'Province'},
      id: 'CA-QC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3673, 48.9931],
            [-102.6464, 48.9931],
            [-104.3519, 48.9931],
            [-106.0575, 48.9931],
            [-107.3366, 48.9931],
            [-109.0421, 48.9931],
            [-109.9994, 48.9931],
            [-109.9995, 49.682],
            [-109.9995, 50.3698],
            [-109.9995, 51.0579],
            [-109.9996, 52.0897],
            [-109.9996, 53.1217],
            [-109.9996, 53.8096],
            [-109.9998, 54.8415],
            [-109.9998, 55.5295],
            [-109.9998, 56.2173],
            [-109.9999, 56.9054],
            [-109.9999, 57.5933],
            [-110, 58.6252],
            [-110, 59.3131],
            [-110, 60.001],
            [-108.5003, 60.001],
            [-107.5005, 60.001],
            [-105.5009, 60.001],
            [-104.0013, 60.001],
            [-102.0016, 60.001],
            [-102.0015, 58.6937],
            [-102.0014, 57.9092],
            [-102.0012, 56.8634],
            [-102.0011, 55.8175],
            [-101.9215, 54.9646],
            [-101.8419, 54.1117],
            [-101.7623, 53.2587],
            [-101.6828, 52.4058],
            [-101.6032, 51.5529],
            [-101.5236, 50.6999],
            [-101.444, 49.8471],
            [-101.3673, 48.9931],
          ],
        ],
      },
      properties: {name: 'Saskatchewan', id: 'CA-SK', CNTRY: 'Canada', TYPE: 'Province'},
      id: 'CA-SK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-136.4437, 68.8952],
            [-136.4454, 67.7465],
            [-136.1693, 67.5143],
            [-136.1052, 67.3041],
            [-136.218, 67.1812],
            [-136.126, 67.0047],
            [-134.0556, 67.0045],
            [-133.7941, 66.8186],
            [-133.764, 66.651],
            [-133.6352, 66.5657],
            [-133.7902, 66.3044],
            [-133.5832, 66.285],
            [-133.5687, 66.1767],
            [-133.6772, 66.0956],
            [-133.5889, 65.9626],
            [-132.938, 66.0286],
            [-132.8213, 65.928],
            [-132.6143, 66.0293],
            [-132.3408, 65.9553],
            [-132.5549, 65.857],
            [-132.2953, 65.7162],
            [-132.1947, 65.5977],
            [-132.3459, 65.4434],
            [-132.5969, 65.2802],
            [-132.5161, 65.0942],
            [-132.3538, 65.0385],
            [-132.497, 64.9574],
            [-132.5537, 64.7802],
            [-132.3207, 64.7627],
            [-132.0522, 64.6842],
            [-131.716, 64.515],
            [-131.7637, 64.3805],
            [-131.5859, 64.3803],
            [-131.3588, 64.4585],
            [-131.1067, 64.4009],
            [-130.9308, 64.2192],
            [-130.9413, 64.1405],
            [-130.6606, 63.9505],
            [-130.5129, 63.9201],
            [-130.1451, 63.7619],
            [-130.0631, 63.6356],
            [-129.845, 63.4858],
            [-130.1017, 63.3188],
            [-129.8275, 63.0918],
            [-129.6298, 63.0683],
            [-129.7186, 62.8316],
            [-129.4903, 62.5987],
            [-129.2111, 62.5134],
            [-129.2871, 62.4411],
            [-129.269, 62.1664],
            [-128.7755, 62.0689],
            [-128.5608, 62.1169],
            [-128.4001, 62.0338],
            [-128.2063, 61.8653],
            [-128.0939, 61.8413],
            [-127.9936, 61.7112],
            [-127.6087, 61.5329],
            [-127.2994, 61.5122],
            [-127.0725, 61.3696],
            [-127.0268, 61.1265],
            [-126.9442, 61.0525],
            [-126.8897, 60.7956],
            [-126.6652, 60.7623],
            [-126.5201, 60.8003],
            [-126.0032, 60.8088],
            [-125.8638, 60.89],
            [-125.3787, 60.7917],
            [-125.1516, 60.8443],
            [-124.8805, 60.8603],
            [-124.8065, 60.961],
            [-124.5826, 60.9528],
            [-124.505, 60.7885],
            [-124.5994, 60.6622],
            [-124.4029, 60.4941],
            [-124.2493, 60.4735],
            [-124.2045, 60.3529],
            [-123.9973, 60.1265],
            [-123.9937, 60.0405],
            [-123.8192, 60.001],
            [-125.7227, 60.001],
            [-127.6262, 60.001],
            [-129.5297, 60.001],
            [-130.4814, 60.001],
            [-132.3849, 60.001],
            [-133.3367, 60.001],
            [-135.2402, 60.001],
            [-136.192, 60.001],
            [-138.0955, 60.001],
            [-139.0565, 60.0016],
            [-139.1852, 60.0836],
            [-139.0792, 60.3438],
            [-139.6763, 60.3283],
            [-139.9733, 60.1832],
            [-140.4528, 60.2997],
            [-140.5254, 60.2184],
            [-141.0021, 60.3002],
            [-141.0021, 60.8846],
            [-141.0021, 61.7612],
            [-141.0021, 62.6378],
            [-141.0021, 63.5145],
            [-141.0021, 64.3911],
            [-141.0021, 65.56],
            [-141.0021, 66.4366],
            [-141.0021, 67.0209],
            [-141.0021, 67.8975],
            [-141.0021, 68.482],
            [-141.0021, 69.6508],
            [-140.4051, 69.6025],
            [-139.9766, 69.6218],
            [-139.1815, 69.5156],
            [-138.6899, 69.3169],
            [-138.1283, 69.1519],
            [-137.2599, 68.9641],
            [-136.7173, 68.8892],
            [-136.4437, 68.8952],
          ],
        ],
      },
      properties: {name: 'Yukon', id: 'CA-YT', CNTRY: 'Canada', TYPE: 'Territory'},
      id: 'CA-YT',
    },
  ],
}
export default map
