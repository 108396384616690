import React, { useEffect, useState } from 'react';
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {TAB_NAME, IReportDetails} from "../../../models/Reports";
import CommonPrintView, {ReportType} from "../../Common/CommonPrintView";
import {TikTokReportDetails} from "../../../models/TikTokReport";

export interface PDFTiktokPrint {
    dataMonth?: TikTokReportDetails;
    closePopup: () => void;
}
const TiktokPrintView: React.FC<PDFTiktokPrint> = ({ closePopup }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails;
    const [tiktokDataLoaded, setTiktokDataLoaded] = useState(false);

    return (
        <CommonPrintView
            reportType={ReportType.TikTok}
            reportData={reportData}
            setLoaded={setTiktokDataLoaded}
            closePopup={closePopup}
            >
        </CommonPrintView>
    );
};

export default TiktokPrintView;
