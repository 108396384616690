/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

const DOTS = '...'

interface PaginationProps {
  pages?: number
  currentPage: number
  setCurrentPage: (data: React.SetStateAction<number>) => void
}

const Pagination: React.FC<PaginationProps> = ({pages, currentPage, setCurrentPage}) => {
  const [numberOfPage, setNumberOfPage] = useState<string[]>([])
  const [arrOfCurButton, setArrOfCurButton] = useState<string[]>([])

  useEffect(() => {
    let numbers: string[] = []
    ;[...Array(pages)].map((item, index) => {
      numbers.push((index + 1).toString())
    })
    setNumberOfPage(numbers)
  }, [pages])

  useEffect(() => {
    let tempNumberOfPage: string[] = [...numberOfPage]
    if (tempNumberOfPage.length < 6) {
      setArrOfCurButton(tempNumberOfPage)
    } else {
      if (currentPage >= 1 && currentPage <= 2) {
        tempNumberOfPage = ['1', '2', '3', DOTS, numberOfPage.length.toString()]
      } else if (currentPage === 3) {
        const beginButtons = numberOfPage.slice(0, 4)
        tempNumberOfPage = [...beginButtons, DOTS, numberOfPage.length.toString()]
      } else if (currentPage > 3 && currentPage < numberOfPage.length - 2) {
        const prevButtons = numberOfPage.slice(currentPage - 2, currentPage)
        const nextButtons = numberOfPage.slice(currentPage, currentPage + 1)
        tempNumberOfPage = [
          '1',
          DOTS,
          ...prevButtons,
          ...nextButtons,
          DOTS,
          numberOfPage.length.toString(),
        ]
      } else if (currentPage > numberOfPage.length - 3) {
        const endButtons = numberOfPage.slice(numberOfPage.length - 4)
        tempNumberOfPage = ['1', DOTS, ...endButtons]
      }
      setArrOfCurButton(tempNumberOfPage)
    }
  }, [currentPage, numberOfPage])

  return (
    <ul className='pagination-custom pagination'>
      <li
        className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={() => setCurrentPage((prev) => (prev === 1 ? prev : prev - 1))}
      >
        <button className='page-link'>
          <i className='previous'></i>
        </button>
      </li>

      {arrOfCurButton.map((item, index) => {
        return (
          <li
            key={index}
            className={
              isNaN(Number(item))
                ? 'page-item disabled'
                : Number(item) === currentPage
                ? 'page-item active'
                : 'page-item'
            }
          >
            <button
              disabled={isNaN(Number(item))}
              onClick={() => setCurrentPage(Number(item))}
              className='page-link'
            >
              {item}
            </button>
          </li>
        )
      })}

      <li
        className={`page-item next ${currentPage === numberOfPage.length ? 'disabled' : ''}`}
        onClick={() => setCurrentPage((prev) => (prev === numberOfPage.length ? prev : prev + 1))}
      >
        <button className='page-link'>
          <i className='next'></i>
        </button>
      </li>
    </ul>
  )
}

export default Pagination
