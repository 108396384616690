import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {KTSVG} from '../../../../../_metronic/helpers'
import {selectors} from '../../redux/ReportsRedux'
import {numSpaceFormater} from "../../../../helpers/formater";
import {Platform} from "../../models/AbstractReportDetails";
import {bool} from "yup";

interface DevicesGroupProps {
  isPdf?: boolean
}


interface CircleChartData {
  sectorName: any
  transactionChart: any
  clicksCart: any
  costChart: any
  revenueChart: any
  userChart: any
  revenuePerUserChart: any
}

const FBPlatformGroup: React.FC<DevicesGroupProps> = ({isPdf}) => {
  const intl = useIntl()
  const details = useSelector(selectors.getReportDetails)

  const [platformData, setPlatformData] = useState<Platform[]>()
  const [hasPlatformData, setHasPlatformData] = useState(false);

  useEffect(() => {
    if (details && details.fbCampaignPlatform) {

      setPlatformData(details.fbCampaignPlatform.data)
      if(details.fbCampaignPlatform.data && details.fbCampaignPlatform.data.length > 0) {
        setHasPlatformData(true);
      }
    }
  }, [details])


  return hasPlatformData ? (
   <>
     <div className={`${isPdf === true ? '' : 'border'} px-5 py-5 mb-5 card-body`}>

       <div className='row'>
         <div className='pb-5'>
           <h3 className='fw-boldest text-sempai-green text-uppercase'>
             {intl.formatMessage({id: 'PLATFORM'})}
           </h3>
         </div>
       </div>

       {platformData && platformData.length > 0 && (
           <div
               className='row device-data d-flex flex-column flex-lg-row'
               data-testid='devices-data'
           >

             {platformData.map((single:any, index:any) => {
               return (
                   single.platform != 'unknown' && (
                     <div key={index} className='col-6 mb-8 d-flex'>
                       <div className='w-100 d-flex flex-row'>
                         <div className=' d-flex align-items-center pe-10'>
                           {(() => {if (single.platform === 'audience_network') {
                             return (
                                 <KTSVG
                                     path='/media/sempai-images/icons/audience.svg'
                                     className='svg-icon-5x svg-icon-sempai-green'
                                 />
                             )
                           }else if (single.platform === 'instagram') {
                             return (
                                 <KTSVG
                                     path='/media/sempai-images/icons/ig.svg'
                                     className='svg-icon-5x svg-icon-sempai-green'
                                 />
                             )
                           } else if (single.platform === 'facebook') {
                             return (
                                 <KTSVG
                                     path='/media/sempai-images/icons/fb.svg'
                                     className='svg-icon-5x text-sempai-green svg-icon-white'
                                     svgClassName='mh-100px'
                                 />
                             )
                           } else if (single.platform === 'messenger') {
                             return (
                                 <KTSVG
                                     path='/media/sempai-images/icons/mess.svg'
                                     className='svg-icon-5x text-sempai-green svg-icon-white'
                                     svgClassName='mh-100px'
                                 />
                             )
                           }
                           })()}



                         </div>
                         <div className='d-flex flex-column'>
                           <div className='mb-1'>
                              <span className='text-dark fs-7 fw-bold pe-3'>
                                {intl.formatMessage({id: 'CLICKS'})}:
                              </span>
                             <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(single.clicks)}</span>
                           </div>
                           <div className='mb-1'>
                              <span className='text-dark fs-7 fw-bold pe-3'>
                                {intl.formatMessage({id: 'REACH'})}:
                              </span>
                             <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(single.reach)}</span>
                           </div>
                           <div className=''>
                              <span className='text-dark fs-7 fw-bold pe-3'>
                                {intl.formatMessage({id: 'IMPRESSIONS'})}:
                              </span>
                             <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(single.impressions)}</span>
                           </div>
                         </div>
                       </div>
                     </div>
                   )
               );
             })}

           </div>
       )}

     </div>

   </>
  ) : <></>
}

export default FBPlatformGroup
