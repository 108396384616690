import React, {useEffect, useState} from 'react'
import {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  chartHeight: string
  sectorsName: string[]
  sectorsValue: number[]
  keytabchart: string | number
}

let ChartDonutWidget: React.FC<Props> = ({className, chartHeight, sectorsName, sectorsValue,keytabchart}) => {
  const forth = '#084736'
  const third = '#44734B'
  const secondaryColor = '#2A3849'
  const mainColor = getCSSVariableValue('--bs-sempai-green')


  const [chartOptions, setChartOptions] = useState<ApexOptions>({
        series: sectorsValue,
        labels: sectorsName,
        chart: {
          fontFamily: 'inherit',
          type: 'pie',
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        colors: [forth, mainColor, third ],
      })

  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      labels: sectorsName,
    }));
  }, [sectorsName]);


  return (
          <div className={`card bg-white ${className}`}>
            <div className='card-body p-0 d-flex justify-content-center align-items-center flex-column'>
              <Chart
                  type='pie'
                  options={chartOptions}
                  series={sectorsValue}
                  width='250'
                  height={chartHeight}
              />
            </div>
          </div>

  )
}

export default ChartDonutWidget
