import {put, select, takeLatest} from 'redux-saga/effects'
import {ActionWithPayload, RootState} from '../../../../setup'
import {IInvoice, IUnpaymentedInvoices} from '../models/Invoices'
import {getInvoicesList, getInvoicesOverdue} from './InvoiceCRUD'
import {selectors as authSelectors} from '../../auth/redux/AuthRedux'

export const INVOICE_REQUEST = 'INVOICE_REQUEST'
export const INVOICE_REQUEST_SUCCESS = 'INVOICE_REQUEST_SUCCESS'
export const INVOICE_REQUEST_FAIL = 'INVOICE_REQUEST_FAIL'

export const OVERDUE_INVOICES_REQUEST = 'OVERDUE_INVOICES_REQUEST'
export const OVERDUE_INVOICES_SUCCESS = 'OVERDUE_INVOICES_SUCCESS'
export const OVERDUE_INVOICES_FAIL = 'OVERDUE_INVOICES_FAIL'

export const INVOICE_PDF_FAIL = 'INVOICE_PDF_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IInvoicesState {
  invoicesList?: IInvoice[]
  invoiceLoading: boolean
  invoiceError: boolean

  invoicePdfError?: string[][] | null
  overdueInvoices?: IUnpaymentedInvoices[]

  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
}

const initailState: IInvoicesState = {
  invoicesList: [],
  invoiceError: false,
  invoiceLoading: false,

  invoicePdfError: undefined,
  overdueInvoices: undefined,

  currentPage: 1,
  totalPages: undefined,
  perPage: 20,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,
}

export const reducer = (
  state: IInvoicesState = initailState,
  action: ActionWithPayload<IInvoicesState>
) => {
  switch (action.type) {
    //get user list
    case INVOICE_REQUEST:
      return {...state, invoiceLoading: true}
    case INVOICE_REQUEST_SUCCESS:
      const invoicesList = action.payload?.invoicesList
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        invoicesList,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        invoiceLoading: false,
        invoiceError: false,
      }
    case INVOICE_REQUEST_FAIL:
      return {...state, invoiceLoading: false, invoiceError: true}
    // pdf
    case INVOICE_PDF_FAIL:
      const invoicePdfError = action.payload?.invoicePdfError
      return {...state, invoicePdfError}

    //unpaymented inv
    case OVERDUE_INVOICES_REQUEST:
      return {...state, invoiceLoading: true}
    case OVERDUE_INVOICES_SUCCESS:
      const overdueInvoices = action.payload?.overdueInvoices
      return {
        ...state,
        overdueInvoices,
        invoiceLoading: false,
        invoiceError: false,
      }
    case OVERDUE_INVOICES_FAIL:
      return {...state, invoiceLoading: false, invoiceError: true}
    // user list filters
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    default:
      return state
  }
}

export const actions = {
  requestInvoices: () => ({type: INVOICE_REQUEST}),

  requestInvoicesSuccess: (
    invoicesList: IInvoice[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: INVOICE_REQUEST_SUCCESS,
    payload: {invoicesList, currentPage, totalPages, perPage, totalItem},
  }),

  requestInvoicesFail: () => ({type: INVOICE_REQUEST_FAIL}),

  getInvoicePdfFail: (errorMsg: string[][]) => ({
    type: INVOICE_PDF_FAIL,
    payload: {invoicePdfError: errorMsg},
  }),

  requestUnpaymentedInvoices: () => ({type: OVERDUE_INVOICES_REQUEST}),

  requestUnpaymentedInvoicesSuccess: (
    overdueInvoices: IUnpaymentedInvoices[],
  ) => ({
    type: OVERDUE_INVOICES_SUCCESS,
    payload: {overdueInvoices},
  }),

  requestUnpaymentedInvoicesFail: () => ({type: OVERDUE_INVOICES_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getInvoicesState: (state: RootState) => state.invoices,
  getOverdueInvoices: (state: RootState) => state.invoices.overdueInvoices,
  getCurrentPage: (state: RootState) => state.invoices.currentPage,
  getLimitPerPage: (state: RootState) => state.invoices.perPage,
  getFilter: (state: RootState) => state.invoices.filter,
  getTotalItem: (state: RootState) => state.invoices.totalItem,
  getSearchText: (state: RootState) => state.invoices.searchText,
}

function* listUpdate() {
  const companyId: number = yield select(authSelectors.getCompanyId)
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getInvoicesList(companyId, page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(INVOICE_REQUEST, function* getInvoicesSaga() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestInvoicesSuccess(
          data.items,
          data.currentPage,
          data.lastPage,
          data.perPage,
          data.total
        )
      )
    } catch (err) {
      yield put(actions.requestInvoicesFail())
    }
  })

  yield takeLatest(OVERDUE_INVOICES_REQUEST, function* getUnpaymentedInvoicesSaga() {
    try {
      const companyId: number = yield select(authSelectors.getCompanyId)
      const {data} = yield getInvoicesOverdue(companyId)
      yield put(actions.requestUnpaymentedInvoicesSuccess(data.items))
    } catch (err) {
      yield put(actions.requestUnpaymentedInvoicesFail())
    }
  })
}
