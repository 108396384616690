import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {numSpaceFormater} from '../../../../../helpers/formater'
import {FBSummaryData, FBReportsDetails, FBSummary} from '../../../models/FBReport'
import {ClicksToRevenuePerDate, IReportDetails} from '../../../models/Reports'
import {selectors} from '../../../redux/ReportsRedux'
import ChartLinesWidget from '../../Widgets/ChartLinesWidget'

interface ClicksGroupProps {
  reportData: IReportDetails
}

const ClicksGroup: React.FC<ClicksGroupProps> = ({reportData}) => {
  const intl = useIntl()
  const details = useSelector(selectors.getReportDetails) as FBReportsDetails

  const [fbSummarySum, setFbSummarySum] = useState<FBSummaryData>()
  const [clicksToRevenueChart, setClicksToRevenueChart] = useState<ClicksToRevenuePerDate>()

  useEffect(() => {
    if (details && details.fbSummary) {
        setFbSummarySum(details.fbSummary as FBSummaryData)
    }
    if (details && details.googleAnalyticDailyChart)
      setClicksToRevenueChart({
        clicks: details.googleAnalyticDailyChart.clicks,
        dates: details.googleAnalyticDailyChart.dates,
        revenue: details.googleAnalyticDailyChart.transaction_revenue,
      })
  }, [details])

  function hasData(fbSummary: FBSummaryData | null) {
    if (fbSummary && fbSummary.data) {
      const data: any = fbSummary.data;

      const fieldsToCheck = ['clicks', 'spend', 'cpm', 'ctr', 'roas'];
      for (const columnName of fieldsToCheck) {
        if (data[columnName] === null) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  return hasData(details.fbSummary) ? (
    <>
      <div className='card-body row clicks-group-report_title pb-10 px-5 py-5 mb-1'>
          <h3 className='fw-bolder text-dark'>
            {`${intl.formatMessage({id: 'REPORT.COM.FACEBOOK.ADS'})} ${intl.formatDate(
              reportData.date,
              {
                year: 'numeric',
                month: 'long',
              }
            )} ${intl.formatMessage({id: 'TO'})} ${reportData.domain}`}
          </h3>
          <div className='text-gray-400 fw-bold fs-6'>
            {intl.formatMessage({id: 'REPORT.COM.FACEBOOK.ADS'})}
          </div>
      </div>
      <div className='row clicks-group-report_title pb-10'>
        <div className='row'>
          <div className='pb-5 clicks-group-report_title'>
            <h3 className='fw-bolder text-dark text-center'>
              Facebook Ads - {intl.formatMessage({id: 'SUMMARY'})} -{' '}
              {intl.formatMessage({id: 'REPORT.SUMMARY.TITLE'})}
            </h3>
            <div className='text-center text-gray-400 fw-bold fs-3'>
              {fbSummarySum &&
                intl.formatDate(fbSummarySum.data.date, {
                  year: 'numeric',
                  month: 'long',
                })}
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-around flex-column flex-lg-row fb-summary'>
          {fbSummarySum && (
            <>
              <div className="d-flex flex-column justify-content-center align-items-center mb-3 fb-summary-param">
                <div className="fw-bold text-dark fb-summary-stat-title" style={{fontSize: '50px'}}>
                  {numSpaceFormater(fbSummarySum.data.clicks)}
                </div>
                <div className="text-dark fs-3 mb-3"></div>
                <div className="text-muted fs-3 fw-bold mw-250px text-center">
                  {intl.formatMessage({id: 'CLICKS'})}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mb-3 fb-summary-param">
                <div className="fw-bold text-dark fb-summary-stat-title" style={{fontSize: '50px'}}>
                  {numSpaceFormater(Number(fbSummarySum.data.spend).toFixed(2))}
                </div>
                <div className="text-dark fs-3 mb-3"></div>
                <div className="text-muted fs-3 fw-bold mw-250px text-center">
                  {intl.formatMessage({id: 'SPEND'})}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mb-3 fb-summary-param">
                <div className="fw-bold text-dark fb-summary-stat-title" style={{fontSize: '50px'}}>
                  {numSpaceFormater(Number(fbSummarySum.data.ctr).toFixed(2))}
                </div>
                <div className="text-dark fs-3 mb-3"></div>
                <div className="text-muted fs-3 fw-bold mw-250px text-center">
                  {intl.formatMessage({id: 'REPORT.CTR'})}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mb-3 fb-summary-param">
                <div className="fw-bold text-dark fb-summary-stat-title" style={{fontSize: '50px'}}>
                  {numSpaceFormater(Number(fbSummarySum.data.cpm).toFixed(2))}
                </div>
                <div className="text-dark fs-3 mb-3"></div>
                <div className="text-muted fs-3 fw-bold mw-250px text-center">
                  {intl.formatMessage({id: 'REPORT.CPM'})}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mb-3 fb-summary-param">
                <div className="fw-bold text-dark fb-summary-stat-title" style={{fontSize: '50px'}}>
                  {numSpaceFormater(Number(fbSummarySum.data.roas).toFixed(2))}
                </div>
                <div className="text-dark fs-3 mb-3"></div>
                <div className="text-muted fs-3 fw-bold mw-250px text-center">
                  {intl.formatMessage({id: 'REPORT.ROAS'})}
                </div>
              </div>
              {fbSummarySum.data.purchase != null && (
              <div className="d-flex flex-column justify-content-center align-items-center mb-3 fb-summary-param">
                <div className="fw-bold text-dark fb-summary-stat-title" style={{fontSize: '50px'}}>
                  {numSpaceFormater(Number(fbSummarySum.data.purchase).toFixed(2))}
                </div>
                <div className="text-dark fs-3 mb-3"></div>
                <div className="text-muted fs-3 fw-bold mw-250px text-center">
                  {intl.formatMessage({id: 'REPORT.PURCHASE'})}
                </div>
              </div>
              )}
            </>
          )}
        </div>
      </div>
      {/*<div className='row g-5 g-xl-8'>*/}
      {/*  <div className='col-xl-12'>*/}
      {/*    {clicksToRevenueChart && (*/}
      {/*      <ChartLinesWidget*/}
      {/*        className='card-xl-stretch mb-xl-8'*/}
      {/*        chartId='fb-ClickGroup'*/}
      {/*        dateArray={clicksToRevenueChart.dates}*/}
      {/*        firstDataSet={clicksToRevenueChart.clicks}*/}
      {/*        secondDataSet={clicksToRevenueChart.revenue}*/}
      {/*        firstDataSetTitle='Click'*/}
      {/*        secondDataSetTitle='Revenue'*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  ) : <></>
}

export default ClicksGroup
