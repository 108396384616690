import React, { useEffect, useState } from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {ScrollTop} from './components/ScrollTop'
import {Toolbar} from './components/toolbar/Toolbar'
import OverdueInvoicesAlert from '../../app/modules/common/OverdueInvoicesAlert/OverdueInvoicesAlert'

const MasterLayout: React.FC = ({children}) => {
  const [pathArr, setPathArr] = useState<string[]>()
  const path = window.location.pathname

  useEffect(() => {
    const pathArr = path.split('/')
    setPathArr(pathArr)
  }, [path])
  
  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            {pathArr && pathArr.length < 3 && <OverdueInvoicesAlert />}
            <div className='post d-flex flex-column-fluid overflow-hidden' id='kt_post'>
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
