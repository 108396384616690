import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import {useIntl} from "react-intl";
import {GA4productsDataByCategoryDaum} from "../../../models/AbstractReportDetails";
import Pagination from "../../../../common/Pagination/Pagination";
import SeoTable from "../../SeoStatistic/SeoWidgets/SeoTable";


interface SeoCategoryData {
    sessions: string
    itemRevenue: number
    itemName: string
    itemUsers: number
}

const GA4ForSeoCategoryTable: React.FC = () => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [categoryDataArray, setCategoryDataArray] = useState<SeoCategoryData[]>()
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number>();
    const itemsPerPage = 10;


    useEffect(() => {
        if (reportData.details.GA4productsDataByCategory) {
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const slicedData = reportData.details.GA4productsDataByCategory.slice(startIndex, endIndex);

            const mappedData = slicedData.map((item: GA4productsDataByCategoryDaum) => ({
                sessions: item.sessions,
                itemRevenue: item.itemRevenue,
                itemName: item.itemCategory === '' ? intl.formatMessage({id: 'DIFFERENT'}) : item.itemCategory,
                itemUsers: item.totalUsers,
                itemsPurchased: item.itemsPurchased,
            }));
            setPages(Math.ceil(reportData.details.GA4productsDataByCategory.length / itemsPerPage));
            setCategoryDataArray(mappedData);
        }
    }, [reportData.details.GA4productsDataByCategory, currentPage]);

    return (
        <>
            {categoryDataArray && categoryDataArray?.length > 0 &&(
                    <div className='px-5 py-3'>
                         <SeoTable
                             dataArray={categoryDataArray}
                             title={intl.formatMessage({id: 'PRODUCT_CATEGORIES'})}
                             subtitle={intl.formatMessage({id: 'CATEGORIES'})}
                         />
                        <div className='d-flex flex-stack flex-wrap justify-content-start justify-content-xl-center pt-5'>
                            <Pagination {...{currentPage, setCurrentPage}} pages={pages} />
                        </div>
                    </div>
            )}
        </>
    )

}


export default GA4ForSeoCategoryTable
