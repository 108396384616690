import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {IReportDetails, SEO_TAB_NAME} from '../../models/Reports'
import {useIntl} from 'react-intl'
import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";

import {KTSVG} from "../../../../../_metronic/helpers";
import SeoPrintView from "../Print/SeoPrintView";
import {selectors as seoReportsListSelectors} from "../../redux/SeoReportListsRedux";
import {bool} from 'yup'

interface SeoTabsNavProps {
  tab: string
  setTab: (data: string) => void
  showPdf: boolean;
}

const SeoTabsNav: React.FC<SeoTabsNavProps> = ({tab, setTab, showPdf}) => {
  const intl = useIntl()
  const reportData = useSelector(selectors.getReportData) as IReportDetails
  const [open, setOpen] = useState(false);
  const [shouldDisplayReport, setShouldDisplayReport] = useState<boolean>(false);
  const {
    topPagesList,
    topPhrasesList,
    topPhrasesCompareList,
  } = useSelector(seoReportsListSelectors.getReportListsState)

  const isArrayNotEmpty = (array: any[] | undefined): boolean => Array.isArray(array) && array.length > 0;

  useEffect(() => {
    const { details } = reportData || {};
    const { SearchConsoleDates } = details || {};
    const { clicks, dates, impressions } = SearchConsoleDates || {};

    if (
        isArrayNotEmpty(clicks) &&
        isArrayNotEmpty(dates) &&
        isArrayNotEmpty(impressions)
    ) {
      setShouldDisplayReport(true);
    } else {
      setTab(reportData?.details?.links ? SEO_TAB_NAME.LINKS : SEO_TAB_NAME.COMMENT);
    }
  }, [reportData, topPhrasesCompareList, topPhrasesList, topPagesList]);

  return (
    <ul
      className='nav nav-tabs-report nav-stretch nav-fixed-top nav-line-tabs fw-bold fs-6 flex-nowrap'
      role='tablist'
    >

      { shouldDisplayReport && (
        <li className='nav-item action-btn'>
        <div
            className={clsx(`nav-link nav-link-report fs-xl-5 fs-6 px-15 me-5`, {
              active: tab === SEO_TAB_NAME.GOOGLE_SEARCH_CONSOLE,
            })}
            onClick={() => setTab(SEO_TAB_NAME.GOOGLE_SEARCH_CONSOLE)}
            role='tab'
        >
          {intl.formatMessage({id: SEO_TAB_NAME.GOOGLE_SEARCH_CONSOLE})}
        </div>
      </li>)}


      {reportData && reportData.details && reportData.details.GA4config && reportData.details.GA4config.status && (
          <li className='nav-item action-btn'>
              <div
                  className={clsx(`nav-link nav-link-report fs-xl-5 fs-6 px-15 me-5`, {
                      active: tab === SEO_TAB_NAME.GA4,
                  })}
                  onClick={() => setTab(SEO_TAB_NAME.GA4)}
                  role='tab'
              >
                  {intl.formatMessage({id: SEO_TAB_NAME.GA4})}
              </div>
          </li>
      )}


      {/*<li className='nav-item action-btn'>*/}
      {/*  <div*/}
      {/*    className={clsx(`nav-link nav-link-report fs-xl-3 font-weight-bold fs-5 px-20 me-5`, {*/}
      {/*      active: tab === SEO_TAB_NAME.ANALYSIS,*/}
      {/*    })}*/}
      {/*    onClick={() => setTab(SEO_TAB_NAME.ANALYSIS)}*/}
      {/*    role='tab'*/}
      {/*  >*/}
      {/*    {intl.formatMessage({id: SEO_TAB_NAME.ANALYSIS})}*/}
      {/*  </div>*/}
      {/*</li>*/}

      {reportData && reportData.details && reportData.details.asps && reportData.details.asps.titles && (
          <li className='nav-item action-btn'>
            <div
                className={clsx(`nav-link nav-link-report fs-xl-5 fs-6 px-15 me-5`, {
                  active: tab === SEO_TAB_NAME.ASPS,
                })}
                onClick={() => setTab(SEO_TAB_NAME.ASPS)}
                role='tab'
            >
              {intl.formatMessage({id: SEO_TAB_NAME.ASPS})}
            </div>
          </li>
      )}

      {reportData && reportData.details && reportData.details.links && (
          <li className='nav-item action-btn'>
              <div
                  className={clsx(`nav-link nav-link-report fs-xl-5 fs-6 px-15 me-5`, {
                      active: tab === SEO_TAB_NAME.LINKS,
                  })}
                  onClick={() => setTab(SEO_TAB_NAME.LINKS)}
                  role='tab'
              >
                  {intl.formatMessage({id: SEO_TAB_NAME.LINKS})}
              </div>
          </li>
      )}

      <li className='nav-item action-btn'>
        <div
            className={clsx(`nav-link nav-link-report fs-xl-5 fs-6 px-15 me-5`, {
              active: tab === SEO_TAB_NAME.COMMENT,
            })}
            onClick={() => setTab(SEO_TAB_NAME.COMMENT)}
            role='tab'
        >
          {intl.formatMessage({id: SEO_TAB_NAME.COMMENT})}
        </div>
      </li>

        {reportData && reportData.details && showPdf && (
            <li className='nav-item action-btn'>
                <div onClick={() => setOpen(true)}
                     className={clsx(`nav-link nav-link-report fw-bolder fs-xl-5 fs-6 px-5`, {
                         active: tab === SEO_TAB_NAME.PRINT,
                     })}
                >
                    <div>
                        <KTSVG
                            path='/media/sempai-images/icons/Icon-feather-download.svg'
                            className='svg-icon-3 svg-icon-white'
                        />
                        <span className='px-2'>{intl.formatMessage({id: SEO_TAB_NAME.PDF})}</span>
                    </div>
                    {open ? <SeoPrintView closePopup={() => setOpen(false)} /> : null}
                </div>
            </li>
        )}
    </ul>
  )
}

export default SeoTabsNav
