import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import React, {useEffect, useState} from "react";
import ErrorView from "../../../../common/ErrorView/ErrorView";
import Spinner from "../../../../common/Spinner/Spinner";
import {IReportDetails} from "../../../models/Reports";
import TiktokTitle from "../TiktokWidgets/TiktokTitle";
import TiktokBasicData from "../TiktokWidgets/TiktokBasicData";
import TikTokTopAds from "../TiktokWidgets/TikTokTopAds";
import TiktokCampaignGroup from "../TiktokWidgets/TiktokCampaignGroup";

interface ITiktokStatisticTab {
    isPdf?: boolean;
    onLoad?: () => void;
}

const TiktokStatisticTab: React.FC<ITiktokStatisticTab> = ({ isPdf, onLoad }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        setLoading(reportsLoading)
    }, [reportsLoading])

    useEffect(() => {
        if(onLoad){
            onLoad();
        }
    }, [onLoad]);

    return !reportsError ? (
        <div
            data-testid="tiktok-report-basic-data"
            className={reportsLoading ? 'overlay overlay-block rounded' : ''}
        >
            {!loading ? (
                <>
                    <div className="clicks-group card-body bg-white">
                        <TiktokTitle/>
                        {/*<BingDailyChart/>*/}
                    </div>
                    <div className='clicks-group bg-white'>
                        <TiktokBasicData/>
                    </div>
                    <div className='clicks-group bg-white'>
                        <TikTokTopAds tabKey={1}/>
                    </div>
                    <div className='clicks-group bg-white'>
                        <TiktokCampaignGroup/>
                    </div>
                </>

            ) : (
                <div style={{height: '350px'}}>
                    <Spinner />
                </div>
            )}
        </div>
    ) : (
        <ErrorView />
    )
}

export default TiktokStatisticTab