/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export const AsideMenuMain: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/sempai-images/icons/main-page-logo.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        // fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted fs-7 fw-bold'>
            {intl.formatMessage({id: 'PAGES'})}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/invoices'
        icon='/media/sempai-images/icons/invoices-logo.svg'
        title={intl.formatMessage({id: 'INVOICES'})}
        // fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/mediainvoices'
        icon='/media/sempai-images/icons/invoices-logo.svg'
        title={intl.formatMessage({id: 'MEDIAINVOICES'})}
        // fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/agreements'
        icon='/media/sempai-images/icons/agreements-logo.svg'
        title={intl.formatMessage({id: 'AGREEMENTS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/reports'
        icon='/media/sempai-images/icons/report-logo.svg'
        title={intl.formatMessage({id: 'REPORTS'})}
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
