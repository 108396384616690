import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {ICompany} from '../../../../app/modules/auth/models/UserModel'
import Select, {SingleValue} from 'react-select'
import {RootState} from '../../../../setup'
import {ISelectValue} from '../../../../app/modules/common/PageItemsSelect/PageItemsSelect'
import {actions as actionsauth} from '../../../../app/modules/auth'
// import {actions, selectors} from '../redux/ReportsRedux'
import {actions} from "../../../../app/modules/reports/redux/ReportsRedux";

const Header: React.FC = () => {
  const dispatch = useDispatch()
  const [currentCompany, setCurrentCompany] = useState<ISelectValue>()
  const [companyList, setCompanyList] = useState<ISelectValue[]>()
  const companies: ICompany[] = useSelector<RootState>(
    ({auth}) => auth.companies,
    shallowEqual
  ) as ICompany[]
  const companyId: number = useSelector<RootState>(
    ({auth}) => auth.companyId,
    shallowEqual
  ) as number

  useEffect(() => {
    if (companies) {
      const selectCompany: ISelectValue[] = companies.map((item) => ({
        value: item.client_id,
        label: item.client_name,
      }))
      setCompanyList(selectCompany)
    }
  }, [companies])

  useEffect(() => {
    if (companies && companyId) {
      let curCompany = companies.find((item) => item.client_id === companyId);

      if (!curCompany && companies.length > 0) {
        curCompany = companies[0];
      }

      curCompany && setCurrentCompany({ value: curCompany.client_id, label: curCompany.client_name });
    }
  }, [companies, companyId])

  const selectChange = (newValue: SingleValue<ISelectValue>) => {
    if (newValue && newValue.value) {
      dispatch(actionsauth.changeCompanyId(Number(newValue.value)))
      window.location.href = '/reports'
    }
  }

  return (
    <div
      className='header-menu align-items-stretch'
      data-kt-drawer='true'
      data-kt-drawer-name='header-menu'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div
        className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'
        id='#kt_header_menu'
        data-kt-menu='true'
      >
        <div className='menu-item w-300px me-lg-1'>
          <span className='menu-title me-5'>Lista firm:</span>
          {currentCompany && companyList && (
            <Select
              className='form-select-formik w-200px fw-bold'
              classNamePrefix='formik-drop'
              menuPlacement='bottom'
              defaultValue={currentCompany}
              options={companyList}
              onChange={selectChange}
              minMenuHeight={50}
              maxMenuHeight={250}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export {Header}
