import {SEOBrandList, SEOCategoryList, SEOEfficiencyList} from '../../../models/SEOReport'
import {numSpaceFormater} from "../../../../../helpers/formater";

interface ProductsParamListItemProps {
  product: SEOEfficiencyList | SEOCategoryList | SEOBrandList
}

const ProductsParamListItem: React.FC<ProductsParamListItemProps> = ({product}) => {
  const efficientyTypeGuard = (
    product: SEOEfficiencyList | SEOCategoryList | SEOBrandList
  ): product is SEOEfficiencyList => {
    return 'product_name' in product
  }

  const categoryTypeGuard = (
    product: SEOEfficiencyList | SEOCategoryList | SEOBrandList
  ): product is SEOCategoryList => {
    return 'product_category' in product
  }

  const brandTypeGuard = (
    product: SEOEfficiencyList | SEOCategoryList | SEOBrandList
  ): product is SEOBrandList => {
    return 'product_brand' in product
  }

  return (
    <>
      <tr>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <div className='text-dark fw-bolder fs-6'>
                {efficientyTypeGuard(product) && product.product_name}
                {categoryTypeGuard(product) && product.product_category}
                {brandTypeGuard(product) && product.product_brand}
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex'>
            <div className='d-flex justify-content-start flex-column'>
              <div className='text-dark fs-6'>{numSpaceFormater(product.item_revenue.toFixed(2))}</div>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start'>
            <div className='text-dark fs-6'>{numSpaceFormater(product.unique_purchases)}</div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start'>
            <div className='text-dark fs-6'>{numSpaceFormater(product.item_quantity)}</div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start'>
            <div className='text-dark fs-6'>{product.revenue_per_item.toFixed(2)}</div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end'>
            <div className='text-dark fs-6'>{product.items_per_purchase.toFixed(2)}</div>
          </div>
        </td>
      </tr>
    </>
  )
}

export default ProductsParamListItem
