import React from 'react'
import GA4ForGAdsDailyChart from '../../GoogleAnalytics4/GA4GoogleAds/GA4ForGAdsDailyChart'
import {useSelector} from 'react-redux'
import {selectors} from '../../../redux/ReportsRedux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import GA4ForGAdsTitle from "../../GoogleAnalytics4/GA4GoogleAds/GA4ForGAdsTitle";
import GA4ForGAdsBasicData from "../../GoogleAnalytics4/GA4GoogleAds/GA4ForGAdsBasicData";
import GA4ForGAdsDemographic from "../../GoogleAnalytics4/GA4GoogleAds/GA4ForGAdsDemographic";
import GA4ForGAdsDevice from "../../GoogleAnalytics4/GA4GoogleAds/GA4ForGAdsDevice";

interface GAdsDataStatisticTabProps {}

const GAdsDataStatisticTabProps: React.FC<GAdsDataStatisticTabProps> = () => {
  const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)

  return !reportsError ? (
    <div data-testid="sem-report-basic-data" className={reportsLoading ? 'overlay overlay-block rounded' : ''}>
      {!reportsLoading ? (
        <>
          <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5'>
            <GA4ForGAdsTitle />
            <GA4ForGAdsDailyChart />
          </div>

          <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5'>
            <GA4ForGAdsBasicData/>
          </div>

          <div className='compare-data card-body bg-white px-5 py-5 pb-0 mb-5'>
            <GA4ForGAdsDemographic />
            {/*<MonthAdsGroup />*/}
          </div>

          <div className='compare-data compare-data-year card-body bg-white px-5 py-5 pb-0 mb-5'>
            <GA4ForGAdsDevice />
            {/*<YearAdsGroup />*/}
          </div>
        </>
      ) : (
        <div style={{height: '350px'}}>
          <Spinner />
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default GAdsDataStatisticTabProps
