import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import React, {useEffect, useState} from "react";
import {basicSum} from "../../../models/AllegroReport";
import AllegroDataWidget from "./AllegroDataWidget";

const AllegroBasicData = () => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [allegroData, setAllegroData] = useState<basicSum>()
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        setCurrency(reportData.details.allegroDaily.campaignInformation.campaigns_currency);
        setAllegroData({
            avg_ctr: Number(parseFloat(String(reportData.details.allegroDaily.basicSum.avg_ctr)).toFixed(2)),
            avg_cpc: Number(parseFloat(String(reportData.details.allegroDaily.basicSum.avg_cpc)).toFixed(2)),
            avg_roas: Number(parseFloat(String(reportData.details.allegroDaily.basicSum.avg_roas)).toFixed(2)),
            clicks: reportData.details.allegroDaily.basicSum.clicks,
            totalAttributionValue: Number(parseFloat(String(reportData.details.allegroDaily.basicSum.totalAttributionValue)).toFixed(2)),
            totalCost: Number(parseFloat(String(reportData.details.allegroDaily.basicSum.totalCost)).toFixed(2)),
            views: reportData.details.allegroDaily.basicSum.views
        })
    }, [reportData]);

    return (
        <>
            {allegroData && (
                <AllegroDataWidget
                    data={allegroData}
                    cardLabel={'ALLEGRO_BASIC_DATA'}
                    currency={currency}
                />
            )}
        </>
    )
}

export default AllegroBasicData