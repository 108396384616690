import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../../redux/ReportsRedux';
import { useIntl } from 'react-intl';
import SortButton from "../../SortButton";
import { numSpaceFormater } from '../../../../../helpers/formater';
import { SEMReportDetails } from '../../../models/SEMReports';

type SortKeys = any;
type SortOrder = "ascn" | "desc";

interface GoogleAdsGroupProps {
    tabKey: string | number;
}

type GoogleCampaignsSumList = GoogleCampaignsSumListArray[];

interface GoogleCampaignsSumListArray {
    [key: string]: any;
    month: string;
    campaign_name: string;
    ad_network_type: string;
    clicks: string;
    cost_micros: number | string;
    conversions: string;
    impressions: string;
    ctr: number;
    cpc: number | string;
    conversion_cost: number | string;
    conversion_rate: number | string;
    conversions_value: number | string;
    conversion_to_cost: number | string;
}

const GoogleAdsSumList: React.FC<GoogleAdsGroupProps> = ({ tabKey }) => {
    const intl = useIntl();
    const details = useSelector(selectors.getReportDetails) as SEMReportDetails;
    const [googleCampaignsSumList, setGoogleCampaignsSumList] = useState<GoogleCampaignsSumList>();
    const multiplier: number = details.googleAdsConfig.multiplier / 100;
    const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");
    const [sortKey, setSortKey] = useState<SortKeys>();

    var ads_currency: string = details.googleAdsConfig.ads_currency;

    const sortListByParam = (param: any, isAscending: string = 'ascn') => {
        if (googleCampaignsSumList) {
            const sortedList = [...googleCampaignsSumList];
            sortedList.sort((a, b) => (a[param] > b[param]) ? 1 : -1);
            if (isAscending !== 'ascn') {
                sortedList.reverse();
            }
            setGoogleCampaignsSumList(sortedList);
        }
    };

    useEffect(() => {
        Object.values(details.googleAdsCampaignList).map((key) => {
            if (tabKey == key.id) {
                setGoogleCampaignsSumList(key.data);
            }
            if (tabKey == 1) {
                setGoogleCampaignsSumList(details.googleAdsSumData.sumCampaign);
            }
        });
    }, [tabKey, details]);

    const changeSort = (key: SortKeys) => {
        const newSortOrder = sortOrder === "ascn" ? "desc" : "ascn";
        setSortOrder(newSortOrder);
        setSortKey(key);
        sortListByParam(key, newSortOrder);
    };

    return (
        <>
            {tabKey == '1' && details.googleAdsSumData.sumCampaignNetwork && (
                <div className='card overlay rounded'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>
                                {intl.formatMessage({ id: 'CAMPAIGNS_REPORT_EFFICIENCY_TITLE' })}
                            </span>
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            {details.googleAdsSumData.sumCampaignNetwork && (
                                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                    <thead>
                                    <tr className='fw-bolder text-muted'>
                                        <th className='min-w-50px'>
                                            {intl.formatMessage({ id: 'NETWORK' })}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CLICKS' })}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'COST' })}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CTR' })} (%)
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CPC' })}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {details.googleAdsSumData.sumCampaignNetwork.map((single) => (
                                        <tr key={single.ad_network_type}>
                                            <td>
                                                <div className='text-dark fw-bolder fs-8'>{single.ad_network_type}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater(single.clicks)}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater((Number(single.cost_micros)).toFixed(2)) + ' ' + ads_currency}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater((Number(single.ctr) * 100).toFixed(2))}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater(Number(single.cpc).toFixed(2)) + ' ' + ads_currency}</div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {tabKey == '1' && googleCampaignsSumList && (
                <div className='card overlay rounded'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>
                                {intl.formatMessage({ id: 'CAMPAIGNS_REPORT_EFFICIENCY_TITLE' })}
                            </span>
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            {googleCampaignsSumList && (
                                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                    <thead>
                                    <tr className='fw-bolder text-muted'>
                                        <th className='min-w-50px'>
                                            <div className='d-flex'>
                                                {intl.formatMessage({ id: 'CAMPAIGN_NAME' })}
                                                <SortButton columnKey={'campaign_name'} onClick={() => changeSort('campaign_name')} sortOrder={sortOrder} sortKey={sortKey} />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            <div className='d-flex'>
                                                {intl.formatMessage({ id: 'CLICKS' })}
                                                <SortButton columnKey={'clicks'} onClick={() => changeSort('clicks')} sortOrder={sortOrder} sortKey={sortKey} />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            <div className='d-flex'>
                                                {intl.formatMessage({ id: 'COST' })}
                                                <SortButton columnKey={'cost_micros'} onClick={() => changeSort('cost_micros')} sortOrder={sortOrder} sortKey={sortKey} />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CPC' })}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            <div className='d-flex'>
                                                {intl.formatMessage({ id: 'CTR' })}
                                                <SortButton columnKey={'ctr'} onClick={() => changeSort('ctr')} sortOrder={sortOrder} sortKey={sortKey} />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CONVERSIONS' })}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CONVERSION_COST' })}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CONVERSIONS_PERCENTAGE' })}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CONVERSION_VALUE' })}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CONVERSION_TO_COST' })}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {googleCampaignsSumList.map((single) => (
                                        <tr key={single.campaign_name}>
                                            <td>
                                                <div className='text-dark fw-bolder fs-8'>{single.campaign_name}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater(Number(single.clicks).toFixed(2))}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater(Number(single.cost_micros).toFixed(2)) + ' ' + ads_currency}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater(Number(single.cpc).toFixed(2))}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{(Number(single.ctr) * 100).toFixed(2)}%</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater((Number(single.conversions) * multiplier).toFixed(2))}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater(Number(single.conversion_cost).toFixed(2)) + ' ' + ads_currency}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{(Number(single.conversion_rate) * 100).toFixed(2)}%</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{numSpaceFormater((Number(single.conversions_value)).toFixed(2)) + ' ' + ads_currency}</div>
                                            </td>
                                            <td>
                                                <div className='text-dark fs-8'>{(Number(single.conversion_to_cost) * 100).toFixed(2)}%</div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default GoogleAdsSumList;
