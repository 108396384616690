var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.9737, 51.3641],
            [15.0082, 51.3642],
            [15.0172, 51.393],
            [15.0558, 51.3923],
            [15.0761, 51.3971],
            [15.0834, 51.4083],
            [15.0846, 51.4195],
            [15.0955, 51.4263],
            [15.117, 51.4315],
            [15.1299, 51.4382],
            [15.1479, 51.4422],
            [15.1652, 51.4505],
            [15.1869, 51.4641],
            [15.2031, 51.463],
            [15.2001, 51.4472],
            [15.2102, 51.4439],
            [15.2405, 51.4454],
            [15.2518, 51.4481],
            [15.2649, 51.4415],
            [15.2775, 51.429],
            [15.2896, 51.4308],
            [15.2916, 51.4235],
            [15.3158, 51.417],
            [15.3426, 51.4453],
            [15.3396, 51.4559],
            [15.3444, 51.4701],
            [15.3803, 51.469],
            [15.3772, 51.4789],
            [15.3685, 51.4856],
            [15.373, 51.5074],
            [15.3833, 51.5119],
            [15.386, 51.5272],
            [15.4009, 51.5272],
            [15.4063, 51.5201],
            [15.4516, 51.5252],
            [15.4692, 51.514],
            [15.4849, 51.5131],
            [15.4903, 51.5268],
            [15.5442, 51.4959],
            [15.5743, 51.4814],
            [15.5952, 51.4686],
            [15.5817, 51.4531],
            [15.5868, 51.4491],
            [15.6056, 51.45],
            [15.6237, 51.4636],
            [15.6252, 51.471],
            [15.645, 51.4782],
            [15.6557, 51.4724],
            [15.6658, 51.4825],
            [15.6678, 51.4991],
            [15.6797, 51.5117],
            [15.6997, 51.5126],
            [15.7135, 51.5174],
            [15.7105, 51.5292],
            [15.7148, 51.5489],
            [15.7438, 51.5565],
            [15.7682, 51.5692],
            [15.7915, 51.5726],
            [15.8228, 51.5732],
            [15.8241, 51.5764],
            [15.8187, 51.6039],
            [15.8225, 51.6128],
            [15.8339, 51.623],
            [15.8301, 51.6378],
            [15.8372, 51.6448],
            [15.8531, 51.6501],
            [15.8566, 51.6587],
            [15.8414, 51.6675],
            [15.8201, 51.6694],
            [15.8446, 51.6766],
            [15.8433, 51.6814],
            [15.8662, 51.6933],
            [15.8708, 51.7215],
            [15.8812, 51.72],
            [15.8915, 51.7298],
            [15.9006, 51.7308],
            [15.9157, 51.7233],
            [15.9237, 51.7269],
            [15.9312, 51.7386],
            [15.9509, 51.7349],
            [15.974, 51.7471],
            [15.9683, 51.7685],
            [15.9884, 51.7694],
            [16.0078, 51.7734],
            [16.0051, 51.781],
            [15.9876, 51.7785],
            [15.9721, 51.7831],
            [15.9652, 51.7926],
            [15.9874, 51.8039],
            [16.0058, 51.8017],
            [16.0312, 51.8031],
            [16.0359, 51.796],
            [16.0342, 51.7842],
            [16.0564, 51.7819],
            [16.0713, 51.7833],
            [16.0742, 51.7752],
            [16.1002, 51.7665],
            [16.1181, 51.7641],
            [16.1334, 51.7651],
            [16.1355, 51.7597],
            [16.1579, 51.7595],
            [16.1773, 51.7643],
            [16.1666, 51.7288],
            [16.1606, 51.7184],
            [16.1615, 51.7111],
            [16.1726, 51.7095],
            [16.1863, 51.7155],
            [16.2104, 51.7155],
            [16.2211, 51.7107],
            [16.237, 51.6891],
            [16.2525, 51.6859],
            [16.2732, 51.6622],
            [16.284, 51.6802],
            [16.2965, 51.684],
            [16.3193, 51.6872],
            [16.3376, 51.7004],
            [16.369, 51.7175],
            [16.3728, 51.723],
            [16.3737, 51.7442],
            [16.3791, 51.7575],
            [16.3905, 51.7673],
            [16.4073, 51.775],
            [16.421, 51.7843],
            [16.4336, 51.79],
            [16.4732, 51.7911],
            [16.5299, 51.7835],
            [16.5528, 51.7824],
            [16.5594, 51.7737],
            [16.5699, 51.7722],
            [16.5841, 51.7566],
            [16.6051, 51.7519],
            [16.6273, 51.7497],
            [16.6313, 51.7468],
            [16.6386, 51.7253],
            [16.6625, 51.7097],
            [16.6625, 51.7006],
            [16.6491, 51.6948],
            [16.6462, 51.681],
            [16.6378, 51.6788],
            [16.6389, 51.6592],
            [16.6604, 51.6588],
            [16.6716, 51.6508],
            [16.7276, 51.6501],
            [16.7432, 51.6425],
            [16.7697, 51.6454],
            [16.7714, 51.628],
            [16.7932, 51.6149],
            [16.795, 51.6049],
            [16.8046, 51.5971],
            [16.8244, 51.5936],
            [16.8336, 51.5833],
            [16.8418, 51.585],
            [16.8676, 51.5792],
            [16.8875, 51.581],
            [16.8965, 51.5782],
            [16.9164, 51.5584],
            [16.9278, 51.5527],
            [16.9601, 51.5535],
            [16.9632, 51.5458],
            [16.9789, 51.5454],
            [16.9834, 51.5504],
            [17.0092, 51.5497],
            [17.02, 51.5534],
            [17.0464, 51.5556],
            [17.0695, 51.5526],
            [17.0652, 51.5663],
            [17.0925, 51.5734],
            [17.1096, 51.5751],
            [17.1265, 51.566],
            [17.1686, 51.5692],
            [17.1874, 51.5768],
            [17.214, 51.5735],
            [17.2128, 51.5884],
            [17.215, 51.5999],
            [17.2077, 51.6109],
            [17.2164, 51.6291],
            [17.2391, 51.6348],
            [17.245, 51.6401],
            [17.2986, 51.6459],
            [17.3291, 51.6472],
            [17.3581, 51.6433],
            [17.3806, 51.6357],
            [17.3894, 51.6362],
            [17.4198, 51.6304],
            [17.4405, 51.6322],
            [17.4692, 51.6258],
            [17.4866, 51.6167],
            [17.4995, 51.615],
            [17.5044, 51.6096],
            [17.5186, 51.6052],
            [17.5224, 51.5891],
            [17.5358, 51.589],
            [17.5568, 51.5836],
            [17.5616, 51.5736],
            [17.572, 51.5629],
            [17.5722, 51.5557],
            [17.5804, 51.5379],
            [17.5747, 51.5301],
            [17.5519, 51.5137],
            [17.5231, 51.5135],
            [17.5159, 51.5103],
            [17.5149, 51.502],
            [17.5266, 51.4993],
            [17.5199, 51.4926],
            [17.5229, 51.484],
            [17.5168, 51.4742],
            [17.5292, 51.4689],
            [17.5219, 51.4623],
            [17.5411, 51.447],
            [17.5514, 51.4357],
            [17.5452, 51.4241],
            [17.5485, 51.4036],
            [17.5862, 51.4025],
            [17.6195, 51.4085],
            [17.626, 51.417],
            [17.6369, 51.4142],
            [17.6527, 51.4178],
            [17.6609, 51.4063],
            [17.6732, 51.4085],
            [17.7094, 51.4025],
            [17.7189, 51.4063],
            [17.723, 51.421],
            [17.7345, 51.4208],
            [17.7489, 51.4093],
            [17.7503, 51.3927],
            [17.7386, 51.3777],
            [17.7252, 51.3744],
            [17.7178, 51.3665],
            [17.7158, 51.3438],
            [17.7458, 51.3367],
            [17.7511, 51.3328],
            [17.754, 51.3177],
            [17.7634, 51.3024],
            [17.7647, 51.295],
            [17.7598, 51.2842],
            [17.748, 51.2708],
            [17.7478, 51.2461],
            [17.7607, 51.2302],
            [17.7696, 51.2112],
            [17.793, 51.1997],
            [17.801, 51.1919],
            [17.7838, 51.1879],
            [17.7802, 51.1829],
            [17.7554, 51.1863],
            [17.7393, 51.1748],
            [17.7142, 51.1749],
            [17.6991, 51.1716],
            [17.7009, 51.1647],
            [17.6842, 51.1657],
            [17.67, 51.1609],
            [17.6403, 51.1734],
            [17.6227, 51.1747],
            [17.6194, 51.1844],
            [17.6084, 51.1894],
            [17.5784, 51.1624],
            [17.568, 51.1657],
            [17.5594, 51.1565],
            [17.5463, 51.1537],
            [17.5466, 51.1434],
            [17.568, 51.1377],
            [17.5761, 51.1299],
            [17.5714, 51.1173],
            [17.5797, 51.1107],
            [17.5624, 51.0936],
            [17.5816, 51.0821],
            [17.5519, 51.0671],
            [17.5375, 51.0697],
            [17.5277, 51.0673],
            [17.5201, 51.0595],
            [17.526, 51.0493],
            [17.5346, 51.043],
            [17.5198, 51.0257],
            [17.5156, 51.0123],
            [17.5269, 51.0041],
            [17.542, 51.0029],
            [17.5419, 50.9908],
            [17.5052, 50.9842],
            [17.5166, 50.9779],
            [17.509, 50.9708],
            [17.5014, 50.9747],
            [17.4684, 50.9715],
            [17.4644, 50.9769],
            [17.4432, 50.9689],
            [17.431, 50.967],
            [17.4342, 50.9549],
            [17.4324, 50.947],
            [17.4395, 50.9384],
            [17.4308, 50.9239],
            [17.4274, 50.9097],
            [17.4065, 50.9102],
            [17.4007, 50.9193],
            [17.38, 50.9242],
            [17.3526, 50.9],
            [17.3732, 50.8982],
            [17.3943, 50.8869],
            [17.3944, 50.8745],
            [17.3841, 50.8744],
            [17.3845, 50.8613],
            [17.3569, 50.8565],
            [17.3479, 50.8522],
            [17.3288, 50.8495],
            [17.3414, 50.8263],
            [17.3199, 50.8212],
            [17.3375, 50.8078],
            [17.3486, 50.8062],
            [17.3533, 50.793],
            [17.3478, 50.7848],
            [17.3057, 50.7881],
            [17.2884, 50.7931],
            [17.2744, 50.7876],
            [17.2672, 50.7712],
            [17.2807, 50.7607],
            [17.2947, 50.7535],
            [17.2888, 50.7426],
            [17.2767, 50.7439],
            [17.2631, 50.7386],
            [17.2615, 50.7295],
            [17.2517, 50.7188],
            [17.2227, 50.714],
            [17.221, 50.7014],
            [17.2589, 50.6903],
            [17.2467, 50.6828],
            [17.236, 50.6815],
            [17.2403, 50.6585],
            [17.2315, 50.6426],
            [17.2342, 50.6327],
            [17.2447, 50.6277],
            [17.2457, 50.6196],
            [17.2362, 50.6127],
            [17.2133, 50.6157],
            [17.1824, 50.6171],
            [17.1209, 50.6077],
            [17.1152, 50.5881],
            [17.1006, 50.5763],
            [17.0953, 50.5651],
            [17.0771, 50.5565],
            [17.0637, 50.5428],
            [17.063, 50.5312],
            [17.0739, 50.5175],
            [17.0588, 50.5046],
            [17.0631, 50.4891],
            [17.0605, 50.4766],
            [17.0474, 50.466],
            [17.0391, 50.4653],
            [17.0189, 50.4692],
            [17.0202, 50.4808],
            [17.0074, 50.4746],
            [16.9884, 50.4782],
            [16.9792, 50.485],
            [16.9588, 50.488],
            [16.947, 50.4809],
            [16.9402, 50.4599],
            [16.918, 50.4524],
            [16.9113, 50.4475],
            [16.8992, 50.4477],
            [16.8822, 50.4427],
            [16.8764, 50.4289],
            [16.868, 50.42],
            [16.8639, 50.4091],
            [16.8705, 50.4035],
            [16.9065, 50.391],
            [16.9157, 50.3826],
            [16.9194, 50.368],
            [16.9153, 50.3627],
            [16.9297, 50.3455],
            [16.9374, 50.3428],
            [16.9357, 50.3307],
            [16.9483, 50.3153],
            [16.9602, 50.3126],
            [16.9691, 50.3163],
            [16.9743, 50.3092],
            [16.9929, 50.3028],
            [17.0023, 50.3031],
            [17.0176, 50.2863],
            [17.0199, 50.2699],
            [17.012, 50.2608],
            [17.0035, 50.2573],
            [17.0129, 50.2489],
            [17.0129, 50.2419],
            [17.0285, 50.2313],
            [17.0169, 50.2201],
            [17.0015, 50.2162],
            [16.9942, 50.2267],
            [16.9832, 50.2346],
            [16.9775, 50.2448],
            [16.9646, 50.2396],
            [16.9659, 50.2298],
            [16.9587, 50.2237],
            [16.9288, 50.2231],
            [16.9187, 50.2268],
            [16.9002, 50.2232],
            [16.8986, 50.2185],
            [16.886, 50.2119],
            [16.8857, 50.202],
            [16.8733, 50.1972],
            [16.8463, 50.2069],
            [16.8365, 50.1979],
            [16.8153, 50.1925],
            [16.8009, 50.1776],
            [16.7988, 50.1652],
            [16.7834, 50.1518],
            [16.7849, 50.1454],
            [16.7561, 50.1343],
            [16.7476, 50.1353],
            [16.7307, 50.1172],
            [16.7216, 50.1031],
            [16.7068, 50.097],
            [16.6913, 50.099],
            [16.6796, 50.1039],
            [16.6433, 50.1124],
            [16.6345, 50.112],
            [16.6246, 50.1226],
            [16.6152, 50.1252],
            [16.613, 50.1326],
            [16.6002, 50.1397],
            [16.5897, 50.1415],
            [16.5824, 50.1484],
            [16.5864, 50.1537],
            [16.5613, 50.1654],
            [16.5591, 50.1729],
            [16.5652, 50.18],
            [16.5559, 50.197],
            [16.5626, 50.2084],
            [16.5557, 50.2162],
            [16.559, 50.2212],
            [16.5386, 50.2392],
            [16.5269, 50.2383],
            [16.5133, 50.2473],
            [16.508, 50.2602],
            [16.4974, 50.2596],
            [16.4889, 50.2644],
            [16.4789, 50.2766],
            [16.4665, 50.286],
            [16.4638, 50.2949],
            [16.4492, 50.3042],
            [16.4468, 50.3116],
            [16.4297, 50.3242],
            [16.4105, 50.3241],
            [16.3997, 50.3191],
            [16.3835, 50.3287],
            [16.3751, 50.3422],
            [16.3628, 50.3485],
            [16.3676, 50.3665],
            [16.3614, 50.3791],
            [16.3405, 50.3776],
            [16.3246, 50.3818],
            [16.3051, 50.3826],
            [16.293, 50.3708],
            [16.2782, 50.3682],
            [16.277, 50.3754],
            [16.2676, 50.3793],
            [16.2637, 50.3922],
            [16.2508, 50.3929],
            [16.2534, 50.4047],
            [16.2379, 50.4138],
            [16.22, 50.4074],
            [16.2097, 50.4256],
            [16.1993, 50.429],
            [16.1995, 50.4426],
            [16.2132, 50.4515],
            [16.2239, 50.442],
            [16.2311, 50.4429],
            [16.2357, 50.4597],
            [16.255, 50.467],
            [16.2493, 50.4737],
            [16.2704, 50.4839],
            [16.2949, 50.4794],
            [16.3018, 50.4911],
            [16.3107, 50.4925],
            [16.3111, 50.5051],
            [16.3202, 50.5072],
            [16.3447, 50.4955],
            [16.3606, 50.5013],
            [16.373, 50.5153],
            [16.3862, 50.5196],
            [16.4013, 50.5293],
            [16.3945, 50.5426],
            [16.4105, 50.5479],
            [16.4106, 50.5566],
            [16.4044, 50.5685],
            [16.4175, 50.5683],
            [16.4441, 50.5787],
            [16.4441, 50.5852],
            [16.4213, 50.6069],
            [16.4033, 50.6176],
            [16.3947, 50.6185],
            [16.3624, 50.6484],
            [16.3565, 50.6468],
            [16.3454, 50.6548],
            [16.3422, 50.662],
            [16.3198, 50.6661],
            [16.3055, 50.659],
            [16.2847, 50.6603],
            [16.2792, 50.6672],
            [16.234, 50.6712],
            [16.2222, 50.6511],
            [16.2204, 50.639],
            [16.2147, 50.632],
            [16.1867, 50.6274],
            [16.1805, 50.629],
            [16.1762, 50.6397],
            [16.1696, 50.6453],
            [16.1483, 50.6532],
            [16.1391, 50.6508],
            [16.1247, 50.6599],
            [16.1008, 50.6622],
            [16.0936, 50.6523],
            [16.0681, 50.6398],
            [16.0631, 50.6326],
            [16.0556, 50.6094],
            [16.0427, 50.6137],
            [16.0229, 50.5987],
            [15.9908, 50.6103],
            [15.9873, 50.6157],
            [15.9969, 50.622],
            [16.0217, 50.6299],
            [16.0087, 50.634],
            [16.0029, 50.6521],
            [16.0048, 50.6571],
            [15.9901, 50.684],
            [15.9666, 50.6915],
            [15.9424, 50.6865],
            [15.9198, 50.6846],
            [15.9061, 50.6793],
            [15.8858, 50.6752],
            [15.8843, 50.6707],
            [15.8611, 50.6742],
            [15.8616, 50.683],
            [15.8519, 50.7083],
            [15.8435, 50.712],
            [15.8314, 50.7243],
            [15.832, 50.7423],
            [15.8116, 50.7551],
            [15.7917, 50.7525],
            [15.7787, 50.7422],
            [15.7624, 50.7433],
            [15.7377, 50.7359],
            [15.7223, 50.7397],
            [15.7057, 50.7367],
            [15.6888, 50.7421],
            [15.6798, 50.7547],
            [15.6572, 50.7596],
            [15.6458, 50.7597],
            [15.6223, 50.7652],
            [15.6131, 50.7736],
            [15.6004, 50.777],
            [15.5796, 50.7789],
            [15.5627, 50.7763],
            [15.5447, 50.7807],
            [15.5241, 50.7773],
            [15.5106, 50.791],
            [15.496, 50.787],
            [15.4805, 50.7898],
            [15.4643, 50.7993],
            [15.4386, 50.8076],
            [15.4266, 50.8055],
            [15.4148, 50.7911],
            [15.3948, 50.7752],
            [15.3851, 50.7747],
            [15.3708, 50.7799],
            [15.3735, 50.7897],
            [15.3668, 50.8008],
            [15.3738, 50.8103],
            [15.3725, 50.8217],
            [15.367, 50.8354],
            [15.3542, 50.8498],
            [15.3389, 50.8554],
            [15.3092, 50.8605],
            [15.2939, 50.8728],
            [15.2772, 50.8894],
            [15.2763, 50.8989],
            [15.2674, 50.9172],
            [15.268, 50.9313],
            [15.279, 50.9411],
            [15.2897, 50.9462],
            [15.292, 50.9525],
            [15.285, 50.9588],
            [15.2751, 50.9605],
            [15.2744, 50.9786],
            [15.2483, 50.9795],
            [15.2329, 50.9978],
            [15.1965, 50.9877],
            [15.191, 50.9797],
            [15.1756, 50.9885],
            [15.174, 51.0127],
            [15.1716, 51.0184],
            [15.1472, 51.0132],
            [15.1295, 50.9895],
            [15.1055, 50.989],
            [15.098, 50.9971],
            [15.1049, 51.0068],
            [15.1005, 51.0119],
            [15.0758, 51.0148],
            [15.0677, 51.0206],
            [15.0567, 51.0202],
            [15.0554, 51.012],
            [15.038, 51.0123],
            [15.0329, 51.0217],
            [15.0204, 51.0236],
            [15.0146, 51.0169],
            [14.9976, 51.0092],
            [14.9868, 51.0109],
            [14.9685, 50.9899],
            [14.9883, 50.9776],
            [15.0071, 50.985],
            [15.0156, 50.9709],
            [15.0124, 50.9615],
            [15.0151, 50.949],
            [15.0138, 50.9381],
            [15.0012, 50.9269],
            [14.9905, 50.9236],
            [14.9985, 50.9119],
            [14.9993, 50.9001],
            [14.9942, 50.8915],
            [14.9982, 50.8874],
            [15.002, 50.8709],
            [14.9783, 50.8677],
            [14.9591, 50.8618],
            [14.9334, 50.87],
            [14.9004, 50.8755],
            [14.8969, 50.8672],
            [14.8715, 50.8776],
            [14.8625, 50.8717],
            [14.8418, 50.8687],
            [14.8328, 50.8729],
            [14.8237, 50.8706],
            [14.8167, 50.8798],
            [14.8197, 50.886],
            [14.8443, 50.901],
            [14.8513, 50.9096],
            [14.8661, 50.9171],
            [14.8755, 50.9302],
            [14.8904, 50.9353],
            [14.8964, 50.9415],
            [14.897, 50.9583],
            [14.9031, 50.9611],
            [14.9042, 50.9706],
            [14.9182, 50.9739],
            [14.916, 50.9918],
            [14.9274, 50.9973],
            [14.9364, 51.011],
            [14.9343, 51.0204],
            [14.9455, 51.0273],
            [14.9502, 51.0375],
            [14.948, 51.043],
            [14.9638, 51.0511],
            [14.9625, 51.0581],
            [14.9697, 51.0653],
            [14.9702, 51.0724],
            [14.978, 51.0767],
            [14.9761, 51.0869],
            [14.9825, 51.0919],
            [14.9827, 51.1019],
            [14.9776, 51.1077],
            [14.9963, 51.1218],
            [14.9961, 51.1329],
            [14.99, 51.1414],
            [14.9995, 51.149],
            [14.9928, 51.1593],
            [15.0043, 51.1674],
            [15.0088, 51.1824],
            [15.0045, 51.1899],
            [15.0132, 51.1954],
            [15.0086, 51.2],
            [15.0176, 51.2143],
            [15.0174, 51.226],
            [15.0386, 51.2437],
            [15.0257, 51.253],
            [15.0382, 51.2574],
            [15.0383, 51.2695],
            [15.0329, 51.2867],
            [15.0347, 51.2916],
            [15.0151, 51.3062],
            [15.0099, 51.3168],
            [14.9827, 51.3345],
            [14.9781, 51.3426],
            [14.9737, 51.3641],
          ],
        ],
      },
      properties: {
        name: 'Dolnośląskie',
        id: 'PL-02',
        NAME_ENG: 'Lower Silesia',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-02',
        find: (param) => {
            return undefined;
        }
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.3916, 53.4904],
            [17.3951, 53.5087],
            [17.4117, 53.5318],
            [17.4218, 53.537],
            [17.4206, 53.5498],
            [17.4148, 53.557],
            [17.4182, 53.5699],
            [17.4313, 53.5721],
            [17.4216, 53.5838],
            [17.4345, 53.5898],
            [17.4493, 53.6019],
            [17.4679, 53.6021],
            [17.5111, 53.6232],
            [17.5189, 53.6247],
            [17.5191, 53.6061],
            [17.5251, 53.5878],
            [17.5422, 53.5827],
            [17.5747, 53.5811],
            [17.5852, 53.5838],
            [17.6058, 53.5989],
            [17.6172, 53.5965],
            [17.6385, 53.5876],
            [17.6561, 53.57],
            [17.6721, 53.5736],
            [17.6804, 53.5816],
            [17.6797, 53.5883],
            [17.6581, 53.5936],
            [17.6615, 53.6043],
            [17.7177, 53.603],
            [17.7226, 53.6116],
            [17.706, 53.6232],
            [17.7048, 53.6332],
            [17.7291, 53.6594],
            [17.7429, 53.6913],
            [17.7529, 53.6951],
            [17.7747, 53.6915],
            [17.7867, 53.6955],
            [17.8003, 53.6886],
            [17.8161, 53.687],
            [17.8621, 53.6982],
            [17.8771, 53.7085],
            [17.8911, 53.7137],
            [17.893, 53.7273],
            [17.8784, 53.7371],
            [17.8763, 53.7488],
            [17.8561, 53.7515],
            [17.855, 53.7604],
            [17.8674, 53.7625],
            [17.8932, 53.7555],
            [17.9212, 53.7549],
            [17.9338, 53.7522],
            [17.9427, 53.757],
            [17.9545, 53.7425],
            [17.9774, 53.7373],
            [17.9983, 53.7253],
            [18.0114, 53.7238],
            [18.0271, 53.7333],
            [18.037, 53.7354],
            [18.0369, 53.7483],
            [18.0427, 53.7525],
            [18.0639, 53.7581],
            [18.0729, 53.7828],
            [18.0948, 53.7738],
            [18.1113, 53.7587],
            [18.129, 53.7669],
            [18.1511, 53.7669],
            [18.1558, 53.753],
            [18.1689, 53.7434],
            [18.1803, 53.745],
            [18.1817, 53.7533],
            [18.2005, 53.7558],
            [18.2005, 53.7452],
            [18.2146, 53.7466],
            [18.2208, 53.7398],
            [18.2562, 53.7461],
            [18.267, 53.7412],
            [18.2732, 53.7316],
            [18.2726, 53.717],
            [18.2592, 53.7128],
            [18.2637, 53.7017],
            [18.3217, 53.6959],
            [18.3444, 53.6918],
            [18.3824, 53.6884],
            [18.4169, 53.6909],
            [18.4187, 53.6874],
            [18.4555, 53.693],
            [18.469, 53.6899],
            [18.4725, 53.7026],
            [18.4945, 53.6994],
            [18.5096, 53.7],
            [18.5175, 53.7045],
            [18.5233, 53.6914],
            [18.5114, 53.6883],
            [18.5134, 53.6806],
            [18.5263, 53.6785],
            [18.5329, 53.6671],
            [18.5439, 53.6658],
            [18.5504, 53.6561],
            [18.5839, 53.6595],
            [18.5871, 53.6736],
            [18.6178, 53.6721],
            [18.6373, 53.6767],
            [18.6533, 53.6953],
            [18.6823, 53.693],
            [18.6952, 53.6991],
            [18.7145, 53.6877],
            [18.7306, 53.6842],
            [18.7404, 53.6912],
            [18.7647, 53.7002],
            [18.7834, 53.6962],
            [18.7737, 53.6797],
            [18.746, 53.6577],
            [18.739, 53.6432],
            [18.7393, 53.6361],
            [18.7581, 53.6097],
            [18.7691, 53.6058],
            [18.7843, 53.607],
            [18.8346, 53.6045],
            [18.8587, 53.6022],
            [18.906, 53.5952],
            [18.9081, 53.5908],
            [18.9032, 53.5774],
            [18.9152, 53.58],
            [18.9267, 53.592],
            [18.9407, 53.589],
            [18.9873, 53.5908],
            [18.993, 53.5962],
            [19.0088, 53.593],
            [19.0267, 53.5932],
            [19.0474, 53.597],
            [19.0953, 53.5971],
            [19.1441, 53.5845],
            [19.1621, 53.5837],
            [19.1816, 53.579],
            [19.2, 53.5695],
            [19.1903, 53.5475],
            [19.209, 53.5477],
            [19.2154, 53.5308],
            [19.2043, 53.5223],
            [19.2046, 53.5129],
            [19.2354, 53.4919],
            [19.2425, 53.4781],
            [19.2398, 53.4645],
            [19.2519, 53.4666],
            [19.2547, 53.4535],
            [19.2665, 53.442],
            [19.268, 53.4332],
            [19.2452, 53.4273],
            [19.2733, 53.4066],
            [19.2902, 53.4017],
            [19.3044, 53.3932],
            [19.3115, 53.3984],
            [19.318, 53.4137],
            [19.3316, 53.4156],
            [19.3436, 53.4095],
            [19.3526, 53.4094],
            [19.365, 53.4154],
            [19.3884, 53.4175],
            [19.3996, 53.4131],
            [19.4018, 53.3985],
            [19.4272, 53.389],
            [19.4514, 53.3856],
            [19.4467, 53.374],
            [19.4605, 53.3706],
            [19.4734, 53.3799],
            [19.4877, 53.3724],
            [19.5131, 53.3753],
            [19.5325, 53.3631],
            [19.5364, 53.3524],
            [19.5365, 53.339],
            [19.5642, 53.3376],
            [19.5784, 53.3394],
            [19.6004, 53.3478],
            [19.6379, 53.352],
            [19.6728, 53.3501],
            [19.6897, 53.3442],
            [19.6971, 53.3171],
            [19.7153, 53.3115],
            [19.7186, 53.302],
            [19.7127, 53.289],
            [19.7254, 53.2842],
            [19.6895, 53.2588],
            [19.6898, 53.2341],
            [19.6995, 53.2356],
            [19.717, 53.2301],
            [19.7348, 53.2294],
            [19.7512, 53.2113],
            [19.741, 53.2],
            [19.7433, 53.1896],
            [19.7511, 53.1841],
            [19.7527, 53.169],
            [19.762, 53.152],
            [19.7332, 53.1434],
            [19.7129, 53.1338],
            [19.71, 53.1221],
            [19.6925, 53.1194],
            [19.6673, 53.1061],
            [19.6392, 53.1074],
            [19.6396, 53.0954],
            [19.6483, 53.0864],
            [19.6473, 53.0775],
            [19.6616, 53.0697],
            [19.6479, 53.0668],
            [19.646, 53.0542],
            [19.6537, 53.0508],
            [19.6546, 53.0395],
            [19.6659, 53.0398],
            [19.6842, 53.0217],
            [19.6769, 52.9999],
            [19.6652, 52.9878],
            [19.6783, 52.9775],
            [19.6812, 52.9557],
            [19.6701, 52.9551],
            [19.6506, 52.9696],
            [19.6386, 52.9692],
            [19.6243, 52.9773],
            [19.6114, 52.9776],
            [19.5766, 52.9843],
            [19.5559, 52.9901],
            [19.5572, 52.9776],
            [19.5651, 52.9741],
            [19.5781, 52.9612],
            [19.5759, 52.9561],
            [19.5402, 52.9479],
            [19.5294, 52.936],
            [19.4945, 52.9401],
            [19.4855, 52.9322],
            [19.4742, 52.942],
            [19.4566, 52.9417],
            [19.4439, 52.9378],
            [19.4496, 52.9249],
            [19.466, 52.916],
            [19.461, 52.9038],
            [19.486, 52.8848],
            [19.4915, 52.8776],
            [19.5075, 52.8675],
            [19.4784, 52.8653],
            [19.4625, 52.8559],
            [19.4744, 52.8506],
            [19.4554, 52.8437],
            [19.4446, 52.837],
            [19.4317, 52.8405],
            [19.4239, 52.8275],
            [19.4339, 52.8157],
            [19.4543, 52.804],
            [19.4657, 52.8039],
            [19.4615, 52.7902],
            [19.4686, 52.784],
            [19.4837, 52.7811],
            [19.4967, 52.7704],
            [19.5047, 52.7689],
            [19.4967, 52.7586],
            [19.5038, 52.748],
            [19.5244, 52.748],
            [19.5095, 52.7369],
            [19.5009, 52.7373],
            [19.5044, 52.7251],
            [19.5137, 52.7218],
            [19.5084, 52.7119],
            [19.519, 52.7112],
            [19.5229, 52.7041],
            [19.4932, 52.7074],
            [19.4923, 52.7138],
            [19.4589, 52.7247],
            [19.4413, 52.7232],
            [19.4405, 52.7104],
            [19.4463, 52.7053],
            [19.4366, 52.692],
            [19.4364, 52.6815],
            [19.4308, 52.6716],
            [19.4154, 52.6709],
            [19.4107, 52.6549],
            [19.4025, 52.6438],
            [19.3849, 52.6434],
            [19.3739, 52.637],
            [19.3685, 52.6251],
            [19.3916, 52.6232],
            [19.4241, 52.6104],
            [19.4211, 52.5997],
            [19.4131, 52.595],
            [19.3984, 52.5933],
            [19.3908, 52.5737],
            [19.3767, 52.568],
            [19.3413, 52.5665],
            [19.3514, 52.5382],
            [19.3494, 52.5287],
            [19.338, 52.5332],
            [19.3325, 52.5249],
            [19.3848, 52.5187],
            [19.3946, 52.5113],
            [19.3716, 52.5062],
            [19.3545, 52.4897],
            [19.3576, 52.4807],
            [19.339, 52.4789],
            [19.3331, 52.4665],
            [19.3176, 52.4477],
            [19.3, 52.4455],
            [19.284, 52.4501],
            [19.2681, 52.4484],
            [19.26, 52.4424],
            [19.2624, 52.4358],
            [19.2871, 52.4326],
            [19.2964, 52.4246],
            [19.2931, 52.4109],
            [19.2818, 52.4048],
            [19.2905, 52.3977],
            [19.2618, 52.388],
            [19.2633, 52.3843],
            [19.2275, 52.3618],
            [19.2106, 52.3653],
            [19.2097, 52.3541],
            [19.2247, 52.3453],
            [19.218, 52.3421],
            [19.2019, 52.3513],
            [19.1805, 52.3494],
            [19.1597, 52.3414],
            [19.1484, 52.3461],
            [19.1208, 52.3446],
            [19.1035, 52.3486],
            [19.0469, 52.3331],
            [19.0291, 52.3335],
            [19.024, 52.3382],
            [19.0053, 52.3419],
            [19.007, 52.3506],
            [18.9835, 52.3578],
            [18.9698, 52.3566],
            [18.9681, 52.3624],
            [18.9784, 52.3656],
            [18.976, 52.3757],
            [18.9535, 52.38],
            [18.9402, 52.3923],
            [18.9211, 52.399],
            [18.9309, 52.3796],
            [18.9213, 52.3768],
            [18.9104, 52.3793],
            [18.9129, 52.3667],
            [18.8936, 52.3638],
            [18.8792, 52.3643],
            [18.8586, 52.3496],
            [18.8365, 52.3433],
            [18.8193, 52.3478],
            [18.8138, 52.3524],
            [18.7891, 52.343],
            [18.7782, 52.3354],
            [18.755, 52.3334],
            [18.7526, 52.3634],
            [18.7424, 52.3648],
            [18.7392, 52.3732],
            [18.7109, 52.4006],
            [18.6802, 52.4],
            [18.6832, 52.4094],
            [18.671, 52.4213],
            [18.6575, 52.4221],
            [18.6452, 52.4527],
            [18.6092, 52.4556],
            [18.5931, 52.468],
            [18.589, 52.4607],
            [18.5692, 52.464],
            [18.5594, 52.4838],
            [18.5368, 52.4857],
            [18.5234, 52.4984],
            [18.4902, 52.5039],
            [18.4865, 52.4974],
            [18.4716, 52.4894],
            [18.4639, 52.4759],
            [18.4402, 52.4795],
            [18.4232, 52.4785],
            [18.4189, 52.4731],
            [18.3996, 52.4729],
            [18.3882, 52.4697],
            [18.3828, 52.4748],
            [18.3858, 52.4862],
            [18.3766, 52.4993],
            [18.378, 52.5161],
            [18.3879, 52.5221],
            [18.3826, 52.5378],
            [18.3714, 52.538],
            [18.3623, 52.5434],
            [18.339, 52.5378],
            [18.3445, 52.5252],
            [18.3386, 52.5198],
            [18.3125, 52.5052],
            [18.2754, 52.4912],
            [18.261, 52.4812],
            [18.2406, 52.4882],
            [18.2304, 52.4838],
            [18.2046, 52.4831],
            [18.1985, 52.4872],
            [18.2012, 52.4988],
            [18.1965, 52.5049],
            [18.1698, 52.5053],
            [18.1541, 52.5101],
            [18.1372, 52.5047],
            [18.1256, 52.5068],
            [18.1227, 52.5157],
            [18.1059, 52.5144],
            [18.0929, 52.5196],
            [18.0928, 52.532],
            [18.1072, 52.5392],
            [18.096, 52.5531],
            [18.0654, 52.5518],
            [18.0629, 52.5483],
            [18.0329, 52.5508],
            [18.023, 52.5555],
            [18.0036, 52.5557],
            [17.999, 52.5431],
            [17.9883, 52.5437],
            [17.9883, 52.5525],
            [17.997, 52.5654],
            [17.9979, 52.5759],
            [17.9841, 52.5787],
            [17.97, 52.5776],
            [17.9577, 52.5732],
            [17.9478, 52.5921],
            [17.937, 52.5972],
            [17.9281, 52.5863],
            [17.916, 52.5968],
            [17.9155, 52.6167],
            [17.9029, 52.6215],
            [17.88, 52.6413],
            [17.8721, 52.6427],
            [17.8559, 52.6382],
            [17.8384, 52.6374],
            [17.8389, 52.6488],
            [17.8078, 52.6516],
            [17.7905, 52.6575],
            [17.776, 52.6388],
            [17.7668, 52.6383],
            [17.7676, 52.6531],
            [17.7635, 52.6587],
            [17.7259, 52.6449],
            [17.7257, 52.64],
            [17.7488, 52.6382],
            [17.7491, 52.6346],
            [17.7283, 52.6206],
            [17.713, 52.6149],
            [17.7023, 52.6157],
            [17.6895, 52.602],
            [17.679, 52.6027],
            [17.6759, 52.6166],
            [17.6819, 52.6234],
            [17.6775, 52.649],
            [17.646, 52.6448],
            [17.6306, 52.6563],
            [17.6399, 52.6631],
            [17.6353, 52.6788],
            [17.649, 52.6913],
            [17.6396, 52.6952],
            [17.6319, 52.7105],
            [17.6133, 52.7042],
            [17.5789, 52.7062],
            [17.5779, 52.695],
            [17.5571, 52.6896],
            [17.5452, 52.6917],
            [17.5097, 52.6741],
            [17.4991, 52.6856],
            [17.4712, 52.6824],
            [17.4747, 52.7044],
            [17.4793, 52.7083],
            [17.4869, 52.7294],
            [17.4608, 52.7401],
            [17.4385, 52.7516],
            [17.4194, 52.7525],
            [17.4098, 52.7583],
            [17.4139, 52.7674],
            [17.4078, 52.7723],
            [17.4166, 52.7852],
            [17.4435, 52.7888],
            [17.4536, 52.7982],
            [17.4629, 52.7879],
            [17.4983, 52.786],
            [17.5074, 52.7953],
            [17.5045, 52.8052],
            [17.522, 52.8236],
            [17.5103, 52.8347],
            [17.5155, 52.8397],
            [17.5134, 52.8561],
            [17.5241, 52.8606],
            [17.5237, 52.8777],
            [17.5293, 52.8857],
            [17.518, 52.8873],
            [17.5187, 52.8971],
            [17.5134, 52.9039],
            [17.5088, 52.9188],
            [17.5189, 52.9288],
            [17.4992, 52.9337],
            [17.4863, 52.9517],
            [17.4681, 52.9491],
            [17.4298, 52.9678],
            [17.4112, 52.9733],
            [17.3964, 52.9839],
            [17.3712, 52.9755],
            [17.3503, 52.9735],
            [17.3176, 52.9749],
            [17.3194, 52.9875],
            [17.3029, 52.9965],
            [17.317, 53.0198],
            [17.3321, 53.0184],
            [17.3374, 53.0422],
            [17.3307, 53.0514],
            [17.3249, 53.0769],
            [17.3379, 53.0777],
            [17.3634, 53.0911],
            [17.3584, 53.1029],
            [17.3593, 53.1209],
            [17.3526, 53.1301],
            [17.3787, 53.1329],
            [17.3843, 53.1453],
            [17.3634, 53.1495],
            [17.3539, 53.1537],
            [17.3391, 53.1548],
            [17.3472, 53.1622],
            [17.3365, 53.1713],
            [17.3348, 53.1796],
            [17.3487, 53.1985],
            [17.3337, 53.2079],
            [17.3314, 53.2181],
            [17.363, 53.2221],
            [17.3821, 53.2276],
            [17.4048, 53.2259],
            [17.4168, 53.2316],
            [17.4132, 53.2412],
            [17.4283, 53.2461],
            [17.4194, 53.253],
            [17.4205, 53.2631],
            [17.4372, 53.2681],
            [17.4211, 53.2826],
            [17.396, 53.2876],
            [17.3967, 53.2947],
            [17.3874, 53.2994],
            [17.3785, 53.3117],
            [17.3592, 53.3173],
            [17.3532, 53.3241],
            [17.358, 53.3302],
            [17.3415, 53.338],
            [17.3383, 53.3473],
            [17.3259, 53.347],
            [17.301, 53.3518],
            [17.2811, 53.3588],
            [17.2795, 53.351],
            [17.2663, 53.3516],
            [17.2487, 53.3811],
            [17.2732, 53.3866],
            [17.288, 53.3946],
            [17.2947, 53.4025],
            [17.2926, 53.4135],
            [17.2992, 53.4227],
            [17.3156, 53.4319],
            [17.3334, 53.439],
            [17.337, 53.4461],
            [17.3552, 53.4534],
            [17.3725, 53.4666],
            [17.3825, 53.4692],
            [17.3916, 53.4904],
          ],
        ],
      },
      properties: {
        name: 'Kujawsko-Pomorskie',
        id: 'PL-04',
        NAME_ENG: 'Kuyavia-Pomerania',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-04',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.2905, 52.3977],
            [19.3057, 52.397],
            [19.32, 52.3879],
            [19.309, 52.3827],
            [19.3087, 52.3764],
            [19.3271, 52.3759],
            [19.3339, 52.3677],
            [19.335, 52.3578],
            [19.355, 52.3503],
            [19.3787, 52.347],
            [19.4048, 52.3535],
            [19.4343, 52.3496],
            [19.4539, 52.3588],
            [19.464, 52.3586],
            [19.4789, 52.3527],
            [19.511, 52.3433],
            [19.5132, 52.3365],
            [19.5073, 52.3252],
            [19.5409, 52.3121],
            [19.58, 52.3004],
            [19.6049, 52.2962],
            [19.627, 52.2958],
            [19.633, 52.2855],
            [19.6416, 52.2823],
            [19.6437, 52.2695],
            [19.6545, 52.2635],
            [19.6809, 52.2612],
            [19.6959, 52.2652],
            [19.7057, 52.2782],
            [19.7191, 52.2813],
            [19.7574, 52.2677],
            [19.7882, 52.2748],
            [19.8113, 52.2869],
            [19.8344, 52.2887],
            [19.8553, 52.2834],
            [19.8752, 52.2879],
            [19.8811, 52.2955],
            [19.8789, 52.3063],
            [19.8972, 52.3142],
            [19.9146, 52.3154],
            [19.9419, 52.3056],
            [19.9728, 52.2892],
            [19.9822, 52.2749],
            [19.9821, 52.2639],
            [20.0177, 52.2628],
            [20.0178, 52.2737],
            [20.035, 52.2737],
            [20.046, 52.2632],
            [20.0567, 52.2599],
            [20.0551, 52.2498],
            [20.0774, 52.247],
            [20.0913, 52.2369],
            [20.0891, 52.2226],
            [20.0796, 52.2058],
            [20.0796, 52.1944],
            [20.0891, 52.1843],
            [20.1006, 52.1786],
            [20.1126, 52.1601],
            [20.1282, 52.1512],
            [20.1529, 52.155],
            [20.1756, 52.1542],
            [20.1816, 52.1459],
            [20.2023, 52.1259],
            [20.2227, 52.1241],
            [20.255, 52.1254],
            [20.2659, 52.1241],
            [20.2779, 52.1123],
            [20.2813, 52.0988],
            [20.2799, 52.0834],
            [20.2586, 52.0823],
            [20.257, 52.0752],
            [20.2654, 52.0647],
            [20.2247, 52.0422],
            [20.2101, 52.04],
            [20.2125, 52.0285],
            [20.2472, 52.0147],
            [20.2666, 52.0176],
            [20.2846, 51.9911],
            [20.2823, 51.9829],
            [20.2893, 51.9768],
            [20.2857, 51.9713],
            [20.2557, 51.9635],
            [20.2426, 51.9577],
            [20.2406, 51.95],
            [20.2459, 51.942],
            [20.2622, 51.9382],
            [20.2743, 51.9388],
            [20.3001, 51.9463],
            [20.3183, 51.9421],
            [20.3193, 51.9316],
            [20.3247, 51.9227],
            [20.3349, 51.9281],
            [20.3556, 51.9262],
            [20.3645, 51.9348],
            [20.3789, 51.9349],
            [20.3872, 51.9479],
            [20.3933, 51.9489],
            [20.4061, 51.9379],
            [20.4177, 51.9386],
            [20.4308, 51.9505],
            [20.456, 51.9443],
            [20.4676, 51.936],
            [20.4885, 51.9269],
            [20.495, 51.9208],
            [20.4943, 51.9053],
            [20.5063, 51.8973],
            [20.5197, 51.8979],
            [20.5669, 51.8954],
            [20.5942, 51.8658],
            [20.6051, 51.8518],
            [20.5967, 51.8393],
            [20.5996, 51.8274],
            [20.6182, 51.8272],
            [20.6189, 51.8203],
            [20.6065, 51.8159],
            [20.5681, 51.8213],
            [20.5615, 51.812],
            [20.581, 51.8108],
            [20.579, 51.7942],
            [20.5935, 51.7702],
            [20.584, 51.7609],
            [20.583, 51.7483],
            [20.6073, 51.7469],
            [20.6156, 51.7425],
            [20.6395, 51.7378],
            [20.6387, 51.7325],
            [20.6591, 51.7234],
            [20.6548, 51.715],
            [20.6591, 51.703],
            [20.6532, 51.6774],
            [20.6388, 51.6761],
            [20.6263, 51.6699],
            [20.6207, 51.6574],
            [20.597, 51.6592],
            [20.5791, 51.6628],
            [20.5363, 51.6683],
            [20.5278, 51.6797],
            [20.513, 51.6797],
            [20.514, 51.6859],
            [20.4887, 51.6836],
            [20.4678, 51.6932],
            [20.4541, 51.6934],
            [20.4511, 51.6862],
            [20.4249, 51.6765],
            [20.408, 51.6754],
            [20.3977, 51.6705],
            [20.3917, 51.6556],
            [20.3959, 51.648],
            [20.3861, 51.6417],
            [20.3934, 51.6359],
            [20.4151, 51.6336],
            [20.4197, 51.6192],
            [20.4267, 51.6107],
            [20.4335, 51.5928],
            [20.4305, 51.5827],
            [20.4336, 51.5748],
            [20.443, 51.5715],
            [20.4617, 51.573],
            [20.4686, 51.5587],
            [20.4569, 51.5439],
            [20.4501, 51.5123],
            [20.4511, 51.5054],
            [20.4856, 51.5028],
            [20.508, 51.5042],
            [20.5235, 51.5134],
            [20.5358, 51.5126],
            [20.5418, 51.4922],
            [20.5399, 51.4818],
            [20.533, 51.4682],
            [20.5217, 51.4627],
            [20.516, 51.4496],
            [20.5014, 51.4303],
            [20.4873, 51.4232],
            [20.4619, 51.417],
            [20.4294, 51.3771],
            [20.4298, 51.369],
            [20.4228, 51.3635],
            [20.4288, 51.3513],
            [20.4317, 51.3353],
            [20.4148, 51.3204],
            [20.3859, 51.3183],
            [20.3719, 51.3104],
            [20.3689, 51.303],
            [20.381, 51.2832],
            [20.393, 51.2695],
            [20.3747, 51.2672],
            [20.3796, 51.2462],
            [20.3706, 51.2367],
            [20.3597, 51.2405],
            [20.3576, 51.2492],
            [20.3302, 51.254],
            [20.3097, 51.2421],
            [20.2766, 51.2433],
            [20.2635, 51.2516],
            [20.2595, 51.2586],
            [20.2431, 51.2565],
            [20.2438, 51.2437],
            [20.2558, 51.236],
            [20.252, 51.2208],
            [20.2644, 51.2195],
            [20.264, 51.2139],
            [20.2523, 51.2066],
            [20.2185, 51.1994],
            [20.2106, 51.2013],
            [20.1948, 51.1965],
            [20.1915, 51.2047],
            [20.1798, 51.2073],
            [20.1757, 51.1962],
            [20.1577, 51.1977],
            [20.1359, 51.191],
            [20.1269, 51.204],
            [20.0871, 51.2056],
            [20.0654, 51.2046],
            [20.0535, 51.2148],
            [20.0254, 51.209],
            [20.0066, 51.202],
            [20.0023, 51.1911],
            [20.0256, 51.177],
            [20.0266, 51.1686],
            [20.0135, 51.1638],
            [19.989, 51.1485],
            [19.9867, 51.1378],
            [19.9989, 51.1207],
            [19.9988, 51.113],
            [19.9885, 51.089],
            [19.9901, 51.0806],
            [19.9983, 51.0777],
            [20.0451, 51.0759],
            [20.0648, 51.0687],
            [20.0526, 51.0633],
            [20.0479, 51.0552],
            [20.0518, 51.0368],
            [20.048, 51.0121],
            [20.0323, 50.9964],
            [20.04, 50.9907],
            [20.0356, 50.9734],
            [20.0236, 50.9694],
            [19.9667, 50.9958],
            [19.9611, 51.0139],
            [19.9552, 51.0194],
            [19.944, 51.0211],
            [19.9345, 51.0323],
            [19.9104, 51.0372],
            [19.8916, 51.0553],
            [19.877, 51.0574],
            [19.8675, 51.049],
            [19.8613, 51.0098],
            [19.8442, 50.9911],
            [19.8332, 50.9862],
            [19.8329, 50.9798],
            [19.8479, 50.9579],
            [19.8419, 50.9496],
            [19.8476, 50.9413],
            [19.8468, 50.9316],
            [19.8386, 50.9171],
            [19.8229, 50.9156],
            [19.8191, 50.9071],
            [19.8077, 50.9021],
            [19.7907, 50.9008],
            [19.7736, 50.8753],
            [19.7519, 50.8635],
            [19.7338, 50.8535],
            [19.7133, 50.849],
            [19.6975, 50.8486],
            [19.6771, 50.8522],
            [19.6722, 50.8561],
            [19.6673, 50.8726],
            [19.6688, 50.8846],
            [19.6368, 50.8866],
            [19.6268, 50.8895],
            [19.6068, 50.9044],
            [19.5828, 50.9096],
            [19.5384, 50.8992],
            [19.5204, 50.8886],
            [19.5, 50.8888],
            [19.491, 50.8957],
            [19.482, 50.9428],
            [19.4711, 50.9448],
            [19.4544, 50.9578],
            [19.4486, 50.9682],
            [19.4327, 50.9797],
            [19.424, 50.983],
            [19.4155, 51.0041],
            [19.4055, 51.011],
            [19.3917, 51.0105],
            [19.3804, 50.9963],
            [19.3626, 50.9921],
            [19.3517, 50.9961],
            [19.3624, 51.005],
            [19.3445, 51.0126],
            [19.3269, 51.0147],
            [19.3153, 51.0237],
            [19.3314, 51.0398],
            [19.3238, 51.0466],
            [19.3131, 51.0483],
            [19.2937, 51.0462],
            [19.2601, 51.0334],
            [19.27, 51.0167],
            [19.264, 51.0019],
            [19.2507, 50.9943],
            [19.2278, 50.9884],
            [19.2167, 50.9898],
            [19.2054, 50.9957],
            [19.1804, 51.0006],
            [19.1667, 51.0175],
            [19.1591, 51.018],
            [19.1485, 51.0082],
            [19.1286, 51.0153],
            [19.1065, 51.0398],
            [19.0915, 51.0453],
            [19.0776, 51.0385],
            [19.0681, 51.0382],
            [19.064, 51.0485],
            [19.0468, 51.0589],
            [19.0117, 51.0766],
            [18.9505, 51.1019],
            [18.9389, 51.1001],
            [18.9405, 51.0934],
            [18.9182, 51.0984],
            [18.8989, 51.0776],
            [18.8949, 51.0663],
            [18.8824, 51.0601],
            [18.8757, 51.0678],
            [18.8612, 51.0736],
            [18.8182, 51.0689],
            [18.8, 51.0713],
            [18.8022, 51.0644],
            [18.7758, 51.0657],
            [18.7567, 51.0622],
            [18.7147, 51.0682],
            [18.7009, 51.0628],
            [18.6659, 51.0548],
            [18.6598, 51.0549],
            [18.6383, 51.0633],
            [18.6241, 51.0765],
            [18.6013, 51.0825],
            [18.5839, 51.0842],
            [18.5794, 51.0926],
            [18.5623, 51.1116],
            [18.5679, 51.1197],
            [18.5526, 51.1389],
            [18.5235, 51.1396],
            [18.5167, 51.1335],
            [18.5172, 51.1219],
            [18.5224, 51.119],
            [18.515, 51.1049],
            [18.4992, 51.0996],
            [18.4901, 51.0914],
            [18.4833, 51.0996],
            [18.4713, 51.1047],
            [18.4679, 51.1147],
            [18.4379, 51.1139],
            [18.4233, 51.1166],
            [18.4182, 51.1259],
            [18.4046, 51.1239],
            [18.3795, 51.1297],
            [18.3682, 51.1358],
            [18.3339, 51.1389],
            [18.3149, 51.1334],
            [18.3061, 51.1348],
            [18.2913, 51.1459],
            [18.2732, 51.1504],
            [18.261, 51.1575],
            [18.2406, 51.1596],
            [18.207, 51.1552],
            [18.1685, 51.1652],
            [18.1604, 51.1753],
            [18.1764, 51.1957],
            [18.1704, 51.2041],
            [18.1728, 51.216],
            [18.1872, 51.2299],
            [18.1883, 51.2371],
            [18.1821, 51.2505],
            [18.1849, 51.2679],
            [18.1825, 51.2744],
            [18.1698, 51.2723],
            [18.1569, 51.2634],
            [18.144, 51.2707],
            [18.1349, 51.2719],
            [18.1237, 51.2656],
            [18.1145, 51.2665],
            [18.1122, 51.2923],
            [18.1158, 51.3068],
            [18.1133, 51.3157],
            [18.0985, 51.3303],
            [18.0839, 51.3563],
            [18.0972, 51.3649],
            [18.107, 51.3655],
            [18.1292, 51.3616],
            [18.1416, 51.3856],
            [18.1511, 51.3909],
            [18.1966, 51.3915],
            [18.2031, 51.3939],
            [18.214, 51.4068],
            [18.2157, 51.4239],
            [18.2103, 51.4339],
            [18.1972, 51.4387],
            [18.2042, 51.4459],
            [18.2199, 51.454],
            [18.238, 51.4521],
            [18.3033, 51.4308],
            [18.3266, 51.4198],
            [18.3405, 51.4305],
            [18.3403, 51.44],
            [18.3567, 51.4557],
            [18.3571, 51.4664],
            [18.3814, 51.4762],
            [18.3831, 51.4827],
            [18.3803, 51.4989],
            [18.381, 51.5177],
            [18.3675, 51.5248],
            [18.3575, 51.5342],
            [18.3699, 51.5439],
            [18.365, 51.563],
            [18.3727, 51.5784],
            [18.3702, 51.5899],
            [18.3764, 51.5995],
            [18.3556, 51.6002],
            [18.3525, 51.6065],
            [18.3689, 51.6173],
            [18.3624, 51.6258],
            [18.3511, 51.627],
            [18.3527, 51.6338],
            [18.3701, 51.6338],
            [18.3761, 51.639],
            [18.3824, 51.6531],
            [18.3548, 51.6602],
            [18.358, 51.6732],
            [18.3655, 51.6821],
            [18.3799, 51.6848],
            [18.3801, 51.6958],
            [18.3987, 51.6951],
            [18.4122, 51.7065],
            [18.4063, 51.7258],
            [18.4205, 51.7251],
            [18.4232, 51.7363],
            [18.414, 51.748],
            [18.4214, 51.7584],
            [18.432, 51.7641],
            [18.4253, 51.7771],
            [18.4129, 51.79],
            [18.4357, 51.793],
            [18.4357, 51.8022],
            [18.4411, 51.8081],
            [18.4406, 51.826],
            [18.4503, 51.8262],
            [18.4705, 51.8202],
            [18.4656, 51.8371],
            [18.4736, 51.8513],
            [18.4821, 51.8562],
            [18.5037, 51.8575],
            [18.5162, 51.8348],
            [18.5311, 51.8341],
            [18.5355, 51.8402],
            [18.5483, 51.8444],
            [18.5691, 51.8433],
            [18.5952, 51.8467],
            [18.6139, 51.8346],
            [18.6312, 51.8293],
            [18.6425, 51.8338],
            [18.649, 51.8257],
            [18.6887, 51.825],
            [18.6975, 51.8546],
            [18.6978, 51.8654],
            [18.7145, 51.868],
            [18.721, 51.8773],
            [18.7395, 51.8935],
            [18.7367, 51.9005],
            [18.7477, 51.9066],
            [18.7605, 51.9212],
            [18.7704, 51.9179],
            [18.7908, 51.9334],
            [18.7541, 51.9462],
            [18.7395, 51.9616],
            [18.7243, 51.9713],
            [18.7248, 51.9784],
            [18.735, 51.9834],
            [18.7339, 51.9906],
            [18.7249, 52.0011],
            [18.7254, 52.0165],
            [18.7146, 52.0298],
            [18.7097, 52.0472],
            [18.7109, 52.0681],
            [18.7367, 52.0799],
            [18.7451, 52.0795],
            [18.7559, 52.072],
            [18.7781, 52.0692],
            [18.8096, 52.0738],
            [18.8139, 52.063],
            [18.8274, 52.0621],
            [18.8364, 52.0657],
            [18.8357, 52.0856],
            [18.8462, 52.0903],
            [18.8714, 52.0936],
            [18.9052, 52.0925],
            [18.9228, 52.0894],
            [18.9288, 52.0939],
            [18.9285, 52.1097],
            [18.9441, 52.1205],
            [18.9403, 52.1359],
            [18.9201, 52.1363],
            [18.9098, 52.1453],
            [18.9094, 52.1588],
            [18.9196, 52.1993],
            [18.9288, 52.2077],
            [18.9466, 52.2091],
            [18.9621, 52.2176],
            [18.9797, 52.2191],
            [18.9937, 52.2236],
            [19.0115, 52.2196],
            [19.0482, 52.2206],
            [19.0894, 52.2072],
            [19.0802, 52.231],
            [19.0816, 52.2387],
            [19.1052, 52.2423],
            [19.1004, 52.2668],
            [19.0557, 52.2743],
            [19.0327, 52.2705],
            [19.0459, 52.2962],
            [19.0394, 52.3008],
            [19.0369, 52.3167],
            [19.0453, 52.3194],
            [19.0469, 52.3331],
            [19.1035, 52.3486],
            [19.1208, 52.3446],
            [19.1484, 52.3461],
            [19.1597, 52.3414],
            [19.1805, 52.3494],
            [19.2019, 52.3513],
            [19.218, 52.3421],
            [19.2247, 52.3453],
            [19.2097, 52.3541],
            [19.2106, 52.3653],
            [19.2275, 52.3618],
            [19.2633, 52.3843],
            [19.2618, 52.388],
            [19.2905, 52.3977],
          ],
        ],
      },
      properties: {
        name: 'Łódzkie',
        id: 'PL-10',
        NAME_ENG: 'Łódź',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-10',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.1334, 52.2857],
            [23.1572, 52.2806],
            [23.1802, 52.284],
            [23.2002, 52.2791],
            [23.1992, 52.2682],
            [23.2046, 52.2573],
            [23.1961, 52.2446],
            [23.1958, 52.2382],
            [23.2058, 52.2268],
            [23.2423, 52.2213],
            [23.2656, 52.2154],
            [23.3023, 52.2224],
            [23.3014, 52.2124],
            [23.3113, 52.2095],
            [23.3266, 52.2111],
            [23.3377, 52.2064],
            [23.3697, 52.2066],
            [23.3708, 52.1983],
            [23.3822, 52.1958],
            [23.392, 52.2012],
            [23.4008, 52.1921],
            [23.3987, 52.1796],
            [23.417, 52.1823],
            [23.422, 52.1746],
            [23.4314, 52.1738],
            [23.447, 52.1841],
            [23.4621, 52.1844],
            [23.4627, 52.1767],
            [23.473, 52.1756],
            [23.4895, 52.1826],
            [23.5012, 52.1799],
            [23.5052, 52.1727],
            [23.488, 52.1668],
            [23.4876, 52.1532],
            [23.4995, 52.1476],
            [23.5147, 52.1451],
            [23.5144, 52.138],
            [23.5228, 52.1325],
            [23.5336, 52.1324],
            [23.5414, 52.1269],
            [23.5485, 52.1108],
            [23.5554, 52.1176],
            [23.5682, 52.1165],
            [23.5801, 52.1196],
            [23.6005, 52.1169],
            [23.6101, 52.1087],
            [23.6168, 52.0929],
            [23.652, 52.0809],
            [23.6451, 52.0574],
            [23.6536, 52.0554],
            [23.6624, 52.0397],
            [23.6599, 52.018],
            [23.6768, 52.0035],
            [23.6847, 51.9999],
            [23.6849, 51.9867],
            [23.6698, 51.9805],
            [23.6655, 51.9733],
            [23.6713, 51.9664],
            [23.6527, 51.9644],
            [23.64, 51.9491],
            [23.6447, 51.9376],
            [23.6343, 51.9316],
            [23.6316, 51.9219],
            [23.617, 51.9228],
            [23.6121, 51.9171],
            [23.6175, 51.9036],
            [23.6321, 51.8992],
            [23.6362, 51.8911],
            [23.6143, 51.8783],
            [23.6063, 51.8767],
            [23.6082, 51.8635],
            [23.6142, 51.8567],
            [23.6074, 51.8296],
            [23.6182, 51.8309],
            [23.6218, 51.8204],
            [23.6287, 51.8173],
            [23.6395, 51.8042],
            [23.6309, 51.787],
            [23.6302, 51.78],
            [23.6172, 51.7779],
            [23.6147, 51.7724],
            [23.5958, 51.771],
            [23.5866, 51.7592],
            [23.572, 51.7566],
            [23.5571, 51.7587],
            [23.5435, 51.7469],
            [23.5307, 51.742],
            [23.5281, 51.7296],
            [23.5354, 51.7228],
            [23.5514, 51.715],
            [23.5508, 51.7015],
            [23.5587, 51.6978],
            [23.5466, 51.6851],
            [23.5549, 51.6804],
            [23.5552, 51.6692],
            [23.5397, 51.6557],
            [23.5384, 51.6463],
            [23.5469, 51.6415],
            [23.5434, 51.6308],
            [23.5467, 51.6211],
            [23.5405, 51.6032],
            [23.5683, 51.5768],
            [23.5643, 51.5709],
            [23.5773, 51.5581],
            [23.5631, 51.5469],
            [23.5656, 51.5351],
            [23.5859, 51.5302],
            [23.5945, 51.5345],
            [23.6098, 51.5292],
            [23.6232, 51.5158],
            [23.6182, 51.5061],
            [23.6299, 51.4949],
            [23.6451, 51.4858],
            [23.6594, 51.4905],
            [23.6713, 51.4829],
            [23.6668, 51.4672],
            [23.65, 51.4598],
            [23.6531, 51.4458],
            [23.6835, 51.4438],
            [23.6851, 51.4343],
            [23.6791, 51.4317],
            [23.6924, 51.4198],
            [23.7025, 51.4208],
            [23.699, 51.4066],
            [23.6866, 51.4001],
            [23.6795, 51.3915],
            [23.6865, 51.3703],
            [23.6694, 51.3684],
            [23.6591, 51.3601],
            [23.6566, 51.3471],
            [23.6454, 51.335],
            [23.6387, 51.32],
            [23.6424, 51.3104],
            [23.6528, 51.3023],
            [23.6563, 51.2902],
            [23.6795, 51.293],
            [23.6954, 51.2886],
            [23.7164, 51.2753],
            [23.721, 51.2657],
            [23.7298, 51.2573],
            [23.7261, 51.2485],
            [23.7408, 51.2325],
            [23.745, 51.2162],
            [23.7578, 51.2133],
            [23.7619, 51.2044],
            [23.7832, 51.1992],
            [23.7868, 51.19],
            [23.8092, 51.1856],
            [23.8153, 51.1718],
            [23.8301, 51.1618],
            [23.8385, 51.1639],
            [23.8613, 51.159],
            [23.8708, 51.146],
            [23.8693, 51.1314],
            [23.854, 51.1302],
            [23.8522, 51.1246],
            [23.8625, 51.1041],
            [23.8722, 51.0948],
            [23.8767, 51.0789],
            [23.8872, 51.0826],
            [23.8942, 51.0761],
            [23.9088, 51.0772],
            [23.9133, 51.0708],
            [23.9173, 51.041],
            [23.9307, 51.0366],
            [23.9176, 51.0305],
            [23.9283, 51.0204],
            [23.9184, 51.007],
            [23.9312, 51.0072],
            [23.9589, 50.9907],
            [23.9692, 50.9785],
            [23.9702, 50.9567],
            [23.9727, 50.9518],
            [23.9905, 50.9448],
            [23.9985, 50.9307],
            [24.016, 50.9195],
            [24.0269, 50.9166],
            [24.0428, 50.9042],
            [24.0527, 50.8999],
            [24.0543, 50.8935],
            [24.0897, 50.8899],
            [24.1025, 50.8775],
            [24.1146, 50.8756],
            [24.1291, 50.869],
            [24.1487, 50.8677],
            [24.1449, 50.8599],
            [24.1336, 50.8509],
            [24.1172, 50.843],
            [24.1, 50.8381],
            [24.074, 50.8353],
            [24.0525, 50.8373],
            [24.0465, 50.8354],
            [24.0311, 50.8425],
            [24.02, 50.8402],
            [24.006, 50.8419],
            [23.9879, 50.8359],
            [23.9758, 50.8245],
            [23.9684, 50.8124],
            [23.966, 50.8001],
            [23.9604, 50.7927],
            [23.9694, 50.7872],
            [23.9661, 50.782],
            [23.9827, 50.7723],
            [23.9995, 50.7696],
            [24.0185, 50.7719],
            [24.0151, 50.7538],
            [24.0241, 50.7467],
            [24.0192, 50.7393],
            [24.0265, 50.7258],
            [24.0424, 50.7198],
            [24.0696, 50.7218],
            [24.0767, 50.7116],
            [24.0716, 50.7082],
            [24.0745, 50.6995],
            [24.066, 50.6794],
            [24.0739, 50.6789],
            [24.0891, 50.6714],
            [24.0871, 50.6513],
            [24.0813, 50.6453],
            [24.0934, 50.6359],
            [24.0991, 50.6366],
            [24.0938, 50.6174],
            [24.1002, 50.6087],
            [24.0999, 50.597],
            [24.0925, 50.5761],
            [24.0932, 50.5624],
            [24.0717, 50.5036],
            [24.0356, 50.4456],
            [24.0067, 50.4349],
            [23.9991, 50.4116],
            [23.9414, 50.4139],
            [23.8799, 50.408],
            [23.8073, 50.4043],
            [23.7776, 50.4002],
            [23.729, 50.388],
            [23.7109, 50.3799],
            [23.7012, 50.3725],
            [23.6926, 50.3557],
            [23.6863, 50.3312],
            [23.6383, 50.3187],
            [23.6281, 50.3037],
            [23.612, 50.2983],
            [23.5835, 50.2662],
            [23.5434, 50.2487],
            [23.5428, 50.2575],
            [23.5294, 50.2624],
            [23.5084, 50.2612],
            [23.5154, 50.2709],
            [23.5143, 50.2783],
            [23.5035, 50.2805],
            [23.4998, 50.287],
            [23.5156, 50.2885],
            [23.5187, 50.2956],
            [23.492, 50.2992],
            [23.4698, 50.2941],
            [23.4598, 50.3033],
            [23.4317, 50.3036],
            [23.4145, 50.3077],
            [23.4171, 50.3206],
            [23.4322, 50.337],
            [23.4363, 50.347],
            [23.4118, 50.3524],
            [23.4008, 50.3645],
            [23.3783, 50.3699],
            [23.3734, 50.3775],
            [23.3831, 50.3822],
            [23.3672, 50.3912],
            [23.3697, 50.4012],
            [23.3836, 50.4004],
            [23.3872, 50.4067],
            [23.358, 50.4121],
            [23.3546, 50.4044],
            [23.3371, 50.4001],
            [23.3185, 50.3925],
            [23.3038, 50.3809],
            [23.2675, 50.3723],
            [23.2607, 50.3655],
            [23.2469, 50.3681],
            [23.2326, 50.3745],
            [23.2151, 50.3885],
            [23.1825, 50.3972],
            [23.1413, 50.3664],
            [23.0942, 50.3343],
            [23.074, 50.3272],
            [23.0601, 50.317],
            [23.0493, 50.3035],
            [23.019, 50.2884],
            [22.9925, 50.2946],
            [22.9764, 50.2955],
            [22.9436, 50.2926],
            [22.8917, 50.3016],
            [22.8721, 50.2998],
            [22.8519, 50.2947],
            [22.8238, 50.3019],
            [22.7851, 50.3015],
            [22.752, 50.3024],
            [22.7284, 50.3003],
            [22.7135, 50.2941],
            [22.7037, 50.294],
            [22.6558, 50.3029],
            [22.647, 50.3068],
            [22.6371, 50.3038],
            [22.6089, 50.3093],
            [22.6067, 50.3267],
            [22.6252, 50.3346],
            [22.6588, 50.3375],
            [22.6863, 50.346],
            [22.6875, 50.3545],
            [22.6535, 50.3556],
            [22.6397, 50.3575],
            [22.6217, 50.3554],
            [22.596, 50.3494],
            [22.5788, 50.3558],
            [22.5649, 50.3574],
            [22.5374, 50.3514],
            [22.5179, 50.3502],
            [22.5062, 50.3444],
            [22.5028, 50.3527],
            [22.4824, 50.3556],
            [22.4509, 50.3718],
            [22.4532, 50.3885],
            [22.4354, 50.3929],
            [22.4382, 50.4002],
            [22.4206, 50.401],
            [22.408, 50.3977],
            [22.4122, 50.4234],
            [22.4459, 50.4198],
            [22.451, 50.4327],
            [22.4596, 50.435],
            [22.4585, 50.4509],
            [22.4459, 50.4621],
            [22.4632, 50.4652],
            [22.4628, 50.4596],
            [22.4879, 50.4555],
            [22.5053, 50.4545],
            [22.5373, 50.4552],
            [22.5455, 50.4534],
            [22.5557, 50.4607],
            [22.5613, 50.4694],
            [22.5812, 50.4754],
            [22.5821, 50.4833],
            [22.5433, 50.4768],
            [22.5399, 50.4834],
            [22.5697, 50.4943],
            [22.5745, 50.4981],
            [22.5686, 50.5092],
            [22.544, 50.5027],
            [22.5224, 50.5084],
            [22.5349, 50.516],
            [22.5265, 50.5212],
            [22.5283, 50.5332],
            [22.5211, 50.5336],
            [22.5203, 50.5497],
            [22.5351, 50.5538],
            [22.5357, 50.5716],
            [22.5188, 50.5842],
            [22.5061, 50.5749],
            [22.4996, 50.5744],
            [22.4858, 50.5821],
            [22.4802, 50.5921],
            [22.4728, 50.5917],
            [22.4438, 50.5816],
            [22.4278, 50.5821],
            [22.4028, 50.6041],
            [22.3851, 50.6067],
            [22.3583, 50.6209],
            [22.3448, 50.6244],
            [22.2984, 50.6265],
            [22.2683, 50.6259],
            [22.2467, 50.6502],
            [22.2198, 50.6694],
            [22.1909, 50.6673],
            [22.1535, 50.6692],
            [22.1471, 50.6747],
            [22.1499, 50.6812],
            [22.1687, 50.6952],
            [22.1736, 50.714],
            [22.2177, 50.7606],
            [22.2144, 50.7691],
            [22.1931, 50.7715],
            [22.1846, 50.7759],
            [22.1865, 50.797],
            [22.1804, 50.804],
            [22.1529, 50.8079],
            [22.1038, 50.8206],
            [22.0754, 50.8236],
            [22.0212, 50.7884],
            [21.9921, 50.7887],
            [21.9726, 50.7785],
            [21.9115, 50.777],
            [21.8954, 50.7816],
            [21.8845, 50.7989],
            [21.8688, 50.8102],
            [21.8668, 50.8229],
            [21.8472, 50.8343],
            [21.8428, 50.8406],
            [21.8474, 50.8675],
            [21.8394, 50.8773],
            [21.8425, 50.8882],
            [21.8348, 50.9232],
            [21.8277, 50.9376],
            [21.8292, 50.9775],
            [21.8185, 50.9806],
            [21.8225, 50.9924],
            [21.8171, 51.0101],
            [21.8003, 51.0205],
            [21.8064, 51.0315],
            [21.8181, 51.0387],
            [21.8244, 51.0477],
            [21.8248, 51.0573],
            [21.8074, 51.0675],
            [21.799, 51.074],
            [21.799, 51.0911],
            [21.794, 51.103],
            [21.7966, 51.1125],
            [21.7947, 51.1289],
            [21.805, 51.1327],
            [21.8102, 51.1505],
            [21.8202, 51.1564],
            [21.8047, 51.17],
            [21.7877, 51.18],
            [21.7894, 51.1965],
            [21.7991, 51.208],
            [21.8013, 51.2154],
            [21.8136, 51.2201],
            [21.8178, 51.2335],
            [21.8088, 51.2428],
            [21.8069, 51.2533],
            [21.8251, 51.2678],
            [21.8313, 51.2893],
            [21.8205, 51.2918],
            [21.8016, 51.2901],
            [21.7949, 51.2942],
            [21.8091, 51.2999],
            [21.8245, 51.3026],
            [21.8247, 51.313],
            [21.8371, 51.3339],
            [21.8253, 51.3381],
            [21.8462, 51.3547],
            [21.8525, 51.3549],
            [21.8378, 51.3786],
            [21.8326, 51.3815],
            [21.8068, 51.3757],
            [21.7937, 51.3863],
            [21.7888, 51.3965],
            [21.7686, 51.3925],
            [21.7602, 51.4009],
            [21.7684, 51.413],
            [21.781, 51.4123],
            [21.7878, 51.4198],
            [21.8029, 51.4276],
            [21.8277, 51.4263],
            [21.8396, 51.4173],
            [21.8495, 51.4291],
            [21.8547, 51.4479],
            [21.8702, 51.4655],
            [21.8727, 51.4749],
            [21.8559, 51.4795],
            [21.8517, 51.4859],
            [21.8523, 51.5089],
            [21.849, 51.5206],
            [21.8355, 51.5327],
            [21.8306, 51.5532],
            [21.819, 51.5647],
            [21.7967, 51.5691],
            [21.7827, 51.5687],
            [21.7529, 51.5591],
            [21.7122, 51.5634],
            [21.679, 51.5749],
            [21.6649, 51.5814],
            [21.6422, 51.6072],
            [21.6167, 51.618],
            [21.6397, 51.6263],
            [21.6408, 51.6429],
            [21.636, 51.6523],
            [21.6475, 51.658],
            [21.6553, 51.6569],
            [21.6895, 51.6428],
            [21.7099, 51.6371],
            [21.7212, 51.6366],
            [21.7261, 51.6188],
            [21.7499, 51.6118],
            [21.7579, 51.6366],
            [21.7612, 51.6408],
            [21.7756, 51.6418],
            [21.7914, 51.6506],
            [21.8035, 51.6474],
            [21.8241, 51.6476],
            [21.8318, 51.6525],
            [21.8425, 51.6527],
            [21.8407, 51.667],
            [21.8558, 51.6699],
            [21.8606, 51.6778],
            [21.8776, 51.6774],
            [21.8789, 51.6924],
            [21.8974, 51.6995],
            [21.9058, 51.6954],
            [21.9116, 51.7109],
            [21.9107, 51.7165],
            [21.8961, 51.7157],
            [21.8855, 51.7235],
            [21.8839, 51.7394],
            [21.8776, 51.7436],
            [21.8549, 51.7462],
            [21.8517, 51.7551],
            [21.869, 51.7615],
            [21.8782, 51.7679],
            [21.904, 51.7717],
            [21.9169, 51.7802],
            [21.9336, 51.7808],
            [21.9622, 51.7981],
            [21.9513, 51.8105],
            [21.9454, 51.8253],
            [21.9302, 51.8412],
            [21.9197, 51.843],
            [21.8998, 51.834],
            [21.8733, 51.829],
            [21.8615, 51.8319],
            [21.8534, 51.8527],
            [21.8641, 51.8576],
            [21.89, 51.8606],
            [21.9004, 51.8715],
            [21.9115, 51.89],
            [21.9125, 51.9039],
            [21.9023, 51.9166],
            [21.8939, 51.9225],
            [21.8795, 51.9431],
            [21.8782, 51.9585],
            [21.8854, 51.9748],
            [21.8929, 51.9831],
            [21.9264, 51.9996],
            [21.9509, 52.0053],
            [21.9735, 52.0045],
            [21.989, 52.0075],
            [22.0045, 52.0184],
            [22.043, 52.0369],
            [22.062, 52.0323],
            [22.1048, 52.0162],
            [22.1302, 52.0156],
            [22.1413, 52.0235],
            [22.1465, 52.0344],
            [22.1552, 52.0407],
            [22.1702, 52.0402],
            [22.2547, 52.0233],
            [22.2681, 52.0238],
            [22.2855, 52.029],
            [22.3062, 52.0502],
            [22.3294, 52.0502],
            [22.3367, 52.0271],
            [22.3479, 52.0178],
            [22.3577, 52.0175],
            [22.3776, 52.0252],
            [22.4231, 52.0249],
            [22.4471, 52.041],
            [22.464, 52.0481],
            [22.4814, 52.0677],
            [22.4989, 52.0739],
            [22.5196, 52.0723],
            [22.5264, 52.068],
            [22.534, 52.0562],
            [22.5588, 52.0505],
            [22.5708, 52.0432],
            [22.5959, 52.0385],
            [22.613, 52.029],
            [22.6235, 52.0155],
            [22.6458, 52.0325],
            [22.6601, 52.0368],
            [22.67, 52.0626],
            [22.6646, 52.0817],
            [22.6491, 52.09],
            [22.678, 52.1117],
            [22.6934, 52.1205],
            [22.7056, 52.1232],
            [22.7162, 52.1215],
            [22.7445, 52.1084],
            [22.7699, 52.1053],
            [22.7789, 52.1],
            [22.8027, 52.0786],
            [22.8101, 52.0771],
            [22.834, 52.0913],
            [22.8513, 52.0903],
            [22.8624, 52.0825],
            [22.8744, 52.0821],
            [22.8855, 52.0726],
            [22.9082, 52.0752],
            [22.9162, 52.0857],
            [22.9088, 52.0994],
            [22.9086, 52.1069],
            [22.9231, 52.1205],
            [22.9433, 52.1214],
            [22.9601, 52.1139],
            [22.9711, 52.1169],
            [22.9711, 52.1238],
            [22.9459, 52.1415],
            [22.9534, 52.1524],
            [22.9862, 52.1676],
            [22.9951, 52.1808],
            [23.0011, 52.1838],
            [23.0556, 52.1816],
            [23.0801, 52.1765],
            [23.0797, 52.1859],
            [23.0598, 52.1904],
            [23.0452, 52.1979],
            [23.0379, 52.2122],
            [23.0424, 52.2234],
            [23.0681, 52.2385],
            [23.0925, 52.2482],
            [23.1334, 52.2857],
          ],
        ],
      },
      properties: {
        name: 'Lubelskie',
        id: 'PL-06',
        NAME_ENG: 'Lublin',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-06',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.6007, 52.6067],
            [14.602, 52.6161],
            [14.5985, 52.6355],
            [14.6037, 52.6462],
            [14.6338, 52.6601],
            [14.653, 52.6617],
            [14.6831, 52.6401],
            [14.711, 52.6346],
            [14.7276, 52.6376],
            [14.7481, 52.6489],
            [14.7518, 52.6666],
            [14.7571, 52.6755],
            [14.7808, 52.6899],
            [14.79, 52.7021],
            [14.7928, 52.7169],
            [14.7944, 52.7527],
            [14.8014, 52.7636],
            [14.8153, 52.7677],
            [14.8378, 52.7687],
            [14.8484, 52.7732],
            [14.8608, 52.7863],
            [14.8687, 52.8037],
            [14.8552, 52.8057],
            [14.8515, 52.819],
            [14.8537, 52.8253],
            [14.8717, 52.8259],
            [14.8741, 52.8399],
            [14.8894, 52.8412],
            [14.9007, 52.8303],
            [14.9173, 52.8373],
            [14.916, 52.8449],
            [14.8837, 52.8562],
            [14.8868, 52.8623],
            [14.9014, 52.8669],
            [14.9101, 52.8807],
            [14.9297, 52.8822],
            [14.9337, 52.875],
            [14.9428, 52.8752],
            [14.9576, 52.8661],
            [14.9805, 52.8595],
            [14.9986, 52.8423],
            [15.0089, 52.8401],
            [15.0139, 52.8514],
            [15.0381, 52.8582],
            [15.0514, 52.8534],
            [15.0651, 52.8373],
            [15.0749, 52.8325],
            [15.1591, 52.851],
            [15.1983, 52.8631],
            [15.2273, 52.8693],
            [15.2798, 52.8822],
            [15.3265, 52.8962],
            [15.3316, 52.9062],
            [15.3207, 52.9085],
            [15.3022, 52.9261],
            [15.2854, 52.9515],
            [15.2862, 52.9583],
            [15.299, 52.9614],
            [15.3215, 52.958],
            [15.3463, 52.9502],
            [15.3658, 52.9503],
            [15.3737, 52.9536],
            [15.4073, 52.9828],
            [15.4203, 52.9878],
            [15.4594, 52.9961],
            [15.4714, 52.9971],
            [15.5175, 53.0083],
            [15.5543, 53.0157],
            [15.5712, 53.0117],
            [15.5866, 52.9937],
            [15.6476, 52.9842],
            [15.6523, 52.9853],
            [15.6789, 53.0019],
            [15.6908, 53.0062],
            [15.7051, 53.0067],
            [15.7248, 52.9947],
            [15.7662, 53.0054],
            [15.7667, 53.0109],
            [15.7496, 53.0188],
            [15.7496, 53.0256],
            [15.7596, 53.0297],
            [15.7744, 53.0305],
            [15.7851, 53.0386],
            [15.8035, 53.0394],
            [15.8042, 53.0445],
            [15.7779, 53.0563],
            [15.7774, 53.0615],
            [15.7973, 53.0619],
            [15.8186, 53.0665],
            [15.8221, 53.0777],
            [15.8088, 53.0788],
            [15.8098, 53.0859],
            [15.8287, 53.0917],
            [15.8577, 53.0973],
            [15.8619, 53.104],
            [15.8527, 53.1184],
            [15.8535, 53.1226],
            [15.885, 53.1229],
            [15.9047, 53.1258],
            [15.9289, 53.1177],
            [15.9571, 53.112],
            [15.9866, 53.1035],
            [15.9861, 53.0941],
            [15.995, 53.0914],
            [15.9935, 53.0747],
            [15.9859, 53.0698],
            [15.9776, 53.0491],
            [15.962, 53.0409],
            [15.947, 53.0397],
            [15.955, 53.0163],
            [15.9513, 52.9924],
            [15.9548, 52.9906],
            [15.9513, 52.9727],
            [15.9619, 52.9716],
            [15.9619, 52.957],
            [15.9789, 52.9338],
            [15.9777, 52.9237],
            [15.981, 52.9093],
            [15.969, 52.899],
            [15.9801, 52.8818],
            [15.9735, 52.8653],
            [15.9757, 52.8585],
            [15.9474, 52.8537],
            [15.9518, 52.8442],
            [15.9243, 52.8326],
            [15.9189, 52.8408],
            [15.9056, 52.8381],
            [15.8973, 52.8252],
            [15.8941, 52.8114],
            [15.9114, 52.8037],
            [15.9349, 52.8023],
            [15.9382, 52.787],
            [15.9274, 52.7854],
            [15.9396, 52.7632],
            [15.9477, 52.7575],
            [15.9491, 52.7443],
            [15.9453, 52.7309],
            [15.9395, 52.7281],
            [15.9027, 52.7195],
            [15.8627, 52.7146],
            [15.8068, 52.7116],
            [15.8054, 52.7034],
            [15.8218, 52.6846],
            [15.8223, 52.677],
            [15.8033, 52.6745],
            [15.7962, 52.6552],
            [15.7959, 52.6446],
            [15.8027, 52.6358],
            [15.8017, 52.6298],
            [15.7866, 52.613],
            [15.7858, 52.6075],
            [15.8063, 52.5925],
            [15.8207, 52.5857],
            [15.824, 52.5786],
            [15.8185, 52.5634],
            [15.8289, 52.5574],
            [15.8531, 52.5506],
            [15.855, 52.5437],
            [15.8321, 52.529],
            [15.8319, 52.5236],
            [15.8474, 52.5119],
            [15.8684, 52.5091],
            [15.8845, 52.4917],
            [15.8918, 52.4688],
            [15.8809, 52.4699],
            [15.879, 52.4617],
            [15.8995, 52.4514],
            [15.9019, 52.4437],
            [15.8864, 52.4398],
            [15.8779, 52.432],
            [15.8629, 52.4391],
            [15.8567, 52.4309],
            [15.8474, 52.4353],
            [15.8373, 52.4456],
            [15.8354, 52.4533],
            [15.8245, 52.4538],
            [15.8156, 52.4399],
            [15.8191, 52.4309],
            [15.8406, 52.4203],
            [15.8489, 52.4191],
            [15.8638, 52.4048],
            [15.8954, 52.3974],
            [15.9046, 52.3928],
            [15.9057, 52.3827],
            [15.8976, 52.362],
            [15.8985, 52.3292],
            [15.8904, 52.3234],
            [15.8927, 52.311],
            [15.8791, 52.2992],
            [15.8804, 52.2777],
            [15.8575, 52.2611],
            [15.8684, 52.2424],
            [15.8698, 52.2323],
            [15.8591, 52.2162],
            [15.8584, 52.2003],
            [15.8663, 52.1739],
            [15.882, 52.1508],
            [15.8807, 52.1403],
            [15.8565, 52.1312],
            [15.8514, 52.1231],
            [15.8565, 52.1],
            [15.8708, 52.0856],
            [15.8802, 52.0817],
            [15.9131, 52.0734],
            [15.9223, 52.0653],
            [15.9301, 52.0762],
            [15.9432, 52.0826],
            [15.9641, 52.0791],
            [15.9801, 52.0721],
            [15.9907, 52.0573],
            [15.9888, 52.0492],
            [15.9798, 52.0427],
            [15.9587, 52.0392],
            [15.9609, 52.0178],
            [15.9795, 52.0001],
            [15.9997, 51.998],
            [16.006, 51.9949],
            [16.0186, 51.9744],
            [16.0263, 51.972],
            [16.0533, 51.972],
            [16.0642, 51.9808],
            [16.0753, 51.9842],
            [16.093, 51.9969],
            [16.1102, 51.9961],
            [16.1224, 51.9914],
            [16.1351, 51.9671],
            [16.1363, 51.9575],
            [16.1236, 51.9553],
            [16.1311, 51.9441],
            [16.1267, 51.9335],
            [16.1374, 51.9271],
            [16.1319, 51.9174],
            [16.1193, 51.9139],
            [16.1006, 51.8953],
            [16.1241, 51.8962],
            [16.162, 51.8918],
            [16.1704, 51.8826],
            [16.1922, 51.8799],
            [16.2012, 51.8699],
            [16.2196, 51.8764],
            [16.2384, 51.8993],
            [16.2463, 51.9055],
            [16.2613, 51.9061],
            [16.2717, 51.8966],
            [16.2954, 51.8974],
            [16.3066, 51.8867],
            [16.3425, 51.8756],
            [16.3398, 51.8655],
            [16.3444, 51.8609],
            [16.3755, 51.8546],
            [16.3848, 51.8508],
            [16.3894, 51.8355],
            [16.4014, 51.8276],
            [16.4025, 51.8159],
            [16.3989, 51.8012],
            [16.403, 51.7905],
            [16.421, 51.7843],
            [16.4073, 51.775],
            [16.3905, 51.7673],
            [16.3791, 51.7575],
            [16.3737, 51.7442],
            [16.3728, 51.723],
            [16.369, 51.7175],
            [16.3376, 51.7004],
            [16.3193, 51.6872],
            [16.2965, 51.684],
            [16.284, 51.6802],
            [16.2732, 51.6622],
            [16.2525, 51.6859],
            [16.237, 51.6891],
            [16.2211, 51.7107],
            [16.2104, 51.7155],
            [16.1863, 51.7155],
            [16.1726, 51.7095],
            [16.1615, 51.7111],
            [16.1606, 51.7184],
            [16.1666, 51.7288],
            [16.1773, 51.7643],
            [16.1579, 51.7595],
            [16.1355, 51.7597],
            [16.1334, 51.7651],
            [16.1181, 51.7641],
            [16.1002, 51.7665],
            [16.0742, 51.7752],
            [16.0713, 51.7833],
            [16.0564, 51.7819],
            [16.0342, 51.7842],
            [16.0359, 51.796],
            [16.0312, 51.8031],
            [16.0058, 51.8017],
            [15.9874, 51.8039],
            [15.9652, 51.7926],
            [15.9721, 51.7831],
            [15.9876, 51.7785],
            [16.0051, 51.781],
            [16.0078, 51.7734],
            [15.9884, 51.7694],
            [15.9683, 51.7685],
            [15.974, 51.7471],
            [15.9509, 51.7349],
            [15.9312, 51.7386],
            [15.9237, 51.7269],
            [15.9157, 51.7233],
            [15.9006, 51.7308],
            [15.8915, 51.7298],
            [15.8812, 51.72],
            [15.8708, 51.7215],
            [15.8662, 51.6933],
            [15.8433, 51.6814],
            [15.8446, 51.6766],
            [15.8201, 51.6694],
            [15.8414, 51.6675],
            [15.8566, 51.6587],
            [15.8531, 51.6501],
            [15.8372, 51.6448],
            [15.8301, 51.6378],
            [15.8339, 51.623],
            [15.8225, 51.6128],
            [15.8187, 51.6039],
            [15.8241, 51.5764],
            [15.8228, 51.5732],
            [15.7915, 51.5726],
            [15.7682, 51.5692],
            [15.7438, 51.5565],
            [15.7148, 51.5489],
            [15.7105, 51.5292],
            [15.7135, 51.5174],
            [15.6997, 51.5126],
            [15.6797, 51.5117],
            [15.6678, 51.4991],
            [15.6658, 51.4825],
            [15.6557, 51.4724],
            [15.645, 51.4782],
            [15.6252, 51.471],
            [15.6237, 51.4636],
            [15.6056, 51.45],
            [15.5868, 51.4491],
            [15.5817, 51.4531],
            [15.5952, 51.4686],
            [15.5743, 51.4814],
            [15.5442, 51.4959],
            [15.4903, 51.5268],
            [15.4849, 51.5131],
            [15.4692, 51.514],
            [15.4516, 51.5252],
            [15.4063, 51.5201],
            [15.4009, 51.5272],
            [15.386, 51.5272],
            [15.3833, 51.5119],
            [15.373, 51.5074],
            [15.3685, 51.4856],
            [15.3772, 51.4789],
            [15.3803, 51.469],
            [15.3444, 51.4701],
            [15.3396, 51.4559],
            [15.3426, 51.4453],
            [15.3158, 51.417],
            [15.2916, 51.4235],
            [15.2896, 51.4308],
            [15.2775, 51.429],
            [15.2649, 51.4415],
            [15.2518, 51.4481],
            [15.2405, 51.4454],
            [15.2102, 51.4439],
            [15.2001, 51.4472],
            [15.2031, 51.463],
            [15.1869, 51.4641],
            [15.1652, 51.4505],
            [15.1479, 51.4422],
            [15.1299, 51.4382],
            [15.117, 51.4315],
            [15.0955, 51.4263],
            [15.0846, 51.4195],
            [15.0834, 51.4083],
            [15.0761, 51.3971],
            [15.0558, 51.3923],
            [15.0172, 51.393],
            [15.0082, 51.3642],
            [14.9737, 51.3641],
            [14.9823, 51.3746],
            [14.968, 51.3802],
            [14.9631, 51.3941],
            [14.9672, 51.4024],
            [14.9588, 51.4105],
            [14.9668, 51.4175],
            [14.9634, 51.4337],
            [14.9725, 51.4353],
            [14.9731, 51.4424],
            [14.9655, 51.4485],
            [14.9565, 51.4633],
            [14.9465, 51.4728],
            [14.9272, 51.4742],
            [14.919, 51.4837],
            [14.8976, 51.4837],
            [14.8885, 51.4879],
            [14.8722, 51.4868],
            [14.8658, 51.4907],
            [14.8522, 51.4905],
            [14.8327, 51.5042],
            [14.7947, 51.5186],
            [14.7674, 51.5192],
            [14.7364, 51.5265],
            [14.7299, 51.531],
            [14.7276, 51.5518],
            [14.7119, 51.56],
            [14.7268, 51.5757],
            [14.7319, 51.5851],
            [14.7503, 51.595],
            [14.765, 51.6072],
            [14.7624, 51.6203],
            [14.754, 51.6278],
            [14.7561, 51.6448],
            [14.7517, 51.651],
            [14.7575, 51.66],
            [14.7476, 51.6754],
            [14.7381, 51.6785],
            [14.7394, 51.6846],
            [14.7277, 51.689],
            [14.7033, 51.7039],
            [14.691, 51.7082],
            [14.6777, 51.7225],
            [14.6691, 51.7257],
            [14.6576, 51.7428],
            [14.6629, 51.7583],
            [14.6536, 51.7604],
            [14.6531, 51.784],
            [14.6463, 51.7954],
            [14.6334, 51.8015],
            [14.624, 51.8013],
            [14.6032, 51.8073],
            [14.5986, 51.8168],
            [14.5914, 51.821],
            [14.5925, 51.8391],
            [14.611, 51.844],
            [14.6074, 51.8517],
            [14.613, 51.8569],
            [14.6262, 51.8581],
            [14.6518, 51.8732],
            [14.6562, 51.8846],
            [14.6645, 51.8898],
            [14.6949, 51.9016],
            [14.6937, 51.9088],
            [14.7058, 51.9231],
            [14.7052, 51.9305],
            [14.7207, 51.9427],
            [14.7206, 51.9549],
            [14.713, 51.9581],
            [14.7053, 51.9763],
            [14.717, 51.9829],
            [14.7253, 52.0134],
            [14.7405, 52.0237],
            [14.7482, 52.033],
            [14.7498, 52.0437],
            [14.7451, 52.0545],
            [14.7589, 52.0645],
            [14.7433, 52.0838],
            [14.7199, 52.0947],
            [14.7048, 52.0967],
            [14.6915, 52.1035],
            [14.6817, 52.1165],
            [14.6835, 52.1243],
            [14.6796, 52.1405],
            [14.6827, 52.1483],
            [14.7038, 52.1666],
            [14.703, 52.1781],
            [14.6866, 52.19],
            [14.6865, 52.1985],
            [14.7061, 52.2092],
            [14.7146, 52.2346],
            [14.6995, 52.2506],
            [14.688, 52.2567],
            [14.6595, 52.2625],
            [14.6437, 52.2634],
            [14.6198, 52.2706],
            [14.5967, 52.2724],
            [14.5745, 52.2933],
            [14.5844, 52.3038],
            [14.5742, 52.3228],
            [14.5613, 52.3291],
            [14.5622, 52.3382],
            [14.5527, 52.3542],
            [14.5514, 52.3757],
            [14.5353, 52.3919],
            [14.5344, 52.3989],
            [14.5445, 52.4102],
            [14.5438, 52.4222],
            [14.5491, 52.4336],
            [14.5619, 52.4398],
            [14.5798, 52.4421],
            [14.6098, 52.4665],
            [14.6128, 52.4775],
            [14.6292, 52.4864],
            [14.6353, 52.4951],
            [14.6318, 52.5017],
            [14.6154, 52.5089],
            [14.6042, 52.5297],
            [14.6193, 52.558],
            [14.6378, 52.5687],
            [14.6356, 52.5758],
            [14.6224, 52.5809],
            [14.6102, 52.5904],
            [14.6007, 52.6067],
          ],
        ],
      },
      properties: {
        name: 'Lubuskie',
        id: 'PL-08',
        NAME_ENG: 'Lubusz',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-08',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.9504, 50.5048],
            [19.9641, 50.5093],
            [19.9769, 50.5177],
            [19.9883, 50.5193],
            [20.0256, 50.5071],
            [20.042, 50.5005],
            [20.0646, 50.5076],
            [20.0834, 50.5063],
            [20.0973, 50.4899],
            [20.1188, 50.4858],
            [20.148, 50.491],
            [20.163, 50.4853],
            [20.1845, 50.4918],
            [20.2036, 50.4924],
            [20.2227, 50.4801],
            [20.2464, 50.4771],
            [20.2578, 50.47],
            [20.2744, 50.4659],
            [20.272, 50.443],
            [20.2736, 50.4338],
            [20.3014, 50.4172],
            [20.3167, 50.3934],
            [20.339, 50.3708],
            [20.3407, 50.3624],
            [20.3268, 50.3526],
            [20.2998, 50.3539],
            [20.2965, 50.3501],
            [20.3085, 50.3433],
            [20.3101, 50.3373],
            [20.2998, 50.3327],
            [20.3041, 50.3245],
            [20.327, 50.327],
            [20.3358, 50.3237],
            [20.354, 50.3098],
            [20.3584, 50.2973],
            [20.3687, 50.2945],
            [20.3693, 50.2883],
            [20.3458, 50.279],
            [20.3467, 50.2714],
            [20.3614, 50.2697],
            [20.359, 50.2556],
            [20.3676, 50.2486],
            [20.3979, 50.2403],
            [20.3929, 50.2353],
            [20.3774, 50.2296],
            [20.3741, 50.2219],
            [20.378, 50.2108],
            [20.3863, 50.2074],
            [20.3963, 50.2101],
            [20.4131, 50.2093],
            [20.4126, 50.1932],
            [20.4258, 50.1917],
            [20.4357, 50.1958],
            [20.4516, 50.1928],
            [20.4686, 50.1959],
            [20.4703, 50.2011],
            [20.4916, 50.1916],
            [20.5006, 50.1899],
            [20.5363, 50.1948],
            [20.5573, 50.2071],
            [20.5698, 50.206],
            [20.5769, 50.1986],
            [20.5889, 50.1981],
            [20.6077, 50.2084],
            [20.6292, 50.2114],
            [20.6418, 50.2188],
            [20.6701, 50.2122],
            [20.6806, 50.2173],
            [20.7001, 50.2174],
            [20.7049, 50.2253],
            [20.7275, 50.2304],
            [20.7284, 50.2495],
            [20.7526, 50.2642],
            [20.7681, 50.2678],
            [20.78, 50.2844],
            [20.7901, 50.2892],
            [20.8083, 50.2889],
            [20.8281, 50.2743],
            [20.8467, 50.2764],
            [20.8544, 50.2958],
            [20.8781, 50.2954],
            [20.8942, 50.3062],
            [20.9012, 50.3063],
            [20.9224, 50.2997],
            [20.9389, 50.3009],
            [20.9445, 50.3125],
            [20.9543, 50.3167],
            [20.974, 50.3145],
            [20.9942, 50.316],
            [21.0108, 50.3138],
            [21.0268, 50.3198],
            [21.0485, 50.316],
            [21.0715, 50.3225],
            [21.0851, 50.338],
            [21.095, 50.3399],
            [21.1082, 50.3376],
            [21.1169, 50.3418],
            [21.1212, 50.3504],
            [21.1383, 50.3553],
            [21.1562, 50.3509],
            [21.1663, 50.3431],
            [21.169, 50.3313],
            [21.1823, 50.332],
            [21.1879, 50.3495],
            [21.1948, 50.3542],
            [21.2094, 50.3553],
            [21.2189, 50.3415],
            [21.2162, 50.3324],
            [21.2205, 50.3278],
            [21.2193, 50.3149],
            [21.197, 50.3073],
            [21.1817, 50.2858],
            [21.1704, 50.2795],
            [21.1464, 50.2602],
            [21.1475, 50.2547],
            [21.1729, 50.246],
            [21.1729, 50.2425],
            [21.1467, 50.2376],
            [21.1456, 50.2335],
            [21.1677, 50.2248],
            [21.1767, 50.2177],
            [21.1729, 50.1757],
            [21.1704, 50.1697],
            [21.1527, 50.1485],
            [21.153, 50.1455],
            [21.1772, 50.1436],
            [21.1805, 50.1381],
            [21.1601, 50.1392],
            [21.1663, 50.1313],
            [21.1939, 50.1302],
            [21.186, 50.1179],
            [21.2069, 50.1008],
            [21.2034, 50.0964],
            [21.1797, 50.097],
            [21.1726, 50.0882],
            [21.1761, 50.0844],
            [21.1949, 50.0828],
            [21.1753, 50.0664],
            [21.1571, 50.0602],
            [21.1573, 50.0515],
            [21.171, 50.0455],
            [21.1966, 50.0389],
            [21.1982, 50.0324],
            [21.1846, 50.016],
            [21.162, 50.0103],
            [21.1587, 50.0065],
            [21.1557, 49.9882],
            [21.1603, 49.9733],
            [21.1704, 49.9615],
            [21.1745, 49.9487],
            [21.1876, 49.9313],
            [21.22, 49.9272],
            [21.2642, 49.9277],
            [21.2821, 49.9267],
            [21.2982, 49.9092],
            [21.2966, 49.8989],
            [21.2851, 49.8888],
            [21.2623, 49.8855],
            [21.2407, 49.891],
            [21.2323, 49.8836],
            [21.2323, 49.8574],
            [21.2069, 49.855],
            [21.1846, 49.8506],
            [21.1434, 49.8501],
            [21.1415, 49.8414],
            [21.1473, 49.8356],
            [21.1674, 49.8242],
            [21.1707, 49.8078],
            [21.1582, 49.7818],
            [21.1834, 49.7787],
            [21.2032, 49.7807],
            [21.2424, 49.7772],
            [21.2475, 49.7803],
            [21.2633, 49.7615],
            [21.2745, 49.7581],
            [21.3079, 49.7579],
            [21.3199, 49.7506],
            [21.3321, 49.7228],
            [21.3356, 49.705],
            [21.3557, 49.699],
            [21.3572, 49.6889],
            [21.3517, 49.6703],
            [21.3541, 49.6632],
            [21.3652, 49.6536],
            [21.3655, 49.6398],
            [21.357, 49.6302],
            [21.325, 49.6044],
            [21.3304, 49.5995],
            [21.3289, 49.5878],
            [21.3476, 49.5776],
            [21.3654, 49.5754],
            [21.3733, 49.5706],
            [21.3738, 49.5618],
            [21.3589, 49.5475],
            [21.3732, 49.5413],
            [21.3747, 49.5276],
            [21.3881, 49.5185],
            [21.4033, 49.5011],
            [21.4182, 49.4974],
            [21.4198, 49.4916],
            [21.4011, 49.4909],
            [21.3904, 49.4848],
            [21.4087, 49.4669],
            [21.4, 49.4571],
            [21.3929, 49.4546],
            [21.3975, 49.4335],
            [21.3832, 49.4341],
            [21.353, 49.4449],
            [21.348, 49.45],
            [21.3358, 49.4513],
            [21.3076, 49.4483],
            [21.2841, 49.4588],
            [21.269, 49.4573],
            [21.2666, 49.4475],
            [21.2582, 49.4449],
            [21.2248, 49.4255],
            [21.2281, 49.4166],
            [21.214, 49.4079],
            [21.194, 49.4015],
            [21.1817, 49.403],
            [21.1808, 49.4088],
            [21.1533, 49.4266],
            [21.1317, 49.4339],
            [21.121, 49.4343],
            [21.1155, 49.4237],
            [21.0998, 49.4294],
            [21.0499, 49.4164],
            [21.0556, 49.4049],
            [21.0712, 49.4013],
            [21.0711, 49.395],
            [21.1015, 49.3794],
            [21.1058, 49.3754],
            [21.0945, 49.3648],
            [21.0805, 49.3631],
            [21.0681, 49.365],
            [21.0576, 49.3618],
            [21.0436, 49.3646],
            [21.0309, 49.3575],
            [21.0111, 49.3376],
            [20.995, 49.332],
            [20.991, 49.3238],
            [20.998, 49.3135],
            [20.9882, 49.3075],
            [20.9778, 49.3101],
            [20.964, 49.309],
            [20.9295, 49.2981],
            [20.9135, 49.3082],
            [20.9113, 49.32],
            [20.9055, 49.3271],
            [20.8855, 49.3274],
            [20.8752, 49.341],
            [20.866, 49.3474],
            [20.8564, 49.3468],
            [20.8413, 49.3348],
            [20.8287, 49.3344],
            [20.8151, 49.3471],
            [20.799, 49.3451],
            [20.8116, 49.3579],
            [20.8013, 49.3629],
            [20.7889, 49.3605],
            [20.7818, 49.3749],
            [20.7667, 49.3705],
            [20.759, 49.3749],
            [20.7607, 49.3849],
            [20.7503, 49.3898],
            [20.7576, 49.4008],
            [20.7448, 49.4035],
            [20.7473, 49.4114],
            [20.742, 49.4163],
            [20.7282, 49.4143],
            [20.7147, 49.4193],
            [20.6927, 49.4197],
            [20.6647, 49.4046],
            [20.6497, 49.4012],
            [20.6412, 49.4075],
            [20.616, 49.4166],
            [20.6125, 49.4033],
            [20.6045, 49.3969],
            [20.6021, 49.3885],
            [20.5921, 49.3819],
            [20.5821, 49.383],
            [20.5766, 49.3766],
            [20.5595, 49.3791],
            [20.5459, 49.3864],
            [20.5225, 49.3925],
            [20.5088, 49.3938],
            [20.5058, 49.404],
            [20.495, 49.4057],
            [20.4722, 49.4158],
            [20.4392, 49.4148],
            [20.4299, 49.4083],
            [20.4137, 49.4042],
            [20.4095, 49.3947],
            [20.3931, 49.3912],
            [20.3811, 49.3946],
            [20.3815, 49.4031],
            [20.3712, 49.4069],
            [20.3669, 49.3996],
            [20.3578, 49.3989],
            [20.3368, 49.404],
            [20.3229, 49.4014],
            [20.3193, 49.389],
            [20.3326, 49.3887],
            [20.3252, 49.3783],
            [20.3267, 49.3655],
            [20.3235, 49.3497],
            [20.3091, 49.3445],
            [20.2971, 49.3509],
            [20.2754, 49.3514],
            [20.2703, 49.3472],
            [20.2444, 49.3509],
            [20.2332, 49.3458],
            [20.2235, 49.3523],
            [20.2131, 49.3449],
            [20.1979, 49.3423],
            [20.1857, 49.3278],
            [20.1708, 49.3233],
            [20.1775, 49.3141],
            [20.1627, 49.3047],
            [20.1544, 49.3158],
            [20.1376, 49.3044],
            [20.1278, 49.2853],
            [20.1249, 49.2707],
            [20.1077, 49.256],
            [20.1054, 49.244],
            [20.0969, 49.2346],
            [20.1033, 49.2244],
            [20.0957, 49.2185],
            [20.0885, 49.2067],
            [20.0863, 49.1935],
            [20.0881, 49.1822],
            [20.0772, 49.1792],
            [20.05, 49.1904],
            [20.0394, 49.198],
            [20.0214, 49.2027],
            [20.0082, 49.213],
            [20.0092, 49.2189],
            [19.9866, 49.2319],
            [19.9709, 49.2316],
            [19.9466, 49.2353],
            [19.9245, 49.2366],
            [19.9045, 49.2308],
            [19.9024, 49.215],
            [19.8773, 49.2027],
            [19.8746, 49.1975],
            [19.8407, 49.1974],
            [19.8302, 49.2024],
            [19.8209, 49.1992],
            [19.8101, 49.2062],
            [19.799, 49.2001],
            [19.7858, 49.2008],
            [19.7728, 49.2066],
            [19.7636, 49.2147],
            [19.7722, 49.2306],
            [19.7691, 49.2358],
            [19.7914, 49.2511],
            [19.7943, 49.2666],
            [19.8091, 49.2735],
            [19.8244, 49.2768],
            [19.8194, 49.2878],
            [19.8109, 49.2962],
            [19.8002, 49.2971],
            [19.7979, 49.3029],
            [19.8112, 49.3262],
            [19.8021, 49.3339],
            [19.7976, 49.3475],
            [19.805, 49.3568],
            [19.8059, 49.3672],
            [19.7962, 49.3777],
            [19.7899, 49.4117],
            [19.7699, 49.4135],
            [19.7444, 49.4054],
            [19.7325, 49.3919],
            [19.7235, 49.3874],
            [19.7099, 49.391],
            [19.6994, 49.3989],
            [19.6623, 49.4081],
            [19.6332, 49.4103],
            [19.6508, 49.4367],
            [19.6404, 49.4424],
            [19.6409, 49.4572],
            [19.6257, 49.4495],
            [19.6151, 49.4477],
            [19.6038, 49.4555],
            [19.5835, 49.4617],
            [19.57, 49.4719],
            [19.567, 49.4807],
            [19.555, 49.4902],
            [19.5529, 49.5087],
            [19.5452, 49.5168],
            [19.5307, 49.5378],
            [19.5295, 49.5618],
            [19.5312, 49.572],
            [19.5197, 49.5743],
            [19.5101, 49.582],
            [19.4999, 49.5817],
            [19.479, 49.5892],
            [19.4711, 49.5964],
            [19.4765, 49.605],
            [19.4705, 49.6122],
            [19.4828, 49.6277],
            [19.4839, 49.6342],
            [19.4787, 49.6565],
            [19.4842, 49.6685],
            [19.4693, 49.6816],
            [19.4566, 49.6852],
            [19.4068, 49.6849],
            [19.3944, 49.6874],
            [19.3865, 49.6943],
            [19.3894, 49.7008],
            [19.4173, 49.7208],
            [19.462, 49.7404],
            [19.4591, 49.7626],
            [19.4511, 49.7746],
            [19.438, 49.7771],
            [19.4192, 49.7688],
            [19.3952, 49.7822],
            [19.3505, 49.7804],
            [19.332, 49.787],
            [19.3018, 49.8178],
            [19.3036, 49.8284],
            [19.311, 49.8378],
            [19.3129, 49.8497],
            [19.311, 49.8615],
            [19.2995, 49.8688],
            [19.2871, 49.8711],
            [19.2425, 49.8722],
            [19.1971, 49.8674],
            [19.1801, 49.8616],
            [19.1693, 49.8744],
            [19.1759, 49.885],
            [19.1844, 49.8903],
            [19.2037, 49.8936],
            [19.2126, 49.9031],
            [19.2146, 49.9118],
            [19.2147, 49.9448],
            [19.1978, 49.955],
            [19.1761, 49.9516],
            [19.1616, 49.9464],
            [19.1596, 49.9401],
            [19.1416, 49.9385],
            [19.119, 49.9396],
            [19.1051, 49.9428],
            [19.0948, 49.9597],
            [19.1137, 49.9627],
            [19.1056, 49.983],
            [19.111, 49.9987],
            [19.1218, 50.0055],
            [19.1243, 50.0149],
            [19.1379, 50.023],
            [19.1384, 50.0316],
            [19.1489, 50.0447],
            [19.1602, 50.0512],
            [19.169, 50.0603],
            [19.178, 50.0572],
            [19.1955, 50.0653],
            [19.2063, 50.0622],
            [19.2228, 50.0643],
            [19.2261, 50.0723],
            [19.2118, 50.0761],
            [19.225, 50.09],
            [19.2236, 50.0946],
            [19.25, 50.1318],
            [19.2548, 50.1296],
            [19.26, 50.1119],
            [19.2741, 50.1171],
            [19.2776, 50.1237],
            [19.2733, 50.1346],
            [19.2774, 50.1414],
            [19.3082, 50.1397],
            [19.3253, 50.1408],
            [19.3447, 50.1474],
            [19.349, 50.1648],
            [19.3673, 50.1681],
            [19.3651, 50.1738],
            [19.3469, 50.1776],
            [19.3482, 50.1872],
            [19.3804, 50.191],
            [19.3924, 50.203],
            [19.4207, 50.2051],
            [19.4305, 50.2196],
            [19.4319, 50.2275],
            [19.4199, 50.2308],
            [19.3796, 50.2381],
            [19.3616, 50.2444],
            [19.3303, 50.2601],
            [19.3254, 50.2821],
            [19.3127, 50.292],
            [19.3332, 50.2953],
            [19.3433, 50.3],
            [19.3626, 50.318],
            [19.3838, 50.3184],
            [19.3983, 50.3123],
            [19.414, 50.3146],
            [19.4249, 50.3244],
            [19.4347, 50.327],
            [19.4646, 50.3209],
            [19.4931, 50.3242],
            [19.4984, 50.3288],
            [19.501, 50.3499],
            [19.496, 50.358],
            [19.462, 50.3615],
            [19.4576, 50.3647],
            [19.4598, 50.3752],
            [19.4652, 50.3763],
            [19.4763, 50.3769],
            [19.5003, 50.3702],
            [19.5082, 50.3763],
            [19.4981, 50.3937],
            [19.5016, 50.4013],
            [19.5322, 50.4184],
            [19.5516, 50.4184],
            [19.5786, 50.4081],
            [19.5954, 50.407],
            [19.6207, 50.4094],
            [19.6381, 50.4177],
            [19.6606, 50.4146],
            [19.6789, 50.4319],
            [19.6983, 50.4364],
            [19.7164, 50.4454],
            [19.7379, 50.4412],
            [19.7602, 50.4456],
            [19.7837, 50.4421],
            [19.8001, 50.448],
            [19.826, 50.4399],
            [19.8523, 50.4393],
            [19.8544, 50.4475],
            [19.884, 50.4504],
            [19.8988, 50.4538],
            [19.901, 50.4583],
            [19.8919, 50.4666],
            [19.9001, 50.4736],
            [19.9273, 50.4752],
            [19.935, 50.4784],
            [19.9671, 50.4758],
            [19.9637, 50.489],
            [19.9525, 50.4947],
            [19.9504, 50.5048],
          ],
        ],
      },
      properties: {
        name: 'Małopolskie',
        id: 'PL-12',
        NAME_ENG: 'Lesser Poland',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-12',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.762, 53.152],
            [19.7769, 53.1509],
            [19.8284, 53.1434],
            [19.8466, 53.1443],
            [19.8541, 53.1518],
            [19.8499, 53.1656],
            [19.8352, 53.1814],
            [19.8417, 53.1885],
            [19.8664, 53.2019],
            [19.8873, 53.2086],
            [19.9006, 53.2152],
            [19.9195, 53.2133],
            [19.9241, 53.2079],
            [19.9234, 53.2002],
            [19.9138, 53.18],
            [19.9191, 53.1707],
            [19.9577, 53.1605],
            [19.9835, 53.1467],
            [20.0104, 53.149],
            [20.0359, 53.1477],
            [20.0508, 53.1527],
            [20.0789, 53.1549],
            [20.102, 53.166],
            [20.1164, 53.1638],
            [20.1321, 53.149],
            [20.1476, 53.1438],
            [20.1637, 53.1532],
            [20.1776, 53.1545],
            [20.2232, 53.1501],
            [20.2535, 53.1403],
            [20.2943, 53.139],
            [20.3061, 53.1364],
            [20.3246, 53.1386],
            [20.3372, 53.1449],
            [20.3658, 53.1503],
            [20.3708, 53.1556],
            [20.368, 53.1651],
            [20.3882, 53.1623],
            [20.3939, 53.1806],
            [20.3791, 53.1865],
            [20.3774, 53.2085],
            [20.4061, 53.2208],
            [20.4117, 53.2138],
            [20.4242, 53.22],
            [20.4567, 53.2453],
            [20.4746, 53.2341],
            [20.5117, 53.2329],
            [20.5134, 53.2261],
            [20.5246, 53.2223],
            [20.5322, 53.2318],
            [20.5541, 53.2245],
            [20.5623, 53.2266],
            [20.5752, 53.2403],
            [20.5826, 53.2331],
            [20.6083, 53.2387],
            [20.6146, 53.2514],
            [20.6576, 53.2508],
            [20.6759, 53.2693],
            [20.6905, 53.2875],
            [20.6932, 53.3017],
            [20.7137, 53.2999],
            [20.7147, 53.3105],
            [20.7416, 53.3053],
            [20.7471, 53.3148],
            [20.7957, 53.3095],
            [20.8189, 53.3057],
            [20.8478, 53.2926],
            [20.8568, 53.3018],
            [20.8657, 53.3041],
            [20.8733, 53.3125],
            [20.873, 53.322],
            [20.8974, 53.3136],
            [20.9163, 53.327],
            [20.9212, 53.336],
            [20.9088, 53.344],
            [20.9189, 53.3536],
            [20.9295, 53.3527],
            [20.9381, 53.3601],
            [20.9685, 53.3524],
            [20.9978, 53.3633],
            [21.0305, 53.3516],
            [21.0508, 53.335],
            [21.0727, 53.3402],
            [21.0832, 53.3472],
            [21.1164, 53.355],
            [21.1357, 53.366],
            [21.142, 53.3773],
            [21.1738, 53.3923],
            [21.1954, 53.4],
            [21.2455, 53.4225],
            [21.2749, 53.4314],
            [21.3009, 53.4339],
            [21.3048, 53.4236],
            [21.326, 53.43],
            [21.3563, 53.421],
            [21.3623, 53.4213],
            [21.3858, 53.437],
            [21.4029, 53.4387],
            [21.4112, 53.4314],
            [21.4288, 53.4401],
            [21.4434, 53.4409],
            [21.4356, 53.4558],
            [21.4512, 53.4653],
            [21.492, 53.4733],
            [21.5354, 53.4772],
            [21.5526, 53.4768],
            [21.567, 53.4806],
            [21.5986, 53.4804],
            [21.6041, 53.4658],
            [21.6028, 53.4532],
            [21.6085, 53.4492],
            [21.6111, 53.4349],
            [21.6301, 53.4272],
            [21.6392, 53.4267],
            [21.6257, 53.4086],
            [21.6369, 53.4054],
            [21.6618, 53.3861],
            [21.6623, 53.3721],
            [21.6834, 53.3673],
            [21.6795, 53.3623],
            [21.6641, 53.3566],
            [21.6555, 53.3492],
            [21.6693, 53.3371],
            [21.6541, 53.3367],
            [21.6524, 53.3316],
            [21.6704, 53.3301],
            [21.6841, 53.3362],
            [21.6908, 53.3292],
            [21.7029, 53.3262],
            [21.7094, 53.3199],
            [21.721, 53.3192],
            [21.7358, 53.3118],
            [21.711, 53.3054],
            [21.6944, 53.314],
            [21.67, 53.3114],
            [21.6669, 53.3169],
            [21.6547, 53.3175],
            [21.6602, 53.3052],
            [21.6606, 53.2945],
            [21.6491, 53.2919],
            [21.658, 53.2789],
            [21.6583, 53.262],
            [21.6363, 53.2598],
            [21.6612, 53.2371],
            [21.6623, 53.229],
            [21.6779, 53.2154],
            [21.6695, 53.2079],
            [21.6766, 53.204],
            [21.6877, 53.1798],
            [21.7034, 53.168],
            [21.7059, 53.1605],
            [21.6927, 53.1477],
            [21.6997, 53.1413],
            [21.7014, 53.1322],
            [21.7339, 53.1094],
            [21.753, 53.1139],
            [21.787, 53.112],
            [21.8077, 53.1148],
            [21.8171, 53.1016],
            [21.8369, 53.0872],
            [21.8428, 53.0767],
            [21.8602, 53.0735],
            [21.859, 53.0646],
            [21.8721, 53.0599],
            [21.8924, 53.0616],
            [21.9144, 53.0712],
            [21.9183, 53.079],
            [21.9293, 53.0785],
            [21.9399, 53.0714],
            [21.9244, 53.0602],
            [21.9221, 53.0558],
            [21.9233, 53.0467],
            [21.9386, 53.0465],
            [21.9397, 53.0389],
            [21.9125, 53.0392],
            [21.8977, 53.0459],
            [21.8925, 53.029],
            [21.876, 53.0224],
            [21.8799, 53.0101],
            [21.8892, 53.0031],
            [21.9099, 52.9995],
            [21.9185, 52.9892],
            [21.9599, 52.9853],
            [21.9645, 52.9791],
            [21.9771, 52.9792],
            [21.9984, 52.9726],
            [22.0035, 52.9583],
            [22.0136, 52.9507],
            [22.0101, 52.9422],
            [22.0276, 52.9408],
            [22.0204, 52.9319],
            [21.9928, 52.9196],
            [21.9935, 52.9152],
            [22.0087, 52.9055],
            [22.0115, 52.9005],
            [22.0053, 52.8925],
            [22.0047, 52.8781],
            [22.0252, 52.8865],
            [22.0386, 52.8823],
            [22.028, 52.8654],
            [22.0382, 52.8604],
            [22.0904, 52.8541],
            [22.0987, 52.8653],
            [22.1074, 52.8675],
            [22.1161, 52.8579],
            [22.1284, 52.8551],
            [22.1438, 52.8597],
            [22.1593, 52.8581],
            [22.1852, 52.8665],
            [22.2136, 52.8643],
            [22.2129, 52.8867],
            [22.2179, 52.8927],
            [22.2454, 52.8901],
            [22.2675, 52.8914],
            [22.2754, 52.8895],
            [22.2875, 52.8801],
            [22.2863, 52.8721],
            [22.277, 52.8626],
            [22.2787, 52.8541],
            [22.304, 52.8615],
            [22.3076, 52.8545],
            [22.2928, 52.8414],
            [22.2783, 52.8402],
            [22.2633, 52.8478],
            [22.2461, 52.8408],
            [22.2452, 52.8368],
            [22.2591, 52.8255],
            [22.2716, 52.8087],
            [22.2825, 52.7823],
            [22.2994, 52.7785],
            [22.3158, 52.7847],
            [22.3221, 52.7819],
            [22.3251, 52.7653],
            [22.3321, 52.7627],
            [22.3529, 52.7819],
            [22.3833, 52.7833],
            [22.3892, 52.8003],
            [22.4076, 52.7974],
            [22.4426, 52.7959],
            [22.4561, 52.7889],
            [22.4534, 52.7497],
            [22.4446, 52.704],
            [22.4479, 52.6845],
            [22.4628, 52.661],
            [22.46, 52.6537],
            [22.4487, 52.6477],
            [22.4247, 52.6249],
            [22.3931, 52.6131],
            [22.4064, 52.6098],
            [22.4176, 52.599],
            [22.4574, 52.5858],
            [22.4614, 52.5737],
            [22.4691, 52.5651],
            [22.4864, 52.5588],
            [22.4907, 52.5478],
            [22.5015, 52.5386],
            [22.5249, 52.5234],
            [22.5204, 52.5053],
            [22.5146, 52.4979],
            [22.5111, 52.4824],
            [22.5251, 52.4708],
            [22.5307, 52.4617],
            [22.5445, 52.4674],
            [22.5534, 52.4612],
            [22.5467, 52.4543],
            [22.5418, 52.4407],
            [22.5417, 52.4234],
            [22.5566, 52.4177],
            [22.5672, 52.4032],
            [22.5913, 52.3983],
            [22.6071, 52.3984],
            [22.6171, 52.3874],
            [22.6254, 52.3931],
            [22.6456, 52.3882],
            [22.659, 52.3936],
            [22.661, 52.3863],
            [22.6931, 52.3932],
            [22.7055, 52.3925],
            [22.7122, 52.3856],
            [22.723, 52.3833],
            [22.7204, 52.3698],
            [22.7336, 52.3676],
            [22.74, 52.3818],
            [22.76, 52.3827],
            [22.766, 52.3738],
            [22.7752, 52.3705],
            [22.7941, 52.3741],
            [22.8126, 52.3678],
            [22.8262, 52.3665],
            [22.8487, 52.3588],
            [22.8629, 52.3649],
            [22.87, 52.3628],
            [22.8882, 52.3645],
            [22.9196, 52.3753],
            [22.9502, 52.3706],
            [22.9658, 52.3639],
            [22.9764, 52.3472],
            [22.9958, 52.3327],
            [23.0022, 52.3381],
            [23.0159, 52.3341],
            [23.0375, 52.3318],
            [23.0461, 52.326],
            [23.0575, 52.3068],
            [23.0577, 52.3006],
            [23.0495, 52.2938],
            [23.059, 52.2845],
            [23.077, 52.2843],
            [23.1096, 52.3021],
            [23.1193, 52.3002],
            [23.1334, 52.2857],
            [23.0925, 52.2482],
            [23.0681, 52.2385],
            [23.0424, 52.2234],
            [23.0379, 52.2122],
            [23.0452, 52.1979],
            [23.0598, 52.1904],
            [23.0797, 52.1859],
            [23.0801, 52.1765],
            [23.0556, 52.1816],
            [23.0011, 52.1838],
            [22.9951, 52.1808],
            [22.9862, 52.1676],
            [22.9534, 52.1524],
            [22.9459, 52.1415],
            [22.9711, 52.1238],
            [22.9711, 52.1169],
            [22.9601, 52.1139],
            [22.9433, 52.1214],
            [22.9231, 52.1205],
            [22.9086, 52.1069],
            [22.9088, 52.0994],
            [22.9162, 52.0857],
            [22.9082, 52.0752],
            [22.8855, 52.0726],
            [22.8744, 52.0821],
            [22.8624, 52.0825],
            [22.8513, 52.0903],
            [22.834, 52.0913],
            [22.8101, 52.0771],
            [22.8027, 52.0786],
            [22.7789, 52.1],
            [22.7699, 52.1053],
            [22.7445, 52.1084],
            [22.7162, 52.1215],
            [22.7056, 52.1232],
            [22.6934, 52.1205],
            [22.678, 52.1117],
            [22.6491, 52.09],
            [22.6646, 52.0817],
            [22.67, 52.0626],
            [22.6601, 52.0368],
            [22.6458, 52.0325],
            [22.6235, 52.0155],
            [22.613, 52.029],
            [22.5959, 52.0385],
            [22.5708, 52.0432],
            [22.5588, 52.0505],
            [22.534, 52.0562],
            [22.5264, 52.068],
            [22.5196, 52.0723],
            [22.4989, 52.0739],
            [22.4814, 52.0677],
            [22.464, 52.0481],
            [22.4471, 52.041],
            [22.4231, 52.0249],
            [22.3776, 52.0252],
            [22.3577, 52.0175],
            [22.3479, 52.0178],
            [22.3367, 52.0271],
            [22.3294, 52.0502],
            [22.3062, 52.0502],
            [22.2855, 52.029],
            [22.2681, 52.0238],
            [22.2547, 52.0233],
            [22.1702, 52.0402],
            [22.1552, 52.0407],
            [22.1465, 52.0344],
            [22.1413, 52.0235],
            [22.1302, 52.0156],
            [22.1048, 52.0162],
            [22.062, 52.0323],
            [22.043, 52.0369],
            [22.0045, 52.0184],
            [21.989, 52.0075],
            [21.9735, 52.0045],
            [21.9509, 52.0053],
            [21.9264, 51.9996],
            [21.8929, 51.9831],
            [21.8854, 51.9748],
            [21.8782, 51.9585],
            [21.8795, 51.9431],
            [21.8939, 51.9225],
            [21.9023, 51.9166],
            [21.9125, 51.9039],
            [21.9115, 51.89],
            [21.9004, 51.8715],
            [21.89, 51.8606],
            [21.8641, 51.8576],
            [21.8534, 51.8527],
            [21.8615, 51.8319],
            [21.8733, 51.829],
            [21.8998, 51.834],
            [21.9197, 51.843],
            [21.9302, 51.8412],
            [21.9454, 51.8253],
            [21.9513, 51.8105],
            [21.9622, 51.7981],
            [21.9336, 51.7808],
            [21.9169, 51.7802],
            [21.904, 51.7717],
            [21.8782, 51.7679],
            [21.869, 51.7615],
            [21.8517, 51.7551],
            [21.8549, 51.7462],
            [21.8776, 51.7436],
            [21.8839, 51.7394],
            [21.8855, 51.7235],
            [21.8961, 51.7157],
            [21.9107, 51.7165],
            [21.9116, 51.7109],
            [21.9058, 51.6954],
            [21.8974, 51.6995],
            [21.8789, 51.6924],
            [21.8776, 51.6774],
            [21.8606, 51.6778],
            [21.8558, 51.6699],
            [21.8407, 51.667],
            [21.8425, 51.6527],
            [21.8318, 51.6525],
            [21.8241, 51.6476],
            [21.8035, 51.6474],
            [21.7914, 51.6506],
            [21.7756, 51.6418],
            [21.7612, 51.6408],
            [21.7579, 51.6366],
            [21.7499, 51.6118],
            [21.7261, 51.6188],
            [21.7212, 51.6366],
            [21.7099, 51.6371],
            [21.6895, 51.6428],
            [21.6553, 51.6569],
            [21.6475, 51.658],
            [21.636, 51.6523],
            [21.6408, 51.6429],
            [21.6397, 51.6263],
            [21.6167, 51.618],
            [21.6422, 51.6072],
            [21.6649, 51.5814],
            [21.679, 51.5749],
            [21.7122, 51.5634],
            [21.7529, 51.5591],
            [21.7827, 51.5687],
            [21.7967, 51.5691],
            [21.819, 51.5647],
            [21.8306, 51.5532],
            [21.8355, 51.5327],
            [21.849, 51.5206],
            [21.8523, 51.5089],
            [21.8517, 51.4859],
            [21.8559, 51.4795],
            [21.8727, 51.4749],
            [21.8702, 51.4655],
            [21.8547, 51.4479],
            [21.8495, 51.4291],
            [21.8396, 51.4173],
            [21.8277, 51.4263],
            [21.8029, 51.4276],
            [21.7878, 51.4198],
            [21.781, 51.4123],
            [21.7684, 51.413],
            [21.7602, 51.4009],
            [21.7686, 51.3925],
            [21.7888, 51.3965],
            [21.7937, 51.3863],
            [21.8068, 51.3757],
            [21.8326, 51.3815],
            [21.8378, 51.3786],
            [21.8525, 51.3549],
            [21.8462, 51.3547],
            [21.8253, 51.3381],
            [21.8371, 51.3339],
            [21.8247, 51.313],
            [21.8245, 51.3026],
            [21.8091, 51.2999],
            [21.7949, 51.2942],
            [21.8016, 51.2901],
            [21.8205, 51.2918],
            [21.8313, 51.2893],
            [21.8251, 51.2678],
            [21.8069, 51.2533],
            [21.8088, 51.2428],
            [21.8178, 51.2335],
            [21.8136, 51.2201],
            [21.8013, 51.2154],
            [21.7991, 51.208],
            [21.7894, 51.1965],
            [21.7877, 51.18],
            [21.8047, 51.17],
            [21.8202, 51.1564],
            [21.8102, 51.1505],
            [21.805, 51.1327],
            [21.7947, 51.1289],
            [21.7966, 51.1125],
            [21.794, 51.103],
            [21.799, 51.0911],
            [21.799, 51.074],
            [21.8074, 51.0675],
            [21.7848, 51.0669],
            [21.7713, 51.0487],
            [21.758, 51.0382],
            [21.7421, 51.0417],
            [21.7383, 51.0484],
            [21.7266, 51.0484],
            [21.7171, 51.0412],
            [21.7089, 51.0406],
            [21.6968, 51.0521],
            [21.6928, 51.0634],
            [21.6762, 51.077],
            [21.6492, 51.0698],
            [21.6394, 51.0763],
            [21.6253, 51.0711],
            [21.6312, 51.0642],
            [21.6142, 51.0643],
            [21.5901, 51.0571],
            [21.5759, 51.0588],
            [21.5448, 51.048],
            [21.5416, 51.0564],
            [21.5149, 51.057],
            [21.4876, 51.0347],
            [21.5025, 51.0311],
            [21.4951, 51.0219],
            [21.4641, 51.0129],
            [21.4324, 51.0295],
            [21.4116, 51.0327],
            [21.3833, 51.041],
            [21.3824, 51.0582],
            [21.3727, 51.0586],
            [21.3659, 51.0651],
            [21.352, 51.0665],
            [21.3559, 51.0819],
            [21.3521, 51.0856],
            [21.3096, 51.0832],
            [21.2283, 51.0828],
            [21.2198, 51.0814],
            [21.153, 51.081],
            [21.1559, 51.0864],
            [21.1483, 51.0921],
            [21.1519, 51.1034],
            [21.1588, 51.1073],
            [21.1536, 51.1241],
            [21.1333, 51.1293],
            [21.1288, 51.1411],
            [21.091, 51.1545],
            [21.0937, 51.1595],
            [21.1181, 51.1822],
            [21.1197, 51.1871],
            [21.1097, 51.2005],
            [21.0912, 51.213],
            [21.0832, 51.2145],
            [21.0702, 51.2087],
            [21.0745, 51.1982],
            [21.0704, 51.1752],
            [21.0589, 51.1577],
            [21.0207, 51.1585],
            [21.0248, 51.1516],
            [20.9986, 51.1452],
            [20.9924, 51.1554],
            [20.9974, 51.1607],
            [20.9846, 51.1665],
            [20.9686, 51.1523],
            [20.9526, 51.1545],
            [20.9403, 51.1523],
            [20.9285, 51.1591],
            [20.8962, 51.1704],
            [20.8788, 51.153],
            [20.8928, 51.143],
            [20.8924, 51.1346],
            [20.8633, 51.1363],
            [20.8584, 51.1264],
            [20.8286, 51.1327],
            [20.8245, 51.1428],
            [20.7879, 51.1522],
            [20.7825, 51.1448],
            [20.7664, 51.1503],
            [20.7702, 51.1565],
            [20.7389, 51.1652],
            [20.7192, 51.1723],
            [20.697, 51.1493],
            [20.6873, 51.1499],
            [20.6841, 51.1658],
            [20.686, 51.1749],
            [20.7016, 51.1826],
            [20.699, 51.1955],
            [20.6898, 51.1977],
            [20.6769, 51.2091],
            [20.6478, 51.2174],
            [20.6145, 51.2337],
            [20.6065, 51.2458],
            [20.5988, 51.2435],
            [20.5777, 51.2438],
            [20.5671, 51.2363],
            [20.5542, 51.2357],
            [20.5496, 51.2479],
            [20.542, 51.2853],
            [20.5366, 51.2968],
            [20.5227, 51.2976],
            [20.5153, 51.309],
            [20.4998, 51.3107],
            [20.4963, 51.318],
            [20.5213, 51.3273],
            [20.5224, 51.3387],
            [20.5135, 51.344],
            [20.4905, 51.3434],
            [20.4491, 51.3502],
            [20.4288, 51.3513],
            [20.4228, 51.3635],
            [20.4298, 51.369],
            [20.4294, 51.3771],
            [20.4619, 51.417],
            [20.4873, 51.4232],
            [20.5014, 51.4303],
            [20.516, 51.4496],
            [20.5217, 51.4627],
            [20.533, 51.4682],
            [20.5399, 51.4818],
            [20.5418, 51.4922],
            [20.5358, 51.5126],
            [20.5235, 51.5134],
            [20.508, 51.5042],
            [20.4856, 51.5028],
            [20.4511, 51.5054],
            [20.4501, 51.5123],
            [20.4569, 51.5439],
            [20.4686, 51.5587],
            [20.4617, 51.573],
            [20.443, 51.5715],
            [20.4336, 51.5748],
            [20.4305, 51.5827],
            [20.4335, 51.5928],
            [20.4267, 51.6107],
            [20.4197, 51.6192],
            [20.4151, 51.6336],
            [20.3934, 51.6359],
            [20.3861, 51.6417],
            [20.3959, 51.648],
            [20.3917, 51.6556],
            [20.3977, 51.6705],
            [20.408, 51.6754],
            [20.4249, 51.6765],
            [20.4511, 51.6862],
            [20.4541, 51.6934],
            [20.4678, 51.6932],
            [20.4887, 51.6836],
            [20.514, 51.6859],
            [20.513, 51.6797],
            [20.5278, 51.6797],
            [20.5363, 51.6683],
            [20.5791, 51.6628],
            [20.597, 51.6592],
            [20.6207, 51.6574],
            [20.6263, 51.6699],
            [20.6388, 51.6761],
            [20.6532, 51.6774],
            [20.6591, 51.703],
            [20.6548, 51.715],
            [20.6591, 51.7234],
            [20.6387, 51.7325],
            [20.6395, 51.7378],
            [20.6156, 51.7425],
            [20.6073, 51.7469],
            [20.583, 51.7483],
            [20.584, 51.7609],
            [20.5935, 51.7702],
            [20.579, 51.7942],
            [20.581, 51.8108],
            [20.5615, 51.812],
            [20.5681, 51.8213],
            [20.6065, 51.8159],
            [20.6189, 51.8203],
            [20.6182, 51.8272],
            [20.5996, 51.8274],
            [20.5967, 51.8393],
            [20.6051, 51.8518],
            [20.5942, 51.8658],
            [20.5669, 51.8954],
            [20.5197, 51.8979],
            [20.5063, 51.8973],
            [20.4943, 51.9053],
            [20.495, 51.9208],
            [20.4885, 51.9269],
            [20.4676, 51.936],
            [20.456, 51.9443],
            [20.4308, 51.9505],
            [20.4177, 51.9386],
            [20.4061, 51.9379],
            [20.3933, 51.9489],
            [20.3872, 51.9479],
            [20.3789, 51.9349],
            [20.3645, 51.9348],
            [20.3556, 51.9262],
            [20.3349, 51.9281],
            [20.3247, 51.9227],
            [20.3193, 51.9316],
            [20.3183, 51.9421],
            [20.3001, 51.9463],
            [20.2743, 51.9388],
            [20.2622, 51.9382],
            [20.2459, 51.942],
            [20.2406, 51.95],
            [20.2426, 51.9577],
            [20.2557, 51.9635],
            [20.2857, 51.9713],
            [20.2893, 51.9768],
            [20.2823, 51.9829],
            [20.2846, 51.9911],
            [20.2666, 52.0176],
            [20.2472, 52.0147],
            [20.2125, 52.0285],
            [20.2101, 52.04],
            [20.2247, 52.0422],
            [20.2654, 52.0647],
            [20.257, 52.0752],
            [20.2586, 52.0823],
            [20.2799, 52.0834],
            [20.2813, 52.0988],
            [20.2779, 52.1123],
            [20.2659, 52.1241],
            [20.255, 52.1254],
            [20.2227, 52.1241],
            [20.2023, 52.1259],
            [20.1816, 52.1459],
            [20.1756, 52.1542],
            [20.1529, 52.155],
            [20.1282, 52.1512],
            [20.1126, 52.1601],
            [20.1006, 52.1786],
            [20.0891, 52.1843],
            [20.0796, 52.1944],
            [20.0796, 52.2058],
            [20.0891, 52.2226],
            [20.0913, 52.2369],
            [20.0774, 52.247],
            [20.0551, 52.2498],
            [20.0567, 52.2599],
            [20.046, 52.2632],
            [20.035, 52.2737],
            [20.0178, 52.2737],
            [20.0177, 52.2628],
            [19.9821, 52.2639],
            [19.9822, 52.2749],
            [19.9728, 52.2892],
            [19.9419, 52.3056],
            [19.9146, 52.3154],
            [19.8972, 52.3142],
            [19.8789, 52.3063],
            [19.8811, 52.2955],
            [19.8752, 52.2879],
            [19.8553, 52.2834],
            [19.8344, 52.2887],
            [19.8113, 52.2869],
            [19.7882, 52.2748],
            [19.7574, 52.2677],
            [19.7191, 52.2813],
            [19.7057, 52.2782],
            [19.6959, 52.2652],
            [19.6809, 52.2612],
            [19.6545, 52.2635],
            [19.6437, 52.2695],
            [19.6416, 52.2823],
            [19.633, 52.2855],
            [19.627, 52.2958],
            [19.6049, 52.2962],
            [19.58, 52.3004],
            [19.5409, 52.3121],
            [19.5073, 52.3252],
            [19.5132, 52.3365],
            [19.511, 52.3433],
            [19.4789, 52.3527],
            [19.464, 52.3586],
            [19.4539, 52.3588],
            [19.4343, 52.3496],
            [19.4048, 52.3535],
            [19.3787, 52.347],
            [19.355, 52.3503],
            [19.335, 52.3578],
            [19.3339, 52.3677],
            [19.3271, 52.3759],
            [19.3087, 52.3764],
            [19.309, 52.3827],
            [19.32, 52.3879],
            [19.3057, 52.397],
            [19.2905, 52.3977],
            [19.2818, 52.4048],
            [19.2931, 52.4109],
            [19.2964, 52.4246],
            [19.2871, 52.4326],
            [19.2624, 52.4358],
            [19.26, 52.4424],
            [19.2681, 52.4484],
            [19.284, 52.4501],
            [19.3, 52.4455],
            [19.3176, 52.4477],
            [19.3331, 52.4665],
            [19.339, 52.4789],
            [19.3576, 52.4807],
            [19.3545, 52.4897],
            [19.3716, 52.5062],
            [19.3946, 52.5113],
            [19.3848, 52.5187],
            [19.3325, 52.5249],
            [19.338, 52.5332],
            [19.3494, 52.5287],
            [19.3514, 52.5382],
            [19.3413, 52.5665],
            [19.3767, 52.568],
            [19.3908, 52.5737],
            [19.3984, 52.5933],
            [19.4131, 52.595],
            [19.4211, 52.5997],
            [19.4241, 52.6104],
            [19.3916, 52.6232],
            [19.3685, 52.6251],
            [19.3739, 52.637],
            [19.3849, 52.6434],
            [19.4025, 52.6438],
            [19.4107, 52.6549],
            [19.4154, 52.6709],
            [19.4308, 52.6716],
            [19.4364, 52.6815],
            [19.4366, 52.692],
            [19.4463, 52.7053],
            [19.4405, 52.7104],
            [19.4413, 52.7232],
            [19.4589, 52.7247],
            [19.4923, 52.7138],
            [19.4932, 52.7074],
            [19.5229, 52.7041],
            [19.519, 52.7112],
            [19.5084, 52.7119],
            [19.5137, 52.7218],
            [19.5044, 52.7251],
            [19.5009, 52.7373],
            [19.5095, 52.7369],
            [19.5244, 52.748],
            [19.5038, 52.748],
            [19.4967, 52.7586],
            [19.5047, 52.7689],
            [19.4967, 52.7704],
            [19.4837, 52.7811],
            [19.4686, 52.784],
            [19.4615, 52.7902],
            [19.4657, 52.8039],
            [19.4543, 52.804],
            [19.4339, 52.8157],
            [19.4239, 52.8275],
            [19.4317, 52.8405],
            [19.4446, 52.837],
            [19.4554, 52.8437],
            [19.4744, 52.8506],
            [19.4625, 52.8559],
            [19.4784, 52.8653],
            [19.5075, 52.8675],
            [19.4915, 52.8776],
            [19.486, 52.8848],
            [19.461, 52.9038],
            [19.466, 52.916],
            [19.4496, 52.9249],
            [19.4439, 52.9378],
            [19.4566, 52.9417],
            [19.4742, 52.942],
            [19.4855, 52.9322],
            [19.4945, 52.9401],
            [19.5294, 52.936],
            [19.5402, 52.9479],
            [19.5759, 52.9561],
            [19.5781, 52.9612],
            [19.5651, 52.9741],
            [19.5572, 52.9776],
            [19.5559, 52.9901],
            [19.5766, 52.9843],
            [19.6114, 52.9776],
            [19.6243, 52.9773],
            [19.6386, 52.9692],
            [19.6506, 52.9696],
            [19.6701, 52.9551],
            [19.6812, 52.9557],
            [19.6783, 52.9775],
            [19.6652, 52.9878],
            [19.6769, 52.9999],
            [19.6842, 53.0217],
            [19.6659, 53.0398],
            [19.6546, 53.0395],
            [19.6537, 53.0508],
            [19.646, 53.0542],
            [19.6479, 53.0668],
            [19.6616, 53.0697],
            [19.6473, 53.0775],
            [19.6483, 53.0864],
            [19.6396, 53.0954],
            [19.6392, 53.1074],
            [19.6673, 53.1061],
            [19.6925, 53.1194],
            [19.71, 53.1221],
            [19.7129, 53.1338],
            [19.7332, 53.1434],
            [19.762, 53.152],
          ],
        ],
      },
      properties: {
        name: 'Mazowieckie',
        id: 'PL-14',
        NAME_ENG: 'Mazovia',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-14',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.801, 51.1919],
            [17.8153, 51.1868],
            [17.8268, 51.1951],
            [17.8467, 51.1863],
            [17.8516, 51.1712],
            [17.8445, 51.1636],
            [17.8323, 51.1374],
            [17.8199, 51.1399],
            [17.8132, 51.1322],
            [17.8241, 51.1179],
            [17.8293, 51.1198],
            [17.8758, 51.1125],
            [17.8865, 51.1048],
            [17.9073, 51.1081],
            [17.9221, 51.1147],
            [17.9414, 51.1093],
            [17.9875, 51.1205],
            [18.005, 51.1233],
            [18.0466, 51.1348],
            [18.0735, 51.145],
            [18.0664, 51.1511],
            [18.0787, 51.1658],
            [18.0941, 51.1565],
            [18.1065, 51.1557],
            [18.1245, 51.1686],
            [18.1358, 51.1718],
            [18.1521, 51.171],
            [18.1604, 51.1753],
            [18.1685, 51.1652],
            [18.207, 51.1552],
            [18.2406, 51.1596],
            [18.261, 51.1575],
            [18.2732, 51.1504],
            [18.2913, 51.1459],
            [18.3061, 51.1348],
            [18.3149, 51.1334],
            [18.3339, 51.1389],
            [18.3682, 51.1358],
            [18.3795, 51.1297],
            [18.4046, 51.1239],
            [18.4182, 51.1259],
            [18.4233, 51.1166],
            [18.4379, 51.1139],
            [18.4679, 51.1147],
            [18.4713, 51.1047],
            [18.4833, 51.0996],
            [18.4901, 51.0914],
            [18.4992, 51.0996],
            [18.515, 51.1049],
            [18.5224, 51.119],
            [18.5172, 51.1219],
            [18.5167, 51.1335],
            [18.5235, 51.1396],
            [18.5526, 51.1389],
            [18.5679, 51.1197],
            [18.5623, 51.1116],
            [18.5794, 51.0926],
            [18.5839, 51.0842],
            [18.6013, 51.0825],
            [18.6241, 51.0765],
            [18.6383, 51.0633],
            [18.6598, 51.0549],
            [18.6659, 51.0548],
            [18.6923, 51.0297],
            [18.6929, 51.0158],
            [18.6599, 51.0104],
            [18.6561, 51.0041],
            [18.6642, 50.9938],
            [18.6572, 50.9777],
            [18.6212, 50.9641],
            [18.619, 50.9556],
            [18.6228, 50.9485],
            [18.646, 50.9275],
            [18.6463, 50.9109],
            [18.6356, 50.8965],
            [18.6261, 50.8651],
            [18.6125, 50.8532],
            [18.5749, 50.8401],
            [18.5664, 50.8286],
            [18.5604, 50.8063],
            [18.5498, 50.8038],
            [18.5133, 50.8008],
            [18.5138, 50.7935],
            [18.5288, 50.7918],
            [18.5269, 50.776],
            [18.5446, 50.7739],
            [18.5653, 50.7572],
            [18.5814, 50.7504],
            [18.58, 50.7431],
            [18.5634, 50.7417],
            [18.5517, 50.7322],
            [18.5217, 50.73],
            [18.5122, 50.7232],
            [18.507, 50.7142],
            [18.4912, 50.7038],
            [18.492, 50.6973],
            [18.5056, 50.694],
            [18.495, 50.6807],
            [18.5054, 50.6733],
            [18.51, 50.6649],
            [18.5193, 50.6589],
            [18.5234, 50.6401],
            [18.5332, 50.6322],
            [18.5343, 50.6231],
            [18.5883, 50.6128],
            [18.5915, 50.606],
            [18.6016, 50.6045],
            [18.5915, 50.5767],
            [18.6015, 50.5678],
            [18.6152, 50.569],
            [18.6072, 50.5528],
            [18.5889, 50.547],
            [18.5734, 50.5476],
            [18.5583, 50.5407],
            [18.5313, 50.5431],
            [18.497, 50.55],
            [18.473, 50.5516],
            [18.437, 50.5457],
            [18.4306, 50.531],
            [18.4381, 50.5267],
            [18.4463, 50.5092],
            [18.4478, 50.4991],
            [18.4672, 50.4994],
            [18.4668, 50.4881],
            [18.4742, 50.4729],
            [18.4849, 50.4697],
            [18.4822, 50.4594],
            [18.4522, 50.4622],
            [18.455, 50.4754],
            [18.4406, 50.4812],
            [18.4131, 50.4741],
            [18.3801, 50.4841],
            [18.3838, 50.4558],
            [18.3886, 50.4398],
            [18.3861, 50.4317],
            [18.3758, 50.4269],
            [18.3661, 50.4271],
            [18.3647, 50.4186],
            [18.38, 50.4117],
            [18.3854, 50.3979],
            [18.3946, 50.3885],
            [18.3756, 50.3766],
            [18.3724, 50.3616],
            [18.3583, 50.3563],
            [18.3715, 50.3471],
            [18.3949, 50.3381],
            [18.4008, 50.3231],
            [18.4087, 50.3175],
            [18.3927, 50.3135],
            [18.3937, 50.3049],
            [18.4042, 50.2957],
            [18.406, 50.2882],
            [18.4001, 50.2764],
            [18.4225, 50.2721],
            [18.4274, 50.2663],
            [18.4211, 50.2601],
            [18.4274, 50.2477],
            [18.4073, 50.2538],
            [18.39, 50.2535],
            [18.3533, 50.258],
            [18.3604, 50.2481],
            [18.3471, 50.2467],
            [18.3241, 50.2395],
            [18.3142, 50.2395],
            [18.31, 50.2313],
            [18.2768, 50.2247],
            [18.272, 50.2197],
            [18.2523, 50.2224],
            [18.2367, 50.2147],
            [18.2348, 50.2007],
            [18.249, 50.1921],
            [18.2083, 50.1903],
            [18.1858, 50.1868],
            [18.1845, 50.1805],
            [18.1628, 50.1887],
            [18.1406, 50.1782],
            [18.1348, 50.184],
            [18.1249, 50.1807],
            [18.1227, 50.1745],
            [18.1109, 50.1674],
            [18.1018, 50.1762],
            [18.0629, 50.1702],
            [18.0568, 50.1611],
            [18.0643, 50.154],
            [18.0505, 50.1391],
            [18.0677, 50.126],
            [18.0617, 50.115],
            [18.0668, 50.1076],
            [18.056, 50.086],
            [18.041, 50.0694],
            [18.0342, 50.0653],
            [18.0105, 50.0535],
            [18.005, 50.0528],
            [18.0043, 50.0382],
            [18.0159, 50.0306],
            [18.0247, 50.0386],
            [18.0444, 50.036],
            [18.0485, 50.0277],
            [18.0428, 50.0218],
            [18.0464, 50.0159],
            [18.0344, 50.0105],
            [18.0078, 50.0102],
            [18.0045, 50.0181],
            [17.9891, 50.0134],
            [17.9826, 50.0049],
            [17.9689, 50.0089],
            [17.9534, 50.0046],
            [17.9218, 49.9783],
            [17.91, 49.9763],
            [17.8845, 49.9787],
            [17.8694, 49.9724],
            [17.8639, 49.9793],
            [17.8515, 49.9827],
            [17.8362, 49.9922],
            [17.8266, 49.9941],
            [17.8359, 50.004],
            [17.8279, 50.0106],
            [17.7969, 50.0125],
            [17.7926, 50.0172],
            [17.7772, 50.0194],
            [17.7751, 50.0289],
            [17.7684, 50.0373],
            [17.7683, 50.0514],
            [17.7608, 50.0532],
            [17.7556, 50.0685],
            [17.7487, 50.0782],
            [17.7329, 50.0838],
            [17.7306, 50.0968],
            [17.7049, 50.1141],
            [17.6829, 50.1166],
            [17.6764, 50.1036],
            [17.6464, 50.1149],
            [17.6434, 50.1276],
            [17.6379, 50.1317],
            [17.6104, 50.1405],
            [17.6033, 50.1512],
            [17.5926, 50.1591],
            [17.6021, 50.1701],
            [17.6255, 50.1692],
            [17.6386, 50.1738],
            [17.665, 50.1782],
            [17.6799, 50.1829],
            [17.7, 50.185],
            [17.7115, 50.1918],
            [17.7118, 50.199],
            [17.7187, 50.2058],
            [17.7428, 50.202],
            [17.7581, 50.2065],
            [17.7568, 50.2224],
            [17.7645, 50.2361],
            [17.7393, 50.2482],
            [17.7248, 50.2571],
            [17.7267, 50.2732],
            [17.7343, 50.2774],
            [17.7424, 50.2906],
            [17.7522, 50.2994],
            [17.7288, 50.3026],
            [17.723, 50.3172],
            [17.7178, 50.3208],
            [17.6872, 50.3278],
            [17.6882, 50.3008],
            [17.6758, 50.2924],
            [17.6678, 50.2911],
            [17.6517, 50.2784],
            [17.6138, 50.2661],
            [17.5968, 50.2711],
            [17.592, 50.2789],
            [17.5806, 50.2782],
            [17.5639, 50.2715],
            [17.5464, 50.2698],
            [17.5268, 50.2732],
            [17.4954, 50.2751],
            [17.4714, 50.2702],
            [17.4598, 50.2702],
            [17.4567, 50.2575],
            [17.4419, 50.252],
            [17.4306, 50.2551],
            [17.4322, 50.2631],
            [17.4209, 50.2778],
            [17.4085, 50.2743],
            [17.393, 50.2826],
            [17.3693, 50.2826],
            [17.3648, 50.2806],
            [17.3646, 50.2656],
            [17.3489, 50.2671],
            [17.3407, 50.284],
            [17.3542, 50.3087],
            [17.3391, 50.3135],
            [17.3421, 50.3225],
            [17.3289, 50.3275],
            [17.3127, 50.3206],
            [17.293, 50.3181],
            [17.2785, 50.3216],
            [17.2679, 50.3292],
            [17.2486, 50.3327],
            [17.2277, 50.3436],
            [17.2106, 50.3597],
            [17.201, 50.3645],
            [17.1989, 50.3754],
            [17.2036, 50.386],
            [17.1848, 50.3898],
            [17.178, 50.3857],
            [17.1571, 50.3802],
            [17.1433, 50.3812],
            [17.1234, 50.394],
            [17.1107, 50.3936],
            [17.1106, 50.4045],
            [17.0524, 50.407],
            [17.0236, 50.4214],
            [17.0134, 50.4185],
            [17.0015, 50.427],
            [16.9745, 50.4185],
            [16.9683, 50.4241],
            [16.9449, 50.435],
            [16.928, 50.4388],
            [16.9113, 50.4475],
            [16.918, 50.4524],
            [16.9402, 50.4599],
            [16.947, 50.4809],
            [16.9588, 50.488],
            [16.9792, 50.485],
            [16.9884, 50.4782],
            [17.0074, 50.4746],
            [17.0202, 50.4808],
            [17.0189, 50.4692],
            [17.0391, 50.4653],
            [17.0474, 50.466],
            [17.0605, 50.4766],
            [17.0631, 50.4891],
            [17.0588, 50.5046],
            [17.0739, 50.5175],
            [17.063, 50.5312],
            [17.0637, 50.5428],
            [17.0771, 50.5565],
            [17.0953, 50.5651],
            [17.1006, 50.5763],
            [17.1152, 50.5881],
            [17.1209, 50.6077],
            [17.1824, 50.6171],
            [17.2133, 50.6157],
            [17.2362, 50.6127],
            [17.2457, 50.6196],
            [17.2447, 50.6277],
            [17.2342, 50.6327],
            [17.2315, 50.6426],
            [17.2403, 50.6585],
            [17.236, 50.6815],
            [17.2467, 50.6828],
            [17.2589, 50.6903],
            [17.221, 50.7014],
            [17.2227, 50.714],
            [17.2517, 50.7188],
            [17.2615, 50.7295],
            [17.2631, 50.7386],
            [17.2767, 50.7439],
            [17.2888, 50.7426],
            [17.2947, 50.7535],
            [17.2807, 50.7607],
            [17.2672, 50.7712],
            [17.2744, 50.7876],
            [17.2884, 50.7931],
            [17.3057, 50.7881],
            [17.3478, 50.7848],
            [17.3533, 50.793],
            [17.3486, 50.8062],
            [17.3375, 50.8078],
            [17.3199, 50.8212],
            [17.3414, 50.8263],
            [17.3288, 50.8495],
            [17.3479, 50.8522],
            [17.3569, 50.8565],
            [17.3845, 50.8613],
            [17.3841, 50.8744],
            [17.3944, 50.8745],
            [17.3943, 50.8869],
            [17.3732, 50.8982],
            [17.3526, 50.9],
            [17.38, 50.9242],
            [17.4007, 50.9193],
            [17.4065, 50.9102],
            [17.4274, 50.9097],
            [17.4308, 50.9239],
            [17.4395, 50.9384],
            [17.4324, 50.947],
            [17.4342, 50.9549],
            [17.431, 50.967],
            [17.4432, 50.9689],
            [17.4644, 50.9769],
            [17.4684, 50.9715],
            [17.5014, 50.9747],
            [17.509, 50.9708],
            [17.5166, 50.9779],
            [17.5052, 50.9842],
            [17.5419, 50.9908],
            [17.542, 51.0029],
            [17.5269, 51.0041],
            [17.5156, 51.0123],
            [17.5198, 51.0257],
            [17.5346, 51.043],
            [17.526, 51.0493],
            [17.5201, 51.0595],
            [17.5277, 51.0673],
            [17.5375, 51.0697],
            [17.5519, 51.0671],
            [17.5816, 51.0821],
            [17.5624, 51.0936],
            [17.5797, 51.1107],
            [17.5714, 51.1173],
            [17.5761, 51.1299],
            [17.568, 51.1377],
            [17.5466, 51.1434],
            [17.5463, 51.1537],
            [17.5594, 51.1565],
            [17.568, 51.1657],
            [17.5784, 51.1624],
            [17.6084, 51.1894],
            [17.6194, 51.1844],
            [17.6227, 51.1747],
            [17.6403, 51.1734],
            [17.67, 51.1609],
            [17.6842, 51.1657],
            [17.7009, 51.1647],
            [17.6991, 51.1716],
            [17.7142, 51.1749],
            [17.7393, 51.1748],
            [17.7554, 51.1863],
            [17.7802, 51.1829],
            [17.7838, 51.1879],
            [17.801, 51.1919],
          ],
        ],
      },
      properties: {
        name: 'Opolskie',
        id: 'PL-16',
        NAME_ENG: 'Upper Silesia',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-16',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.8688, 50.8102],
            [21.8845, 50.7989],
            [21.8954, 50.7816],
            [21.9115, 50.777],
            [21.9726, 50.7785],
            [21.9921, 50.7887],
            [22.0212, 50.7884],
            [22.0754, 50.8236],
            [22.1038, 50.8206],
            [22.1529, 50.8079],
            [22.1804, 50.804],
            [22.1865, 50.797],
            [22.1846, 50.7759],
            [22.1931, 50.7715],
            [22.2144, 50.7691],
            [22.2177, 50.7606],
            [22.1736, 50.714],
            [22.1687, 50.6952],
            [22.1499, 50.6812],
            [22.1471, 50.6747],
            [22.1535, 50.6692],
            [22.1909, 50.6673],
            [22.2198, 50.6694],
            [22.2467, 50.6502],
            [22.2683, 50.6259],
            [22.2984, 50.6265],
            [22.3448, 50.6244],
            [22.3583, 50.6209],
            [22.3851, 50.6067],
            [22.4028, 50.6041],
            [22.4278, 50.5821],
            [22.4438, 50.5816],
            [22.4728, 50.5917],
            [22.4802, 50.5921],
            [22.4858, 50.5821],
            [22.4996, 50.5744],
            [22.5061, 50.5749],
            [22.5188, 50.5842],
            [22.5357, 50.5716],
            [22.5351, 50.5538],
            [22.5203, 50.5497],
            [22.5211, 50.5336],
            [22.5283, 50.5332],
            [22.5265, 50.5212],
            [22.5349, 50.516],
            [22.5224, 50.5084],
            [22.544, 50.5027],
            [22.5686, 50.5092],
            [22.5745, 50.4981],
            [22.5697, 50.4943],
            [22.5399, 50.4834],
            [22.5433, 50.4768],
            [22.5821, 50.4833],
            [22.5812, 50.4754],
            [22.5613, 50.4694],
            [22.5557, 50.4607],
            [22.5455, 50.4534],
            [22.5373, 50.4552],
            [22.5053, 50.4545],
            [22.4879, 50.4555],
            [22.4628, 50.4596],
            [22.4632, 50.4652],
            [22.4459, 50.4621],
            [22.4585, 50.4509],
            [22.4596, 50.435],
            [22.451, 50.4327],
            [22.4459, 50.4198],
            [22.4122, 50.4234],
            [22.408, 50.3977],
            [22.4206, 50.401],
            [22.4382, 50.4002],
            [22.4354, 50.3929],
            [22.4532, 50.3885],
            [22.4509, 50.3718],
            [22.4824, 50.3556],
            [22.5028, 50.3527],
            [22.5062, 50.3444],
            [22.5179, 50.3502],
            [22.5374, 50.3514],
            [22.5649, 50.3574],
            [22.5788, 50.3558],
            [22.596, 50.3494],
            [22.6217, 50.3554],
            [22.6397, 50.3575],
            [22.6535, 50.3556],
            [22.6875, 50.3545],
            [22.6863, 50.346],
            [22.6588, 50.3375],
            [22.6252, 50.3346],
            [22.6067, 50.3267],
            [22.6089, 50.3093],
            [22.6371, 50.3038],
            [22.647, 50.3068],
            [22.6558, 50.3029],
            [22.7037, 50.294],
            [22.7135, 50.2941],
            [22.7284, 50.3003],
            [22.752, 50.3024],
            [22.7851, 50.3015],
            [22.8238, 50.3019],
            [22.8519, 50.2947],
            [22.8721, 50.2998],
            [22.8917, 50.3016],
            [22.9436, 50.2926],
            [22.9764, 50.2955],
            [22.9925, 50.2946],
            [23.019, 50.2884],
            [23.0493, 50.3035],
            [23.0601, 50.317],
            [23.074, 50.3272],
            [23.0942, 50.3343],
            [23.1413, 50.3664],
            [23.1825, 50.3972],
            [23.2151, 50.3885],
            [23.2326, 50.3745],
            [23.2469, 50.3681],
            [23.2607, 50.3655],
            [23.2675, 50.3723],
            [23.3038, 50.3809],
            [23.3185, 50.3925],
            [23.3371, 50.4001],
            [23.3546, 50.4044],
            [23.358, 50.4121],
            [23.3872, 50.4067],
            [23.3836, 50.4004],
            [23.3697, 50.4012],
            [23.3672, 50.3912],
            [23.3831, 50.3822],
            [23.3734, 50.3775],
            [23.3783, 50.3699],
            [23.4008, 50.3645],
            [23.4118, 50.3524],
            [23.4363, 50.347],
            [23.4322, 50.337],
            [23.4171, 50.3206],
            [23.4145, 50.3077],
            [23.4317, 50.3036],
            [23.4598, 50.3033],
            [23.4698, 50.2941],
            [23.492, 50.2992],
            [23.5187, 50.2956],
            [23.5156, 50.2885],
            [23.4998, 50.287],
            [23.5035, 50.2805],
            [23.5143, 50.2783],
            [23.5154, 50.2709],
            [23.5084, 50.2612],
            [23.5294, 50.2624],
            [23.5428, 50.2575],
            [23.5434, 50.2487],
            [23.5108, 50.2316],
            [23.4738, 50.2177],
            [23.4455, 50.1947],
            [23.4222, 50.1791],
            [23.3779, 50.1567],
            [23.3595, 50.1446],
            [23.3177, 50.1134],
            [23.3064, 50.1104],
            [23.2976, 50.1033],
            [23.281, 50.1033],
            [23.282, 50.086],
            [23.2682, 50.08],
            [23.2395, 50.0536],
            [23.2199, 50.0494],
            [23.2151, 50.0307],
            [23.1881, 50.0069],
            [23.1455, 49.9723],
            [23.12, 49.9583],
            [23.1073, 49.9397],
            [23.093, 49.9268],
            [23.0601, 49.9026],
            [23.0162, 49.8624],
            [22.9973, 49.8424],
            [22.9714, 49.8383],
            [22.959, 49.8126],
            [22.9623, 49.8049],
            [22.9429, 49.7985],
            [22.9298, 49.7901],
            [22.9026, 49.7678],
            [22.8961, 49.7504],
            [22.875, 49.7402],
            [22.8541, 49.7216],
            [22.847, 49.71],
            [22.8259, 49.6958],
            [22.8093, 49.6943],
            [22.7896, 49.6773],
            [22.7851, 49.6566],
            [22.7604, 49.6357],
            [22.7485, 49.6286],
            [22.6992, 49.5841],
            [22.6856, 49.5695],
            [22.6766, 49.5501],
            [22.6644, 49.5386],
            [22.6431, 49.5307],
            [22.6532, 49.5105],
            [22.6755, 49.4973],
            [22.7003, 49.4943],
            [22.7114, 49.4515],
            [22.7172, 49.4343],
            [22.739, 49.3894],
            [22.749, 49.3625],
            [22.7464, 49.3345],
            [22.7536, 49.3173],
            [22.7466, 49.2972],
            [22.7425, 49.2485],
            [22.7243, 49.2426],
            [22.7181, 49.2346],
            [22.7232, 49.2211],
            [22.7439, 49.2196],
            [22.7103, 49.176],
            [22.7219, 49.1655],
            [22.7266, 49.1745],
            [22.7375, 49.1832],
            [22.75, 49.1846],
            [22.7586, 49.181],
            [22.7462, 49.1714],
            [22.742, 49.1596],
            [22.7549, 49.1541],
            [22.7801, 49.1562],
            [22.7907, 49.1588],
            [22.7959, 49.1394],
            [22.8093, 49.134],
            [22.8148, 49.1253],
            [22.8305, 49.1207],
            [22.831, 49.1146],
            [22.8422, 49.1109],
            [22.8518, 49.1154],
            [22.8612, 49.1122],
            [22.861, 49.1032],
            [22.894, 49.0939],
            [22.8821, 49.0817],
            [22.8704, 49.0772],
            [22.8678, 49.0676],
            [22.8767, 49.0507],
            [22.8691, 49.0404],
            [22.8766, 49.0382],
            [22.8812, 49.0253],
            [22.8937, 49.0206],
            [22.892, 49.0085],
            [22.8642, 49.0029],
            [22.8495, 49.0033],
            [22.8418, 49.0205],
            [22.8351, 49.0269],
            [22.8172, 49.0261],
            [22.8115, 49.0341],
            [22.7888, 49.0474],
            [22.767, 49.0544],
            [22.7576, 49.0438],
            [22.7205, 49.0534],
            [22.7055, 49.0518],
            [22.6842, 49.0403],
            [22.6703, 49.0487],
            [22.6561, 49.0509],
            [22.64, 49.0637],
            [22.6281, 49.081],
            [22.6071, 49.0866],
            [22.6018, 49.0945],
            [22.5844, 49.0977],
            [22.5644, 49.088],
            [22.5417, 49.0957],
            [22.5144, 49.093],
            [22.5012, 49.0865],
            [22.4894, 49.0893],
            [22.4634, 49.1037],
            [22.4378, 49.1029],
            [22.4301, 49.0992],
            [22.4136, 49.1045],
            [22.3929, 49.1202],
            [22.391, 49.1289],
            [22.3812, 49.1321],
            [22.3742, 49.1432],
            [22.3662, 49.1461],
            [22.3414, 49.1472],
            [22.3301, 49.1444],
            [22.3206, 49.1363],
            [22.3082, 49.1381],
            [22.2506, 49.1528],
            [22.2281, 49.1537],
            [22.2291, 49.1623],
            [22.2358, 49.1677],
            [22.2332, 49.1796],
            [22.226, 49.1841],
            [22.2122, 49.1856],
            [22.2003, 49.1774],
            [22.1886, 49.1768],
            [22.1814, 49.1821],
            [22.1472, 49.1924],
            [22.1454, 49.2002],
            [22.1186, 49.2025],
            [22.0874, 49.2086],
            [22.0506, 49.2178],
            [22.0352, 49.2261],
            [22.0388, 49.2424],
            [22.0446, 49.256],
            [22.0332, 49.2635],
            [22.0341, 49.2802],
            [22.0023, 49.2975],
            [21.9863, 49.3105],
            [21.9764, 49.3314],
            [21.9656, 49.3463],
            [21.9595, 49.3494],
            [21.9326, 49.346],
            [21.919, 49.3534],
            [21.9022, 49.3535],
            [21.8949, 49.3568],
            [21.8907, 49.3666],
            [21.8797, 49.3725],
            [21.8604, 49.3689],
            [21.8493, 49.3783],
            [21.8492, 49.3856],
            [21.8422, 49.391],
            [21.8232, 49.3887],
            [21.8135, 49.3822],
            [21.7981, 49.3784],
            [21.7905, 49.3706],
            [21.7907, 49.3544],
            [21.7765, 49.3578],
            [21.7692, 49.3657],
            [21.7617, 49.3852],
            [21.7442, 49.3917],
            [21.7367, 49.3989],
            [21.7256, 49.4021],
            [21.7231, 49.4125],
            [21.7003, 49.4191],
            [21.6832, 49.4112],
            [21.6603, 49.4172],
            [21.66, 49.4236],
            [21.6451, 49.4311],
            [21.6337, 49.448],
            [21.6158, 49.4442],
            [21.6041, 49.4354],
            [21.5877, 49.4336],
            [21.5672, 49.4414],
            [21.5531, 49.4333],
            [21.5326, 49.4352],
            [21.5261, 49.4322],
            [21.5156, 49.4187],
            [21.4987, 49.429],
            [21.4882, 49.427],
            [21.4772, 49.4142],
            [21.4601, 49.416],
            [21.449, 49.4123],
            [21.4333, 49.4127],
            [21.3975, 49.4335],
            [21.3929, 49.4546],
            [21.4, 49.4571],
            [21.4087, 49.4669],
            [21.3904, 49.4848],
            [21.4011, 49.4909],
            [21.4198, 49.4916],
            [21.4182, 49.4974],
            [21.4033, 49.5011],
            [21.3881, 49.5185],
            [21.3747, 49.5276],
            [21.3732, 49.5413],
            [21.3589, 49.5475],
            [21.3738, 49.5618],
            [21.3733, 49.5706],
            [21.3654, 49.5754],
            [21.3476, 49.5776],
            [21.3289, 49.5878],
            [21.3304, 49.5995],
            [21.325, 49.6044],
            [21.357, 49.6302],
            [21.3655, 49.6398],
            [21.3652, 49.6536],
            [21.3541, 49.6632],
            [21.3517, 49.6703],
            [21.3572, 49.6889],
            [21.3557, 49.699],
            [21.3356, 49.705],
            [21.3321, 49.7228],
            [21.3199, 49.7506],
            [21.3079, 49.7579],
            [21.2745, 49.7581],
            [21.2633, 49.7615],
            [21.2475, 49.7803],
            [21.2424, 49.7772],
            [21.2032, 49.7807],
            [21.1834, 49.7787],
            [21.1582, 49.7818],
            [21.1707, 49.8078],
            [21.1674, 49.8242],
            [21.1473, 49.8356],
            [21.1415, 49.8414],
            [21.1434, 49.8501],
            [21.1846, 49.8506],
            [21.2069, 49.855],
            [21.2323, 49.8574],
            [21.2323, 49.8836],
            [21.2407, 49.891],
            [21.2623, 49.8855],
            [21.2851, 49.8888],
            [21.2966, 49.8989],
            [21.2982, 49.9092],
            [21.2821, 49.9267],
            [21.2642, 49.9277],
            [21.22, 49.9272],
            [21.1876, 49.9313],
            [21.1745, 49.9487],
            [21.1704, 49.9615],
            [21.1603, 49.9733],
            [21.1557, 49.9882],
            [21.1587, 50.0065],
            [21.162, 50.0103],
            [21.1846, 50.016],
            [21.1982, 50.0324],
            [21.1966, 50.0389],
            [21.171, 50.0455],
            [21.1573, 50.0515],
            [21.1571, 50.0602],
            [21.1753, 50.0664],
            [21.1949, 50.0828],
            [21.1761, 50.0844],
            [21.1726, 50.0882],
            [21.1797, 50.097],
            [21.2034, 50.0964],
            [21.2069, 50.1008],
            [21.186, 50.1179],
            [21.1939, 50.1302],
            [21.1663, 50.1313],
            [21.1601, 50.1392],
            [21.1805, 50.1381],
            [21.1772, 50.1436],
            [21.153, 50.1455],
            [21.1527, 50.1485],
            [21.1704, 50.1697],
            [21.1729, 50.1757],
            [21.1767, 50.2177],
            [21.1677, 50.2248],
            [21.1456, 50.2335],
            [21.1467, 50.2376],
            [21.1729, 50.2425],
            [21.1729, 50.246],
            [21.1475, 50.2547],
            [21.1464, 50.2602],
            [21.1704, 50.2795],
            [21.1817, 50.2858],
            [21.197, 50.3073],
            [21.2193, 50.3149],
            [21.2205, 50.3278],
            [21.2162, 50.3324],
            [21.2189, 50.3415],
            [21.2094, 50.3553],
            [21.2202, 50.358],
            [21.2268, 50.3705],
            [21.2359, 50.3761],
            [21.2472, 50.3767],
            [21.2542, 50.3881],
            [21.2644, 50.3919],
            [21.277, 50.3906],
            [21.2824, 50.3961],
            [21.28, 50.4057],
            [21.3043, 50.416],
            [21.3142, 50.423],
            [21.3272, 50.4257],
            [21.3442, 50.4357],
            [21.3682, 50.4407],
            [21.3995, 50.4388],
            [21.4473, 50.4575],
            [21.4527, 50.467],
            [21.4441, 50.4872],
            [21.45, 50.4922],
            [21.4794, 50.4951],
            [21.4959, 50.4999],
            [21.5261, 50.5122],
            [21.5601, 50.5211],
            [21.5856, 50.5165],
            [21.6038, 50.5199],
            [21.6251, 50.5454],
            [21.6492, 50.5632],
            [21.6594, 50.5755],
            [21.6691, 50.603],
            [21.6739, 50.6084],
            [21.7097, 50.6287],
            [21.7174, 50.6301],
            [21.7353, 50.6418],
            [21.7507, 50.6398],
            [21.7573, 50.652],
            [21.7708, 50.642],
            [21.7885, 50.6412],
            [21.7976, 50.6661],
            [21.826, 50.6983],
            [21.8281, 50.7163],
            [21.8439, 50.7373],
            [21.8402, 50.7525],
            [21.8432, 50.7642],
            [21.8575, 50.7798],
            [21.8627, 50.7996],
            [21.8688, 50.8102],
          ],
        ],
      },
      properties: {
        name: 'Podkarpackie',
        id: 'PL-18',
        NAME_ENG: 'Subcarpathia',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-18',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.1802, 52.284],
            [23.1572, 52.2806],
            [23.1334, 52.2857],
            [23.1193, 52.3002],
            [23.1096, 52.3021],
            [23.077, 52.2843],
            [23.059, 52.2845],
            [23.0495, 52.2938],
            [23.0577, 52.3006],
            [23.0575, 52.3068],
            [23.0461, 52.326],
            [23.0375, 52.3318],
            [23.0159, 52.3341],
            [23.0022, 52.3381],
            [22.9958, 52.3327],
            [22.9764, 52.3472],
            [22.9658, 52.3639],
            [22.9502, 52.3706],
            [22.9196, 52.3753],
            [22.8882, 52.3645],
            [22.87, 52.3628],
            [22.8629, 52.3649],
            [22.8487, 52.3588],
            [22.8262, 52.3665],
            [22.8126, 52.3678],
            [22.7941, 52.3741],
            [22.7752, 52.3705],
            [22.766, 52.3738],
            [22.76, 52.3827],
            [22.74, 52.3818],
            [22.7336, 52.3676],
            [22.7204, 52.3698],
            [22.723, 52.3833],
            [22.7122, 52.3856],
            [22.7055, 52.3925],
            [22.6931, 52.3932],
            [22.661, 52.3863],
            [22.659, 52.3936],
            [22.6456, 52.3882],
            [22.6254, 52.3931],
            [22.6171, 52.3874],
            [22.6071, 52.3984],
            [22.5913, 52.3983],
            [22.5672, 52.4032],
            [22.5566, 52.4177],
            [22.5417, 52.4234],
            [22.5418, 52.4407],
            [22.5467, 52.4543],
            [22.5534, 52.4612],
            [22.5445, 52.4674],
            [22.5307, 52.4617],
            [22.5251, 52.4708],
            [22.5111, 52.4824],
            [22.5146, 52.4979],
            [22.5204, 52.5053],
            [22.5249, 52.5234],
            [22.5015, 52.5386],
            [22.4907, 52.5478],
            [22.4864, 52.5588],
            [22.4691, 52.5651],
            [22.4614, 52.5737],
            [22.4574, 52.5858],
            [22.4176, 52.599],
            [22.4064, 52.6098],
            [22.3931, 52.6131],
            [22.4247, 52.6249],
            [22.4487, 52.6477],
            [22.46, 52.6537],
            [22.4628, 52.661],
            [22.4479, 52.6845],
            [22.4446, 52.704],
            [22.4534, 52.7497],
            [22.4561, 52.7889],
            [22.4426, 52.7959],
            [22.4076, 52.7974],
            [22.3892, 52.8003],
            [22.3833, 52.7833],
            [22.3529, 52.7819],
            [22.3321, 52.7627],
            [22.3251, 52.7653],
            [22.3221, 52.7819],
            [22.3158, 52.7847],
            [22.2994, 52.7785],
            [22.2825, 52.7823],
            [22.2716, 52.8087],
            [22.2591, 52.8255],
            [22.2452, 52.8368],
            [22.2461, 52.8408],
            [22.2633, 52.8478],
            [22.2783, 52.8402],
            [22.2928, 52.8414],
            [22.3076, 52.8545],
            [22.304, 52.8615],
            [22.2787, 52.8541],
            [22.277, 52.8626],
            [22.2863, 52.8721],
            [22.2875, 52.8801],
            [22.2754, 52.8895],
            [22.2675, 52.8914],
            [22.2454, 52.8901],
            [22.2179, 52.8927],
            [22.2129, 52.8867],
            [22.2136, 52.8643],
            [22.1852, 52.8665],
            [22.1593, 52.8581],
            [22.1438, 52.8597],
            [22.1284, 52.8551],
            [22.1161, 52.8579],
            [22.1074, 52.8675],
            [22.0987, 52.8653],
            [22.0904, 52.8541],
            [22.0382, 52.8604],
            [22.028, 52.8654],
            [22.0386, 52.8823],
            [22.0252, 52.8865],
            [22.0047, 52.8781],
            [22.0053, 52.8925],
            [22.0115, 52.9005],
            [22.0087, 52.9055],
            [21.9935, 52.9152],
            [21.9928, 52.9196],
            [22.0204, 52.9319],
            [22.0276, 52.9408],
            [22.0101, 52.9422],
            [22.0136, 52.9507],
            [22.0035, 52.9583],
            [21.9984, 52.9726],
            [21.9771, 52.9792],
            [21.9645, 52.9791],
            [21.9599, 52.9853],
            [21.9185, 52.9892],
            [21.9099, 52.9995],
            [21.8892, 53.0031],
            [21.8799, 53.0101],
            [21.876, 53.0224],
            [21.8925, 53.029],
            [21.8977, 53.0459],
            [21.9125, 53.0392],
            [21.9397, 53.0389],
            [21.9386, 53.0465],
            [21.9233, 53.0467],
            [21.9221, 53.0558],
            [21.9244, 53.0602],
            [21.9399, 53.0714],
            [21.9293, 53.0785],
            [21.9183, 53.079],
            [21.9144, 53.0712],
            [21.8924, 53.0616],
            [21.8721, 53.0599],
            [21.859, 53.0646],
            [21.8602, 53.0735],
            [21.8428, 53.0767],
            [21.8369, 53.0872],
            [21.8171, 53.1016],
            [21.8077, 53.1148],
            [21.787, 53.112],
            [21.753, 53.1139],
            [21.7339, 53.1094],
            [21.7014, 53.1322],
            [21.6997, 53.1413],
            [21.6927, 53.1477],
            [21.7059, 53.1605],
            [21.7034, 53.168],
            [21.6877, 53.1798],
            [21.6766, 53.204],
            [21.6695, 53.2079],
            [21.6779, 53.2154],
            [21.6623, 53.229],
            [21.6612, 53.2371],
            [21.6363, 53.2598],
            [21.6583, 53.262],
            [21.658, 53.2789],
            [21.6491, 53.2919],
            [21.6606, 53.2945],
            [21.6602, 53.3052],
            [21.6547, 53.3175],
            [21.6669, 53.3169],
            [21.67, 53.3114],
            [21.6944, 53.314],
            [21.711, 53.3054],
            [21.7358, 53.3118],
            [21.721, 53.3192],
            [21.7094, 53.3199],
            [21.7029, 53.3262],
            [21.6908, 53.3292],
            [21.6841, 53.3362],
            [21.6704, 53.3301],
            [21.6524, 53.3316],
            [21.6541, 53.3367],
            [21.6693, 53.3371],
            [21.6555, 53.3492],
            [21.6641, 53.3566],
            [21.6795, 53.3623],
            [21.6834, 53.3673],
            [21.6623, 53.3721],
            [21.6618, 53.3861],
            [21.6369, 53.4054],
            [21.6257, 53.4086],
            [21.6392, 53.4267],
            [21.6301, 53.4272],
            [21.6111, 53.4349],
            [21.6085, 53.4492],
            [21.6028, 53.4532],
            [21.6041, 53.4658],
            [21.5986, 53.4804],
            [21.6481, 53.4801],
            [21.6821, 53.4917],
            [21.7304, 53.4888],
            [21.7548, 53.4827],
            [21.7783, 53.4806],
            [21.8042, 53.475],
            [21.8261, 53.4655],
            [21.8437, 53.4602],
            [21.8625, 53.4578],
            [21.8818, 53.4622],
            [21.8913, 53.4678],
            [21.9019, 53.4841],
            [21.9018, 53.4928],
            [21.9329, 53.5103],
            [21.9539, 53.5103],
            [21.9913, 53.5215],
            [22.0086, 53.5136],
            [22.0219, 53.4978],
            [22.0345, 53.5028],
            [22.0421, 53.5155],
            [22.0562, 53.5229],
            [22.1164, 53.5377],
            [22.1573, 53.5537],
            [22.1948, 53.5659],
            [22.2078, 53.5771],
            [22.227, 53.5852],
            [22.2335, 53.5933],
            [22.2513, 53.6031],
            [22.2702, 53.609],
            [22.2819, 53.6214],
            [22.2994, 53.6249],
            [22.3382, 53.6375],
            [22.3653, 53.6558],
            [22.4007, 53.6675],
            [22.4228, 53.6817],
            [22.4409, 53.6732],
            [22.4403, 53.666],
            [22.449, 53.6632],
            [22.4711, 53.6641],
            [22.4714, 53.6784],
            [22.4668, 53.6899],
            [22.4699, 53.6989],
            [22.4923, 53.6996],
            [22.5048, 53.6963],
            [22.5153, 53.6991],
            [22.5646, 53.7203],
            [22.5853, 53.7343],
            [22.5861, 53.726],
            [22.5966, 53.7189],
            [22.6099, 53.7193],
            [22.6177, 53.7334],
            [22.6282, 53.7411],
            [22.6257, 53.7506],
            [22.6399, 53.7546],
            [22.6415, 53.7463],
            [22.6728, 53.7562],
            [22.698, 53.7613],
            [22.7194, 53.7803],
            [22.7321, 53.7955],
            [22.7362, 53.8042],
            [22.732, 53.8278],
            [22.7391, 53.8378],
            [22.7633, 53.8487],
            [22.7771, 53.8628],
            [22.7977, 53.8775],
            [22.7891, 53.9023],
            [22.7889, 53.9188],
            [22.7804, 53.9331],
            [22.7426, 53.9603],
            [22.7272, 53.9767],
            [22.709, 53.9907],
            [22.7002, 54.0077],
            [22.6887, 54.0159],
            [22.672, 54.0385],
            [22.6535, 54.0471],
            [22.6163, 54.0591],
            [22.6141, 54.0753],
            [22.6178, 54.0839],
            [22.6395, 54.0967],
            [22.6348, 54.1097],
            [22.5978, 54.1494],
            [22.593, 54.1516],
            [22.572, 54.1433],
            [22.551, 54.146],
            [22.5407, 54.1629],
            [22.5256, 54.1673],
            [22.5073, 54.1922],
            [22.4773, 54.1949],
            [22.4765, 54.2016],
            [22.5025, 54.2133],
            [22.5185, 54.2257],
            [22.5188, 54.2405],
            [22.5232, 54.2452],
            [22.5592, 54.2501],
            [22.573, 54.2589],
            [22.584, 54.2608],
            [22.6174, 54.2606],
            [22.6336, 54.268],
            [22.6415, 54.2819],
            [22.652, 54.2831],
            [22.6852, 54.2748],
            [22.7016, 54.2743],
            [22.7446, 54.2865],
            [22.7706, 54.289],
            [22.7863, 54.2963],
            [22.796, 54.3083],
            [22.7963, 54.3201],
            [22.7815, 54.3273],
            [22.7979, 54.3329],
            [22.808, 54.3414],
            [22.8035, 54.3506],
            [22.7893, 54.3631],
            [22.8112, 54.37],
            [22.8361, 54.4054],
            [22.8559, 54.4095],
            [22.8881, 54.4078],
            [22.8881, 54.3992],
            [22.9327, 54.3957],
            [22.9505, 54.3917],
            [22.9836, 54.3893],
            [22.9853, 54.384],
            [23.0103, 54.3821],
            [22.9938, 54.3615],
            [23.0111, 54.3548],
            [23.0494, 54.3482],
            [23.0633, 54.3403],
            [23.0495, 54.3316],
            [23.042, 54.3157],
            [23.0908, 54.2984],
            [23.1398, 54.315],
            [23.1596, 54.3089],
            [23.1543, 54.2961],
            [23.1767, 54.2897],
            [23.2028, 54.2876],
            [23.2058, 54.282],
            [23.2234, 54.2711],
            [23.2344, 54.2596],
            [23.2485, 54.2554],
            [23.2817, 54.259],
            [23.2886, 54.2555],
            [23.3375, 54.2501],
            [23.3484, 54.2438],
            [23.3614, 54.2293],
            [23.3815, 54.2269],
            [23.3968, 54.2128],
            [23.4056, 54.1951],
            [23.419, 54.1878],
            [23.4255, 54.1758],
            [23.4596, 54.1736],
            [23.4618, 54.166],
            [23.4759, 54.1615],
            [23.475, 54.1555],
            [23.4867, 54.151],
            [23.4914, 54.1343],
            [23.4955, 54.1302],
            [23.4931, 54.1151],
            [23.5021, 54.111],
            [23.5065, 54.0988],
            [23.5183, 54.0877],
            [23.5183, 54.0769],
            [23.5284, 54.064],
            [23.5246, 54.0292],
            [23.5165, 54.0265],
            [23.5088, 54.0101],
            [23.4821, 53.9978],
            [23.4837, 53.9892],
            [23.4925, 53.9722],
            [23.5114, 53.9615],
            [23.5147, 53.9532],
            [23.5117, 53.9103],
            [23.5266, 53.8634],
            [23.5305, 53.858],
            [23.5476, 53.8548],
            [23.5448, 53.7906],
            [23.5516, 53.7646],
            [23.581, 53.7435],
            [23.5856, 53.725],
            [23.5853, 53.7044],
            [23.6032, 53.6606],
            [23.6268, 53.595],
            [23.6523, 53.542],
            [23.6601, 53.5197],
            [23.675, 53.4978],
            [23.7072, 53.4359],
            [23.7378, 53.3889],
            [23.7702, 53.3243],
            [23.8, 53.2713],
            [23.8191, 53.2425],
            [23.8541, 53.2311],
            [23.8616, 53.2194],
            [23.8575, 53.2089],
            [23.8629, 53.1957],
            [23.8793, 53.1872],
            [23.8945, 53.1751],
            [23.9075, 53.1707],
            [23.9142, 53.1612],
            [23.9145, 53.1409],
            [23.8962, 53.1293],
            [23.8888, 53.1154],
            [23.8878, 53.1037],
            [23.8822, 53.1],
            [23.8721, 53.0788],
            [23.8776, 53.078],
            [23.8815, 53.0647],
            [23.9016, 53.0563],
            [23.8976, 53.0485],
            [23.9159, 53.0377],
            [23.9146, 53.0293],
            [23.9249, 53.0238],
            [23.9332, 52.9879],
            [23.9306, 52.9756],
            [23.9459, 52.9582],
            [23.9161, 52.9373],
            [23.9158, 52.9043],
            [23.9253, 52.8979],
            [23.9233, 52.8533],
            [23.9245, 52.8299],
            [23.9388, 52.8121],
            [23.938, 52.7124],
            [23.8992, 52.6904],
            [23.8921, 52.6794],
            [23.8784, 52.6789],
            [23.8256, 52.6521],
            [23.7719, 52.6234],
            [23.7346, 52.6068],
            [23.7149, 52.6145],
            [23.6728, 52.6108],
            [23.6383, 52.6065],
            [23.5835, 52.5933],
            [23.5523, 52.5839],
            [23.5135, 52.5702],
            [23.4726, 52.5533],
            [23.4568, 52.5401],
            [23.4309, 52.5272],
            [23.4002, 52.5054],
            [23.3582, 52.4707],
            [23.329, 52.4422],
            [23.3166, 52.4319],
            [23.3091, 52.4131],
            [23.2979, 52.4028],
            [23.2767, 52.3913],
            [23.2428, 52.3588],
            [23.2228, 52.3372],
            [23.1855, 52.2925],
            [23.1802, 52.284],
          ],
        ],
      },
      properties: {
        name: 'Podlaskie',
        id: 'PL-20',
        NAME_ENG: 'Podlaskie',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-20',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.2631, 54.2734],
            [19.2738, 54.2625],
            [19.317, 54.2281],
            [19.3238, 54.2102],
            [19.3181, 54.1999],
            [19.2606, 54.181],
            [19.2517, 54.1715],
            [19.2711, 54.1588],
            [19.2707, 54.1459],
            [19.2538, 54.1273],
            [19.2408, 54.1164],
            [19.2404, 54.1112],
            [19.2493, 54.099],
            [19.2488, 54.087],
            [19.225, 54.0774],
            [19.223, 54.0697],
            [19.2548, 54.0602],
            [19.2654, 54.0546],
            [19.2959, 54.0502],
            [19.2977, 54.0467],
            [19.2785, 54.0377],
            [19.2779, 54.03],
            [19.2961, 54.0156],
            [19.3121, 54.025],
            [19.3279, 54.0247],
            [19.3518, 54.0145],
            [19.3721, 54.0088],
            [19.4061, 54.004],
            [19.4148, 53.9982],
            [19.3907, 53.9791],
            [19.3883, 53.9511],
            [19.4061, 53.9449],
            [19.4125, 53.9378],
            [19.4315, 53.9385],
            [19.4563, 53.9485],
            [19.4843, 53.9515],
            [19.4963, 53.956],
            [19.5236, 53.9554],
            [19.5685, 53.9568],
            [19.5939, 53.9611],
            [19.5982, 53.9504],
            [19.5838, 53.9539],
            [19.5771, 53.9493],
            [19.5831, 53.9376],
            [19.5804, 53.9271],
            [19.5565, 53.9249],
            [19.5606, 53.9179],
            [19.5563, 53.8965],
            [19.534, 53.8793],
            [19.5258, 53.8631],
            [19.5134, 53.8543],
            [19.5173, 53.8457],
            [19.4789, 53.8163],
            [19.4995, 53.8059],
            [19.4743, 53.7851],
            [19.4603, 53.7899],
            [19.4586, 53.8049],
            [19.447, 53.8084],
            [19.3966, 53.8156],
            [19.3543, 53.8226],
            [19.3384, 53.8226],
            [19.3151, 53.8069],
            [19.3193, 53.7933],
            [19.306, 53.7833],
            [19.3056, 53.7515],
            [19.2941, 53.7412],
            [19.271, 53.732],
            [19.2309, 53.7042],
            [19.2248, 53.6978],
            [19.2286, 53.6775],
            [19.2229, 53.6741],
            [19.2234, 53.6513],
            [19.2184, 53.645],
            [19.1919, 53.6482],
            [19.1847, 53.646],
            [19.1482, 53.6237],
            [19.1368, 53.613],
            [19.1362, 53.6054],
            [19.1441, 53.5845],
            [19.0953, 53.5971],
            [19.0474, 53.597],
            [19.0267, 53.5932],
            [19.0088, 53.593],
            [18.993, 53.5962],
            [18.9873, 53.5908],
            [18.9407, 53.589],
            [18.9267, 53.592],
            [18.9152, 53.58],
            [18.9032, 53.5774],
            [18.9081, 53.5908],
            [18.906, 53.5952],
            [18.8587, 53.6022],
            [18.8346, 53.6045],
            [18.7843, 53.607],
            [18.7691, 53.6058],
            [18.7581, 53.6097],
            [18.7393, 53.6361],
            [18.739, 53.6432],
            [18.746, 53.6577],
            [18.7737, 53.6797],
            [18.7834, 53.6962],
            [18.7647, 53.7002],
            [18.7404, 53.6912],
            [18.7306, 53.6842],
            [18.7145, 53.6877],
            [18.6952, 53.6991],
            [18.6823, 53.693],
            [18.6533, 53.6953],
            [18.6373, 53.6767],
            [18.6178, 53.6721],
            [18.5871, 53.6736],
            [18.5839, 53.6595],
            [18.5504, 53.6561],
            [18.5439, 53.6658],
            [18.5329, 53.6671],
            [18.5263, 53.6785],
            [18.5134, 53.6806],
            [18.5114, 53.6883],
            [18.5233, 53.6914],
            [18.5175, 53.7045],
            [18.5096, 53.7],
            [18.4945, 53.6994],
            [18.4725, 53.7026],
            [18.469, 53.6899],
            [18.4555, 53.693],
            [18.4187, 53.6874],
            [18.4169, 53.6909],
            [18.3824, 53.6884],
            [18.3444, 53.6918],
            [18.3217, 53.6959],
            [18.2637, 53.7017],
            [18.2592, 53.7128],
            [18.2726, 53.717],
            [18.2732, 53.7316],
            [18.267, 53.7412],
            [18.2562, 53.7461],
            [18.2208, 53.7398],
            [18.2146, 53.7466],
            [18.2005, 53.7452],
            [18.2005, 53.7558],
            [18.1817, 53.7533],
            [18.1803, 53.745],
            [18.1689, 53.7434],
            [18.1558, 53.753],
            [18.1511, 53.7669],
            [18.129, 53.7669],
            [18.1113, 53.7587],
            [18.0948, 53.7738],
            [18.0729, 53.7828],
            [18.0639, 53.7581],
            [18.0427, 53.7525],
            [18.0369, 53.7483],
            [18.037, 53.7354],
            [18.0271, 53.7333],
            [18.0114, 53.7238],
            [17.9983, 53.7253],
            [17.9774, 53.7373],
            [17.9545, 53.7425],
            [17.9427, 53.757],
            [17.9338, 53.7522],
            [17.9212, 53.7549],
            [17.8932, 53.7555],
            [17.8674, 53.7625],
            [17.855, 53.7604],
            [17.8561, 53.7515],
            [17.8763, 53.7488],
            [17.8784, 53.7371],
            [17.893, 53.7273],
            [17.8911, 53.7137],
            [17.8771, 53.7085],
            [17.8621, 53.6982],
            [17.8161, 53.687],
            [17.8003, 53.6886],
            [17.7867, 53.6955],
            [17.7747, 53.6915],
            [17.7529, 53.6951],
            [17.7429, 53.6913],
            [17.7291, 53.6594],
            [17.7048, 53.6332],
            [17.706, 53.6232],
            [17.7226, 53.6116],
            [17.7177, 53.603],
            [17.6615, 53.6043],
            [17.6581, 53.5936],
            [17.6797, 53.5883],
            [17.6804, 53.5816],
            [17.6721, 53.5736],
            [17.6561, 53.57],
            [17.6385, 53.5876],
            [17.6172, 53.5965],
            [17.6058, 53.5989],
            [17.5852, 53.5838],
            [17.5747, 53.5811],
            [17.5422, 53.5827],
            [17.5251, 53.5878],
            [17.5191, 53.6061],
            [17.5189, 53.6247],
            [17.5111, 53.6232],
            [17.4679, 53.6021],
            [17.4493, 53.6019],
            [17.4345, 53.5898],
            [17.4216, 53.5838],
            [17.4313, 53.5721],
            [17.4182, 53.5699],
            [17.4148, 53.557],
            [17.4206, 53.5498],
            [17.4218, 53.537],
            [17.4117, 53.5318],
            [17.3951, 53.5087],
            [17.3916, 53.4904],
            [17.3857, 53.4966],
            [17.3733, 53.4939],
            [17.3634, 53.4966],
            [17.3512, 53.4927],
            [17.3374, 53.5019],
            [17.3335, 53.5028],
            [17.3231, 53.5121],
            [17.305, 53.5121],
            [17.3088, 53.5251],
            [17.2976, 53.5262],
            [17.2675, 53.5372],
            [17.2378, 53.5336],
            [17.2198, 53.5338],
            [17.2094, 53.5373],
            [17.1962, 53.533],
            [17.1751, 53.5332],
            [17.1627, 53.527],
            [17.1443, 53.5337],
            [17.103, 53.5426],
            [17.0894, 53.542],
            [17.0531, 53.518],
            [17.0306, 53.5166],
            [17.0056, 53.5173],
            [16.9945, 53.5317],
            [17.0047, 53.5347],
            [17.0009, 53.55],
            [16.9798, 53.554],
            [16.9575, 53.5483],
            [16.9458, 53.5697],
            [16.938, 53.573],
            [16.936, 53.5865],
            [16.9209, 53.6036],
            [16.9139, 53.6059],
            [16.8988, 53.6213],
            [16.9043, 53.6264],
            [16.896, 53.6369],
            [16.8995, 53.6423],
            [16.8936, 53.6574],
            [16.8804, 53.6906],
            [16.8807, 53.7025],
            [16.8757, 53.7145],
            [16.8774, 53.7203],
            [16.8581, 53.7466],
            [16.8833, 53.7574],
            [16.9012, 53.761],
            [16.916, 53.7729],
            [16.9322, 53.7781],
            [16.9444, 53.7986],
            [16.9389, 53.8019],
            [16.9197, 53.7991],
            [16.9115, 53.8026],
            [16.9061, 53.8134],
            [16.9105, 53.8187],
            [16.8705, 53.8353],
            [16.8725, 53.8444],
            [16.8686, 53.8531],
            [16.8722, 53.867],
            [16.8955, 53.8736],
            [16.8961, 53.8789],
            [16.9246, 53.8745],
            [16.9624, 53.8749],
            [16.9765, 53.8772],
            [16.9803, 53.8981],
            [16.9737, 53.9036],
            [16.9505, 53.9123],
            [16.9425, 53.9117],
            [16.9274, 53.9183],
            [16.9185, 53.9259],
            [16.9091, 53.9253],
            [16.8709, 53.9479],
            [16.8766, 53.9521],
            [16.8805, 53.9639],
            [16.8756, 53.976],
            [16.8471, 53.9847],
            [16.8429, 53.9977],
            [16.8297, 53.9958],
            [16.8256, 54.0049],
            [16.8035, 53.9951],
            [16.8012, 54.0119],
            [16.7872, 54.019],
            [16.7845, 54.0248],
            [16.7987, 54.0383],
            [16.7955, 54.0502],
            [16.815, 54.0579],
            [16.8091, 54.066],
            [16.8091, 54.0754],
            [16.788, 54.0871],
            [16.7832, 54.0954],
            [16.7881, 54.1094],
            [16.8001, 54.1047],
            [16.8048, 54.1168],
            [16.7892, 54.1216],
            [16.7843, 54.1313],
            [16.7742, 54.1343],
            [16.774, 54.1549],
            [16.7623, 54.1559],
            [16.7491, 54.1716],
            [16.7354, 54.1759],
            [16.7372, 54.1866],
            [16.7334, 54.2022],
            [16.7218, 54.2026],
            [16.7154, 54.2139],
            [16.7288, 54.224],
            [16.7312, 54.2342],
            [16.7786, 54.2458],
            [16.8202, 54.2495],
            [16.8545, 54.2615],
            [16.8643, 54.2715],
            [16.8654, 54.2839],
            [16.8526, 54.3057],
            [16.8389, 54.3167],
            [16.8151, 54.3185],
            [16.8246, 54.341],
            [16.823, 54.3508],
            [16.8072, 54.366],
            [16.8112, 54.3731],
            [16.825, 54.37],
            [16.8497, 54.3809],
            [16.8397, 54.3922],
            [16.8331, 54.4055],
            [16.8337, 54.4198],
            [16.8446, 54.4369],
            [16.8446, 54.4461],
            [16.8364, 54.4561],
            [16.828, 54.473],
            [16.8117, 54.4834],
            [16.7993, 54.4861],
            [16.7794, 54.4858],
            [16.773, 54.4894],
            [16.7608, 54.5059],
            [16.7541, 54.5212],
            [16.7493, 54.5432],
            [16.7333, 54.5578],
            [16.7269, 54.5674],
            [16.7629, 54.5678],
            [16.792, 54.5762],
            [16.8363, 54.5854],
            [16.8903, 54.5908],
            [16.9164, 54.5992],
            [16.9627, 54.617],
            [17.0007, 54.6377],
            [17.0463, 54.6678],
            [17.0765, 54.6775],
            [17.1054, 54.6847],
            [17.1796, 54.7069],
            [17.2282, 54.7251],
            [17.2604, 54.7323],
            [17.2816, 54.7346],
            [17.3337, 54.743],
            [17.3534, 54.748],
            [17.4638, 54.7551],
            [17.5011, 54.7606],
            [17.5944, 54.7705],
            [17.6488, 54.7788],
            [17.7103, 54.7905],
            [17.7461, 54.7986],
            [17.8226, 54.8083],
            [17.8951, 54.8226],
            [17.9248, 54.825],
            [17.9686, 54.8303],
            [17.9918, 54.83],
            [18.0667, 54.8336],
            [18.1167, 54.8316],
            [18.1574, 54.8323],
            [18.2301, 54.8317],
            [18.3119, 54.8342],
            [18.3364, 54.8313],
            [18.3522, 54.8199],
            [18.3689, 54.8117],
            [18.4003, 54.8006],
            [18.4395, 54.7847],
            [18.4815, 54.7693],
            [18.5176, 54.7575],
            [18.5923, 54.7307],
            [18.6705, 54.7056],
            [18.7448, 54.6732],
            [18.7852, 54.6508],
            [18.8167, 54.6299],
            [18.8282, 54.6137],
            [18.8259, 54.6011],
            [18.814, 54.5918],
            [18.8079, 54.5926],
            [18.7947, 54.6095],
            [18.782, 54.6137],
            [18.7634, 54.6367],
            [18.7493, 54.6471],
            [18.7132, 54.6796],
            [18.6876, 54.6935],
            [18.6647, 54.6987],
            [18.6539, 54.699],
            [18.6359, 54.712],
            [18.6169, 54.7206],
            [18.5963, 54.7263],
            [18.5232, 54.7525],
            [18.5099, 54.7558],
            [18.467, 54.7708],
            [18.4501, 54.7786],
            [18.4262, 54.7864],
            [18.4201, 54.7833],
            [18.4185, 54.7697],
            [18.4032, 54.7564],
            [18.3966, 54.7437],
            [18.3945, 54.7283],
            [18.4072, 54.7221],
            [18.4191, 54.7205],
            [18.4481, 54.7023],
            [18.4688, 54.6948],
            [18.4662, 54.6835],
            [18.4652, 54.6551],
            [18.4731, 54.6511],
            [18.4679, 54.633],
            [18.4853, 54.6286],
            [18.5119, 54.6324],
            [18.5111, 54.6194],
            [18.5143, 54.608],
            [18.5324, 54.5881],
            [18.5413, 54.5832],
            [18.5592, 54.5516],
            [18.5481, 54.5367],
            [18.5132, 54.5433],
            [18.52, 54.5336],
            [18.5294, 54.5371],
            [18.5483, 54.5323],
            [18.5535, 54.5222],
            [18.5502, 54.514],
            [18.5638, 54.4973],
            [18.5694, 54.4854],
            [18.562, 54.4734],
            [18.5617, 54.4599],
            [18.5712, 54.4452],
            [18.5842, 54.4334],
            [18.5917, 54.4324],
            [18.6156, 54.4179],
            [18.6275, 54.413],
            [18.6547, 54.4089],
            [18.6613, 54.4126],
            [18.6966, 54.4017],
            [18.7119, 54.3817],
            [18.7346, 54.3739],
            [18.7722, 54.3706],
            [18.7819, 54.3713],
            [18.8288, 54.3559],
            [18.8593, 54.3499],
            [18.8979, 54.3458],
            [18.9193, 54.3459],
            [18.9373, 54.3519],
            [18.9421, 54.3648],
            [18.9533, 54.36],
            [18.9697, 54.3485],
            [18.9861, 54.3452],
            [19.0369, 54.3439],
            [19.0928, 54.3455],
            [19.1638, 54.3493],
            [19.2104, 54.3526],
            [19.2782, 54.3587],
            [19.3123, 54.3629],
            [19.3813, 54.3737],
            [19.4246, 54.3824],
            [19.4708, 54.3934],
            [19.5091, 54.4037],
            [19.5541, 54.4187],
            [19.6022, 54.4384],
            [19.6245, 54.4489],
            [19.6413, 54.4478],
            [19.598, 54.4249],
            [19.5524, 54.4097],
            [19.544, 54.4055],
            [19.5045, 54.3946],
            [19.4695, 54.3865],
            [19.4436, 54.3754],
            [19.4135, 54.366],
            [19.3912, 54.3633],
            [19.3637, 54.3541],
            [19.339, 54.3504],
            [19.304, 54.3512],
            [19.2712, 54.3476],
            [19.2475, 54.3432],
            [19.224, 54.3345],
            [19.2263, 54.3298],
            [19.2443, 54.3293],
            [19.2474, 54.318],
            [19.2583, 54.3115],
            [19.2606, 54.303],
            [19.2517, 54.3003],
            [19.2465, 54.2902],
            [19.2591, 54.2831],
            [19.2631, 54.2734],
          ],
        ],
      },
      properties: {
        name: 'Pomorskie',
        id: 'PL-22',
        NAME_ENG: 'Pomerania',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-22',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.6659, 51.0548],
            [18.7009, 51.0628],
            [18.7147, 51.0682],
            [18.7567, 51.0622],
            [18.7758, 51.0657],
            [18.8022, 51.0644],
            [18.8, 51.0713],
            [18.8182, 51.0689],
            [18.8612, 51.0736],
            [18.8757, 51.0678],
            [18.8824, 51.0601],
            [18.8949, 51.0663],
            [18.8989, 51.0776],
            [18.9182, 51.0984],
            [18.9405, 51.0934],
            [18.9389, 51.1001],
            [18.9505, 51.1019],
            [19.0117, 51.0766],
            [19.0468, 51.0589],
            [19.064, 51.0485],
            [19.0681, 51.0382],
            [19.0776, 51.0385],
            [19.0915, 51.0453],
            [19.1065, 51.0398],
            [19.1286, 51.0153],
            [19.1485, 51.0082],
            [19.1591, 51.018],
            [19.1667, 51.0175],
            [19.1804, 51.0006],
            [19.2054, 50.9957],
            [19.2167, 50.9898],
            [19.2278, 50.9884],
            [19.2507, 50.9943],
            [19.264, 51.0019],
            [19.27, 51.0167],
            [19.2601, 51.0334],
            [19.2937, 51.0462],
            [19.3131, 51.0483],
            [19.3238, 51.0466],
            [19.3314, 51.0398],
            [19.3153, 51.0237],
            [19.3269, 51.0147],
            [19.3445, 51.0126],
            [19.3624, 51.005],
            [19.3517, 50.9961],
            [19.3626, 50.9921],
            [19.3804, 50.9963],
            [19.3917, 51.0105],
            [19.4055, 51.011],
            [19.4155, 51.0041],
            [19.424, 50.983],
            [19.4327, 50.9797],
            [19.4486, 50.9682],
            [19.4544, 50.9578],
            [19.4711, 50.9448],
            [19.482, 50.9428],
            [19.491, 50.8957],
            [19.5, 50.8888],
            [19.5204, 50.8886],
            [19.5384, 50.8992],
            [19.5828, 50.9096],
            [19.6068, 50.9044],
            [19.6268, 50.8895],
            [19.6368, 50.8866],
            [19.6688, 50.8846],
            [19.6673, 50.8726],
            [19.6722, 50.8561],
            [19.6771, 50.8522],
            [19.6975, 50.8486],
            [19.7133, 50.849],
            [19.7338, 50.8535],
            [19.7519, 50.8635],
            [19.7623, 50.8585],
            [19.7958, 50.8522],
            [19.8118, 50.8467],
            [19.8384, 50.8251],
            [19.8363, 50.8074],
            [19.833, 50.8063],
            [19.8068, 50.8161],
            [19.7965, 50.8104],
            [19.7807, 50.7954],
            [19.7899, 50.7869],
            [19.7888, 50.7818],
            [19.7725, 50.7777],
            [19.7578, 50.7564],
            [19.7504, 50.754],
            [19.7112, 50.763],
            [19.7071, 50.7564],
            [19.7335, 50.7431],
            [19.7267, 50.7281],
            [19.7305, 50.7207],
            [19.7728, 50.7191],
            [19.7962, 50.7232],
            [19.8185, 50.7155],
            [19.8474, 50.7123],
            [19.8556, 50.7006],
            [19.881, 50.6965],
            [19.8812, 50.6916],
            [19.8662, 50.6845],
            [19.8532, 50.6828],
            [19.851, 50.6768],
            [19.8551, 50.6602],
            [19.8799, 50.6586],
            [19.8929, 50.6542],
            [19.9177, 50.6559],
            [19.9098, 50.6417],
            [19.913, 50.6219],
            [19.8976, 50.6165],
            [19.8641, 50.6218],
            [19.842, 50.596],
            [19.8434, 50.5911],
            [19.8234, 50.5812],
            [19.8173, 50.5731],
            [19.8047, 50.5658],
            [19.7895, 50.5683],
            [19.7882, 50.5545],
            [19.8425, 50.5548],
            [19.8566, 50.5441],
            [19.9012, 50.5395],
            [19.9126, 50.5125],
            [19.9348, 50.5024],
            [19.9504, 50.5048],
            [19.9525, 50.4947],
            [19.9637, 50.489],
            [19.9671, 50.4758],
            [19.935, 50.4784],
            [19.9273, 50.4752],
            [19.9001, 50.4736],
            [19.8919, 50.4666],
            [19.901, 50.4583],
            [19.8988, 50.4538],
            [19.884, 50.4504],
            [19.8544, 50.4475],
            [19.8523, 50.4393],
            [19.826, 50.4399],
            [19.8001, 50.448],
            [19.7837, 50.4421],
            [19.7602, 50.4456],
            [19.7379, 50.4412],
            [19.7164, 50.4454],
            [19.6983, 50.4364],
            [19.6789, 50.4319],
            [19.6606, 50.4146],
            [19.6381, 50.4177],
            [19.6207, 50.4094],
            [19.5954, 50.407],
            [19.5786, 50.4081],
            [19.5516, 50.4184],
            [19.5322, 50.4184],
            [19.5016, 50.4013],
            [19.4981, 50.3937],
            [19.5082, 50.3763],
            [19.5003, 50.3702],
            [19.4763, 50.3769],
            [19.4652, 50.3763],
            [19.4598, 50.3752],
            [19.4576, 50.3647],
            [19.462, 50.3615],
            [19.496, 50.358],
            [19.501, 50.3499],
            [19.4984, 50.3288],
            [19.4931, 50.3242],
            [19.4646, 50.3209],
            [19.4347, 50.327],
            [19.4249, 50.3244],
            [19.414, 50.3146],
            [19.3983, 50.3123],
            [19.3838, 50.3184],
            [19.3626, 50.318],
            [19.3433, 50.3],
            [19.3332, 50.2953],
            [19.3127, 50.292],
            [19.3254, 50.2821],
            [19.3303, 50.2601],
            [19.3616, 50.2444],
            [19.3796, 50.2381],
            [19.4199, 50.2308],
            [19.4319, 50.2275],
            [19.4305, 50.2196],
            [19.4207, 50.2051],
            [19.3924, 50.203],
            [19.3804, 50.191],
            [19.3482, 50.1872],
            [19.3469, 50.1776],
            [19.3651, 50.1738],
            [19.3673, 50.1681],
            [19.349, 50.1648],
            [19.3447, 50.1474],
            [19.3253, 50.1408],
            [19.3082, 50.1397],
            [19.2774, 50.1414],
            [19.2733, 50.1346],
            [19.2776, 50.1237],
            [19.2741, 50.1171],
            [19.26, 50.1119],
            [19.2548, 50.1296],
            [19.25, 50.1318],
            [19.2236, 50.0946],
            [19.225, 50.09],
            [19.2118, 50.0761],
            [19.2261, 50.0723],
            [19.2228, 50.0643],
            [19.2063, 50.0622],
            [19.1955, 50.0653],
            [19.178, 50.0572],
            [19.169, 50.0603],
            [19.1602, 50.0512],
            [19.1489, 50.0447],
            [19.1384, 50.0316],
            [19.1379, 50.023],
            [19.1243, 50.0149],
            [19.1218, 50.0055],
            [19.111, 49.9987],
            [19.1056, 49.983],
            [19.1137, 49.9627],
            [19.0948, 49.9597],
            [19.1051, 49.9428],
            [19.119, 49.9396],
            [19.1416, 49.9385],
            [19.1596, 49.9401],
            [19.1616, 49.9464],
            [19.1761, 49.9516],
            [19.1978, 49.955],
            [19.2147, 49.9448],
            [19.2146, 49.9118],
            [19.2126, 49.9031],
            [19.2037, 49.8936],
            [19.1844, 49.8903],
            [19.1759, 49.885],
            [19.1693, 49.8744],
            [19.1801, 49.8616],
            [19.1971, 49.8674],
            [19.2425, 49.8722],
            [19.2871, 49.8711],
            [19.2995, 49.8688],
            [19.311, 49.8615],
            [19.3129, 49.8497],
            [19.311, 49.8378],
            [19.3036, 49.8284],
            [19.3018, 49.8178],
            [19.332, 49.787],
            [19.3505, 49.7804],
            [19.3952, 49.7822],
            [19.4192, 49.7688],
            [19.438, 49.7771],
            [19.4511, 49.7746],
            [19.4591, 49.7626],
            [19.462, 49.7404],
            [19.4173, 49.7208],
            [19.3894, 49.7008],
            [19.3865, 49.6943],
            [19.3944, 49.6874],
            [19.4068, 49.6849],
            [19.4566, 49.6852],
            [19.4693, 49.6816],
            [19.4842, 49.6685],
            [19.4787, 49.6565],
            [19.4839, 49.6342],
            [19.4828, 49.6277],
            [19.4705, 49.6122],
            [19.4495, 49.6134],
            [19.4435, 49.6075],
            [19.4459, 49.5999],
            [19.437, 49.5963],
            [19.421, 49.5954],
            [19.4108, 49.591],
            [19.4096, 49.5816],
            [19.396, 49.5725],
            [19.3732, 49.5679],
            [19.3749, 49.5587],
            [19.3663, 49.5533],
            [19.3666, 49.5391],
            [19.3602, 49.5357],
            [19.3358, 49.5339],
            [19.3205, 49.53],
            [19.3114, 49.5368],
            [19.3015, 49.5337],
            [19.2819, 49.5348],
            [19.2631, 49.528],
            [19.2359, 49.5109],
            [19.237, 49.4963],
            [19.234, 49.4871],
            [19.2216, 49.4744],
            [19.2302, 49.4644],
            [19.2208, 49.4494],
            [19.2104, 49.4519],
            [19.1995, 49.4474],
            [19.1889, 49.4361],
            [19.1948, 49.4285],
            [19.1918, 49.4107],
            [19.1789, 49.4141],
            [19.1555, 49.4051],
            [19.1395, 49.4113],
            [19.1276, 49.4034],
            [19.1109, 49.4041],
            [19.0919, 49.4161],
            [19.0734, 49.4171],
            [19.0717, 49.4128],
            [19.0535, 49.4144],
            [19.0478, 49.4025],
            [19.0362, 49.3958],
            [19.0113, 49.3952],
            [18.9994, 49.3988],
            [18.9807, 49.3954],
            [18.9738, 49.4009],
            [18.9708, 49.414],
            [18.9856, 49.4219],
            [18.9893, 49.4316],
            [18.9791, 49.4413],
            [18.9746, 49.4521],
            [18.9615, 49.4549],
            [18.9699, 49.4774],
            [18.9634, 49.4836],
            [18.9729, 49.5036],
            [18.9341, 49.5196],
            [18.8988, 49.5144],
            [18.8744, 49.5161],
            [18.8682, 49.5199],
            [18.8558, 49.5175],
            [18.8371, 49.5234],
            [18.8429, 49.5333],
            [18.857, 49.5376],
            [18.859, 49.5499],
            [18.8494, 49.5583],
            [18.8383, 49.561],
            [18.831, 49.5828],
            [18.8389, 49.5951],
            [18.829, 49.5948],
            [18.8227, 49.6037],
            [18.824, 49.6142],
            [18.8064, 49.6513],
            [18.8067, 49.6659],
            [18.8106, 49.6717],
            [18.8047, 49.6785],
            [18.7953, 49.6788],
            [18.7818, 49.6851],
            [18.7639, 49.6841],
            [18.7505, 49.6766],
            [18.7372, 49.6817],
            [18.7182, 49.683],
            [18.7154, 49.6952],
            [18.7047, 49.7044],
            [18.6897, 49.7062],
            [18.6685, 49.7031],
            [18.6669, 49.7094],
            [18.6425, 49.7121],
            [18.6254, 49.7224],
            [18.6289, 49.747],
            [18.6168, 49.7527],
            [18.6103, 49.7706],
            [18.597, 49.7844],
            [18.5992, 49.7896],
            [18.5927, 49.8015],
            [18.5854, 49.805],
            [18.5823, 49.8171],
            [18.5705, 49.8276],
            [18.5695, 49.8334],
            [18.5892, 49.8462],
            [18.5834, 49.8513],
            [18.6027, 49.8563],
            [18.6014, 49.8651],
            [18.5795, 49.869],
            [18.5695, 49.8732],
            [18.5657, 49.88],
            [18.5793, 49.9047],
            [18.5715, 49.9213],
            [18.5594, 49.9174],
            [18.5451, 49.9255],
            [18.5395, 49.912],
            [18.5469, 49.9083],
            [18.533, 49.901],
            [18.4891, 49.9038],
            [18.4822, 49.9099],
            [18.4669, 49.9156],
            [18.4619, 49.9221],
            [18.4449, 49.9224],
            [18.4329, 49.928],
            [18.43, 49.9378],
            [18.3962, 49.9341],
            [18.3691, 49.9362],
            [18.3568, 49.9434],
            [18.3398, 49.9449],
            [18.3353, 49.9395],
            [18.3433, 49.9344],
            [18.3419, 49.9262],
            [18.3308, 49.9253],
            [18.3269, 49.9196],
            [18.316, 49.9189],
            [18.2881, 49.9287],
            [18.2797, 49.9408],
            [18.2817, 49.9496],
            [18.2773, 49.9643],
            [18.2358, 49.9709],
            [18.2217, 49.9675],
            [18.2141, 49.9725],
            [18.2111, 49.9919],
            [18.2066, 49.9978],
            [18.1931, 49.9944],
            [18.1893, 49.9987],
            [18.1679, 49.9982],
            [18.1718, 49.9897],
            [18.154, 49.9821],
            [18.147, 49.9911],
            [18.1304, 49.9976],
            [18.1208, 49.9943],
            [18.1066, 49.9996],
            [18.0936, 50.0142],
            [18.102, 50.0219],
            [18.0851, 50.0338],
            [18.0892, 50.0434],
            [18.0674, 50.0473],
            [18.061, 50.059],
            [18.0498, 50.058],
            [18.0342, 50.0653],
            [18.041, 50.0694],
            [18.056, 50.086],
            [18.0668, 50.1076],
            [18.0617, 50.115],
            [18.0677, 50.126],
            [18.0505, 50.1391],
            [18.0643, 50.154],
            [18.0568, 50.1611],
            [18.0629, 50.1702],
            [18.1018, 50.1762],
            [18.1109, 50.1674],
            [18.1227, 50.1745],
            [18.1249, 50.1807],
            [18.1348, 50.184],
            [18.1406, 50.1782],
            [18.1628, 50.1887],
            [18.1845, 50.1805],
            [18.1858, 50.1868],
            [18.2083, 50.1903],
            [18.249, 50.1921],
            [18.2348, 50.2007],
            [18.2367, 50.2147],
            [18.2523, 50.2224],
            [18.272, 50.2197],
            [18.2768, 50.2247],
            [18.31, 50.2313],
            [18.3142, 50.2395],
            [18.3241, 50.2395],
            [18.3471, 50.2467],
            [18.3604, 50.2481],
            [18.3533, 50.258],
            [18.39, 50.2535],
            [18.4073, 50.2538],
            [18.4274, 50.2477],
            [18.4211, 50.2601],
            [18.4274, 50.2663],
            [18.4225, 50.2721],
            [18.4001, 50.2764],
            [18.406, 50.2882],
            [18.4042, 50.2957],
            [18.3937, 50.3049],
            [18.3927, 50.3135],
            [18.4087, 50.3175],
            [18.4008, 50.3231],
            [18.3949, 50.3381],
            [18.3715, 50.3471],
            [18.3583, 50.3563],
            [18.3724, 50.3616],
            [18.3756, 50.3766],
            [18.3946, 50.3885],
            [18.3854, 50.3979],
            [18.38, 50.4117],
            [18.3647, 50.4186],
            [18.3661, 50.4271],
            [18.3758, 50.4269],
            [18.3861, 50.4317],
            [18.3886, 50.4398],
            [18.3838, 50.4558],
            [18.3801, 50.4841],
            [18.4131, 50.4741],
            [18.4406, 50.4812],
            [18.455, 50.4754],
            [18.4522, 50.4622],
            [18.4822, 50.4594],
            [18.4849, 50.4697],
            [18.4742, 50.4729],
            [18.4668, 50.4881],
            [18.4672, 50.4994],
            [18.4478, 50.4991],
            [18.4463, 50.5092],
            [18.4381, 50.5267],
            [18.4306, 50.531],
            [18.437, 50.5457],
            [18.473, 50.5516],
            [18.497, 50.55],
            [18.5313, 50.5431],
            [18.5583, 50.5407],
            [18.5734, 50.5476],
            [18.5889, 50.547],
            [18.6072, 50.5528],
            [18.6152, 50.569],
            [18.6015, 50.5678],
            [18.5915, 50.5767],
            [18.6016, 50.6045],
            [18.5915, 50.606],
            [18.5883, 50.6128],
            [18.5343, 50.6231],
            [18.5332, 50.6322],
            [18.5234, 50.6401],
            [18.5193, 50.6589],
            [18.51, 50.6649],
            [18.5054, 50.6733],
            [18.495, 50.6807],
            [18.5056, 50.694],
            [18.492, 50.6973],
            [18.4912, 50.7038],
            [18.507, 50.7142],
            [18.5122, 50.7232],
            [18.5217, 50.73],
            [18.5517, 50.7322],
            [18.5634, 50.7417],
            [18.58, 50.7431],
            [18.5814, 50.7504],
            [18.5653, 50.7572],
            [18.5446, 50.7739],
            [18.5269, 50.776],
            [18.5288, 50.7918],
            [18.5138, 50.7935],
            [18.5133, 50.8008],
            [18.5498, 50.8038],
            [18.5604, 50.8063],
            [18.5664, 50.8286],
            [18.5749, 50.8401],
            [18.6125, 50.8532],
            [18.6261, 50.8651],
            [18.6356, 50.8965],
            [18.6463, 50.9109],
            [18.646, 50.9275],
            [18.6228, 50.9485],
            [18.619, 50.9556],
            [18.6212, 50.9641],
            [18.6572, 50.9777],
            [18.6642, 50.9938],
            [18.6561, 51.0041],
            [18.6599, 51.0104],
            [18.6929, 51.0158],
            [18.6923, 51.0297],
            [18.6659, 51.0548],
          ],
        ],
      },
      properties: {
        name: 'Śląskie',
        id: 'PL-24',
        NAME_ENG: 'Silesia',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-24',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.4288, 51.3513],
            [20.4491, 51.3502],
            [20.4905, 51.3434],
            [20.5135, 51.344],
            [20.5224, 51.3387],
            [20.5213, 51.3273],
            [20.4963, 51.318],
            [20.4998, 51.3107],
            [20.5153, 51.309],
            [20.5227, 51.2976],
            [20.5366, 51.2968],
            [20.542, 51.2853],
            [20.5496, 51.2479],
            [20.5542, 51.2357],
            [20.5671, 51.2363],
            [20.5777, 51.2438],
            [20.5988, 51.2435],
            [20.6065, 51.2458],
            [20.6145, 51.2337],
            [20.6478, 51.2174],
            [20.6769, 51.2091],
            [20.6898, 51.1977],
            [20.699, 51.1955],
            [20.7016, 51.1826],
            [20.686, 51.1749],
            [20.6841, 51.1658],
            [20.6873, 51.1499],
            [20.697, 51.1493],
            [20.7192, 51.1723],
            [20.7389, 51.1652],
            [20.7702, 51.1565],
            [20.7664, 51.1503],
            [20.7825, 51.1448],
            [20.7879, 51.1522],
            [20.8245, 51.1428],
            [20.8286, 51.1327],
            [20.8584, 51.1264],
            [20.8633, 51.1363],
            [20.8924, 51.1346],
            [20.8928, 51.143],
            [20.8788, 51.153],
            [20.8962, 51.1704],
            [20.9285, 51.1591],
            [20.9403, 51.1523],
            [20.9526, 51.1545],
            [20.9686, 51.1523],
            [20.9846, 51.1665],
            [20.9974, 51.1607],
            [20.9924, 51.1554],
            [20.9986, 51.1452],
            [21.0248, 51.1516],
            [21.0207, 51.1585],
            [21.0589, 51.1577],
            [21.0704, 51.1752],
            [21.0745, 51.1982],
            [21.0702, 51.2087],
            [21.0832, 51.2145],
            [21.0912, 51.213],
            [21.1097, 51.2005],
            [21.1197, 51.1871],
            [21.1181, 51.1822],
            [21.0937, 51.1595],
            [21.091, 51.1545],
            [21.1288, 51.1411],
            [21.1333, 51.1293],
            [21.1536, 51.1241],
            [21.1588, 51.1073],
            [21.1519, 51.1034],
            [21.1483, 51.0921],
            [21.1559, 51.0864],
            [21.153, 51.081],
            [21.2198, 51.0814],
            [21.2283, 51.0828],
            [21.3096, 51.0832],
            [21.3521, 51.0856],
            [21.3559, 51.0819],
            [21.352, 51.0665],
            [21.3659, 51.0651],
            [21.3727, 51.0586],
            [21.3824, 51.0582],
            [21.3833, 51.041],
            [21.4116, 51.0327],
            [21.4324, 51.0295],
            [21.4641, 51.0129],
            [21.4951, 51.0219],
            [21.5025, 51.0311],
            [21.4876, 51.0347],
            [21.5149, 51.057],
            [21.5416, 51.0564],
            [21.5448, 51.048],
            [21.5759, 51.0588],
            [21.5901, 51.0571],
            [21.6142, 51.0643],
            [21.6312, 51.0642],
            [21.6253, 51.0711],
            [21.6394, 51.0763],
            [21.6492, 51.0698],
            [21.6762, 51.077],
            [21.6928, 51.0634],
            [21.6968, 51.0521],
            [21.7089, 51.0406],
            [21.7171, 51.0412],
            [21.7266, 51.0484],
            [21.7383, 51.0484],
            [21.7421, 51.0417],
            [21.758, 51.0382],
            [21.7713, 51.0487],
            [21.7848, 51.0669],
            [21.8074, 51.0675],
            [21.8248, 51.0573],
            [21.8244, 51.0477],
            [21.8181, 51.0387],
            [21.8064, 51.0315],
            [21.8003, 51.0205],
            [21.8171, 51.0101],
            [21.8225, 50.9924],
            [21.8185, 50.9806],
            [21.8292, 50.9775],
            [21.8277, 50.9376],
            [21.8348, 50.9232],
            [21.8425, 50.8882],
            [21.8394, 50.8773],
            [21.8474, 50.8675],
            [21.8428, 50.8406],
            [21.8472, 50.8343],
            [21.8668, 50.8229],
            [21.8688, 50.8102],
            [21.8627, 50.7996],
            [21.8575, 50.7798],
            [21.8432, 50.7642],
            [21.8402, 50.7525],
            [21.8439, 50.7373],
            [21.8281, 50.7163],
            [21.826, 50.6983],
            [21.7976, 50.6661],
            [21.7885, 50.6412],
            [21.7708, 50.642],
            [21.7573, 50.652],
            [21.7507, 50.6398],
            [21.7353, 50.6418],
            [21.7174, 50.6301],
            [21.7097, 50.6287],
            [21.6739, 50.6084],
            [21.6691, 50.603],
            [21.6594, 50.5755],
            [21.6492, 50.5632],
            [21.6251, 50.5454],
            [21.6038, 50.5199],
            [21.5856, 50.5165],
            [21.5601, 50.5211],
            [21.5261, 50.5122],
            [21.4959, 50.4999],
            [21.4794, 50.4951],
            [21.45, 50.4922],
            [21.4441, 50.4872],
            [21.4527, 50.467],
            [21.4473, 50.4575],
            [21.3995, 50.4388],
            [21.3682, 50.4407],
            [21.3442, 50.4357],
            [21.3272, 50.4257],
            [21.3142, 50.423],
            [21.3043, 50.416],
            [21.28, 50.4057],
            [21.2824, 50.3961],
            [21.277, 50.3906],
            [21.2644, 50.3919],
            [21.2542, 50.3881],
            [21.2472, 50.3767],
            [21.2359, 50.3761],
            [21.2268, 50.3705],
            [21.2202, 50.358],
            [21.2094, 50.3553],
            [21.1948, 50.3542],
            [21.1879, 50.3495],
            [21.1823, 50.332],
            [21.169, 50.3313],
            [21.1663, 50.3431],
            [21.1562, 50.3509],
            [21.1383, 50.3553],
            [21.1212, 50.3504],
            [21.1169, 50.3418],
            [21.1082, 50.3376],
            [21.095, 50.3399],
            [21.0851, 50.338],
            [21.0715, 50.3225],
            [21.0485, 50.316],
            [21.0268, 50.3198],
            [21.0108, 50.3138],
            [20.9942, 50.316],
            [20.974, 50.3145],
            [20.9543, 50.3167],
            [20.9445, 50.3125],
            [20.9389, 50.3009],
            [20.9224, 50.2997],
            [20.9012, 50.3063],
            [20.8942, 50.3062],
            [20.8781, 50.2954],
            [20.8544, 50.2958],
            [20.8467, 50.2764],
            [20.8281, 50.2743],
            [20.8083, 50.2889],
            [20.7901, 50.2892],
            [20.78, 50.2844],
            [20.7681, 50.2678],
            [20.7526, 50.2642],
            [20.7284, 50.2495],
            [20.7275, 50.2304],
            [20.7049, 50.2253],
            [20.7001, 50.2174],
            [20.6806, 50.2173],
            [20.6701, 50.2122],
            [20.6418, 50.2188],
            [20.6292, 50.2114],
            [20.6077, 50.2084],
            [20.5889, 50.1981],
            [20.5769, 50.1986],
            [20.5698, 50.206],
            [20.5573, 50.2071],
            [20.5363, 50.1948],
            [20.5006, 50.1899],
            [20.4916, 50.1916],
            [20.4703, 50.2011],
            [20.4686, 50.1959],
            [20.4516, 50.1928],
            [20.4357, 50.1958],
            [20.4258, 50.1917],
            [20.4126, 50.1932],
            [20.4131, 50.2093],
            [20.3963, 50.2101],
            [20.3863, 50.2074],
            [20.378, 50.2108],
            [20.3741, 50.2219],
            [20.3774, 50.2296],
            [20.3929, 50.2353],
            [20.3979, 50.2403],
            [20.3676, 50.2486],
            [20.359, 50.2556],
            [20.3614, 50.2697],
            [20.3467, 50.2714],
            [20.3458, 50.279],
            [20.3693, 50.2883],
            [20.3687, 50.2945],
            [20.3584, 50.2973],
            [20.354, 50.3098],
            [20.3358, 50.3237],
            [20.327, 50.327],
            [20.3041, 50.3245],
            [20.2998, 50.3327],
            [20.3101, 50.3373],
            [20.3085, 50.3433],
            [20.2965, 50.3501],
            [20.2998, 50.3539],
            [20.3268, 50.3526],
            [20.3407, 50.3624],
            [20.339, 50.3708],
            [20.3167, 50.3934],
            [20.3014, 50.4172],
            [20.2736, 50.4338],
            [20.272, 50.443],
            [20.2744, 50.4659],
            [20.2578, 50.47],
            [20.2464, 50.4771],
            [20.2227, 50.4801],
            [20.2036, 50.4924],
            [20.1845, 50.4918],
            [20.163, 50.4853],
            [20.148, 50.491],
            [20.1188, 50.4858],
            [20.0973, 50.4899],
            [20.0834, 50.5063],
            [20.0646, 50.5076],
            [20.042, 50.5005],
            [20.0256, 50.5071],
            [19.9883, 50.5193],
            [19.9769, 50.5177],
            [19.9641, 50.5093],
            [19.9504, 50.5048],
            [19.9348, 50.5024],
            [19.9126, 50.5125],
            [19.9012, 50.5395],
            [19.8566, 50.5441],
            [19.8425, 50.5548],
            [19.7882, 50.5545],
            [19.7895, 50.5683],
            [19.8047, 50.5658],
            [19.8173, 50.5731],
            [19.8234, 50.5812],
            [19.8434, 50.5911],
            [19.842, 50.596],
            [19.8641, 50.6218],
            [19.8976, 50.6165],
            [19.913, 50.6219],
            [19.9098, 50.6417],
            [19.9177, 50.6559],
            [19.8929, 50.6542],
            [19.8799, 50.6586],
            [19.8551, 50.6602],
            [19.851, 50.6768],
            [19.8532, 50.6828],
            [19.8662, 50.6845],
            [19.8812, 50.6916],
            [19.881, 50.6965],
            [19.8556, 50.7006],
            [19.8474, 50.7123],
            [19.8185, 50.7155],
            [19.7962, 50.7232],
            [19.7728, 50.7191],
            [19.7305, 50.7207],
            [19.7267, 50.7281],
            [19.7335, 50.7431],
            [19.7071, 50.7564],
            [19.7112, 50.763],
            [19.7504, 50.754],
            [19.7578, 50.7564],
            [19.7725, 50.7777],
            [19.7888, 50.7818],
            [19.7899, 50.7869],
            [19.7807, 50.7954],
            [19.7965, 50.8104],
            [19.8068, 50.8161],
            [19.833, 50.8063],
            [19.8363, 50.8074],
            [19.8384, 50.8251],
            [19.8118, 50.8467],
            [19.7958, 50.8522],
            [19.7623, 50.8585],
            [19.7519, 50.8635],
            [19.7736, 50.8753],
            [19.7907, 50.9008],
            [19.8077, 50.9021],
            [19.8191, 50.9071],
            [19.8229, 50.9156],
            [19.8386, 50.9171],
            [19.8468, 50.9316],
            [19.8476, 50.9413],
            [19.8419, 50.9496],
            [19.8479, 50.9579],
            [19.8329, 50.9798],
            [19.8332, 50.9862],
            [19.8442, 50.9911],
            [19.8613, 51.0098],
            [19.8675, 51.049],
            [19.877, 51.0574],
            [19.8916, 51.0553],
            [19.9104, 51.0372],
            [19.9345, 51.0323],
            [19.944, 51.0211],
            [19.9552, 51.0194],
            [19.9611, 51.0139],
            [19.9667, 50.9958],
            [20.0236, 50.9694],
            [20.0356, 50.9734],
            [20.04, 50.9907],
            [20.0323, 50.9964],
            [20.048, 51.0121],
            [20.0518, 51.0368],
            [20.0479, 51.0552],
            [20.0526, 51.0633],
            [20.0648, 51.0687],
            [20.0451, 51.0759],
            [19.9983, 51.0777],
            [19.9901, 51.0806],
            [19.9885, 51.089],
            [19.9988, 51.113],
            [19.9989, 51.1207],
            [19.9867, 51.1378],
            [19.989, 51.1485],
            [20.0135, 51.1638],
            [20.0266, 51.1686],
            [20.0256, 51.177],
            [20.0023, 51.1911],
            [20.0066, 51.202],
            [20.0254, 51.209],
            [20.0535, 51.2148],
            [20.0654, 51.2046],
            [20.0871, 51.2056],
            [20.1269, 51.204],
            [20.1359, 51.191],
            [20.1577, 51.1977],
            [20.1757, 51.1962],
            [20.1798, 51.2073],
            [20.1915, 51.2047],
            [20.1948, 51.1965],
            [20.2106, 51.2013],
            [20.2185, 51.1994],
            [20.2523, 51.2066],
            [20.264, 51.2139],
            [20.2644, 51.2195],
            [20.252, 51.2208],
            [20.2558, 51.236],
            [20.2438, 51.2437],
            [20.2431, 51.2565],
            [20.2595, 51.2586],
            [20.2635, 51.2516],
            [20.2766, 51.2433],
            [20.3097, 51.2421],
            [20.3302, 51.254],
            [20.3576, 51.2492],
            [20.3597, 51.2405],
            [20.3706, 51.2367],
            [20.3796, 51.2462],
            [20.3747, 51.2672],
            [20.393, 51.2695],
            [20.381, 51.2832],
            [20.3689, 51.303],
            [20.3719, 51.3104],
            [20.3859, 51.3183],
            [20.4148, 51.3204],
            [20.4317, 51.3353],
            [20.4288, 51.3513],
          ],
        ],
      },
      properties: {
        name: 'Świętokrzyskie',
        id: 'PL-26',
        NAME_ENG: 'Holy Cross',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-26',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.7893, 54.3631],
            [22.8035, 54.3506],
            [22.808, 54.3414],
            [22.7979, 54.3329],
            [22.7815, 54.3273],
            [22.7963, 54.3201],
            [22.796, 54.3083],
            [22.7863, 54.2963],
            [22.7706, 54.289],
            [22.7446, 54.2865],
            [22.7016, 54.2743],
            [22.6852, 54.2748],
            [22.652, 54.2831],
            [22.6415, 54.2819],
            [22.6336, 54.268],
            [22.6174, 54.2606],
            [22.584, 54.2608],
            [22.573, 54.2589],
            [22.5592, 54.2501],
            [22.5232, 54.2452],
            [22.5188, 54.2405],
            [22.5185, 54.2257],
            [22.5025, 54.2133],
            [22.4765, 54.2016],
            [22.4773, 54.1949],
            [22.5073, 54.1922],
            [22.5256, 54.1673],
            [22.5407, 54.1629],
            [22.551, 54.146],
            [22.572, 54.1433],
            [22.593, 54.1516],
            [22.5978, 54.1494],
            [22.6348, 54.1097],
            [22.6395, 54.0967],
            [22.6178, 54.0839],
            [22.6141, 54.0753],
            [22.6163, 54.0591],
            [22.6535, 54.0471],
            [22.672, 54.0385],
            [22.6887, 54.0159],
            [22.7002, 54.0077],
            [22.709, 53.9907],
            [22.7272, 53.9767],
            [22.7426, 53.9603],
            [22.7804, 53.9331],
            [22.7889, 53.9188],
            [22.7891, 53.9023],
            [22.7977, 53.8775],
            [22.7771, 53.8628],
            [22.7633, 53.8487],
            [22.7391, 53.8378],
            [22.732, 53.8278],
            [22.7362, 53.8042],
            [22.7321, 53.7955],
            [22.7194, 53.7803],
            [22.698, 53.7613],
            [22.6728, 53.7562],
            [22.6415, 53.7463],
            [22.6399, 53.7546],
            [22.6257, 53.7506],
            [22.6282, 53.7411],
            [22.6177, 53.7334],
            [22.6099, 53.7193],
            [22.5966, 53.7189],
            [22.5861, 53.726],
            [22.5853, 53.7343],
            [22.5646, 53.7203],
            [22.5153, 53.6991],
            [22.5048, 53.6963],
            [22.4923, 53.6996],
            [22.4699, 53.6989],
            [22.4668, 53.6899],
            [22.4714, 53.6784],
            [22.4711, 53.6641],
            [22.449, 53.6632],
            [22.4403, 53.666],
            [22.4409, 53.6732],
            [22.4228, 53.6817],
            [22.4007, 53.6675],
            [22.3653, 53.6558],
            [22.3382, 53.6375],
            [22.2994, 53.6249],
            [22.2819, 53.6214],
            [22.2702, 53.609],
            [22.2513, 53.6031],
            [22.2335, 53.5933],
            [22.227, 53.5852],
            [22.2078, 53.5771],
            [22.1948, 53.5659],
            [22.1573, 53.5537],
            [22.1164, 53.5377],
            [22.0562, 53.5229],
            [22.0421, 53.5155],
            [22.0345, 53.5028],
            [22.0219, 53.4978],
            [22.0086, 53.5136],
            [21.9913, 53.5215],
            [21.9539, 53.5103],
            [21.9329, 53.5103],
            [21.9018, 53.4928],
            [21.9019, 53.4841],
            [21.8913, 53.4678],
            [21.8818, 53.4622],
            [21.8625, 53.4578],
            [21.8437, 53.4602],
            [21.8261, 53.4655],
            [21.8042, 53.475],
            [21.7783, 53.4806],
            [21.7548, 53.4827],
            [21.7304, 53.4888],
            [21.6821, 53.4917],
            [21.6481, 53.4801],
            [21.5986, 53.4804],
            [21.567, 53.4806],
            [21.5526, 53.4768],
            [21.5354, 53.4772],
            [21.492, 53.4733],
            [21.4512, 53.4653],
            [21.4356, 53.4558],
            [21.4434, 53.4409],
            [21.4288, 53.4401],
            [21.4112, 53.4314],
            [21.4029, 53.4387],
            [21.3858, 53.437],
            [21.3623, 53.4213],
            [21.3563, 53.421],
            [21.326, 53.43],
            [21.3048, 53.4236],
            [21.3009, 53.4339],
            [21.2749, 53.4314],
            [21.2455, 53.4225],
            [21.1954, 53.4],
            [21.1738, 53.3923],
            [21.142, 53.3773],
            [21.1357, 53.366],
            [21.1164, 53.355],
            [21.0832, 53.3472],
            [21.0727, 53.3402],
            [21.0508, 53.335],
            [21.0305, 53.3516],
            [20.9978, 53.3633],
            [20.9685, 53.3524],
            [20.9381, 53.3601],
            [20.9295, 53.3527],
            [20.9189, 53.3536],
            [20.9088, 53.344],
            [20.9212, 53.336],
            [20.9163, 53.327],
            [20.8974, 53.3136],
            [20.873, 53.322],
            [20.8733, 53.3125],
            [20.8657, 53.3041],
            [20.8568, 53.3018],
            [20.8478, 53.2926],
            [20.8189, 53.3057],
            [20.7957, 53.3095],
            [20.7471, 53.3148],
            [20.7416, 53.3053],
            [20.7147, 53.3105],
            [20.7137, 53.2999],
            [20.6932, 53.3017],
            [20.6905, 53.2875],
            [20.6759, 53.2693],
            [20.6576, 53.2508],
            [20.6146, 53.2514],
            [20.6083, 53.2387],
            [20.5826, 53.2331],
            [20.5752, 53.2403],
            [20.5623, 53.2266],
            [20.5541, 53.2245],
            [20.5322, 53.2318],
            [20.5246, 53.2223],
            [20.5134, 53.2261],
            [20.5117, 53.2329],
            [20.4746, 53.2341],
            [20.4567, 53.2453],
            [20.4242, 53.22],
            [20.4117, 53.2138],
            [20.4061, 53.2208],
            [20.3774, 53.2085],
            [20.3791, 53.1865],
            [20.3939, 53.1806],
            [20.3882, 53.1623],
            [20.368, 53.1651],
            [20.3708, 53.1556],
            [20.3658, 53.1503],
            [20.3372, 53.1449],
            [20.3246, 53.1386],
            [20.3061, 53.1364],
            [20.2943, 53.139],
            [20.2535, 53.1403],
            [20.2232, 53.1501],
            [20.1776, 53.1545],
            [20.1637, 53.1532],
            [20.1476, 53.1438],
            [20.1321, 53.149],
            [20.1164, 53.1638],
            [20.102, 53.166],
            [20.0789, 53.1549],
            [20.0508, 53.1527],
            [20.0359, 53.1477],
            [20.0104, 53.149],
            [19.9835, 53.1467],
            [19.9577, 53.1605],
            [19.9191, 53.1707],
            [19.9138, 53.18],
            [19.9234, 53.2002],
            [19.9241, 53.2079],
            [19.9195, 53.2133],
            [19.9006, 53.2152],
            [19.8873, 53.2086],
            [19.8664, 53.2019],
            [19.8417, 53.1885],
            [19.8352, 53.1814],
            [19.8499, 53.1656],
            [19.8541, 53.1518],
            [19.8466, 53.1443],
            [19.8284, 53.1434],
            [19.7769, 53.1509],
            [19.762, 53.152],
            [19.7527, 53.169],
            [19.7511, 53.1841],
            [19.7433, 53.1896],
            [19.741, 53.2],
            [19.7512, 53.2113],
            [19.7348, 53.2294],
            [19.717, 53.2301],
            [19.6995, 53.2356],
            [19.6898, 53.2341],
            [19.6895, 53.2588],
            [19.7254, 53.2842],
            [19.7127, 53.289],
            [19.7186, 53.302],
            [19.7153, 53.3115],
            [19.6971, 53.3171],
            [19.6897, 53.3442],
            [19.6728, 53.3501],
            [19.6379, 53.352],
            [19.6004, 53.3478],
            [19.5784, 53.3394],
            [19.5642, 53.3376],
            [19.5365, 53.339],
            [19.5364, 53.3524],
            [19.5325, 53.3631],
            [19.5131, 53.3753],
            [19.4877, 53.3724],
            [19.4734, 53.3799],
            [19.4605, 53.3706],
            [19.4467, 53.374],
            [19.4514, 53.3856],
            [19.4272, 53.389],
            [19.4018, 53.3985],
            [19.3996, 53.4131],
            [19.3884, 53.4175],
            [19.365, 53.4154],
            [19.3526, 53.4094],
            [19.3436, 53.4095],
            [19.3316, 53.4156],
            [19.318, 53.4137],
            [19.3115, 53.3984],
            [19.3044, 53.3932],
            [19.2902, 53.4017],
            [19.2733, 53.4066],
            [19.2452, 53.4273],
            [19.268, 53.4332],
            [19.2665, 53.442],
            [19.2547, 53.4535],
            [19.2519, 53.4666],
            [19.2398, 53.4645],
            [19.2425, 53.4781],
            [19.2354, 53.4919],
            [19.2046, 53.5129],
            [19.2043, 53.5223],
            [19.2154, 53.5308],
            [19.209, 53.5477],
            [19.1903, 53.5475],
            [19.2, 53.5695],
            [19.1816, 53.579],
            [19.1621, 53.5837],
            [19.1441, 53.5845],
            [19.1362, 53.6054],
            [19.1368, 53.613],
            [19.1482, 53.6237],
            [19.1847, 53.646],
            [19.1919, 53.6482],
            [19.2184, 53.645],
            [19.2234, 53.6513],
            [19.2229, 53.6741],
            [19.2286, 53.6775],
            [19.2248, 53.6978],
            [19.2309, 53.7042],
            [19.271, 53.732],
            [19.2941, 53.7412],
            [19.3056, 53.7515],
            [19.306, 53.7833],
            [19.3193, 53.7933],
            [19.3151, 53.8069],
            [19.3384, 53.8226],
            [19.3543, 53.8226],
            [19.3966, 53.8156],
            [19.447, 53.8084],
            [19.4586, 53.8049],
            [19.4603, 53.7899],
            [19.4743, 53.7851],
            [19.4995, 53.8059],
            [19.4789, 53.8163],
            [19.5173, 53.8457],
            [19.5134, 53.8543],
            [19.5258, 53.8631],
            [19.534, 53.8793],
            [19.5563, 53.8965],
            [19.5606, 53.9179],
            [19.5565, 53.9249],
            [19.5804, 53.9271],
            [19.5831, 53.9376],
            [19.5771, 53.9493],
            [19.5838, 53.9539],
            [19.5982, 53.9504],
            [19.5939, 53.9611],
            [19.5685, 53.9568],
            [19.5236, 53.9554],
            [19.4963, 53.956],
            [19.4843, 53.9515],
            [19.4563, 53.9485],
            [19.4315, 53.9385],
            [19.4125, 53.9378],
            [19.4061, 53.9449],
            [19.3883, 53.9511],
            [19.3907, 53.9791],
            [19.4148, 53.9982],
            [19.4061, 54.004],
            [19.3721, 54.0088],
            [19.3518, 54.0145],
            [19.3279, 54.0247],
            [19.3121, 54.025],
            [19.2961, 54.0156],
            [19.2779, 54.03],
            [19.2785, 54.0377],
            [19.2977, 54.0467],
            [19.2959, 54.0502],
            [19.2654, 54.0546],
            [19.2548, 54.0602],
            [19.223, 54.0697],
            [19.225, 54.0774],
            [19.2488, 54.087],
            [19.2493, 54.099],
            [19.2404, 54.1112],
            [19.2408, 54.1164],
            [19.2538, 54.1273],
            [19.2707, 54.1459],
            [19.2711, 54.1588],
            [19.2517, 54.1715],
            [19.2606, 54.181],
            [19.3181, 54.1999],
            [19.3238, 54.2102],
            [19.317, 54.2281],
            [19.2738, 54.2625],
            [19.2631, 54.2734],
            [19.2713, 54.2705],
            [19.2811, 54.2765],
            [19.3087, 54.273],
            [19.3163, 54.2743],
            [19.3594, 54.2677],
            [19.3768, 54.2635],
            [19.3928, 54.2683],
            [19.3812, 54.2506],
            [19.3819, 54.2435],
            [19.361, 54.2239],
            [19.3744, 54.2182],
            [19.3849, 54.2259],
            [19.3955, 54.2523],
            [19.4083, 54.2687],
            [19.4392, 54.287],
            [19.462, 54.2954],
            [19.4971, 54.3193],
            [19.5325, 54.3247],
            [19.5584, 54.3366],
            [19.5749, 54.3391],
            [19.627, 54.3394],
            [19.6471, 54.3444],
            [19.6722, 54.3549],
            [19.6893, 54.367],
            [19.6904, 54.3744],
            [19.7075, 54.3807],
            [19.7183, 54.4001],
            [19.7317, 54.4056],
            [19.7496, 54.4193],
            [19.7425, 54.4249],
            [19.7527, 54.432],
            [19.7827, 54.4329],
            [19.804, 54.4382],
            [19.9138, 54.4291],
            [19.9426, 54.4262],
            [20.0187, 54.4206],
            [20.077, 54.4181],
            [20.1115, 54.4147],
            [20.1711, 54.4104],
            [20.2209, 54.4048],
            [20.2561, 54.4035],
            [20.3355, 54.3969],
            [20.3827, 54.39],
            [20.4073, 54.3889],
            [20.4471, 54.3829],
            [20.5054, 54.3791],
            [20.5155, 54.3767],
            [20.5801, 54.3743],
            [20.6236, 54.3666],
            [20.6368, 54.3631],
            [20.6478, 54.3665],
            [20.6963, 54.3677],
            [20.7257, 54.3649],
            [20.7425, 54.3655],
            [20.8192, 54.3554],
            [20.8509, 54.3562],
            [20.861, 54.3542],
            [20.9276, 54.3519],
            [20.9538, 54.3526],
            [20.9613, 54.3502],
            [21.0015, 54.349],
            [21.0478, 54.3435],
            [21.1043, 54.3417],
            [21.149, 54.3367],
            [21.1666, 54.3333],
            [21.2006, 54.3333],
            [21.2424, 54.3271],
            [21.2761, 54.3242],
            [21.3048, 54.329],
            [21.3472, 54.3245],
            [21.3831, 54.3254],
            [21.4131, 54.322],
            [21.4377, 54.3216],
            [21.4483, 54.3135],
            [21.4969, 54.3191],
            [21.53, 54.321],
            [21.5698, 54.3178],
            [21.5779, 54.3208],
            [21.6366, 54.3224],
            [21.6661, 54.3217],
            [21.7814, 54.3261],
            [21.8406, 54.3261],
            [21.8617, 54.3274],
            [21.9003, 54.327],
            [21.9724, 54.328],
            [21.9878, 54.3295],
            [22.0041, 54.3351],
            [22.0291, 54.3363],
            [22.0746, 54.3359],
            [22.0972, 54.3375],
            [22.1282, 54.3363],
            [22.18, 54.3381],
            [22.2261, 54.3444],
            [22.2535, 54.3415],
            [22.3104, 54.342],
            [22.3362, 54.3439],
            [22.3964, 54.3461],
            [22.4448, 54.3493],
            [22.4721, 54.3488],
            [22.5294, 54.3496],
            [22.5713, 54.3541],
            [22.5984, 54.3541],
            [22.6174, 54.3564],
            [22.6507, 54.3543],
            [22.6724, 54.3595],
            [22.7093, 54.362],
            [22.7893, 54.3631],
          ],
        ],
      },
      properties: {
        name: 'Warmińsko-Mazurskie',
        id: 'PL-28',
        NAME_ENG: 'Warmia-Masuria',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-28',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.8936, 53.6574],
            [16.8995, 53.6423],
            [16.896, 53.6369],
            [16.9043, 53.6264],
            [16.8988, 53.6213],
            [16.9139, 53.6059],
            [16.9209, 53.6036],
            [16.936, 53.5865],
            [16.938, 53.573],
            [16.9458, 53.5697],
            [16.9575, 53.5483],
            [16.9798, 53.554],
            [17.0009, 53.55],
            [17.0047, 53.5347],
            [16.9945, 53.5317],
            [17.0056, 53.5173],
            [17.0306, 53.5166],
            [17.0531, 53.518],
            [17.0894, 53.542],
            [17.103, 53.5426],
            [17.1443, 53.5337],
            [17.1627, 53.527],
            [17.1751, 53.5332],
            [17.1962, 53.533],
            [17.2094, 53.5373],
            [17.2198, 53.5338],
            [17.2378, 53.5336],
            [17.2675, 53.5372],
            [17.2976, 53.5262],
            [17.3088, 53.5251],
            [17.305, 53.5121],
            [17.3231, 53.5121],
            [17.3335, 53.5028],
            [17.3374, 53.5019],
            [17.3512, 53.4927],
            [17.3634, 53.4966],
            [17.3733, 53.4939],
            [17.3857, 53.4966],
            [17.3916, 53.4904],
            [17.3825, 53.4692],
            [17.3725, 53.4666],
            [17.3552, 53.4534],
            [17.337, 53.4461],
            [17.3334, 53.439],
            [17.3156, 53.4319],
            [17.2992, 53.4227],
            [17.2926, 53.4135],
            [17.2947, 53.4025],
            [17.288, 53.3946],
            [17.2732, 53.3866],
            [17.2487, 53.3811],
            [17.2663, 53.3516],
            [17.2795, 53.351],
            [17.2811, 53.3588],
            [17.301, 53.3518],
            [17.3259, 53.347],
            [17.3383, 53.3473],
            [17.3415, 53.338],
            [17.358, 53.3302],
            [17.3532, 53.3241],
            [17.3592, 53.3173],
            [17.3785, 53.3117],
            [17.3874, 53.2994],
            [17.3967, 53.2947],
            [17.396, 53.2876],
            [17.4211, 53.2826],
            [17.4372, 53.2681],
            [17.4205, 53.2631],
            [17.4194, 53.253],
            [17.4283, 53.2461],
            [17.4132, 53.2412],
            [17.4168, 53.2316],
            [17.4048, 53.2259],
            [17.3821, 53.2276],
            [17.363, 53.2221],
            [17.3314, 53.2181],
            [17.3337, 53.2079],
            [17.3487, 53.1985],
            [17.3348, 53.1796],
            [17.3365, 53.1713],
            [17.3472, 53.1622],
            [17.3391, 53.1548],
            [17.3539, 53.1537],
            [17.3634, 53.1495],
            [17.3843, 53.1453],
            [17.3787, 53.1329],
            [17.3526, 53.1301],
            [17.3593, 53.1209],
            [17.3584, 53.1029],
            [17.3634, 53.0911],
            [17.3379, 53.0777],
            [17.3249, 53.0769],
            [17.3307, 53.0514],
            [17.3374, 53.0422],
            [17.3321, 53.0184],
            [17.317, 53.0198],
            [17.3029, 52.9965],
            [17.3194, 52.9875],
            [17.3176, 52.9749],
            [17.3503, 52.9735],
            [17.3712, 52.9755],
            [17.3964, 52.9839],
            [17.4112, 52.9733],
            [17.4298, 52.9678],
            [17.4681, 52.9491],
            [17.4863, 52.9517],
            [17.4992, 52.9337],
            [17.5189, 52.9288],
            [17.5088, 52.9188],
            [17.5134, 52.9039],
            [17.5187, 52.8971],
            [17.518, 52.8873],
            [17.5293, 52.8857],
            [17.5237, 52.8777],
            [17.5241, 52.8606],
            [17.5134, 52.8561],
            [17.5155, 52.8397],
            [17.5103, 52.8347],
            [17.522, 52.8236],
            [17.5045, 52.8052],
            [17.5074, 52.7953],
            [17.4983, 52.786],
            [17.4629, 52.7879],
            [17.4536, 52.7982],
            [17.4435, 52.7888],
            [17.4166, 52.7852],
            [17.4078, 52.7723],
            [17.4139, 52.7674],
            [17.4098, 52.7583],
            [17.4194, 52.7525],
            [17.4385, 52.7516],
            [17.4608, 52.7401],
            [17.4869, 52.7294],
            [17.4793, 52.7083],
            [17.4747, 52.7044],
            [17.4712, 52.6824],
            [17.4991, 52.6856],
            [17.5097, 52.6741],
            [17.5452, 52.6917],
            [17.5571, 52.6896],
            [17.5779, 52.695],
            [17.5789, 52.7062],
            [17.6133, 52.7042],
            [17.6319, 52.7105],
            [17.6396, 52.6952],
            [17.649, 52.6913],
            [17.6353, 52.6788],
            [17.6399, 52.6631],
            [17.6306, 52.6563],
            [17.646, 52.6448],
            [17.6775, 52.649],
            [17.6819, 52.6234],
            [17.6759, 52.6166],
            [17.679, 52.6027],
            [17.6895, 52.602],
            [17.7023, 52.6157],
            [17.713, 52.6149],
            [17.7283, 52.6206],
            [17.7491, 52.6346],
            [17.7488, 52.6382],
            [17.7257, 52.64],
            [17.7259, 52.6449],
            [17.7635, 52.6587],
            [17.7676, 52.6531],
            [17.7668, 52.6383],
            [17.776, 52.6388],
            [17.7905, 52.6575],
            [17.8078, 52.6516],
            [17.8389, 52.6488],
            [17.8384, 52.6374],
            [17.8559, 52.6382],
            [17.8721, 52.6427],
            [17.88, 52.6413],
            [17.9029, 52.6215],
            [17.9155, 52.6167],
            [17.916, 52.5968],
            [17.9281, 52.5863],
            [17.937, 52.5972],
            [17.9478, 52.5921],
            [17.9577, 52.5732],
            [17.97, 52.5776],
            [17.9841, 52.5787],
            [17.9979, 52.5759],
            [17.997, 52.5654],
            [17.9883, 52.5525],
            [17.9883, 52.5437],
            [17.999, 52.5431],
            [18.0036, 52.5557],
            [18.023, 52.5555],
            [18.0329, 52.5508],
            [18.0629, 52.5483],
            [18.0654, 52.5518],
            [18.096, 52.5531],
            [18.1072, 52.5392],
            [18.0928, 52.532],
            [18.0929, 52.5196],
            [18.1059, 52.5144],
            [18.1227, 52.5157],
            [18.1256, 52.5068],
            [18.1372, 52.5047],
            [18.1541, 52.5101],
            [18.1698, 52.5053],
            [18.1965, 52.5049],
            [18.2012, 52.4988],
            [18.1985, 52.4872],
            [18.2046, 52.4831],
            [18.2304, 52.4838],
            [18.2406, 52.4882],
            [18.261, 52.4812],
            [18.2754, 52.4912],
            [18.3125, 52.5052],
            [18.3386, 52.5198],
            [18.3445, 52.5252],
            [18.339, 52.5378],
            [18.3623, 52.5434],
            [18.3714, 52.538],
            [18.3826, 52.5378],
            [18.3879, 52.5221],
            [18.378, 52.5161],
            [18.3766, 52.4993],
            [18.3858, 52.4862],
            [18.3828, 52.4748],
            [18.3882, 52.4697],
            [18.3996, 52.4729],
            [18.4189, 52.4731],
            [18.4232, 52.4785],
            [18.4402, 52.4795],
            [18.4639, 52.4759],
            [18.4716, 52.4894],
            [18.4865, 52.4974],
            [18.4902, 52.5039],
            [18.5234, 52.4984],
            [18.5368, 52.4857],
            [18.5594, 52.4838],
            [18.5692, 52.464],
            [18.589, 52.4607],
            [18.5931, 52.468],
            [18.6092, 52.4556],
            [18.6452, 52.4527],
            [18.6575, 52.4221],
            [18.671, 52.4213],
            [18.6832, 52.4094],
            [18.6802, 52.4],
            [18.7109, 52.4006],
            [18.7392, 52.3732],
            [18.7424, 52.3648],
            [18.7526, 52.3634],
            [18.755, 52.3334],
            [18.7782, 52.3354],
            [18.7891, 52.343],
            [18.8138, 52.3524],
            [18.8193, 52.3478],
            [18.8365, 52.3433],
            [18.8586, 52.3496],
            [18.8792, 52.3643],
            [18.8936, 52.3638],
            [18.9129, 52.3667],
            [18.9104, 52.3793],
            [18.9213, 52.3768],
            [18.9309, 52.3796],
            [18.9211, 52.399],
            [18.9402, 52.3923],
            [18.9535, 52.38],
            [18.976, 52.3757],
            [18.9784, 52.3656],
            [18.9681, 52.3624],
            [18.9698, 52.3566],
            [18.9835, 52.3578],
            [19.007, 52.3506],
            [19.0053, 52.3419],
            [19.024, 52.3382],
            [19.0291, 52.3335],
            [19.0469, 52.3331],
            [19.0453, 52.3194],
            [19.0369, 52.3167],
            [19.0394, 52.3008],
            [19.0459, 52.2962],
            [19.0327, 52.2705],
            [19.0557, 52.2743],
            [19.1004, 52.2668],
            [19.1052, 52.2423],
            [19.0816, 52.2387],
            [19.0802, 52.231],
            [19.0894, 52.2072],
            [19.0482, 52.2206],
            [19.0115, 52.2196],
            [18.9937, 52.2236],
            [18.9797, 52.2191],
            [18.9621, 52.2176],
            [18.9466, 52.2091],
            [18.9288, 52.2077],
            [18.9196, 52.1993],
            [18.9094, 52.1588],
            [18.9098, 52.1453],
            [18.9201, 52.1363],
            [18.9403, 52.1359],
            [18.9441, 52.1205],
            [18.9285, 52.1097],
            [18.9288, 52.0939],
            [18.9228, 52.0894],
            [18.9052, 52.0925],
            [18.8714, 52.0936],
            [18.8462, 52.0903],
            [18.8357, 52.0856],
            [18.8364, 52.0657],
            [18.8274, 52.0621],
            [18.8139, 52.063],
            [18.8096, 52.0738],
            [18.7781, 52.0692],
            [18.7559, 52.072],
            [18.7451, 52.0795],
            [18.7367, 52.0799],
            [18.7109, 52.0681],
            [18.7097, 52.0472],
            [18.7146, 52.0298],
            [18.7254, 52.0165],
            [18.7249, 52.0011],
            [18.7339, 51.9906],
            [18.735, 51.9834],
            [18.7248, 51.9784],
            [18.7243, 51.9713],
            [18.7395, 51.9616],
            [18.7541, 51.9462],
            [18.7908, 51.9334],
            [18.7704, 51.9179],
            [18.7605, 51.9212],
            [18.7477, 51.9066],
            [18.7367, 51.9005],
            [18.7395, 51.8935],
            [18.721, 51.8773],
            [18.7145, 51.868],
            [18.6978, 51.8654],
            [18.6975, 51.8546],
            [18.6887, 51.825],
            [18.649, 51.8257],
            [18.6425, 51.8338],
            [18.6312, 51.8293],
            [18.6139, 51.8346],
            [18.5952, 51.8467],
            [18.5691, 51.8433],
            [18.5483, 51.8444],
            [18.5355, 51.8402],
            [18.5311, 51.8341],
            [18.5162, 51.8348],
            [18.5037, 51.8575],
            [18.4821, 51.8562],
            [18.4736, 51.8513],
            [18.4656, 51.8371],
            [18.4705, 51.8202],
            [18.4503, 51.8262],
            [18.4406, 51.826],
            [18.4411, 51.8081],
            [18.4357, 51.8022],
            [18.4357, 51.793],
            [18.4129, 51.79],
            [18.4253, 51.7771],
            [18.432, 51.7641],
            [18.4214, 51.7584],
            [18.414, 51.748],
            [18.4232, 51.7363],
            [18.4205, 51.7251],
            [18.4063, 51.7258],
            [18.4122, 51.7065],
            [18.3987, 51.6951],
            [18.3801, 51.6958],
            [18.3799, 51.6848],
            [18.3655, 51.6821],
            [18.358, 51.6732],
            [18.3548, 51.6602],
            [18.3824, 51.6531],
            [18.3761, 51.639],
            [18.3701, 51.6338],
            [18.3527, 51.6338],
            [18.3511, 51.627],
            [18.3624, 51.6258],
            [18.3689, 51.6173],
            [18.3525, 51.6065],
            [18.3556, 51.6002],
            [18.3764, 51.5995],
            [18.3702, 51.5899],
            [18.3727, 51.5784],
            [18.365, 51.563],
            [18.3699, 51.5439],
            [18.3575, 51.5342],
            [18.3675, 51.5248],
            [18.381, 51.5177],
            [18.3803, 51.4989],
            [18.3831, 51.4827],
            [18.3814, 51.4762],
            [18.3571, 51.4664],
            [18.3567, 51.4557],
            [18.3403, 51.44],
            [18.3405, 51.4305],
            [18.3266, 51.4198],
            [18.3033, 51.4308],
            [18.238, 51.4521],
            [18.2199, 51.454],
            [18.2042, 51.4459],
            [18.1972, 51.4387],
            [18.2103, 51.4339],
            [18.2157, 51.4239],
            [18.214, 51.4068],
            [18.2031, 51.3939],
            [18.1966, 51.3915],
            [18.1511, 51.3909],
            [18.1416, 51.3856],
            [18.1292, 51.3616],
            [18.107, 51.3655],
            [18.0972, 51.3649],
            [18.0839, 51.3563],
            [18.0985, 51.3303],
            [18.1133, 51.3157],
            [18.1158, 51.3068],
            [18.1122, 51.2923],
            [18.1145, 51.2665],
            [18.1237, 51.2656],
            [18.1349, 51.2719],
            [18.144, 51.2707],
            [18.1569, 51.2634],
            [18.1698, 51.2723],
            [18.1825, 51.2744],
            [18.1849, 51.2679],
            [18.1821, 51.2505],
            [18.1883, 51.2371],
            [18.1872, 51.2299],
            [18.1728, 51.216],
            [18.1704, 51.2041],
            [18.1764, 51.1957],
            [18.1604, 51.1753],
            [18.1521, 51.171],
            [18.1358, 51.1718],
            [18.1245, 51.1686],
            [18.1065, 51.1557],
            [18.0941, 51.1565],
            [18.0787, 51.1658],
            [18.0664, 51.1511],
            [18.0735, 51.145],
            [18.0466, 51.1348],
            [18.005, 51.1233],
            [17.9875, 51.1205],
            [17.9414, 51.1093],
            [17.9221, 51.1147],
            [17.9073, 51.1081],
            [17.8865, 51.1048],
            [17.8758, 51.1125],
            [17.8293, 51.1198],
            [17.8241, 51.1179],
            [17.8132, 51.1322],
            [17.8199, 51.1399],
            [17.8323, 51.1374],
            [17.8445, 51.1636],
            [17.8516, 51.1712],
            [17.8467, 51.1863],
            [17.8268, 51.1951],
            [17.8153, 51.1868],
            [17.801, 51.1919],
            [17.793, 51.1997],
            [17.7696, 51.2112],
            [17.7607, 51.2302],
            [17.7478, 51.2461],
            [17.748, 51.2708],
            [17.7598, 51.2842],
            [17.7647, 51.295],
            [17.7634, 51.3024],
            [17.754, 51.3177],
            [17.7511, 51.3328],
            [17.7458, 51.3367],
            [17.7158, 51.3438],
            [17.7178, 51.3665],
            [17.7252, 51.3744],
            [17.7386, 51.3777],
            [17.7503, 51.3927],
            [17.7489, 51.4093],
            [17.7345, 51.4208],
            [17.723, 51.421],
            [17.7189, 51.4063],
            [17.7094, 51.4025],
            [17.6732, 51.4085],
            [17.6609, 51.4063],
            [17.6527, 51.4178],
            [17.6369, 51.4142],
            [17.626, 51.417],
            [17.6195, 51.4085],
            [17.5862, 51.4025],
            [17.5485, 51.4036],
            [17.5452, 51.4241],
            [17.5514, 51.4357],
            [17.5411, 51.447],
            [17.5219, 51.4623],
            [17.5292, 51.4689],
            [17.5168, 51.4742],
            [17.5229, 51.484],
            [17.5199, 51.4926],
            [17.5266, 51.4993],
            [17.5149, 51.502],
            [17.5159, 51.5103],
            [17.5231, 51.5135],
            [17.5519, 51.5137],
            [17.5747, 51.5301],
            [17.5804, 51.5379],
            [17.5722, 51.5557],
            [17.572, 51.5629],
            [17.5616, 51.5736],
            [17.5568, 51.5836],
            [17.5358, 51.589],
            [17.5224, 51.5891],
            [17.5186, 51.6052],
            [17.5044, 51.6096],
            [17.4995, 51.615],
            [17.4866, 51.6167],
            [17.4692, 51.6258],
            [17.4405, 51.6322],
            [17.4198, 51.6304],
            [17.3894, 51.6362],
            [17.3806, 51.6357],
            [17.3581, 51.6433],
            [17.3291, 51.6472],
            [17.2986, 51.6459],
            [17.245, 51.6401],
            [17.2391, 51.6348],
            [17.2164, 51.6291],
            [17.2077, 51.6109],
            [17.215, 51.5999],
            [17.2128, 51.5884],
            [17.214, 51.5735],
            [17.1874, 51.5768],
            [17.1686, 51.5692],
            [17.1265, 51.566],
            [17.1096, 51.5751],
            [17.0925, 51.5734],
            [17.0652, 51.5663],
            [17.0695, 51.5526],
            [17.0464, 51.5556],
            [17.02, 51.5534],
            [17.0092, 51.5497],
            [16.9834, 51.5504],
            [16.9789, 51.5454],
            [16.9632, 51.5458],
            [16.9601, 51.5535],
            [16.9278, 51.5527],
            [16.9164, 51.5584],
            [16.8965, 51.5782],
            [16.8875, 51.581],
            [16.8676, 51.5792],
            [16.8418, 51.585],
            [16.8336, 51.5833],
            [16.8244, 51.5936],
            [16.8046, 51.5971],
            [16.795, 51.6049],
            [16.7932, 51.6149],
            [16.7714, 51.628],
            [16.7697, 51.6454],
            [16.7432, 51.6425],
            [16.7276, 51.6501],
            [16.6716, 51.6508],
            [16.6604, 51.6588],
            [16.6389, 51.6592],
            [16.6378, 51.6788],
            [16.6462, 51.681],
            [16.6491, 51.6948],
            [16.6625, 51.7006],
            [16.6625, 51.7097],
            [16.6386, 51.7253],
            [16.6313, 51.7468],
            [16.6273, 51.7497],
            [16.6051, 51.7519],
            [16.5841, 51.7566],
            [16.5699, 51.7722],
            [16.5594, 51.7737],
            [16.5528, 51.7824],
            [16.5299, 51.7835],
            [16.4732, 51.7911],
            [16.4336, 51.79],
            [16.421, 51.7843],
            [16.403, 51.7905],
            [16.3989, 51.8012],
            [16.4025, 51.8159],
            [16.4014, 51.8276],
            [16.3894, 51.8355],
            [16.3848, 51.8508],
            [16.3755, 51.8546],
            [16.3444, 51.8609],
            [16.3398, 51.8655],
            [16.3425, 51.8756],
            [16.3066, 51.8867],
            [16.2954, 51.8974],
            [16.2717, 51.8966],
            [16.2613, 51.9061],
            [16.2463, 51.9055],
            [16.2384, 51.8993],
            [16.2196, 51.8764],
            [16.2012, 51.8699],
            [16.1922, 51.8799],
            [16.1704, 51.8826],
            [16.162, 51.8918],
            [16.1241, 51.8962],
            [16.1006, 51.8953],
            [16.1193, 51.9139],
            [16.1319, 51.9174],
            [16.1374, 51.9271],
            [16.1267, 51.9335],
            [16.1311, 51.9441],
            [16.1236, 51.9553],
            [16.1363, 51.9575],
            [16.1351, 51.9671],
            [16.1224, 51.9914],
            [16.1102, 51.9961],
            [16.093, 51.9969],
            [16.0753, 51.9842],
            [16.0642, 51.9808],
            [16.0533, 51.972],
            [16.0263, 51.972],
            [16.0186, 51.9744],
            [16.006, 51.9949],
            [15.9997, 51.998],
            [15.9795, 52.0001],
            [15.9609, 52.0178],
            [15.9587, 52.0392],
            [15.9798, 52.0427],
            [15.9888, 52.0492],
            [15.9907, 52.0573],
            [15.9801, 52.0721],
            [15.9641, 52.0791],
            [15.9432, 52.0826],
            [15.9301, 52.0762],
            [15.9223, 52.0653],
            [15.9131, 52.0734],
            [15.8802, 52.0817],
            [15.8708, 52.0856],
            [15.8565, 52.1],
            [15.8514, 52.1231],
            [15.8565, 52.1312],
            [15.8807, 52.1403],
            [15.882, 52.1508],
            [15.8663, 52.1739],
            [15.8584, 52.2003],
            [15.8591, 52.2162],
            [15.8698, 52.2323],
            [15.8684, 52.2424],
            [15.8575, 52.2611],
            [15.8804, 52.2777],
            [15.8791, 52.2992],
            [15.8927, 52.311],
            [15.8904, 52.3234],
            [15.8985, 52.3292],
            [15.8976, 52.362],
            [15.9057, 52.3827],
            [15.9046, 52.3928],
            [15.8954, 52.3974],
            [15.8638, 52.4048],
            [15.8489, 52.4191],
            [15.8406, 52.4203],
            [15.8191, 52.4309],
            [15.8156, 52.4399],
            [15.8245, 52.4538],
            [15.8354, 52.4533],
            [15.8373, 52.4456],
            [15.8474, 52.4353],
            [15.8567, 52.4309],
            [15.8629, 52.4391],
            [15.8779, 52.432],
            [15.8864, 52.4398],
            [15.9019, 52.4437],
            [15.8995, 52.4514],
            [15.879, 52.4617],
            [15.8809, 52.4699],
            [15.8918, 52.4688],
            [15.8845, 52.4917],
            [15.8684, 52.5091],
            [15.8474, 52.5119],
            [15.8319, 52.5236],
            [15.8321, 52.529],
            [15.855, 52.5437],
            [15.8531, 52.5506],
            [15.8289, 52.5574],
            [15.8185, 52.5634],
            [15.824, 52.5786],
            [15.8207, 52.5857],
            [15.8063, 52.5925],
            [15.7858, 52.6075],
            [15.7866, 52.613],
            [15.8017, 52.6298],
            [15.8027, 52.6358],
            [15.7959, 52.6446],
            [15.7962, 52.6552],
            [15.8033, 52.6745],
            [15.8223, 52.677],
            [15.8218, 52.6846],
            [15.8054, 52.7034],
            [15.8068, 52.7116],
            [15.8627, 52.7146],
            [15.9027, 52.7195],
            [15.9395, 52.7281],
            [15.9453, 52.7309],
            [15.9491, 52.7443],
            [15.9477, 52.7575],
            [15.9396, 52.7632],
            [15.9274, 52.7854],
            [15.9382, 52.787],
            [15.9349, 52.8023],
            [15.9114, 52.8037],
            [15.8941, 52.8114],
            [15.8973, 52.8252],
            [15.9056, 52.8381],
            [15.9189, 52.8408],
            [15.9243, 52.8326],
            [15.9518, 52.8442],
            [15.9474, 52.8537],
            [15.9757, 52.8585],
            [15.9735, 52.8653],
            [15.9801, 52.8818],
            [15.969, 52.899],
            [15.981, 52.9093],
            [15.9777, 52.9237],
            [15.9789, 52.9338],
            [15.9619, 52.957],
            [15.9619, 52.9716],
            [15.9513, 52.9727],
            [15.9548, 52.9906],
            [15.9513, 52.9924],
            [15.955, 53.0163],
            [15.947, 53.0397],
            [15.962, 53.0409],
            [15.9691, 53.0362],
            [16.0053, 53.029],
            [16.0383, 53.0279],
            [16.0796, 53.0132],
            [16.1168, 53.0075],
            [16.1308, 53.009],
            [16.1513, 53.0166],
            [16.1869, 53.0326],
            [16.2024, 53.0366],
            [16.232, 53.0399],
            [16.2538, 53.0473],
            [16.2711, 53.0504],
            [16.2921, 53.043],
            [16.3148, 53.0424],
            [16.3027, 53.0526],
            [16.3167, 53.0559],
            [16.3232, 53.0633],
            [16.3395, 53.0689],
            [16.3577, 53.0824],
            [16.3628, 53.0913],
            [16.3415, 53.1076],
            [16.3377, 53.1146],
            [16.3474, 53.1195],
            [16.3433, 53.1257],
            [16.3881, 53.1358],
            [16.3902, 53.1536],
            [16.3824, 53.1621],
            [16.4075, 53.179],
            [16.4247, 53.1748],
            [16.4209, 53.1661],
            [16.4286, 53.1643],
            [16.4402, 53.1709],
            [16.4458, 53.1798],
            [16.4696, 53.1917],
            [16.4856, 53.1908],
            [16.4914, 53.1841],
            [16.5114, 53.1841],
            [16.5239, 53.1941],
            [16.5243, 53.2052],
            [16.5352, 53.2159],
            [16.5277, 53.2232],
            [16.5376, 53.2323],
            [16.5512, 53.2252],
            [16.5643, 53.2328],
            [16.5595, 53.2419],
            [16.5868, 53.2395],
            [16.6051, 53.233],
            [16.6173, 53.2199],
            [16.6237, 53.2239],
            [16.6384, 53.2246],
            [16.6377, 53.2332],
            [16.626, 53.243],
            [16.626, 53.2484],
            [16.6351, 53.2662],
            [16.6277, 53.2729],
            [16.6462, 53.2826],
            [16.658, 53.2925],
            [16.671, 53.2938],
            [16.6912, 53.3044],
            [16.7063, 53.31],
            [16.7085, 53.3147],
            [16.6865, 53.3316],
            [16.676, 53.3363],
            [16.6527, 53.342],
            [16.6344, 53.3565],
            [16.5917, 53.3645],
            [16.5584, 53.369],
            [16.5381, 53.3858],
            [16.5061, 53.3985],
            [16.4852, 53.393],
            [16.474, 53.3945],
            [16.4602, 53.4368],
            [16.4618, 53.4473],
            [16.4696, 53.4584],
            [16.4485, 53.4758],
            [16.4558, 53.487],
            [16.4832, 53.4777],
            [16.4923, 53.4705],
            [16.5544, 53.4787],
            [16.5765, 53.4793],
            [16.5847, 53.4842],
            [16.6176, 53.4827],
            [16.6462, 53.4849],
            [16.6714, 53.4994],
            [16.6951, 53.5186],
            [16.7043, 53.5155],
            [16.7177, 53.5207],
            [16.7275, 53.5284],
            [16.7153, 53.5333],
            [16.7188, 53.5448],
            [16.7277, 53.5555],
            [16.7276, 53.5667],
            [16.7396, 53.5666],
            [16.7462, 53.5761],
            [16.7398, 53.5843],
            [16.7423, 53.5998],
            [16.7496, 53.6016],
            [16.7582, 53.6168],
            [16.755, 53.6231],
            [16.7841, 53.6289],
            [16.8118, 53.6305],
            [16.8112, 53.6387],
            [16.824, 53.6436],
            [16.8432, 53.6256],
            [16.8574, 53.6329],
            [16.8557, 53.6413],
            [16.8683, 53.6519],
            [16.8936, 53.6574],
          ],
        ],
      },
      properties: {
        name: 'Wielkopolskie',
        id: 'PL-30',
        NAME_ENG: 'Greater Poland',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-30',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [16.7269, 54.5674],
              [16.7333, 54.5578],
              [16.7493, 54.5432],
              [16.7541, 54.5212],
              [16.7608, 54.5059],
              [16.773, 54.4894],
              [16.7794, 54.4858],
              [16.7993, 54.4861],
              [16.8117, 54.4834],
              [16.828, 54.473],
              [16.8364, 54.4561],
              [16.8446, 54.4461],
              [16.8446, 54.4369],
              [16.8337, 54.4198],
              [16.8331, 54.4055],
              [16.8397, 54.3922],
              [16.8497, 54.3809],
              [16.825, 54.37],
              [16.8112, 54.3731],
              [16.8072, 54.366],
              [16.823, 54.3508],
              [16.8246, 54.341],
              [16.8151, 54.3185],
              [16.8389, 54.3167],
              [16.8526, 54.3057],
              [16.8654, 54.2839],
              [16.8643, 54.2715],
              [16.8545, 54.2615],
              [16.8202, 54.2495],
              [16.7786, 54.2458],
              [16.7312, 54.2342],
              [16.7288, 54.224],
              [16.7154, 54.2139],
              [16.7218, 54.2026],
              [16.7334, 54.2022],
              [16.7372, 54.1866],
              [16.7354, 54.1759],
              [16.7491, 54.1716],
              [16.7623, 54.1559],
              [16.774, 54.1549],
              [16.7742, 54.1343],
              [16.7843, 54.1313],
              [16.7892, 54.1216],
              [16.8048, 54.1168],
              [16.8001, 54.1047],
              [16.7881, 54.1094],
              [16.7832, 54.0954],
              [16.788, 54.0871],
              [16.8091, 54.0754],
              [16.8091, 54.066],
              [16.815, 54.0579],
              [16.7955, 54.0502],
              [16.7987, 54.0383],
              [16.7845, 54.0248],
              [16.7872, 54.019],
              [16.8012, 54.0119],
              [16.8035, 53.9951],
              [16.8256, 54.0049],
              [16.8297, 53.9958],
              [16.8429, 53.9977],
              [16.8471, 53.9847],
              [16.8756, 53.976],
              [16.8805, 53.9639],
              [16.8766, 53.9521],
              [16.8709, 53.9479],
              [16.9091, 53.9253],
              [16.9185, 53.9259],
              [16.9274, 53.9183],
              [16.9425, 53.9117],
              [16.9505, 53.9123],
              [16.9737, 53.9036],
              [16.9803, 53.8981],
              [16.9765, 53.8772],
              [16.9624, 53.8749],
              [16.9246, 53.8745],
              [16.8961, 53.8789],
              [16.8955, 53.8736],
              [16.8722, 53.867],
              [16.8686, 53.8531],
              [16.8725, 53.8444],
              [16.8705, 53.8353],
              [16.9105, 53.8187],
              [16.9061, 53.8134],
              [16.9115, 53.8026],
              [16.9197, 53.7991],
              [16.9389, 53.8019],
              [16.9444, 53.7986],
              [16.9322, 53.7781],
              [16.916, 53.7729],
              [16.9012, 53.761],
              [16.8833, 53.7574],
              [16.8581, 53.7466],
              [16.8774, 53.7203],
              [16.8757, 53.7145],
              [16.8807, 53.7025],
              [16.8804, 53.6906],
              [16.8936, 53.6574],
              [16.8683, 53.6519],
              [16.8557, 53.6413],
              [16.8574, 53.6329],
              [16.8432, 53.6256],
              [16.824, 53.6436],
              [16.8112, 53.6387],
              [16.8118, 53.6305],
              [16.7841, 53.6289],
              [16.755, 53.6231],
              [16.7582, 53.6168],
              [16.7496, 53.6016],
              [16.7423, 53.5998],
              [16.7398, 53.5843],
              [16.7462, 53.5761],
              [16.7396, 53.5666],
              [16.7276, 53.5667],
              [16.7277, 53.5555],
              [16.7188, 53.5448],
              [16.7153, 53.5333],
              [16.7275, 53.5284],
              [16.7177, 53.5207],
              [16.7043, 53.5155],
              [16.6951, 53.5186],
              [16.6714, 53.4994],
              [16.6462, 53.4849],
              [16.6176, 53.4827],
              [16.5847, 53.4842],
              [16.5765, 53.4793],
              [16.5544, 53.4787],
              [16.4923, 53.4705],
              [16.4832, 53.4777],
              [16.4558, 53.487],
              [16.4485, 53.4758],
              [16.4696, 53.4584],
              [16.4618, 53.4473],
              [16.4602, 53.4368],
              [16.474, 53.3945],
              [16.4852, 53.393],
              [16.5061, 53.3985],
              [16.5381, 53.3858],
              [16.5584, 53.369],
              [16.5917, 53.3645],
              [16.6344, 53.3565],
              [16.6527, 53.342],
              [16.676, 53.3363],
              [16.6865, 53.3316],
              [16.7085, 53.3147],
              [16.7063, 53.31],
              [16.6912, 53.3044],
              [16.671, 53.2938],
              [16.658, 53.2925],
              [16.6462, 53.2826],
              [16.6277, 53.2729],
              [16.6351, 53.2662],
              [16.626, 53.2484],
              [16.626, 53.243],
              [16.6377, 53.2332],
              [16.6384, 53.2246],
              [16.6237, 53.2239],
              [16.6173, 53.2199],
              [16.6051, 53.233],
              [16.5868, 53.2395],
              [16.5595, 53.2419],
              [16.5643, 53.2328],
              [16.5512, 53.2252],
              [16.5376, 53.2323],
              [16.5277, 53.2232],
              [16.5352, 53.2159],
              [16.5243, 53.2052],
              [16.5239, 53.1941],
              [16.5114, 53.1841],
              [16.4914, 53.1841],
              [16.4856, 53.1908],
              [16.4696, 53.1917],
              [16.4458, 53.1798],
              [16.4402, 53.1709],
              [16.4286, 53.1643],
              [16.4209, 53.1661],
              [16.4247, 53.1748],
              [16.4075, 53.179],
              [16.3824, 53.1621],
              [16.3902, 53.1536],
              [16.3881, 53.1358],
              [16.3433, 53.1257],
              [16.3474, 53.1195],
              [16.3377, 53.1146],
              [16.3415, 53.1076],
              [16.3628, 53.0913],
              [16.3577, 53.0824],
              [16.3395, 53.0689],
              [16.3232, 53.0633],
              [16.3167, 53.0559],
              [16.3027, 53.0526],
              [16.3148, 53.0424],
              [16.2921, 53.043],
              [16.2711, 53.0504],
              [16.2538, 53.0473],
              [16.232, 53.0399],
              [16.2024, 53.0366],
              [16.1869, 53.0326],
              [16.1513, 53.0166],
              [16.1308, 53.009],
              [16.1168, 53.0075],
              [16.0796, 53.0132],
              [16.0383, 53.0279],
              [16.0053, 53.029],
              [15.9691, 53.0362],
              [15.962, 53.0409],
              [15.9776, 53.0491],
              [15.9859, 53.0698],
              [15.9935, 53.0747],
              [15.995, 53.0914],
              [15.9861, 53.0941],
              [15.9866, 53.1035],
              [15.9571, 53.112],
              [15.9289, 53.1177],
              [15.9047, 53.1258],
              [15.885, 53.1229],
              [15.8535, 53.1226],
              [15.8527, 53.1184],
              [15.8619, 53.104],
              [15.8577, 53.0973],
              [15.8287, 53.0917],
              [15.8098, 53.0859],
              [15.8088, 53.0788],
              [15.8221, 53.0777],
              [15.8186, 53.0665],
              [15.7973, 53.0619],
              [15.7774, 53.0615],
              [15.7779, 53.0563],
              [15.8042, 53.0445],
              [15.8035, 53.0394],
              [15.7851, 53.0386],
              [15.7744, 53.0305],
              [15.7596, 53.0297],
              [15.7496, 53.0256],
              [15.7496, 53.0188],
              [15.7667, 53.0109],
              [15.7662, 53.0054],
              [15.7248, 52.9947],
              [15.7051, 53.0067],
              [15.6908, 53.0062],
              [15.6789, 53.0019],
              [15.6523, 52.9853],
              [15.6476, 52.9842],
              [15.5866, 52.9937],
              [15.5712, 53.0117],
              [15.5543, 53.0157],
              [15.5175, 53.0083],
              [15.4714, 52.9971],
              [15.4594, 52.9961],
              [15.4203, 52.9878],
              [15.4073, 52.9828],
              [15.3737, 52.9536],
              [15.3658, 52.9503],
              [15.3463, 52.9502],
              [15.3215, 52.958],
              [15.299, 52.9614],
              [15.2862, 52.9583],
              [15.2854, 52.9515],
              [15.3022, 52.9261],
              [15.3207, 52.9085],
              [15.3316, 52.9062],
              [15.3265, 52.8962],
              [15.2798, 52.8822],
              [15.2273, 52.8693],
              [15.1983, 52.8631],
              [15.1591, 52.851],
              [15.0749, 52.8325],
              [15.0651, 52.8373],
              [15.0514, 52.8534],
              [15.0381, 52.8582],
              [15.0139, 52.8514],
              [15.0089, 52.8401],
              [14.9986, 52.8423],
              [14.9805, 52.8595],
              [14.9576, 52.8661],
              [14.9428, 52.8752],
              [14.9337, 52.875],
              [14.9297, 52.8822],
              [14.9101, 52.8807],
              [14.9014, 52.8669],
              [14.8868, 52.8623],
              [14.8837, 52.8562],
              [14.916, 52.8449],
              [14.9173, 52.8373],
              [14.9007, 52.8303],
              [14.8894, 52.8412],
              [14.8741, 52.8399],
              [14.8717, 52.8259],
              [14.8537, 52.8253],
              [14.8515, 52.819],
              [14.8552, 52.8057],
              [14.8687, 52.8037],
              [14.8608, 52.7863],
              [14.8484, 52.7732],
              [14.8378, 52.7687],
              [14.8153, 52.7677],
              [14.8014, 52.7636],
              [14.7944, 52.7527],
              [14.7928, 52.7169],
              [14.79, 52.7021],
              [14.7808, 52.6899],
              [14.7571, 52.6755],
              [14.7518, 52.6666],
              [14.7481, 52.6489],
              [14.7276, 52.6376],
              [14.711, 52.6346],
              [14.6831, 52.6401],
              [14.653, 52.6617],
              [14.6338, 52.6601],
              [14.6037, 52.6462],
              [14.5985, 52.6355],
              [14.602, 52.6161],
              [14.6007, 52.6067],
              [14.5708, 52.6215],
              [14.505, 52.6433],
              [14.4642, 52.6616],
              [14.4532, 52.676],
              [14.4343, 52.6809],
              [14.4263, 52.6939],
              [14.4153, 52.7],
              [14.4063, 52.7102],
              [14.3876, 52.7222],
              [14.3756, 52.7345],
              [14.3628, 52.7397],
              [14.3516, 52.7507],
              [14.3257, 52.7607],
              [14.3062, 52.7649],
              [14.2848, 52.7723],
              [14.2691, 52.7799],
              [14.2331, 52.8032],
              [14.2239, 52.813],
              [14.2103, 52.8181],
              [14.1421, 52.8244],
              [14.1293, 52.8292],
              [14.123, 52.8382],
              [14.1246, 52.8451],
              [14.1351, 52.8563],
              [14.1555, 52.8719],
              [14.1615, 52.8888],
              [14.153, 52.8999],
              [14.142, 52.9341],
              [14.1394, 52.9482],
              [14.1445, 52.9611],
              [14.1637, 52.9654],
              [14.169, 52.9728],
              [14.2025, 52.9855],
              [14.2339, 52.9933],
              [14.2595, 53.0038],
              [14.3019, 53.0266],
              [14.3091, 53.0329],
              [14.3349, 53.0458],
              [14.3523, 53.0611],
              [14.3637, 53.0783],
              [14.3697, 53.0939],
              [14.3688, 53.1017],
              [14.3759, 53.1086],
              [14.3869, 53.1408],
              [14.3675, 53.161],
              [14.3697, 53.1807],
              [14.3765, 53.1993],
              [14.406, 53.2124],
              [14.4085, 53.2234],
              [14.4326, 53.2413],
              [14.4354, 53.2493],
              [14.4471, 53.2627],
              [14.4395, 53.2757],
              [14.421, 53.2782],
              [14.4159, 53.3008],
              [14.408, 53.3082],
              [14.4146, 53.3269],
              [14.4061, 53.347],
              [14.3944, 53.3526],
              [14.3957, 53.3746],
              [14.3882, 53.3903],
              [14.3742, 53.4101],
              [14.3751, 53.4192],
              [14.3705, 53.428],
              [14.374, 53.4451],
              [14.3693, 53.455],
              [14.3579, 53.4603],
              [14.3537, 53.4921],
              [14.3501, 53.4972],
              [14.3297, 53.5031],
              [14.3172, 53.5235],
              [14.319, 53.5316],
              [14.3073, 53.5442],
              [14.3039, 53.5546],
              [14.3149, 53.5668],
              [14.3114, 53.5805],
              [14.3168, 53.6178],
              [14.3084, 53.6204],
              [14.284, 53.6349],
              [14.2841, 53.6575],
              [14.2699, 53.6687],
              [14.2812, 53.6771],
              [14.28, 53.6873],
              [14.2722, 53.6968],
              [14.3011, 53.6995],
              [14.3092, 53.7121],
              [14.3015, 53.7206],
              [14.3013, 53.7441],
              [14.3321, 53.7348],
              [14.3424, 53.7176],
              [14.3669, 53.7],
              [14.3888, 53.6878],
              [14.4115, 53.6788],
              [14.4549, 53.6758],
              [14.492, 53.6685],
              [14.5039, 53.668],
              [14.5315, 53.6537],
              [14.5421, 53.6452],
              [14.5621, 53.6173],
              [14.5724, 53.6058],
              [14.5703, 53.5999],
              [14.5884, 53.5939],
              [14.598, 53.5959],
              [14.5938, 53.6097],
              [14.5955, 53.6269],
              [14.6076, 53.6269],
              [14.6193, 53.6336],
              [14.6063, 53.6582],
              [14.5844, 53.6533],
              [14.5685, 53.6599],
              [14.5648, 53.6661],
              [14.5453, 53.6794],
              [14.5487, 53.6866],
              [14.5399, 53.702],
              [14.5556, 53.7399],
              [14.5591, 53.7536],
              [14.5686, 53.7651],
              [14.5815, 53.7551],
              [14.5908, 53.7631],
              [14.6083, 53.7625],
              [14.6172, 53.7718],
              [14.6126, 53.7889],
              [14.6131, 53.803],
              [14.6069, 53.8232],
              [14.6215, 53.8324],
              [14.628, 53.8563],
              [14.6413, 53.8836],
              [14.6549, 53.8991],
              [14.6713, 53.9036],
              [14.6826, 53.9168],
              [14.7067, 53.9312],
              [14.7218, 53.9354],
              [14.7291, 53.9416],
              [14.7425, 53.9391],
              [14.7478, 53.9304],
              [14.7613, 53.9298],
              [14.7606, 53.9407],
              [14.7652, 53.9483],
              [14.7614, 53.954],
              [14.7668, 53.9788],
              [14.7648, 53.9912],
              [14.7747, 54.0058],
              [14.7837, 54.0098],
              [14.8022, 54.0088],
              [14.8051, 54.0303],
              [14.7965, 54.0325],
              [14.7731, 54.032],
              [14.8636, 54.0493],
              [14.891, 54.053],
              [14.9331, 54.0626],
              [15.0049, 54.0815],
              [15.0688, 54.0958],
              [15.0868, 54.0981],
              [15.1527, 54.1136],
              [15.2441, 54.1329],
              [15.2949, 54.1471],
              [15.3869, 54.1572],
              [15.4208, 54.1628],
              [15.444, 54.1647],
              [15.4783, 54.1643],
              [15.5008, 54.1669],
              [15.5334, 54.1756],
              [15.5531, 54.1849],
              [15.5811, 54.1847],
              [15.6428, 54.195],
              [15.6731, 54.1987],
              [15.7416, 54.2124],
              [15.7877, 54.2241],
              [15.8125, 54.232],
              [15.8497, 54.2399],
              [15.8748, 54.2429],
              [15.9425, 54.2474],
              [16.0256, 54.2569],
              [16.0787, 54.2673],
              [16.1168, 54.278],
              [16.1533, 54.2905],
              [16.1932, 54.3077],
              [16.2102, 54.3167],
              [16.3014, 54.3743],
              [16.3276, 54.3936],
              [16.3546, 54.4169],
              [16.3771, 54.4389],
              [16.3955, 54.4469],
              [16.41, 54.4644],
              [16.4353, 54.4884],
              [16.4751, 54.5123],
              [16.5029, 54.524],
              [16.5251, 54.5373],
              [16.5702, 54.5493],
              [16.6189, 54.5593],
              [16.6512, 54.5622],
              [16.6749, 54.5667],
              [16.7269, 54.5674],
            ],
          ],
          [
            [
              [14.3104, 53.8586],
              [14.3173, 53.8548],
              [14.3194, 53.8548],
              [14.3217, 53.8556],
              [14.3431, 53.8536],
              [14.3518, 53.8535],
              [14.3604, 53.856],
              [14.3828, 53.8551],
              [14.3898, 53.851],
              [14.3798, 53.8405],
              [14.3575, 53.8385],
              [14.339, 53.8284],
              [14.3404, 53.809],
              [14.2877, 53.8483],
              [14.2823, 53.8584],
              [14.3104, 53.8586],
            ],
          ],
          [
            [
              [14.3312, 53.8701],
              [14.338, 53.8696],
              [14.355, 53.8545],
              [14.34, 53.8543],
              [14.326, 53.8564],
              [14.322, 53.8563],
              [14.3186, 53.855],
              [14.313, 53.8587],
              [14.3237, 53.8682],
              [14.3312, 53.8701],
            ],
          ],
          [
            [
              [14.2493, 53.9233],
              [14.2795, 53.9209],
              [14.2747, 53.9132],
              [14.2526, 53.9065],
              [14.2511, 53.8958],
              [14.274, 53.8744],
              [14.2831, 53.8502],
              [14.2893, 53.8441],
              [14.3333, 53.8102],
              [14.3191, 53.8123],
              [14.3173, 53.8193],
              [14.3075, 53.8229],
              [14.2894, 53.8234],
              [14.2733, 53.8282],
              [14.2394, 53.8554],
              [14.2244, 53.865],
              [14.2137, 53.8667],
              [14.2146, 53.9007],
              [14.2044, 53.9108],
              [14.2071, 53.916],
              [14.2273, 53.9282],
              [14.2493, 53.9233],
            ],
          ],
          [
            [
              [14.367, 53.8823],
              [14.3603, 53.8741],
              [14.3701, 53.866],
              [14.3598, 53.8617],
              [14.3456, 53.8751],
              [14.3588, 53.8828],
              [14.367, 53.8823],
            ],
          ],
          [
            [
              [14.7566, 53.9666],
              [14.7604, 53.959],
              [14.7559, 53.9524],
              [14.742, 53.9549],
              [14.7133, 53.9463],
              [14.7031, 53.9493],
              [14.7079, 53.9638],
              [14.7172, 53.9699],
              [14.7101, 53.979],
              [14.7326, 53.9792],
              [14.7575, 53.9712],
              [14.7566, 53.9666],
            ],
          ],
          [
            [
              [14.4071, 53.8603],
              [14.4175, 53.8536],
              [14.4089, 53.8436],
              [14.3886, 53.8377],
              [14.3916, 53.8502],
              [14.3896, 53.8575],
              [14.4071, 53.8603],
            ],
          ],
          [
            [
              [14.7668, 54.0199],
              [14.7676, 54.0122],
              [14.7588, 54.0091],
              [14.7275, 54.0047],
              [14.716, 53.9981],
              [14.7008, 53.9983],
              [14.6947, 53.9942],
              [14.7002, 53.9816],
              [14.6878, 53.9503],
              [14.6957, 53.9375],
              [14.673, 53.9229],
              [14.6633, 53.9083],
              [14.6458, 53.9043],
              [14.6345, 53.8945],
              [14.6312, 53.8828],
              [14.6206, 53.8751],
              [14.6171, 53.8623],
              [14.6226, 53.8516],
              [14.6107, 53.8302],
              [14.5976, 53.8292],
              [14.5878, 53.8187],
              [14.5963, 53.8076],
              [14.5816, 53.804],
              [14.5723, 53.8211],
              [14.5842, 53.8395],
              [14.5774, 53.8473],
              [14.5553, 53.8564],
              [14.5315, 53.8598],
              [14.517, 53.8584],
              [14.5041, 53.8618],
              [14.4639, 53.8663],
              [14.4407, 53.863],
              [14.4233, 53.868],
              [14.4241, 53.8806],
              [14.4372, 53.8949],
              [14.4277, 53.8975],
              [14.4208, 53.8889],
              [14.4081, 53.8953],
              [14.3836, 53.8937],
              [14.3363, 53.8808],
              [14.3072, 53.8692],
              [14.2895, 53.8601],
              [14.2845, 53.8644],
              [14.2812, 53.8909],
              [14.2562, 53.897],
              [14.2593, 53.9034],
              [14.2813, 53.9131],
              [14.2846, 53.9221],
              [14.3313, 53.9123],
              [14.3507, 53.9109],
              [14.3973, 53.9179],
              [14.4313, 53.9268],
              [14.4637, 53.9416],
              [14.492, 53.9602],
              [14.5056, 53.9653],
              [14.5493, 53.9743],
              [14.5857, 53.9858],
              [14.6698, 54.0036],
              [14.6984, 54.0122],
              [14.7258, 54.0223],
              [14.7476, 54.0189],
              [14.7553, 54.0235],
              [14.7668, 54.0199],
            ],
          ],
        ],
      },
      properties: {
        name: 'Zachodniopomorskie',
        id: 'PL-32',
        NAME_ENG: 'West Pomerania',
        CNTRY: 'Poland',
        TYPE: 'Voivodeship',
        TYPE_ENG: 'Province',
      },
      id: 'PL-32',
    },
  ],
}
export default map
