import {numSpaceFormater} from "../../../../../helpers/formater";
import React from "react";
import {useIntl} from "react-intl";

interface SeoTableProps {
  dataArray: any[]
  title: string
  subtitle: string
}

const SeoTable: React.FC<SeoTableProps> = ({dataArray,title,subtitle}) => {
  const intl = useIntl()
  return (
    <>

        <div className='border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='fw-boldest text-sempai-green text-uppercase mb-0'>{title}</span>
            </h3>
            <div className='d-flex flex-stack'></div>
        </div>
        <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-2'>
                <thead>
                <tr className='fw-bolder text-muted'>
                    <th className='min-w-50px'>
                        {intl.formatMessage({id: 'BRAND'})}
                    </th>
                    <th className='min-w-100px text-start'>
                        {intl.formatMessage({id: 'ITEMS_PURCHASED'})}
                    </th>
                    <th className='min-w-100px text-start'>
                        {intl.formatMessage({id: 'USERS'})}
                    </th>
                    <th className='min-w-100px text-start'>
                        {intl.formatMessage({id: 'SESSIONS'})}
                    </th>
                    <th className='min-w-50px'>
                        {intl.formatMessage({id: 'REVENUE'})}
                    </th>
                </tr>
                </thead>
                <tbody>
                {dataArray && dataArray.map((item, index) => (
                    <tr key={index}>

                        <td>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-dark fw-bolder fs-7'>{item.itemName}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-dark fs-8'>{numSpaceFormater(item.itemsPurchased)}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-dark fs-8'>{numSpaceFormater(item.itemUsers)}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-dark fs-8'>{numSpaceFormater(Number(item.sessions).toFixed(2))}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-dark fs-8'>{numSpaceFormater(Number(item.itemRevenue).toFixed(2))} ZŁ</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    </>
  )
}

export default SeoTable
