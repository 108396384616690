import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

export function PrivateRoutes() {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const InvoicesWrapper = lazy(() => import('../pages/invoices/InvoicesWrapper'))
  const AgreementsWrapper = lazy(() => import('../pages/agreements/AgreementsWrapper'))
  const ReportsWrapper = lazy(() => import('../pages/reports/ReportsWrapper'))
  const MediaInvoicesWrapper = lazy(() => import('../pages/mediainvoices/MediaInvoicesWrapper'))


  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/invoices' component={InvoicesWrapper} />
        <Route path='/mediainvoices' component={MediaInvoicesWrapper} />
        <Route path='/agreements' component={AgreementsWrapper} />
        <Route path='/reports' component={ReportsWrapper} />
        <Route path='/crafted/account' component={AccountPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
