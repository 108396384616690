import {useIntl} from 'react-intl'
import {SEOReportDeatils} from '../../../models/SEOReport'
import TimeStatisticWidget from '../SeoWidgets/TimeStatisticWidget'
import {selectors} from '../../../redux/ReportsRedux'
import {useSelector} from 'react-redux'
import {IReportDetails} from '../../../models/Reports'
import {useEffect, useState} from 'react'
import {numSpaceFormater, percentCalc} from '../../../../../helpers/formater'

interface TimeStatisticGroupProps {}

const TimeStatisticGroup: React.FC<TimeStatisticGroupProps> = () => {
  const intl = useIntl()
  const {date} = useSelector(selectors.getReportData) as IReportDetails
  const {analyticTimeComparison} = useSelector(selectors.getReportDetails) as SEOReportDeatils
  const [currDate, setCurrDate] = useState<Date>()
  const [prevYear, setPrevYear] = useState<Date>()
  const [prevMonth, setPrevMonth] = useState<Date>()

  useEffect(() => {
    if (date) {
      setCurrDate(new Date(date))
      const curYear = new Date(date)
      const yearAgo = curYear.setFullYear(curYear.getFullYear() - 1)
      setPrevYear(new Date(yearAgo))

      const curMonth = new Date(date)
      const monthAgo = curMonth.setMonth(curMonth.getMonth() - 1)
      setPrevMonth(new Date(monthAgo))
    }
  }, [date])

  return (
    <>
      <div className='border px-3 py-5 mb-5'>
        <div className='row'>
          <div className='pb-5'>
            <h3 className='fs-3 fw-boldest text-sempai-green text-uppercase'>
              {`${intl.formatMessage({id: 'BASE_STATISTICS_COMPARE'})} ${intl.formatDate(currDate, {
                month: 'long',
                year: 'numeric',
              })} / ${intl.formatDate(prevMonth, {
                month: 'long',
                year: 'numeric',
              })}`}
            </h3>
          </div>
        </div>
        {analyticTimeComparison &&
          analyticTimeComparison.monthMinus1Month &&
          analyticTimeComparison.monthCurrent && (
            <>
              <div className='row pb-5'>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'PAGE_VIEWS'})}
                        // value={analyticTimeComparison.monthCurrent.pageviews}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.pageviews)}
                        currentValue={analyticTimeComparison.monthCurrent.pageviews}
                        prevValue={analyticTimeComparison.monthMinus1Month.pageviews}
                        type={null}
                      />
                    </div>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'USERS'})}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.users)}
                        currentValue={analyticTimeComparison.monthCurrent.users}
                        prevValue={analyticTimeComparison.monthMinus1Month.users}
                        type={null}
                      />
                    </div>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'SESSIONS'})}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.sessions)}
                        currentValue={analyticTimeComparison.monthCurrent.sessions}
                        prevValue={analyticTimeComparison.monthMinus1Month.sessions}
                        type={null}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'PER_NEW_SESSIONS'})}
                        value={Number(
                          analyticTimeComparison.monthCurrent.percentNewSessions
                        ).toFixed(1)}
                        currentValue={analyticTimeComparison.monthCurrent.percentNewSessions}
                        prevValue={analyticTimeComparison.monthMinus1Month.percentNewSessions}
                        type={null}
                      />
                    </div>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'NEW_USERS'})}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.new_users)}
                        currentValue={analyticTimeComparison.monthCurrent.new_users}
                        prevValue={analyticTimeComparison.monthMinus1Month.new_users}
                        type={null}
                      />
                    </div>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'TRANSACTION_REVENUE'})}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.transactions_revenue)}
                        currentValue={analyticTimeComparison.monthCurrent.transactions_revenue}
                        prevValue={analyticTimeComparison.monthMinus1Month.transactions_revenue}
                        type={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
      <div className='border px-3 py-5'>
        <div className='row'>
          <div className='pb-5'>
            <h3 className='fs-3 fw-boldest text-sempai-green text-uppercase'>
              {`${intl.formatMessage({id: 'BASE_STATISTICS_COMPARE'})} ${intl.formatDate(currDate, {
                month: 'long',
                year: 'numeric',
              })} / ${intl.formatDate(prevYear, {
                month: 'long',
                year: 'numeric',
              })}`}
            </h3>
          </div>
        </div>
        {analyticTimeComparison &&
          analyticTimeComparison.monthMinus12Month &&
          analyticTimeComparison.monthCurrent && (
            <>
              <div className='row pb-5'>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'PAGE_VIEWS'})}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.pageviews)}
                        currentValue={analyticTimeComparison.monthCurrent.pageviews}
                        prevValue={analyticTimeComparison.monthMinus12Month.pageviews}
                        type={null}
                      />
                    </div>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'USERS'})}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.users)}
                        currentValue={analyticTimeComparison.monthCurrent.users}
                        prevValue={analyticTimeComparison.monthMinus12Month.users}
                        type={null}
                      />
                    </div>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'SESSIONS'})}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.sessions)}
                        currentValue={analyticTimeComparison.monthCurrent.sessions}
                        prevValue={analyticTimeComparison.monthMinus12Month.sessions}
                        type={null}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'PER_NEW_SESSIONS'})}
                        value={Number(
                          analyticTimeComparison.monthCurrent.percentNewSessions
                        ).toFixed(1)}
                        currentValue={analyticTimeComparison.monthCurrent.percentNewSessions}
                        prevValue={analyticTimeComparison.monthMinus12Month.percentNewSessions}
                        type={null}
                      />
                    </div>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'NEW_USERS'})}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.new_users)}
                        currentValue={analyticTimeComparison.monthCurrent.new_users}
                        prevValue={analyticTimeComparison.monthMinus12Month.new_users}
                        type={null}
                      />
                    </div>
                    <div className='col-4'>
                      <TimeStatisticWidget
                        label={intl.formatMessage({id: 'TRANSACTION_REVENUE'})}
                        value={numSpaceFormater(analyticTimeComparison.monthCurrent.transactions_revenue)}
                        currentValue={analyticTimeComparison.monthCurrent.transactions_revenue}
                        prevValue={analyticTimeComparison.monthMinus12Month.transactions_revenue}
                        type={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='fs-5'>
                  {`
                    W porównaniu do analogicznego okresu w zeszłym roku skala kampanii (rozumiana w
                  sesjach) ${
                    percentCalc(
                      analyticTimeComparison.monthCurrent.sessions -
                        analyticTimeComparison.monthMinus12Month.sessions,
                      analyticTimeComparison.monthCurrent.sessions
                    ) > 0
                      ? 'zwiększyła'
                      : 'zmniejszyła'
                  } się o ${percentCalc(
                    analyticTimeComparison.monthCurrent.sessions -
                      analyticTimeComparison.monthMinus12Month.sessions,
                    analyticTimeComparison.monthCurrent.sessions
                  ).toFixed(2)}%. Poziom przychodów ${
                    percentCalc(
                      analyticTimeComparison.monthCurrent.transactions_revenue -
                        analyticTimeComparison.monthMinus12Month.transactions_revenue,
                      analyticTimeComparison.monthCurrent.transactions_revenue
                    ) > 0
                      ? 'wzrósł'
                      : 'spadł'
                  } o
                  ${percentCalc(
                    analyticTimeComparison.monthCurrent.transactions_revenue -
                      analyticTimeComparison.monthMinus12Month.transactions_revenue,
                    analyticTimeComparison.monthCurrent.transactions_revenue
                  ).toFixed(2)}%.
                    `}
                </div>
              </div>
            </>
          )}
      </div>
    </>
  )
}

export default TimeStatisticGroup
