import {put, takeLatest} from 'redux-saga/effects'
import {ActionWithPayload, RootState} from '../../../../setup'
import {BrandProduct, CategoryProduct, EfficiencyProduct} from '../models/SEMReports'
import {brandListSEM, categoryListSEM, efficiencyListSEM} from './ReportsCRUD'

export const GET_SEM_EFFICIENCY_LIST_REQUEST = 'GET_SEM_EFFICIENCY_LIST_REQUEST'
export const GET_SEM_EFFICIENCY_LIST_SUCCESS = 'GET_SEM_EFFICIENCY_LIST_SUCCESS'
export const GET_SEM_EFFICIENCY_LIST_FAIL = 'GET_SEM_EFFICIENCY_LIST_FAIL'

export const GET_SEM_CATEGORY_LIST_REQUEST = 'GET_SEM_CATEGORY_LIST_REQUEST'
export const GET_SEM_CATEGORY_LIST_SUCCESS = 'GET_SEM_CATEGORY_LIST_SUCCESS'
export const GET_SEM_CATEGORY_LIST_FAIL = 'GET_SEM_CATEGORY_LIST_FAIL'

export const GET_SEM_BRAND_LIST_REQUEST = 'GET_SEM_BRAND_LIST_REQUEST'
export const GET_SEM_BRAND_LIST_SUCCESS = 'GET_SEM_BRAND_LIST_SUCCESS'
export const GET_SEM_BRAND_LIST_FAIL = 'GET_SEM_BRAND_LIST_FAIL'

export interface ISEMReportListsState {
  efficiencyList?: EfficiencyProduct[]
  efficiencyLoading: boolean
  efficiencyError: boolean
  efficiencyTotalPages?: number
  efficiencyTotalItems?: number

  categoryList?: CategoryProduct[]
  categoryLoading: boolean
  categoryError: boolean
  categoryTotalPages?: number
  categoryTotalItems?: number

  brandList?: BrandProduct[]
  brandLoading: boolean
  brandError: boolean
  brandTotalPages?: number
  brandTotalItems?: number
}

const initailState: ISEMReportListsState = {
  efficiencyList: [],
  efficiencyLoading: false,
  efficiencyError: false,
  efficiencyTotalPages: 1,
  efficiencyTotalItems: 0,

  categoryList: [],
  categoryLoading: false,
  categoryError: false,
  categoryTotalPages: 1,
  categoryTotalItems: 0,

  brandList: [],
  brandLoading: false,
  brandError: false,
  brandTotalPages: 1,
  brandTotalItems: 0,
}

export const reducer = (
  state: ISEMReportListsState = initailState,
  action: ActionWithPayload<ISEMReportListsState>
) => {
  switch (action.type) {
    case GET_SEM_EFFICIENCY_LIST_REQUEST:
      return {...state, efficiencyLoading: true, efficiencyError: false}
    case GET_SEM_EFFICIENCY_LIST_SUCCESS:
      const effProductList = action.payload?.efficiencyList
      const effTotalPages = action.payload?.efficiencyTotalPages
      const effTotalItems = action.payload?.efficiencyTotalItems
      return {
        ...state,
        efficiencyLoading: false,
        efficiencyList: effProductList,
        efficiencyTotalPages: effTotalPages,
        efficiencyTotalItems: effTotalItems,
      }
    case GET_SEM_EFFICIENCY_LIST_FAIL:
      return {...state, efficiencyLoading: false, efficiencyError: true}

    case GET_SEM_CATEGORY_LIST_REQUEST:
      return {...state, categoryLoading: true, categoryError: false}
    case GET_SEM_CATEGORY_LIST_SUCCESS:
      const categoryProductList = action.payload?.categoryList
      const categoryTotalPages = action.payload?.categoryTotalPages
      const categorylItems = action.payload?.categoryTotalItems
      return {
        ...state,
        categoryLoading: false,
        categoryList: categoryProductList,
        categoryTotalPages: categoryTotalPages,
        categoryTotalItems: categorylItems,
      }
    case GET_SEM_CATEGORY_LIST_FAIL:
      return {...state, categoryLoading: false, categoryError: true}

    case GET_SEM_BRAND_LIST_REQUEST:
      return {...state, brandLoading: true, brandError: false}
    case GET_SEM_BRAND_LIST_SUCCESS:
      const brandProductList = action.payload?.brandList
      const brandTotalPages = action.payload?.brandTotalPages
      const brandlItems = action.payload?.brandTotalItems
      return {
        ...state,
        brandLoading: false,
        brandList: brandProductList,
        brandTotalPages: brandTotalPages,
        brandTotalItems: brandlItems,
      }
    case GET_SEM_BRAND_LIST_FAIL:
      return {...state, brandLoading: false, brandError: true}

    default:
      return state
  }
}

export const actions = {
  requestEfficiencyList: (
    reportId: string,
    companyId: string,
    currentPage: number,
    perPage: number,
    filters: string,
    serach?: string
  ) => ({
    type: GET_SEM_EFFICIENCY_LIST_REQUEST,
    payload: {reportId, companyId, currentPage, perPage, filters, serach},
  }),
  requestEfficiencyListSuccess: (
    productList: EfficiencyProduct[],
    totalPages: number,
    totlaItems: number
  ) => ({
    type: GET_SEM_EFFICIENCY_LIST_SUCCESS,
    payload: {
      efficiencyList: productList,
      efficiencyTotalPages: totalPages,
      efficiencyTotalItems: totlaItems,
    },
  }),
  requestEfficiencyListFail: () => ({
    type: GET_SEM_EFFICIENCY_LIST_FAIL,
  }),

  requestCategoryList: (
    reportId: string,
    companyId: string,
    currentPage: number,
    perPage: number,
    filters: string,
    serach?: string
  ) => ({
    type: GET_SEM_CATEGORY_LIST_REQUEST,
    payload: {reportId, companyId, currentPage, perPage, filters, serach},
  }),
  requestCategoryListSuccess: (
    productList: CategoryProduct[],
    totalPages: number,
    totlaItems: number
  ) => ({
    type: GET_SEM_CATEGORY_LIST_SUCCESS,
    payload: {
      categoryList: productList,
      categoryTotalPages: totalPages,
      categoryTotalItems: totlaItems,
    },
  }),
  requestCategoryListFail: () => ({
    type: GET_SEM_CATEGORY_LIST_FAIL,
  }),

  requestBrandList: (
    reportId: string,
    companyId: string,
    currentPage: number,
    perPage: number,
    filters: string,
    serach?: string
  ) => ({
    type: GET_SEM_BRAND_LIST_REQUEST,
    payload: {reportId, companyId, currentPage, perPage, filters, serach},
  }),
  requestBrandListSuccess: (
    productList: BrandProduct[],
    totalPages: number,
    totlaItems: number
  ) => ({
    type: GET_SEM_BRAND_LIST_SUCCESS,
    payload: {
      brandList: productList,
      brandTotalPages: totalPages,
      brandTotalItems: totlaItems,
    },
  }),
  requestBrandListFail: () => ({
    type: GET_SEM_BRAND_LIST_FAIL,
  }),
}

export const selectors = {
  getReportListsState: (state: RootState) => state.semReportLists,
}

interface SEMReportListWithParamAction {
  type: string
  payload: {
    reportId: string
    companyId: string
    currentPage: number
    perPage: number
    filters: string
    serach?: string
  }
}

export function* saga() {
  yield takeLatest(
    GET_SEM_EFFICIENCY_LIST_REQUEST,
    function* getEfficiencyListSaga(action: SEMReportListWithParamAction) {
      try {
        const {data} = yield efficiencyListSEM(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          action.payload.currentPage,
          action.payload.perPage,
          action.payload.filters,
          action.payload.serach
        )
        yield put(actions.requestEfficiencyListSuccess(data.items, data.lastPage, data.total))
      } catch (err) {
        yield put(actions.requestEfficiencyListFail())
      }
    }
  )

  yield takeLatest(
    GET_SEM_CATEGORY_LIST_REQUEST,
    function* getCategoryListSaga(action: SEMReportListWithParamAction) {
      try {
        const {data} = yield categoryListSEM(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          action.payload.currentPage,
          action.payload.perPage,
          action.payload.filters,
          action.payload.serach
        )
        yield put(actions.requestCategoryListSuccess(data.items, data.lastPage, data.total))
      } catch (err) {
        yield put(actions.requestCategoryListFail())
      }
    }
  )

  yield takeLatest(
    GET_SEM_BRAND_LIST_REQUEST,
    function* getBrandListSaga(action: SEMReportListWithParamAction) {
      try {
        const {data} = yield brandListSEM(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          action.payload.currentPage,
          action.payload.perPage,
          action.payload.filters,
          action.payload.serach
        )
        yield put(actions.requestBrandListSuccess(data.items, data.lastPage, data.total))
      } catch (err) {
        yield put(actions.requestBrandListFail())
      }
    }
  )
}
