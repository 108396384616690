var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [24.7835, 59.587],
              [24.7617, 59.5715],
              [24.7401, 59.585],
              [24.7835, 59.587],
            ],
          ],
          [
            [
              [24.5115, 59.6057],
              [24.5363, 59.582],
              [24.5598, 59.538],
              [24.5208, 59.5392],
              [24.494, 59.5498],
              [24.4771, 59.5685],
              [24.5115, 59.6057],
            ],
          ],
          [
            [
              [23.9978, 59.3474],
              [24.0115, 59.3238],
              [24.0089, 59.3145],
              [23.972, 59.3151],
              [23.9663, 59.3296],
              [23.9411, 59.3497],
              [23.9559, 59.3617],
              [23.9978, 59.3474],
            ],
          ],
          [
            [
              [25.8306, 59.5637],
              [25.8317, 59.5471],
              [25.796, 59.5396],
              [25.7993, 59.524],
              [25.8293, 59.5049],
              [25.8449, 59.4434],
              [25.8782, 59.4427],
              [25.8965, 59.4248],
              [25.8841, 59.4133],
              [25.897, 59.3873],
              [25.9169, 59.3762],
              [25.9343, 59.3506],
              [25.9313, 59.3413],
              [25.9421, 59.3156],
              [25.9373, 59.3102],
              [25.9541, 59.2777],
              [25.912, 59.2709],
              [25.883, 59.2882],
              [25.7723, 59.2955],
              [25.7472, 59.2826],
              [25.7027, 59.2841],
              [25.656, 59.267],
              [25.6396, 59.2545],
              [25.5896, 59.2723],
              [25.562, 59.272],
              [25.5337, 59.2617],
              [25.504, 59.2682],
              [25.4745, 59.2623],
              [25.5052, 59.246],
              [25.5123, 59.2291],
              [25.5293, 59.2132],
              [25.4932, 59.1725],
              [25.4485, 59.1423],
              [25.4585, 59.1316],
              [25.4428, 59.1126],
              [25.4862, 59.0993],
              [25.4577, 59.0679],
              [25.433, 59.0876],
              [25.4175, 59.0907],
              [25.3881, 59.0772],
              [25.4097, 59.0596],
              [25.4002, 59.0222],
              [25.3737, 59.0068],
              [25.3458, 59.0118],
              [25.3327, 58.9942],
              [25.2985, 58.9983],
              [25.2732, 59.0193],
              [25.2026, 59.0388],
              [25.2033, 59.0521],
              [25.1821, 59.0696],
              [25.2006, 59.0839],
              [25.1561, 59.0935],
              [25.1023, 59.0955],
              [25.0582, 59.1038],
              [25.0161, 59.133],
              [24.9236, 59.1553],
              [24.9226, 59.184],
              [24.9045, 59.182],
              [24.8942, 59.2237],
              [24.8658, 59.2273],
              [24.8321, 59.2213],
              [24.7926, 59.2217],
              [24.7872, 59.196],
              [24.7622, 59.2085],
              [24.7435, 59.2092],
              [24.7242, 59.2222],
              [24.702, 59.214],
              [24.689, 59.2206],
              [24.6457, 59.219],
              [24.596, 59.1882],
              [24.5673, 59.1911],
              [24.5677, 59.1767],
              [24.5563, 59.1548],
              [24.5757, 59.1416],
              [24.539, 59.1353],
              [24.5702, 59.1232],
              [24.5701, 59.0936],
              [24.493, 59.1002],
              [24.445, 59.0911],
              [24.3802, 59.0902],
              [24.349, 59.0852],
              [24.3693, 59.0574],
              [24.3251, 59.0538],
              [24.3051, 59.0383],
              [24.313, 59.0271],
              [24.2782, 59.0214],
              [24.2572, 59.0239],
              [24.2242, 59.0168],
              [24.1993, 59.0338],
              [24.1698, 59.0206],
              [24.1528, 59.0272],
              [24.1245, 59.0034],
              [24.0922, 58.9889],
              [24.0861, 59.0134],
              [24.1646, 59.0534],
              [24.1483, 59.0658],
              [24.1193, 59.0748],
              [24.1125, 59.0992],
              [24.12, 59.1147],
              [24.065, 59.1311],
              [24.0356, 59.1356],
              [23.9492, 59.1397],
              [23.91, 59.1286],
              [23.865, 59.16],
              [23.8317, 59.1745],
              [23.782, 59.1737],
              [23.7813, 59.2058],
              [23.7316, 59.2058],
              [23.73, 59.2367],
              [23.7439, 59.2521],
              [23.7381, 59.2701],
              [23.7656, 59.2691],
              [23.785, 59.2843],
              [23.8219, 59.2888],
              [23.8439, 59.284],
              [23.8811, 59.2676],
              [23.9074, 59.2791],
              [23.9507, 59.2694],
              [23.9801, 59.2786],
              [24.0367, 59.2894],
              [24.0461, 59.2836],
              [24.0816, 59.2811],
              [24.1099, 59.2847],
              [24.1185, 59.3025],
              [24.1021, 59.3268],
              [24.0658, 59.339],
              [24.0374, 59.361],
              [24.0353, 59.3883],
              [24.0768, 59.3849],
              [24.1363, 59.367],
              [24.1593, 59.355],
              [24.2013, 59.3406],
              [24.2403, 59.3436],
              [24.2476, 59.3565],
              [24.2313, 59.3767],
              [24.1933, 59.3893],
              [24.2325, 59.3935],
              [24.2603, 59.4033],
              [24.2823, 59.4047],
              [24.3398, 59.4237],
              [24.3503, 59.4367],
              [24.3479, 59.4624],
              [24.3783, 59.4727],
              [24.4261, 59.4735],
              [24.4627, 59.4689],
              [24.4937, 59.448],
              [24.5424, 59.4366],
              [24.5935, 59.4527],
              [24.6631, 59.4306],
              [24.6838, 59.448],
              [24.6524, 59.4616],
              [24.687, 59.465],
              [24.7071, 59.4812],
              [24.723, 59.4727],
              [24.715, 59.4632],
              [24.7504, 59.4474],
              [24.7912, 59.4435],
              [24.8298, 59.4714],
              [24.8377, 59.4847],
              [24.8072, 59.5046],
              [24.7968, 59.5168],
              [24.7901, 59.5412],
              [24.7991, 59.5637],
              [24.8566, 59.5478],
              [24.8825, 59.5497],
              [24.8901, 59.5307],
              [24.9131, 59.5202],
              [24.9126, 59.5014],
              [24.9332, 59.494],
              [24.9702, 59.4894],
              [25.0009, 59.4955],
              [25.0031, 59.5066],
              [25.0512, 59.4995],
              [25.0876, 59.4999],
              [25.1082, 59.4881],
              [25.1484, 59.4821],
              [25.1702, 59.4907],
              [25.1718, 59.5094],
              [25.1381, 59.5245],
              [25.1551, 59.5365],
              [25.188, 59.5242],
              [25.2013, 59.5041],
              [25.2248, 59.5038],
              [25.2663, 59.5205],
              [25.2726, 59.5105],
              [25.345, 59.4932],
              [25.3672, 59.4967],
              [25.4043, 59.4892],
              [25.4261, 59.4895],
              [25.4567, 59.498],
              [25.4578, 59.5163],
              [25.4796, 59.5203],
              [25.5294, 59.5191],
              [25.5558, 59.5422],
              [25.524, 59.5668],
              [25.5248, 59.5767],
              [25.503, 59.5897],
              [25.493, 59.6038],
              [25.4929, 59.6287],
              [25.507, 59.648],
              [25.5238, 59.6295],
              [25.5158, 59.6215],
              [25.5495, 59.608],
              [25.6042, 59.595],
              [25.6373, 59.5698],
              [25.6591, 59.5635],
              [25.6876, 59.5667],
              [25.7123, 59.6019],
              [25.6837, 59.6149],
              [25.6806, 59.6356],
              [25.7057, 59.6611],
              [25.7292, 59.6591],
              [25.7675, 59.6398],
              [25.7625, 59.627],
              [25.786, 59.6199],
              [25.771, 59.6094],
              [25.7816, 59.596],
              [25.7816, 59.5767],
              [25.7905, 59.5681],
              [25.8306, 59.5637],
            ],
          ],
          [
            [
              [23.913, 59.3466],
              [23.9445, 59.3211],
              [23.9504, 59.3086],
              [23.9364, 59.3004],
              [23.8787, 59.3318],
              [23.8752, 59.346],
              [23.913, 59.3466],
            ],
          ],
          [
            [
              [24.973, 59.6472],
              [25.0099, 59.6384],
              [25.0368, 59.6209],
              [25.0016, 59.6172],
              [24.973, 59.6472],
            ],
          ],
        ],
      },
      properties: {
        name: 'Harjumaa',
        id: 'EE-37',
        NAME_ENG: 'Harju',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-37',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [22.9995, 58.9331],
              [23.0382, 58.9199],
              [23.0323, 58.9013],
              [23.0109, 58.9103],
              [22.9995, 58.9331],
            ],
          ],
          [
            [
              [22.5927, 59.0867],
              [22.619, 59.0804],
              [22.6556, 59.0867],
              [22.696, 59.0714],
              [22.6848, 59.0442],
              [22.6922, 59.0196],
              [22.7082, 59.0074],
              [22.7335, 59.004],
              [22.7522, 59.0087],
              [22.8052, 58.9966],
              [22.8398, 59.003],
              [22.904, 58.9754],
              [22.9296, 58.9712],
              [22.9415, 58.9406],
              [22.9722, 58.9159],
              [23.0217, 58.8974],
              [23.0229, 58.8795],
              [23.0474, 58.8662],
              [23.0463, 58.8583],
              [23.069, 58.8368],
              [23.026, 58.8236],
              [23.011, 58.8395],
              [22.9677, 58.8494],
              [22.9428, 58.8419],
              [22.9191, 58.822],
              [22.8615, 58.8356],
              [22.8606, 58.8272],
              [22.824, 58.826],
              [22.79, 58.8152],
              [22.7718, 58.8188],
              [22.7481, 58.8029],
              [22.7244, 58.797],
              [22.7124, 58.7764],
              [22.7277, 58.7709],
              [22.6824, 58.7256],
              [22.6671, 58.6973],
              [22.6112, 58.6934],
              [22.5665, 58.6865],
              [22.5201, 58.6938],
              [22.4773, 58.7054],
              [22.4805, 58.7245],
              [22.5069, 58.7305],
              [22.4824, 58.7498],
              [22.4724, 58.7789],
              [22.4467, 58.7924],
              [22.4619, 58.7976],
              [22.4579, 58.8269],
              [22.4496, 58.8467],
              [22.4346, 58.8538],
              [22.4274, 58.8696],
              [22.3952, 58.8899],
              [22.3641, 58.897],
              [22.3059, 58.8866],
              [22.2842, 58.8922],
              [22.2594, 58.8818],
              [22.2099, 58.8784],
              [22.1724, 58.8813],
              [22.1398, 58.8937],
              [22.1358, 58.9064],
              [22.0873, 58.9158],
              [22.0536, 58.9184],
              [22.0536, 58.943],
              [22.086, 58.9463],
              [22.1045, 58.9397],
              [22.2038, 58.9328],
              [22.2378, 58.9346],
              [22.274, 58.9465],
              [22.3112, 58.948],
              [22.3733, 58.9319],
              [22.395, 58.9337],
              [22.4304, 58.9603],
              [22.4688, 58.9626],
              [22.4386, 58.9838],
              [22.4961, 59.0006],
              [22.4787, 59.0238],
              [22.518, 59.0224],
              [22.5305, 59.0321],
              [22.569, 59.0301],
              [22.5792, 59.0356],
              [22.578, 59.0606],
              [22.5927, 59.0867],
            ],
          ],
          [
            [
              [22.8696, 58.8261],
              [22.8666, 58.7901],
              [22.8308, 58.783],
              [22.818, 58.7706],
              [22.7977, 58.7706],
              [22.7734, 58.7849],
              [22.8082, 58.7986],
              [22.812, 58.8174],
              [22.8492, 58.8149],
              [22.8696, 58.8261],
            ],
          ],
        ],
      },
      properties: {
        name: 'Hiiumaa',
        id: 'EE-39',
        NAME_ENG: 'Hiiu',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-39',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.964, 58.8657],
            [26.9329, 58.8691],
            [26.9211, 58.8832],
            [26.8961, 58.8923],
            [26.8586, 58.8931],
            [26.8339, 58.8875],
            [26.7863, 58.8858],
            [26.7567, 58.8804],
            [26.7376, 58.9056],
            [26.7433, 58.9259],
            [26.7381, 58.9434],
            [26.708, 58.962],
            [26.7335, 58.9737],
            [26.7819, 58.9781],
            [26.8011, 58.9917],
            [26.7991, 59.0212],
            [26.8196, 59.0286],
            [26.8065, 59.0392],
            [26.8166, 59.1218],
            [26.842, 59.1238],
            [26.857, 59.1365],
            [26.894, 59.1387],
            [26.9328, 59.1363],
            [26.9466, 59.1809],
            [26.918, 59.2135],
            [26.8819, 59.2194],
            [26.8521, 59.2355],
            [26.8453, 59.2635],
            [26.7602, 59.2394],
            [26.7513, 59.2662],
            [26.7525, 59.2965],
            [26.7646, 59.3058],
            [26.7763, 59.3288],
            [26.8184, 59.3436],
            [26.7768, 59.3555],
            [26.7404, 59.3528],
            [26.7152, 59.3687],
            [26.7429, 59.3746],
            [26.7553, 59.3845],
            [26.7273, 59.4094],
            [26.7579, 59.4176],
            [26.794, 59.4067],
            [26.7856, 59.4331],
            [26.7867, 59.4621],
            [26.7597, 59.4897],
            [26.7598, 59.4996],
            [26.7826, 59.4957],
            [26.8648, 59.4636],
            [26.8958, 59.4475],
            [26.948, 59.4366],
            [27.0497, 59.433],
            [27.1395, 59.4365],
            [27.2214, 59.4433],
            [27.3774, 59.4465],
            [27.4276, 59.4412],
            [27.5942, 59.4137],
            [27.7154, 59.4126],
            [27.739, 59.4149],
            [27.7588, 59.4011],
            [27.8062, 59.3947],
            [27.9147, 59.4014],
            [27.9715, 59.4206],
            [28.0491, 59.4646],
            [28.0789, 59.4593],
            [28.1222, 59.4384],
            [28.138, 59.4195],
            [28.1976, 59.396],
            [28.2083, 59.383],
            [28.2077, 59.3633],
            [28.1893, 59.3443],
            [28.1692, 59.3342],
            [28.1569, 59.3161],
            [28.1295, 59.2905],
            [28.0582, 59.2907],
            [27.9876, 59.2739],
            [27.9598, 59.2721],
            [27.9205, 59.2525],
            [27.899, 59.233],
            [27.8881, 59.1842],
            [27.8638, 59.16],
            [27.8287, 59.1435],
            [27.8054, 59.1245],
            [27.8098, 59.1079],
            [27.7888, 59.0923],
            [27.7915, 59.0705],
            [27.7749, 59.0605],
            [27.768, 59.0326],
            [27.7469, 59.0277],
            [27.7432, 58.994],
            [27.6717, 59.0056],
            [27.6261, 59.0095],
            [27.4968, 59.0091],
            [27.3836, 59.0051],
            [27.3152, 58.9997],
            [27.2106, 58.987],
            [27.1403, 58.9718],
            [27.0224, 58.9266],
            [27.011, 58.9089],
            [26.971, 58.8878],
            [26.964, 58.8657],
          ],
        ],
      },
      properties: {
        name: 'Ida-Virumaa',
        id: 'EE-44',
        NAME_ENG: 'Ida-Viru',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-44',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.964, 58.8657],
            [26.953, 58.8203],
            [26.9653, 58.7996],
            [27.0005, 58.7809],
            [27.0442, 58.7744],
            [27.0994, 58.7488],
            [27.1308, 58.725],
            [27.1384, 58.7132],
            [27.1669, 58.6953],
            [27.1733, 58.6838],
            [27.135, 58.6769],
            [27.1263, 58.6821],
            [27.0802, 58.6712],
            [27.0426, 58.6456],
            [27.0209, 58.645],
            [27.016, 58.6218],
            [27.0313, 58.6057],
            [27.0129, 58.5931],
            [26.9989, 58.6099],
            [26.9608, 58.6066],
            [26.93, 58.6216],
            [26.8616, 58.6327],
            [26.8473, 58.5896],
            [26.8505, 58.5759],
            [26.825, 58.575],
            [26.7955, 58.5571],
            [26.773, 58.5606],
            [26.7107, 58.5427],
            [26.6842, 58.5604],
            [26.6663, 58.5614],
            [26.6468, 58.5508],
            [26.6408, 58.5222],
            [26.6094, 58.5153],
            [26.5423, 58.5464],
            [26.4971, 58.5437],
            [26.481, 58.5288],
            [26.4355, 58.5393],
            [26.4062, 58.5302],
            [26.3788, 58.5275],
            [26.342, 58.5347],
            [26.3334, 58.5256],
            [26.3073, 58.5215],
            [26.2206, 58.4875],
            [26.2027, 58.4932],
            [26.1859, 58.4867],
            [26.155, 58.4923],
            [26.1455, 58.4712],
            [26.0917, 58.5014],
            [26.0798, 58.4964],
            [26.0249, 58.5211],
            [26.0203, 58.5558],
            [25.9963, 58.5466],
            [25.9553, 58.5684],
            [25.9565, 58.5888],
            [25.9285, 58.5917],
            [25.8859, 58.6045],
            [25.8315, 58.5985],
            [25.7584, 58.6047],
            [25.7697, 58.6209],
            [25.7974, 58.6307],
            [25.7918, 58.6504],
            [25.7805, 58.66],
            [25.7803, 58.6907],
            [25.7957, 58.715],
            [25.8069, 58.7136],
            [25.8387, 58.7303],
            [25.8312, 58.7501],
            [25.8657, 58.7532],
            [25.8956, 58.7728],
            [25.9196, 58.7643],
            [25.9287, 58.7511],
            [25.9684, 58.7659],
            [25.9698, 58.7837],
            [25.9555, 58.8079],
            [25.9732, 58.8168],
            [26.0061, 58.8142],
            [26.0282, 58.8277],
            [26.0654, 58.8348],
            [26.1263, 58.8366],
            [26.1269, 58.8773],
            [26.1569, 58.8898],
            [26.2443, 58.8942],
            [26.2663, 58.8982],
            [26.2696, 58.9129],
            [26.3374, 58.9209],
            [26.3536, 58.8918],
            [26.3751, 58.8769],
            [26.4109, 58.8765],
            [26.4231, 58.886],
            [26.4498, 58.8888],
            [26.4652, 58.9041],
            [26.4564, 58.9212],
            [26.4883, 58.9437],
            [26.5283, 58.9474],
            [26.5618, 58.9327],
            [26.6225, 58.9522],
            [26.6871, 58.9667],
            [26.708, 58.962],
            [26.7381, 58.9434],
            [26.7433, 58.9259],
            [26.7376, 58.9056],
            [26.7567, 58.8804],
            [26.7863, 58.8858],
            [26.8339, 58.8875],
            [26.8586, 58.8931],
            [26.8961, 58.8923],
            [26.9211, 58.8832],
            [26.9329, 58.8691],
            [26.964, 58.8657],
          ],
        ],
      },
      properties: {
        name: 'Jõgevamaa',
        id: 'EE-49',
        NAME_ENG: 'Jõgeva',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-49',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.5337, 59.2617],
            [25.6158, 59.2271],
            [25.6473, 59.2003],
            [25.6984, 59.2],
            [25.6972, 59.2098],
            [25.7316, 59.214],
            [25.7608, 59.2259],
            [25.7934, 59.2159],
            [25.7988, 59.2],
            [25.841, 59.207],
            [25.8796, 59.1954],
            [25.9576, 59.19],
            [25.9753, 59.181],
            [26.0062, 59.1862],
            [26.0157, 59.1626],
            [25.9906, 59.1539],
            [25.9767, 59.1228],
            [25.9599, 59.1234],
            [25.936, 59.1036],
            [25.9408, 59.0903],
            [25.9313, 59.0662],
            [25.9463, 59.0561],
            [25.9742, 59.0618],
            [25.9916, 59.0507],
            [26.0261, 59.0548],
            [26.0491, 59.0444],
            [26.0604, 59.0184],
            [26.0871, 59.0141],
            [26.0925, 59.0005],
            [26.139, 58.9864],
            [26.134, 58.9731],
            [26.1481, 58.9534],
            [26.1614, 58.9507],
            [26.1563, 58.933],
            [26.1673, 58.9205],
            [26.1507, 58.8926],
            [26.1569, 58.8898],
            [26.1269, 58.8773],
            [26.1263, 58.8366],
            [26.0654, 58.8348],
            [26.0282, 58.8277],
            [26.0061, 58.8142],
            [25.9732, 58.8168],
            [25.9555, 58.8079],
            [25.9698, 58.7837],
            [25.9684, 58.7659],
            [25.9287, 58.7511],
            [25.9196, 58.7643],
            [25.8956, 58.7728],
            [25.8657, 58.7532],
            [25.8312, 58.7501],
            [25.8387, 58.7303],
            [25.8069, 58.7136],
            [25.7957, 58.715],
            [25.7803, 58.6907],
            [25.7668, 58.6824],
            [25.7358, 58.6789],
            [25.7146, 58.6709],
            [25.6584, 58.6686],
            [25.6432, 58.6498],
            [25.6081, 58.6473],
            [25.5863, 58.6346],
            [25.5513, 58.6384],
            [25.4966, 58.6257],
            [25.4907, 58.6177],
            [25.4566, 58.6061],
            [25.3978, 58.6127],
            [25.3553, 58.6064],
            [25.346, 58.6164],
            [25.3097, 58.6108],
            [25.2986, 58.6174],
            [25.2553, 58.6204],
            [25.2462, 58.6282],
            [25.258, 58.6332],
            [25.306, 58.7061],
            [25.2577, 58.72],
            [25.2012, 58.7246],
            [25.1993, 58.7339],
            [25.2306, 58.7672],
            [25.1943, 58.8011],
            [25.2217, 58.8047],
            [25.2775, 58.8619],
            [25.2508, 58.8709],
            [25.247, 58.8892],
            [25.257, 58.9074],
            [25.3023, 58.9214],
            [25.3115, 58.9576],
            [25.2866, 58.9773],
            [25.3327, 58.9942],
            [25.3458, 59.0118],
            [25.3737, 59.0068],
            [25.4002, 59.0222],
            [25.4097, 59.0596],
            [25.3881, 59.0772],
            [25.4175, 59.0907],
            [25.433, 59.0876],
            [25.4577, 59.0679],
            [25.4862, 59.0993],
            [25.4428, 59.1126],
            [25.4585, 59.1316],
            [25.4485, 59.1423],
            [25.4932, 59.1725],
            [25.5293, 59.2132],
            [25.5123, 59.2291],
            [25.5052, 59.246],
            [25.4745, 59.2623],
            [25.504, 59.2682],
            [25.5337, 59.2617],
          ],
        ],
      },
      properties: {
        name: 'Järvamaa',
        id: 'EE-51',
        NAME_ENG: 'Järva',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-51',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [23.73, 59.2367],
              [23.7316, 59.2058],
              [23.7813, 59.2058],
              [23.782, 59.1737],
              [23.8317, 59.1745],
              [23.865, 59.16],
              [23.91, 59.1286],
              [23.9492, 59.1397],
              [24.0356, 59.1356],
              [24.065, 59.1311],
              [24.12, 59.1147],
              [24.1125, 59.0992],
              [24.1193, 59.0748],
              [24.1483, 59.0658],
              [24.1646, 59.0534],
              [24.0861, 59.0134],
              [24.0922, 58.9889],
              [24.0693, 58.9684],
              [24.0908, 58.9369],
              [24.109, 58.9248],
              [24.1333, 58.9188],
              [24.1275, 58.9097],
              [24.1558, 58.8651],
              [24.1863, 58.8501],
              [24.1852, 58.8301],
              [24.1413, 58.8337],
              [24.1303, 58.8058],
              [24.1416, 58.7944],
              [24.1353, 58.7831],
              [24.0995, 58.7638],
              [24.0777, 58.7618],
              [24.0884, 58.7455],
              [24.1119, 58.7493],
              [24.1249, 58.7399],
              [24.1499, 58.7534],
              [24.1671, 58.7367],
              [24.131, 58.6993],
              [24.1182, 58.6904],
              [24.0871, 58.6818],
              [24.0398, 58.6747],
              [24.0079, 58.6739],
              [23.9958, 58.685],
              [23.9725, 58.6765],
              [23.9866, 58.648],
              [23.9871, 58.6324],
              [23.9572, 58.6246],
              [23.9441, 58.6083],
              [23.8885, 58.6252],
              [23.8686, 58.608],
              [23.8917, 58.5786],
              [23.9154, 58.5634],
              [23.8993, 58.5559],
              [23.8573, 58.552],
              [23.8207, 58.559],
              [23.8145, 58.5501],
              [23.7535, 58.5417],
              [23.7418, 58.534],
              [23.7103, 58.5478],
              [23.6673, 58.5463],
              [23.6524, 58.5382],
              [23.6273, 58.547],
              [23.6092, 58.5343],
              [23.5828, 58.5486],
              [23.5606, 58.5811],
              [23.5412, 58.56],
              [23.5097, 58.5589],
              [23.4994, 58.5694],
              [23.5186, 58.579],
              [23.5276, 58.5932],
              [23.5232, 58.6134],
              [23.5009, 58.6535],
              [23.5077, 58.6717],
              [23.4954, 58.6876],
              [23.5314, 58.6996],
              [23.5696, 58.704],
              [23.5477, 58.7209],
              [23.5289, 58.7227],
              [23.5428, 58.7387],
              [23.5829, 58.7259],
              [23.6214, 58.7334],
              [23.6512, 58.73],
              [23.6573, 58.7449],
              [23.6843, 58.7501],
              [23.7217, 58.7495],
              [23.7437, 58.7535],
              [23.7532, 58.7699],
              [23.7478, 58.7819],
              [23.7269, 58.7896],
              [23.6876, 58.7751],
              [23.6588, 58.7765],
              [23.6416, 58.7857],
              [23.5831, 58.7809],
              [23.547, 58.7689],
              [23.5151, 58.7764],
              [23.4771, 58.769],
              [23.4555, 58.7708],
              [23.4523, 58.7819],
              [23.5008, 58.8077],
              [23.5286, 58.8063],
              [23.5217, 58.8357],
              [23.4834, 58.8536],
              [23.4725, 58.873],
              [23.4507, 58.8871],
              [23.4543, 58.8985],
              [23.4227, 58.9053],
              [23.4313, 58.9228],
              [23.4246, 58.9414],
              [23.4729, 58.932],
              [23.5248, 58.951],
              [23.5518, 58.9449],
              [23.6055, 58.9608],
              [23.6253, 58.9743],
              [23.6436, 58.962],
              [23.6623, 58.9712],
              [23.6581, 58.9858],
              [23.6208, 59.0029],
              [23.5706, 59.0048],
              [23.5685, 58.9685],
              [23.5271, 58.9736],
              [23.5057, 58.9821],
              [23.4708, 58.9773],
              [23.4222, 59.0021],
              [23.4038, 59.0259],
              [23.4413, 59.0192],
              [23.4489, 59.0281],
              [23.4281, 59.0415],
              [23.4348, 59.0766],
              [23.4788, 59.0724],
              [23.5121, 59.0956],
              [23.5406, 59.0908],
              [23.5244, 59.1193],
              [23.5108, 59.1659],
              [23.4912, 59.1738],
              [23.4847, 59.1896],
              [23.49, 59.209],
              [23.5263, 59.2247],
              [23.5383, 59.2156],
              [23.5776, 59.216],
              [23.6373, 59.2345],
              [23.6608, 59.2473],
              [23.6731, 59.235],
              [23.6949, 59.2283],
              [23.73, 59.2367],
            ],
          ],
          [
            [
              [23.4386, 58.8336],
              [23.4663, 58.8232],
              [23.4406, 58.8173],
              [23.4386, 58.8336],
            ],
          ],
          [
            [
              [23.4156, 59.288],
              [23.4111, 59.2783],
              [23.3762, 59.286],
              [23.395, 59.2979],
              [23.4156, 59.288],
            ],
          ],
          [
            [
              [23.1903, 59.0417],
              [23.2203, 59.0351],
              [23.2515, 59.035],
              [23.2758, 59.0188],
              [23.2942, 59.0309],
              [23.3126, 59.0261],
              [23.3295, 59.0353],
              [23.359, 59.02],
              [23.3637, 59.007],
              [23.3872, 58.9922],
              [23.3748, 58.9739],
              [23.3489, 58.9827],
              [23.3178, 58.973],
              [23.2557, 58.9745],
              [23.2183, 58.9831],
              [23.214, 58.9695],
              [23.1905, 58.9582],
              [23.1648, 58.9588],
              [23.1691, 58.976],
              [23.118, 59.011],
              [23.1186, 59.0297],
              [23.1564, 59.0414],
              [23.1903, 59.0417],
            ],
          ],
        ],
      },
      properties: {
        name: 'Läänemaa',
        id: 'EE-57',
        NAME_ENG: 'Lääne',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-57',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.7598, 59.4996],
            [26.7597, 59.4897],
            [26.7867, 59.4621],
            [26.7856, 59.4331],
            [26.794, 59.4067],
            [26.7579, 59.4176],
            [26.7273, 59.4094],
            [26.7553, 59.3845],
            [26.7429, 59.3746],
            [26.7152, 59.3687],
            [26.7404, 59.3528],
            [26.7768, 59.3555],
            [26.8184, 59.3436],
            [26.7763, 59.3288],
            [26.7646, 59.3058],
            [26.7525, 59.2965],
            [26.7513, 59.2662],
            [26.7602, 59.2394],
            [26.8453, 59.2635],
            [26.8521, 59.2355],
            [26.8819, 59.2194],
            [26.918, 59.2135],
            [26.9466, 59.1809],
            [26.9328, 59.1363],
            [26.894, 59.1387],
            [26.857, 59.1365],
            [26.842, 59.1238],
            [26.8166, 59.1218],
            [26.8065, 59.0392],
            [26.8196, 59.0286],
            [26.7991, 59.0212],
            [26.8011, 58.9917],
            [26.7819, 58.9781],
            [26.7335, 58.9737],
            [26.708, 58.962],
            [26.6871, 58.9667],
            [26.6225, 58.9522],
            [26.5618, 58.9327],
            [26.5283, 58.9474],
            [26.4883, 58.9437],
            [26.4564, 58.9212],
            [26.4652, 58.9041],
            [26.4498, 58.8888],
            [26.4231, 58.886],
            [26.4109, 58.8765],
            [26.3751, 58.8769],
            [26.3536, 58.8918],
            [26.3374, 58.9209],
            [26.2696, 58.9129],
            [26.2663, 58.8982],
            [26.2443, 58.8942],
            [26.1569, 58.8898],
            [26.1507, 58.8926],
            [26.1673, 58.9205],
            [26.1563, 58.933],
            [26.1614, 58.9507],
            [26.1481, 58.9534],
            [26.134, 58.9731],
            [26.139, 58.9864],
            [26.0925, 59.0005],
            [26.0871, 59.0141],
            [26.0604, 59.0184],
            [26.0491, 59.0444],
            [26.0261, 59.0548],
            [25.9916, 59.0507],
            [25.9742, 59.0618],
            [25.9463, 59.0561],
            [25.9313, 59.0662],
            [25.9408, 59.0903],
            [25.936, 59.1036],
            [25.9599, 59.1234],
            [25.9767, 59.1228],
            [25.9906, 59.1539],
            [26.0157, 59.1626],
            [26.0062, 59.1862],
            [25.9753, 59.181],
            [25.9576, 59.19],
            [25.8796, 59.1954],
            [25.841, 59.207],
            [25.7988, 59.2],
            [25.7934, 59.2159],
            [25.7608, 59.2259],
            [25.7316, 59.214],
            [25.6972, 59.2098],
            [25.6984, 59.2],
            [25.6473, 59.2003],
            [25.6158, 59.2271],
            [25.5337, 59.2617],
            [25.562, 59.272],
            [25.5896, 59.2723],
            [25.6396, 59.2545],
            [25.656, 59.267],
            [25.7027, 59.2841],
            [25.7472, 59.2826],
            [25.7723, 59.2955],
            [25.883, 59.2882],
            [25.912, 59.2709],
            [25.9541, 59.2777],
            [25.9373, 59.3102],
            [25.9421, 59.3156],
            [25.9313, 59.3413],
            [25.9343, 59.3506],
            [25.9169, 59.3762],
            [25.897, 59.3873],
            [25.8841, 59.4133],
            [25.8965, 59.4248],
            [25.8782, 59.4427],
            [25.8449, 59.4434],
            [25.8293, 59.5049],
            [25.7993, 59.524],
            [25.796, 59.5396],
            [25.8317, 59.5471],
            [25.8306, 59.5637],
            [25.8701, 59.573],
            [25.8709, 59.5986],
            [25.864, 59.6121],
            [25.9088, 59.6148],
            [25.9242, 59.6012],
            [25.9106, 59.5847],
            [25.9493, 59.5772],
            [25.976, 59.5875],
            [25.9677, 59.6323],
            [25.9874, 59.6262],
            [25.9983, 59.6125],
            [26.0421, 59.6228],
            [26.0652, 59.6058],
            [26.0826, 59.6019],
            [26.0987, 59.5842],
            [26.1464, 59.5829],
            [26.2227, 59.587],
            [26.2701, 59.5847],
            [26.3267, 59.5552],
            [26.3636, 59.5565],
            [26.3742, 59.5503],
            [26.4229, 59.5474],
            [26.4346, 59.5392],
            [26.4665, 59.533],
            [26.4771, 59.5223],
            [26.5064, 59.5115],
            [26.543, 59.5229],
            [26.5734, 59.5226],
            [26.6065, 59.5527],
            [26.6499, 59.5461],
            [26.6676, 59.5363],
            [26.69, 59.5358],
            [26.7142, 59.5228],
            [26.7498, 59.514],
            [26.7598, 59.4996],
          ],
        ],
      },
      properties: {
        name: 'Lääne-Virumaa',
        id: 'EE-59',
        NAME_ENG: 'Lääne-Viru',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-59',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.4836, 58.1911],
            [27.5057, 58.1756],
            [27.5064, 58.1609],
            [27.5278, 58.1443],
            [27.5357, 58.1062],
            [27.5656, 58.1036],
            [27.577, 58.0923],
            [27.6077, 58.0847],
            [27.5977, 58.0722],
            [27.6087, 58.0529],
            [27.5913, 58.0367],
            [27.6127, 58.0117],
            [27.6405, 58.0008],
            [27.6566, 58.0056],
            [27.6926, 58.005],
            [27.6981, 57.9898],
            [27.6796, 57.973],
            [27.6863, 57.9602],
            [27.6594, 57.9544],
            [27.6857, 57.9336],
            [27.685, 57.9247],
            [27.7184, 57.9208],
            [27.7331, 57.9063],
            [27.7676, 57.8938],
            [27.7899, 57.8992],
            [27.8164, 57.8967],
            [27.8146, 57.8614],
            [27.7759, 57.8331],
            [27.7411, 57.8288],
            [27.7, 57.8382],
            [27.6248, 57.84],
            [27.5867, 57.8334],
            [27.5486, 57.8319],
            [27.504, 57.8301],
            [27.4443, 57.8388],
            [27.3967, 57.8373],
            [27.352, 57.8166],
            [27.3108, 57.8307],
            [27.3067, 57.843],
            [27.2743, 57.8437],
            [27.2803, 57.8607],
            [27.334, 57.8898],
            [27.3154, 57.9],
            [27.2947, 57.897],
            [27.2626, 57.9075],
            [27.2401, 57.9046],
            [27.189, 57.9175],
            [27.156, 57.9315],
            [27.1383, 57.9474],
            [27.1129, 57.9564],
            [27.0846, 57.9412],
            [27.0544, 57.9364],
            [27.0681, 57.9177],
            [27.0858, 57.9055],
            [26.991, 57.9141],
            [26.9736, 57.9196],
            [26.9697, 57.9402],
            [26.9211, 57.9247],
            [26.8432, 57.9249],
            [26.8098, 57.9154],
            [26.7937, 57.9268],
            [26.7295, 57.9301],
            [26.7067, 57.9405],
            [26.6811, 57.931],
            [26.6512, 57.9352],
            [26.6212, 57.9298],
            [26.6101, 57.9379],
            [26.5777, 57.9445],
            [26.5671, 57.9559],
            [26.582, 57.9774],
            [26.5681, 58.0032],
            [26.5902, 58.0207],
            [26.5702, 58.0665],
            [26.5941, 58.0866],
            [26.5918, 58.0957],
            [26.6235, 58.1209],
            [26.6572, 58.1268],
            [26.6602, 58.1592],
            [26.6523, 58.1766],
            [26.6964, 58.1776],
            [26.7189, 58.1881],
            [26.7411, 58.1883],
            [26.7511, 58.1722],
            [26.7948, 58.183],
            [26.8338, 58.1847],
            [26.8651, 58.1998],
            [26.877, 58.2331],
            [26.9065, 58.2489],
            [26.9127, 58.2354],
            [26.9796, 58.2391],
            [26.9818, 58.2252],
            [27.0253, 58.2286],
            [27.0582, 58.2634],
            [27.087, 58.2447],
            [27.1329, 58.2507],
            [27.1324, 58.2636],
            [27.1501, 58.2667],
            [27.1621, 58.2543],
            [27.241, 58.2529],
            [27.2802, 58.2447],
            [27.2719, 58.2234],
            [27.3504, 58.2147],
            [27.3913, 58.207],
            [27.4165, 58.21],
            [27.4285, 58.1947],
            [27.4836, 58.1911],
          ],
        ],
      },
      properties: {
        name: 'Põlvamaa',
        id: 'EE-65',
        NAME_ENG: 'Põlva',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-65',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [24.0009, 58.1544],
              [24.021, 58.1513],
              [24.0128, 58.1264],
              [23.9903, 58.1003],
              [23.9641, 58.0985],
              [23.9522, 58.1177],
              [23.9617, 58.1499],
              [24.0009, 58.1544],
            ],
          ],
          [
            [
              [24.131, 58.6993],
              [24.1861, 58.6746],
              [24.2335, 58.6832],
              [24.2824, 58.6713],
              [24.3518, 58.6633],
              [24.3874, 58.6616],
              [24.4284, 58.6763],
              [24.4576, 58.6807],
              [24.4714, 58.6897],
              [24.481, 58.7146],
              [24.5202, 58.7263],
              [24.5457, 58.7386],
              [24.5799, 58.7216],
              [24.5677, 58.7041],
              [24.658, 58.7239],
              [24.6835, 58.7499],
              [24.7689, 58.7319],
              [24.8182, 58.7339],
              [24.8337, 58.7462],
              [24.8625, 58.7557],
              [24.9178, 58.7324],
              [24.9058, 58.7019],
              [24.9165, 58.6699],
              [24.9446, 58.6641],
              [24.9523, 58.6827],
              [24.9901, 58.7097],
              [25.011, 58.7138],
              [25.0308, 58.7078],
              [25.077, 58.7242],
              [25.1526, 58.7184],
              [25.2012, 58.7246],
              [25.2577, 58.72],
              [25.306, 58.7061],
              [25.258, 58.6332],
              [25.2462, 58.6282],
              [25.2138, 58.6194],
              [25.2245, 58.6084],
              [25.2317, 58.5697],
              [25.2006, 58.5693],
              [25.1732, 58.5521],
              [25.176, 58.5401],
              [25.1467, 58.5359],
              [25.1213, 58.5203],
              [25.0801, 58.5166],
              [25.0708, 58.5059],
              [25.0333, 58.5031],
              [25.0291, 58.4887],
              [25.0096, 58.4829],
              [25.0152, 58.4337],
              [24.9969, 58.4208],
              [24.9867, 58.3968],
              [24.9711, 58.3425],
              [24.9798, 58.3218],
              [25.0389, 58.3287],
              [25.0875, 58.3197],
              [25.1007, 58.3111],
              [25.1338, 58.3194],
              [25.1506, 58.314],
              [25.1754, 58.2849],
              [25.2618, 58.2774],
              [25.2657, 58.2561],
              [25.2423, 58.2475],
              [25.2547, 58.2234],
              [25.2543, 58.2039],
              [25.2267, 58.2048],
              [25.1982, 58.1935],
              [25.17, 58.1964],
              [25.1613, 58.1743],
              [25.1157, 58.1625],
              [25.1043, 58.1418],
              [25.1236, 58.1196],
              [25.0566, 58.1203],
              [25.0638, 58.1034],
              [25.0233, 58.0914],
              [25.0131, 58.0729],
              [25.0282, 58.0686],
              [25.0333, 58.0476],
              [25.0444, 58.0404],
              [25.0201, 58.0204],
              [24.9931, 58.011],
              [24.9519, 58.0126],
              [24.8483, 57.9762],
              [24.8308, 57.9737],
              [24.8085, 57.9911],
              [24.7425, 57.9833],
              [24.7415, 57.9656],
              [24.7133, 57.9622],
              [24.6915, 57.948],
              [24.6734, 57.9599],
              [24.6433, 57.9575],
              [24.6278, 57.9408],
              [24.5845, 57.9608],
              [24.5419, 57.9444],
              [24.5223, 57.9453],
              [24.51, 57.9298],
              [24.4619, 57.9237],
              [24.4459, 57.9092],
              [24.4598, 57.8785],
              [24.4129, 57.8647],
              [24.4056, 57.8708],
              [24.3557, 57.8734],
              [24.3598, 57.8934],
              [24.3865, 57.9219],
              [24.3883, 57.9493],
              [24.4209, 57.9983],
              [24.4443, 58.008],
              [24.4484, 58.0245],
              [24.4801, 58.0713],
              [24.4769, 58.0936],
              [24.4656, 58.1013],
              [24.4777, 58.117],
              [24.4717, 58.1421],
              [24.4785, 58.1485],
              [24.4522, 58.172],
              [24.4668, 58.201],
              [24.4625, 58.2115],
              [24.4785, 58.2203],
              [24.4792, 58.2404],
              [24.4893, 58.2567],
              [24.5515, 58.2757],
              [24.584, 58.2978],
              [24.5796, 58.3309],
              [24.5322, 58.3662],
              [24.4885, 58.3732],
              [24.4818, 58.3809],
              [24.4149, 58.3873],
              [24.3316, 58.3839],
              [24.3009, 58.3744],
              [24.2924, 58.3634],
              [24.2903, 58.3254],
              [24.2648, 58.275],
              [24.1978, 58.27],
              [24.1713, 58.2706],
              [24.1528, 58.2533],
              [24.1125, 58.2396],
              [24.0726, 58.2508],
              [24.0409, 58.2937],
              [23.9123, 58.33],
              [23.9077, 58.3147],
              [23.8771, 58.3218],
              [23.8531, 58.3482],
              [23.8268, 58.3564],
              [23.8168, 58.337],
              [23.7991, 58.3451],
              [23.7378, 58.3481],
              [23.7434, 58.3606],
              [23.7159, 58.3852],
              [23.7064, 58.4092],
              [23.69, 58.4225],
              [23.6776, 58.4458],
              [23.6833, 58.4514],
              [23.6811, 58.4907],
              [23.6908, 58.5053],
              [23.675, 58.5228],
              [23.6405, 58.5177],
              [23.6092, 58.5343],
              [23.6273, 58.547],
              [23.6524, 58.5382],
              [23.6673, 58.5463],
              [23.7103, 58.5478],
              [23.7418, 58.534],
              [23.7535, 58.5417],
              [23.8145, 58.5501],
              [23.8207, 58.559],
              [23.8573, 58.552],
              [23.8993, 58.5559],
              [23.9154, 58.5634],
              [23.8917, 58.5786],
              [23.8686, 58.608],
              [23.8885, 58.6252],
              [23.9441, 58.6083],
              [23.9572, 58.6246],
              [23.9871, 58.6324],
              [23.9866, 58.648],
              [23.9725, 58.6765],
              [23.9958, 58.685],
              [24.0079, 58.6739],
              [24.0398, 58.6747],
              [24.0871, 58.6818],
              [24.1182, 58.6904],
              [24.131, 58.6993],
            ],
          ],
        ],
      },
      properties: {
        name: 'Pärnumaa',
        id: 'EE-67',
        NAME_ENG: 'Pärnu',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-67',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.0922, 58.9889],
            [24.1245, 59.0034],
            [24.1528, 59.0272],
            [24.1698, 59.0206],
            [24.1993, 59.0338],
            [24.2242, 59.0168],
            [24.2572, 59.0239],
            [24.2782, 59.0214],
            [24.313, 59.0271],
            [24.3051, 59.0383],
            [24.3251, 59.0538],
            [24.3693, 59.0574],
            [24.349, 59.0852],
            [24.3802, 59.0902],
            [24.445, 59.0911],
            [24.493, 59.1002],
            [24.5701, 59.0936],
            [24.5702, 59.1232],
            [24.539, 59.1353],
            [24.5757, 59.1416],
            [24.5563, 59.1548],
            [24.5677, 59.1767],
            [24.5673, 59.1911],
            [24.596, 59.1882],
            [24.6457, 59.219],
            [24.689, 59.2206],
            [24.702, 59.214],
            [24.7242, 59.2222],
            [24.7435, 59.2092],
            [24.7622, 59.2085],
            [24.7872, 59.196],
            [24.7926, 59.2217],
            [24.8321, 59.2213],
            [24.8658, 59.2273],
            [24.8942, 59.2237],
            [24.9045, 59.182],
            [24.9226, 59.184],
            [24.9236, 59.1553],
            [25.0161, 59.133],
            [25.0582, 59.1038],
            [25.1023, 59.0955],
            [25.1561, 59.0935],
            [25.2006, 59.0839],
            [25.1821, 59.0696],
            [25.2033, 59.0521],
            [25.2026, 59.0388],
            [25.2732, 59.0193],
            [25.2985, 58.9983],
            [25.3327, 58.9942],
            [25.2866, 58.9773],
            [25.3115, 58.9576],
            [25.3023, 58.9214],
            [25.257, 58.9074],
            [25.247, 58.8892],
            [25.2508, 58.8709],
            [25.2775, 58.8619],
            [25.2217, 58.8047],
            [25.1943, 58.8011],
            [25.2306, 58.7672],
            [25.1993, 58.7339],
            [25.2012, 58.7246],
            [25.1526, 58.7184],
            [25.077, 58.7242],
            [25.0308, 58.7078],
            [25.011, 58.7138],
            [24.9901, 58.7097],
            [24.9523, 58.6827],
            [24.9446, 58.6641],
            [24.9165, 58.6699],
            [24.9058, 58.7019],
            [24.9178, 58.7324],
            [24.8625, 58.7557],
            [24.8337, 58.7462],
            [24.8182, 58.7339],
            [24.7689, 58.7319],
            [24.6835, 58.7499],
            [24.658, 58.7239],
            [24.5677, 58.7041],
            [24.5799, 58.7216],
            [24.5457, 58.7386],
            [24.5202, 58.7263],
            [24.481, 58.7146],
            [24.4714, 58.6897],
            [24.4576, 58.6807],
            [24.4284, 58.6763],
            [24.3874, 58.6616],
            [24.3518, 58.6633],
            [24.2824, 58.6713],
            [24.2335, 58.6832],
            [24.1861, 58.6746],
            [24.131, 58.6993],
            [24.1671, 58.7367],
            [24.1499, 58.7534],
            [24.1249, 58.7399],
            [24.1119, 58.7493],
            [24.0884, 58.7455],
            [24.0777, 58.7618],
            [24.0995, 58.7638],
            [24.1353, 58.7831],
            [24.1416, 58.7944],
            [24.1303, 58.8058],
            [24.1413, 58.8337],
            [24.1852, 58.8301],
            [24.1863, 58.8501],
            [24.1558, 58.8651],
            [24.1275, 58.9097],
            [24.1333, 58.9188],
            [24.109, 58.9248],
            [24.0908, 58.9369],
            [24.0693, 58.9684],
            [24.0922, 58.9889],
          ],
        ],
      },
      properties: {
        name: 'Raplamaa',
        id: 'EE-70',
        NAME_ENG: 'Rapla',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-70',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [23.2332, 57.8214],
              [23.2698, 57.8049],
              [23.2664, 57.7816],
              [23.2128, 57.7964],
              [23.2115, 57.8074],
              [23.2332, 57.8214],
            ],
          ],
          [
            [
              [23.1948, 58.6832],
              [23.2716, 58.664],
              [23.3209, 58.649],
              [23.3457, 58.6475],
              [23.3622, 58.628],
              [23.3437, 58.6178],
              [23.3451, 58.6079],
              [23.3907, 58.5731],
              [23.4057, 58.55],
              [23.3987, 58.5383],
              [23.3763, 58.5329],
              [23.3367, 58.5347],
              [23.3305, 58.5491],
              [23.3042, 58.5314],
              [23.2599, 58.5484],
              [23.2419, 58.5246],
              [23.2179, 58.5253],
              [23.2178, 58.5403],
              [23.1932, 58.5438],
              [23.1769, 58.562],
              [23.1522, 58.5788],
              [23.1274, 58.573],
              [23.0851, 58.5851],
              [23.0685, 58.597],
              [23.0817, 58.6208],
              [23.098, 58.6262],
              [23.1224, 58.6192],
              [23.1461, 58.6277],
              [23.1369, 58.6393],
              [23.1408, 58.6721],
              [23.1948, 58.6832],
            ],
          ],
          [
            [
              [23.3744, 58.5255],
              [23.3895, 58.5105],
              [23.3523, 58.5091],
              [23.3744, 58.5255],
            ],
          ],
          [
            [
              [23.0202, 58.6456],
              [23.0445, 58.625],
              [23.0085, 58.6301],
              [23.0202, 58.6456],
            ],
          ],
          [
            [
              [22.5033, 58.1773],
              [22.5106, 58.1753],
              [22.532, 58.1414],
              [22.5043, 58.1252],
              [22.4835, 58.1416],
              [22.5033, 58.1773],
            ],
          ],
          [
            [
              [21.9103, 58.3904],
              [21.9118, 58.3823],
              [21.8651, 58.3706],
              [21.831, 58.3727],
              [21.8058, 58.3821],
              [21.8298, 58.3898],
              [21.9103, 58.3904],
            ],
          ],
          [
            [
              [22.538, 58.6295],
              [22.5732, 58.634],
              [22.6206, 58.5865],
              [22.6513, 58.5776],
              [22.677, 58.5776],
              [22.7039, 58.5971],
              [22.7266, 58.5793],
              [22.7513, 58.5722],
              [22.7631, 58.5823],
              [22.7743, 58.6126],
              [22.7965, 58.6243],
              [22.845, 58.6143],
              [22.877, 58.6243],
              [22.9006, 58.6145],
              [22.9408, 58.6163],
              [22.9589, 58.6071],
              [23.0007, 58.598],
              [23.0703, 58.5615],
              [23.135, 58.5525],
              [23.1281, 58.5319],
              [23.139, 58.5184],
              [23.1858, 58.5128],
              [23.2263, 58.4963],
              [23.256, 58.4939],
              [23.3193, 58.4584],
              [23.3238, 58.4459],
              [23.284, 58.4465],
              [23.2813, 58.4724],
              [23.2644, 58.4677],
              [23.2617, 58.4555],
              [23.2279, 58.4523],
              [23.2608, 58.4335],
              [23.2479, 58.424],
              [23.2126, 58.4403],
              [23.194, 58.4554],
              [23.1657, 58.4683],
              [23.1434, 58.4607],
              [23.1538, 58.4414],
              [23.134, 58.4398],
              [23.1321, 58.4275],
              [23.1106, 58.4126],
              [23.0984, 58.427],
              [23.0763, 58.4259],
              [23.064, 58.4104],
              [23.0772, 58.3944],
              [23.0453, 58.3905],
              [23.0342, 58.3789],
              [23.0412, 58.3687],
              [23.0061, 58.3585],
              [22.9851, 58.3643],
              [22.9671, 58.3793],
              [22.9475, 58.3742],
              [22.9695, 58.349],
              [22.9479, 58.3478],
              [22.9212, 58.3154],
              [22.9141, 58.299],
              [22.8809, 58.3007],
              [22.8726, 58.3115],
              [22.8271, 58.2865],
              [22.8262, 58.2641],
              [22.7904, 58.2715],
              [22.7586, 58.2704],
              [22.7344, 58.2799],
              [22.7126, 58.2779],
              [22.7007, 58.2623],
              [22.7332, 58.2397],
              [22.7485, 58.2209],
              [22.7108, 58.2095],
              [22.6794, 58.2169],
              [22.6611, 58.2287],
              [22.6369, 58.2301],
              [22.6556, 58.2461],
              [22.6467, 58.2521],
              [22.6126, 58.2458],
              [22.6158, 58.2318],
              [22.5981, 58.2216],
              [22.5859, 58.235],
              [22.5558, 58.2184],
              [22.5304, 58.2475],
              [22.5169, 58.2246],
              [22.4915, 58.2347],
              [22.4541, 58.2396],
              [22.4469, 58.2231],
              [22.4268, 58.2159],
              [22.3932, 58.2217],
              [22.3541, 58.2184],
              [22.3057, 58.2054],
              [22.2702, 58.1884],
              [22.2566, 58.1741],
              [22.2527, 58.1489],
              [22.2657, 58.13],
              [22.2567, 58.124],
              [22.2442, 58.0943],
              [22.2202, 58.0646],
              [22.2279, 58.0523],
              [22.2036, 58.0339],
              [22.1841, 58.0103],
              [22.2033, 57.9806],
              [22.1701, 57.9647],
              [22.1524, 57.9649],
              [22.1083, 57.9405],
              [22.0954, 57.9287],
              [22.0556, 57.9138],
              [22.02, 57.9149],
              [22.0048, 57.9421],
              [21.9831, 57.9518],
              [21.9832, 57.9749],
              [22.0185, 57.9804],
              [22.02, 58.0013],
              [22.0424, 58.0057],
              [22.0373, 58.0226],
              [22.0569, 58.0277],
              [22.0683, 58.063],
              [22.0777, 58.0737],
              [22.1082, 58.084],
              [22.1274, 58.0792],
              [22.1349, 58.0666],
              [22.1652, 58.0648],
              [22.1721, 58.0805],
              [22.168, 58.0954],
              [22.1961, 58.1276],
              [22.2223, 58.1348],
              [22.2153, 58.1546],
              [22.1439, 58.1602],
              [22.1445, 58.1705],
              [22.1001, 58.1799],
              [22.0732, 58.1805],
              [22.0409, 58.2022],
              [22.0429, 58.2183],
              [22.0036, 58.2471],
              [21.9723, 58.2349],
              [21.9411, 58.245],
              [21.9213, 58.2444],
              [21.8907, 58.258],
              [21.8654, 58.2612],
              [21.8318, 58.2798],
              [21.844, 58.2902],
              [21.828, 58.3059],
              [21.8463, 58.3113],
              [21.8838, 58.2965],
              [21.9076, 58.3064],
              [21.882, 58.3226],
              [21.8937, 58.3409],
              [21.9198, 58.3287],
              [21.9291, 58.3081],
              [21.9576, 58.3202],
              [21.9599, 58.3445],
              [21.9989, 58.343],
              [21.9826, 58.3615],
              [21.9943, 58.3769],
              [21.9885, 58.397],
              [21.9658, 58.4018],
              [21.9387, 58.4195],
              [21.9479, 58.4256],
              [21.9093, 58.4498],
              [21.903, 58.4684],
              [21.8772, 58.4729],
              [21.8646, 58.4654],
              [21.8405, 58.4771],
              [21.8511, 58.4939],
              [21.8757, 58.4784],
              [21.9168, 58.4831],
              [21.9215, 58.497],
              [21.9087, 58.5035],
              [21.9166, 58.5167],
              [21.964, 58.5053],
              [21.9924, 58.5087],
              [22.0201, 58.499],
              [22.0549, 58.4465],
              [22.0768, 58.419],
              [22.1057, 58.4165],
              [22.1172, 58.4238],
              [22.1115, 58.4563],
              [22.1172, 58.4803],
              [22.1364, 58.4971],
              [22.1783, 58.512],
              [22.2006, 58.5135],
              [22.1958, 58.5333],
              [22.218, 58.5454],
              [22.2358, 58.5344],
              [22.238, 58.4975],
              [22.2461, 58.4877],
              [22.2879, 58.4849],
              [22.2983, 58.49],
              [22.3137, 58.5197],
              [22.3053, 58.5368],
              [22.2867, 58.5476],
              [22.2822, 58.5622],
              [22.313, 58.5644],
              [22.3611, 58.5768],
              [22.3886, 58.5725],
              [22.4737, 58.5806],
              [22.4984, 58.5908],
              [22.538, 58.6295],
            ],
          ],
        ],
      },
      properties: {
        name: 'Saaremaa',
        id: 'EE-74',
        NAME_ENG: 'Saare',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-74',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [27.1733, 58.6838],
              [27.172, 58.6618],
              [27.1856, 58.6384],
              [27.2169, 58.6076],
              [27.2226, 58.5681],
              [27.2483, 58.525],
              [27.254, 58.4806],
              [27.2554, 58.4414],
              [27.2483, 58.4197],
              [27.2747, 58.3946],
              [27.2939, 58.3909],
              [27.3146, 58.4088],
              [27.3617, 58.4029],
              [27.4251, 58.3729],
              [27.413, 58.3553],
              [27.428, 58.3455],
              [27.4387, 58.3139],
              [27.418, 58.297],
              [27.4694, 58.2606],
              [27.4822, 58.2388],
              [27.4736, 58.2294],
              [27.4843, 58.2079],
              [27.4836, 58.1911],
              [27.4285, 58.1947],
              [27.4165, 58.21],
              [27.3913, 58.207],
              [27.3504, 58.2147],
              [27.2719, 58.2234],
              [27.2802, 58.2447],
              [27.241, 58.2529],
              [27.1621, 58.2543],
              [27.1501, 58.2667],
              [27.1324, 58.2636],
              [27.1329, 58.2507],
              [27.087, 58.2447],
              [27.0582, 58.2634],
              [27.0253, 58.2286],
              [26.9818, 58.2252],
              [26.9796, 58.2391],
              [26.9127, 58.2354],
              [26.9065, 58.2489],
              [26.877, 58.2331],
              [26.8651, 58.1998],
              [26.8338, 58.1847],
              [26.7948, 58.183],
              [26.7511, 58.1722],
              [26.7411, 58.1883],
              [26.7189, 58.1881],
              [26.6964, 58.1776],
              [26.6523, 58.1766],
              [26.6602, 58.1592],
              [26.6572, 58.1268],
              [26.6235, 58.1209],
              [26.6022, 58.1251],
              [26.602, 58.1386],
              [26.5693, 58.1448],
              [26.5578, 58.1617],
              [26.5195, 58.1649],
              [26.4928, 58.1623],
              [26.4378, 58.141],
              [26.4166, 58.1503],
              [26.4084, 58.1656],
              [26.3695, 58.1712],
              [26.3305, 58.131],
              [26.2864, 58.1358],
              [26.2729, 58.1125],
              [26.2108, 58.1134],
              [26.151, 58.1172],
              [26.1181, 58.1137],
              [26.0715, 58.1235],
              [26.0479, 58.3308],
              [26.1712, 58.4081],
              [26.1884, 58.4066],
              [26.1654, 58.4499],
              [26.1455, 58.4712],
              [26.155, 58.4923],
              [26.1859, 58.4867],
              [26.2027, 58.4932],
              [26.2206, 58.4875],
              [26.3073, 58.5215],
              [26.3334, 58.5256],
              [26.342, 58.5347],
              [26.3788, 58.5275],
              [26.4062, 58.5302],
              [26.4355, 58.5393],
              [26.481, 58.5288],
              [26.4971, 58.5437],
              [26.5423, 58.5464],
              [26.6094, 58.5153],
              [26.6408, 58.5222],
              [26.6468, 58.5508],
              [26.6663, 58.5614],
              [26.6842, 58.5604],
              [26.7107, 58.5427],
              [26.773, 58.5606],
              [26.7955, 58.5571],
              [26.825, 58.575],
              [26.8505, 58.5759],
              [26.8473, 58.5896],
              [26.8616, 58.6327],
              [26.93, 58.6216],
              [26.9608, 58.6066],
              [26.9989, 58.6099],
              [27.0129, 58.5931],
              [27.0313, 58.6057],
              [27.016, 58.6218],
              [27.0209, 58.645],
              [27.0426, 58.6456],
              [27.0802, 58.6712],
              [27.1263, 58.6821],
              [27.135, 58.6769],
              [27.1733, 58.6838],
            ],
          ],
          [
            [
              [27.5172, 58.367],
              [27.4953, 58.366],
              [27.4801, 58.38],
              [27.4901, 58.392],
              [27.5276, 58.3892],
              [27.5376, 58.3695],
              [27.5172, 58.367],
            ],
          ],
        ],
      },
      properties: {
        name: 'Tartumaa',
        id: 'EE-78',
        NAME_ENG: 'Tartu',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-78',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.0715, 58.1235],
            [26.1181, 58.1137],
            [26.151, 58.1172],
            [26.2108, 58.1134],
            [26.2729, 58.1125],
            [26.2864, 58.1358],
            [26.3305, 58.131],
            [26.3695, 58.1712],
            [26.4084, 58.1656],
            [26.4166, 58.1503],
            [26.4378, 58.141],
            [26.4928, 58.1623],
            [26.5195, 58.1649],
            [26.5578, 58.1617],
            [26.5693, 58.1448],
            [26.602, 58.1386],
            [26.6022, 58.1251],
            [26.6235, 58.1209],
            [26.5918, 58.0957],
            [26.5941, 58.0866],
            [26.5702, 58.0665],
            [26.5902, 58.0207],
            [26.5681, 58.0032],
            [26.582, 57.9774],
            [26.5671, 57.9559],
            [26.5525, 57.9511],
            [26.5126, 57.9496],
            [26.4981, 57.954],
            [26.4732, 57.9414],
            [26.4309, 57.9343],
            [26.4073, 57.9399],
            [26.3962, 57.9277],
            [26.4126, 57.9205],
            [26.416, 57.9022],
            [26.3979, 57.8935],
            [26.3959, 57.8668],
            [26.4189, 57.8662],
            [26.4361, 57.8524],
            [26.4142, 57.8445],
            [26.4294, 57.816],
            [26.4201, 57.8029],
            [26.439, 57.7809],
            [26.4762, 57.7903],
            [26.4967, 57.7851],
            [26.4961, 57.7734],
            [26.4765, 57.745],
            [26.4499, 57.7371],
            [26.4709, 57.7215],
            [26.4712, 57.7113],
            [26.5005, 57.7068],
            [26.5051, 57.694],
            [26.4697, 57.6744],
            [26.4423, 57.6507],
            [26.4984, 57.6255],
            [26.4679, 57.5953],
            [26.4879, 57.5919],
            [26.4704, 57.5759],
            [26.4032, 57.5726],
            [26.3466, 57.5838],
            [26.3277, 57.5792],
            [26.312, 57.5932],
            [26.2891, 57.5921],
            [26.2476, 57.6191],
            [26.242, 57.6584],
            [26.2171, 57.6654],
            [26.214, 57.6795],
            [26.1909, 57.6941],
            [26.2022, 57.7134],
            [26.1732, 57.7282],
            [26.1456, 57.7315],
            [26.1365, 57.7494],
            [26.0797, 57.7652],
            [26.058, 57.7612],
            [26.027, 57.7749],
            [26.0361, 57.81],
            [26.0534, 57.8294],
            [26.0568, 57.848],
            [26.0239, 57.847],
            [26.0018, 57.8601],
            [25.9632, 57.8455],
            [25.933, 57.8528],
            [25.8989, 57.8515],
            [25.8195, 57.8635],
            [25.7791, 57.9116],
            [25.738, 57.9226],
            [25.7065, 57.9038],
            [25.6804, 57.912],
            [25.6485, 57.9158],
            [25.6323, 57.9321],
            [25.5852, 57.9438],
            [25.5952, 57.9582],
            [25.6318, 57.9981],
            [25.6089, 58.0276],
            [25.6171, 58.0332],
            [25.6774, 58.0332],
            [25.7031, 58.0293],
            [25.7532, 58.0635],
            [25.7427, 58.0705],
            [25.8003, 58.0755],
            [25.8051, 58.0843],
            [25.8295, 58.0885],
            [25.8546, 58.0996],
            [25.8492, 58.1187],
            [25.8634, 58.1287],
            [25.8708, 58.147],
            [25.9021, 58.1361],
            [25.9204, 58.1184],
            [25.9248, 58.0996],
            [25.954, 58.0972],
            [25.9794, 58.1097],
            [26.0182, 58.1149],
            [26.026, 58.1232],
            [26.0715, 58.1235],
          ],
        ],
      },
      properties: {
        name: 'Valgamaa',
        id: 'EE-82',
        NAME_ENG: 'Valga',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-82',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.7803, 58.6907],
            [25.7805, 58.66],
            [25.7918, 58.6504],
            [25.7974, 58.6307],
            [25.7697, 58.6209],
            [25.7584, 58.6047],
            [25.8315, 58.5985],
            [25.8859, 58.6045],
            [25.9285, 58.5917],
            [25.9565, 58.5888],
            [25.9553, 58.5684],
            [25.9963, 58.5466],
            [26.0203, 58.5558],
            [26.0249, 58.5211],
            [26.0798, 58.4964],
            [26.0917, 58.5014],
            [26.1455, 58.4712],
            [26.1654, 58.4499],
            [26.1884, 58.4066],
            [26.1712, 58.4081],
            [26.0479, 58.3308],
            [26.0715, 58.1235],
            [26.026, 58.1232],
            [26.0182, 58.1149],
            [25.9794, 58.1097],
            [25.954, 58.0972],
            [25.9248, 58.0996],
            [25.9204, 58.1184],
            [25.9021, 58.1361],
            [25.8708, 58.147],
            [25.8634, 58.1287],
            [25.8492, 58.1187],
            [25.8546, 58.0996],
            [25.8295, 58.0885],
            [25.8051, 58.0843],
            [25.8003, 58.0755],
            [25.7427, 58.0705],
            [25.7532, 58.0635],
            [25.7031, 58.0293],
            [25.6774, 58.0332],
            [25.6171, 58.0332],
            [25.6089, 58.0276],
            [25.6318, 57.9981],
            [25.5952, 57.9582],
            [25.5799, 57.9732],
            [25.5286, 57.9704],
            [25.5204, 57.9812],
            [25.485, 57.9768],
            [25.4439, 57.9956],
            [25.4657, 58.0049],
            [25.4522, 58.0146],
            [25.41, 58.0298],
            [25.365, 58.031],
            [25.3359, 58.0594],
            [25.2911, 58.0834],
            [25.2636, 58.0641],
            [25.2944, 58.0459],
            [25.3049, 58.0332],
            [25.2969, 58.0147],
            [25.3012, 57.9958],
            [25.2589, 57.9951],
            [25.2241, 58.0229],
            [25.2122, 58.063],
            [25.2125, 58.0799],
            [25.189, 58.0759],
            [25.1325, 58.0803],
            [25.1061, 58.0775],
            [25.1025, 58.0663],
            [25.0798, 58.066],
            [25.0444, 58.0404],
            [25.0333, 58.0476],
            [25.0282, 58.0686],
            [25.0131, 58.0729],
            [25.0233, 58.0914],
            [25.0638, 58.1034],
            [25.0566, 58.1203],
            [25.1236, 58.1196],
            [25.1043, 58.1418],
            [25.1157, 58.1625],
            [25.1613, 58.1743],
            [25.17, 58.1964],
            [25.1982, 58.1935],
            [25.2267, 58.2048],
            [25.2543, 58.2039],
            [25.2547, 58.2234],
            [25.2423, 58.2475],
            [25.2657, 58.2561],
            [25.2618, 58.2774],
            [25.1754, 58.2849],
            [25.1506, 58.314],
            [25.1338, 58.3194],
            [25.1007, 58.3111],
            [25.0875, 58.3197],
            [25.0389, 58.3287],
            [24.9798, 58.3218],
            [24.9711, 58.3425],
            [24.9867, 58.3968],
            [24.9969, 58.4208],
            [25.0152, 58.4337],
            [25.0096, 58.4829],
            [25.0291, 58.4887],
            [25.0333, 58.5031],
            [25.0708, 58.5059],
            [25.0801, 58.5166],
            [25.1213, 58.5203],
            [25.1467, 58.5359],
            [25.176, 58.5401],
            [25.1732, 58.5521],
            [25.2006, 58.5693],
            [25.2317, 58.5697],
            [25.2245, 58.6084],
            [25.2138, 58.6194],
            [25.2462, 58.6282],
            [25.2553, 58.6204],
            [25.2986, 58.6174],
            [25.3097, 58.6108],
            [25.346, 58.6164],
            [25.3553, 58.6064],
            [25.3978, 58.6127],
            [25.4566, 58.6061],
            [25.4907, 58.6177],
            [25.4966, 58.6257],
            [25.5513, 58.6384],
            [25.5863, 58.6346],
            [25.6081, 58.6473],
            [25.6432, 58.6498],
            [25.6584, 58.6686],
            [25.7146, 58.6709],
            [25.7358, 58.6789],
            [25.7668, 58.6824],
            [25.7803, 58.6907],
          ],
        ],
      },
      properties: {
        name: 'Viljandimaa',
        id: 'EE-84',
        NAME_ENG: 'Viljandi',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-84',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.5671, 57.9559],
            [26.5777, 57.9445],
            [26.6101, 57.9379],
            [26.6212, 57.9298],
            [26.6512, 57.9352],
            [26.6811, 57.931],
            [26.7067, 57.9405],
            [26.7295, 57.9301],
            [26.7937, 57.9268],
            [26.8098, 57.9154],
            [26.8432, 57.9249],
            [26.9211, 57.9247],
            [26.9697, 57.9402],
            [26.9736, 57.9196],
            [26.991, 57.9141],
            [27.0858, 57.9055],
            [27.0681, 57.9177],
            [27.0544, 57.9364],
            [27.0846, 57.9412],
            [27.1129, 57.9564],
            [27.1383, 57.9474],
            [27.156, 57.9315],
            [27.189, 57.9175],
            [27.2401, 57.9046],
            [27.2626, 57.9075],
            [27.2947, 57.897],
            [27.3154, 57.9],
            [27.334, 57.8898],
            [27.2803, 57.8607],
            [27.2743, 57.8437],
            [27.3067, 57.843],
            [27.3108, 57.8307],
            [27.352, 57.8166],
            [27.3967, 57.8373],
            [27.4443, 57.8388],
            [27.504, 57.8301],
            [27.5486, 57.8319],
            [27.559, 57.8297],
            [27.5372, 57.7923],
            [27.507, 57.7839],
            [27.5098, 57.7649],
            [27.5297, 57.7561],
            [27.5249, 57.7091],
            [27.4996, 57.7064],
            [27.4882, 57.7195],
            [27.4683, 57.7086],
            [27.4499, 57.716],
            [27.3838, 57.6763],
            [27.3752, 57.6653],
            [27.3813, 57.6493],
            [27.4077, 57.6134],
            [27.355, 57.5968],
            [27.3254, 57.578],
            [27.3381, 57.5471],
            [27.3499, 57.5351],
            [27.3472, 57.523],
            [27.326, 57.5235],
            [27.2646, 57.5495],
            [27.2296, 57.5539],
            [27.1979, 57.5516],
            [27.1647, 57.5591],
            [27.1247, 57.5611],
            [27.0862, 57.5769],
            [27.0469, 57.5816],
            [27.0443, 57.5972],
            [27.0315, 57.6047],
            [26.948, 57.6018],
            [26.9259, 57.6325],
            [26.8656, 57.6154],
            [26.86, 57.5926],
            [26.836, 57.5827],
            [26.8035, 57.5818],
            [26.7872, 57.5736],
            [26.7514, 57.5797],
            [26.7109, 57.5669],
            [26.6752, 57.5656],
            [26.6521, 57.5552],
            [26.6151, 57.5293],
            [26.6148, 57.5102],
            [26.5906, 57.5175],
            [26.5905, 57.537],
            [26.565, 57.5293],
            [26.56, 57.5128],
            [26.5261, 57.5164],
            [26.5008, 57.525],
            [26.4887, 57.5528],
            [26.4704, 57.5759],
            [26.4879, 57.5919],
            [26.4679, 57.5953],
            [26.4984, 57.6255],
            [26.4423, 57.6507],
            [26.4697, 57.6744],
            [26.5051, 57.694],
            [26.5005, 57.7068],
            [26.4712, 57.7113],
            [26.4709, 57.7215],
            [26.4499, 57.7371],
            [26.4765, 57.745],
            [26.4961, 57.7734],
            [26.4967, 57.7851],
            [26.4762, 57.7903],
            [26.439, 57.7809],
            [26.4201, 57.8029],
            [26.4294, 57.816],
            [26.4142, 57.8445],
            [26.4361, 57.8524],
            [26.4189, 57.8662],
            [26.3959, 57.8668],
            [26.3979, 57.8935],
            [26.416, 57.9022],
            [26.4126, 57.9205],
            [26.3962, 57.9277],
            [26.4073, 57.9399],
            [26.4309, 57.9343],
            [26.4732, 57.9414],
            [26.4981, 57.954],
            [26.5126, 57.9496],
            [26.5525, 57.9511],
            [26.5671, 57.9559],
          ],
        ],
      },
      properties: {
        name: 'Võrumaa',
        id: 'EE-86',
        NAME_ENG: 'Võru',
        CNTRY: 'Estonia',
        TYPE: 'County',
      },
      id: 'EE-86',
    },
  ],
}
export default map
