import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import {IReportDetails} from '../../../models/Reports'
import {selectors} from '../../../redux/ReportsRedux'
import FBPlatformGroup from '../../WidgetsGroup/FBPlatformGroup'
import FBSocialGroup from '../../WidgetsGroup/FBSocialGroup'
import ClicksGroup from '../FbWidgetsGroup/ClicksGroup'
import FbAdsCampaignGroup from '../FbWidgetsGroup/FbAdsCampaignGroup'
import FbConversionCampaignGroup from '../FbWidgetsGroup/FbConversionCampaignGroup'
import FbGoogleAnalyticsGroup from '../FbWidgetsGroup/FbGoogleAnalyticsGroup'
import FbDailyChart from "../FbWidgetsGroup/FbDailyChart";

interface DataStatisticTabProps {
  isPdf?: boolean
  onLoad?: () => void;
}

const DataStatisticTab: React.FC<DataStatisticTabProps> = ({ isPdf, onLoad }) => {
  const reportData = useSelector(selectors.getReportData) as IReportDetails
  const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(reportsLoading)
  }, [reportsLoading])

  useEffect(() => {
    if(onLoad){
      onLoad();
    }
  }, [onLoad]);

  return !reportsError ? (
    <div
      className={reportsLoading ? 'overlay overlay-block rounded' : ''}
      data-testid='fb-report-basic-data'
    >
      {!loading ? (
        <>
          <div className='clicks-group bg-white'>
            <ClicksGroup reportData={reportData}/>
          </div>
          <div className='clicks-group bg-white'>
            <FbDailyChart reportData={reportData} />
          </div>
          <div className='bg-white'>
            <FBSocialGroup isPdf={isPdf}/>
          </div>

          <div className='bg-white'>
            <FBPlatformGroup isPdf={isPdf}/>
          </div>

          <div className='card-body bg-white px-5 py-5 mb-5 d-print-none'>
            <FbAdsCampaignGroup />
          </div>
          <div className='bg-white d-print-none'>
            <FbConversionCampaignGroup isPdf={isPdf}/>
          </div>

          {/*<div className='card-body bg-white px-5 py-5 mb-5'>*/}
          {/*  <FbGoogleAnalyticsGroup />*/}
          {/*</div>*/}
        </>
      ) : (
        <div style={{height: '500px'}} className='d-flex justify-content-center align-items-center'>
          <Spinner color='#ffffff' />
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default DataStatisticTab
