import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";
import {TAB_NAME, IReportDetails} from '../../models/Reports'
import React from 'react'
import clsx from "clsx";
import CommentTab from "../SeoStatistic/Tabs/CommentTab";
import AttachmentsTab from '../FbStatistic/Tabs/AttachmantsTab'

interface ILinkedinTabsContent {
    tab: string
}
const LinkedinTabsContent: React.FC<ILinkedinTabsContent> = ({ tab }) => {
  const reportData = useSelector(selectors.getReportData) as IReportDetails

  return (
    <div className="tab-content border">
      <div className={clsx("tab-pane", { active: tab === TAB_NAME.COMMENT })}>
        <CommentTab />
      </div>
      {reportData && reportData.attachments && (
        <div className={clsx('tab-pane', {active: tab === TAB_NAME.ATTACHMENTS})}>
         <AttachmentsTab />
        </div>
      )}
    </div>

  );
};

export default LinkedinTabsContent;
