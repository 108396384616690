
// create component ForecastPopup

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import React from "react";
import {toAbsoluteUrl} from "../../../../../../_metronic/helpers";



const ForecastPopup: React.FC = () => {
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null); // Zmiana typu na `null | HTMLElement`

    const open = Boolean(anchorEl);


    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={"relative"}>
            {/*<h4>How to use Popover Component in ReactJS?</h4>*/}
            <img width={"25px"}
                 src={toAbsoluteUrl('/media/sempai-images/icons/png/shield.png') }
                 onMouseEnter={handlePopoverOpen}
                 aria-haspopup="true"
            />
            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    color="primary"*/}
            {/*    // onMouseEnter={(event) => {*/}
            {/*    //     setAnchorEl(event.currentTarget);*/}
            {/*    // }}*/}
            {/*    // onMouseLeave={() => {*/}
            {/*    //     setAnchorEl(null);*/}
            {/*    // }}*/}
            {/*    onMouseEnter={handlePopoverOpen}*/}
            {/*    // onMouseLeave={handlePopoverClose}*/}
            {/*>*/}
            {/*    Click me to open Popover*/}
            {/*</Button>*/}
            <Popover
                anchorEl={anchorEl}
                open={open}
                id={open ? "simple-popover" : undefined}
                onClose={() => {
                    setAnchorEl(null);
                }}
                style={{ position : "absolute", zIndex : 20 }}
                disableScrollLock={true}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
            >
                <div>
                    <div className={"p-5"} style={{ maxWidth : "300px" }}>Dane dot. KONWERSJI są próbkowane, kompletne dane konwersji w atrybucji Google Ads będą dostępne po 30 dniach od końca  miesiąca.
                        Więcej informacji:<a href={"https://support.google.com/analytics/answer/2679221?hl=pl "}> LINK</a></div>
                </div>


            </Popover>
        </div>
        // JSX komponentu
    );
};
export default ForecastPopup