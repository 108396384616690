import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";

const BingTitle: React.FC = () => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails

    return (
        <>
            <div className='row'>
                <div className=''>
                    <div className='d-flex justify-content-between'>
                        <h3 className='fw-bolder text-dark'>
                            {intl.formatMessage({id: 'BING_REPORT_TITLE'})}
                            {reportData.domain}
                            {' za miesiąc '}
                            {(reportData.date)}
                        </h3>
                    </div>
                    <div className='text-gray-400 fw-bold fs-6'>
                        {intl.formatMessage({id: 'BING_REPORT_SUBTITLE'})}
                        {' na miesiąc: '}
                        {reportData.date}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BingTitle