import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {KTSVG} from '../../../../../_metronic/helpers'
import {selectors} from '../../redux/ReportsRedux'
import ChartLineWithColumnWidget, {FBAgeBracketsData} from '../Widgets/ChartLineWithColumnWidget'
import {numSpaceFormater} from "../../../../helpers/formater";
import {Gender} from "../../models/AbstractReportDetails";

interface FBSocialGroupProps {
  isPdf?: boolean
}

const SocialGroup: React.FC<FBSocialGroupProps> = ({isPdf}) => {
  const intl = useIntl()
  const details = useSelector(selectors.getReportDetails)

  const [ageReport, setAgeReport] = useState<FBAgeBracketsData>()
  const [genderReport, setGenderReport] = useState<Gender[]>()
  const [hasAgeData, setHasAgeData] = useState(false);
  const [hasGenderData, setHasGenderData] = useState(false);

  useEffect(() => {
    if (details && details.fbCampaignAge && details.fbCampaignAge.data) {
      const ageData = details.fbCampaignAge.data.filter((item: any) => item.age !== 'Unknown');

      if (ageData.length > 0) {
        setHasAgeData(true);
      }
      const chartData: FBAgeBracketsData = {
        ageBracket: ageData.map((item: any) => item.age),
        transaction: ageData.map((item: any) => Number(item.total_reach)),
        sessions: ageData.map((item: any) => Number(item.total_impressions)),
      };

      setAgeReport(chartData);
    }
  }, [details]);

  useEffect(() => {
    if (details && details.fbCampaignGender) {
      setGenderReport(details.fbCampaignGender.data)
    }
    if(details && details.fbCampaignGender && details.fbCampaignGender.data && details.fbCampaignGender.data.length > 0) {
      setHasGenderData(true);
    }
  }, [details])


return hasAgeData ? (
      <>
        <div className='row card-body px-5 py-5 mb-5'>
            {ageReport && (
                <div className='col-4 social-group-age-bracket'>
                  <div className={`h-100 ${isPdf === true ? '' : 'border'}`}>
                    <div className='px-5 py-5'>
                      <h3 className='fw-boldest text-sempai-green text-uppercase'>
                        {intl.formatMessage({id: 'DEMPGRAPHY_AGE'})}
                      </h3>
                    </div>
                    <div className='' data-testid='age-chart'>
                      <ChartLineWithColumnWidget className=''
                                                 name_one={intl.formatMessage({id: 'IMPRESSIONS'})}
                                                 name_two={intl.formatMessage({id: 'REACH'})}
                                                 chartData={ageReport}
                                                 var_width={'100%'}
                      />
                    </div>
                  </div>
                </div>
            )}

          {genderReport && (
              <div className='col-8 social-group-genders'>
                <div className={`h-100 ${isPdf === true ? '' : 'border'}`}>
                  <div className='px-5 py-5'>
                    <h3 className='fw-boldest text-sempai-green text-uppercase mb-0'>
                      {intl.formatMessage({id: 'DEMOGRAPHY_GENDER'})}
                    </h3>
                  </div>
                  <div className='row g-0' data-testid='gender-data'>
                    {genderReport.map((item: any) => {
                      if (['male', 'female', 'unknown'].includes(item.gender)) {
                        return(
                        <div className='col-4' >
                          <div className='d-flex flex-row align-items-start'>
                            <div className='d-flex justify-content-center align-items-center mt-3'>
                              {(() => {
                                if (item.gender === 'female') {
                                  return (
                                      <KTSVG
                                          path='/media/sempai-images/icons/female.svg'
                                          className='svg-icon-5x svg-icon-sempai-green'
                                      />
                                  )
                                }else if (item.gender === 'male') {
                                  return (
                                      <KTSVG
                                          path='/media/sempai-images/icons/male.svg'
                                          className='svg-icon-5x svg-icon-sempai-green'
                                      />
                                  )
                                } else if (item.gender === 'unknown') {
                                  return (
                                      <KTSVG
                                          path='/media/sempai-images/icons/unknown.svg'
                                          className='svg-icon-5x svg-icon-sempai-green'
                                      />
                                  )
                                }
                              })()}
                            </div>
                            <div className='d-flex flex-column'>
                              <div className='d-flex flex-column mb-3'>
                                <div className='text-dark fs-6 fw-bold'>
                                  {intl.formatMessage({id: 'ENGAGEMENT'})}:
                                </div>
                                <div
                                    className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.total_engagement)}</div>
                              </div>
                              <div className='d-flex flex-column mb-3'>
                                <div className='text-dark fs-6 fw-bold'>
                                  {intl.formatMessage({id: 'REACH'})}:
                                </div>
                                <div className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.total_reach)}</div>
                              </div>
                              <div className='d-flex flex-column mb-3'>
                                <div className='text-dark fs-6 fw-bold'>
                                  {intl.formatMessage({id: 'AVG_CPC'})}:
                                </div>
                                <div
                                    className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.avg_cpc).toFixed(4))}</div>
                              </div>
                              <div className='d-flex flex-column mb-3'>
                                <div className='text-dark fs-6 fw-bold'>
                                  {intl.formatMessage({id: 'AVG_CPM'})}:
                                </div>
                                <div
                                    className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.avg_cpm).toFixed(4))}</div>
                              </div>
                              <div className='d-flex flex-column mb-3'>
                                <div className='text-dark fs-6 fw-bold'>
                                  {intl.formatMessage({id: 'AVG_CTR'})}:
                                </div>
                                <div
                                    className='text-dark fs-3 fw-boldest'>{numSpaceFormater(Number(item.avg_ctr).toFixed(4))}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        );
                      }})}
                  </div>
                </div>
              </div>
          )}
      </div>
      </>
  ) : <></>
}

export default SocialGroup
