import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {numSpaceFormater, percentCalc} from '../../../../../helpers/formater'
import {GSCTopPhrasesTimeCompare} from '../../../models/SEOReport'
import TableWidget from '../../Widgets/TableWidget'

type GSCComapreTime = 'month' | 'year'

interface GSCTimeCompareListProps {
  list: GSCTopPhrasesTimeCompare[]
  time: GSCComapreTime
  loading: boolean
  pagination?: boolean
  pages?: number
  currentPage?: number
  setCurrentPage?: (data: React.SetStateAction<number>) => void
  paginationPosition?: 'start' | 'center' | 'end'
}

interface GSCTimeCompareListItemProps {
  currentData: number | string
  type: string
  percent: number | string
}

const GSCTimeCompareListItem: React.FC<GSCTimeCompareListItemProps> = ({currentData,type, percent}) => {
  return (
    <div className='d-flex justify-content-around'>
      <span>{currentData}</span>
      <span>
        <KTSVG
          path={`/media/icons/duotone/Navigation/${
            typeof percent === 'number' ? percent > 0 ? 'Arrow-up.svg' : 'Arrow-down.svg' : ''
          }`}
          className={`svg-icon ${
            type === 'percentage' ?
              percent > 0 ? 'svg-icon-sempai-green-text' : 'svg-icon-overdue' 
            : type === 'number' ?
              percent > 0 ? 'svg-icon-overdue' : 'svg-icon-sempai-green-text'
            : ''
          }`}
        />

        {typeof percent === 'number' && type === 'percentage' ? Number(percent).toFixed(2) : ''}
        {typeof percent === 'number' && type === 'number' ? Number(percent).toFixed(1) : ''}
        {typeof percent === 'string' ? percent : ''}
        {typeof percent === 'number' && type === 'percentage' ? '%' : ''}

      </span>
    </div>
  )
}

const GSCTimeCompareList: React.FC<GSCTimeCompareListProps> = ({
  list,
  time,
  loading,
  pagination,
  pages = 1,
  currentPage,
  setCurrentPage,
  paginationPosition = 'center',
}) => {
  const intl = useIntl()
  return (
    <TableWidget
      title=''
      pagination={pagination}
      pages={pages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      paginationPosition={paginationPosition}
    >
      <>
        <thead>
          <tr className='fw-boldest text-dark fs-6'>
            <th className='min-w-100px'>Fraza</th>
            <th className='min-w-50px text-end crop-text'>
              {intl.formatMessage({id: 'AVG_POSITION'})}
            </th>
            <th className='min-w-50px text-end'>{intl.formatMessage({id: 'CLICKS'})}</th>
            <th className='min-w-50px text-end'>{intl.formatMessage({id: 'VIEWS'})}</th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            list &&
            list.map((item, index) => (
              <tr key={index}>
                <td className='crop-text mw-100px fs-8 fw-bolder'>{item.query_phrase}</td>
                <td className='text-end fs-8 fw-bolder'>
                  {item.position && (
                    <GSCTimeCompareListItem
                      currentData={Number(item.position).toFixed(1)}
                      type={'number'}
                      percent={
                        time === 'month'
                          ? item.last_month
                            ?
                                Number(item.position) - Number(item.last_month.position)

                            : '-'
                          : item.last_year
                            ?
                              Number(item.position) - Number(item.last_year.position)

                            : '-'
                      }
                    />
                  )}
                </td>
                <td className='text-center fs-8 fw-bolder'>
                  {item.url_clicks && (
                    <GSCTimeCompareListItem
                      currentData={numSpaceFormater(item.url_clicks)}
                      type={'percentage'}
                      percent={
                        time === 'month' && item.last_month
                          ? item.last_month
                            ? percentCalc(
                                Number(item.url_clicks) - Number(item.last_month.url_clicks),
                                Number(item.url_clicks)
                              )
                            : '-'
                          : item.last_year
                          ? percentCalc(
                              Number(item.url_clicks) - Number(item.last_year.url_clicks),
                              Number(item.url_clicks)
                            )
                          : '-'
                      }
                    />
                  )}
                </td>
                <td className='text-center fs-8 fw-bolder'>
                  {item.impressions && (
                    <GSCTimeCompareListItem
                      currentData={numSpaceFormater(item.impressions)}
                      type={'percentage'}
                      percent={
                        time === 'month'
                          ? item.last_month
                            ? percentCalc(
                                Number(item.impressions) - Number(item.last_month.impressions),
                                Number(item.impressions)
                              )
                            : '-'
                          : item.last_year
                          ? percentCalc(
                              Number(item.impressions) - Number(item.last_year.impressions),
                              Number(item.impressions)
                            )
                          : '-'
                      }
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    </TableWidget>
  )
}

export default GSCTimeCompareList
