var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.808, 46.5605],
            [22.8283, 46.5427],
            [22.8796, 46.5333],
            [22.9277, 46.5506],
            [23.0118, 46.5471],
            [23.0319, 46.5295],
            [23.0445, 46.4696],
            [23.0936, 46.4643],
            [23.1084, 46.4878],
            [23.1838, 46.5062],
            [23.189, 46.4921],
            [23.238, 46.4856],
            [23.2942, 46.5129],
            [23.3371, 46.5227],
            [23.3852, 46.5448],
            [23.4251, 46.532],
            [23.4857, 46.488],
            [23.5335, 46.4782],
            [23.5738, 46.4926],
            [23.6141, 46.4755],
            [23.5853, 46.4431],
            [23.5799, 46.4164],
            [23.6362, 46.3972],
            [23.6516, 46.4242],
            [23.6973, 46.4524],
            [23.7254, 46.4484],
            [23.7656, 46.4593],
            [23.8284, 46.4596],
            [23.8354, 46.4437],
            [23.9468, 46.4489],
            [23.9631, 46.4549],
            [23.9744, 46.4318],
            [24.0223, 46.4198],
            [23.9992, 46.3829],
            [24.0206, 46.3575],
            [24.0814, 46.3313],
            [24.1339, 46.3311],
            [24.1471, 46.2941],
            [24.1998, 46.2749],
            [24.2549, 46.2412],
            [24.1964, 46.217],
            [24.1884, 46.1798],
            [24.2121, 46.1553],
            [24.1506, 46.1337],
            [24.1155, 46.1319],
            [24.037, 46.0512],
            [24.0439, 46.0307],
            [24.004, 46.0139],
            [23.9459, 46.039],
            [23.8925, 46.0418],
            [23.8504, 46.0098],
            [23.8697, 45.9875],
            [23.8668, 45.9603],
            [23.8378, 45.9482],
            [23.8163, 45.9231],
            [23.79, 45.9264],
            [23.743, 45.9095],
            [23.7294, 45.8195],
            [23.7004, 45.8237],
            [23.6565, 45.8101],
            [23.6314, 45.7827],
            [23.6537, 45.7751],
            [23.6394, 45.7327],
            [23.5994, 45.7215],
            [23.5914, 45.6706],
            [23.6291, 45.6551],
            [23.6488, 45.6348],
            [23.6131, 45.5612],
            [23.6387, 45.5452],
            [23.6534, 45.5172],
            [23.6596, 45.4693],
            [23.6322, 45.4634],
            [23.5962, 45.4676],
            [23.5949, 45.489],
            [23.5725, 45.5225],
            [23.5341, 45.5485],
            [23.5122, 45.5473],
            [23.501, 45.5751],
            [23.4256, 45.5858],
            [23.427, 45.6233],
            [23.3858, 45.6383],
            [23.419, 45.6718],
            [23.3625, 45.7094],
            [23.3827, 45.752],
            [23.3513, 45.7749],
            [23.3239, 45.8523],
            [23.3331, 45.8832],
            [23.3071, 45.915],
            [23.2661, 45.9319],
            [23.2331, 45.9979],
            [23.2138, 46.0128],
            [23.1433, 46.0447],
            [23.0882, 46.0538],
            [23.0895, 46.0969],
            [23.0733, 46.1137],
            [23.0942, 46.1823],
            [23.0525, 46.209],
            [22.9996, 46.2104],
            [22.9472, 46.2639],
            [22.9613, 46.2831],
            [22.9143, 46.3295],
            [22.8112, 46.3227],
            [22.779, 46.3473],
            [22.7539, 46.3491],
            [22.7016, 46.36],
            [22.6852, 46.3813],
            [22.6712, 46.4273],
            [22.6928, 46.4492],
            [22.6733, 46.4811],
            [22.6767, 46.5189],
            [22.7086, 46.5391],
            [22.6958, 46.5672],
            [22.7437, 46.5911],
            [22.808, 46.5605],
          ],
        ],
      },
      properties: {
        name: 'Alba',
        id: 'RO-AB',
        NAME_ENG: 'Alba',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-AB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.6883, 45.6003],
            [24.8298, 45.6046],
            [24.8401, 45.5894],
            [24.9605, 45.5918],
            [25.0075, 45.5835],
            [25.1027, 45.5794],
            [25.1927, 45.5212],
            [25.2329, 45.5146],
            [25.2384, 45.4748],
            [25.2599, 45.4318],
            [25.3041, 45.4221],
            [25.3216, 45.3797],
            [25.3258, 45.3533],
            [25.3115, 45.3352],
            [25.3048, 45.2905],
            [25.2794, 45.2681],
            [25.2915, 45.2324],
            [25.2547, 45.2151],
            [25.2498, 45.1782],
            [25.1989, 45.1806],
            [25.1541, 45.2312],
            [25.1343, 45.2164],
            [25.156, 45.1929],
            [25.1444, 45.1421],
            [25.1755, 45.1033],
            [25.1621, 45.0749],
            [25.1272, 45.0597],
            [25.1452, 45.0389],
            [25.189, 45.0277],
            [25.1997, 45.0036],
            [25.1758, 44.985],
            [25.1661, 44.9463],
            [25.1968, 44.8588],
            [25.2009, 44.797],
            [25.1909, 44.7338],
            [25.1799, 44.7161],
            [25.226, 44.6975],
            [25.1927, 44.6615],
            [25.2027, 44.632],
            [25.1946, 44.6123],
            [25.2254, 44.5952],
            [25.269, 44.5562],
            [25.2741, 44.5225],
            [25.2581, 44.5119],
            [25.278, 44.4782],
            [25.2593, 44.438],
            [25.2132, 44.4443],
            [25.1868, 44.4405],
            [25.0448, 44.397],
            [24.9958, 44.4025],
            [24.9613, 44.3779],
            [24.8864, 44.3803],
            [24.8555, 44.3722],
            [24.8106, 44.3774],
            [24.7541, 44.386],
            [24.7288, 44.4388],
            [24.7357, 44.46],
            [24.7218, 44.4933],
            [24.7344, 44.5151],
            [24.7163, 44.5789],
            [24.7293, 44.606],
            [24.6862, 44.6926],
            [24.7062, 44.7325],
            [24.6234, 44.7299],
            [24.5924, 44.7531],
            [24.5873, 44.7963],
            [24.553, 44.8331],
            [24.5435, 44.8918],
            [24.5241, 44.8927],
            [24.4921, 44.8232],
            [24.4283, 44.8197],
            [24.4288, 44.8477],
            [24.4752, 44.8958],
            [24.4651, 44.9108],
            [24.4866, 44.9476],
            [24.4836, 44.9756],
            [24.5123, 45.0471],
            [24.489, 45.0604],
            [24.5087, 45.0827],
            [24.5199, 45.1333],
            [24.5009, 45.1541],
            [24.5197, 45.18],
            [24.4671, 45.2289],
            [24.4958, 45.2811],
            [24.4836, 45.3574],
            [24.4513, 45.4091],
            [24.4534, 45.4759],
            [24.4697, 45.5135],
            [24.4972, 45.5472],
            [24.4888, 45.5715],
            [24.5046, 45.5828],
            [24.5654, 45.5771],
            [24.6168, 45.5982],
            [24.6727, 45.5932],
            [24.6883, 45.6003],
          ],
        ],
      },
      properties: {
        name: 'Argeş',
        id: 'RO-AG',
        NAME_ENG: 'Arges',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-AG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.4429, 46.6515],
            [21.5097, 46.654],
            [21.541, 46.6259],
            [21.5726, 46.6473],
            [21.6091, 46.642],
            [21.646, 46.6623],
            [21.7801, 46.6537],
            [21.8587, 46.6651],
            [21.8778, 46.6789],
            [21.9111, 46.6599],
            [21.883, 46.6304],
            [21.9539, 46.6152],
            [21.9988, 46.6239],
            [22.0236, 46.6534],
            [22.0555, 46.6252],
            [22.1616, 46.604],
            [22.1803, 46.5296],
            [22.2116, 46.492],
            [22.2607, 46.4817],
            [22.3123, 46.4822],
            [22.3676, 46.4737],
            [22.3737, 46.4367],
            [22.4418, 46.3942],
            [22.503, 46.3834],
            [22.5065, 46.4],
            [22.6852, 46.3813],
            [22.7016, 46.36],
            [22.7539, 46.3491],
            [22.7207, 46.3282],
            [22.6775, 46.318],
            [22.6811, 46.2962],
            [22.659, 46.2642],
            [22.6562, 46.2283],
            [22.5111, 46.2166],
            [22.4664, 46.2261],
            [22.4381, 46.2078],
            [22.4539, 46.1749],
            [22.4893, 46.1593],
            [22.481, 46.1423],
            [22.4369, 46.1174],
            [22.4296, 46.0866],
            [22.4408, 46.0259],
            [22.4334, 46.0083],
            [22.394, 46.0084],
            [22.3473, 45.9965],
            [22.3412, 45.9778],
            [22.3654, 45.9557],
            [22.4087, 45.9558],
            [22.4318, 45.9435],
            [22.4088, 45.9174],
            [22.348, 45.9432],
            [22.3444, 45.9705],
            [22.319, 45.9707],
            [22.3014, 45.9317],
            [22.2671, 45.9427],
            [22.237, 45.936],
            [22.2156, 45.9141],
            [22.1275, 45.9353],
            [22.0683, 45.957],
            [22.0045, 45.9715],
            [21.9669, 45.9936],
            [21.9296, 45.9855],
            [21.8917, 45.9501],
            [21.8694, 45.9613],
            [21.8275, 45.9584],
            [21.8067, 45.9331],
            [21.7365, 45.9463],
            [21.6673, 45.9242],
            [21.6159, 45.9809],
            [21.523, 46.0097],
            [21.527, 46.0413],
            [21.5134, 46.0645],
            [21.4862, 46.0634],
            [21.4342, 46.0214],
            [21.3628, 45.9922],
            [21.2964, 46.0048],
            [21.2634, 45.9989],
            [21.2466, 45.9683],
            [21.1899, 45.9849],
            [21.1467, 46.0116],
            [21.1092, 45.9773],
            [21.0838, 45.9976],
            [21.0684, 46.0381],
            [21.0253, 46.0284],
            [20.9825, 46.0425],
            [20.9421, 46.0366],
            [20.8858, 46.0819],
            [20.8324, 46.0872],
            [20.7791, 46.1224],
            [20.771, 46.1409],
            [20.7267, 46.1411],
            [20.6968, 46.1576],
            [20.7142, 46.1646],
            [20.7297, 46.2069],
            [20.7602, 46.2044],
            [20.751, 46.2469],
            [20.7911, 46.272],
            [20.8726, 46.2864],
            [20.9162, 46.2614],
            [20.9916, 46.2586],
            [21.0224, 46.2657],
            [21.0625, 46.2432],
            [21.1016, 46.2618],
            [21.1134, 46.2997],
            [21.1723, 46.2984],
            [21.1764, 46.334],
            [21.1992, 46.3482],
            [21.2057, 46.4013],
            [21.2226, 46.4127],
            [21.2844, 46.4036],
            [21.3159, 46.4513],
            [21.2605, 46.5003],
            [21.2753, 46.5331],
            [21.3198, 46.5812],
            [21.3025, 46.5899],
            [21.3269, 46.628],
            [21.3669, 46.6364],
            [21.4101, 46.6217],
            [21.4429, 46.6515],
          ],
        ],
      },
      properties: {
        name: 'Arad',
        id: 'RO-AR',
        NAME_ENG: 'Arad',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-AR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.9782, 46.6892],
            [25.999, 46.7014],
            [26.0703, 46.7037],
            [26.078, 46.6569],
            [26.1004, 46.6473],
            [26.14, 46.6587],
            [26.1555, 46.6415],
            [26.191, 46.6529],
            [26.2355, 46.6904],
            [26.281, 46.6823],
            [26.2951, 46.6544],
            [26.3596, 46.6424],
            [26.4336, 46.6785],
            [26.4877, 46.6796],
            [26.5284, 46.6918],
            [26.5596, 46.6677],
            [26.6014, 46.7017],
            [26.6707, 46.731],
            [26.6824, 46.7668],
            [26.763, 46.7543],
            [26.7776, 46.7277],
            [26.8196, 46.743],
            [26.8179, 46.7627],
            [26.8472, 46.777],
            [26.87, 46.8294],
            [26.9063, 46.8262],
            [26.9142, 46.7926],
            [26.9361, 46.7838],
            [26.9468, 46.7551],
            [27.027, 46.7698],
            [27.0612, 46.7582],
            [27.1006, 46.7689],
            [27.1165, 46.7478],
            [27.1608, 46.7363],
            [27.1947, 46.759],
            [27.2233, 46.7405],
            [27.3003, 46.7084],
            [27.3138, 46.6509],
            [27.2751, 46.6228],
            [27.3276, 46.5839],
            [27.3403, 46.5572],
            [27.3815, 46.5106],
            [27.4137, 46.4504],
            [27.4206, 46.4236],
            [27.4485, 46.3991],
            [27.4516, 46.3702],
            [27.4759, 46.3272],
            [27.4625, 46.2893],
            [27.4858, 46.1976],
            [27.5061, 46.1877],
            [27.4798, 46.1453],
            [27.4466, 46.1845],
            [27.39, 46.1902],
            [27.3787, 46.1581],
            [27.3224, 46.1709],
            [27.3142, 46.2013],
            [27.276, 46.1951],
            [27.2421, 46.1742],
            [27.2132, 46.1873],
            [27.1217, 46.145],
            [27.1459, 46.1302],
            [27.1356, 46.1022],
            [27.0869, 46.1143],
            [27.0792, 46.0857],
            [26.9899, 46.1134],
            [26.9325, 46.0635],
            [26.8725, 46.0829],
            [26.8178, 46.0687],
            [26.7429, 46.0857],
            [26.6989, 46.0892],
            [26.6537, 46.0768],
            [26.6328, 46.0572],
            [26.5851, 46.0385],
            [26.5651, 46.0216],
            [26.5134, 46.0247],
            [26.4925, 46.0391],
            [26.4373, 46.042],
            [26.4237, 46.0955],
            [26.3823, 46.0947],
            [26.3376, 46.1196],
            [26.3452, 46.1558],
            [26.3305, 46.1762],
            [26.3401, 46.1999],
            [26.3211, 46.233],
            [26.2605, 46.2455],
            [26.2526, 46.271],
            [26.2804, 46.2967],
            [26.2905, 46.3274],
            [26.2416, 46.3334],
            [26.2087, 46.3111],
            [26.1552, 46.3107],
            [26.1662, 46.3681],
            [26.1854, 46.4013],
            [26.0613, 46.4056],
            [26.0063, 46.4213],
            [26.0138, 46.467],
            [26.0713, 46.5066],
            [26.0619, 46.5335],
            [25.9963, 46.6023],
            [26.0083, 46.6381],
            [26.0005, 46.6687],
            [25.9782, 46.6892],
          ],
        ],
      },
      properties: {
        name: 'Bacău',
        id: 'RO-BC',
        NAME_ENG: 'Bacau',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-BC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.1869, 47.6093],
            [22.2246, 47.5971],
            [22.2322, 47.5755],
            [22.2903, 47.5386],
            [22.2901, 47.5059],
            [22.3499, 47.4871],
            [22.3439, 47.4533],
            [22.3594, 47.4263],
            [22.4076, 47.4327],
            [22.4321, 47.419],
            [22.4751, 47.4349],
            [22.5183, 47.4225],
            [22.5203, 47.4041],
            [22.5615, 47.3952],
            [22.5707, 47.3713],
            [22.6074, 47.3466],
            [22.6115, 47.3327],
            [22.5522, 47.3124],
            [22.5414, 47.2331],
            [22.5098, 47.2132],
            [22.5044, 47.1699],
            [22.5295, 47.1693],
            [22.5364, 47.1405],
            [22.5747, 47.0765],
            [22.6071, 47.0817],
            [22.6328, 47.0528],
            [22.7199, 47.0405],
            [22.7234, 47.0044],
            [22.6808, 46.9678],
            [22.6807, 46.9377],
            [22.7796, 46.8924],
            [22.7306, 46.8311],
            [22.7243, 46.8129],
            [22.6692, 46.7983],
            [22.6567, 46.7748],
            [22.7128, 46.7563],
            [22.7078, 46.738],
            [22.7333, 46.7175],
            [22.7472, 46.6881],
            [22.746, 46.6575],
            [22.8059, 46.6282],
            [22.8189, 46.5991],
            [22.808, 46.5605],
            [22.7437, 46.5911],
            [22.6958, 46.5672],
            [22.7086, 46.5391],
            [22.6767, 46.5189],
            [22.6733, 46.4811],
            [22.6928, 46.4492],
            [22.6712, 46.4273],
            [22.6852, 46.3813],
            [22.5065, 46.4],
            [22.503, 46.3834],
            [22.4418, 46.3942],
            [22.3737, 46.4367],
            [22.3676, 46.4737],
            [22.3123, 46.4822],
            [22.2607, 46.4817],
            [22.2116, 46.492],
            [22.1803, 46.5296],
            [22.1616, 46.604],
            [22.0555, 46.6252],
            [22.0236, 46.6534],
            [21.9988, 46.6239],
            [21.9539, 46.6152],
            [21.883, 46.6304],
            [21.9111, 46.6599],
            [21.8778, 46.6789],
            [21.8587, 46.6651],
            [21.7801, 46.6537],
            [21.646, 46.6623],
            [21.6091, 46.642],
            [21.5726, 46.6473],
            [21.541, 46.6259],
            [21.5097, 46.654],
            [21.4429, 46.6515],
            [21.4296, 46.6859],
            [21.4717, 46.6942],
            [21.4925, 46.6847],
            [21.528, 46.7191],
            [21.5261, 46.738],
            [21.4919, 46.7571],
            [21.523, 46.8083],
            [21.5208, 46.8364],
            [21.6007, 46.8637],
            [21.6045, 46.9315],
            [21.637, 46.9329],
            [21.6801, 46.9683],
            [21.6809, 47.0453],
            [21.7138, 47.0738],
            [21.7251, 47.0978],
            [21.7887, 47.1113],
            [21.8162, 47.1713],
            [21.8573, 47.1876],
            [21.8504, 47.2369],
            [21.8868, 47.2732],
            [21.8954, 47.315],
            [21.9388, 47.373],
            [22.0087, 47.3764],
            [22.0334, 47.4275],
            [22.0064, 47.4758],
            [22.02, 47.5159],
            [22.0533, 47.5466],
            [22.0901, 47.5568],
            [22.1279, 47.5883],
            [22.1702, 47.5915],
            [22.1869, 47.6093],
          ],
        ],
      },
      properties: {
        name: 'Bihor',
        id: 'RO-BH',
        NAME_ENG: 'Bihor',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-BH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.0219, 47.3599],
            [24.0586, 47.378],
            [24.1073, 47.4251],
            [24.1235, 47.4552],
            [24.1128, 47.4685],
            [24.1277, 47.4985],
            [24.1983, 47.5055],
            [24.2421, 47.4983],
            [24.2775, 47.5252],
            [24.3024, 47.5734],
            [24.3564, 47.5701],
            [24.3859, 47.6037],
            [24.4564, 47.5937],
            [24.5446, 47.5653],
            [24.6273, 47.5699],
            [24.6918, 47.5571],
            [24.723, 47.573],
            [24.7868, 47.573],
            [24.8247, 47.5978],
            [24.8578, 47.603],
            [24.9483, 47.5941],
            [25.0057, 47.5666],
            [25.0579, 47.5024],
            [25.0916, 47.4904],
            [25.0473, 47.4675],
            [25.068, 47.4545],
            [25.0605, 47.4318],
            [25.0072, 47.4083],
            [25.0682, 47.3704],
            [25.0617, 47.354],
            [25.0773, 47.3136],
            [25.0357, 47.2899],
            [25.0596, 47.2616],
            [25.0559, 47.2262],
            [25.0396, 47.2069],
            [25.0676, 47.1662],
            [25.0684, 47.1362],
            [25.0295, 47.1142],
            [24.9521, 47.1166],
            [24.9337, 47.101],
            [24.8817, 47.1023],
            [24.82, 47.0694],
            [24.7877, 47.0404],
            [24.7678, 46.9952],
            [24.7381, 46.9754],
            [24.7207, 46.9308],
            [24.7258, 46.9025],
            [24.6657, 46.9067],
            [24.6371, 46.9415],
            [24.6009, 46.9432],
            [24.5602, 46.9017],
            [24.5229, 46.894],
            [24.5347, 46.8712],
            [24.5048, 46.8371],
            [24.4345, 46.775],
            [24.3682, 46.7494],
            [24.309, 46.7561],
            [24.2436, 46.7722],
            [24.1809, 46.8008],
            [24.1877, 46.8276],
            [24.2352, 46.8837],
            [24.1936, 46.9294],
            [24.1607, 46.9277],
            [24.1589, 46.9503],
            [24.1133, 46.9604],
            [24.0985, 47.0253],
            [24.1359, 47.0812],
            [24.0979, 47.1284],
            [24.0518, 47.1226],
            [24.0172, 47.1762],
            [23.9568, 47.1773],
            [23.9391, 47.1875],
            [23.9248, 47.2419],
            [23.9275, 47.2973],
            [23.9487, 47.3104],
            [23.9811, 47.2962],
            [24.0273, 47.3215],
            [24.0219, 47.3599],
          ],
        ],
      },
      properties: {
        name: 'Bistriţa-Năsăud',
        id: 'RO-BN',
        NAME_ENG: 'Bistrita-Nasaud',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-BN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.5538, 45.4825],
            [27.6001, 45.4828],
            [27.6122, 45.4668],
            [27.6607, 45.4702],
            [27.7491, 45.4461],
            [27.7414, 45.4129],
            [27.8143, 45.3935],
            [27.8577, 45.4011],
            [27.8716, 45.4154],
            [27.9564, 45.4018],
            [27.9835, 45.3896],
            [28.0283, 45.4022],
            [28.0264, 45.3576],
            [27.9963, 45.303],
            [28.022, 45.2656],
            [28.0953, 45.2479],
            [28.1305, 45.2323],
            [28.0923, 45.188],
            [28.1975, 45.1342],
            [28.1698, 45.121],
            [28.1649, 45.0972],
            [28.1338, 45.0782],
            [28.1523, 45.0337],
            [28.128, 45.0125],
            [28.1411, 44.9776],
            [28.1235, 44.9112],
            [28.141, 44.8917],
            [28.1006, 44.8619],
            [28.0739, 44.8003],
            [28.0339, 44.7608],
            [27.9979, 44.7587],
            [27.9504, 44.7785],
            [27.8786, 44.7596],
            [27.8316, 44.7768],
            [27.7119, 44.7628],
            [27.5793, 44.782],
            [27.431, 44.7787],
            [27.3927, 44.7733],
            [27.316, 44.8031],
            [27.2683, 44.7432],
            [27.2275, 44.7673],
            [27.2064, 44.7922],
            [27.2263, 44.8543],
            [27.2577, 44.9266],
            [27.244, 44.9535],
            [27.2768, 44.9546],
            [27.2742, 44.9916],
            [27.2427, 44.9939],
            [27.2389, 45.0236],
            [27.1969, 45.0267],
            [27.2057, 45.0915],
            [27.1687, 45.0987],
            [27.1489, 45.1697],
            [27.0904, 45.1448],
            [27.0694, 45.1611],
            [27.1188, 45.2103],
            [27.1643, 45.2407],
            [27.2371, 45.2097],
            [27.2825, 45.1975],
            [27.335, 45.2357],
            [27.3565, 45.2675],
            [27.3887, 45.2859],
            [27.3789, 45.3274],
            [27.3984, 45.3574],
            [27.4402, 45.3737],
            [27.4337, 45.3922],
            [27.489, 45.4228],
            [27.4926, 45.4413],
            [27.5538, 45.4825],
          ],
        ],
      },
      properties: {
        name: 'Brăila',
        id: 'RO-BR',
        NAME_ENG: 'Braila',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-BR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.3908, 47.5866],
            [27.3214, 47.5762],
            [27.2874, 47.5586],
            [27.2647, 47.5329],
            [27.2255, 47.5234],
            [27.1427, 47.5238],
            [27.0979, 47.5486],
            [27.0771, 47.5008],
            [27.0797, 47.4624],
            [27.0413, 47.438],
            [26.9981, 47.4894],
            [26.8457, 47.4931],
            [26.7444, 47.5191],
            [26.7334, 47.49],
            [26.68, 47.4685],
            [26.5889, 47.4636],
            [26.591, 47.4877],
            [26.5521, 47.5048],
            [26.5774, 47.5325],
            [26.5593, 47.5758],
            [26.5377, 47.5953],
            [26.5363, 47.645],
            [26.4796, 47.67],
            [26.456, 47.7061],
            [26.3898, 47.7418],
            [26.4056, 47.757],
            [26.366, 47.7761],
            [26.319, 47.8116],
            [26.2708, 47.875],
            [26.2286, 47.8984],
            [26.1851, 47.8906],
            [26.1835, 47.9184],
            [26.126, 47.9314],
            [26.0833, 47.984],
            [26.1096, 47.9789],
            [26.1948, 47.9999],
            [26.2141, 48.051],
            [26.2758, 48.0823],
            [26.2998, 48.134],
            [26.3362, 48.1595],
            [26.329, 48.1791],
            [26.3785, 48.1976],
            [26.4007, 48.1882],
            [26.4571, 48.2059],
            [26.4709, 48.2201],
            [26.5296, 48.2094],
            [26.5783, 48.2436],
            [26.6216, 48.24],
            [26.71, 48.2506],
            [26.7849, 48.2399],
            [26.8095, 48.2517],
            [26.853, 48.2309],
            [26.8869, 48.1996],
            [26.9455, 48.1932],
            [26.9353, 48.1728],
            [26.9533, 48.1381],
            [27.04, 48.1015],
            [27.0261, 48.0873],
            [27.0799, 48.0514],
            [27.0894, 48.0134],
            [27.1357, 47.991],
            [27.178, 47.9448],
            [27.151, 47.9189],
            [27.1997, 47.8999],
            [27.2169, 47.8226],
            [27.2499, 47.7914],
            [27.2651, 47.757],
            [27.2883, 47.7449],
            [27.2671, 47.709],
            [27.2963, 47.6774],
            [27.3154, 47.6389],
            [27.3598, 47.6169],
            [27.3908, 47.5866],
          ],
        ],
      },
      properties: {
        name: 'Botoşani',
        id: 'RO-BT',
        NAME_ENG: 'Botosani',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-BT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.245, 44.4256],
            [26.2233, 44.395],
            [26.1774, 44.3887],
            [26.1422, 44.3433],
            [26.0604, 44.3618],
            [26.0256, 44.388],
            [25.9842, 44.393],
            [25.9723, 44.4278],
            [26.0244, 44.4296],
            [25.9849, 44.4589],
            [25.9874, 44.4833],
            [26.0196, 44.5065],
            [26.0618, 44.5241],
            [26.1106, 44.5229],
            [26.1299, 44.4726],
            [26.1992, 44.4649],
            [26.1781, 44.4414],
            [26.245, 44.4256],
          ],
        ],
      },
      properties: {
        name: 'București',
        id: 'RO-B',
        NAME_ENG: 'Bucharest',
        CNTRY: 'Romania',
        TYPE: 'Municipality',
        TYPE_ENG: 'Municipality',
      },
      id: 'RO-B',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.1722, 46.165],
            [25.1894, 46.1685],
            [25.2529, 46.1375],
            [25.2643, 46.1654],
            [25.3008, 46.1799],
            [25.3403, 46.1723],
            [25.372, 46.1326],
            [25.4515, 46.1228],
            [25.4536, 46.1138],
            [25.4449, 46.0812],
            [25.4936, 46.0382],
            [25.5516, 46.0504],
            [25.5734, 46.0232],
            [25.5475, 45.9941],
            [25.5423, 45.9296],
            [25.5582, 45.8943],
            [25.6023, 45.8893],
            [25.574, 45.8658],
            [25.5783, 45.8268],
            [25.6168, 45.8209],
            [25.6258, 45.8061],
            [25.6958, 45.759],
            [25.7344, 45.7755],
            [25.7509, 45.7602],
            [25.7869, 45.761],
            [25.8639, 45.7052],
            [25.9085, 45.7076],
            [25.9658, 45.686],
            [25.9784, 45.6656],
            [25.9602, 45.6485],
            [26.0179, 45.6366],
            [26.0109, 45.6162],
            [26.0538, 45.6163],
            [26.091, 45.5894],
            [26.0982, 45.5483],
            [26.0908, 45.5148],
            [26.0436, 45.4778],
            [25.9938, 45.5126],
            [25.9647, 45.5068],
            [25.9212, 45.5132],
            [25.8988, 45.496],
            [25.8857, 45.4437],
            [25.8656, 45.4227],
            [25.8276, 45.4302],
            [25.8105, 45.4637],
            [25.7649, 45.4636],
            [25.6446, 45.4843],
            [25.589, 45.4596],
            [25.5007, 45.4641],
            [25.4494, 45.4393],
            [25.4083, 45.4188],
            [25.405, 45.3857],
            [25.3664, 45.3887],
            [25.3216, 45.3797],
            [25.3041, 45.4221],
            [25.2599, 45.4318],
            [25.2384, 45.4748],
            [25.2329, 45.5146],
            [25.1927, 45.5212],
            [25.1027, 45.5794],
            [25.0075, 45.5835],
            [24.9605, 45.5918],
            [24.8401, 45.5894],
            [24.8298, 45.6046],
            [24.6883, 45.6003],
            [24.6783, 45.6191],
            [24.6875, 45.648],
            [24.6793, 45.7092],
            [24.6431, 45.761],
            [24.6317, 45.8006],
            [24.6898, 45.8326],
            [24.7715, 45.8371],
            [24.7707, 45.8656],
            [24.7185, 45.8941],
            [24.7019, 45.937],
            [24.7555, 45.9451],
            [24.7918, 45.9705],
            [24.8375, 45.9768],
            [24.8352, 46.0042],
            [24.866, 46.0088],
            [24.8908, 46.0434],
            [24.9461, 46.0652],
            [24.943, 46.0778],
            [24.9545, 46.1074],
            [25.0152, 46.1449],
            [25.0591, 46.1375],
            [25.0982, 46.1491],
            [25.1475, 46.138],
            [25.1722, 46.165],
          ],
        ],
      },
      properties: {
        name: 'Braşov',
        id: 'RO-BV',
        NAME_ENG: 'Brasov',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-BV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.39, 45.7988],
            [26.4405, 45.7746],
            [26.4348, 45.7356],
            [26.4654, 45.7171],
            [26.4933, 45.6743],
            [26.5439, 45.629],
            [26.5951, 45.6188],
            [26.6386, 45.6304],
            [26.6686, 45.5838],
            [26.7005, 45.5871],
            [26.747, 45.5487],
            [26.8062, 45.5419],
            [26.8342, 45.5177],
            [26.9462, 45.5245],
            [26.9718, 45.5308],
            [26.9921, 45.4942],
            [27.0311, 45.4544],
            [27.0716, 45.4457],
            [27.0995, 45.4618],
            [27.1606, 45.4159],
            [27.2028, 45.4389],
            [27.2234, 45.4252],
            [27.2591, 45.4471],
            [27.2716, 45.3793],
            [27.354, 45.3697],
            [27.4008, 45.3754],
            [27.4337, 45.3922],
            [27.4402, 45.3737],
            [27.3984, 45.3574],
            [27.3789, 45.3274],
            [27.3887, 45.2859],
            [27.3565, 45.2675],
            [27.335, 45.2357],
            [27.2825, 45.1975],
            [27.2371, 45.2097],
            [27.1643, 45.2407],
            [27.1188, 45.2103],
            [27.0694, 45.1611],
            [27.0904, 45.1448],
            [27.1489, 45.1697],
            [27.1687, 45.0987],
            [27.2057, 45.0915],
            [27.1969, 45.0267],
            [27.2389, 45.0236],
            [27.2427, 44.9939],
            [27.2742, 44.9916],
            [27.2768, 44.9546],
            [27.244, 44.9535],
            [27.2577, 44.9266],
            [27.2263, 44.8543],
            [27.2064, 44.7922],
            [27.1531, 44.7859],
            [27.1481, 44.7638],
            [27.1941, 44.7508],
            [27.1809, 44.7296],
            [27.0988, 44.7435],
            [27.1113, 44.7787],
            [27.0197, 44.8143],
            [26.9953, 44.7908],
            [26.9106, 44.807],
            [26.8416, 44.797],
            [26.8253, 44.7813],
            [26.7909, 44.7905],
            [26.7698, 44.8129],
            [26.719, 44.8068],
            [26.6303, 44.8112],
            [26.6087, 44.8569],
            [26.5718, 44.8756],
            [26.5527, 44.9015],
            [26.5161, 44.9178],
            [26.5737, 44.9426],
            [26.5753, 44.9705],
            [26.5344, 44.9996],
            [26.4997, 44.9877],
            [26.4479, 45.0852],
            [26.4542, 45.1224],
            [26.4718, 45.1528],
            [26.4426, 45.1822],
            [26.3897, 45.1664],
            [26.3551, 45.1666],
            [26.3331, 45.2167],
            [26.3073, 45.1999],
            [26.2646, 45.2026],
            [26.255, 45.2339],
            [26.2079, 45.2953],
            [26.2301, 45.3199],
            [26.1935, 45.3511],
            [26.1436, 45.3623],
            [26.1078, 45.4063],
            [26.0483, 45.434],
            [26.0436, 45.4778],
            [26.0908, 45.5148],
            [26.1355, 45.5192],
            [26.1781, 45.57],
            [26.1849, 45.6045],
            [26.2124, 45.6178],
            [26.2447, 45.6164],
            [26.2791, 45.5829],
            [26.3284, 45.6271],
            [26.3247, 45.6504],
            [26.3498, 45.7206],
            [26.3858, 45.7331],
            [26.3799, 45.7779],
            [26.39, 45.7988],
          ],
        ],
      },
      properties: {
        name: 'Buzău',
        id: 'RO-BZ',
        NAME_ENG: 'Buzau',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-BZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.8437, 47.3342],
            [23.8563, 47.3683],
            [23.8946, 47.3559],
            [23.9996, 47.3665],
            [24.0219, 47.3599],
            [24.0273, 47.3215],
            [23.9811, 47.2962],
            [23.9487, 47.3104],
            [23.9275, 47.2973],
            [23.9248, 47.2419],
            [23.9391, 47.1875],
            [23.9568, 47.1773],
            [24.0172, 47.1762],
            [24.0518, 47.1226],
            [24.0979, 47.1284],
            [24.1359, 47.0812],
            [24.0985, 47.0253],
            [24.1133, 46.9604],
            [24.1589, 46.9503],
            [24.1607, 46.9277],
            [24.1936, 46.9294],
            [24.2352, 46.8837],
            [24.1877, 46.8276],
            [24.1809, 46.8008],
            [24.1679, 46.7795],
            [24.0644, 46.7353],
            [24.0781, 46.7146],
            [24.11, 46.7082],
            [24.102, 46.6595],
            [24.0345, 46.6447],
            [24.021, 46.6153],
            [24.0693, 46.6055],
            [24.0442, 46.5707],
            [24.0376, 46.5419],
            [23.9905, 46.5407],
            [23.9671, 46.5201],
            [23.9631, 46.4549],
            [23.9468, 46.4489],
            [23.8354, 46.4437],
            [23.8284, 46.4596],
            [23.7656, 46.4593],
            [23.7254, 46.4484],
            [23.6973, 46.4524],
            [23.6516, 46.4242],
            [23.6362, 46.3972],
            [23.5799, 46.4164],
            [23.5853, 46.4431],
            [23.6141, 46.4755],
            [23.5738, 46.4926],
            [23.5335, 46.4782],
            [23.4857, 46.488],
            [23.4251, 46.532],
            [23.3852, 46.5448],
            [23.3371, 46.5227],
            [23.2942, 46.5129],
            [23.238, 46.4856],
            [23.189, 46.4921],
            [23.1838, 46.5062],
            [23.1084, 46.4878],
            [23.0936, 46.4643],
            [23.0445, 46.4696],
            [23.0319, 46.5295],
            [23.0118, 46.5471],
            [22.9277, 46.5506],
            [22.8796, 46.5333],
            [22.8283, 46.5427],
            [22.808, 46.5605],
            [22.8189, 46.5991],
            [22.8059, 46.6282],
            [22.746, 46.6575],
            [22.7472, 46.6881],
            [22.7333, 46.7175],
            [22.7078, 46.738],
            [22.7128, 46.7563],
            [22.6567, 46.7748],
            [22.6692, 46.7983],
            [22.7243, 46.8129],
            [22.7306, 46.8311],
            [22.7796, 46.8924],
            [22.6807, 46.9377],
            [22.6808, 46.9678],
            [22.7234, 47.0044],
            [22.7864, 46.9984],
            [22.8586, 47.0082],
            [22.9038, 46.9863],
            [22.8996, 46.9408],
            [22.9664, 46.8987],
            [23.0027, 46.9041],
            [23.0403, 46.8788],
            [23.0652, 46.9021],
            [23.0915, 46.8878],
            [23.0754, 46.8581],
            [23.1027, 46.8507],
            [23.1567, 46.8695],
            [23.1993, 46.8753],
            [23.2118, 46.8886],
            [23.2082, 46.9225],
            [23.2356, 46.9429],
            [23.264, 46.9076],
            [23.3016, 46.899],
            [23.3502, 46.9128],
            [23.3824, 46.943],
            [23.3822, 46.9714],
            [23.405, 46.9886],
            [23.4445, 46.9871],
            [23.4856, 47.0234],
            [23.4735, 47.0536],
            [23.4308, 47.0872],
            [23.4854, 47.0882],
            [23.5038, 47.1095],
            [23.49, 47.1508],
            [23.538, 47.1577],
            [23.5626, 47.1795],
            [23.6045, 47.1631],
            [23.6543, 47.2193],
            [23.7558, 47.2714],
            [23.7908, 47.2597],
            [23.8137, 47.3049],
            [23.8437, 47.3342],
          ],
        ],
      },
      properties: {
        name: 'Cluj',
        id: 'RO-CJ',
        NAME_ENG: 'Cluj',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-CJ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.415, 44.5176],
            [26.4351, 44.5476],
            [26.4801, 44.582],
            [26.514, 44.5659],
            [26.5441, 44.5313],
            [26.5904, 44.5194],
            [26.6673, 44.5743],
            [26.7031, 44.5652],
            [26.6991, 44.5402],
            [26.805, 44.5071],
            [26.8769, 44.5256],
            [26.8931, 44.5133],
            [26.955, 44.5275],
            [27.0277, 44.5288],
            [27.0258, 44.5051],
            [27.0863, 44.5088],
            [27.0871, 44.4837],
            [27.1321, 44.482],
            [27.1378, 44.5002],
            [27.2069, 44.4836],
            [27.2268, 44.4903],
            [27.3544, 44.4829],
            [27.3923, 44.4927],
            [27.5181, 44.4948],
            [27.5254, 44.5412],
            [27.548, 44.5603],
            [27.5722, 44.556],
            [27.5788, 44.4721],
            [27.6506, 44.464],
            [27.65, 44.4361],
            [27.6776, 44.4203],
            [27.7297, 44.413],
            [27.7685, 44.3912],
            [27.7502, 44.3591],
            [27.775, 44.3389],
            [27.8148, 44.3321],
            [27.8349, 44.3422],
            [27.8553, 44.3758],
            [27.9512, 44.3588],
            [28.0164, 44.3364],
            [27.994, 44.2999],
            [27.9348, 44.2467],
            [27.833, 44.2439],
            [27.7908, 44.2319],
            [27.7298, 44.1937],
            [27.642, 44.2008],
            [27.601, 44.1934],
            [27.5253, 44.1623],
            [27.4547, 44.1197],
            [27.395, 44.1271],
            [27.3571, 44.1232],
            [27.3343, 44.1332],
            [27.2751, 44.1202],
            [27.2361, 44.1128],
            [27.1829, 44.118],
            [27.1294, 44.1369],
            [27.0991, 44.1339],
            [27.0583, 44.146],
            [26.9908, 44.1306],
            [26.9015, 44.1276],
            [26.8513, 44.1125],
            [26.7576, 44.0735],
            [26.6924, 44.0719],
            [26.5993, 44.0484],
            [26.5356, 44.0512],
            [26.4426, 44.0327],
            [26.3661, 44.0368],
            [26.3484, 44.0972],
            [26.4264, 44.1401],
            [26.413, 44.1976],
            [26.3987, 44.2187],
            [26.3618, 44.2202],
            [26.3554, 44.2382],
            [26.2945, 44.257],
            [26.284, 44.278],
            [26.2515, 44.2934],
            [26.2763, 44.314],
            [26.2864, 44.3427],
            [26.315, 44.3697],
            [26.3227, 44.3958],
            [26.3727, 44.4183],
            [26.4025, 44.4155],
            [26.4633, 44.4443],
            [26.457, 44.4569],
            [26.3938, 44.4555],
            [26.3626, 44.483],
            [26.415, 44.5176],
          ],
        ],
      },
      properties: {
        name: 'Călăraşi',
        id: 'RO-CL',
        NAME_ENG: 'Calarasi',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-CL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.4263, 45.6654],
            [22.4536, 45.6557],
            [22.4755, 45.6201],
            [22.4944, 45.6141],
            [22.525, 45.5709],
            [22.5817, 45.581],
            [22.5958, 45.5598],
            [22.6409, 45.5596],
            [22.6469, 45.5361],
            [22.6889, 45.4865],
            [22.7095, 45.4089],
            [22.6447, 45.3282],
            [22.5929, 45.2963],
            [22.6043, 45.2697],
            [22.5906, 45.241],
            [22.6485, 45.2296],
            [22.6293, 45.2068],
            [22.5889, 45.1943],
            [22.562, 45.1513],
            [22.5948, 45.1315],
            [22.6512, 45.1243],
            [22.6613, 45.1035],
            [22.5577, 45.0111],
            [22.4902, 44.9707],
            [22.4902, 44.8743],
            [22.4712, 44.8645],
            [22.4464, 44.8159],
            [22.4134, 44.7993],
            [22.4023, 44.7761],
            [22.366, 44.7835],
            [22.3173, 44.7756],
            [22.2686, 44.7807],
            [22.2105, 44.8142],
            [22.1946, 44.7868],
            [22.2024, 44.7564],
            [22.158, 44.7375],
            [22.1501, 44.6799],
            [22.1392, 44.6606],
            [22.1756, 44.6384],
            [22.1691, 44.6039],
            [22.1093, 44.5942],
            [22.0938, 44.6165],
            [22.0498, 44.5969],
            [22.0084, 44.6045],
            [21.9926, 44.6334],
            [21.9216, 44.648],
            [21.8062, 44.6549],
            [21.7922, 44.664],
            [21.7223, 44.6548],
            [21.6924, 44.6659],
            [21.6311, 44.663],
            [21.6148, 44.6792],
            [21.6128, 44.7155],
            [21.5839, 44.7603],
            [21.5337, 44.7734],
            [21.3995, 44.7777],
            [21.3493, 44.843],
            [21.3661, 44.869],
            [21.4905, 44.8711],
            [21.5565, 44.8887],
            [21.5416, 44.9309],
            [21.4662, 44.9582],
            [21.4344, 44.9602],
            [21.4032, 44.9784],
            [21.3563, 45.0201],
            [21.4019, 45.0355],
            [21.4519, 45.0391],
            [21.4535, 45.0702],
            [21.4795, 45.0903],
            [21.4747, 45.1203],
            [21.5247, 45.1395],
            [21.5121, 45.1695],
            [21.4802, 45.1888],
            [21.5146, 45.1996],
            [21.5322, 45.2617],
            [21.5516, 45.2918],
            [21.5205, 45.3152],
            [21.5107, 45.34],
            [21.4584, 45.3676],
            [21.4426, 45.4097],
            [21.4706, 45.4297],
            [21.4596, 45.4731],
            [21.5378, 45.4891],
            [21.5952, 45.5649],
            [21.6899, 45.5775],
            [21.7031, 45.5588],
            [21.742, 45.5509],
            [21.7609, 45.5273],
            [21.8306, 45.5368],
            [21.9072, 45.5286],
            [21.9543, 45.518],
            [21.9399, 45.4948],
            [21.9739, 45.4845],
            [22.0275, 45.4855],
            [22.0549, 45.5109],
            [22.045, 45.5697],
            [22.073, 45.569],
            [22.1044, 45.5893],
            [22.1359, 45.5939],
            [22.1615, 45.627],
            [22.2564, 45.5821],
            [22.2762, 45.5889],
            [22.3027, 45.6433],
            [22.4029, 45.6488],
            [22.4263, 45.6654],
          ],
        ],
      },
      properties: {
        name: 'Caraş-Severin',
        id: 'RO-CS',
        NAME_ENG: 'Caras-Severin',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-CS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.0739, 44.8003],
            [28.115, 44.7914],
            [28.1537, 44.7984],
            [28.2206, 44.764],
            [28.2597, 44.7632],
            [28.258, 44.7184],
            [28.3149, 44.6997],
            [28.3389, 44.6775],
            [28.4037, 44.6716],
            [28.4229, 44.6445],
            [28.4819, 44.6617],
            [28.4847, 44.6425],
            [28.5414, 44.6416],
            [28.5449, 44.6617],
            [28.5874, 44.6581],
            [28.6953, 44.6674],
            [28.7045, 44.6859],
            [28.7381, 44.6911],
            [28.7762, 44.6746],
            [28.7996, 44.6167],
            [28.7619, 44.6174],
            [28.7623, 44.5789],
            [28.7448, 44.5508],
            [28.777, 44.5479],
            [28.7507, 44.4941],
            [28.7501, 44.4672],
            [28.8597, 44.5625],
            [28.9049, 44.5737],
            [28.851, 44.5001],
            [28.7483, 44.412],
            [28.6905, 44.3443],
            [28.6622, 44.3437],
            [28.6248, 44.2895],
            [28.6231, 44.2488],
            [28.6511, 44.2086],
            [28.6557, 44.1783],
            [28.6401, 44.1547],
            [28.6424, 44.0538],
            [28.6675, 43.9878],
            [28.6386, 43.9492],
            [28.6357, 43.9212],
            [28.6128, 43.896],
            [28.6076, 43.849],
            [28.5915, 43.8383],
            [28.5777, 43.7919],
            [28.5791, 43.7388],
            [28.4462, 43.7336],
            [28.3469, 43.7523],
            [28.2331, 43.759],
            [28.1079, 43.8001],
            [27.9937, 43.8432],
            [27.9773, 43.8977],
            [27.9534, 43.942],
            [27.9447, 43.9829],
            [27.9171, 44.0082],
            [27.8444, 43.9666],
            [27.7547, 43.9584],
            [27.7103, 43.9596],
            [27.6748, 44.0261],
            [27.6454, 44.0438],
            [27.6134, 44.0082],
            [27.474, 44.0179],
            [27.4019, 44.0078],
            [27.3585, 44.0528],
            [27.2928, 44.07],
            [27.2751, 44.1202],
            [27.3343, 44.1332],
            [27.3571, 44.1232],
            [27.395, 44.1271],
            [27.4547, 44.1197],
            [27.5253, 44.1623],
            [27.601, 44.1934],
            [27.642, 44.2008],
            [27.7298, 44.1937],
            [27.7908, 44.2319],
            [27.833, 44.2439],
            [27.9348, 44.2467],
            [27.994, 44.2999],
            [28.0164, 44.3364],
            [28.0232, 44.3577],
            [28.0721, 44.4114],
            [28.104, 44.4279],
            [28.0903, 44.4861],
            [28.0492, 44.5133],
            [28.0121, 44.5719],
            [28.0334, 44.6172],
            [28.0128, 44.6504],
            [27.9696, 44.6777],
            [27.9383, 44.6777],
            [27.8833, 44.7056],
            [27.8717, 44.725],
            [27.8786, 44.7596],
            [27.9504, 44.7785],
            [27.9979, 44.7587],
            [28.0339, 44.7608],
            [28.0739, 44.8003],
          ],
        ],
      },
      properties: {
        name: 'Constanţa',
        id: 'RO-CT',
        NAME_ENG: 'Constanta',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-CT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.4536, 46.1138],
            [25.4851, 46.1229],
            [25.5104, 46.1833],
            [25.5711, 46.2081],
            [25.5968, 46.2372],
            [25.6271, 46.2407],
            [25.6758, 46.2622],
            [25.6883, 46.282],
            [25.7406, 46.2624],
            [25.7266, 46.2482],
            [25.7603, 46.2232],
            [25.7974, 46.1824],
            [25.8031, 46.163],
            [25.8509, 46.1186],
            [25.9197, 46.129],
            [26.0173, 46.1273],
            [26.0344, 46.1612],
            [26.0833, 46.1559],
            [26.1306, 46.1695],
            [26.2092, 46.1755],
            [26.2213, 46.2013],
            [26.2588, 46.2138],
            [26.2605, 46.2455],
            [26.3211, 46.233],
            [26.3401, 46.1999],
            [26.3305, 46.1762],
            [26.3452, 46.1558],
            [26.3376, 46.1196],
            [26.3823, 46.0947],
            [26.4237, 46.0955],
            [26.4373, 46.042],
            [26.4374, 45.9884],
            [26.3974, 45.9659],
            [26.3928, 45.9202],
            [26.3747, 45.9015],
            [26.3857, 45.8759],
            [26.3703, 45.8446],
            [26.3717, 45.8176],
            [26.39, 45.7988],
            [26.3799, 45.7779],
            [26.3858, 45.7331],
            [26.3498, 45.7206],
            [26.3247, 45.6504],
            [26.3284, 45.6271],
            [26.2791, 45.5829],
            [26.2447, 45.6164],
            [26.2124, 45.6178],
            [26.1849, 45.6045],
            [26.1781, 45.57],
            [26.1355, 45.5192],
            [26.0908, 45.5148],
            [26.0982, 45.5483],
            [26.091, 45.5894],
            [26.0538, 45.6163],
            [26.0109, 45.6162],
            [26.0179, 45.6366],
            [25.9602, 45.6485],
            [25.9784, 45.6656],
            [25.9658, 45.686],
            [25.9085, 45.7076],
            [25.8639, 45.7052],
            [25.7869, 45.761],
            [25.7509, 45.7602],
            [25.7344, 45.7755],
            [25.6958, 45.759],
            [25.6258, 45.8061],
            [25.6168, 45.8209],
            [25.5783, 45.8268],
            [25.574, 45.8658],
            [25.6023, 45.8893],
            [25.5582, 45.8943],
            [25.5423, 45.9296],
            [25.5475, 45.9941],
            [25.5734, 46.0232],
            [25.5516, 46.0504],
            [25.4936, 46.0382],
            [25.4449, 46.0812],
            [25.4536, 46.1138],
          ],
        ],
      },
      properties: {
        name: 'Covasna',
        id: 'RO-CV',
        NAME_ENG: 'Covasna',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-CV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.4494, 45.4393],
            [25.4679, 45.425],
            [25.4796, 45.3896],
            [25.4639, 45.3322],
            [25.5278, 45.3065],
            [25.5535, 45.2671],
            [25.5325, 45.2547],
            [25.5203, 45.2206],
            [25.5291, 45.1861],
            [25.5609, 45.1843],
            [25.5646, 45.1615],
            [25.5963, 45.1472],
            [25.615, 45.1085],
            [25.6767, 45.0735],
            [25.6855, 45.0263],
            [25.6775, 45.0078],
            [25.6822, 44.9543],
            [25.7339, 44.9597],
            [25.7483, 44.9423],
            [25.7776, 44.9472],
            [25.8201, 44.909],
            [25.814, 44.8739],
            [25.7889, 44.8525],
            [25.8359, 44.8176],
            [25.8737, 44.8283],
            [25.9021, 44.7931],
            [25.953, 44.7472],
            [25.9127, 44.7212],
            [25.9358, 44.6962],
            [25.971, 44.7204],
            [25.9801, 44.6681],
            [25.9385, 44.646],
            [25.9909, 44.6142],
            [25.9486, 44.5811],
            [25.906, 44.5629],
            [25.8755, 44.5839],
            [25.8552, 44.5354],
            [25.8244, 44.5414],
            [25.7841, 44.5345],
            [25.7293, 44.5356],
            [25.6812, 44.5216],
            [25.6337, 44.5548],
            [25.5911, 44.5229],
            [25.5661, 44.5357],
            [25.4933, 44.5085],
            [25.4587, 44.4694],
            [25.4535, 44.4986],
            [25.3936, 44.5166],
            [25.3437, 44.5102],
            [25.3672, 44.4718],
            [25.3265, 44.4482],
            [25.3032, 44.4206],
            [25.2509, 44.4091],
            [25.2132, 44.4443],
            [25.2593, 44.438],
            [25.278, 44.4782],
            [25.2581, 44.5119],
            [25.2741, 44.5225],
            [25.269, 44.5562],
            [25.2254, 44.5952],
            [25.1946, 44.6123],
            [25.2027, 44.632],
            [25.1927, 44.6615],
            [25.226, 44.6975],
            [25.1799, 44.7161],
            [25.1909, 44.7338],
            [25.2009, 44.797],
            [25.1968, 44.8588],
            [25.1661, 44.9463],
            [25.1758, 44.985],
            [25.1997, 45.0036],
            [25.189, 45.0277],
            [25.1452, 45.0389],
            [25.1272, 45.0597],
            [25.1621, 45.0749],
            [25.1755, 45.1033],
            [25.1444, 45.1421],
            [25.156, 45.1929],
            [25.1343, 45.2164],
            [25.1541, 45.2312],
            [25.1989, 45.1806],
            [25.2498, 45.1782],
            [25.2547, 45.2151],
            [25.2915, 45.2324],
            [25.2794, 45.2681],
            [25.3048, 45.2905],
            [25.3115, 45.3352],
            [25.3258, 45.3533],
            [25.3216, 45.3797],
            [25.3664, 45.3887],
            [25.405, 45.3857],
            [25.4083, 45.4188],
            [25.4494, 45.4393],
          ],
        ],
      },
      properties: {
        name: 'Dâmboviţa',
        id: 'RO-DB',
        NAME_ENG: 'Dîmbovita',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-DB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.4544, 44.5685],
            [23.4927, 44.5749],
            [23.5389, 44.6069],
            [23.588, 44.6117],
            [23.6272, 44.5872],
            [23.6922, 44.6003],
            [23.7218, 44.6192],
            [23.7113, 44.6563],
            [23.7208, 44.7098],
            [23.764, 44.7079],
            [23.7752, 44.6876],
            [23.766, 44.6644],
            [23.8153, 44.6377],
            [23.9, 44.6222],
            [23.8934, 44.5842],
            [23.9134, 44.5764],
            [23.9286, 44.5277],
            [23.8917, 44.4831],
            [23.8875, 44.4338],
            [23.961, 44.3802],
            [24.0436, 44.3636],
            [24.0693, 44.3187],
            [24.1038, 44.2907],
            [24.1142, 44.2653],
            [24.1507, 44.2596],
            [24.1834, 44.2309],
            [24.2087, 44.2343],
            [24.2291, 44.2106],
            [24.2143, 44.1726],
            [24.2216, 44.1487],
            [24.1942, 44.0184],
            [24.2314, 43.9926],
            [24.2095, 43.9563],
            [24.2332, 43.9409],
            [24.211, 43.8567],
            [24.1754, 43.8341],
            [24.1368, 43.7798],
            [24.1134, 43.7093],
            [24.1459, 43.6885],
            [24.0891, 43.7],
            [24.058, 43.7179],
            [23.973, 43.7424],
            [23.8623, 43.7535],
            [23.766, 43.7829],
            [23.7233, 43.8034],
            [23.6136, 43.7896],
            [23.5599, 43.8028],
            [23.5149, 43.8326],
            [23.4276, 43.8491],
            [23.2725, 43.8446],
            [23.2198, 43.8332],
            [23.1439, 43.8057],
            [23.0658, 43.7937],
            [22.9689, 43.8091],
            [22.8775, 43.8325],
            [22.8563, 43.8467],
            [22.8409, 43.8812],
            [22.8814, 43.9776],
            [22.9309, 44.0013],
            [23.0102, 44.0109],
            [23.0447, 44.0529],
            [23.0332, 44.0878],
            [22.9698, 44.0978],
            [22.9805, 44.122],
            [23.0382, 44.1647],
            [23.101, 44.1468],
            [23.1192, 44.2308],
            [23.0817, 44.2449],
            [23.1085, 44.2617],
            [23.1345, 44.3209],
            [23.1807, 44.3284],
            [23.1923, 44.3655],
            [23.2426, 44.3708],
            [23.24, 44.3844],
            [23.1977, 44.4025],
            [23.1861, 44.4271],
            [23.1976, 44.4653],
            [23.2771, 44.4989],
            [23.254, 44.5243],
            [23.3387, 44.5227],
            [23.3953, 44.5377],
            [23.4156, 44.5247],
            [23.4503, 44.5513],
            [23.4544, 44.5685],
          ],
        ],
      },
      properties: {
        name: 'Dolj',
        id: 'RO-DJ',
        NAME_ENG: 'Dolj',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-DJ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.5899, 45.3511],
            [23.6289, 45.3332],
            [23.6412, 45.349],
            [23.6846, 45.3286],
            [23.7373, 45.3315],
            [23.7915, 45.3485],
            [23.843, 45.332],
            [23.8421, 45.3086],
            [23.7969, 45.2568],
            [23.8151, 45.2115],
            [23.8492, 45.1757],
            [23.856, 45.1447],
            [23.8153, 45.1253],
            [23.8241, 45.1021],
            [23.8171, 45.0704],
            [23.7843, 45.0121],
            [23.7574, 45.0013],
            [23.7697, 44.9597],
            [23.7685, 44.9037],
            [23.7762, 44.8664],
            [23.7974, 44.8301],
            [23.7817, 44.8249],
            [23.7704, 44.7782],
            [23.7827, 44.7441],
            [23.764, 44.7079],
            [23.7208, 44.7098],
            [23.7113, 44.6563],
            [23.7218, 44.6192],
            [23.6922, 44.6003],
            [23.6272, 44.5872],
            [23.588, 44.6117],
            [23.5389, 44.6069],
            [23.4927, 44.5749],
            [23.4544, 44.5685],
            [23.4113, 44.6083],
            [23.3034, 44.6511],
            [23.2269, 44.677],
            [23.192, 44.6762],
            [23.142, 44.6891],
            [23.0888, 44.6943],
            [23.0674, 44.7233],
            [23.0034, 44.7464],
            [22.9858, 44.7776],
            [22.9073, 44.8209],
            [22.8968, 44.878],
            [22.8751, 44.947],
            [22.8971, 44.9632],
            [22.8746, 44.9904],
            [22.8528, 44.9898],
            [22.8308, 45.0214],
            [22.7114, 45.067],
            [22.688, 45.0987],
            [22.6613, 45.1035],
            [22.6512, 45.1243],
            [22.5948, 45.1315],
            [22.562, 45.1513],
            [22.5889, 45.1943],
            [22.6293, 45.2068],
            [22.6485, 45.2296],
            [22.6806, 45.2475],
            [22.8027, 45.2712],
            [22.8257, 45.281],
            [22.8629, 45.2605],
            [22.8808, 45.2679],
            [22.9586, 45.2708],
            [22.9826, 45.2883],
            [23.0231, 45.2252],
            [23.0399, 45.223],
            [23.0836, 45.2501],
            [23.0896, 45.2885],
            [23.1567, 45.2711],
            [23.2108, 45.2847],
            [23.2509, 45.3057],
            [23.3278, 45.306],
            [23.3346, 45.3274],
            [23.373, 45.3286],
            [23.4494, 45.293],
            [23.5402, 45.3368],
            [23.5899, 45.3511],
          ],
        ],
      },
      properties: {
        name: 'Gorj',
        id: 'RO-GJ',
        NAME_ENG: 'Gorj',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-GJ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.4807, 46.1305],
            [27.5075, 46.1294],
            [27.5299, 46.1039],
            [27.5157, 46.053],
            [27.5407, 46.0206],
            [27.5754, 46.0033],
            [27.6043, 46.0165],
            [27.605, 46.0524],
            [27.6271, 46.0591],
            [27.5863, 46.118],
            [27.5898, 46.1473],
            [27.6675, 46.1241],
            [27.7559, 46.1302],
            [27.7751, 46.1088],
            [27.8065, 46.1115],
            [27.8495, 46.1363],
            [27.9117, 46.1511],
            [27.9398, 46.1335],
            [27.966, 46.139],
            [27.9799, 46.1072],
            [28.0198, 46.103],
            [28.0772, 46.1155],
            [28.116, 46.1094],
            [28.0888, 46.0673],
            [28.1035, 46.0528],
            [28.0843, 46.0082],
            [28.1264, 45.9223],
            [28.1132, 45.8969],
            [28.1337, 45.875],
            [28.1068, 45.8415],
            [28.1714, 45.7072],
            [28.1635, 45.6585],
            [28.1744, 45.6427],
            [28.1444, 45.6193],
            [28.1152, 45.6246],
            [28.0944, 45.5958],
            [28.1393, 45.5753],
            [28.1665, 45.5431],
            [28.1636, 45.4985],
            [28.1878, 45.4719],
            [28.2178, 45.4647],
            [28.1767, 45.4136],
            [28.1373, 45.4348],
            [28.0957, 45.4388],
            [28.0612, 45.4267],
            [28.0283, 45.4022],
            [27.9835, 45.3896],
            [27.9564, 45.4018],
            [27.8716, 45.4154],
            [27.8577, 45.4011],
            [27.8143, 45.3935],
            [27.7414, 45.4129],
            [27.7491, 45.4461],
            [27.6607, 45.4702],
            [27.6122, 45.4668],
            [27.6001, 45.4828],
            [27.5538, 45.4825],
            [27.5207, 45.523],
            [27.5286, 45.5438],
            [27.5045, 45.5582],
            [27.5189, 45.5867],
            [27.5128, 45.6173],
            [27.4648, 45.6339],
            [27.4485, 45.6752],
            [27.4028, 45.6792],
            [27.3617, 45.7262],
            [27.3571, 45.7654],
            [27.3287, 45.7913],
            [27.3218, 45.8242],
            [27.2871, 45.8806],
            [27.2273, 45.9957],
            [27.2274, 46.0185],
            [27.2816, 46.0389],
            [27.299, 46.0758],
            [27.3648, 46.0777],
            [27.4241, 46.0872],
            [27.4265, 46.1362],
            [27.4725, 46.1125],
            [27.4807, 46.1305],
          ],
        ],
      },
      properties: {
        name: 'Galaţi',
        id: 'RO-GL',
        NAME_ENG: 'Galati',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-GL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.4587, 44.4694],
            [25.4933, 44.5085],
            [25.5661, 44.5357],
            [25.5911, 44.5229],
            [25.6337, 44.5548],
            [25.6812, 44.5216],
            [25.7293, 44.5356],
            [25.7841, 44.5345],
            [25.8244, 44.5414],
            [25.8552, 44.5354],
            [25.8906, 44.5385],
            [25.9223, 44.5115],
            [25.8992, 44.4802],
            [25.8968, 44.4493],
            [25.8627, 44.4513],
            [25.8678, 44.4152],
            [25.8288, 44.3975],
            [25.892, 44.344],
            [25.9729, 44.327],
            [25.9997, 44.2972],
            [26.0317, 44.2905],
            [26.0594, 44.2643],
            [26.1411, 44.2314],
            [26.2171, 44.2612],
            [26.2515, 44.2934],
            [26.284, 44.278],
            [26.2945, 44.257],
            [26.3554, 44.2382],
            [26.3618, 44.2202],
            [26.3987, 44.2187],
            [26.413, 44.1976],
            [26.4264, 44.1401],
            [26.3484, 44.0972],
            [26.3661, 44.0368],
            [26.3067, 44.0263],
            [26.2019, 43.9856],
            [26.1493, 43.9855],
            [26.0818, 43.9458],
            [26.0571, 43.9074],
            [25.9533, 43.8613],
            [25.9265, 43.8375],
            [25.8784, 43.7784],
            [25.8503, 43.7585],
            [25.8115, 43.7459],
            [25.783, 43.7094],
            [25.7308, 43.6892],
            [25.6709, 43.6887],
            [25.6619, 43.7254],
            [25.6518, 43.8198],
            [25.5983, 43.8711],
            [25.6167, 43.8813],
            [25.5952, 44.0423],
            [25.6302, 44.0489],
            [25.6162, 44.0731],
            [25.6403, 44.1013],
            [25.6773, 44.0976],
            [25.6959, 44.1438],
            [25.6547, 44.163],
            [25.6938, 44.2521],
            [25.6659, 44.2765],
            [25.6192, 44.2868],
            [25.6279, 44.301],
            [25.5569, 44.3156],
            [25.5034, 44.3127],
            [25.4686, 44.3222],
            [25.4723, 44.3485],
            [25.5086, 44.3932],
            [25.4827, 44.4192],
            [25.4803, 44.4488],
            [25.4587, 44.4694],
          ],
        ],
      },
      properties: {
        name: 'Giurgiu',
        id: 'RO-GR',
        NAME_ENG: 'Giurgiu',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-GR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.7539, 46.3491],
            [22.779, 46.3473],
            [22.8112, 46.3227],
            [22.9143, 46.3295],
            [22.9613, 46.2831],
            [22.9472, 46.2639],
            [22.9996, 46.2104],
            [23.0525, 46.209],
            [23.0942, 46.1823],
            [23.0733, 46.1137],
            [23.0895, 46.0969],
            [23.0882, 46.0538],
            [23.1433, 46.0447],
            [23.2138, 46.0128],
            [23.2331, 45.9979],
            [23.2661, 45.9319],
            [23.3071, 45.915],
            [23.3331, 45.8832],
            [23.3239, 45.8523],
            [23.3513, 45.7749],
            [23.3827, 45.752],
            [23.3625, 45.7094],
            [23.419, 45.6718],
            [23.3858, 45.6383],
            [23.427, 45.6233],
            [23.4256, 45.5858],
            [23.501, 45.5751],
            [23.5122, 45.5473],
            [23.5341, 45.5485],
            [23.5725, 45.5225],
            [23.5949, 45.489],
            [23.5962, 45.4676],
            [23.5754, 45.4239],
            [23.6018, 45.3755],
            [23.5899, 45.3511],
            [23.5402, 45.3368],
            [23.4494, 45.293],
            [23.373, 45.3286],
            [23.3346, 45.3274],
            [23.3278, 45.306],
            [23.2509, 45.3057],
            [23.2108, 45.2847],
            [23.1567, 45.2711],
            [23.0896, 45.2885],
            [23.0836, 45.2501],
            [23.0399, 45.223],
            [23.0231, 45.2252],
            [22.9826, 45.2883],
            [22.9586, 45.2708],
            [22.8808, 45.2679],
            [22.8629, 45.2605],
            [22.8257, 45.281],
            [22.8027, 45.2712],
            [22.6806, 45.2475],
            [22.6485, 45.2296],
            [22.5906, 45.241],
            [22.6043, 45.2697],
            [22.5929, 45.2963],
            [22.6447, 45.3282],
            [22.7095, 45.4089],
            [22.6889, 45.4865],
            [22.6469, 45.5361],
            [22.6409, 45.5596],
            [22.5958, 45.5598],
            [22.5817, 45.581],
            [22.525, 45.5709],
            [22.4944, 45.6141],
            [22.4755, 45.6201],
            [22.4536, 45.6557],
            [22.4263, 45.6654],
            [22.4658, 45.7023],
            [22.4706, 45.7283],
            [22.5177, 45.7371],
            [22.5466, 45.7761],
            [22.524, 45.7936],
            [22.4807, 45.7859],
            [22.4625, 45.8455],
            [22.4138, 45.8487],
            [22.3763, 45.885],
            [22.4088, 45.9174],
            [22.4318, 45.9435],
            [22.4087, 45.9558],
            [22.3654, 45.9557],
            [22.3412, 45.9778],
            [22.3473, 45.9965],
            [22.394, 46.0084],
            [22.4334, 46.0083],
            [22.4408, 46.0259],
            [22.4296, 46.0866],
            [22.4369, 46.1174],
            [22.481, 46.1423],
            [22.4893, 46.1593],
            [22.4539, 46.1749],
            [22.4381, 46.2078],
            [22.4664, 46.2261],
            [22.5111, 46.2166],
            [22.6562, 46.2283],
            [22.659, 46.2642],
            [22.6811, 46.2962],
            [22.6775, 46.318],
            [22.7207, 46.3282],
            [22.7539, 46.3491],
          ],
        ],
      },
      properties: {
        name: 'Hunedoara',
        id: 'RO-HD',
        NAME_ENG: 'Hunedoara',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-HD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.238, 47.0875],
            [25.2817, 47.0999],
            [25.3098, 47.1186],
            [25.3485, 47.101],
            [25.403, 47.0942],
            [25.4166, 47.1231],
            [25.4655, 47.1325],
            [25.529, 47.1093],
            [25.5344, 47.0814],
            [25.6015, 47.0734],
            [25.6231, 47.0923],
            [25.6588, 47.0859],
            [25.7347, 47.0691],
            [25.7745, 47.0274],
            [25.8029, 46.9629],
            [25.8504, 46.9627],
            [25.8437, 46.9333],
            [25.8015, 46.9135],
            [25.7616, 46.8813],
            [25.781, 46.8636],
            [25.7715, 46.8332],
            [25.8088, 46.8202],
            [25.8172, 46.7493],
            [25.7877, 46.7228],
            [25.8688, 46.6501],
            [25.9025, 46.6495],
            [25.8925, 46.6984],
            [25.9782, 46.6892],
            [26.0005, 46.6687],
            [26.0083, 46.6381],
            [25.9963, 46.6023],
            [26.0619, 46.5335],
            [26.0713, 46.5066],
            [26.0138, 46.467],
            [26.0063, 46.4213],
            [26.0613, 46.4056],
            [26.1854, 46.4013],
            [26.1662, 46.3681],
            [26.1552, 46.3107],
            [26.2087, 46.3111],
            [26.2416, 46.3334],
            [26.2905, 46.3274],
            [26.2804, 46.2967],
            [26.2526, 46.271],
            [26.2605, 46.2455],
            [26.2588, 46.2138],
            [26.2213, 46.2013],
            [26.2092, 46.1755],
            [26.1306, 46.1695],
            [26.0833, 46.1559],
            [26.0344, 46.1612],
            [26.0173, 46.1273],
            [25.9197, 46.129],
            [25.8509, 46.1186],
            [25.8031, 46.163],
            [25.7974, 46.1824],
            [25.7603, 46.2232],
            [25.7266, 46.2482],
            [25.7406, 46.2624],
            [25.6883, 46.282],
            [25.6758, 46.2622],
            [25.6271, 46.2407],
            [25.5968, 46.2372],
            [25.5711, 46.2081],
            [25.5104, 46.1833],
            [25.4851, 46.1229],
            [25.4536, 46.1138],
            [25.4515, 46.1228],
            [25.372, 46.1326],
            [25.3403, 46.1723],
            [25.3008, 46.1799],
            [25.2643, 46.1654],
            [25.2529, 46.1375],
            [25.1894, 46.1685],
            [25.1722, 46.165],
            [25.1287, 46.1869],
            [25.1041, 46.2314],
            [25.0525, 46.2441],
            [24.9495, 46.245],
            [24.9282, 46.2727],
            [24.8554, 46.3059],
            [24.8588, 46.3336],
            [24.8404, 46.37],
            [24.8536, 46.38],
            [24.9064, 46.3797],
            [24.9382, 46.3645],
            [24.9741, 46.3777],
            [24.939, 46.4215],
            [25.0043, 46.4526],
            [25.0315, 46.4848],
            [25.0127, 46.513],
            [25.0227, 46.5374],
            [25.0658, 46.5513],
            [25.0864, 46.5724],
            [25.1405, 46.5684],
            [25.2366, 46.6532],
            [25.2939, 46.6818],
            [25.2847, 46.7058],
            [25.2974, 46.7348],
            [25.2851, 46.8296],
            [25.2544, 46.8395],
            [25.2838, 46.9335],
            [25.2726, 46.9657],
            [25.2722, 47.029],
            [25.2331, 47.058],
            [25.238, 47.0875],
          ],
        ],
      },
      properties: {
        name: 'Harghita',
        id: 'RO-HR',
        NAME_ENG: 'Harghita',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-HR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.2515, 44.2934],
            [26.2171, 44.2612],
            [26.1411, 44.2314],
            [26.0594, 44.2643],
            [26.0317, 44.2905],
            [25.9997, 44.2972],
            [25.9729, 44.327],
            [25.892, 44.344],
            [25.8288, 44.3975],
            [25.8678, 44.4152],
            [25.8627, 44.4513],
            [25.8968, 44.4493],
            [25.8992, 44.4802],
            [25.9223, 44.5115],
            [25.8906, 44.5385],
            [25.8552, 44.5354],
            [25.8755, 44.5839],
            [25.906, 44.5629],
            [25.9486, 44.5811],
            [25.9909, 44.6142],
            [25.9385, 44.646],
            [25.9801, 44.6681],
            [25.971, 44.7204],
            [25.982, 44.7433],
            [26.0974, 44.739],
            [26.1509, 44.7576],
            [26.217, 44.7487],
            [26.2524, 44.7353],
            [26.265, 44.7546],
            [26.3012, 44.7647],
            [26.3467, 44.7349],
            [26.3669, 44.7067],
            [26.3439, 44.6834],
            [26.3745, 44.6206],
            [26.4476, 44.6394],
            [26.4519, 44.6108],
            [26.4263, 44.5977],
            [26.4056, 44.612],
            [26.3466, 44.5502],
            [26.3622, 44.5254],
            [26.415, 44.5176],
            [26.3626, 44.483],
            [26.3938, 44.4555],
            [26.457, 44.4569],
            [26.4633, 44.4443],
            [26.4025, 44.4155],
            [26.3727, 44.4183],
            [26.3227, 44.3958],
            [26.315, 44.3697],
            [26.2864, 44.3427],
            [26.2763, 44.314],
            [26.2515, 44.2934],
          ],
          [
            [26.245, 44.4256],
            [26.1781, 44.4414],
            [26.1992, 44.4649],
            [26.1299, 44.4726],
            [26.1106, 44.5229],
            [26.0618, 44.5241],
            [26.0196, 44.5065],
            [25.9874, 44.4833],
            [25.9849, 44.4589],
            [26.0244, 44.4296],
            [25.9723, 44.4278],
            [25.9842, 44.393],
            [26.0256, 44.388],
            [26.0604, 44.3618],
            [26.1422, 44.3433],
            [26.1774, 44.3887],
            [26.2233, 44.395],
            [26.245, 44.4256],
          ],
        ],
      },
      properties: {
        name: 'Ilfov',
        id: 'RO-IF',
        NAME_ENG: '',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-IF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.6087, 44.8569],
            [26.6303, 44.8112],
            [26.719, 44.8068],
            [26.7698, 44.8129],
            [26.7909, 44.7905],
            [26.8253, 44.7813],
            [26.8416, 44.797],
            [26.9106, 44.807],
            [26.9953, 44.7908],
            [27.0197, 44.8143],
            [27.1113, 44.7787],
            [27.0988, 44.7435],
            [27.1809, 44.7296],
            [27.1941, 44.7508],
            [27.1481, 44.7638],
            [27.1531, 44.7859],
            [27.2064, 44.7922],
            [27.2275, 44.7673],
            [27.2683, 44.7432],
            [27.316, 44.8031],
            [27.3927, 44.7733],
            [27.431, 44.7787],
            [27.5793, 44.782],
            [27.7119, 44.7628],
            [27.8316, 44.7768],
            [27.8786, 44.7596],
            [27.8717, 44.725],
            [27.8833, 44.7056],
            [27.9383, 44.6777],
            [27.9696, 44.6777],
            [28.0128, 44.6504],
            [28.0334, 44.6172],
            [28.0121, 44.5719],
            [28.0492, 44.5133],
            [28.0903, 44.4861],
            [28.104, 44.4279],
            [28.0721, 44.4114],
            [28.0232, 44.3577],
            [28.0164, 44.3364],
            [27.9512, 44.3588],
            [27.8553, 44.3758],
            [27.8349, 44.3422],
            [27.8148, 44.3321],
            [27.775, 44.3389],
            [27.7502, 44.3591],
            [27.7685, 44.3912],
            [27.7297, 44.413],
            [27.6776, 44.4203],
            [27.65, 44.4361],
            [27.6506, 44.464],
            [27.5788, 44.4721],
            [27.5722, 44.556],
            [27.548, 44.5603],
            [27.5254, 44.5412],
            [27.5181, 44.4948],
            [27.3923, 44.4927],
            [27.3544, 44.4829],
            [27.2268, 44.4903],
            [27.2069, 44.4836],
            [27.1378, 44.5002],
            [27.1321, 44.482],
            [27.0871, 44.4837],
            [27.0863, 44.5088],
            [27.0258, 44.5051],
            [27.0277, 44.5288],
            [26.955, 44.5275],
            [26.8931, 44.5133],
            [26.8769, 44.5256],
            [26.805, 44.5071],
            [26.6991, 44.5402],
            [26.7031, 44.5652],
            [26.6673, 44.5743],
            [26.5904, 44.5194],
            [26.5441, 44.5313],
            [26.514, 44.5659],
            [26.4801, 44.582],
            [26.4351, 44.5476],
            [26.415, 44.5176],
            [26.3622, 44.5254],
            [26.3466, 44.5502],
            [26.4056, 44.612],
            [26.4263, 44.5977],
            [26.4519, 44.6108],
            [26.4476, 44.6394],
            [26.3745, 44.6206],
            [26.3439, 44.6834],
            [26.3669, 44.7067],
            [26.3467, 44.7349],
            [26.3012, 44.7647],
            [26.3145, 44.7789],
            [26.3642, 44.7807],
            [26.398, 44.7663],
            [26.4577, 44.8093],
            [26.4944, 44.7915],
            [26.6087, 44.8569],
          ],
        ],
      },
      properties: {
        name: 'Ialomiţa',
        id: 'RO-IL',
        NAME_ENG: 'Ialomita',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-IL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.1144, 46.8436],
            [28.0269, 46.8365],
            [27.9924, 46.8392],
            [27.9782, 46.8554],
            [27.9342, 46.8419],
            [27.9289, 46.8188],
            [27.8231, 46.8816],
            [27.7514, 46.8854],
            [27.7286, 46.9171],
            [27.6614, 46.9687],
            [27.6307, 46.9852],
            [27.6108, 46.9586],
            [27.6178, 46.9292],
            [27.644, 46.9007],
            [27.5991, 46.8893],
            [27.5243, 46.8981],
            [27.5295, 46.8762],
            [27.4078, 46.8719],
            [27.3848, 46.886],
            [27.2945, 46.8724],
            [27.2205, 46.8696],
            [27.1968, 46.8813],
            [27.1843, 46.9103],
            [27.1196, 46.9459],
            [27.1684, 46.9768],
            [27.235, 46.9932],
            [27.24, 47.0083],
            [27.183, 47.0295],
            [27.1715, 47.0773],
            [27.1166, 47.0858],
            [27.0576, 47.0818],
            [27.0319, 47.0372],
            [27.0013, 47.0396],
            [26.9835, 47.0755],
            [26.9597, 47.0547],
            [26.8906, 47.0269],
            [26.8214, 47.0361],
            [26.8077, 47.0629],
            [26.7496, 47.1053],
            [26.7095, 47.0904],
            [26.6845, 47.1111],
            [26.6305, 47.0919],
            [26.6307, 47.143],
            [26.6136, 47.1941],
            [26.5736, 47.2145],
            [26.5604, 47.2382],
            [26.5284, 47.2547],
            [26.5704, 47.2943],
            [26.5004, 47.3518],
            [26.4923, 47.3896],
            [26.5232, 47.3988],
            [26.5389, 47.3766],
            [26.5696, 47.3775],
            [26.6116, 47.3543],
            [26.6762, 47.3569],
            [26.6801, 47.3714],
            [26.6436, 47.3992],
            [26.679, 47.4129],
            [26.68, 47.4685],
            [26.7334, 47.49],
            [26.7444, 47.5191],
            [26.8457, 47.4931],
            [26.9981, 47.4894],
            [27.0413, 47.438],
            [27.0797, 47.4624],
            [27.0771, 47.5008],
            [27.0979, 47.5486],
            [27.1427, 47.5238],
            [27.2255, 47.5234],
            [27.2647, 47.5329],
            [27.2874, 47.5586],
            [27.3214, 47.5762],
            [27.3908, 47.5866],
            [27.4434, 47.533],
            [27.4721, 47.4838],
            [27.5418, 47.4752],
            [27.5709, 47.4487],
            [27.5686, 47.373],
            [27.6006, 47.3659],
            [27.6054, 47.3273],
            [27.6338, 47.3024],
            [27.6643, 47.3068],
            [27.6915, 47.2909],
            [27.7288, 47.294],
            [27.7583, 47.2562],
            [27.7457, 47.2405],
            [27.786, 47.2175],
            [27.7864, 47.1889],
            [27.8118, 47.1623],
            [27.7817, 47.1398],
            [27.8527, 47.1282],
            [27.9148, 47.066],
            [27.958, 47.0563],
            [27.9968, 47.0256],
            [28.0381, 47.0211],
            [28.0492, 46.9982],
            [28.097, 46.9768],
            [28.0785, 46.9607],
            [28.1115, 46.919],
            [28.1023, 46.8931],
            [28.117, 46.876],
            [28.1144, 46.8436],
          ],
        ],
      },
      properties: {
        name: 'Iaşi',
        id: 'RO-IS',
        NAME_ENG: 'Iasi',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-IS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.0084, 44.6045],
            [22.0498, 44.5969],
            [22.0938, 44.6165],
            [22.1093, 44.5942],
            [22.1691, 44.6039],
            [22.1756, 44.6384],
            [22.1392, 44.6606],
            [22.1501, 44.6799],
            [22.158, 44.7375],
            [22.2024, 44.7564],
            [22.1946, 44.7868],
            [22.2105, 44.8142],
            [22.2686, 44.7807],
            [22.3173, 44.7756],
            [22.366, 44.7835],
            [22.4023, 44.7761],
            [22.4134, 44.7993],
            [22.4464, 44.8159],
            [22.4712, 44.8645],
            [22.4902, 44.8743],
            [22.4902, 44.9707],
            [22.5577, 45.0111],
            [22.6613, 45.1035],
            [22.688, 45.0987],
            [22.7114, 45.067],
            [22.8308, 45.0214],
            [22.8528, 44.9898],
            [22.8746, 44.9904],
            [22.8971, 44.9632],
            [22.8751, 44.947],
            [22.8968, 44.878],
            [22.9073, 44.8209],
            [22.9858, 44.7776],
            [23.0034, 44.7464],
            [23.0674, 44.7233],
            [23.0888, 44.6943],
            [23.142, 44.6891],
            [23.192, 44.6762],
            [23.2269, 44.677],
            [23.3034, 44.6511],
            [23.4113, 44.6083],
            [23.4544, 44.5685],
            [23.4503, 44.5513],
            [23.4156, 44.5247],
            [23.3953, 44.5377],
            [23.3387, 44.5227],
            [23.254, 44.5243],
            [23.2771, 44.4989],
            [23.1976, 44.4653],
            [23.1861, 44.4271],
            [23.1977, 44.4025],
            [23.24, 44.3844],
            [23.2426, 44.3708],
            [23.1923, 44.3655],
            [23.1807, 44.3284],
            [23.1345, 44.3209],
            [23.1085, 44.2617],
            [23.0817, 44.2449],
            [23.1192, 44.2308],
            [23.101, 44.1468],
            [23.0382, 44.1647],
            [22.9805, 44.122],
            [22.9698, 44.0978],
            [22.9203, 44.1043],
            [22.8671, 44.1339],
            [22.8073, 44.1559],
            [22.7454, 44.1981],
            [22.6946, 44.2037],
            [22.6713, 44.2219],
            [22.6866, 44.2541],
            [22.657, 44.288],
            [22.559, 44.3052],
            [22.5232, 44.3426],
            [22.503, 44.3844],
            [22.5002, 44.4175],
            [22.4722, 44.4349],
            [22.4663, 44.4754],
            [22.5193, 44.4688],
            [22.5584, 44.4899],
            [22.5653, 44.5316],
            [22.5857, 44.5471],
            [22.6282, 44.5466],
            [22.6957, 44.5136],
            [22.758, 44.5345],
            [22.7551, 44.5615],
            [22.7092, 44.6007],
            [22.673, 44.6163],
            [22.6242, 44.6131],
            [22.5653, 44.6389],
            [22.4748, 44.7127],
            [22.4169, 44.7034],
            [22.3756, 44.6759],
            [22.3401, 44.676],
            [22.3033, 44.6538],
            [22.2757, 44.6238],
            [22.2613, 44.5899],
            [22.238, 44.5661],
            [22.1852, 44.4789],
            [22.1476, 44.4691],
            [22.0794, 44.4957],
            [22.0685, 44.5264],
            [22.0362, 44.542],
            [22.0255, 44.5915],
            [22.0084, 44.6045],
          ],
        ],
      },
      properties: {
        name: 'Mehedinţi',
        id: 'RO-MH',
        NAME_ENG: 'Mehedinti',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-MH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.9452, 47.7294],
            [24.9513, 47.7015],
            [24.9811, 47.6948],
            [25.0097, 47.6721],
            [25.0537, 47.6556],
            [25.0521, 47.6376],
            [25.0085, 47.6312],
            [24.9483, 47.5941],
            [24.8578, 47.603],
            [24.8247, 47.5978],
            [24.7868, 47.573],
            [24.723, 47.573],
            [24.6918, 47.5571],
            [24.6273, 47.5699],
            [24.5446, 47.5653],
            [24.4564, 47.5937],
            [24.3859, 47.6037],
            [24.3564, 47.5701],
            [24.3024, 47.5734],
            [24.2775, 47.5252],
            [24.2421, 47.4983],
            [24.1983, 47.5055],
            [24.1277, 47.4985],
            [24.1128, 47.4685],
            [24.1235, 47.4552],
            [24.1073, 47.4251],
            [24.0586, 47.378],
            [24.0219, 47.3599],
            [23.9996, 47.3665],
            [23.8946, 47.3559],
            [23.8563, 47.3683],
            [23.8437, 47.3342],
            [23.7943, 47.337],
            [23.7288, 47.3295],
            [23.7143, 47.3507],
            [23.6873, 47.3521],
            [23.6491, 47.384],
            [23.5636, 47.3643],
            [23.4948, 47.3773],
            [23.4371, 47.379],
            [23.402, 47.3938],
            [23.3275, 47.4096],
            [23.3156, 47.4246],
            [23.2595, 47.4289],
            [23.1818, 47.455],
            [23.0995, 47.4247],
            [23.0786, 47.4422],
            [23.0187, 47.4242],
            [22.9683, 47.4382],
            [22.9645, 47.4616],
            [23.0431, 47.508],
            [23.0541, 47.5537],
            [23.1437, 47.6238],
            [23.1764, 47.5877],
            [23.2226, 47.5754],
            [23.265, 47.5519],
            [23.284, 47.5749],
            [23.2844, 47.6098],
            [23.2976, 47.6439],
            [23.3263, 47.6448],
            [23.3932, 47.6841],
            [23.365, 47.6988],
            [23.2655, 47.7267],
            [23.2359, 47.7503],
            [23.2848, 47.7979],
            [23.3523, 47.7779],
            [23.3813, 47.7828],
            [23.4207, 47.7681],
            [23.4665, 47.7673],
            [23.4822, 47.782],
            [23.5621, 47.8048],
            [23.5764, 47.8333],
            [23.6156, 47.845],
            [23.6464, 47.8665],
            [23.6294, 47.892],
            [23.5946, 47.9018],
            [23.5672, 47.9442],
            [23.5451, 47.9432],
            [23.5015, 47.9685],
            [23.5426, 48.0099],
            [23.6163, 48.008],
            [23.6681, 47.9839],
            [23.7503, 47.9964],
            [23.8098, 47.9862],
            [23.8486, 47.9403],
            [23.943, 47.9474],
            [23.9541, 47.9626],
            [24.0099, 47.967],
            [24.0335, 47.9491],
            [24.063, 47.9524],
            [24.0959, 47.9374],
            [24.1131, 47.9149],
            [24.1932, 47.9181],
            [24.2214, 47.8985],
            [24.2817, 47.9066],
            [24.3256, 47.9265],
            [24.3506, 47.9152],
            [24.3828, 47.9268],
            [24.3943, 47.9538],
            [24.4361, 47.9702],
            [24.5116, 47.9513],
            [24.5838, 47.9656],
            [24.6286, 47.9498],
            [24.6376, 47.9244],
            [24.6744, 47.896],
            [24.6726, 47.8648],
            [24.7064, 47.8412],
            [24.7674, 47.8393],
            [24.7845, 47.825],
            [24.8288, 47.8201],
            [24.8365, 47.7807],
            [24.8776, 47.7534],
            [24.8846, 47.7242],
            [24.9452, 47.7294],
          ],
        ],
      },
      properties: {
        name: 'Maramureş',
        id: 'RO-MM',
        NAME_ENG: 'Maramures',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-MM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.0684, 47.1362],
            [25.0962, 47.1192],
            [25.1418, 47.1167],
            [25.1737, 47.129],
            [25.238, 47.0875],
            [25.2331, 47.058],
            [25.2722, 47.029],
            [25.2726, 46.9657],
            [25.2838, 46.9335],
            [25.2544, 46.8395],
            [25.2851, 46.8296],
            [25.2974, 46.7348],
            [25.2847, 46.7058],
            [25.2939, 46.6818],
            [25.2366, 46.6532],
            [25.1405, 46.5684],
            [25.0864, 46.5724],
            [25.0658, 46.5513],
            [25.0227, 46.5374],
            [25.0127, 46.513],
            [25.0315, 46.4848],
            [25.0043, 46.4526],
            [24.939, 46.4215],
            [24.9741, 46.3777],
            [24.9382, 46.3645],
            [24.9064, 46.3797],
            [24.8536, 46.38],
            [24.8404, 46.37],
            [24.8588, 46.3336],
            [24.8554, 46.3059],
            [24.9282, 46.2727],
            [24.9495, 46.245],
            [25.0525, 46.2441],
            [25.1041, 46.2314],
            [25.1287, 46.1869],
            [25.1722, 46.165],
            [25.1475, 46.138],
            [25.0982, 46.1491],
            [25.0591, 46.1375],
            [25.0152, 46.1449],
            [24.9545, 46.1074],
            [24.943, 46.0778],
            [24.9166, 46.0803],
            [24.8992, 46.1026],
            [24.8658, 46.1083],
            [24.8186, 46.0931],
            [24.716, 46.0762],
            [24.6727, 46.101],
            [24.6764, 46.1677],
            [24.6851, 46.1925],
            [24.6647, 46.2345],
            [24.669, 46.2809],
            [24.6425, 46.2754],
            [24.6238, 46.251],
            [24.5981, 46.2466],
            [24.5641, 46.2602],
            [24.5361, 46.2491],
            [24.4253, 46.2609],
            [24.3791, 46.2415],
            [24.353, 46.251],
            [24.269, 46.2525],
            [24.2549, 46.2412],
            [24.1998, 46.2749],
            [24.1471, 46.2941],
            [24.1339, 46.3311],
            [24.0814, 46.3313],
            [24.0206, 46.3575],
            [23.9992, 46.3829],
            [24.0223, 46.4198],
            [23.9744, 46.4318],
            [23.9631, 46.4549],
            [23.9671, 46.5201],
            [23.9905, 46.5407],
            [24.0376, 46.5419],
            [24.0442, 46.5707],
            [24.0693, 46.6055],
            [24.021, 46.6153],
            [24.0345, 46.6447],
            [24.102, 46.6595],
            [24.11, 46.7082],
            [24.0781, 46.7146],
            [24.0644, 46.7353],
            [24.1679, 46.7795],
            [24.1809, 46.8008],
            [24.2436, 46.7722],
            [24.309, 46.7561],
            [24.3682, 46.7494],
            [24.4345, 46.775],
            [24.5048, 46.8371],
            [24.5347, 46.8712],
            [24.5229, 46.894],
            [24.5602, 46.9017],
            [24.6009, 46.9432],
            [24.6371, 46.9415],
            [24.6657, 46.9067],
            [24.7258, 46.9025],
            [24.7207, 46.9308],
            [24.7381, 46.9754],
            [24.7678, 46.9952],
            [24.7877, 47.0404],
            [24.82, 47.0694],
            [24.8817, 47.1023],
            [24.9337, 47.101],
            [24.9521, 47.1166],
            [25.0295, 47.1142],
            [25.0684, 47.1362],
          ],
        ],
      },
      properties: {
        name: 'Mureş',
        id: 'RO-MS',
        NAME_ENG: 'Mures',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-MS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.6588, 47.0859],
            [25.6663, 47.1162],
            [25.6947, 47.1395],
            [25.7138, 47.1962],
            [25.7357, 47.2139],
            [25.803, 47.2178],
            [25.829, 47.249],
            [25.7903, 47.283],
            [25.7737, 47.3216],
            [25.8359, 47.3123],
            [25.8959, 47.2621],
            [25.978, 47.2878],
            [26.0225, 47.2882],
            [26.0686, 47.2763],
            [26.1266, 47.2961],
            [26.1807, 47.2897],
            [26.1907, 47.3006],
            [26.2507, 47.2787],
            [26.2687, 47.298],
            [26.3007, 47.2947],
            [26.404, 47.3389],
            [26.4391, 47.3282],
            [26.466, 47.2989],
            [26.5284, 47.2547],
            [26.5604, 47.2382],
            [26.5736, 47.2145],
            [26.6136, 47.1941],
            [26.6307, 47.143],
            [26.6305, 47.0919],
            [26.6845, 47.1111],
            [26.7095, 47.0904],
            [26.7496, 47.1053],
            [26.8077, 47.0629],
            [26.8214, 47.0361],
            [26.8906, 47.0269],
            [26.9597, 47.0547],
            [26.9835, 47.0755],
            [27.0013, 47.0396],
            [27.0319, 47.0372],
            [27.0576, 47.0818],
            [27.1166, 47.0858],
            [27.1715, 47.0773],
            [27.183, 47.0295],
            [27.24, 47.0083],
            [27.235, 46.9932],
            [27.1684, 46.9768],
            [27.1196, 46.9459],
            [27.1843, 46.9103],
            [27.1968, 46.8813],
            [27.2154, 46.8484],
            [27.2089, 46.799],
            [27.2147, 46.769],
            [27.1947, 46.759],
            [27.1608, 46.7363],
            [27.1165, 46.7478],
            [27.1006, 46.7689],
            [27.0612, 46.7582],
            [27.027, 46.7698],
            [26.9468, 46.7551],
            [26.9361, 46.7838],
            [26.9142, 46.7926],
            [26.9063, 46.8262],
            [26.87, 46.8294],
            [26.8472, 46.777],
            [26.8179, 46.7627],
            [26.8196, 46.743],
            [26.7776, 46.7277],
            [26.763, 46.7543],
            [26.6824, 46.7668],
            [26.6707, 46.731],
            [26.6014, 46.7017],
            [26.5596, 46.6677],
            [26.5284, 46.6918],
            [26.4877, 46.6796],
            [26.4336, 46.6785],
            [26.3596, 46.6424],
            [26.2951, 46.6544],
            [26.281, 46.6823],
            [26.2355, 46.6904],
            [26.191, 46.6529],
            [26.1555, 46.6415],
            [26.14, 46.6587],
            [26.1004, 46.6473],
            [26.078, 46.6569],
            [26.0703, 46.7037],
            [25.999, 46.7014],
            [25.9782, 46.6892],
            [25.8925, 46.6984],
            [25.9025, 46.6495],
            [25.8688, 46.6501],
            [25.7877, 46.7228],
            [25.8172, 46.7493],
            [25.8088, 46.8202],
            [25.7715, 46.8332],
            [25.781, 46.8636],
            [25.7616, 46.8813],
            [25.8015, 46.9135],
            [25.8437, 46.9333],
            [25.8504, 46.9627],
            [25.8029, 46.9629],
            [25.7745, 47.0274],
            [25.7347, 47.0691],
            [25.6588, 47.0859],
          ],
        ],
      },
      properties: {
        name: 'Neamţ',
        id: 'RO-NT',
        NAME_ENG: 'Neamt',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-NT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.9286, 44.5277],
            [23.985, 44.5358],
            [24.0425, 44.5234],
            [24.0569, 44.493],
            [24.1046, 44.5027],
            [24.1461, 44.5596],
            [24.1755, 44.5657],
            [24.2113, 44.5527],
            [24.2526, 44.5746],
            [24.329, 44.5767],
            [24.322, 44.6152],
            [24.2902, 44.6867],
            [24.2733, 44.6999],
            [24.2799, 44.7536],
            [24.3134, 44.7793],
            [24.3142, 44.8334],
            [24.3423, 44.8495],
            [24.334, 44.8684],
            [24.3914, 44.8836],
            [24.4266, 44.8819],
            [24.4288, 44.8477],
            [24.4283, 44.8197],
            [24.4921, 44.8232],
            [24.5241, 44.8927],
            [24.5435, 44.8918],
            [24.553, 44.8331],
            [24.5873, 44.7963],
            [24.5924, 44.7531],
            [24.6234, 44.7299],
            [24.7062, 44.7325],
            [24.6862, 44.6926],
            [24.7293, 44.606],
            [24.7163, 44.5789],
            [24.7344, 44.5151],
            [24.7218, 44.4933],
            [24.7357, 44.46],
            [24.7288, 44.4388],
            [24.7541, 44.386],
            [24.8106, 44.3774],
            [24.8307, 44.3293],
            [24.8489, 44.3166],
            [24.8248, 44.2943],
            [24.8265, 44.2354],
            [24.8388, 44.2238],
            [24.7951, 44.1884],
            [24.8043, 44.1564],
            [24.8434, 44.1306],
            [24.8537, 44.094],
            [24.7915, 44.0824],
            [24.7167, 44.0589],
            [24.7287, 44.0388],
            [24.664, 44.03],
            [24.5979, 44.0094],
            [24.5911, 43.9906],
            [24.6333, 43.9319],
            [24.658, 43.9101],
            [24.6674, 43.883],
            [24.7022, 43.8588],
            [24.7206, 43.8286],
            [24.7253, 43.7864],
            [24.6948, 43.7814],
            [24.6856, 43.7578],
            [24.6564, 43.751],
            [24.6523, 43.7233],
            [24.5935, 43.7499],
            [24.5031, 43.7612],
            [24.4055, 43.7344],
            [24.3778, 43.7108],
            [24.3411, 43.6955],
            [24.3043, 43.6968],
            [24.2223, 43.6838],
            [24.1459, 43.6885],
            [24.1134, 43.7093],
            [24.1368, 43.7798],
            [24.1754, 43.8341],
            [24.211, 43.8567],
            [24.2332, 43.9409],
            [24.2095, 43.9563],
            [24.2314, 43.9926],
            [24.1942, 44.0184],
            [24.2216, 44.1487],
            [24.2143, 44.1726],
            [24.2291, 44.2106],
            [24.2087, 44.2343],
            [24.1834, 44.2309],
            [24.1507, 44.2596],
            [24.1142, 44.2653],
            [24.1038, 44.2907],
            [24.0693, 44.3187],
            [24.0436, 44.3636],
            [23.961, 44.3802],
            [23.8875, 44.4338],
            [23.8917, 44.4831],
            [23.9286, 44.5277],
          ],
        ],
      },
      properties: {
        name: 'Olt',
        id: 'RO-OT',
        NAME_ENG: 'Olt',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-OT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.4494, 45.4393],
            [25.5007, 45.4641],
            [25.589, 45.4596],
            [25.6446, 45.4843],
            [25.7649, 45.4636],
            [25.8105, 45.4637],
            [25.8276, 45.4302],
            [25.8656, 45.4227],
            [25.8857, 45.4437],
            [25.8988, 45.496],
            [25.9212, 45.5132],
            [25.9647, 45.5068],
            [25.9938, 45.5126],
            [26.0436, 45.4778],
            [26.0483, 45.434],
            [26.1078, 45.4063],
            [26.1436, 45.3623],
            [26.1935, 45.3511],
            [26.2301, 45.3199],
            [26.2079, 45.2953],
            [26.255, 45.2339],
            [26.2646, 45.2026],
            [26.3073, 45.1999],
            [26.3331, 45.2167],
            [26.3551, 45.1666],
            [26.3897, 45.1664],
            [26.4426, 45.1822],
            [26.4718, 45.1528],
            [26.4542, 45.1224],
            [26.4479, 45.0852],
            [26.4997, 44.9877],
            [26.5344, 44.9996],
            [26.5753, 44.9705],
            [26.5737, 44.9426],
            [26.5161, 44.9178],
            [26.5527, 44.9015],
            [26.5718, 44.8756],
            [26.6087, 44.8569],
            [26.4944, 44.7915],
            [26.4577, 44.8093],
            [26.398, 44.7663],
            [26.3642, 44.7807],
            [26.3145, 44.7789],
            [26.3012, 44.7647],
            [26.265, 44.7546],
            [26.2524, 44.7353],
            [26.217, 44.7487],
            [26.1509, 44.7576],
            [26.0974, 44.739],
            [25.982, 44.7433],
            [25.971, 44.7204],
            [25.9358, 44.6962],
            [25.9127, 44.7212],
            [25.953, 44.7472],
            [25.9021, 44.7931],
            [25.8737, 44.8283],
            [25.8359, 44.8176],
            [25.7889, 44.8525],
            [25.814, 44.8739],
            [25.8201, 44.909],
            [25.7776, 44.9472],
            [25.7483, 44.9423],
            [25.7339, 44.9597],
            [25.6822, 44.9543],
            [25.6775, 45.0078],
            [25.6855, 45.0263],
            [25.6767, 45.0735],
            [25.615, 45.1085],
            [25.5963, 45.1472],
            [25.5646, 45.1615],
            [25.5609, 45.1843],
            [25.5291, 45.1861],
            [25.5203, 45.2206],
            [25.5325, 45.2547],
            [25.5535, 45.2671],
            [25.5278, 45.3065],
            [25.4639, 45.3322],
            [25.4796, 45.3896],
            [25.4679, 45.425],
            [25.4494, 45.4393],
          ],
        ],
      },
      properties: {
        name: 'Prahova',
        id: 'RO-PH',
        NAME_ENG: 'Prahova',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-PH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.2549, 46.2412],
            [24.269, 46.2525],
            [24.353, 46.251],
            [24.3791, 46.2415],
            [24.4253, 46.2609],
            [24.5361, 46.2491],
            [24.5641, 46.2602],
            [24.5981, 46.2466],
            [24.6238, 46.251],
            [24.6425, 46.2754],
            [24.669, 46.2809],
            [24.6647, 46.2345],
            [24.6851, 46.1925],
            [24.6764, 46.1677],
            [24.6727, 46.101],
            [24.716, 46.0762],
            [24.8186, 46.0931],
            [24.8658, 46.1083],
            [24.8992, 46.1026],
            [24.9166, 46.0803],
            [24.943, 46.0778],
            [24.9461, 46.0652],
            [24.8908, 46.0434],
            [24.866, 46.0088],
            [24.8352, 46.0042],
            [24.8375, 45.9768],
            [24.7918, 45.9705],
            [24.7555, 45.9451],
            [24.7019, 45.937],
            [24.7185, 45.8941],
            [24.7707, 45.8656],
            [24.7715, 45.8371],
            [24.6898, 45.8326],
            [24.6317, 45.8006],
            [24.6431, 45.761],
            [24.6793, 45.7092],
            [24.6875, 45.648],
            [24.6783, 45.6191],
            [24.6883, 45.6003],
            [24.6727, 45.5932],
            [24.6168, 45.5982],
            [24.5654, 45.5771],
            [24.5046, 45.5828],
            [24.4888, 45.5715],
            [24.4469, 45.5864],
            [24.4327, 45.5781],
            [24.3491, 45.5832],
            [24.2878, 45.569],
            [24.2603, 45.5403],
            [24.2322, 45.5398],
            [24.2047, 45.5213],
            [24.1189, 45.5199],
            [24.0851, 45.5067],
            [24.0213, 45.5295],
            [23.9235, 45.5317],
            [23.8735, 45.521],
            [23.8532, 45.5326],
            [23.7972, 45.5291],
            [23.7456, 45.5004],
            [23.7077, 45.4968],
            [23.6543, 45.4577],
            [23.6322, 45.4634],
            [23.6596, 45.4693],
            [23.6534, 45.5172],
            [23.6387, 45.5452],
            [23.6131, 45.5612],
            [23.6488, 45.6348],
            [23.6291, 45.6551],
            [23.5914, 45.6706],
            [23.5994, 45.7215],
            [23.6394, 45.7327],
            [23.6537, 45.7751],
            [23.6314, 45.7827],
            [23.6565, 45.8101],
            [23.7004, 45.8237],
            [23.7294, 45.8195],
            [23.743, 45.9095],
            [23.79, 45.9264],
            [23.8163, 45.9231],
            [23.8378, 45.9482],
            [23.8668, 45.9603],
            [23.8697, 45.9875],
            [23.8504, 46.0098],
            [23.8925, 46.0418],
            [23.9459, 46.039],
            [24.004, 46.0139],
            [24.0439, 46.0307],
            [24.037, 46.0512],
            [24.1155, 46.1319],
            [24.1506, 46.1337],
            [24.2121, 46.1553],
            [24.1884, 46.1798],
            [24.1964, 46.217],
            [24.2549, 46.2412],
          ],
        ],
      },
      properties: {
        name: 'Sibiu',
        id: 'RO-SB',
        NAME_ENG: 'Sibiu',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-SB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.0995, 47.4247],
            [23.1818, 47.455],
            [23.2595, 47.4289],
            [23.3156, 47.4246],
            [23.3275, 47.4096],
            [23.402, 47.3938],
            [23.4371, 47.379],
            [23.4948, 47.3773],
            [23.5636, 47.3643],
            [23.6491, 47.384],
            [23.6873, 47.3521],
            [23.7143, 47.3507],
            [23.7288, 47.3295],
            [23.7943, 47.337],
            [23.8437, 47.3342],
            [23.8137, 47.3049],
            [23.7908, 47.2597],
            [23.7558, 47.2714],
            [23.6543, 47.2193],
            [23.6045, 47.1631],
            [23.5626, 47.1795],
            [23.538, 47.1577],
            [23.49, 47.1508],
            [23.5038, 47.1095],
            [23.4854, 47.0882],
            [23.4308, 47.0872],
            [23.4735, 47.0536],
            [23.4856, 47.0234],
            [23.4445, 46.9871],
            [23.405, 46.9886],
            [23.3822, 46.9714],
            [23.3824, 46.943],
            [23.3502, 46.9128],
            [23.3016, 46.899],
            [23.264, 46.9076],
            [23.2356, 46.9429],
            [23.2082, 46.9225],
            [23.2118, 46.8886],
            [23.1993, 46.8753],
            [23.1567, 46.8695],
            [23.1027, 46.8507],
            [23.0754, 46.8581],
            [23.0915, 46.8878],
            [23.0652, 46.9021],
            [23.0403, 46.8788],
            [23.0027, 46.9041],
            [22.9664, 46.8987],
            [22.8996, 46.9408],
            [22.9038, 46.9863],
            [22.8586, 47.0082],
            [22.7864, 46.9984],
            [22.7234, 47.0044],
            [22.7199, 47.0405],
            [22.6328, 47.0528],
            [22.6071, 47.0817],
            [22.5747, 47.0765],
            [22.5364, 47.1405],
            [22.5295, 47.1693],
            [22.5044, 47.1699],
            [22.5098, 47.2132],
            [22.5414, 47.2331],
            [22.5522, 47.3124],
            [22.6115, 47.3327],
            [22.6074, 47.3466],
            [22.6454, 47.3727],
            [22.6804, 47.3756],
            [22.7127, 47.4029],
            [22.8097, 47.4065],
            [22.8481, 47.4214],
            [22.8935, 47.4065],
            [22.9773, 47.352],
            [23.0139, 47.3638],
            [23.0567, 47.3543],
            [23.1113, 47.3981],
            [23.0995, 47.4247],
          ],
        ],
      },
      properties: {
        name: 'Sălaj',
        id: 'RO-SJ',
        NAME_ENG: 'Sàlaj',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-SJ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.5015, 47.9685],
            [23.5451, 47.9432],
            [23.5672, 47.9442],
            [23.5946, 47.9018],
            [23.6294, 47.892],
            [23.6464, 47.8665],
            [23.6156, 47.845],
            [23.5764, 47.8333],
            [23.5621, 47.8048],
            [23.4822, 47.782],
            [23.4665, 47.7673],
            [23.4207, 47.7681],
            [23.3813, 47.7828],
            [23.3523, 47.7779],
            [23.2848, 47.7979],
            [23.2359, 47.7503],
            [23.2655, 47.7267],
            [23.365, 47.6988],
            [23.3932, 47.6841],
            [23.3263, 47.6448],
            [23.2976, 47.6439],
            [23.2844, 47.6098],
            [23.284, 47.5749],
            [23.265, 47.5519],
            [23.2226, 47.5754],
            [23.1764, 47.5877],
            [23.1437, 47.6238],
            [23.0541, 47.5537],
            [23.0431, 47.508],
            [22.9645, 47.4616],
            [22.9683, 47.4382],
            [23.0187, 47.4242],
            [23.0786, 47.4422],
            [23.0995, 47.4247],
            [23.1113, 47.3981],
            [23.0567, 47.3543],
            [23.0139, 47.3638],
            [22.9773, 47.352],
            [22.8935, 47.4065],
            [22.8481, 47.4214],
            [22.8097, 47.4065],
            [22.7127, 47.4029],
            [22.6804, 47.3756],
            [22.6454, 47.3727],
            [22.6074, 47.3466],
            [22.5707, 47.3713],
            [22.5615, 47.3952],
            [22.5203, 47.4041],
            [22.5183, 47.4225],
            [22.4751, 47.4349],
            [22.4321, 47.419],
            [22.4076, 47.4327],
            [22.3594, 47.4263],
            [22.3439, 47.4533],
            [22.3499, 47.4871],
            [22.2901, 47.5059],
            [22.2903, 47.5386],
            [22.2322, 47.5755],
            [22.2246, 47.5971],
            [22.1869, 47.6093],
            [22.2223, 47.6837],
            [22.2577, 47.6987],
            [22.2637, 47.7304],
            [22.3548, 47.7493],
            [22.4256, 47.7503],
            [22.4479, 47.8034],
            [22.4814, 47.8103],
            [22.5489, 47.7721],
            [22.6096, 47.7716],
            [22.6797, 47.7875],
            [22.7255, 47.8378],
            [22.7748, 47.842],
            [22.7609, 47.8664],
            [22.7914, 47.8903],
            [22.8433, 47.9077],
            [22.8854, 47.9504],
            [22.9366, 47.9603],
            [22.9403, 47.9978],
            [22.9741, 48.0111],
            [23.0231, 47.9916],
            [23.0937, 48.0058],
            [23.1096, 48.0356],
            [23.1156, 48.0875],
            [23.1812, 48.1179],
            [23.1933, 48.0976],
            [23.2475, 48.0926],
            [23.2925, 48.0727],
            [23.3336, 48.02],
            [23.4356, 47.9896],
            [23.4526, 47.976],
            [23.5015, 47.9685],
          ],
        ],
      },
      properties: {
        name: 'Satu Mare',
        id: 'RO-SM',
        NAME_ENG: 'Satu Mare',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-SM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.0833, 47.984],
            [26.126, 47.9314],
            [26.1835, 47.9184],
            [26.1851, 47.8906],
            [26.2286, 47.8984],
            [26.2708, 47.875],
            [26.319, 47.8116],
            [26.366, 47.7761],
            [26.4056, 47.757],
            [26.3898, 47.7418],
            [26.456, 47.7061],
            [26.4796, 47.67],
            [26.5363, 47.645],
            [26.5377, 47.5953],
            [26.5593, 47.5758],
            [26.5774, 47.5325],
            [26.5521, 47.5048],
            [26.591, 47.4877],
            [26.5889, 47.4636],
            [26.68, 47.4685],
            [26.679, 47.4129],
            [26.6436, 47.3992],
            [26.6801, 47.3714],
            [26.6762, 47.3569],
            [26.6116, 47.3543],
            [26.5696, 47.3775],
            [26.5389, 47.3766],
            [26.5232, 47.3988],
            [26.4923, 47.3896],
            [26.5004, 47.3518],
            [26.5704, 47.2943],
            [26.5284, 47.2547],
            [26.466, 47.2989],
            [26.4391, 47.3282],
            [26.404, 47.3389],
            [26.3007, 47.2947],
            [26.2687, 47.298],
            [26.2507, 47.2787],
            [26.1907, 47.3006],
            [26.1807, 47.2897],
            [26.1266, 47.2961],
            [26.0686, 47.2763],
            [26.0225, 47.2882],
            [25.978, 47.2878],
            [25.8959, 47.2621],
            [25.8359, 47.3123],
            [25.7737, 47.3216],
            [25.7903, 47.283],
            [25.829, 47.249],
            [25.803, 47.2178],
            [25.7357, 47.2139],
            [25.7138, 47.1962],
            [25.6947, 47.1395],
            [25.6663, 47.1162],
            [25.6588, 47.0859],
            [25.6231, 47.0923],
            [25.6015, 47.0734],
            [25.5344, 47.0814],
            [25.529, 47.1093],
            [25.4655, 47.1325],
            [25.4166, 47.1231],
            [25.403, 47.0942],
            [25.3485, 47.101],
            [25.3098, 47.1186],
            [25.2817, 47.0999],
            [25.238, 47.0875],
            [25.1737, 47.129],
            [25.1418, 47.1167],
            [25.0962, 47.1192],
            [25.0684, 47.1362],
            [25.0676, 47.1662],
            [25.0396, 47.2069],
            [25.0559, 47.2262],
            [25.0596, 47.2616],
            [25.0357, 47.2899],
            [25.0773, 47.3136],
            [25.0617, 47.354],
            [25.0682, 47.3704],
            [25.0072, 47.4083],
            [25.0605, 47.4318],
            [25.068, 47.4545],
            [25.0473, 47.4675],
            [25.0916, 47.4904],
            [25.0579, 47.5024],
            [25.0057, 47.5666],
            [24.9483, 47.5941],
            [25.0085, 47.6312],
            [25.0521, 47.6376],
            [25.0537, 47.6556],
            [25.0097, 47.6721],
            [24.9811, 47.6948],
            [24.9513, 47.7015],
            [24.9452, 47.7294],
            [25.0093, 47.7348],
            [25.041, 47.7258],
            [25.1181, 47.7553],
            [25.1247, 47.7703],
            [25.1815, 47.8174],
            [25.2243, 47.8826],
            [25.2716, 47.8935],
            [25.3148, 47.9149],
            [25.3378, 47.9145],
            [25.5377, 47.937],
            [25.5899, 47.9382],
            [25.6486, 47.9501],
            [25.6847, 47.9424],
            [25.776, 47.9394],
            [25.8268, 47.9634],
            [25.8616, 47.9699],
            [25.8917, 47.9606],
            [25.9169, 47.9772],
            [25.9536, 47.9708],
            [26.0651, 47.9883],
            [26.0833, 47.984],
          ],
        ],
      },
      properties: {
        name: 'Suceava',
        id: 'RO-SV',
        NAME_ENG: 'Suceava',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-SV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.7762, 44.6746],
            [28.7381, 44.6911],
            [28.7045, 44.6859],
            [28.6953, 44.6674],
            [28.5874, 44.6581],
            [28.5449, 44.6617],
            [28.5414, 44.6416],
            [28.4847, 44.6425],
            [28.4819, 44.6617],
            [28.4229, 44.6445],
            [28.4037, 44.6716],
            [28.3389, 44.6775],
            [28.3149, 44.6997],
            [28.258, 44.7184],
            [28.2597, 44.7632],
            [28.2206, 44.764],
            [28.1537, 44.7984],
            [28.115, 44.7914],
            [28.0739, 44.8003],
            [28.1006, 44.8619],
            [28.141, 44.8917],
            [28.1235, 44.9112],
            [28.1411, 44.9776],
            [28.128, 45.0125],
            [28.1523, 45.0337],
            [28.1338, 45.0782],
            [28.1649, 45.0972],
            [28.1698, 45.121],
            [28.1975, 45.1342],
            [28.0923, 45.188],
            [28.1305, 45.2323],
            [28.0953, 45.2479],
            [28.022, 45.2656],
            [27.9963, 45.303],
            [28.0264, 45.3576],
            [28.0283, 45.4022],
            [28.0612, 45.4267],
            [28.0957, 45.4388],
            [28.1373, 45.4348],
            [28.1767, 45.4136],
            [28.2178, 45.4647],
            [28.2744, 45.4513],
            [28.2879, 45.4262],
            [28.2839, 45.3972],
            [28.3496, 45.3207],
            [28.49, 45.2778],
            [28.5634, 45.2485],
            [28.6402, 45.2432],
            [28.7116, 45.2241],
            [28.7794, 45.2367],
            [28.7917, 45.2519],
            [28.7521, 45.2823],
            [28.791, 45.2912],
            [28.8202, 45.3353],
            [28.8403, 45.3194],
            [28.8738, 45.3165],
            [28.9153, 45.2865],
            [28.9419, 45.2796],
            [28.9496, 45.312],
            [29.0444, 45.3599],
            [29.1078, 45.3755],
            [29.1799, 45.3999],
            [29.1885, 45.4133],
            [29.2407, 45.4333],
            [29.2903, 45.4274],
            [29.3247, 45.4486],
            [29.3532, 45.4373],
            [29.4299, 45.4433],
            [29.4789, 45.4232],
            [29.5495, 45.4095],
            [29.599, 45.3856],
            [29.6568, 45.334],
            [29.6777, 45.271],
            [29.6608, 45.2439],
            [29.672, 45.2197],
            [29.627, 45.2165],
            [29.6401, 45.1692],
            [29.6804, 45.1446],
            [29.6561, 45.0973],
            [29.62, 44.8837],
            [29.592, 44.8348],
            [29.5309, 44.8216],
            [29.2398, 44.7936],
            [29.1437, 44.7726],
            [29.0789, 44.7445],
            [29.0073, 44.6887],
            [28.984, 44.7084],
            [28.9758, 44.7403],
            [29.0225, 44.7468],
            [29.1238, 44.7835],
            [29.1467, 44.8194],
            [29.0927, 44.8716],
            [29.0761, 44.8318],
            [29.0493, 44.8485],
            [29.0345, 44.8902],
            [29.0448, 44.9411],
            [29.0987, 44.955],
            [29.0911, 44.9862],
            [29.0113, 44.9985],
            [29.0061, 45.0157],
            [28.9597, 45.0118],
            [28.9654, 44.991],
            [28.9078, 44.981],
            [28.8612, 44.9569],
            [28.852, 44.9101],
            [28.8601, 44.8793],
            [28.885, 44.8655],
            [28.9443, 44.8132],
            [28.9087, 44.8025],
            [28.9312, 44.7482],
            [28.877, 44.7464],
            [28.7835, 44.7186],
            [28.7762, 44.6746],
          ],
        ],
      },
      properties: {
        name: 'Tulcea',
        id: 'RO-TL',
        NAME_ENG: 'Tulcea',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-TL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.6968, 46.1576],
            [20.7267, 46.1411],
            [20.771, 46.1409],
            [20.7791, 46.1224],
            [20.8324, 46.0872],
            [20.8858, 46.0819],
            [20.9421, 46.0366],
            [20.9825, 46.0425],
            [21.0253, 46.0284],
            [21.0684, 46.0381],
            [21.0838, 45.9976],
            [21.1092, 45.9773],
            [21.1467, 46.0116],
            [21.1899, 45.9849],
            [21.2466, 45.9683],
            [21.2634, 45.9989],
            [21.2964, 46.0048],
            [21.3628, 45.9922],
            [21.4342, 46.0214],
            [21.4862, 46.0634],
            [21.5134, 46.0645],
            [21.527, 46.0413],
            [21.523, 46.0097],
            [21.6159, 45.9809],
            [21.6673, 45.9242],
            [21.7365, 45.9463],
            [21.8067, 45.9331],
            [21.8275, 45.9584],
            [21.8694, 45.9613],
            [21.8917, 45.9501],
            [21.9296, 45.9855],
            [21.9669, 45.9936],
            [22.0045, 45.9715],
            [22.0683, 45.957],
            [22.1275, 45.9353],
            [22.2156, 45.9141],
            [22.237, 45.936],
            [22.2671, 45.9427],
            [22.3014, 45.9317],
            [22.319, 45.9707],
            [22.3444, 45.9705],
            [22.348, 45.9432],
            [22.4088, 45.9174],
            [22.3763, 45.885],
            [22.4138, 45.8487],
            [22.4625, 45.8455],
            [22.4807, 45.7859],
            [22.524, 45.7936],
            [22.5466, 45.7761],
            [22.5177, 45.7371],
            [22.4706, 45.7283],
            [22.4658, 45.7023],
            [22.4263, 45.6654],
            [22.4029, 45.6488],
            [22.3027, 45.6433],
            [22.2762, 45.5889],
            [22.2564, 45.5821],
            [22.1615, 45.627],
            [22.1359, 45.5939],
            [22.1044, 45.5893],
            [22.073, 45.569],
            [22.045, 45.5697],
            [22.0549, 45.5109],
            [22.0275, 45.4855],
            [21.9739, 45.4845],
            [21.9399, 45.4948],
            [21.9543, 45.518],
            [21.9072, 45.5286],
            [21.8306, 45.5368],
            [21.7609, 45.5273],
            [21.742, 45.5509],
            [21.7031, 45.5588],
            [21.6899, 45.5775],
            [21.5952, 45.5649],
            [21.5378, 45.4891],
            [21.4596, 45.4731],
            [21.4706, 45.4297],
            [21.4426, 45.4097],
            [21.4584, 45.3676],
            [21.5107, 45.34],
            [21.5205, 45.3152],
            [21.5516, 45.2918],
            [21.5322, 45.2617],
            [21.5146, 45.1996],
            [21.4802, 45.1888],
            [21.391, 45.2172],
            [21.289, 45.2415],
            [21.2721, 45.2289],
            [21.2004, 45.2653],
            [21.1784, 45.3078],
            [21.0954, 45.2961],
            [21.0615, 45.3315],
            [21.0164, 45.3243],
            [21.0103, 45.3413],
            [20.96, 45.366],
            [20.9201, 45.4156],
            [20.8704, 45.4305],
            [20.8672, 45.4625],
            [20.833, 45.4777],
            [20.7706, 45.4779],
            [20.7684, 45.4977],
            [20.8323, 45.5333],
            [20.7947, 45.5854],
            [20.7654, 45.6098],
            [20.8048, 45.654],
            [20.7961, 45.6836],
            [20.8027, 45.7342],
            [20.8232, 45.7724],
            [20.7836, 45.7834],
            [20.7743, 45.7516],
            [20.7002, 45.7478],
            [20.6841, 45.7947],
            [20.651, 45.8088],
            [20.6607, 45.8262],
            [20.6118, 45.8612],
            [20.5816, 45.8666],
            [20.5694, 45.9078],
            [20.5158, 45.8896],
            [20.4833, 45.9513],
            [20.4473, 45.9684],
            [20.4057, 45.9634],
            [20.3464, 45.998],
            [20.3422, 46.0474],
            [20.2729, 46.0964],
            [20.2628, 46.1199],
            [20.2885, 46.1452],
            [20.3544, 46.169],
            [20.3674, 46.1497],
            [20.4001, 46.1575],
            [20.4365, 46.1432],
            [20.4642, 46.1449],
            [20.4953, 46.1693],
            [20.4998, 46.1914],
            [20.5453, 46.1785],
            [20.6284, 46.1285],
            [20.6564, 46.15],
            [20.6968, 46.1576],
          ],
        ],
      },
      properties: {
        name: 'Timiş',
        id: 'RO-TM',
        NAME_ENG: 'Timis',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-TM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.2132, 44.4443],
            [25.2509, 44.4091],
            [25.3032, 44.4206],
            [25.3265, 44.4482],
            [25.3672, 44.4718],
            [25.3437, 44.5102],
            [25.3936, 44.5166],
            [25.4535, 44.4986],
            [25.4587, 44.4694],
            [25.4803, 44.4488],
            [25.4827, 44.4192],
            [25.5086, 44.3932],
            [25.4723, 44.3485],
            [25.4686, 44.3222],
            [25.5034, 44.3127],
            [25.5569, 44.3156],
            [25.6279, 44.301],
            [25.6192, 44.2868],
            [25.6659, 44.2765],
            [25.6938, 44.2521],
            [25.6547, 44.163],
            [25.6959, 44.1438],
            [25.6773, 44.0976],
            [25.6403, 44.1013],
            [25.6162, 44.0731],
            [25.6302, 44.0489],
            [25.5952, 44.0423],
            [25.6167, 43.8813],
            [25.5983, 43.8711],
            [25.6518, 43.8198],
            [25.6619, 43.7254],
            [25.6709, 43.6887],
            [25.5584, 43.6428],
            [25.4838, 43.6419],
            [25.4327, 43.6233],
            [25.383, 43.6178],
            [25.345, 43.626],
            [25.2582, 43.6799],
            [25.184, 43.6973],
            [25.1082, 43.6829],
            [25.064, 43.6893],
            [24.9956, 43.7265],
            [24.9389, 43.7257],
            [24.8745, 43.7033],
            [24.8066, 43.7087],
            [24.7567, 43.6859],
            [24.7149, 43.6865],
            [24.6523, 43.7233],
            [24.6564, 43.751],
            [24.6856, 43.7578],
            [24.6948, 43.7814],
            [24.7253, 43.7864],
            [24.7206, 43.8286],
            [24.7022, 43.8588],
            [24.6674, 43.883],
            [24.658, 43.9101],
            [24.6333, 43.9319],
            [24.5911, 43.9906],
            [24.5979, 44.0094],
            [24.664, 44.03],
            [24.7287, 44.0388],
            [24.7167, 44.0589],
            [24.7915, 44.0824],
            [24.8537, 44.094],
            [24.8434, 44.1306],
            [24.8043, 44.1564],
            [24.7951, 44.1884],
            [24.8388, 44.2238],
            [24.8265, 44.2354],
            [24.8248, 44.2943],
            [24.8489, 44.3166],
            [24.8307, 44.3293],
            [24.8106, 44.3774],
            [24.8555, 44.3722],
            [24.8864, 44.3803],
            [24.9613, 44.3779],
            [24.9958, 44.4025],
            [25.0448, 44.397],
            [25.1868, 44.4405],
            [25.2132, 44.4443],
          ],
        ],
      },
      properties: {
        name: 'Teleorman',
        id: 'RO-TR',
        NAME_ENG: 'Teleorman',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-TR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.6322, 45.4634],
            [23.6543, 45.4577],
            [23.7077, 45.4968],
            [23.7456, 45.5004],
            [23.7972, 45.5291],
            [23.8532, 45.5326],
            [23.8735, 45.521],
            [23.9235, 45.5317],
            [24.0213, 45.5295],
            [24.0851, 45.5067],
            [24.1189, 45.5199],
            [24.2047, 45.5213],
            [24.2322, 45.5398],
            [24.2603, 45.5403],
            [24.2878, 45.569],
            [24.3491, 45.5832],
            [24.4327, 45.5781],
            [24.4469, 45.5864],
            [24.4888, 45.5715],
            [24.4972, 45.5472],
            [24.4697, 45.5135],
            [24.4534, 45.4759],
            [24.4513, 45.4091],
            [24.4836, 45.3574],
            [24.4958, 45.2811],
            [24.4671, 45.2289],
            [24.5197, 45.18],
            [24.5009, 45.1541],
            [24.5199, 45.1333],
            [24.5087, 45.0827],
            [24.489, 45.0604],
            [24.5123, 45.0471],
            [24.4836, 44.9756],
            [24.4866, 44.9476],
            [24.4651, 44.9108],
            [24.4752, 44.8958],
            [24.4288, 44.8477],
            [24.4266, 44.8819],
            [24.3914, 44.8836],
            [24.334, 44.8684],
            [24.3423, 44.8495],
            [24.3142, 44.8334],
            [24.3134, 44.7793],
            [24.2799, 44.7536],
            [24.2733, 44.6999],
            [24.2902, 44.6867],
            [24.322, 44.6152],
            [24.329, 44.5767],
            [24.2526, 44.5746],
            [24.2113, 44.5527],
            [24.1755, 44.5657],
            [24.1461, 44.5596],
            [24.1046, 44.5027],
            [24.0569, 44.493],
            [24.0425, 44.5234],
            [23.985, 44.5358],
            [23.9286, 44.5277],
            [23.9134, 44.5764],
            [23.8934, 44.5842],
            [23.9, 44.6222],
            [23.8153, 44.6377],
            [23.766, 44.6644],
            [23.7752, 44.6876],
            [23.764, 44.7079],
            [23.7827, 44.7441],
            [23.7704, 44.7782],
            [23.7817, 44.8249],
            [23.7974, 44.8301],
            [23.7762, 44.8664],
            [23.7685, 44.9037],
            [23.7697, 44.9597],
            [23.7574, 45.0013],
            [23.7843, 45.0121],
            [23.8171, 45.0704],
            [23.8241, 45.1021],
            [23.8153, 45.1253],
            [23.856, 45.1447],
            [23.8492, 45.1757],
            [23.8151, 45.2115],
            [23.7969, 45.2568],
            [23.8421, 45.3086],
            [23.843, 45.332],
            [23.7915, 45.3485],
            [23.7373, 45.3315],
            [23.6846, 45.3286],
            [23.6412, 45.349],
            [23.6289, 45.3332],
            [23.5899, 45.3511],
            [23.6018, 45.3755],
            [23.5754, 45.4239],
            [23.5962, 45.4676],
            [23.6322, 45.4634],
          ],
        ],
      },
      properties: {
        name: 'Vâlcea',
        id: 'RO-VL',
        NAME_ENG: 'Vîlcea',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-VL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.4373, 46.042],
            [26.4925, 46.0391],
            [26.5134, 46.0247],
            [26.5651, 46.0216],
            [26.5851, 46.0385],
            [26.6328, 46.0572],
            [26.6537, 46.0768],
            [26.6989, 46.0892],
            [26.7429, 46.0857],
            [26.8178, 46.0687],
            [26.8725, 46.0829],
            [26.9325, 46.0635],
            [26.9899, 46.1134],
            [27.0792, 46.0857],
            [27.0869, 46.1143],
            [27.1356, 46.1022],
            [27.1459, 46.1302],
            [27.1217, 46.145],
            [27.2132, 46.1873],
            [27.2421, 46.1742],
            [27.276, 46.1951],
            [27.3142, 46.2013],
            [27.3224, 46.1709],
            [27.3787, 46.1581],
            [27.39, 46.1902],
            [27.4466, 46.1845],
            [27.4798, 46.1453],
            [27.4807, 46.1305],
            [27.4725, 46.1125],
            [27.4265, 46.1362],
            [27.4241, 46.0872],
            [27.3648, 46.0777],
            [27.299, 46.0758],
            [27.2816, 46.0389],
            [27.2274, 46.0185],
            [27.2273, 45.9957],
            [27.2871, 45.8806],
            [27.3218, 45.8242],
            [27.3287, 45.7913],
            [27.3571, 45.7654],
            [27.3617, 45.7262],
            [27.4028, 45.6792],
            [27.4485, 45.6752],
            [27.4648, 45.6339],
            [27.5128, 45.6173],
            [27.5189, 45.5867],
            [27.5045, 45.5582],
            [27.5286, 45.5438],
            [27.5207, 45.523],
            [27.5538, 45.4825],
            [27.4926, 45.4413],
            [27.489, 45.4228],
            [27.4337, 45.3922],
            [27.4008, 45.3754],
            [27.354, 45.3697],
            [27.2716, 45.3793],
            [27.2591, 45.4471],
            [27.2234, 45.4252],
            [27.2028, 45.4389],
            [27.1606, 45.4159],
            [27.0995, 45.4618],
            [27.0716, 45.4457],
            [27.0311, 45.4544],
            [26.9921, 45.4942],
            [26.9718, 45.5308],
            [26.9462, 45.5245],
            [26.8342, 45.5177],
            [26.8062, 45.5419],
            [26.747, 45.5487],
            [26.7005, 45.5871],
            [26.6686, 45.5838],
            [26.6386, 45.6304],
            [26.5951, 45.6188],
            [26.5439, 45.629],
            [26.4933, 45.6743],
            [26.4654, 45.7171],
            [26.4348, 45.7356],
            [26.4405, 45.7746],
            [26.39, 45.7988],
            [26.3717, 45.8176],
            [26.3703, 45.8446],
            [26.3857, 45.8759],
            [26.3747, 45.9015],
            [26.3928, 45.9202],
            [26.3974, 45.9659],
            [26.4374, 45.9884],
            [26.4373, 46.042],
          ],
        ],
      },
      properties: {
        name: 'Vrancea',
        id: 'RO-VN',
        NAME_ENG: 'Vrancea',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-VN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.1968, 46.8813],
            [27.2205, 46.8696],
            [27.2945, 46.8724],
            [27.3848, 46.886],
            [27.4078, 46.8719],
            [27.5295, 46.8762],
            [27.5243, 46.8981],
            [27.5991, 46.8893],
            [27.644, 46.9007],
            [27.6178, 46.9292],
            [27.6108, 46.9586],
            [27.6307, 46.9852],
            [27.6614, 46.9687],
            [27.7286, 46.9171],
            [27.7514, 46.8854],
            [27.8231, 46.8816],
            [27.9289, 46.8188],
            [27.9342, 46.8419],
            [27.9782, 46.8554],
            [27.9924, 46.8392],
            [28.0269, 46.8365],
            [28.1144, 46.8436],
            [28.1148, 46.8179],
            [28.1509, 46.7972],
            [28.2013, 46.6996],
            [28.2345, 46.6789],
            [28.2522, 46.6098],
            [28.2234, 46.5774],
            [28.2333, 46.5614],
            [28.2106, 46.5041],
            [28.2582, 46.46],
            [28.2584, 46.422],
            [28.2311, 46.4042],
            [28.1955, 46.354],
            [28.1866, 46.2895],
            [28.156, 46.285],
            [28.1078, 46.2132],
            [28.1413, 46.1897],
            [28.142, 46.163],
            [28.123, 46.1465],
            [28.116, 46.1094],
            [28.0772, 46.1155],
            [28.0198, 46.103],
            [27.9799, 46.1072],
            [27.966, 46.139],
            [27.9398, 46.1335],
            [27.9117, 46.1511],
            [27.8495, 46.1363],
            [27.8065, 46.1115],
            [27.7751, 46.1088],
            [27.7559, 46.1302],
            [27.6675, 46.1241],
            [27.5898, 46.1473],
            [27.5863, 46.118],
            [27.6271, 46.0591],
            [27.605, 46.0524],
            [27.6043, 46.0165],
            [27.5754, 46.0033],
            [27.5407, 46.0206],
            [27.5157, 46.053],
            [27.5299, 46.1039],
            [27.5075, 46.1294],
            [27.4807, 46.1305],
            [27.4798, 46.1453],
            [27.5061, 46.1877],
            [27.4858, 46.1976],
            [27.4625, 46.2893],
            [27.4759, 46.3272],
            [27.4516, 46.3702],
            [27.4485, 46.3991],
            [27.4206, 46.4236],
            [27.4137, 46.4504],
            [27.3815, 46.5106],
            [27.3403, 46.5572],
            [27.3276, 46.5839],
            [27.2751, 46.6228],
            [27.3138, 46.6509],
            [27.3003, 46.7084],
            [27.2233, 46.7405],
            [27.1947, 46.759],
            [27.2147, 46.769],
            [27.2089, 46.799],
            [27.2154, 46.8484],
            [27.1968, 46.8813],
          ],
        ],
      },
      properties: {
        name: 'Vaslui',
        id: 'RO-VS',
        NAME_ENG: 'Vaslui',
        CNTRY: 'Romania',
        TYPE: 'Judet',
        TYPE_ENG: 'County',
      },
      id: 'RO-VS',
    },
  ],
}
export default map
