var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.9982, 46.8341],
            [16.0053, 46.8456],
            [16.0024, 46.8506],
            [16.01, 46.8616],
            [16.0079, 46.8712],
            [16.013, 46.8808],
            [16.0293, 46.8892],
            [16.0342, 46.9007],
            [16.0459, 46.9103],
            [16.0561, 46.9089],
            [16.0633, 46.9137],
            [16.0616, 46.9206],
            [16.0716, 46.9331],
            [16.0936, 46.9357],
            [16.0974, 46.9424],
            [16.0957, 46.954],
            [16.11, 46.967],
            [16.112, 46.9784],
            [16.1213, 46.9877],
            [16.1307, 46.992],
            [16.1533, 46.9929],
            [16.1469, 47.0007],
            [16.1291, 47.011],
            [16.1258, 47.0236],
            [16.1137, 47.0314],
            [16.1184, 47.0415],
            [16.1046, 47.0598],
            [16.0938, 47.0642],
            [16.0873, 47.0878],
            [16.0921, 47.1059],
            [16.1096, 47.1179],
            [16.1162, 47.1414],
            [16.1085, 47.1687],
            [16.0999, 47.1768],
            [16.0989, 47.1923],
            [16.0891, 47.2046],
            [16.0882, 47.2126],
            [16.0814, 47.2252],
            [16.0879, 47.2328],
            [16.0837, 47.2395],
            [16.0873, 47.2462],
            [16.0809, 47.2499],
            [16.0808, 47.261],
            [16.0761, 47.2746],
            [16.0638, 47.2874],
            [16.0605, 47.3088],
            [16.0478, 47.3225],
            [16.0477, 47.3306],
            [16.0338, 47.3442],
            [16.0338, 47.3544],
            [16.0268, 47.3588],
            [16.0334, 47.3714],
            [16.0311, 47.3781],
            [16.0621, 47.3828],
            [16.0709, 47.3989],
            [16.0837, 47.4067],
            [16.0934, 47.3883],
            [16.0984, 47.3908],
            [16.1094, 47.4132],
            [16.1237, 47.4117],
            [16.1695, 47.4222],
            [16.1927, 47.4209],
            [16.2235, 47.4268],
            [16.2421, 47.4365],
            [16.2553, 47.4495],
            [16.2712, 47.4546],
            [16.2808, 47.4549],
            [16.2877, 47.4619],
            [16.3005, 47.4676],
            [16.3112, 47.4803],
            [16.3113, 47.4893],
            [16.3225, 47.4952],
            [16.3327, 47.5043],
            [16.3394, 47.5149],
            [16.3384, 47.5252],
            [16.3102, 47.5449],
            [16.3168, 47.5592],
            [16.3142, 47.565],
            [16.3299, 47.5812],
            [16.3305, 47.598],
            [16.3451, 47.6006],
            [16.3538, 47.6119],
            [16.356, 47.6203],
            [16.3688, 47.6209],
            [16.3717, 47.6263],
            [16.3673, 47.6348],
            [16.3732, 47.6433],
            [16.3618, 47.6554],
            [16.3605, 47.6636],
            [16.3519, 47.6706],
            [16.3514, 47.6807],
            [16.3354, 47.6768],
            [16.3219, 47.6842],
            [16.3077, 47.6867],
            [16.3087, 47.6937],
            [16.295, 47.7135],
            [16.2901, 47.7271],
            [16.2973, 47.74],
            [16.2977, 47.7536],
            [16.2937, 47.7672],
            [16.3028, 47.7811],
            [16.2973, 47.7876],
            [16.2875, 47.7865],
            [16.2684, 47.7919],
            [16.2772, 47.8084],
            [16.2859, 47.81],
            [16.3112, 47.806],
            [16.31, 47.7872],
            [16.3251, 47.7878],
            [16.3312, 47.8005],
            [16.3448, 47.8037],
            [16.3491, 47.8165],
            [16.3876, 47.8408],
            [16.3832, 47.847],
            [16.3686, 47.8542],
            [16.3569, 47.8644],
            [16.3809, 47.8751],
            [16.3878, 47.882],
            [16.3996, 47.8762],
            [16.4086, 47.8797],
            [16.4229, 47.9018],
            [16.4177, 47.9099],
            [16.4253, 47.9134],
            [16.4369, 47.9255],
            [16.4483, 47.9337],
            [16.4646, 47.9387],
            [16.4701, 47.9352],
            [16.4847, 47.941],
            [16.5035, 47.9393],
            [16.5192, 47.9424],
            [16.5326, 47.9244],
            [16.5537, 47.9089],
            [16.5607, 47.8988],
            [16.5744, 47.8925],
            [16.5883, 47.8955],
            [16.588, 47.9009],
            [16.6013, 47.9044],
            [16.6089, 47.9147],
            [16.6318, 47.9286],
            [16.6305, 47.9451],
            [16.6523, 47.9492],
            [16.6531, 47.9558],
            [16.6687, 47.96],
            [16.6779, 47.9596],
            [16.6964, 47.9637],
            [16.7008, 47.975],
            [16.6962, 47.9861],
            [16.6999, 47.9905],
            [16.7002, 48.0042],
            [16.7145, 48.0124],
            [16.7292, 48.0086],
            [16.7465, 48.0139],
            [16.7544, 48.0117],
            [16.7735, 48.0197],
            [16.7917, 48.023],
            [16.8137, 48.0297],
            [16.8269, 48.0268],
            [16.838, 48.0315],
            [16.829, 48.0408],
            [16.8425, 48.0464],
            [16.8487, 48.0561],
            [16.862, 48.0591],
            [16.8749, 48.0724],
            [16.8823, 48.0676],
            [16.8912, 48.0741],
            [16.918, 48.0676],
            [16.9194, 48.0501],
            [16.9531, 48.0455],
            [16.9722, 48.0407],
            [16.9771, 48.034],
            [16.9688, 48.0261],
            [16.9806, 48.0231],
            [16.9858, 48.0294],
            [16.9976, 48.0547],
            [17.0156, 48.0738],
            [16.9804, 48.0945],
            [16.9697, 48.0958],
            [16.9751, 48.1043],
            [17, 48.108],
            [17.0051, 48.0992],
            [17.0224, 48.1038],
            [17.0257, 48.099],
            [17.0125, 48.0887],
            [17.0357, 48.0854],
            [17.0438, 48.1004],
            [17.0647, 48.1091],
            [17.0679, 48.1182],
            [17.0826, 48.1035],
            [17.0938, 48.0997],
            [17.0906, 48.0922],
            [17.0777, 48.0858],
            [17.0809, 48.0782],
            [17.0748, 48.0707],
            [17.0877, 48.0654],
            [17.0704, 48.0548],
            [17.0852, 48.0505],
            [17.0886, 48.0452],
            [17.0709, 48.0326],
            [17.093, 48.0208],
            [17.1114, 48.0311],
            [17.1279, 48.0215],
            [17.1362, 48.0231],
            [17.1474, 48.0183],
            [17.1624, 48.0069],
            [17.1212, 47.9849],
            [17.0963, 47.9703],
            [17.118, 47.9601],
            [17.098, 47.9375],
            [17.1156, 47.9257],
            [17.0975, 47.9034],
            [17.1054, 47.8986],
            [17.0873, 47.875],
            [17.0785, 47.8772],
            [17.0511, 47.8712],
            [17.0425, 47.8671],
            [17.0193, 47.868],
            [17.0118, 47.8583],
            [17.0169, 47.8546],
            [17.0534, 47.8377],
            [17.0488, 47.8277],
            [17.0751, 47.8082],
            [17.0573, 47.7985],
            [17.0531, 47.7926],
            [17.0657, 47.7753],
            [17.072, 47.7489],
            [17.0715, 47.727],
            [17.0917, 47.708],
            [17.0416, 47.7045],
            [16.9887, 47.6968],
            [16.9122, 47.6875],
            [16.8728, 47.6896],
            [16.8701, 47.7187],
            [16.8399, 47.7041],
            [16.8282, 47.6825],
            [16.7496, 47.6812],
            [16.7363, 47.6979],
            [16.7217, 47.7219],
            [16.7202, 47.7325],
            [16.7105, 47.7359],
            [16.6575, 47.7409],
            [16.6389, 47.7487],
            [16.6332, 47.7586],
            [16.6223, 47.755],
            [16.6062, 47.7602],
            [16.598, 47.7561],
            [16.5669, 47.7512],
            [16.5484, 47.7508],
            [16.5466, 47.7394],
            [16.5375, 47.7354],
            [16.552, 47.7217],
            [16.5413, 47.7109],
            [16.5268, 47.7093],
            [16.5048, 47.7017],
            [16.4849, 47.6911],
            [16.4772, 47.6896],
            [16.4758, 47.6814],
            [16.4608, 47.6829],
            [16.4544, 47.6951],
            [16.448, 47.6912],
            [16.4434, 47.6731],
            [16.4235, 47.6651],
            [16.4438, 47.6552],
            [16.464, 47.6511],
            [16.4819, 47.6498],
            [16.4889, 47.6395],
            [16.5083, 47.6394],
            [16.5191, 47.6432],
            [16.5292, 47.642],
            [16.5397, 47.6353],
            [16.5738, 47.6198],
            [16.5954, 47.6177],
            [16.628, 47.6279],
            [16.6519, 47.6226],
            [16.6596, 47.6065],
            [16.6701, 47.6061],
            [16.6675, 47.5939],
            [16.6759, 47.5803],
            [16.6712, 47.5706],
            [16.6639, 47.5686],
            [16.6739, 47.5591],
            [16.686, 47.5602],
            [16.6977, 47.5469],
            [16.7151, 47.5393],
            [16.704, 47.5331],
            [16.7056, 47.5227],
            [16.6959, 47.518],
            [16.6888, 47.5098],
            [16.6714, 47.5037],
            [16.6613, 47.5081],
            [16.6516, 47.4989],
            [16.6608, 47.483],
            [16.6693, 47.4735],
            [16.6589, 47.4614],
            [16.6613, 47.4555],
            [16.6456, 47.4458],
            [16.6361, 47.4451],
            [16.6221, 47.4351],
            [16.6075, 47.4288],
            [16.5762, 47.4114],
            [16.5739, 47.4051],
            [16.5472, 47.4042],
            [16.5409, 47.4078],
            [16.5115, 47.4086],
            [16.5107, 47.3964],
            [16.4978, 47.389],
            [16.4846, 47.4009],
            [16.4862, 47.4061],
            [16.4566, 47.4114],
            [16.4471, 47.4072],
            [16.4479, 47.3967],
            [16.4562, 47.3843],
            [16.4508, 47.3754],
            [16.4583, 47.3671],
            [16.4469, 47.3567],
            [16.4351, 47.3533],
            [16.4492, 47.3379],
            [16.464, 47.3347],
            [16.4713, 47.3141],
            [16.4811, 47.2982],
            [16.4832, 47.2878],
            [16.4895, 47.2799],
            [16.479, 47.2762],
            [16.4803, 47.2615],
            [16.4674, 47.2523],
            [16.4591, 47.2544],
            [16.4397, 47.2467],
            [16.4424, 47.234],
            [16.4383, 47.2187],
            [16.4338, 47.2125],
            [16.4157, 47.2048],
            [16.42, 47.1941],
            [16.4281, 47.1843],
            [16.452, 47.1877],
            [16.4561, 47.1756],
            [16.4631, 47.1665],
            [16.4535, 47.1567],
            [16.4499, 47.1451],
            [16.4598, 47.1407],
            [16.4864, 47.1471],
            [16.5164, 47.1474],
            [16.5256, 47.1365],
            [16.5295, 47.1259],
            [16.4997, 47.1218],
            [16.5002, 47.109],
            [16.4839, 47.1075],
            [16.4832, 47.1018],
            [16.4651, 47.0973],
            [16.4632, 47.091],
            [16.4746, 47.0842],
            [16.4729, 47.0728],
            [16.4961, 47.0694],
            [16.5204, 47.0611],
            [16.5204, 47.0567],
            [16.508, 47.0498],
            [16.4758, 47.046],
            [16.4422, 47.0371],
            [16.439, 47.029],
            [16.448, 47.0221],
            [16.4593, 47.0294],
            [16.4744, 47.0261],
            [16.478, 47.0201],
            [16.512, 47.0005],
            [16.4863, 46.9937],
            [16.4643, 46.9964],
            [16.4531, 47.0039],
            [16.4469, 46.9938],
            [16.4306, 46.992],
            [16.4203, 46.995],
            [16.4133, 47.0041],
            [16.4007, 47.0035],
            [16.3724, 46.9976],
            [16.3654, 47.0059],
            [16.348, 47.0083],
            [16.3411, 46.9949],
            [16.3236, 47.0014],
            [16.3017, 46.9978],
            [16.2992, 47.0065],
            [16.2905, 47.0125],
            [16.2766, 46.9864],
            [16.2774, 46.9728],
            [16.2742, 46.962],
            [16.2532, 46.9619],
            [16.247, 46.9567],
            [16.2449, 46.946],
            [16.236, 46.9455],
            [16.219, 46.9364],
            [16.2169, 46.9406],
            [16.1971, 46.9393],
            [16.1792, 46.907],
            [16.1636, 46.9035],
            [16.1327, 46.8753],
            [16.1096, 46.8673],
            [16.1005, 46.8596],
            [16.088, 46.8607],
            [16.0804, 46.8515],
            [16.0735, 46.8521],
            [16.0628, 46.8455],
            [16.0594, 46.8372],
            [16.0429, 46.8391],
            [16.0113, 46.8332],
            [15.9982, 46.8341],
          ],
        ],
      },
      properties: {name: 'Burgenland', id: 'AT-1', CNTRYNAME: 'Austria', TYPE: 'State'},
      id: 'AT-1',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.6476, 47.1011],
            [12.6579, 47.1074],
            [12.665, 47.1191],
            [12.6885, 47.1239],
            [12.7044, 47.1223],
            [12.7142, 47.1281],
            [12.7304, 47.1309],
            [12.7357, 47.1028],
            [12.7527, 47.0988],
            [12.7725, 47.1003],
            [12.7885, 47.0932],
            [12.8035, 47.0979],
            [12.8194, 47.0976],
            [12.8361, 47.0826],
            [12.8604, 47.0807],
            [12.8693, 47.0855],
            [12.8925, 47.0804],
            [12.9178, 47.0809],
            [12.935, 47.0763],
            [12.9426, 47.0591],
            [12.9547, 47.0536],
            [12.9556, 47.043],
            [12.9699, 47.0369],
            [12.9849, 47.0348],
            [13.0011, 47.0423],
            [13.0199, 47.0398],
            [13.0202, 47.0296],
            [13.0565, 47.0174],
            [13.0703, 47.0196],
            [13.0859, 47.0265],
            [13.0988, 47.0246],
            [13.1252, 47.0261],
            [13.1332, 47.034],
            [13.1563, 47.0345],
            [13.1695, 47.04],
            [13.2085, 47.0438],
            [13.2327, 47.0447],
            [13.2472, 47.0474],
            [13.2427, 47.0568],
            [13.2467, 47.0651],
            [13.2408, 47.0695],
            [13.2503, 47.0864],
            [13.2909, 47.0953],
            [13.301, 47.0857],
            [13.3099, 47.085],
            [13.3337, 47.0941],
            [13.3624, 47.097],
            [13.3787, 47.0915],
            [13.3814, 47.0796],
            [13.3978, 47.0688],
            [13.4089, 47.0691],
            [13.4271, 47.0802],
            [13.4396, 47.0771],
            [13.46, 47.0786],
            [13.5041, 47.0688],
            [13.5195, 47.0676],
            [13.5592, 47.0551],
            [13.5808, 47.0509],
            [13.6008, 47.0539],
            [13.6127, 47.0585],
            [13.637, 47.0543],
            [13.6433, 47.0419],
            [13.6506, 47.0385],
            [13.6702, 47.0385],
            [13.681, 47.0407],
            [13.6951, 47.0353],
            [13.6875, 47.0278],
            [13.6943, 47.0053],
            [13.7063, 46.9949],
            [13.7315, 46.9886],
            [13.745, 46.9933],
            [13.7543, 46.9909],
            [13.76, 46.9811],
            [13.7731, 46.9747],
            [13.7757, 46.9504],
            [13.7829, 46.9436],
            [13.7842, 46.9374],
            [13.8111, 46.9245],
            [13.8162, 46.9176],
            [13.8489, 46.9095],
            [13.8652, 46.9199],
            [13.8936, 46.9212],
            [13.905, 46.9175],
            [13.909, 46.9251],
            [13.9362, 46.9245],
            [13.928, 46.934],
            [13.9164, 46.9381],
            [13.9328, 46.9443],
            [13.9505, 46.9416],
            [13.9512, 46.9518],
            [13.9639, 46.9603],
            [13.9777, 46.9654],
            [14.005, 46.9706],
            [14.0037, 46.9769],
            [14.0165, 46.9851],
            [14.0151, 47.0039],
            [14.0254, 47.0072],
            [14.0534, 47.0041],
            [14.0703, 47.0101],
            [14.0789, 47.0217],
            [14.1101, 47.0403],
            [14.1261, 47.0448],
            [14.124, 47.0562],
            [14.131, 47.0609],
            [14.163, 47.0516],
            [14.1747, 47.0602],
            [14.1933, 47.0701],
            [14.2032, 47.0693],
            [14.2186, 47.0524],
            [14.2215, 47.0385],
            [14.2335, 47.0261],
            [14.2508, 47.0348],
            [14.264, 47.0247],
            [14.3021, 47.0278],
            [14.328, 47.0281],
            [14.3383, 47.023],
            [14.3634, 47.024],
            [14.3818, 47.0141],
            [14.3766, 47.0072],
            [14.3763, 46.9963],
            [14.3827, 46.9804],
            [14.4, 46.9789],
            [14.4252, 46.9945],
            [14.4403, 46.9956],
            [14.456, 46.9937],
            [14.4591, 47.0054],
            [14.475, 47.0115],
            [14.4829, 47.0207],
            [14.5024, 47.0256],
            [14.5035, 47.0185],
            [14.5142, 47.0093],
            [14.554, 47.0117],
            [14.5858, 47.0117],
            [14.5941, 47.0005],
            [14.6139, 46.9963],
            [14.6268, 47.0141],
            [14.6494, 47.0143],
            [14.659, 47.0177],
            [14.6871, 47.0184],
            [14.7054, 47.0246],
            [14.7214, 47.0247],
            [14.7361, 47.0318],
            [14.7409, 47.0288],
            [14.7685, 47.0378],
            [14.7837, 47.0368],
            [14.7983, 47.0399],
            [14.8086, 47.0387],
            [14.8304, 47.0491],
            [14.8429, 47.0488],
            [14.8392, 47.0418],
            [14.85, 47.0296],
            [14.8738, 47.0223],
            [14.8858, 47.0107],
            [14.9051, 46.9989],
            [14.9407, 46.9925],
            [14.9509, 46.9927],
            [14.9699, 46.9789],
            [14.9668, 46.9725],
            [14.9781, 46.9642],
            [14.9918, 46.9371],
            [15.0162, 46.921],
            [15.0263, 46.9095],
            [15.0164, 46.8852],
            [15.0066, 46.8795],
            [15.007, 46.8562],
            [15.0175, 46.8444],
            [15.0132, 46.8336],
            [14.9881, 46.823],
            [14.986, 46.8102],
            [14.9778, 46.8026],
            [14.9748, 46.786],
            [14.9928, 46.7835],
            [15.0071, 46.7751],
            [15.0244, 46.7724],
            [15.025, 46.762],
            [15.0338, 46.7494],
            [15.0328, 46.7434],
            [15.0407, 46.7363],
            [15.0353, 46.7281],
            [15.0419, 46.7232],
            [15.044, 46.7135],
            [15.0351, 46.7054],
            [15.0299, 46.6794],
            [15.0216, 46.6725],
            [15.0352, 46.6665],
            [15.0501, 46.6687],
            [15.0618, 46.6602],
            [15.0655, 46.6513],
            [15.0422, 46.6519],
            [15.0148, 46.6402],
            [15.0068, 46.6299],
            [14.9932, 46.6215],
            [14.9941, 46.6126],
            [14.9756, 46.602],
            [14.9678, 46.6193],
            [14.9537, 46.628],
            [14.9423, 46.6236],
            [14.9312, 46.6151],
            [14.9202, 46.6182],
            [14.9235, 46.6014],
            [14.9185, 46.5992],
            [14.8887, 46.6104],
            [14.866, 46.6022],
            [14.868, 46.5953],
            [14.8574, 46.5909],
            [14.851, 46.5805],
            [14.8392, 46.5726],
            [14.8417, 46.5669],
            [14.8364, 46.5558],
            [14.8243, 46.5459],
            [14.831, 46.5381],
            [14.8166, 46.5213],
            [14.818, 46.5101],
            [14.783, 46.4991],
            [14.7749, 46.5036],
            [14.758, 46.5034],
            [14.7468, 46.4975],
            [14.7405, 46.5042],
            [14.7324, 46.4932],
            [14.7175, 46.4973],
            [14.7101, 46.4913],
            [14.7063, 46.4785],
            [14.697, 46.4734],
            [14.6993, 46.4632],
            [14.6809, 46.4659],
            [14.6749, 46.4497],
            [14.6613, 46.4437],
            [14.6504, 46.4436],
            [14.6342, 46.4388],
            [14.6126, 46.4388],
            [14.5965, 46.4354],
            [14.5856, 46.4284],
            [14.5829, 46.4151],
            [14.5887, 46.4121],
            [14.5865, 46.3932],
            [14.5769, 46.3753],
            [14.5625, 46.372],
            [14.5568, 46.3766],
            [14.552, 46.3936],
            [14.5415, 46.4027],
            [14.5382, 46.4121],
            [14.5215, 46.4236],
            [14.5086, 46.4249],
            [14.4926, 46.4122],
            [14.4767, 46.4187],
            [14.442, 46.4224],
            [14.4419, 46.4269],
            [14.4304, 46.4336],
            [14.4362, 46.4392],
            [14.4296, 46.4455],
            [14.3981, 46.4444],
            [14.3815, 46.4378],
            [14.3703, 46.4397],
            [14.3466, 46.4362],
            [14.331, 46.431],
            [14.3118, 46.4294],
            [14.285, 46.4426],
            [14.2656, 46.4375],
            [14.2577, 46.4382],
            [14.2377, 46.4317],
            [14.2278, 46.4343],
            [14.2217, 46.4412],
            [14.2045, 46.4388],
            [14.1914, 46.4418],
            [14.169, 46.4328],
            [14.1574, 46.4335],
            [14.1519, 46.4422],
            [14.135, 46.4503],
            [14.1361, 46.4559],
            [14.1239, 46.4712],
            [14.1169, 46.4761],
            [14.1006, 46.478],
            [14.0939, 46.4836],
            [14.0705, 46.4862],
            [14.051, 46.4917],
            [14.0349, 46.4904],
            [14.0207, 46.4813],
            [14.0078, 46.4805],
            [13.9878, 46.4923],
            [13.9594, 46.501],
            [13.9425, 46.511],
            [13.9264, 46.5136],
            [13.9113, 46.5206],
            [13.8978, 46.5185],
            [13.8818, 46.5221],
            [13.8596, 46.5142],
            [13.8283, 46.5142],
            [13.8115, 46.5058],
            [13.7941, 46.5051],
            [13.7711, 46.5155],
            [13.7357, 46.517],
            [13.7209, 46.5222],
            [13.7058, 46.5179],
            [13.6665, 46.5228],
            [13.6328, 46.5351],
            [13.6174, 46.5393],
            [13.5872, 46.5421],
            [13.5765, 46.535],
            [13.5678, 46.5376],
            [13.559, 46.5492],
            [13.541, 46.5504],
            [13.5198, 46.5445],
            [13.5125, 46.5463],
            [13.5009, 46.5641],
            [13.4713, 46.5543],
            [13.4551, 46.5565],
            [13.4437, 46.5536],
            [13.4362, 46.5565],
            [13.4248, 46.5547],
            [13.4082, 46.558],
            [13.4079, 46.5696],
            [13.372, 46.5766],
            [13.3675, 46.571],
            [13.3508, 46.5638],
            [13.344, 46.5663],
            [13.3209, 46.5491],
            [13.3071, 46.5515],
            [13.2975, 46.5572],
            [13.2858, 46.5551],
            [13.2669, 46.5582],
            [13.2497, 46.5573],
            [13.238, 46.5492],
            [13.2294, 46.5522],
            [13.2273, 46.5599],
            [13.2163, 46.5681],
            [13.1923, 46.5697],
            [13.176, 46.5775],
            [13.1666, 46.5863],
            [13.1453, 46.5853],
            [13.1357, 46.5897],
            [13.1247, 46.589],
            [13.1053, 46.5923],
            [13.0853, 46.6],
            [13.0805, 46.5973],
            [13.0644, 46.5983],
            [13.0475, 46.5927],
            [13.0292, 46.595],
            [13.0215, 46.5997],
            [12.9918, 46.5978],
            [12.9578, 46.6045],
            [12.9521, 46.6025],
            [12.9318, 46.6082],
            [12.897, 46.6088],
            [12.8882, 46.6062],
            [12.8646, 46.6083],
            [12.8454, 46.605],
            [12.8338, 46.6097],
            [12.8365, 46.6264],
            [12.8283, 46.6298],
            [12.8179, 46.6275],
            [12.8043, 46.6317],
            [12.7926, 46.6402],
            [12.7615, 46.6469],
            [12.7461, 46.6426],
            [12.7442, 46.6383],
            [12.7297, 46.6345],
            [12.7137, 46.6485],
            [12.6988, 46.6514],
            [12.6899, 46.6568],
            [12.6859, 46.6862],
            [12.6975, 46.7036],
            [12.7204, 46.7007],
            [12.7067, 46.713],
            [12.7144, 46.7293],
            [12.7118, 46.7362],
            [12.726, 46.7424],
            [12.7408, 46.7441],
            [12.7561, 46.7512],
            [12.773, 46.7516],
            [12.8049, 46.7576],
            [12.8133, 46.7506],
            [12.8228, 46.7517],
            [12.8305, 46.7475],
            [12.842, 46.7492],
            [12.8555, 46.7592],
            [12.8792, 46.7631],
            [12.8982, 46.7746],
            [12.9039, 46.7742],
            [12.9201, 46.7642],
            [12.9299, 46.7627],
            [12.9456, 46.769],
            [12.9536, 46.7814],
            [12.9658, 46.7869],
            [12.9649, 46.7958],
            [12.9446, 46.8034],
            [12.9127, 46.8209],
            [12.8973, 46.8237],
            [12.8866, 46.8421],
            [12.8741, 46.8452],
            [12.8664, 46.8505],
            [12.8452, 46.8588],
            [12.8412, 46.8914],
            [12.8306, 46.9004],
            [12.8359, 46.914],
            [12.8211, 46.917],
            [12.8108, 46.922],
            [12.7983, 46.9223],
            [12.7821, 46.9342],
            [12.7847, 46.9393],
            [12.7678, 46.944],
            [12.7582, 46.9528],
            [12.7574, 46.9588],
            [12.7453, 46.9604],
            [12.7367, 46.9808],
            [12.723, 46.9952],
            [12.7292, 46.9965],
            [12.7407, 47.0147],
            [12.7531, 47.0257],
            [12.7549, 47.033],
            [12.7656, 47.0374],
            [12.7663, 47.0432],
            [12.7545, 47.0445],
            [12.7418, 47.0408],
            [12.7154, 47.0443],
            [12.7059, 47.0574],
            [12.6986, 47.0744],
            [12.6788, 47.0844],
            [12.6558, 47.0992],
            [12.6476, 47.1011],
          ],
        ],
      },
      properties: {name: 'Carinthia', id: 'AT-2', CNTRYNAME: 'Austria', TYPE: 'State'},
      id: 'AT-2',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.0679, 48.1182],
            [17.0647, 48.1091],
            [17.0438, 48.1004],
            [17.0357, 48.0854],
            [17.0125, 48.0887],
            [17.0257, 48.099],
            [17.0224, 48.1038],
            [17.0051, 48.0992],
            [17, 48.108],
            [16.9751, 48.1043],
            [16.9697, 48.0958],
            [16.9804, 48.0945],
            [17.0156, 48.0738],
            [16.9976, 48.0547],
            [16.9858, 48.0294],
            [16.9806, 48.0231],
            [16.9688, 48.0261],
            [16.9771, 48.034],
            [16.9722, 48.0407],
            [16.9531, 48.0455],
            [16.9194, 48.0501],
            [16.918, 48.0676],
            [16.8912, 48.0741],
            [16.8823, 48.0676],
            [16.8749, 48.0724],
            [16.862, 48.0591],
            [16.8487, 48.0561],
            [16.8425, 48.0464],
            [16.829, 48.0408],
            [16.838, 48.0315],
            [16.8269, 48.0268],
            [16.8137, 48.0297],
            [16.7917, 48.023],
            [16.7735, 48.0197],
            [16.7544, 48.0117],
            [16.7465, 48.0139],
            [16.7292, 48.0086],
            [16.7145, 48.0124],
            [16.7002, 48.0042],
            [16.6999, 47.9905],
            [16.6962, 47.9861],
            [16.7008, 47.975],
            [16.6964, 47.9637],
            [16.6779, 47.9596],
            [16.6687, 47.96],
            [16.6531, 47.9558],
            [16.6523, 47.9492],
            [16.6305, 47.9451],
            [16.6318, 47.9286],
            [16.6089, 47.9147],
            [16.6013, 47.9044],
            [16.588, 47.9009],
            [16.5883, 47.8955],
            [16.5744, 47.8925],
            [16.5607, 47.8988],
            [16.5537, 47.9089],
            [16.5326, 47.9244],
            [16.5192, 47.9424],
            [16.5035, 47.9393],
            [16.4847, 47.941],
            [16.4701, 47.9352],
            [16.4646, 47.9387],
            [16.4483, 47.9337],
            [16.4369, 47.9255],
            [16.4253, 47.9134],
            [16.4177, 47.9099],
            [16.4229, 47.9018],
            [16.4086, 47.8797],
            [16.3996, 47.8762],
            [16.3878, 47.882],
            [16.3809, 47.8751],
            [16.3569, 47.8644],
            [16.3686, 47.8542],
            [16.3832, 47.847],
            [16.3876, 47.8408],
            [16.3491, 47.8165],
            [16.3448, 47.8037],
            [16.3312, 47.8005],
            [16.3251, 47.7878],
            [16.31, 47.7872],
            [16.3112, 47.806],
            [16.2859, 47.81],
            [16.2772, 47.8084],
            [16.2684, 47.7919],
            [16.2875, 47.7865],
            [16.2973, 47.7876],
            [16.3028, 47.7811],
            [16.2937, 47.7672],
            [16.2977, 47.7536],
            [16.2973, 47.74],
            [16.2901, 47.7271],
            [16.295, 47.7135],
            [16.3087, 47.6937],
            [16.3077, 47.6867],
            [16.3219, 47.6842],
            [16.3354, 47.6768],
            [16.3514, 47.6807],
            [16.3519, 47.6706],
            [16.3605, 47.6636],
            [16.3618, 47.6554],
            [16.3732, 47.6433],
            [16.3673, 47.6348],
            [16.3717, 47.6263],
            [16.3688, 47.6209],
            [16.356, 47.6203],
            [16.3538, 47.6119],
            [16.3451, 47.6006],
            [16.3305, 47.598],
            [16.3299, 47.5812],
            [16.3142, 47.565],
            [16.3168, 47.5592],
            [16.3102, 47.5449],
            [16.3384, 47.5252],
            [16.3394, 47.5149],
            [16.3327, 47.5043],
            [16.3225, 47.4952],
            [16.3113, 47.4893],
            [16.3112, 47.4803],
            [16.3005, 47.4676],
            [16.2877, 47.4619],
            [16.2808, 47.4549],
            [16.2712, 47.4546],
            [16.2553, 47.4495],
            [16.2421, 47.4365],
            [16.2235, 47.4268],
            [16.1927, 47.4209],
            [16.1695, 47.4222],
            [16.1454, 47.435],
            [16.1399, 47.4467],
            [16.1318, 47.4542],
            [16.1353, 47.4615],
            [16.1222, 47.4698],
            [16.1193, 47.4861],
            [16.114, 47.4989],
            [16.1188, 47.5063],
            [16.1118, 47.5103],
            [16.111, 47.5206],
            [16.0982, 47.5231],
            [16.094, 47.5103],
            [16.0807, 47.5038],
            [16.0661, 47.5021],
            [16.0624, 47.4888],
            [16.0488, 47.4885],
            [16.0263, 47.5124],
            [16.0107, 47.5167],
            [16.0007, 47.5149],
            [15.9754, 47.5203],
            [15.9525, 47.5178],
            [15.9382, 47.5264],
            [15.9176, 47.5319],
            [15.9064, 47.5401],
            [15.903, 47.5481],
            [15.9059, 47.5613],
            [15.8897, 47.572],
            [15.8753, 47.5736],
            [15.8643, 47.5655],
            [15.8483, 47.5674],
            [15.8459, 47.585],
            [15.8492, 47.5975],
            [15.8571, 47.6036],
            [15.8613, 47.6126],
            [15.857, 47.6209],
            [15.8465, 47.6178],
            [15.8326, 47.6229],
            [15.8225, 47.6364],
            [15.8057, 47.6336],
            [15.7808, 47.6416],
            [15.7638, 47.634],
            [15.7493, 47.6308],
            [15.7438, 47.641],
            [15.7258, 47.6478],
            [15.7286, 47.6663],
            [15.7161, 47.6949],
            [15.72, 47.7084],
            [15.7161, 47.7112],
            [15.6969, 47.7113],
            [15.6915, 47.7035],
            [15.6778, 47.6978],
            [15.6739, 47.7072],
            [15.6521, 47.7166],
            [15.6373, 47.714],
            [15.628, 47.7294],
            [15.6197, 47.7361],
            [15.6174, 47.7445],
            [15.5984, 47.7498],
            [15.583, 47.7462],
            [15.5644, 47.7446],
            [15.5534, 47.7482],
            [15.5279, 47.7436],
            [15.5133, 47.7454],
            [15.5191, 47.7602],
            [15.5074, 47.7667],
            [15.4778, 47.7691],
            [15.4609, 47.7669],
            [15.4456, 47.775],
            [15.4386, 47.7906],
            [15.4226, 47.7823],
            [15.4117, 47.7833],
            [15.404, 47.807],
            [15.4054, 47.8129],
            [15.3896, 47.8139],
            [15.3742, 47.8226],
            [15.3625, 47.8215],
            [15.3457, 47.8258],
            [15.3347, 47.8252],
            [15.3271, 47.8196],
            [15.3167, 47.8219],
            [15.3024, 47.8183],
            [15.2941, 47.8113],
            [15.2937, 47.7973],
            [15.2889, 47.7883],
            [15.2619, 47.7924],
            [15.254, 47.7905],
            [15.2366, 47.7975],
            [15.2175, 47.7929],
            [15.2081, 47.7863],
            [15.2066, 47.7764],
            [15.2103, 47.7608],
            [15.1995, 47.7517],
            [15.1931, 47.7544],
            [15.1695, 47.7526],
            [15.1594, 47.757],
            [15.1441, 47.756],
            [15.1372, 47.7587],
            [15.1326, 47.7468],
            [15.1278, 47.745],
            [15.0822, 47.74],
            [15.0602, 47.7386],
            [15.0346, 47.752],
            [15.0134, 47.7442],
            [14.9968, 47.7401],
            [14.9828, 47.7397],
            [14.9731, 47.7432],
            [14.9663, 47.7363],
            [14.9518, 47.7319],
            [14.9427, 47.7229],
            [14.93, 47.7152],
            [14.9285, 47.7104],
            [14.9081, 47.7094],
            [14.8763, 47.7157],
            [14.8734, 47.7304],
            [14.8604, 47.7417],
            [14.838, 47.7333],
            [14.8306, 47.7435],
            [14.8207, 47.7452],
            [14.8095, 47.7366],
            [14.7993, 47.7372],
            [14.7756, 47.7271],
            [14.7427, 47.7387],
            [14.7395, 47.7474],
            [14.7441, 47.7565],
            [14.7363, 47.7584],
            [14.7239, 47.7679],
            [14.7137, 47.7893],
            [14.7328, 47.8023],
            [14.7138, 47.8135],
            [14.7108, 47.8189],
            [14.7236, 47.8307],
            [14.7272, 47.8403],
            [14.7157, 47.8444],
            [14.7109, 47.8501],
            [14.7371, 47.8632],
            [14.7575, 47.8663],
            [14.7731, 47.8832],
            [14.7609, 47.9015],
            [14.7493, 47.9077],
            [14.7444, 47.9167],
            [14.7306, 47.9229],
            [14.7289, 47.9342],
            [14.71, 47.9306],
            [14.7044, 47.9249],
            [14.6846, 47.9291],
            [14.6665, 47.9388],
            [14.6481, 47.9422],
            [14.6302, 47.9414],
            [14.6242, 47.9472],
            [14.6092, 47.9502],
            [14.5896, 47.965],
            [14.5736, 47.9731],
            [14.5256, 47.9894],
            [14.5027, 47.9925],
            [14.4826, 48.0171],
            [14.4746, 48.0317],
            [14.4574, 48.0397],
            [14.4625, 48.0606],
            [14.4583, 48.0804],
            [14.4594, 48.0886],
            [14.4733, 48.1006],
            [14.4918, 48.1077],
            [14.4953, 48.1128],
            [14.4704, 48.1247],
            [14.4792, 48.1333],
            [14.4787, 48.1397],
            [14.4704, 48.1458],
            [14.4711, 48.1523],
            [14.4821, 48.1656],
            [14.4847, 48.1763],
            [14.4805, 48.1822],
            [14.4846, 48.1881],
            [14.4822, 48.1989],
            [14.4916, 48.2211],
            [14.5227, 48.2363],
            [14.5344, 48.2367],
            [14.577, 48.2254],
            [14.5892, 48.2208],
            [14.6124, 48.195],
            [14.626, 48.189],
            [14.638, 48.1786],
            [14.6672, 48.1603],
            [14.6772, 48.1619],
            [14.6891, 48.1568],
            [14.7005, 48.1578],
            [14.7162, 48.1772],
            [14.7279, 48.1772],
            [14.7428, 48.1728],
            [14.8082, 48.187],
            [14.8317, 48.1891],
            [14.8449, 48.1953],
            [14.8558, 48.2098],
            [14.8598, 48.2266],
            [14.8841, 48.2325],
            [14.8931, 48.2272],
            [14.9155, 48.2322],
            [14.9386, 48.2308],
            [14.9482, 48.2218],
            [14.9585, 48.2053],
            [14.9735, 48.1992],
            [14.9788, 48.202],
            [14.96, 48.2155],
            [14.9567, 48.2249],
            [14.9725, 48.2338],
            [14.9708, 48.2467],
            [14.9901, 48.2575],
            [14.9877, 48.2648],
            [14.9858, 48.3001],
            [14.9797, 48.3227],
            [14.9606, 48.3251],
            [14.9419, 48.3235],
            [14.9487, 48.3411],
            [14.9571, 48.3747],
            [14.9623, 48.3793],
            [14.9638, 48.3895],
            [14.9449, 48.395],
            [14.9337, 48.3944],
            [14.9237, 48.3885],
            [14.9192, 48.3966],
            [14.9333, 48.3993],
            [14.9441, 48.406],
            [14.9369, 48.4256],
            [14.9182, 48.429],
            [14.9028, 48.447],
            [14.8746, 48.4608],
            [14.8771, 48.4715],
            [14.8692, 48.4808],
            [14.8785, 48.4828],
            [14.8888, 48.4898],
            [14.8927, 48.4996],
            [14.9022, 48.5016],
            [14.9203, 48.4989],
            [14.92, 48.5059],
            [14.9105, 48.5097],
            [14.9122, 48.5162],
            [14.9004, 48.5231],
            [14.8806, 48.5267],
            [14.8436, 48.5305],
            [14.8497, 48.5372],
            [14.8233, 48.5485],
            [14.813, 48.5488],
            [14.8089, 48.5565],
            [14.7988, 48.5561],
            [14.7821, 48.5605],
            [14.7683, 48.5558],
            [14.76, 48.565],
            [14.7474, 48.559],
            [14.7323, 48.5588],
            [14.7165, 48.5712],
            [14.7088, 48.58],
            [14.6953, 48.5797],
            [14.6918, 48.5851],
            [14.7013, 48.5821],
            [14.7064, 48.5911],
            [14.721, 48.601],
            [14.7143, 48.617],
            [14.7214, 48.6362],
            [14.7186, 48.6482],
            [14.7123, 48.6504],
            [14.719, 48.6678],
            [14.7263, 48.6782],
            [14.7264, 48.6855],
            [14.7355, 48.6982],
            [14.7574, 48.7093],
            [14.7733, 48.7113],
            [14.7843, 48.7172],
            [14.7936, 48.7292],
            [14.8082, 48.7337],
            [14.801, 48.7473],
            [14.8121, 48.7653],
            [14.8065, 48.7716],
            [14.8095, 48.7789],
            [14.8259, 48.7834],
            [14.8426, 48.7838],
            [14.8511, 48.7773],
            [14.8684, 48.781],
            [14.8806, 48.7803],
            [14.888, 48.7701],
            [14.8959, 48.7724],
            [14.9062, 48.767],
            [14.9162, 48.7694],
            [14.932, 48.7664],
            [14.9556, 48.7581],
            [14.9797, 48.7718],
            [14.9802, 48.7789],
            [14.9694, 48.7847],
            [14.9558, 48.7879],
            [14.9535, 48.7981],
            [14.9594, 48.8039],
            [14.9591, 48.8156],
            [14.9689, 48.8233],
            [14.9683, 48.8362],
            [14.9721, 48.842],
            [14.9845, 48.8424],
            [14.9728, 48.8535],
            [14.972, 48.8744],
            [14.9841, 48.8748],
            [14.9829, 48.8901],
            [14.9919, 48.9029],
            [14.9908, 48.9148],
            [14.9788, 48.9272],
            [14.9855, 48.9407],
            [14.9768, 48.9537],
            [14.9784, 48.98],
            [14.9854, 48.987],
            [14.9945, 48.9895],
            [14.9998, 49.0043],
            [14.9911, 49.0092],
            [15.0111, 49.0137],
            [15.0209, 49.0202],
            [15.0419, 49.0111],
            [15.0649, 49.0149],
            [15.0686, 48.9991],
            [15.0901, 48.9972],
            [15.1028, 48.9937],
            [15.1077, 48.9986],
            [15.142, 48.9971],
            [15.1568, 48.9929],
            [15.157, 48.9845],
            [15.1697, 48.9614],
            [15.1562, 48.948],
            [15.161, 48.9424],
            [15.1774, 48.9472],
            [15.1899, 48.9431],
            [15.1999, 48.9519],
            [15.225, 48.9601],
            [15.2409, 48.9595],
            [15.262, 48.9535],
            [15.2687, 48.9574],
            [15.2817, 48.9807],
            [15.2758, 48.9874],
            [15.2776, 48.994],
            [15.2895, 48.9925],
            [15.2915, 48.9841],
            [15.307, 48.9784],
            [15.322, 48.9761],
            [15.3327, 48.9846],
            [15.3485, 48.9846],
            [15.3672, 48.9816],
            [15.3889, 48.9725],
            [15.3913, 48.9667],
            [15.4032, 48.9636],
            [15.4125, 48.9566],
            [15.4257, 48.9513],
            [15.4519, 48.949],
            [15.4564, 48.9451],
            [15.4691, 48.9516],
            [15.4776, 48.9446],
            [15.5015, 48.9362],
            [15.5073, 48.9198],
            [15.515, 48.9131],
            [15.5574, 48.9047],
            [15.5784, 48.8961],
            [15.6192, 48.8955],
            [15.6302, 48.8846],
            [15.6424, 48.886],
            [15.6548, 48.8817],
            [15.6867, 48.8567],
            [15.7095, 48.8606],
            [15.716, 48.8578],
            [15.7378, 48.8552],
            [15.7522, 48.8514],
            [15.7651, 48.8582],
            [15.779, 48.8746],
            [15.8053, 48.8721],
            [15.8318, 48.8719],
            [15.8395, 48.8773],
            [15.8635, 48.8527],
            [15.8809, 48.8548],
            [15.8859, 48.8418],
            [15.898, 48.8483],
            [15.9085, 48.8357],
            [15.9171, 48.8385],
            [15.9271, 48.8307],
            [15.9242, 48.8241],
            [15.9331, 48.8194],
            [15.948, 48.8225],
            [15.9526, 48.8144],
            [15.9508, 48.8052],
            [15.9805, 48.7896],
            [15.9938, 48.7787],
            [16.0089, 48.7764],
            [16.0363, 48.7651],
            [16.051, 48.7613],
            [16.0629, 48.7546],
            [16.1033, 48.7452],
            [16.1183, 48.7465],
            [16.1381, 48.7428],
            [16.1541, 48.7487],
            [16.176, 48.7462],
            [16.1918, 48.7424],
            [16.2466, 48.7402],
            [16.2619, 48.7373],
            [16.2774, 48.7414],
            [16.3117, 48.7333],
            [16.3364, 48.733],
            [16.342, 48.7467],
            [16.3573, 48.7382],
            [16.3519, 48.7335],
            [16.3604, 48.7276],
            [16.3957, 48.7367],
            [16.4097, 48.7444],
            [16.4155, 48.7579],
            [16.4377, 48.7685],
            [16.4388, 48.7822],
            [16.4482, 48.7904],
            [16.4497, 48.7997],
            [16.4696, 48.8062],
            [16.4769, 48.8022],
            [16.4923, 48.8012],
            [16.5287, 48.8034],
            [16.541, 48.8137],
            [16.5571, 48.8026],
            [16.5669, 48.7929],
            [16.5772, 48.7935],
            [16.593, 48.7876],
            [16.5943, 48.7819],
            [16.6302, 48.7803],
            [16.6372, 48.7866],
            [16.6496, 48.786],
            [16.6639, 48.781],
            [16.6649, 48.7754],
            [16.675, 48.7709],
            [16.6808, 48.7591],
            [16.6813, 48.7505],
            [16.6736, 48.7391],
            [16.6825, 48.7279],
            [16.7012, 48.7366],
            [16.7254, 48.7377],
            [16.7405, 48.7309],
            [16.7475, 48.7322],
            [16.7536, 48.7206],
            [16.7648, 48.7188],
            [16.7724, 48.7125],
            [16.7899, 48.7138],
            [16.817, 48.7102],
            [16.8481, 48.7125],
            [16.8676, 48.7115],
            [16.88, 48.7231],
            [16.8862, 48.7157],
            [16.9024, 48.7176],
            [16.9027, 48.7116],
            [16.9119, 48.7064],
            [16.9182, 48.6929],
            [16.9097, 48.6769],
            [16.9222, 48.6705],
            [16.9233, 48.6583],
            [16.9193, 48.651],
            [16.9276, 48.6424],
            [16.9198, 48.6217],
            [16.9413, 48.6163],
            [16.9415, 48.6049],
            [16.9358, 48.6013],
            [16.9403, 48.5841],
            [16.9367, 48.5726],
            [16.9457, 48.5614],
            [16.9446, 48.5435],
            [16.9504, 48.5362],
            [16.9429, 48.5306],
            [16.9415, 48.5217],
            [16.9215, 48.5208],
            [16.9138, 48.5131],
            [16.9186, 48.4969],
            [16.9013, 48.4988],
            [16.8928, 48.4939],
            [16.8974, 48.4874],
            [16.8733, 48.4684],
            [16.865, 48.4661],
            [16.8613, 48.4523],
            [16.8513, 48.4476],
            [16.8544, 48.4326],
            [16.8638, 48.4197],
            [16.8528, 48.4131],
            [16.8564, 48.3997],
            [16.8524, 48.385],
            [16.8349, 48.381],
            [16.8427, 48.3715],
            [16.8442, 48.3536],
            [16.8507, 48.3494],
            [16.8757, 48.3479],
            [16.8737, 48.3388],
            [16.9083, 48.3276],
            [16.91, 48.3234],
            [16.897, 48.3101],
            [16.9105, 48.2885],
            [16.93, 48.272],
            [16.9485, 48.2708],
            [16.9584, 48.2647],
            [16.9528, 48.2567],
            [16.9485, 48.2418],
            [16.9503, 48.2342],
            [16.9608, 48.2264],
            [16.9712, 48.197],
            [16.9763, 48.1884],
            [16.9759, 48.172],
            [16.9957, 48.163],
            [17.0247, 48.1432],
            [17.0353, 48.1402],
            [17.0566, 48.1433],
            [17.0675, 48.1274],
            [17.0679, 48.1182],
          ],
          [
            [16.4416, 48.3034],
            [16.44, 48.3072],
            [16.4357, 48.3155],
            [16.419, 48.3212],
            [16.3936, 48.318],
            [16.3859, 48.3225],
            [16.3735, 48.3134],
            [16.3754, 48.3107],
            [16.3812, 48.3063],
            [16.3729, 48.2957],
            [16.3773, 48.2894],
            [16.3572, 48.281],
            [16.3474, 48.2889],
            [16.3183, 48.2787],
            [16.3054, 48.2712],
            [16.2879, 48.269],
            [16.2917, 48.2627],
            [16.2698, 48.2514],
            [16.256, 48.2406],
            [16.2423, 48.2379],
            [16.2365, 48.2476],
            [16.2073, 48.262],
            [16.1957, 48.2422],
            [16.1969, 48.205],
            [16.2019, 48.2007],
            [16.2008, 48.187],
            [16.1832, 48.1711],
            [16.1934, 48.1662],
            [16.1986, 48.1544],
            [16.2103, 48.1487],
            [16.2226, 48.1341],
            [16.2357, 48.1354],
            [16.2485, 48.1286],
            [16.2707, 48.1327],
            [16.2987, 48.1273],
            [16.3125, 48.1188],
            [16.3191, 48.1218],
            [16.3283, 48.1364],
            [16.3601, 48.1281],
            [16.3877, 48.1246],
            [16.4057, 48.1198],
            [16.4334, 48.1252],
            [16.433, 48.1361],
            [16.4543, 48.1377],
            [16.4855, 48.1566],
            [16.5005, 48.1555],
            [16.5139, 48.158],
            [16.5315, 48.1507],
            [16.5434, 48.1419],
            [16.576, 48.1354],
            [16.5783, 48.1464],
            [16.5714, 48.1617],
            [16.5524, 48.1614],
            [16.5465, 48.1703],
            [16.5481, 48.1784],
            [16.5385, 48.1797],
            [16.5399, 48.1924],
            [16.5364, 48.1999],
            [16.5472, 48.2185],
            [16.5451, 48.2236],
            [16.5526, 48.2373],
            [16.5415, 48.2422],
            [16.5467, 48.2611],
            [16.5293, 48.2609],
            [16.508, 48.2726],
            [16.5138, 48.2826],
            [16.5112, 48.2865],
            [16.4854, 48.2924],
            [16.4793, 48.2897],
            [16.4796, 48.2752],
            [16.469, 48.2776],
            [16.4433, 48.2912],
            [16.4416, 48.3034],
          ],
        ],
      },
      properties: {name: 'Lower Austria', id: 'AT-3', CNTRYNAME: 'Austria', TYPE: 'State'},
      id: 'AT-3',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.6918, 48.5851],
            [14.6953, 48.5797],
            [14.7088, 48.58],
            [14.7165, 48.5712],
            [14.7323, 48.5588],
            [14.7474, 48.559],
            [14.76, 48.565],
            [14.7683, 48.5558],
            [14.7821, 48.5605],
            [14.7988, 48.5561],
            [14.8089, 48.5565],
            [14.813, 48.5488],
            [14.8233, 48.5485],
            [14.8497, 48.5372],
            [14.8436, 48.5305],
            [14.8806, 48.5267],
            [14.9004, 48.5231],
            [14.9122, 48.5162],
            [14.9105, 48.5097],
            [14.92, 48.5059],
            [14.9203, 48.4989],
            [14.9022, 48.5016],
            [14.8927, 48.4996],
            [14.8888, 48.4898],
            [14.8785, 48.4828],
            [14.8692, 48.4808],
            [14.8771, 48.4715],
            [14.8746, 48.4608],
            [14.9028, 48.447],
            [14.9182, 48.429],
            [14.9369, 48.4256],
            [14.9441, 48.406],
            [14.9333, 48.3993],
            [14.9192, 48.3966],
            [14.9237, 48.3885],
            [14.9337, 48.3944],
            [14.9449, 48.395],
            [14.9638, 48.3895],
            [14.9623, 48.3793],
            [14.9571, 48.3747],
            [14.9487, 48.3411],
            [14.9419, 48.3235],
            [14.9606, 48.3251],
            [14.9797, 48.3227],
            [14.9858, 48.3001],
            [14.9877, 48.2648],
            [14.9901, 48.2575],
            [14.9708, 48.2467],
            [14.9725, 48.2338],
            [14.9567, 48.2249],
            [14.96, 48.2155],
            [14.9788, 48.202],
            [14.9735, 48.1992],
            [14.9585, 48.2053],
            [14.9482, 48.2218],
            [14.9386, 48.2308],
            [14.9155, 48.2322],
            [14.8931, 48.2272],
            [14.8841, 48.2325],
            [14.8598, 48.2266],
            [14.8558, 48.2098],
            [14.8449, 48.1953],
            [14.8317, 48.1891],
            [14.8082, 48.187],
            [14.7428, 48.1728],
            [14.7279, 48.1772],
            [14.7162, 48.1772],
            [14.7005, 48.1578],
            [14.6891, 48.1568],
            [14.6772, 48.1619],
            [14.6672, 48.1603],
            [14.638, 48.1786],
            [14.626, 48.189],
            [14.6124, 48.195],
            [14.5892, 48.2208],
            [14.577, 48.2254],
            [14.5344, 48.2367],
            [14.5227, 48.2363],
            [14.4916, 48.2211],
            [14.4822, 48.1989],
            [14.4846, 48.1881],
            [14.4805, 48.1822],
            [14.4847, 48.1763],
            [14.4821, 48.1656],
            [14.4711, 48.1523],
            [14.4704, 48.1458],
            [14.4787, 48.1397],
            [14.4792, 48.1333],
            [14.4704, 48.1247],
            [14.4953, 48.1128],
            [14.4918, 48.1077],
            [14.4733, 48.1006],
            [14.4594, 48.0886],
            [14.4583, 48.0804],
            [14.4625, 48.0606],
            [14.4574, 48.0397],
            [14.4746, 48.0317],
            [14.4826, 48.0171],
            [14.5027, 47.9925],
            [14.5256, 47.9894],
            [14.5736, 47.9731],
            [14.5896, 47.965],
            [14.6092, 47.9502],
            [14.6242, 47.9472],
            [14.6302, 47.9414],
            [14.6481, 47.9422],
            [14.6665, 47.9388],
            [14.6846, 47.9291],
            [14.7044, 47.9249],
            [14.71, 47.9306],
            [14.7289, 47.9342],
            [14.7306, 47.9229],
            [14.7444, 47.9167],
            [14.7493, 47.9077],
            [14.7609, 47.9015],
            [14.7731, 47.8832],
            [14.7575, 47.8663],
            [14.7371, 47.8632],
            [14.7109, 47.8501],
            [14.7157, 47.8444],
            [14.7272, 47.8403],
            [14.7236, 47.8307],
            [14.7108, 47.8189],
            [14.7138, 47.8135],
            [14.7328, 47.8023],
            [14.7137, 47.7893],
            [14.7239, 47.7679],
            [14.7363, 47.7584],
            [14.7441, 47.7565],
            [14.7395, 47.7474],
            [14.7285, 47.7544],
            [14.6943, 47.7637],
            [14.6878, 47.7584],
            [14.6823, 47.7472],
            [14.6751, 47.7403],
            [14.6516, 47.7335],
            [14.647, 47.7296],
            [14.6319, 47.7288],
            [14.6138, 47.7308],
            [14.5882, 47.7163],
            [14.5716, 47.714],
            [14.5548, 47.717],
            [14.5223, 47.7032],
            [14.5299, 47.6935],
            [14.5256, 47.6845],
            [14.5137, 47.6773],
            [14.5026, 47.6764],
            [14.4904, 47.6798],
            [14.475, 47.6742],
            [14.4675, 47.6684],
            [14.4507, 47.663],
            [14.4485, 47.6561],
            [14.4363, 47.6517],
            [14.4196, 47.6552],
            [14.4011, 47.6528],
            [14.3944, 47.6484],
            [14.396, 47.636],
            [14.3677, 47.6287],
            [14.337, 47.6183],
            [14.3272, 47.6067],
            [14.301, 47.6085],
            [14.2836, 47.613],
            [14.2652, 47.6094],
            [14.2347, 47.6275],
            [14.2243, 47.6416],
            [14.2339, 47.6498],
            [14.2059, 47.6508],
            [14.1827, 47.6381],
            [14.1672, 47.6385],
            [14.1591, 47.6351],
            [14.1478, 47.6362],
            [14.133, 47.6288],
            [14.1098, 47.626],
            [14.0915, 47.6186],
            [14.0657, 47.6153],
            [14.0509, 47.6168],
            [14.0463, 47.6263],
            [14.0551, 47.6676],
            [14.0485, 47.6738],
            [14.0367, 47.6784],
            [14.019, 47.6906],
            [14.0092, 47.7008],
            [13.9827, 47.6967],
            [13.9516, 47.7092],
            [13.9325, 47.7081],
            [13.9187, 47.705],
            [13.9073, 47.7098],
            [13.9041, 47.717],
            [13.894, 47.7184],
            [13.8775, 47.7255],
            [13.8624, 47.7265],
            [13.8507, 47.7141],
            [13.839, 47.7185],
            [13.7995, 47.7149],
            [13.7912, 47.7126],
            [13.7784, 47.7165],
            [13.7611, 47.7015],
            [13.7552, 47.6999],
            [13.7484, 47.6874],
            [13.7351, 47.6843],
            [13.7363, 47.6737],
            [13.7142, 47.668],
            [13.695, 47.6281],
            [13.6948, 47.6169],
            [13.6984, 47.6041],
            [13.6924, 47.5894],
            [13.6933, 47.5831],
            [13.7098, 47.5798],
            [13.711, 47.5744],
            [13.7353, 47.5763],
            [13.7553, 47.5522],
            [13.7558, 47.5454],
            [13.7456, 47.538],
            [13.7519, 47.5245],
            [13.76, 47.5193],
            [13.7415, 47.4925],
            [13.7286, 47.4855],
            [13.7172, 47.4721],
            [13.7231, 47.4615],
            [13.7148, 47.4604],
            [13.6857, 47.462],
            [13.6538, 47.4617],
            [13.6408, 47.4662],
            [13.6269, 47.4662],
            [13.6085, 47.4736],
            [13.5856, 47.4725],
            [13.568, 47.4814],
            [13.5467, 47.4895],
            [13.5189, 47.4952],
            [13.5044, 47.509],
            [13.4879, 47.5134],
            [13.4808, 47.5203],
            [13.4769, 47.5353],
            [13.4841, 47.5508],
            [13.4778, 47.5676],
            [13.4971, 47.5797],
            [13.5054, 47.5815],
            [13.5063, 47.601],
            [13.5225, 47.6173],
            [13.516, 47.6296],
            [13.494, 47.6293],
            [13.4849, 47.6343],
            [13.4838, 47.6408],
            [13.5001, 47.6588],
            [13.4996, 47.6685],
            [13.5109, 47.676],
            [13.5166, 47.6749],
            [13.5332, 47.6882],
            [13.5367, 47.6974],
            [13.547, 47.7064],
            [13.5446, 47.7135],
            [13.5327, 47.7166],
            [13.5064, 47.7175],
            [13.4941, 47.7216],
            [13.4765, 47.7224],
            [13.438, 47.7368],
            [13.4394, 47.7496],
            [13.446, 47.7586],
            [13.4427, 47.7639],
            [13.4465, 47.7718],
            [13.458, 47.7768],
            [13.4643, 47.7858],
            [13.4996, 47.7751],
            [13.5171, 47.772],
            [13.5344, 47.7759],
            [13.5384, 47.7819],
            [13.5275, 47.7979],
            [13.5098, 47.7971],
            [13.5059, 47.7915],
            [13.4896, 47.7897],
            [13.4764, 47.7997],
            [13.4664, 47.8029],
            [13.442, 47.8026],
            [13.419, 47.7993],
            [13.4041, 47.7997],
            [13.3906, 47.7941],
            [13.371, 47.801],
            [13.3474, 47.8128],
            [13.3302, 47.8125],
            [13.3108, 47.8158],
            [13.3091, 47.8208],
            [13.29, 47.8239],
            [13.2862, 47.8296],
            [13.2881, 47.8464],
            [13.2953, 47.8556],
            [13.2701, 47.8899],
            [13.2806, 47.8961],
            [13.2818, 47.9141],
            [13.2768, 47.9199],
            [13.2848, 47.927],
            [13.2842, 47.9367],
            [13.2782, 47.9444],
            [13.2814, 47.9525],
            [13.2913, 47.9609],
            [13.3084, 47.9625],
            [13.3135, 47.9573],
            [13.3404, 47.9511],
            [13.3384, 47.945],
            [13.3567, 47.9413],
            [13.3646, 47.9595],
            [13.373, 47.9651],
            [13.3685, 47.9756],
            [13.353, 47.9769],
            [13.3468, 47.9824],
            [13.3299, 47.9881],
            [13.3007, 48.0029],
            [13.2949, 48.0099],
            [13.2815, 47.9994],
            [13.2571, 47.991],
            [13.2406, 47.9901],
            [13.1969, 47.9736],
            [13.1628, 47.9749],
            [13.1471, 47.9692],
            [13.1407, 47.9799],
            [13.1477, 47.9938],
            [13.1313, 47.9876],
            [13.1229, 47.9929],
            [13.1046, 47.9963],
            [13.0946, 47.9953],
            [13.076, 48.0044],
            [13.0729, 48.0136],
            [13.0574, 48.0187],
            [13.0607, 48.0235],
            [13.0516, 48.034],
            [13.0414, 48.0405],
            [13.0286, 48.0319],
            [13.0176, 48.0365],
            [13.0066, 48.0303],
            [12.9866, 48.0279],
            [12.9707, 48.0237],
            [12.9627, 48.032],
            [12.945, 48.0375],
            [12.9332, 48.0353],
            [12.9219, 48.022],
            [12.908, 48.02],
            [12.8954, 47.9959],
            [12.8603, 47.9972],
            [12.8562, 48.0094],
            [12.8418, 48.023],
            [12.7714, 48.0653],
            [12.7625, 48.0723],
            [12.7532, 48.0867],
            [12.7496, 48.1087],
            [12.7598, 48.1191],
            [12.7581, 48.1249],
            [12.7712, 48.1283],
            [12.7884, 48.1246],
            [12.8018, 48.1474],
            [12.8127, 48.1512],
            [12.8271, 48.1522],
            [12.8351, 48.1574],
            [12.8371, 48.1654],
            [12.8479, 48.1672],
            [12.8541, 48.1789],
            [12.8626, 48.188],
            [12.8659, 48.1985],
            [12.8744, 48.2033],
            [12.9069, 48.2042],
            [12.9244, 48.2066],
            [12.9521, 48.2076],
            [12.9627, 48.2122],
            [12.9975, 48.2355],
            [13.0105, 48.2502],
            [13.0212, 48.2575],
            [13.0536, 48.2648],
            [13.0764, 48.2752],
            [13.0923, 48.2778],
            [13.1249, 48.2771],
            [13.1322, 48.2785],
            [13.1679, 48.2913],
            [13.1888, 48.295],
            [13.2553, 48.2925],
            [13.27, 48.2975],
            [13.3153, 48.316],
            [13.3341, 48.3256],
            [13.3645, 48.3501],
            [13.4106, 48.3743],
            [13.4162, 48.3821],
            [13.4235, 48.4096],
            [13.4352, 48.4243],
            [13.4395, 48.436],
            [13.4261, 48.4565],
            [13.4385, 48.4793],
            [13.4444, 48.4844],
            [13.4477, 48.4954],
            [13.4574, 48.5116],
            [13.4454, 48.5261],
            [13.4507, 48.5358],
            [13.442, 48.5442],
            [13.4388, 48.558],
            [13.4505, 48.5639],
            [13.4572, 48.5579],
            [13.4697, 48.554],
            [13.4748, 48.5619],
            [13.4861, 48.5618],
            [13.5063, 48.5752],
            [13.5011, 48.5816],
            [13.5125, 48.5903],
            [13.5243, 48.5874],
            [13.5518, 48.5691],
            [13.5736, 48.5598],
            [13.5811, 48.5598],
            [13.5946, 48.5689],
            [13.6063, 48.5663],
            [13.6146, 48.5599],
            [13.6299, 48.5534],
            [13.654, 48.548],
            [13.6624, 48.5356],
            [13.6827, 48.5293],
            [13.7056, 48.5186],
            [13.7259, 48.513],
            [13.7329, 48.5159],
            [13.7346, 48.5251],
            [13.7479, 48.532],
            [13.7432, 48.5415],
            [13.7521, 48.5494],
            [13.7452, 48.5533],
            [13.7543, 48.563],
            [13.7603, 48.5633],
            [13.7686, 48.5541],
            [13.7908, 48.5723],
            [13.8003, 48.5742],
            [13.8056, 48.5838],
            [13.7998, 48.5957],
            [13.8058, 48.6047],
            [13.8229, 48.6139],
            [13.8225, 48.6421],
            [13.8154, 48.6542],
            [13.8195, 48.6605],
            [13.8182, 48.6731],
            [13.8115, 48.6832],
            [13.8165, 48.6947],
            [13.8334, 48.6915],
            [13.8368, 48.699],
            [13.821, 48.6978],
            [13.8117, 48.7011],
            [13.7953, 48.7124],
            [13.8032, 48.7228],
            [13.8153, 48.7295],
            [13.8154, 48.7356],
            [13.8245, 48.7567],
            [13.8376, 48.7636],
            [13.8397, 48.7712],
            [13.8575, 48.7709],
            [13.8741, 48.7673],
            [13.8958, 48.7567],
            [13.9198, 48.7405],
            [13.9414, 48.7215],
            [13.9531, 48.7205],
            [13.9564, 48.7134],
            [13.9796, 48.7097],
            [14.0033, 48.7083],
            [14.0254, 48.689],
            [14.0247, 48.6834],
            [14.0391, 48.6759],
            [14.0597, 48.6741],
            [14.0574, 48.6596],
            [14.053, 48.6528],
            [14.0342, 48.6551],
            [14.0249, 48.6529],
            [14.0235, 48.6461],
            [14.0111, 48.6384],
            [14.0234, 48.6306],
            [14.048, 48.6261],
            [14.0418, 48.6129],
            [14.0543, 48.6043],
            [14.0687, 48.6066],
            [14.0725, 48.5999],
            [14.0965, 48.5997],
            [14.0958, 48.5932],
            [14.116, 48.5891],
            [14.1303, 48.5973],
            [14.154, 48.5952],
            [14.1658, 48.5906],
            [14.1835, 48.5933],
            [14.23, 48.5841],
            [14.242, 48.5772],
            [14.2529, 48.5812],
            [14.2718, 48.5811],
            [14.2808, 48.5737],
            [14.31, 48.564],
            [14.3245, 48.5637],
            [14.3279, 48.5534],
            [14.3412, 48.5542],
            [14.3577, 48.5744],
            [14.3694, 48.5696],
            [14.3835, 48.5697],
            [14.3946, 48.5779],
            [14.3876, 48.5848],
            [14.3871, 48.593],
            [14.4085, 48.5914],
            [14.4138, 48.5932],
            [14.4303, 48.5883],
            [14.4419, 48.5998],
            [14.4481, 48.6127],
            [14.4477, 48.6197],
            [14.4555, 48.6246],
            [14.4443, 48.6432],
            [14.4681, 48.6461],
            [14.4803, 48.637],
            [14.4926, 48.6326],
            [14.4949, 48.6224],
            [14.5026, 48.6168],
            [14.5171, 48.6194],
            [14.5273, 48.6157],
            [14.5649, 48.6086],
            [14.5801, 48.6164],
            [14.591, 48.6188],
            [14.605, 48.628],
            [14.6141, 48.6274],
            [14.6241, 48.6063],
            [14.6313, 48.6042],
            [14.6448, 48.61],
            [14.6534, 48.6048],
            [14.663, 48.583],
            [14.6712, 48.5813],
            [14.6918, 48.5851],
          ],
        ],
      },
      properties: {name: 'Upper Austria', id: 'AT-4', CNTRYNAME: 'Austria', TYPE: 'State'},
      id: 'AT-4',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.8603, 47.9972],
            [12.8954, 47.9959],
            [12.908, 48.02],
            [12.9219, 48.022],
            [12.9332, 48.0353],
            [12.945, 48.0375],
            [12.9627, 48.032],
            [12.9707, 48.0237],
            [12.9866, 48.0279],
            [13.0066, 48.0303],
            [13.0176, 48.0365],
            [13.0286, 48.0319],
            [13.0414, 48.0405],
            [13.0516, 48.034],
            [13.0607, 48.0235],
            [13.0574, 48.0187],
            [13.0729, 48.0136],
            [13.076, 48.0044],
            [13.0946, 47.9953],
            [13.1046, 47.9963],
            [13.1229, 47.9929],
            [13.1313, 47.9876],
            [13.1477, 47.9938],
            [13.1407, 47.9799],
            [13.1471, 47.9692],
            [13.1628, 47.9749],
            [13.1969, 47.9736],
            [13.2406, 47.9901],
            [13.2571, 47.991],
            [13.2815, 47.9994],
            [13.2949, 48.0099],
            [13.3007, 48.0029],
            [13.3299, 47.9881],
            [13.3468, 47.9824],
            [13.353, 47.9769],
            [13.3685, 47.9756],
            [13.373, 47.9651],
            [13.3646, 47.9595],
            [13.3567, 47.9413],
            [13.3384, 47.945],
            [13.3404, 47.9511],
            [13.3135, 47.9573],
            [13.3084, 47.9625],
            [13.2913, 47.9609],
            [13.2814, 47.9525],
            [13.2782, 47.9444],
            [13.2842, 47.9367],
            [13.2848, 47.927],
            [13.2768, 47.9199],
            [13.2818, 47.9141],
            [13.2806, 47.8961],
            [13.2701, 47.8899],
            [13.2953, 47.8556],
            [13.2881, 47.8464],
            [13.2862, 47.8296],
            [13.29, 47.8239],
            [13.3091, 47.8208],
            [13.3108, 47.8158],
            [13.3302, 47.8125],
            [13.3474, 47.8128],
            [13.371, 47.801],
            [13.3906, 47.7941],
            [13.4041, 47.7997],
            [13.419, 47.7993],
            [13.442, 47.8026],
            [13.4664, 47.8029],
            [13.4764, 47.7997],
            [13.4896, 47.7897],
            [13.5059, 47.7915],
            [13.5098, 47.7971],
            [13.5275, 47.7979],
            [13.5384, 47.7819],
            [13.5344, 47.7759],
            [13.5171, 47.772],
            [13.4996, 47.7751],
            [13.4643, 47.7858],
            [13.458, 47.7768],
            [13.4465, 47.7718],
            [13.4427, 47.7639],
            [13.446, 47.7586],
            [13.4394, 47.7496],
            [13.438, 47.7368],
            [13.4765, 47.7224],
            [13.4941, 47.7216],
            [13.5064, 47.7175],
            [13.5327, 47.7166],
            [13.5446, 47.7135],
            [13.547, 47.7064],
            [13.5367, 47.6974],
            [13.5332, 47.6882],
            [13.5166, 47.6749],
            [13.5109, 47.676],
            [13.4996, 47.6685],
            [13.5001, 47.6588],
            [13.4838, 47.6408],
            [13.4849, 47.6343],
            [13.494, 47.6293],
            [13.516, 47.6296],
            [13.5225, 47.6173],
            [13.5063, 47.601],
            [13.5054, 47.5815],
            [13.4971, 47.5797],
            [13.4778, 47.5676],
            [13.4841, 47.5508],
            [13.4769, 47.5353],
            [13.4808, 47.5203],
            [13.4879, 47.5134],
            [13.5044, 47.509],
            [13.5189, 47.4952],
            [13.5467, 47.4895],
            [13.568, 47.4814],
            [13.5856, 47.4725],
            [13.5917, 47.4683],
            [13.5915, 47.4594],
            [13.5776, 47.4373],
            [13.5746, 47.4278],
            [13.5637, 47.4204],
            [13.5664, 47.4109],
            [13.5744, 47.4047],
            [13.5714, 47.3911],
            [13.5792, 47.3829],
            [13.582, 47.368],
            [13.5944, 47.3494],
            [13.6041, 47.3275],
            [13.5947, 47.3154],
            [13.6027, 47.3099],
            [13.6038, 47.301],
            [13.5989, 47.2914],
            [13.6071, 47.2831],
            [13.6189, 47.282],
            [13.633, 47.2735],
            [13.6539, 47.2756],
            [13.6656, 47.2715],
            [13.6756, 47.2735],
            [13.6911, 47.2658],
            [13.6955, 47.2587],
            [13.7113, 47.2586],
            [13.7135, 47.2689],
            [13.7227, 47.2769],
            [13.7454, 47.2771],
            [13.7563, 47.2681],
            [13.7653, 47.2678],
            [13.7838, 47.2746],
            [13.7978, 47.2871],
            [13.8113, 47.2959],
            [13.825, 47.2925],
            [13.8222, 47.2718],
            [13.8252, 47.266],
            [13.8443, 47.2605],
            [13.8634, 47.2522],
            [13.8677, 47.2398],
            [13.8516, 47.2291],
            [13.8791, 47.2061],
            [13.9033, 47.1935],
            [13.8956, 47.1846],
            [13.9104, 47.183],
            [13.9206, 47.1718],
            [13.9512, 47.1645],
            [13.9684, 47.1491],
            [13.992, 47.1413],
            [13.9954, 47.1303],
            [13.9865, 47.1249],
            [13.9707, 47.1266],
            [13.9587, 47.1232],
            [13.9447, 47.1353],
            [13.933, 47.1335],
            [13.9098, 47.1229],
            [13.8991, 47.1136],
            [13.8796, 47.1078],
            [13.8771, 47.0959],
            [13.887, 47.0907],
            [13.9074, 47.0654],
            [13.8918, 47.0544],
            [13.8776, 47.0333],
            [13.8656, 47.0214],
            [13.8635, 47.0116],
            [13.8653, 46.9954],
            [13.845, 46.9881],
            [13.8424, 46.9791],
            [13.833, 46.9742],
            [13.8317, 46.9647],
            [13.8182, 46.9578],
            [13.8145, 46.9519],
            [13.8014, 46.9501],
            [13.7897, 46.9435],
            [13.7829, 46.9436],
            [13.7757, 46.9504],
            [13.7731, 46.9747],
            [13.76, 46.9811],
            [13.7543, 46.9909],
            [13.745, 46.9933],
            [13.7315, 46.9886],
            [13.7063, 46.9949],
            [13.6943, 47.0053],
            [13.6875, 47.0278],
            [13.6951, 47.0353],
            [13.681, 47.0407],
            [13.6702, 47.0385],
            [13.6506, 47.0385],
            [13.6433, 47.0419],
            [13.637, 47.0543],
            [13.6127, 47.0585],
            [13.6008, 47.0539],
            [13.5808, 47.0509],
            [13.5592, 47.0551],
            [13.5195, 47.0676],
            [13.5041, 47.0688],
            [13.46, 47.0786],
            [13.4396, 47.0771],
            [13.4271, 47.0802],
            [13.4089, 47.0691],
            [13.3978, 47.0688],
            [13.3814, 47.0796],
            [13.3787, 47.0915],
            [13.3624, 47.097],
            [13.3337, 47.0941],
            [13.3099, 47.085],
            [13.301, 47.0857],
            [13.2909, 47.0953],
            [13.2503, 47.0864],
            [13.2408, 47.0695],
            [13.2467, 47.0651],
            [13.2427, 47.0568],
            [13.2472, 47.0474],
            [13.2327, 47.0447],
            [13.2085, 47.0438],
            [13.1695, 47.04],
            [13.1563, 47.0345],
            [13.1332, 47.034],
            [13.1252, 47.0261],
            [13.0988, 47.0246],
            [13.0859, 47.0265],
            [13.0703, 47.0196],
            [13.0565, 47.0174],
            [13.0202, 47.0296],
            [13.0199, 47.0398],
            [13.0011, 47.0423],
            [12.9849, 47.0348],
            [12.9699, 47.0369],
            [12.9556, 47.043],
            [12.9547, 47.0536],
            [12.9426, 47.0591],
            [12.935, 47.0763],
            [12.9178, 47.0809],
            [12.8925, 47.0804],
            [12.8693, 47.0855],
            [12.8604, 47.0807],
            [12.8361, 47.0826],
            [12.8194, 47.0976],
            [12.8035, 47.0979],
            [12.7885, 47.0932],
            [12.7725, 47.1003],
            [12.7527, 47.0988],
            [12.7357, 47.1028],
            [12.7304, 47.1309],
            [12.7142, 47.1281],
            [12.7044, 47.1223],
            [12.6885, 47.1239],
            [12.665, 47.1191],
            [12.6579, 47.1074],
            [12.6476, 47.1011],
            [12.625, 47.1188],
            [12.5971, 47.1246],
            [12.5869, 47.1305],
            [12.5614, 47.134],
            [12.551, 47.1276],
            [12.5307, 47.1398],
            [12.5167, 47.1505],
            [12.4833, 47.1568],
            [12.4637, 47.1468],
            [12.4479, 47.1441],
            [12.4333, 47.1489],
            [12.422, 47.1424],
            [12.4112, 47.1428],
            [12.3934, 47.1506],
            [12.3824, 47.1434],
            [12.3652, 47.1399],
            [12.3589, 47.1333],
            [12.3538, 47.109],
            [12.325, 47.1069],
            [12.3128, 47.1016],
            [12.308, 47.0923],
            [12.2892, 47.0939],
            [12.2818, 47.0914],
            [12.2609, 47.0718],
            [12.2365, 47.0682],
            [12.2261, 47.0808],
            [12.2103, 47.0862],
            [12.1967, 47.0875],
            [12.1842, 47.0925],
            [12.1519, 47.0807],
            [12.1348, 47.0804],
            [12.1236, 47.0968],
            [12.1257, 47.1089],
            [12.1133, 47.1217],
            [12.113, 47.1369],
            [12.1094, 47.1436],
            [12.0936, 47.1483],
            [12.0913, 47.166],
            [12.0846, 47.1726],
            [12.0872, 47.1809],
            [12.0807, 47.1944],
            [12.0882, 47.2061],
            [12.0853, 47.2145],
            [12.0918, 47.2186],
            [12.0977, 47.2309],
            [12.1086, 47.2436],
            [12.1005, 47.2572],
            [12.0913, 47.2587],
            [12.0754, 47.2679],
            [12.0829, 47.2831],
            [12.0959, 47.2896],
            [12.1127, 47.3057],
            [12.1294, 47.2951],
            [12.1444, 47.2901],
            [12.1684, 47.3034],
            [12.2065, 47.3009],
            [12.2155, 47.3105],
            [12.2251, 47.3116],
            [12.2365, 47.3003],
            [12.253, 47.3071],
            [12.2646, 47.3014],
            [12.279, 47.3156],
            [12.2938, 47.3262],
            [12.313, 47.3289],
            [12.3177, 47.3244],
            [12.3332, 47.327],
            [12.3502, 47.3174],
            [12.357, 47.3174],
            [12.3736, 47.3105],
            [12.3886, 47.3143],
            [12.4093, 47.3126],
            [12.4175, 47.3208],
            [12.4382, 47.3268],
            [12.4695, 47.3231],
            [12.4794, 47.3267],
            [12.4872, 47.337],
            [12.4972, 47.3434],
            [12.4853, 47.3519],
            [12.4866, 47.3586],
            [12.4787, 47.3635],
            [12.4817, 47.3769],
            [12.4959, 47.3797],
            [12.5051, 47.3928],
            [12.5212, 47.39],
            [12.5545, 47.3988],
            [12.5639, 47.3939],
            [12.577, 47.3916],
            [12.5896, 47.3997],
            [12.6024, 47.4128],
            [12.6224, 47.4163],
            [12.6255, 47.4281],
            [12.6375, 47.438],
            [12.6371, 47.4486],
            [12.6304, 47.4585],
            [12.6333, 47.4658],
            [12.6435, 47.4672],
            [12.6645, 47.4644],
            [12.6842, 47.4732],
            [12.6985, 47.4771],
            [12.698, 47.4867],
            [12.6728, 47.4976],
            [12.658, 47.5143],
            [12.6537, 47.5294],
            [12.6306, 47.5465],
            [12.6429, 47.5553],
            [12.6516, 47.5673],
            [12.6658, 47.5761],
            [12.6505, 47.5876],
            [12.6268, 47.5942],
            [12.5748, 47.6017],
            [12.5694, 47.6132],
            [12.5749, 47.632],
            [12.5865, 47.6525],
            [12.6053, 47.6724],
            [12.6192, 47.6716],
            [12.6308, 47.6754],
            [12.6391, 47.6703],
            [12.6494, 47.67],
            [12.6653, 47.6806],
            [12.6779, 47.6824],
            [12.7055, 47.6812],
            [12.718, 47.6768],
            [12.7292, 47.6801],
            [12.7519, 47.6714],
            [12.7575, 47.6643],
            [12.7727, 47.6658],
            [12.7794, 47.6605],
            [12.7596, 47.6509],
            [12.765, 47.6438],
            [12.7808, 47.6328],
            [12.7929, 47.632],
            [12.802, 47.6251],
            [12.8251, 47.6122],
            [12.8079, 47.6106],
            [12.7922, 47.5997],
            [12.796, 47.5921],
            [12.7812, 47.5812],
            [12.7828, 47.5716],
            [12.794, 47.5641],
            [12.795, 47.5582],
            [12.8125, 47.5471],
            [12.8281, 47.5441],
            [12.8439, 47.5469],
            [12.8504, 47.5427],
            [12.8514, 47.5328],
            [12.8586, 47.5269],
            [12.8776, 47.5232],
            [12.8855, 47.5189],
            [12.8873, 47.5121],
            [12.9092, 47.4969],
            [12.937, 47.4868],
            [12.9557, 47.478],
            [12.9723, 47.4737],
            [12.974, 47.4849],
            [12.9947, 47.4783],
            [12.994, 47.4709],
            [13.0031, 47.4633],
            [13.0144, 47.4705],
            [13.0267, 47.4865],
            [13.0467, 47.4915],
            [13.0486, 47.4995],
            [13.0459, 47.526],
            [13.0324, 47.5349],
            [13.0539, 47.5554],
            [13.0516, 47.5651],
            [13.0431, 47.576],
            [13.0461, 47.5839],
            [13.0707, 47.5868],
            [13.0627, 47.6016],
            [13.0735, 47.6131],
            [13.081, 47.6159],
            [13.0967, 47.6295],
            [13.0966, 47.642],
            [13.0772, 47.6696],
            [13.0808, 47.6866],
            [13.0627, 47.6961],
            [13.0455, 47.7127],
            [13.0172, 47.7231],
            [13.0079, 47.7239],
            [13.0015, 47.7135],
            [12.9898, 47.7083],
            [12.9555, 47.7076],
            [12.9438, 47.7124],
            [12.9325, 47.7104],
            [12.9161, 47.7167],
            [12.9082, 47.7231],
            [12.9179, 47.743],
            [12.935, 47.7486],
            [12.9353, 47.755],
            [12.943, 47.7638],
            [12.9391, 47.7709],
            [12.9398, 47.7859],
            [12.9468, 47.7864],
            [12.9632, 47.7987],
            [12.989, 47.8325],
            [12.9999, 47.8389],
            [13.0042, 47.8464],
            [13.0012, 47.8544],
            [12.9884, 47.8663],
            [12.9664, 47.9003],
            [12.9481, 47.9196],
            [12.9321, 47.9389],
            [12.9188, 47.9419],
            [12.9196, 47.9515],
            [12.914, 47.9558],
            [12.8789, 47.9605],
            [12.8701, 47.9662],
            [12.8603, 47.9972],
          ],
        ],
      },
      properties: {name: 'Salzburg', id: 'AT-5', CNTRYNAME: 'Austria', TYPE: 'State'},
      id: 'AT-5',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.7395, 47.7474],
            [14.7427, 47.7387],
            [14.7756, 47.7271],
            [14.7993, 47.7372],
            [14.8095, 47.7366],
            [14.8207, 47.7452],
            [14.8306, 47.7435],
            [14.838, 47.7333],
            [14.8604, 47.7417],
            [14.8734, 47.7304],
            [14.8763, 47.7157],
            [14.9081, 47.7094],
            [14.9285, 47.7104],
            [14.93, 47.7152],
            [14.9427, 47.7229],
            [14.9518, 47.7319],
            [14.9663, 47.7363],
            [14.9731, 47.7432],
            [14.9828, 47.7397],
            [14.9968, 47.7401],
            [15.0134, 47.7442],
            [15.0346, 47.752],
            [15.0602, 47.7386],
            [15.0822, 47.74],
            [15.1278, 47.745],
            [15.1326, 47.7468],
            [15.1372, 47.7587],
            [15.1441, 47.756],
            [15.1594, 47.757],
            [15.1695, 47.7526],
            [15.1931, 47.7544],
            [15.1995, 47.7517],
            [15.2103, 47.7608],
            [15.2066, 47.7764],
            [15.2081, 47.7863],
            [15.2175, 47.7929],
            [15.2366, 47.7975],
            [15.254, 47.7905],
            [15.2619, 47.7924],
            [15.2889, 47.7883],
            [15.2937, 47.7973],
            [15.2941, 47.8113],
            [15.3024, 47.8183],
            [15.3167, 47.8219],
            [15.3271, 47.8196],
            [15.3347, 47.8252],
            [15.3457, 47.8258],
            [15.3625, 47.8215],
            [15.3742, 47.8226],
            [15.3896, 47.8139],
            [15.4054, 47.8129],
            [15.404, 47.807],
            [15.4117, 47.7833],
            [15.4226, 47.7823],
            [15.4386, 47.7906],
            [15.4456, 47.775],
            [15.4609, 47.7669],
            [15.4778, 47.7691],
            [15.5074, 47.7667],
            [15.5191, 47.7602],
            [15.5133, 47.7454],
            [15.5279, 47.7436],
            [15.5534, 47.7482],
            [15.5644, 47.7446],
            [15.583, 47.7462],
            [15.5984, 47.7498],
            [15.6174, 47.7445],
            [15.6197, 47.7361],
            [15.628, 47.7294],
            [15.6373, 47.714],
            [15.6521, 47.7166],
            [15.6739, 47.7072],
            [15.6778, 47.6978],
            [15.6915, 47.7035],
            [15.6969, 47.7113],
            [15.7161, 47.7112],
            [15.72, 47.7084],
            [15.7161, 47.6949],
            [15.7286, 47.6663],
            [15.7258, 47.6478],
            [15.7438, 47.641],
            [15.7493, 47.6308],
            [15.7638, 47.634],
            [15.7808, 47.6416],
            [15.8057, 47.6336],
            [15.8225, 47.6364],
            [15.8326, 47.6229],
            [15.8465, 47.6178],
            [15.857, 47.6209],
            [15.8613, 47.6126],
            [15.8571, 47.6036],
            [15.8492, 47.5975],
            [15.8459, 47.585],
            [15.8483, 47.5674],
            [15.8643, 47.5655],
            [15.8753, 47.5736],
            [15.8897, 47.572],
            [15.9059, 47.5613],
            [15.903, 47.5481],
            [15.9064, 47.5401],
            [15.9176, 47.5319],
            [15.9382, 47.5264],
            [15.9525, 47.5178],
            [15.9754, 47.5203],
            [16.0007, 47.5149],
            [16.0107, 47.5167],
            [16.0263, 47.5124],
            [16.0488, 47.4885],
            [16.0624, 47.4888],
            [16.0661, 47.5021],
            [16.0807, 47.5038],
            [16.094, 47.5103],
            [16.0982, 47.5231],
            [16.111, 47.5206],
            [16.1118, 47.5103],
            [16.1188, 47.5063],
            [16.114, 47.4989],
            [16.1193, 47.4861],
            [16.1222, 47.4698],
            [16.1353, 47.4615],
            [16.1318, 47.4542],
            [16.1399, 47.4467],
            [16.1454, 47.435],
            [16.1695, 47.4222],
            [16.1237, 47.4117],
            [16.1094, 47.4132],
            [16.0984, 47.3908],
            [16.0934, 47.3883],
            [16.0837, 47.4067],
            [16.0709, 47.3989],
            [16.0621, 47.3828],
            [16.0311, 47.3781],
            [16.0334, 47.3714],
            [16.0268, 47.3588],
            [16.0338, 47.3544],
            [16.0338, 47.3442],
            [16.0477, 47.3306],
            [16.0478, 47.3225],
            [16.0605, 47.3088],
            [16.0638, 47.2874],
            [16.0761, 47.2746],
            [16.0808, 47.261],
            [16.0809, 47.2499],
            [16.0873, 47.2462],
            [16.0837, 47.2395],
            [16.0879, 47.2328],
            [16.0814, 47.2252],
            [16.0882, 47.2126],
            [16.0891, 47.2046],
            [16.0989, 47.1923],
            [16.0999, 47.1768],
            [16.1085, 47.1687],
            [16.1162, 47.1414],
            [16.1096, 47.1179],
            [16.0921, 47.1059],
            [16.0873, 47.0878],
            [16.0938, 47.0642],
            [16.1046, 47.0598],
            [16.1184, 47.0415],
            [16.1137, 47.0314],
            [16.1258, 47.0236],
            [16.1291, 47.011],
            [16.1469, 47.0007],
            [16.1533, 46.9929],
            [16.1307, 46.992],
            [16.1213, 46.9877],
            [16.112, 46.9784],
            [16.11, 46.967],
            [16.0957, 46.954],
            [16.0974, 46.9424],
            [16.0936, 46.9357],
            [16.0716, 46.9331],
            [16.0616, 46.9206],
            [16.0633, 46.9137],
            [16.0561, 46.9089],
            [16.0459, 46.9103],
            [16.0342, 46.9007],
            [16.0293, 46.8892],
            [16.013, 46.8808],
            [16.0079, 46.8712],
            [16.01, 46.8616],
            [16.0024, 46.8506],
            [16.0053, 46.8456],
            [15.9982, 46.8341],
            [15.9862, 46.8261],
            [15.9921, 46.8147],
            [15.9987, 46.8112],
            [15.9923, 46.8004],
            [15.9958, 46.7878],
            [15.9886, 46.774],
            [15.9905, 46.7584],
            [15.9865, 46.7405],
            [15.9917, 46.7307],
            [16.0037, 46.7236],
            [16.0032, 46.7176],
            [16.0172, 46.7167],
            [16.0312, 46.711],
            [16.0289, 46.6992],
            [16.0405, 46.6859],
            [16.0399, 46.6596],
            [16.0317, 46.6567],
            [16.0102, 46.6647],
            [16.0029, 46.6757],
            [15.9928, 46.6818],
            [15.9783, 46.6858],
            [15.9526, 46.6885],
            [15.9438, 46.6911],
            [15.9174, 46.7071],
            [15.8715, 46.7214],
            [15.8414, 46.7221],
            [15.8222, 46.7196],
            [15.7995, 46.714],
            [15.7693, 46.7002],
            [15.7581, 46.6986],
            [15.7374, 46.7],
            [15.7197, 46.6961],
            [15.6988, 46.6979],
            [15.6769, 46.7066],
            [15.6539, 46.7053],
            [15.6526, 46.6919],
            [15.6355, 46.6899],
            [15.6256, 46.6797],
            [15.6095, 46.6833],
            [15.6103, 46.6755],
            [15.5989, 46.6743],
            [15.5894, 46.6833],
            [15.5711, 46.6785],
            [15.5579, 46.6705],
            [15.5501, 46.6716],
            [15.5486, 46.6597],
            [15.5532, 46.6478],
            [15.5441, 46.6439],
            [15.5484, 46.6368],
            [15.5266, 46.6272],
            [15.5113, 46.6161],
            [15.5005, 46.6148],
            [15.4906, 46.6203],
            [15.4718, 46.612],
            [15.4643, 46.6148],
            [15.4629, 46.6243],
            [15.4554, 46.6294],
            [15.4559, 46.6388],
            [15.4404, 46.6396],
            [15.4144, 46.6548],
            [15.371, 46.6498],
            [15.3493, 46.6538],
            [15.3431, 46.6503],
            [15.3266, 46.6527],
            [15.3193, 46.6472],
            [15.3072, 46.6522],
            [15.2927, 46.6517],
            [15.2811, 46.6445],
            [15.2641, 46.6445],
            [15.2342, 46.6389],
            [15.1926, 46.6474],
            [15.1826, 46.6554],
            [15.1666, 46.6543],
            [15.1631, 46.6572],
            [15.1419, 46.6594],
            [15.132, 46.6562],
            [15.1299, 46.6503],
            [15.1153, 46.6574],
            [15.1056, 46.659],
            [15.0807, 46.6457],
            [15.0655, 46.6513],
            [15.0618, 46.6602],
            [15.0501, 46.6687],
            [15.0352, 46.6665],
            [15.0216, 46.6725],
            [15.0299, 46.6794],
            [15.0351, 46.7054],
            [15.044, 46.7135],
            [15.0419, 46.7232],
            [15.0353, 46.7281],
            [15.0407, 46.7363],
            [15.0328, 46.7434],
            [15.0338, 46.7494],
            [15.025, 46.762],
            [15.0244, 46.7724],
            [15.0071, 46.7751],
            [14.9928, 46.7835],
            [14.9748, 46.786],
            [14.9778, 46.8026],
            [14.986, 46.8102],
            [14.9881, 46.823],
            [15.0132, 46.8336],
            [15.0175, 46.8444],
            [15.007, 46.8562],
            [15.0066, 46.8795],
            [15.0164, 46.8852],
            [15.0263, 46.9095],
            [15.0162, 46.921],
            [14.9918, 46.9371],
            [14.9781, 46.9642],
            [14.9668, 46.9725],
            [14.9699, 46.9789],
            [14.9509, 46.9927],
            [14.9407, 46.9925],
            [14.9051, 46.9989],
            [14.8858, 47.0107],
            [14.8738, 47.0223],
            [14.85, 47.0296],
            [14.8392, 47.0418],
            [14.8429, 47.0488],
            [14.8304, 47.0491],
            [14.8086, 47.0387],
            [14.7983, 47.0399],
            [14.7837, 47.0368],
            [14.7685, 47.0378],
            [14.7409, 47.0288],
            [14.7361, 47.0318],
            [14.7214, 47.0247],
            [14.7054, 47.0246],
            [14.6871, 47.0184],
            [14.659, 47.0177],
            [14.6494, 47.0143],
            [14.6268, 47.0141],
            [14.6139, 46.9963],
            [14.5941, 47.0005],
            [14.5858, 47.0117],
            [14.554, 47.0117],
            [14.5142, 47.0093],
            [14.5035, 47.0185],
            [14.5024, 47.0256],
            [14.4829, 47.0207],
            [14.475, 47.0115],
            [14.4591, 47.0054],
            [14.456, 46.9937],
            [14.4403, 46.9956],
            [14.4252, 46.9945],
            [14.4, 46.9789],
            [14.3827, 46.9804],
            [14.3763, 46.9963],
            [14.3766, 47.0072],
            [14.3818, 47.0141],
            [14.3634, 47.024],
            [14.3383, 47.023],
            [14.328, 47.0281],
            [14.3021, 47.0278],
            [14.264, 47.0247],
            [14.2508, 47.0348],
            [14.2335, 47.0261],
            [14.2215, 47.0385],
            [14.2186, 47.0524],
            [14.2032, 47.0693],
            [14.1933, 47.0701],
            [14.1747, 47.0602],
            [14.163, 47.0516],
            [14.131, 47.0609],
            [14.124, 47.0562],
            [14.1261, 47.0448],
            [14.1101, 47.0403],
            [14.0789, 47.0217],
            [14.0703, 47.0101],
            [14.0534, 47.0041],
            [14.0254, 47.0072],
            [14.0151, 47.0039],
            [14.0165, 46.9851],
            [14.0037, 46.9769],
            [14.005, 46.9706],
            [13.9777, 46.9654],
            [13.9639, 46.9603],
            [13.9512, 46.9518],
            [13.9505, 46.9416],
            [13.9328, 46.9443],
            [13.9164, 46.9381],
            [13.928, 46.934],
            [13.9362, 46.9245],
            [13.909, 46.9251],
            [13.905, 46.9175],
            [13.8936, 46.9212],
            [13.8652, 46.9199],
            [13.8489, 46.9095],
            [13.8162, 46.9176],
            [13.8111, 46.9245],
            [13.7842, 46.9374],
            [13.7829, 46.9436],
            [13.7897, 46.9435],
            [13.8014, 46.9501],
            [13.8145, 46.9519],
            [13.8182, 46.9578],
            [13.8317, 46.9647],
            [13.833, 46.9742],
            [13.8424, 46.9791],
            [13.845, 46.9881],
            [13.8653, 46.9954],
            [13.8635, 47.0116],
            [13.8656, 47.0214],
            [13.8776, 47.0333],
            [13.8918, 47.0544],
            [13.9074, 47.0654],
            [13.887, 47.0907],
            [13.8771, 47.0959],
            [13.8796, 47.1078],
            [13.8991, 47.1136],
            [13.9098, 47.1229],
            [13.933, 47.1335],
            [13.9447, 47.1353],
            [13.9587, 47.1232],
            [13.9707, 47.1266],
            [13.9865, 47.1249],
            [13.9954, 47.1303],
            [13.992, 47.1413],
            [13.9684, 47.1491],
            [13.9512, 47.1645],
            [13.9206, 47.1718],
            [13.9104, 47.183],
            [13.8956, 47.1846],
            [13.9033, 47.1935],
            [13.8791, 47.2061],
            [13.8516, 47.2291],
            [13.8677, 47.2398],
            [13.8634, 47.2522],
            [13.8443, 47.2605],
            [13.8252, 47.266],
            [13.8222, 47.2718],
            [13.825, 47.2925],
            [13.8113, 47.2959],
            [13.7978, 47.2871],
            [13.7838, 47.2746],
            [13.7653, 47.2678],
            [13.7563, 47.2681],
            [13.7454, 47.2771],
            [13.7227, 47.2769],
            [13.7135, 47.2689],
            [13.7113, 47.2586],
            [13.6955, 47.2587],
            [13.6911, 47.2658],
            [13.6756, 47.2735],
            [13.6656, 47.2715],
            [13.6539, 47.2756],
            [13.633, 47.2735],
            [13.6189, 47.282],
            [13.6071, 47.2831],
            [13.5989, 47.2914],
            [13.6038, 47.301],
            [13.6027, 47.3099],
            [13.5947, 47.3154],
            [13.6041, 47.3275],
            [13.5944, 47.3494],
            [13.582, 47.368],
            [13.5792, 47.3829],
            [13.5714, 47.3911],
            [13.5744, 47.4047],
            [13.5664, 47.4109],
            [13.5637, 47.4204],
            [13.5746, 47.4278],
            [13.5776, 47.4373],
            [13.5915, 47.4594],
            [13.5917, 47.4683],
            [13.5856, 47.4725],
            [13.6085, 47.4736],
            [13.6269, 47.4662],
            [13.6408, 47.4662],
            [13.6538, 47.4617],
            [13.6857, 47.462],
            [13.7148, 47.4604],
            [13.7231, 47.4615],
            [13.7172, 47.4721],
            [13.7286, 47.4855],
            [13.7415, 47.4925],
            [13.76, 47.5193],
            [13.7519, 47.5245],
            [13.7456, 47.538],
            [13.7558, 47.5454],
            [13.7553, 47.5522],
            [13.7353, 47.5763],
            [13.711, 47.5744],
            [13.7098, 47.5798],
            [13.6933, 47.5831],
            [13.6924, 47.5894],
            [13.6984, 47.6041],
            [13.6948, 47.6169],
            [13.695, 47.6281],
            [13.7142, 47.668],
            [13.7363, 47.6737],
            [13.7351, 47.6843],
            [13.7484, 47.6874],
            [13.7552, 47.6999],
            [13.7611, 47.7015],
            [13.7784, 47.7165],
            [13.7912, 47.7126],
            [13.7995, 47.7149],
            [13.839, 47.7185],
            [13.8507, 47.7141],
            [13.8624, 47.7265],
            [13.8775, 47.7255],
            [13.894, 47.7184],
            [13.9041, 47.717],
            [13.9073, 47.7098],
            [13.9187, 47.705],
            [13.9325, 47.7081],
            [13.9516, 47.7092],
            [13.9827, 47.6967],
            [14.0092, 47.7008],
            [14.019, 47.6906],
            [14.0367, 47.6784],
            [14.0485, 47.6738],
            [14.0551, 47.6676],
            [14.0463, 47.6263],
            [14.0509, 47.6168],
            [14.0657, 47.6153],
            [14.0915, 47.6186],
            [14.1098, 47.626],
            [14.133, 47.6288],
            [14.1478, 47.6362],
            [14.1591, 47.6351],
            [14.1672, 47.6385],
            [14.1827, 47.6381],
            [14.2059, 47.6508],
            [14.2339, 47.6498],
            [14.2243, 47.6416],
            [14.2347, 47.6275],
            [14.2652, 47.6094],
            [14.2836, 47.613],
            [14.301, 47.6085],
            [14.3272, 47.6067],
            [14.337, 47.6183],
            [14.3677, 47.6287],
            [14.396, 47.636],
            [14.3944, 47.6484],
            [14.4011, 47.6528],
            [14.4196, 47.6552],
            [14.4363, 47.6517],
            [14.4485, 47.6561],
            [14.4507, 47.663],
            [14.4675, 47.6684],
            [14.475, 47.6742],
            [14.4904, 47.6798],
            [14.5026, 47.6764],
            [14.5137, 47.6773],
            [14.5256, 47.6845],
            [14.5299, 47.6935],
            [14.5223, 47.7032],
            [14.5548, 47.717],
            [14.5716, 47.714],
            [14.5882, 47.7163],
            [14.6138, 47.7308],
            [14.6319, 47.7288],
            [14.647, 47.7296],
            [14.6516, 47.7335],
            [14.6751, 47.7403],
            [14.6823, 47.7472],
            [14.6878, 47.7584],
            [14.6943, 47.7637],
            [14.7285, 47.7544],
            [14.7395, 47.7474],
          ],
        ],
      },
      properties: {name: 'Styria', id: 'AT-6', CNTRYNAME: 'Austria', TYPE: 'State'},
      id: 'AT-6',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [12.2365, 47.0682],
              [12.2609, 47.0718],
              [12.2818, 47.0914],
              [12.2892, 47.0939],
              [12.308, 47.0923],
              [12.3128, 47.1016],
              [12.325, 47.1069],
              [12.3538, 47.109],
              [12.3589, 47.1333],
              [12.3652, 47.1399],
              [12.3824, 47.1434],
              [12.3934, 47.1506],
              [12.4112, 47.1428],
              [12.422, 47.1424],
              [12.4333, 47.1489],
              [12.4479, 47.1441],
              [12.4637, 47.1468],
              [12.4833, 47.1568],
              [12.5167, 47.1505],
              [12.5307, 47.1398],
              [12.551, 47.1276],
              [12.5614, 47.134],
              [12.5869, 47.1305],
              [12.5971, 47.1246],
              [12.625, 47.1188],
              [12.6476, 47.1011],
              [12.6558, 47.0992],
              [12.6788, 47.0844],
              [12.6986, 47.0744],
              [12.7059, 47.0574],
              [12.7154, 47.0443],
              [12.7418, 47.0408],
              [12.7545, 47.0445],
              [12.7663, 47.0432],
              [12.7656, 47.0374],
              [12.7549, 47.033],
              [12.7531, 47.0257],
              [12.7407, 47.0147],
              [12.7292, 46.9965],
              [12.723, 46.9952],
              [12.7367, 46.9808],
              [12.7453, 46.9604],
              [12.7574, 46.9588],
              [12.7582, 46.9528],
              [12.7678, 46.944],
              [12.7847, 46.9393],
              [12.7821, 46.9342],
              [12.7983, 46.9223],
              [12.8108, 46.922],
              [12.8211, 46.917],
              [12.8359, 46.914],
              [12.8306, 46.9004],
              [12.8412, 46.8914],
              [12.8452, 46.8588],
              [12.8664, 46.8505],
              [12.8741, 46.8452],
              [12.8866, 46.8421],
              [12.8973, 46.8237],
              [12.9127, 46.8209],
              [12.9446, 46.8034],
              [12.9649, 46.7958],
              [12.9658, 46.7869],
              [12.9536, 46.7814],
              [12.9456, 46.769],
              [12.9299, 46.7627],
              [12.9201, 46.7642],
              [12.9039, 46.7742],
              [12.8982, 46.7746],
              [12.8792, 46.7631],
              [12.8555, 46.7592],
              [12.842, 46.7492],
              [12.8305, 46.7475],
              [12.8228, 46.7517],
              [12.8133, 46.7506],
              [12.8049, 46.7576],
              [12.773, 46.7516],
              [12.7561, 46.7512],
              [12.7408, 46.7441],
              [12.726, 46.7424],
              [12.7118, 46.7362],
              [12.7144, 46.7293],
              [12.7067, 46.713],
              [12.7204, 46.7007],
              [12.6975, 46.7036],
              [12.6859, 46.6862],
              [12.6899, 46.6568],
              [12.6734, 46.659],
              [12.6587, 46.6582],
              [12.6491, 46.6528],
              [12.634, 46.6553],
              [12.6302, 46.6612],
              [12.6177, 46.6613],
              [12.6047, 46.6569],
              [12.5752, 46.6569],
              [12.5695, 46.6519],
              [12.5596, 46.6533],
              [12.5464, 46.6596],
              [12.5191, 46.6779],
              [12.5046, 46.6792],
              [12.4825, 46.6766],
              [12.4741, 46.6861],
              [12.4499, 46.6895],
              [12.4417, 46.6887],
              [12.4269, 46.6994],
              [12.4148, 46.6998],
              [12.3798, 46.7189],
              [12.3712, 46.7411],
              [12.3544, 46.775],
              [12.3128, 46.7838],
              [12.3025, 46.7821],
              [12.2832, 46.784],
              [12.2822, 46.7913],
              [12.2905, 46.8002],
              [12.2843, 46.8151],
              [12.3027, 46.8305],
              [12.3065, 46.8398],
              [12.2977, 46.8429],
              [12.2902, 46.8534],
              [12.29, 46.8652],
              [12.2768, 46.8718],
              [12.2759, 46.8826],
              [12.2605, 46.8866],
              [12.2386, 46.8872],
              [12.2292, 46.879],
              [12.2145, 46.8741],
              [12.1971, 46.8907],
              [12.1962, 46.8984],
              [12.1864, 46.9051],
              [12.1596, 46.9074],
              [12.1422, 46.9127],
              [12.154, 46.9179],
              [12.167, 46.9338],
              [12.166, 46.939],
              [12.1526, 46.9499],
              [12.1362, 46.9572],
              [12.1354, 46.9836],
              [12.12, 47.0081],
              [12.1455, 47.0245],
              [12.1608, 47.0201],
              [12.1696, 47.0232],
              [12.2029, 47.0283],
              [12.2142, 47.0472],
              [12.2139, 47.0576],
              [12.2365, 47.0682],
            ],
          ],
          [
            [
              [12.5749, 47.632],
              [12.5694, 47.6132],
              [12.5748, 47.6017],
              [12.6268, 47.5942],
              [12.6505, 47.5876],
              [12.6658, 47.5761],
              [12.6516, 47.5673],
              [12.6429, 47.5553],
              [12.6306, 47.5465],
              [12.6537, 47.5294],
              [12.658, 47.5143],
              [12.6728, 47.4976],
              [12.698, 47.4867],
              [12.6985, 47.4771],
              [12.6842, 47.4732],
              [12.6645, 47.4644],
              [12.6435, 47.4672],
              [12.6333, 47.4658],
              [12.6304, 47.4585],
              [12.6371, 47.4486],
              [12.6375, 47.438],
              [12.6255, 47.4281],
              [12.6224, 47.4163],
              [12.6024, 47.4128],
              [12.5896, 47.3997],
              [12.577, 47.3916],
              [12.5639, 47.3939],
              [12.5545, 47.3988],
              [12.5212, 47.39],
              [12.5051, 47.3928],
              [12.4959, 47.3797],
              [12.4817, 47.3769],
              [12.4787, 47.3635],
              [12.4866, 47.3586],
              [12.4853, 47.3519],
              [12.4972, 47.3434],
              [12.4872, 47.337],
              [12.4794, 47.3267],
              [12.4695, 47.3231],
              [12.4382, 47.3268],
              [12.4175, 47.3208],
              [12.4093, 47.3126],
              [12.3886, 47.3143],
              [12.3736, 47.3105],
              [12.357, 47.3174],
              [12.3502, 47.3174],
              [12.3332, 47.327],
              [12.3177, 47.3244],
              [12.313, 47.3289],
              [12.2938, 47.3262],
              [12.279, 47.3156],
              [12.2646, 47.3014],
              [12.253, 47.3071],
              [12.2365, 47.3003],
              [12.2251, 47.3116],
              [12.2155, 47.3105],
              [12.2065, 47.3009],
              [12.1684, 47.3034],
              [12.1444, 47.2901],
              [12.1294, 47.2951],
              [12.1127, 47.3057],
              [12.0959, 47.2896],
              [12.0829, 47.2831],
              [12.0754, 47.2679],
              [12.0913, 47.2587],
              [12.1005, 47.2572],
              [12.1086, 47.2436],
              [12.0977, 47.2309],
              [12.0918, 47.2186],
              [12.0853, 47.2145],
              [12.0882, 47.2061],
              [12.0807, 47.1944],
              [12.0872, 47.1809],
              [12.0846, 47.1726],
              [12.0913, 47.166],
              [12.0936, 47.1483],
              [12.1094, 47.1436],
              [12.113, 47.1369],
              [12.1133, 47.1217],
              [12.1257, 47.1089],
              [12.1236, 47.0968],
              [12.1348, 47.0804],
              [12.112, 47.0737],
              [12.0944, 47.0785],
              [12.0776, 47.0681],
              [12.0725, 47.0594],
              [12.0558, 47.0591],
              [12.0368, 47.062],
              [12.026, 47.0506],
              [12.019, 47.0476],
              [11.9928, 47.05],
              [11.9743, 47.0499],
              [11.9654, 47.0408],
              [11.9518, 47.0419],
              [11.9459, 47.0345],
              [11.9341, 47.0375],
              [11.9151, 47.0336],
              [11.8888, 47.0166],
              [11.8638, 47.0093],
              [11.8515, 47.001],
              [11.8403, 46.9986],
              [11.836, 46.9921],
              [11.8151, 46.9891],
              [11.7987, 46.9904],
              [11.7748, 46.9863],
              [11.7642, 46.9729],
              [11.747, 46.9683],
              [11.7268, 46.9722],
              [11.7098, 46.9911],
              [11.6857, 46.9929],
              [11.6631, 46.992],
              [11.6345, 47.0014],
              [11.6243, 47.0105],
              [11.6091, 47.0112],
              [11.5995, 47.0052],
              [11.5842, 47.004],
              [11.5777, 46.9969],
              [11.5634, 46.9905],
              [11.5499, 46.9883],
              [11.5366, 46.9824],
              [11.528, 46.9907],
              [11.4983, 47.0082],
              [11.4795, 47.0107],
              [11.4666, 46.9943],
              [11.4529, 46.9904],
              [11.4404, 46.9743],
              [11.417, 46.9656],
              [11.4004, 46.9639],
              [11.3854, 46.9683],
              [11.3753, 46.9781],
              [11.3574, 46.9898],
              [11.3431, 46.9889],
              [11.3381, 46.9839],
              [11.3199, 46.9913],
              [11.3081, 46.9831],
              [11.295, 46.9848],
              [11.2712, 46.9787],
              [11.24, 46.9683],
              [11.22, 46.9678],
              [11.2073, 46.9615],
              [11.2033, 46.9668],
              [11.1872, 46.969],
              [11.1752, 46.9615],
              [11.1638, 46.9638],
              [11.1619, 46.9493],
              [11.1675, 46.9431],
              [11.1479, 46.9297],
              [11.1393, 46.9269],
              [11.1168, 46.93],
              [11.1092, 46.9263],
              [11.1099, 46.9153],
              [11.0961, 46.9119],
              [11.0989, 46.8879],
              [11.0814, 46.8677],
              [11.0711, 46.8501],
              [11.0748, 46.8356],
              [11.083, 46.8227],
              [11.064, 46.8139],
              [11.0423, 46.8004],
              [11.0219, 46.7673],
              [11.0128, 46.7704],
              [10.9938, 46.7656],
              [10.9747, 46.7704],
              [10.9527, 46.7734],
              [10.9324, 46.7717],
              [10.9178, 46.7733],
              [10.9041, 46.7666],
              [10.8942, 46.7682],
              [10.8854, 46.7616],
              [10.8684, 46.7648],
              [10.8621, 46.7722],
              [10.8523, 46.7728],
              [10.8417, 46.7793],
              [10.8327, 46.7804],
              [10.8272, 46.773],
              [10.8119, 46.7757],
              [10.7981, 46.788],
              [10.7867, 46.7948],
              [10.7699, 46.7871],
              [10.7549, 46.7838],
              [10.7279, 46.7878],
              [10.7265, 46.7952],
              [10.743, 46.8023],
              [10.7509, 46.8131],
              [10.7618, 46.8197],
              [10.7541, 46.8331],
              [10.7349, 46.8335],
              [10.7205, 46.8377],
              [10.715, 46.8459],
              [10.7044, 46.846],
              [10.6922, 46.8504],
              [10.6965, 46.8591],
              [10.689, 46.8658],
              [10.6721, 46.8686],
              [10.6659, 46.8742],
              [10.6514, 46.8647],
              [10.6244, 46.8631],
              [10.6117, 46.8577],
              [10.596, 46.8559],
              [10.5898, 46.8499],
              [10.5733, 46.8452],
              [10.5689, 46.8407],
              [10.5523, 46.8381],
              [10.5493, 46.8481],
              [10.5216, 46.8442],
              [10.4996, 46.8454],
              [10.4686, 46.8627],
              [10.4718, 46.8746],
              [10.466, 46.8855],
              [10.4765, 46.8929],
              [10.4786, 46.9099],
              [10.4871, 46.9233],
              [10.4889, 46.9398],
              [10.4799, 46.9414],
              [10.47, 46.9482],
              [10.4573, 46.9524],
              [10.4302, 46.9575],
              [10.4239, 46.9614],
              [10.4277, 46.9768],
              [10.4137, 46.9838],
              [10.3993, 46.9977],
              [10.3851, 47.0002],
              [10.3726, 46.9916],
              [10.3557, 46.9931],
              [10.348, 46.9905],
              [10.3301, 46.9638],
              [10.3281, 46.9544],
              [10.3107, 46.9511],
              [10.3071, 46.9403],
              [10.318, 46.9315],
              [10.3178, 46.9264],
              [10.3033, 46.9253],
              [10.2981, 46.9203],
              [10.266, 46.9293],
              [10.2434, 46.9319],
              [10.2431, 46.9196],
              [10.2382, 46.9159],
              [10.2345, 46.9026],
              [10.227, 46.8969],
              [10.2356, 46.8859],
              [10.2317, 46.8692],
              [10.2052, 46.8644],
              [10.1953, 46.8665],
              [10.1703, 46.8504],
              [10.1461, 46.8508],
              [10.1398, 46.8639],
              [10.1402, 46.874],
              [10.1193, 46.8816],
              [10.1078, 46.8949],
              [10.1081, 46.9067],
              [10.0962, 46.925],
              [10.105, 46.9279],
              [10.1119, 46.9352],
              [10.1228, 46.9392],
              [10.1322, 46.9515],
              [10.1343, 46.9659],
              [10.1444, 46.9825],
              [10.159, 46.9823],
              [10.1539, 46.9913],
              [10.1586, 46.9975],
              [10.1528, 47.0045],
              [10.1394, 47.009],
              [10.1394, 47.0155],
              [10.1284, 47.0256],
              [10.1539, 47.0416],
              [10.1552, 47.0486],
              [10.1506, 47.0601],
              [10.1334, 47.0628],
              [10.1325, 47.0811],
              [10.1425, 47.0952],
              [10.1613, 47.113],
              [10.1703, 47.1184],
              [10.1857, 47.118],
              [10.182, 47.1264],
              [10.1952, 47.141],
              [10.2109, 47.148],
              [10.2119, 47.1579],
              [10.1981, 47.1835],
              [10.1957, 47.193],
              [10.1984, 47.2006],
              [10.209, 47.2089],
              [10.2075, 47.2297],
              [10.1971, 47.2339],
              [10.2008, 47.2394],
              [10.2203, 47.2524],
              [10.194, 47.2569],
              [10.1799, 47.2706],
              [10.2014, 47.2768],
              [10.2233, 47.2754],
              [10.2386, 47.2718],
              [10.2464, 47.2808],
              [10.2588, 47.2815],
              [10.266, 47.2891],
              [10.2802, 47.2886],
              [10.3149, 47.3065],
              [10.3307, 47.3054],
              [10.3568, 47.3332],
              [10.3766, 47.3521],
              [10.3872, 47.3574],
              [10.3907, 47.3714],
              [10.397, 47.3762],
              [10.4141, 47.3785],
              [10.4301, 47.3911],
              [10.4386, 47.4129],
              [10.4559, 47.419],
              [10.4596, 47.4276],
              [10.4749, 47.4309],
              [10.4731, 47.4433],
              [10.4667, 47.4528],
              [10.4657, 47.464],
              [10.4692, 47.4765],
              [10.4637, 47.4827],
              [10.4438, 47.4845],
              [10.4318, 47.503],
              [10.4426, 47.5179],
              [10.4392, 47.5227],
              [10.4531, 47.5538],
              [10.4447, 47.5676],
              [10.4374, 47.5683],
              [10.4308, 47.5768],
              [10.437, 47.5836],
              [10.4533, 47.5827],
              [10.4726, 47.5867],
              [10.4725, 47.5703],
              [10.4559, 47.5567],
              [10.4724, 47.5508],
              [10.4893, 47.5396],
              [10.5239, 47.5347],
              [10.5559, 47.5372],
              [10.574, 47.5346],
              [10.5813, 47.548],
              [10.5782, 47.5543],
              [10.5878, 47.5585],
              [10.5987, 47.5684],
              [10.6162, 47.5669],
              [10.6346, 47.5588],
              [10.6758, 47.5585],
              [10.6902, 47.5542],
              [10.6949, 47.5443],
              [10.7105, 47.5427],
              [10.7191, 47.5378],
              [10.7393, 47.5386],
              [10.7585, 47.5339],
              [10.7647, 47.5187],
              [10.7774, 47.5139],
              [10.7933, 47.5202],
              [10.8122, 47.5196],
              [10.8118, 47.5255],
              [10.8482, 47.5296],
              [10.8528, 47.5338],
              [10.8865, 47.5367],
              [10.9041, 47.5173],
              [10.9164, 47.5128],
              [10.8885, 47.5076],
              [10.8686, 47.4994],
              [10.868, 47.482],
              [10.8811, 47.4781],
              [10.8971, 47.4825],
              [10.9133, 47.4841],
              [10.9342, 47.4805],
              [10.9279, 47.4708],
              [10.9489, 47.4571],
              [10.9826, 47.43],
              [10.9817, 47.4205],
              [10.9716, 47.4176],
              [10.9682, 47.4109],
              [10.9697, 47.3998],
              [10.9875, 47.3959],
              [11.0082, 47.3942],
              [11.0228, 47.3968],
              [11.046, 47.3941],
              [11.0619, 47.3969],
              [11.0996, 47.3948],
              [11.118, 47.3972],
              [11.1268, 47.4121],
              [11.1343, 47.4126],
              [11.1547, 47.421],
              [11.1771, 47.426],
              [11.1976, 47.4285],
              [11.2024, 47.4331],
              [11.2186, 47.4311],
              [11.2469, 47.4328],
              [11.2523, 47.4279],
              [11.2381, 47.4188],
              [11.2206, 47.398],
              [11.2713, 47.3975],
              [11.2889, 47.4075],
              [11.2927, 47.418],
              [11.2903, 47.4271],
              [11.2997, 47.431],
              [11.3154, 47.4319],
              [11.3283, 47.438],
              [11.3298, 47.4435],
              [11.3402, 47.45],
              [11.3674, 47.4476],
              [11.3811, 47.4489],
              [11.4212, 47.4456],
              [11.4124, 47.4562],
              [11.4073, 47.4678],
              [11.3909, 47.4676],
              [11.3837, 47.4724],
              [11.3876, 47.4784],
              [11.4085, 47.491],
              [11.4127, 47.4977],
              [11.433, 47.5133],
              [11.441, 47.516],
              [11.4515, 47.5078],
              [11.4833, 47.5091],
              [11.4903, 47.5066],
              [11.5119, 47.5062],
              [11.5189, 47.511],
              [11.536, 47.5089],
              [11.5521, 47.5129],
              [11.5703, 47.5138],
              [11.5883, 47.5254],
              [11.586, 47.5432],
              [11.5912, 47.5595],
              [11.6003, 47.566],
              [11.6063, 47.581],
              [11.6225, 47.5836],
              [11.6358, 47.5813],
              [11.6341, 47.5927],
              [11.6402, 47.594],
              [11.6585, 47.5845],
              [11.6827, 47.5843],
              [11.6933, 47.5877],
              [11.7259, 47.5887],
              [11.7454, 47.588],
              [11.7828, 47.5911],
              [11.7916, 47.5877],
              [11.8311, 47.5813],
              [11.8478, 47.5827],
              [11.8525, 47.5973],
              [11.8636, 47.6012],
              [11.9047, 47.6098],
              [11.9138, 47.6137],
              [11.9362, 47.6128],
              [11.9641, 47.6167],
              [11.9738, 47.6153],
              [11.9833, 47.6195],
              [12.0008, 47.6214],
              [12.0087, 47.6249],
              [12.0192, 47.6102],
              [12.0345, 47.6171],
              [12.0503, 47.6148],
              [12.0602, 47.6185],
              [12.0833, 47.609],
              [12.1133, 47.6112],
              [12.1289, 47.6058],
              [12.1439, 47.6061],
              [12.1619, 47.6127],
              [12.177, 47.6137],
              [12.1785, 47.6047],
              [12.2022, 47.6058],
              [12.2068, 47.6141],
              [12.1993, 47.6375],
              [12.1851, 47.657],
              [12.1814, 47.6681],
              [12.1721, 47.6755],
              [12.1654, 47.6854],
              [12.1625, 47.6995],
              [12.1827, 47.6996],
              [12.1959, 47.7075],
              [12.2136, 47.7114],
              [12.2279, 47.7203],
              [12.2474, 47.7407],
              [12.2553, 47.7424],
              [12.2637, 47.7323],
              [12.2462, 47.7104],
              [12.2405, 47.6935],
              [12.2431, 47.6887],
              [12.259, 47.6775],
              [12.2744, 47.6858],
              [12.3353, 47.6962],
              [12.3575, 47.6898],
              [12.3668, 47.6835],
              [12.4007, 47.692],
              [12.4271, 47.6969],
              [12.4419, 47.691],
              [12.4383, 47.6749],
              [12.4517, 47.6665],
              [12.4653, 47.6489],
              [12.4889, 47.6372],
              [12.4979, 47.6246],
              [12.5142, 47.6259],
              [12.5376, 47.6356],
              [12.5749, 47.632],
            ],
          ],
        ],
      },
      properties: {name: 'Tyrol', id: 'AT-7', CNTRYNAME: 'Austria', TYPE: 'State'},
      id: 'AT-7',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1799, 47.2706],
            [10.194, 47.2569],
            [10.2203, 47.2524],
            [10.2008, 47.2394],
            [10.1971, 47.2339],
            [10.2075, 47.2297],
            [10.209, 47.2089],
            [10.1984, 47.2006],
            [10.1957, 47.193],
            [10.1981, 47.1835],
            [10.2119, 47.1579],
            [10.2109, 47.148],
            [10.1952, 47.141],
            [10.182, 47.1264],
            [10.1857, 47.118],
            [10.1703, 47.1184],
            [10.1613, 47.113],
            [10.1425, 47.0952],
            [10.1325, 47.0811],
            [10.1334, 47.0628],
            [10.1506, 47.0601],
            [10.1552, 47.0486],
            [10.1539, 47.0416],
            [10.1284, 47.0256],
            [10.1394, 47.0155],
            [10.1394, 47.009],
            [10.1528, 47.0045],
            [10.1586, 46.9975],
            [10.1539, 46.9913],
            [10.159, 46.9823],
            [10.1444, 46.9825],
            [10.1343, 46.9659],
            [10.1322, 46.9515],
            [10.1228, 46.9392],
            [10.1119, 46.9352],
            [10.105, 46.9279],
            [10.0962, 46.925],
            [10.1081, 46.9067],
            [10.1078, 46.8949],
            [10.1193, 46.8816],
            [10.1402, 46.874],
            [10.1398, 46.8639],
            [10.1461, 46.8508],
            [10.1408, 46.8472],
            [10.1244, 46.8481],
            [10.1196, 46.8437],
            [10.106, 46.8412],
            [10.0941, 46.8506],
            [10.0934, 46.8577],
            [10.0758, 46.8617],
            [10.0612, 46.8605],
            [10.052, 46.8655],
            [10.0529, 46.8757],
            [10.0334, 46.8874],
            [10.0204, 46.899],
            [10.0066, 46.8986],
            [9.989, 46.9047],
            [9.9782, 46.9158],
            [9.9648, 46.9128],
            [9.9592, 46.9158],
            [9.9398, 46.9131],
            [9.9197, 46.9205],
            [9.9131, 46.9257],
            [9.8777, 46.9365],
            [9.8775, 46.9519],
            [9.8801, 46.958],
            [9.8723, 46.9638],
            [9.8772, 46.9746],
            [9.8936, 46.9895],
            [9.8902, 47.0003],
            [9.8728, 47.0101],
            [9.8803, 47.0208],
            [9.8617, 47.0233],
            [9.8567, 47.0177],
            [9.8336, 47.0145],
            [9.8133, 47.022],
            [9.784, 47.0385],
            [9.7494, 47.0374],
            [9.7428, 47.0426],
            [9.7197, 47.0439],
            [9.7011, 47.0528],
            [9.6902, 47.0556],
            [9.6827, 47.0625],
            [9.6592, 47.0586],
            [9.6458, 47.0598],
            [9.6344, 47.0517],
            [9.6204, 47.0537],
            [9.609, 47.0611],
            [9.6108, 47.0774],
            [9.6327, 47.086],
            [9.635, 47.102],
            [9.6248, 47.108],
            [9.624, 47.1151],
            [9.6329, 47.1267],
            [9.6261, 47.1343],
            [9.6236, 47.1484],
            [9.6116, 47.1479],
            [9.6051, 47.1521],
            [9.6007, 47.162],
            [9.589, 47.1696],
            [9.5743, 47.1703],
            [9.5748, 47.1847],
            [9.5814, 47.1956],
            [9.5786, 47.2121],
            [9.5604, 47.225],
            [9.5691, 47.243],
            [9.5381, 47.2649],
            [9.5334, 47.273],
            [9.5478, 47.2808],
            [9.5589, 47.3006],
            [9.5826, 47.3121],
            [9.5909, 47.3222],
            [9.6012, 47.3471],
            [9.6251, 47.3674],
            [9.6516, 47.3688],
            [9.6694, 47.3767],
            [9.6739, 47.3834],
            [9.6724, 47.3939],
            [9.6599, 47.3988],
            [9.6523, 47.4077],
            [9.6517, 47.4207],
            [9.6459, 47.4384],
            [9.6596, 47.449],
            [9.6573, 47.4552],
            [9.621, 47.4614],
            [9.6135, 47.47],
            [9.6043, 47.4637],
            [9.5967, 47.465],
            [9.5817, 47.4854],
            [9.5656, 47.4952],
            [9.5645, 47.5057],
            [9.5023, 47.5603],
            [9.599, 47.5479],
            [9.729, 47.5331],
            [9.7355, 47.5343],
            [9.7366, 47.5465],
            [9.7459, 47.5555],
            [9.7491, 47.5704],
            [9.7556, 47.5782],
            [9.7788, 47.5934],
            [9.8, 47.5952],
            [9.8229, 47.5858],
            [9.8261, 47.579],
            [9.821, 47.5705],
            [9.8249, 47.5621],
            [9.8161, 47.5528],
            [9.8159, 47.5472],
            [9.8459, 47.5419],
            [9.8704, 47.5307],
            [9.8766, 47.5362],
            [9.8751, 47.5437],
            [9.9026, 47.5432],
            [9.9167, 47.5302],
            [9.9339, 47.533],
            [9.9398, 47.5375],
            [9.9504, 47.5346],
            [9.9656, 47.5407],
            [9.9648, 47.5215],
            [9.995, 47.5029],
            [9.9867, 47.4977],
            [9.9956, 47.4865],
            [10.0113, 47.482],
            [10.0374, 47.4887],
            [10.0539, 47.4753],
            [10.0546, 47.4659],
            [10.0646, 47.4631],
            [10.0695, 47.4559],
            [10.0899, 47.4586],
            [10.0949, 47.4507],
            [10.0945, 47.4373],
            [10.105, 47.4283],
            [10.0843, 47.4156],
            [10.0735, 47.4149],
            [10.0708, 47.4079],
            [10.084, 47.3986],
            [10.0811, 47.394],
            [10.0986, 47.3552],
            [10.1182, 47.3747],
            [10.1401, 47.3674],
            [10.1647, 47.3684],
            [10.1694, 47.3719],
            [10.1701, 47.3856],
            [10.1952, 47.3897],
            [10.199, 47.3829],
            [10.2133, 47.3797],
            [10.2275, 47.388],
            [10.2374, 47.3822],
            [10.2353, 47.3711],
            [10.2167, 47.3495],
            [10.205, 47.3383],
            [10.201, 47.3254],
            [10.2116, 47.3193],
            [10.2148, 47.3123],
            [10.2103, 47.3051],
            [10.1895, 47.2949],
            [10.1807, 47.2965],
            [10.1719, 47.28],
            [10.1799, 47.2706],
          ],
        ],
      },
      properties: {name: 'Vorarlberg', id: 'AT-8', CNTRYNAME: 'Austria', TYPE: 'State'},
      id: 'AT-8',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.44, 48.3072],
            [16.4416, 48.3034],
            [16.4433, 48.2912],
            [16.469, 48.2776],
            [16.4796, 48.2752],
            [16.4793, 48.2897],
            [16.4854, 48.2924],
            [16.5112, 48.2865],
            [16.5138, 48.2826],
            [16.508, 48.2726],
            [16.5293, 48.2609],
            [16.5467, 48.2611],
            [16.5415, 48.2422],
            [16.5526, 48.2373],
            [16.5451, 48.2236],
            [16.5472, 48.2185],
            [16.5364, 48.1999],
            [16.5399, 48.1924],
            [16.5385, 48.1797],
            [16.5481, 48.1784],
            [16.5465, 48.1703],
            [16.5524, 48.1614],
            [16.5714, 48.1617],
            [16.5783, 48.1464],
            [16.576, 48.1354],
            [16.5434, 48.1419],
            [16.5315, 48.1507],
            [16.5139, 48.158],
            [16.5005, 48.1555],
            [16.4855, 48.1566],
            [16.4543, 48.1377],
            [16.433, 48.1361],
            [16.4334, 48.1252],
            [16.4057, 48.1198],
            [16.3877, 48.1246],
            [16.3601, 48.1281],
            [16.3283, 48.1364],
            [16.3191, 48.1218],
            [16.3125, 48.1188],
            [16.2987, 48.1273],
            [16.2707, 48.1327],
            [16.2485, 48.1286],
            [16.2357, 48.1354],
            [16.2226, 48.1341],
            [16.2103, 48.1487],
            [16.1986, 48.1544],
            [16.1934, 48.1662],
            [16.1832, 48.1711],
            [16.2008, 48.187],
            [16.2019, 48.2007],
            [16.1969, 48.205],
            [16.1957, 48.2422],
            [16.2073, 48.262],
            [16.2365, 48.2476],
            [16.2423, 48.2379],
            [16.256, 48.2406],
            [16.2698, 48.2514],
            [16.2917, 48.2627],
            [16.2879, 48.269],
            [16.3054, 48.2712],
            [16.3183, 48.2787],
            [16.3474, 48.2889],
            [16.3572, 48.281],
            [16.3773, 48.2894],
            [16.3729, 48.2957],
            [16.3812, 48.3063],
            [16.3754, 48.3107],
            [16.3735, 48.3134],
            [16.3859, 48.3225],
            [16.3936, 48.318],
            [16.419, 48.3212],
            [16.4357, 48.3155],
            [16.44, 48.3072],
          ],
        ],
      },
      properties: {name: 'Vienna', id: 'AT-9', CNTRYNAME: 'Austria', TYPE: 'State'},
      id: 'AT-9',
    },
  ],
}
export default map
