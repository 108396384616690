import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import React, {useEffect, useState} from "react";
import AllegroDataWidget from "./AllegroDataWidget";

const AllegroGraphicSum = () => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [shouldShow, setShouldShow] = useState(false)
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        setCurrency(reportData.details.allegroDaily.campaignInformation.campaigns_currency);
        if(reportData.details.allegroDaily.graphicalSum.clicks != 0 || reportData.details.allegroDaily.graphicalSum.totalAttributionValue != 0 || reportData.details.allegroDaily.graphicalSum.totalCost != 0 || reportData.details.allegroDaily.graphicalSum.views != 0) {
            setShouldShow(true);
        }
    }, [reportData]);

    return shouldShow ? (
        <>
            <AllegroDataWidget
                data={reportData.details.allegroDaily.graphicalSum}
                cardLabel="ALLEGRO_GRAPHIC_SUM_TITLE"
                currency={currency}
            />
        </>
    ) : <></>
}

export default AllegroGraphicSum