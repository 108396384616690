import {useSelector} from 'react-redux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import {selectors} from '../../../redux/ReportsRedux'
import GSCBasicOrganicTraffic from '../SeoWidgetsGroup/GSCBasicOrganicTraffic'
import GSCComapreData from '../SeoWidgetsGroup/GSCCompareData'
import GSCTopLists from '../SeoWidgetsGroup/GSCTopLists'
import GSCTopPhrasesCompareLists from '../SeoWidgetsGroup/GSCTopPhrasesCompareLists'
import React from "react";

interface GoogleSearchConsoleTabProps {}

const GoogleSearchConsoleTab: React.FC<GoogleSearchConsoleTabProps> = () => {
  const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)

  return !reportsError ? (
    <div className={reportsLoading ? 'overlay overlay-block rounded' : ''}>
      {!reportsLoading ? (
        <>
            <div className='basic-data-group card-body bg-white px-5 py-5 mb-5'>
              <GSCBasicOrganicTraffic />
            </div>

            <div className='basic-data-group card-body bg-white px-5 py-5 mb-5'>
              <GSCTopLists />
            </div>

            <div className='basic-data-group card-body bg-white px-5 py-5 mb-5'>
              <GSCComapreData />
              <div className='page-slice'></div>
            </div>

            <div className='basic-data-group card-body bg-white px-5 py-5 mb-5'>
              <GSCTopPhrasesCompareLists />
            </div>
        </>
      ) : (
        <div style={{height: '350px'}}>
          <Spinner />
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default GoogleSearchConsoleTab
