import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {SEOReportDeatils} from '../../../models/SEOReport'
import {selectors} from '../../../redux/ReportsRedux'
import TableWidget from '../../Widgets/TableWidget'
import FacilitiesWidgetItem from './FacilitiesWidgetItem'

interface FacilitiesWidgetProps {}

const FacilitiesWidget: React.FC<FacilitiesWidgetProps> = () => {
  const intl = useIntl()
  const {facilities} = useSelector(selectors.getReportDetails) as SEOReportDeatils

  return (
    <div className='border'>
      <div className='row'>
        <div className='px-5 py-5 pb-0'>
          <h3 className='fs-3 fw-boldest text-sempai-green text-uppercase'>
            {intl.formatMessage({id: 'FACILITIES'})}
          </h3>
        </div>
      </div>
      <div className='card-body bg-white px-0 py-0 pb-0'>
        {facilities && Array.isArray(facilities) && (
          <TableWidget title=''>
            <>
              <thead>
                <tr className='fw-boldest text-dark fs-6'>
                  <th className='min-w-300px'>{intl.formatMessage({id: 'INVOICE.DOMAIN'})}</th>
                  <th className='min-w-100px'>Login</th>
                  <th className='min-w-200px text-end'>{intl.formatMessage({id: 'PASSWORD'})}</th>
                </tr>
              </thead>
              <tbody>
                {facilities.map((item, index) => (
                  <FacilitiesWidgetItem
                    satellite={item}
                    key={index}
                    className={index % 2 !== 0 ? 'bg-light' : ''}
                  />
                ))}
              </tbody>
            </>
          </TableWidget>
        )}
      </div>
    </div>
  )
}

export default FacilitiesWidget
