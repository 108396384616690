import {useIntl} from 'react-intl'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Pagination from '../../../../common/Pagination/Pagination'
import Spinner from '../../../../common/Spinner/Spinner'
import {SEOBrandList, SEOCategoryList, SEOEfficiencyList} from '../../../models/SEOReport'
import ProductsParamListItem from '../SeoWidgets/ProductsParamListItem'

interface ProductParamListsProps {
  title: string
  currentPage: number
  setCurrentPage: (data: React.SetStateAction<number>) => void
  loading: boolean
  error: boolean
  pages: number
  productsArray: SEOEfficiencyList[] | SEOCategoryList[] | SEOBrandList[]
}

const ProductParamLists: React.FC<ProductParamListsProps> = ({
  title,
  currentPage,
  setCurrentPage,
  loading,
  error,
  pages,
  productsArray,
}) => {
  const intl = useIntl()

  return !error ? (
    <>
      <div className={loading ? 'overlay overlay-block rounded' : 'border'}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-boldest text-sempai-green text-uppercase mb-0'>{title}</span>
          </h3>
          <div className='d-flex flex-stack'></div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {!loading ? (
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-50px'>
                      {intl.formatMessage({id: 'REPORT_EFFICIENCY_NAME'})}
                    </th>
                    <th className='min-w-100px text-start'>
                      {intl.formatMessage({id: 'REPORT_EFFICIENCY_ITEM_REVENUE'})}
                    </th>
                    <th className='min-w-50px'>
                      {intl.formatMessage({id: 'REPORT_EFFICIENCY_UNIQUE'})}
                    </th>
                    <th className='min-w-100px'>
                      {intl.formatMessage({id: 'REPORT_EFFICIENCY_QUANTITY'})}
                    </th>
                    <th className='min-w-50px'>
                      {intl.formatMessage({id: 'REPORT_EFFICIENCY_REVENUE_PER_ITEM'})}
                    </th>
                    <th className='min-w-50px text-end'>
                      {intl.formatMessage({id: 'REPORT_EFFICIENCY_ITEMS_PER_PURCHASE'})}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productsArray &&
                    productsArray.map((item, index) => (
                      <ProductsParamListItem key={index} product={item} />
                    ))}
                </tbody>
              </table>
            ) : (
              <div style={{height: '350px'}}>
                <Spinner />
              </div>
            )}
          </div>
          <div className='d-flex flex-stack flex-wrap justify-content-start justify-content-xl-center pt-5'>
            <Pagination {...{currentPage, setCurrentPage}} pages={pages} />
          </div>
        </div>
      </div>
    </>
  ) : (
    <ErrorView />
  )
}

export default ProductParamLists
