import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import {Action} from '@reduxjs/toolkit'

import * as auth from '../../app/modules/auth'
import {reducer as profileReducer} from '../../app/modules/accounts/components/settings/redux/ProfileRedux'
import {reducer as invReducer} from '../../app/modules/invoices/redux/InvoiceRedux'
import {reducer as mediaInvReducer} from '../../app/modules/mediainvoices/redux/MediaInvoicesRedux'
import {reducer as agreeReducer} from '../../app/modules/agreements/redux/AgreementRedux'
import {reducer as reportReducer} from '../../app/modules/reports/redux/ReportsRedux'
import {reducer as reportListReducer} from '../../app/modules/reports/redux/SemReportListRedux'
import {reducer as seoReportListReducer} from '../../app/modules/reports/redux/SeoReportListsRedux'
import {saga as profileSaga} from '../../app/modules/accounts/components/settings/redux/ProfileRedux'
import {saga as invSaga} from '../../app/modules/invoices/redux/InvoiceRedux'
import {saga as agreeSaga} from '../../app/modules/agreements/redux/AgreementRedux'
import {saga as reportSaga} from '../../app/modules/reports/redux/ReportsRedux'
import {saga as reportListSaga} from '../../app/modules/reports/redux/SemReportListRedux'
import {saga as mediaInvSaga} from '../../app/modules/mediainvoices/redux/MediaInvoicesRedux'
import {saga as seoReportListSaga} from '../../app/modules/reports/redux/SeoReportListsRedux'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  profile: profileReducer,
  invoices: invReducer,
  mediaInvoices: mediaInvReducer,
  agreements: agreeReducer,
  reports: reportReducer,
  semReportLists: reportListReducer,
  seoReportList: seoReportListReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IErrorResponse {
  [key: string]: string[]
}

export function* rootSaga() {
  yield all([
    auth.saga(),
    profileSaga(),
    invSaga(),
    agreeSaga(),
    reportSaga(),
    reportListSaga(),
    mediaInvSaga(),
    seoReportListSaga(),
  ])
}
