/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import ResetPassword from "../../pages/password/ResetPassword";

const AuthPage: React.FC = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/sempai-images/ekran-logowania-bg.png')})`,
        backgroundSize: 'cover'
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href='https://sempai.pl/' className='mb-12' target="_blank" rel='noreferrer'>
          <img alt='Logo' src={toAbsoluteUrl('/media/sempai-images/icons/sempai-logo-white.svg')} className='h-xl-45px h-30px' />
        </a>
        <div className='w-lg-400px ps-10 pe-10 pe-lg-12 ps-lg-12 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path="/password/reset" component={ResetPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export {AuthPage}
