import {put, takeLatest} from 'redux-saga/effects'
import {ActionWithPayload, RootState} from '../../../../setup'
import {
  GSCTopPages,
  GSCTopPhrases,
  GSCTopPhrasesTimeCompare,
  SEOBrandList,
  SEOCategoryList,
  SEOEfficiencyList,
} from '../models/SEOReport'
import {
  brandListSEO,
  categoryListSEO,
  efficiencyListSEO,
  topPagesList,
  topPhrasesCompareList,
  topPhrasesList,
} from './ReportsCRUD'

export const GET_SEO_EFFICIENCY_LIST_REQUEST = 'GET_SEO_EFFICIENCY_LIST_REQUEST'
export const GET_SEO_EFFICIENCY_LIST_SUCCESS = 'GET_SEO_EFFICIENCY_LIST_SUCCESS'
export const GET_SEO_EFFICIENCY_LIST_FAIL = 'GET_SEO_EFFICIENCY_LIST_FAIL'

export const GET_SEO_CATEGORY_LIST_REQUEST = 'GET_SEO_CATEGORY_LIST_REQUEST'
export const GET_SEO_CATEGORY_LIST_SUCCESS = 'GET_SEO_CATEGORY_LIST_SUCCESS'
export const GET_SEO_CATEGORY_LIST_FAIL = 'GET_SEO_CATEGORY_LIST_FAIL'

export const GET_SEO_BRAND_LIST_REQUEST = 'GET_SEO_BRAND_LIST_REQUEST'
export const GET_SEO_BRAND_LIST_SUCCESS = 'GET_SEO_BRAND_LIST_SUCCESS'
export const GET_SEO_BRAND_LIST_FAIL = 'GET_SEO_BRAND_LIST_FAIL'

export const GET_SEO_GSC_TOP_PHRASES_REQUEST = 'GET_SEO_GSC_TOP_PHRASES_REQUEST'
export const GET_SEO_GSC_TOP_PHRASES_SUCCESS = 'GET_SEO_GSC_TOP_PHRASES_SUCCESS'
export const GET_SEO_GSC_TOP_PHRASES_FAIL = 'GET_SEO_GSC_TOP_PHRASES_FAIL'

export const GET_SEO_GSC_TOP_PAGES_REQUEST = 'GET_SEO_GSC_TOP_PAGES_REQUEST'
export const GET_SEO_GSC_TOP_PAGES_SUCCESS = 'GET_SEO_GSC_TOP_PAGES_SUCCESS'
export const GET_SEO_GSC_TOP_PAGES_FAIL = 'GET_SEO_GSC_TOP_PAGES_FAIL'

export const GET_SEO_GSC_TOP_PHRASES_COMPARE_REQUEST = 'GET_SEO_GSC_TOP_PHRASES_COMPARE_REQUEST'
export const GET_SEO_GSC_TOP_PHRASES_COMPARE_SUCCESS = 'GET_SEO_GSC_TOP_PHRASES_COMPARE_SUCCESS'
export const GET_SEO_GSC_TOP_PHRASES_COMPARE_FAIL = 'GET_SEO_GSC_TOP_PHRASES_COMPARE_FAIL'

export interface ISEOReportListsState {
  efficiencyList?: SEOEfficiencyList[]
  efficiencyLoading: boolean
  efficiencyError: boolean
  efficiencyTotalPages?: number
  efficiencyTotalItems?: number

  categoryList?: SEOCategoryList[]
  categoryLoading: boolean
  categoryError: boolean
  categoryTotalPages?: number
  categoryTotalItems?: number

  brandList?: SEOBrandList[]
  brandLoading: boolean
  brandError: boolean
  brandTotalPages?: number
  brandTotalItems?: number

  topPhrasesList?: GSCTopPhrases[]
  topPhrasesLoading: boolean
  topPhrasesError: boolean
  topPhrasesTotalPages?: number
  topPhrasesTotalItems?: number

  topPagesList?: GSCTopPages[]
  topPagesLoading: boolean
  topPagesError: boolean
  topPagesTotalPages?: number
  topPagesTotalItems?: number

  topPhrasesCompareList?: GSCTopPhrasesTimeCompare[]
  topPhrasesCompareLoading: boolean
  topPhrasesCompareError: boolean
  topPhrasesCompareTotalPages?: number
  topPhrasesCompareTotalItems?: number
}

const initailState: ISEOReportListsState = {
  efficiencyList: [],
  efficiencyLoading: false,
  efficiencyError: false,
  efficiencyTotalPages: 1,
  efficiencyTotalItems: 0,

  categoryList: [],
  categoryLoading: false,
  categoryError: false,
  categoryTotalPages: 1,
  categoryTotalItems: 0,

  brandList: [],
  brandLoading: false,
  brandError: false,
  brandTotalPages: 1,
  brandTotalItems: 0,

  topPhrasesList: [],
  topPhrasesLoading: false,
  topPhrasesError: false,
  topPhrasesTotalPages: 1,
  topPhrasesTotalItems: 0,

  topPagesList: [],
  topPagesLoading: false,
  topPagesError: false,
  topPagesTotalPages: 1,
  topPagesTotalItems: 0,

  topPhrasesCompareList: [],
  topPhrasesCompareLoading: false,
  topPhrasesCompareError: false,
  topPhrasesCompareTotalPages: 1,
  topPhrasesCompareTotalItems: 0,
}

export const reducer = (
  state: ISEOReportListsState = initailState,
  action: ActionWithPayload<ISEOReportListsState>
) => {
  switch (action.type) {
    case GET_SEO_EFFICIENCY_LIST_REQUEST:
      return {...state, efficiencyLoading: true, efficiencyError: false}
    case GET_SEO_EFFICIENCY_LIST_SUCCESS:
      const effProductList = action.payload?.efficiencyList
      const effTotalPages = action.payload?.efficiencyTotalPages
      const effTotalItems = action.payload?.efficiencyTotalItems
      return {
        ...state,
        efficiencyLoading: false,
        efficiencyList: effProductList,
        efficiencyTotalPages: effTotalPages,
        efficiencyTotalItems: effTotalItems,
      }
    case GET_SEO_EFFICIENCY_LIST_FAIL:
      return {...state, efficiencyLoading: false, efficiencyError: true}

    case GET_SEO_CATEGORY_LIST_REQUEST:
      return {...state, categoryLoading: true, categoryError: false}
    case GET_SEO_CATEGORY_LIST_SUCCESS:
      const categoryProductList = action.payload?.categoryList
      const categoryTotalPages = action.payload?.categoryTotalPages
      const categorylItems = action.payload?.categoryTotalItems
      return {
        ...state,
        categoryLoading: false,
        categoryList: categoryProductList,
        categoryTotalPages: categoryTotalPages,
        categoryTotalItems: categorylItems,
      }
    case GET_SEO_CATEGORY_LIST_FAIL:
      return {...state, categoryLoading: false, categoryError: true}

    case GET_SEO_BRAND_LIST_REQUEST:
      return {...state, brandLoading: true, brandError: false}
    case GET_SEO_BRAND_LIST_SUCCESS:
      const brandProductList = action.payload?.brandList
      const brandTotalPages = action.payload?.brandTotalPages
      const brandlItems = action.payload?.brandTotalItems
      return {
        ...state,
        brandLoading: false,
        brandList: brandProductList,
        brandTotalPages: brandTotalPages,
        brandTotalItems: brandlItems,
      }
    case GET_SEO_BRAND_LIST_FAIL:
      return {...state, brandLoading: false, brandError: true}

    case GET_SEO_GSC_TOP_PHRASES_REQUEST:
      return {...state, topPhrasesLoading: true, topPhrasesError: false}
    case GET_SEO_GSC_TOP_PHRASES_SUCCESS:
      const topPhrasesList = action.payload?.topPhrasesList
      const topPhrasesTotalPages = action.payload?.topPhrasesTotalPages
      const topPhrasesTotalItems = action.payload?.topPhrasesTotalItems
      return {
        ...state,
        topPhrasesLoading: false,
        topPhrasesList: topPhrasesList,
        topPhrasesTotalPages: topPhrasesTotalPages,
        topPhrasesTotalItems: topPhrasesTotalItems,
      }
    case GET_SEO_GSC_TOP_PHRASES_FAIL:
      return {...state, topPhrasesLoading: false, topPhrasesError: true}

    case GET_SEO_GSC_TOP_PAGES_REQUEST:
      return {...state, topPagesLoading: true, topPagesError: false}
    case GET_SEO_GSC_TOP_PAGES_SUCCESS:
      const topPagesList = action.payload?.topPagesList
      const topPagesTotalPages = action.payload?.topPagesTotalPages
      const topPagesTotalItems = action.payload?.topPagesTotalItems
      return {
        ...state,
        topPagesLoading: false,
        topPagesList: topPagesList,
        topPagesTotalPages: topPagesTotalPages,
        topPagesTotalItems: topPagesTotalItems,
      }
    case GET_SEO_GSC_TOP_PAGES_FAIL:
      return {...state, topPagesLoading: false, topPagesError: true}

    case GET_SEO_GSC_TOP_PHRASES_COMPARE_REQUEST:
      return {...state, topPhrasesCompareLoading: true, topPhrasesCompareError: false}
    case GET_SEO_GSC_TOP_PHRASES_COMPARE_SUCCESS:
      const topPhrasesCompareList = action.payload?.topPhrasesCompareList
      const topPhrasesCompareTotalPages = action.payload?.topPhrasesCompareTotalPages
      const topPhrasesCompareTotalItems = action.payload?.topPhrasesCompareTotalItems
      return {
        ...state,
        topPhrasesCompareLoading: false,
        topPhrasesCompareList: topPhrasesCompareList,
        topPhrasesCompareTotalPages: topPhrasesCompareTotalPages,
        topPhrasesCompareTotalItems: topPhrasesCompareTotalItems,
      }
    case GET_SEO_GSC_TOP_PHRASES_COMPARE_FAIL:
      return {...state, topPhrasesCompareLoading: false, topPhrasesCompareError: true}

    default:
      return state
  }
}

export const actions = {
  requestEfficiencyList: (reportId: number, companyId: number, currentPage: number) => ({
    type: GET_SEO_EFFICIENCY_LIST_REQUEST,
    payload: {reportId, companyId, currentPage},
  }),
  requestEfficiencyListSuccess: (
    productList: SEOEfficiencyList[],
    totalPages: number,
    totlaItems: number
  ) => ({
    type: GET_SEO_EFFICIENCY_LIST_SUCCESS,
    payload: {
      efficiencyList: productList,
      efficiencyTotalPages: totalPages,
      efficiencyTotalItems: totlaItems,
    },
  }),
  requestEfficiencyListFail: () => ({
    type: GET_SEO_EFFICIENCY_LIST_FAIL,
  }),

  requestCategoryList: (reportId: number, companyId: number, currentPage: number) => ({
    type: GET_SEO_CATEGORY_LIST_REQUEST,
    payload: {reportId, companyId, currentPage},
  }),
  requestCategoryListSuccess: (
    productList: SEOCategoryList[],
    totalPages: number,
    totlaItems: number
  ) => ({
    type: GET_SEO_CATEGORY_LIST_SUCCESS,
    payload: {
      categoryList: productList,
      categoryTotalPages: totalPages,
      categoryTotalItems: totlaItems,
    },
  }),
  requestCategoryListFail: () => ({
    type: GET_SEO_CATEGORY_LIST_FAIL,
  }),

  requestBrandList: (reportId: number, companyId: number, currentPage: number) => ({
    type: GET_SEO_BRAND_LIST_REQUEST,
    payload: {reportId, companyId, currentPage},
  }),
  requestBrandListSuccess: (
    productList: SEOBrandList[],
    totalPages: number,
    totlaItems: number
  ) => ({
    type: GET_SEO_BRAND_LIST_SUCCESS,
    payload: {
      brandList: productList,
      brandTotalPages: totalPages,
      brandTotalItems: totlaItems,
    },
  }),
  requestBrandListFail: () => ({
    type: GET_SEO_BRAND_LIST_FAIL,
  }),

  requestTopPhrasesList: (reportId: number, companyId: number, currentPage: number) => ({
    type: GET_SEO_GSC_TOP_PHRASES_REQUEST,
    payload: {reportId, companyId, currentPage},
  }),
  requestTopPhrasesListSuccess: (
    phrasesList: GSCTopPhrases[],
    totalPages: number,
    totlaItems: number
  ) => ({
    type: GET_SEO_GSC_TOP_PHRASES_SUCCESS,
    payload: {
      topPhrasesList: phrasesList,
      topPhrasesTotalPages: totalPages,
      topPhrasesTotalItems: totlaItems,
    },
  }),
  requestTopPhrasesListFail: () => ({
    type: GET_SEO_GSC_TOP_PHRASES_FAIL,
  }),

  requestTopPagesList: (reportId: number, companyId: number, currentPage: number) => ({
    type: GET_SEO_GSC_TOP_PAGES_REQUEST,
    payload: {reportId, companyId, currentPage},
  }),
  requestTopPagesListSuccess: (
    pagesList: GSCTopPages[],
    totalPages: number,
    totlaItems: number
  ) => ({
    type: GET_SEO_GSC_TOP_PAGES_SUCCESS,
    payload: {
      topPagesList: pagesList,
      topPagesTotalPages: totalPages,
      topPagesTotalItems: totlaItems,
    },
  }),
  requestTopPagesListFail: () => ({
    type: GET_SEO_GSC_TOP_PAGES_FAIL,
  }),

  requestTopPhrasesCompareList: (reportId: number, companyId: number, currentPage: number) => ({
    type: GET_SEO_GSC_TOP_PHRASES_COMPARE_REQUEST,
    payload: {reportId, companyId, currentPage},
  }),
  requestTopPhrasesCompareListSuccess: (
    phrasesList: GSCTopPhrasesTimeCompare[],
    totalPages: number,
    totlaItems: number
  ) => ({
    type: GET_SEO_GSC_TOP_PHRASES_COMPARE_SUCCESS,
    payload: {
      topPhrasesCompareList: phrasesList,
      topPhrasesCompareTotalPages: totalPages,
      topPhrasesCompareTotalItems: totlaItems,
    },
  }),
  requestTopPhrasesCompareListFail: () => ({
    type: GET_SEO_GSC_TOP_PHRASES_COMPARE_SUCCESS,
  }),
}

export const selectors = {
  getReportListsState: (state: RootState) => state.seoReportList,
}

interface SEOReportListWithParamAction {
  type: string
  payload: {
    reportId: number
    companyId: number
    currentPage: number
  }
}

export function* saga() {
  yield takeLatest(
    GET_SEO_EFFICIENCY_LIST_REQUEST,
    function* getEfficiencyListSaga(action: SEOReportListWithParamAction) {
      try {
        const {data} = yield efficiencyListSEO(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          action.payload.currentPage
        )
        yield put(
          actions.requestEfficiencyListSuccess(
            data.report.data,
            data.report.last_page,
            data.report.total
          )
        )
      } catch (err) {
        yield put(actions.requestEfficiencyListFail())
      }
    }
  )

  yield takeLatest(
    GET_SEO_CATEGORY_LIST_REQUEST,
    function* getCategoryListSaga(action: SEOReportListWithParamAction) {
      try {
        const {data} = yield categoryListSEO(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          action.payload.currentPage
        )
        yield put(
          actions.requestCategoryListSuccess(
            data.report.data,
            data.report.last_page,
            data.report.total
          )
        )
      } catch (err) {
        yield put(actions.requestCategoryListFail())
      }
    }
  )

  yield takeLatest(
    GET_SEO_BRAND_LIST_REQUEST,
    function* getBrandListSaga(action: SEOReportListWithParamAction) {
      try {
        const {data} = yield brandListSEO(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          action.payload.currentPage
        )
        yield put(
          actions.requestBrandListSuccess(
            data.report.data,
            data.report.last_page,
            data.report.total
          )
        )
      } catch (err) {
        yield put(actions.requestBrandListFail())
      }
    }
  )

  yield takeLatest(
    GET_SEO_GSC_TOP_PHRASES_REQUEST,
    function* getTopPhrasesSaga(action: SEOReportListWithParamAction) {
      try {
        const {data} = yield topPhrasesList(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          action.payload.currentPage
        )
        yield put(
          actions.requestTopPhrasesListSuccess(
            data.report.data,
            data.report.last_page,
            data.report.total
          )
        )
      } catch (err) {
        yield put(actions.requestTopPhrasesListFail())
      }
    }
  )

  yield takeLatest(
    GET_SEO_GSC_TOP_PAGES_REQUEST,
    function* getTopPagesSaga(action: SEOReportListWithParamAction) {
      try {
        const {data} = yield topPagesList(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          action.payload.currentPage
        )
        yield put(
          actions.requestTopPagesListSuccess(
            data.report.data,
            data.report.last_page,
            data.report.total
          )
        )
      } catch (err) {
        yield put(actions.requestTopPagesListFail())
      }
    }
  )

  yield takeLatest(
    GET_SEO_GSC_TOP_PHRASES_COMPARE_REQUEST,
    function* getTopPagesSaga(action: SEOReportListWithParamAction) {
      try {
        const {data} = yield topPhrasesCompareList(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          action.payload.currentPage
        )
        yield put(
          actions.requestTopPhrasesCompareListSuccess(
            data.report.data,
            data.report.last_page,
            data.report.total
          )
        )
      } catch (err) {
        yield put(actions.requestTopPhrasesCompareListFail())
      }
    }
  )
}
