import React from 'react'
import './Spinner.scss'

interface SpinnerProps {
  color?: string
}

const Spinner: React.FC<SpinnerProps> = ({color}) => {
  return (
    <div className={`overlay-layer rounded bg-opacity-5 ${color ? color : ' bg-dark'}`}>
      <div className='lds-roller'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Spinner
