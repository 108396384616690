import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import {selectors} from '../../../redux/ReportsRedux'
import CommentWidget from '../SeoWidgets/CommentWidget'

interface CommentGroupProps {}

const CommentGroup: React.FC<CommentGroupProps> = () => {
  const intl = useIntl()
  const reportData = useSelector(selectors.getReportData)
  const {reportsLoading, reportsError} = useSelector(selectors.getReportsState)

  return !reportsError ? (
    <div className='row align-items-stretch'>
      {!reportsLoading && reportData ? (
        <>
          {reportData.comment && (
            <div className='col-12 mb-8'>
              <CommentWidget
                commentTitle={intl.formatMessage({id: 'SPECIALIST_COMMENT'})}
                commentBody={reportData.comment}
              />
            </div>
          )}
          {reportData.client_plan && (
            <div className='col-12 mb-8'>
              <CommentWidget
                commentTitle={intl.formatMessage({id: 'CAMPAIGN_PLANS'})}
                commentBody={reportData.client_plan}
              />
            </div>
          )}
          {reportData.client_todo && (
            <div className='col-12'>
              <CommentWidget
                commentTitle={intl.formatMessage({id: 'CLIENT_TASKS'})}
                commentBody={reportData.client_todo}
              />
            </div>
          )}
          {reportData.usefull_links && (
            <div className='col-12'>
              <CommentWidget
                commentTitle={intl.formatMessage({id: 'USEFUL_LINKS'})}
                commentBody={reportData.usefull_links}
              />
            </div>
          )}
        </>
      ) : (
        <div style={{height: '350px'}}>
          <Spinner color='bg-white' />
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default CommentGroup
