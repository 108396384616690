var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.7542, 54.4654],
            [23.7631, 54.4664],
            [23.7742, 54.4583],
            [23.7954, 54.4626],
            [23.7949, 54.4684],
            [23.8383, 54.4698],
            [23.8721, 54.4666],
            [23.872, 54.4592],
            [23.9052, 54.4643],
            [23.9001, 54.4836],
            [23.9053, 54.4892],
            [23.9711, 54.4856],
            [24.0078, 54.4975],
            [24.0135, 54.5043],
            [24.0094, 54.5117],
            [23.9934, 54.514],
            [24.0026, 54.5237],
            [24.0054, 54.5329],
            [24.015, 54.5452],
            [24.0323, 54.55],
            [24.0695, 54.554],
            [24.0882, 54.5493],
            [24.1006, 54.5401],
            [24.1051, 54.5311],
            [24.1136, 54.5306],
            [24.1278, 54.5397],
            [24.1456, 54.5379],
            [24.1962, 54.5395],
            [24.2041, 54.5573],
            [24.2634, 54.5617],
            [24.2611, 54.5452],
            [24.2721, 54.5386],
            [24.2803, 54.5229],
            [24.3194, 54.5176],
            [24.3435, 54.5222],
            [24.3726, 54.5185],
            [24.4046, 54.5213],
            [24.4145, 54.5312],
            [24.4094, 54.5243],
            [24.4122, 54.5012],
            [24.4217, 54.5006],
            [24.4262, 54.4835],
            [24.3994, 54.4761],
            [24.4079, 54.4665],
            [24.4288, 54.4692],
            [24.4417, 54.4587],
            [24.4416, 54.4478],
            [24.4225, 54.4359],
            [24.4222, 54.4279],
            [24.444, 54.4233],
            [24.4547, 54.4184],
            [24.4988, 54.4174],
            [24.5087, 54.4221],
            [24.5429, 54.4236],
            [24.5426, 54.4142],
            [24.5598, 54.4134],
            [24.5799, 54.4187],
            [24.5835, 54.4277],
            [24.6095, 54.4386],
            [24.6185, 54.4446],
            [24.641, 54.44],
            [24.6481, 54.4459],
            [24.6626, 54.4468],
            [24.691, 54.4304],
            [24.712, 54.427],
            [24.7115, 54.4128],
            [24.7042, 54.4102],
            [24.7052, 54.3958],
            [24.7306, 54.3935],
            [24.7489, 54.3985],
            [24.7999, 54.402],
            [24.8002, 54.3973],
            [24.8424, 54.3969],
            [24.8396, 54.4056],
            [24.8483, 54.4116],
            [24.8618, 54.4093],
            [24.8702, 54.4133],
            [24.9021, 54.4156],
            [24.9182, 54.4228],
            [24.9336, 54.4239],
            [24.95, 54.4301],
            [24.9724, 54.428],
            [24.9859, 54.4346],
            [24.9994, 54.4338],
            [25.0167, 54.438],
            [25.0228, 54.4236],
            [25.0075, 54.4203],
            [25.0152, 54.3978],
            [24.9926, 54.3946],
            [24.9952, 54.3825],
            [24.9244, 54.3745],
            [24.9269, 54.3632],
            [24.9092, 54.3602],
            [24.915, 54.3436],
            [24.9254, 54.3367],
            [24.9169, 54.325],
            [24.9303, 54.298],
            [24.8807, 54.296],
            [24.8705, 54.2906],
            [24.8713, 54.2847],
            [24.8845, 54.2772],
            [24.8611, 54.2758],
            [24.8498, 54.2607],
            [24.8304, 54.2565],
            [24.8198, 54.2581],
            [24.8038, 54.2534],
            [24.8073, 54.2384],
            [24.7854, 54.2389],
            [24.776, 54.2322],
            [24.7658, 54.2349],
            [24.7569, 54.2272],
            [24.7582, 54.2169],
            [24.7535, 54.2056],
            [24.7856, 54.1994],
            [24.8033, 54.1919],
            [24.8128, 54.1923],
            [24.8122, 54.1745],
            [24.8259, 54.1647],
            [24.8336, 54.1488],
            [24.8184, 54.1456],
            [24.8315, 54.1323],
            [24.7984, 54.1167],
            [24.7727, 54.1123],
            [24.7806, 54.1005],
            [24.8021, 54.0916],
            [24.8207, 54.0714],
            [24.8263, 54.0568],
            [24.823, 54.0455],
            [24.8531, 54.0343],
            [24.8651, 54.0387],
            [24.8733, 54.0321],
            [24.8381, 54.0284],
            [24.8449, 54.0107],
            [24.8278, 54.0089],
            [24.8268, 53.999],
            [24.8157, 53.995],
            [24.7949, 53.9986],
            [24.7895, 53.9947],
            [24.77, 53.9933],
            [24.7356, 53.9743],
            [24.7383, 53.9666],
            [24.7116, 53.9649],
            [24.6887, 53.9654],
            [24.6928, 53.9826],
            [24.7019, 53.9951],
            [24.7053, 54.0086],
            [24.7016, 54.0161],
            [24.6908, 54.0202],
            [24.6747, 54.0186],
            [24.6593, 54.0071],
            [24.6495, 54.0061],
            [24.622, 54.0103],
            [24.6038, 53.9987],
            [24.6014, 53.9925],
            [24.5651, 53.9827],
            [24.5198, 53.9599],
            [24.5042, 53.9443],
            [24.4924, 53.9367],
            [24.4765, 53.931],
            [24.4594, 53.9177],
            [24.4564, 53.9089],
            [24.4343, 53.9024],
            [24.4206, 53.908],
            [24.3834, 53.9009],
            [24.3419, 53.9056],
            [24.3289, 53.9023],
            [24.3124, 53.9059],
            [24.301, 53.9173],
            [24.2912, 53.9172],
            [24.2738, 53.9357],
            [24.2602, 53.9408],
            [24.2607, 53.9483],
            [24.251, 53.9522],
            [24.2331, 53.9446],
            [24.2116, 53.9496],
            [24.2151, 53.9577],
            [24.2024, 53.9698],
            [24.1938, 53.9656],
            [24.157, 53.9629],
            [24.1428, 53.9538],
            [24.1166, 53.9571],
            [24.1062, 53.9501],
            [24.1015, 53.939],
            [24.0872, 53.9382],
            [24.0464, 53.9323],
            [24.0006, 53.927],
            [23.976, 53.9262],
            [23.9727, 53.9398],
            [23.9649, 53.9518],
            [23.9558, 53.9567],
            [23.9103, 53.9647],
            [23.9113, 53.9558],
            [23.9045, 53.9474],
            [23.8835, 53.9417],
            [23.8598, 53.9531],
            [23.8393, 53.9534],
            [23.8196, 53.9413],
            [23.8022, 53.935],
            [23.8013, 53.9262],
            [23.815, 53.9196],
            [23.8179, 53.9085],
            [23.8002, 53.8997],
            [23.7783, 53.8991],
            [23.7826, 53.9089],
            [23.7685, 53.9179],
            [23.7678, 53.9233],
            [23.7463, 53.9265],
            [23.7113, 53.9391],
            [23.6673, 53.9309],
            [23.6568, 53.9342],
            [23.6086, 53.926],
            [23.5808, 53.9425],
            [23.5691, 53.9447],
            [23.536, 53.9435],
            [23.4876, 53.9777],
            [23.4821, 53.9978],
            [23.5088, 54.0101],
            [23.5165, 54.0265],
            [23.5246, 54.0292],
            [23.5284, 54.064],
            [23.5183, 54.0769],
            [23.5183, 54.0877],
            [23.5065, 54.0988],
            [23.5021, 54.111],
            [23.4931, 54.1151],
            [23.4955, 54.1302],
            [23.4867, 54.151],
            [23.475, 54.1555],
            [23.4759, 54.1615],
            [23.4618, 54.166],
            [23.4596, 54.1736],
            [23.4255, 54.1758],
            [23.419, 54.1878],
            [23.4056, 54.1951],
            [23.3968, 54.2128],
            [23.3815, 54.2269],
            [23.366, 54.228],
            [23.3578, 54.2323],
            [23.3468, 54.245],
            [23.3375, 54.2501],
            [23.3236, 54.2514],
            [23.3282, 54.2646],
            [23.3355, 54.2741],
            [23.314, 54.2964],
            [23.3471, 54.3066],
            [23.3407, 54.3237],
            [23.3475, 54.3351],
            [23.3705, 54.3463],
            [23.3688, 54.3557],
            [23.3978, 54.361],
            [23.405, 54.3653],
            [23.4395, 54.3626],
            [23.4561, 54.3657],
            [23.4584, 54.3715],
            [23.4403, 54.3806],
            [23.4448, 54.3944],
            [23.459, 54.3984],
            [23.4904, 54.4129],
            [23.5152, 54.4112],
            [23.515, 54.4641],
            [23.5337, 54.4645],
            [23.5388, 54.4678],
            [23.5734, 54.4736],
            [23.6087, 54.4893],
            [23.6265, 54.5077],
            [23.6458, 54.514],
            [23.662, 54.5089],
            [23.671, 54.5006],
            [23.6729, 54.4886],
            [23.685, 54.4765],
            [23.7169, 54.4845],
            [23.728, 54.4753],
            [23.7542, 54.4654],
          ],
        ],
      },
      properties: {name: 'Alytus', id: 'LT-AL', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-AL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.6801, 55.4744],
            [22.703, 55.4855],
            [22.7153, 55.4804],
            [22.7584, 55.5104],
            [22.774, 55.5001],
            [22.8008, 55.4996],
            [22.8212, 55.5019],
            [22.8433, 55.5017],
            [22.8494, 55.4788],
            [22.8288, 55.4706],
            [22.8368, 55.462],
            [22.8486, 55.4571],
            [22.8544, 55.4668],
            [22.8961, 55.4613],
            [22.9197, 55.4769],
            [22.9337, 55.4824],
            [22.9671, 55.4836],
            [22.9842, 55.4889],
            [23.0051, 55.4873],
            [23.0313, 55.4988],
            [23.0407, 55.5071],
            [23.0494, 55.4991],
            [23.0645, 55.5037],
            [23.077, 55.4996],
            [23.0893, 55.5049],
            [23.0799, 55.5144],
            [23.0741, 55.5354],
            [23.0961, 55.5366],
            [23.0956, 55.5623],
            [23.1228, 55.5655],
            [23.1268, 55.5513],
            [23.189, 55.5555],
            [23.2159, 55.5626],
            [23.2301, 55.5693],
            [23.2338, 55.5439],
            [23.2416, 55.5298],
            [23.2555, 55.5302],
            [23.2573, 55.524],
            [23.2766, 55.5328],
            [23.2862, 55.5326],
            [23.331, 55.5476],
            [23.3428, 55.5423],
            [23.3605, 55.5408],
            [23.3804, 55.5316],
            [23.4016, 55.5154],
            [23.4017, 55.5048],
            [23.4176, 55.5054],
            [23.4565, 55.4952],
            [23.467, 55.4854],
            [23.4845, 55.4948],
            [23.483, 55.505],
            [23.4761, 55.5141],
            [23.5053, 55.5152],
            [23.5362, 55.5226],
            [23.5464, 55.5145],
            [23.5586, 55.5135],
            [23.5694, 55.5073],
            [23.5609, 55.4933],
            [23.562, 55.4796],
            [23.5677, 55.4736],
            [23.5978, 55.4734],
            [23.6181, 55.4755],
            [23.6362, 55.4709],
            [23.6602, 55.4717],
            [23.6654, 55.4811],
            [23.6572, 55.4845],
            [23.6851, 55.4942],
            [23.6976, 55.5082],
            [23.7396, 55.5151],
            [23.7384, 55.5221],
            [23.8223, 55.5418],
            [23.821, 55.5454],
            [23.8507, 55.552],
            [23.861, 55.5494],
            [23.8949, 55.5642],
            [23.9188, 55.5458],
            [23.9018, 55.542],
            [23.9082, 55.5306],
            [23.9345, 55.5133],
            [23.9581, 55.5044],
            [23.9591, 55.5107],
            [23.9828, 55.5124],
            [23.9966, 55.5077],
            [24.0411, 55.4782],
            [24.0595, 55.477],
            [24.0574, 55.4656],
            [24.0478, 55.4526],
            [24.0554, 55.4483],
            [24.1004, 55.4426],
            [24.112, 55.4531],
            [24.1307, 55.4523],
            [24.1342, 55.4442],
            [24.1437, 55.4389],
            [24.1575, 55.4425],
            [24.1716, 55.4532],
            [24.21, 55.4521],
            [24.2155, 55.4751],
            [24.2557, 55.4656],
            [24.2575, 55.4512],
            [24.3057, 55.4411],
            [24.314, 55.453],
            [24.3404, 55.4455],
            [24.3527, 55.4384],
            [24.3638, 55.4415],
            [24.3777, 55.4403],
            [24.4025, 55.4315],
            [24.4003, 55.4141],
            [24.4304, 55.3995],
            [24.4493, 55.3988],
            [24.4473, 55.3757],
            [24.4257, 55.3707],
            [24.4234, 55.3569],
            [24.4087, 55.3518],
            [24.4108, 55.3343],
            [24.4025, 55.3326],
            [24.3941, 55.2934],
            [24.4266, 55.2917],
            [24.4347, 55.2809],
            [24.4341, 55.2696],
            [24.4208, 55.2427],
            [24.4191, 55.2343],
            [24.4502, 55.2319],
            [24.4707, 55.2271],
            [24.4936, 55.2251],
            [24.5106, 55.2208],
            [24.4869, 55.203],
            [24.4977, 55.1932],
            [24.4958, 55.1817],
            [24.5054, 55.174],
            [24.5095, 55.1601],
            [24.5256, 55.157],
            [24.5368, 55.1591],
            [24.5482, 55.1555],
            [24.543, 55.1481],
            [24.5286, 55.1434],
            [24.5476, 55.1309],
            [24.5476, 55.1213],
            [24.5624, 55.1141],
            [24.5864, 55.112],
            [24.5865, 55.096],
            [24.596, 55.0895],
            [24.6079, 55.0749],
            [24.6186, 55.0746],
            [24.6355, 55.0611],
            [24.6346, 55.052],
            [24.6251, 55.0373],
            [24.631, 55.0317],
            [24.5926, 55.0285],
            [24.5949, 55.0121],
            [24.5682, 55.0065],
            [24.567, 54.9969],
            [24.5552, 54.9901],
            [24.5721, 54.9828],
            [24.5826, 54.9731],
            [24.6, 54.9718],
            [24.6223, 54.9734],
            [24.6333, 54.9607],
            [24.6531, 54.9515],
            [24.666, 54.9513],
            [24.6807, 54.9457],
            [24.69, 54.9348],
            [24.6905, 54.9282],
            [24.7261, 54.932],
            [24.7515, 54.9311],
            [24.7682, 54.9176],
            [24.7995, 54.9102],
            [24.8131, 54.9022],
            [24.812, 54.888],
            [24.7949, 54.8815],
            [24.7899, 54.8866],
            [24.7575, 54.8834],
            [24.753, 54.8783],
            [24.766, 54.8236],
            [24.7588, 54.8269],
            [24.729, 54.8175],
            [24.7203, 54.8184],
            [24.6874, 54.8144],
            [24.6651, 54.8078],
            [24.6452, 54.7984],
            [24.6407, 54.7875],
            [24.6428, 54.777],
            [24.6327, 54.7683],
            [24.644, 54.7605],
            [24.6466, 54.7504],
            [24.6327, 54.7489],
            [24.6346, 54.7337],
            [24.626, 54.7308],
            [24.6203, 54.7186],
            [24.628, 54.7131],
            [24.6263, 54.704],
            [24.6128, 54.7005],
            [24.5939, 54.702],
            [24.5831, 54.7135],
            [24.5622, 54.7147],
            [24.5485, 54.6914],
            [24.5083, 54.7025],
            [24.479, 54.699],
            [24.4581, 54.6804],
            [24.4635, 54.6641],
            [24.4595, 54.6619],
            [24.4747, 54.6362],
            [24.472, 54.6282],
            [24.4835, 54.6087],
            [24.475, 54.6029],
            [24.4599, 54.6025],
            [24.436, 54.5902],
            [24.4253, 54.5892],
            [24.4305, 54.5783],
            [24.4254, 54.5705],
            [24.4282, 54.5623],
            [24.4225, 54.5533],
            [24.4145, 54.5312],
            [24.4046, 54.5213],
            [24.3726, 54.5185],
            [24.3435, 54.5222],
            [24.3194, 54.5176],
            [24.2803, 54.5229],
            [24.2721, 54.5386],
            [24.2611, 54.5452],
            [24.2634, 54.5617],
            [24.2041, 54.5573],
            [24.1962, 54.5395],
            [24.1456, 54.5379],
            [24.1278, 54.5397],
            [24.1136, 54.5306],
            [24.1051, 54.5311],
            [24.1006, 54.5401],
            [24.0882, 54.5493],
            [24.0695, 54.554],
            [24.0323, 54.55],
            [24.015, 54.5452],
            [24.0054, 54.5329],
            [24.0026, 54.5237],
            [23.9934, 54.514],
            [24.0094, 54.5117],
            [24.0135, 54.5043],
            [24.0078, 54.4975],
            [23.9711, 54.4856],
            [23.9053, 54.4892],
            [23.9001, 54.4836],
            [23.9052, 54.4643],
            [23.872, 54.4592],
            [23.8721, 54.4666],
            [23.8383, 54.4698],
            [23.7949, 54.4684],
            [23.7954, 54.4626],
            [23.7742, 54.4583],
            [23.7631, 54.4664],
            [23.7542, 54.4654],
            [23.7848, 54.4891],
            [23.7891, 54.5022],
            [23.7708, 54.5122],
            [23.7548, 54.5245],
            [23.7524, 54.5402],
            [23.746, 54.549],
            [23.7249, 54.5435],
            [23.7024, 54.5404],
            [23.6895, 54.5583],
            [23.683, 54.5578],
            [23.6649, 54.583],
            [23.6901, 54.5888],
            [23.6985, 54.5928],
            [23.6731, 54.6174],
            [23.6442, 54.6159],
            [23.6447, 54.6238],
            [23.6314, 54.6226],
            [23.6286, 54.629],
            [23.6731, 54.6387],
            [23.6562, 54.6446],
            [23.6557, 54.6522],
            [23.6756, 54.6602],
            [23.671, 54.6767],
            [23.6605, 54.6881],
            [23.639, 54.7056],
            [23.6674, 54.7193],
            [23.6434, 54.7421],
            [23.6405, 54.7491],
            [23.6485, 54.7641],
            [23.6155, 54.7709],
            [23.6327, 54.7824],
            [23.6331, 54.7895],
            [23.6496, 54.7988],
            [23.608, 54.8229],
            [23.6186, 54.8318],
            [23.6063, 54.8397],
            [23.6281, 54.8585],
            [23.5456, 54.8924],
            [23.5406, 54.8965],
            [23.5009, 54.8877],
            [23.4823, 54.8892],
            [23.4768, 54.902],
            [23.4738, 54.9278],
            [23.3924, 54.9514],
            [23.4005, 54.9578],
            [23.4119, 54.9566],
            [23.4353, 54.9733],
            [23.4531, 54.979],
            [23.5122, 54.9846],
            [23.5247, 54.9881],
            [23.531, 54.9947],
            [23.5664, 55.0002],
            [23.5862, 55.0088],
            [23.5858, 55.0328],
            [23.5793, 55.0372],
            [23.5542, 55.0431],
            [23.528, 55.0453],
            [23.5007, 55.0448],
            [23.4652, 55.0535],
            [23.4292, 55.0709],
            [23.4124, 55.0728],
            [23.4319, 55.0831],
            [23.4635, 55.1115],
            [23.4701, 55.1123],
            [23.4801, 55.1245],
            [23.4834, 55.1451],
            [23.4805, 55.1613],
            [23.5084, 55.2047],
            [23.4581, 55.2133],
            [23.4558, 55.2195],
            [23.4245, 55.2138],
            [23.4138, 55.2177],
            [23.397, 55.2137],
            [23.3774, 55.2202],
            [23.3809, 55.2305],
            [23.3762, 55.2433],
            [23.37, 55.2448],
            [23.334, 55.2428],
            [23.3077, 55.2358],
            [23.2867, 55.2351],
            [23.2461, 55.2415],
            [23.2424, 55.2384],
            [23.191, 55.2379],
            [23.1979, 55.2492],
            [23.1923, 55.2727],
            [23.184, 55.2685],
            [23.1619, 55.2715],
            [23.1512, 55.2634],
            [23.1098, 55.2604],
            [23.0946, 55.267],
            [23.0784, 55.2645],
            [23.0637, 55.2551],
            [23.0081, 55.2552],
            [23.0041, 55.2606],
            [23.0272, 55.2785],
            [23.0077, 55.2878],
            [22.9785, 55.2929],
            [22.9884, 55.3195],
            [22.9494, 55.313],
            [22.9433, 55.3216],
            [22.9321, 55.3206],
            [22.9238, 55.3124],
            [22.9002, 55.3077],
            [22.9009, 55.2979],
            [22.8839, 55.2988],
            [22.8617, 55.3119],
            [22.8498, 55.3137],
            [22.8493, 55.3251],
            [22.8335, 55.3251],
            [22.8151, 55.3204],
            [22.795, 55.3257],
            [22.8026, 55.3451],
            [22.8149, 55.349],
            [22.8111, 55.3554],
            [22.8134, 55.3677],
            [22.7952, 55.3847],
            [22.7599, 55.3794],
            [22.7517, 55.3907],
            [22.7348, 55.3897],
            [22.7308, 55.4027],
            [22.7426, 55.4083],
            [22.741, 55.4253],
            [22.7277, 55.4259],
            [22.7219, 55.4198],
            [22.6982, 55.4171],
            [22.6894, 55.4285],
            [22.6833, 55.4494],
            [22.6722, 55.4542],
            [22.6936, 55.4621],
            [22.6954, 55.4687],
            [22.6801, 55.4744],
          ],
        ],
      },
      properties: {name: 'Kaunas', id: 'LT-KU', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-KU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [21.1022, 55.7174],
              [21.1286, 55.6884],
              [21.1355, 55.6768],
              [21.1295, 55.656],
              [21.1319, 55.6343],
              [21.1356, 55.6328],
              [21.1372, 55.6075],
              [21.1309, 55.5546],
              [21.1239, 55.5417],
              [21.1182, 55.5212],
              [21.1204, 55.513],
              [21.1156, 55.5034],
              [21.1201, 55.4889],
              [21.1113, 55.4818],
              [21.105, 55.4691],
              [21.1001, 55.4507],
              [21.0935, 55.4389],
              [21.1092, 55.4301],
              [21.1124, 55.4204],
              [21.0886, 55.4102],
              [21.095, 55.4037],
              [21.0763, 55.3916],
              [21.0769, 55.3802],
              [21.0588, 55.3677],
              [21.0569, 55.3585],
              [21.0635, 55.3507],
              [21.0541, 55.3372],
              [21.0606, 55.3283],
              [21.0316, 55.3194],
              [21.0189, 55.3132],
              [21.0081, 55.2991],
              [20.9962, 55.295],
              [21.0044, 55.2886],
              [20.9867, 55.2825],
              [20.9833, 55.2769],
              [20.9543, 55.2796],
              [20.9632, 55.2873],
              [20.9894, 55.3177],
              [21.0007, 55.339],
              [21.0073, 55.3452],
              [21.0467, 55.4027],
              [21.071, 55.4481],
              [21.0743, 55.4654],
              [21.0894, 55.5011],
              [21.1022, 55.5497],
              [21.1029, 55.5734],
              [21.1063, 55.5926],
              [21.1085, 55.631],
              [21.1062, 55.6579],
              [21.0934, 55.7226],
              [21.1022, 55.7174],
            ],
          ],
          [
            [
              [21.9731, 56.3757],
              [21.9691, 56.359],
              [21.9628, 56.3506],
              [21.9343, 56.3514],
              [21.93, 56.318],
              [21.9758, 56.3155],
              [21.968, 56.2679],
              [21.9756, 56.267],
              [21.9814, 56.2513],
              [21.9909, 56.2485],
              [21.9969, 56.2315],
              [21.988, 56.2256],
              [22.0136, 56.211],
              [22.0123, 56.1962],
              [21.9645, 56.201],
              [21.9582, 56.1776],
              [21.9451, 56.1772],
              [21.9438, 56.1633],
              [21.9363, 56.1329],
              [21.9218, 56.1239],
              [21.9256, 56.1194],
              [21.9106, 56.113],
              [21.9024, 56.1215],
              [21.8484, 56.1261],
              [21.8492, 56.1321],
              [21.8333, 56.1333],
              [21.8268, 56.1257],
              [21.837, 56.1207],
              [21.8449, 56.0973],
              [21.7788, 56.1115],
              [21.7528, 56.11],
              [21.7317, 56.0791],
              [21.7087, 56.0819],
              [21.7052, 56.0738],
              [21.6842, 56.0424],
              [21.6761, 56.0416],
              [21.6498, 56.0065],
              [21.6344, 56.0085],
              [21.6193, 56.0053],
              [21.6047, 56.0134],
              [21.5742, 56.013],
              [21.5623, 55.997],
              [21.5976, 55.9884],
              [21.5724, 55.9567],
              [21.5801, 55.9476],
              [21.5812, 55.9239],
              [21.5634, 55.9236],
              [21.5703, 55.9156],
              [21.5824, 55.914],
              [21.607, 55.9038],
              [21.6167, 55.8962],
              [21.6056, 55.8901],
              [21.5897, 55.8892],
              [21.5763, 55.8823],
              [21.5824, 55.876],
              [21.5787, 55.8688],
              [21.5648, 55.8701],
              [21.5476, 55.865],
              [21.547, 55.8552],
              [21.5325, 55.8572],
              [21.5256, 55.8531],
              [21.5322, 55.8395],
              [21.5192, 55.8414],
              [21.5012, 55.815],
              [21.5256, 55.8066],
              [21.5289, 55.8026],
              [21.5483, 55.7983],
              [21.5596, 55.8059],
              [21.5682, 55.8057],
              [21.566, 55.819],
              [21.5779, 55.8194],
              [21.5893, 55.8045],
              [21.6047, 55.7997],
              [21.6067, 55.7933],
              [21.6184, 55.7898],
              [21.6137, 55.7793],
              [21.6197, 55.7756],
              [21.6177, 55.7557],
              [21.6383, 55.7526],
              [21.6547, 55.7646],
              [21.7034, 55.7507],
              [21.7297, 55.7528],
              [21.7375, 55.7634],
              [21.7561, 55.7569],
              [21.7525, 55.7371],
              [21.757, 55.7301],
              [21.7472, 55.7259],
              [21.7419, 55.7127],
              [21.7554, 55.7046],
              [21.7716, 55.7046],
              [21.781, 55.6964],
              [21.805, 55.6961],
              [21.7867, 55.6866],
              [21.7851, 55.6742],
              [21.7732, 55.6661],
              [21.7776, 55.6494],
              [21.7657, 55.6432],
              [21.7788, 55.6339],
              [21.7903, 55.6329],
              [21.7929, 55.6088],
              [21.8158, 55.6113],
              [21.8313, 55.6159],
              [21.8388, 55.61],
              [21.8794, 55.5933],
              [21.8665, 55.583],
              [21.8749, 55.5774],
              [21.8388, 55.5639],
              [21.831, 55.5646],
              [21.8138, 55.5525],
              [21.8135, 55.5447],
              [21.8312, 55.5498],
              [21.8313, 55.5403],
              [21.8191, 55.5367],
              [21.8243, 55.5156],
              [21.8344, 55.4937],
              [21.8475, 55.497],
              [21.8631, 55.464],
              [21.863, 55.4434],
              [21.8679, 55.4221],
              [21.8727, 55.4205],
              [21.9177, 55.4345],
              [21.9278, 55.4323],
              [21.963, 55.4192],
              [21.9963, 55.4227],
              [22.0034, 55.411],
              [22.0115, 55.4111],
              [22.0193, 55.3964],
              [22.0174, 55.3866],
              [22.0051, 55.3711],
              [21.9897, 55.3609],
              [21.9853, 55.3503],
              [21.9548, 55.3345],
              [21.9537, 55.3274],
              [21.9401, 55.3231],
              [21.9214, 55.3065],
              [21.8938, 55.2856],
              [21.8851, 55.2761],
              [21.9122, 55.2663],
              [21.9371, 55.2616],
              [21.9633, 55.2619],
              [21.9879, 55.2491],
              [22.0028, 55.2473],
              [22.0328, 55.2367],
              [22.023, 55.2257],
              [22.0336, 55.2146],
              [22.0505, 55.1827],
              [22.0642, 55.1622],
              [22.0947, 55.1677],
              [22.1498, 55.166],
              [22.1603, 55.1703],
              [22.1852, 55.1524],
              [22.2153, 55.1558],
              [22.2567, 55.1478],
              [22.2843, 55.1488],
              [22.306, 55.1376],
              [22.2693, 55.1389],
              [22.2727, 55.1317],
              [22.2627, 55.1221],
              [22.2669, 55.1149],
              [22.2611, 55.1055],
              [22.2778, 55.0639],
              [22.2538, 55.0615],
              [22.2248, 55.0614],
              [22.1926, 55.0547],
              [22.1586, 55.0558],
              [22.1354, 55.045],
              [22.13, 55.0331],
              [22.1207, 55.0264],
              [22.0885, 55.0241],
              [22.0576, 55.0306],
              [22.0398, 55.041],
              [22.034, 55.0577],
              [22.0423, 55.0688],
              [22.0397, 55.0825],
              [22.0107, 55.0869],
              [21.9944, 55.0855],
              [21.9668, 55.0741],
              [21.922, 55.0796],
              [21.8819, 55.0927],
              [21.8598, 55.0947],
              [21.8454, 55.1003],
              [21.8294, 55.114],
              [21.8127, 55.1195],
              [21.7842, 55.1184],
              [21.7443, 55.1294],
              [21.7266, 55.1325],
              [21.7172, 55.1483],
              [21.6938, 55.1606],
              [21.6764, 55.1662],
              [21.6537, 55.18],
              [21.6155, 55.1869],
              [21.5994, 55.1871],
              [21.5774, 55.1967],
              [21.5467, 55.1963],
              [21.5251, 55.1862],
              [21.5133, 55.1847],
              [21.497, 55.1912],
              [21.4909, 55.2012],
              [21.4651, 55.2138],
              [21.4448, 55.2306],
              [21.4355, 55.2501],
              [21.4211, 55.2615],
              [21.4079, 55.2777],
              [21.3861, 55.294],
              [21.3759, 55.2869],
              [21.364, 55.2854],
              [21.341, 55.2768],
              [21.3298, 55.2685],
              [21.3111, 55.261],
              [21.3018, 55.2527],
              [21.278, 55.2454],
              [21.2802, 55.2568],
              [21.2758, 55.2611],
              [21.2947, 55.2763],
              [21.2928, 55.2866],
              [21.3005, 55.3004],
              [21.2789, 55.3259],
              [21.26, 55.3304],
              [21.2492, 55.3364],
              [21.2608, 55.3637],
              [21.2452, 55.3641],
              [21.2367, 55.3523],
              [21.2159, 55.348],
              [21.1893, 55.3451],
              [21.2024, 55.3563],
              [21.2187, 55.3763],
              [21.2289, 55.3855],
              [21.2322, 55.398],
              [21.2449, 55.4091],
              [21.2521, 55.4277],
              [21.2487, 55.435],
              [21.2534, 55.4663],
              [21.2506, 55.4866],
              [21.2373, 55.5086],
              [21.2348, 55.5175],
              [21.2181, 55.5249],
              [21.2105, 55.54],
              [21.2108, 55.5586],
              [21.2037, 55.5701],
              [21.1774, 55.596],
              [21.1628, 55.6317],
              [21.1545, 55.634],
              [21.148, 55.6481],
              [21.1536, 55.6509],
              [21.1415, 55.6836],
              [21.1303, 55.6984],
              [21.1089, 55.7201],
              [21.0822, 55.7298],
              [21.0855, 55.7342],
              [21.0802, 55.7608],
              [21.0729, 55.778],
              [21.0675, 55.8009],
              [21.0659, 55.8289],
              [21.0619, 55.8369],
              [21.0611, 55.8683],
              [21.056, 55.8793],
              [21.0506, 55.9196],
              [21.0588, 55.929],
              [21.0683, 55.9568],
              [21.0757, 56.0003],
              [21.0739, 56.0395],
              [21.0707, 56.0575],
              [21.0637, 56.069],
              [21.1444, 56.0803],
              [21.1583, 56.0855],
              [21.1682, 56.0833],
              [21.1801, 56.0747],
              [21.1917, 56.0747],
              [21.2046, 56.0799],
              [21.2123, 56.0955],
              [21.2223, 56.1028],
              [21.2245, 56.1118],
              [21.237, 56.1226],
              [21.2375, 56.1302],
              [21.2292, 56.1375],
              [21.2309, 56.1617],
              [21.2655, 56.167],
              [21.28, 56.1745],
              [21.289, 56.1879],
              [21.2991, 56.1916],
              [21.3218, 56.2131],
              [21.3557, 56.2234],
              [21.3774, 56.2264],
              [21.4046, 56.2342],
              [21.4167, 56.2401],
              [21.432, 56.2392],
              [21.4533, 56.2471],
              [21.4648, 56.2608],
              [21.4658, 56.2683],
              [21.4937, 56.2909],
              [21.5176, 56.288],
              [21.5477, 56.2954],
              [21.5559, 56.301],
              [21.5609, 56.3119],
              [21.5727, 56.3136],
              [21.5886, 56.3206],
              [21.6113, 56.3226],
              [21.6655, 56.3142],
              [21.6741, 56.3095],
              [21.7261, 56.3128],
              [21.7434, 56.3286],
              [21.7835, 56.3432],
              [21.8068, 56.3599],
              [21.8241, 56.3622],
              [21.8353, 56.3695],
              [21.8522, 56.3682],
              [21.8677, 56.3591],
              [21.9035, 56.3636],
              [21.9214, 56.3701],
              [21.946, 56.3733],
              [21.9563, 56.3796],
              [21.9731, 56.3757],
            ],
          ],
        ],
      },
      properties: {name: 'Klaipėda', id: 'LT-KL', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-KL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.5917, 55.069],
            [22.6237, 55.0591],
            [22.6405, 55.0507],
            [22.6563, 55.0477],
            [22.7155, 55.0573],
            [22.7458, 55.0588],
            [22.7751, 55.0735],
            [22.788, 55.0762],
            [22.8197, 55.0769],
            [22.8359, 55.0835],
            [22.8592, 55.0882],
            [22.8802, 55.0879],
            [22.904, 55.083],
            [22.9343, 55.082],
            [22.9469, 55.0839],
            [23.0134, 55.1001],
            [23.0557, 55.1044],
            [23.0686, 55.104],
            [23.1319, 55.0906],
            [23.1814, 55.0885],
            [23.2324, 55.0789],
            [23.2951, 55.0722],
            [23.3255, 55.0668],
            [23.3533, 55.0669],
            [23.3762, 55.073],
            [23.4124, 55.0728],
            [23.4292, 55.0709],
            [23.4652, 55.0535],
            [23.5007, 55.0448],
            [23.528, 55.0453],
            [23.5542, 55.0431],
            [23.5793, 55.0372],
            [23.5858, 55.0328],
            [23.5862, 55.0088],
            [23.5664, 55.0002],
            [23.531, 54.9947],
            [23.5247, 54.9881],
            [23.5122, 54.9846],
            [23.4531, 54.979],
            [23.4353, 54.9733],
            [23.4119, 54.9566],
            [23.4005, 54.9578],
            [23.3924, 54.9514],
            [23.4738, 54.9278],
            [23.4768, 54.902],
            [23.4823, 54.8892],
            [23.5009, 54.8877],
            [23.5406, 54.8965],
            [23.5456, 54.8924],
            [23.6281, 54.8585],
            [23.6063, 54.8397],
            [23.6186, 54.8318],
            [23.608, 54.8229],
            [23.6496, 54.7988],
            [23.6331, 54.7895],
            [23.6327, 54.7824],
            [23.6155, 54.7709],
            [23.6485, 54.7641],
            [23.6405, 54.7491],
            [23.6434, 54.7421],
            [23.6674, 54.7193],
            [23.639, 54.7056],
            [23.6605, 54.6881],
            [23.671, 54.6767],
            [23.6756, 54.6602],
            [23.6557, 54.6522],
            [23.6562, 54.6446],
            [23.6731, 54.6387],
            [23.6286, 54.629],
            [23.6314, 54.6226],
            [23.6447, 54.6238],
            [23.6442, 54.6159],
            [23.6731, 54.6174],
            [23.6985, 54.5928],
            [23.6901, 54.5888],
            [23.6649, 54.583],
            [23.683, 54.5578],
            [23.6895, 54.5583],
            [23.7024, 54.5404],
            [23.7249, 54.5435],
            [23.746, 54.549],
            [23.7524, 54.5402],
            [23.7548, 54.5245],
            [23.7708, 54.5122],
            [23.7891, 54.5022],
            [23.7848, 54.4891],
            [23.7542, 54.4654],
            [23.728, 54.4753],
            [23.7169, 54.4845],
            [23.685, 54.4765],
            [23.6729, 54.4886],
            [23.671, 54.5006],
            [23.662, 54.5089],
            [23.6458, 54.514],
            [23.6265, 54.5077],
            [23.6087, 54.4893],
            [23.5734, 54.4736],
            [23.5388, 54.4678],
            [23.5337, 54.4645],
            [23.515, 54.4641],
            [23.5152, 54.4112],
            [23.4904, 54.4129],
            [23.459, 54.3984],
            [23.4448, 54.3944],
            [23.4403, 54.3806],
            [23.4584, 54.3715],
            [23.4561, 54.3657],
            [23.4395, 54.3626],
            [23.405, 54.3653],
            [23.3978, 54.361],
            [23.3688, 54.3557],
            [23.3705, 54.3463],
            [23.3475, 54.3351],
            [23.3407, 54.3237],
            [23.3471, 54.3066],
            [23.314, 54.2964],
            [23.3355, 54.2741],
            [23.3282, 54.2646],
            [23.3236, 54.2514],
            [23.2886, 54.2555],
            [23.2817, 54.259],
            [23.2485, 54.2554],
            [23.2344, 54.2596],
            [23.2234, 54.2711],
            [23.2058, 54.282],
            [23.2028, 54.2876],
            [23.1767, 54.2897],
            [23.1543, 54.2961],
            [23.1596, 54.3089],
            [23.1398, 54.315],
            [23.0908, 54.2984],
            [23.042, 54.3157],
            [23.0508, 54.3328],
            [23.0633, 54.3407],
            [23.0494, 54.3482],
            [23.0111, 54.3548],
            [22.994, 54.3622],
            [23.0103, 54.3821],
            [22.9853, 54.384],
            [22.9836, 54.3893],
            [22.9505, 54.3917],
            [22.9327, 54.3957],
            [22.8887, 54.3991],
            [22.8881, 54.4078],
            [22.8559, 54.4095],
            [22.8358, 54.4052],
            [22.8112, 54.37],
            [22.7953, 54.3646],
            [22.7847, 54.3701],
            [22.7637, 54.3934],
            [22.7256, 54.4268],
            [22.7047, 54.4547],
            [22.7032, 54.4772],
            [22.7003, 54.4839],
            [22.702, 54.5062],
            [22.6853, 54.522],
            [22.6828, 54.5328],
            [22.6961, 54.5507],
            [22.7102, 54.5626],
            [22.6985, 54.576],
            [22.6863, 54.5805],
            [22.6865, 54.5866],
            [22.7028, 54.5943],
            [22.7093, 54.6022],
            [22.7217, 54.6066],
            [22.7227, 54.6164],
            [22.7289, 54.6228],
            [22.7461, 54.6265],
            [22.758, 54.6534],
            [22.7415, 54.659],
            [22.7329, 54.6697],
            [22.741, 54.673],
            [22.7388, 54.6808],
            [22.7289, 54.6837],
            [22.7318, 54.6974],
            [22.7481, 54.7071],
            [22.7415, 54.7169],
            [22.7458, 54.7321],
            [22.7657, 54.7371],
            [22.7743, 54.7474],
            [22.8074, 54.7553],
            [22.8157, 54.7666],
            [22.8463, 54.7676],
            [22.8603, 54.7796],
            [22.8515, 54.7856],
            [22.8793, 54.7902],
            [22.8739, 54.7977],
            [22.8812, 54.8118],
            [22.8822, 54.8272],
            [22.8783, 54.8348],
            [22.8624, 54.8381],
            [22.8613, 54.8433],
            [22.8725, 54.8524],
            [22.8631, 54.8581],
            [22.8432, 54.8636],
            [22.8457, 54.8759],
            [22.8565, 54.885],
            [22.8497, 54.8966],
            [22.8178, 54.9121],
            [22.8026, 54.9021],
            [22.7906, 54.9085],
            [22.7915, 54.9179],
            [22.7763, 54.9255],
            [22.757, 54.9184],
            [22.7525, 54.9253],
            [22.7679, 54.9323],
            [22.7648, 54.937],
            [22.7447, 54.9393],
            [22.7347, 54.9479],
            [22.7286, 54.9629],
            [22.7099, 54.9734],
            [22.6923, 54.972],
            [22.6896, 54.9808],
            [22.6813, 54.9874],
            [22.6648, 54.984],
            [22.6615, 54.9782],
            [22.6689, 54.9684],
            [22.6538, 54.9658],
            [22.6456, 54.9717],
            [22.6277, 55.0045],
            [22.616, 55.0133],
            [22.6047, 55.0166],
            [22.5993, 55.0255],
            [22.5977, 55.0459],
            [22.5917, 55.069],
          ],
        ],
      },
      properties: {name: 'Marijampolė', id: 'LT-MR', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-MR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.0462, 55.9546],
            [25.9959, 55.9495],
            [25.9719, 55.9318],
            [25.9646, 55.9306],
            [25.9625, 55.9183],
            [25.9826, 55.9026],
            [25.9686, 55.8893],
            [25.9702, 55.8775],
            [25.9529, 55.8747],
            [25.8998, 55.8696],
            [25.9074, 55.8339],
            [25.8899, 55.8285],
            [25.8639, 55.8274],
            [25.8437, 55.8319],
            [25.8447, 55.8218],
            [25.8335, 55.8029],
            [25.8395, 55.7963],
            [25.8246, 55.7732],
            [25.8256, 55.7692],
            [25.7927, 55.7722],
            [25.7713, 55.7802],
            [25.7354, 55.773],
            [25.7313, 55.7573],
            [25.7335, 55.7514],
            [25.729, 55.7385],
            [25.7152, 55.7389],
            [25.6955, 55.7247],
            [25.6834, 55.7222],
            [25.6777, 55.7428],
            [25.6689, 55.7444],
            [25.6474, 55.7332],
            [25.6247, 55.7377],
            [25.6139, 55.7345],
            [25.5948, 55.7433],
            [25.5826, 55.7226],
            [25.5641, 55.7278],
            [25.5433, 55.7303],
            [25.535, 55.7184],
            [25.5215, 55.7181],
            [25.4949, 55.7256],
            [25.5012, 55.7356],
            [25.4744, 55.7409],
            [25.4642, 55.7295],
            [25.4365, 55.7389],
            [25.4206, 55.7495],
            [25.386, 55.7632],
            [25.3626, 55.76],
            [25.3497, 55.7449],
            [25.3301, 55.7344],
            [25.3214, 55.7375],
            [25.2937, 55.7322],
            [25.2962, 55.7145],
            [25.3098, 55.7114],
            [25.3014, 55.699],
            [25.2858, 55.7039],
            [25.2687, 55.7173],
            [25.245, 55.7129],
            [25.1011, 55.6816],
            [25.087, 55.7021],
            [25.1018, 55.7144],
            [25.0894, 55.7156],
            [25.0661, 55.7123],
            [25.0512, 55.7211],
            [25.0267, 55.7226],
            [24.98, 55.7346],
            [24.9701, 55.7338],
            [24.9581, 55.7235],
            [24.9276, 55.716],
            [24.9213, 55.7203],
            [24.8912, 55.7217],
            [24.8727, 55.7186],
            [24.8722, 55.7041],
            [24.8489, 55.7052],
            [24.8471, 55.7132],
            [24.8312, 55.7219],
            [24.7898, 55.709],
            [24.7692, 55.7144],
            [24.7513, 55.7097],
            [24.7293, 55.7216],
            [24.7189, 55.7182],
            [24.7205, 55.6951],
            [24.7122, 55.6829],
            [24.7254, 55.6784],
            [24.7418, 55.6789],
            [24.7563, 55.6716],
            [24.7446, 55.6679],
            [24.7389, 55.6581],
            [24.7259, 55.6538],
            [24.7115, 55.6606],
            [24.673, 55.6664],
            [24.655, 55.6634],
            [24.6574, 55.6524],
            [24.6543, 55.6436],
            [24.664, 55.6405],
            [24.6819, 55.6429],
            [24.6959, 55.6499],
            [24.7134, 55.6419],
            [24.7246, 55.6282],
            [24.7265, 55.6087],
            [24.6772, 55.5993],
            [24.6662, 55.5817],
            [24.678, 55.5644],
            [24.6623, 55.5578],
            [24.6704, 55.5486],
            [24.6648, 55.538],
            [24.6512, 55.5295],
            [24.6528, 55.5189],
            [24.6247, 55.5093],
            [24.6375, 55.4963],
            [24.6379, 55.483],
            [24.6507, 55.4721],
            [24.6401, 55.4634],
            [24.6408, 55.4578],
            [24.5949, 55.4463],
            [24.5374, 55.4567],
            [24.5309, 55.4447],
            [24.5344, 55.4364],
            [24.5148, 55.431],
            [24.5226, 55.4175],
            [24.4898, 55.4069],
            [24.487, 55.3981],
            [24.4493, 55.3988],
            [24.4304, 55.3995],
            [24.4003, 55.4141],
            [24.4025, 55.4315],
            [24.3777, 55.4403],
            [24.3638, 55.4415],
            [24.3527, 55.4384],
            [24.3404, 55.4455],
            [24.314, 55.453],
            [24.3057, 55.4411],
            [24.2575, 55.4512],
            [24.2557, 55.4656],
            [24.2155, 55.4751],
            [24.21, 55.4521],
            [24.1716, 55.4532],
            [24.1575, 55.4425],
            [24.1437, 55.4389],
            [24.1342, 55.4442],
            [24.1307, 55.4523],
            [24.112, 55.4531],
            [24.1004, 55.4426],
            [24.0554, 55.4483],
            [24.0478, 55.4526],
            [24.0574, 55.4656],
            [24.0595, 55.477],
            [24.0411, 55.4782],
            [23.9966, 55.5077],
            [23.9828, 55.5124],
            [23.9591, 55.5107],
            [23.9581, 55.5044],
            [23.9345, 55.5133],
            [23.9082, 55.5306],
            [23.9018, 55.542],
            [23.9188, 55.5458],
            [23.8949, 55.5642],
            [23.9083, 55.5867],
            [23.9154, 55.588],
            [23.9288, 55.6098],
            [23.9261, 55.6327],
            [23.9774, 55.6226],
            [23.9879, 55.6335],
            [24.0253, 55.6322],
            [24.0461, 55.6398],
            [24.0367, 55.6518],
            [24.0288, 55.6528],
            [24.0296, 55.6689],
            [24.0223, 55.6766],
            [24.0266, 55.6873],
            [24.0385, 55.6828],
            [24.0487, 55.6922],
            [24.0708, 55.6949],
            [24.0751, 55.7012],
            [24.0664, 55.7078],
            [24.0497, 55.7109],
            [24.0392, 55.7165],
            [24.0423, 55.7286],
            [24.0531, 55.7478],
            [24.0439, 55.7466],
            [24.0384, 55.7565],
            [24.0207, 55.7613],
            [24.0352, 55.7699],
            [24.0224, 55.7869],
            [24.0156, 55.7898],
            [23.9882, 55.7849],
            [23.9827, 55.8002],
            [23.9731, 55.8049],
            [23.9633, 55.8319],
            [23.964, 55.8387],
            [23.9744, 55.8412],
            [24.0172, 55.8371],
            [24.0048, 55.8551],
            [24.0364, 55.8519],
            [24.0356, 55.8629],
            [24.0593, 55.8668],
            [24.0601, 55.8721],
            [24.087, 55.8746],
            [24.0787, 55.8883],
            [24.0839, 55.9018],
            [24.0978, 55.9147],
            [24.096, 55.9421],
            [24.0677, 55.9406],
            [24.0549, 55.9528],
            [24.0341, 55.9483],
            [24.0314, 55.9554],
            [24.0186, 55.9539],
            [24.0145, 55.9657],
            [24.0283, 55.9841],
            [24.0288, 55.9937],
            [24.0227, 55.999],
            [24.0431, 56.0126],
            [24.0376, 56.0199],
            [24.0415, 56.0347],
            [24.0701, 56.0293],
            [24.078, 56.0603],
            [24.0742, 56.0689],
            [24.0923, 56.0712],
            [24.1175, 56.0924],
            [24.117, 56.1073],
            [24.1259, 56.1089],
            [24.1317, 56.1217],
            [24.1438, 56.1181],
            [24.1685, 56.1232],
            [24.1744, 56.1315],
            [24.181, 56.1582],
            [24.1806, 56.1707],
            [24.1568, 56.1667],
            [24.1605, 56.1831],
            [24.171, 56.1903],
            [24.1336, 56.202],
            [24.1274, 56.2218],
            [24.1208, 56.23],
            [24.1265, 56.2409],
            [24.1524, 56.245],
            [24.1541, 56.253],
            [24.1764, 56.2517],
            [24.2044, 56.2676],
            [24.2392, 56.2775],
            [24.2569, 56.2763],
            [24.276, 56.2842],
            [24.289, 56.2858],
            [24.286, 56.2979],
            [24.3196, 56.2982],
            [24.3426, 56.2941],
            [24.3461, 56.2839],
            [24.3567, 56.2724],
            [24.38, 56.278],
            [24.3922, 56.2776],
            [24.4221, 56.261],
            [24.4538, 56.2576],
            [24.4649, 56.2675],
            [24.5069, 56.2767],
            [24.5224, 56.2823],
            [24.5439, 56.2839],
            [24.5646, 56.2885],
            [24.5801, 56.2881],
            [24.5842, 56.3049],
            [24.5768, 56.3147],
            [24.5786, 56.3221],
            [24.5882, 56.3306],
            [24.6092, 56.3354],
            [24.63, 56.35],
            [24.6272, 56.3608],
            [24.633, 56.3649],
            [24.6316, 56.3746],
            [24.6488, 56.3766],
            [24.6589, 56.3738],
            [24.6952, 56.3744],
            [24.7106, 56.3861],
            [24.7052, 56.3956],
            [24.7267, 56.3995],
            [24.7361, 56.3979],
            [24.7649, 56.4013],
            [24.7764, 56.3972],
            [24.8039, 56.4106],
            [24.8115, 56.4194],
            [24.8226, 56.4164],
            [24.8249, 56.4096],
            [24.8369, 56.408],
            [24.8657, 56.4141],
            [24.8598, 56.424],
            [24.8599, 56.4334],
            [24.8781, 56.4466],
            [24.9079, 56.4447],
            [24.9211, 56.4338],
            [24.9084, 56.4211],
            [24.9131, 56.4113],
            [24.9347, 56.3912],
            [24.945, 56.3734],
            [24.9727, 56.362],
            [24.9687, 56.3427],
            [24.9728, 56.3317],
            [24.9692, 56.3258],
            [24.978, 56.3099],
            [24.9933, 56.3052],
            [24.9985, 56.298],
            [25.0165, 56.2887],
            [25.021, 56.2755],
            [25.0451, 56.2656],
            [25.057, 56.2638],
            [25.0611, 56.2394],
            [25.0695, 56.2341],
            [25.0648, 56.2284],
            [25.0754, 56.2171],
            [25.0718, 56.2092],
            [25.0809, 56.1925],
            [25.0911, 56.195],
            [25.0969, 56.1866],
            [25.1268, 56.1829],
            [25.1459, 56.1782],
            [25.1543, 56.173],
            [25.2022, 56.173],
            [25.2209, 56.1857],
            [25.2353, 56.1903],
            [25.2527, 56.1824],
            [25.2591, 56.1733],
            [25.2732, 56.1684],
            [25.2823, 56.1566],
            [25.3183, 56.1428],
            [25.3394, 56.152],
            [25.3711, 56.1522],
            [25.4069, 56.156],
            [25.4066, 56.1465],
            [25.4254, 56.1438],
            [25.4371, 56.1528],
            [25.4569, 56.1519],
            [25.4579, 56.1464],
            [25.4847, 56.1414],
            [25.5069, 56.1424],
            [25.5153, 56.1584],
            [25.5372, 56.1642],
            [25.5556, 56.1604],
            [25.5694, 56.1386],
            [25.6, 56.1329],
            [25.6551, 56.1335],
            [25.6749, 56.1474],
            [25.6885, 56.143],
            [25.6831, 56.1339],
            [25.6878, 56.1252],
            [25.7035, 56.1182],
            [25.697, 56.1028],
            [25.7041, 56.0991],
            [25.6905, 56.0916],
            [25.693, 56.0847],
            [25.7201, 56.0793],
            [25.7517, 56.0706],
            [25.7657, 56.0617],
            [25.8, 56.0581],
            [25.8239, 56.0536],
            [25.8284, 56.0426],
            [25.8412, 56.0283],
            [25.8524, 56.0277],
            [25.8617, 56.0134],
            [25.8555, 56.0066],
            [25.8639, 55.9989],
            [25.8968, 56.0007],
            [25.9066, 55.9954],
            [25.945, 55.9874],
            [25.978, 55.9789],
            [25.9988, 55.964],
            [26.015, 55.9653],
            [26.0462, 55.9546],
          ],
        ],
      },
      properties: {name: 'Panevėžys', id: 'LT-PN', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-PN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.1541, 56.253],
            [24.1524, 56.245],
            [24.1265, 56.2409],
            [24.1208, 56.23],
            [24.1274, 56.2218],
            [24.1336, 56.202],
            [24.171, 56.1903],
            [24.1605, 56.1831],
            [24.1568, 56.1667],
            [24.1806, 56.1707],
            [24.181, 56.1582],
            [24.1744, 56.1315],
            [24.1685, 56.1232],
            [24.1438, 56.1181],
            [24.1317, 56.1217],
            [24.1259, 56.1089],
            [24.117, 56.1073],
            [24.1175, 56.0924],
            [24.0923, 56.0712],
            [24.0742, 56.0689],
            [24.078, 56.0603],
            [24.0701, 56.0293],
            [24.0415, 56.0347],
            [24.0376, 56.0199],
            [24.0431, 56.0126],
            [24.0227, 55.999],
            [24.0288, 55.9937],
            [24.0283, 55.9841],
            [24.0145, 55.9657],
            [24.0186, 55.9539],
            [24.0314, 55.9554],
            [24.0341, 55.9483],
            [24.0549, 55.9528],
            [24.0677, 55.9406],
            [24.096, 55.9421],
            [24.0978, 55.9147],
            [24.0839, 55.9018],
            [24.0787, 55.8883],
            [24.087, 55.8746],
            [24.0601, 55.8721],
            [24.0593, 55.8668],
            [24.0356, 55.8629],
            [24.0364, 55.8519],
            [24.0048, 55.8551],
            [24.0172, 55.8371],
            [23.9744, 55.8412],
            [23.964, 55.8387],
            [23.9633, 55.8319],
            [23.9731, 55.8049],
            [23.9827, 55.8002],
            [23.9882, 55.7849],
            [24.0156, 55.7898],
            [24.0224, 55.7869],
            [24.0352, 55.7699],
            [24.0207, 55.7613],
            [24.0384, 55.7565],
            [24.0439, 55.7466],
            [24.0531, 55.7478],
            [24.0423, 55.7286],
            [24.0392, 55.7165],
            [24.0497, 55.7109],
            [24.0664, 55.7078],
            [24.0751, 55.7012],
            [24.0708, 55.6949],
            [24.0487, 55.6922],
            [24.0385, 55.6828],
            [24.0266, 55.6873],
            [24.0223, 55.6766],
            [24.0296, 55.6689],
            [24.0288, 55.6528],
            [24.0367, 55.6518],
            [24.0461, 55.6398],
            [24.0253, 55.6322],
            [23.9879, 55.6335],
            [23.9774, 55.6226],
            [23.9261, 55.6327],
            [23.9288, 55.6098],
            [23.9154, 55.588],
            [23.9083, 55.5867],
            [23.8949, 55.5642],
            [23.861, 55.5494],
            [23.8507, 55.552],
            [23.821, 55.5454],
            [23.8223, 55.5418],
            [23.7384, 55.5221],
            [23.7396, 55.5151],
            [23.6976, 55.5082],
            [23.6851, 55.4942],
            [23.6572, 55.4845],
            [23.6654, 55.4811],
            [23.6602, 55.4717],
            [23.6362, 55.4709],
            [23.6181, 55.4755],
            [23.5978, 55.4734],
            [23.5677, 55.4736],
            [23.562, 55.4796],
            [23.5609, 55.4933],
            [23.5694, 55.5073],
            [23.5586, 55.5135],
            [23.5464, 55.5145],
            [23.5362, 55.5226],
            [23.5053, 55.5152],
            [23.4761, 55.5141],
            [23.483, 55.505],
            [23.4845, 55.4948],
            [23.467, 55.4854],
            [23.4565, 55.4952],
            [23.4176, 55.5054],
            [23.4017, 55.5048],
            [23.4016, 55.5154],
            [23.3804, 55.5316],
            [23.3605, 55.5408],
            [23.3428, 55.5423],
            [23.331, 55.5476],
            [23.2862, 55.5326],
            [23.2766, 55.5328],
            [23.2573, 55.524],
            [23.2555, 55.5302],
            [23.2416, 55.5298],
            [23.2338, 55.5439],
            [23.2301, 55.5693],
            [23.2159, 55.5626],
            [23.189, 55.5555],
            [23.1268, 55.5513],
            [23.1228, 55.5655],
            [23.0956, 55.5623],
            [23.0961, 55.5366],
            [23.0741, 55.5354],
            [23.0799, 55.5144],
            [23.0893, 55.5049],
            [23.077, 55.4996],
            [23.0645, 55.5037],
            [23.0494, 55.4991],
            [23.0407, 55.5071],
            [23.0313, 55.4988],
            [23.0051, 55.4873],
            [22.9842, 55.4889],
            [22.9671, 55.4836],
            [22.9337, 55.4824],
            [22.9197, 55.4769],
            [22.8961, 55.4613],
            [22.8544, 55.4668],
            [22.8486, 55.4571],
            [22.8368, 55.462],
            [22.8288, 55.4706],
            [22.8494, 55.4788],
            [22.8433, 55.5017],
            [22.8212, 55.5019],
            [22.8008, 55.4996],
            [22.774, 55.5001],
            [22.7584, 55.5104],
            [22.7153, 55.4804],
            [22.703, 55.4855],
            [22.6801, 55.4744],
            [22.6255, 55.4797],
            [22.6258, 55.4833],
            [22.6466, 55.4898],
            [22.6517, 55.4955],
            [22.6455, 55.5026],
            [22.6291, 55.5112],
            [22.6267, 55.5164],
            [22.6098, 55.5208],
            [22.5968, 55.5357],
            [22.5823, 55.5444],
            [22.5863, 55.5603],
            [22.5576, 55.5583],
            [22.5364, 55.5852],
            [22.5067, 55.5895],
            [22.5033, 55.5954],
            [22.5086, 55.6112],
            [22.5195, 55.6218],
            [22.5094, 55.6239],
            [22.5016, 55.6316],
            [22.4815, 55.6357],
            [22.4875, 55.659],
            [22.4752, 55.6742],
            [22.4813, 55.6833],
            [22.4946, 55.6889],
            [22.5181, 55.688],
            [22.5314, 55.6943],
            [22.5342, 55.7075],
            [22.5295, 55.7252],
            [22.5304, 55.7351],
            [22.5189, 55.738],
            [22.5246, 55.7464],
            [22.5186, 55.7509],
            [22.482, 55.7935],
            [22.496, 55.7941],
            [22.5502, 55.8041],
            [22.5783, 55.8067],
            [22.5808, 55.8463],
            [22.5668, 55.8563],
            [22.579, 55.8633],
            [22.6335, 55.8596],
            [22.6621, 55.8722],
            [22.685, 55.8658],
            [22.6885, 55.8725],
            [22.6787, 55.8827],
            [22.6957, 55.8852],
            [22.7061, 55.8829],
            [22.7034, 55.8988],
            [22.6942, 55.9175],
            [22.717, 55.9209],
            [22.7085, 55.9322],
            [22.6899, 55.9311],
            [22.6836, 55.9396],
            [22.6691, 55.9711],
            [22.6474, 55.9753],
            [22.6579, 55.9499],
            [22.6482, 55.9478],
            [22.6389, 55.9535],
            [22.6204, 55.9505],
            [22.6039, 55.9536],
            [22.6069, 55.9672],
            [22.5981, 55.9691],
            [22.5868, 55.9819],
            [22.6007, 55.9912],
            [22.613, 55.9921],
            [22.619, 56.0119],
            [22.6275, 56.0185],
            [22.624, 56.0238],
            [22.6345, 56.0341],
            [22.6258, 56.0361],
            [22.6229, 56.0481],
            [22.6416, 56.0388],
            [22.6439, 56.0454],
            [22.6694, 56.0555],
            [22.6835, 56.0552],
            [22.6973, 56.0506],
            [22.7463, 56.0621],
            [22.7478, 56.076],
            [22.7232, 56.0836],
            [22.7164, 56.0887],
            [22.7165, 56.1091],
            [22.7252, 56.1152],
            [22.7068, 56.1237],
            [22.6848, 56.1196],
            [22.6552, 56.1112],
            [22.6369, 56.1127],
            [22.6352, 56.0983],
            [22.6232, 56.1001],
            [22.6171, 56.0917],
            [22.5883, 56.0946],
            [22.5613, 56.1078],
            [22.5417, 56.1113],
            [22.5159, 56.1116],
            [22.5117, 56.1025],
            [22.4747, 56.1039],
            [22.4732, 56.1083],
            [22.4294, 56.1094],
            [22.4404, 56.1189],
            [22.4226, 56.125],
            [22.4339, 56.1344],
            [22.4383, 56.1476],
            [22.4252, 56.1553],
            [22.3993, 56.156],
            [22.4117, 56.2008],
            [22.4271, 56.2249],
            [22.4322, 56.2472],
            [22.4711, 56.2473],
            [22.49, 56.254],
            [22.5015, 56.2867],
            [22.4816, 56.2852],
            [22.4799, 56.2947],
            [22.4727, 56.3021],
            [22.4894, 56.3033],
            [22.5279, 56.298],
            [22.5359, 56.3007],
            [22.5386, 56.3098],
            [22.6013, 56.3031],
            [22.625, 56.3336],
            [22.5981, 56.3487],
            [22.5906, 56.36],
            [22.6424, 56.359],
            [22.6441, 56.3629],
            [22.6601, 56.3612],
            [22.6806, 56.3503],
            [22.6976, 56.3523],
            [22.7135, 56.3639],
            [22.7279, 56.3702],
            [22.7494, 56.3712],
            [22.7667, 56.3676],
            [22.7913, 56.3688],
            [22.8421, 56.3653],
            [22.8714, 56.373],
            [22.8995, 56.3737],
            [22.9196, 56.3785],
            [22.9342, 56.3879],
            [22.9323, 56.3932],
            [22.9431, 56.4136],
            [22.9671, 56.413],
            [22.9781, 56.4046],
            [22.9972, 56.4057],
            [23.0039, 56.3992],
            [23.0168, 56.3717],
            [23.0033, 56.368],
            [23.0147, 56.3444],
            [23.0236, 56.3393],
            [23.0251, 56.3288],
            [23.044, 56.3194],
            [23.0678, 56.3234],
            [23.0831, 56.3172],
            [23.0927, 56.3042],
            [23.1123, 56.3074],
            [23.1168, 56.3147],
            [23.1432, 56.3254],
            [23.1729, 56.3327],
            [23.182, 56.3372],
            [23.172, 56.3534],
            [23.1788, 56.359],
            [23.1796, 56.3684],
            [23.2686, 56.3708],
            [23.2806, 56.37],
            [23.3118, 56.3819],
            [23.3862, 56.3774],
            [23.3974, 56.3718],
            [23.4175, 56.3687],
            [23.4352, 56.3523],
            [23.4559, 56.3502],
            [23.4928, 56.3438],
            [23.5108, 56.3348],
            [23.5333, 56.334],
            [23.5452, 56.3311],
            [23.5713, 56.3381],
            [23.5864, 56.3455],
            [23.6137, 56.352],
            [23.633, 56.36],
            [23.658, 56.3638],
            [23.7009, 56.3655],
            [23.7238, 56.3696],
            [23.7404, 56.3687],
            [23.7648, 56.3724],
            [23.7782, 56.3667],
            [23.7609, 56.3335],
            [23.7644, 56.3218],
            [23.7798, 56.3192],
            [23.7966, 56.3383],
            [23.8161, 56.3406],
            [23.8274, 56.3376],
            [23.9124, 56.3384],
            [23.922, 56.3295],
            [23.9627, 56.3275],
            [24.0144, 56.3291],
            [24.0217, 56.3095],
            [24.0476, 56.297],
            [24.0486, 56.279],
            [24.0968, 56.2767],
            [24.1006, 56.2673],
            [24.1162, 56.261],
            [24.1122, 56.2513],
            [24.1214, 56.248],
            [24.1541, 56.253],
          ],
        ],
      },
      properties: {name: 'Šiauliai', id: 'LT-SA', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-SA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.8794, 55.5933],
            [21.9372, 55.6002],
            [21.9679, 55.6072],
            [21.9792, 55.607],
            [21.9905, 55.6121],
            [21.9983, 55.6037],
            [22.0271, 55.6037],
            [22.0258, 55.61],
            [22.0493, 55.6176],
            [22.048, 55.6293],
            [22.0659, 55.6354],
            [22.0703, 55.6485],
            [22.0869, 55.6558],
            [22.1149, 55.6477],
            [22.117, 55.6553],
            [22.1443, 55.6638],
            [22.1444, 55.6666],
            [22.1823, 55.6741],
            [22.2215, 55.6796],
            [22.2291, 55.6861],
            [22.2505, 55.6902],
            [22.2997, 55.6698],
            [22.3096, 55.6746],
            [22.3361, 55.6758],
            [22.3686, 55.66],
            [22.3786, 55.659],
            [22.3916, 55.6657],
            [22.4212, 55.672],
            [22.4464, 55.6643],
            [22.459, 55.6709],
            [22.4752, 55.6742],
            [22.4875, 55.659],
            [22.4815, 55.6357],
            [22.5016, 55.6316],
            [22.5094, 55.6239],
            [22.5195, 55.6218],
            [22.5086, 55.6112],
            [22.5033, 55.5954],
            [22.5067, 55.5895],
            [22.5364, 55.5852],
            [22.5576, 55.5583],
            [22.5863, 55.5603],
            [22.5823, 55.5444],
            [22.5968, 55.5357],
            [22.6098, 55.5208],
            [22.6267, 55.5164],
            [22.6291, 55.5112],
            [22.6455, 55.5026],
            [22.6517, 55.4955],
            [22.6466, 55.4898],
            [22.6258, 55.4833],
            [22.6255, 55.4797],
            [22.6801, 55.4744],
            [22.6954, 55.4687],
            [22.6936, 55.4621],
            [22.6722, 55.4542],
            [22.6833, 55.4494],
            [22.6894, 55.4285],
            [22.6982, 55.4171],
            [22.7219, 55.4198],
            [22.7277, 55.4259],
            [22.741, 55.4253],
            [22.7426, 55.4083],
            [22.7308, 55.4027],
            [22.7348, 55.3897],
            [22.7517, 55.3907],
            [22.7599, 55.3794],
            [22.7952, 55.3847],
            [22.8134, 55.3677],
            [22.8111, 55.3554],
            [22.8149, 55.349],
            [22.8026, 55.3451],
            [22.795, 55.3257],
            [22.8151, 55.3204],
            [22.8335, 55.3251],
            [22.8493, 55.3251],
            [22.8498, 55.3137],
            [22.8617, 55.3119],
            [22.8839, 55.2988],
            [22.9009, 55.2979],
            [22.9002, 55.3077],
            [22.9238, 55.3124],
            [22.9321, 55.3206],
            [22.9433, 55.3216],
            [22.9494, 55.313],
            [22.9884, 55.3195],
            [22.9785, 55.2929],
            [23.0077, 55.2878],
            [23.0272, 55.2785],
            [23.0041, 55.2606],
            [23.0081, 55.2552],
            [23.0637, 55.2551],
            [23.0784, 55.2645],
            [23.0946, 55.267],
            [23.1098, 55.2604],
            [23.1512, 55.2634],
            [23.1619, 55.2715],
            [23.184, 55.2685],
            [23.1923, 55.2727],
            [23.1979, 55.2492],
            [23.191, 55.2379],
            [23.2424, 55.2384],
            [23.2461, 55.2415],
            [23.2867, 55.2351],
            [23.3077, 55.2358],
            [23.334, 55.2428],
            [23.37, 55.2448],
            [23.3762, 55.2433],
            [23.3809, 55.2305],
            [23.3774, 55.2202],
            [23.397, 55.2137],
            [23.4138, 55.2177],
            [23.4245, 55.2138],
            [23.4558, 55.2195],
            [23.4581, 55.2133],
            [23.5084, 55.2047],
            [23.4805, 55.1613],
            [23.4834, 55.1451],
            [23.4801, 55.1245],
            [23.4701, 55.1123],
            [23.4635, 55.1115],
            [23.4319, 55.0831],
            [23.4124, 55.0728],
            [23.3762, 55.073],
            [23.3533, 55.0669],
            [23.3255, 55.0668],
            [23.2951, 55.0722],
            [23.2324, 55.0789],
            [23.1814, 55.0885],
            [23.1319, 55.0906],
            [23.0686, 55.104],
            [23.0557, 55.1044],
            [23.0134, 55.1001],
            [22.9469, 55.0839],
            [22.9343, 55.082],
            [22.904, 55.083],
            [22.8802, 55.0879],
            [22.8592, 55.0882],
            [22.8359, 55.0835],
            [22.8197, 55.0769],
            [22.788, 55.0762],
            [22.7751, 55.0735],
            [22.7458, 55.0588],
            [22.7155, 55.0573],
            [22.6563, 55.0477],
            [22.6405, 55.0507],
            [22.6237, 55.0591],
            [22.5917, 55.069],
            [22.5773, 55.0695],
            [22.5416, 55.0633],
            [22.5046, 55.0509],
            [22.4764, 55.0444],
            [22.4615, 55.0445],
            [22.4443, 55.0502],
            [22.4226, 55.0538],
            [22.386, 55.0544],
            [22.3497, 55.0616],
            [22.3319, 55.0614],
            [22.2994, 55.0646],
            [22.2778, 55.0639],
            [22.2611, 55.1055],
            [22.2669, 55.1149],
            [22.2627, 55.1221],
            [22.2727, 55.1317],
            [22.2693, 55.1389],
            [22.306, 55.1376],
            [22.2843, 55.1488],
            [22.2567, 55.1478],
            [22.2153, 55.1558],
            [22.1852, 55.1524],
            [22.1603, 55.1703],
            [22.1498, 55.166],
            [22.0947, 55.1677],
            [22.0642, 55.1622],
            [22.0505, 55.1827],
            [22.0336, 55.2146],
            [22.023, 55.2257],
            [22.0328, 55.2367],
            [22.0028, 55.2473],
            [21.9879, 55.2491],
            [21.9633, 55.2619],
            [21.9371, 55.2616],
            [21.9122, 55.2663],
            [21.8851, 55.2761],
            [21.8938, 55.2856],
            [21.9214, 55.3065],
            [21.9401, 55.3231],
            [21.9537, 55.3274],
            [21.9548, 55.3345],
            [21.9853, 55.3503],
            [21.9897, 55.3609],
            [22.0051, 55.3711],
            [22.0174, 55.3866],
            [22.0193, 55.3964],
            [22.0115, 55.4111],
            [22.0034, 55.411],
            [21.9963, 55.4227],
            [21.963, 55.4192],
            [21.9278, 55.4323],
            [21.9177, 55.4345],
            [21.8727, 55.4205],
            [21.8679, 55.4221],
            [21.863, 55.4434],
            [21.8631, 55.464],
            [21.8475, 55.497],
            [21.8344, 55.4937],
            [21.8243, 55.5156],
            [21.8191, 55.5367],
            [21.8313, 55.5403],
            [21.8312, 55.5498],
            [21.8135, 55.5447],
            [21.8138, 55.5525],
            [21.831, 55.5646],
            [21.8388, 55.5639],
            [21.8749, 55.5774],
            [21.8665, 55.583],
            [21.8794, 55.5933],
          ],
        ],
      },
      properties: {name: 'Taurage', id: 'LT-TA', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-TA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.6441, 56.3629],
            [22.6424, 56.359],
            [22.5906, 56.36],
            [22.5981, 56.3487],
            [22.625, 56.3336],
            [22.6013, 56.3031],
            [22.5386, 56.3098],
            [22.5359, 56.3007],
            [22.5279, 56.298],
            [22.4894, 56.3033],
            [22.4727, 56.3021],
            [22.4799, 56.2947],
            [22.4816, 56.2852],
            [22.5015, 56.2867],
            [22.49, 56.254],
            [22.4711, 56.2473],
            [22.4322, 56.2472],
            [22.4271, 56.2249],
            [22.4117, 56.2008],
            [22.3993, 56.156],
            [22.4252, 56.1553],
            [22.4383, 56.1476],
            [22.4339, 56.1344],
            [22.4226, 56.125],
            [22.4404, 56.1189],
            [22.4294, 56.1094],
            [22.4732, 56.1083],
            [22.4747, 56.1039],
            [22.5117, 56.1025],
            [22.5159, 56.1116],
            [22.5417, 56.1113],
            [22.5613, 56.1078],
            [22.5883, 56.0946],
            [22.6171, 56.0917],
            [22.6232, 56.1001],
            [22.6352, 56.0983],
            [22.6369, 56.1127],
            [22.6552, 56.1112],
            [22.6848, 56.1196],
            [22.7068, 56.1237],
            [22.7252, 56.1152],
            [22.7165, 56.1091],
            [22.7164, 56.0887],
            [22.7232, 56.0836],
            [22.7478, 56.076],
            [22.7463, 56.0621],
            [22.6973, 56.0506],
            [22.6835, 56.0552],
            [22.6694, 56.0555],
            [22.6439, 56.0454],
            [22.6416, 56.0388],
            [22.6229, 56.0481],
            [22.6258, 56.0361],
            [22.6345, 56.0341],
            [22.624, 56.0238],
            [22.6275, 56.0185],
            [22.619, 56.0119],
            [22.613, 55.9921],
            [22.6007, 55.9912],
            [22.5868, 55.9819],
            [22.5981, 55.9691],
            [22.6069, 55.9672],
            [22.6039, 55.9536],
            [22.6204, 55.9505],
            [22.6389, 55.9535],
            [22.6482, 55.9478],
            [22.6579, 55.9499],
            [22.6474, 55.9753],
            [22.6691, 55.9711],
            [22.6836, 55.9396],
            [22.6899, 55.9311],
            [22.7085, 55.9322],
            [22.717, 55.9209],
            [22.6942, 55.9175],
            [22.7034, 55.8988],
            [22.7061, 55.8829],
            [22.6957, 55.8852],
            [22.6787, 55.8827],
            [22.6885, 55.8725],
            [22.685, 55.8658],
            [22.6621, 55.8722],
            [22.6335, 55.8596],
            [22.579, 55.8633],
            [22.5668, 55.8563],
            [22.5808, 55.8463],
            [22.5783, 55.8067],
            [22.5502, 55.8041],
            [22.496, 55.7941],
            [22.482, 55.7935],
            [22.5186, 55.7509],
            [22.5246, 55.7464],
            [22.5189, 55.738],
            [22.5304, 55.7351],
            [22.5295, 55.7252],
            [22.5342, 55.7075],
            [22.5314, 55.6943],
            [22.5181, 55.688],
            [22.4946, 55.6889],
            [22.4813, 55.6833],
            [22.4752, 55.6742],
            [22.459, 55.6709],
            [22.4464, 55.6643],
            [22.4212, 55.672],
            [22.3916, 55.6657],
            [22.3786, 55.659],
            [22.3686, 55.66],
            [22.3361, 55.6758],
            [22.3096, 55.6746],
            [22.2997, 55.6698],
            [22.2505, 55.6902],
            [22.2291, 55.6861],
            [22.2215, 55.6796],
            [22.1823, 55.6741],
            [22.1444, 55.6666],
            [22.1443, 55.6638],
            [22.117, 55.6553],
            [22.1149, 55.6477],
            [22.0869, 55.6558],
            [22.0703, 55.6485],
            [22.0659, 55.6354],
            [22.048, 55.6293],
            [22.0493, 55.6176],
            [22.0258, 55.61],
            [22.0271, 55.6037],
            [21.9983, 55.6037],
            [21.9905, 55.6121],
            [21.9792, 55.607],
            [21.9679, 55.6072],
            [21.9372, 55.6002],
            [21.8794, 55.5933],
            [21.8388, 55.61],
            [21.8313, 55.6159],
            [21.8158, 55.6113],
            [21.7929, 55.6088],
            [21.7903, 55.6329],
            [21.7788, 55.6339],
            [21.7657, 55.6432],
            [21.7776, 55.6494],
            [21.7732, 55.6661],
            [21.7851, 55.6742],
            [21.7867, 55.6866],
            [21.805, 55.6961],
            [21.781, 55.6964],
            [21.7716, 55.7046],
            [21.7554, 55.7046],
            [21.7419, 55.7127],
            [21.7472, 55.7259],
            [21.757, 55.7301],
            [21.7525, 55.7371],
            [21.7561, 55.7569],
            [21.7375, 55.7634],
            [21.7297, 55.7528],
            [21.7034, 55.7507],
            [21.6547, 55.7646],
            [21.6383, 55.7526],
            [21.6177, 55.7557],
            [21.6197, 55.7756],
            [21.6137, 55.7793],
            [21.6184, 55.7898],
            [21.6067, 55.7933],
            [21.6047, 55.7997],
            [21.5893, 55.8045],
            [21.5779, 55.8194],
            [21.566, 55.819],
            [21.5682, 55.8057],
            [21.5596, 55.8059],
            [21.5483, 55.7983],
            [21.5289, 55.8026],
            [21.5256, 55.8066],
            [21.5012, 55.815],
            [21.5192, 55.8414],
            [21.5322, 55.8395],
            [21.5256, 55.8531],
            [21.5325, 55.8572],
            [21.547, 55.8552],
            [21.5476, 55.865],
            [21.5648, 55.8701],
            [21.5787, 55.8688],
            [21.5824, 55.876],
            [21.5763, 55.8823],
            [21.5897, 55.8892],
            [21.6056, 55.8901],
            [21.6167, 55.8962],
            [21.607, 55.9038],
            [21.5824, 55.914],
            [21.5703, 55.9156],
            [21.5634, 55.9236],
            [21.5812, 55.9239],
            [21.5801, 55.9476],
            [21.5724, 55.9567],
            [21.5976, 55.9884],
            [21.5623, 55.997],
            [21.5742, 56.013],
            [21.6047, 56.0134],
            [21.6193, 56.0053],
            [21.6344, 56.0085],
            [21.6498, 56.0065],
            [21.6761, 56.0416],
            [21.6842, 56.0424],
            [21.7052, 56.0738],
            [21.7087, 56.0819],
            [21.7317, 56.0791],
            [21.7528, 56.11],
            [21.7788, 56.1115],
            [21.8449, 56.0973],
            [21.837, 56.1207],
            [21.8268, 56.1257],
            [21.8333, 56.1333],
            [21.8492, 56.1321],
            [21.8484, 56.1261],
            [21.9024, 56.1215],
            [21.9106, 56.113],
            [21.9256, 56.1194],
            [21.9218, 56.1239],
            [21.9363, 56.1329],
            [21.9438, 56.1633],
            [21.9451, 56.1772],
            [21.9582, 56.1776],
            [21.9645, 56.201],
            [22.0123, 56.1962],
            [22.0136, 56.211],
            [21.988, 56.2256],
            [21.9969, 56.2315],
            [21.9909, 56.2485],
            [21.9814, 56.2513],
            [21.9756, 56.267],
            [21.968, 56.2679],
            [21.9758, 56.3155],
            [21.93, 56.318],
            [21.9343, 56.3514],
            [21.9628, 56.3506],
            [21.9691, 56.359],
            [21.9731, 56.3757],
            [21.9814, 56.3837],
            [21.979, 56.3916],
            [21.9938, 56.4062],
            [21.9946, 56.4127],
            [22.0133, 56.4139],
            [22.0156, 56.4227],
            [22.0309, 56.4237],
            [22.0616, 56.418],
            [22.0799, 56.4216],
            [22.0893, 56.427],
            [22.1136, 56.4272],
            [22.126, 56.4312],
            [22.1698, 56.4189],
            [22.2027, 56.4234],
            [22.215, 56.4109],
            [22.233, 56.4082],
            [22.2431, 56.4012],
            [22.3132, 56.4074],
            [22.3205, 56.4002],
            [22.3338, 56.4003],
            [22.3501, 56.3933],
            [22.3662, 56.401],
            [22.3863, 56.3994],
            [22.4035, 56.4063],
            [22.4279, 56.4089],
            [22.4483, 56.404],
            [22.478, 56.4037],
            [22.4917, 56.3959],
            [22.5375, 56.3881],
            [22.5563, 56.3863],
            [22.5813, 56.3891],
            [22.5886, 56.3829],
            [22.6149, 56.3815],
            [22.6378, 56.3635],
            [22.6441, 56.3629],
          ],
        ],
      },
      properties: {name: 'Telšiai', id: 'LT-TE', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-TE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.7448, 55.2522],
            [26.7418, 55.2554],
            [26.7614, 55.2626],
            [26.6925, 55.2597],
            [26.6867, 55.2622],
            [26.6166, 55.2513],
            [26.6173, 55.2366],
            [26.6021, 55.2242],
            [26.5699, 55.2242],
            [26.5548, 55.2165],
            [26.5404, 55.2225],
            [26.5246, 55.2196],
            [26.5185, 55.2116],
            [26.4944, 55.2142],
            [26.4856, 55.211],
            [26.4587, 55.2182],
            [26.4471, 55.2106],
            [26.4064, 55.2171],
            [26.3762, 55.2243],
            [26.3653, 55.2201],
            [26.3293, 55.2235],
            [26.3071, 55.2283],
            [26.2905, 55.2244],
            [26.2769, 55.2294],
            [26.2666, 55.2193],
            [26.2509, 55.2253],
            [26.2421, 55.239],
            [26.2317, 55.2449],
            [26.2206, 55.2396],
            [26.2013, 55.2373],
            [26.1898, 55.2231],
            [26.1537, 55.2255],
            [26.1526, 55.2304],
            [26.1763, 55.2509],
            [26.1683, 55.2583],
            [26.1518, 55.2617],
            [26.1513, 55.2801],
            [26.1431, 55.2873],
            [26.1321, 55.2869],
            [26.1169, 55.2933],
            [26.1024, 55.2852],
            [26.0968, 55.2895],
            [26.0652, 55.295],
            [26.0458, 55.2935],
            [26.0472, 55.2863],
            [26.034, 55.2758],
            [26.0274, 55.2791],
            [26.0009, 55.2764],
            [25.9923, 55.2694],
            [25.9711, 55.275],
            [25.9526, 55.2721],
            [25.9421, 55.2781],
            [25.9412, 55.2874],
            [25.9296, 55.2877],
            [25.9311, 55.2774],
            [25.9166, 55.2816],
            [25.9105, 55.2734],
            [25.8946, 55.2802],
            [25.8973, 55.2902],
            [25.8702, 55.3009],
            [25.8544, 55.2968],
            [25.8454, 55.3001],
            [25.8347, 55.2964],
            [25.849, 55.2881],
            [25.8215, 55.2744],
            [25.8094, 55.2849],
            [25.8133, 55.2902],
            [25.7806, 55.3164],
            [25.7687, 55.3151],
            [25.7614, 55.3067],
            [25.7384, 55.3017],
            [25.7121, 55.2701],
            [25.6906, 55.2663],
            [25.7087, 55.2467],
            [25.7026, 55.2382],
            [25.6782, 55.2427],
            [25.668, 55.2351],
            [25.6709, 55.2263],
            [25.6564, 55.2063],
            [25.6533, 55.1965],
            [25.6608, 55.183],
            [25.6814, 55.1801],
            [25.6892, 55.1725],
            [25.7134, 55.1699],
            [25.714, 55.1458],
            [25.7248, 55.1446],
            [25.7306, 55.1374],
            [25.7459, 55.129],
            [25.7581, 55.1313],
            [25.7633, 55.1255],
            [25.796, 55.119],
            [25.8037, 55.1114],
            [25.7842, 55.1107],
            [25.7793, 55.1011],
            [25.7703, 55.103],
            [25.7575, 55.0975],
            [25.7685, 55.0924],
            [25.7526, 55.0657],
            [25.7422, 55.0607],
            [25.7412, 55.0529],
            [25.7468, 55.0414],
            [25.7401, 55.0339],
            [25.7159, 55.0343],
            [25.6963, 55.0417],
            [25.6684, 55.0418],
            [25.6611, 55.0576],
            [25.6282, 55.0657],
            [25.6064, 55.0583],
            [25.5879, 55.0593],
            [25.5772, 55.0494],
            [25.5541, 55.0544],
            [25.5318, 55.0525],
            [25.5351, 55.0442],
            [25.5282, 55.0368],
            [25.4972, 55.0399],
            [25.4789, 55.0361],
            [25.4368, 55.0316],
            [25.4212, 55.0315],
            [25.3974, 55.0347],
            [25.384, 55.028],
            [25.3707, 55.0314],
            [25.3402, 55.0313],
            [25.3181, 55.0437],
            [25.27, 55.0446],
            [25.2218, 55.0401],
            [25.2172, 55.0456],
            [25.2314, 55.0504],
            [25.2385, 55.0571],
            [25.2279, 55.064],
            [25.2227, 55.0742],
            [25.2236, 55.0866],
            [25.1921, 55.09],
            [25.1592, 55.0895],
            [25.1497, 55.0964],
            [25.1562, 55.105],
            [25.1502, 55.1103],
            [25.1628, 55.1196],
            [25.1608, 55.129],
            [25.175, 55.1355],
            [25.1714, 55.148],
            [25.1877, 55.1513],
            [25.2372, 55.1693],
            [25.2275, 55.1787],
            [25.2363, 55.1878],
            [25.2354, 55.1968],
            [25.2266, 55.2079],
            [25.2135, 55.2142],
            [25.2036, 55.2106],
            [25.1774, 55.2067],
            [25.1575, 55.2192],
            [25.1829, 55.2391],
            [25.1883, 55.2641],
            [25.173, 55.2657],
            [25.1426, 55.2557],
            [25.1032, 55.2656],
            [25.1071, 55.2793],
            [25.0981, 55.2898],
            [25.082, 55.2919],
            [25.0851, 55.3025],
            [25.0763, 55.3096],
            [25.0579, 55.3054],
            [25.0551, 55.3118],
            [25.0381, 55.3088],
            [25.0254, 55.3111],
            [25.0003, 55.3062],
            [24.9845, 55.3109],
            [24.9787, 55.3323],
            [24.9808, 55.3421],
            [24.9707, 55.3574],
            [24.9054, 55.3411],
            [24.893, 55.3434],
            [24.9009, 55.3573],
            [24.8791, 55.3575],
            [24.8453, 55.3536],
            [24.8305, 55.3612],
            [24.7848, 55.3751],
            [24.7875, 55.398],
            [24.7809, 55.4072],
            [24.7938, 55.4235],
            [24.8145, 55.4154],
            [24.8218, 55.4261],
            [24.8258, 55.4392],
            [24.8147, 55.4393],
            [24.7829, 55.45],
            [24.7738, 55.4667],
            [24.7716, 55.4772],
            [24.7433, 55.4775],
            [24.7324, 55.4938],
            [24.7136, 55.5068],
            [24.6909, 55.5049],
            [24.6619, 55.4956],
            [24.6557, 55.505],
            [24.6528, 55.5189],
            [24.6512, 55.5295],
            [24.6648, 55.538],
            [24.6704, 55.5486],
            [24.6623, 55.5578],
            [24.678, 55.5644],
            [24.6662, 55.5817],
            [24.6772, 55.5993],
            [24.7265, 55.6087],
            [24.7246, 55.6282],
            [24.7134, 55.6419],
            [24.6959, 55.6499],
            [24.6819, 55.6429],
            [24.664, 55.6405],
            [24.6543, 55.6436],
            [24.6574, 55.6524],
            [24.655, 55.6634],
            [24.673, 55.6664],
            [24.7115, 55.6606],
            [24.7259, 55.6538],
            [24.7389, 55.6581],
            [24.7446, 55.6679],
            [24.7563, 55.6716],
            [24.7418, 55.6789],
            [24.7254, 55.6784],
            [24.7122, 55.6829],
            [24.7205, 55.6951],
            [24.7189, 55.7182],
            [24.7293, 55.7216],
            [24.7513, 55.7097],
            [24.7692, 55.7144],
            [24.7898, 55.709],
            [24.8312, 55.7219],
            [24.8471, 55.7132],
            [24.8489, 55.7052],
            [24.8722, 55.7041],
            [24.8727, 55.7186],
            [24.8912, 55.7217],
            [24.9213, 55.7203],
            [24.9276, 55.716],
            [24.9581, 55.7235],
            [24.9701, 55.7338],
            [24.98, 55.7346],
            [25.0267, 55.7226],
            [25.0512, 55.7211],
            [25.0661, 55.7123],
            [25.0894, 55.7156],
            [25.1018, 55.7144],
            [25.087, 55.7021],
            [25.1011, 55.6816],
            [25.245, 55.7129],
            [25.2687, 55.7173],
            [25.2858, 55.7039],
            [25.3014, 55.699],
            [25.3098, 55.7114],
            [25.2962, 55.7145],
            [25.2937, 55.7322],
            [25.3214, 55.7375],
            [25.3301, 55.7344],
            [25.3497, 55.7449],
            [25.3626, 55.76],
            [25.386, 55.7632],
            [25.4206, 55.7495],
            [25.4365, 55.7389],
            [25.4642, 55.7295],
            [25.4744, 55.7409],
            [25.5012, 55.7356],
            [25.4949, 55.7256],
            [25.5215, 55.7181],
            [25.535, 55.7184],
            [25.5433, 55.7303],
            [25.5641, 55.7278],
            [25.5826, 55.7226],
            [25.5948, 55.7433],
            [25.6139, 55.7345],
            [25.6247, 55.7377],
            [25.6474, 55.7332],
            [25.6689, 55.7444],
            [25.6777, 55.7428],
            [25.6834, 55.7222],
            [25.6955, 55.7247],
            [25.7152, 55.7389],
            [25.729, 55.7385],
            [25.7335, 55.7514],
            [25.7313, 55.7573],
            [25.7354, 55.773],
            [25.7713, 55.7802],
            [25.7927, 55.7722],
            [25.8256, 55.7692],
            [25.8246, 55.7732],
            [25.8395, 55.7963],
            [25.8335, 55.8029],
            [25.8447, 55.8218],
            [25.8437, 55.8319],
            [25.8639, 55.8274],
            [25.8899, 55.8285],
            [25.9074, 55.8339],
            [25.8998, 55.8696],
            [25.9529, 55.8747],
            [25.9702, 55.8775],
            [25.9686, 55.8893],
            [25.9826, 55.9026],
            [25.9625, 55.9183],
            [25.9646, 55.9306],
            [25.9719, 55.9318],
            [25.9959, 55.9495],
            [26.0462, 55.9546],
            [26.0494, 55.945],
            [26.0666, 55.9288],
            [26.0917, 55.9013],
            [26.1238, 55.8784],
            [26.1541, 55.875],
            [26.1756, 55.8642],
            [26.192, 55.8651],
            [26.21, 55.8544],
            [26.2316, 55.8372],
            [26.2382, 55.8194],
            [26.2343, 55.8111],
            [26.2648, 55.7778],
            [26.2711, 55.7666],
            [26.3095, 55.7452],
            [26.3286, 55.7395],
            [26.3523, 55.7406],
            [26.3679, 55.7297],
            [26.3812, 55.7055],
            [26.3893, 55.7034],
            [26.4066, 55.7078],
            [26.4247, 55.7086],
            [26.4666, 55.704],
            [26.4933, 55.6913],
            [26.4951, 55.6857],
            [26.5167, 55.6852],
            [26.525, 55.678],
            [26.5359, 55.6758],
            [26.575, 55.6778],
            [26.5799, 55.6736],
            [26.6026, 55.6727],
            [26.6211, 55.6784],
            [26.633, 55.6786],
            [26.6332, 55.654],
            [26.6473, 55.6529],
            [26.6474, 55.6357],
            [26.6541, 55.6259],
            [26.6367, 55.6092],
            [26.6456, 55.6015],
            [26.6465, 55.5728],
            [26.6264, 55.5631],
            [26.6115, 55.5624],
            [26.5845, 55.5365],
            [26.5683, 55.5344],
            [26.5462, 55.5265],
            [26.5294, 55.5045],
            [26.5409, 55.4929],
            [26.5465, 55.4826],
            [26.5581, 55.4817],
            [26.5685, 55.469],
            [26.5521, 55.4598],
            [26.5433, 55.4391],
            [26.5607, 55.4119],
            [26.5512, 55.4082],
            [26.5535, 55.3878],
            [26.5328, 55.3882],
            [26.514, 55.3789],
            [26.5037, 55.3698],
            [26.4916, 55.372],
            [26.4777, 55.3695],
            [26.4741, 55.3577],
            [26.445, 55.3481],
            [26.4503, 55.3432],
            [26.4682, 55.3406],
            [26.4785, 55.3423],
            [26.5185, 55.3303],
            [26.5267, 55.332],
            [26.5505, 55.3281],
            [26.5587, 55.3216],
            [26.5726, 55.3216],
            [26.5857, 55.3261],
            [26.6044, 55.3211],
            [26.6282, 55.33],
            [26.6731, 55.3378],
            [26.6831, 55.3317],
            [26.7098, 55.3289],
            [26.7215, 55.3221],
            [26.7379, 55.3243],
            [26.7657, 55.3229],
            [26.7793, 55.3185],
            [26.8155, 55.3156],
            [26.8158, 55.309],
            [26.8309, 55.3072],
            [26.8377, 55.2977],
            [26.8376, 55.2863],
            [26.8293, 55.2769],
            [26.8017, 55.274],
            [26.7797, 55.2597],
            [26.7545, 55.2521],
            [26.7448, 55.2522],
          ],
        ],
      },
      properties: {name: 'Utena', id: 'LT-UT', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-UT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [24.6528, 55.5189],
              [24.6557, 55.505],
              [24.6619, 55.4956],
              [24.6909, 55.5049],
              [24.7136, 55.5068],
              [24.7324, 55.4938],
              [24.7433, 55.4775],
              [24.7716, 55.4772],
              [24.7738, 55.4667],
              [24.7829, 55.45],
              [24.8147, 55.4393],
              [24.8258, 55.4392],
              [24.8218, 55.4261],
              [24.8145, 55.4154],
              [24.7938, 55.4235],
              [24.7809, 55.4072],
              [24.7875, 55.398],
              [24.7848, 55.3751],
              [24.8305, 55.3612],
              [24.8453, 55.3536],
              [24.8791, 55.3575],
              [24.9009, 55.3573],
              [24.893, 55.3434],
              [24.9054, 55.3411],
              [24.9707, 55.3574],
              [24.9808, 55.3421],
              [24.9787, 55.3323],
              [24.9845, 55.3109],
              [25.0003, 55.3062],
              [25.0254, 55.3111],
              [25.0381, 55.3088],
              [25.0551, 55.3118],
              [25.0579, 55.3054],
              [25.0763, 55.3096],
              [25.0851, 55.3025],
              [25.082, 55.2919],
              [25.0981, 55.2898],
              [25.1071, 55.2793],
              [25.1032, 55.2656],
              [25.1426, 55.2557],
              [25.173, 55.2657],
              [25.1883, 55.2641],
              [25.1829, 55.2391],
              [25.1575, 55.2192],
              [25.1774, 55.2067],
              [25.2036, 55.2106],
              [25.2135, 55.2142],
              [25.2266, 55.2079],
              [25.2354, 55.1968],
              [25.2363, 55.1878],
              [25.2275, 55.1787],
              [25.2372, 55.1693],
              [25.1877, 55.1513],
              [25.1714, 55.148],
              [25.175, 55.1355],
              [25.1608, 55.129],
              [25.1628, 55.1196],
              [25.1502, 55.1103],
              [25.1562, 55.105],
              [25.1497, 55.0964],
              [25.1592, 55.0895],
              [25.1921, 55.09],
              [25.2236, 55.0866],
              [25.2227, 55.0742],
              [25.2279, 55.064],
              [25.2385, 55.0571],
              [25.2314, 55.0504],
              [25.2172, 55.0456],
              [25.2218, 55.0401],
              [25.27, 55.0446],
              [25.3181, 55.0437],
              [25.3402, 55.0313],
              [25.3707, 55.0314],
              [25.384, 55.028],
              [25.3974, 55.0347],
              [25.4212, 55.0315],
              [25.4368, 55.0316],
              [25.4789, 55.0361],
              [25.4972, 55.0399],
              [25.5282, 55.0368],
              [25.5351, 55.0442],
              [25.5318, 55.0525],
              [25.5541, 55.0544],
              [25.5772, 55.0494],
              [25.5879, 55.0593],
              [25.6064, 55.0583],
              [25.6282, 55.0657],
              [25.6611, 55.0576],
              [25.6684, 55.0418],
              [25.6963, 55.0417],
              [25.7159, 55.0343],
              [25.7401, 55.0339],
              [25.7468, 55.0414],
              [25.7412, 55.0529],
              [25.7422, 55.0607],
              [25.7526, 55.0657],
              [25.7685, 55.0924],
              [25.7575, 55.0975],
              [25.7703, 55.103],
              [25.7793, 55.1011],
              [25.7842, 55.1107],
              [25.8037, 55.1114],
              [25.796, 55.119],
              [25.7633, 55.1255],
              [25.7581, 55.1313],
              [25.7459, 55.129],
              [25.7306, 55.1374],
              [25.7248, 55.1446],
              [25.714, 55.1458],
              [25.7134, 55.1699],
              [25.6892, 55.1725],
              [25.6814, 55.1801],
              [25.6608, 55.183],
              [25.6533, 55.1965],
              [25.6564, 55.2063],
              [25.6709, 55.2263],
              [25.668, 55.2351],
              [25.6782, 55.2427],
              [25.7026, 55.2382],
              [25.7087, 55.2467],
              [25.6906, 55.2663],
              [25.7121, 55.2701],
              [25.7384, 55.3017],
              [25.7614, 55.3067],
              [25.7687, 55.3151],
              [25.7806, 55.3164],
              [25.8133, 55.2902],
              [25.8094, 55.2849],
              [25.8215, 55.2744],
              [25.849, 55.2881],
              [25.8347, 55.2964],
              [25.8454, 55.3001],
              [25.8544, 55.2968],
              [25.8702, 55.3009],
              [25.8973, 55.2902],
              [25.8946, 55.2802],
              [25.9105, 55.2734],
              [25.9166, 55.2816],
              [25.9311, 55.2774],
              [25.9296, 55.2877],
              [25.9412, 55.2874],
              [25.9421, 55.2781],
              [25.9526, 55.2721],
              [25.9711, 55.275],
              [25.9923, 55.2694],
              [26.0009, 55.2764],
              [26.0274, 55.2791],
              [26.034, 55.2758],
              [26.0472, 55.2863],
              [26.0458, 55.2935],
              [26.0652, 55.295],
              [26.0968, 55.2895],
              [26.1024, 55.2852],
              [26.1169, 55.2933],
              [26.1321, 55.2869],
              [26.1431, 55.2873],
              [26.1513, 55.2801],
              [26.1518, 55.2617],
              [26.1683, 55.2583],
              [26.1763, 55.2509],
              [26.1526, 55.2304],
              [26.1537, 55.2255],
              [26.1898, 55.2231],
              [26.2013, 55.2373],
              [26.2206, 55.2396],
              [26.2317, 55.2449],
              [26.2421, 55.239],
              [26.2509, 55.2253],
              [26.2666, 55.2193],
              [26.2769, 55.2294],
              [26.2905, 55.2244],
              [26.3071, 55.2283],
              [26.3293, 55.2235],
              [26.3653, 55.2201],
              [26.3762, 55.2243],
              [26.4064, 55.2171],
              [26.4471, 55.2106],
              [26.4587, 55.2182],
              [26.4856, 55.211],
              [26.4944, 55.2142],
              [26.5185, 55.2116],
              [26.5246, 55.2196],
              [26.5404, 55.2225],
              [26.5548, 55.2165],
              [26.5699, 55.2242],
              [26.6021, 55.2242],
              [26.6173, 55.2366],
              [26.6166, 55.2513],
              [26.6867, 55.2622],
              [26.6925, 55.2597],
              [26.7614, 55.2626],
              [26.7418, 55.2554],
              [26.7448, 55.2522],
              [26.7293, 55.2393],
              [26.732, 55.2164],
              [26.717, 55.2095],
              [26.7067, 55.2007],
              [26.6962, 55.2058],
              [26.6786, 55.1988],
              [26.6801, 55.1897],
              [26.6896, 55.1779],
              [26.7059, 55.1729],
              [26.6969, 55.1658],
              [26.6809, 55.1693],
              [26.6788, 55.1591],
              [26.6684, 55.1566],
              [26.6555, 55.1616],
              [26.6391, 55.1523],
              [26.6232, 55.1477],
              [26.5859, 55.1541],
              [26.5737, 55.149],
              [26.5615, 55.1488],
              [26.5435, 55.1439],
              [26.5173, 55.1604],
              [26.4968, 55.1513],
              [26.4815, 55.1485],
              [26.4794, 55.1375],
              [26.4636, 55.1298],
              [26.4273, 55.1397],
              [26.4113, 55.1398],
              [26.4015, 55.134],
              [26.3899, 55.1424],
              [26.3701, 55.1493],
              [26.3588, 55.1503],
              [26.3436, 55.1451],
              [26.3262, 55.1454],
              [26.3263, 55.1354],
              [26.3014, 55.1247],
              [26.2653, 55.1213],
              [26.2299, 55.1072],
              [26.2297, 55.104],
              [26.2549, 55.0913],
              [26.2707, 55.0792],
              [26.2439, 55.0669],
              [26.239, 55.0406],
              [26.2257, 55.0206],
              [26.2113, 55.0137],
              [26.2116, 55.0019],
              [26.1923, 54.9927],
              [26.162, 54.9921],
              [26.1389, 54.9835],
              [26.1225, 54.9869],
              [26.1138, 54.9724],
              [26.1035, 54.9691],
              [26.0816, 54.9687],
              [26.0758, 54.9621],
              [26.084, 54.9552],
              [26.0735, 54.9495],
              [26.0562, 54.9482],
              [26.0533, 54.9411],
              [26.0375, 54.9421],
              [25.9925, 54.9506],
              [25.9844, 54.9578],
              [25.9485, 54.9483],
              [25.9311, 54.9366],
              [25.9241, 54.938],
              [25.9083, 54.9317],
              [25.8884, 54.9363],
              [25.88, 54.9292],
              [25.8611, 54.9286],
              [25.856, 54.9179],
              [25.8591, 54.9061],
              [25.8531, 54.8967],
              [25.8179, 54.8795],
              [25.8121, 54.8682],
              [25.7807, 54.8526],
              [25.7801, 54.8288],
              [25.7761, 54.8241],
              [25.7584, 54.8232],
              [25.7461, 54.8164],
              [25.7373, 54.8003],
              [25.7447, 54.7746],
              [25.7503, 54.7661],
              [25.7509, 54.749],
              [25.7487, 54.7231],
              [25.7376, 54.7025],
              [25.74, 54.6869],
              [25.7348, 54.6841],
              [25.747, 54.6404],
              [25.7412, 54.6332],
              [25.7557, 54.6199],
              [25.756, 54.6001],
              [25.7642, 54.5854],
              [25.762, 54.5755],
              [25.753, 54.5643],
              [25.6836, 54.5356],
              [25.6584, 54.5059],
              [25.656, 54.4941],
              [25.6411, 54.4798],
              [25.6397, 54.4703],
              [25.6234, 54.4686],
              [25.6138, 54.4619],
              [25.6265, 54.4579],
              [25.621, 54.4488],
              [25.63, 54.4336],
              [25.6315, 54.4227],
              [25.6067, 54.3978],
              [25.5916, 54.3987],
              [25.5866, 54.393],
              [25.5925, 54.3762],
              [25.5647, 54.3651],
              [25.5568, 54.3437],
              [25.5388, 54.3427],
              [25.5369, 54.3324],
              [25.5503, 54.3325],
              [25.5481, 54.3222],
              [25.5557, 54.3148],
              [25.5899, 54.3225],
              [25.5913, 54.3161],
              [25.6204, 54.3102],
              [25.6336, 54.3176],
              [25.644, 54.3113],
              [25.6641, 54.3219],
              [25.6828, 54.3236],
              [25.7109, 54.2976],
              [25.7043, 54.2931],
              [25.7091, 54.2854],
              [25.7034, 54.2786],
              [25.7136, 54.2724],
              [25.723, 54.2764],
              [25.7455, 54.2727],
              [25.7582, 54.2521],
              [25.767, 54.2426],
              [25.7895, 54.2358],
              [25.7547, 54.2203],
              [25.7658, 54.2077],
              [25.7815, 54.1949],
              [25.7727, 54.1834],
              [25.7799, 54.1781],
              [25.7799, 54.1683],
              [25.8011, 54.1621],
              [25.7565, 54.1609],
              [25.7297, 54.1624],
              [25.7068, 54.1688],
              [25.7051, 54.1572],
              [25.6928, 54.1542],
              [25.6862, 54.1407],
              [25.6689, 54.1414],
              [25.6566, 54.1364],
              [25.6508, 54.1292],
              [25.6319, 54.1319],
              [25.6254, 54.1389],
              [25.601, 54.1425],
              [25.5662, 54.1518],
              [25.5549, 54.148],
              [25.548, 54.1542],
              [25.5357, 54.1564],
              [25.5326, 54.1646],
              [25.5123, 54.1746],
              [25.5162, 54.1859],
              [25.5421, 54.1905],
              [25.563, 54.1973],
              [25.5634, 54.2145],
              [25.546, 54.2214],
              [25.55, 54.2291],
              [25.5654, 54.2319],
              [25.5767, 54.2265],
              [25.5843, 54.2342],
              [25.5704, 54.2475],
              [25.5719, 54.2575],
              [25.5597, 54.268],
              [25.5644, 54.2716],
              [25.52, 54.2843],
              [25.5159, 54.2934],
              [25.5174, 54.3072],
              [25.501, 54.3113],
              [25.4979, 54.3027],
              [25.482, 54.3024],
              [25.4836, 54.2958],
              [25.4573, 54.2903],
              [25.4563, 54.2979],
              [25.4217, 54.2973],
              [25.412, 54.2835],
              [25.3944, 54.2753],
              [25.3644, 54.2724],
              [25.3548, 54.2645],
              [25.3369, 54.2674],
              [25.326, 54.2626],
              [25.314, 54.2677],
              [25.3061, 54.2624],
              [25.2891, 54.2645],
              [25.2669, 54.2726],
              [25.2531, 54.2524],
              [25.2347, 54.2638],
              [25.2147, 54.2534],
              [25.213, 54.2428],
              [25.2051, 54.2401],
              [25.2061, 54.2228],
              [25.1894, 54.2103],
              [25.1685, 54.2018],
              [25.1627, 54.1928],
              [25.1401, 54.1824],
              [25.1191, 54.1696],
              [25.1106, 54.1592],
              [25.0806, 54.1432],
              [25.0767, 54.1355],
              [25.0517, 54.1399],
              [25.0156, 54.1368],
              [24.9868, 54.1476],
              [24.9773, 54.1594],
              [24.9812, 54.1653],
              [24.9599, 54.1723],
              [24.9227, 54.1601],
              [24.9127, 54.1593],
              [24.8693, 54.147],
              [24.8435, 54.1464],
              [24.8336, 54.1488],
              [24.8259, 54.1647],
              [24.8122, 54.1745],
              [24.8128, 54.1923],
              [24.8033, 54.1919],
              [24.7856, 54.1994],
              [24.7535, 54.2056],
              [24.7582, 54.2169],
              [24.7569, 54.2272],
              [24.7658, 54.2349],
              [24.776, 54.2322],
              [24.7854, 54.2389],
              [24.8073, 54.2384],
              [24.8038, 54.2534],
              [24.8198, 54.2581],
              [24.8304, 54.2565],
              [24.8498, 54.2607],
              [24.8611, 54.2758],
              [24.8845, 54.2772],
              [24.8713, 54.2847],
              [24.8705, 54.2906],
              [24.8807, 54.296],
              [24.9303, 54.298],
              [24.9169, 54.325],
              [24.9254, 54.3367],
              [24.915, 54.3436],
              [24.9092, 54.3602],
              [24.9269, 54.3632],
              [24.9244, 54.3745],
              [24.9952, 54.3825],
              [24.9926, 54.3946],
              [25.0152, 54.3978],
              [25.0075, 54.4203],
              [25.0228, 54.4236],
              [25.0167, 54.438],
              [24.9994, 54.4338],
              [24.9859, 54.4346],
              [24.9724, 54.428],
              [24.95, 54.4301],
              [24.9336, 54.4239],
              [24.9182, 54.4228],
              [24.9021, 54.4156],
              [24.8702, 54.4133],
              [24.8618, 54.4093],
              [24.8483, 54.4116],
              [24.8396, 54.4056],
              [24.8424, 54.3969],
              [24.8002, 54.3973],
              [24.7999, 54.402],
              [24.7489, 54.3985],
              [24.7306, 54.3935],
              [24.7052, 54.3958],
              [24.7042, 54.4102],
              [24.7115, 54.4128],
              [24.712, 54.427],
              [24.691, 54.4304],
              [24.6626, 54.4468],
              [24.6481, 54.4459],
              [24.641, 54.44],
              [24.6185, 54.4446],
              [24.6095, 54.4386],
              [24.5835, 54.4277],
              [24.5799, 54.4187],
              [24.5598, 54.4134],
              [24.5426, 54.4142],
              [24.5429, 54.4236],
              [24.5087, 54.4221],
              [24.4988, 54.4174],
              [24.4547, 54.4184],
              [24.444, 54.4233],
              [24.4222, 54.4279],
              [24.4225, 54.4359],
              [24.4416, 54.4478],
              [24.4417, 54.4587],
              [24.4288, 54.4692],
              [24.4079, 54.4665],
              [24.3994, 54.4761],
              [24.4262, 54.4835],
              [24.4217, 54.5006],
              [24.4122, 54.5012],
              [24.4094, 54.5243],
              [24.4145, 54.5312],
              [24.4225, 54.5533],
              [24.4282, 54.5623],
              [24.4254, 54.5705],
              [24.4305, 54.5783],
              [24.4253, 54.5892],
              [24.436, 54.5902],
              [24.4599, 54.6025],
              [24.475, 54.6029],
              [24.4835, 54.6087],
              [24.472, 54.6282],
              [24.4747, 54.6362],
              [24.4595, 54.6619],
              [24.4635, 54.6641],
              [24.4581, 54.6804],
              [24.479, 54.699],
              [24.5083, 54.7025],
              [24.5485, 54.6914],
              [24.5622, 54.7147],
              [24.5831, 54.7135],
              [24.5939, 54.702],
              [24.6128, 54.7005],
              [24.6263, 54.704],
              [24.628, 54.7131],
              [24.6203, 54.7186],
              [24.626, 54.7308],
              [24.6346, 54.7337],
              [24.6327, 54.7489],
              [24.6466, 54.7504],
              [24.644, 54.7605],
              [24.6327, 54.7683],
              [24.6428, 54.777],
              [24.6407, 54.7875],
              [24.6452, 54.7984],
              [24.6651, 54.8078],
              [24.6874, 54.8144],
              [24.7203, 54.8184],
              [24.729, 54.8175],
              [24.7588, 54.8269],
              [24.766, 54.8236],
              [24.753, 54.8783],
              [24.7575, 54.8834],
              [24.7899, 54.8866],
              [24.7949, 54.8815],
              [24.812, 54.888],
              [24.8131, 54.9022],
              [24.7995, 54.9102],
              [24.7682, 54.9176],
              [24.7515, 54.9311],
              [24.7261, 54.932],
              [24.6905, 54.9282],
              [24.69, 54.9348],
              [24.6807, 54.9457],
              [24.666, 54.9513],
              [24.6531, 54.9515],
              [24.6333, 54.9607],
              [24.6223, 54.9734],
              [24.6, 54.9718],
              [24.5826, 54.9731],
              [24.5721, 54.9828],
              [24.5552, 54.9901],
              [24.567, 54.9969],
              [24.5682, 55.0065],
              [24.5949, 55.0121],
              [24.5926, 55.0285],
              [24.631, 55.0317],
              [24.6251, 55.0373],
              [24.6346, 55.052],
              [24.6355, 55.0611],
              [24.6186, 55.0746],
              [24.6079, 55.0749],
              [24.596, 55.0895],
              [24.5865, 55.096],
              [24.5864, 55.112],
              [24.5624, 55.1141],
              [24.5476, 55.1213],
              [24.5476, 55.1309],
              [24.5286, 55.1434],
              [24.543, 55.1481],
              [24.5482, 55.1555],
              [24.5368, 55.1591],
              [24.5256, 55.157],
              [24.5095, 55.1601],
              [24.5054, 55.174],
              [24.4958, 55.1817],
              [24.4977, 55.1932],
              [24.4869, 55.203],
              [24.5106, 55.2208],
              [24.4936, 55.2251],
              [24.4707, 55.2271],
              [24.4502, 55.2319],
              [24.4191, 55.2343],
              [24.4208, 55.2427],
              [24.4341, 55.2696],
              [24.4347, 55.2809],
              [24.4266, 55.2917],
              [24.3941, 55.2934],
              [24.4025, 55.3326],
              [24.4108, 55.3343],
              [24.4087, 55.3518],
              [24.4234, 55.3569],
              [24.4257, 55.3707],
              [24.4473, 55.3757],
              [24.4493, 55.3988],
              [24.487, 55.3981],
              [24.4898, 55.4069],
              [24.5226, 55.4175],
              [24.5148, 55.431],
              [24.5344, 55.4364],
              [24.5309, 55.4447],
              [24.5374, 55.4567],
              [24.5949, 55.4463],
              [24.6408, 55.4578],
              [24.6401, 55.4634],
              [24.6507, 55.4721],
              [24.6379, 55.483],
              [24.6375, 55.4963],
              [24.6247, 55.5093],
              [24.6528, 55.5189],
            ],
          ],
          [
            [
              [25.4863, 54.2765],
              [25.4721, 54.2786],
              [25.4807, 54.286],
              [25.5016, 54.2839],
              [25.4863, 54.2765],
            ],
          ],
        ],
      },
      properties: {name: 'Vilnius', id: 'LT-VL', CNTRY: 'Lithuania', TYPE: 'County'},
      id: 'LT-VL',
    },
  ],
}
export default map
