import React, {useEffect, useState} from 'react';
import { SEOReportDeatils } from '../../models/SEOReport';
import GoogleAdsGroup from "../SemStatistic/SemWidgetsGroup/GoogleAdsGroup";
import GoogleAdsSumList from "../SemStatistic/SemWidgetsGroup/GoogleAdsSumList";
import CommentTab from "../SeoStatistic/Tabs/CommentTab";
import {IReportDetails, SEM_TAB_NAME} from "../../models/Reports";
import { useIntl} from "react-intl";
import {enableSplashScreen} from "../../../../../_metronic/layout/core";
import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";
import {printPDF} from "./Functions/PdfUtils";

interface PDFGadsPrint {
    analyticDataMonth?: SEOReportDeatils;
    closePopup: () => void;
}

const GadsPrintView: React.FC<PDFGadsPrint> = ({ closePopup }) => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [googleAdsLoaded, setGoogleAdsLoaded] = useState(false);


    useEffect(() => {
        enableSplashScreen();
        if (googleAdsLoaded) {
            setTimeout(() => {
                printPDF(document.getElementById('reportPrint'), reportData, closePopup);
            }, 1000);
        }
    }, [googleAdsLoaded]);


    return (
        <>
            <div id='print_overlay' className='print_overlay'>
            </div>
            <div className="popup-container">
                <div className="popup-body py-5 px-10">
                    {/*<button onClick={closePopup}>Close X</button>*/}
                    {/*<div>*/}
                    {/*    <button onClick={printPDF}>drukuj</button>*/}
                    {/*</div>*/}
                    <div id="reportPrint">
                        <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5'>

                                <GoogleAdsGroup
                                    tabKey={1}
                                    onLoad={() => setGoogleAdsLoaded(true)}
                                />

                        </div>
                        <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5'>

                                <GoogleAdsSumList
                                    tabKey={1}
                                />

                        </div>
                        <div className='sem-basic-data card-body bg-white px-5 py-5 pb-0 mb-5 text-left'>
                            <h3 className='fw-bolder text-dark px-5'>
                                {intl.formatMessage({id: SEM_TAB_NAME.COMMENT})}
                            </h3>
                            <CommentTab />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GadsPrintView;