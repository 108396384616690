import {useEffect, useState} from 'react'
import {Accordion} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {numSpaceFormater} from '../../../../../helpers/formater'
import useWindowDimensions from '../../../../../helpers/windowHook'
import {FBCampaign, FBCampaignData, FBReportsDetails} from '../../../models/FBReport'
import {selectors} from '../../../redux/ReportsRedux'

interface FbConversionCampaignGroupProps {
  isPdf?: boolean
}

interface FbConversionGroupItemProps {
  value: string | number
  label: string | number
  img: string
  color: string
}

const FbConversionGroupItem: React.FC<FbConversionGroupItemProps> = ({
  value,
  label,
  img,
  color,
}) => {
  return (
    <div className='mb-xl-0 mb-2'>
      <div className='d-flex flex-column align-items-center'>
        <div className='symbol-label'>
          <KTSVG path={img} className={`svg-icon-xl-2x svg-icon-5x svg-icon-${color}`} />
        </div>
        <div className='d-flex fw-bold text-dark' style={{fontSize: '25px'}}>
          {value}
        </div>
        <div className='d-flex text-muted fs-6 fw-bold mw-250px text-center'>{label}</div>
      </div>
    </div>
  )
}

const FbConversionCampaignGroup: React.FC<FbConversionCampaignGroupProps> = ({ isPdf }) => {
  const intl = useIntl()
  const details = useSelector(selectors.getReportDetails) as FBReportsDetails
  const [campaign, setCampaign] = useState<FBCampaignData>()
  const [campaignTitle, setCampaignTitle] = useState<FBCampaign>()
  const [accordionActive, setAccordionActive] = useState<string>('1')
  const {width} = useWindowDimensions()
  const [hasCampaignData, setHasCampaignData] = useState(false);
  // useEffect(() => {
  //   if (width > 991) setAccordionActive('1')
  //   else setAccordionActive('1')
  // }, [width])
  useEffect(() => {
    if (details && details.fbCampaign) {
      setCampaign(details.fbCampaign as FBCampaignData)

      setCampaignTitle(details.fbCampaign?.titles)
      if(isPdf) {
        setAccordionActive('0');
      }
      if(details.fbCampaign.data && details.fbCampaign.data.length > 0) {
        setHasCampaignData(true);
      }
    }
  }, [details])

  return hasCampaignData ? (
    <>
      <div className='row card-body px-5 py-5 mb-5'>
        <div className='pb-10'>
          <h3 className='fw-bolder fs-xl-3 fs-4 text-dark text-center'>{`Facebook Ads - ${intl.formatMessage(
            {
              id: 'REPORT.CONV_ATTR',
            }
          )}`}</h3>
          <div className='d-flex justify-content-center align-items-center'>
            <div className='text-center text-gray-400 fw-bold fs-xl-4 fs-5 w-xl-800px'>
              {intl.formatMessage({id: 'REPORT.CONVERSION.SUBTITLE'})}
            </div>
          </div>
        </div>
      </div>
      {campaign && campaignTitle && (
        <div className='row conversion-data' data-testid='conversion-data'>
          {campaign.data.map((item, index) => {
            return (
                <div key={index} className='col-12'>
                  <div className='card card-xl-stretch mb-xl-8 mb-5 bg-white shadow-sm'>
                    <Accordion defaultActiveKey={accordionActive}>
                      <Accordion.Item eventKey='0' className='p-0'>
                        <Accordion.Header className='bg-light-info p-0'>
                          <div className='card-header justify-content-start align-items-center border-0 py-xl-5 px-xl-7 py-2 px-3'>
                          <span className='symbol-label'>
                            <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                                className='h-xl-35px h-25px align-self-center m-1'
                                alt=''
                            />
                          </span>
                            <h3 className='flex-column'>
                            <span className='fw-bolder fs-xl-2 fs-4 mb-1'>
                              {item.campaign_name + " "}
                            </span>
                              <span className='text-muted fw-bold fs-xl-4 fs-5'>
                              {intl.formatMessage({id: 'CAMPAIGN'})}
                            </span>
                            </h3>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className='p-xl-5 p-0'>
                          <div className='card-body p-0 d-flex flex-column'>
                            <div className='card pt-5 pb-xl-10 pb-5 px-5 flex-grow-1'>
                              <div className='px-2 py-3 mb-5 border border-gray-200 rounded shadow-sm'>
                                <div className='fw-bolder fs-6 mb-3 text-center'>
                                  {intl.formatMessage({id: 'CONVERSION'})}
                                </div>
                                <div className='conversion-data-info d-flex justify-content-around flex-md-row flex-column'>
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(item.link_click)}
                                      label={intl.formatMessage({id: 'CLICKS'})}
                                      img='/media/icons/duotone/General/Cursor.svg'
                                      color='info'
                                  />
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(item.purchase)}
                                      label={intl.formatMessage({id: 'PURCHASE'})}
                                      img='/media/icons/duotone/Shopping/Cart6.svg'
                                      color='success'
                                  />
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(
                                          Number(item.cost_per_conversion).toFixed(2)
                                      )}
                                      label={intl.formatMessage({id: 'REPORT.COST_PER_CONV'})}
                                      img='/media/icons/duotone/Shopping/Price1.svg'
                                      color='primary'
                                  />
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(item.conversions)}
                                      label={intl.formatMessage({id: 'CONVERSION'})}
                                      img='/media/icons/duotone/Design/Color-profile.svg'
                                      color='warning'
                                  />
                                  <FbConversionGroupItem
                                      value={item.roas ? numSpaceFormater(Number(item.roas).toFixed(2)) : '0'}
                                      label={campaignTitle.roas}
                                      img='/media/icons/duotone/Shopping/Barcode-read.svg'
                                      color='secondary'
                                  />
                                </div>
                              </div>
                              <div className='px-2 py-3 mb-5 border border-gray-200 rounded shadow-sm'>
                                <div className='fw-bolder fs-6 mb-3 text-center'>
                                  {intl.formatMessage({id: 'ATTRIBUTION.1_DAY'})}
                                </div>
                                <div className='conversion-data-info d-flex justify-content-around flex-md-row flex-column'>
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(item.link_click_1_day)}
                                      label={intl.formatMessage({id: 'CLICKS'})}
                                      img='/media/icons/duotone/General/Cursor.svg'
                                      color='info'
                                  />
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(item.purchase_1_day)}
                                      label={intl.formatMessage({id: 'PURCHASE'})}
                                      img='/media/icons/duotone/Shopping/Cart6.svg'
                                      color='success'
                                  />
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(
                                          Number(item.cost_per_conversion_1_day).toFixed(2)
                                      )}
                                      label={intl.formatMessage({id: 'REPORT.COST_PER_CONV'})}
                                      img='/media/icons/duotone/Shopping/Price1.svg'
                                      color='primary'
                                  />
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(item.conversions_1_day)}
                                      label={intl.formatMessage({id: 'CONVERSION'})}
                                      img='/media/icons/duotone/Design/Color-profile.svg'
                                      color='warning'
                                  />
                                  <FbConversionGroupItem
                                      value={item.roas_1_day ? numSpaceFormater(Number(item.roas_1_day).toFixed(2)) : '0'}
                                      label={campaignTitle.roas}
                                      img='/media/icons/duotone/Shopping/Barcode-read.svg'
                                      color='secondary'
                                  />
                                </div>
                              </div>
                              <div className='px-2 py-3 border border-gray-200 rounded shadow-sm'>
                                <div className='fw-bolder fs-6 mb-3 text-center'>
                                  {intl.formatMessage({id: 'ATTRIBUTION.7_DAY'})}
                                </div>
                                <div className='conversion-data-info d-flex justify-content-around flex-md-row flex-column'>
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(item.link_click_7_day)}
                                      label={intl.formatMessage({id: 'CLICKS'})}
                                      img='/media/icons/duotone/General/Cursor.svg'
                                      color='info'
                                  />
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(item.purchase_7_day)}
                                      label={intl.formatMessage({id: 'PURCHASE'})}
                                      img='/media/icons/duotone/Shopping/Cart6.svg'
                                      color='success'
                                  />
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(
                                          Number(item.cost_per_conversion_7_day).toFixed(2)
                                      )}
                                      label={intl.formatMessage({id: 'REPORT.COST_PER_CONV'})}
                                      img='/media/icons/duotone/Shopping/Price1.svg'
                                      color='primary'
                                  />
                                  <FbConversionGroupItem
                                      value={numSpaceFormater(item.conversions_7_day)}
                                      label={intl.formatMessage({id: 'CONVERSION'})}
                                      img='/media/icons/duotone/Design/Color-profile.svg'
                                      color='warning'
                                  />
                                  <FbConversionGroupItem
                                      value={item.roas_7_day ? numSpaceFormater(Number(item.roas_7_day).toFixed(2)) : '0'}
                                      label={campaignTitle.roas}
                                      img='/media/icons/duotone/Shopping/Barcode-read.svg'
                                      color='secondary'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
            )
          })}
        </div>
      )}
    </>
  ) : <></>
}

export default FbConversionCampaignGroup
