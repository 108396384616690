import React, { useEffect, useState } from 'react';
import { FBReportsDetails } from '../../models/FBReport';
import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";
import {IReportDetails} from "../../models/Reports";
import CommonPrintView, { ReportType } from "../Common/CommonPrintView";

interface PDFFbPrint {
    analyticDataMonth?: FBReportsDetails;
    closePopup: () => void;
}

const FbPrintView: React.FC<PDFFbPrint> = ({ closePopup }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails;
    const [facebookAdsLoaded, setFacebookAdsLoaded] = useState(false);

    return (
        <CommonPrintView
            reportType={ReportType.Facebook}
            reportData={reportData}
            setLoaded={setFacebookAdsLoaded}
            closePopup={closePopup}
        >
        </CommonPrintView>
    );
};

export default FbPrintView;