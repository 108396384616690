import html2pdf from "html2pdf.js";
import {IReportDetails} from "../../../models/Reports";
import {disableSplashScreen} from "../../../../../../_metronic/layout/core";

export function printPDF(element: HTMLElement | null, reportData: IReportDetails, closePopup: () => void): void {
    var opt = {
        margin: [10, 10, 0, 10],
        // width: element_width,
        filename: reportData.domain + '_' + reportData.date + '_' + reportData.id + '.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, dpi: 150 },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.page-break' },
        jsPDF: { unit: 'mm', format: [340, 550], orientation: 'portrait' } // 481
    };

    html2pdf()
        .set(opt)
        .from(element)
        .toCanvas()
        .toImg()
        .toPdf()
        .then(function () {
            disableSplashScreen();
            closePopup();
        })
        .save();
}
