import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const MEDIA_INV_API = `${API_URL}/mediainvoices`

export const MEDIA_INV_PDG_API = `${API_URL}/mediainvoice`

export async function getMediaInvoicesList(
  companyId: number,
  page: number,
  perPage: number,
  filter: string,
  invoiceType: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${MEDIA_INV_API}?company_id=${companyId}&invoice_type=${invoiceType}&search=${search}&page=${page}&perPage=${perPage}`
    )
  return await axios.get(
    `${MEDIA_INV_API}?company_id=${companyId}&invoice_type=${invoiceType}&page=${page}&perPage=${perPage}&${filter}`
  )
}

export async function getMediaInvoicePdf(id: number, companyId: number) {
  return await axios.get(`${MEDIA_INV_PDG_API}/${id}/pdf?company_id=${companyId}`, {
    responseType: 'blob',
  })
}
