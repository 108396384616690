var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [20.5104, 60.0606],
              [20.525, 60.0437],
              [20.4714, 60.0181],
              [20.4666, 60.0593],
              [20.5104, 60.0606],
            ],
          ],
          [
            [
              [20.4221, 60.0581],
              [20.4664, 60.0699],
              [20.4434, 60.026],
              [20.4165, 60.0202],
              [20.3784, 60.0286],
              [20.4221, 60.0581],
            ],
          ],
          [
            [
              [20.3369, 60.2789],
              [20.3397, 60.2896],
              [20.408, 60.2679],
              [20.433, 60.254],
              [20.3975, 60.2038],
              [20.3709, 60.2089],
              [20.3757, 60.226],
              [20.3443, 60.2304],
              [20.3248, 60.2565],
              [20.3369, 60.2789],
            ],
          ],
          [
            [
              [20.6362, 60.1452],
              [20.6758, 60.1496],
              [20.6939, 60.1277],
              [20.6653, 60.1146],
              [20.6362, 60.1452],
            ],
          ],
          [
            [
              [20.6667, 60.2206],
              [20.7081, 60.2248],
              [20.7211, 60.2028],
              [20.6839, 60.1911],
              [20.6667, 60.2206],
            ],
          ],
          [
            [
              [20.2281, 60.1577],
              [20.2524, 60.1604],
              [20.2997, 60.1501],
              [20.2786, 60.09],
              [20.2548, 60.0808],
              [20.2066, 60.0918],
              [20.2264, 60.1191],
              [20.2119, 60.1429],
              [20.2281, 60.1577],
            ],
          ],
          [
            [
              [19.4839, 60.2706],
              [19.5139, 60.2653],
              [19.5337, 60.2413],
              [19.4756, 60.2448],
              [19.4839, 60.2706],
            ],
          ],
          [
            [
              [21.0951, 60.4769],
              [21.1032, 60.4619],
              [21.0643, 60.458],
              [21.064, 60.4772],
              [21.0951, 60.4769],
            ],
          ],
          [
            [
              [19.6079, 60.3142],
              [19.6154, 60.287],
              [19.6559, 60.2611],
              [19.6553, 60.2313],
              [19.6781, 60.2284],
              [19.6818, 60.1985],
              [19.6699, 60.1723],
              [19.5949, 60.1697],
              [19.6085, 60.1524],
              [19.5786, 60.1451],
              [19.546, 60.15],
              [19.52, 60.2023],
              [19.5467, 60.2355],
              [19.5415, 60.2632],
              [19.5658, 60.2615],
              [19.6079, 60.3142],
            ],
          ],
          [
            [
              [20.7647, 60.3633],
              [20.7983, 60.3548],
              [20.7933, 60.3382],
              [20.7346, 60.3363],
              [20.7647, 60.3633],
            ],
          ],
          [
            [
              [19.8292, 60.2738],
              [19.808, 60.2995],
              [19.8529, 60.3106],
              [19.8292, 60.2738],
            ],
          ],
          [
            [
              [19.8531, 60.4266],
              [19.9192, 60.4243],
              [19.9224, 60.4117],
              [19.9647, 60.4014],
              [19.9411, 60.3771],
              [20.0166, 60.3713],
              [20.1127, 60.3731],
              [20.1024, 60.357],
              [20.1962, 60.346],
              [20.2152, 60.3288],
              [20.1834, 60.301],
              [20.2483, 60.2957],
              [20.2604, 60.2872],
              [20.2579, 60.2518],
              [20.2412, 60.242],
              [20.2618, 60.2044],
              [20.1936, 60.2032],
              [20.1705, 60.1762],
              [20.124, 60.2004],
              [20.0973, 60.1859],
              [20.0342, 60.2156],
              [20.0044, 60.2076],
              [20.0444, 60.1654],
              [20.0233, 60.1506],
              [20.0265, 60.126],
              [20.0545, 60.121],
              [20.0446, 60.0836],
              [20.079, 60.0895],
              [20.0854, 60.1107],
              [20.1383, 60.0911],
              [20.2284, 60.0837],
              [20.2147, 60.0511],
              [20.2207, 60.0158],
              [20.1884, 59.9956],
              [20.1596, 60.0063],
              [20.1206, 59.9992],
              [20.1161, 60.0281],
              [20.089, 60.052],
              [20.0347, 60.0495],
              [20.0213, 60.0711],
              [19.9884, 60.0681],
              [19.9843, 60.106],
              [19.9374, 60.0804],
              [19.9132, 60.081],
              [19.8879, 60.1002],
              [19.8194, 60.0916],
              [19.7864, 60.1064],
              [19.7694, 60.0922],
              [19.7362, 60.1163],
              [19.7359, 60.1308],
              [19.7062, 60.1655],
              [19.6906, 60.2016],
              [19.6977, 60.2205],
              [19.662, 60.25],
              [19.6694, 60.2632],
              [19.6389, 60.294],
              [19.6816, 60.3002],
              [19.7564, 60.3029],
              [19.747, 60.3177],
              [19.7579, 60.3391],
              [19.8081, 60.3246],
              [19.7979, 60.2984],
              [19.7707, 60.2605],
              [19.8203, 60.2433],
              [19.8709, 60.2552],
              [19.9006, 60.2828],
              [19.8622, 60.3248],
              [19.8414, 60.3273],
              [19.8284, 60.358],
              [19.7897, 60.3661],
              [19.8005, 60.3993],
              [19.8192, 60.421],
              [19.8531, 60.4266],
            ],
          ],
          [
            [
              [20.7793, 60.2927],
              [20.8067, 60.289],
              [20.8337, 60.2723],
              [20.8345, 60.25],
              [20.8103, 60.2256],
              [20.7683, 60.2348],
              [20.7355, 60.2594],
              [20.7793, 60.2927],
            ],
          ],
          [
            [
              [20.8682, 59.9391],
              [20.9073, 59.9452],
              [20.917, 59.9253],
              [20.9054, 59.9041],
              [20.8587, 59.9268],
              [20.8682, 59.9391],
            ],
          ],
          [
            [
              [20.9137, 59.9576],
              [20.9696, 59.9598],
              [20.9916, 59.9397],
              [20.937, 59.9354],
              [20.9137, 59.9576],
            ],
          ],
          [
            [
              [20.4392, 59.9888],
              [20.3966, 60.0146],
              [20.4342, 60.0164],
              [20.4589, 60.0064],
              [20.4392, 59.9888],
            ],
          ],
          [
            [
              [20.3333, 60.0186],
              [20.3912, 60.0147],
              [20.4157, 59.9839],
              [20.3026, 59.9885],
              [20.3333, 60.0186],
            ],
          ],
          [
            [
              [20.5735, 60.0557],
              [20.6026, 60.0441],
              [20.6025, 60.0207],
              [20.5151, 60.0061],
              [20.499, 60.0234],
              [20.5372, 60.0451],
              [20.5735, 60.0557],
            ],
          ],
        ],
      },
      properties: {name: 'Åland Islands', id: 'FI-01', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-01',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.9945, 60.6692],
            [27.9562, 60.667],
            [27.9554, 60.686],
            [27.904, 60.6976],
            [27.8634, 60.7375],
            [27.8522, 60.7655],
            [27.795, 60.7695],
            [27.7754, 60.7593],
            [27.7355, 60.7616],
            [27.713, 60.7888],
            [27.6806, 60.8026],
            [27.6127, 60.8061],
            [27.5502, 60.7985],
            [27.5077, 60.7998],
            [27.4552, 60.822],
            [27.3744, 60.88],
            [27.3057, 60.8941],
            [27.2184, 60.9045],
            [27.139, 60.9518],
            [27.1827, 60.9508],
            [27.2498, 60.9668],
            [27.2246, 60.9777],
            [27.2112, 61.0082],
            [27.175, 60.9981],
            [27.1437, 61.0106],
            [27.162, 61.0284],
            [27.1515, 61.0689],
            [27.1855, 61.0858],
            [27.2243, 61.0928],
            [27.1897, 61.1208],
            [27.2189, 61.141],
            [27.21, 61.1625],
            [27.3587, 61.1727],
            [27.364, 61.1941],
            [27.3922, 61.2013],
            [27.3312, 61.2231],
            [27.3533, 61.2465],
            [27.313, 61.273],
            [27.2963, 61.3001],
            [27.241, 61.3065],
            [27.2674, 61.3246],
            [27.2183, 61.3316],
            [27.1463, 61.3178],
            [27.0872, 61.3323],
            [27.0398, 61.3513],
            [26.9957, 61.3447],
            [26.9667, 61.3569],
            [27.0042, 61.3668],
            [27.0255, 61.3863],
            [27.0779, 61.3811],
            [27.096, 61.3699],
            [27.1414, 61.3712],
            [27.1696, 61.4015],
            [27.2702, 61.4044],
            [27.3703, 61.3982],
            [27.4362, 61.3826],
            [27.4718, 61.3816],
            [27.5758, 61.3553],
            [27.6749, 61.3555],
            [27.754, 61.3698],
            [27.8565, 61.3511],
            [27.9311, 61.3501],
            [28.0613, 61.3548],
            [28.2566, 61.3796],
            [28.2897, 61.3909],
            [28.3127, 61.4165],
            [28.348, 61.4318],
            [28.4307, 61.4574],
            [28.4247, 61.4868],
            [28.4947, 61.5143],
            [28.5286, 61.499],
            [28.5288, 61.4796],
            [28.5835, 61.4785],
            [28.5983, 61.4698],
            [28.651, 61.4743],
            [28.6815, 61.4696],
            [28.721, 61.4834],
            [28.7972, 61.4789],
            [28.8181, 61.5035],
            [28.8177, 61.5359],
            [28.8918, 61.5283],
            [28.9612, 61.5411],
            [29.0522, 61.5348],
            [29.2208, 61.5598],
            [29.2635, 61.5582],
            [29.2916, 61.5936],
            [29.4036, 61.6518],
            [29.4437, 61.6644],
            [29.5437, 61.6846],
            [29.5635, 61.7223],
            [30.1002, 61.8565],
            [30.1477, 61.8522],
            [30.0764, 61.8189],
            [30.0438, 61.7659],
            [29.873, 61.6826],
            [29.8233, 61.6555],
            [29.8144, 61.6367],
            [29.7587, 61.6032],
            [29.746, 61.5777],
            [29.6474, 61.5267],
            [29.5963, 61.4965],
            [29.5008, 61.4922],
            [29.506, 61.4615],
            [29.3261, 61.3483],
            [29.2466, 61.271],
            [29.2026, 61.257],
            [29.164, 61.2369],
            [28.9981, 61.1787],
            [28.9597, 61.1526],
            [28.886, 61.1424],
            [28.8222, 61.1208],
            [28.8078, 61.0934],
            [28.7176, 61.0453],
            [28.686, 60.9809],
            [28.6558, 60.9495],
            [28.6159, 60.9557],
            [28.5739, 60.9522],
            [28.5258, 60.9568],
            [28.4833, 60.9261],
            [28.4642, 60.9225],
            [28.3128, 60.8415],
            [28.2215, 60.7835],
            [28.1739, 60.7784],
            [28.137, 60.7411],
            [27.9945, 60.6692],
          ],
        ],
      },
      properties: {name: 'South Karelia', id: 'FI-02', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-02',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.6514, 62.0215],
            [21.6319, 62.0417],
            [21.6539, 62.0614],
            [21.6296, 62.1009],
            [21.6461, 62.1158],
            [21.645, 62.1506],
            [21.6969, 62.1454],
            [21.7065, 62.1686],
            [21.6683, 62.1884],
            [21.7105, 62.2141],
            [21.769, 62.2196],
            [21.7301, 62.2553],
            [21.6742, 62.2611],
            [21.6711, 62.2773],
            [21.6881, 62.3549],
            [21.6496, 62.3676],
            [21.5612, 62.3589],
            [21.526, 62.3802],
            [21.5531, 62.3969],
            [21.547, 62.4489],
            [21.6134, 62.5419],
            [21.5998, 62.5599],
            [21.5943, 62.606],
            [21.6511, 62.6241],
            [21.6855, 62.6469],
            [21.7955, 62.6951],
            [21.7513, 62.7226],
            [21.7339, 62.757],
            [21.7331, 62.8213],
            [21.7681, 62.8473],
            [21.7759, 62.8637],
            [21.8347, 62.8459],
            [21.8455, 62.819],
            [21.9155, 62.7959],
            [22.0645, 62.7648],
            [22.1268, 62.7676],
            [22.2023, 62.7522],
            [22.2773, 62.7921],
            [22.3065, 62.7966],
            [22.3751, 62.8583],
            [22.409, 62.8671],
            [22.39, 62.9131],
            [22.43, 62.945],
            [22.4181, 62.9531],
            [22.4636, 62.9798],
            [22.469, 63.0053],
            [22.5245, 63.0501],
            [22.5366, 63.0666],
            [22.6416, 63.0974],
            [22.6496, 63.1259],
            [22.6823, 63.1259],
            [22.6781, 63.193],
            [22.6246, 63.181],
            [22.599, 63.2023],
            [22.6356, 63.2116],
            [22.6172, 63.2386],
            [22.5607, 63.2546],
            [22.5657, 63.2671],
            [22.6796, 63.2983],
            [22.6541, 63.3182],
            [22.7236, 63.3381],
            [22.7611, 63.3426],
            [22.744, 63.3598],
            [22.8109, 63.3574],
            [22.8337, 63.3473],
            [22.902, 63.3543],
            [22.9855, 63.3339],
            [22.9778, 63.3719],
            [23.03, 63.3802],
            [23.0405, 63.3627],
            [23.0881, 63.3676],
            [23.118, 63.3543],
            [23.2112, 63.4],
            [23.2674, 63.4712],
            [23.3959, 63.4996],
            [23.4226, 63.484],
            [23.4735, 63.4799],
            [23.5225, 63.4653],
            [23.6315, 63.4005],
            [23.7012, 63.3808],
            [23.7713, 63.3141],
            [23.8591, 63.2783],
            [23.9571, 63.2766],
            [24.0207, 63.2685],
            [24.1002, 63.2454],
            [24.1708, 63.2143],
            [24.2386, 63.1716],
            [24.2076, 63.1552],
            [24.2611, 63.1331],
            [24.3122, 63.1408],
            [24.358, 63.1189],
            [24.3221, 63.0675],
            [24.2824, 63.0646],
            [24.2797, 63.0334],
            [24.3105, 63.0192],
            [24.2958, 62.9991],
            [24.3141, 62.9776],
            [24.3524, 62.9642],
            [24.3486, 62.9186],
            [24.4002, 62.9166],
            [24.452, 62.898],
            [24.4532, 62.8715],
            [24.3697, 62.8626],
            [24.4003, 62.8195],
            [24.435, 62.7966],
            [24.4923, 62.7403],
            [24.5263, 62.6876],
            [24.5883, 62.6673],
            [24.517, 62.6362],
            [24.5095, 62.6227],
            [24.5599, 62.6091],
            [24.571, 62.5828],
            [24.5411, 62.5793],
            [24.5312, 62.5594],
            [24.5423, 62.5374],
            [24.5069, 62.5191],
            [24.5047, 62.4975],
            [24.4603, 62.4933],
            [24.4294, 62.4734],
            [24.4064, 62.5012],
            [24.3773, 62.5016],
            [24.3268, 62.4817],
            [24.3477, 62.4643],
            [24.3245, 62.4482],
            [24.2042, 62.4822],
            [24.1011, 62.4152],
            [24.1037, 62.4086],
            [24.0397, 62.3799],
            [24.0165, 62.3637],
            [23.9956, 62.3778],
            [23.9262, 62.3908],
            [23.9046, 62.4338],
            [23.914, 62.4568],
            [23.9013, 62.4839],
            [23.8714, 62.4849],
            [23.8701, 62.46],
            [23.7556, 62.4525],
            [23.6673, 62.4089],
            [23.5068, 62.4137],
            [23.521, 62.368],
            [23.4783, 62.3826],
            [23.449, 62.3761],
            [23.3917, 62.3898],
            [23.3385, 62.4248],
            [23.2782, 62.4084],
            [23.2408, 62.3586],
            [23.2121, 62.3327],
            [22.9015, 62.2705],
            [22.8347, 62.2717],
            [22.7526, 62.2737],
            [22.5944, 62.3108],
            [22.5264, 62.2117],
            [22.508, 62.214],
            [22.4497, 62.1995],
            [22.3291, 62.1059],
            [22.2013, 62.1029],
            [22.1131, 62.0331],
            [21.9783, 61.9816],
            [21.7123, 62.0224],
            [21.6514, 62.0215],
          ],
        ],
      },
      properties: {name: 'Southern Ostrobothnia', id: 'FI-03', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-03',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.6576, 62.6592],
            [28.6789, 62.6508],
            [28.793, 62.6421],
            [28.8654, 62.6017],
            [28.9462, 62.5784],
            [28.9442, 62.5667],
            [29.0101, 62.5369],
            [29.034, 62.532],
            [29.0402, 62.51],
            [29.1075, 62.4807],
            [29.0845, 62.4264],
            [29.0984, 62.4043],
            [29.1274, 62.3905],
            [29.2026, 62.4026],
            [29.234, 62.4186],
            [29.2716, 62.4084],
            [29.2983, 62.3886],
            [29.315, 62.3318],
            [29.3953, 62.253],
            [29.4333, 62.2367],
            [29.4123, 62.2005],
            [29.4631, 62.0817],
            [29.5116, 62.0398],
            [29.5712, 62.0247],
            [29.6638, 61.9771],
            [29.6199, 61.9366],
            [29.6189, 61.9141],
            [29.6985, 61.865],
            [29.6695, 61.8547],
            [29.694, 61.8233],
            [29.6802, 61.7932],
            [29.6426, 61.7623],
            [29.5755, 61.7367],
            [29.5635, 61.7223],
            [29.5437, 61.6846],
            [29.4437, 61.6644],
            [29.4036, 61.6518],
            [29.2916, 61.5936],
            [29.2635, 61.5582],
            [29.2208, 61.5598],
            [29.0522, 61.5348],
            [28.9612, 61.5411],
            [28.8918, 61.5283],
            [28.8177, 61.5359],
            [28.8181, 61.5035],
            [28.7972, 61.4789],
            [28.721, 61.4834],
            [28.6815, 61.4696],
            [28.651, 61.4743],
            [28.5983, 61.4698],
            [28.5835, 61.4785],
            [28.5288, 61.4796],
            [28.5286, 61.499],
            [28.4947, 61.5143],
            [28.4247, 61.4868],
            [28.4307, 61.4574],
            [28.348, 61.4318],
            [28.3127, 61.4165],
            [28.2897, 61.3909],
            [28.2566, 61.3796],
            [28.0613, 61.3548],
            [27.9311, 61.3501],
            [27.8565, 61.3511],
            [27.754, 61.3698],
            [27.6749, 61.3555],
            [27.5758, 61.3553],
            [27.4718, 61.3816],
            [27.4362, 61.3826],
            [27.3703, 61.3982],
            [27.2702, 61.4044],
            [27.1696, 61.4015],
            [27.1414, 61.3712],
            [27.096, 61.3699],
            [27.0779, 61.3811],
            [27.0255, 61.3863],
            [27.0042, 61.3668],
            [26.9667, 61.3569],
            [26.9957, 61.3447],
            [27.0398, 61.3513],
            [27.0872, 61.3323],
            [27.1463, 61.3178],
            [27.2183, 61.3316],
            [27.2674, 61.3246],
            [27.241, 61.3065],
            [27.2963, 61.3001],
            [27.313, 61.273],
            [27.3533, 61.2465],
            [27.3312, 61.2231],
            [27.3922, 61.2013],
            [27.364, 61.1941],
            [27.3587, 61.1727],
            [27.21, 61.1625],
            [27.0415, 61.152],
            [26.9177, 61.1839],
            [26.9336, 61.211],
            [26.8983, 61.2469],
            [26.8826, 61.2855],
            [26.85, 61.2836],
            [26.7759, 61.2684],
            [26.7468, 61.2307],
            [26.6629, 61.2072],
            [26.6319, 61.2215],
            [26.6715, 61.2441],
            [26.6325, 61.2916],
            [26.5628, 61.2715],
            [26.513, 61.2727],
            [26.483, 61.3231],
            [26.3886, 61.3263],
            [26.287, 61.3745],
            [26.3223, 61.3997],
            [26.2677, 61.4275],
            [26.2457, 61.4483],
            [26.2959, 61.4684],
            [26.267, 61.5069],
            [26.2962, 61.526],
            [26.3029, 61.5452],
            [26.2887, 61.5691],
            [26.3219, 61.5746],
            [26.3186, 61.6098],
            [26.3562, 61.6221],
            [26.3786, 61.6594],
            [26.4504, 61.6407],
            [26.5192, 61.6275],
            [26.5369, 61.6929],
            [26.5147, 61.7275],
            [26.4304, 61.7961],
            [26.3821, 61.8146],
            [26.3922, 61.8544],
            [26.3548, 61.8862],
            [26.3365, 61.9349],
            [26.3454, 61.9546],
            [26.4177, 61.9744],
            [26.2254, 62.1203],
            [26.3183, 62.2013],
            [26.4512, 62.2112],
            [26.4982, 62.2301],
            [26.5464, 62.2666],
            [26.6169, 62.2181],
            [26.6529, 62.2177],
            [26.6275, 62.2677],
            [26.634, 62.2835],
            [26.6118, 62.2955],
            [26.6837, 62.3085],
            [26.6702, 62.3426],
            [26.7759, 62.3557],
            [26.6838, 62.4045],
            [26.7087, 62.4274],
            [26.713, 62.4535],
            [26.7669, 62.4245],
            [26.8365, 62.4428],
            [26.8207, 62.4562],
            [26.8617, 62.4787],
            [26.8902, 62.4767],
            [26.9397, 62.4577],
            [26.9742, 62.4895],
            [26.9995, 62.4703],
            [27.0642, 62.4717],
            [27.0835, 62.4884],
            [27.1226, 62.4992],
            [27.1944, 62.4704],
            [27.2232, 62.4641],
            [27.2551, 62.4801],
            [27.2895, 62.4629],
            [27.3071, 62.4864],
            [27.3913, 62.5059],
            [27.4532, 62.484],
            [27.4696, 62.4719],
            [27.5737, 62.45],
            [27.6339, 62.4197],
            [27.6959, 62.3752],
            [27.763, 62.3678],
            [27.7375, 62.3421],
            [27.7421, 62.325],
            [27.7896, 62.313],
            [27.835, 62.2801],
            [27.8615, 62.2866],
            [27.9219, 62.283],
            [27.9725, 62.263],
            [28.0864, 62.2262],
            [28.2005, 62.2262],
            [28.286, 62.235],
            [28.3526, 62.2173],
            [28.4096, 62.2129],
            [28.5427, 62.184],
            [28.6045, 62.184],
            [28.6521, 62.2151],
            [28.6521, 62.2373],
            [28.5, 62.3258],
            [28.4096, 62.3015],
            [28.3906, 62.3059],
            [28.3954, 62.3347],
            [28.3811, 62.3568],
            [28.3258, 62.3603],
            [28.325, 62.3847],
            [28.2872, 62.3945],
            [28.2859, 62.415],
            [28.2482, 62.4255],
            [28.3013, 62.4551],
            [28.2933, 62.4772],
            [28.3604, 62.4624],
            [28.3855, 62.4634],
            [28.3892, 62.5103],
            [28.4036, 62.5334],
            [28.4009, 62.557],
            [28.4155, 62.5691],
            [28.4774, 62.5682],
            [28.5234, 62.5502],
            [28.5464, 62.5702],
            [28.6286, 62.6075],
            [28.6576, 62.6592],
          ],
        ],
      },
      properties: {name: 'Southern Savonia', id: 'FI-04', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-04',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.8721, 64.0374],
            [26.7818, 64.0587],
            [26.7702, 64.2644],
            [26.6904, 64.2765],
            [26.6827, 64.3167],
            [26.5561, 64.3217],
            [26.4678, 64.3556],
            [26.4848, 64.3893],
            [26.4306, 64.4303],
            [26.2972, 64.4805],
            [26.4654, 64.566],
            [26.552, 64.5599],
            [26.5367, 64.5763],
            [26.5803, 64.5902],
            [26.605, 64.6132],
            [26.6902, 64.6375],
            [26.8431, 64.6634],
            [26.8634, 64.6912],
            [26.8946, 64.707],
            [26.8964, 64.7367],
            [26.9131, 64.761],
            [27.1504, 64.8367],
            [27.358, 64.813],
            [27.4067, 64.8563],
            [27.3742, 64.8912],
            [27.4085, 64.9278],
            [27.3907, 64.9594],
            [27.2676, 65.0288],
            [27.3337, 65.092],
            [27.4574, 65.0884],
            [27.5745, 65.0952],
            [27.6724, 65.1225],
            [27.8984, 65.0922],
            [27.9448, 65.1807],
            [27.9527, 65.2053],
            [28.0387, 65.2394],
            [28.2692, 65.2608],
            [28.5949, 65.2858],
            [28.6334, 65.3553],
            [28.6602, 65.3733],
            [28.6519, 65.3908],
            [28.9618, 65.4483],
            [29.048, 65.4381],
            [29.1246, 65.4498],
            [29.2241, 65.4943],
            [29.2196, 65.5192],
            [29.3246, 65.5218],
            [29.5064, 65.4899],
            [29.5557, 65.5064],
            [29.7155, 65.4942],
            [29.7588, 65.498],
            [29.7367, 65.4733],
            [29.7357, 65.3741],
            [29.7499, 65.3483],
            [29.6509, 65.2908],
            [29.6129, 65.2767],
            [29.6053, 65.2616],
            [29.6383, 65.2322],
            [29.7697, 65.2297],
            [29.8522, 65.2183],
            [29.8968, 65.1939],
            [29.8236, 65.1444],
            [29.8998, 65.1057],
            [29.8348, 65.0927],
            [29.7379, 65.092],
            [29.6304, 65.0605],
            [29.6186, 65.0132],
            [29.6036, 64.9952],
            [29.6223, 64.9596],
            [29.614, 64.9267],
            [29.6505, 64.8659],
            [29.7036, 64.8376],
            [29.6923, 64.8193],
            [29.7431, 64.7907],
            [29.8721, 64.7907],
            [29.8935, 64.794],
            [30.0463, 64.7939],
            [30.0946, 64.766],
            [30.0458, 64.7417],
            [30.059, 64.713],
            [30.1402, 64.6494],
            [30.1227, 64.6261],
            [30.0603, 64.6157],
            [29.9958, 64.5876],
            [30.0038, 64.5764],
            [29.9931, 64.5341],
            [30.0306, 64.4831],
            [30.0632, 64.4514],
            [30.0512, 64.4021],
            [30.1151, 64.3654],
            [30.2184, 64.3504],
            [30.29, 64.3304],
            [30.3718, 64.2999],
            [30.3666, 64.2814],
            [30.3936, 64.2696],
            [30.4885, 64.2622],
            [30.5151, 64.2221],
            [30.4721, 64.2041],
            [30.5209, 64.1561],
            [30.5596, 64.1321],
            [30.5596, 64.102],
            [30.5333, 64.0488],
            [30.4539, 63.9842],
            [30.3629, 63.935],
            [30.3259, 63.91],
            [30.2641, 63.8221],
            [30.1503, 63.8003],
            [29.9773, 63.7575],
            [29.8782, 63.7492],
            [29.7805, 63.7042],
            [29.7272, 63.7477],
            [29.6853, 63.879],
            [29.5204, 63.87],
            [29.286, 63.851],
            [29.0246, 63.8441],
            [28.8903, 63.8362],
            [28.5183, 63.8814],
            [28.4601, 63.8706],
            [28.476, 63.8574],
            [28.3633, 63.7564],
            [28.2771, 63.6704],
            [28.2137, 63.7079],
            [28.1472, 63.7265],
            [28.064, 63.7826],
            [27.9178, 63.8515],
            [27.896, 63.85],
            [27.7892, 63.8891],
            [27.7491, 63.9089],
            [27.669, 63.9239],
            [27.6529, 63.9201],
            [27.5436, 63.9579],
            [27.4519, 63.9459],
            [27.4201, 63.9673],
            [27.2602, 63.9913],
            [27.1364, 63.9987],
            [27.1165, 63.9954],
            [27.0205, 64.0002],
            [26.8721, 64.0374],
          ],
        ],
      },
      properties: {name: 'Kainuu', id: 'FI-05', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-05',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.9149, 61.3247],
            [24.9274, 61.2831],
            [24.974, 61.275],
            [25.016, 61.2904],
            [25.0327, 61.2477],
            [25.1101, 61.2559],
            [25.1852, 61.2415],
            [25.2643, 61.1876],
            [25.2737, 61.1579],
            [25.2414, 61.1424],
            [25.2627, 61.1295],
            [25.2201, 61.106],
            [25.2022, 61.0776],
            [25.1554, 61.0645],
            [25.0615, 61.051],
            [25.0303, 61.0317],
            [25.0632, 60.9886],
            [25.0284, 60.962],
            [25.0122, 60.9295],
            [25.0978, 60.9192],
            [25.113, 60.8655],
            [25.082, 60.8412],
            [25.1129, 60.8162],
            [25.1086, 60.81],
            [25.1583, 60.7802],
            [25.1569, 60.7704],
            [25.0948, 60.7641],
            [25.063, 60.727],
            [25.123, 60.7477],
            [25.1496, 60.7328],
            [25.1487, 60.717],
            [25.0892, 60.6996],
            [25.1144, 60.6625],
            [25.0653, 60.6698],
            [25.0384, 60.6858],
            [24.9383, 60.6747],
            [24.8961, 60.6599],
            [24.7984, 60.6559],
            [24.769, 60.6599],
            [24.7421, 60.6485],
            [24.6941, 60.6503],
            [24.6173, 60.6681],
            [24.5696, 60.6376],
            [24.5662, 60.6022],
            [24.5791, 60.5866],
            [24.5512, 60.5607],
            [24.4278, 60.5537],
            [24.4025, 60.5794],
            [24.4128, 60.5935],
            [24.3526, 60.6127],
            [24.2981, 60.5855],
            [24.2895, 60.567],
            [24.2387, 60.6004],
            [24.2405, 60.6186],
            [24.1803, 60.6286],
            [24.1377, 60.6241],
            [24.0892, 60.629],
            [24.0455, 60.6451],
            [23.9738, 60.639],
            [23.9225, 60.6548],
            [23.9027, 60.6507],
            [23.8164, 60.6568],
            [23.7499, 60.653],
            [23.7186, 60.6643],
            [23.687, 60.6585],
            [23.6836, 60.6841],
            [23.6261, 60.695],
            [23.577, 60.6958],
            [23.5452, 60.7043],
            [23.5045, 60.7284],
            [23.5048, 60.7453],
            [23.4443, 60.7487],
            [23.3368, 60.7191],
            [23.2484, 60.7342],
            [23.1584, 60.7757],
            [23.2233, 60.8305],
            [23.1445, 60.8403],
            [23.1417, 60.8856],
            [23.1612, 60.8933],
            [23.269, 60.8899],
            [23.2625, 60.9147],
            [23.2913, 60.9177],
            [23.2978, 60.955],
            [23.2569, 60.9645],
            [23.2029, 61.0018],
            [23.2696, 61.0068],
            [23.2644, 61.0198],
            [23.3375, 61.0101],
            [23.3653, 61.0138],
            [23.4125, 60.9691],
            [23.4571, 60.9818],
            [23.548, 60.9995],
            [23.5887, 60.9846],
            [23.6226, 61.0031],
            [23.6909, 61.0046],
            [23.7174, 61.01],
            [23.7978, 60.9886],
            [23.8431, 60.9399],
            [23.8677, 60.9546],
            [23.8371, 61.0029],
            [23.8813, 61.0223],
            [23.868, 61.0369],
            [23.7963, 61.0255],
            [23.8049, 61.05],
            [23.761, 61.0576],
            [23.8068, 61.079],
            [23.8492, 61.0746],
            [23.8948, 61.0833],
            [24.0248, 61.1188],
            [24.1127, 61.1278],
            [24.1443, 61.1436],
            [24.2377, 61.1505],
            [24.3068, 61.1923],
            [24.2637, 61.2218],
            [24.2869, 61.2404],
            [24.3125, 61.2338],
            [24.4247, 61.2537],
            [24.4977, 61.2831],
            [24.5771, 61.3044],
            [24.6012, 61.2912],
            [24.6652, 61.2781],
            [24.72, 61.2802],
            [24.7357, 61.3179],
            [24.7722, 61.2692],
            [24.8901, 61.2873],
            [24.8815, 61.2976],
            [24.9149, 61.3247],
          ],
        ],
      },
      properties: {name: 'Tavastia Proper', id: 'FI-06', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-06',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [23.5997, 64.1888],
              [23.6535, 64.2066],
              [23.6602, 64.1906],
              [23.5997, 64.1888],
            ],
          ],
          [
            [
              [22.9945, 63.7896],
              [22.9269, 63.7778],
              [22.8825, 63.8055],
              [22.8754, 63.8308],
              [22.9045, 63.8367],
              [22.8857, 63.8637],
              [22.838, 63.8726],
              [22.8587, 63.8945],
              [22.9474, 63.8933],
              [22.9447, 63.879],
              [22.9752, 63.8632],
              [22.9598, 63.8478],
              [22.9996, 63.8081],
              [22.9945, 63.7896],
            ],
          ],
          [
            [
              [23.7029, 64.1718],
              [23.7944, 64.1458],
              [23.856, 64.1224],
              [23.9167, 64.1172],
              [23.9508, 64.0991],
              [24.009, 64.0933],
              [24.0907, 64.0721],
              [24.1049, 64.0068],
              [24.1818, 63.9413],
              [24.2227, 63.9345],
              [24.2533, 63.8778],
              [24.2897, 63.8636],
              [24.4307, 63.8399],
              [24.5654, 63.6917],
              [24.6243, 63.6514],
              [24.7563, 63.6142],
              [24.8224, 63.5888],
              [24.9223, 63.5244],
              [25.0396, 63.4593],
              [24.9122, 63.4336],
              [24.7799, 63.3603],
              [24.8101, 63.2777],
              [24.7606, 63.2673],
              [24.7687, 63.2193],
              [24.7526, 63.1982],
              [24.7382, 63.1481],
              [24.7793, 63.1346],
              [24.5363, 63.1579],
              [24.3648, 63.1172],
              [24.358, 63.1189],
              [24.3122, 63.1408],
              [24.2611, 63.1331],
              [24.2076, 63.1552],
              [24.2386, 63.1716],
              [24.1708, 63.2143],
              [24.1002, 63.2454],
              [24.0207, 63.2685],
              [23.9571, 63.2766],
              [23.8591, 63.2783],
              [23.7713, 63.3141],
              [23.7012, 63.3808],
              [23.7125, 63.384],
              [23.6633, 63.4362],
              [23.6524, 63.5329],
              [23.5836, 63.5549],
              [23.5605, 63.5696],
              [23.583, 63.5869],
              [23.5572, 63.6072],
              [23.4946, 63.5879],
              [23.4264, 63.6188],
              [23.5088, 63.6486],
              [23.5517, 63.6351],
              [23.6158, 63.6519],
              [23.6535, 63.6716],
              [23.7087, 63.6581],
              [23.7433, 63.6779],
              [23.6023, 63.7296],
              [23.5834, 63.7413],
              [23.4808, 63.7801],
              [23.4605, 63.7651],
              [23.4602, 63.7428],
              [23.3946, 63.7604],
              [23.3301, 63.7633],
              [23.2699, 63.7429],
              [23.2505, 63.7214],
              [23.2156, 63.7227],
              [23.076, 63.7756],
              [23.0208, 63.7916],
              [23.032, 63.8228],
              [23.0489, 63.8347],
              [23.0207, 63.8553],
              [23.0463, 63.878],
              [23.0967, 63.8722],
              [23.1583, 63.8921],
              [23.1696, 63.9151],
              [23.2255, 63.9107],
              [23.273, 63.9206],
              [23.2982, 63.9163],
              [23.3136, 63.9412],
              [23.3482, 63.9269],
              [23.4029, 63.9412],
              [23.4028, 63.9566],
              [23.3694, 63.9704],
              [23.4038, 63.9812],
              [23.3864, 64.054],
              [23.4224, 64.084],
              [23.4463, 64.0829],
              [23.4823, 64.0562],
              [23.6095, 64.0484],
              [23.6377, 64.0752],
              [23.5887, 64.1185],
              [23.6316, 64.1163],
              [23.7029, 64.1718],
            ],
          ],
        ],
      },
      properties: {name: 'Central Ostrobothnia', id: 'FI-07', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-07',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.713, 62.4535],
            [26.7087, 62.4274],
            [26.6838, 62.4045],
            [26.7759, 62.3557],
            [26.6702, 62.3426],
            [26.6837, 62.3085],
            [26.6118, 62.2955],
            [26.634, 62.2835],
            [26.6275, 62.2677],
            [26.6529, 62.2177],
            [26.6169, 62.2181],
            [26.5464, 62.2666],
            [26.4982, 62.2301],
            [26.4512, 62.2112],
            [26.3183, 62.2013],
            [26.2254, 62.1203],
            [26.4177, 61.9744],
            [26.3454, 61.9546],
            [26.3365, 61.9349],
            [26.3548, 61.8862],
            [26.3922, 61.8544],
            [26.3821, 61.8146],
            [26.4304, 61.7961],
            [26.5147, 61.7275],
            [26.5369, 61.6929],
            [26.5192, 61.6275],
            [26.4504, 61.6407],
            [26.3786, 61.6594],
            [26.3562, 61.6221],
            [26.3186, 61.6098],
            [26.3044, 61.6275],
            [26.317, 61.6404],
            [26.231, 61.6421],
            [26.1955, 61.6507],
            [26.1732, 61.6201],
            [26.1465, 61.6224],
            [26.0812, 61.6698],
            [26.0845, 61.7107],
            [26.0694, 61.7281],
            [26.0177, 61.7226],
            [25.9962, 61.7319],
            [25.9864, 61.7604],
            [25.9309, 61.782],
            [25.9102, 61.7666],
            [25.8636, 61.7628],
            [25.8376, 61.7721],
            [25.7736, 61.6862],
            [25.753, 61.7057],
            [25.67, 61.7179],
            [25.6119, 61.6816],
            [25.57, 61.6746],
            [25.503, 61.6776],
            [25.5093, 61.6327],
            [25.4393, 61.4888],
            [25.2782, 61.4817],
            [25.2569, 61.4747],
            [25.1881, 61.4801],
            [25.1479, 61.4614],
            [25.1227, 61.4739],
            [24.975, 61.4514],
            [24.9322, 61.4818],
            [24.9275, 61.5067],
            [24.9479, 61.5399],
            [24.8808, 61.5466],
            [24.9185, 61.5671],
            [24.9197, 61.5897],
            [24.8922, 61.5986],
            [24.8518, 61.5888],
            [24.8215, 61.5922],
            [24.7869, 61.6225],
            [24.8152, 61.6609],
            [24.8342, 61.6509],
            [24.8563, 61.6476],
            [24.8653, 61.6891],
            [24.7798, 61.6958],
            [24.7608, 61.7206],
            [24.7608, 61.759],
            [24.8035, 61.768],
            [24.8273, 61.7815],
            [24.6942, 61.8467],
            [24.5801, 61.9162],
            [24.5754, 61.9386],
            [24.5944, 61.9677],
            [24.6657, 61.9812],
            [24.699, 61.9923],
            [24.7349, 62.024],
            [24.7077, 62.0467],
            [24.671, 62.0528],
            [24.677, 62.116],
            [24.605, 62.1401],
            [24.5625, 62.1866],
            [24.5043, 62.1972],
            [24.4176, 62.1645],
            [24.3416, 62.1681],
            [24.2974, 62.1969],
            [24.2704, 62.26],
            [24.2567, 62.2531],
            [24.1579, 62.253],
            [24.0857, 62.2761],
            [24.0801, 62.2965],
            [24.052, 62.2985],
            [24.0276, 62.3162],
            [24.0165, 62.3637],
            [24.0397, 62.3799],
            [24.1037, 62.4086],
            [24.1011, 62.4152],
            [24.2042, 62.4822],
            [24.3245, 62.4482],
            [24.3477, 62.4643],
            [24.3268, 62.4817],
            [24.3773, 62.5016],
            [24.4064, 62.5012],
            [24.4294, 62.4734],
            [24.4603, 62.4933],
            [24.5047, 62.4975],
            [24.5069, 62.5191],
            [24.5423, 62.5374],
            [24.5312, 62.5594],
            [24.5411, 62.5793],
            [24.571, 62.5828],
            [24.5599, 62.6091],
            [24.5095, 62.6227],
            [24.517, 62.6362],
            [24.5883, 62.6673],
            [24.5263, 62.6876],
            [24.4923, 62.7403],
            [24.435, 62.7966],
            [24.4003, 62.8195],
            [24.3697, 62.8626],
            [24.4532, 62.8715],
            [24.452, 62.898],
            [24.4002, 62.9166],
            [24.3486, 62.9186],
            [24.3524, 62.9642],
            [24.3141, 62.9776],
            [24.2958, 62.9991],
            [24.3105, 63.0192],
            [24.2797, 63.0334],
            [24.2824, 63.0646],
            [24.3221, 63.0675],
            [24.358, 63.1189],
            [24.3648, 63.1172],
            [24.5363, 63.1579],
            [24.7793, 63.1346],
            [24.7382, 63.1481],
            [24.7526, 63.1982],
            [24.7687, 63.2193],
            [24.7606, 63.2673],
            [24.8101, 63.2777],
            [24.7799, 63.3603],
            [24.9122, 63.4336],
            [25.0396, 63.4593],
            [25.0864, 63.4687],
            [25.1169, 63.5018],
            [25.1449, 63.5755],
            [25.2555, 63.6139],
            [25.4912, 63.5335],
            [25.5429, 63.525],
            [25.7061, 63.5208],
            [25.7716, 63.5087],
            [25.8151, 63.495],
            [25.9002, 63.4409],
            [26.0019, 63.4301],
            [26.1445, 63.4585],
            [26.1821, 63.4399],
            [26.1268, 63.4067],
            [26.0777, 63.356],
            [26.1357, 63.331],
            [26.1485, 63.2762],
            [26.1826, 63.2404],
            [26.1899, 63.2137],
            [26.1539, 63.2099],
            [26.1712, 63.1473],
            [26.1952, 63.1347],
            [26.2254, 63.0901],
            [26.2494, 63.0729],
            [26.3398, 63.0369],
            [26.244, 63.0453],
            [26.2277, 63.0274],
            [26.3245, 62.9665],
            [26.2717, 62.9359],
            [26.2308, 62.9559],
            [26.129, 62.9531],
            [26.0889, 62.9397],
            [26.0455, 62.9345],
            [26.073, 62.8908],
            [26.1706, 62.8729],
            [26.1883, 62.8595],
            [26.1592, 62.8469],
            [26.1922, 62.8263],
            [26.2277, 62.8261],
            [26.3605, 62.7481],
            [26.4043, 62.7662],
            [26.3961, 62.7765],
            [26.4484, 62.7897],
            [26.5103, 62.7444],
            [26.534, 62.7096],
            [26.569, 62.6847],
            [26.5729, 62.655],
            [26.5949, 62.6417],
            [26.6031, 62.6131],
            [26.5817, 62.6047],
            [26.5627, 62.567],
            [26.5127, 62.5339],
            [26.4761, 62.52],
            [26.5806, 62.4795],
            [26.6369, 62.4519],
            [26.6885, 62.4637],
            [26.713, 62.4535],
          ],
        ],
      },
      properties: {name: 'Central Finland', id: 'FI-08', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-08',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.513, 61.2727],
            [26.5628, 61.2715],
            [26.6325, 61.2916],
            [26.6715, 61.2441],
            [26.6319, 61.2215],
            [26.6629, 61.2072],
            [26.7468, 61.2307],
            [26.7759, 61.2684],
            [26.85, 61.2836],
            [26.8826, 61.2855],
            [26.8983, 61.2469],
            [26.9336, 61.211],
            [26.9177, 61.1839],
            [27.0415, 61.152],
            [27.21, 61.1625],
            [27.2189, 61.141],
            [27.1897, 61.1208],
            [27.2243, 61.0928],
            [27.1855, 61.0858],
            [27.1515, 61.0689],
            [27.162, 61.0284],
            [27.1437, 61.0106],
            [27.175, 60.9981],
            [27.2112, 61.0082],
            [27.2246, 60.9777],
            [27.2498, 60.9668],
            [27.1827, 60.9508],
            [27.139, 60.9518],
            [27.2184, 60.9045],
            [27.3057, 60.8941],
            [27.3744, 60.88],
            [27.4552, 60.822],
            [27.5077, 60.7998],
            [27.5502, 60.7985],
            [27.6127, 60.8061],
            [27.6806, 60.8026],
            [27.713, 60.7888],
            [27.7355, 60.7616],
            [27.7754, 60.7593],
            [27.795, 60.7695],
            [27.8522, 60.7655],
            [27.8634, 60.7375],
            [27.904, 60.6976],
            [27.9554, 60.686],
            [27.9562, 60.667],
            [27.9945, 60.6692],
            [27.8752, 60.6048],
            [27.8407, 60.579],
            [27.8459, 60.5611],
            [27.787, 60.5458],
            [27.7691, 60.5735],
            [27.7381, 60.5705],
            [27.7192, 60.5414],
            [27.7445, 60.5303],
            [27.7475, 60.5041],
            [27.687, 60.4851],
            [27.6318, 60.4923],
            [27.5818, 60.4754],
            [27.568, 60.4881],
            [27.5129, 60.5033],
            [27.4638, 60.4976],
            [27.4546, 60.4744],
            [27.4164, 60.4834],
            [27.3755, 60.5054],
            [27.3404, 60.506],
            [27.2095, 60.5327],
            [27.1709, 60.5194],
            [27.1356, 60.5227],
            [27.1119, 60.5404],
            [27.0271, 60.5233],
            [26.9454, 60.4461],
            [26.9004, 60.4724],
            [26.8886, 60.4637],
            [26.9329, 60.4314],
            [26.8948, 60.424],
            [26.8469, 60.4467],
            [26.8775, 60.4668],
            [26.8293, 60.4668],
            [26.8018, 60.4555],
            [26.7376, 60.486],
            [26.7518, 60.4507],
            [26.7154, 60.4329],
            [26.657, 60.4314],
            [26.643, 60.4555],
            [26.5875, 60.4614],
            [26.5263, 60.449],
            [26.4901, 60.4655],
            [26.4607, 60.4903],
            [26.4743, 60.5142],
            [26.515, 60.5271],
            [26.4938, 60.5443],
            [26.547, 60.5557],
            [26.5524, 60.5758],
            [26.5969, 60.6084],
            [26.6283, 60.6143],
            [26.6748, 60.6106],
            [26.7006, 60.5898],
            [26.758, 60.5767],
            [26.6873, 60.6502],
            [26.6505, 60.6527],
            [26.6037, 60.6436],
            [26.6, 60.6295],
            [26.5446, 60.5962],
            [26.4882, 60.5987],
            [26.4864, 60.6149],
            [26.4576, 60.6423],
            [26.4341, 60.6509],
            [26.3521, 60.662],
            [26.3292, 60.6967],
            [26.3342, 60.7145],
            [26.3041, 60.7315],
            [26.3051, 60.7494],
            [26.2595, 60.7617],
            [26.2138, 60.7585],
            [26.2044, 60.8041],
            [26.1273, 60.8331],
            [26.0813, 60.8371],
            [26.0962, 60.8617],
            [26.125, 60.8711],
            [26.1275, 60.8981],
            [26.1732, 60.9301],
            [26.0631, 60.9386],
            [26.0451, 60.9736],
            [26.0771, 60.9728],
            [26.1184, 60.9862],
            [26.1347, 61.0287],
            [26.1054, 61.0496],
            [26.1098, 61.0783],
            [26.0487, 61.0972],
            [26.1064, 61.1413],
            [26.1411, 61.1559],
            [26.1707, 61.1395],
            [26.2378, 61.1318],
            [26.2698, 61.1501],
            [26.2696, 61.1638],
            [26.343, 61.1721],
            [26.3864, 61.1887],
            [26.4006, 61.2159],
            [26.4308, 61.2363],
            [26.4491, 61.2617],
            [26.513, 61.2727],
          ],
        ],
      },
      properties: {name: 'Kymenlaakso', id: 'FI-09', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-09',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [24.5991, 65.704],
              [24.6027, 65.6776],
              [24.5857, 65.6576],
              [24.5157, 65.6591],
              [24.515, 65.6761],
              [24.5715, 65.6819],
              [24.5991, 65.704],
            ],
          ],
          [
            [
              [29.5765, 66.4334],
              [29.2635, 66.4327],
              [29.2603, 66.4089],
              [28.7006, 66.4866],
              [28.6709, 66.4141],
              [28.6232, 66.4137],
              [28.5541, 66.3825],
              [28.5442, 66.3319],
              [28.5961, 66.2686],
              [28.7521, 66.2561],
              [28.7634, 66.2389],
              [28.7408, 66.2064],
              [28.7735, 66.1851],
              [28.7216, 66.1613],
              [28.719, 66.1364],
              [28.692, 66.1278],
              [28.611, 66.128],
              [28.6345, 66.0722],
              [28.7483, 66.0515],
              [28.7504, 66.0029],
              [28.6791, 66.0012],
              [28.6569, 65.9879],
              [28.603, 65.9888],
              [28.6015, 66.0005],
              [28.4889, 66.0041],
              [28.4868, 65.9909],
              [28.4184, 65.9878],
              [28.3773, 65.9619],
              [28.4373, 65.9477],
              [28.4638, 65.9194],
              [28.5117, 65.8926],
              [28.518, 65.8478],
              [28.469, 65.8465],
              [28.2288, 65.8028],
              [28.1502, 65.8162],
              [27.7687, 65.8278],
              [27.7722, 65.8423],
              [27.6989, 65.9014],
              [27.6592, 65.9607],
              [27.6028, 65.9551],
              [27.569, 65.9293],
              [27.5445, 65.9259],
              [27.45, 65.9625],
              [27.1147, 65.7522],
              [27.0769, 65.7308],
              [27.0393, 65.7393],
              [26.8467, 65.7031],
              [26.6621, 65.7105],
              [26.3878, 65.7142],
              [26.1647, 65.7254],
              [26.1634, 65.8225],
              [25.7971, 65.7415],
              [25.4266, 65.7434],
              [25.3642, 65.7287],
              [25.3127, 65.6869],
              [25.195, 65.656],
              [25.1341, 65.6082],
              [25.0889, 65.5892],
              [25.06, 65.6217],
              [24.9505, 65.633],
              [24.9034, 65.6461],
              [24.8729, 65.6707],
              [24.841, 65.6606],
              [24.7403, 65.66],
              [24.7273, 65.6401],
              [24.6967, 65.6314],
              [24.6619, 65.6399],
              [24.6794, 65.6599],
              [24.611, 65.6943],
              [24.5576, 65.7426],
              [24.4587, 65.7526],
              [24.5055, 65.7759],
              [24.4237, 65.7716],
              [24.3494, 65.791],
              [24.2904, 65.7971],
              [24.2393, 65.8159],
              [24.2272, 65.7962],
              [24.2364, 65.7745],
              [24.1574, 65.7531],
              [24.1446, 65.7793],
              [24.1676, 65.7881],
              [24.1433, 65.8434],
              [24.1503, 65.8659],
              [24.1155, 65.8991],
              [24.1173, 65.9279],
              [24.0873, 65.9327],
              [24.0415, 65.9635],
              [24.0471, 65.9994],
              [24.0019, 66.0301],
              [23.9604, 66.0691],
              [23.9396, 66.0764],
              [23.9407, 66.1217],
              [23.9191, 66.1375],
              [23.9276, 66.1561],
              [23.8923, 66.1669],
              [23.834, 66.1699],
              [23.7886, 66.187],
              [23.7291, 66.1948],
              [23.7028, 66.2203],
              [23.6989, 66.2447],
              [23.6718, 66.2589],
              [23.652, 66.3032],
              [23.6616, 66.3532],
              [23.6865, 66.3782],
              [23.6411, 66.4299],
              [23.6509, 66.4562],
              [23.7186, 66.4771],
              [23.7377, 66.5056],
              [23.794, 66.5184],
              [23.7852, 66.5373],
              [23.8555, 66.5583],
              [23.8739, 66.5765],
              [23.8847, 66.6155],
              [23.8652, 66.6551],
              [23.8865, 66.6734],
              [23.907, 66.7205],
              [23.8762, 66.754],
              [23.9628, 66.7835],
              [23.9947, 66.8222],
              [23.9369, 66.8833],
              [23.8652, 66.9189],
              [23.8607, 66.9548],
              [23.8288, 66.9611],
              [23.8165, 66.9803],
              [23.7636, 67.0024],
              [23.6725, 67.0654],
              [23.6547, 67.1032],
              [23.5518, 67.1703],
              [23.5876, 67.1902],
              [23.5952, 67.2087],
              [23.547, 67.2257],
              [23.5636, 67.2622],
              [23.6804, 67.2828],
              [23.7194, 67.2835],
              [23.7562, 67.3276],
              [23.7205, 67.3915],
              [23.751, 67.4072],
              [23.7635, 67.4295],
              [23.71, 67.4458],
              [23.672, 67.4431],
              [23.5497, 67.4607],
              [23.492, 67.4469],
              [23.4101, 67.4675],
              [23.3937, 67.4851],
              [23.4056, 67.5013],
              [23.4848, 67.5389],
              [23.4575, 67.5621],
              [23.5057, 67.5632],
              [23.5405, 67.579],
              [23.5393, 67.6051],
              [23.558, 67.62],
              [23.4958, 67.6611],
              [23.4987, 67.6813],
              [23.4758, 67.7394],
              [23.4916, 67.7536],
              [23.4943, 67.7908],
              [23.4736, 67.8174],
              [23.4778, 67.8421],
              [23.5181, 67.8844],
              [23.5528, 67.8974],
              [23.644, 67.9166],
              [23.665, 67.9413],
              [23.6515, 67.961],
              [23.5753, 67.9722],
              [23.5324, 68.0061],
              [23.4709, 68.0193],
              [23.4652, 68.0306],
              [23.403, 68.0425],
              [23.3721, 68.0613],
              [23.3377, 68.0994],
              [23.3052, 68.1491],
              [23.2794, 68.1561],
              [23.1645, 68.1332],
              [23.1393, 68.1551],
              [23.1439, 68.246],
              [23.0765, 68.2671],
              [23.0686, 68.3004],
              [22.9861, 68.3202],
              [22.9696, 68.3323],
              [22.9168, 68.3336],
              [22.8914, 68.3494],
              [22.8405, 68.3631],
              [22.8438, 68.3835],
              [22.7992, 68.3936],
              [22.731, 68.3855],
              [22.6817, 68.4098],
              [22.616, 68.4271],
              [22.574, 68.422],
              [22.552, 68.4342],
              [22.4665, 68.4418],
              [22.4322, 68.4645],
              [22.4037, 68.466],
              [22.3403, 68.448],
              [22.3498, 68.4805],
              [22.296, 68.4837],
              [22.202, 68.4805],
              [22.1509, 68.4706],
              [22.0179, 68.4951],
              [21.9923, 68.5337],
              [21.9685, 68.5513],
              [21.888, 68.5846],
              [21.8423, 68.5905],
              [21.7794, 68.5854],
              [21.6992, 68.5979],
              [21.7085, 68.6261],
              [21.6186, 68.6639],
              [21.5642, 68.6755],
              [21.5018, 68.6764],
              [21.4206, 68.6963],
              [21.4113, 68.7484],
              [21.3901, 68.7645],
              [21.2995, 68.7617],
              [21.2371, 68.8125],
              [21.1868, 68.8242],
              [21.1233, 68.8546],
              [20.9914, 68.8969],
              [20.9026, 68.9011],
              [20.8454, 68.9357],
              [20.8915, 68.9407],
              [20.9133, 68.9592],
              [20.8786, 68.9863],
              [20.8368, 68.9951],
              [20.815, 69.0163],
              [20.772, 69.0329],
              [20.7046, 69.0436],
              [20.6352, 69.0445],
              [20.5501, 69.0593],
              [20.7228, 69.1207],
              [21.0633, 69.0372],
              [21.1144, 69.1045],
              [21.0627, 69.1229],
              [21.0517, 69.1416],
              [20.9947, 69.1935],
              [21.0122, 69.2249],
              [21.1008, 69.2616],
              [21.2848, 69.313],
              [21.6287, 69.2778],
              [21.7361, 69.2114],
              [21.9944, 69.07],
              [22.181, 68.9581],
              [22.2, 68.9196],
              [22.3472, 68.8276],
              [22.3834, 68.7182],
              [22.5412, 68.7441],
              [22.605, 68.7274],
              [22.8049, 68.6876],
              [23.0512, 68.6893],
              [23.1735, 68.6284],
              [23.4466, 68.6919],
              [23.6791, 68.7052],
              [23.7362, 68.7499],
              [23.7811, 68.8201],
              [23.8786, 68.8371],
              [23.9911, 68.8274],
              [24.0817, 68.7803],
              [24.1644, 68.7906],
              [24.1599, 68.7532],
              [24.2144, 68.7453],
              [24.2625, 68.7258],
              [24.3103, 68.7168],
              [24.6119, 68.6821],
              [24.7864, 68.6371],
              [24.8642, 68.562],
              [24.9066, 68.5552],
              [24.9228, 68.6103],
              [25.0264, 68.6246],
              [25.0834, 68.6235],
              [25.1186, 68.64],
              [25.1103, 68.6816],
              [25.1269, 68.7521],
              [25.1614, 68.7998],
              [25.2343, 68.8259],
              [25.2478, 68.8417],
              [25.3008, 68.8613],
              [25.4868, 68.9052],
              [25.5467, 68.8868],
              [25.5931, 68.883],
              [25.6601, 68.9071],
              [25.7177, 68.9663],
              [25.7303, 68.9908],
              [25.7779, 69.013],
              [25.7523, 69.0395],
              [25.7562, 69.0583],
              [25.7272, 69.0803],
              [25.7248, 69.1158],
              [25.7458, 69.1384],
              [25.6988, 69.197],
              [25.7142, 69.2126],
              [25.7058, 69.2546],
              [25.7335, 69.263],
              [25.7524, 69.2869],
              [25.747, 69.3179],
              [25.8414, 69.3937],
              [25.8016, 69.4221],
              [25.8402, 69.4652],
              [25.8701, 69.4749],
              [25.8574, 69.4941],
              [25.8848, 69.5269],
              [25.8482, 69.548],
              [25.9426, 69.5725],
              [25.9835, 69.6097],
              [25.9622, 69.647],
              [25.9003, 69.6629],
              [26.0098, 69.7184],
              [26.1278, 69.7331],
              [26.1983, 69.7754],
              [26.2559, 69.7976],
              [26.2534, 69.8164],
              [26.3387, 69.8361],
              [26.3885, 69.8532],
              [26.4222, 69.8812],
              [26.4159, 69.9018],
              [26.4611, 69.9194],
              [26.4708, 69.9382],
              [26.6778, 69.9636],
              [26.7395, 69.9463],
              [26.791, 69.9589],
              [26.8546, 69.96],
              [26.8882, 69.9313],
              [26.966, 69.9395],
              [27.0479, 69.9106],
              [27.1077, 69.9174],
              [27.1578, 69.9324],
              [27.3043, 69.9549],
              [27.2823, 69.9751],
              [27.3193, 69.9903],
              [27.3604, 69.9912],
              [27.4394, 70.0203],
              [27.5329, 70.0234],
              [27.562, 70.0593],
              [27.6206, 70.075],
              [27.691, 70.0764],
              [27.7538, 70.0646],
              [27.8043, 70.077],
              [27.9651, 70.0921],
              [27.9931, 70.0133],
              [28.1698, 69.9205],
              [28.3537, 69.8811],
              [28.3374, 69.8497],
              [28.4142, 69.8171],
              [28.9202, 69.7302],
              [29.1419, 69.695],
              [29.1771, 69.6405],
              [29.3448, 69.4784],
              [29.2247, 69.3966],
              [28.8397, 69.2247],
              [28.8386, 69.1731],
              [28.8128, 69.1119],
              [28.8669, 69.0756],
              [28.9397, 69.0516],
              [28.5108, 68.9318],
              [28.4253, 68.9151],
              [28.4783, 68.8854],
              [28.6743, 68.8861],
              [28.8097, 68.8692],
              [28.7104, 68.7315],
              [28.4393, 68.5405],
              [28.6587, 68.1952],
              [29.3337, 68.0748],
              [29.4623, 67.9648],
              [29.4866, 67.9504],
              [29.5723, 67.8781],
              [29.6041, 67.8632],
              [29.6935, 67.7937],
              [29.9549, 67.7026],
              [30.0293, 67.6805],
              [29.9351, 67.5231],
              [29.8418, 67.4779],
              [29.7146, 67.3895],
              [29.6623, 67.3706],
              [29.6224, 67.3446],
              [29.6469, 67.3364],
              [29.583, 67.3167],
              [29.5285, 67.31],
              [29.5206, 67.2804],
              [29.0776, 66.9952],
              [29.0457, 66.9598],
              [29.0392, 66.9254],
              [29.066, 66.8551],
              [29.1229, 66.8049],
              [29.1127, 66.7912],
              [29.1577, 66.7796],
              [29.3655, 66.6386],
              [29.388, 66.5981],
              [29.477, 66.5434],
              [29.5765, 66.4334],
            ],
          ],
        ],
      },
      properties: {name: 'Lapland', id: 'FI-10', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-10',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.0165, 62.3637],
            [24.0276, 62.3162],
            [24.052, 62.2985],
            [24.0801, 62.2965],
            [24.0857, 62.2761],
            [24.1579, 62.253],
            [24.2567, 62.2531],
            [24.2704, 62.26],
            [24.2974, 62.1969],
            [24.3416, 62.1681],
            [24.4176, 62.1645],
            [24.5043, 62.1972],
            [24.5625, 62.1866],
            [24.605, 62.1401],
            [24.677, 62.116],
            [24.671, 62.0528],
            [24.7077, 62.0467],
            [24.7349, 62.024],
            [24.699, 61.9923],
            [24.6657, 61.9812],
            [24.5944, 61.9677],
            [24.5754, 61.9386],
            [24.5801, 61.9162],
            [24.6942, 61.8467],
            [24.8273, 61.7815],
            [24.8035, 61.768],
            [24.7608, 61.759],
            [24.7608, 61.7206],
            [24.7798, 61.6958],
            [24.8653, 61.6891],
            [24.8563, 61.6476],
            [24.8342, 61.6509],
            [24.8152, 61.6609],
            [24.7869, 61.6225],
            [24.8215, 61.5922],
            [24.8518, 61.5888],
            [24.8922, 61.5986],
            [24.9197, 61.5897],
            [24.9185, 61.5671],
            [24.8808, 61.5466],
            [24.9479, 61.5399],
            [24.9275, 61.5067],
            [24.9322, 61.4818],
            [24.975, 61.4514],
            [24.8547, 61.4777],
            [24.8746, 61.4379],
            [24.913, 61.416],
            [24.8912, 61.3548],
            [24.9149, 61.3247],
            [24.8815, 61.2976],
            [24.8901, 61.2873],
            [24.7722, 61.2692],
            [24.7357, 61.3179],
            [24.72, 61.2802],
            [24.6652, 61.2781],
            [24.6012, 61.2912],
            [24.5771, 61.3044],
            [24.4977, 61.2831],
            [24.4247, 61.2537],
            [24.3125, 61.2338],
            [24.2869, 61.2404],
            [24.2637, 61.2218],
            [24.3068, 61.1923],
            [24.2377, 61.1505],
            [24.1443, 61.1436],
            [24.1127, 61.1278],
            [24.0248, 61.1188],
            [23.8948, 61.0833],
            [23.8492, 61.0746],
            [23.8068, 61.079],
            [23.761, 61.0576],
            [23.8049, 61.05],
            [23.7963, 61.0255],
            [23.868, 61.0369],
            [23.8813, 61.0223],
            [23.8371, 61.0029],
            [23.8677, 60.9546],
            [23.8431, 60.9399],
            [23.7978, 60.9886],
            [23.7174, 61.01],
            [23.6909, 61.0046],
            [23.6226, 61.0031],
            [23.5887, 60.9846],
            [23.548, 60.9995],
            [23.4571, 60.9818],
            [23.4125, 60.9691],
            [23.3653, 61.0138],
            [23.3375, 61.0101],
            [23.2644, 61.0198],
            [23.2915, 61.0575],
            [23.2575, 61.0858],
            [23.2744, 61.1092],
            [23.386, 61.1301],
            [23.3068, 61.1681],
            [23.2649, 61.1826],
            [23.2773, 61.2201],
            [23.235, 61.2083],
            [23.172, 61.2127],
            [23.1095, 61.1974],
            [22.9844, 61.1923],
            [22.9387, 61.2055],
            [22.8605, 61.2361],
            [22.8487, 61.23],
            [22.7737, 61.2465],
            [22.6488, 61.2473],
            [22.6416, 61.2689],
            [22.6147, 61.3004],
            [22.5624, 61.3315],
            [22.5946, 61.3429],
            [22.5628, 61.3948],
            [22.6406, 61.3861],
            [22.6707, 61.4265],
            [22.7355, 61.4338],
            [22.7176, 61.4595],
            [22.7267, 61.477],
            [22.6604, 61.4788],
            [22.6058, 61.5436],
            [22.6448, 61.5632],
            [22.6481, 61.5841],
            [22.7001, 61.6006],
            [22.7075, 61.624],
            [22.729, 61.6452],
            [22.6901, 61.6616],
            [22.6587, 61.6552],
            [22.6513, 61.6969],
            [22.6953, 61.7034],
            [22.6836, 61.7254],
            [22.8081, 61.7706],
            [22.8323, 61.8166],
            [22.7718, 61.8551],
            [22.7873, 61.8814],
            [22.7391, 61.8901],
            [22.7438, 61.9109],
            [22.6916, 61.9214],
            [22.6522, 61.9519],
            [22.6601, 61.9657],
            [22.6022, 61.9669],
            [22.539, 61.9969],
            [22.607, 62.0372],
            [22.6876, 62.0541],
            [22.7234, 62.0527],
            [22.7833, 62.061],
            [22.7738, 62.1562],
            [22.863, 62.1722],
            [22.8556, 62.1995],
            [22.8244, 62.2399],
            [22.8347, 62.2717],
            [22.9015, 62.2705],
            [23.2121, 62.3327],
            [23.2408, 62.3586],
            [23.2782, 62.4084],
            [23.3385, 62.4248],
            [23.3917, 62.3898],
            [23.449, 62.3761],
            [23.4783, 62.3826],
            [23.521, 62.368],
            [23.5068, 62.4137],
            [23.6673, 62.4089],
            [23.7556, 62.4525],
            [23.8701, 62.46],
            [23.8714, 62.4849],
            [23.9013, 62.4839],
            [23.914, 62.4568],
            [23.9046, 62.4338],
            [23.9262, 62.3908],
            [23.9956, 62.3778],
            [24.0165, 62.3637],
          ],
        ],
      },
      properties: {name: 'Pirkanmaa', id: 'FI-11', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-11',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [21.2308, 62.394],
              [21.2534, 62.3825],
              [21.243, 62.3368],
              [21.2143, 62.3437],
              [21.2181, 62.3816],
              [21.2308, 62.394],
            ],
          ],
          [
            [
              [21.0743, 62.5809],
              [21.0971, 62.5699],
              [21.0822, 62.5395],
              [21.0534, 62.5541],
              [21.0743, 62.5809],
            ],
          ],
          [
            [
              [21.0577, 62.744],
              [21.0934, 62.7271],
              [21.0543, 62.7168],
              [21.0577, 62.744],
            ],
          ],
          [
            [
              [21.181, 62.8701],
              [21.2215, 62.8594],
              [21.2242, 62.8403],
              [21.1791, 62.8324],
              [21.0967, 62.8324],
              [21.1418, 62.8611],
              [21.181, 62.8701],
            ],
          ],
          [
            [
              [21.1802, 62.9293],
              [21.237, 62.8952],
              [21.214, 62.8905],
              [21.1739, 62.9113],
              [21.1802, 62.9293],
            ],
          ],
          [
            [
              [21.3996, 62.9602],
              [21.4113, 62.9498],
              [21.3699, 62.9305],
              [21.3514, 62.9417],
              [21.3996, 62.9602],
            ],
          ],
          [
            [
              [21.1508, 63.013],
              [21.2021, 62.9904],
              [21.2006, 62.9571],
              [21.1628, 62.9668],
              [21.1226, 63.0002],
              [21.1508, 63.013],
            ],
          ],
          [
            [
              [21.3753, 63.0468],
              [21.4299, 63.0298],
              [21.4299, 63.0122],
              [21.3556, 63.0308],
              [21.3753, 63.0468],
            ],
          ],
          [
            [
              [21.4418, 63.0847],
              [21.4404, 63.0587],
              [21.4585, 63.0433],
              [21.4288, 63.0314],
              [21.3996, 63.0573],
              [21.4418, 63.0847],
            ],
          ],
          [
            [
              [21.7334, 63.3143],
              [21.784, 63.2947],
              [21.7828, 63.277],
              [21.7455, 63.2758],
              [21.721, 63.2925],
              [21.7334, 63.3143],
            ],
          ],
          [
            [
              [21.2359, 63.3219],
              [21.2509, 63.2894],
              [21.2275, 63.2754],
              [21.2192, 63.2521],
              [21.2355, 63.2388],
              [21.3232, 63.2554],
              [21.3805, 63.2789],
              [21.4249, 63.2741],
              [21.4578, 63.2461],
              [21.4236, 63.2038],
              [21.3747, 63.1898],
              [21.362, 63.2145],
              [21.305, 63.1568],
              [21.2333, 63.1711],
              [21.2364, 63.1857],
              [21.1687, 63.186],
              [21.1408, 63.2138],
              [21.151, 63.2309],
              [21.0884, 63.2827],
              [21.1103, 63.2951],
              [21.1556, 63.2856],
              [21.1793, 63.3031],
              [21.2359, 63.3219],
            ],
          ],
          [
            [
              [21.6961, 63.3302],
              [21.6961, 63.3005],
              [21.6718, 63.2865],
              [21.6748, 63.2607],
              [21.6393, 63.2649],
              [21.6342, 63.3033],
              [21.6612, 63.3239],
              [21.6961, 63.3302],
            ],
          ],
          [
            [
              [22.2228, 63.3424],
              [22.2418, 63.3032],
              [22.2251, 63.2785],
              [22.1699, 63.2818],
              [22.1632, 63.257],
              [22.1144, 63.2591],
              [22.0483, 63.2884],
              [21.9752, 63.2819],
              [21.977, 63.3016],
              [22.0414, 63.3296],
              [22.0784, 63.2915],
              [22.0989, 63.2988],
              [22.0656, 63.3223],
              [22.1111, 63.3373],
              [22.1522, 63.3181],
              [22.2125, 63.3202],
              [22.2228, 63.3424],
            ],
          ],
          [
            [
              [21.3455, 63.3724],
              [21.3976, 63.3724],
              [21.4211, 63.3511],
              [21.39, 63.3313],
              [21.3882, 63.3132],
              [21.3448, 63.3003],
              [21.3173, 63.3193],
              [21.2812, 63.3111],
              [21.2701, 63.3367],
              [21.2465, 63.348],
              [21.3089, 63.3696],
              [21.3455, 63.3724],
            ],
          ],
          [
            [
              [22.0247, 63.3934],
              [22.0973, 63.3891],
              [22.0992, 63.367],
              [22.0257, 63.3494],
              [21.9474, 63.3446],
              [21.9935, 63.3888],
              [22.0247, 63.3934],
            ],
          ],
          [
            [
              [21.2524, 63.3991],
              [21.2505, 63.3711],
              [21.22, 63.3631],
              [21.1871, 63.3725],
              [21.203, 63.3909],
              [21.2524, 63.3991],
            ],
          ],
          [
            [
              [23.0208, 63.7916],
              [23.076, 63.7756],
              [23.2156, 63.7227],
              [23.2505, 63.7214],
              [23.2699, 63.7429],
              [23.3301, 63.7633],
              [23.3946, 63.7604],
              [23.4602, 63.7428],
              [23.4605, 63.7651],
              [23.4808, 63.7801],
              [23.5834, 63.7413],
              [23.6023, 63.7296],
              [23.7433, 63.6779],
              [23.7087, 63.6581],
              [23.6535, 63.6716],
              [23.6158, 63.6519],
              [23.5517, 63.6351],
              [23.5088, 63.6486],
              [23.4264, 63.6188],
              [23.4946, 63.5879],
              [23.5572, 63.6072],
              [23.583, 63.5869],
              [23.5605, 63.5696],
              [23.5836, 63.5549],
              [23.6524, 63.5329],
              [23.6633, 63.4362],
              [23.7125, 63.384],
              [23.7012, 63.3808],
              [23.6315, 63.4005],
              [23.5225, 63.4653],
              [23.4735, 63.4799],
              [23.4226, 63.484],
              [23.3959, 63.4996],
              [23.2674, 63.4712],
              [23.2112, 63.4],
              [23.118, 63.3543],
              [23.0881, 63.3676],
              [23.0405, 63.3627],
              [23.03, 63.3802],
              [22.9778, 63.3719],
              [22.9855, 63.3339],
              [22.902, 63.3543],
              [22.8337, 63.3473],
              [22.8109, 63.3574],
              [22.744, 63.3598],
              [22.7611, 63.3426],
              [22.7236, 63.3381],
              [22.6541, 63.3182],
              [22.6796, 63.2983],
              [22.5657, 63.2671],
              [22.5607, 63.2546],
              [22.6172, 63.2386],
              [22.6356, 63.2116],
              [22.599, 63.2023],
              [22.6246, 63.181],
              [22.6781, 63.193],
              [22.6823, 63.1259],
              [22.6496, 63.1259],
              [22.6416, 63.0974],
              [22.5366, 63.0666],
              [22.5245, 63.0501],
              [22.469, 63.0053],
              [22.4636, 62.9798],
              [22.4181, 62.9531],
              [22.43, 62.945],
              [22.39, 62.9131],
              [22.409, 62.8671],
              [22.3751, 62.8583],
              [22.3065, 62.7966],
              [22.2773, 62.7921],
              [22.2023, 62.7522],
              [22.1268, 62.7676],
              [22.0645, 62.7648],
              [21.9155, 62.7959],
              [21.8455, 62.819],
              [21.8347, 62.8459],
              [21.7759, 62.8637],
              [21.7681, 62.8473],
              [21.7331, 62.8213],
              [21.7339, 62.757],
              [21.7513, 62.7226],
              [21.7955, 62.6951],
              [21.6855, 62.6469],
              [21.6511, 62.6241],
              [21.5943, 62.606],
              [21.5998, 62.5599],
              [21.6134, 62.5419],
              [21.547, 62.4489],
              [21.5531, 62.3969],
              [21.526, 62.3802],
              [21.5612, 62.3589],
              [21.6496, 62.3676],
              [21.6881, 62.3549],
              [21.6711, 62.2773],
              [21.6742, 62.2611],
              [21.7301, 62.2553],
              [21.769, 62.2196],
              [21.7105, 62.2141],
              [21.6683, 62.1884],
              [21.7065, 62.1686],
              [21.6969, 62.1454],
              [21.645, 62.1506],
              [21.6461, 62.1158],
              [21.6296, 62.1009],
              [21.6539, 62.0614],
              [21.6319, 62.0417],
              [21.6514, 62.0215],
              [21.5253, 62.027],
              [21.4298, 62.0107],
              [21.3839, 61.978],
              [21.3333, 61.9587],
              [21.331, 62.0048],
              [21.2892, 61.9885],
              [21.3027, 62.0392],
              [21.3157, 62.0559],
              [21.3252, 62.1055],
              [21.317, 62.1482],
              [21.3606, 62.1419],
              [21.3622, 62.1856],
              [21.4096, 62.2096],
              [21.4096, 62.2311],
              [21.3776, 62.2319],
              [21.3878, 62.2565],
              [21.3196, 62.2461],
              [21.3452, 62.275],
              [21.3386, 62.2964],
              [21.3055, 62.3123],
              [21.3217, 62.3307],
              [21.3562, 62.3341],
              [21.3659, 62.3715],
              [21.3287, 62.3713],
              [21.2843, 62.3941],
              [21.2503, 62.3911],
              [21.224, 62.4113],
              [21.2476, 62.4375],
              [21.1696, 62.4233],
              [21.1787, 62.463],
              [21.1135, 62.4897],
              [21.1349, 62.4997],
              [21.1579, 62.5308],
              [21.1147, 62.5241],
              [21.1103, 62.5519],
              [21.154, 62.5598],
              [21.1542, 62.5733],
              [21.1983, 62.5928],
              [21.1939, 62.6266],
              [21.1453, 62.6182],
              [21.1446, 62.6396],
              [21.0781, 62.6175],
              [21.0909, 62.669],
              [21.1741, 62.7215],
              [21.1846, 62.7528],
              [21.1542, 62.7491],
              [21.1317, 62.7749],
              [21.1323, 62.7961],
              [21.1756, 62.8161],
              [21.251, 62.8359],
              [21.2527, 62.8686],
              [21.2645, 62.8847],
              [21.2954, 62.8936],
              [21.3685, 62.873],
              [21.4117, 62.878],
              [21.4114, 62.9012],
              [21.4325, 62.9243],
              [21.4392, 62.9586],
              [21.4915, 62.969],
              [21.4756, 62.9844],
              [21.4965, 62.9983],
              [21.4654, 63.0161],
              [21.4742, 63.0595],
              [21.5036, 63.057],
              [21.5265, 63.079],
              [21.5516, 63.0848],
              [21.6143, 63.0544],
              [21.6751, 63.0441],
              [21.6919, 63.0688],
              [21.6168, 63.083],
              [21.5862, 63.1046],
              [21.597, 63.1228],
              [21.5637, 63.134],
              [21.5189, 63.1628],
              [21.5259, 63.1899],
              [21.5176, 63.2176],
              [21.53, 63.2413],
              [21.5779, 63.2782],
              [21.581, 63.2481],
              [21.6347, 63.2261],
              [21.6825, 63.2143],
              [21.703, 63.2388],
              [21.7257, 63.2358],
              [21.8026, 63.2713],
              [21.8202, 63.2579],
              [21.8911, 63.2763],
              [21.9355, 63.2631],
              [21.9942, 63.266],
              [22.0208, 63.2531],
              [22.0177, 63.2169],
              [21.9661, 63.2119],
              [21.9456, 63.1888],
              [21.9865, 63.1641],
              [21.9892, 63.202],
              [22.0482, 63.2064],
              [22.0238, 63.2415],
              [22.0436, 63.2528],
              [22.1794, 63.246],
              [22.205, 63.2643],
              [22.2405, 63.2749],
              [22.2587, 63.2984],
              [22.3105, 63.3141],
              [22.3392, 63.2928],
              [22.3995, 63.3357],
              [22.347, 63.3818],
              [22.3, 63.3941],
              [22.2974, 63.4145],
              [22.267, 63.4177],
              [22.2071, 63.4583],
              [22.2115, 63.4808],
              [22.2668, 63.4814],
              [22.3095, 63.4891],
              [22.2914, 63.5241],
              [22.3129, 63.5362],
              [22.3452, 63.577],
              [22.3855, 63.5752],
              [22.3743, 63.5442],
              [22.4276, 63.4794],
              [22.4522, 63.4766],
              [22.454, 63.5139],
              [22.4881, 63.5438],
              [22.4654, 63.5663],
              [22.4802, 63.6046],
              [22.5287, 63.6241],
              [22.5261, 63.6564],
              [22.5579, 63.6745],
              [22.6005, 63.668],
              [22.6214, 63.6984],
              [22.6186, 63.7243],
              [22.6937, 63.7099],
              [22.7094, 63.7199],
              [22.7843, 63.6904],
              [22.8261, 63.6676],
              [22.8672, 63.6705],
              [22.8377, 63.6926],
              [22.9074, 63.6951],
              [22.9021, 63.7129],
              [22.9204, 63.7319],
              [22.8819, 63.7339],
              [22.9269, 63.7778],
              [22.9945, 63.7896],
              [23.0208, 63.7916],
            ],
          ],
          [
            [
              [22.7725, 63.7949],
              [22.8375, 63.7779],
              [22.8072, 63.7363],
              [22.7637, 63.7183],
              [22.7198, 63.7272],
              [22.679, 63.7608],
              [22.7193, 63.7838],
              [22.7725, 63.7949],
            ],
          ],
          [
            [
              [22.8754, 63.8308],
              [22.8825, 63.8055],
              [22.8491, 63.7944],
              [22.7899, 63.8017],
              [22.7304, 63.7881],
              [22.672, 63.8151],
              [22.7079, 63.8428],
              [22.7946, 63.8517],
              [22.8099, 63.8606],
              [22.8754, 63.8308],
            ],
          ],
        ],
      },
      properties: {name: 'Ostrobothnia', id: 'FI-12', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-12',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.3633, 63.7564],
            [28.476, 63.8574],
            [28.4601, 63.8706],
            [28.5183, 63.8814],
            [28.8903, 63.8362],
            [29.0246, 63.8441],
            [29.286, 63.851],
            [29.5204, 63.87],
            [29.6853, 63.879],
            [29.7272, 63.7477],
            [29.7805, 63.7042],
            [29.8782, 63.7492],
            [29.9773, 63.7575],
            [30.0676, 63.7088],
            [30.0771, 63.6949],
            [30.2387, 63.6121],
            [30.2576, 63.5843],
            [30.3857, 63.545],
            [30.4831, 63.4666],
            [30.7894, 63.4053],
            [30.855, 63.3686],
            [30.9373, 63.3565],
            [30.9483, 63.336],
            [30.9835, 63.3069],
            [31.0236, 63.3028],
            [31.1468, 63.262],
            [31.1723, 63.2417],
            [31.2405, 63.2185],
            [31.2404, 63.1953],
            [31.2575, 63.1787],
            [31.2579, 63.1412],
            [31.2742, 63.1076],
            [31.2985, 63.0933],
            [31.3636, 63.0718],
            [31.3916, 63.0483],
            [31.4286, 63.0422],
            [31.4648, 63.0242],
            [31.4526, 63.0057],
            [31.5051, 62.9955],
            [31.5886, 62.9084],
            [31.4414, 62.7849],
            [31.3828, 62.6908],
            [31.3746, 62.6496],
            [31.3501, 62.6401],
            [31.224, 62.4986],
            [31.1869, 62.4934],
            [31.1641, 62.4453],
            [31.1376, 62.4402],
            [30.9475, 62.3344],
            [30.9442, 62.3063],
            [30.9039, 62.3107],
            [30.8091, 62.2614],
            [30.722, 62.2097],
            [30.6594, 62.2096],
            [30.6319, 62.19],
            [30.6378, 62.1737],
            [30.6061, 62.1425],
            [30.4485, 62.0374],
            [30.3818, 62.0003],
            [30.3528, 61.9786],
            [30.3112, 61.9657],
            [30.3167, 61.9449],
            [30.2783, 61.9334],
            [30.1477, 61.8522],
            [30.1002, 61.8565],
            [29.5635, 61.7223],
            [29.5755, 61.7367],
            [29.6426, 61.7623],
            [29.6802, 61.7932],
            [29.694, 61.8233],
            [29.6695, 61.8547],
            [29.6985, 61.865],
            [29.6189, 61.9141],
            [29.6199, 61.9366],
            [29.6638, 61.9771],
            [29.5712, 62.0247],
            [29.5116, 62.0398],
            [29.4631, 62.0817],
            [29.4123, 62.2005],
            [29.4333, 62.2367],
            [29.3953, 62.253],
            [29.315, 62.3318],
            [29.2983, 62.3886],
            [29.2716, 62.4084],
            [29.234, 62.4186],
            [29.2026, 62.4026],
            [29.1274, 62.3905],
            [29.0984, 62.4043],
            [29.0845, 62.4264],
            [29.1075, 62.4807],
            [29.0402, 62.51],
            [29.034, 62.532],
            [29.0101, 62.5369],
            [28.9442, 62.5667],
            [28.9462, 62.5784],
            [28.8654, 62.6017],
            [28.793, 62.6421],
            [28.6789, 62.6508],
            [28.6576, 62.6592],
            [28.6601, 62.6713],
            [28.7047, 62.6952],
            [28.6461, 62.7319],
            [28.6462, 62.7612],
            [28.6841, 62.7779],
            [28.7214, 62.7754],
            [28.7517, 62.8004],
            [28.7485, 62.814],
            [28.7949, 62.8351],
            [28.8524, 62.8099],
            [28.9042, 62.7973],
            [28.9461, 62.7762],
            [28.9718, 62.8054],
            [29.1012, 62.8472],
            [29.0146, 62.8749],
            [28.9943, 62.8966],
            [28.9415, 62.9202],
            [28.9982, 62.9289],
            [28.9657, 62.9472],
            [28.976, 62.9602],
            [29.0703, 62.9861],
            [29.0409, 63.0124],
            [28.9261, 63.0384],
            [28.8706, 63.0574],
            [28.8323, 63.0967],
            [28.7118, 63.1718],
            [28.7023, 63.1846],
            [28.6161, 63.2054],
            [28.6802, 63.2579],
            [28.7106, 63.2736],
            [28.7126, 63.3003],
            [28.7405, 63.3128],
            [28.7628, 63.388],
            [28.6687, 63.4241],
            [28.6538, 63.4688],
            [28.6078, 63.5278],
            [28.687, 63.5539],
            [28.6283, 63.5661],
            [28.6104, 63.5767],
            [28.5906, 63.6596],
            [28.6098, 63.6757],
            [28.5551, 63.7013],
            [28.4653, 63.6994],
            [28.4292, 63.7266],
            [28.3633, 63.7564],
          ],
        ],
      },
      properties: {name: 'North Karelia', id: 'FI-13', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-13',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [24.5567, 65.0386],
              [24.6539, 65.0828],
              [24.716, 65.086],
              [24.7656, 65.0821],
              [24.8389, 65.0953],
              [24.8904, 65.0808],
              [24.8888, 65.0594],
              [24.958, 65.0618],
              [25.0319, 65.0571],
              [25.0629, 65.0445],
              [25.0253, 65.0325],
              [24.9266, 65.0348],
              [24.9042, 65.0479],
              [24.8182, 65.0502],
              [24.8241, 65.0324],
              [24.8633, 65.0209],
              [24.884, 65.0009],
              [24.8787, 64.9679],
              [24.8245, 64.9514],
              [24.7726, 64.9456],
              [24.7345, 64.9691],
              [24.7092, 64.9638],
              [24.6401, 64.9662],
              [24.6221, 64.9863],
              [24.5815, 65.004],
              [24.5567, 65.0386],
            ],
          ],
          [
            [
              [29.7588, 65.498],
              [29.7155, 65.4942],
              [29.5557, 65.5064],
              [29.5064, 65.4899],
              [29.3246, 65.5218],
              [29.2196, 65.5192],
              [29.2241, 65.4943],
              [29.1246, 65.4498],
              [29.048, 65.4381],
              [28.9618, 65.4483],
              [28.6519, 65.3908],
              [28.6602, 65.3733],
              [28.6334, 65.3553],
              [28.5949, 65.2858],
              [28.2692, 65.2608],
              [28.0387, 65.2394],
              [27.9527, 65.2053],
              [27.9448, 65.1807],
              [27.8984, 65.0922],
              [27.6724, 65.1225],
              [27.5745, 65.0952],
              [27.4574, 65.0884],
              [27.3337, 65.092],
              [27.2676, 65.0288],
              [27.3907, 64.9594],
              [27.4085, 64.9278],
              [27.3742, 64.8912],
              [27.4067, 64.8563],
              [27.358, 64.813],
              [27.1504, 64.8367],
              [26.9131, 64.761],
              [26.8964, 64.7367],
              [26.8946, 64.707],
              [26.8634, 64.6912],
              [26.8431, 64.6634],
              [26.6902, 64.6375],
              [26.605, 64.6132],
              [26.5803, 64.5902],
              [26.5367, 64.5763],
              [26.552, 64.5599],
              [26.4654, 64.566],
              [26.2972, 64.4805],
              [26.4306, 64.4303],
              [26.4848, 64.3893],
              [26.4678, 64.3556],
              [26.5561, 64.3217],
              [26.6827, 64.3167],
              [26.6904, 64.2765],
              [26.7702, 64.2644],
              [26.7818, 64.0587],
              [26.8721, 64.0374],
              [26.6795, 63.9961],
              [26.5893, 63.9747],
              [26.3757, 63.8902],
              [26.2242, 63.8334],
              [26.2457, 63.8073],
              [26.2642, 63.7487],
              [26.2982, 63.592],
              [26.2009, 63.5692],
              [26.1941, 63.5012],
              [26.1445, 63.4585],
              [26.0019, 63.4301],
              [25.9002, 63.4409],
              [25.8151, 63.495],
              [25.7716, 63.5087],
              [25.7061, 63.5208],
              [25.5429, 63.525],
              [25.4912, 63.5335],
              [25.2555, 63.6139],
              [25.1449, 63.5755],
              [25.1169, 63.5018],
              [25.0864, 63.4687],
              [25.0396, 63.4593],
              [24.9223, 63.5244],
              [24.8224, 63.5888],
              [24.7563, 63.6142],
              [24.6243, 63.6514],
              [24.5654, 63.6917],
              [24.4307, 63.8399],
              [24.2897, 63.8636],
              [24.2533, 63.8778],
              [24.2227, 63.9345],
              [24.1818, 63.9413],
              [24.1049, 64.0068],
              [24.0907, 64.0721],
              [24.009, 64.0933],
              [23.9508, 64.0991],
              [23.9167, 64.1172],
              [23.856, 64.1224],
              [23.7944, 64.1458],
              [23.7029, 64.1718],
              [23.732, 64.1897],
              [23.7046, 64.2073],
              [23.7044, 64.2239],
              [23.7594, 64.2216],
              [23.8164, 64.2372],
              [23.8409, 64.2698],
              [23.8642, 64.2851],
              [23.9244, 64.2903],
              [23.9272, 64.3342],
              [23.9961, 64.3564],
              [24.0065, 64.3936],
              [24.0349, 64.4142],
              [24.0685, 64.4108],
              [24.1047, 64.4196],
              [24.0953, 64.4331],
              [24.1581, 64.459],
              [24.1869, 64.4578],
              [24.2083, 64.4766],
              [24.2536, 64.4666],
              [24.2395, 64.4952],
              [24.2844, 64.5058],
              [24.292, 64.5258],
              [24.3379, 64.5297],
              [24.3825, 64.5527],
              [24.3858, 64.6139],
              [24.4002, 64.6244],
              [24.4105, 64.6659],
              [24.4714, 64.6823],
              [24.4784, 64.7017],
              [24.5601, 64.7183],
              [24.5759, 64.7426],
              [24.5601, 64.7581],
              [24.584, 64.7859],
              [24.5448, 64.8143],
              [24.6839, 64.8406],
              [24.7367, 64.871],
              [24.8007, 64.8755],
              [24.8249, 64.8874],
              [24.9508, 64.8814],
              [24.9697, 64.9047],
              [25.0352, 64.9072],
              [25.1346, 64.9183],
              [25.1352, 64.9033],
              [25.2052, 64.8986],
              [25.2234, 64.8681],
              [25.2633, 64.8725],
              [25.2997, 64.8398],
              [25.3477, 64.8368],
              [25.3588, 64.8604],
              [25.4069, 64.8906],
              [25.3851, 64.9108],
              [25.3415, 64.9072],
              [25.2202, 64.9528],
              [25.2, 64.9793],
              [25.2394, 65.0022],
              [25.3469, 64.9845],
              [25.3579, 64.9725],
              [25.4197, 64.9494],
              [25.467, 64.9474],
              [25.4934, 64.9559],
              [25.4908, 64.9752],
              [25.4201, 64.9922],
              [25.42, 65.0419],
              [25.396, 65.0703],
              [25.3879, 65.0989],
              [25.3437, 65.1063],
              [25.3031, 65.1242],
              [25.2428, 65.1377],
              [25.2316, 65.1708],
              [25.2804, 65.1754],
              [25.2872, 65.2381],
              [25.315, 65.2499],
              [25.3209, 65.2919],
              [25.2581, 65.3215],
              [25.2512, 65.3358],
              [25.28, 65.3514],
              [25.3386, 65.3618],
              [25.2627, 65.4011],
              [25.2883, 65.41],
              [25.3304, 65.4067],
              [25.369, 65.425],
              [25.346, 65.4644],
              [25.2909, 65.4956],
              [25.2371, 65.5364],
              [25.1932, 65.5615],
              [25.154, 65.5715],
              [25.1271, 65.5615],
              [25.0889, 65.5892],
              [25.1341, 65.6082],
              [25.195, 65.656],
              [25.3127, 65.6869],
              [25.3642, 65.7287],
              [25.4266, 65.7434],
              [25.7971, 65.7415],
              [26.1634, 65.8225],
              [26.1647, 65.7254],
              [26.3878, 65.7142],
              [26.6621, 65.7105],
              [26.8467, 65.7031],
              [27.0393, 65.7393],
              [27.0769, 65.7308],
              [27.1147, 65.7522],
              [27.45, 65.9625],
              [27.5445, 65.9259],
              [27.569, 65.9293],
              [27.6028, 65.9551],
              [27.6592, 65.9607],
              [27.6989, 65.9014],
              [27.7722, 65.8423],
              [27.7687, 65.8278],
              [28.1502, 65.8162],
              [28.2288, 65.8028],
              [28.469, 65.8465],
              [28.518, 65.8478],
              [28.5117, 65.8926],
              [28.4638, 65.9194],
              [28.4373, 65.9477],
              [28.3773, 65.9619],
              [28.4184, 65.9878],
              [28.4868, 65.9909],
              [28.4889, 66.0041],
              [28.6015, 66.0005],
              [28.603, 65.9888],
              [28.6569, 65.9879],
              [28.6791, 66.0012],
              [28.7504, 66.0029],
              [28.7483, 66.0515],
              [28.6345, 66.0722],
              [28.611, 66.128],
              [28.692, 66.1278],
              [28.719, 66.1364],
              [28.7216, 66.1613],
              [28.7735, 66.1851],
              [28.7408, 66.2064],
              [28.7634, 66.2389],
              [28.7521, 66.2561],
              [28.5961, 66.2686],
              [28.5442, 66.3319],
              [28.5541, 66.3825],
              [28.6232, 66.4137],
              [28.6709, 66.4141],
              [28.7006, 66.4866],
              [29.2603, 66.4089],
              [29.2635, 66.4327],
              [29.5765, 66.4334],
              [29.639, 66.3623],
              [29.7006, 66.2785],
              [29.9299, 66.1269],
              [29.9762, 66.0406],
              [29.9952, 66.0226],
              [30.0004, 65.9798],
              [30.0272, 65.9676],
              [30.071, 65.8993],
              [30.1395, 65.6968],
              [30.1428, 65.67],
              [30.0472, 65.6876],
              [30.0195, 65.6972],
              [29.7265, 65.6381],
              [29.7584, 65.6103],
              [29.8679, 65.5614],
              [29.7588, 65.498],
            ],
          ],
        ],
      },
      properties: {name: 'Northern Ostrobothnia', id: 'FI-14', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-14',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.3633, 63.7564],
            [28.4292, 63.7266],
            [28.4653, 63.6994],
            [28.5551, 63.7013],
            [28.6098, 63.6757],
            [28.5906, 63.6596],
            [28.6104, 63.5767],
            [28.6283, 63.5661],
            [28.687, 63.5539],
            [28.6078, 63.5278],
            [28.6538, 63.4688],
            [28.6687, 63.4241],
            [28.7628, 63.388],
            [28.7405, 63.3128],
            [28.7126, 63.3003],
            [28.7106, 63.2736],
            [28.6802, 63.2579],
            [28.6161, 63.2054],
            [28.7023, 63.1846],
            [28.7118, 63.1718],
            [28.8323, 63.0967],
            [28.8706, 63.0574],
            [28.9261, 63.0384],
            [29.0409, 63.0124],
            [29.0703, 62.9861],
            [28.976, 62.9602],
            [28.9657, 62.9472],
            [28.9982, 62.9289],
            [28.9415, 62.9202],
            [28.9943, 62.8966],
            [29.0146, 62.8749],
            [29.1012, 62.8472],
            [28.9718, 62.8054],
            [28.9461, 62.7762],
            [28.9042, 62.7973],
            [28.8524, 62.8099],
            [28.7949, 62.8351],
            [28.7485, 62.814],
            [28.7517, 62.8004],
            [28.7214, 62.7754],
            [28.6841, 62.7779],
            [28.6462, 62.7612],
            [28.6461, 62.7319],
            [28.7047, 62.6952],
            [28.6601, 62.6713],
            [28.6576, 62.6592],
            [28.6286, 62.6075],
            [28.5464, 62.5702],
            [28.5234, 62.5502],
            [28.4774, 62.5682],
            [28.4155, 62.5691],
            [28.4009, 62.557],
            [28.4036, 62.5334],
            [28.3892, 62.5103],
            [28.3855, 62.4634],
            [28.3604, 62.4624],
            [28.2933, 62.4772],
            [28.3013, 62.4551],
            [28.2482, 62.4255],
            [28.2859, 62.415],
            [28.2872, 62.3945],
            [28.325, 62.3847],
            [28.3258, 62.3603],
            [28.3811, 62.3568],
            [28.3954, 62.3347],
            [28.3906, 62.3059],
            [28.4096, 62.3015],
            [28.5, 62.3258],
            [28.6521, 62.2373],
            [28.6521, 62.2151],
            [28.6045, 62.184],
            [28.5427, 62.184],
            [28.4096, 62.2129],
            [28.3526, 62.2173],
            [28.286, 62.235],
            [28.2005, 62.2262],
            [28.0864, 62.2262],
            [27.9725, 62.263],
            [27.9219, 62.283],
            [27.8615, 62.2866],
            [27.835, 62.2801],
            [27.7896, 62.313],
            [27.7421, 62.325],
            [27.7375, 62.3421],
            [27.763, 62.3678],
            [27.6959, 62.3752],
            [27.6339, 62.4197],
            [27.5737, 62.45],
            [27.4696, 62.4719],
            [27.4532, 62.484],
            [27.3913, 62.5059],
            [27.3071, 62.4864],
            [27.2895, 62.4629],
            [27.2551, 62.4801],
            [27.2232, 62.4641],
            [27.1944, 62.4704],
            [27.1226, 62.4992],
            [27.0835, 62.4884],
            [27.0642, 62.4717],
            [26.9995, 62.4703],
            [26.9742, 62.4895],
            [26.9397, 62.4577],
            [26.8902, 62.4767],
            [26.8617, 62.4787],
            [26.8207, 62.4562],
            [26.8365, 62.4428],
            [26.7669, 62.4245],
            [26.713, 62.4535],
            [26.6885, 62.4637],
            [26.6369, 62.4519],
            [26.5806, 62.4795],
            [26.4761, 62.52],
            [26.5127, 62.5339],
            [26.5627, 62.567],
            [26.5817, 62.6047],
            [26.6031, 62.6131],
            [26.5949, 62.6417],
            [26.5729, 62.655],
            [26.569, 62.6847],
            [26.534, 62.7096],
            [26.5103, 62.7444],
            [26.4484, 62.7897],
            [26.3961, 62.7765],
            [26.4043, 62.7662],
            [26.3605, 62.7481],
            [26.2277, 62.8261],
            [26.1922, 62.8263],
            [26.1592, 62.8469],
            [26.1883, 62.8595],
            [26.1706, 62.8729],
            [26.073, 62.8908],
            [26.0455, 62.9345],
            [26.0889, 62.9397],
            [26.129, 62.9531],
            [26.2308, 62.9559],
            [26.2717, 62.9359],
            [26.3245, 62.9665],
            [26.2277, 63.0274],
            [26.244, 63.0453],
            [26.3398, 63.0369],
            [26.2494, 63.0729],
            [26.2254, 63.0901],
            [26.1952, 63.1347],
            [26.1712, 63.1473],
            [26.1539, 63.2099],
            [26.1899, 63.2137],
            [26.1826, 63.2404],
            [26.1485, 63.2762],
            [26.1357, 63.331],
            [26.0777, 63.356],
            [26.1268, 63.4067],
            [26.1821, 63.4399],
            [26.1445, 63.4585],
            [26.1941, 63.5012],
            [26.2009, 63.5692],
            [26.2982, 63.592],
            [26.2642, 63.7487],
            [26.2457, 63.8073],
            [26.2242, 63.8334],
            [26.3757, 63.8902],
            [26.5893, 63.9747],
            [26.6795, 63.9961],
            [26.8721, 64.0374],
            [27.0205, 64.0002],
            [27.1165, 63.9954],
            [27.1364, 63.9987],
            [27.2602, 63.9913],
            [27.4201, 63.9673],
            [27.4519, 63.9459],
            [27.5436, 63.9579],
            [27.6529, 63.9201],
            [27.669, 63.9239],
            [27.7491, 63.9089],
            [27.7892, 63.8891],
            [27.896, 63.85],
            [27.9178, 63.8515],
            [28.064, 63.7826],
            [28.1472, 63.7265],
            [28.2137, 63.7079],
            [28.2771, 63.6704],
            [28.3633, 63.7564],
          ],
        ],
      },
      properties: {name: 'Northern Savonia', id: 'FI-15', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-15',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.975, 61.4514],
            [25.1227, 61.4739],
            [25.1479, 61.4614],
            [25.1881, 61.4801],
            [25.2569, 61.4747],
            [25.2782, 61.4817],
            [25.4393, 61.4888],
            [25.5093, 61.6327],
            [25.503, 61.6776],
            [25.57, 61.6746],
            [25.6119, 61.6816],
            [25.67, 61.7179],
            [25.753, 61.7057],
            [25.7736, 61.6862],
            [25.8376, 61.7721],
            [25.8636, 61.7628],
            [25.9102, 61.7666],
            [25.9309, 61.782],
            [25.9864, 61.7604],
            [25.9962, 61.7319],
            [26.0177, 61.7226],
            [26.0694, 61.7281],
            [26.0845, 61.7107],
            [26.0812, 61.6698],
            [26.1465, 61.6224],
            [26.1732, 61.6201],
            [26.1955, 61.6507],
            [26.231, 61.6421],
            [26.317, 61.6404],
            [26.3044, 61.6275],
            [26.3186, 61.6098],
            [26.3219, 61.5746],
            [26.2887, 61.5691],
            [26.3029, 61.5452],
            [26.2962, 61.526],
            [26.267, 61.5069],
            [26.2959, 61.4684],
            [26.2457, 61.4483],
            [26.2677, 61.4275],
            [26.3223, 61.3997],
            [26.287, 61.3745],
            [26.3886, 61.3263],
            [26.483, 61.3231],
            [26.513, 61.2727],
            [26.4491, 61.2617],
            [26.4308, 61.2363],
            [26.4006, 61.2159],
            [26.3864, 61.1887],
            [26.343, 61.1721],
            [26.2696, 61.1638],
            [26.2698, 61.1501],
            [26.2378, 61.1318],
            [26.1707, 61.1395],
            [26.1411, 61.1559],
            [26.1064, 61.1413],
            [26.0487, 61.0972],
            [26.1098, 61.0783],
            [26.1054, 61.0496],
            [26.1347, 61.0287],
            [26.1184, 60.9862],
            [26.0771, 60.9728],
            [26.0451, 60.9736],
            [26.0631, 60.9386],
            [26.1732, 60.9301],
            [26.1275, 60.8981],
            [26.125, 60.8711],
            [26.0962, 60.8617],
            [26.0813, 60.8371],
            [26.1273, 60.8331],
            [26.2044, 60.8041],
            [26.2138, 60.7585],
            [26.2281, 60.7307],
            [26.2188, 60.7176],
            [26.1467, 60.692],
            [26.1139, 60.6874],
            [26.0324, 60.7174],
            [25.9384, 60.7068],
            [25.9189, 60.7372],
            [25.8626, 60.733],
            [25.8378, 60.7163],
            [25.7505, 60.7096],
            [25.7256, 60.7198],
            [25.6823, 60.693],
            [25.6143, 60.6731],
            [25.5241, 60.7056],
            [25.5196, 60.7455],
            [25.4782, 60.7945],
            [25.4395, 60.797],
            [25.385, 60.7541],
            [25.3249, 60.7614],
            [25.1898, 60.8193],
            [25.1129, 60.8162],
            [25.082, 60.8412],
            [25.113, 60.8655],
            [25.0978, 60.9192],
            [25.0122, 60.9295],
            [25.0284, 60.962],
            [25.0632, 60.9886],
            [25.0303, 61.0317],
            [25.0615, 61.051],
            [25.1554, 61.0645],
            [25.2022, 61.0776],
            [25.2201, 61.106],
            [25.2627, 61.1295],
            [25.2414, 61.1424],
            [25.2737, 61.1579],
            [25.2643, 61.1876],
            [25.1852, 61.2415],
            [25.1101, 61.2559],
            [25.0327, 61.2477],
            [25.016, 61.2904],
            [24.974, 61.275],
            [24.9274, 61.2831],
            [24.9149, 61.3247],
            [24.8912, 61.3548],
            [24.913, 61.416],
            [24.8746, 61.4379],
            [24.8547, 61.4777],
            [24.975, 61.4514],
          ],
        ],
      },
      properties: {name: 'Päijänne Tavastia', id: 'FI-16', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-16',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [21.367, 61.2171],
              [21.3835, 61.201],
              [21.3697, 61.1734],
              [21.3429, 61.1786],
              [21.3679, 61.2026],
              [21.367, 61.2171],
            ],
          ],
          [
            [
              [21.4613, 61.2486],
              [21.4949, 61.2498],
              [21.5281, 61.2367],
              [21.5002, 61.22],
              [21.4622, 61.238],
              [21.4613, 61.2486],
            ],
          ],
          [
            [
              [21.4846, 61.6442],
              [21.5121, 61.6436],
              [21.5251, 61.6262],
              [21.4694, 61.625],
              [21.4846, 61.6442],
            ],
          ],
          [
            [
              [22.8347, 62.2717],
              [22.8244, 62.2399],
              [22.8556, 62.1995],
              [22.863, 62.1722],
              [22.7738, 62.1562],
              [22.7833, 62.061],
              [22.7234, 62.0527],
              [22.6876, 62.0541],
              [22.607, 62.0372],
              [22.539, 61.9969],
              [22.6022, 61.9669],
              [22.6601, 61.9657],
              [22.6522, 61.9519],
              [22.6916, 61.9214],
              [22.7438, 61.9109],
              [22.7391, 61.8901],
              [22.7873, 61.8814],
              [22.7718, 61.8551],
              [22.8323, 61.8166],
              [22.8081, 61.7706],
              [22.6836, 61.7254],
              [22.6953, 61.7034],
              [22.6513, 61.6969],
              [22.6587, 61.6552],
              [22.6901, 61.6616],
              [22.729, 61.6452],
              [22.7075, 61.624],
              [22.7001, 61.6006],
              [22.6481, 61.5841],
              [22.6448, 61.5632],
              [22.6058, 61.5436],
              [22.6604, 61.4788],
              [22.7267, 61.477],
              [22.7176, 61.4595],
              [22.7355, 61.4338],
              [22.6707, 61.4265],
              [22.6406, 61.3861],
              [22.5628, 61.3948],
              [22.5946, 61.3429],
              [22.5624, 61.3315],
              [22.6147, 61.3004],
              [22.6416, 61.2689],
              [22.6488, 61.2473],
              [22.7737, 61.2465],
              [22.8487, 61.23],
              [22.8605, 61.2361],
              [22.9387, 61.2055],
              [22.9844, 61.1923],
              [23.1095, 61.1974],
              [23.172, 61.2127],
              [23.235, 61.2083],
              [23.2773, 61.2201],
              [23.2649, 61.1826],
              [23.3068, 61.1681],
              [23.386, 61.1301],
              [23.2744, 61.1092],
              [23.2575, 61.0858],
              [23.2915, 61.0575],
              [23.2644, 61.0198],
              [23.2696, 61.0068],
              [23.2029, 61.0018],
              [23.1703, 61.0056],
              [23.1529, 61.0309],
              [23.0592, 61.032],
              [23.0195, 61.0229],
              [22.9741, 61.0433],
              [22.9396, 61.0437],
              [22.8858, 61.0592],
              [22.8715, 61.0845],
              [22.8273, 61.1016],
              [22.8196, 61.0797],
              [22.8409, 61.0689],
              [22.8109, 61.0421],
              [22.8298, 61.0165],
              [22.8165, 60.9951],
              [22.7846, 60.9951],
              [22.7157, 60.9822],
              [22.7021, 60.9871],
              [22.5834, 60.9909],
              [22.5279, 60.9768],
              [22.5871, 60.94],
              [22.5223, 60.9513],
              [22.4166, 60.9371],
              [22.3639, 60.9335],
              [22.3172, 60.9166],
              [22.2647, 60.9281],
              [22.1903, 60.9029],
              [22.1598, 60.8753],
              [22.1295, 60.905],
              [21.9876, 60.9018],
              [21.9983, 60.9126],
              [21.9489, 60.9462],
              [21.952, 60.9791],
              [21.884, 60.9849],
              [21.8434, 61.0099],
              [21.7768, 61.0348],
              [21.7673, 61.014],
              [21.718, 60.9985],
              [21.6669, 60.9958],
              [21.6221, 61.0504],
              [21.6101, 61.034],
              [21.5673, 61.009],
              [21.493, 61.0025],
              [21.4392, 61.0239],
              [21.4214, 61.0485],
              [21.4124, 61.0842],
              [21.4504, 61.0969],
              [21.4408, 61.1267],
              [21.4667, 61.1411],
              [21.4327, 61.166],
              [21.4539, 61.1886],
              [21.5072, 61.2017],
              [21.5255, 61.2312],
              [21.5809, 61.237],
              [21.4943, 61.2647],
              [21.4941, 61.306],
              [21.5604, 61.3362],
              [21.54, 61.4033],
              [21.4504, 61.4318],
              [21.46, 61.4438],
              [21.5658, 61.4259],
              [21.602, 61.447],
              [21.5327, 61.4859],
              [21.4965, 61.5127],
              [21.5788, 61.4956],
              [21.637, 61.5082],
              [21.5888, 61.5312],
              [21.5432, 61.5268],
              [21.5197, 61.5699],
              [21.4768, 61.5987],
              [21.5296, 61.6034],
              [21.564, 61.5835],
              [21.6556, 61.5556],
              [21.6661, 61.5748],
              [21.6121, 61.6129],
              [21.5879, 61.6449],
              [21.5556, 61.6632],
              [21.5278, 61.6967],
              [21.5554, 61.7358],
              [21.5526, 61.7556],
              [21.4467, 61.7901],
              [21.4567, 61.8123],
              [21.497, 61.8055],
              [21.5158, 61.8214],
              [21.4672, 61.8467],
              [21.4641, 61.8914],
              [21.4289, 61.9146],
              [21.4407, 61.9297],
              [21.4019, 61.9421],
              [21.3504, 61.9432],
              [21.3333, 61.9587],
              [21.3839, 61.978],
              [21.4298, 62.0107],
              [21.5253, 62.027],
              [21.6514, 62.0215],
              [21.7123, 62.0224],
              [21.9783, 61.9816],
              [22.1131, 62.0331],
              [22.2013, 62.1029],
              [22.3291, 62.1059],
              [22.4497, 62.1995],
              [22.508, 62.214],
              [22.5264, 62.2117],
              [22.5944, 62.3108],
              [22.7526, 62.2737],
              [22.8347, 62.2717],
            ],
          ],
        ],
      },
      properties: {name: 'Satakunta', id: 'FI-17', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-17',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [25.8528, 60.2782],
              [25.8331, 60.261],
              [25.7953, 60.2626],
              [25.7889, 60.2379],
              [25.7589, 60.2453],
              [25.7102, 60.2789],
              [25.6664, 60.2814],
              [25.6453, 60.3237],
              [25.6806, 60.3344],
              [25.7107, 60.3335],
              [25.7203, 60.3224],
              [25.7412, 60.315],
              [25.7626, 60.3127],
              [25.7892, 60.2823],
              [25.8528, 60.2782],
            ],
          ],
          [
            [
              [26.2138, 60.7585],
              [26.2595, 60.7617],
              [26.3051, 60.7494],
              [26.3041, 60.7315],
              [26.3342, 60.7145],
              [26.3292, 60.6967],
              [26.3521, 60.662],
              [26.4341, 60.6509],
              [26.4576, 60.6423],
              [26.4864, 60.6149],
              [26.4882, 60.5987],
              [26.5446, 60.5962],
              [26.6, 60.6295],
              [26.6037, 60.6436],
              [26.6505, 60.6527],
              [26.6873, 60.6502],
              [26.758, 60.5767],
              [26.7006, 60.5898],
              [26.6748, 60.6106],
              [26.6283, 60.6143],
              [26.5969, 60.6084],
              [26.5524, 60.5758],
              [26.547, 60.5557],
              [26.4938, 60.5443],
              [26.515, 60.5271],
              [26.4743, 60.5142],
              [26.4607, 60.4903],
              [26.4451, 60.4663],
              [26.4615, 60.4535],
              [26.4578, 60.4166],
              [26.4242, 60.4164],
              [26.3773, 60.3975],
              [26.3358, 60.3975],
              [26.2945, 60.4058],
              [26.2906, 60.4292],
              [26.2383, 60.4548],
              [26.2549, 60.4084],
              [26.2025, 60.3923],
              [26.2098, 60.3631],
              [26.1717, 60.3705],
              [26.167, 60.3871],
              [26.0967, 60.4138],
              [26.0745, 60.4347],
              [26.0369, 60.4402],
              [25.9717, 60.4735],
              [25.9688, 60.4438],
              [26.0462, 60.4134],
              [26.0201, 60.3868],
              [26.0467, 60.3803],
              [26.1147, 60.326],
              [26.054, 60.3033],
              [26.0278, 60.3214],
              [26.0167, 60.3443],
              [25.9468, 60.3575],
              [25.9394, 60.369],
              [25.9084, 60.3677],
              [25.921, 60.3441],
              [25.8679, 60.3258],
              [25.906, 60.2731],
              [25.932, 60.2539],
              [25.8901, 60.2442],
              [25.8603, 60.2743],
              [25.8613, 60.2899],
              [25.8176, 60.2845],
              [25.7607, 60.3211],
              [25.7408, 60.316],
              [25.7115, 60.3338],
              [25.6924, 60.34],
              [25.6602, 60.3337],
              [25.6455, 60.3532],
              [25.6685, 60.3608],
              [25.7123, 60.3432],
              [25.7092, 60.3704],
              [25.6676, 60.3766],
              [25.5848, 60.317],
              [25.573, 60.3348],
              [25.5354, 60.3338],
              [25.5577, 60.31],
              [25.5387, 60.2828],
              [25.4879, 60.2498],
              [25.3476, 60.2577],
              [25.2853, 60.256],
              [25.1893, 60.2377],
              [25.19, 60.2026],
              [25.0716, 60.1988],
              [25.0126, 60.1802],
              [24.9611, 60.1783],
              [24.9661, 60.1555],
              [24.9271, 60.1485],
              [24.9044, 60.1641],
              [24.9133, 60.182],
              [24.878, 60.1882],
              [24.8438, 60.2045],
              [24.8387, 60.1692],
              [24.8092, 60.16],
              [24.6895, 60.1389],
              [24.6587, 60.1413],
              [24.615, 60.1198],
              [24.6549, 60.1063],
              [24.6461, 60.0938],
              [24.5565, 60.0441],
              [24.5535, 60.0295],
              [24.5131, 60.0204],
              [24.4936, 59.9952],
              [24.4453, 59.9842],
              [24.43, 60.0082],
              [24.4495, 60.0303],
              [24.4204, 60.0351],
              [24.3877, 60.0169],
              [24.3658, 60.0373],
              [24.38, 60.0825],
              [24.3544, 60.0917],
              [24.3014, 60.0689],
              [24.2692, 60.0457],
              [24.2501, 60.043],
              [24.1761, 60.0581],
              [24.2062, 60.0276],
              [24.1697, 60.0253],
              [24.1238, 60.041],
              [24.0856, 60.0248],
              [24.0039, 60.031],
              [23.9247, 59.9984],
              [23.8846, 59.9962],
              [23.8604, 60.0053],
              [23.8281, 59.9751],
              [23.8042, 59.9679],
              [23.7656, 59.9832],
              [23.6934, 59.9399],
              [23.5899, 59.9353],
              [23.545, 59.9384],
              [23.4161, 59.9172],
              [23.3945, 59.9267],
              [23.3953, 59.9444],
              [23.5375, 59.9833],
              [23.4829, 59.9821],
              [23.475, 59.971],
              [23.3909, 59.9602],
              [23.3781, 59.939],
              [23.3194, 59.9227],
              [23.3074, 59.9118],
              [23.229, 59.8918],
              [23.2365, 59.8565],
              [23.2218, 59.8424],
              [23.1821, 59.8303],
              [23.0839, 59.8255],
              [23.061, 59.8298],
              [22.9561, 59.8175],
              [22.9271, 59.8293],
              [22.9356, 59.8449],
              [22.9879, 59.8472],
              [23.0316, 59.8668],
              [23.0732, 59.8715],
              [23.1078, 59.8885],
              [23.1755, 59.8935],
              [23.1498, 59.9234],
              [23.1589, 59.9374],
              [23.1898, 59.9431],
              [23.2162, 59.9604],
              [23.1977, 59.9776],
              [23.1021, 59.9479],
              [23.1112, 59.9837],
              [23.0835, 59.9786],
              [23.062, 59.9496],
              [23.0736, 59.923],
              [23.0526, 59.9168],
              [22.9923, 59.9378],
              [22.9788, 59.9179],
              [22.9471, 59.9088],
              [22.893, 59.9165],
              [22.8729, 59.93],
              [22.8733, 59.9528],
              [22.857, 59.9666],
              [22.9177, 59.9929],
              [22.9552, 59.9924],
              [22.9777, 59.9727],
              [23.0121, 59.9803],
              [23.031, 60.003],
              [22.9895, 60.0335],
              [23.0291, 60.0497],
              [23.112, 60.0376],
              [23.1621, 60.0524],
              [23.1335, 60.0673],
              [23.0824, 60.0442],
              [22.9931, 60.0712],
              [23.0296, 60.1012],
              [23.059, 60.0876],
              [23.1521, 60.1012],
              [23.2186, 60.1344],
              [23.2829, 60.154],
              [23.2873, 60.1764],
              [23.3208, 60.1724],
              [23.368, 60.1504],
              [23.3847, 60.167],
              [23.4705, 60.1718],
              [23.5569, 60.2022],
              [23.6165, 60.1917],
              [23.6528, 60.2022],
              [23.6368, 60.2337],
              [23.5885, 60.2579],
              [23.6298, 60.2605],
              [23.6473, 60.2836],
              [23.7308, 60.3078],
              [23.7366, 60.3571],
              [23.7762, 60.3869],
              [23.7962, 60.43],
              [23.7871, 60.4505],
              [23.6984, 60.4508],
              [23.7396, 60.491],
              [23.8203, 60.4953],
              [23.8691, 60.5057],
              [23.8668, 60.5402],
              [23.8481, 60.5574],
              [23.8902, 60.5595],
              [23.8903, 60.574],
              [23.9204, 60.608],
              [23.8706, 60.6199],
              [23.9027, 60.6507],
              [23.9225, 60.6548],
              [23.9738, 60.639],
              [24.0455, 60.6451],
              [24.0892, 60.629],
              [24.1377, 60.6241],
              [24.1803, 60.6286],
              [24.2405, 60.6186],
              [24.2387, 60.6004],
              [24.2895, 60.567],
              [24.2981, 60.5855],
              [24.3526, 60.6127],
              [24.4128, 60.5935],
              [24.4025, 60.5794],
              [24.4278, 60.5537],
              [24.5512, 60.5607],
              [24.5791, 60.5866],
              [24.5662, 60.6022],
              [24.5696, 60.6376],
              [24.6173, 60.6681],
              [24.6941, 60.6503],
              [24.7421, 60.6485],
              [24.769, 60.6599],
              [24.7984, 60.6559],
              [24.8961, 60.6599],
              [24.9383, 60.6747],
              [25.0384, 60.6858],
              [25.0653, 60.6698],
              [25.1144, 60.6625],
              [25.0892, 60.6996],
              [25.1487, 60.717],
              [25.1496, 60.7328],
              [25.123, 60.7477],
              [25.063, 60.727],
              [25.0948, 60.7641],
              [25.1569, 60.7704],
              [25.1583, 60.7802],
              [25.1086, 60.81],
              [25.1129, 60.8162],
              [25.1898, 60.8193],
              [25.3249, 60.7614],
              [25.385, 60.7541],
              [25.4395, 60.797],
              [25.4782, 60.7945],
              [25.5196, 60.7455],
              [25.5241, 60.7056],
              [25.6143, 60.6731],
              [25.6823, 60.693],
              [25.7256, 60.7198],
              [25.7505, 60.7096],
              [25.8378, 60.7163],
              [25.8626, 60.733],
              [25.9189, 60.7372],
              [25.9384, 60.7068],
              [26.0324, 60.7174],
              [26.1139, 60.6874],
              [26.1467, 60.692],
              [26.2188, 60.7176],
              [26.2281, 60.7307],
              [26.2138, 60.7585],
            ],
            [
              [26.0164, 60.2926],
              [26.0147, 60.3222],
              [25.9404, 60.3566],
              [25.9392, 60.3413],
              [25.9604, 60.3051],
              [25.9367, 60.2825],
              [25.96, 60.2742],
              [26.0132, 60.2773],
              [26.0164, 60.2926],
            ],
            [
              [25.6761, 60.2602],
              [25.6211, 60.2832],
              [25.6227, 60.3045],
              [25.5788, 60.3041],
              [25.5744, 60.2639],
              [25.5964, 60.2264],
              [25.6287, 60.2023],
              [25.6647, 60.2283],
              [25.6761, 60.2602],
            ],
          ],
        ],
      },
      properties: {name: 'Uusimaa', id: 'FI-18', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-18',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [21.4214, 61.0485],
              [21.4392, 61.0239],
              [21.493, 61.0025],
              [21.5673, 61.009],
              [21.6076, 61.0091],
              [21.6101, 61.034],
              [21.6221, 61.0504],
              [21.6669, 60.9958],
              [21.718, 60.9985],
              [21.7673, 61.014],
              [21.7768, 61.0348],
              [21.8434, 61.0099],
              [21.884, 60.9849],
              [21.952, 60.9791],
              [21.9489, 60.9462],
              [21.9983, 60.9126],
              [21.9876, 60.9018],
              [22.1295, 60.905],
              [22.1598, 60.8753],
              [22.1903, 60.9029],
              [22.2647, 60.9281],
              [22.3172, 60.9166],
              [22.3639, 60.9335],
              [22.4166, 60.9371],
              [22.5223, 60.9513],
              [22.5871, 60.94],
              [22.5279, 60.9768],
              [22.5834, 60.9909],
              [22.7021, 60.9871],
              [22.7157, 60.9822],
              [22.7846, 60.9951],
              [22.8165, 60.9951],
              [22.8298, 61.0165],
              [22.8109, 61.0421],
              [22.8409, 61.0689],
              [22.8196, 61.0797],
              [22.8273, 61.1016],
              [22.8715, 61.0845],
              [22.8858, 61.0592],
              [22.9396, 61.0437],
              [22.9741, 61.0433],
              [23.0195, 61.0229],
              [23.0592, 61.032],
              [23.1529, 61.0309],
              [23.1703, 61.0056],
              [23.2029, 61.0018],
              [23.2569, 60.9645],
              [23.2978, 60.955],
              [23.2913, 60.9177],
              [23.2625, 60.9147],
              [23.269, 60.8899],
              [23.1612, 60.8933],
              [23.1417, 60.8856],
              [23.1445, 60.8403],
              [23.2233, 60.8305],
              [23.1584, 60.7757],
              [23.2484, 60.7342],
              [23.3368, 60.7191],
              [23.4443, 60.7487],
              [23.5048, 60.7453],
              [23.5045, 60.7284],
              [23.5452, 60.7043],
              [23.577, 60.6958],
              [23.6261, 60.695],
              [23.6836, 60.6841],
              [23.687, 60.6585],
              [23.7186, 60.6643],
              [23.7499, 60.653],
              [23.8164, 60.6568],
              [23.9027, 60.6507],
              [23.8706, 60.6199],
              [23.9204, 60.608],
              [23.8903, 60.574],
              [23.8902, 60.5595],
              [23.8481, 60.5574],
              [23.8668, 60.5402],
              [23.8691, 60.5057],
              [23.8203, 60.4953],
              [23.7396, 60.491],
              [23.6984, 60.4508],
              [23.7871, 60.4505],
              [23.7962, 60.43],
              [23.7762, 60.3869],
              [23.7366, 60.3571],
              [23.7308, 60.3078],
              [23.6473, 60.2836],
              [23.6298, 60.2605],
              [23.5885, 60.2579],
              [23.6368, 60.2337],
              [23.6528, 60.2022],
              [23.6165, 60.1917],
              [23.5569, 60.2022],
              [23.4705, 60.1718],
              [23.3847, 60.167],
              [23.368, 60.1504],
              [23.3208, 60.1724],
              [23.2873, 60.1764],
              [23.2829, 60.154],
              [23.2186, 60.1344],
              [23.1521, 60.1012],
              [23.059, 60.0876],
              [23.0296, 60.1012],
              [22.9935, 60.1094],
              [22.9712, 60.1245],
              [22.9078, 60.1432],
              [22.8818, 60.1567],
              [22.8951, 60.1789],
              [22.8767, 60.2014],
              [22.9191, 60.2362],
              [22.9538, 60.2495],
              [22.9518, 60.2669],
              [22.983, 60.2874],
              [23.0145, 60.2888],
              [23.0534, 60.3335],
              [22.9936, 60.3346],
              [22.949, 60.3192],
              [22.8845, 60.3093],
              [22.8596, 60.2859],
              [22.7964, 60.2579],
              [22.7269, 60.2456],
              [22.6219, 60.2391],
              [22.5751, 60.2169],
              [22.5231, 60.2143],
              [22.5325, 60.2304],
              [22.5966, 60.2468],
              [22.5314, 60.2694],
              [22.4742, 60.2576],
              [22.4554, 60.2677],
              [22.5107, 60.3085],
              [22.5676, 60.3394],
              [22.558, 60.3484],
              [22.6024, 60.3633],
              [22.5859, 60.3781],
              [22.4981, 60.3834],
              [22.4743, 60.3898],
              [22.5044, 60.42],
              [22.3906, 60.3966],
              [22.2964, 60.3845],
              [22.2733, 60.4078],
              [22.2094, 60.4461],
              [22.1272, 60.4412],
              [22.0631, 60.4576],
              [22.0242, 60.4612],
              [22.0071, 60.4879],
              [21.9902, 60.4907],
              [21.959, 60.5317],
              [21.8689, 60.5511],
              [21.8832, 60.5065],
              [21.8252, 60.5014],
              [21.8008, 60.5184],
              [21.8279, 60.5466],
              [21.8188, 60.5805],
              [21.8437, 60.5983],
              [21.8444, 60.6294],
              [21.7904, 60.6182],
              [21.7652, 60.5971],
              [21.6737, 60.5849],
              [21.6852, 60.5529],
              [21.6653, 60.5409],
              [21.6581, 60.5189],
              [21.63, 60.5016],
              [21.5757, 60.5028],
              [21.5996, 60.5349],
              [21.5611, 60.5643],
              [21.4631, 60.5866],
              [21.4263, 60.604],
              [21.4266, 60.6298],
              [21.4121, 60.6501],
              [21.3808, 60.67],
              [21.3359, 60.6873],
              [21.3519, 60.7179],
              [21.4072, 60.7163],
              [21.4224, 60.7059],
              [21.4668, 60.7041],
              [21.4284, 60.7413],
              [21.4356, 60.7578],
              [21.3876, 60.7764],
              [21.41, 60.7892],
              [21.3552, 60.8216],
              [21.3572, 60.8607],
              [21.3409, 60.8928],
              [21.2311, 60.9123],
              [21.2549, 60.9271],
              [21.3242, 60.9276],
              [21.3117, 60.9431],
              [21.3247, 60.9591],
              [21.2449, 60.9836],
              [21.3008, 61.0061],
              [21.3798, 60.9501],
              [21.4122, 60.9318],
              [21.4429, 60.945],
              [21.4306, 60.9606],
              [21.3905, 60.9808],
              [21.341, 61.0173],
              [21.3068, 61.059],
              [21.2985, 61.0882],
              [21.3959, 61.0623],
              [21.4214, 61.0485],
            ],
          ],
          [
            [
              [22.4344, 59.9754],
              [22.4274, 59.9287],
              [22.3958, 59.949],
              [22.4344, 59.9754],
            ],
          ],
          [
            [
              [22.526, 59.9878],
              [22.5607, 59.9834],
              [22.5567, 59.9678],
              [22.5125, 59.9587],
              [22.5051, 59.9832],
              [22.526, 59.9878],
            ],
          ],
          [
            [
              [22.9181, 60.0696],
              [22.921, 60.043],
              [22.8859, 60.0318],
              [22.8912, 60.0087],
              [22.8638, 60.0047],
              [22.8516, 60.0555],
              [22.889, 60.0692],
              [22.9181, 60.0696],
            ],
          ],
          [
            [
              [22.931, 60.1251],
              [22.9702, 60.12],
              [22.9735, 60.0907],
              [22.9307, 60.0905],
              [22.9148, 60.08],
              [22.8725, 60.0852],
              [22.8699, 60.1007],
              [22.8915, 60.1196],
              [22.931, 60.1251],
            ],
          ],
          [
            [
              [21.6071, 60.1875],
              [21.6541, 60.1786],
              [21.6961, 60.1783],
              [21.6931, 60.157],
              [21.6643, 60.14],
              [21.6728, 60.1202],
              [21.6498, 60.1133],
              [21.5868, 60.1093],
              [21.5227, 60.1123],
              [21.503, 60.1279],
              [21.5164, 60.1562],
              [21.5683, 60.1826],
              [21.6071, 60.1875],
            ],
          ],
          [
            [
              [22.3902, 60.21],
              [22.367, 60.17],
              [22.3401, 60.1489],
              [22.2881, 60.1525],
              [22.3111, 60.1934],
              [22.3902, 60.21],
            ],
          ],
          [
            [
              [22.1047, 60.2221],
              [22.1073, 60.1988],
              [22.0777, 60.1893],
              [22.0625, 60.1683],
              [22.0305, 60.1502],
              [21.9754, 60.1495],
              [21.9427, 60.1667],
              [21.958, 60.1944],
              [22.0093, 60.2033],
              [22.0524, 60.2185],
              [22.1047, 60.2221],
            ],
          ],
          [
            [
              [21.6418, 60.254],
              [21.6127, 60.223],
              [21.5585, 60.2246],
              [21.5732, 60.2524],
              [21.6418, 60.254],
            ],
          ],
          [
            [
              [22.3091, 60.2724],
              [22.3073, 60.2571],
              [22.3293, 60.2365],
              [22.2782, 60.1999],
              [22.2201, 60.1943],
              [22.2035, 60.2056],
              [22.2345, 60.2488],
              [22.269, 60.2656],
              [22.3091, 60.2724],
            ],
          ],
          [
            [
              [22.4652, 60.3092],
              [22.4721, 60.2981],
              [22.4257, 60.2696],
              [22.4242, 60.2505],
              [22.3752, 60.2438],
              [22.3316, 60.2462],
              [22.3146, 60.2679],
              [22.3641, 60.2823],
              [22.3713, 60.2965],
              [22.4399, 60.3025],
              [22.4652, 60.3092],
            ],
          ],
          [
            [
              [21.931, 60.3137],
              [21.9651, 60.3141],
              [21.9751, 60.288],
              [21.9602, 60.278],
              [21.9137, 60.2799],
              [21.8826, 60.2898],
              [21.8893, 60.3049],
              [21.931, 60.3137],
            ],
          ],
          [
            [
              [22.965, 60.3131],
              [22.9866, 60.3014],
              [22.9396, 60.2605],
              [22.8994, 60.2393],
              [22.8996, 60.2232],
              [22.8643, 60.2189],
              [22.8595, 60.182],
              [22.8323, 60.1745],
              [22.87, 60.152],
              [22.8591, 60.1417],
              [22.8551, 60.1064],
              [22.8201, 60.0858],
              [22.7745, 60.0772],
              [22.7925, 60.0374],
              [22.7876, 60.0177],
              [22.7309, 60.0163],
              [22.6986, 60.0069],
              [22.679, 60.0344],
              [22.6308, 60.0207],
              [22.5918, 60.021],
              [22.5732, 60.032],
              [22.4957, 60.0174],
              [22.4563, 60.0001],
              [22.4359, 60.0218],
              [22.4065, 60.0139],
              [22.3774, 60.0233],
              [22.3859, 60.0393],
              [22.3657, 60.0618],
              [22.3683, 60.0911],
              [22.3931, 60.0934],
              [22.4297, 60.1244],
              [22.4647, 60.1378],
              [22.4927, 60.1584],
              [22.4528, 60.1646],
              [22.4216, 60.1585],
              [22.4172, 60.1822],
              [22.4612, 60.2033],
              [22.4553, 60.2235],
              [22.4846, 60.241],
              [22.5088, 60.2298],
              [22.4876, 60.2054],
              [22.5947, 60.211],
              [22.6133, 60.2312],
              [22.6467, 60.2293],
              [22.7332, 60.2381],
              [22.794, 60.235],
              [22.8685, 60.2478],
              [22.8712, 60.2717],
              [22.9042, 60.2807],
              [22.909, 60.3021],
              [22.965, 60.3131],
            ],
          ],
          [
            [
              [22.2562, 60.3326],
              [22.3009, 60.3217],
              [22.3658, 60.3183],
              [22.3402, 60.2876],
              [22.303, 60.2878],
              [22.2683, 60.2723],
              [22.2266, 60.2679],
              [22.2374, 60.2511],
              [22.1859, 60.2556],
              [22.1328, 60.2485],
              [22.0897, 60.2866],
              [22.1296, 60.3312],
              [22.2562, 60.3326],
            ],
          ],
          [
            [
              [22.015, 60.3364],
              [22.041, 60.3241],
              [22.0089, 60.3086],
              [21.9216, 60.3227],
              [21.9838, 60.3374],
              [22.015, 60.3364],
            ],
          ],
          [
            [
              [22.4247, 60.3713],
              [22.4184, 60.351],
              [22.4877, 60.3466],
              [22.5167, 60.3379],
              [22.4507, 60.3069],
              [22.3707, 60.2983],
              [22.3741, 60.3205],
              [22.3083, 60.3282],
              [22.322, 60.3514],
              [22.3946, 60.3716],
              [22.4247, 60.3713],
            ],
          ],
          [
            [
              [22.2967, 60.3776],
              [22.3077, 60.3598],
              [22.1813, 60.3409],
              [22.1458, 60.3557],
              [22.1917, 60.3671],
              [22.2967, 60.3776],
            ],
          ],
          [
            [
              [22.4783, 60.4087],
              [22.4446, 60.3837],
              [22.3512, 60.3655],
              [22.3237, 60.3853],
              [22.4783, 60.4087],
            ],
          ],
          [
            [
              [22.2277, 60.4313],
              [22.2682, 60.3981],
              [22.2272, 60.3854],
              [22.1744, 60.3854],
              [22.1543, 60.4123],
              [22.2046, 60.4311],
              [22.2277, 60.4313],
            ],
          ],
          [
            [
              [21.8051, 60.4479],
              [21.8325, 60.4345],
              [21.8233, 60.421],
              [21.7836, 60.4298],
              [21.8051, 60.4479],
            ],
          ],
          [
            [
              [21.6385, 60.4561],
              [21.6459, 60.4263],
              [21.6052, 60.4053],
              [21.6075, 60.4376],
              [21.6385, 60.4561],
            ],
          ],
          [
            [
              [21.9289, 60.4852],
              [21.9823, 60.4758],
              [21.9912, 60.467],
              [21.9987, 60.4697],
              [22.0062, 60.4639],
              [22.0288, 60.4605],
              [22.0307, 60.4432],
              [22.0759, 60.4421],
              [22.0697, 60.4269],
              [21.9952, 60.4231],
              [21.9868, 60.4389],
              [21.9467, 60.4464],
              [21.9289, 60.4852],
            ],
          ],
          [
            [
              [21.8548, 60.4894],
              [21.903, 60.4764],
              [21.9419, 60.434],
              [21.9786, 60.4155],
              [21.9821, 60.4009],
              [22.0134, 60.374],
              [22.0524, 60.3531],
              [22.0311, 60.3436],
              [21.9167, 60.3465],
              [21.8644, 60.3642],
              [21.8527, 60.377],
              [21.7998, 60.3899],
              [21.7996, 60.4154],
              [21.83, 60.4166],
              [21.8443, 60.4346],
              [21.8137, 60.4525],
              [21.8269, 60.4887],
              [21.8548, 60.4894],
            ],
          ],
          [
            [
              [21.761, 60.5559],
              [21.8054, 60.5409],
              [21.7772, 60.5068],
              [21.7351, 60.4921],
              [21.7439, 60.4717],
              [21.7162, 60.4558],
              [21.6964, 60.4696],
              [21.7179, 60.5269],
              [21.7504, 60.5392],
              [21.761, 60.5559],
            ],
          ],
          [
            [
              [21.2338, 60.5868],
              [21.3136, 60.5523],
              [21.3187, 60.5205],
              [21.3504, 60.4949],
              [21.2868, 60.4915],
              [21.2381, 60.5266],
              [21.2846, 60.5303],
              [21.2287, 60.5734],
              [21.2338, 60.5868],
            ],
          ],
          [
            [
              [21.3796, 60.5969],
              [21.4486, 60.5597],
              [21.5051, 60.5491],
              [21.4805, 60.5142],
              [21.5007, 60.4955],
              [21.4709, 60.4779],
              [21.4355, 60.4847],
              [21.434, 60.5016],
              [21.4041, 60.5066],
              [21.3308, 60.5442],
              [21.3491, 60.5739],
              [21.367, 60.5818],
              [21.3796, 60.5969],
            ],
          ],
          [
            [
              [21.2751, 60.6697],
              [21.3227, 60.6655],
              [21.3111, 60.6448],
              [21.3553, 60.6246],
              [21.3647, 60.5834],
              [21.3153, 60.6118],
              [21.3253, 60.5881],
              [21.2924, 60.5883],
              [21.2768, 60.6078],
              [21.2303, 60.6306],
              [21.2751, 60.6697],
            ],
          ],
          [
            [
              [21.2645, 60.8579],
              [21.2814, 60.8271],
              [21.2295, 60.838],
              [21.2645, 60.8579],
            ],
          ],
          [
            [
              [21.2096, 60.894],
              [21.2827, 60.8898],
              [21.2732, 60.8752],
              [21.2417, 60.8671],
              [21.226, 60.8432],
              [21.1828, 60.8626],
              [21.1776, 60.8819],
              [21.2096, 60.894],
            ],
          ],
        ],
      },
      properties: {name: 'Southwest Finland', id: 'FI-19', CNTRY: 'Finland', TYPE: 'Region'},
      id: 'FI-19',
    },
  ],
}
export default map
