import {KTSVG} from '../../../../../../_metronic/helpers'

interface BasicDataWidgetProps {
  icon: string
  title: string
  value: string | number
  titleFSzClass?: string
  valueFSzClass?: string
  className?: string
}

const BasicDataWidget: React.FC<BasicDataWidgetProps> = ({
  icon,
  title,
  value,
  titleFSzClass,
  valueFSzClass,
  className = 'd-flex border rounded-0 ps-3 py-5',
}) => {
  return (
    <div className={className}>
      <div className='d-flex me-4'>
        <KTSVG className='svg-icon-3x svg-icon-sempai-green' path={icon} />
      </div>
      <div className='d-flex flex-column'>
        <div className={`fw-bold ${titleFSzClass ? titleFSzClass : 'fs-8'}`}>{title}</div>
        <div className={`fw-boldest ${valueFSzClass ? valueFSzClass : 'fs-2'}`}>{value}</div>
      </div>
    </div>
  )
}

export default BasicDataWidget
