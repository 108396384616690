import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";
import {TAB_NAME, IReportDetails} from "../../models/Reports";
import React, {useRef} from "react";
import clsx from "clsx";
import CommentTab from "../SeoStatistic/Tabs/CommentTab";
import BingDataStatisticTab from "./Tabs/BingDataStatisticTab";

interface IBingTabsContent {
    tab: string
}
const BingTabsContent: React.FC<IBingTabsContent> = ({ tab }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const componentRef = useRef<HTMLDivElement>(null)

    return(
        <div className='tab-content border'>
            <div className={clsx('tab-pane', {active: tab === TAB_NAME.COMMENT})}>
                <CommentTab />
            </div>
            <div className={clsx('tab-pane', {active: tab === TAB_NAME.ANALYSIS})}>
                <BingDataStatisticTab/>
            </div>
        </div>
    )
}

export default BingTabsContent