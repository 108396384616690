/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {ICompany, UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {toAbsoluteUrl} from '../../../helpers'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useHistory} from 'react-router-dom'

const REPORTS_PATHNAME_REGEX = /\/reports\/[0-9]{0}\w+/

const HeaderUserMenu: FC = (props) => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const companies: ICompany[] = useSelector<RootState>(
    ({auth}) => auth.companies,
    shallowEqual
  ) as ICompany[]

  const logout = () => {
    dispatch(auth.actions.logout())
  }

  const changeCompany = useCallback(
    (newId: number) => {
      dispatch(auth.actions.changeCompanyId(newId))
      if (REPORTS_PATHNAME_REGEX.test(window.location.pathname)) {
        history.push('/reports')
      }
    },
    [dispatch, history]
  )

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')} alt='metronic' />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.name} {user.last_name}
            </div>
            <div className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/account/overview'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MY_PROFILE'})}
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          {intl.formatMessage({id: 'ACCOUNT.SETTINGS'})}
        </Link>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>{intl.formatMessage({id: 'MY_COMPANIES'})}</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-250px py-4'>
          {companies &&
            companies.map((item, index) => (
              <div
                key={index}
                className='menu-item px-3'
                onClick={() => changeCompany(item.client_id)}
              >
                <div className='menu-link px-5'>{item.client_name}</div>
              </div>
            ))}
        </div>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5'>
        <a className='menu-link px-5' onClick={logout}>
          {intl.formatMessage({id: 'SIGN_OUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
