import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {useIntl} from "react-intl";

interface AdPreviewModalProps {
    showModal: boolean;
    modalUrl: string;
    onHide: () => void;
}

const AdPreviewModal: React.FC<AdPreviewModalProps> = ({ showModal, modalUrl, onHide }) => {
    const intl = useIntl()

    return (
        <Modal show={showModal} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({id: 'AD_PREVIEW'})}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe src={modalUrl} width="100%" height="700px" title={intl.formatMessage({id: 'AD_PREVIEW'})}></iframe>
            </Modal.Body>
        </Modal>
    );
};

export default AdPreviewModal;
