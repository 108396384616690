import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const AGR_API = `${API_URL}/agreements`

export async function getAgreementsList(
  companyId: number,
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${AGR_API}?company_id=${companyId}&search=${search}&page=${page}&perPage=${perPage}`
    )
  return await axios.get(
    `${AGR_API}?company_id=${companyId}&page=${page}&perPage=${perPage}&${filter}`
  )
}

