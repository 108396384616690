import React from 'react'
import {SEOLinks} from "../../../models/AbstractReportDetails";

interface LinksItemProps {
  item: SEOLinks
  className?: string
}

const LinksItem: React.FC<LinksItemProps> = ({item, className}) => {
  return (
    <tr className={className}>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column mw-700px'>
            <a
              href={item.url}
              target='_blank'
              rel='noreferrer'
              className='crop-text mw-600px text-lite-primary text-hover fw-bolder fs-6'
            >
              {item.url}
            </a>
          </div>
        </div>
      </td>
      <td>
        <div className='text-end'>
          <div className='fw-bolder text-dark fs-6'>{item.categoryName}</div>
        </div>
      </td>
    </tr>
  )
}

export default LinksItem
