import React from 'react'
import Spinner from '../Spinner/Spinner'


interface ErrorViewProps {}

const LoadingView: React.FC<ErrorViewProps> = () => {

  return (
    <div className='d-flex flex-column flex-root overlay overlay-block rounded'>
      <div data-testid="loading-view" style={{height: '350px'}} className="bg-white">
        <Spinner color='bg-white'/>
      </div>
    </div>
  )
}

export default LoadingView
