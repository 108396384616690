import { put, select, takeLatest } from 'redux-saga/effects'
import {ActionWithPayload, RootState} from '../../../../setup'
import {IAgreement} from '../models/Agreement'
import { getAgreementsList } from './AgreementCRUD'
import {selectors as authSelectors} from '../../auth/redux/AuthRedux'


export const AGREEMENT_REQUEST = 'AGREEMENT_REQUEST'
export const AGREEMENT_REQUEST_SUCCESS = 'AGREEMENT_REQUEST_SUCCESS'
export const AGREEMENT_REQUEST_FAIL = 'AGREEMENT_REQUEST_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IAgreementState {
  agreementList?: IAgreement[]
  agreementError: boolean
  agreementLoading: boolean

  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
}

const initailState: IAgreementState = {
  agreementList: [],
  agreementError: false,
  agreementLoading: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 20,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,
}

export const reducer = (
  state: IAgreementState = initailState,
  action: ActionWithPayload<IAgreementState>
) => {
  switch (action.type) {
    case AGREEMENT_REQUEST:
      return {...state, agreementLoading: true}
    case AGREEMENT_REQUEST_SUCCESS:
      const agreementList = action.payload?.agreementList
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        agreementList,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        agreementLoading: false,
        agreementError: false,
      }
    case AGREEMENT_REQUEST_FAIL:
      return {...state, agreementLoading: false, agreementError: true}

    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    default:
      return state
  }
}

export const actions = {
  requestAgreements: () => ({type: AGREEMENT_REQUEST}),

  requestAgreementsSuccess: (
    agreementList: IAgreement[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: AGREEMENT_REQUEST_SUCCESS,
    payload: {agreementList, currentPage, totalPages, perPage, totalItem},
  }),

  requestAgreementFail: () => ({type: AGREEMENT_REQUEST_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getAgreementState: (state: RootState) => state.agreements,
  getCurrentPage: (state: RootState) => state.agreements.currentPage,
  getLimitPerPage: (state: RootState) => state.agreements.perPage,
  getFilter: (state: RootState) => state.agreements.filter,
  getTotalItem: (state: RootState) => state.agreements.totalItem,
  getSearchText: (state: RootState) => state.agreements.searchText,
}

function* listUpdate() {
    const companyId: number = yield select(authSelectors.getCompanyId)
    const searchValue: string = yield select(selectors.getSearchText)
    const page: number = yield select(selectors.getCurrentPage)
    const limit: number = yield select(selectors.getLimitPerPage)
    const filter: string = yield select(selectors.getFilter)
    const {data} = yield getAgreementsList(companyId, page, limit, filter, searchValue)
    return {data}
  }

export function* saga() {
  yield takeLatest(AGREEMENT_REQUEST, function* getAgreementSaga() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestAgreementsSuccess(
          data.items,
          data.currentPage,
          data.lastPage,
          data.perPage,
          data.total
        )
      )
    } catch (err) {
      yield put(actions.requestAgreementFail())
    }
  })
}
