import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IReportDetails} from '../../../models/Reports'
import {selectors} from '../../../redux/ReportsRedux'
import AttachmentItem from './AttachmentItem'

interface AttachemntsTabProps {}

const AttachemntsTab: React.FC<AttachemntsTabProps> = () => {
  const intl = useIntl()
  const reportData = useSelector(selectors.getReportData) as IReportDetails

  const fileInfoParse = (url: string) => {
    const dataArr = []
    for (let i = url.length; i >= 0; i--) {
      if (url[i] === '.') {
        dataArr.push(url.slice(i + 1))
      }
      if (url[i] === '/') {
        dataArr.push(url.slice(i + 1))
        break
      }
    }
    return dataArr
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          {intl.formatMessage({id: 'ATTACHMENTS'})}
          {reportData && reportData.attachments && (
            <span className='fs-6 text-gray-400 fw-bold ms-1'>
              {reportData.attachments.length} resources
            </span>
          )}
        </h3>

        <div className='d-flex my-2'>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-3 position-absolute ms-3'
            />
          </div>
        </div>
      </div>

      <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        {reportData &&
          reportData.attachments?.map((item, index) => (
            <div key={index} className='col-12 col-sm-12 col-xl-2'>
              <AttachmentItem
                fileData={fileInfoParse(item)}
                url={item}
              />
            </div>
          ))}
      </div>
    </>
  )
}

export default AttachemntsTab
