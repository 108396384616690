import React, {useEffect, useState} from 'react'
import {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {FullScreen, useFullScreenHandle} from 'react-full-screen'

type Props = {
  className: string
  chartId: string
  dateArray: Date[]
  firstDataSet: number[] | string[]
  secondDataSet: number[] | string[]
  firstDataSetTitle: string
  secondDataSetTitle: string
  stroke?: 'smooth' | 'straight' | 'stepline'
  height?: number
  colors?: string[]
  print?: boolean
}

const ChartLinesWidget: React.FC<Props> = ({
  className,
  chartId,
  dateArray,
  firstDataSet,
  secondDataSet,
  firstDataSetTitle,
  secondDataSetTitle,
  stroke = 'straight',
  height = 350,
  colors,
  print = false,
}) => {
  const handle = useFullScreenHandle()

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-500')
  const baseColor = getCSSVariableValue('--bs-sempai-green')
  const baseLightColor = getCSSVariableValue('--bs-light-success')
  const secondaryColor = getCSSVariableValue('--bs-dark')
  const secondaryLightColor = getCSSVariableValue('--bs-light-primary')
  const [chartOptions] = useState<ApexOptions>({

    chart: {
      id: chartId,
      fontFamily: 'inherit',
      height: 350,
      toolbar: {
        show: (print ? false : true),
        tools:{
          download:true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      },
    },
    stroke: {
      curve: stroke,
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      markers: {
        width: 38,
        height: 2,
        offsetY: -2,
      },
    },
    xaxis: {
      type: 'datetime',
      categories: dateArray,
      axisBorder: {
        show: true,
        color: secondaryColor,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: labelColor,
        },
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: secondaryColor,
        },
        labels: {
          style: {
            colors: labelColor,
          },
           formatter: (val: number) => (val > 0 ? val + '' : '0'),
        },
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          color: secondaryColor,
        },
        labels: {
          formatter: (val: number) => (val ? val + '' : '0'),
        },
      },
    ],
    colors: colors ? colors : [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      colors: [baseLightColor, secondaryLightColor],
      strokeColors: [baseLightColor, secondaryLightColor],
      strokeWidth: 3,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: baseColor,
              },
              labels: {
                formatter: (val: number) => {
                  if (val / 1000 > 0) return (val / 1000).toString() + 'k'
                  return val.toString()
                },
              },
            },
            {
              opposite: true,
              axisBorder: {
                show: true,
                color: secondaryColor,
              },
              labels: {
                formatter: (val: number) => {
                  if (val / 1000 > 0) return (val / 1000).toString() + 'k'
                  return val.toString()
                },
              },
            },
          ],
        },
      },
    ],
  })

  useEffect(() => {
    if (handle.active) {
      ApexCharts.exec(
        'chart-lines-sem',
        'updateOptions',
        {
          ...chartOptions,
          responsive: [],
        },
        true
      )
    } else {
      ApexCharts.exec(
        'chart-lines-sem',
        'updateOptions',
        {
          ...chartOptions,
        },
        true
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handle])

  return (
    <>
      <div className={`session-group-chart px-5 ${className}`}>
        <div className='align-items-center border-0 clicks-group-chart'>
          <Button
            className='d-lg-none d-block btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1 d-print-none'
            variant='light'
            onClick={handle.enter}
          >
            <KTSVG path='/media/icons/duotone/General/Expand-arrows.svg' className='svg-icon-3' />
          </Button>
        </div>
        <div className='pt-xl-2 pb-xl-2 p-0' data-testid='click-revenue-to-date-chart'>
          <FullScreen handle={handle} className='bg-white'>
            {handle.active && (
              <div className='d-flex justify-content-end mb-5'>
                <Button
                  onClick={handle.exit}
                  variant='light'
                  className='d-lg-none d-block btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
                >
                  <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-2x' />
                </Button>
              </div>
            )}
            <Chart
              series={[
                {
                  name: firstDataSetTitle,
                  type: 'line',
                  data: firstDataSet,
                },
                {
                  name: secondDataSetTitle,
                  type: 'line',
                  data: secondDataSet,
                },
              ]}
              options={chartOptions}
              height={height}
              width='100%'
            />
          </FullScreen>
        </div>
      </div>
    </>
  )
}

export default ChartLinesWidget
