import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import React, {useEffect, useState} from "react";
import ChartLinesWidget from "../../Widgets/ChartLinesWidget";

interface IChartData {
    conversionsArray: number[]
    clicksArray: number[]
    datesChart: Date[]
}
const BingDailyChart: React.FC = () => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [chartData, setChartData] = useState<IChartData>()

    useEffect(() => {
        const conversionsArray: number[] = []
        const clicksArray: number[] = []
        const datesArray: any[] = []

        setChartData({
            conversionsArray: conversionsArray,
            clicksArray: clicksArray,
            datesChart: datesArray,

        })
        if(reportData.details.bingAdsDaily) {
            reportData.details.bingAdsDaily.forEach((item: any) => {
                conversionsArray.push(item.conversions)
                clicksArray.push(item.clicks)
                datesArray.push(item.date)

            })
        }
    }, [reportData]);

    return (
        <>
            <div className='row g-5 g-xl-8'>
                <div className='row g-5 g-xl-8'>
                    <div className='col-xl-12'>
                        {chartData && (
                            <ChartLinesWidget
                                className='card-xl-stretch mb-xl-8'
                                chartId='bing-BingAdsGroup'
                                dateArray={chartData.datesChart}
                                firstDataSet={chartData.clicksArray}
                                secondDataSet={chartData.conversionsArray}
                                firstDataSetTitle='Kliknięcia'
                                secondDataSetTitle='Konwersje'
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BingDailyChart