import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../redux/ReportsRedux";
import { IReportDetails, TAB_NAME } from "../../models/Reports";
import clsx from "clsx";
import { useIntl } from "react-intl";
import BingPrintView from "./Print/BingPrintView";
import { KTSVG } from "../../../../../_metronic/helpers";
import TabsNav from "../Common/TabsNav";

interface BingTabsNavProps {
    tab: string;
    setTab: (data: string) => void;
    showPdf?: boolean;
}

const BingTabsNav: React.FC<BingTabsNavProps> = ({ tab, setTab, showPdf}) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails | undefined;

    const hasData = Boolean(
        (reportData?.details?.bingAdsMonthly && reportData.details.bingAdsMonthly.length > 0) ||
        (reportData?.details?.bingAdsAge && reportData.details.bingAdsAge.length > 0) ||
        (reportData?.details?.bingAdsCampaignList && reportData.details.bingAdsCampaignList.length > 0) ||
        (reportData?.details?.bingAdsDaily && reportData.details.bingAdsDaily.length > 0) ||
        (reportData?.details?.bingAdsDevice && reportData.details.bingAdsDevice.length > 0) ||
        (reportData?.details?.bingAdsGender && reportData.details.bingAdsGender.length > 0) ||
        (reportData?.details?.bingAdsGeoData && reportData.details.bingAdsGeoData.length > 0)
    );

    return (
        <TabsNav
            tab={tab}
            setTab={setTab}
            reportData={reportData}
            PrintViewComponent={BingPrintView}
            showPdf={showPdf}
            hasData={hasData}
        />
    );
};

export default BingTabsNav;