import BasicDataWidget from "../../SeoStatistic/SeoWidgets/BasicDataWidget";
import React from "react";
import { graphicalSum, sponsoredSum } from "../../../models/AllegroReport";
import {useIntl} from "react-intl";
import {numSpaceFormater} from "../../../../../helpers/formater";

type DataProps = graphicalSum | sponsoredSum;

interface IAllegroDataWidget {
    data: DataProps;
    cardLabel: string;
    currency: string;
}
const AllegroDataWidget: React.FC<IAllegroDataWidget> = ({ data, cardLabel, currency }) => {
    const intl = useIntl();
    if (!data || (data.clicks === 0 && data.totalAttributionValue === 0 && data.totalCost === 0 && data.views === 0)) {
        return null;
    }

    return (
        <div className='row card-body g-1 w-100'>
            <span className='card-label fw-bolder fs-2 mb-1'>
                {intl.formatMessage({ id: cardLabel })}
            </span>
            <div className='col-12'>
                <div className='row g-0 justify-content-between'>
                    <div className='col-3'>
                        <BasicDataWidget
                            icon='/media/sempai-images/icons/Group-129.svg'
                            title={intl.formatMessage({id: 'CLICKS'})}
                            value={numSpaceFormater(data.clicks)}
                            valueFSzClass='fs-4'
                            className='d-flex border-right ps-3 py-5'
                        />
                    </div>
                    <div className='col-3'>
                        <BasicDataWidget
                            icon='/media/sempai-images/icons/conversion_value_1.svg'
                            title={intl.formatMessage({id: 'COST'})}
                            value={numSpaceFormater(data.totalCost.toFixed(2)) + ' ' + currency}
                            valueFSzClass='fs-4'
                            className='d-flex border-right ps-3 py-5'
                        />
                    </div>
                    <div className='col-3'>
                        <BasicDataWidget
                            icon='/media/sempai-images/icons/conversion_value_1.svg'
                            title={intl.formatMessage({id: 'INCOME'})}
                            value={numSpaceFormater(data.totalAttributionValue.toFixed(2)) + ' ' + currency}
                            valueFSzClass='fs-4'
                            className='d-flex border-right ps-3 py-5'
                        />
                    </div>
                    <div className='col-3'>
                        <BasicDataWidget
                            icon='/media/sempai-images/icons/monitor-with-eye.svg'
                            title={intl.formatMessage({id: 'IMPRESSIONS'})}
                            value={numSpaceFormater(data.views)}
                            valueFSzClass='fs-4'
                            className='d-flex border-right ps-3 py-5'
                        />
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <div className='row g-0 justify-content-between'>
                    <div className='col-3'>
                        <BasicDataWidget
                            icon='/media/sempai-images/icons/cost_per_conversion.svg'
                            title={intl.formatMessage({id: 'CPC'})}
                            value={data.avg_cpc.toFixed(2) + ' ' + currency}
                            valueFSzClass='fs-4'
                            className='d-flex border-right ps-3 py-5'
                        />
                    </div>
                    <div className='col-3'>
                        <BasicDataWidget
                            icon='/media/sempai-images/icons/Group-159.svg'
                            title={intl.formatMessage({id: 'CTR'})}
                            value={data.avg_ctr.toFixed(2) + '%'}
                            valueFSzClass='fs-4'
                            className='d-flex border-right ps-3 py-5'
                        />
                    </div>
                    <div className='col-3'>
                        <BasicDataWidget
                            icon='/media/sempai-images/icons/Group-44.svg'
                            title={intl.formatMessage({id: 'ROAS'})}
                            value={numSpaceFormater(data.avg_roas.toFixed(2)) + '%'}
                            valueFSzClass='fs-4'
                            className='d-flex border-right ps-3 py-5'
                        />
                    </div>
                    <div className='col-3'>
                        <BasicDataWidget
                            icon='/media/sempai-images/icons/audience.svg'
                            title={intl.formatMessage({id: 'CURRENCY'})}
                            value={currency}
                            valueFSzClass='fs-4'
                            className='d-flex border-right ps-3 py-5'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllegroDataWidget;
