import React from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../redux/ReportsRedux";
import { IReportDetails, TAB_NAME } from "../../models/Reports";
import TiktokPrintView from "./Print/TiktokPrintView";
import TabsNav from "../Common/TabsNav";

interface TiktokTabsNavProps {
    tab: string;
    setTab: (data: string) => void;
    showPdf?: boolean;
}
const TiktokTabsNav: React.FC<TiktokTabsNavProps> = ({ tab, setTab, showPdf = true }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails;

    const hasData =
      reportData &&
      reportData.type === 'tiktok' &&
      reportData.details &&
      reportData.details.monthly &&
      reportData.details.monthly.length > 0 &&
      reportData.details.top10ads &&
      reportData.details.top10ads.length > 0;

    return (
        <TabsNav
            tab={tab}
            setTab={setTab}
            reportData={reportData}
            PrintViewComponent={TiktokPrintView}
            showPdf={showPdf}
            hasData={hasData}
        />
    );
};

export default TiktokTabsNav;