import {useSelector} from 'react-redux'
import {selectors} from '../../../redux/ReportsRedux'
import LinksItem from './LinksItem'
import {SEOReportDeatils} from '../../../models/SEOReport'
import {useIntl} from "react-intl";

interface LinksTabProps {
    print?: boolean
}

const LinksWidget: React.FC<LinksTabProps> = (props) => {

    const intl = useIntl()
    const {links} = useSelector(selectors.getReportDetails) as SEOReportDeatils

    return links ? (
        <>
            { !props.print ? (
                <div className='row align-items-stretch'>
                    <div className=''>
                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>
                                  {intl.formatMessage({id: 'LINKS'})}
                                </span>
                            </h3>
                        </div>
                        <div className='card-body py-3'>
                            <div className='table-responsive'>
                                <table
                                    className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-2 gx-2'>
                                    <thead>
                                    <tr className='fw-bolder text-muted'>
                                        <th className='min-w-100px'>
                                            {intl.formatMessage({id: 'LINK'})}
                                        </th>
                                        <th className='text-end'>
                                            {intl.formatMessage({id: 'TYPE'})}
                                        </th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {links &&
                                        Array.isArray(links) &&
                                        // eslint-disable-next-line array-callback-return
                                        links.map((item, index) => {

                                            return (
                                                <LinksItem
                                                    key={index}
                                                    item={item}
                                                    className={index % 2 !== 0 ? 'bg-light' : ''}
                                                />
                                            )

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
           ):(
                <div className={'card'}>
                    <div className={'card-header border-0 pt-2 px-2'}>
                        <h3 className='card-title align-items-start flex-column'>
                                <span className={'card-label fw-bolder fs-3 mb-1'}>
                                  {'Raport przedstawiający pozyskane linki zewnętrzne znajdą Państwo w panelu klienta'}
                                </span>
                        </h3>
                    </div>
                </div>
           )}
        </>
    ):(
        <>

        </>
    )
}

export default LinksWidget
