import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import React, {useEffect, useState} from "react";
import BasicDataWidget from "../../SeoStatistic/SeoWidgets/BasicDataWidget";
import {Ga4monthlyData} from "../../GoogleAnalytics4/GA4GoogleAds/GA4ForGAdsBasicData";
import {round} from "@popperjs/core/lib/utils/math";
import {numSpaceFormater} from '../../../../../helpers/formater'

export interface bingMonthlyData {
    clicks: string | number
    cost: number | string
    conversion_value: number | string // revenue
    cpc: string
    roas: string
    ctr: string
    conversion_rate: string
    conversions: number | string
    impressions: number | string
    ctc: number | string
}
const BingBasicData = () => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [bingMonthlyData, setBingMonthlyData] = useState<bingMonthlyData>()

    useEffect(() => {
        setBingMonthlyData({
            roas: numSpaceFormater((parseFloat(reportData.details.bingAdsMonthly[0].conversion_value) / parseFloat(reportData.details.bingAdsMonthly[0].cost) * 100).toFixed(2)) + '%',
            cost: numSpaceFormater(reportData.details.bingAdsMonthly[0].cost) + ' zł',
            conversion_value: numSpaceFormater(reportData.details.bingAdsMonthly[0].conversion_value) + ' zł',
            cpc: numSpaceFormater(reportData.details.bingAdsMonthly[0].cpc),
            ctr: reportData.details.bingAdsMonthly[0].ctr,
            conversion_rate: numSpaceFormater(reportData.details.bingAdsMonthly[0].conversion_rate),
            impressions: numSpaceFormater(reportData.details.bingAdsMonthly[0].impressions),
            conversions: numSpaceFormater(reportData.details.bingAdsMonthly[0].conversions),
            clicks: numSpaceFormater(reportData.details.bingAdsMonthly[0].clicks),
            ctc: numSpaceFormater((parseFloat(reportData.details.bingAdsMonthly[0].conversion_value) / parseFloat(reportData.details.bingAdsMonthly[0].cost)).toFixed(2))
        })
    }, [reportData]);

    return (
        <>

            {bingMonthlyData && (
                <div className='row card-body g-1 w-100'>
                    <div className='col-12'>
                        <div className='row g-0 justify-content-between'>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-129.svg'
                                    title={intl.formatMessage({id: 'CLICKS'})}
                                    value={bingMonthlyData.clicks}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/cost_per_conversion_2.svg'
                                    title={intl.formatMessage({id: 'COST'})}
                                    value={bingMonthlyData.cost}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/monitor-with-eye.svg'
                                    title={intl.formatMessage({id: 'IMPRESSIONS'})}
                                    value={bingMonthlyData.impressions}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-44.svg'
                                    title={intl.formatMessage({id: 'ROAS'})}
                                    value={bingMonthlyData.roas}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row g-0 justify-content-between'>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/conversions.svg'
                                    title={intl.formatMessage({id: 'CONVERSIONS'})}
                                    value={bingMonthlyData.conversions}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/cost_per_conversion_2.svg'
                                    title={intl.formatMessage({id: 'CONVERSION_VALUE'})}
                                    value={bingMonthlyData.conversion_value}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            {/*<div className='col-3'>*/}
                            {/*    <BasicDataWidget*/}
                            {/*        icon='/media/sempai-images/icons/conversion_from_interaction_rate_2.svg'*/}
                            {/*        title={intl.formatMessage({id: 'CONVERSION_RATE'})}*/}
                            {/*        value={bingMonthlyData.conversion_rate}*/}
                            {/*        valueFSzClass='fs-4'*/}
                            {/*        className='d-flex border-right ps-3 py-5'*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-159.svg'
                                    title={intl.formatMessage({id: 'CTR'})}
                                    value={bingMonthlyData.ctr}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/cost_micros.svg'
                                    title={intl.formatMessage({id: 'CPC'})}
                                    value={bingMonthlyData.cpc}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </>
    )
}

export default BingBasicData