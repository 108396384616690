import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {SEOReportDeatils} from '../../../models/SEOReport'
import {selectors} from '../../../redux/ReportsRedux'
import ChartLinesWidget from '../../Widgets/ChartLinesWidget'
import BasicDataWidget from '../SeoWidgets/BasicDataWidget'
import {numSpaceFormater} from "../../../../../helpers/formater";

interface GSCBasicOrganicTrafficProps {
    print?: boolean
    onLoad?: () => void
}

const GSCBasicOrganicTraffic: React.FC<GSCBasicOrganicTrafficProps> = (props) => {
    const { print, onLoad } = props;
    const intl = useIntl()
    const {SearchConsoleDates} = useSelector(selectors.getReportDetails) as SEOReportDeatils
    const [clicksToImpresionChart, setClicksToImpresionChart] =
        useState<{ dates: Date[]; clicks: number[]; impresion: number[] }>()
    const [shouldDisplay, setShouldDisplay] = useState<boolean>(false);

    useEffect(() => {
        if(onLoad){
            onLoad();
        }
    }, [onLoad]);

    useEffect(() => {
        if (
            SearchConsoleDates &&
            Array.isArray(SearchConsoleDates.clicks) &&
            Array.isArray(SearchConsoleDates.dates) &&
            Array.isArray(SearchConsoleDates.impressions)
        ) {
            setClicksToImpresionChart({
                clicks: SearchConsoleDates.clicks,
                dates: SearchConsoleDates.dates,
                impresion: SearchConsoleDates.impressions,
            })

            if(SearchConsoleDates.clicks.length > 0 && SearchConsoleDates.impressions.length > 0 && SearchConsoleDates.dates.length > 0) {
                setShouldDisplay(true);
            }
        }
    }, [SearchConsoleDates])

    return shouldDisplay ? (
        <div className={print ? '' : 'border'}>
            <div className='row px-5 py-5'>
                <div className='pb-10'>
                    <div className='d-flex align-items-center'>
                        <div className='title me-15'>
                            <h3 className='fw-boldest text-sempai-green fs-2'>
                                {intl.formatMessage({id: 'TRAFFIC_GSC_BASIC_DATA'})}
                            </h3>
                            <div className='text-dark fw-bold fs-6'>
                                {intl.formatMessage({id: 'GSC_REPORT_SUBTITLE'})}
                            </div>
                        </div>
                        {SearchConsoleDates && SearchConsoleDates.dates && (
                            <div className='text-dark fw-bold fs-5 ms-15'>{`${intl.formatDate(
                                SearchConsoleDates.dates[0],
                                {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                }
                            )} - ${intl.formatDate(
                                SearchConsoleDates.dates[SearchConsoleDates.dates.length - 1],
                                {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                }
                            )}`}</div>
                        )}
                    </div>
                </div>
            </div>
            {SearchConsoleDates && (
                <div className='row g-1'>
                    <div className='col-12'>
                        <div className='row g-0'>
                            <div className='col-3 d-flex justify-content-center'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/search-engine.svg'
                                    title={intl.formatMessage({id: 'AVG_POSITION'})}
                                    value={SearchConsoleDates.ave_position.toFixed(2)}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3 d-flex justify-content-center'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-129.svg'
                                    title={intl.formatMessage({id: 'CLICKS'})}
                                    value={numSpaceFormater(SearchConsoleDates.clicks_sum)}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3 d-flex justify-content-center'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/monitor-with-eye.svg'
                                    title={intl.formatMessage({id: 'VIEWS'})}
                                    value={numSpaceFormater(SearchConsoleDates.impressions_sum)}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3 d-flex justify-content-center'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-159.svg'
                                    title='CTR'
                                    value={(SearchConsoleDates.ctr * 100).toFixed(2) + '%'}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <div className='row'>
                <div className='col-12'>

                    {clicksToImpresionChart && (
                        <ChartLinesWidget
                            className='card-xl-stretch'
                            chartId='seo-GSCBasicOrganicTraffic'
                            dateArray={clicksToImpresionChart.dates}
                            firstDataSet={clicksToImpresionChart.clicks}
                            secondDataSet={clicksToImpresionChart.impresion}
                            firstDataSetTitle={intl.formatMessage({id: 'CLICKS'})}
                            secondDataSetTitle={intl.formatMessage({id: 'IMPRESSIONS'})}
                            stroke='smooth'
                            height={350}
                            print={print ? true : false}
                            colors={[
                                getCSSVariableValue('--bs-sempai-green'),
                                getCSSVariableValue('--bs-sempai-green-text'),
                            ]}
                        />
                    )}
                </div>
            </div>
        </div>

    ) : <></>
}

export default GSCBasicOrganicTraffic
