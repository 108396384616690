import React, {useLayoutEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
// import am4geodata_polandLow from '../../../../../setup/utils/polandLow'
import {countriesMaps} from '../../../../../setup/utils/countriesMaps'
import {MapChart} from '@amcharts/amcharts4/maps'

am4core.useTheme(am4themes_animated)
am4core.addLicense('ch-custom-attribution')

export interface StatisticMapData {
  id: string
  value: number
}

interface StatisticMapProps {
  statisticData: StatisticMapData[]
  country?: string
}

interface CountriesMaps {
  [key: string]: any
}

const StatisticMap: React.FC<StatisticMapProps> = ({statisticData, country}) => {
  const chart = useRef<MapChart>()
  const countriesMapObj = countriesMaps as CountriesMaps

  useLayoutEffect(() => {
    let map = am4core.create('chartdiv', am4maps.MapChart)
    map.chartContainer.wheelable = false
    map.tapToActivate = true
    map.dragGrip.disabled = true
    map.mouseWheelBehavior = 'none'
    map.draggable = false
    map.background.fill = am4core.color('#F3F3F3')
    map.background.fillOpacity = 1
    map.geodata = countriesMapObj.PL
    if (country) {
      map.geodata = countriesMapObj[country]
    }

    let polygonSeries = map.series.push(new am4maps.MapPolygonSeries())
    polygonSeries.useGeodata = true

    map.projection = new am4maps.projections.Mercator()

    // code from https://en.wikipedia.org/wiki/ISO_3166-2:PLF
    // code for any https://pl.wikipedia.org/wiki/ISO_3166-1
    polygonSeries.data = statisticData

    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: am4core.color('#DAECD5'),
      max: am4core.color('#5DAE4D'),
    })

    let polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.tooltipText = '{name}: {value}'
    polygonTemplate.nonScalingStroke = true
    polygonTemplate.strokeWidth = 0.5

    let hs = polygonTemplate.states.create('hover')
    hs.properties.fill = am4core.color('#80FF67')

    chart.current = map

    return () => {
      map.dispose()
    }
  }, [statisticData, country, countriesMapObj])

  return (
    <div className=''>
      <div
        data-testid='geography-chart'
        id='chartdiv'
        style={{width: '100%', height: '280px'}}
      ></div>
    </div>
  )
}

export default StatisticMap
