import axios, {AxiosResponse} from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/me`
export const LOGIN_URL = `${API_URL}/login`
export const COMPANY_URL = `${API_URL}/companies/available`

// Server should return AuthModel
export async function login(email: string, password: string): Promise<AxiosResponse<AuthModel>> {
  const login = axios.post(LOGIN_URL, {email, password})
  return await login
}

export async function getAvailableCompanies() {
  return await axios.get(COMPANY_URL)
}

export async function getMyCompany(companyId: number) {
  return await axios.get(`${GET_USER_BY_ACCESSTOKEN_URL}/company?company_id=${companyId}`)
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
