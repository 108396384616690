import React, {useState} from 'react'
import clsx from 'clsx'
import {IReportDetails, SEM_TAB_NAME, TAB_NAME} from '../../models/Reports'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { selectors } from '../../redux/ReportsRedux'
import {KTSVG} from "../../../../../_metronic/helpers";
import LinkedinPrintView from '../Print/LinkedinPrintView'

interface TabsNavProps {
    tab: string
    setTab: (data: string) => void
    showPdf?: boolean
}

const LinkedinTabsNav: React.FC<TabsNavProps> = ({tab, setTab, showPdf = true}) => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [open, setOpen] = useState(false);

    return (
      <ul
        className='nav nav-tabs-report nav-stretch nav-fixed-top nav-line-tabs fw-bold fs-6 flex-nowrap'
        role='tablist'
      >
          <li className='nav-item action-btn'>
              <div
                className={clsx(`nav-link nav-link-report fs-xl-3 font-weight-bold fs-5 px-20 me-5`, {
                    active: tab === TAB_NAME.COMMENT,
                })}
                onClick={() => setTab(TAB_NAME.COMMENT)}
                role='tab'
              >
                  {intl.formatMessage({id: TAB_NAME.COMMENT})}
              </div>
          </li>

          {reportData && reportData.attachments && (
            <li className='nav-item action-btn'>
                <div
                  className={clsx(`nav-link nav-link-report fw-bolder fs-xl-4 fs-6 px-5`, {
                      active: tab === TAB_NAME.ATTACHMENTS,
                  })}
                  onClick={() => setTab(TAB_NAME.ATTACHMENTS)}
                  role='tab'
                >
                    {intl.formatMessage({id: TAB_NAME.ATTACHMENTS})}
                </div>
            </li>
          )}
          {reportData && showPdf && (
            <li className='nav-item action-btn'>
              <div onClick={() => setOpen(true)}
                   className={clsx(`nav-link nav-link-report fw-bolder fs-xl-4 fs-6 px-5`, {
                     active: tab === SEM_TAB_NAME.PRINT,
                   })}
              >
                <div>
                  <KTSVG
                    path='/media/sempai-images/icons/Icon-feather-download.svg'
                    className='svg-icon-3 svg-icon-white'
                  />
                  <span className='px-2'>{intl.formatMessage({id: SEM_TAB_NAME.PDF})}</span>
                </div>
                {open ? <LinkedinPrintView closePopup={() => setOpen(false)} /> : null}

              </div>
            </li>
          )}
      </ul>
    )
}

export default LinkedinTabsNav
