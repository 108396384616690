import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import {useIntl} from "react-intl";
import BasicDataWidget from "../../SeoStatistic/SeoWidgets/BasicDataWidget";
import {numSpaceFormater} from "../../../../../helpers/formater";

export interface Ga4monthlyData {
    costs: string
    revenue: string
    cpc: string
    roas: string

    conversion_rate: string
    users: string
    new_users: string
    sessions: string

    bounce_rate: string
    transactions: string
}

const GA4ForSeoBasicData: React.FC = () => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [ga4monthlyData, setGa4monthlyData] = useState<Ga4monthlyData>()

    useEffect(() => {

        setGa4monthlyData({
            costs: numSpaceFormater(reportData.details.GA4blockData.advertiserAdCost) + ' zł',
            revenue: numSpaceFormater(reportData.details.GA4blockData.purchaseRevenue) + ' zł',
            cpc: (reportData.details.GA4blockData.advertiserAdCost / reportData.details.GA4blockData.advertiserAdClicks * 100).toFixed(2) + '%',
            roas: (reportData.details.GA4blockData.purchaseRevenue / reportData.details.GA4blockData.advertiserAdCost * 100).toFixed(2) + '%',

            conversion_rate: (reportData.details.GA4blockData.conversions / reportData.details.GA4blockData.advertiserAdClicks * 100).toFixed(2) + '%',
            users: numSpaceFormater(reportData.details.GA4blockData.totalUsers),
            new_users: numSpaceFormater(reportData.details.GA4blockData.newUsers ?? 0),
            sessions: numSpaceFormater(reportData.details.GA4blockData.sessions),
            bounce_rate: (reportData.details.GA4blockData.bounceRate * 100).toFixed(2) + '%',
            transactions: numSpaceFormater(reportData.details.GA4blockData.transactions),
        })
    }, [reportData]);
    return (
        <>
            {ga4monthlyData && (
                <div className='row g-1 w-100 px-5'>
                    <div className='col-12'>
                        <div className='row g-0 justify-content-between'>
                            <div className='col-4'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/conversion_value2.svg'
                                    title={intl.formatMessage({id: 'REVENUE'})}
                                    value={ga4monthlyData.revenue}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-4'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-44.svg'
                                    title={intl.formatMessage({id: 'TRANSACTIONS'})}
                                    value={ga4monthlyData.transactions}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-4'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-159.svg'
                                    title={intl.formatMessage({id: 'BOUNCE_RATE'})}
                                    value={ga4monthlyData.bounce_rate}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row g-0 justify-content-between'>
                            <div className='col-4'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-50.svg'
                                    title={intl.formatMessage({id: 'NEW_USERS'})}
                                    value={ga4monthlyData.new_users}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-4'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-55.svg'
                                    title={intl.formatMessage({id: 'USERS'})}
                                    value={ga4monthlyData.users}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-4'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-129.svg'
                                    title={intl.formatMessage({id: 'SESSIONS'})}
                                    value={ga4monthlyData.sessions}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </>
    )

}


export default GA4ForSeoBasicData
