var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.2672, 54.102],
            [-6.2867, 54.112],
            [-6.3064, 54.1084],
            [-6.3216, 54.0936],
            [-6.3341, 54.0965],
            [-6.3389, 54.1152],
            [-6.3624, 54.1148],
            [-6.3622, 54.0734],
            [-6.3863, 54.0616],
            [-6.4117, 54.0645],
            [-6.4364, 54.0592],
            [-6.4562, 54.074],
            [-6.4771, 54.0792],
            [-6.4812, 54.0672],
            [-6.4964, 54.0645],
            [-6.5091, 54.0547],
            [-6.5288, 54.0596],
            [-6.544, 54.0536],
            [-6.5874, 54.0572],
            [-6.5981, 54.0465],
            [-6.6211, 54.0404],
            [-6.6456, 54.0625],
            [-6.658, 54.0628],
            [-6.6536, 54.0892],
            [-6.6416, 54.0976],
            [-6.6586, 54.1179],
            [-6.6388, 54.1322],
            [-6.6282, 54.1581],
            [-6.643, 54.1773],
            [-6.6636, 54.1944],
            [-6.7003, 54.2025],
            [-6.7127, 54.1965],
            [-6.7197, 54.1837],
            [-6.7393, 54.1844],
            [-6.7541, 54.1993],
            [-6.7742, 54.2006],
            [-6.8151, 54.226],
            [-6.8276, 54.2484],
            [-6.8269, 54.2612],
            [-6.8618, 54.2713],
            [-6.8737, 54.2858],
            [-6.8532, 54.2942],
            [-6.856, 54.3156],
            [-6.8806, 54.3493],
            [-6.9006, 54.3531],
            [-6.9047, 54.3707],
            [-6.9568, 54.3933],
            [-6.9817, 54.4091],
            [-7.0253, 54.4208],
            [-7.0513, 54.4118],
            [-7.0833, 54.3801],
            [-7.1077, 54.3687],
            [-7.1012, 54.3556],
            [-7.1179, 54.3543],
            [-7.1502, 54.337],
            [-7.185, 54.3406],
            [-7.1757, 54.3252],
            [-7.1802, 54.3119],
            [-7.1956, 54.3125],
            [-7.2063, 54.3049],
            [-7.2054, 54.2944],
            [-7.1713, 54.2854],
            [-7.1412, 54.2532],
            [-7.1452, 54.225],
            [-7.165, 54.2198],
            [-7.1881, 54.2265],
            [-7.2011, 54.2181],
            [-7.2253, 54.2146],
            [-7.2368, 54.202],
            [-7.2525, 54.195],
            [-7.2493, 54.1749],
            [-7.2648, 54.1468],
            [-7.2617, 54.1371],
            [-7.2888, 54.1344],
            [-7.2983, 54.1447],
            [-7.2843, 54.1543],
            [-7.2788, 54.1679],
            [-7.2902, 54.1727],
            [-7.3076, 54.1689],
            [-7.3374, 54.1459],
            [-7.3184, 54.1386],
            [-7.3068, 54.1251],
            [-7.3202, 54.119],
            [-7.3452, 54.116],
            [-7.3496, 54.1274],
            [-7.3717, 54.1386],
            [-7.4242, 54.1437],
            [-7.4108, 54.1576],
            [-7.4424, 54.1532],
            [-7.4658, 54.1436],
            [-7.4778, 54.1259],
            [-7.4976, 54.1264],
            [-7.5271, 54.1349],
            [-7.547, 54.1223],
            [-7.5647, 54.1289],
            [-7.5758, 54.1425],
            [-7.6002, 54.1431],
            [-7.6243, 54.1566],
            [-7.6264, 54.1719],
            [-7.663, 54.1891],
            [-7.6745, 54.1834],
            [-7.6851, 54.2078],
            [-7.7349, 54.2038],
            [-7.7487, 54.2119],
            [-7.8036, 54.2077],
            [-7.8129, 54.2026],
            [-7.8557, 54.2172],
            [-7.86, 54.2616],
            [-7.873, 54.2739],
            [-7.8678, 54.2954],
            [-7.8807, 54.293],
            [-7.8986, 54.3025],
            [-7.947, 54.3077],
            [-7.9654, 54.3262],
            [-7.9983, 54.35],
            [-8.0148, 54.3578],
            [-8.0605, 54.3705],
            [-8.1021, 54.4127],
            [-8.1578, 54.4397],
            [-8.1403, 54.4525],
            [-8.1737, 54.4656],
            [-8.1488, 54.4709],
            [-8.1121, 54.4706],
            [-8.0939, 54.4853],
            [-8.0422, 54.4878],
            [-8.0396, 54.5081],
            [-8.0038, 54.5465],
            [-7.9844, 54.5446],
            [-7.9667, 54.5494],
            [-7.9499, 54.535],
            [-7.9066, 54.5382],
            [-7.8446, 54.5351],
            [-7.8224, 54.5453],
            [-7.83, 54.5507],
            [-7.8221, 54.5624],
            [-7.7955, 54.5733],
            [-7.7946, 54.5812],
            [-7.7634, 54.5869],
            [-7.7494, 54.5992],
            [-7.7067, 54.6072],
            [-7.7051, 54.6339],
            [-7.738, 54.6197],
            [-7.77, 54.6225],
            [-7.8105, 54.645],
            [-7.8273, 54.6335],
            [-7.8451, 54.6313],
            [-7.8583, 54.6397],
            [-7.857, 54.651],
            [-7.9016, 54.6618],
            [-7.9112, 54.6775],
            [-7.898, 54.6864],
            [-7.9171, 54.6968],
            [-7.9072, 54.7018],
            [-7.8776, 54.7047],
            [-7.8563, 54.7249],
            [-7.8309, 54.7385],
            [-7.8161, 54.7365],
            [-7.8, 54.7214],
            [-7.7757, 54.7178],
            [-7.7718, 54.7117],
            [-7.745, 54.708],
            [-7.7344, 54.7174],
            [-7.7102, 54.727],
            [-7.6967, 54.7261],
            [-7.6378, 54.7514],
            [-7.6152, 54.7444],
            [-7.5524, 54.7464],
            [-7.5348, 54.7521],
            [-7.5467, 54.7603],
            [-7.5411, 54.7842],
            [-7.5436, 54.793],
            [-7.524, 54.8085],
            [-7.479, 54.825],
            [-7.4421, 54.8708],
            [-7.4414, 54.8824],
            [-7.4507, 54.8963],
            [-7.4444, 54.9123],
            [-7.4452, 54.9362],
            [-7.4332, 54.9424],
            [-7.3937, 54.9461],
            [-7.4038, 54.9566],
            [-7.3984, 54.9732],
            [-7.4061, 54.983],
            [-7.3902, 54.9977],
            [-7.401, 55.0065],
            [-7.3944, 55.0188],
            [-7.346, 55.0517],
            [-7.3172, 55.0467],
            [-7.2976, 55.0571],
            [-7.2885, 55.0508],
            [-7.2707, 55.0646],
            [-7.2569, 55.0676],
            [-7.2492, 55.0567],
            [-7.2112, 55.0568],
            [-7.1824, 55.0621],
            [-7.1461, 55.058],
            [-7.1448, 55.0471],
            [-7.1266, 55.0429],
            [-7.0514, 55.0505],
            [-7.0423, 55.0599],
            [-7.0123, 55.0772],
            [-7.019, 55.0895],
            [-7.0152, 55.1014],
            [-6.9953, 55.1067],
            [-6.9693, 55.1472],
            [-6.9613, 55.18],
            [-6.9663, 55.1946],
            [-6.9464, 55.1922],
            [-6.9232, 55.1798],
            [-6.8885, 55.1698],
            [-6.8542, 55.1674],
            [-6.8034, 55.1699],
            [-6.7702, 55.1677],
            [-6.7292, 55.1708],
            [-6.71, 55.19],
            [-6.6911, 55.1972],
            [-6.6588, 55.1992],
            [-6.6539, 55.204],
            [-6.6233, 55.2082],
            [-6.6078, 55.2064],
            [-6.5431, 55.2169],
            [-6.527, 55.2257],
            [-6.5304, 55.234],
            [-6.5056, 55.2411],
            [-6.5033, 55.2472],
            [-6.4664, 55.2513],
            [-6.459, 55.243],
            [-6.39, 55.2349],
            [-6.3784, 55.2428],
            [-6.3325, 55.2392],
            [-6.3081, 55.2296],
            [-6.2953, 55.2314],
            [-6.2348, 55.2049],
            [-6.1775, 55.215],
            [-6.1551, 55.2278],
            [-6.1375, 55.2276],
            [-6.1148, 55.2117],
            [-6.0984, 55.2089],
            [-6.063, 55.1921],
            [-6.0484, 55.1763],
            [-6.0308, 55.1702],
            [-6.0236, 55.1578],
            [-6.0246, 55.141],
            [-6.0392, 55.132],
            [-6.0334, 55.1237],
            [-6.0359, 55.1012],
            [-6.0508, 55.087],
            [-6.0541, 55.0611],
            [-6.0399, 55.0549],
            [-5.9916, 55.0614],
            [-5.9704, 55.0564],
            [-5.961, 55.0455],
            [-5.9759, 55.0075],
            [-5.9904, 54.9896],
            [-5.9782, 54.9805],
            [-5.9639, 54.9818],
            [-5.9543, 54.9708],
            [-5.9346, 54.9713],
            [-5.9181, 54.9628],
            [-5.8958, 54.9427],
            [-5.8762, 54.9099],
            [-5.8577, 54.9013],
            [-5.8411, 54.9018],
            [-5.836, 54.8844],
            [-5.8103, 54.8697],
            [-5.7941, 54.85],
            [-5.7626, 54.8545],
            [-5.7602, 54.8614],
            [-5.7248, 54.8501],
            [-5.7082, 54.8362],
            [-5.6886, 54.8079],
            [-5.6915, 54.7794],
            [-5.6868, 54.769],
            [-5.7078, 54.7472],
            [-5.731, 54.7385],
            [-5.7452, 54.7276],
            [-5.7831, 54.7226],
            [-5.8395, 54.7069],
            [-5.8929, 54.6749],
            [-5.8977, 54.6614],
            [-5.9116, 54.6496],
            [-5.9153, 54.6381],
            [-5.9025, 54.6337],
            [-5.916, 54.6091],
            [-5.9007, 54.6065],
            [-5.8844, 54.6125],
            [-5.8823, 54.6259],
            [-5.7969, 54.6617],
            [-5.7742, 54.6655],
            [-5.7388, 54.6786],
            [-5.712, 54.6674],
            [-5.6767, 54.6699],
            [-5.6491, 54.6664],
            [-5.6305, 54.6733],
            [-5.5801, 54.6789],
            [-5.5655, 54.666],
            [-5.5395, 54.6553],
            [-5.5258, 54.6381],
            [-5.5302, 54.6234],
            [-5.5186, 54.5984],
            [-5.4872, 54.5785],
            [-5.4747, 54.5633],
            [-5.482, 54.5478],
            [-5.4804, 54.5369],
            [-5.4681, 54.5256],
            [-5.4595, 54.4984],
            [-5.4301, 54.4867],
            [-5.4338, 54.4688],
            [-5.453, 54.4379],
            [-5.4765, 54.4272],
            [-5.4618, 54.4139],
            [-5.4604, 54.3834],
            [-5.4771, 54.3837],
            [-5.49, 54.365],
            [-5.4881, 54.3577],
            [-5.5014, 54.3314],
            [-5.5122, 54.3443],
            [-5.5286, 54.349],
            [-5.5282, 54.3618],
            [-5.5608, 54.3904],
            [-5.5781, 54.4003],
            [-5.5763, 54.4114],
            [-5.5623, 54.4298],
            [-5.5378, 54.4252],
            [-5.5334, 54.4311],
            [-5.5303, 54.4709],
            [-5.5353, 54.4937],
            [-5.5437, 54.4961],
            [-5.558, 54.5333],
            [-5.5765, 54.5319],
            [-5.5923, 54.5486],
            [-5.6117, 54.559],
            [-5.6357, 54.5625],
            [-5.6795, 54.5836],
            [-5.6959, 54.5688],
            [-5.6936, 54.5469],
            [-5.7037, 54.5375],
            [-5.677, 54.5236],
            [-5.6495, 54.5202],
            [-5.674, 54.4986],
            [-5.6537, 54.4979],
            [-5.641, 54.4807],
            [-5.636, 54.4636],
            [-5.6391, 54.4488],
            [-5.6277, 54.4381],
            [-5.6282, 54.4288],
            [-5.6443, 54.42],
            [-5.6392, 54.4013],
            [-5.6485, 54.3884],
            [-5.6718, 54.3758],
            [-5.6608, 54.3668],
            [-5.6306, 54.3714],
            [-5.6135, 54.3789],
            [-5.5708, 54.3828],
            [-5.5489, 54.3694],
            [-5.5488, 54.3541],
            [-5.5394, 54.3389],
            [-5.5435, 54.3325],
            [-5.5364, 54.3127],
            [-5.5461, 54.3074],
            [-5.5605, 54.2849],
            [-5.6089, 54.2489],
            [-5.6282, 54.2589],
            [-5.635, 54.2424],
            [-5.6586, 54.2264],
            [-5.6716, 54.2354],
            [-5.6784, 54.249],
            [-5.7208, 54.2501],
            [-5.7287, 54.2543],
            [-5.7554, 54.2474],
            [-5.8192, 54.2437],
            [-5.879, 54.2174],
            [-5.8889, 54.2063],
            [-5.8735, 54.1885],
            [-5.8707, 54.1673],
            [-5.8754, 54.1521],
            [-5.8872, 54.137],
            [-5.8849, 54.1233],
            [-5.8942, 54.1085],
            [-5.9104, 54.0968],
            [-5.9508, 54.0796],
            [-5.9546, 54.071],
            [-5.9978, 54.0585],
            [-6.02, 54.0401],
            [-6.0594, 54.0249],
            [-6.1064, 54.0418],
            [-6.0947, 54.0647],
            [-6.1382, 54.0643],
            [-6.1648, 54.0684],
            [-6.1838, 54.0761],
            [-6.1918, 54.0958],
            [-6.2042, 54.1016],
            [-6.2672, 54.102],
          ],
        ],
      },
      properties: {name: 'Northern Ireland', id: 'GB-NIR', CNTRY: 'Great Britain', TYPE: 'Nation'},
      id: 'GB-NIR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-9.006, 53.1427],
            [-9.024, 53.1396],
            [-9.029, 53.1314],
            [-9.0099, 53.1044],
            [-8.9522, 53.0884],
            [-8.9251, 53.0749],
            [-8.9202, 53.0685],
            [-8.8926, 53.0648],
            [-8.8982, 53.0389],
            [-8.8826, 53.0184],
            [-8.8968, 53.0113],
            [-8.8809, 52.9778],
            [-8.8599, 52.9801],
            [-8.8324, 52.9891],
            [-8.8254, 52.9805],
            [-8.8019, 52.9804],
            [-8.783, 52.9744],
            [-8.7757, 52.9899],
            [-8.7352, 53.0068],
            [-8.7172, 53.0269],
            [-8.6964, 53.0278],
            [-8.6653, 53.0412],
            [-8.6293, 53.043],
            [-8.6055, 53.0408],
            [-8.556, 53.0436],
            [-8.5489, 53.0242],
            [-8.5272, 53.0251],
            [-8.5237, 53.015],
            [-8.5066, 53.0092],
            [-8.5082, 52.9998],
            [-8.4994, 52.9841],
            [-8.4797, 52.9892],
            [-8.4588, 52.983],
            [-8.4469, 52.9732],
            [-8.4282, 52.9804],
            [-8.4122, 52.9957],
            [-8.3978, 52.9861],
            [-8.3779, 52.9835],
            [-8.3544, 52.9734],
            [-8.3376, 52.9874],
            [-8.3131, 52.9855],
            [-8.3073, 52.9779],
            [-8.2815, 52.9686],
            [-8.3056, 52.9508],
            [-8.3195, 52.9295],
            [-8.3374, 52.9159],
            [-8.3893, 52.9177],
            [-8.4032, 52.914],
            [-8.4171, 52.8753],
            [-8.4528, 52.8491],
            [-8.4475, 52.8136],
            [-8.4365, 52.806],
            [-8.4447, 52.7859],
            [-8.4616, 52.7758],
            [-8.4773, 52.7597],
            [-8.5072, 52.7491],
            [-8.5013, 52.72],
            [-8.5188, 52.7052],
            [-8.5403, 52.6998],
            [-8.5419, 52.6858],
            [-8.5765, 52.6706],
            [-8.5797, 52.6569],
            [-8.5915, 52.6439],
            [-8.6086, 52.6374],
            [-8.6204, 52.6439],
            [-8.6687, 52.6432],
            [-8.6869, 52.6465],
            [-8.6826, 52.6628],
            [-8.6515, 52.6706],
            [-8.6462, 52.6881],
            [-8.6796, 52.6874],
            [-8.7075, 52.673],
            [-8.7299, 52.6763],
            [-8.7633, 52.6758],
            [-8.8317, 52.6889],
            [-8.8586, 52.698],
            [-8.8822, 52.6993],
            [-8.9294, 52.6837],
            [-8.9626, 52.6856],
            [-8.968, 52.6934],
            [-8.9251, 52.7097],
            [-8.9454, 52.7306],
            [-8.9433, 52.7436],
            [-8.9562, 52.7488],
            [-8.9454, 52.7618],
            [-8.9605, 52.7683],
            [-8.9776, 52.7683],
            [-9.0323, 52.7501],
            [-9.0377, 52.7455],
            [-9.0291, 52.7306],
            [-9.0313, 52.7208],
            [-9.0506, 52.7058],
            [-9.0688, 52.683],
            [-9.0838, 52.6804],
            [-9.0913, 52.6641],
            [-9.1181, 52.6524],
            [-9.1278, 52.638],
            [-9.1503, 52.6243],
            [-9.1825, 52.6236],
            [-9.2061, 52.6178],
            [-9.2458, 52.6178],
            [-9.2565, 52.6152],
            [-9.2726, 52.5943],
            [-9.2844, 52.591],
            [-9.3252, 52.589],
            [-9.3294, 52.6021],
            [-9.2823, 52.6243],
            [-9.2898, 52.6308],
            [-9.3112, 52.6243],
            [-9.3563, 52.6217],
            [-9.3906, 52.6119],
            [-9.4292, 52.6106],
            [-9.4453, 52.6178],
            [-9.4828, 52.6184],
            [-9.5097, 52.6295],
            [-9.5257, 52.6295],
            [-9.5397, 52.64],
            [-9.5418, 52.6641],
            [-9.5579, 52.6693],
            [-9.574, 52.6621],
            [-9.5762, 52.6458],
            [-9.5633, 52.6341],
            [-9.588, 52.6282],
            [-9.5976, 52.6152],
            [-9.6362, 52.6178],
            [-9.6673, 52.6086],
            [-9.6963, 52.6073],
            [-9.7081, 52.5851],
            [-9.7421, 52.5866],
            [-9.7427, 52.5798],
            [-9.7704, 52.5719],
            [-9.808, 52.5649],
            [-9.8258, 52.5698],
            [-9.8607, 52.5699],
            [-9.8789, 52.5572],
            [-9.9121, 52.5585],
            [-9.9118, 52.5755],
            [-9.8946, 52.5813],
            [-9.8808, 52.5791],
            [-9.8759, 52.5907],
            [-9.8055, 52.6049],
            [-9.8048, 52.6147],
            [-9.7919, 52.619],
            [-9.7728, 52.634],
            [-9.7449, 52.6401],
            [-9.7247, 52.6517],
            [-9.7222, 52.6585],
            [-9.6962, 52.6648],
            [-9.6928, 52.6722],
            [-9.6637, 52.6837],
            [-9.65, 52.6926],
            [-9.6444, 52.7054],
            [-9.6147, 52.717],
            [-9.6252, 52.7328],
            [-9.6061, 52.7342],
            [-9.6039, 52.7449],
            [-9.5543, 52.7531],
            [-9.5406, 52.7468],
            [-9.504, 52.7472],
            [-9.4903, 52.7622],
            [-9.5, 52.7717],
            [-9.48, 52.7783],
            [-9.4897, 52.8108],
            [-9.4578, 52.818],
            [-9.4338, 52.8346],
            [-9.441, 52.8535],
            [-9.4255, 52.8694],
            [-9.4315, 52.8843],
            [-9.3944, 52.8963],
            [-9.3534, 52.9193],
            [-9.3479, 52.9326],
            [-9.3619, 52.9455],
            [-9.3737, 52.9451],
            [-9.4, 52.935],
            [-9.4241, 52.9346],
            [-9.4373, 52.9391],
            [-9.4658, 52.9326],
            [-9.4759, 52.9345],
            [-9.4684, 52.95],
            [-9.4538, 52.9546],
            [-9.4287, 52.9719],
            [-9.4289, 52.9875],
            [-9.3912, 53.0104],
            [-9.4074, 53.0201],
            [-9.3947, 53.0269],
            [-9.3822, 53.0433],
            [-9.3647, 53.0519],
            [-9.3574, 53.0737],
            [-9.3082, 53.0952],
            [-9.2864, 53.1208],
            [-9.2902, 53.1332],
            [-9.2805, 53.1458],
            [-9.2623, 53.156],
            [-9.2179, 53.1411],
            [-9.1816, 53.1364],
            [-9.1789, 53.1311],
            [-9.1514, 53.1208],
            [-9.1371, 53.1232],
            [-9.1042, 53.1448],
            [-9.1122, 53.1578],
            [-9.1005, 53.1602],
            [-9.0713, 53.1567],
            [-9.0534, 53.1598],
            [-9.0305, 53.1472],
            [-9.006, 53.1427],
          ],
        ],
      },
      properties: {name: 'Clare', id: 'IE-CE', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-CE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.947, 54.3077],
            [-7.8986, 54.3025],
            [-7.8807, 54.293],
            [-7.8678, 54.2954],
            [-7.873, 54.2739],
            [-7.86, 54.2616],
            [-7.8557, 54.2172],
            [-7.8129, 54.2026],
            [-7.8036, 54.2077],
            [-7.7487, 54.2119],
            [-7.7349, 54.2038],
            [-7.6851, 54.2078],
            [-7.6745, 54.1834],
            [-7.663, 54.1891],
            [-7.6264, 54.1719],
            [-7.6243, 54.1566],
            [-7.6002, 54.1431],
            [-7.5758, 54.1425],
            [-7.5647, 54.1289],
            [-7.547, 54.1223],
            [-7.5271, 54.1349],
            [-7.4976, 54.1264],
            [-7.4778, 54.1259],
            [-7.4658, 54.1436],
            [-7.4424, 54.1532],
            [-7.4108, 54.1576],
            [-7.4242, 54.1437],
            [-7.3717, 54.1386],
            [-7.3496, 54.1274],
            [-7.3452, 54.116],
            [-7.3202, 54.119],
            [-7.3161, 54.107],
            [-7.2871, 54.1072],
            [-7.2616, 54.0951],
            [-7.2405, 54.1029],
            [-7.2228, 54.1025],
            [-7.1781, 54.0881],
            [-7.153, 54.0838],
            [-7.1206, 54.097],
            [-7.0766, 54.0907],
            [-7.0484, 54.1021],
            [-7.0165, 54.0953],
            [-7.0284, 54.0879],
            [-7.0108, 54.0786],
            [-7.0008, 54.0654],
            [-6.9807, 54.0614],
            [-6.9669, 54.0455],
            [-6.9381, 54.0346],
            [-6.9227, 54.0154],
            [-6.9128, 54.0167],
            [-6.8632, 53.9874],
            [-6.8504, 53.965],
            [-6.8283, 53.9406],
            [-6.8179, 53.9338],
            [-6.7755, 53.929],
            [-6.7611, 53.919],
            [-6.7825, 53.9119],
            [-6.7933, 53.8867],
            [-6.8086, 53.8987],
            [-6.8217, 53.9002],
            [-6.8465, 53.8878],
            [-6.8435, 53.879],
            [-6.8615, 53.8753],
            [-6.8832, 53.8786],
            [-6.9204, 53.8926],
            [-6.9339, 53.8926],
            [-6.9245, 53.8759],
            [-6.9701, 53.8575],
            [-6.9503, 53.8365],
            [-6.917, 53.8177],
            [-6.9118, 53.8093],
            [-6.921, 53.801],
            [-6.9356, 53.8015],
            [-6.9445, 53.7812],
            [-6.9594, 53.7909],
            [-6.9972, 53.766],
            [-7.0404, 53.7768],
            [-7.064, 53.7738],
            [-7.1029, 53.7764],
            [-7.1048, 53.7882],
            [-7.1271, 53.7914],
            [-7.1476, 53.8032],
            [-7.156, 53.7961],
            [-7.1934, 53.7954],
            [-7.2119, 53.7863],
            [-7.2179, 53.7751],
            [-7.2502, 53.7767],
            [-7.2734, 53.7822],
            [-7.2842, 53.7991],
            [-7.3073, 53.813],
            [-7.3414, 53.8],
            [-7.3712, 53.7876],
            [-7.4073, 53.7769],
            [-7.4127, 53.7906],
            [-7.4336, 53.7948],
            [-7.4688, 53.8116],
            [-7.4651, 53.8198],
            [-7.4756, 53.8457],
            [-7.4956, 53.8612],
            [-7.5251, 53.8644],
            [-7.5432, 53.855],
            [-7.5818, 53.8585],
            [-7.571, 53.8811],
            [-7.5568, 53.8789],
            [-7.5512, 53.8937],
            [-7.5582, 53.905],
            [-7.5813, 53.9138],
            [-7.6109, 53.9379],
            [-7.6045, 53.9486],
            [-7.5861, 53.9614],
            [-7.5943, 53.9686],
            [-7.5814, 53.9772],
            [-7.5983, 53.9879],
            [-7.5912, 53.9981],
            [-7.5995, 54.0146],
            [-7.6404, 54.0266],
            [-7.6528, 54.0439],
            [-7.6563, 54.0577],
            [-7.681, 54.0633],
            [-7.706, 54.086],
            [-7.7331, 54.084],
            [-7.7376, 54.0955],
            [-7.7558, 54.1069],
            [-7.7866, 54.1096],
            [-7.7973, 54.1178],
            [-7.8286, 54.0992],
            [-7.8567, 54.1143],
            [-7.8494, 54.1202],
            [-7.8743, 54.1272],
            [-7.8872, 54.1436],
            [-7.9246, 54.1691],
            [-7.9495, 54.1789],
            [-7.988, 54.1816],
            [-8.0064, 54.193],
            [-8.0184, 54.1874],
            [-8.043, 54.1875],
            [-8.0463, 54.2007],
            [-8.0339, 54.2143],
            [-8.057, 54.2376],
            [-8.0406, 54.2554],
            [-8.0096, 54.2503],
            [-7.9832, 54.2649],
            [-7.9727, 54.2849],
            [-7.947, 54.3077],
          ],
        ],
      },
      properties: {name: 'Cavan', id: 'IE-CN', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-CN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-9.2168, 52.2979],
              [-9.2093, 52.3067],
              [-9.1737, 52.3128],
              [-9.1537, 52.3306],
              [-9.1391, 52.3344],
              [-9.1352, 52.3449],
              [-9.1074, 52.3337],
              [-9.0718, 52.3322],
              [-9.0429, 52.3225],
              [-9.0318, 52.3236],
              [-9.006, 52.3111],
              [-8.9905, 52.2954],
              [-8.9738, 52.295],
              [-8.9836, 52.313],
              [-8.9545, 52.3212],
              [-8.9123, 52.326],
              [-8.892, 52.3321],
              [-8.8753, 52.3548],
              [-8.8507, 52.358],
              [-8.8512, 52.3684],
              [-8.8272, 52.3721],
              [-8.8192, 52.3853],
              [-8.8022, 52.3877],
              [-8.792, 52.3734],
              [-8.7774, 52.3868],
              [-8.7336, 52.3723],
              [-8.6794, 52.3823],
              [-8.6728, 52.3663],
              [-8.6533, 52.3488],
              [-8.6144, 52.3402],
              [-8.6019, 52.3224],
              [-8.5841, 52.3086],
              [-8.5569, 52.3082],
              [-8.5408, 52.2914],
              [-8.5046, 52.2801],
              [-8.4883, 52.286],
              [-8.4542, 52.2885],
              [-8.4362, 52.2939],
              [-8.386, 52.2957],
              [-8.3812, 52.3081],
              [-8.3624, 52.3123],
              [-8.3606, 52.3211],
              [-8.338, 52.3296],
              [-8.3273, 52.3192],
              [-8.3112, 52.3181],
              [-8.2924, 52.2967],
              [-8.2793, 52.2894],
              [-8.2461, 52.2994],
              [-8.2066, 52.2969],
              [-8.1836, 52.2903],
              [-8.1766, 52.2774],
              [-8.1628, 52.2785],
              [-8.1581, 52.2439],
              [-8.151, 52.2402],
              [-8.1018, 52.2322],
              [-8.1032, 52.208],
              [-8.1165, 52.1986],
              [-8.1275, 52.1693],
              [-8.1523, 52.1661],
              [-8.1531, 52.1509],
              [-8.1369, 52.1441],
              [-8.1108, 52.1477],
              [-8.092, 52.137],
              [-8.0657, 52.1439],
              [-8.0612, 52.1387],
              [-8.0668, 52.1195],
              [-8.0444, 52.1164],
              [-8.0288, 52.106],
              [-8.0147, 52.076],
              [-8.0218, 52.0548],
              [-8.0072, 52.0518],
              [-7.9849, 52.0371],
              [-7.9623, 52.0276],
              [-7.9571, 52.0209],
              [-7.9285, 52.0105],
              [-7.9298, 52.0017],
              [-7.9165, 51.9764],
              [-7.8713, 51.9743],
              [-7.8517, 51.9793],
              [-7.838, 51.9542],
              [-7.839, 51.941],
              [-7.8625, 51.9307],
              [-7.8813, 51.9115],
              [-7.9028, 51.9077],
              [-7.8888, 51.8959],
              [-7.862, 51.8845],
              [-7.8696, 51.8779],
              [-7.8878, 51.8783],
              [-7.9731, 51.8667],
              [-7.9996, 51.8583],
              [-8.024, 51.84],
              [-8.0049, 51.8252],
              [-8.047, 51.8179],
              [-8.0605, 51.8105],
              [-8.0906, 51.8111],
              [-8.1286, 51.8068],
              [-8.1596, 51.7879],
              [-8.1768, 51.7962],
              [-8.2058, 51.7934],
              [-8.2179, 51.7958],
              [-8.2521, 51.7929],
              [-8.247, 51.8062],
              [-8.2609, 51.8178],
              [-8.2201, 51.8429],
              [-8.1909, 51.8453],
              [-8.1858, 51.8579],
              [-8.236, 51.8592],
              [-8.3032, 51.8486],
              [-8.3277, 51.8502],
              [-8.3384, 51.8416],
              [-8.3275, 51.8329],
              [-8.3013, 51.8322],
              [-8.2919, 51.8133],
              [-8.2718, 51.799],
              [-8.2918, 51.7804],
              [-8.2961, 51.7564],
              [-8.308, 51.7444],
              [-8.309, 51.7327],
              [-8.3279, 51.7348],
              [-8.3412, 51.7164],
              [-8.3818, 51.7154],
              [-8.3904, 51.7024],
              [-8.417, 51.7048],
              [-8.4212, 51.694],
              [-8.4374, 51.6865],
              [-8.4916, 51.6767],
              [-8.4901, 51.6912],
              [-8.5173, 51.6822],
              [-8.5292, 51.6632],
              [-8.5474, 51.645],
              [-8.5366, 51.6338],
              [-8.5546, 51.6279],
              [-8.5593, 51.6401],
              [-8.5769, 51.6459],
              [-8.6548, 51.6365],
              [-8.6666, 51.6538],
              [-8.6769, 51.6468],
              [-8.6953, 51.6499],
              [-8.7073, 51.6412],
              [-8.6843, 51.6293],
              [-8.6972, 51.6222],
              [-8.6864, 51.611],
              [-8.7029, 51.5919],
              [-8.695, 51.5841],
              [-8.7046, 51.5742],
              [-8.7444, 51.5766],
              [-8.7534, 51.5882],
              [-8.7778, 51.5901],
              [-8.8026, 51.597],
              [-8.8648, 51.5933],
              [-8.8666, 51.578],
              [-8.8894, 51.5721],
              [-8.8958, 51.5544],
              [-8.9145, 51.5423],
              [-8.936, 51.548],
              [-8.9961, 51.5694],
              [-9.0123, 51.5605],
              [-9.0285, 51.5609],
              [-9.0512, 51.5525],
              [-9.0823, 51.548],
              [-9.1058, 51.5553],
              [-9.1184, 51.5497],
              [-9.1085, 51.539],
              [-9.1354, 51.5316],
              [-9.1459, 51.5226],
              [-9.1738, 51.5169],
              [-9.1878, 51.52],
              [-9.2158, 51.4991],
              [-9.2099, 51.4881],
              [-9.2274, 51.4821],
              [-9.2646, 51.5027],
              [-9.2921, 51.4974],
              [-9.3194, 51.4803],
              [-9.3494, 51.4733],
              [-9.3792, 51.4698],
              [-9.3852, 51.5115],
              [-9.4124, 51.4972],
              [-9.4301, 51.5109],
              [-9.4056, 51.5231],
              [-9.3921, 51.5457],
              [-9.4128, 51.5406],
              [-9.4144, 51.5524],
              [-9.445, 51.5537],
              [-9.4381, 51.54],
              [-9.462, 51.5243],
              [-9.4896, 51.5245],
              [-9.5035, 51.519],
              [-9.528, 51.52],
              [-9.562, 51.506],
              [-9.5867, 51.5062],
              [-9.5843, 51.4984],
              [-9.6024, 51.4939],
              [-9.6284, 51.4997],
              [-9.6312, 51.5128],
              [-9.6556, 51.5169],
              [-9.6733, 51.5056],
              [-9.7035, 51.4931],
              [-9.708, 51.4791],
              [-9.7588, 51.4619],
              [-9.781, 51.4625],
              [-9.7957, 51.4514],
              [-9.8142, 51.4536],
              [-9.8165, 51.4641],
              [-9.8055, 51.4747],
              [-9.829, 51.4788],
              [-9.8106, 51.4926],
              [-9.774, 51.5058],
              [-9.7713, 51.5121],
              [-9.7472, 51.5235],
              [-9.7201, 51.5247],
              [-9.6872, 51.5314],
              [-9.6672, 51.5449],
              [-9.6436, 51.5531],
              [-9.6431, 51.5655],
              [-9.6134, 51.5739],
              [-9.5879, 51.5891],
              [-9.5738, 51.6013],
              [-9.583, 51.6119],
              [-9.6574, 51.585],
              [-9.6908, 51.5799],
              [-9.7036, 51.5713],
              [-9.7242, 51.5701],
              [-9.7732, 51.5516],
              [-9.7929, 51.5513],
              [-9.804, 51.5452],
              [-9.8432, 51.5409],
              [-9.831, 51.5518],
              [-9.7836, 51.5703],
              [-9.7828, 51.5805],
              [-9.7687, 51.5832],
              [-9.746, 51.5947],
              [-9.7173, 51.6007],
              [-9.6959, 51.614],
              [-9.651, 51.6286],
              [-9.633, 51.6299],
              [-9.5599, 51.6538],
              [-9.5089, 51.6639],
              [-9.4872, 51.6797],
              [-9.4638, 51.6808],
              [-9.45, 51.6957],
              [-9.4588, 51.7201],
              [-9.4813, 51.7152],
              [-9.5008, 51.7172],
              [-9.5104, 51.729],
              [-9.5293, 51.7305],
              [-9.5304, 51.75],
              [-9.5523, 51.7475],
              [-9.5585, 51.734],
              [-9.5428, 51.7241],
              [-9.5485, 51.7142],
              [-9.5716, 51.7072],
              [-9.5958, 51.6935],
              [-9.6482, 51.6747],
              [-9.7131, 51.6822],
              [-9.7241, 51.6752],
              [-9.7447, 51.6718],
              [-9.7633, 51.6629],
              [-9.819, 51.6605],
              [-9.8697, 51.6565],
              [-9.8789, 51.6502],
              [-9.899, 51.6522],
              [-9.9289, 51.6217],
              [-9.9524, 51.6159],
              [-9.9672, 51.6183],
              [-10.0196, 51.5952],
              [-10.0386, 51.5893],
              [-10.0537, 51.5944],
              [-10.0477, 51.6036],
              [-10.1279, 51.5953],
              [-10.1575, 51.6097],
              [-10.1418, 51.6207],
              [-10.131, 51.613],
              [-10.0657, 51.6233],
              [-10.0571, 51.6484],
              [-10.0731, 51.6511],
              [-10.085, 51.6598],
              [-10.1071, 51.6635],
              [-10.0896, 51.6745],
              [-10.0547, 51.6687],
              [-10.0316, 51.6781],
              [-10.0209, 51.6733],
              [-10.0013, 51.6778],
              [-9.9979, 51.6856],
              [-9.9712, 51.6866],
              [-9.9706, 51.6961],
              [-9.9512, 51.7152],
              [-10.0054, 51.7158],
              [-10.0006, 51.7223],
              [-9.9433, 51.7419],
              [-9.908, 51.7497],
              [-9.8843, 51.7633],
              [-9.876, 51.7471],
              [-9.8559, 51.7514],
              [-9.8342, 51.7334],
              [-9.8442, 51.7244],
              [-9.8245, 51.7186],
              [-9.8282, 51.701],
              [-9.8219, 51.6901],
              [-9.7907, 51.6948],
              [-9.7603, 51.7135],
              [-9.7607, 51.721],
              [-9.7379, 51.7301],
              [-9.7239, 51.7405],
              [-9.7031, 51.7462],
              [-9.6921, 51.7432],
              [-9.6673, 51.7492],
              [-9.6512, 51.7662],
              [-9.6204, 51.7875],
              [-9.5786, 51.7857],
              [-9.5656, 51.7915],
              [-9.5416, 51.7922],
              [-9.5232, 51.788],
              [-9.4818, 51.7904],
              [-9.4616, 51.7954],
              [-9.4475, 51.806],
              [-9.4357, 51.826],
              [-9.4047, 51.8266],
              [-9.3859, 51.8372],
              [-9.3603, 51.8359],
              [-9.3489, 51.8438],
              [-9.3296, 51.8467],
              [-9.3249, 51.8573],
              [-9.3092, 51.8633],
              [-9.3014, 51.8791],
              [-9.288, 51.8839],
              [-9.2785, 51.9004],
              [-9.2916, 51.9045],
              [-9.2856, 51.9147],
              [-9.3017, 51.9252],
              [-9.3064, 51.9422],
              [-9.2766, 51.9569],
              [-9.262, 51.9602],
              [-9.2545, 51.9706],
              [-9.2113, 51.9766],
              [-9.1927, 51.9843],
              [-9.1556, 51.9876],
              [-9.1363, 51.9838],
              [-9.1176, 52.0003],
              [-9.1344, 52.0053],
              [-9.1618, 52.0055],
              [-9.1795, 52.028],
              [-9.2072, 52.0244],
              [-9.2189, 52.0512],
              [-9.2061, 52.0718],
              [-9.1895, 52.0753],
              [-9.19, 52.0855],
              [-9.2277, 52.122],
              [-9.2244, 52.1286],
              [-9.2365, 52.1483],
              [-9.2305, 52.1768],
              [-9.2442, 52.1843],
              [-9.251, 52.2109],
              [-9.2738, 52.2304],
              [-9.2712, 52.2561],
              [-9.2453, 52.2663],
              [-9.2468, 52.279],
              [-9.2218, 52.2832],
              [-9.2097, 52.29],
              [-9.2168, 52.2979],
            ],
          ],
          [
            [
              [-10.1624, 51.6094],
              [-10.1602, 51.6035],
              [-10.2, 51.59],
              [-10.2361, 51.5837],
              [-10.2028, 51.6042],
              [-10.1841, 51.6096],
              [-10.1624, 51.6094],
            ],
          ],
          [
            [
              [-9.4805, 51.6994],
              [-9.4962, 51.6853],
              [-9.5258, 51.6835],
              [-9.4985, 51.6989],
              [-9.4805, 51.6994],
            ],
          ],
          [
            [
              [-9.4071, 51.4913],
              [-9.3944, 51.4725],
              [-9.4209, 51.4611],
              [-9.4208, 51.4799],
              [-9.4071, 51.4913],
            ],
          ],
          [
            [
              [-9.7916, 51.6403],
              [-9.8198, 51.6296],
              [-9.877, 51.6171],
              [-9.9111, 51.6154],
              [-9.9179, 51.619],
              [-9.9076, 51.6354],
              [-9.8349, 51.6451],
              [-9.8197, 51.6383],
              [-9.7916, 51.6403],
            ],
          ],
          [
            [
              [-9.4635, 51.4537],
              [-9.4613, 51.4483],
              [-9.4779, 51.4353],
              [-9.5126, 51.4204],
              [-9.519, 51.4298],
              [-9.482, 51.4519],
              [-9.4635, 51.4537],
            ],
          ],
        ],
      },
      properties: {name: 'Cork', id: 'IE-CO', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-CO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.7362, 52.916],
            [-6.7104, 52.9185],
            [-6.6843, 52.9047],
            [-6.6697, 52.9101],
            [-6.5868, 52.8967],
            [-6.5842, 52.8807],
            [-6.5654, 52.8828],
            [-6.5475, 52.877],
            [-6.5324, 52.8911],
            [-6.5062, 52.8808],
            [-6.5069, 52.8691],
            [-6.5261, 52.8666],
            [-6.5218, 52.8448],
            [-6.5339, 52.8228],
            [-6.5628, 52.8163],
            [-6.5694, 52.804],
            [-6.6018, 52.811],
            [-6.6136, 52.8091],
            [-6.6236, 52.8227],
            [-6.6599, 52.8362],
            [-6.6651, 52.8207],
            [-6.6976, 52.8062],
            [-6.6945, 52.7754],
            [-6.6768, 52.7821],
            [-6.6531, 52.769],
            [-6.6429, 52.7548],
            [-6.6515, 52.7472],
            [-6.6286, 52.731],
            [-6.6122, 52.7131],
            [-6.6297, 52.7049],
            [-6.6437, 52.6889],
            [-6.6707, 52.6803],
            [-6.6724, 52.6651],
            [-6.6514, 52.6629],
            [-6.6615, 52.6488],
            [-6.6831, 52.6366],
            [-6.7268, 52.6435],
            [-6.7489, 52.6394],
            [-6.7742, 52.6235],
            [-6.7809, 52.5986],
            [-6.7785, 52.5806],
            [-6.7878, 52.5662],
            [-6.8034, 52.5649],
            [-6.8043, 52.5521],
            [-6.8356, 52.5317],
            [-6.8318, 52.5194],
            [-6.8542, 52.4966],
            [-6.866, 52.4767],
            [-6.8935, 52.4649],
            [-6.9155, 52.465],
            [-6.939, 52.5007],
            [-6.9319, 52.5173],
            [-6.9339, 52.5279],
            [-6.9493, 52.5313],
            [-6.9448, 52.5426],
            [-6.9147, 52.5619],
            [-6.9171, 52.5791],
            [-6.9483, 52.6017],
            [-6.9691, 52.6057],
            [-6.9874, 52.6307],
            [-6.9813, 52.6442],
            [-6.9854, 52.6587],
            [-6.9763, 52.6696],
            [-6.9939, 52.676],
            [-7.0039, 52.6956],
            [-7.0427, 52.715],
            [-7.05, 52.7056],
            [-7.0667, 52.7107],
            [-7.1007, 52.7476],
            [-7.084, 52.758],
            [-7.0874, 52.7765],
            [-7.0648, 52.7851],
            [-7.0599, 52.7924],
            [-7.0431, 52.7957],
            [-7.0357, 52.8088],
            [-6.9708, 52.8104],
            [-6.95, 52.8146],
            [-6.9365, 52.8266],
            [-6.944, 52.8398],
            [-6.9284, 52.8463],
            [-6.9365, 52.8781],
            [-6.9297, 52.8866],
            [-6.9035, 52.8693],
            [-6.8796, 52.8595],
            [-6.8325, 52.8648],
            [-6.813, 52.874],
            [-6.7886, 52.8671],
            [-6.7634, 52.891],
            [-6.745, 52.8986],
            [-6.747, 52.913],
            [-6.7362, 52.916],
          ],
        ],
      },
      properties: {name: 'Carlow', id: 'IE-CW', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-CW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-6.0007, 53.4952],
              [-6.0086, 53.4843],
              [-6.0326, 53.491],
              [-6.0094, 53.4999],
              [-6.0007, 53.4952],
            ],
          ],
          [
            [
              [-6.1011, 53.2128],
              [-6.1353, 53.2002],
              [-6.1572, 53.2028],
              [-6.1786, 53.2206],
              [-6.2069, 53.2178],
              [-6.2405, 53.2033],
              [-6.2756, 53.2126],
              [-6.2895, 53.2228],
              [-6.3005, 53.2256],
              [-6.3107, 53.1956],
              [-6.3333, 53.1793],
              [-6.3722, 53.1934],
              [-6.3673, 53.2146],
              [-6.3939, 53.2142],
              [-6.4106, 53.2184],
              [-6.4188, 53.2316],
              [-6.4732, 53.2353],
              [-6.47, 53.2383],
              [-6.5328, 53.2547],
              [-6.5148, 53.2642],
              [-6.545, 53.2804],
              [-6.527, 53.2903],
              [-6.5257, 53.3026],
              [-6.5372, 53.3086],
              [-6.5166, 53.3246],
              [-6.4979, 53.3221],
              [-6.4894, 53.3385],
              [-6.4952, 53.3461],
              [-6.4866, 53.3626],
              [-6.4755, 53.3642],
              [-6.466, 53.3831],
              [-6.4547, 53.3899],
              [-6.4395, 53.4052],
              [-6.4191, 53.418],
              [-6.4108, 53.4412],
              [-6.3872, 53.4393],
              [-6.3521, 53.4479],
              [-6.3543, 53.4605],
              [-6.3365, 53.4797],
              [-6.3439, 53.5006],
              [-6.3662, 53.5081],
              [-6.3651, 53.5163],
              [-6.3896, 53.5256],
              [-6.4072, 53.5273],
              [-6.4339, 53.5487],
              [-6.4218, 53.5684],
              [-6.4032, 53.5731],
              [-6.3882, 53.5927],
              [-6.3679, 53.5904],
              [-6.3334, 53.5738],
              [-6.3177, 53.5773],
              [-6.3076, 53.5867],
              [-6.2869, 53.5903],
              [-6.2712, 53.599],
              [-6.2715, 53.6159],
              [-6.2576, 53.6331],
              [-6.2392, 53.6362],
              [-6.2294, 53.6299],
              [-6.213, 53.6347],
              [-6.188, 53.6316],
              [-6.1872, 53.6202],
              [-6.1642, 53.6082],
              [-6.1465, 53.5886],
              [-6.1028, 53.5819],
              [-6.0965, 53.5687],
              [-6.0832, 53.5621],
              [-6.0777, 53.5413],
              [-6.0866, 53.5319],
              [-6.0745, 53.5213],
              [-6.0952, 53.5187],
              [-6.1083, 53.5119],
              [-6.1248, 53.5149],
              [-6.1641, 53.506],
              [-6.1203, 53.5003],
              [-6.0964, 53.4941],
              [-6.1026, 53.4869],
              [-6.1315, 53.4699],
              [-6.1429, 53.4758],
              [-6.1908, 53.4661],
              [-6.1722, 53.4567],
              [-6.1384, 53.4521],
              [-6.1228, 53.4457],
              [-6.1218, 53.4238],
              [-6.1154, 53.3948],
              [-6.0737, 53.3918],
              [-6.0587, 53.3943],
              [-6.0427, 53.383],
              [-6.0523, 53.3685],
              [-6.0775, 53.3626],
              [-6.0967, 53.3719],
              [-6.1084, 53.3883],
              [-6.128, 53.3885],
              [-6.1551, 53.3797],
              [-6.1772, 53.3625],
              [-6.1976, 53.3543],
              [-6.1749, 53.3432],
              [-6.1909, 53.3356],
              [-6.2104, 53.3355],
              [-6.2023, 53.317],
              [-6.1938, 53.3115],
              [-6.1522, 53.2968],
              [-6.1403, 53.2994],
              [-6.1155, 53.2883],
              [-6.1026, 53.2868],
              [-6.0895, 53.2718],
              [-6.1094, 53.2605],
              [-6.1101, 53.2421],
              [-6.1011, 53.2128],
            ],
          ],
        ],
      },
      properties: {name: 'Dublin', id: 'IE-D', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-D',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-8.5304, 55.0132],
              [-8.4945, 55.0031],
              [-8.499, 54.995],
              [-8.4876, 54.9845],
              [-8.4872, 54.9744],
              [-8.5435, 54.9738],
              [-8.5583, 54.9843],
              [-8.5624, 54.9957],
              [-8.5304, 55.0132],
            ],
          ],
          [
            [
              [-7.2569, 55.0676],
              [-7.2707, 55.0646],
              [-7.2885, 55.0508],
              [-7.2976, 55.0571],
              [-7.3172, 55.0467],
              [-7.346, 55.0517],
              [-7.3944, 55.0188],
              [-7.401, 55.0065],
              [-7.3902, 54.9977],
              [-7.4061, 54.983],
              [-7.3984, 54.9732],
              [-7.4038, 54.9566],
              [-7.3937, 54.9461],
              [-7.4332, 54.9424],
              [-7.4452, 54.9362],
              [-7.4444, 54.9123],
              [-7.4507, 54.8963],
              [-7.4414, 54.8824],
              [-7.4421, 54.8708],
              [-7.479, 54.825],
              [-7.524, 54.8085],
              [-7.5436, 54.793],
              [-7.5411, 54.7842],
              [-7.5467, 54.7603],
              [-7.5348, 54.7521],
              [-7.5524, 54.7464],
              [-7.6152, 54.7444],
              [-7.6378, 54.7514],
              [-7.6967, 54.7261],
              [-7.7102, 54.727],
              [-7.7344, 54.7174],
              [-7.745, 54.708],
              [-7.7718, 54.7117],
              [-7.7757, 54.7178],
              [-7.8, 54.7214],
              [-7.8161, 54.7365],
              [-7.8309, 54.7385],
              [-7.8563, 54.7249],
              [-7.8776, 54.7047],
              [-7.9072, 54.7018],
              [-7.9171, 54.6968],
              [-7.898, 54.6864],
              [-7.9112, 54.6775],
              [-7.9016, 54.6618],
              [-7.857, 54.651],
              [-7.8583, 54.6397],
              [-7.8451, 54.6313],
              [-7.8273, 54.6335],
              [-7.8105, 54.645],
              [-7.77, 54.6225],
              [-7.738, 54.6197],
              [-7.7051, 54.6339],
              [-7.7067, 54.6072],
              [-7.7494, 54.5992],
              [-7.7634, 54.5869],
              [-7.7946, 54.5812],
              [-7.7955, 54.5733],
              [-7.8221, 54.5624],
              [-7.83, 54.5507],
              [-7.8224, 54.5453],
              [-7.8446, 54.5351],
              [-7.9066, 54.5382],
              [-7.9499, 54.535],
              [-7.9667, 54.5494],
              [-7.9844, 54.5446],
              [-8.0038, 54.5465],
              [-8.0396, 54.5081],
              [-8.0422, 54.4878],
              [-8.0939, 54.4853],
              [-8.1121, 54.4706],
              [-8.1488, 54.4709],
              [-8.1737, 54.4656],
              [-8.2178, 54.4742],
              [-8.2325, 54.4625],
              [-8.2852, 54.4783],
              [-8.2822, 54.4887],
              [-8.2645, 54.4924],
              [-8.2551, 54.5133],
              [-8.268, 54.5238],
              [-8.2227, 54.5471],
              [-8.2113, 54.5497],
              [-8.222, 54.5697],
              [-8.2052, 54.5743],
              [-8.1916, 54.5858],
              [-8.1771, 54.5879],
              [-8.1656, 54.5974],
              [-8.1688, 54.6211],
              [-8.1363, 54.6048],
              [-8.1335, 54.6195],
              [-8.1231, 54.6308],
              [-8.1231, 54.6453],
              [-8.1518, 54.6365],
              [-8.1864, 54.642],
              [-8.2073, 54.6309],
              [-8.2297, 54.6251],
              [-8.2731, 54.6076],
              [-8.3002, 54.6099],
              [-8.2818, 54.6315],
              [-8.2718, 54.6372],
              [-8.2834, 54.648],
              [-8.3044, 54.6397],
              [-8.3418, 54.6316],
              [-8.3514, 54.6167],
              [-8.3754, 54.6061],
              [-8.3781, 54.6252],
              [-8.3952, 54.6373],
              [-8.4152, 54.627],
              [-8.4423, 54.624],
              [-8.4629, 54.6072],
              [-8.4826, 54.6062],
              [-8.4854, 54.619],
              [-8.4774, 54.6379],
              [-8.5429, 54.6189],
              [-8.5656, 54.6149],
              [-8.619, 54.6197],
              [-8.6293, 54.6238],
              [-8.6612, 54.6211],
              [-8.6786, 54.624],
              [-8.6908, 54.6435],
              [-8.7239, 54.6479],
              [-8.7386, 54.6535],
              [-8.7717, 54.6581],
              [-8.7835, 54.676],
              [-8.7852, 54.6874],
              [-8.8027, 54.696],
              [-8.7971, 54.7037],
              [-8.7783, 54.7033],
              [-8.7475, 54.7143],
              [-8.7478, 54.7299],
              [-8.7343, 54.7395],
              [-8.7023, 54.7545],
              [-8.6714, 54.7618],
              [-8.6656, 54.7718],
              [-8.6365, 54.7725],
              [-8.5928, 54.78],
              [-8.5489, 54.7723],
              [-8.5335, 54.7666],
              [-8.4745, 54.7606],
              [-8.468, 54.7681],
              [-8.5036, 54.7768],
              [-8.5151, 54.7739],
              [-8.545, 54.7846],
              [-8.5307, 54.7921],
              [-8.5137, 54.7861],
              [-8.4911, 54.8004],
              [-8.4966, 54.8101],
              [-8.5289, 54.8178],
              [-8.5634, 54.8298],
              [-8.5341, 54.8366],
              [-8.5089, 54.8326],
              [-8.501, 54.8459],
              [-8.486, 54.8501],
              [-8.4463, 54.8415],
              [-8.4227, 54.8505],
              [-8.3829, 54.8427],
              [-8.3805, 54.8811],
              [-8.3676, 54.8779],
              [-8.3511, 54.8851],
              [-8.3204, 54.8759],
              [-8.3206, 54.8852],
              [-8.3411, 54.8935],
              [-8.3493, 54.9064],
              [-8.3765, 54.9006],
              [-8.3879, 54.8913],
              [-8.409, 54.9003],
              [-8.4414, 54.9064],
              [-8.4569, 54.9204],
              [-8.4426, 54.9324],
              [-8.4526, 54.9464],
              [-8.4062, 54.9377],
              [-8.3848, 54.9458],
              [-8.3783, 54.9538],
              [-8.4121, 54.9607],
              [-8.4483, 54.9981],
              [-8.4496, 55.007],
              [-8.4287, 55.0082],
              [-8.4061, 55.002],
              [-8.4078, 55.0186],
              [-8.3687, 55.0418],
              [-8.3495, 55.0405],
              [-8.3423, 55.0491],
              [-8.348, 55.0641],
              [-8.329, 55.0674],
              [-8.3163, 55.0919],
              [-8.3177, 55.1059],
              [-8.3097, 55.1206],
              [-8.296, 55.1263],
              [-8.2852, 55.1594],
              [-8.2736, 55.155],
              [-8.2003, 55.1473],
              [-8.1669, 55.1457],
              [-8.1528, 55.1512],
              [-8.1484, 55.1636],
              [-8.1303, 55.1617],
              [-8.1443, 55.1487],
              [-8.1608, 55.1429],
              [-8.1483, 55.1313],
              [-8.1144, 55.1604],
              [-8.0692, 55.1636],
              [-8.0408, 55.1762],
              [-8.0236, 55.1751],
              [-8.0246, 55.1901],
              [-8.0169, 55.2152],
              [-7.9814, 55.2265],
              [-7.9651, 55.2131],
              [-7.9415, 55.2141],
              [-7.9418, 55.198],
              [-7.9577, 55.1914],
              [-7.9434, 55.1817],
              [-7.9138, 55.1863],
              [-7.8852, 55.1709],
              [-7.8538, 55.1641],
              [-7.8646, 55.1506],
              [-7.8907, 55.1472],
              [-7.8633, 55.1363],
              [-7.8372, 55.1508],
              [-7.8233, 55.1794],
              [-7.8364, 55.1951],
              [-7.8601, 55.1948],
              [-7.8715, 55.2022],
              [-7.8684, 55.2213],
              [-7.8422, 55.2321],
              [-7.8165, 55.2265],
              [-7.819, 55.2395],
              [-7.8076, 55.2466],
              [-7.79, 55.2414],
              [-7.788, 55.2332],
              [-7.8072, 55.2168],
              [-7.8111, 55.1982],
              [-7.7943, 55.1851],
              [-7.7673, 55.1839],
              [-7.7245, 55.1682],
              [-7.7098, 55.1673],
              [-7.6938, 55.1525],
              [-7.6941, 55.1391],
              [-7.7044, 55.1094],
              [-7.6908, 55.1127],
              [-7.6687, 55.1424],
              [-7.6647, 55.1598],
              [-7.7086, 55.1918],
              [-7.696, 55.2037],
              [-7.7074, 55.2307],
              [-7.7213, 55.2192],
              [-7.7107, 55.2068],
              [-7.7202, 55.1996],
              [-7.7172, 55.1773],
              [-7.7432, 55.1873],
              [-7.7331, 55.1971],
              [-7.7714, 55.193],
              [-7.779, 55.2045],
              [-7.7944, 55.2072],
              [-7.7936, 55.2214],
              [-7.7797, 55.2234],
              [-7.7573, 55.2353],
              [-7.7583, 55.2422],
              [-7.7296, 55.2558],
              [-7.7261, 55.2479],
              [-7.7001, 55.2487],
              [-7.6556, 55.2772],
              [-7.6276, 55.2696],
              [-7.6168, 55.2556],
              [-7.6132, 55.2397],
              [-7.6034, 55.2336],
              [-7.6083, 55.2181],
              [-7.6252, 55.2044],
              [-7.6123, 55.1922],
              [-7.5922, 55.1893],
              [-7.5717, 55.1807],
              [-7.5726, 55.1674],
              [-7.5515, 55.1565],
              [-7.5404, 55.1433],
              [-7.5198, 55.1324],
              [-7.5179, 55.1236],
              [-7.5312, 55.1117],
              [-7.5342, 55.0946],
              [-7.5832, 55.0822],
              [-7.5979, 55.0699],
              [-7.596, 55.0625],
              [-7.6236, 55.0584],
              [-7.6324, 55.0488],
              [-7.6079, 55.0446],
              [-7.5669, 55.054],
              [-7.5594, 55.0348],
              [-7.5952, 55.0131],
              [-7.6384, 54.9972],
              [-7.6643, 54.9585],
              [-7.622, 54.9648],
              [-7.5945, 54.9952],
              [-7.5548, 55.0134],
              [-7.5377, 55.0252],
              [-7.5148, 55.0256],
              [-7.5109, 55.0419],
              [-7.4532, 55.0458],
              [-7.4441, 55.0524],
              [-7.4563, 55.0785],
              [-7.4764, 55.0893],
              [-7.4768, 55.1036],
              [-7.4608, 55.1157],
              [-7.4598, 55.1399],
              [-7.4717, 55.1409],
              [-7.4847, 55.1586],
              [-7.5013, 55.1637],
              [-7.5137, 55.1835],
              [-7.5513, 55.1958],
              [-7.5448, 55.2177],
              [-7.5362, 55.2296],
              [-7.5183, 55.2394],
              [-7.5332, 55.2487],
              [-7.5131, 55.2567],
              [-7.5082, 55.2712],
              [-7.5248, 55.2815],
              [-7.5024, 55.2909],
              [-7.479, 55.283],
              [-7.4628, 55.2857],
              [-7.4342, 55.2804],
              [-7.4165, 55.2926],
              [-7.3815, 55.2928],
              [-7.3616, 55.3184],
              [-7.3344, 55.3132],
              [-7.308, 55.2989],
              [-7.3084, 55.2846],
              [-7.286, 55.2755],
              [-7.2531, 55.283],
              [-7.318, 55.3118],
              [-7.3297, 55.3127],
              [-7.334, 55.3264],
              [-7.3523, 55.3338],
              [-7.3481, 55.3603],
              [-7.3719, 55.3616],
              [-7.3906, 55.3765],
              [-7.3707, 55.384],
              [-7.3389, 55.3752],
              [-7.3333, 55.3683],
              [-7.2779, 55.3669],
              [-7.2709, 55.3611],
              [-7.2437, 55.3601],
              [-7.2312, 55.3524],
              [-7.1937, 55.3474],
              [-7.1794, 55.3417],
              [-7.1692, 55.3293],
              [-7.1755, 55.3207],
              [-7.1565, 55.3109],
              [-7.1553, 55.2969],
              [-7.0936, 55.2861],
              [-7.0695, 55.2734],
              [-7.0448, 55.276],
              [-7.0356, 55.2695],
              [-7.0097, 55.2661],
              [-6.9942, 55.2552],
              [-6.9768, 55.258],
              [-6.9518, 55.2538],
              [-6.9214, 55.2374],
              [-6.9283, 55.2258],
              [-6.9528, 55.2108],
              [-7.0026, 55.1974],
              [-7.0209, 55.19],
              [-7.0474, 55.1866],
              [-7.0558, 55.1813],
              [-7.1023, 55.1672],
              [-7.1185, 55.1593],
              [-7.1584, 55.1486],
              [-7.204, 55.1171],
              [-7.2105, 55.1046],
              [-7.2406, 55.0841],
              [-7.2569, 55.0676],
            ],
          ],
          [
            [
              [-7.5136, 55.0847],
              [-7.4915, 55.0852],
              [-7.4539, 55.056],
              [-7.4951, 55.0487],
              [-7.5056, 55.0567],
              [-7.524, 55.0594],
              [-7.5327, 55.0731],
              [-7.5136, 55.0847],
            ],
          ],
        ],
      },
      properties: {name: 'Donegal', id: 'IE-DL', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-DL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-8.8145, 53.6698],
              [-8.7949, 53.6595],
              [-8.7748, 53.6667],
              [-8.7389, 53.6648],
              [-8.6598, 53.6777],
              [-8.6393, 53.6879],
              [-8.6324, 53.706],
              [-8.5813, 53.7108],
              [-8.571, 53.7194],
              [-8.53, 53.7193],
              [-8.5167, 53.7138],
              [-8.492, 53.7175],
              [-8.4666, 53.7164],
              [-8.4532, 53.7066],
              [-8.4541, 53.6971],
              [-8.4325, 53.6778],
              [-8.401, 53.6687],
              [-8.3628, 53.6543],
              [-8.367, 53.642],
              [-8.39, 53.6416],
              [-8.3973, 53.6317],
              [-8.3778, 53.6186],
              [-8.3756, 53.6025],
              [-8.3523, 53.6009],
              [-8.3381, 53.5892],
              [-8.3384, 53.5799],
              [-8.3066, 53.5802],
              [-8.2966, 53.5682],
              [-8.2889, 53.5204],
              [-8.2919, 53.5113],
              [-8.274, 53.4972],
              [-8.2707, 53.4822],
              [-8.2858, 53.4741],
              [-8.2624, 53.4643],
              [-8.2609, 53.4581],
              [-8.2376, 53.4477],
              [-8.2605, 53.4375],
              [-8.2563, 53.4148],
              [-8.2392, 53.414],
              [-8.2249, 53.3974],
              [-8.2421, 53.3685],
              [-8.2292, 53.3512],
              [-8.2115, 53.3462],
              [-8.198, 53.3493],
              [-8.1868, 53.3366],
              [-8.1926, 53.3095],
              [-8.1761, 53.2901],
              [-8.0892, 53.2753],
              [-8.053, 53.2747],
              [-8.0276, 53.2652],
              [-8.0255, 53.2548],
              [-8.0057, 53.253],
              [-7.9729, 53.2368],
              [-7.968, 53.2161],
              [-7.9892, 53.1955],
              [-8.0358, 53.1967],
              [-8.0584, 53.19],
              [-8.0816, 53.172],
              [-8.0809, 53.1673],
              [-8.0848, 53.164],
              [-8.1272, 53.1592],
              [-8.1446, 53.1484],
              [-8.1691, 53.1229],
              [-8.1676, 53.1156],
              [-8.1992, 53.0841],
              [-8.2195, 53.0682],
              [-8.2615, 53.0582],
              [-8.2749, 53.0205],
              [-8.297, 53.0099],
              [-8.3131, 52.9855],
              [-8.3376, 52.9874],
              [-8.3544, 52.9734],
              [-8.3779, 52.9835],
              [-8.3978, 52.9861],
              [-8.4122, 52.9957],
              [-8.4282, 52.9804],
              [-8.4469, 52.9732],
              [-8.4588, 52.983],
              [-8.4797, 52.9892],
              [-8.4994, 52.9841],
              [-8.5082, 52.9998],
              [-8.5066, 53.0092],
              [-8.5237, 53.015],
              [-8.5272, 53.0251],
              [-8.5489, 53.0242],
              [-8.556, 53.0436],
              [-8.6055, 53.0408],
              [-8.6293, 53.043],
              [-8.6653, 53.0412],
              [-8.6964, 53.0278],
              [-8.7172, 53.0269],
              [-8.7352, 53.0068],
              [-8.7757, 52.9899],
              [-8.783, 52.9744],
              [-8.8019, 52.9804],
              [-8.8254, 52.9805],
              [-8.8324, 52.9891],
              [-8.8599, 52.9801],
              [-8.8809, 52.9778],
              [-8.8968, 53.0113],
              [-8.8826, 53.0184],
              [-8.8982, 53.0389],
              [-8.8926, 53.0648],
              [-8.9202, 53.0685],
              [-8.9251, 53.0749],
              [-8.9522, 53.0884],
              [-9.0099, 53.1044],
              [-9.029, 53.1314],
              [-9.024, 53.1396],
              [-9.006, 53.1427],
              [-9.0041, 53.1512],
              [-9.0465, 53.1672],
              [-9.0217, 53.1721],
              [-8.9701, 53.1699],
              [-8.9646, 53.1603],
              [-8.9331, 53.1421],
              [-8.9329, 53.1552],
              [-8.9467, 53.1847],
              [-8.9269, 53.1988],
              [-8.9239, 53.2143],
              [-8.9391, 53.2143],
              [-8.9748, 53.2074],
              [-8.9966, 53.2142],
              [-8.9739, 53.2417],
              [-8.9733, 53.2576],
              [-8.9473, 53.2578],
              [-8.9291, 53.2714],
              [-8.9428, 53.2742],
              [-8.9909, 53.2634],
              [-8.9915, 53.2718],
              [-9.0151, 53.2681],
              [-9.0355, 53.271],
              [-9.0528, 53.2646],
              [-9.1617, 53.2476],
              [-9.2234, 53.2509],
              [-9.2442, 53.2466],
              [-9.3075, 53.2446],
              [-9.3372, 53.2401],
              [-9.3613, 53.2436],
              [-9.4003, 53.235],
              [-9.4505, 53.2355],
              [-9.4653, 53.2275],
              [-9.5216, 53.223],
              [-9.5484, 53.2396],
              [-9.5518, 53.2543],
              [-9.5658, 53.2648],
              [-9.5544, 53.2695],
              [-9.5549, 53.2856],
              [-9.5821, 53.2726],
              [-9.5727, 53.2625],
              [-9.5769, 53.248],
              [-9.6052, 53.2398],
              [-9.6247, 53.24],
              [-9.6299, 53.2522],
              [-9.6201, 53.2665],
              [-9.5965, 53.2812],
              [-9.5985, 53.2967],
              [-9.6239, 53.3196],
              [-9.6203, 53.3273],
              [-9.5816, 53.3299],
              [-9.5472, 53.3435],
              [-9.5948, 53.361],
              [-9.5922, 53.3743],
              [-9.556, 53.3778],
              [-9.5589, 53.4019],
              [-9.5875, 53.3825],
              [-9.6002, 53.378],
              [-9.5979, 53.3683],
              [-9.6049, 53.3499],
              [-9.5951, 53.3377],
              [-9.6248, 53.3319],
              [-9.6414, 53.3324],
              [-9.651, 53.345],
              [-9.628, 53.3773],
              [-9.6312, 53.3889],
              [-9.656, 53.3816],
              [-9.6638, 53.3716],
              [-9.6939, 53.3629],
              [-9.7108, 53.3371],
              [-9.7301, 53.3201],
              [-9.7323, 53.3098],
              [-9.7568, 53.3079],
              [-9.78, 53.2969],
              [-9.7909, 53.3078],
              [-9.8315, 53.3131],
              [-9.847, 53.3115],
              [-9.8363, 53.2926],
              [-9.8502, 53.2929],
              [-9.8626, 53.3018],
              [-9.8613, 53.3123],
              [-9.8787, 53.3137],
              [-9.9042, 53.3256],
              [-9.8972, 53.341],
              [-9.8812, 53.3461],
              [-9.8941, 53.3554],
              [-9.8843, 53.3715],
              [-9.8648, 53.3703],
              [-9.8176, 53.3786],
              [-9.8066, 53.3881],
              [-9.7844, 53.3942],
              [-9.8204, 53.4087],
              [-9.8621, 53.4027],
              [-9.8802, 53.396],
              [-9.8845, 53.4151],
              [-9.8986, 53.4265],
              [-9.9146, 53.4169],
              [-9.9173, 53.3899],
              [-9.941, 53.3804],
              [-9.9686, 53.3861],
              [-9.9861, 53.3824],
              [-10.0374, 53.3925],
              [-10.0377, 53.4104],
              [-10.0625, 53.4166],
              [-10.0988, 53.4167],
              [-10.1274, 53.4085],
              [-10.1625, 53.4318],
              [-10.145, 53.4413],
              [-10.1339, 53.4533],
              [-10.0982, 53.4517],
              [-10.0908, 53.4404],
              [-10.065, 53.4441],
              [-10.0629, 53.4532],
              [-10.07, 53.4721],
              [-10.1006, 53.4828],
              [-10.0809, 53.4908],
              [-10.0903, 53.4987],
              [-10.1228, 53.5029],
              [-10.1367, 53.5225],
              [-10.1089, 53.5212],
              [-10.084, 53.5087],
              [-10.0731, 53.5165],
              [-10.0981, 53.5211],
              [-10.1345, 53.5243],
              [-10.1462, 53.544],
              [-10.189, 53.5454],
              [-10.1917, 53.5566],
              [-10.1512, 53.5673],
              [-10.1345, 53.5594],
              [-10.1244, 53.5626],
              [-10.0904, 53.554],
              [-10.128, 53.5776],
              [-10.0821, 53.5765],
              [-10.0612, 53.5712],
              [-10.046, 53.5731],
              [-10.0144, 53.5681],
              [-10.0203, 53.5597],
              [-10.0068, 53.5507],
              [-9.9753, 53.5577],
              [-10.0025, 53.5749],
              [-10.0228, 53.5797],
              [-10.0443, 53.5954],
              [-10.0485, 53.6117],
              [-10.0201, 53.6079],
              [-9.9965, 53.6123],
              [-9.9657, 53.6021],
              [-9.933, 53.5995],
              [-9.9091, 53.6029],
              [-9.9002, 53.6112],
              [-9.8731, 53.618],
              [-9.8705, 53.6289],
              [-9.7768, 53.5992],
              [-9.7328, 53.6027],
              [-9.6989, 53.6014],
              [-9.666, 53.614],
              [-9.6473, 53.6021],
              [-9.6082, 53.6069],
              [-9.5767, 53.6002],
              [-9.5618, 53.6128],
              [-9.5422, 53.5837],
              [-9.512, 53.5802],
              [-9.4776, 53.5635],
              [-9.44, 53.5662],
              [-9.4171, 53.565],
              [-9.4045, 53.5693],
              [-9.3583, 53.5736],
              [-9.3442, 53.5645],
              [-9.3508, 53.5537],
              [-9.3426, 53.5424],
              [-9.3241, 53.5411],
              [-9.3179, 53.5502],
              [-9.2886, 53.5469],
              [-9.2747, 53.5162],
              [-9.2327, 53.4997],
              [-9.2331, 53.4822],
              [-9.2032, 53.4755],
              [-9.1817, 53.4749],
              [-9.1567, 53.4857],
              [-9.1335, 53.4804],
              [-9.1146, 53.4958],
              [-9.0984, 53.4948],
              [-9.0912, 53.5163],
              [-9.0358, 53.5363],
              [-9.0253, 53.5506],
              [-9.039, 53.5674],
              [-9.0426, 53.5798],
              [-9.0036, 53.5853],
              [-9.014, 53.5983],
              [-8.9772, 53.6077],
              [-8.9836, 53.6214],
              [-8.9723, 53.6283],
              [-8.9686, 53.656],
              [-8.9424, 53.664],
              [-8.9112, 53.6475],
              [-8.8985, 53.6543],
              [-8.8637, 53.6578],
              [-8.8419, 53.6551],
              [-8.8145, 53.6698],
            ],
          ],
          [
            [
              [-9.5201, 53.0677],
              [-9.5021, 53.0615],
              [-9.5256, 53.0482],
              [-9.5534, 53.0517],
              [-9.5429, 53.0668],
              [-9.5201, 53.0677],
            ],
          ],
          [
            [
              [-9.5698, 53.1033],
              [-9.5621, 53.0883],
              [-9.5766, 53.0748],
              [-9.6036, 53.0681],
              [-9.6161, 53.0733],
              [-9.6128, 53.0847],
              [-9.5959, 53.1001],
              [-9.5698, 53.1033],
            ],
          ],
          [
            [
              [-9.7257, 53.2496],
              [-9.723, 53.2397],
              [-9.7371, 53.2309],
              [-9.7416, 53.2489],
              [-9.7257, 53.2496],
            ],
          ],
          [
            [
              [-9.7767, 53.1498],
              [-9.7594, 53.149],
              [-9.7501, 53.1327],
              [-9.7331, 53.1392],
              [-9.7016, 53.143],
              [-9.6543, 53.1329],
              [-9.6658, 53.1096],
              [-9.638, 53.102],
              [-9.6521, 53.091],
              [-9.6868, 53.1054],
              [-9.718, 53.115],
              [-9.7401, 53.1185],
              [-9.7576, 53.1266],
              [-9.827, 53.1403],
              [-9.8068, 53.1526],
              [-9.7767, 53.1498],
            ],
          ],
          [
            [
              [-9.6719, 53.3067],
              [-9.6365, 53.2969],
              [-9.6537, 53.2891],
              [-9.6732, 53.2881],
              [-9.7102, 53.2981],
              [-9.684, 53.3109],
              [-9.6719, 53.3067],
            ],
          ],
          [
            [
              [-10.1512, 53.5318],
              [-10.1685, 53.5249],
              [-10.1809, 53.5373],
              [-10.1609, 53.54],
              [-10.1512, 53.5318],
            ],
          ],
          [
            [
              [-9.6537, 53.2843],
              [-9.6439, 53.265],
              [-9.6412, 53.2435],
              [-9.6562, 53.228],
              [-9.6741, 53.226],
              [-9.7128, 53.2362],
              [-9.7018, 53.2536],
              [-9.7217, 53.2648],
              [-9.6812, 53.272],
              [-9.6807, 53.2819],
              [-9.6537, 53.2843],
            ],
          ],
          [
            [
              [-10.1947, 53.637],
              [-10.1788, 53.6335],
              [-10.1758, 53.6202],
              [-10.1937, 53.6087],
              [-10.2059, 53.6151],
              [-10.2333, 53.6139],
              [-10.2535, 53.62],
              [-10.2222, 53.6331],
              [-10.1947, 53.637],
            ],
          ],
          [
            [
              [-9.8971, 53.4143],
              [-9.8866, 53.3965],
              [-9.8891, 53.3822],
              [-9.9004, 53.3779],
              [-9.9067, 53.397],
              [-9.8971, 53.4143],
            ],
          ],
        ],
      },
      properties: {name: 'Galway', id: 'IE-G', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-G',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.0816, 53.3826],
            [-7.0729, 53.3988],
            [-7.0324, 53.4117],
            [-7.0044, 53.4382],
            [-6.9629, 53.4528],
            [-6.9466, 53.4432],
            [-6.9147, 53.4495],
            [-6.9116, 53.4388],
            [-6.8898, 53.4285],
            [-6.8797, 53.4158],
            [-6.8438, 53.4048],
            [-6.8119, 53.4001],
            [-6.79, 53.4207],
            [-6.7472, 53.432],
            [-6.6869, 53.4145],
            [-6.6483, 53.3959],
            [-6.6317, 53.3957],
            [-6.5706, 53.3886],
            [-6.55, 53.4061],
            [-6.5351, 53.3965],
            [-6.4938, 53.3842],
            [-6.482, 53.3904],
            [-6.4547, 53.3899],
            [-6.466, 53.3831],
            [-6.4755, 53.3642],
            [-6.4866, 53.3626],
            [-6.4952, 53.3461],
            [-6.4894, 53.3385],
            [-6.4979, 53.3221],
            [-6.5166, 53.3246],
            [-6.5372, 53.3086],
            [-6.5257, 53.3026],
            [-6.527, 53.2903],
            [-6.545, 53.2804],
            [-6.5148, 53.2642],
            [-6.5328, 53.2547],
            [-6.47, 53.2383],
            [-6.4732, 53.2353],
            [-6.4916, 53.2213],
            [-6.507, 53.2154],
            [-6.4978, 53.2061],
            [-6.5196, 53.1878],
            [-6.5373, 53.1904],
            [-6.5555, 53.1833],
            [-6.5572, 53.1747],
            [-6.5387, 53.1675],
            [-6.5718, 53.1511],
            [-6.5731, 53.1344],
            [-6.6085, 53.1061],
            [-6.6242, 53.1057],
            [-6.6287, 53.0939],
            [-6.6597, 53.09],
            [-6.682, 53.0785],
            [-6.7178, 53.0722],
            [-6.7311, 53.0666],
            [-6.7293, 53.0516],
            [-6.756, 53.0257],
            [-6.7765, 53.0209],
            [-6.7664, 53.0102],
            [-6.7879, 53.0009],
            [-6.7936, 52.9926],
            [-6.7852, 52.9768],
            [-6.7367, 52.9438],
            [-6.7313, 52.9285],
            [-6.7362, 52.916],
            [-6.747, 52.913],
            [-6.745, 52.8986],
            [-6.7634, 52.891],
            [-6.7886, 52.8671],
            [-6.813, 52.874],
            [-6.8325, 52.8648],
            [-6.8796, 52.8595],
            [-6.9035, 52.8693],
            [-6.9297, 52.8866],
            [-6.9365, 52.8781],
            [-6.9401, 52.8923],
            [-6.9343, 52.9174],
            [-6.9681, 52.9594],
            [-6.9782, 52.9798],
            [-7.0204, 52.9668],
            [-7.0188, 52.9764],
            [-7.0576, 52.9823],
            [-7.0885, 53.0067],
            [-7.0697, 53.0225],
            [-7.059, 53.0528],
            [-7.0487, 53.0581],
            [-7.0454, 53.0743],
            [-7.0565, 53.1045],
            [-7.072, 53.1107],
            [-7.0845, 53.1315],
            [-7.1008, 53.1375],
            [-7.0921, 53.1492],
            [-7.0985, 53.1595],
            [-7.0641, 53.1616],
            [-7.0811, 53.1731],
            [-7.1033, 53.1692],
            [-7.124, 53.1819],
            [-7.1602, 53.1832],
            [-7.1545, 53.192],
            [-7.0979, 53.188],
            [-7.0686, 53.1927],
            [-7.035, 53.1918],
            [-7.0366, 53.2082],
            [-7.0109, 53.219],
            [-7.0312, 53.2278],
            [-7.0454, 53.2253],
            [-7.0496, 53.2448],
            [-7.0177, 53.2547],
            [-7.0311, 53.2797],
            [-6.9901, 53.2867],
            [-7.0101, 53.2974],
            [-6.9758, 53.3091],
            [-6.988, 53.3216],
            [-7.0231, 53.331],
            [-7.0219, 53.3418],
            [-7.0419, 53.3574],
            [-7.0586, 53.3542],
            [-7.0885, 53.3716],
            [-7.0816, 53.3826],
          ],
        ],
      },
      properties: {name: 'Kildare', id: 'IE-KE', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-KE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.6734, 52.7829],
            [-7.6501, 52.7912],
            [-7.6142, 52.7917],
            [-7.6132, 52.8094],
            [-7.5828, 52.823],
            [-7.5642, 52.8212],
            [-7.5576, 52.8103],
            [-7.5261, 52.812],
            [-7.4897, 52.7983],
            [-7.4765, 52.787],
            [-7.4486, 52.7901],
            [-7.4281, 52.8051],
            [-7.3996, 52.7976],
            [-7.3894, 52.8201],
            [-7.373, 52.8315],
            [-7.3455, 52.832],
            [-7.3242, 52.8625],
            [-7.3083, 52.8681],
            [-7.2584, 52.8497],
            [-7.2421, 52.8655],
            [-7.2442, 52.8792],
            [-7.2175, 52.8781],
            [-7.2004, 52.8918],
            [-7.1841, 52.8937],
            [-7.1652, 52.8835],
            [-7.1583, 52.8726],
            [-7.1392, 52.8706],
            [-7.1341, 52.8471],
            [-7.119, 52.8272],
            [-7.0946, 52.8258],
            [-7.0731, 52.8148],
            [-7.0599, 52.7924],
            [-7.0648, 52.7851],
            [-7.0874, 52.7765],
            [-7.084, 52.758],
            [-7.1007, 52.7476],
            [-7.0667, 52.7107],
            [-7.05, 52.7056],
            [-7.0427, 52.715],
            [-7.0039, 52.6956],
            [-6.9939, 52.676],
            [-6.9763, 52.6696],
            [-6.9854, 52.6587],
            [-6.9813, 52.6442],
            [-6.9874, 52.6307],
            [-6.9691, 52.6057],
            [-6.9483, 52.6017],
            [-6.9171, 52.5791],
            [-6.9147, 52.5619],
            [-6.9448, 52.5426],
            [-6.9493, 52.5313],
            [-6.9339, 52.5279],
            [-6.9319, 52.5173],
            [-6.939, 52.5007],
            [-6.9155, 52.465],
            [-6.926, 52.4447],
            [-6.9155, 52.4298],
            [-6.9266, 52.4221],
            [-6.9472, 52.4275],
            [-6.9396, 52.405],
            [-6.963, 52.3811],
            [-6.964, 52.3699],
            [-6.9847, 52.3695],
            [-6.9945, 52.3502],
            [-6.9869, 52.3209],
            [-7.0008, 52.3148],
            [-7.0142, 52.297],
            [-6.996, 52.2766],
            [-7.0187, 52.2724],
            [-7.0382, 52.2581],
            [-7.0656, 52.2584],
            [-7.0881, 52.2533],
            [-7.099, 52.2664],
            [-7.1281, 52.2696],
            [-7.1467, 52.2793],
            [-7.1691, 52.2745],
            [-7.2019, 52.2485],
            [-7.227, 52.2489],
            [-7.2386, 52.2633],
            [-7.2637, 52.2692],
            [-7.2824, 52.2928],
            [-7.2979, 52.3048],
            [-7.302, 52.3177],
            [-7.3208, 52.3315],
            [-7.3535, 52.3377],
            [-7.3696, 52.3441],
            [-7.3778, 52.3552],
            [-7.3953, 52.3661],
            [-7.3871, 52.3865],
            [-7.39, 52.3992],
            [-7.3824, 52.4244],
            [-7.4094, 52.4353],
            [-7.4231, 52.4522],
            [-7.4414, 52.4643],
            [-7.4652, 52.4739],
            [-7.4548, 52.4854],
            [-7.4325, 52.4961],
            [-7.4205, 52.5113],
            [-7.4316, 52.5186],
            [-7.4564, 52.5233],
            [-7.4517, 52.5559],
            [-7.4588, 52.5613],
            [-7.453, 52.5813],
            [-7.4739, 52.593],
            [-7.48, 52.6038],
            [-7.4749, 52.6193],
            [-7.497, 52.633],
            [-7.5196, 52.6559],
            [-7.5345, 52.6914],
            [-7.5492, 52.7036],
            [-7.5758, 52.7136],
            [-7.6069, 52.7214],
            [-7.6265, 52.7347],
            [-7.6472, 52.7339],
            [-7.6497, 52.7452],
            [-7.6678, 52.7565],
            [-7.6734, 52.7829],
          ],
        ],
      },
      properties: {name: 'Kilkenny', id: 'IE-KK', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-KK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-10.3454, 51.9318],
              [-10.3166, 51.9245],
              [-10.2878, 51.927],
              [-10.2895, 51.9158],
              [-10.3067, 51.9063],
              [-10.3454, 51.897],
              [-10.3538, 51.8908],
              [-10.3799, 51.8868],
              [-10.3912, 51.8922],
              [-10.4132, 51.8828],
              [-10.4209, 51.8978],
              [-10.4082, 51.9118],
              [-10.3757, 51.9146],
              [-10.3497, 51.9259],
              [-10.3454, 51.9318],
            ],
          ],
          [
            [
              [-9.3592, 52.5717],
              [-9.3418, 52.5504],
              [-9.348, 52.5426],
              [-9.3046, 52.523],
              [-9.2962, 52.4966],
              [-9.3148, 52.4943],
              [-9.3201, 52.4732],
              [-9.3399, 52.4726],
              [-9.3068, 52.4279],
              [-9.325, 52.4235],
              [-9.3376, 52.4078],
              [-9.3031, 52.3867],
              [-9.3127, 52.3761],
              [-9.3394, 52.3691],
              [-9.3436, 52.3598],
              [-9.3165, 52.3495],
              [-9.2957, 52.3326],
              [-9.2744, 52.3304],
              [-9.2383, 52.3202],
              [-9.2168, 52.2979],
              [-9.2097, 52.29],
              [-9.2218, 52.2832],
              [-9.2468, 52.279],
              [-9.2453, 52.2663],
              [-9.2712, 52.2561],
              [-9.2738, 52.2304],
              [-9.251, 52.2109],
              [-9.2442, 52.1843],
              [-9.2305, 52.1768],
              [-9.2365, 52.1483],
              [-9.2244, 52.1286],
              [-9.2277, 52.122],
              [-9.19, 52.0855],
              [-9.1895, 52.0753],
              [-9.2061, 52.0718],
              [-9.2189, 52.0512],
              [-9.2072, 52.0244],
              [-9.1795, 52.028],
              [-9.1618, 52.0055],
              [-9.1344, 52.0053],
              [-9.1176, 52.0003],
              [-9.1363, 51.9838],
              [-9.1556, 51.9876],
              [-9.1927, 51.9843],
              [-9.2113, 51.9766],
              [-9.2545, 51.9706],
              [-9.262, 51.9602],
              [-9.2766, 51.9569],
              [-9.3064, 51.9422],
              [-9.3017, 51.9252],
              [-9.2856, 51.9147],
              [-9.2916, 51.9045],
              [-9.2785, 51.9004],
              [-9.288, 51.8839],
              [-9.3014, 51.8791],
              [-9.3092, 51.8633],
              [-9.3249, 51.8573],
              [-9.3296, 51.8467],
              [-9.3489, 51.8438],
              [-9.3603, 51.8359],
              [-9.3859, 51.8372],
              [-9.4047, 51.8266],
              [-9.4357, 51.826],
              [-9.4475, 51.806],
              [-9.4616, 51.7954],
              [-9.4818, 51.7904],
              [-9.5232, 51.788],
              [-9.5416, 51.7922],
              [-9.5656, 51.7915],
              [-9.5786, 51.7857],
              [-9.6204, 51.7875],
              [-9.6512, 51.7662],
              [-9.6673, 51.7492],
              [-9.6921, 51.7432],
              [-9.7031, 51.7462],
              [-9.7239, 51.7405],
              [-9.7379, 51.7301],
              [-9.7607, 51.721],
              [-9.7603, 51.7135],
              [-9.7907, 51.6948],
              [-9.8219, 51.6901],
              [-9.8282, 51.701],
              [-9.8245, 51.7186],
              [-9.8442, 51.7244],
              [-9.8342, 51.7334],
              [-9.8559, 51.7514],
              [-9.8551, 51.7654],
              [-9.8447, 51.7773],
              [-9.8263, 51.7662],
              [-9.8034, 51.7598],
              [-9.7808, 51.7651],
              [-9.7951, 51.7753],
              [-9.8243, 51.785],
              [-9.7801, 51.8007],
              [-9.7775, 51.8097],
              [-9.7448, 51.8206],
              [-9.7417, 51.828],
              [-9.7166, 51.8394],
              [-9.6693, 51.8418],
              [-9.634, 51.8509],
              [-9.5876, 51.8704],
              [-9.6019, 51.8743],
              [-9.6297, 51.8674],
              [-9.6524, 51.8721],
              [-9.6871, 51.8615],
              [-9.7306, 51.8534],
              [-9.7521, 51.845],
              [-9.7725, 51.8416],
              [-9.7859, 51.834],
              [-9.8306, 51.824],
              [-9.8406, 51.8095],
              [-9.8536, 51.8066],
              [-9.8772, 51.8173],
              [-9.9084, 51.8043],
              [-9.9277, 51.8055],
              [-9.9495, 51.8014],
              [-9.9696, 51.7895],
              [-9.9775, 51.7908],
              [-10.0137, 51.7716],
              [-10.0511, 51.773],
              [-10.0568, 51.7612],
              [-10.1001, 51.7571],
              [-10.102, 51.7495],
              [-10.141, 51.7427],
              [-10.1145, 51.7615],
              [-10.1448, 51.7593],
              [-10.1826, 51.7671],
              [-10.2017, 51.7819],
              [-10.1817, 51.7882],
              [-10.1727, 51.8241],
              [-10.1789, 51.8325],
              [-10.2059, 51.845],
              [-10.2472, 51.8438],
              [-10.2543, 51.8326],
              [-10.2725, 51.8229],
              [-10.2739, 51.8113],
              [-10.2887, 51.8091],
              [-10.3075, 51.7954],
              [-10.3406, 51.7882],
              [-10.3541, 51.7985],
              [-10.3343, 51.8052],
              [-10.3396, 51.817],
              [-10.3362, 51.8362],
              [-10.3412, 51.8446],
              [-10.3566, 51.8475],
              [-10.3752, 51.8419],
              [-10.3937, 51.848],
              [-10.3829, 51.8599],
              [-10.3972, 51.8776],
              [-10.3839, 51.8834],
              [-10.3492, 51.8856],
              [-10.3232, 51.8909],
              [-10.3097, 51.898],
              [-10.2822, 51.9021],
              [-10.2785, 51.912],
              [-10.257, 51.9144],
              [-10.2584, 51.9246],
              [-10.2781, 51.9299],
              [-10.2681, 51.9395],
              [-10.2785, 51.9515],
              [-10.3187, 51.9528],
              [-10.3066, 51.9627],
              [-10.2798, 51.9698],
              [-10.2533, 51.9891],
              [-10.2108, 51.9978],
              [-10.1817, 52.0128],
              [-10.1623, 52.0146],
              [-10.1473, 52.0248],
              [-10.128, 52.0256],
              [-10.0543, 52.0408],
              [-10.0251, 52.0383],
              [-9.9747, 52.055],
              [-9.9455, 52.0723],
              [-9.9235, 52.0722],
              [-9.9333, 52.086],
              [-9.9109, 52.1003],
              [-9.8979, 52.1211],
              [-9.8806, 52.1109],
              [-9.8576, 52.1139],
              [-9.8351, 52.1271],
              [-9.8114, 52.1323],
              [-9.7928, 52.1316],
              [-9.7971, 52.1437],
              [-9.8204, 52.1594],
              [-9.8636, 52.1585],
              [-9.9203, 52.1478],
              [-9.9505, 52.1466],
              [-9.942, 52.117],
              [-9.9545, 52.1122],
              [-9.9695, 52.1314],
              [-9.985, 52.1436],
              [-10.0775, 52.1285],
              [-10.1036, 52.1259],
              [-10.1201, 52.1148],
              [-10.1543, 52.1196],
              [-10.1911, 52.107],
              [-10.2011, 52.1196],
              [-10.2153, 52.1223],
              [-10.2465, 52.1172],
              [-10.2585, 52.1239],
              [-10.2695, 52.1389],
              [-10.2932, 52.1345],
              [-10.2979, 52.1269],
              [-10.264, 52.1238],
              [-10.2635, 52.1148],
              [-10.2898, 52.1114],
              [-10.3217, 52.1152],
              [-10.3248, 52.1224],
              [-10.3589, 52.132],
              [-10.3743, 52.1196],
              [-10.3693, 52.1085],
              [-10.4068, 52.1036],
              [-10.421, 52.0985],
              [-10.4495, 52.0972],
              [-10.462, 52.1144],
              [-10.4566, 52.1219],
              [-10.4674, 52.1318],
              [-10.4596, 52.1541],
              [-10.4425, 52.1766],
              [-10.47, 52.1807],
              [-10.4123, 52.2031],
              [-10.4124, 52.1835],
              [-10.3707, 52.174],
              [-10.3627, 52.1861],
              [-10.3756, 52.1975],
              [-10.3531, 52.2128],
              [-10.3687, 52.2162],
              [-10.3622, 52.2332],
              [-10.3241, 52.2362],
              [-10.2976, 52.2457],
              [-10.2905, 52.2579],
              [-10.2663, 52.2592],
              [-10.2578, 52.2698],
              [-10.1732, 52.2908],
              [-10.1532, 52.2884],
              [-10.1563, 52.2657],
              [-10.1463, 52.2617],
              [-10.1696, 52.2353],
              [-10.1306, 52.2441],
              [-10.0992, 52.2449],
              [-10.071, 52.2531],
              [-10.0457, 52.2689],
              [-10.0318, 52.2827],
              [-10.021, 52.2795],
              [-10.0195, 52.2676],
              [-9.9954, 52.2514],
              [-9.9535, 52.2324],
              [-9.922, 52.2345],
              [-9.9066, 52.2292],
              [-9.8614, 52.2361],
              [-9.838, 52.2502],
              [-9.8248, 52.2434],
              [-9.8029, 52.2439],
              [-9.7803, 52.2527],
              [-9.7603, 52.2512],
              [-9.7465, 52.2563],
              [-9.7507, 52.267],
              [-9.7738, 52.2743],
              [-9.8108, 52.2712],
              [-9.8476, 52.2799],
              [-9.8739, 52.2746],
              [-9.8746, 52.2994],
              [-9.8578, 52.2899],
              [-9.8364, 52.2923],
              [-9.8253, 52.311],
              [-9.8325, 52.325],
              [-9.8361, 52.3621],
              [-9.8304, 52.3719],
              [-9.835, 52.3872],
              [-9.8724, 52.3912],
              [-9.937, 52.4028],
              [-9.9463, 52.4105],
              [-9.9419, 52.4211],
              [-9.9074, 52.4325],
              [-9.8608, 52.4261],
              [-9.825, 52.4334],
              [-9.7948, 52.4456],
              [-9.7687, 52.4484],
              [-9.7504, 52.4572],
              [-9.7426, 52.4688],
              [-9.7083, 52.4815],
              [-9.6886, 52.4832],
              [-9.6793, 52.4981],
              [-9.6708, 52.5424],
              [-9.6778, 52.5477],
              [-9.647, 52.5675],
              [-9.6158, 52.5779],
              [-9.574, 52.5714],
              [-9.5129, 52.572],
              [-9.4882, 52.5596],
              [-9.4646, 52.5609],
              [-9.4507, 52.576],
              [-9.3949, 52.576],
              [-9.3863, 52.5845],
              [-9.3627, 52.58],
              [-9.3592, 52.5717],
            ],
          ],
          [
            [
              [-10.5145, 52.1113],
              [-10.5045, 52.1049],
              [-10.5128, 52.0962],
              [-10.5565, 52.0814],
              [-10.563, 52.0865],
              [-10.5249, 52.1011],
              [-10.5145, 52.1113],
            ],
          ],
        ],
      },
      properties: {name: 'Kerry', id: 'IE-KY', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-KY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.9164, 53.8374],
            [-7.9123, 53.8225],
            [-7.8839, 53.8262],
            [-7.8632, 53.825],
            [-7.8292, 53.8123],
            [-7.8204, 53.8209],
            [-7.7884, 53.8175],
            [-7.7812, 53.837],
            [-7.7704, 53.8483],
            [-7.7754, 53.8667],
            [-7.7571, 53.8671],
            [-7.7477, 53.8802],
            [-7.7299, 53.8797],
            [-7.7105, 53.9006],
            [-7.6904, 53.9065],
            [-7.6905, 53.9189],
            [-7.6691, 53.9212],
            [-7.6585, 53.9375],
            [-7.648, 53.9422],
            [-7.6201, 53.9357],
            [-7.6109, 53.9379],
            [-7.5813, 53.9138],
            [-7.5582, 53.905],
            [-7.5512, 53.8937],
            [-7.5568, 53.8789],
            [-7.571, 53.8811],
            [-7.5818, 53.8585],
            [-7.5432, 53.855],
            [-7.5251, 53.8644],
            [-7.4956, 53.8612],
            [-7.4756, 53.8457],
            [-7.4651, 53.8198],
            [-7.4688, 53.8116],
            [-7.4336, 53.7948],
            [-7.4127, 53.7906],
            [-7.4073, 53.7769],
            [-7.3828, 53.773],
            [-7.3725, 53.7572],
            [-7.3816, 53.752],
            [-7.3842, 53.7358],
            [-7.4577, 53.7298],
            [-7.4612, 53.7161],
            [-7.4725, 53.7155],
            [-7.4857, 53.7023],
            [-7.5199, 53.6966],
            [-7.5332, 53.6863],
            [-7.5283, 53.6763],
            [-7.5505, 53.6699],
            [-7.5683, 53.6716],
            [-7.5745, 53.659],
            [-7.6084, 53.659],
            [-7.6149, 53.6495],
            [-7.5738, 53.6387],
            [-7.5654, 53.6302],
            [-7.5725, 53.6125],
            [-7.5862, 53.6161],
            [-7.6039, 53.6115],
            [-7.6019, 53.5971],
            [-7.6285, 53.5912],
            [-7.6348, 53.5777],
            [-7.6614, 53.5598],
            [-7.6572, 53.5433],
            [-7.6627, 53.5347],
            [-7.7062, 53.5384],
            [-7.7209, 53.5278],
            [-7.7409, 53.5285],
            [-7.7547, 53.5355],
            [-7.7982, 53.5387],
            [-7.8193, 53.5455],
            [-7.8352, 53.5416],
            [-7.8584, 53.5509],
            [-7.8878, 53.5453],
            [-7.9164, 53.5453],
            [-7.9428, 53.5277],
            [-7.9685, 53.5237],
            [-7.9803, 53.5515],
            [-8.0164, 53.5859],
            [-8.0353, 53.6093],
            [-8.035, 53.6436],
            [-8.0208, 53.6599],
            [-7.9999, 53.6686],
            [-7.9844, 53.6814],
            [-7.9853, 53.7016],
            [-7.9628, 53.713],
            [-7.9499, 53.7132],
            [-7.9462, 53.7371],
            [-7.9174, 53.7363],
            [-7.9157, 53.7483],
            [-7.8859, 53.7602],
            [-7.8804, 53.7847],
            [-7.907, 53.7943],
            [-7.9163, 53.8191],
            [-7.9164, 53.8374],
          ],
        ],
      },
      properties: {name: 'Longford', id: 'IE-LD', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-LD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.5981, 54.0465],
            [-6.5874, 54.0572],
            [-6.544, 54.0536],
            [-6.5288, 54.0596],
            [-6.5091, 54.0547],
            [-6.4964, 54.0645],
            [-6.4812, 54.0672],
            [-6.4771, 54.0792],
            [-6.4562, 54.074],
            [-6.4364, 54.0592],
            [-6.4117, 54.0645],
            [-6.3863, 54.0616],
            [-6.3622, 54.0734],
            [-6.3624, 54.1148],
            [-6.3389, 54.1152],
            [-6.3341, 54.0965],
            [-6.3216, 54.0936],
            [-6.3064, 54.1084],
            [-6.2867, 54.112],
            [-6.2672, 54.102],
            [-6.2437, 54.0788],
            [-6.1966, 54.0603],
            [-6.1809, 54.0424],
            [-6.1487, 54.0294],
            [-6.1298, 54.036],
            [-6.1261, 54.0196],
            [-6.1017, 54.0025],
            [-6.1387, 53.9816],
            [-6.1641, 53.9791],
            [-6.1956, 53.9811],
            [-6.218, 53.9868],
            [-6.2477, 53.9895],
            [-6.292, 54.0084],
            [-6.3196, 54.0154],
            [-6.3381, 54.0144],
            [-6.3382, 53.9986],
            [-6.3627, 53.9818],
            [-6.3588, 53.973],
            [-6.3708, 53.9457],
            [-6.3741, 53.9229],
            [-6.3656, 53.9024],
            [-6.3468, 53.8871],
            [-6.3285, 53.8787],
            [-6.3035, 53.8795],
            [-6.2907, 53.8737],
            [-6.2642, 53.871],
            [-6.237, 53.8623],
            [-6.2509, 53.8499],
            [-6.2588, 53.825],
            [-6.2581, 53.8101],
            [-6.2438, 53.8013],
            [-6.216, 53.7971],
            [-6.2353, 53.7874],
            [-6.2448, 53.7656],
            [-6.2529, 53.7289],
            [-6.2452, 53.7234],
            [-6.2704, 53.7319],
            [-6.2936, 53.7238],
            [-6.3196, 53.72],
            [-6.3224, 53.7089],
            [-6.3551, 53.7067],
            [-6.3772, 53.7176],
            [-6.4229, 53.726],
            [-6.4333, 53.7166],
            [-6.4623, 53.7149],
            [-6.48, 53.7318],
            [-6.4604, 53.7514],
            [-6.4546, 53.7658],
            [-6.4688, 53.771],
            [-6.5158, 53.7682],
            [-6.5316, 53.7771],
            [-6.5484, 53.7792],
            [-6.5538, 53.8002],
            [-6.5776, 53.8042],
            [-6.5792, 53.8237],
            [-6.5977, 53.839],
            [-6.5916, 53.8471],
            [-6.6021, 53.8616],
            [-6.6261, 53.8795],
            [-6.6576, 53.8837],
            [-6.6655, 53.8915],
            [-6.694, 53.9036],
            [-6.6827, 53.9137],
            [-6.6709, 53.9106],
            [-6.6465, 53.9174],
            [-6.6352, 53.9322],
            [-6.6575, 53.9412],
            [-6.6464, 53.9482],
            [-6.6603, 53.9638],
            [-6.638, 53.9646],
            [-6.6411, 53.9747],
            [-6.6174, 53.9838],
            [-6.5857, 53.9719],
            [-6.5656, 53.9738],
            [-6.5552, 53.9906],
            [-6.5609, 53.9995],
            [-6.55, 54.006],
            [-6.5565, 54.0165],
            [-6.5785, 54.0307],
            [-6.5735, 54.0352],
            [-6.5981, 54.0465],
          ],
        ],
      },
      properties: {name: 'Louth', id: 'IE-LH', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-LH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.4773, 52.7597],
            [-8.4665, 52.7408],
            [-8.4659, 52.709],
            [-8.4457, 52.6873],
            [-8.4254, 52.6798],
            [-8.4019, 52.6928],
            [-8.3821, 52.6837],
            [-8.3588, 52.6821],
            [-8.3356, 52.6907],
            [-8.2951, 52.6979],
            [-8.2845, 52.6969],
            [-8.2509, 52.6838],
            [-8.2511, 52.6716],
            [-8.2242, 52.6693],
            [-8.2088, 52.673],
            [-8.1873, 52.6686],
            [-8.1942, 52.6448],
            [-8.2115, 52.6354],
            [-8.2178, 52.6102],
            [-8.2113, 52.5983],
            [-8.2345, 52.583],
            [-8.2228, 52.5728],
            [-8.2326, 52.5634],
            [-8.2241, 52.5567],
            [-8.2303, 52.5369],
            [-8.2306, 52.5172],
            [-8.2546, 52.5113],
            [-8.2838, 52.5225],
            [-8.2891, 52.5151],
            [-8.3112, 52.5109],
            [-8.3266, 52.4975],
            [-8.3352, 52.513],
            [-8.3582, 52.5119],
            [-8.3659, 52.5033],
            [-8.3918, 52.4873],
            [-8.3901, 52.4737],
            [-8.4037, 52.4665],
            [-8.3881, 52.4343],
            [-8.364, 52.447],
            [-8.3498, 52.436],
            [-8.3296, 52.4421],
            [-8.3132, 52.437],
            [-8.3096, 52.4211],
            [-8.286, 52.4171],
            [-8.2826, 52.4012],
            [-8.2493, 52.4092],
            [-8.2327, 52.4096],
            [-8.2284, 52.3871],
            [-8.214, 52.368],
            [-8.1925, 52.3657],
            [-8.1645, 52.368],
            [-8.1666, 52.3465],
            [-8.1784, 52.3374],
            [-8.1742, 52.3248],
            [-8.1616, 52.3208],
            [-8.1637, 52.2955],
            [-8.1836, 52.2903],
            [-8.2066, 52.2969],
            [-8.2461, 52.2994],
            [-8.2793, 52.2894],
            [-8.2924, 52.2967],
            [-8.3112, 52.3181],
            [-8.3273, 52.3192],
            [-8.338, 52.3296],
            [-8.3606, 52.3211],
            [-8.3624, 52.3123],
            [-8.3812, 52.3081],
            [-8.386, 52.2957],
            [-8.4362, 52.2939],
            [-8.4542, 52.2885],
            [-8.4883, 52.286],
            [-8.5046, 52.2801],
            [-8.5408, 52.2914],
            [-8.5569, 52.3082],
            [-8.5841, 52.3086],
            [-8.6019, 52.3224],
            [-8.6144, 52.3402],
            [-8.6533, 52.3488],
            [-8.6728, 52.3663],
            [-8.6794, 52.3823],
            [-8.7336, 52.3723],
            [-8.7774, 52.3868],
            [-8.792, 52.3734],
            [-8.8022, 52.3877],
            [-8.8192, 52.3853],
            [-8.8272, 52.3721],
            [-8.8512, 52.3684],
            [-8.8507, 52.358],
            [-8.8753, 52.3548],
            [-8.892, 52.3321],
            [-8.9123, 52.326],
            [-8.9545, 52.3212],
            [-8.9836, 52.313],
            [-8.9738, 52.295],
            [-8.9905, 52.2954],
            [-9.006, 52.3111],
            [-9.0318, 52.3236],
            [-9.0429, 52.3225],
            [-9.0718, 52.3322],
            [-9.1074, 52.3337],
            [-9.1352, 52.3449],
            [-9.1391, 52.3344],
            [-9.1537, 52.3306],
            [-9.1737, 52.3128],
            [-9.2093, 52.3067],
            [-9.2168, 52.2979],
            [-9.2383, 52.3202],
            [-9.2744, 52.3304],
            [-9.2957, 52.3326],
            [-9.3165, 52.3495],
            [-9.3436, 52.3598],
            [-9.3394, 52.3691],
            [-9.3127, 52.3761],
            [-9.3031, 52.3867],
            [-9.3376, 52.4078],
            [-9.325, 52.4235],
            [-9.3068, 52.4279],
            [-9.3399, 52.4726],
            [-9.3201, 52.4732],
            [-9.3148, 52.4943],
            [-9.2962, 52.4966],
            [-9.3046, 52.523],
            [-9.348, 52.5426],
            [-9.3418, 52.5504],
            [-9.3592, 52.5717],
            [-9.3241, 52.576],
            [-9.293, 52.5688],
            [-9.2415, 52.5851],
            [-9.2254, 52.5851],
            [-9.1964, 52.5969],
            [-9.1428, 52.6099],
            [-9.1042, 52.6106],
            [-9.0527, 52.6302],
            [-9.0516, 52.6217],
            [-9.0291, 52.6243],
            [-8.9948, 52.6217],
            [-8.9819, 52.625],
            [-8.9808, 52.636],
            [-8.9626, 52.6491],
            [-8.9615, 52.6569],
            [-8.9186, 52.6634],
            [-8.8768, 52.6654],
            [-8.8639, 52.6634],
            [-8.8371, 52.6693],
            [-8.8157, 52.67],
            [-8.7963, 52.6543],
            [-8.7856, 52.6654],
            [-8.7633, 52.6758],
            [-8.7299, 52.6763],
            [-8.7075, 52.673],
            [-8.6796, 52.6874],
            [-8.6462, 52.6881],
            [-8.6515, 52.6706],
            [-8.6826, 52.6628],
            [-8.6869, 52.6465],
            [-8.6687, 52.6432],
            [-8.6204, 52.6439],
            [-8.6086, 52.6374],
            [-8.5915, 52.6439],
            [-8.5797, 52.6569],
            [-8.5765, 52.6706],
            [-8.5419, 52.6858],
            [-8.5403, 52.6998],
            [-8.5188, 52.7052],
            [-8.5013, 52.72],
            [-8.5072, 52.7491],
            [-8.4773, 52.7597],
          ],
        ],
      },
      properties: {name: 'Limerick', id: 'IE-LK', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-LK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.2852, 54.4783],
            [-8.2325, 54.4625],
            [-8.2178, 54.4742],
            [-8.1737, 54.4656],
            [-8.1403, 54.4525],
            [-8.1578, 54.4397],
            [-8.1021, 54.4127],
            [-8.0605, 54.3705],
            [-8.0148, 54.3578],
            [-7.9983, 54.35],
            [-7.9654, 54.3262],
            [-7.947, 54.3077],
            [-7.9727, 54.2849],
            [-7.9832, 54.2649],
            [-8.0096, 54.2503],
            [-8.0406, 54.2554],
            [-8.057, 54.2376],
            [-8.0339, 54.2143],
            [-8.0463, 54.2007],
            [-8.043, 54.1875],
            [-8.0184, 54.1874],
            [-8.0064, 54.193],
            [-7.988, 54.1816],
            [-7.9495, 54.1789],
            [-7.9246, 54.1691],
            [-7.8872, 54.1436],
            [-7.8743, 54.1272],
            [-7.8494, 54.1202],
            [-7.8567, 54.1143],
            [-7.8286, 54.0992],
            [-7.7973, 54.1178],
            [-7.7866, 54.1096],
            [-7.7558, 54.1069],
            [-7.7376, 54.0955],
            [-7.7331, 54.084],
            [-7.706, 54.086],
            [-7.681, 54.0633],
            [-7.6563, 54.0577],
            [-7.6528, 54.0439],
            [-7.6404, 54.0266],
            [-7.5995, 54.0146],
            [-7.5912, 53.9981],
            [-7.5983, 53.9879],
            [-7.5814, 53.9772],
            [-7.5943, 53.9686],
            [-7.5861, 53.9614],
            [-7.6045, 53.9486],
            [-7.6109, 53.9379],
            [-7.6201, 53.9357],
            [-7.648, 53.9422],
            [-7.6585, 53.9375],
            [-7.6691, 53.9212],
            [-7.6905, 53.9189],
            [-7.6904, 53.9065],
            [-7.7105, 53.9006],
            [-7.7299, 53.8797],
            [-7.7477, 53.8802],
            [-7.7571, 53.8671],
            [-7.7754, 53.8667],
            [-7.7704, 53.8483],
            [-7.7812, 53.837],
            [-7.7884, 53.8175],
            [-7.8204, 53.8209],
            [-7.8292, 53.8123],
            [-7.8632, 53.825],
            [-7.8839, 53.8262],
            [-7.9123, 53.8225],
            [-7.9164, 53.8374],
            [-7.9261, 53.8496],
            [-7.9405, 53.8489],
            [-7.962, 53.8673],
            [-7.977, 53.8655],
            [-7.9768, 53.8905],
            [-7.991, 53.9037],
            [-7.9869, 53.9158],
            [-8.0044, 53.921],
            [-8.0119, 53.9342],
            [-8.0321, 53.933],
            [-8.0299, 53.9243],
            [-8.0627, 53.9109],
            [-8.0849, 53.9212],
            [-8.0923, 53.9308],
            [-8.0823, 53.9375],
            [-8.1145, 53.9558],
            [-8.0784, 53.9789],
            [-8.0809, 54.0117],
            [-8.0891, 54.021],
            [-8.0861, 54.0463],
            [-8.0614, 54.0589],
            [-8.0479, 54.0731],
            [-8.0561, 54.0798],
            [-8.0541, 54.1051],
            [-8.1038, 54.1105],
            [-8.144, 54.1262],
            [-8.1614, 54.1106],
            [-8.1792, 54.129],
            [-8.215, 54.1395],
            [-8.2231, 54.1548],
            [-8.2494, 54.1606],
            [-8.2574, 54.1539],
            [-8.2788, 54.1541],
            [-8.326, 54.1794],
            [-8.3155, 54.1904],
            [-8.346, 54.1982],
            [-8.3294, 54.2125],
            [-8.3362, 54.2282],
            [-8.3295, 54.2427],
            [-8.3391, 54.2532],
            [-8.3445, 54.2755],
            [-8.3437, 54.3021],
            [-8.3531, 54.3133],
            [-8.3868, 54.3214],
            [-8.3845, 54.3294],
            [-8.3962, 54.3612],
            [-8.3637, 54.3737],
            [-8.343, 54.4238],
            [-8.3888, 54.4206],
            [-8.4156, 54.4164],
            [-8.4241, 54.4232],
            [-8.4056, 54.4326],
            [-8.4019, 54.4453],
            [-8.3673, 54.4557],
            [-8.3798, 54.4672],
            [-8.3345, 54.4716],
            [-8.3105, 54.4799],
            [-8.2852, 54.4783],
          ],
        ],
      },
      properties: {name: 'Leitrim', id: 'IE-LM', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-LM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.7308, 52.9588],
            [-7.73, 52.9656],
            [-7.7115, 52.9761],
            [-7.7041, 52.9867],
            [-7.709, 53.0096],
            [-7.6953, 53.0287],
            [-7.6546, 53.041],
            [-7.6554, 53.0528],
            [-7.6119, 53.0721],
            [-7.5847, 53.1047],
            [-7.6122, 53.1116],
            [-7.611, 53.1201],
            [-7.6313, 53.131],
            [-7.6454, 53.1512],
            [-7.6315, 53.1615],
            [-7.6306, 53.1699],
            [-7.6037, 53.1746],
            [-7.5479, 53.1789],
            [-7.5306, 53.1858],
            [-7.4796, 53.195],
            [-7.4584, 53.1896],
            [-7.4307, 53.189],
            [-7.4274, 53.2074],
            [-7.4036, 53.2142],
            [-7.3903, 53.2089],
            [-7.3786, 53.2159],
            [-7.3598, 53.2102],
            [-7.352, 53.199],
            [-7.3326, 53.189],
            [-7.3148, 53.171],
            [-7.3296, 53.1702],
            [-7.3153, 53.1442],
            [-7.2984, 53.1352],
            [-7.2817, 53.1387],
            [-7.2379, 53.1403],
            [-7.225, 53.1501],
            [-7.1985, 53.157],
            [-7.1784, 53.1757],
            [-7.17, 53.1594],
            [-7.0985, 53.1595],
            [-7.0921, 53.1492],
            [-7.1008, 53.1375],
            [-7.0845, 53.1315],
            [-7.072, 53.1107],
            [-7.0565, 53.1045],
            [-7.0454, 53.0743],
            [-7.0487, 53.0581],
            [-7.059, 53.0528],
            [-7.0697, 53.0225],
            [-7.0885, 53.0067],
            [-7.0576, 52.9823],
            [-7.0188, 52.9764],
            [-7.0204, 52.9668],
            [-6.9782, 52.9798],
            [-6.9681, 52.9594],
            [-6.9343, 52.9174],
            [-6.9401, 52.8923],
            [-6.9365, 52.8781],
            [-6.9284, 52.8463],
            [-6.944, 52.8398],
            [-6.9365, 52.8266],
            [-6.95, 52.8146],
            [-6.9708, 52.8104],
            [-7.0357, 52.8088],
            [-7.0431, 52.7957],
            [-7.0599, 52.7924],
            [-7.0731, 52.8148],
            [-7.0946, 52.8258],
            [-7.119, 52.8272],
            [-7.1341, 52.8471],
            [-7.1392, 52.8706],
            [-7.1583, 52.8726],
            [-7.1652, 52.8835],
            [-7.1841, 52.8937],
            [-7.2004, 52.8918],
            [-7.2175, 52.8781],
            [-7.2442, 52.8792],
            [-7.2421, 52.8655],
            [-7.2584, 52.8497],
            [-7.3083, 52.8681],
            [-7.3242, 52.8625],
            [-7.3455, 52.832],
            [-7.373, 52.8315],
            [-7.3894, 52.8201],
            [-7.3996, 52.7976],
            [-7.4281, 52.8051],
            [-7.4486, 52.7901],
            [-7.4765, 52.787],
            [-7.4897, 52.7983],
            [-7.5261, 52.812],
            [-7.5576, 52.8103],
            [-7.5642, 52.8212],
            [-7.5828, 52.823],
            [-7.6132, 52.8094],
            [-7.6142, 52.7917],
            [-7.6501, 52.7912],
            [-7.6734, 52.7829],
            [-7.6788, 52.8043],
            [-7.6934, 52.8299],
            [-7.7166, 52.839],
            [-7.7322, 52.8583],
            [-7.7177, 52.8649],
            [-7.7222, 52.8889],
            [-7.71, 52.8918],
            [-7.706, 52.904],
            [-7.6753, 52.9156],
            [-7.6613, 52.9354],
            [-7.7019, 52.9349],
            [-7.7286, 52.9467],
            [-7.7308, 52.9588],
          ],
        ],
      },
      properties: {name: 'Laois', id: 'IE-LS', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-LS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.7611, 53.919],
            [-6.734, 53.9177],
            [-6.7173, 53.9047],
            [-6.694, 53.9036],
            [-6.6655, 53.8915],
            [-6.6576, 53.8837],
            [-6.6261, 53.8795],
            [-6.6021, 53.8616],
            [-6.5916, 53.8471],
            [-6.5977, 53.839],
            [-6.5792, 53.8237],
            [-6.5776, 53.8042],
            [-6.5538, 53.8002],
            [-6.5484, 53.7792],
            [-6.5316, 53.7771],
            [-6.5158, 53.7682],
            [-6.4688, 53.771],
            [-6.4546, 53.7658],
            [-6.4604, 53.7514],
            [-6.48, 53.7318],
            [-6.4623, 53.7149],
            [-6.4333, 53.7166],
            [-6.4229, 53.726],
            [-6.3772, 53.7176],
            [-6.3551, 53.7067],
            [-6.3224, 53.7089],
            [-6.3196, 53.72],
            [-6.2936, 53.7238],
            [-6.2704, 53.7319],
            [-6.2452, 53.7234],
            [-6.2433, 53.7016],
            [-6.2318, 53.6668],
            [-6.2209, 53.6547],
            [-6.213, 53.6347],
            [-6.2294, 53.6299],
            [-6.2392, 53.6362],
            [-6.2576, 53.6331],
            [-6.2715, 53.6159],
            [-6.2712, 53.599],
            [-6.2869, 53.5903],
            [-6.3076, 53.5867],
            [-6.3177, 53.5773],
            [-6.3334, 53.5738],
            [-6.3679, 53.5904],
            [-6.3882, 53.5927],
            [-6.4032, 53.5731],
            [-6.4218, 53.5684],
            [-6.4339, 53.5487],
            [-6.4072, 53.5273],
            [-6.3896, 53.5256],
            [-6.3651, 53.5163],
            [-6.3662, 53.5081],
            [-6.3439, 53.5006],
            [-6.3365, 53.4797],
            [-6.3543, 53.4605],
            [-6.3521, 53.4479],
            [-6.3872, 53.4393],
            [-6.4108, 53.4412],
            [-6.4191, 53.418],
            [-6.4395, 53.4052],
            [-6.4547, 53.3899],
            [-6.482, 53.3904],
            [-6.4938, 53.3842],
            [-6.5351, 53.3965],
            [-6.55, 53.4061],
            [-6.5706, 53.3886],
            [-6.6317, 53.3957],
            [-6.6483, 53.3959],
            [-6.6869, 53.4145],
            [-6.7472, 53.432],
            [-6.79, 53.4207],
            [-6.8119, 53.4001],
            [-6.8438, 53.4048],
            [-6.8797, 53.4158],
            [-6.8898, 53.4285],
            [-6.9116, 53.4388],
            [-6.9147, 53.4495],
            [-6.9466, 53.4432],
            [-6.9629, 53.4528],
            [-7.0044, 53.4382],
            [-7.0324, 53.4117],
            [-7.0729, 53.3988],
            [-7.0816, 53.3826],
            [-7.1014, 53.3838],
            [-7.1409, 53.4045],
            [-7.1894, 53.424],
            [-7.1913, 53.4339],
            [-7.1582, 53.4391],
            [-7.1486, 53.4505],
            [-7.1176, 53.4467],
            [-7.089, 53.4558],
            [-7.061, 53.4697],
            [-7.0586, 53.4858],
            [-7.0649, 53.4955],
            [-7.0409, 53.5009],
            [-7.0211, 53.5229],
            [-7.0167, 53.5448],
            [-7.032, 53.5546],
            [-7.0298, 53.5679],
            [-7.0154, 53.5782],
            [-6.9938, 53.5767],
            [-6.9959, 53.5864],
            [-6.9848, 53.5924],
            [-6.9804, 53.6046],
            [-6.9871, 53.6194],
            [-6.9612, 53.6351],
            [-6.9552, 53.6505],
            [-6.9723, 53.6587],
            [-6.9867, 53.6774],
            [-6.9996, 53.679],
            [-7.0072, 53.6676],
            [-7.0504, 53.658],
            [-7.0659, 53.6454],
            [-7.1045, 53.6686],
            [-7.1169, 53.6836],
            [-7.1324, 53.6734],
            [-7.146, 53.6842],
            [-7.1829, 53.6913],
            [-7.2238, 53.7037],
            [-7.2372, 53.7186],
            [-7.2301, 53.7348],
            [-7.2624, 53.749],
            [-7.2872, 53.7777],
            [-7.3379, 53.7912],
            [-7.3414, 53.8],
            [-7.3073, 53.813],
            [-7.2842, 53.7991],
            [-7.2734, 53.7822],
            [-7.2502, 53.7767],
            [-7.2179, 53.7751],
            [-7.2119, 53.7863],
            [-7.1934, 53.7954],
            [-7.156, 53.7961],
            [-7.1476, 53.8032],
            [-7.1271, 53.7914],
            [-7.1048, 53.7882],
            [-7.1029, 53.7764],
            [-7.064, 53.7738],
            [-7.0404, 53.7768],
            [-6.9972, 53.766],
            [-6.9594, 53.7909],
            [-6.9445, 53.7812],
            [-6.9356, 53.8015],
            [-6.921, 53.801],
            [-6.9118, 53.8093],
            [-6.917, 53.8177],
            [-6.9503, 53.8365],
            [-6.9701, 53.8575],
            [-6.9245, 53.8759],
            [-6.9339, 53.8926],
            [-6.9204, 53.8926],
            [-6.8832, 53.8786],
            [-6.8615, 53.8753],
            [-6.8435, 53.879],
            [-6.8465, 53.8878],
            [-6.8217, 53.9002],
            [-6.8086, 53.8987],
            [-6.7933, 53.8867],
            [-6.7825, 53.9119],
            [-6.7611, 53.919],
          ],
        ],
      },
      properties: {name: 'Meath', id: 'IE-MH', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-MH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.3202, 54.119],
            [-7.3068, 54.1251],
            [-7.3184, 54.1386],
            [-7.3374, 54.1459],
            [-7.3076, 54.1689],
            [-7.2902, 54.1727],
            [-7.2788, 54.1679],
            [-7.2843, 54.1543],
            [-7.2983, 54.1447],
            [-7.2888, 54.1344],
            [-7.2617, 54.1371],
            [-7.2648, 54.1468],
            [-7.2493, 54.1749],
            [-7.2525, 54.195],
            [-7.2368, 54.202],
            [-7.2253, 54.2146],
            [-7.2011, 54.2181],
            [-7.1881, 54.2265],
            [-7.165, 54.2198],
            [-7.1452, 54.225],
            [-7.1412, 54.2532],
            [-7.1713, 54.2854],
            [-7.2054, 54.2944],
            [-7.2063, 54.3049],
            [-7.1956, 54.3125],
            [-7.1802, 54.3119],
            [-7.1757, 54.3252],
            [-7.185, 54.3406],
            [-7.1502, 54.337],
            [-7.1179, 54.3543],
            [-7.1012, 54.3556],
            [-7.1077, 54.3687],
            [-7.0833, 54.3801],
            [-7.0513, 54.4118],
            [-7.0253, 54.4208],
            [-6.9817, 54.4091],
            [-6.9568, 54.3933],
            [-6.9047, 54.3707],
            [-6.9006, 54.3531],
            [-6.8806, 54.3493],
            [-6.856, 54.3156],
            [-6.8532, 54.2942],
            [-6.8737, 54.2858],
            [-6.8618, 54.2713],
            [-6.8269, 54.2612],
            [-6.8276, 54.2484],
            [-6.8151, 54.226],
            [-6.7742, 54.2006],
            [-6.7541, 54.1993],
            [-6.7393, 54.1844],
            [-6.7197, 54.1837],
            [-6.7127, 54.1965],
            [-6.7003, 54.2025],
            [-6.6636, 54.1944],
            [-6.643, 54.1773],
            [-6.6282, 54.1581],
            [-6.6388, 54.1322],
            [-6.6586, 54.1179],
            [-6.6416, 54.0976],
            [-6.6536, 54.0892],
            [-6.658, 54.0628],
            [-6.6456, 54.0625],
            [-6.6211, 54.0404],
            [-6.5981, 54.0465],
            [-6.5735, 54.0352],
            [-6.5785, 54.0307],
            [-6.5565, 54.0165],
            [-6.55, 54.006],
            [-6.5609, 53.9995],
            [-6.5552, 53.9906],
            [-6.5656, 53.9738],
            [-6.5857, 53.9719],
            [-6.6174, 53.9838],
            [-6.6411, 53.9747],
            [-6.638, 53.9646],
            [-6.6603, 53.9638],
            [-6.6464, 53.9482],
            [-6.6575, 53.9412],
            [-6.6352, 53.9322],
            [-6.6465, 53.9174],
            [-6.6709, 53.9106],
            [-6.6827, 53.9137],
            [-6.694, 53.9036],
            [-6.7173, 53.9047],
            [-6.734, 53.9177],
            [-6.7611, 53.919],
            [-6.7755, 53.929],
            [-6.8179, 53.9338],
            [-6.8283, 53.9406],
            [-6.8504, 53.965],
            [-6.8632, 53.9874],
            [-6.9128, 54.0167],
            [-6.9227, 54.0154],
            [-6.9381, 54.0346],
            [-6.9669, 54.0455],
            [-6.9807, 54.0614],
            [-7.0008, 54.0654],
            [-7.0108, 54.0786],
            [-7.0284, 54.0879],
            [-7.0165, 54.0953],
            [-7.0484, 54.1021],
            [-7.0766, 54.0907],
            [-7.1206, 54.097],
            [-7.153, 54.0838],
            [-7.1781, 54.0881],
            [-7.2228, 54.1025],
            [-7.2405, 54.1029],
            [-7.2616, 54.0951],
            [-7.2871, 54.1072],
            [-7.3161, 54.107],
            [-7.3202, 54.119],
          ],
        ],
      },
      properties: {name: 'Monaghan', id: 'IE-MN', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-MN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-10.0875, 53.7153],
              [-10.0922, 53.6994],
              [-10.1293, 53.6926],
              [-10.1375, 53.7005],
              [-10.1239, 53.7078],
              [-10.0875, 53.7153],
            ],
          ],
          [
            [
              [-9.9622, 53.8143],
              [-9.9432, 53.8126],
              [-9.9489, 53.8008],
              [-9.9921, 53.7923],
              [-10.022, 53.794],
              [-10.0519, 53.7921],
              [-10.0448, 53.807],
              [-10.0228, 53.8134],
              [-9.9947, 53.8158],
              [-9.9746, 53.8282],
              [-9.9622, 53.8143],
            ],
          ],
          [
            [
              [-10.2047, 54.1478],
              [-10.1843, 54.1377],
              [-10.2093, 54.1335],
              [-10.2047, 54.1478],
            ],
          ],
          [
            [
              [-9.9618, 54.0231],
              [-9.9423, 54.0136],
              [-9.9354, 53.9935],
              [-9.9576, 53.9926],
              [-9.9506, 53.9669],
              [-9.9193, 53.9637],
              [-9.9263, 53.9567],
              [-9.9223, 53.9342],
              [-9.932, 53.9194],
              [-9.9461, 53.9186],
              [-9.9364, 53.9017],
              [-9.9475, 53.8809],
              [-9.9632, 53.8772],
              [-10.0023, 53.8991],
              [-9.9937, 53.9056],
              [-10.0144, 53.9141],
              [-10.0537, 53.9187],
              [-10.0542, 53.9406],
              [-10.0428, 53.9606],
              [-10.0687, 53.9747],
              [-10.0993, 53.9665],
              [-10.1268, 53.9761],
              [-10.1541, 53.9685],
              [-10.1901, 53.9724],
              [-10.2136, 53.9691],
              [-10.2337, 53.9829],
              [-10.2096, 53.9832],
              [-10.1708, 54.0095],
              [-10.1464, 54.0108],
              [-10.143, 54.0039],
              [-10.1005, 54.0026],
              [-10.0721, 54.0253],
              [-10.047, 54.027],
              [-10.0262, 54.0135],
              [-9.9758, 54.0252],
              [-9.9618, 54.0231],
            ],
          ],
          [
            [
              [-10, 54.3073],
              [-9.988, 54.3018],
              [-9.9868, 54.2915],
              [-9.9694, 54.2897],
              [-9.9432, 54.271],
              [-9.9214, 54.2765],
              [-9.9175, 54.2659],
              [-9.8989, 54.2654],
              [-9.8914, 54.2563],
              [-9.9127, 54.2305],
              [-9.9574, 54.225],
              [-9.99, 54.2273],
              [-10.0142, 54.2269],
              [-10.0036, 54.2169],
              [-10.0158, 54.1876],
              [-10.0396, 54.1951],
              [-10.0362, 54.1783],
              [-10.0543, 54.1728],
              [-10.0896, 54.1559],
              [-10.0634, 54.1522],
              [-10.0748, 54.1407],
              [-10.073, 54.1293],
              [-10.0908, 54.1247],
              [-10.0891, 54.1136],
              [-10.0669, 54.1111],
              [-10.0612, 54.1009],
              [-10.0726, 54.0914],
              [-10.092, 54.0892],
              [-10.1119, 54.0981],
              [-10.1208, 54.1126],
              [-10.1244, 54.1317],
              [-10.1171, 54.1466],
              [-10.1018, 54.1545],
              [-10.087, 54.1797],
              [-10.096, 54.2007],
              [-10.0814, 54.2113],
              [-10.0629, 54.212],
              [-10.0549, 54.2278],
              [-10.082, 54.2334],
              [-10.1087, 54.2439],
              [-10.0854, 54.2552],
              [-10.0689, 54.2776],
              [-10.0264, 54.284],
              [-10.0116, 54.3035],
              [-10, 54.3073],
            ],
          ],
          [
            [
              [-9.8905, 54.002],
              [-9.8955, 53.9914],
              [-9.9155, 53.9881],
              [-9.9132, 54.0028],
              [-9.8905, 54.002],
            ],
          ],
          [
            [
              [-9.1348, 54.1636],
              [-9.0941, 54.1444],
              [-9.0558, 54.1457],
              [-9.0506, 54.1571],
              [-9.0163, 54.158],
              [-8.9945, 54.1509],
              [-8.9633, 54.1495],
              [-8.9338, 54.1446],
              [-8.941, 54.1343],
              [-8.9142, 54.1197],
              [-8.9344, 54.1071],
              [-8.9572, 54.1038],
              [-8.9527, 54.0926],
              [-8.9666, 54.0867],
              [-8.9632, 54.0744],
              [-8.975, 54.0712],
              [-9.015, 54.0504],
              [-9.0162, 54.0253],
              [-8.9624, 54.0269],
              [-8.9445, 54.0189],
              [-8.9396, 54.0107],
              [-8.9211, 54.0108],
              [-8.8918, 54.0228],
              [-8.877, 53.9996],
              [-8.8779, 53.9899],
              [-8.8508, 53.9751],
              [-8.7952, 53.9653],
              [-8.7787, 53.9746],
              [-8.7531, 53.9814],
              [-8.738, 53.9958],
              [-8.7214, 53.9887],
              [-8.7094, 53.9916],
              [-8.6998, 54.006],
              [-8.6808, 54.0098],
              [-8.674, 54.0183],
              [-8.6798, 54.0308],
              [-8.6441, 54.0345],
              [-8.6145, 54.0224],
              [-8.5819, 54.0133],
              [-8.5918, 53.992],
              [-8.5921, 53.9713],
              [-8.5815, 53.9557],
              [-8.6117, 53.9582],
              [-8.6392, 53.9649],
              [-8.6659, 53.9754],
              [-8.6858, 53.9638],
              [-8.6777, 53.9462],
              [-8.6843, 53.9202],
              [-8.6612, 53.909],
              [-8.6347, 53.9005],
              [-8.6394, 53.8906],
              [-8.6255, 53.8842],
              [-8.6468, 53.8676],
              [-8.6741, 53.8773],
              [-8.7018, 53.8752],
              [-8.692, 53.8628],
              [-8.6952, 53.8522],
              [-8.7153, 53.8435],
              [-8.7144, 53.8191],
              [-8.7385, 53.8118],
              [-8.7076, 53.8046],
              [-8.6866, 53.7962],
              [-8.6677, 53.776],
              [-8.6845, 53.747],
              [-8.697, 53.7362],
              [-8.693, 53.7269],
              [-8.7123, 53.7254],
              [-8.7105, 53.7147],
              [-8.7538, 53.7017],
              [-8.7571, 53.6926],
              [-8.774, 53.6916],
              [-8.7898, 53.6973],
              [-8.804, 53.6774],
              [-8.8145, 53.6698],
              [-8.8419, 53.6551],
              [-8.8637, 53.6578],
              [-8.8985, 53.6543],
              [-8.9112, 53.6475],
              [-8.9424, 53.664],
              [-8.9686, 53.656],
              [-8.9723, 53.6283],
              [-8.9836, 53.6214],
              [-8.9772, 53.6077],
              [-9.014, 53.5983],
              [-9.0036, 53.5853],
              [-9.0426, 53.5798],
              [-9.039, 53.5674],
              [-9.0253, 53.5506],
              [-9.0358, 53.5363],
              [-9.0912, 53.5163],
              [-9.0984, 53.4948],
              [-9.1146, 53.4958],
              [-9.1335, 53.4804],
              [-9.1567, 53.4857],
              [-9.1817, 53.4749],
              [-9.2032, 53.4755],
              [-9.2331, 53.4822],
              [-9.2327, 53.4997],
              [-9.2747, 53.5162],
              [-9.2886, 53.5469],
              [-9.3179, 53.5502],
              [-9.3241, 53.5411],
              [-9.3426, 53.5424],
              [-9.3508, 53.5537],
              [-9.3442, 53.5645],
              [-9.3583, 53.5736],
              [-9.4045, 53.5693],
              [-9.4171, 53.565],
              [-9.44, 53.5662],
              [-9.4776, 53.5635],
              [-9.512, 53.5802],
              [-9.5422, 53.5837],
              [-9.5618, 53.6128],
              [-9.5767, 53.6002],
              [-9.6082, 53.6069],
              [-9.6473, 53.6021],
              [-9.666, 53.614],
              [-9.6989, 53.6014],
              [-9.7328, 53.6027],
              [-9.7768, 53.5992],
              [-9.8705, 53.6289],
              [-9.8905, 53.651],
              [-9.9102, 53.6545],
              [-9.9132, 53.6675],
              [-9.9059, 53.6795],
              [-9.9179, 53.6912],
              [-9.9107, 53.7141],
              [-9.8973, 53.7271],
              [-9.8939, 53.7403],
              [-9.9075, 53.7549],
              [-9.9002, 53.7669],
              [-9.8803, 53.7657],
              [-9.8502, 53.7707],
              [-9.821, 53.7717],
              [-9.8026, 53.7793],
              [-9.7703, 53.7831],
              [-9.7649, 53.7762],
              [-9.7382, 53.7748],
              [-9.7021, 53.7789],
              [-9.6932, 53.7836],
              [-9.6602, 53.7886],
              [-9.6181, 53.7873],
              [-9.5814, 53.7825],
              [-9.5498, 53.8077],
              [-9.6067, 53.8136],
              [-9.6187, 53.8353],
              [-9.61, 53.8484],
              [-9.5936, 53.8554],
              [-9.5777, 53.8548],
              [-9.561, 53.8629],
              [-9.5821, 53.8728],
              [-9.5674, 53.8936],
              [-9.5966, 53.898],
              [-9.6148, 53.8896],
              [-9.6288, 53.8978],
              [-9.6536, 53.8932],
              [-9.7081, 53.902],
              [-9.7463, 53.8958],
              [-9.7728, 53.9034],
              [-9.7938, 53.8982],
              [-9.8116, 53.8817],
              [-9.8653, 53.8686],
              [-9.897, 53.8682],
              [-9.9227, 53.8731],
              [-9.9286, 53.8808],
              [-9.9236, 53.8975],
              [-9.9292, 53.9152],
              [-9.9144, 53.9199],
              [-9.9155, 53.9386],
              [-9.9013, 53.9558],
              [-9.8699, 53.9605],
              [-9.8336, 53.9602],
              [-9.8298, 53.9504],
              [-9.8017, 53.9525],
              [-9.8004, 53.9338],
              [-9.8159, 53.9318],
              [-9.8043, 53.915],
              [-9.7924, 53.928],
              [-9.79, 53.9506],
              [-9.8107, 53.9664],
              [-9.8306, 53.9709],
              [-9.8515, 53.9694],
              [-9.8478, 53.9968],
              [-9.878, 54.0096],
              [-9.8808, 54.0245],
              [-9.8939, 54.0277],
              [-9.9067, 54.0484],
              [-9.8903, 54.055],
              [-9.8944, 54.0743],
              [-9.9345, 54.0645],
              [-9.9631, 54.0743],
              [-9.9758, 54.0984],
              [-9.9882, 54.106],
              [-9.9531, 54.1098],
              [-9.927, 54.1191],
              [-9.8949, 54.1197],
              [-9.9146, 54.1351],
              [-9.9578, 54.1453],
              [-9.9559, 54.1559],
              [-9.9281, 54.1493],
              [-9.9212, 54.1561],
              [-9.9425, 54.1624],
              [-9.9534, 54.1744],
              [-9.9345, 54.1824],
              [-9.9317, 54.1969],
              [-9.9682, 54.2055],
              [-9.9902, 54.2209],
              [-9.9859, 54.2263],
              [-9.9502, 54.2154],
              [-9.8889, 54.2165],
              [-9.8743, 54.2201],
              [-9.8787, 54.2466],
              [-9.8716, 54.2529],
              [-9.8674, 54.2748],
              [-9.8173, 54.286],
              [-9.8128, 54.2936],
              [-9.8528, 54.3101],
              [-9.8581, 54.3196],
              [-9.834, 54.3257],
              [-9.8055, 54.3425],
              [-9.7738, 54.3365],
              [-9.7576, 54.3398],
              [-9.7389, 54.3283],
              [-9.6961, 54.3231],
              [-9.6601, 54.3153],
              [-9.616, 54.3277],
              [-9.5795, 54.3218],
              [-9.5617, 54.3228],
              [-9.5173, 54.3114],
              [-9.4788, 54.3186],
              [-9.4685, 54.3135],
              [-9.4294, 54.3139],
              [-9.3912, 54.311],
              [-9.3896, 54.2994],
              [-9.3439, 54.3221],
              [-9.2884, 54.3136],
              [-9.2625, 54.3115],
              [-9.2474, 54.2989],
              [-9.254, 54.2823],
              [-9.2064, 54.2778],
              [-9.2205, 54.2481],
              [-9.1951, 54.2485],
              [-9.1981, 54.2313],
              [-9.2151, 54.225],
              [-9.2186, 54.215],
              [-9.1824, 54.2099],
              [-9.16, 54.2026],
              [-9.1658, 54.1926],
              [-9.1444, 54.1853],
              [-9.1348, 54.1636],
            ],
          ],
        ],
      },
      properties: {name: 'Mayo', id: 'IE-MO', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-MO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.1894, 53.424],
            [-7.1409, 53.4045],
            [-7.1014, 53.3838],
            [-7.0816, 53.3826],
            [-7.0885, 53.3716],
            [-7.0586, 53.3542],
            [-7.0419, 53.3574],
            [-7.0219, 53.3418],
            [-7.0231, 53.331],
            [-6.988, 53.3216],
            [-6.9758, 53.3091],
            [-7.0101, 53.2974],
            [-6.9901, 53.2867],
            [-7.0311, 53.2797],
            [-7.0177, 53.2547],
            [-7.0496, 53.2448],
            [-7.0454, 53.2253],
            [-7.0312, 53.2278],
            [-7.0109, 53.219],
            [-7.0366, 53.2082],
            [-7.035, 53.1918],
            [-7.0686, 53.1927],
            [-7.0979, 53.188],
            [-7.1545, 53.192],
            [-7.1602, 53.1832],
            [-7.124, 53.1819],
            [-7.1033, 53.1692],
            [-7.0811, 53.1731],
            [-7.0641, 53.1616],
            [-7.0985, 53.1595],
            [-7.17, 53.1594],
            [-7.1784, 53.1757],
            [-7.1985, 53.157],
            [-7.225, 53.1501],
            [-7.2379, 53.1403],
            [-7.2817, 53.1387],
            [-7.2984, 53.1352],
            [-7.3153, 53.1442],
            [-7.3296, 53.1702],
            [-7.3148, 53.171],
            [-7.3326, 53.189],
            [-7.352, 53.199],
            [-7.3598, 53.2102],
            [-7.3786, 53.2159],
            [-7.3903, 53.2089],
            [-7.4036, 53.2142],
            [-7.4274, 53.2074],
            [-7.4307, 53.189],
            [-7.4584, 53.1896],
            [-7.4796, 53.195],
            [-7.5306, 53.1858],
            [-7.5479, 53.1789],
            [-7.6037, 53.1746],
            [-7.6306, 53.1699],
            [-7.6315, 53.1615],
            [-7.6454, 53.1512],
            [-7.6313, 53.131],
            [-7.611, 53.1201],
            [-7.6122, 53.1116],
            [-7.5847, 53.1047],
            [-7.6119, 53.0721],
            [-7.6554, 53.0528],
            [-7.6546, 53.041],
            [-7.6953, 53.0287],
            [-7.709, 53.0096],
            [-7.7041, 52.9867],
            [-7.7115, 52.9761],
            [-7.73, 52.9656],
            [-7.7308, 52.9588],
            [-7.7743, 52.9718],
            [-7.7886, 52.971],
            [-7.8023, 52.9808],
            [-7.8379, 52.9644],
            [-7.8453, 52.956],
            [-7.8638, 52.9579],
            [-7.8674, 52.9465],
            [-7.8464, 52.9358],
            [-7.8683, 52.924],
            [-7.8598, 52.9176],
            [-7.8944, 52.9073],
            [-7.8978, 52.8939],
            [-7.9282, 52.8773],
            [-7.9725, 52.8494],
            [-7.9951, 52.8669],
            [-7.9849, 52.88],
            [-8.0284, 52.891],
            [-8.0456, 52.9016],
            [-8.0388, 52.9139],
            [-8.0517, 52.9286],
            [-8.0424, 52.9405],
            [-8.0115, 52.9302],
            [-7.994, 52.931],
            [-7.9742, 52.95],
            [-7.9872, 52.974],
            [-8.0056, 52.9848],
            [-7.9912, 52.9986],
            [-7.9487, 52.9958],
            [-7.9615, 53.0124],
            [-7.9529, 53.0343],
            [-7.9385, 53.044],
            [-7.9246, 53.0684],
            [-7.9195, 53.0858],
            [-7.9261, 53.0975],
            [-7.919, 53.1051],
            [-7.9387, 53.1188],
            [-7.9773, 53.1353],
            [-8.0014, 53.1399],
            [-8.0152, 53.1486],
            [-8.05, 53.1546],
            [-8.0809, 53.1673],
            [-8.0816, 53.172],
            [-8.0584, 53.19],
            [-8.0358, 53.1967],
            [-7.9892, 53.1955],
            [-7.968, 53.2161],
            [-7.9729, 53.2368],
            [-8.0057, 53.253],
            [-8.0255, 53.2548],
            [-8.0276, 53.2652],
            [-8.053, 53.2747],
            [-8.0439, 53.2897],
            [-8.0299, 53.2967],
            [-8.0242, 53.3226],
            [-7.9963, 53.3238],
            [-7.9821, 53.3376],
            [-7.964, 53.3365],
            [-7.9464, 53.3417],
            [-7.9367, 53.3576],
            [-7.9213, 53.3648],
            [-7.9027, 53.3627],
            [-7.8804, 53.3501],
            [-7.8532, 53.3486],
            [-7.8304, 53.3556],
            [-7.7928, 53.3549],
            [-7.7704, 53.3488],
            [-7.7203, 53.3608],
            [-7.7086, 53.3819],
            [-7.6923, 53.3939],
            [-7.6699, 53.404],
            [-7.6536, 53.4062],
            [-7.6235, 53.4181],
            [-7.606, 53.4125],
            [-7.6036, 53.4013],
            [-7.5749, 53.3966],
            [-7.5795, 53.3881],
            [-7.5689, 53.3806],
            [-7.564, 53.3529],
            [-7.5422, 53.339],
            [-7.5304, 53.3436],
            [-7.4934, 53.3428],
            [-7.4947, 53.3304],
            [-7.4708, 53.3326],
            [-7.4545, 53.3197],
            [-7.4246, 53.3365],
            [-7.3983, 53.3316],
            [-7.3676, 53.3415],
            [-7.364, 53.3499],
            [-7.341, 53.3647],
            [-7.3257, 53.3677],
            [-7.2745, 53.3967],
            [-7.2518, 53.4002],
            [-7.2126, 53.4109],
            [-7.2131, 53.4179],
            [-7.1894, 53.424],
          ],
        ],
      },
      properties: {name: 'Offaly', id: 'IE-OY', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-OY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.1614, 54.1106],
            [-8.144, 54.1262],
            [-8.1038, 54.1105],
            [-8.0541, 54.1051],
            [-8.0561, 54.0798],
            [-8.0479, 54.0731],
            [-8.0614, 54.0589],
            [-8.0861, 54.0463],
            [-8.0891, 54.021],
            [-8.0809, 54.0117],
            [-8.0784, 53.9789],
            [-8.1145, 53.9558],
            [-8.0823, 53.9375],
            [-8.0923, 53.9308],
            [-8.0849, 53.9212],
            [-8.0627, 53.9109],
            [-8.0299, 53.9243],
            [-8.0321, 53.933],
            [-8.0119, 53.9342],
            [-8.0044, 53.921],
            [-7.9869, 53.9158],
            [-7.991, 53.9037],
            [-7.9768, 53.8905],
            [-7.977, 53.8655],
            [-7.962, 53.8673],
            [-7.9405, 53.8489],
            [-7.9261, 53.8496],
            [-7.9164, 53.8374],
            [-7.9163, 53.8191],
            [-7.907, 53.7943],
            [-7.8804, 53.7847],
            [-7.8859, 53.7602],
            [-7.9157, 53.7483],
            [-7.9174, 53.7363],
            [-7.9462, 53.7371],
            [-7.9499, 53.7132],
            [-7.9628, 53.713],
            [-7.9853, 53.7016],
            [-7.9844, 53.6814],
            [-7.9999, 53.6686],
            [-8.0208, 53.6599],
            [-8.035, 53.6436],
            [-8.0353, 53.6093],
            [-8.0164, 53.5859],
            [-7.9803, 53.5515],
            [-7.9685, 53.5237],
            [-7.9548, 53.485],
            [-7.9655, 53.4509],
            [-7.9555, 53.4335],
            [-7.9681, 53.4228],
            [-7.9416, 53.4135],
            [-7.9386, 53.4045],
            [-7.923, 53.3946],
            [-7.9086, 53.3717],
            [-7.9213, 53.3648],
            [-7.9367, 53.3576],
            [-7.9464, 53.3417],
            [-7.964, 53.3365],
            [-7.9821, 53.3376],
            [-7.9963, 53.3238],
            [-8.0242, 53.3226],
            [-8.0299, 53.2967],
            [-8.0439, 53.2897],
            [-8.053, 53.2747],
            [-8.0892, 53.2753],
            [-8.1761, 53.2901],
            [-8.1926, 53.3095],
            [-8.1868, 53.3366],
            [-8.198, 53.3493],
            [-8.2115, 53.3462],
            [-8.2292, 53.3512],
            [-8.2421, 53.3685],
            [-8.2249, 53.3974],
            [-8.2392, 53.414],
            [-8.2563, 53.4148],
            [-8.2605, 53.4375],
            [-8.2376, 53.4477],
            [-8.2609, 53.4581],
            [-8.2624, 53.4643],
            [-8.2858, 53.4741],
            [-8.2707, 53.4822],
            [-8.274, 53.4972],
            [-8.2919, 53.5113],
            [-8.2889, 53.5204],
            [-8.2966, 53.5682],
            [-8.3066, 53.5802],
            [-8.3384, 53.5799],
            [-8.3381, 53.5892],
            [-8.3523, 53.6009],
            [-8.3756, 53.6025],
            [-8.3778, 53.6186],
            [-8.3973, 53.6317],
            [-8.39, 53.6416],
            [-8.367, 53.642],
            [-8.3628, 53.6543],
            [-8.401, 53.6687],
            [-8.4325, 53.6778],
            [-8.4541, 53.6971],
            [-8.4532, 53.7066],
            [-8.4666, 53.7164],
            [-8.492, 53.7175],
            [-8.5167, 53.7138],
            [-8.53, 53.7193],
            [-8.571, 53.7194],
            [-8.5813, 53.7108],
            [-8.6324, 53.706],
            [-8.6393, 53.6879],
            [-8.6598, 53.6777],
            [-8.7389, 53.6648],
            [-8.7748, 53.6667],
            [-8.7949, 53.6595],
            [-8.8145, 53.6698],
            [-8.804, 53.6774],
            [-8.7898, 53.6973],
            [-8.774, 53.6916],
            [-8.7571, 53.6926],
            [-8.7538, 53.7017],
            [-8.7105, 53.7147],
            [-8.7123, 53.7254],
            [-8.693, 53.7269],
            [-8.697, 53.7362],
            [-8.6845, 53.747],
            [-8.6677, 53.776],
            [-8.6866, 53.7962],
            [-8.7076, 53.8046],
            [-8.7385, 53.8118],
            [-8.7144, 53.8191],
            [-8.7153, 53.8435],
            [-8.6952, 53.8522],
            [-8.692, 53.8628],
            [-8.7018, 53.8752],
            [-8.6741, 53.8773],
            [-8.6468, 53.8676],
            [-8.6255, 53.8842],
            [-8.6394, 53.8906],
            [-8.6347, 53.9005],
            [-8.6612, 53.909],
            [-8.6843, 53.9202],
            [-8.6777, 53.9462],
            [-8.6858, 53.9638],
            [-8.6659, 53.9754],
            [-8.6392, 53.9649],
            [-8.6117, 53.9582],
            [-8.5815, 53.9557],
            [-8.5564, 53.9548],
            [-8.5354, 53.9587],
            [-8.5178, 53.9444],
            [-8.5167, 53.9282],
            [-8.4768, 53.9214],
            [-8.4666, 53.9148],
            [-8.4493, 53.9151],
            [-8.448, 53.925],
            [-8.435, 53.9294],
            [-8.3912, 53.9266],
            [-8.3354, 53.9278],
            [-8.3479, 53.9504],
            [-8.4037, 53.9587],
            [-8.3797, 53.9708],
            [-8.3851, 53.9897],
            [-8.3947, 54.0016],
            [-8.3655, 54.0065],
            [-8.342, 54.0007],
            [-8.3134, 54.0061],
            [-8.3049, 54.034],
            [-8.2951, 54.045],
            [-8.2612, 54.0546],
            [-8.2379, 54.0404],
            [-8.237, 54.058],
            [-8.2182, 54.0626],
            [-8.2178, 54.084],
            [-8.1758, 54.0896],
            [-8.1614, 54.1106],
          ],
        ],
      },
      properties: {name: 'Roscommon', id: 'IE-RN', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-RN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.3798, 54.4672],
            [-8.3673, 54.4557],
            [-8.4019, 54.4453],
            [-8.4056, 54.4326],
            [-8.4241, 54.4232],
            [-8.4156, 54.4164],
            [-8.3888, 54.4206],
            [-8.343, 54.4238],
            [-8.3637, 54.3737],
            [-8.3962, 54.3612],
            [-8.3845, 54.3294],
            [-8.3868, 54.3214],
            [-8.3531, 54.3133],
            [-8.3437, 54.3021],
            [-8.3445, 54.2755],
            [-8.3391, 54.2532],
            [-8.3295, 54.2427],
            [-8.3362, 54.2282],
            [-8.3294, 54.2125],
            [-8.346, 54.1982],
            [-8.3155, 54.1904],
            [-8.326, 54.1794],
            [-8.2788, 54.1541],
            [-8.2574, 54.1539],
            [-8.2494, 54.1606],
            [-8.2231, 54.1548],
            [-8.215, 54.1395],
            [-8.1792, 54.129],
            [-8.1614, 54.1106],
            [-8.1758, 54.0896],
            [-8.2178, 54.084],
            [-8.2182, 54.0626],
            [-8.237, 54.058],
            [-8.2379, 54.0404],
            [-8.2612, 54.0546],
            [-8.2951, 54.045],
            [-8.3049, 54.034],
            [-8.3134, 54.0061],
            [-8.342, 54.0007],
            [-8.3655, 54.0065],
            [-8.3947, 54.0016],
            [-8.3851, 53.9897],
            [-8.3797, 53.9708],
            [-8.4037, 53.9587],
            [-8.3479, 53.9504],
            [-8.3354, 53.9278],
            [-8.3912, 53.9266],
            [-8.435, 53.9294],
            [-8.448, 53.925],
            [-8.4493, 53.9151],
            [-8.4666, 53.9148],
            [-8.4768, 53.9214],
            [-8.5167, 53.9282],
            [-8.5178, 53.9444],
            [-8.5354, 53.9587],
            [-8.5564, 53.9548],
            [-8.5815, 53.9557],
            [-8.5921, 53.9713],
            [-8.5918, 53.992],
            [-8.5819, 54.0133],
            [-8.6145, 54.0224],
            [-8.6441, 54.0345],
            [-8.6798, 54.0308],
            [-8.674, 54.0183],
            [-8.6808, 54.0098],
            [-8.6998, 54.006],
            [-8.7094, 53.9916],
            [-8.7214, 53.9887],
            [-8.738, 53.9958],
            [-8.7531, 53.9814],
            [-8.7787, 53.9746],
            [-8.7952, 53.9653],
            [-8.8508, 53.9751],
            [-8.8779, 53.9899],
            [-8.877, 53.9996],
            [-8.8918, 54.0228],
            [-8.9211, 54.0108],
            [-8.9396, 54.0107],
            [-8.9445, 54.0189],
            [-8.9624, 54.0269],
            [-9.0162, 54.0253],
            [-9.015, 54.0504],
            [-8.975, 54.0712],
            [-8.9632, 54.0744],
            [-8.9666, 54.0867],
            [-8.9527, 54.0926],
            [-8.9572, 54.1038],
            [-8.9344, 54.1071],
            [-8.9142, 54.1197],
            [-8.941, 54.1343],
            [-8.9338, 54.1446],
            [-8.9633, 54.1495],
            [-8.9945, 54.1509],
            [-9.0163, 54.158],
            [-9.0506, 54.1571],
            [-9.0558, 54.1457],
            [-9.0941, 54.1444],
            [-9.1348, 54.1636],
            [-9.1364, 54.1793],
            [-9.1234, 54.1822],
            [-9.1271, 54.1977],
            [-9.1004, 54.213],
            [-9.0971, 54.2267],
            [-9.0788, 54.2445],
            [-9.0551, 54.2748],
            [-9.0606, 54.2819],
            [-9.0526, 54.2959],
            [-9.0228, 54.2975],
            [-8.9985, 54.293],
            [-8.9887, 54.296],
            [-8.9326, 54.2944],
            [-8.9083, 54.2867],
            [-8.8904, 54.2736],
            [-8.8626, 54.2636],
            [-8.8247, 54.2543],
            [-8.7934, 54.2624],
            [-8.7818, 54.2734],
            [-8.7614, 54.2811],
            [-8.7543, 54.2702],
            [-8.7274, 54.2649],
            [-8.6849, 54.2745],
            [-8.653, 54.2704],
            [-8.6269, 54.2497],
            [-8.6248, 54.2388],
            [-8.6051, 54.2324],
            [-8.602, 54.2247],
            [-8.5744, 54.2182],
            [-8.5432, 54.2191],
            [-8.5433, 54.231],
            [-8.5707, 54.235],
            [-8.5705, 54.2424],
            [-8.619, 54.265],
            [-8.6053, 54.2774],
            [-8.5664, 54.2774],
            [-8.5437, 54.2824],
            [-8.5175, 54.2791],
            [-8.5039, 54.285],
            [-8.4872, 54.2799],
            [-8.4797, 54.2886],
            [-8.5221, 54.3042],
            [-8.5728, 54.3076],
            [-8.5696, 54.3266],
            [-8.5178, 54.3154],
            [-8.5076, 54.3286],
            [-8.5329, 54.3338],
            [-8.5546, 54.3431],
            [-8.592, 54.3446],
            [-8.6445, 54.3325],
            [-8.6695, 54.3515],
            [-8.6642, 54.3641],
            [-8.6417, 54.3723],
            [-8.623, 54.3705],
            [-8.6155, 54.3814],
            [-8.5686, 54.3937],
            [-8.551, 54.3958],
            [-8.513, 54.4147],
            [-8.4971, 54.4162],
            [-8.4869, 54.4286],
            [-8.4681, 54.4397],
            [-8.4736, 54.4611],
            [-8.4679, 54.4713],
            [-8.446, 54.4701],
            [-8.4386, 54.4557],
            [-8.4183, 54.4575],
            [-8.3798, 54.4672],
          ],
        ],
      },
      properties: {name: 'Sligo', id: 'IE-SO', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-SO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.3696, 52.3441],
            [-7.3952, 52.3352],
            [-7.4224, 52.348],
            [-7.4462, 52.3515],
            [-7.4795, 52.3521],
            [-7.5158, 52.3605],
            [-7.5657, 52.3597],
            [-7.5942, 52.3651],
            [-7.6275, 52.3597],
            [-7.66, 52.3609],
            [-7.674, 52.353],
            [-7.7004, 52.3479],
            [-7.7341, 52.3497],
            [-7.749, 52.3435],
            [-7.7849, 52.3445],
            [-7.7957, 52.3008],
            [-7.7836, 52.2792],
            [-7.7646, 52.2721],
            [-7.7536, 52.2559],
            [-7.7427, 52.2191],
            [-7.7593, 52.2159],
            [-7.781, 52.2209],
            [-7.8183, 52.2213],
            [-7.8485, 52.2286],
            [-7.9019, 52.2261],
            [-7.9354, 52.2403],
            [-7.9398, 52.2462],
            [-7.971, 52.2435],
            [-7.9872, 52.2369],
            [-7.9923, 52.225],
            [-7.9874, 52.2115],
            [-7.9994, 52.2052],
            [-8.0214, 52.2112],
            [-8.0748, 52.2104],
            [-8.1032, 52.208],
            [-8.1018, 52.2322],
            [-8.151, 52.2402],
            [-8.1581, 52.2439],
            [-8.1628, 52.2785],
            [-8.1766, 52.2774],
            [-8.1836, 52.2903],
            [-8.1637, 52.2955],
            [-8.1616, 52.3208],
            [-8.1742, 52.3248],
            [-8.1784, 52.3374],
            [-8.1666, 52.3465],
            [-8.1645, 52.368],
            [-8.1925, 52.3657],
            [-8.214, 52.368],
            [-8.2284, 52.3871],
            [-8.2327, 52.4096],
            [-8.2493, 52.4092],
            [-8.2826, 52.4012],
            [-8.286, 52.4171],
            [-8.3096, 52.4211],
            [-8.3132, 52.437],
            [-8.3296, 52.4421],
            [-8.3498, 52.436],
            [-8.364, 52.447],
            [-8.3881, 52.4343],
            [-8.4037, 52.4665],
            [-8.3901, 52.4737],
            [-8.3918, 52.4873],
            [-8.3659, 52.5033],
            [-8.3582, 52.5119],
            [-8.3352, 52.513],
            [-8.3266, 52.4975],
            [-8.3112, 52.5109],
            [-8.2891, 52.5151],
            [-8.2838, 52.5225],
            [-8.2546, 52.5113],
            [-8.2306, 52.5172],
            [-8.2303, 52.5369],
            [-8.2241, 52.5567],
            [-8.2326, 52.5634],
            [-8.2228, 52.5728],
            [-8.2345, 52.583],
            [-8.2113, 52.5983],
            [-8.2178, 52.6102],
            [-8.2115, 52.6354],
            [-8.1942, 52.6448],
            [-8.1873, 52.6686],
            [-8.2088, 52.673],
            [-8.2242, 52.6693],
            [-8.2511, 52.6716],
            [-8.2509, 52.6838],
            [-8.2845, 52.6969],
            [-8.2951, 52.6979],
            [-8.3356, 52.6907],
            [-8.3588, 52.6821],
            [-8.3821, 52.6837],
            [-8.4019, 52.6928],
            [-8.4254, 52.6798],
            [-8.4457, 52.6873],
            [-8.4659, 52.709],
            [-8.4665, 52.7408],
            [-8.4773, 52.7597],
            [-8.4616, 52.7758],
            [-8.4447, 52.7859],
            [-8.4365, 52.806],
            [-8.4475, 52.8136],
            [-8.4528, 52.8491],
            [-8.4171, 52.8753],
            [-8.4032, 52.914],
            [-8.3893, 52.9177],
            [-8.3374, 52.9159],
            [-8.3195, 52.9295],
            [-8.3056, 52.9508],
            [-8.2815, 52.9686],
            [-8.3073, 52.9779],
            [-8.3131, 52.9855],
            [-8.297, 53.0099],
            [-8.2749, 53.0205],
            [-8.2615, 53.0582],
            [-8.2195, 53.0682],
            [-8.1992, 53.0841],
            [-8.1676, 53.1156],
            [-8.1691, 53.1229],
            [-8.1446, 53.1484],
            [-8.1272, 53.1592],
            [-8.0848, 53.164],
            [-8.0809, 53.1673],
            [-8.05, 53.1546],
            [-8.0152, 53.1486],
            [-8.0014, 53.1399],
            [-7.9773, 53.1353],
            [-7.9387, 53.1188],
            [-7.919, 53.1051],
            [-7.9261, 53.0975],
            [-7.9195, 53.0858],
            [-7.9246, 53.0684],
            [-7.9385, 53.044],
            [-7.9529, 53.0343],
            [-7.9615, 53.0124],
            [-7.9487, 52.9958],
            [-7.9912, 52.9986],
            [-8.0056, 52.9848],
            [-7.9872, 52.974],
            [-7.9742, 52.95],
            [-7.994, 52.931],
            [-8.0115, 52.9302],
            [-8.0424, 52.9405],
            [-8.0517, 52.9286],
            [-8.0388, 52.9139],
            [-8.0456, 52.9016],
            [-8.0284, 52.891],
            [-7.9849, 52.88],
            [-7.9951, 52.8669],
            [-7.9725, 52.8494],
            [-7.9282, 52.8773],
            [-7.8978, 52.8939],
            [-7.8944, 52.9073],
            [-7.8598, 52.9176],
            [-7.8683, 52.924],
            [-7.8464, 52.9358],
            [-7.8674, 52.9465],
            [-7.8638, 52.9579],
            [-7.8453, 52.956],
            [-7.8379, 52.9644],
            [-7.8023, 52.9808],
            [-7.7886, 52.971],
            [-7.7743, 52.9718],
            [-7.7308, 52.9588],
            [-7.7286, 52.9467],
            [-7.7019, 52.9349],
            [-7.6613, 52.9354],
            [-7.6753, 52.9156],
            [-7.706, 52.904],
            [-7.71, 52.8918],
            [-7.7222, 52.8889],
            [-7.7177, 52.8649],
            [-7.7322, 52.8583],
            [-7.7166, 52.839],
            [-7.6934, 52.8299],
            [-7.6788, 52.8043],
            [-7.6734, 52.7829],
            [-7.6678, 52.7565],
            [-7.6497, 52.7452],
            [-7.6472, 52.7339],
            [-7.6265, 52.7347],
            [-7.6069, 52.7214],
            [-7.5758, 52.7136],
            [-7.5492, 52.7036],
            [-7.5345, 52.6914],
            [-7.5196, 52.6559],
            [-7.497, 52.633],
            [-7.4749, 52.6193],
            [-7.48, 52.6038],
            [-7.4739, 52.593],
            [-7.453, 52.5813],
            [-7.4588, 52.5613],
            [-7.4517, 52.5559],
            [-7.4564, 52.5233],
            [-7.4316, 52.5186],
            [-7.4205, 52.5113],
            [-7.4325, 52.4961],
            [-7.4548, 52.4854],
            [-7.4652, 52.4739],
            [-7.4414, 52.4643],
            [-7.4231, 52.4522],
            [-7.4094, 52.4353],
            [-7.3824, 52.4244],
            [-7.39, 52.3992],
            [-7.3871, 52.3865],
            [-7.3953, 52.3661],
            [-7.3778, 52.3552],
            [-7.3696, 52.3441],
          ],
        ],
      },
      properties: {name: 'Tipperary', id: 'IE-TA', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-TA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.1032, 52.208],
            [-8.0748, 52.2104],
            [-8.0214, 52.2112],
            [-7.9994, 52.2052],
            [-7.9874, 52.2115],
            [-7.9923, 52.225],
            [-7.9872, 52.2369],
            [-7.971, 52.2435],
            [-7.9398, 52.2462],
            [-7.9354, 52.2403],
            [-7.9019, 52.2261],
            [-7.8485, 52.2286],
            [-7.8183, 52.2213],
            [-7.781, 52.2209],
            [-7.7593, 52.2159],
            [-7.7427, 52.2191],
            [-7.7536, 52.2559],
            [-7.7646, 52.2721],
            [-7.7836, 52.2792],
            [-7.7957, 52.3008],
            [-7.7849, 52.3445],
            [-7.749, 52.3435],
            [-7.7341, 52.3497],
            [-7.7004, 52.3479],
            [-7.674, 52.353],
            [-7.66, 52.3609],
            [-7.6275, 52.3597],
            [-7.5942, 52.3651],
            [-7.5657, 52.3597],
            [-7.5158, 52.3605],
            [-7.4795, 52.3521],
            [-7.4462, 52.3515],
            [-7.4224, 52.348],
            [-7.3952, 52.3352],
            [-7.3696, 52.3441],
            [-7.3535, 52.3377],
            [-7.3208, 52.3315],
            [-7.302, 52.3177],
            [-7.2979, 52.3048],
            [-7.2824, 52.2928],
            [-7.2637, 52.2692],
            [-7.2386, 52.2633],
            [-7.227, 52.2489],
            [-7.2019, 52.2485],
            [-7.1691, 52.2745],
            [-7.1467, 52.2793],
            [-7.1281, 52.2696],
            [-7.099, 52.2664],
            [-7.0881, 52.2533],
            [-7.0656, 52.2584],
            [-7.0382, 52.2581],
            [-7.0187, 52.2724],
            [-6.996, 52.2766],
            [-6.9819, 52.2681],
            [-6.9887, 52.2612],
            [-6.9809, 52.2491],
            [-6.9683, 52.2436],
            [-6.9688, 52.2096],
            [-6.9816, 52.1908],
            [-6.9719, 52.1869],
            [-6.9691, 52.1737],
            [-6.9978, 52.143],
            [-7.0321, 52.1342],
            [-7.0481, 52.1406],
            [-7.0809, 52.137],
            [-7.0938, 52.1307],
            [-7.1051, 52.137],
            [-7.0784, 52.1565],
            [-7.0814, 52.1632],
            [-7.1025, 52.1719],
            [-7.1207, 52.1687],
            [-7.1125, 52.1569],
            [-7.1466, 52.1613],
            [-7.1565, 52.1561],
            [-7.1684, 52.1387],
            [-7.2407, 52.1356],
            [-7.2894, 52.1433],
            [-7.3075, 52.1376],
            [-7.3671, 52.1403],
            [-7.3918, 52.1308],
            [-7.4033, 52.1339],
            [-7.4397, 52.1299],
            [-7.49, 52.1059],
            [-7.5135, 52.1079],
            [-7.5374, 52.1003],
            [-7.5503, 52.0797],
            [-7.5694, 52.0854],
            [-7.5817, 52.0994],
            [-7.6318, 52.0849],
            [-7.6327, 52.0685],
            [-7.6072, 52.0667],
            [-7.5862, 52.0535],
            [-7.5648, 52.0531],
            [-7.5647, 52.0421],
            [-7.5819, 52.0207],
            [-7.576, 52.0107],
            [-7.5855, 51.9926],
            [-7.6133, 51.9818],
            [-7.6388, 51.9773],
            [-7.694, 51.974],
            [-7.7157, 51.9593],
            [-7.719, 51.9393],
            [-7.7504, 51.9399],
            [-7.7726, 51.9513],
            [-7.7932, 51.9415],
            [-7.8269, 51.9404],
            [-7.838, 51.9542],
            [-7.8517, 51.9793],
            [-7.8713, 51.9743],
            [-7.9165, 51.9764],
            [-7.9298, 52.0017],
            [-7.9285, 52.0105],
            [-7.9571, 52.0209],
            [-7.9623, 52.0276],
            [-7.9849, 52.0371],
            [-8.0072, 52.0518],
            [-8.0218, 52.0548],
            [-8.0147, 52.076],
            [-8.0288, 52.106],
            [-8.0444, 52.1164],
            [-8.0668, 52.1195],
            [-8.0612, 52.1387],
            [-8.0657, 52.1439],
            [-8.092, 52.137],
            [-8.1108, 52.1477],
            [-8.1369, 52.1441],
            [-8.1531, 52.1509],
            [-8.1523, 52.1661],
            [-8.1275, 52.1693],
            [-8.1165, 52.1986],
            [-8.1032, 52.208],
          ],
        ],
      },
      properties: {name: 'Waterford', id: 'IE-WD', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-WD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.3414, 53.8],
            [-7.3379, 53.7912],
            [-7.2872, 53.7777],
            [-7.2624, 53.749],
            [-7.2301, 53.7348],
            [-7.2372, 53.7186],
            [-7.2238, 53.7037],
            [-7.1829, 53.6913],
            [-7.146, 53.6842],
            [-7.1324, 53.6734],
            [-7.1169, 53.6836],
            [-7.1045, 53.6686],
            [-7.0659, 53.6454],
            [-7.0504, 53.658],
            [-7.0072, 53.6676],
            [-6.9996, 53.679],
            [-6.9867, 53.6774],
            [-6.9723, 53.6587],
            [-6.9552, 53.6505],
            [-6.9612, 53.6351],
            [-6.9871, 53.6194],
            [-6.9804, 53.6046],
            [-6.9848, 53.5924],
            [-6.9959, 53.5864],
            [-6.9938, 53.5767],
            [-7.0154, 53.5782],
            [-7.0298, 53.5679],
            [-7.032, 53.5546],
            [-7.0167, 53.5448],
            [-7.0211, 53.5229],
            [-7.0409, 53.5009],
            [-7.0649, 53.4955],
            [-7.0586, 53.4858],
            [-7.061, 53.4697],
            [-7.089, 53.4558],
            [-7.1176, 53.4467],
            [-7.1486, 53.4505],
            [-7.1582, 53.4391],
            [-7.1913, 53.4339],
            [-7.1894, 53.424],
            [-7.2131, 53.4179],
            [-7.2126, 53.4109],
            [-7.2518, 53.4002],
            [-7.2745, 53.3967],
            [-7.3257, 53.3677],
            [-7.341, 53.3647],
            [-7.364, 53.3499],
            [-7.3676, 53.3415],
            [-7.3983, 53.3316],
            [-7.4246, 53.3365],
            [-7.4545, 53.3197],
            [-7.4708, 53.3326],
            [-7.4947, 53.3304],
            [-7.4934, 53.3428],
            [-7.5304, 53.3436],
            [-7.5422, 53.339],
            [-7.564, 53.3529],
            [-7.5689, 53.3806],
            [-7.5795, 53.3881],
            [-7.5749, 53.3966],
            [-7.6036, 53.4013],
            [-7.606, 53.4125],
            [-7.6235, 53.4181],
            [-7.6536, 53.4062],
            [-7.6699, 53.404],
            [-7.6923, 53.3939],
            [-7.7086, 53.3819],
            [-7.7203, 53.3608],
            [-7.7704, 53.3488],
            [-7.7928, 53.3549],
            [-7.8304, 53.3556],
            [-7.8532, 53.3486],
            [-7.8804, 53.3501],
            [-7.9027, 53.3627],
            [-7.9213, 53.3648],
            [-7.9086, 53.3717],
            [-7.923, 53.3946],
            [-7.9386, 53.4045],
            [-7.9416, 53.4135],
            [-7.9681, 53.4228],
            [-7.9555, 53.4335],
            [-7.9655, 53.4509],
            [-7.9548, 53.485],
            [-7.9685, 53.5237],
            [-7.9428, 53.5277],
            [-7.9164, 53.5453],
            [-7.8878, 53.5453],
            [-7.8584, 53.5509],
            [-7.8352, 53.5416],
            [-7.8193, 53.5455],
            [-7.7982, 53.5387],
            [-7.7547, 53.5355],
            [-7.7409, 53.5285],
            [-7.7209, 53.5278],
            [-7.7062, 53.5384],
            [-7.6627, 53.5347],
            [-7.6572, 53.5433],
            [-7.6614, 53.5598],
            [-7.6348, 53.5777],
            [-7.6285, 53.5912],
            [-7.6019, 53.5971],
            [-7.6039, 53.6115],
            [-7.5862, 53.6161],
            [-7.5725, 53.6125],
            [-7.5654, 53.6302],
            [-7.5738, 53.6387],
            [-7.6149, 53.6495],
            [-7.6084, 53.659],
            [-7.5745, 53.659],
            [-7.5683, 53.6716],
            [-7.5505, 53.6699],
            [-7.5283, 53.6763],
            [-7.5332, 53.6863],
            [-7.5199, 53.6966],
            [-7.4857, 53.7023],
            [-7.4725, 53.7155],
            [-7.4612, 53.7161],
            [-7.4577, 53.7298],
            [-7.3842, 53.7358],
            [-7.3816, 53.752],
            [-7.3725, 53.7572],
            [-7.3828, 53.773],
            [-7.4073, 53.7769],
            [-7.3712, 53.7876],
            [-7.3414, 53.8],
          ],
        ],
      },
      properties: {name: 'Westmeath', id: 'IE-WH', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-WH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.4732, 53.2353],
            [-6.4188, 53.2316],
            [-6.4106, 53.2184],
            [-6.3939, 53.2142],
            [-6.3673, 53.2146],
            [-6.3722, 53.1934],
            [-6.3333, 53.1793],
            [-6.3107, 53.1956],
            [-6.3005, 53.2256],
            [-6.2895, 53.2228],
            [-6.2756, 53.2126],
            [-6.2405, 53.2033],
            [-6.2069, 53.2178],
            [-6.1786, 53.2206],
            [-6.1572, 53.2028],
            [-6.1353, 53.2002],
            [-6.1011, 53.2128],
            [-6.0904, 53.1987],
            [-6.0755, 53.1917],
            [-6.07, 53.1775],
            [-6.0703, 53.1589],
            [-6.0452, 53.1313],
            [-6.0375, 53.117],
            [-6.0312, 53.0708],
            [-6.0451, 53.0333],
            [-6.044, 52.995],
            [-6.0165, 52.9746],
            [-5.9978, 52.9656],
            [-6.0123, 52.9533],
            [-6.019, 52.9278],
            [-6.0353, 52.918],
            [-6.037, 52.9009],
            [-6.0547, 52.886],
            [-6.0609, 52.8676],
            [-6.0549, 52.8594],
            [-6.097, 52.8432],
            [-6.1133, 52.8272],
            [-6.1179, 52.8165],
            [-6.1354, 52.8022],
            [-6.143, 52.7827],
            [-6.1421, 52.7647],
            [-6.1342, 52.76],
            [-6.1435, 52.745],
            [-6.1375, 52.7373],
            [-6.1546, 52.7386],
            [-6.1744, 52.7559],
            [-6.1674, 52.7677],
            [-6.1948, 52.7701],
            [-6.2111, 52.7785],
            [-6.239, 52.7787],
            [-6.259, 52.7959],
            [-6.2924, 52.7832],
            [-6.3163, 52.7976],
            [-6.371, 52.7947],
            [-6.3959, 52.7882],
            [-6.4114, 52.7783],
            [-6.4145, 52.757],
            [-6.4299, 52.756],
            [-6.4313, 52.7358],
            [-6.461, 52.7192],
            [-6.4934, 52.6929],
            [-6.5353, 52.6843],
            [-6.5681, 52.6856],
            [-6.569, 52.7017],
            [-6.595, 52.7037],
            [-6.6122, 52.7131],
            [-6.6286, 52.731],
            [-6.6515, 52.7472],
            [-6.6429, 52.7548],
            [-6.6531, 52.769],
            [-6.6768, 52.7821],
            [-6.6945, 52.7754],
            [-6.6976, 52.8062],
            [-6.6651, 52.8207],
            [-6.6599, 52.8362],
            [-6.6236, 52.8227],
            [-6.6136, 52.8091],
            [-6.6018, 52.811],
            [-6.5694, 52.804],
            [-6.5628, 52.8163],
            [-6.5339, 52.8228],
            [-6.5218, 52.8448],
            [-6.5261, 52.8666],
            [-6.5069, 52.8691],
            [-6.5062, 52.8808],
            [-6.5324, 52.8911],
            [-6.5475, 52.877],
            [-6.5654, 52.8828],
            [-6.5842, 52.8807],
            [-6.5868, 52.8967],
            [-6.6697, 52.9101],
            [-6.6843, 52.9047],
            [-6.7104, 52.9185],
            [-6.7362, 52.916],
            [-6.7313, 52.9285],
            [-6.7367, 52.9438],
            [-6.7852, 52.9768],
            [-6.7936, 52.9926],
            [-6.7879, 53.0009],
            [-6.7664, 53.0102],
            [-6.7765, 53.0209],
            [-6.756, 53.0257],
            [-6.7293, 53.0516],
            [-6.7311, 53.0666],
            [-6.7178, 53.0722],
            [-6.682, 53.0785],
            [-6.6597, 53.09],
            [-6.6287, 53.0939],
            [-6.6242, 53.1057],
            [-6.6085, 53.1061],
            [-6.5731, 53.1344],
            [-6.5718, 53.1511],
            [-6.5387, 53.1675],
            [-6.5572, 53.1747],
            [-6.5555, 53.1833],
            [-6.5373, 53.1904],
            [-6.5196, 53.1878],
            [-6.4978, 53.2061],
            [-6.507, 53.2154],
            [-6.4916, 53.2213],
            [-6.4732, 53.2353],
          ],
        ],
      },
      properties: {name: 'Wicklow', id: 'IE-WW', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-WW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.6122, 52.7131],
            [-6.595, 52.7037],
            [-6.569, 52.7017],
            [-6.5681, 52.6856],
            [-6.5353, 52.6843],
            [-6.4934, 52.6929],
            [-6.461, 52.7192],
            [-6.4313, 52.7358],
            [-6.4299, 52.756],
            [-6.4145, 52.757],
            [-6.4114, 52.7783],
            [-6.3959, 52.7882],
            [-6.371, 52.7947],
            [-6.3163, 52.7976],
            [-6.2924, 52.7832],
            [-6.259, 52.7959],
            [-6.239, 52.7787],
            [-6.2111, 52.7785],
            [-6.1948, 52.7701],
            [-6.1674, 52.7677],
            [-6.1744, 52.7559],
            [-6.1546, 52.7386],
            [-6.1375, 52.7373],
            [-6.1558, 52.717],
            [-6.1838, 52.7013],
            [-6.2166, 52.657],
            [-6.2254, 52.6246],
            [-6.2079, 52.604],
            [-6.2089, 52.5893],
            [-6.1935, 52.562],
            [-6.2132, 52.5333],
            [-6.2287, 52.5218],
            [-6.2887, 52.4676],
            [-6.3191, 52.4348],
            [-6.3491, 52.4138],
            [-6.3609, 52.3935],
            [-6.3619, 52.364],
            [-6.3529, 52.3486],
            [-6.3822, 52.3576],
            [-6.4332, 52.3584],
            [-6.4603, 52.3433],
            [-6.4446, 52.3294],
            [-6.4441, 52.3095],
            [-6.4273, 52.3087],
            [-6.3976, 52.2902],
            [-6.3696, 52.2586],
            [-6.3407, 52.255],
            [-6.3133, 52.2434],
            [-6.3255, 52.2268],
            [-6.3418, 52.2161],
            [-6.3498, 52.2018],
            [-6.3447, 52.1913],
            [-6.3603, 52.1759],
            [-6.3861, 52.1791],
            [-6.4163, 52.1889],
            [-6.4621, 52.1925],
            [-6.4937, 52.1916],
            [-6.5481, 52.1844],
            [-6.5581, 52.1788],
            [-6.5919, 52.1757],
            [-6.6073, 52.1881],
            [-6.6443, 52.2044],
            [-6.678, 52.2153],
            [-6.706, 52.2204],
            [-6.7395, 52.2134],
            [-6.7547, 52.2152],
            [-6.7673, 52.208],
            [-6.7858, 52.2069],
            [-6.8061, 52.2202],
            [-6.752, 52.2501],
            [-6.7937, 52.2422],
            [-6.8281, 52.2232],
            [-6.817, 52.2103],
            [-6.8286, 52.2],
            [-6.8196, 52.1936],
            [-6.855, 52.1684],
            [-6.8777, 52.1638],
            [-6.8999, 52.1488],
            [-6.9164, 52.1274],
            [-6.9307, 52.1332],
            [-6.9103, 52.1527],
            [-6.8987, 52.1577],
            [-6.8968, 52.1762],
            [-6.91, 52.1933],
            [-6.9018, 52.2015],
            [-6.9204, 52.2195],
            [-6.9527, 52.2419],
            [-6.9683, 52.2436],
            [-6.9809, 52.2491],
            [-6.9887, 52.2612],
            [-6.9819, 52.2681],
            [-6.996, 52.2766],
            [-7.0142, 52.297],
            [-7.0008, 52.3148],
            [-6.9869, 52.3209],
            [-6.9945, 52.3502],
            [-6.9847, 52.3695],
            [-6.964, 52.3699],
            [-6.963, 52.3811],
            [-6.9396, 52.405],
            [-6.9472, 52.4275],
            [-6.9266, 52.4221],
            [-6.9155, 52.4298],
            [-6.926, 52.4447],
            [-6.9155, 52.465],
            [-6.8935, 52.4649],
            [-6.866, 52.4767],
            [-6.8542, 52.4966],
            [-6.8318, 52.5194],
            [-6.8356, 52.5317],
            [-6.8043, 52.5521],
            [-6.8034, 52.5649],
            [-6.7878, 52.5662],
            [-6.7785, 52.5806],
            [-6.7809, 52.5986],
            [-6.7742, 52.6235],
            [-6.7489, 52.6394],
            [-6.7268, 52.6435],
            [-6.6831, 52.6366],
            [-6.6615, 52.6488],
            [-6.6514, 52.6629],
            [-6.6724, 52.6651],
            [-6.6707, 52.6803],
            [-6.6437, 52.6889],
            [-6.6297, 52.7049],
            [-6.6122, 52.7131],
          ],
        ],
      },
      properties: {name: 'Wexford', id: 'IE-WX', CNTRY: 'Ireland', TYPE: 'County'},
      id: 'IE-WX',
    },
  ],
}
export default map
