import React, {useEffect, useRef, useState, MouseEvent, useCallback} from 'react'
import {ContentBlock, ContentState, Editor, EditorState, RichUtils} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'
import {stateFromHTML} from 'draft-js-import-html'
import './RichEditor.scss'

export function convertToPlain(html: string) {
  var tempDivElement = document.createElement('div')
  tempDivElement.innerHTML = html
  return tempDivElement.textContent || tempDivElement.innerText || ''
}

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontSize: 16,
    padding: 2,
  },
}

function getBlockStyle(block: ContentBlock) {
  if (block.getType() === 'blockquote') return 'RichEditor-blockquote' as string
  else return ''
}

const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one'},
  {label: 'H2', style: 'header-two'},
  {label: 'H3', style: 'header-three'},
  {label: 'H4', style: 'header-four'},
  {label: 'H5', style: 'header-five'},
  {label: 'H6', style: 'header-six'},
  {label: 'Blockquote', style: 'blockquote'},
  {label: 'UL', style: 'unordered-list-item'},
  {label: 'OL', style: 'ordered-list-item'},
  {label: 'Code Block', style: 'code-block'},
]

var INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD'},
  {label: 'Italic', style: 'ITALIC'},
  {label: 'Underline', style: 'UNDERLINE'},
  {label: 'Monospace', style: 'CODE'},
]

interface StyleControled {
  editorState: EditorState
  onToggle: (data: any) => void
}

const BlockStyleControls: React.FC<StyleControled> = ({editorState, onToggle}) => {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()
  return (
    <div className='RichEditor-controls'>
      {BLOCK_TYPES.map((item, index) => (
        <StyleButton
          key={index}
          active={item.style === blockType}
          label={item.label}
          onToggle={onToggle}
          style={item.style}
        />
      ))}
    </div>
  )
}

const InlineStyleControls: React.FC<StyleControled> = ({editorState, onToggle}) => {
  var currentStyle = editorState.getCurrentInlineStyle()
  return (
    <div className='RichEditor-controls'>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

interface StyleButtonProps {
  active: boolean
  label: string
  onToggle: (data: any) => void
  style: string
}

const StyleButton: React.FC<StyleButtonProps> = ({active, label, onToggle, style}) => {
  const [className, setClassName] = useState<string>('RichEditor-styleButton')
  const onToggleBtn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onToggle(style)
  }

  useEffect(() => {
    if (active) {
      setClassName((prev) => (prev += ' RichEditor-activeButton'))
    } else {
      setClassName('RichEditor-styleButton')
    }
  }, [active])

  return (
    <span className={className} onMouseDown={onToggleBtn}>
      {label}
    </span>
  )
}

interface RichEditorProps {
  editorState: EditorState
  onChange: (title: string, data: any) => void
  description?: string
}

const RichEditor: React.FC<RichEditorProps> = ({editorState, onChange, description}) => {
  const [newEditorState, setNewEditorState] = useState<EditorState>(editorState)
  const editor = useRef<any>()

  const onChangeEditor = useCallback(
    (editorState: EditorState) => {
      setNewEditorState(editorState)
      const contentState: ContentState = editorState.getCurrentContent()
      const html: string = '<div>' + stateToHTML(contentState) + '</div>'
      onChange('description', html)
    },
    [onChange]
  )

  useEffect(() => {
    if (description) {
      const contentState: ContentState = stateFromHTML(description)
      const newEditor: EditorState = EditorState.createWithContent(contentState)
      onChangeEditor(newEditor)
    }
  }, [description, onChangeEditor])

  function focusEditor() {
    editor.current.focus()
  }

  const handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(newEditorState, command)
    if (newState) {
      onChangeEditor(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const toggleBlockType = (blockType: any) => {
    onChangeEditor(RichUtils.toggleBlockType(newEditorState, blockType))
  }

  const toggleInlineStyle = (inlineStyle: any) => {
    onChangeEditor(RichUtils.toggleInlineStyle(newEditorState, inlineStyle))
  }

  return (
    <div className='RichEditor-root'>
      <BlockStyleControls editorState={newEditorState} onToggle={toggleBlockType} />
      <InlineStyleControls editorState={newEditorState} onToggle={toggleInlineStyle} />
      <div className='RichEditor-editor' onClick={focusEditor}>
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={newEditorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onChangeEditor}
          ref={editor}
          spellCheck={true}
        />
      </div>
    </div>
  )
}

export default RichEditor
