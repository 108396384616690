import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectors } from "../../../redux/ReportsRedux";
import { IReportDetails } from "../../../models/Reports";
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { numSpaceFormater } from "../../../../../helpers/formater";
import ChartDonutWidget from "../../Widgets/ChartDonutWidget";

export interface ICircleChartBingAds {
   costChart: number[];
    conversionValues: number[];
    sectorName: string[];
    clicks: number[];
    cost: number[];
    conversions: number[];
    impressions: number[];
}

const BingDevice: React.FC = () => {
    const intl = useIntl();
    const reportData = useSelector(selectors.getReportData) as IReportDetails;

    const [circleChart, setCircleChart] = useState<ICircleChartBingAds | undefined>();

    useEffect(() => {
        if (reportData.details.bingAdsDevice) {
            let chartData: ICircleChartBingAds = {
                costChart: [],
                conversionValues: [],
                sectorName: [],
                clicks: [],
                cost: [],
                conversions: [],
                impressions: [],
            };

            reportData.details.bingAdsDevice.map((item) => {
                chartData.costChart.push(Number(item.cost));
                chartData.conversionValues.push(Number(item.conversion_value));
                chartData.sectorName.push(item.device_type);
                chartData.clicks.push(Number(item.clicks));
                chartData.cost.push(Number(item.cost));
                chartData.conversions.push(Number(item.conversions));
                chartData.impressions.push(Number(item.impressions));
            });

            setCircleChart(chartData);
        }
    }, [reportData]);

    return (
        <>
            {reportData && reportData.details.bingAdsDevice && reportData.details.bingAdsDevice.length > 0 ? (
                <div className='border card-body px-5 py-5 mb-5'>
                    <div className='row'>
                        <div className='pb-5'>
                            <h3 className='fw-boldest text-sempai-green text-uppercase'>
                                {intl.formatMessage({ id: 'DEVICES' })}
                            </h3>
                        </div>
                    </div>
                    <div
                        className='device-data d-flex justify-content-between flex-column flex-lg-row'
                        data-testid='devices-data'
                    >
                        {reportData.details.bingAdsDevice.map((item) => (
                            <div className='d-flex justify-content-xl-start justify-content-center'>
                                <div className='w-100 d-flex justify-content-xl-center justify-content-around'>
                                    <div className='d-flex justify-content-center align-items-center pe-10'>
                                        {item.device_type == 'Computer' && (
                                            <KTSVG
                                                path='/media/sempai-images/icons/Icon-feather-monitor.svg'
                                                className='svg-icon-5x svg-icon-sempai-green'
                                                svgClassName='mh-100px'
                                            />
                                        )}
                                        {item.device_type == 'Smartphone' && (
                                            <KTSVG
                                                path='/media/sempai-images/icons/Icon-feather-phone.svg'
                                                className='svg-icon-5x text-sempai-green svg-icon-white'
                                                svgClassName='mh-100px'
                                            />
                                        )}
                                        {item.device_type == 'Tablet' && (
                                            <KTSVG
                                                path='/media/sempai-images/icons/Icon-feather-tablet.svg'
                                                className='svg-icon-5x text-sempai-green svg-icon-white'
                                                svgClassName='mh-100px'
                                            />
                                        )}
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='mb-1'>
                                            <span className='text-dark fs-7 fw-bold pe-3'>
                                                {intl.formatMessage({ id: 'SESSIONS' })}:
                                            </span>
                                            <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.clicks)}</span>
                                        </div>
                                        <div className=''>
                                            <span className='text-dark fs-7 fw-bold pe-3'>
                                                {intl.formatMessage({ id: 'TRANSACTIONS' })}:
                                            </span>
                                            <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.conversions)}</span>
                                        </div>
                                        <div className=''>
                                            <span className='text-dark fs-7 fw-bold pe-3'>
                                                {intl.formatMessage({ id: 'USERS' })}:
                                            </span>
                                            <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.cost)}</span>
                                        </div>
                                        <div className=''>
                                            <span className='text-dark fs-7 fw-bold pe-3'>
                                                {intl.formatMessage({ id: 'CONVERSIONS' })}:
                                            </span>
                                            <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.conversion_value)}</span>
                                        </div>
                                        <div className=''>
                                            <span className='text-dark fs-7 fw-bold pe-3'>
                                                {intl.formatMessage({ id: 'IMPRESSIONS' })}:
                                            </span>
                                            <span className='text-dark fs-3 fw-boldest'>{numSpaceFormater(item.impressions)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className='row g-3 device-charts d-flex'>
                {circleChart && circleChart.costChart && (
                    <div className='col-3' data-testid='revenue-donut-chart'>
                        <div className='border'>
                            <div className='pb-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                                    {intl.formatMessage({ id: 'COST' })}
                                </h3>
                            </div>
                            <ChartDonutWidget
                                keytabchart={2}
                                className='card-xxl-stretch-50 mb-5'
                                chartHeight='350px'
                                sectorsValue={circleChart.costChart}
                                sectorsName={circleChart.sectorName}
                            />
                        </div>
                    </div>
                )}
                {circleChart && circleChart.clicks && (
                    <div className='col-3' data-testid='clicks-donut-chart'>
                        <div className='border'>
                            <div className='pb-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                                    {intl.formatMessage({ id: 'CLICKS' })}
                                </h3>
                            </div>
                            <ChartDonutWidget
                                keytabchart={6}
                                className='card-xxl-stretch-50 mb-5'
                                chartHeight='350px'
                                sectorsValue={circleChart.clicks}
                                sectorsName={circleChart.sectorName}
                            />
                        </div>
                    </div>
                )}
                {circleChart && circleChart.conversions && (
                    <div className='col-3' data-testid='conversions-donut-chart'>
                        <div className='border'>
                            <div className='pb-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                                    {intl.formatMessage({ id: 'CONVERSIONS' })}
                                </h3>
                            </div>
                            <ChartDonutWidget
                                keytabchart={8}
                                className='card-xxl-stretch-50 mb-5'
                                chartHeight='350px'
                                sectorsValue={circleChart.conversions}
                                sectorsName={circleChart.sectorName}
                            />
                        </div>
                    </div>
                )}
                {circleChart && circleChart.impressions && (
                    <div className='col-3' data-testid='impressions-donut-chart'>
                        <div className='border'>
                            <div className='pb-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                                    {intl.formatMessage({ id: 'IMPRESSIONS' })}
                                </h3>
                            </div>
                            <ChartDonutWidget
                                keytabchart={10}
                                className='card-xxl-stretch-50 mb-5'
                                chartHeight='350px'
                                sectorsValue={circleChart.impressions}
                                sectorsName={circleChart.sectorName}
                            />
                        </div>
                    </div>
                )}
                {circleChart && circleChart.conversionValues && (
                    <div className='col-3' data-testid='revenue-donut-chart'>
                        <div className='border'>
                            <div className='pb-5'>
                                <h3 className='fw-boldest text-sempai-green text-uppercase ms-5 mt-5 fs-4'>
                                    {intl.formatMessage({ id: 'CONVERSION_VALUE' })}
                                </h3>
                            </div>
                            <ChartDonutWidget
                                keytabchart={3}
                                className='card-xxl-stretch-50 mb-5'
                                chartHeight='350px'
                                sectorsValue={circleChart.conversionValues}
                                sectorsName={circleChart.sectorName}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className='row g-3 device-charts d-flex'>
            </div>

            <div className='row pt-5'></div>
            <div className='page-slice'></div>
        </>
    );
};

export default BingDevice;
