var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [8.4052, 56.6759],
              [8.4041, 56.6704],
              [8.3554, 56.6773],
              [8.3476, 56.6928],
              [8.3166, 56.7155],
              [8.2988, 56.758],
              [8.2562, 56.7777],
              [8.2471, 56.7049],
              [8.2241, 56.7319],
              [8.2277, 56.781],
              [8.2369, 56.8041],
              [8.2719, 56.8583],
              [8.3613, 56.9585],
              [8.41, 56.9851],
              [8.4758, 57.0442],
              [8.5023, 57.0468],
              [8.531, 57.0652],
              [8.5909, 57.1198],
              [8.6269, 57.1234],
              [8.7401, 57.0994],
              [8.8231, 57.1085],
              [8.8969, 57.1273],
              [8.9558, 57.152],
              [8.9809, 57.1578],
              [9.03, 57.1587],
              [9.1031, 57.1432],
              [9.1865, 57.1379],
              [9.2801, 57.1451],
              [9.3208, 57.1545],
              [9.3672, 57.1564],
              [9.3993, 57.1635],
              [9.5256, 57.2173],
              [9.5395, 57.2256],
              [9.6391, 57.3065],
              [9.704, 57.3717],
              [9.7164, 57.3797],
              [9.7946, 57.4765],
              [9.8636, 57.5157],
              [9.8873, 57.5336],
              [9.9348, 57.583],
              [9.977, 57.594],
              [10.0288, 57.5898],
              [10.1077, 57.5886],
              [10.1597, 57.5933],
              [10.245, 57.6111],
              [10.3232, 57.6363],
              [10.522, 57.7298],
              [10.5761, 57.7477],
              [10.6257, 57.7509],
              [10.6473, 57.7441],
              [10.551, 57.7033],
              [10.4888, 57.6617],
              [10.4432, 57.6196],
              [10.4249, 57.5782],
              [10.4473, 57.5344],
              [10.504, 57.4953],
              [10.5351, 57.4691],
              [10.5483, 57.4439],
              [10.5262, 57.428],
              [10.515, 57.3825],
              [10.5273, 57.324],
              [10.5456, 57.273],
              [10.5472, 57.2269],
              [10.4934, 57.2049],
              [10.4423, 57.1701],
              [10.402, 57.1201],
              [10.3762, 57.0566],
              [10.3526, 57.0113],
              [10.3307, 56.9887],
              [10.3001, 56.994],
              [10.2581, 56.9863],
              [10.2223, 56.9896],
              [10.1093, 57.0469],
              [10.0782, 57.0553],
              [10.0175, 57.0872],
              [9.9808, 57.0846],
              [9.9355, 57.0536],
              [9.8736, 57.0699],
              [9.8376, 57.0912],
              [9.7874, 57.1076],
              [9.7449, 57.0663],
              [9.7022, 57.0625],
              [9.6662, 57.0745],
              [9.6665, 57.0951],
              [9.6114, 57.075],
              [9.6243, 57.0615],
              [9.6117, 57.0496],
              [9.5755, 57.0409],
              [9.4882, 57.0459],
              [9.4293, 57.037],
              [9.3757, 57.0237],
              [9.3082, 57.0122],
              [9.2567, 56.9922],
              [9.2223, 56.9951],
              [9.133, 57.0396],
              [9.0615, 57.0229],
              [8.9843, 57.0175],
              [8.9661, 57.0037],
              [8.9263, 57.0009],
              [8.912, 56.9818],
              [8.8893, 56.9914],
              [8.8521, 56.9958],
              [8.8284, 56.9866],
              [8.8469, 56.973],
              [8.7494, 56.9458],
              [8.6731, 56.9527],
              [8.6249, 56.8883],
              [8.6328, 56.8645],
              [8.6098, 56.8418],
              [8.5745, 56.8186],
              [8.534, 56.8174],
              [8.4871, 56.7959],
              [8.4687, 56.7812],
              [8.4999, 56.7547],
              [8.4784, 56.7227],
              [8.5155, 56.7143],
              [8.528, 56.7006],
              [8.4902, 56.6839],
              [8.4533, 56.6973],
              [8.4052, 56.6759],
            ],
          ],
          [
            [
              [10.1942, 56.6845],
              [10.1568, 56.6645],
              [10.1585, 56.6521],
              [10.1233, 56.6463],
              [10.1159, 56.6237],
              [10.0874, 56.6191],
              [10.042, 56.6017],
              [9.9788, 56.591],
              [9.9489, 56.581],
              [9.8824, 56.5698],
              [9.879, 56.5561],
              [9.8328, 56.5504],
              [9.7958, 56.5616],
              [9.7507, 56.5554],
              [9.713, 56.5851],
              [9.6773, 56.5901],
              [9.6436, 56.5799],
              [9.6446, 56.6084],
              [9.6762, 56.6143],
              [9.6707, 56.6284],
              [9.5973, 56.6289],
              [9.5642, 56.6351],
              [9.5682, 56.6485],
              [9.5253, 56.6806],
              [9.4771, 56.6746],
              [9.4554, 56.6369],
              [9.4306, 56.6394],
              [9.3653, 56.6626],
              [9.354, 56.6714],
              [9.3195, 56.6722],
              [9.2935, 56.6981],
              [9.2354, 56.6887],
              [9.1755, 56.6651],
              [9.1598, 56.6827],
              [9.2194, 56.7143],
              [9.2434, 56.7455],
              [9.2318, 56.7612],
              [9.1843, 56.7668],
              [9.1878, 56.787],
              [9.1741, 56.8051],
              [9.2082, 56.8421],
              [9.1995, 56.8738],
              [9.1675, 56.8858],
              [9.178, 56.9174],
              [9.2284, 56.9498],
              [9.2423, 56.9669],
              [9.2772, 56.9729],
              [9.2879, 56.9928],
              [9.3536, 57.0082],
              [9.4276, 57.0173],
              [9.5102, 57.0131],
              [9.5833, 56.988],
              [9.5378, 56.9558],
              [9.552, 56.9366],
              [9.5747, 56.9579],
              [9.6196, 56.9696],
              [9.6709, 57.011],
              [9.679, 57.0344],
              [9.6982, 57.0409],
              [9.7782, 57.0522],
              [9.8433, 57.0432],
              [9.8685, 57.0542],
              [9.9081, 57.0573],
              [9.9299, 57.049],
              [9.9588, 57.0528],
              [9.9976, 57.0783],
              [10.0246, 57.0764],
              [10.0373, 57.0608],
              [10.1223, 57.0207],
              [10.1372, 57.0089],
              [10.1913, 56.9873],
              [10.2313, 56.979],
              [10.301, 56.9828],
              [10.2988, 56.9657],
              [10.272, 56.9379],
              [10.2618, 56.8936],
              [10.2662, 56.885],
              [10.2762, 56.8034],
              [10.2976, 56.7546],
              [10.3332, 56.7235],
              [10.3245, 56.7042],
              [10.3, 56.711],
              [10.2531, 56.7128],
              [10.227, 56.72],
              [10.1841, 56.703],
              [10.1434, 56.7206],
              [10.105, 56.71],
              [10.0752, 56.6937],
              [10.0612, 56.702],
              [10.0247, 56.6945],
              [10.0151, 56.6813],
              [9.9858, 56.6788],
              [9.9598, 56.6499],
              [10.0322, 56.6779],
              [10.0505, 56.6907],
              [10.087, 56.6829],
              [10.1137, 56.7078],
              [10.1642, 56.7062],
              [10.1942, 56.6845],
            ],
          ],
          [
            [
              [8.9107, 56.9558],
              [8.9228, 56.9183],
              [8.8866, 56.8812],
              [8.844, 56.8855],
              [8.8288, 56.87],
              [8.8424, 56.8335],
              [8.8746, 56.8066],
              [8.8362, 56.7622],
              [8.8309, 56.7392],
              [8.7992, 56.7268],
              [8.7681, 56.7039],
              [8.7757, 56.6941],
              [8.7315, 56.6821],
              [8.7041, 56.6823],
              [8.6426, 56.6685],
              [8.6351, 56.6927],
              [8.612, 56.7062],
              [8.5627, 56.7027],
              [8.5403, 56.7403],
              [8.5586, 56.7584],
              [8.5477, 56.7828],
              [8.5869, 56.7961],
              [8.6206, 56.7916],
              [8.6652, 56.8055],
              [8.6624, 56.8169],
              [8.6095, 56.822],
              [8.6484, 56.8547],
              [8.6453, 56.8792],
              [8.7568, 56.8979],
              [8.8171, 56.9036],
              [8.8304, 56.9308],
              [8.8679, 56.9502],
              [8.9107, 56.9558],
            ],
          ],
          [
            [
              [11.2001, 57.3203],
              [11.1925, 57.2985],
              [11.1167, 57.2984],
              [11.0786, 57.2882],
              [11.094, 57.2788],
              [11.0861, 57.2539],
              [11.0597, 57.2344],
              [11.0052, 57.2178],
              [10.9773, 57.216],
              [10.968, 57.2335],
              [10.9422, 57.2447],
              [10.9115, 57.2439],
              [10.8788, 57.2528],
              [10.8727, 57.2649],
              [10.9059, 57.2794],
              [10.9216, 57.294],
              [10.9754, 57.3063],
              [11.0107, 57.3064],
              [11.0301, 57.3186],
              [11.1195, 57.3198],
              [11.1473, 57.3281],
              [11.2001, 57.3203],
            ],
          ],
        ],
      },
      properties: {
        name: 'Nordjylland',
        id: 'DK-81',
        NAME_ENG: 'North Denmark Region',
        TYPE: 'Region',
        CNTRY: 'Denmark',
      },
      id: 'DK-81',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [8.6559, 56.5818],
              [8.6211, 56.5419],
              [8.6118, 56.5539],
              [8.639, 56.581],
              [8.6559, 56.5818],
            ],
          ],
          [
            [
              [10.3285, 55.7697],
              [10.3226, 55.7475],
              [10.2474, 55.7469],
              [10.2976, 55.7699],
              [10.3285, 55.7697],
            ],
          ],
          [
            [
              [9.0382, 56.8467],
              [9.0671, 56.842],
              [9.0645, 56.8197],
              [9.023, 56.8112],
              [9.01, 56.796],
              [8.9788, 56.8113],
              [8.9561, 56.8375],
              [9.0382, 56.8467],
            ],
          ],
          [
            [
              [8.4041, 56.6704],
              [8.4052, 56.6759],
              [8.4256, 56.6706],
              [8.4695, 56.685],
              [8.5068, 56.667],
              [8.5568, 56.6883],
              [8.5812, 56.6747],
              [8.5699, 56.6547],
              [8.5844, 56.6419],
              [8.6118, 56.6636],
              [8.6379, 56.6536],
              [8.6311, 56.6288],
              [8.5959, 56.6367],
              [8.5876, 56.6009],
              [8.5498, 56.6002],
              [8.515, 56.5893],
              [8.4923, 56.6275],
              [8.4263, 56.6643],
              [8.4041, 56.6704],
            ],
          ],
          [
            [
              [10.5825, 55.9458],
              [10.6056, 55.929],
              [10.6521, 55.9165],
              [10.6098, 55.9076],
              [10.6179, 55.8919],
              [10.6592, 55.8601],
              [10.6415, 55.8377],
              [10.643, 55.7972],
              [10.6211, 55.7647],
              [10.5517, 55.7692],
              [10.5282, 55.7948],
              [10.5299, 55.8517],
              [10.5487, 55.8639],
              [10.5768, 55.8652],
              [10.5934, 55.8816],
              [10.6005, 55.9068],
              [10.5788, 55.9304],
              [10.536, 55.9366],
              [10.5224, 55.9792],
              [10.5621, 56.0027],
              [10.5716, 55.9606],
              [10.5825, 55.9458],
            ],
          ],
          [
            [
              [8.1898, 55.8174],
              [8.171, 55.8155],
              [8.1541, 55.8761],
              [8.1395, 55.9561],
              [8.1715, 55.9499],
              [8.1704, 55.9018],
              [8.1967, 55.864],
              [8.1743, 55.8446],
              [8.1759, 55.8254],
              [8.1898, 55.8174],
            ],
          ],
          [
            [
              [9.3195, 56.6722],
              [9.354, 56.6714],
              [9.3653, 56.6626],
              [9.4306, 56.6394],
              [9.4554, 56.6369],
              [9.4771, 56.6746],
              [9.5253, 56.6806],
              [9.5682, 56.6485],
              [9.5642, 56.6351],
              [9.5973, 56.6289],
              [9.6707, 56.6284],
              [9.6762, 56.6143],
              [9.6446, 56.6084],
              [9.6436, 56.5799],
              [9.6773, 56.5901],
              [9.713, 56.5851],
              [9.7507, 56.5554],
              [9.7958, 56.5616],
              [9.8328, 56.5504],
              [9.879, 56.5561],
              [9.8824, 56.5698],
              [9.9489, 56.581],
              [9.9788, 56.591],
              [10.042, 56.6017],
              [10.0874, 56.6191],
              [10.1159, 56.6237],
              [10.1233, 56.6463],
              [10.1585, 56.6521],
              [10.1568, 56.6645],
              [10.1942, 56.6845],
              [10.2516, 56.6982],
              [10.2842, 56.6934],
              [10.3385, 56.7015],
              [10.353, 56.6928],
              [10.363, 56.6617],
              [10.3591, 56.639],
              [10.3434, 56.6231],
              [10.2981, 56.6092],
              [10.2824, 56.5914],
              [10.2464, 56.5781],
              [10.2149, 56.5431],
              [10.2361, 56.5416],
              [10.2731, 56.5765],
              [10.3026, 56.5914],
              [10.3024, 56.6073],
              [10.3431, 56.5957],
              [10.3465, 56.5775],
              [10.378, 56.5485],
              [10.4274, 56.5252],
              [10.5076, 56.513],
              [10.5601, 56.5145],
              [10.6713, 56.5297],
              [10.7962, 56.5374],
              [10.837, 56.5306],
              [10.8617, 56.5176],
              [10.8775, 56.487],
              [10.9502, 56.4563],
              [10.9552, 56.4368],
              [10.9353, 56.4325],
              [10.9148, 56.3788],
              [10.9145, 56.3437],
              [10.8635, 56.2999],
              [10.8319, 56.2854],
              [10.8113, 56.2571],
              [10.7916, 56.2441],
              [10.7513, 56.2315],
              [10.7343, 56.2066],
              [10.74, 56.1801],
              [10.7143, 56.142],
              [10.6892, 56.1413],
              [10.6778, 56.1552],
              [10.6377, 56.1792],
              [10.6693, 56.1898],
              [10.6742, 56.2136],
              [10.6554, 56.2258],
              [10.6147, 56.2282],
              [10.586, 56.2197],
              [10.577, 56.2028],
              [10.543, 56.1816],
              [10.5333, 56.1641],
              [10.5522, 56.1477],
              [10.5589, 56.1243],
              [10.5452, 56.102],
              [10.5126, 56.0946],
              [10.4811, 56.1397],
              [10.5166, 56.145],
              [10.5284, 56.168],
              [10.492, 56.1706],
              [10.469, 56.1802],
              [10.4375, 56.1775],
              [10.4193, 56.1623],
              [10.3779, 56.1687],
              [10.3597, 56.199],
              [10.4064, 56.2075],
              [10.4659, 56.1993],
              [10.4807, 56.2072],
              [10.458, 56.23],
              [10.5052, 56.2748],
              [10.4458, 56.2912],
              [10.3902, 56.2926],
              [10.3892, 56.2782],
              [10.3457, 56.2628],
              [10.3371, 56.2347],
              [10.3058, 56.2256],
              [10.2807, 56.1999],
              [10.2424, 56.1867],
              [10.2193, 56.1416],
              [10.2094, 56.1357],
              [10.2483, 56.0934],
              [10.2711, 56.0481],
              [10.2752, 56.0234],
              [10.2536, 55.9929],
              [10.2663, 55.9579],
              [10.2577, 55.9109],
              [10.238, 55.9077],
              [10.2077, 55.8868],
              [10.2048, 55.8592],
              [10.1928, 55.8373],
              [10.1594, 55.8463],
              [10.1392, 55.8618],
              [10.1158, 55.8641],
              [10.0814, 55.849],
              [10.0371, 55.8517],
              [10.0587, 55.8696],
              [10.1139, 55.8642],
              [10.1103, 55.8825],
              [10.0717, 55.8841],
              [10.0165, 55.8754],
              [9.9685, 55.8792],
              [9.9667, 55.8589],
              [9.8748, 55.8568],
              [9.8922, 55.8424],
              [9.9998, 55.8252],
              [10.0503, 55.8235],
              [10.066, 55.8081],
              [10.0198, 55.7805],
              [10.0252, 55.7586],
              [10.0771, 55.7528],
              [10.0362, 55.7345],
              [10.0012, 55.7279],
              [10.0191, 55.7156],
              [10.0125, 55.7015],
              [9.9754, 55.6953],
              [9.9212, 55.693],
              [9.8819, 55.6867],
              [9.8189, 55.669],
              [9.8082, 55.678],
              [9.7463, 55.6805],
              [9.7154, 55.7027],
              [9.6719, 55.7122],
              [9.6815, 55.7495],
              [9.6519, 55.7596],
              [9.6021, 55.752],
              [9.5795, 55.7748],
              [9.5393, 55.7733],
              [9.5202, 55.7857],
              [9.5038, 55.8271],
              [9.4473, 55.8347],
              [9.4602, 55.8619],
              [9.3865, 55.9191],
              [9.3862, 55.9256],
              [9.3451, 55.9364],
              [9.3452, 55.9361],
              [9.3404, 55.9345],
              [9.3366, 55.9323],
              [9.3036, 55.9369],
              [9.2808, 55.9383],
              [9.2572, 55.9535],
              [9.2194, 55.9547],
              [9.1952, 55.9307],
              [9.1957, 55.9168],
              [9.1499, 55.8835],
              [9.1176, 55.8693],
              [9.0973, 55.8772],
              [9.0709, 55.8444],
              [9.0094, 55.8306],
              [8.9579, 55.8455],
              [8.9656, 55.8849],
              [8.8815, 55.8957],
              [8.8395, 55.8818],
              [8.823, 55.8475],
              [8.7944, 55.8378],
              [8.8267, 55.8194],
              [8.7589, 55.8061],
              [8.7224, 55.8139],
              [8.708, 55.8378],
              [8.6762, 55.8483],
              [8.593, 55.8349],
              [8.5699, 55.8477],
              [8.5323, 55.8412],
              [8.5463, 55.8223],
              [8.5127, 55.8103],
              [8.5139, 55.7876],
              [8.4947, 55.7768],
              [8.4494, 55.7764],
              [8.4407, 55.7881],
              [8.3799, 55.8015],
              [8.3508, 55.8223],
              [8.3218, 55.801],
              [8.2711, 55.7972],
              [8.2237, 55.8179],
              [8.2246, 55.8265],
              [8.1799, 55.8306],
              [8.209, 55.8625],
              [8.2112, 55.8974],
              [8.2604, 55.8663],
              [8.2753, 55.8493],
              [8.327, 55.8692],
              [8.3567, 55.8919],
              [8.3809, 55.9019],
              [8.3759, 55.9457],
              [8.3325, 55.9763],
              [8.3127, 55.9964],
              [8.3021, 56.0385],
              [8.3039, 56.0582],
              [8.2304, 56.0929],
              [8.1392, 56.1104],
              [8.1237, 56.0378],
              [8.1343, 56.0094],
              [8.1141, 55.9996],
              [8.0981, 56.0442],
              [8.1038, 56.1057],
              [8.1283, 56.2105],
              [8.13, 56.2505],
              [8.1168, 56.3714],
              [8.14, 56.3641],
              [8.128, 56.3485],
              [8.1309, 56.328],
              [8.1655, 56.3368],
              [8.2357, 56.3142],
              [8.2517, 56.2879],
              [8.2779, 56.2974],
              [8.2918, 56.3137],
              [8.2614, 56.3247],
              [8.2528, 56.3466],
              [8.1864, 56.3797],
              [8.168, 56.4],
              [8.1645, 56.4355],
              [8.1233, 56.4173],
              [8.1356, 56.3896],
              [8.115, 56.3726],
              [8.125, 56.4735],
              [8.1153, 56.5246],
              [8.1236, 56.5585],
              [8.169, 56.66],
              [8.2031, 56.7022],
              [8.226, 56.6971],
              [8.2302, 56.6828],
              [8.1958, 56.6554],
              [8.1992, 56.634],
              [8.2308, 56.6256],
              [8.2305, 56.6064],
              [8.2713, 56.5978],
              [8.2993, 56.565],
              [8.3285, 56.5779],
              [8.3877, 56.579],
              [8.4878, 56.5525],
              [8.5146, 56.5413],
              [8.5546, 56.5622],
              [8.5763, 56.5545],
              [8.6039, 56.5184],
              [8.5988, 56.4957],
              [8.6223, 56.4743],
              [8.6584, 56.4671],
              [8.7313, 56.4783],
              [8.7436, 56.4999],
              [8.7341, 56.5315],
              [8.7619, 56.5526],
              [8.7518, 56.5651],
              [8.6996, 56.5883],
              [8.6808, 56.6256],
              [8.727, 56.6234],
              [8.7742, 56.6547],
              [8.7843, 56.6745],
              [8.8129, 56.6944],
              [8.8365, 56.687],
              [8.876, 56.7052],
              [8.8248, 56.707],
              [8.8537, 56.7434],
              [8.9265, 56.7877],
              [8.964, 56.8018],
              [9.0319, 56.7844],
              [9.0252, 56.8003],
              [9.0669, 56.8029],
              [9.1128, 56.7858],
              [9.111, 56.7694],
              [9.1314, 56.7404],
              [9.1718, 56.714],
              [9.1207, 56.6779],
              [9.0973, 56.668],
              [9.1001, 56.6473],
              [9.0547, 56.6273],
              [9.0427, 56.5932],
              [9.0578, 56.5566],
              [9.0763, 56.5567],
              [9.0972, 56.5789],
              [9.0953, 56.6029],
              [9.1356, 56.5998],
              [9.1517, 56.6099],
              [9.1104, 56.6203],
              [9.1378, 56.6338],
              [9.1859, 56.621],
              [9.2108, 56.63],
              [9.2846, 56.6221],
              [9.29, 56.6025],
              [9.2616, 56.5918],
              [9.2454, 56.5741],
              [9.2605, 56.5558],
              [9.3069, 56.5252],
              [9.3366, 56.5408],
              [9.3698, 56.5353],
              [9.368, 56.568],
              [9.3385, 56.5578],
              [9.2966, 56.5532],
              [9.2869, 56.5642],
              [9.3119, 56.6343],
              [9.3249, 56.6522],
              [9.3195, 56.6722],
            ],
          ],
          [
            [
              [11.6503, 56.7378],
              [11.5755, 56.6895],
              [11.5393, 56.6893],
              [11.514, 56.7155],
              [11.5329, 56.7263],
              [11.5649, 56.723],
              [11.6503, 56.7378],
            ],
          ],
        ],
      },
      properties: {
        name: 'Midtjylland',
        id: 'DK-82',
        NAME_ENG: 'Central Denmark Region',
        TYPE: 'Region',
        CNTRY: 'Denmark',
      },
      id: 'DK-82',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [9.8406, 55.0496],
              [9.8776, 55.0272],
              [9.9262, 55.019],
              [9.9654, 55.0062],
              [9.9965, 54.9845],
              [10.0441, 54.9316],
              [10.0447, 54.9191],
              [10.0714, 54.877],
              [10.0214, 54.8724],
              [9.9887, 54.8524],
              [9.9424, 54.8537],
              [9.8909, 54.865],
              [9.8677, 54.8771],
              [9.8998, 54.8967],
              [9.9171, 54.884],
              [9.949, 54.893],
              [9.8919, 54.9081],
              [9.8319, 54.8892],
              [9.7961, 54.8976],
              [9.7696, 54.9186],
              [9.7593, 54.9712],
              [9.7933, 54.9671],
              [9.8349, 54.9337],
              [9.8537, 54.9419],
              [9.8326, 54.9569],
              [9.8456, 54.9686],
              [9.8226, 54.9794],
              [9.7847, 54.9827],
              [9.7659, 55.0111],
              [9.7004, 55.0067],
              [9.6776, 55.0149],
              [9.6441, 55.0391],
              [9.6392, 55.0512],
              [9.6995, 55.0775],
              [9.7442, 55.0838],
              [9.8168, 55.0646],
              [9.8406, 55.0496],
            ],
          ],
          [
            [
              [9.7569, 55.2723],
              [9.7589, 55.2496],
              [9.7387, 55.2492],
              [9.7262, 55.2698],
              [9.7569, 55.2723],
            ],
          ],
          [
            [
              [10.7008, 55.056],
              [10.702, 55.0337],
              [10.6679, 55.0304],
              [10.647, 55.049],
              [10.7008, 55.056],
            ],
          ],
          [
            [
              [10.4224, 54.9843],
              [10.4403, 54.9702],
              [10.4205, 54.9625],
              [10.3879, 54.9774],
              [10.4224, 54.9843],
            ],
          ],
          [
            [
              [10.6254, 54.9028],
              [10.6059, 54.8906],
              [10.5911, 54.9147],
              [10.6173, 54.9185],
              [10.6254, 54.9028],
            ],
          ],
          [
            [
              [10.631, 55.0555],
              [10.6567, 55.0339],
              [10.6384, 54.9983],
              [10.6919, 54.9969],
              [10.6886, 54.974],
              [10.6523, 54.9717],
              [10.62, 54.9482],
              [10.5549, 54.9469],
              [10.5503, 54.9622],
              [10.5251, 54.9773],
              [10.515, 55.0083],
              [10.573, 55.0264],
              [10.631, 55.0555],
            ],
          ],
          [
            [
              [10.9556, 55.1463],
              [10.9357, 55.0826],
              [10.8963, 55.0076],
              [10.8948, 54.9947],
              [10.8578, 54.9675],
              [10.8176, 54.9088],
              [10.8147, 54.8857],
              [10.7763, 54.8293],
              [10.7358, 54.7436],
              [10.7166, 54.7275],
              [10.6862, 54.7237],
              [10.6676, 54.74],
              [10.6758, 54.7581],
              [10.66, 54.7797],
              [10.6585, 54.7974],
              [10.6356, 54.8179],
              [10.6634, 54.8516],
              [10.6715, 54.8819],
              [10.7021, 54.8705],
              [10.717, 54.8845],
              [10.7072, 54.8972],
              [10.6752, 54.9103],
              [10.7241, 54.9543],
              [10.7698, 54.9828],
              [10.809, 54.9947],
              [10.8273, 55.0206],
              [10.8466, 55.0345],
              [10.8617, 55.0601],
              [10.8765, 55.1022],
              [10.9016, 55.1386],
              [10.9398, 55.1647],
              [10.9556, 55.1463],
            ],
          ],
          [
            [
              [9.3036, 55.9369],
              [9.3366, 55.9323],
              [9.3404, 55.9345],
              [9.3452, 55.9361],
              [9.3451, 55.9364],
              [9.3862, 55.9256],
              [9.3865, 55.9191],
              [9.4602, 55.8619],
              [9.4473, 55.8347],
              [9.5038, 55.8271],
              [9.5202, 55.7857],
              [9.5393, 55.7733],
              [9.5795, 55.7748],
              [9.6021, 55.752],
              [9.6519, 55.7596],
              [9.6815, 55.7495],
              [9.6719, 55.7122],
              [9.6031, 55.7048],
              [9.5879, 55.69],
              [9.6674, 55.6948],
              [9.6884, 55.6758],
              [9.7379, 55.6585],
              [9.7595, 55.6229],
              [9.8068, 55.6284],
              [9.8476, 55.6164],
              [9.7951, 55.5946],
              [9.7699, 55.5687],
              [9.737, 55.5592],
              [9.7299, 55.5429],
              [9.7422, 55.5241],
              [9.7239, 55.5182],
              [9.6734, 55.5277],
              [9.6404, 55.5091],
              [9.5892, 55.5169],
              [9.5735, 55.4992],
              [9.5148, 55.501],
              [9.5143, 55.4835],
              [9.6258, 55.4987],
              [9.6935, 55.461],
              [9.6536, 55.4366],
              [9.5986, 55.4263],
              [9.5928, 55.4188],
              [9.622, 55.3866],
              [9.6241, 55.3565],
              [9.6468, 55.3419],
              [9.6425, 55.3128],
              [9.6839, 55.2706],
              [9.7124, 55.2623],
              [9.7157, 55.2377],
              [9.6911, 55.2197],
              [9.6935, 55.1949],
              [9.6371, 55.1794],
              [9.6293, 55.1895],
              [9.5896, 55.1934],
              [9.5615, 55.1777],
              [9.4985, 55.1568],
              [9.5051, 55.1355],
              [9.4972, 55.1189],
              [9.5199, 55.1095],
              [9.5505, 55.0753],
              [9.5004, 55.0452],
              [9.4664, 55.0483],
              [9.4421, 55.0407],
              [9.425, 55.0249],
              [9.4537, 55.0163],
              [9.5063, 55.0167],
              [9.5186, 55.0245],
              [9.5732, 55.0344],
              [9.6064, 55.0355],
              [9.6301, 55.0103],
              [9.6532, 55.0061],
              [9.6934, 54.988],
              [9.7377, 54.9875],
              [9.7547, 54.929],
              [9.7778, 54.9035],
              [9.7565, 54.8971],
              [9.71, 54.9035],
              [9.7016, 54.8959],
              [9.74, 54.8813],
              [9.7585, 54.8617],
              [9.7527, 54.8403],
              [9.7272, 54.8351],
              [9.6804, 54.8572],
              [9.6388, 54.8609],
              [9.6567, 54.881],
              [9.622, 54.8956],
              [9.606, 54.9105],
              [9.6703, 54.9191],
              [9.6214, 54.9346],
              [9.6113, 54.9155],
              [9.5741, 54.9007],
              [9.5568, 54.8762],
              [9.5387, 54.8816],
              [9.5094, 54.8714],
              [9.4854, 54.8482],
              [9.4623, 54.8392],
              [9.418, 54.8322],
              [9.3829, 54.8392],
              [9.3741, 54.8212],
              [9.3294, 54.8056],
              [9.2704, 54.8121],
              [9.2501, 54.8096],
              [9.2358, 54.8312],
              [9.2403, 54.85],
              [9.1531, 54.8712],
              [9.1104, 54.874],
              [9.0479, 54.872],
              [9.0144, 54.8866],
              [8.9483, 54.9025],
              [8.8922, 54.9049],
              [8.8596, 54.8967],
              [8.8333, 54.9067],
              [8.7765, 54.8953],
              [8.7279, 54.8928],
              [8.6811, 54.9104],
              [8.6355, 54.9115],
              [8.6579, 54.9632],
              [8.659, 54.9864],
              [8.6317, 55.04],
              [8.6462, 55.0895],
              [8.6592, 55.0992],
              [8.686, 55.1441],
              [8.6518, 55.1484],
              [8.6084, 55.1448],
              [8.5561, 55.1496],
              [8.5592, 55.1223],
              [8.5508, 55.1013],
              [8.5694, 55.0851],
              [8.5055, 55.0584],
              [8.4669, 55.0911],
              [8.4633, 55.1006],
              [8.4761, 55.1435],
              [8.4792, 55.178],
              [8.4936, 55.2016],
              [8.5518, 55.2019],
              [8.5911, 55.1902],
              [8.5721, 55.1721],
              [8.5675, 55.1536],
              [8.6302, 55.148],
              [8.6642, 55.1509],
              [8.6845, 55.1627],
              [8.6513, 55.2678],
              [8.6594, 55.277],
              [8.6429, 55.3084],
              [8.5864, 55.289],
              [8.5697, 55.2678],
              [8.5442, 55.2656],
              [8.5321, 55.2819],
              [8.5475, 55.2935],
              [8.591, 55.2937],
              [8.6424, 55.3114],
              [8.6598, 55.3083],
              [8.6705, 55.3325],
              [8.6568, 55.3488],
              [8.6229, 55.4259],
              [8.5432, 55.4561],
              [8.5199, 55.4515],
              [8.4415, 55.4574],
              [8.4167, 55.4832],
              [8.3169, 55.5464],
              [8.2931, 55.5668],
              [8.3085, 55.589],
              [8.2684, 55.5959],
              [8.2366, 55.5668],
              [8.2368, 55.5551],
              [8.2601, 55.5241],
              [8.3059, 55.4964],
              [8.2936, 55.4769],
              [8.1915, 55.5272],
              [8.0753, 55.5581],
              [8.0951, 55.5911],
              [8.1602, 55.7119],
              [8.1764, 55.7727],
              [8.171, 55.8155],
              [8.1898, 55.8174],
              [8.1799, 55.8306],
              [8.2246, 55.8265],
              [8.2237, 55.8179],
              [8.2711, 55.7972],
              [8.3218, 55.801],
              [8.3508, 55.8223],
              [8.3799, 55.8015],
              [8.4407, 55.7881],
              [8.4494, 55.7764],
              [8.4947, 55.7768],
              [8.5139, 55.7876],
              [8.5127, 55.8103],
              [8.5463, 55.8223],
              [8.5323, 55.8412],
              [8.5699, 55.8477],
              [8.593, 55.8349],
              [8.6762, 55.8483],
              [8.708, 55.8378],
              [8.7224, 55.8139],
              [8.7589, 55.8061],
              [8.8267, 55.8194],
              [8.7944, 55.8378],
              [8.823, 55.8475],
              [8.8395, 55.8818],
              [8.8815, 55.8957],
              [8.9656, 55.8849],
              [8.9579, 55.8455],
              [9.0094, 55.8306],
              [9.0709, 55.8444],
              [9.0973, 55.8772],
              [9.1176, 55.8693],
              [9.1499, 55.8835],
              [9.1957, 55.9168],
              [9.1952, 55.9307],
              [9.2194, 55.9547],
              [9.2572, 55.9535],
              [9.2808, 55.9383],
              [9.3036, 55.9369],
            ],
          ],
          [
            [
              [10.2057, 54.9717],
              [10.2537, 54.9431],
              [10.2826, 54.9398],
              [10.3245, 54.9149],
              [10.3365, 54.9002],
              [10.3711, 54.8847],
              [10.4053, 54.8968],
              [10.42, 54.8771],
              [10.4483, 54.8623],
              [10.4718, 54.887],
              [10.5213, 54.8578],
              [10.5242, 54.8438],
              [10.4576, 54.8392],
              [10.421, 54.8163],
              [10.373, 54.8371],
              [10.3205, 54.8764],
              [10.288, 54.8886],
              [10.2466, 54.919],
              [10.2393, 54.9416],
              [10.2117, 54.958],
              [10.2057, 54.9717],
            ],
          ],
          [
            [
              [9.8201, 55.327],
              [9.8277, 55.3009],
              [9.7987, 55.2954],
              [9.7901, 55.316],
              [9.8201, 55.327],
            ],
          ],
          [
            [
              [8.3449, 55.4587],
              [8.3715, 55.4694],
              [8.4094, 55.4647],
              [8.4056, 55.4351],
              [8.4329, 55.4362],
              [8.4536, 55.4271],
              [8.4664, 55.406],
              [8.4477, 55.3945],
              [8.4675, 55.3771],
              [8.4717, 55.3428],
              [8.4514, 55.3412],
              [8.426, 55.3524],
              [8.3755, 55.4158],
              [8.3316, 55.4409],
              [8.3449, 55.4587],
            ],
          ],
          [
            [
              [10.5948, 55.611],
              [10.6242, 55.6147],
              [10.7053, 55.5388],
              [10.7029, 55.5276],
              [10.7213, 55.5036],
              [10.7393, 55.4951],
              [10.7476, 55.4769],
              [10.7129, 55.4758],
              [10.6735, 55.4641],
              [10.6657, 55.4508],
              [10.6269, 55.4462],
              [10.6073, 55.4566],
              [10.5807, 55.4587],
              [10.5541, 55.4542],
              [10.5444, 55.4415],
              [10.5832, 55.4282],
              [10.5751, 55.4506],
              [10.5879, 55.4574],
              [10.6392, 55.4383],
              [10.668, 55.447],
              [10.7422, 55.3986],
              [10.7631, 55.3882],
              [10.7913, 55.3593],
              [10.8191, 55.3106],
              [10.7862, 55.3063],
              [10.7877, 55.2731],
              [10.8153, 55.2312],
              [10.8069, 55.1808],
              [10.7915, 55.1638],
              [10.771, 55.1101],
              [10.7436, 55.0688],
              [10.6842, 55.058],
              [10.6236, 55.0595],
              [10.598, 55.0474],
              [10.5165, 55.0266],
              [10.4709, 55.0423],
              [10.4154, 55.0395],
              [10.3702, 55.0592],
              [10.3546, 55.0507],
              [10.3202, 55.054],
              [10.2741, 55.0845],
              [10.2388, 55.0929],
              [10.2294, 55.0775],
              [10.2023, 55.0665],
              [10.1853, 55.0789],
              [10.1307, 55.0902],
              [10.0748, 55.0759],
              [10.0832, 55.1065],
              [10.1574, 55.1219],
              [10.1629, 55.1324],
              [10.1126, 55.1896],
              [10.0853, 55.1763],
              [10.0189, 55.1817],
              [9.9857, 55.1757],
              [10.0439, 55.1336],
              [10.0245, 55.1205],
              [9.9784, 55.1328],
              [9.991, 55.1475],
              [9.9817, 55.1673],
              [9.9879, 55.2019],
              [9.9657, 55.2187],
              [9.9279, 55.2193],
              [9.8915, 55.2445],
              [9.893, 55.3059],
              [9.8739, 55.3513],
              [9.84, 55.3505],
              [9.7867, 55.3637],
              [9.8406, 55.3916],
              [9.8258, 55.4237],
              [9.7916, 55.4325],
              [9.7438, 55.4352],
              [9.7467, 55.4461],
              [9.8067, 55.4445],
              [9.7418, 55.4733],
              [9.7295, 55.4928],
              [9.7017, 55.4945],
              [9.6815, 55.5069],
              [9.6974, 55.5181],
              [9.7229, 55.5082],
              [9.7558, 55.5115],
              [9.7836, 55.5429],
              [9.8345, 55.5548],
              [9.8521, 55.5454],
              [9.8493, 55.5251],
              [9.8657, 55.5136],
              [9.9397, 55.5118],
              [10.0361, 55.5569],
              [10.0736, 55.5616],
              [10.1057, 55.5742],
              [10.2019, 55.5913],
              [10.2331, 55.6045],
              [10.2796, 55.6047],
              [10.304, 55.5946],
              [10.312, 55.6224],
              [10.4157, 55.589],
              [10.4956, 55.548],
              [10.471, 55.5264],
              [10.5044, 55.4992],
              [10.458, 55.489],
              [10.444, 55.4799],
              [10.425, 55.4388],
              [10.4732, 55.4383],
              [10.523, 55.4655],
              [10.6266, 55.4907],
              [10.6199, 55.5043],
              [10.5706, 55.5164],
              [10.578, 55.53],
              [10.6183, 55.5303],
              [10.6356, 55.5675],
              [10.6286, 55.5897],
              [10.5948, 55.611],
            ],
          ],
        ],
      },
      properties: {
        name: 'Syddanmark',
        id: 'DK-85',
        NAME_ENG: 'Region of Southern Denmark',
        TYPE: 'Region',
        CNTRY: 'Denmark',
      },
      id: 'DK-85',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [14.7792, 55.2954],
              [14.8062, 55.277],
              [14.8246, 55.2528],
              [14.8752, 55.2375],
              [14.8841, 55.2283],
              [14.9436, 55.2098],
              [14.9693, 55.2152],
              [14.9891, 55.1941],
              [15.0885, 55.1507],
              [15.1376, 55.1442],
              [15.1542, 55.1335],
              [15.1433, 55.113],
              [15.1571, 55.0831],
              [15.1384, 55.0568],
              [15.1098, 55.0392],
              [15.1202, 55.0144],
              [15.0825, 54.9885],
              [14.9635, 55.0039],
              [14.9242, 55.0147],
              [14.8855, 55.0317],
              [14.8323, 55.041],
              [14.7948, 55.0539],
              [14.7768, 55.0524],
              [14.7104, 55.0813],
              [14.6994, 55.107],
              [14.707, 55.1305],
              [14.7012, 55.1964],
              [14.7116, 55.2229],
              [14.7525, 55.2683],
              [14.7517, 55.2874],
              [14.7792, 55.2954],
            ],
          ],
          [
            [
              [12.7391, 55.6281],
              [12.7336, 55.639],
              [12.7454, 55.6673],
              [12.7763, 55.6672],
              [12.784, 55.6397],
              [12.7816, 55.6064],
              [12.7427, 55.6196],
              [12.7391, 55.6281],
            ],
          ],
          [
            [
              [12.6136, 55.6871],
              [12.6489, 55.6756],
              [12.6324, 55.664],
              [12.6546, 55.6341],
              [12.681, 55.6289],
              [12.6699, 55.6044],
              [12.6769, 55.5905],
              [12.632, 55.5768],
              [12.5918, 55.5548],
              [12.5637, 55.5602],
              [12.5569, 55.5745],
              [12.5282, 55.5765],
              [12.5089, 55.6085],
              [12.5524, 55.6406],
              [12.5682, 55.6612],
              [12.6136, 55.6871],
            ],
          ],
          [
            [
              [12.3632, 55.594],
              [12.3566, 55.5973],
              [12.3552, 55.5983],
              [12.3371, 55.6079],
              [12.2761, 55.6221],
              [12.2095, 55.6045],
              [12.1597, 55.6128],
              [12.1668, 55.6342],
              [12.1504, 55.671],
              [12.1855, 55.6882],
              [12.212, 55.6872],
              [12.2507, 55.7084],
              [12.2144, 55.7268],
              [12.2087, 55.7507],
              [12.167, 55.7469],
              [12.0893, 55.7809],
              [12.063, 55.8283],
              [12.0419, 55.8439],
              [12.0642, 55.86],
              [12.0668, 55.8775],
              [12.0464, 55.9],
              [12.0481, 55.9145],
              [12.0179, 55.9597],
              [11.9766, 55.9669],
              [11.933, 55.9499],
              [11.8905, 55.9418],
              [11.8533, 55.946],
              [11.8434, 55.9669],
              [11.8928, 55.988],
              [12.0086, 56.031],
              [12.0622, 56.0593],
              [12.1792, 56.1081],
              [12.2868, 56.1292],
              [12.3492, 56.1198],
              [12.3853, 56.1013],
              [12.4349, 56.0932],
              [12.4982, 56.0914],
              [12.5398, 56.0798],
              [12.6062, 56.0439],
              [12.6158, 56.0322],
              [12.5969, 56.0129],
              [12.5641, 55.9954],
              [12.5454, 55.9726],
              [12.5174, 55.9221],
              [12.5725, 55.8481],
              [12.5773, 55.8118],
              [12.5959, 55.7883],
              [12.6012, 55.7596],
              [12.5803, 55.7322],
              [12.6232, 55.7178],
              [12.5919, 55.7061],
              [12.594, 55.6791],
              [12.5626, 55.6633],
              [12.546, 55.6393],
              [12.5092, 55.6345],
              [12.4939, 55.6197],
              [12.4909, 55.6006],
              [12.4482, 55.6042],
              [12.4421, 55.6153],
              [12.402, 55.6162],
              [12.3997, 55.6063],
              [12.3632, 55.594],
            ],
          ],
          [
            [
              [11.9812, 55.7301],
              [11.932, 55.7453],
              [11.9051, 55.7314],
              [11.8758, 55.7379],
              [11.8568, 55.7365],
              [11.8644, 55.7963],
              [11.8778, 55.814],
              [11.9489, 55.8278],
              [11.9602, 55.8514],
              [11.9292, 55.8808],
              [11.9311, 55.8917],
              [11.902, 55.9182],
              [11.9057, 55.9378],
              [11.9644, 55.9295],
              [11.9852, 55.9135],
              [12.0038, 55.9153],
              [12.0262, 55.9004],
              [12.001, 55.882],
              [12.0014, 55.8585],
              [12.0299, 55.8513],
              [12.0437, 55.7962],
              [12.0672, 55.7667],
              [12.0554, 55.7567],
              [12.0556, 55.7335],
              [12.0072, 55.7228],
              [11.9812, 55.7301],
            ],
          ],
        ],
      },
      properties: {
        name: 'Hovedstaden',
        id: 'DK-84',
        NAME_ENG: 'Capital Region of Denmark',
        TYPE: 'Region',
        CNTRY: 'Denmark',
      },
      id: 'DK-84',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [11.8758, 55.7379],
              [11.9051, 55.7314],
              [11.932, 55.7453],
              [11.9812, 55.7301],
              [11.9805, 55.7164],
              [11.9518, 55.6758],
              [11.9973, 55.6784],
              [12.006, 55.6915],
              [12.0314, 55.6953],
              [12.0253, 55.6679],
              [12.086, 55.6541],
              [12.0704, 55.6801],
              [12.1095, 55.7031],
              [12.1018, 55.7193],
              [12.1181, 55.7264],
              [12.0952, 55.7436],
              [12.0893, 55.7809],
              [12.167, 55.7469],
              [12.2087, 55.7507],
              [12.2144, 55.7268],
              [12.2507, 55.7084],
              [12.212, 55.6872],
              [12.1855, 55.6882],
              [12.1504, 55.671],
              [12.1668, 55.6342],
              [12.1597, 55.6128],
              [12.2095, 55.6045],
              [12.2761, 55.6221],
              [12.3371, 55.6079],
              [12.3552, 55.5983],
              [12.3517, 55.5908],
              [12.2959, 55.5743],
              [12.2401, 55.5423],
              [12.1935, 55.4683],
              [12.2003, 55.4412],
              [12.2161, 55.4275],
              [12.3029, 55.4007],
              [12.365, 55.3971],
              [12.4164, 55.3692],
              [12.4446, 55.3478],
              [12.4544, 55.3309],
              [12.445, 55.3146],
              [12.455, 55.2936],
              [12.4448, 55.2768],
              [12.4138, 55.2603],
              [12.3698, 55.2541],
              [12.354, 55.236],
              [12.2732, 55.242],
              [12.2337, 55.2354],
              [12.1603, 55.2142],
              [12.1363, 55.1921],
              [12.1142, 55.1828],
              [12.1064, 55.1603],
              [12.1122, 55.1407],
              [12.0776, 55.1383],
              [12.0911, 55.1772],
              [12.0297, 55.1665],
              [12.0108, 55.1487],
              [12.0315, 55.1435],
              [12.0558, 55.1204],
              [12.1138, 55.1241],
              [12.1304, 55.1315],
              [12.1813, 55.1144],
              [12.1705, 55.0875],
              [12.1545, 55.0762],
              [12.1233, 55.0788],
              [12.1275, 55.0478],
              [12.171, 55.0039],
              [12.1648, 54.996],
              [12.1214, 54.995],
              [12.0771, 54.9707],
              [12.0253, 54.9627],
              [11.978, 54.9767],
              [11.9539, 54.9967],
              [11.9123, 55.0049],
              [11.8973, 54.9944],
              [11.829, 55.024],
              [11.8094, 55.0377],
              [11.7298, 55.0549],
              [11.7469, 55.0644],
              [11.8253, 55.0414],
              [11.8292, 55.0538],
              [11.8091, 55.0719],
              [11.7807, 55.0811],
              [11.7607, 55.0767],
              [11.72, 55.1131],
              [11.7598, 55.1186],
              [11.7783, 55.1325],
              [11.8074, 55.1287],
              [11.8099, 55.1498],
              [11.7728, 55.1562],
              [11.7333, 55.1521],
              [11.7148, 55.1691],
              [11.7358, 55.197],
              [11.7129, 55.2089],
              [11.6858, 55.2067],
              [11.6392, 55.1765],
              [11.5968, 55.1901],
              [11.5511, 55.1903],
              [11.4302, 55.2191],
              [11.4562, 55.1883],
              [11.4168, 55.1893],
              [11.4129, 55.2064],
              [11.3786, 55.1989],
              [11.3165, 55.2093],
              [11.2954, 55.1927],
              [11.2576, 55.2013],
              [11.2513, 55.2227],
              [11.2337, 55.2439],
              [11.2812, 55.236],
              [11.3065, 55.2675],
              [11.2873, 55.2737],
              [11.2735, 55.2469],
              [11.239, 55.2497],
              [11.2449, 55.2629],
              [11.2349, 55.2863],
              [11.1746, 55.3059],
              [11.129, 55.3327],
              [11.1796, 55.3252],
              [11.2074, 55.3424],
              [11.1805, 55.354],
              [11.1409, 55.3315],
              [11.1092, 55.3483],
              [11.12, 55.3648],
              [11.1508, 55.3676],
              [11.202, 55.3827],
              [11.2134, 55.4031],
              [11.195, 55.4183],
              [11.2044, 55.444],
              [11.1776, 55.476],
              [11.1738, 55.497],
              [11.1413, 55.5154],
              [11.0804, 55.515],
              [11.0896, 55.5345],
              [11.1404, 55.5365],
              [11.1576, 55.557],
              [11.1503, 55.5854],
              [11.1298, 55.6125],
              [11.1106, 55.6238],
              [11.0392, 55.6495],
              [11.0089, 55.6548],
              [10.9988, 55.6692],
              [11.0573, 55.6616],
              [11.0963, 55.6749],
              [11.061, 55.6828],
              [11.024, 55.6982],
              [10.9944, 55.7241],
              [10.9683, 55.7296],
              [10.9284, 55.7269],
              [10.8968, 55.7436],
              [10.9452, 55.7515],
              [10.9865, 55.7465],
              [11.0462, 55.7318],
              [11.0811, 55.7352],
              [11.1277, 55.7522],
              [11.1765, 55.7472],
              [11.1967, 55.7319],
              [11.228, 55.7426],
              [11.2567, 55.7297],
              [11.2858, 55.7397],
              [11.2914, 55.7534],
              [11.3287, 55.7533],
              [11.3705, 55.7683],
              [11.3826, 55.789],
              [11.373, 55.8334],
              [11.4068, 55.833],
              [11.4476, 55.8475],
              [11.492, 55.8469],
              [11.5158, 55.8651],
              [11.525, 55.8838],
              [11.5262, 55.9165],
              [11.5167, 55.9319],
              [11.4614, 55.9492],
              [11.3766, 55.9532],
              [11.3358, 55.9693],
              [11.298, 55.9752],
              [11.3031, 55.9892],
              [11.3826, 55.9672],
              [11.4192, 55.9618],
              [11.4825, 55.9605],
              [11.5223, 55.9513],
              [11.5916, 55.9618],
              [11.6085, 55.9459],
              [11.6501, 55.9393],
              [11.6998, 55.9481],
              [11.7768, 55.9769],
              [11.7909, 55.9572],
              [11.7547, 55.9406],
              [11.7518, 55.9051],
              [11.7338, 55.899],
              [11.7071, 55.9129],
              [11.6737, 55.9124],
              [11.6682, 55.8814],
              [11.6914, 55.8744],
              [11.731, 55.8365],
              [11.7333, 55.8209],
              [11.7105, 55.8106],
              [11.6633, 55.8183],
              [11.6466, 55.7944],
              [11.611, 55.7847],
              [11.631, 55.7717],
              [11.6686, 55.7756],
              [11.7027, 55.7905],
              [11.7439, 55.7893],
              [11.7697, 55.7512],
              [11.7488, 55.7391],
              [11.6622, 55.7342],
              [11.664, 55.716],
              [11.7836, 55.7237],
              [11.7922, 55.6994],
              [11.7825, 55.6585],
              [11.8108, 55.6651],
              [11.8368, 55.6836],
              [11.8405, 55.7067],
              [11.8573, 55.7314],
              [11.8758, 55.7379],
            ],
          ],
          [
            [
              [12.2366, 55.0586],
              [12.2179, 55.0335],
              [12.187, 55.0406],
              [12.2095, 55.0574],
              [12.2366, 55.0586],
            ],
          ],
          [
            [
              [11.8394, 54.9596],
              [11.8782, 54.9435],
              [11.8901, 54.9221],
              [11.9167, 54.916],
              [11.9336, 54.9358],
              [11.9672, 54.9389],
              [11.9849, 54.9043],
              [12.0037, 54.8957],
              [12.0584, 54.8886],
              [12.073, 54.8933],
              [12.1207, 54.8868],
              [12.1275, 54.8673],
              [12.1657, 54.8341],
              [12.1347, 54.8224],
              [12.1162, 54.8],
              [12.0809, 54.7883],
              [12.0403, 54.7568],
              [12.0252, 54.7313],
              [11.9874, 54.7083],
              [11.971, 54.6908],
              [11.9612, 54.6501],
              [11.974, 54.5756],
              [11.9709, 54.5595],
              [11.918, 54.5746],
              [11.9205, 54.5947],
              [11.8663, 54.6571],
              [11.8785, 54.6893],
              [11.9108, 54.7165],
              [11.8699, 54.7268],
              [11.8844, 54.7472],
              [11.8621, 54.7737],
              [11.7946, 54.8116],
              [11.8148, 54.8283],
              [11.8067, 54.8434],
              [11.7837, 54.8537],
              [11.7552, 54.8554],
              [11.7569, 54.8812],
              [11.7405, 54.8964],
              [11.7549, 54.9118],
              [11.7453, 54.9295],
              [11.7046, 54.9322],
              [11.7436, 54.9606],
              [11.7832, 54.9548],
              [11.8009, 54.9364],
              [11.8394, 54.9596],
            ],
          ],
          [
            [
              [11.4111, 54.9687],
              [11.4504, 54.9644],
              [11.4692, 54.943],
              [11.4335, 54.9446],
              [11.4054, 54.9224],
              [11.372, 54.9355],
              [11.3655, 54.9564],
              [11.4112, 54.953],
              [11.4111, 54.9687],
            ],
          ],
          [
            [
              [11.177, 55.1707],
              [11.1505, 55.1401],
              [11.1333, 55.1599],
              [11.177, 55.1707],
            ],
          ],
          [
            [
              [11.1901, 55.2406],
              [11.2116, 55.1837],
              [11.1745, 55.1986],
              [11.1705, 55.2308],
              [11.1901, 55.2406],
            ],
          ],
          [
            [
              [11.2641, 54.9568],
              [11.2934, 54.9456],
              [11.319, 54.9238],
              [11.3598, 54.9163],
              [11.3615, 54.8974],
              [11.4097, 54.8767],
              [11.4328, 54.861],
              [11.4688, 54.8591],
              [11.464, 54.8453],
              [11.5652, 54.8235],
              [11.5614, 54.8458],
              [11.5839, 54.8574],
              [11.6091, 54.8487],
              [11.6519, 54.8586],
              [11.6429, 54.8698],
              [11.6599, 54.9012],
              [11.7204, 54.8726],
              [11.7496, 54.8393],
              [11.7798, 54.8314],
              [11.782, 54.8066],
              [11.8577, 54.7688],
              [11.8653, 54.7367],
              [11.8073, 54.7144],
              [11.816, 54.704],
              [11.8601, 54.6915],
              [11.8343, 54.6515],
              [11.8038, 54.6447],
              [11.74, 54.651],
              [11.688, 54.6645],
              [11.5888, 54.6578],
              [11.5609, 54.6591],
              [11.5434, 54.64],
              [11.497, 54.6354],
              [11.4612, 54.6115],
              [11.3438, 54.6552],
              [11.2975, 54.6858],
              [11.2632, 54.702],
              [11.1133, 54.7381],
              [11.0533, 54.7485],
              [11.0028, 54.7642],
              [10.9721, 54.7968],
              [10.9981, 54.7977],
              [11.0149, 54.815],
              [11.045, 54.7989],
              [11.0702, 54.7996],
              [11.0993, 54.8216],
              [11.0784, 54.8344],
              [11.0784, 54.8479],
              [11.0304, 54.8706],
              [11.0156, 54.889],
              [11.0278, 54.9134],
              [11.0715, 54.939],
              [11.1065, 54.9409],
              [11.1816, 54.9608],
              [11.2641, 54.9568],
            ],
          ],
          [
            [
              [12.2794, 55.0431],
              [12.3163, 55.0276],
              [12.3881, 55.0272],
              [12.4622, 55.0229],
              [12.5218, 55.0078],
              [12.5471, 54.9807],
              [12.5526, 54.9583],
              [12.5409, 54.9463],
              [12.509, 54.9435],
              [12.4613, 54.9558],
              [12.3853, 54.9653],
              [12.3592, 54.9644],
              [12.3159, 54.9534],
              [12.2824, 54.9377],
              [12.2069, 54.8816],
              [12.1418, 54.8815],
              [12.1248, 54.8993],
              [12.0028, 54.9222],
              [12.0319, 54.9385],
              [12.0691, 54.9384],
              [12.0882, 54.9262],
              [12.0754, 54.9098],
              [12.1041, 54.9051],
              [12.13, 54.947],
              [12.1215, 54.9584],
              [12.1633, 54.9718],
              [12.1672, 54.9868],
              [12.1972, 54.9808],
              [12.2067, 54.9667],
              [12.2771, 54.9837],
              [12.2707, 54.999],
              [12.2827, 55.0157],
              [12.2351, 55.0491],
              [12.2711, 55.0567],
              [12.2794, 55.0431],
            ],
          ],
          [
            [
              [11.5179, 54.9917],
              [11.5492, 54.9883],
              [11.5676, 54.9726],
              [11.5428, 54.9554],
              [11.5248, 54.9575],
              [11.4977, 54.9806],
              [11.5179, 54.9917],
            ],
          ],
          [
            [
              [11.721, 55.1838],
              [11.687, 55.1757],
              [11.7024, 55.2023],
              [11.721, 55.1838],
            ],
          ],
          [
            [
              [11.8458, 55.7826],
              [11.8337, 55.7617],
              [11.8148, 55.7502],
              [11.7926, 55.7583],
              [11.775, 55.7825],
              [11.7983, 55.7924],
              [11.8162, 55.7826],
              [11.8458, 55.7826],
            ],
          ],
        ],
      },
      properties: {
        name: 'Sjælland',
        id: 'DK-83',
        NAME_ENG: 'Region Zealand',
        TYPE: 'Region',
        CNTRY: 'Denmark',
      },
      id: 'DK-83',
    },
  ],
}
export default map
