export const percentCalc = (a: number, b: number) => {
  return (a / b) * 100
}

export const timeFormat = (sec: number) => {
  const date = new Date(0)
  date.setSeconds(sec)
  return date.toISOString().substr(11, 8)
}

export const numSpaceFormater = (num: number | string) => {
  if (Number(num).toString().indexOf('.') !== -1)
    return Number(Math.trunc(Number(num))).toLocaleString()  + Number(num).toString().slice(num.toString().indexOf('.'))
  else return Number(num).toLocaleString()
}
