var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.825, 48.7422],
            [18.8292, 48.7478],
            [18.8445, 48.7524],
            [18.857, 48.7637],
            [18.8733, 48.7698],
            [18.8828, 48.7689],
            [18.8899, 48.7515],
            [18.9071, 48.7498],
            [18.9176, 48.7432],
            [18.927, 48.7428],
            [18.9405, 48.7496],
            [18.9631, 48.7456],
            [18.9897, 48.7479],
            [18.9979, 48.758],
            [19.0139, 48.7688],
            [19.0163, 48.7913],
            [18.9946, 48.8017],
            [18.9898, 48.8076],
            [18.9898, 48.8187],
            [18.9968, 48.825],
            [18.9892, 48.8494],
            [19.0104, 48.8506],
            [19.0267, 48.8666],
            [19.0334, 48.8807],
            [19.0534, 48.8838],
            [19.0616, 48.8783],
            [19.0804, 48.8798],
            [19.1002, 48.8773],
            [19.1114, 48.8796],
            [19.149, 48.8818],
            [19.1608, 48.8801],
            [19.169, 48.8839],
            [19.1822, 48.8807],
            [19.207, 48.8942],
            [19.2198, 48.8946],
            [19.235, 48.8989],
            [19.2542, 48.8931],
            [19.2601, 48.8834],
            [19.2607, 48.8696],
            [19.2456, 48.8612],
            [19.2499, 48.8573],
            [19.2791, 48.8623],
            [19.3124, 48.8642],
            [19.3198, 48.8789],
            [19.3373, 48.8969],
            [19.3759, 48.9263],
            [19.3958, 48.9277],
            [19.4425, 48.9282],
            [19.458, 48.9308],
            [19.4953, 48.9433],
            [19.5163, 48.9399],
            [19.5393, 48.9467],
            [19.5484, 48.9416],
            [19.563, 48.9413],
            [19.584, 48.9361],
            [19.5914, 48.9297],
            [19.6171, 48.9174],
            [19.6382, 48.9101],
            [19.6602, 48.8984],
            [19.6887, 48.8984],
            [19.7335, 48.9092],
            [19.7477, 48.8981],
            [19.7648, 48.9049],
            [19.7762, 48.9052],
            [19.7977, 48.9141],
            [19.8198, 48.9136],
            [19.8361, 48.9056],
            [19.8808, 48.9097],
            [19.9386, 48.9074],
            [19.9651, 48.9087],
            [19.9765, 48.916],
            [19.9846, 48.9149],
            [19.9985, 48.904],
            [20.0019, 48.9027],
            [20.0423, 48.9017],
            [20.0516, 48.9001],
            [20.0594, 48.8915],
            [20.1258, 48.8839],
            [20.143, 48.8835],
            [20.1612, 48.8917],
            [20.1755, 48.9077],
            [20.1961, 48.895],
            [20.2105, 48.8963],
            [20.2228, 48.8938],
            [20.2489, 48.8748],
            [20.2628, 48.8719],
            [20.2497, 48.8679],
            [20.2395, 48.8436],
            [20.198, 48.8184],
            [20.2009, 48.8076],
            [20.1995, 48.7862],
            [20.2052, 48.77],
            [20.1805, 48.7579],
            [20.1835, 48.741],
            [20.1908, 48.7403],
            [20.1885, 48.727],
            [20.193, 48.7198],
            [20.209, 48.7227],
            [20.2417, 48.7028],
            [20.2565, 48.6962],
            [20.2636, 48.6841],
            [20.278, 48.6738],
            [20.2798, 48.6663],
            [20.2895, 48.6558],
            [20.3019, 48.6491],
            [20.2923, 48.6371],
            [20.3008, 48.6102],
            [20.2959, 48.6016],
            [20.3146, 48.5955],
            [20.3382, 48.5981],
            [20.3405, 48.5909],
            [20.3556, 48.5863],
            [20.3479, 48.5656],
            [20.3277, 48.5398],
            [20.3278, 48.5284],
            [20.3198, 48.5183],
            [20.3069, 48.5109],
            [20.3019, 48.4928],
            [20.3107, 48.4838],
            [20.3087, 48.4718],
            [20.3299, 48.4678],
            [20.3323, 48.454],
            [20.3657, 48.4551],
            [20.3859, 48.4594],
            [20.395, 48.4638],
            [20.4063, 48.4766],
            [20.4201, 48.4808],
            [20.4301, 48.4674],
            [20.4571, 48.4738],
            [20.4661, 48.4647],
            [20.4721, 48.456],
            [20.4635, 48.4501],
            [20.4508, 48.4476],
            [20.4445, 48.4373],
            [20.4189, 48.4206],
            [20.4159, 48.4119],
            [20.4209, 48.4051],
            [20.4196, 48.3951],
            [20.4053, 48.3832],
            [20.4131, 48.3703],
            [20.409, 48.3631],
            [20.3878, 48.3504],
            [20.3858, 48.3451],
            [20.3696, 48.3306],
            [20.3751, 48.3269],
            [20.367, 48.3179],
            [20.3421, 48.3026],
            [20.3296, 48.288],
            [20.3269, 48.2728],
            [20.3124, 48.2739],
            [20.3132, 48.265],
            [20.2869, 48.262],
            [20.2813, 48.2669],
            [20.2522, 48.2787],
            [20.2416, 48.2807],
            [20.2285, 48.2767],
            [20.236, 48.2711],
            [20.2291, 48.2625],
            [20.2175, 48.2598],
            [20.2057, 48.2622],
            [20.208, 48.2516],
            [20.1779, 48.2488],
            [20.1711, 48.2521],
            [20.1559, 48.2512],
            [20.1464, 48.2553],
            [20.1341, 48.2494],
            [20.1408, 48.2435],
            [20.1339, 48.2253],
            [20.1033, 48.2093],
            [20.093, 48.1989],
            [20.0775, 48.1967],
            [20.0689, 48.1896],
            [20.0727, 48.1798],
            [20.0456, 48.1688],
            [20.0324, 48.1773],
            [20.0183, 48.1758],
            [19.9946, 48.1685],
            [19.9911, 48.1632],
            [19.9782, 48.166],
            [19.9671, 48.1538],
            [19.9541, 48.1523],
            [19.9395, 48.1333],
            [19.9013, 48.1274],
            [19.9052, 48.1372],
            [19.9155, 48.1459],
            [19.9017, 48.1662],
            [19.8902, 48.1649],
            [19.8741, 48.1714],
            [19.8614, 48.1712],
            [19.8465, 48.1631],
            [19.8377, 48.1631],
            [19.8248, 48.1707],
            [19.7976, 48.1548],
            [19.7866, 48.1645],
            [19.794, 48.1746],
            [19.809, 48.1867],
            [19.8014, 48.1955],
            [19.7932, 48.1935],
            [19.7842, 48.202],
            [19.7684, 48.2075],
            [19.7639, 48.2141],
            [19.7389, 48.2157],
            [19.7275, 48.2033],
            [19.7092, 48.2017],
            [19.6978, 48.2047],
            [19.6827, 48.2141],
            [19.6783, 48.2206],
            [19.6728, 48.2401],
            [19.6627, 48.2423],
            [19.6488, 48.2398],
            [19.634, 48.2503],
            [19.611, 48.2384],
            [19.61, 48.2348],
            [19.593, 48.226],
            [19.5825, 48.2177],
            [19.5692, 48.2152],
            [19.5503, 48.2064],
            [19.5425, 48.2093],
            [19.5282, 48.203],
            [19.5314, 48.1957],
            [19.5253, 48.1848],
            [19.5093, 48.1727],
            [19.5157, 48.1546],
            [19.5105, 48.1473],
            [19.4967, 48.1398],
            [19.4989, 48.1194],
            [19.4879, 48.108],
            [19.4916, 48.1001],
            [19.4819, 48.0945],
            [19.472, 48.0949],
            [19.4706, 48.0836],
            [19.4636, 48.0835],
            [19.4537, 48.0957],
            [19.4384, 48.0993],
            [19.4278, 48.0896],
            [19.4009, 48.0831],
            [19.3892, 48.0916],
            [19.3593, 48.0902],
            [19.3429, 48.0874],
            [19.3327, 48.0907],
            [19.3001, 48.0873],
            [19.2752, 48.0734],
            [19.2556, 48.07],
            [19.2504, 48.058],
            [19.2428, 48.054],
            [19.2137, 48.0643],
            [19.1891, 48.0591],
            [19.1597, 48.0657],
            [19.1453, 48.0723],
            [19.1279, 48.0729],
            [19.121, 48.0651],
            [19.1016, 48.0656],
            [19.0894, 48.0724],
            [19.0644, 48.059],
            [19.047, 48.0675],
            [19.0346, 48.0658],
            [19.0159, 48.077],
            [19.0158, 48.093],
            [19.0251, 48.0955],
            [19.026, 48.1023],
            [19.0351, 48.1104],
            [19.0502, 48.1163],
            [19.0674, 48.134],
            [19.0635, 48.149],
            [19.0521, 48.1632],
            [19.0582, 48.1655],
            [19.061, 48.1779],
            [19.071, 48.1839],
            [19.0727, 48.1954],
            [19.0505, 48.2056],
            [19.0355, 48.1935],
            [18.9887, 48.1872],
            [18.9723, 48.1821],
            [18.9646, 48.1874],
            [18.9546, 48.1756],
            [18.942, 48.1842],
            [18.9292, 48.1831],
            [18.9274, 48.1747],
            [18.9348, 48.16],
            [18.8931, 48.1616],
            [18.8564, 48.1693],
            [18.8522, 48.1736],
            [18.8303, 48.1783],
            [18.8257, 48.168],
            [18.8106, 48.1661],
            [18.8033, 48.1752],
            [18.8009, 48.1879],
            [18.8104, 48.1963],
            [18.8176, 48.1952],
            [18.8333, 48.2075],
            [18.8218, 48.2168],
            [18.8141, 48.2328],
            [18.8198, 48.2385],
            [18.8093, 48.2466],
            [18.8158, 48.2586],
            [18.8268, 48.2648],
            [18.8429, 48.2684],
            [18.8453, 48.276],
            [18.8424, 48.2883],
            [18.8529, 48.307],
            [18.8468, 48.3208],
            [18.8345, 48.3218],
            [18.8252, 48.3352],
            [18.8259, 48.3456],
            [18.8204, 48.3523],
            [18.8183, 48.3638],
            [18.8106, 48.372],
            [18.7852, 48.3773],
            [18.774, 48.392],
            [18.7572, 48.403],
            [18.759, 48.4098],
            [18.7478, 48.4164],
            [18.7332, 48.4161],
            [18.7187, 48.4018],
            [18.7013, 48.3938],
            [18.6898, 48.3835],
            [18.6903, 48.3744],
            [18.6813, 48.3728],
            [18.682, 48.3595],
            [18.6557, 48.3462],
            [18.6526, 48.3514],
            [18.6354, 48.354],
            [18.6286, 48.3483],
            [18.6029, 48.3397],
            [18.5747, 48.3456],
            [18.5603, 48.3297],
            [18.5427, 48.3168],
            [18.5294, 48.3276],
            [18.528, 48.3345],
            [18.518, 48.3452],
            [18.5163, 48.3526],
            [18.5196, 48.3733],
            [18.5167, 48.3854],
            [18.5196, 48.3932],
            [18.5292, 48.3988],
            [18.5299, 48.4063],
            [18.5462, 48.4328],
            [18.5385, 48.4393],
            [18.5367, 48.4523],
            [18.5248, 48.4562],
            [18.5455, 48.4736],
            [18.5346, 48.4751],
            [18.541, 48.4888],
            [18.5414, 48.4984],
            [18.5314, 48.5051],
            [18.5125, 48.5093],
            [18.4847, 48.5008],
            [18.4928, 48.5179],
            [18.5018, 48.5214],
            [18.5018, 48.529],
            [18.4944, 48.5356],
            [18.4925, 48.5481],
            [18.492, 48.5559],
            [18.4972, 48.5708],
            [18.5099, 48.5715],
            [18.5363, 48.5665],
            [18.5772, 48.5664],
            [18.5873, 48.5723],
            [18.5986, 48.5731],
            [18.603, 48.5803],
            [18.6008, 48.5889],
            [18.6092, 48.5993],
            [18.6222, 48.6051],
            [18.6432, 48.6317],
            [18.6539, 48.639],
            [18.6625, 48.6394],
            [18.6752, 48.6334],
            [18.6993, 48.6328],
            [18.7174, 48.6262],
            [18.721, 48.6309],
            [18.7161, 48.6473],
            [18.7383, 48.6589],
            [18.7463, 48.6688],
            [18.7502, 48.6882],
            [18.7646, 48.6983],
            [18.7807, 48.6948],
            [18.7941, 48.7008],
            [18.8079, 48.7151],
            [18.825, 48.7422],
          ],
        ],
      },
      properties: {
        name: 'Banskobystrický',
        id: 'SK-BC',
        NAME_ENG: 'Banská Bystrica',
        CNTRY: 'Slovakia',
        TYPE: 'kraj',
        TYPE_ENG: 'Region',
      },
      id: 'SK-BC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.9479, 48.5339],
            [16.9758, 48.5338],
            [16.9777, 48.5445],
            [17.0086, 48.5468],
            [17.0403, 48.5555],
            [17.0507, 48.5677],
            [17.09, 48.5533],
            [17.1, 48.5598],
            [17.1142, 48.5496],
            [17.1884, 48.5597],
            [17.213, 48.5685],
            [17.2361, 48.5712],
            [17.2396, 48.5803],
            [17.264, 48.5921],
            [17.27, 48.6007],
            [17.2946, 48.6231],
            [17.3073, 48.6323],
            [17.3147, 48.6441],
            [17.3259, 48.6539],
            [17.3561, 48.6426],
            [17.3702, 48.6229],
            [17.3672, 48.6097],
            [17.3512, 48.6033],
            [17.339, 48.5905],
            [17.3218, 48.5906],
            [17.3152, 48.5751],
            [17.276, 48.5562],
            [17.2747, 48.5484],
            [17.3044, 48.535],
            [17.3086, 48.5227],
            [17.3289, 48.5206],
            [17.3296, 48.5118],
            [17.3423, 48.5068],
            [17.3389, 48.492],
            [17.3336, 48.4842],
            [17.3154, 48.4758],
            [17.3053, 48.4669],
            [17.321, 48.4516],
            [17.3473, 48.4508],
            [17.3636, 48.439],
            [17.3947, 48.4388],
            [17.3896, 48.4296],
            [17.3897, 48.4197],
            [17.4056, 48.4182],
            [17.4052, 48.4099],
            [17.4169, 48.4078],
            [17.4232, 48.3992],
            [17.4319, 48.375],
            [17.4373, 48.3704],
            [17.4534, 48.3746],
            [17.471, 48.3635],
            [17.4768, 48.3483],
            [17.4609, 48.3227],
            [17.461, 48.3128],
            [17.4553, 48.3055],
            [17.4659, 48.3021],
            [17.4712, 48.2941],
            [17.4921, 48.284],
            [17.5027, 48.2896],
            [17.5152, 48.28],
            [17.5004, 48.2657],
            [17.4826, 48.2526],
            [17.475, 48.2397],
            [17.4745, 48.2277],
            [17.4921, 48.2244],
            [17.5201, 48.2156],
            [17.5201, 48.2089],
            [17.5097, 48.2009],
            [17.5085, 48.1947],
            [17.5218, 48.1868],
            [17.522, 48.1734],
            [17.5067, 48.1699],
            [17.5011, 48.1635],
            [17.4845, 48.1631],
            [17.4738, 48.1558],
            [17.4665, 48.1448],
            [17.439, 48.1382],
            [17.4211, 48.1463],
            [17.4069, 48.1463],
            [17.4064, 48.1612],
            [17.3881, 48.1652],
            [17.376, 48.158],
            [17.3803, 48.1472],
            [17.3907, 48.1422],
            [17.3802, 48.1314],
            [17.3712, 48.1336],
            [17.3426, 48.119],
            [17.3365, 48.1204],
            [17.3212, 48.1143],
            [17.3096, 48.1191],
            [17.3035, 48.0998],
            [17.311, 48.0903],
            [17.3215, 48.085],
            [17.3355, 48.0871],
            [17.3407, 48.0932],
            [17.3512, 48.0895],
            [17.3361, 48.0791],
            [17.3344, 48.0642],
            [17.314, 48.0616],
            [17.2989, 48.0663],
            [17.3052, 48.0477],
            [17.2817, 48.0405],
            [17.2483, 48.0129],
            [17.2438, 48.0222],
            [17.2313, 48.0167],
            [17.2023, 48.0197],
            [17.1733, 48.0119],
            [17.1624, 48.0069],
            [17.1474, 48.0183],
            [17.1362, 48.0231],
            [17.1279, 48.0215],
            [17.1114, 48.0311],
            [17.093, 48.0208],
            [17.0709, 48.0326],
            [17.0886, 48.0452],
            [17.0852, 48.0505],
            [17.0704, 48.0548],
            [17.0877, 48.0654],
            [17.0748, 48.0707],
            [17.0809, 48.0782],
            [17.0777, 48.0858],
            [17.0906, 48.0922],
            [17.0938, 48.0997],
            [17.0826, 48.1035],
            [17.0626, 48.1218],
            [17.0675, 48.1274],
            [17.0566, 48.1433],
            [17.0353, 48.1402],
            [17.0247, 48.1432],
            [16.9957, 48.163],
            [16.9759, 48.172],
            [16.9763, 48.1884],
            [16.9712, 48.197],
            [16.9608, 48.2264],
            [16.9503, 48.2342],
            [16.9485, 48.2418],
            [16.9528, 48.2567],
            [16.9584, 48.2647],
            [16.9485, 48.2708],
            [16.9255, 48.273],
            [16.9029, 48.2983],
            [16.897, 48.3101],
            [16.91, 48.3234],
            [16.9083, 48.3276],
            [16.8737, 48.3388],
            [16.8757, 48.3479],
            [16.8507, 48.3494],
            [16.8442, 48.3536],
            [16.8427, 48.3715],
            [16.8354, 48.3798],
            [16.8393, 48.3845],
            [16.8524, 48.385],
            [16.8564, 48.3997],
            [16.8522, 48.4086],
            [16.8638, 48.4197],
            [16.8544, 48.4326],
            [16.8513, 48.4476],
            [16.8647, 48.4558],
            [16.8611, 48.4603],
            [16.88, 48.4761],
            [16.8877, 48.4782],
            [16.8974, 48.4874],
            [16.8928, 48.4939],
            [16.9013, 48.4988],
            [16.9186, 48.4969],
            [16.9138, 48.5131],
            [16.9215, 48.5208],
            [16.9415, 48.5217],
            [16.9479, 48.5339],
          ],
        ],
      },
      properties: {
        name: 'Bratislavský',
        id: 'SK-BL',
        NAME_ENG: 'Bratislava',
        CNTRY: 'Slovakia',
        TYPE: 'kraj',
        TYPE_ENG: 'Region',
      },
      id: 'SK-BL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.2628, 48.8719],
            [20.2795, 48.863],
            [20.2911, 48.8641],
            [20.2957, 48.8749],
            [20.2908, 48.8863],
            [20.2969, 48.8909],
            [20.2951, 48.8995],
            [20.2978, 48.9177],
            [20.311, 48.9231],
            [20.3116, 48.9323],
            [20.3012, 48.9374],
            [20.3001, 48.9444],
            [20.3326, 48.9659],
            [20.3443, 48.9686],
            [20.3646, 48.9805],
            [20.3843, 48.9844],
            [20.3978, 48.9961],
            [20.409, 48.9948],
            [20.4313, 48.987],
            [20.4608, 48.9732],
            [20.4719, 48.9648],
            [20.4895, 48.9603],
            [20.5029, 48.9624],
            [20.4809, 48.9813],
            [20.4854, 48.9897],
            [20.5083, 49.0042],
            [20.5145, 49.0016],
            [20.5254, 48.9872],
            [20.544, 48.9768],
            [20.5464, 48.9701],
            [20.5668, 48.9625],
            [20.5814, 48.9665],
            [20.604, 48.9676],
            [20.6137, 48.9543],
            [20.6339, 48.9387],
            [20.6564, 48.9239],
            [20.6635, 48.9264],
            [20.6664, 48.9411],
            [20.6778, 48.9475],
            [20.6776, 48.9569],
            [20.7174, 48.9661],
            [20.7243, 48.9551],
            [20.7354, 48.9542],
            [20.7421, 48.9616],
            [20.753, 48.9624],
            [20.7591, 48.9529],
            [20.7784, 48.9592],
            [20.7675, 48.9698],
            [20.7686, 48.9779],
            [20.76, 48.9843],
            [20.7868, 48.9835],
            [20.8016, 48.9748],
            [20.816, 48.9743],
            [20.8305, 48.9804],
            [20.8334, 48.9748],
            [20.8169, 48.9686],
            [20.8268, 48.9613],
            [20.8443, 48.9579],
            [20.8855, 48.9682],
            [20.9, 48.9689],
            [20.9169, 48.9652],
            [20.9333, 48.9573],
            [20.9449, 48.9412],
            [20.9637, 48.9481],
            [20.9804, 48.9493],
            [20.9934, 48.9391],
            [21.001, 48.9292],
            [21.0157, 48.9235],
            [21.0199, 48.9061],
            [21.0346, 48.904],
            [21.0651, 48.904],
            [21.0826, 48.8985],
            [21.1185, 48.8956],
            [21.13, 48.9043],
            [21.1401, 48.899],
            [21.1557, 48.8959],
            [21.1646, 48.8807],
            [21.1796, 48.8715],
            [21.1982, 48.8845],
            [21.2232, 48.8748],
            [21.2259, 48.8806],
            [21.2385, 48.8803],
            [21.2379, 48.8691],
            [21.2583, 48.8749],
            [21.2634, 48.864],
            [21.248, 48.8305],
            [21.2601, 48.8198],
            [21.2831, 48.8108],
            [21.299, 48.815],
            [21.3034, 48.83],
            [21.3224, 48.8379],
            [21.3362, 48.8351],
            [21.3434, 48.8393],
            [21.3694, 48.8391],
            [21.3817, 48.8525],
            [21.377, 48.8788],
            [21.3812, 48.8836],
            [21.3971, 48.8863],
            [21.4166, 48.8745],
            [21.4155, 48.8861],
            [21.4253, 48.8907],
            [21.4306, 48.874],
            [21.4473, 48.8715],
            [21.457, 48.878],
            [21.4686, 48.8792],
            [21.4832, 48.8859],
            [21.4873, 48.8693],
            [21.5044, 48.8637],
            [21.4948, 48.8565],
            [21.4988, 48.844],
            [21.5225, 48.8332],
            [21.5308, 48.8222],
            [21.5274, 48.8153],
            [21.541, 48.7973],
            [21.5508, 48.7942],
            [21.5765, 48.7912],
            [21.5818, 48.7815],
            [21.6039, 48.7802],
            [21.622, 48.7727],
            [21.6388, 48.7775],
            [21.6615, 48.7808],
            [21.672, 48.7703],
            [21.6881, 48.7618],
            [21.7109, 48.7642],
            [21.7503, 48.7785],
            [21.7644, 48.7792],
            [21.7649, 48.7886],
            [21.7832, 48.7924],
            [21.7997, 48.8001],
            [21.8045, 48.8139],
            [21.794, 48.8243],
            [21.7936, 48.8463],
            [21.7892, 48.8556],
            [21.7908, 48.8695],
            [21.7875, 48.8859],
            [21.7926, 48.9014],
            [21.812, 48.9046],
            [21.8205, 48.9009],
            [21.825, 48.8928],
            [21.8535, 48.8943],
            [21.8719, 48.8936],
            [21.8918, 48.8863],
            [21.9173, 48.8805],
            [21.9305, 48.8753],
            [21.9501, 48.8625],
            [21.9586, 48.8502],
            [21.993, 48.8478],
            [22, 48.8391],
            [22.0332, 48.839],
            [22.0747, 48.8453],
            [22.0984, 48.8457],
            [22.1287, 48.8436],
            [22.1367, 48.8497],
            [22.1339, 48.8833],
            [22.1415, 48.8888],
            [22.1561, 48.8918],
            [22.1689, 48.9071],
            [22.1881, 48.9183],
            [22.1979, 48.9265],
            [22.208, 48.9267],
            [22.2373, 48.9215],
            [22.2428, 48.9159],
            [22.2503, 48.8921],
            [22.26, 48.8829],
            [22.2611, 48.8697],
            [22.2656, 48.8673],
            [22.3105, 48.8692],
            [22.3311, 48.8747],
            [22.3512, 48.8688],
            [22.3794, 48.8682],
            [22.3862, 48.865],
            [22.3825, 48.8462],
            [22.3775, 48.8355],
            [22.3862, 48.823],
            [22.3889, 48.8006],
            [22.381, 48.795],
            [22.3754, 48.7844],
            [22.3523, 48.771],
            [22.3452, 48.7513],
            [22.3629, 48.742],
            [22.3633, 48.7292],
            [22.3473, 48.7052],
            [22.3416, 48.6847],
            [22.3275, 48.6832],
            [22.3052, 48.6769],
            [22.281, 48.6609],
            [22.252, 48.6465],
            [22.2465, 48.6319],
            [22.2324, 48.6226],
            [22.2139, 48.6211],
            [22.2028, 48.6103],
            [22.1825, 48.599],
            [22.165, 48.5745],
            [22.1613, 48.5648],
            [22.1602, 48.5355],
            [22.1579, 48.52],
            [22.1395, 48.4345],
            [22.1398, 48.4154],
            [22.1481, 48.3944],
            [22.1326, 48.3883],
            [22.1376, 48.3807],
            [22.1139, 48.3798],
            [22.0906, 48.3737],
            [22.0856, 48.3813],
            [22.0605, 48.3821],
            [22.0576, 48.3775],
            [22.0296, 48.3925],
            [22.026, 48.3879],
            [21.9936, 48.3781],
            [21.9853, 48.3799],
            [21.9498, 48.3786],
            [21.9351, 48.3709],
            [21.9334, 48.364],
            [21.9195, 48.363],
            [21.9116, 48.3683],
            [21.898, 48.3627],
            [21.8899, 48.3703],
            [21.8764, 48.3604],
            [21.8608, 48.3557],
            [21.8596, 48.3636],
            [21.8395, 48.3648],
            [21.8344, 48.335],
            [21.806, 48.3374],
            [21.8017, 48.3416],
            [21.7687, 48.3401],
            [21.7604, 48.3457],
            [21.7282, 48.3492],
            [21.7144, 48.3549],
            [21.7049, 48.3825],
            [21.6846, 48.3905],
            [21.6732, 48.3889],
            [21.6633, 48.3979],
            [21.6637, 48.4124],
            [21.6495, 48.4395],
            [21.6308, 48.4553],
            [21.6244, 48.4671],
            [21.6216, 48.4839],
            [21.6237, 48.4929],
            [21.6077, 48.5082],
            [21.5967, 48.5146],
            [21.5861, 48.5121],
            [21.581, 48.5047],
            [21.5547, 48.5083],
            [21.5271, 48.5228],
            [21.5162, 48.5397],
            [21.5165, 48.5502],
            [21.4976, 48.5596],
            [21.488, 48.5592],
            [21.4629, 48.5737],
            [21.4508, 48.5756],
            [21.4412, 48.5861],
            [21.4303, 48.5852],
            [21.4222, 48.5767],
            [21.4217, 48.5631],
            [21.4163, 48.5596],
            [21.394, 48.5637],
            [21.3788, 48.5592],
            [21.35, 48.561],
            [21.3299, 48.5601],
            [21.3227, 48.5473],
            [21.3116, 48.539],
            [21.3066, 48.5234],
            [21.2982, 48.5224],
            [21.2726, 48.5285],
            [21.2681, 48.5262],
            [21.2403, 48.5399],
            [21.234, 48.5349],
            [21.2216, 48.5381],
            [21.2097, 48.5279],
            [21.1963, 48.5244],
            [21.1636, 48.5219],
            [21.1506, 48.5168],
            [21.1328, 48.4942],
            [21.1108, 48.4932],
            [21.0983, 48.5017],
            [21.0994, 48.5128],
            [21.0895, 48.5204],
            [21.0768, 48.5215],
            [21.0664, 48.5269],
            [21.0537, 48.5224],
            [21.0459, 48.5259],
            [21.0153, 48.5328],
            [20.9941, 48.5269],
            [20.9829, 48.5179],
            [20.9756, 48.5236],
            [20.9587, 48.5231],
            [20.9575, 48.5338],
            [20.9353, 48.5387],
            [20.9289, 48.5525],
            [20.9127, 48.5589],
            [20.8809, 48.5515],
            [20.8675, 48.5521],
            [20.8465, 48.5674],
            [20.8367, 48.5817],
            [20.8066, 48.5751],
            [20.7377, 48.57],
            [20.712, 48.57],
            [20.6829, 48.5608],
            [20.659, 48.5609],
            [20.648, 48.5529],
            [20.6217, 48.5481],
            [20.6164, 48.5428],
            [20.6021, 48.5429],
            [20.5874, 48.5346],
            [20.5601, 48.5434],
            [20.5475, 48.5432],
            [20.5379, 48.5278],
            [20.5217, 48.537],
            [20.5049, 48.5316],
            [20.5046, 48.5194],
            [20.5118, 48.515],
            [20.5068, 48.4911],
            [20.4968, 48.4878],
            [20.492, 48.4791],
            [20.4796, 48.4689],
            [20.4661, 48.4647],
            [20.4571, 48.4738],
            [20.4301, 48.4674],
            [20.4201, 48.4808],
            [20.4063, 48.4766],
            [20.395, 48.4638],
            [20.3859, 48.4594],
            [20.3657, 48.4551],
            [20.3323, 48.454],
            [20.3299, 48.4678],
            [20.3087, 48.4718],
            [20.3107, 48.4838],
            [20.3019, 48.4928],
            [20.3069, 48.5109],
            [20.3198, 48.5183],
            [20.3278, 48.5284],
            [20.3277, 48.5398],
            [20.3479, 48.5656],
            [20.3556, 48.5863],
            [20.3405, 48.5909],
            [20.3382, 48.5981],
            [20.3146, 48.5955],
            [20.2959, 48.6016],
            [20.3008, 48.6102],
            [20.2923, 48.6371],
            [20.3019, 48.6491],
            [20.2895, 48.6558],
            [20.2798, 48.6663],
            [20.278, 48.6738],
            [20.2636, 48.6841],
            [20.2565, 48.6962],
            [20.2417, 48.7028],
            [20.209, 48.7227],
            [20.193, 48.7198],
            [20.1885, 48.727],
            [20.1908, 48.7403],
            [20.1835, 48.741],
            [20.1805, 48.7579],
            [20.2052, 48.77],
            [20.1995, 48.7862],
            [20.2009, 48.8076],
            [20.198, 48.8184],
            [20.2395, 48.8436],
            [20.2497, 48.8679],
            [20.2628, 48.8719],
          ],
        ],
      },
      properties: {
        name: 'Košický',
        id: 'SK-KI',
        NAME_ENG: 'Košice',
        CNTRY: 'Slovakia',
        TYPE: 'kraj',
        TYPE_ENG: 'Region',
      },
      id: 'SK-KI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.9766, 48.6173],
            [18.003, 48.6255],
            [18.0138, 48.6319],
            [18.0168, 48.6391],
            [18.0146, 48.651],
            [18.0016, 48.6771],
            [17.9944, 48.6815],
            [17.9921, 48.7067],
            [18.003, 48.7122],
            [18.0154, 48.713],
            [18.0267, 48.704],
            [18.0732, 48.7047],
            [18.0913, 48.6978],
            [18.105, 48.701],
            [18.1118, 48.6953],
            [18.1327, 48.685],
            [18.1411, 48.6779],
            [18.1511, 48.6541],
            [18.169, 48.6443],
            [18.1799, 48.6489],
            [18.1978, 48.6432],
            [18.2149, 48.6425],
            [18.2239, 48.6316],
            [18.2305, 48.616],
            [18.2413, 48.6054],
            [18.2384, 48.5937],
            [18.2164, 48.5803],
            [18.23, 48.5719],
            [18.2232, 48.5648],
            [18.2263, 48.5551],
            [18.2557, 48.5554],
            [18.2747, 48.5428],
            [18.2854, 48.5285],
            [18.2999, 48.5181],
            [18.3065, 48.4991],
            [18.3386, 48.4903],
            [18.349, 48.4934],
            [18.3643, 48.4892],
            [18.3764, 48.4972],
            [18.3899, 48.5018],
            [18.4061, 48.5144],
            [18.4203, 48.5223],
            [18.4471, 48.5306],
            [18.4689, 48.5455],
            [18.4925, 48.5481],
            [18.4944, 48.5356],
            [18.5018, 48.529],
            [18.5018, 48.5214],
            [18.4928, 48.5179],
            [18.4847, 48.5008],
            [18.5125, 48.5093],
            [18.5314, 48.5051],
            [18.5414, 48.4984],
            [18.541, 48.4888],
            [18.5346, 48.4751],
            [18.5455, 48.4736],
            [18.5248, 48.4562],
            [18.5367, 48.4523],
            [18.5385, 48.4393],
            [18.5462, 48.4328],
            [18.5299, 48.4063],
            [18.5292, 48.3988],
            [18.5196, 48.3932],
            [18.5167, 48.3854],
            [18.5196, 48.3733],
            [18.5163, 48.3526],
            [18.518, 48.3452],
            [18.528, 48.3345],
            [18.5294, 48.3276],
            [18.5427, 48.3168],
            [18.5603, 48.3297],
            [18.5747, 48.3456],
            [18.6029, 48.3397],
            [18.6286, 48.3483],
            [18.6354, 48.354],
            [18.6526, 48.3514],
            [18.6557, 48.3462],
            [18.682, 48.3595],
            [18.6813, 48.3728],
            [18.6903, 48.3744],
            [18.6898, 48.3835],
            [18.7013, 48.3938],
            [18.7187, 48.4018],
            [18.7332, 48.4161],
            [18.7478, 48.4164],
            [18.759, 48.4098],
            [18.7572, 48.403],
            [18.774, 48.392],
            [18.7852, 48.3773],
            [18.8106, 48.372],
            [18.8183, 48.3638],
            [18.8204, 48.3523],
            [18.8259, 48.3456],
            [18.8252, 48.3352],
            [18.8345, 48.3218],
            [18.8468, 48.3208],
            [18.8529, 48.307],
            [18.8424, 48.2883],
            [18.8453, 48.276],
            [18.8429, 48.2684],
            [18.8268, 48.2648],
            [18.8158, 48.2586],
            [18.8093, 48.2466],
            [18.8198, 48.2385],
            [18.8141, 48.2328],
            [18.8218, 48.2168],
            [18.8333, 48.2075],
            [18.8176, 48.1952],
            [18.8104, 48.1963],
            [18.8009, 48.1879],
            [18.8033, 48.1752],
            [18.8106, 48.1661],
            [18.8257, 48.168],
            [18.8303, 48.1783],
            [18.8522, 48.1736],
            [18.8564, 48.1693],
            [18.8931, 48.1616],
            [18.9348, 48.16],
            [18.9274, 48.1747],
            [18.9292, 48.1831],
            [18.942, 48.1842],
            [18.9546, 48.1756],
            [18.9646, 48.1874],
            [18.9723, 48.1821],
            [18.9887, 48.1872],
            [19.0355, 48.1935],
            [19.0505, 48.2056],
            [19.0727, 48.1954],
            [19.071, 48.1839],
            [19.061, 48.1779],
            [19.0582, 48.1655],
            [19.0521, 48.1632],
            [19.0635, 48.149],
            [19.0674, 48.134],
            [19.0502, 48.1163],
            [19.0351, 48.1104],
            [19.026, 48.1023],
            [19.0251, 48.0955],
            [19.0158, 48.093],
            [19.0159, 48.077],
            [19.0101, 48.0709],
            [18.9998, 48.0695],
            [18.9864, 48.0571],
            [18.9342, 48.0592],
            [18.913, 48.0525],
            [18.8863, 48.0578],
            [18.8844, 48.0497],
            [18.8659, 48.0499],
            [18.8561, 48.0468],
            [18.8477, 48.0515],
            [18.8328, 48.0469],
            [18.8246, 48.0395],
            [18.8301, 48.0308],
            [18.8186, 48.0221],
            [18.8227, 48.0077],
            [18.8204, 48],
            [18.8036, 47.9848],
            [18.7854, 47.9872],
            [18.7695, 47.9843],
            [18.7597, 47.9737],
            [18.7742, 47.9636],
            [18.7787, 47.9513],
            [18.7733, 47.9457],
            [18.7746, 47.9334],
            [18.7612, 47.9202],
            [18.7683, 47.9086],
            [18.7629, 47.8985],
            [18.7663, 47.8843],
            [18.7641, 47.8704],
            [18.7711, 47.8672],
            [18.7936, 47.8648],
            [18.8009, 47.8578],
            [18.8238, 47.8505],
            [18.8323, 47.8353],
            [18.8526, 47.8263],
            [18.8438, 47.8175],
            [18.8159, 47.8195],
            [18.7947, 47.8231],
            [18.7397, 47.8129],
            [18.7315, 47.7934],
            [18.7225, 47.7854],
            [18.7096, 47.782],
            [18.6835, 47.7676],
            [18.6471, 47.7591],
            [18.6123, 47.758],
            [18.569, 47.7656],
            [18.5424, 47.7648],
            [18.5194, 47.7553],
            [18.4906, 47.753],
            [18.4752, 47.7612],
            [18.4488, 47.764],
            [18.4306, 47.756],
            [18.4175, 47.7531],
            [18.3908, 47.7508],
            [18.3703, 47.7437],
            [18.3404, 47.7375],
            [18.2927, 47.7315],
            [18.2666, 47.7375],
            [18.214, 47.7429],
            [18.1992, 47.7406],
            [18.1741, 47.7414],
            [18.1585, 47.746],
            [18.1136, 47.752],
            [18.0907, 47.7564],
            [18.0317, 47.7569],
            [18.0048, 47.7467],
            [17.9945, 47.7486],
            [17.9804, 47.7563],
            [17.9702, 47.7573],
            [17.9518, 47.7462],
            [17.9144, 47.7438],
            [17.8994, 47.7398],
            [17.8606, 47.7465],
            [17.8384, 47.7416],
            [17.8115, 47.7431],
            [17.7837, 47.7399],
            [17.7661, 47.7489],
            [17.7507, 47.7498],
            [17.7127, 47.757],
            [17.6962, 47.7699],
            [17.714, 47.7813],
            [17.7216, 47.8078],
            [17.7261, 47.8125],
            [17.7419, 47.8113],
            [17.7457, 47.8071],
            [17.7635, 47.8035],
            [17.7716, 47.8155],
            [17.7903, 47.8218],
            [17.8028, 47.8356],
            [17.7984, 47.8548],
            [17.8112, 47.8707],
            [17.8351, 47.8766],
            [17.8486, 47.892],
            [17.8771, 47.9031],
            [17.8901, 47.9138],
            [17.869, 47.9273],
            [17.8775, 47.9468],
            [17.872, 47.9591],
            [17.8725, 47.9704],
            [17.8791, 47.9848],
            [17.879, 47.9921],
            [17.8667, 48.0087],
            [17.8695, 48.0204],
            [17.8655, 48.0267],
            [17.8802, 48.0343],
            [17.8762, 48.0498],
            [17.8683, 48.0558],
            [17.8552, 48.0565],
            [17.8392, 48.0662],
            [17.8493, 48.0734],
            [17.8425, 48.0811],
            [17.8169, 48.068],
            [17.8033, 48.0729],
            [17.8019, 48.083],
            [17.8138, 48.1027],
            [17.8124, 48.1161],
            [17.7847, 48.1375],
            [17.7478, 48.1517],
            [17.7506, 48.1555],
            [17.7713, 48.1646],
            [17.7846, 48.1649],
            [17.7927, 48.157],
            [17.8007, 48.1568],
            [17.8142, 48.168],
            [17.7959, 48.1895],
            [17.8429, 48.1919],
            [17.865, 48.1986],
            [17.8752, 48.206],
            [17.8778, 48.2173],
            [17.8744, 48.2255],
            [17.8473, 48.2313],
            [17.836, 48.2437],
            [17.8601, 48.2518],
            [17.8536, 48.2623],
            [17.8628, 48.2689],
            [17.8691, 48.279],
            [17.8615, 48.2886],
            [17.8418, 48.2893],
            [17.8348, 48.2986],
            [17.8492, 48.3078],
            [17.8513, 48.332],
            [17.8623, 48.3377],
            [17.858, 48.3508],
            [17.8725, 48.36],
            [17.8824, 48.3747],
            [17.9069, 48.3995],
            [17.9096, 48.408],
            [17.923, 48.4243],
            [17.9224, 48.4294],
            [17.933, 48.4407],
            [17.945, 48.4469],
            [17.9569, 48.4588],
            [17.9549, 48.4641],
            [17.9356, 48.4728],
            [17.8912, 48.5051],
            [17.8587, 48.5099],
            [17.8477, 48.5141],
            [17.8467, 48.5206],
            [17.8641, 48.5297],
            [17.8666, 48.547],
            [17.8733, 48.562],
            [17.8915, 48.5765],
            [17.8932, 48.5865],
            [17.902, 48.5924],
            [17.9245, 48.5822],
            [17.9486, 48.5827],
            [17.9498, 48.5905],
            [17.9622, 48.5912],
            [17.9748, 48.5988],
            [17.9766, 48.6173],
          ],
        ],
      },
      properties: {
        name: 'Nitriansky',
        id: 'SK-NI',
        NAME_ENG: 'Nitra',
        CNTRY: 'Slovakia',
        TYPE: 'kraj',
        TYPE_ENG: 'Region',
      },
      id: 'SK-NI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.3862, 48.865],
            [22.3794, 48.8682],
            [22.3512, 48.8688],
            [22.3311, 48.8747],
            [22.3105, 48.8692],
            [22.2656, 48.8673],
            [22.2611, 48.8697],
            [22.26, 48.8829],
            [22.2503, 48.8921],
            [22.2428, 48.9159],
            [22.2373, 48.9215],
            [22.208, 48.9267],
            [22.1979, 48.9265],
            [22.1881, 48.9183],
            [22.1689, 48.9071],
            [22.1561, 48.8918],
            [22.1415, 48.8888],
            [22.1339, 48.8833],
            [22.1367, 48.8497],
            [22.1287, 48.8436],
            [22.0984, 48.8457],
            [22.0747, 48.8453],
            [22.0332, 48.839],
            [22, 48.8391],
            [21.993, 48.8478],
            [21.9586, 48.8502],
            [21.9501, 48.8625],
            [21.9305, 48.8753],
            [21.9173, 48.8805],
            [21.8918, 48.8863],
            [21.8719, 48.8936],
            [21.8535, 48.8943],
            [21.825, 48.8928],
            [21.8205, 48.9009],
            [21.812, 48.9046],
            [21.7926, 48.9014],
            [21.7875, 48.8859],
            [21.7908, 48.8695],
            [21.7892, 48.8556],
            [21.7936, 48.8463],
            [21.794, 48.8243],
            [21.8045, 48.8139],
            [21.7997, 48.8001],
            [21.7832, 48.7924],
            [21.7649, 48.7886],
            [21.7644, 48.7792],
            [21.7503, 48.7785],
            [21.7109, 48.7642],
            [21.6881, 48.7618],
            [21.672, 48.7703],
            [21.6615, 48.7808],
            [21.6388, 48.7775],
            [21.622, 48.7727],
            [21.6039, 48.7802],
            [21.5818, 48.7815],
            [21.5765, 48.7912],
            [21.5508, 48.7942],
            [21.541, 48.7973],
            [21.5274, 48.8153],
            [21.5308, 48.8222],
            [21.5225, 48.8332],
            [21.4988, 48.844],
            [21.4948, 48.8565],
            [21.5044, 48.8637],
            [21.4873, 48.8693],
            [21.4832, 48.8859],
            [21.4686, 48.8792],
            [21.457, 48.878],
            [21.4473, 48.8715],
            [21.4306, 48.874],
            [21.4253, 48.8907],
            [21.4155, 48.8861],
            [21.4166, 48.8745],
            [21.3971, 48.8863],
            [21.3812, 48.8836],
            [21.377, 48.8788],
            [21.3817, 48.8525],
            [21.3694, 48.8391],
            [21.3434, 48.8393],
            [21.3362, 48.8351],
            [21.3224, 48.8379],
            [21.3034, 48.83],
            [21.299, 48.815],
            [21.2831, 48.8108],
            [21.2601, 48.8198],
            [21.248, 48.8305],
            [21.2634, 48.864],
            [21.2583, 48.8749],
            [21.2379, 48.8691],
            [21.2385, 48.8803],
            [21.2259, 48.8806],
            [21.2232, 48.8748],
            [21.1982, 48.8845],
            [21.1796, 48.8715],
            [21.1646, 48.8807],
            [21.1557, 48.8959],
            [21.1401, 48.899],
            [21.13, 48.9043],
            [21.1185, 48.8956],
            [21.0826, 48.8985],
            [21.0651, 48.904],
            [21.0346, 48.904],
            [21.0199, 48.9061],
            [21.0157, 48.9235],
            [21.001, 48.9292],
            [20.9934, 48.9391],
            [20.9804, 48.9493],
            [20.9637, 48.9481],
            [20.9449, 48.9412],
            [20.9333, 48.9573],
            [20.9169, 48.9652],
            [20.9, 48.9689],
            [20.8855, 48.9682],
            [20.8443, 48.9579],
            [20.8268, 48.9613],
            [20.8169, 48.9686],
            [20.8334, 48.9748],
            [20.8305, 48.9804],
            [20.816, 48.9743],
            [20.8016, 48.9748],
            [20.7868, 48.9835],
            [20.76, 48.9843],
            [20.7686, 48.9779],
            [20.7675, 48.9698],
            [20.7784, 48.9592],
            [20.7591, 48.9529],
            [20.753, 48.9624],
            [20.7421, 48.9616],
            [20.7354, 48.9542],
            [20.7243, 48.9551],
            [20.7174, 48.9661],
            [20.6776, 48.9569],
            [20.6778, 48.9475],
            [20.6664, 48.9411],
            [20.6635, 48.9264],
            [20.6564, 48.9239],
            [20.6339, 48.9387],
            [20.6137, 48.9543],
            [20.604, 48.9676],
            [20.5814, 48.9665],
            [20.5668, 48.9625],
            [20.5464, 48.9701],
            [20.544, 48.9768],
            [20.5254, 48.9872],
            [20.5145, 49.0016],
            [20.5083, 49.0042],
            [20.4854, 48.9897],
            [20.4809, 48.9813],
            [20.5029, 48.9624],
            [20.4895, 48.9603],
            [20.4719, 48.9648],
            [20.4608, 48.9732],
            [20.4313, 48.987],
            [20.409, 48.9948],
            [20.3978, 48.9961],
            [20.3843, 48.9844],
            [20.3646, 48.9805],
            [20.3443, 48.9686],
            [20.3326, 48.9659],
            [20.3001, 48.9444],
            [20.3012, 48.9374],
            [20.3116, 48.9323],
            [20.311, 48.9231],
            [20.2978, 48.9177],
            [20.2951, 48.8995],
            [20.2969, 48.8909],
            [20.2908, 48.8863],
            [20.2957, 48.8749],
            [20.2911, 48.8641],
            [20.2795, 48.863],
            [20.2628, 48.8719],
            [20.2489, 48.8748],
            [20.2228, 48.8938],
            [20.2105, 48.8963],
            [20.1961, 48.895],
            [20.1755, 48.9077],
            [20.1612, 48.8917],
            [20.143, 48.8835],
            [20.1258, 48.8839],
            [20.0594, 48.8915],
            [20.0516, 48.9001],
            [20.0423, 48.9017],
            [20.0019, 48.9027],
            [19.9985, 48.904],
            [20.0025, 48.9107],
            [20.0332, 48.9147],
            [20.0498, 48.9226],
            [20.0507, 48.9295],
            [20.0338, 48.9304],
            [20.0187, 48.9587],
            [20.0226, 48.968],
            [20.01, 48.9729],
            [19.9992, 48.9899],
            [19.965, 49.006],
            [19.9681, 49.0114],
            [19.9854, 49.0075],
            [19.996, 49.0124],
            [20.0161, 49.0163],
            [20.016, 49.0353],
            [20.0261, 49.0522],
            [20.0244, 49.069],
            [20.038, 49.093],
            [20.0393, 49.1067],
            [20.0261, 49.112],
            [20.0099, 49.1082],
            [19.9778, 49.1082],
            [19.9542, 49.1158],
            [19.946, 49.1272],
            [19.9301, 49.1265],
            [19.9215, 49.1338],
            [19.906, 49.1279],
            [19.9005, 49.1325],
            [19.9088, 49.1435],
            [19.9098, 49.1542],
            [19.8759, 49.1893],
            [19.8737, 49.1973],
            [19.8773, 49.2027],
            [19.9024, 49.215],
            [19.9045, 49.2308],
            [19.9245, 49.2366],
            [19.9466, 49.2353],
            [19.9709, 49.2316],
            [19.9866, 49.2319],
            [20.0092, 49.2189],
            [20.0082, 49.213],
            [20.0214, 49.2027],
            [20.0394, 49.198],
            [20.05, 49.1904],
            [20.0772, 49.1792],
            [20.0881, 49.1822],
            [20.0863, 49.1935],
            [20.0885, 49.2067],
            [20.1033, 49.2244],
            [20.0969, 49.2346],
            [20.1054, 49.244],
            [20.1077, 49.256],
            [20.1249, 49.2707],
            [20.1278, 49.2853],
            [20.1436, 49.3101],
            [20.1544, 49.3158],
            [20.1627, 49.3047],
            [20.1774, 49.3159],
            [20.1708, 49.3233],
            [20.1857, 49.3278],
            [20.1979, 49.3423],
            [20.2131, 49.3449],
            [20.2235, 49.3523],
            [20.2332, 49.3458],
            [20.2444, 49.3509],
            [20.2703, 49.3472],
            [20.2754, 49.3514],
            [20.2971, 49.3509],
            [20.3091, 49.3445],
            [20.3235, 49.3497],
            [20.3267, 49.3655],
            [20.3252, 49.3783],
            [20.3326, 49.3887],
            [20.3193, 49.389],
            [20.3221, 49.4001],
            [20.3368, 49.404],
            [20.3578, 49.3989],
            [20.3669, 49.3996],
            [20.3712, 49.4069],
            [20.3815, 49.4031],
            [20.3835, 49.3916],
            [20.4095, 49.3947],
            [20.4137, 49.4042],
            [20.4299, 49.4083],
            [20.4392, 49.4148],
            [20.4722, 49.4158],
            [20.495, 49.4057],
            [20.5058, 49.404],
            [20.5088, 49.3938],
            [20.5225, 49.3925],
            [20.5459, 49.3864],
            [20.5595, 49.3791],
            [20.5766, 49.3766],
            [20.5821, 49.383],
            [20.5921, 49.3819],
            [20.6021, 49.3885],
            [20.6045, 49.3969],
            [20.6125, 49.4033],
            [20.616, 49.4166],
            [20.6412, 49.4075],
            [20.6497, 49.4012],
            [20.6647, 49.4046],
            [20.6927, 49.4197],
            [20.7147, 49.4193],
            [20.7282, 49.4143],
            [20.742, 49.4163],
            [20.7448, 49.4035],
            [20.7576, 49.4008],
            [20.7503, 49.3898],
            [20.7607, 49.3849],
            [20.759, 49.3749],
            [20.7667, 49.3705],
            [20.7818, 49.3749],
            [20.7889, 49.3605],
            [20.8013, 49.3629],
            [20.8116, 49.3579],
            [20.799, 49.3451],
            [20.8151, 49.3471],
            [20.8287, 49.3344],
            [20.8413, 49.3348],
            [20.8564, 49.3468],
            [20.866, 49.3474],
            [20.8752, 49.341],
            [20.8855, 49.3274],
            [20.9055, 49.3271],
            [20.9135, 49.3082],
            [20.9295, 49.2981],
            [20.964, 49.309],
            [20.9882, 49.3075],
            [20.998, 49.3135],
            [20.991, 49.3238],
            [20.995, 49.332],
            [21.0111, 49.3376],
            [21.0309, 49.3575],
            [21.0436, 49.3646],
            [21.0576, 49.3618],
            [21.0681, 49.365],
            [21.0945, 49.3648],
            [21.1058, 49.3754],
            [21.1015, 49.3794],
            [21.0711, 49.395],
            [21.0712, 49.4013],
            [21.0556, 49.4049],
            [21.0499, 49.4164],
            [21.0998, 49.4294],
            [21.1155, 49.4237],
            [21.121, 49.4343],
            [21.1317, 49.4339],
            [21.1533, 49.4266],
            [21.1808, 49.4088],
            [21.1817, 49.403],
            [21.194, 49.4015],
            [21.214, 49.4079],
            [21.2281, 49.4166],
            [21.2248, 49.4255],
            [21.2582, 49.4449],
            [21.2666, 49.4475],
            [21.2683, 49.4559],
            [21.2801, 49.4603],
            [21.3076, 49.4483],
            [21.3358, 49.4513],
            [21.348, 49.45],
            [21.353, 49.4449],
            [21.3832, 49.4341],
            [21.4045, 49.4329],
            [21.4086, 49.4263],
            [21.4333, 49.4127],
            [21.449, 49.4123],
            [21.4601, 49.416],
            [21.4772, 49.4142],
            [21.4882, 49.427],
            [21.4987, 49.429],
            [21.5156, 49.4187],
            [21.5261, 49.4322],
            [21.5326, 49.4352],
            [21.5531, 49.4333],
            [21.5672, 49.4414],
            [21.5877, 49.4336],
            [21.6041, 49.4354],
            [21.6158, 49.4442],
            [21.6337, 49.448],
            [21.6451, 49.4311],
            [21.66, 49.4236],
            [21.6603, 49.4172],
            [21.6832, 49.4112],
            [21.7003, 49.4191],
            [21.7231, 49.4125],
            [21.7256, 49.4021],
            [21.7367, 49.3989],
            [21.7442, 49.3917],
            [21.7617, 49.3852],
            [21.7692, 49.3657],
            [21.7765, 49.3578],
            [21.7907, 49.3544],
            [21.7905, 49.3706],
            [21.7981, 49.3784],
            [21.8135, 49.3822],
            [21.8232, 49.3887],
            [21.8422, 49.391],
            [21.8493, 49.3783],
            [21.8604, 49.3689],
            [21.8797, 49.3725],
            [21.8907, 49.3666],
            [21.8949, 49.3568],
            [21.9022, 49.3535],
            [21.919, 49.3534],
            [21.9326, 49.346],
            [21.9595, 49.3494],
            [21.9656, 49.3463],
            [21.9764, 49.3314],
            [21.9863, 49.3105],
            [22.0023, 49.2975],
            [22.0341, 49.2802],
            [22.0332, 49.2635],
            [22.0446, 49.256],
            [22.0352, 49.2261],
            [22.0506, 49.2178],
            [22.0874, 49.2086],
            [22.1186, 49.2025],
            [22.1454, 49.2002],
            [22.1472, 49.1924],
            [22.1814, 49.1821],
            [22.1886, 49.1768],
            [22.2003, 49.1774],
            [22.2122, 49.1856],
            [22.226, 49.1841],
            [22.2332, 49.1796],
            [22.2358, 49.1677],
            [22.2291, 49.1623],
            [22.2281, 49.1537],
            [22.2506, 49.1528],
            [22.3082, 49.1381],
            [22.3206, 49.1363],
            [22.3301, 49.1444],
            [22.3414, 49.1472],
            [22.3662, 49.1461],
            [22.3742, 49.1432],
            [22.3812, 49.1321],
            [22.391, 49.1289],
            [22.3929, 49.1202],
            [22.4136, 49.1045],
            [22.4301, 49.0992],
            [22.4378, 49.1029],
            [22.4634, 49.1037],
            [22.4894, 49.0893],
            [22.5012, 49.0865],
            [22.5144, 49.093],
            [22.5466, 49.0952],
            [22.5673, 49.0844],
            [22.5549, 49.0782],
            [22.5528, 49.0288],
            [22.5396, 49.0215],
            [22.548, 49.0094],
            [22.5458, 49.006],
            [22.5201, 48.9967],
            [22.4984, 48.9996],
            [22.4805, 48.9928],
            [22.4778, 48.9797],
            [22.4679, 48.973],
            [22.4263, 48.9298],
            [22.4328, 48.9213],
            [22.4263, 48.9139],
            [22.4223, 48.886],
            [22.3984, 48.877],
            [22.3862, 48.865],
          ],
        ],
      },
      properties: {
        name: 'Prešovský',
        id: 'SK-PV',
        NAME_ENG: 'Prešov',
        CNTRY: 'Slovakia',
        TYPE: 'kraj',
        TYPE_ENG: 'Region',
      },
      id: 'SK-PV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [17.3974, 48.8119],
            [17.3964, 48.8041],
            [17.3848, 48.7908],
            [17.3688, 48.7851],
            [17.3526, 48.7761],
            [17.3685, 48.7642],
            [17.3822, 48.7651],
            [17.4041, 48.7733],
            [17.4115, 48.7814],
            [17.4201, 48.7813],
            [17.4257, 48.7699],
            [17.44, 48.763],
            [17.4632, 48.7594],
            [17.475, 48.7444],
            [17.4881, 48.7415],
            [17.4918, 48.7326],
            [17.4886, 48.722],
            [17.4745, 48.7187],
            [17.4652, 48.7006],
            [17.4712, 48.6935],
            [17.4708, 48.6831],
            [17.4801, 48.6821],
            [17.4885, 48.6606],
            [17.5002, 48.65],
            [17.5061, 48.637],
            [17.5206, 48.6228],
            [17.5502, 48.6329],
            [17.5631, 48.6432],
            [17.5811, 48.6379],
            [17.6097, 48.6403],
            [17.6259, 48.6446],
            [17.6169, 48.6549],
            [17.6177, 48.6621],
            [17.6275, 48.664],
            [17.6331, 48.6572],
            [17.6769, 48.6707],
            [17.7013, 48.6832],
            [17.7233, 48.6718],
            [17.7359, 48.6573],
            [17.7451, 48.6507],
            [17.7831, 48.6404],
            [17.8225, 48.6392],
            [17.8239, 48.6528],
            [17.8441, 48.6516],
            [17.8422, 48.6421],
            [17.8491, 48.6331],
            [17.8788, 48.6313],
            [17.9097, 48.6237],
            [17.938, 48.6227],
            [17.9483, 48.6204],
            [17.9581, 48.6133],
            [17.9766, 48.6173],
            [17.9748, 48.5988],
            [17.9622, 48.5912],
            [17.9498, 48.5905],
            [17.9486, 48.5827],
            [17.9245, 48.5822],
            [17.902, 48.5924],
            [17.8932, 48.5865],
            [17.8915, 48.5765],
            [17.8733, 48.562],
            [17.8666, 48.547],
            [17.8641, 48.5297],
            [17.8467, 48.5206],
            [17.8477, 48.5141],
            [17.8587, 48.5099],
            [17.8912, 48.5051],
            [17.9356, 48.4728],
            [17.9549, 48.4641],
            [17.9569, 48.4588],
            [17.945, 48.4469],
            [17.933, 48.4407],
            [17.9224, 48.4294],
            [17.923, 48.4243],
            [17.9096, 48.408],
            [17.9069, 48.3995],
            [17.8824, 48.3747],
            [17.8725, 48.36],
            [17.858, 48.3508],
            [17.8623, 48.3377],
            [17.8513, 48.332],
            [17.8492, 48.3078],
            [17.8348, 48.2986],
            [17.8418, 48.2893],
            [17.8615, 48.2886],
            [17.8691, 48.279],
            [17.8628, 48.2689],
            [17.8536, 48.2623],
            [17.8601, 48.2518],
            [17.836, 48.2437],
            [17.8473, 48.2313],
            [17.8744, 48.2255],
            [17.8778, 48.2173],
            [17.8752, 48.206],
            [17.865, 48.1986],
            [17.8429, 48.1919],
            [17.7959, 48.1895],
            [17.8142, 48.168],
            [17.8007, 48.1568],
            [17.7927, 48.157],
            [17.7846, 48.1649],
            [17.7713, 48.1646],
            [17.7506, 48.1555],
            [17.7478, 48.1517],
            [17.7847, 48.1375],
            [17.8124, 48.1161],
            [17.8138, 48.1027],
            [17.8019, 48.083],
            [17.8033, 48.0729],
            [17.8169, 48.068],
            [17.8425, 48.0811],
            [17.8493, 48.0734],
            [17.8392, 48.0662],
            [17.8552, 48.0565],
            [17.8683, 48.0558],
            [17.8762, 48.0498],
            [17.8802, 48.0343],
            [17.8655, 48.0267],
            [17.8695, 48.0204],
            [17.8667, 48.0087],
            [17.879, 47.9921],
            [17.8791, 47.9848],
            [17.8725, 47.9704],
            [17.872, 47.9591],
            [17.8775, 47.9468],
            [17.869, 47.9273],
            [17.8901, 47.9138],
            [17.8771, 47.9031],
            [17.8486, 47.892],
            [17.8351, 47.8766],
            [17.8112, 47.8707],
            [17.7984, 47.8548],
            [17.8028, 47.8356],
            [17.7903, 47.8218],
            [17.7716, 47.8155],
            [17.7635, 47.8035],
            [17.7457, 47.8071],
            [17.7419, 47.8113],
            [17.7261, 47.8125],
            [17.7216, 47.8078],
            [17.714, 47.7813],
            [17.6962, 47.7699],
            [17.6842, 47.7792],
            [17.6359, 47.8011],
            [17.6236, 47.8131],
            [17.6098, 47.8223],
            [17.6015, 47.8225],
            [17.5757, 47.8155],
            [17.5657, 47.8181],
            [17.5485, 47.8382],
            [17.5433, 47.852],
            [17.5229, 47.8676],
            [17.5058, 47.8668],
            [17.472, 47.8808],
            [17.4574, 47.8841],
            [17.4462, 47.8988],
            [17.4351, 47.9189],
            [17.4142, 47.9349],
            [17.3958, 47.9513],
            [17.3795, 47.9584],
            [17.3403, 47.9906],
            [17.3247, 47.9964],
            [17.2827, 47.9946],
            [17.254, 48.0004],
            [17.2483, 48.0129],
            [17.2817, 48.0405],
            [17.3052, 48.0477],
            [17.2989, 48.0663],
            [17.314, 48.0616],
            [17.3344, 48.0642],
            [17.3361, 48.0791],
            [17.3512, 48.0895],
            [17.3407, 48.0932],
            [17.3355, 48.0871],
            [17.3215, 48.085],
            [17.311, 48.0903],
            [17.3035, 48.0998],
            [17.3096, 48.1191],
            [17.3212, 48.1143],
            [17.3365, 48.1204],
            [17.3426, 48.119],
            [17.3712, 48.1336],
            [17.3802, 48.1314],
            [17.3907, 48.1422],
            [17.3803, 48.1472],
            [17.376, 48.158],
            [17.3881, 48.1652],
            [17.4064, 48.1612],
            [17.4069, 48.1463],
            [17.4211, 48.1463],
            [17.439, 48.1382],
            [17.4665, 48.1448],
            [17.4738, 48.1558],
            [17.4845, 48.1631],
            [17.5011, 48.1635],
            [17.5067, 48.1699],
            [17.522, 48.1734],
            [17.5218, 48.1868],
            [17.5085, 48.1947],
            [17.5097, 48.2009],
            [17.5201, 48.2089],
            [17.5201, 48.2156],
            [17.4921, 48.2244],
            [17.4745, 48.2277],
            [17.475, 48.2397],
            [17.4826, 48.2526],
            [17.5004, 48.2657],
            [17.5152, 48.28],
            [17.5027, 48.2896],
            [17.4921, 48.284],
            [17.4712, 48.2941],
            [17.4659, 48.3021],
            [17.4553, 48.3055],
            [17.461, 48.3128],
            [17.4609, 48.3227],
            [17.4768, 48.3483],
            [17.471, 48.3635],
            [17.4534, 48.3746],
            [17.4373, 48.3704],
            [17.4319, 48.375],
            [17.4232, 48.3992],
            [17.4169, 48.4078],
            [17.4052, 48.4099],
            [17.4056, 48.4182],
            [17.3897, 48.4197],
            [17.3896, 48.4296],
            [17.3947, 48.4388],
            [17.3636, 48.439],
            [17.3473, 48.4508],
            [17.321, 48.4516],
            [17.3053, 48.4669],
            [17.3154, 48.4758],
            [17.3336, 48.4842],
            [17.3389, 48.492],
            [17.3423, 48.5068],
            [17.3296, 48.5118],
            [17.3289, 48.5206],
            [17.3086, 48.5227],
            [17.3044, 48.535],
            [17.2747, 48.5484],
            [17.276, 48.5562],
            [17.3152, 48.5751],
            [17.3218, 48.5906],
            [17.339, 48.5905],
            [17.3512, 48.6033],
            [17.3672, 48.6097],
            [17.3702, 48.6229],
            [17.3561, 48.6426],
            [17.3259, 48.6539],
            [17.3147, 48.6441],
            [17.3073, 48.6323],
            [17.2946, 48.6231],
            [17.27, 48.6007],
            [17.264, 48.5921],
            [17.2396, 48.5803],
            [17.2361, 48.5712],
            [17.213, 48.5685],
            [17.1884, 48.5597],
            [17.1142, 48.5496],
            [17.1, 48.5598],
            [17.09, 48.5533],
            [17.0507, 48.5677],
            [17.0403, 48.5555],
            [17.0086, 48.5468],
            [16.9777, 48.5445],
            [16.9758, 48.5338],
            [16.9479, 48.5339],
            [16.9446, 48.5435],
            [16.9457, 48.5614],
            [16.9367, 48.5726],
            [16.9403, 48.5841],
            [16.9358, 48.6017],
            [16.9424, 48.6059],
            [16.9411, 48.6151],
            [16.9568, 48.6243],
            [16.9645, 48.6382],
            [16.976, 48.6487],
            [16.9705, 48.6512],
            [16.9696, 48.6672],
            [16.9848, 48.6798],
            [17.0106, 48.6951],
            [16.9992, 48.704],
            [17.0009, 48.7101],
            [17.0116, 48.7166],
            [17.0157, 48.7289],
            [17.0311, 48.7459],
            [17.0415, 48.7525],
            [17.0483, 48.7662],
            [17.0688, 48.7772],
            [17.085, 48.782],
            [17.094, 48.7969],
            [17.107, 48.8057],
            [17.1074, 48.8226],
            [17.1156, 48.8309],
            [17.155, 48.8433],
            [17.1637, 48.8519],
            [17.1739, 48.8556],
            [17.1908, 48.8709],
            [17.2041, 48.8724],
            [17.2203, 48.8667],
            [17.2461, 48.8645],
            [17.2654, 48.8569],
            [17.2777, 48.8574],
            [17.2849, 48.8498],
            [17.3061, 48.8392],
            [17.3215, 48.842],
            [17.3269, 48.8333],
            [17.3548, 48.8194],
            [17.3609, 48.8123],
            [17.3773, 48.8167],
            [17.3974, 48.8119],
          ],
        ],
      },
      properties: {
        name: 'Trnavský',
        id: 'SK-TA',
        NAME_ENG: 'Trnava',
        CNTRY: 'Slovakia',
        TYPE: 'kraj',
        TYPE_ENG: 'Region',
      },
      id: 'SK-TA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.3269, 49.3155],
            [18.3342, 49.3131],
            [18.3571, 49.3169],
            [18.377, 49.3162],
            [18.3764, 49.3029],
            [18.3928, 49.2922],
            [18.4123, 49.2682],
            [18.4276, 49.2581],
            [18.4267, 49.2475],
            [18.4357, 49.2428],
            [18.4438, 49.232],
            [18.4405, 49.2243],
            [18.4489, 49.2096],
            [18.4651, 49.2029],
            [18.468, 49.1889],
            [18.4805, 49.1854],
            [18.5076, 49.1681],
            [18.5284, 49.1594],
            [18.5431, 49.1574],
            [18.544, 49.1473],
            [18.5745, 49.1405],
            [18.577, 49.1234],
            [18.5871, 49.1195],
            [18.5895, 49.1105],
            [18.5801, 49.1021],
            [18.5661, 49.0967],
            [18.5583, 49.0874],
            [18.5887, 49.0809],
            [18.6034, 49.0656],
            [18.6031, 49.0545],
            [18.5961, 49.046],
            [18.6008, 49.0394],
            [18.5993, 49.0233],
            [18.588, 49.019],
            [18.5849, 49.0109],
            [18.5697, 48.9982],
            [18.5604, 48.9936],
            [18.5302, 48.9906],
            [18.5077, 48.9804],
            [18.5087, 48.975],
            [18.4981, 48.9639],
            [18.4867, 48.9631],
            [18.4689, 48.9563],
            [18.463, 48.95],
            [18.4762, 48.9405],
            [18.5035, 48.9401],
            [18.5251, 48.9375],
            [18.539, 48.9459],
            [18.5567, 48.9478],
            [18.5623, 48.9575],
            [18.5759, 48.9704],
            [18.5921, 48.9581],
            [18.6086, 48.9585],
            [18.6256, 48.9633],
            [18.6713, 48.9549],
            [18.6941, 48.9584],
            [18.7151, 48.9434],
            [18.7154, 48.9366],
            [18.6944, 48.9152],
            [18.693, 48.9104],
            [18.7086, 48.8974],
            [18.6955, 48.8799],
            [18.7004, 48.8759],
            [18.698, 48.8608],
            [18.7005, 48.8444],
            [18.7225, 48.8379],
            [18.728, 48.8319],
            [18.7399, 48.8318],
            [18.7502, 48.827],
            [18.7657, 48.8136],
            [18.7782, 48.8123],
            [18.7758, 48.7952],
            [18.7785, 48.7899],
            [18.8084, 48.77],
            [18.8138, 48.7589],
            [18.8231, 48.7571],
            [18.825, 48.7422],
            [18.8079, 48.7151],
            [18.7941, 48.7008],
            [18.7807, 48.6948],
            [18.7646, 48.6983],
            [18.7502, 48.6882],
            [18.7463, 48.6688],
            [18.7383, 48.6589],
            [18.7161, 48.6473],
            [18.721, 48.6309],
            [18.7174, 48.6262],
            [18.6993, 48.6328],
            [18.6752, 48.6334],
            [18.6625, 48.6394],
            [18.6539, 48.639],
            [18.6432, 48.6317],
            [18.6222, 48.6051],
            [18.6092, 48.5993],
            [18.6008, 48.5889],
            [18.603, 48.5803],
            [18.5986, 48.5731],
            [18.5873, 48.5723],
            [18.5772, 48.5664],
            [18.5363, 48.5665],
            [18.5099, 48.5715],
            [18.4972, 48.5708],
            [18.492, 48.5559],
            [18.4925, 48.5481],
            [18.4689, 48.5455],
            [18.4471, 48.5306],
            [18.4203, 48.5223],
            [18.4061, 48.5144],
            [18.3899, 48.5018],
            [18.3764, 48.4972],
            [18.3643, 48.4892],
            [18.349, 48.4934],
            [18.3386, 48.4903],
            [18.3065, 48.4991],
            [18.2999, 48.5181],
            [18.2854, 48.5285],
            [18.2747, 48.5428],
            [18.2557, 48.5554],
            [18.2263, 48.5551],
            [18.2232, 48.5648],
            [18.23, 48.5719],
            [18.2164, 48.5803],
            [18.2384, 48.5937],
            [18.2413, 48.6054],
            [18.2305, 48.616],
            [18.2239, 48.6316],
            [18.2149, 48.6425],
            [18.1978, 48.6432],
            [18.1799, 48.6489],
            [18.169, 48.6443],
            [18.1511, 48.6541],
            [18.1411, 48.6779],
            [18.1327, 48.685],
            [18.1118, 48.6953],
            [18.105, 48.701],
            [18.0913, 48.6978],
            [18.0732, 48.7047],
            [18.0267, 48.704],
            [18.0154, 48.713],
            [18.003, 48.7122],
            [17.9921, 48.7067],
            [17.9944, 48.6815],
            [18.0016, 48.6771],
            [18.0146, 48.651],
            [18.0168, 48.6391],
            [18.0138, 48.6319],
            [18.003, 48.6255],
            [17.9766, 48.6173],
            [17.9581, 48.6133],
            [17.9483, 48.6204],
            [17.938, 48.6227],
            [17.9097, 48.6237],
            [17.8788, 48.6313],
            [17.8491, 48.6331],
            [17.8422, 48.6421],
            [17.8441, 48.6516],
            [17.8239, 48.6528],
            [17.8225, 48.6392],
            [17.7831, 48.6404],
            [17.7451, 48.6507],
            [17.7359, 48.6573],
            [17.7233, 48.6718],
            [17.7013, 48.6832],
            [17.6769, 48.6707],
            [17.6331, 48.6572],
            [17.6275, 48.664],
            [17.6177, 48.6621],
            [17.6169, 48.6549],
            [17.6259, 48.6446],
            [17.6097, 48.6403],
            [17.5811, 48.6379],
            [17.5631, 48.6432],
            [17.5502, 48.6329],
            [17.5206, 48.6228],
            [17.5061, 48.637],
            [17.5002, 48.65],
            [17.4885, 48.6606],
            [17.4801, 48.6821],
            [17.4708, 48.6831],
            [17.4712, 48.6935],
            [17.4652, 48.7006],
            [17.4745, 48.7187],
            [17.4886, 48.722],
            [17.4918, 48.7326],
            [17.4881, 48.7415],
            [17.475, 48.7444],
            [17.4632, 48.7594],
            [17.44, 48.763],
            [17.4257, 48.7699],
            [17.4201, 48.7813],
            [17.4115, 48.7814],
            [17.4041, 48.7733],
            [17.3822, 48.7651],
            [17.3685, 48.7642],
            [17.3526, 48.7761],
            [17.3688, 48.7851],
            [17.3848, 48.7908],
            [17.3964, 48.8041],
            [17.3974, 48.8119],
            [17.4174, 48.825],
            [17.4291, 48.8248],
            [17.4347, 48.8363],
            [17.4436, 48.8346],
            [17.4533, 48.8432],
            [17.4789, 48.8376],
            [17.5156, 48.8132],
            [17.5249, 48.8109],
            [17.5386, 48.8159],
            [17.5463, 48.8138],
            [17.5553, 48.8199],
            [17.5913, 48.8229],
            [17.5993, 48.8367],
            [17.6187, 48.8387],
            [17.6328, 48.8521],
            [17.6685, 48.8525],
            [17.6856, 48.8591],
            [17.7039, 48.8578],
            [17.7187, 48.8707],
            [17.737, 48.8798],
            [17.7574, 48.902],
            [17.7798, 48.9122],
            [17.7843, 48.9223],
            [17.8517, 48.9244],
            [17.8689, 48.9203],
            [17.8841, 48.9262],
            [17.8869, 48.937],
            [17.8997, 48.9604],
            [17.9013, 48.9784],
            [17.9112, 48.9915],
            [17.9123, 49.0077],
            [17.9345, 49.0209],
            [17.9535, 49.0217],
            [17.975, 49.0198],
            [17.9881, 49.0245],
            [18.0101, 49.0208],
            [18.0247, 49.0206],
            [18.032, 49.0246],
            [18.0704, 49.0353],
            [18.0921, 49.0537],
            [18.0992, 49.0574],
            [18.0983, 49.0661],
            [18.1168, 49.0921],
            [18.1119, 49.1105],
            [18.1193, 49.1248],
            [18.1112, 49.133],
            [18.1186, 49.1434],
            [18.118, 49.1501],
            [18.1362, 49.1602],
            [18.1312, 49.1685],
            [18.1312, 49.1864],
            [18.1494, 49.203],
            [18.1554, 49.2157],
            [18.156, 49.2276],
            [18.1463, 49.2307],
            [18.1492, 49.248],
            [18.186, 49.2703],
            [18.1857, 49.2858],
            [18.239, 49.2947],
            [18.2522, 49.2932],
            [18.3002, 49.3042],
            [18.3024, 49.3077],
            [18.3269, 49.3155],
          ],
        ],
      },
      properties: {
        name: 'Trenčiansky',
        id: 'SK-TC',
        NAME_ENG: 'Trenčín',
        CNTRY: 'Slovakia',
        TYPE: 'kraj',
        TYPE_ENG: 'Region',
      },
      id: 'SK-TC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.8737, 49.1973],
            [19.8759, 49.1893],
            [19.9098, 49.1542],
            [19.9088, 49.1435],
            [19.9005, 49.1325],
            [19.906, 49.1279],
            [19.9215, 49.1338],
            [19.9301, 49.1265],
            [19.946, 49.1272],
            [19.9542, 49.1158],
            [19.9778, 49.1082],
            [20.0099, 49.1082],
            [20.0261, 49.112],
            [20.0393, 49.1067],
            [20.038, 49.093],
            [20.0244, 49.069],
            [20.0261, 49.0522],
            [20.016, 49.0353],
            [20.0161, 49.0163],
            [19.996, 49.0124],
            [19.9854, 49.0075],
            [19.9681, 49.0114],
            [19.965, 49.006],
            [19.9992, 48.9899],
            [20.01, 48.9729],
            [20.0226, 48.968],
            [20.0187, 48.9587],
            [20.0338, 48.9304],
            [20.0507, 48.9295],
            [20.0498, 48.9226],
            [20.0332, 48.9147],
            [20.0025, 48.9107],
            [19.9985, 48.904],
            [19.9846, 48.9149],
            [19.9765, 48.916],
            [19.9651, 48.9087],
            [19.9386, 48.9074],
            [19.8808, 48.9097],
            [19.8361, 48.9056],
            [19.8198, 48.9136],
            [19.7977, 48.9141],
            [19.7762, 48.9052],
            [19.7648, 48.9049],
            [19.7477, 48.8981],
            [19.7335, 48.9092],
            [19.6887, 48.8984],
            [19.6602, 48.8984],
            [19.6382, 48.9101],
            [19.6171, 48.9174],
            [19.5914, 48.9297],
            [19.584, 48.9361],
            [19.563, 48.9413],
            [19.5484, 48.9416],
            [19.5393, 48.9467],
            [19.5163, 48.9399],
            [19.4953, 48.9433],
            [19.458, 48.9308],
            [19.4425, 48.9282],
            [19.3958, 48.9277],
            [19.3759, 48.9263],
            [19.3373, 48.8969],
            [19.3198, 48.8789],
            [19.3124, 48.8642],
            [19.2791, 48.8623],
            [19.2499, 48.8573],
            [19.2456, 48.8612],
            [19.2607, 48.8696],
            [19.2601, 48.8834],
            [19.2542, 48.8931],
            [19.235, 48.8989],
            [19.2198, 48.8946],
            [19.207, 48.8942],
            [19.1822, 48.8807],
            [19.169, 48.8839],
            [19.1608, 48.8801],
            [19.149, 48.8818],
            [19.1114, 48.8796],
            [19.1002, 48.8773],
            [19.0804, 48.8798],
            [19.0616, 48.8783],
            [19.0534, 48.8838],
            [19.0334, 48.8807],
            [19.0267, 48.8666],
            [19.0104, 48.8506],
            [18.9892, 48.8494],
            [18.9968, 48.825],
            [18.9898, 48.8187],
            [18.9898, 48.8076],
            [18.9946, 48.8017],
            [19.0163, 48.7913],
            [19.0139, 48.7688],
            [18.9979, 48.758],
            [18.9897, 48.7479],
            [18.9631, 48.7456],
            [18.9405, 48.7496],
            [18.927, 48.7428],
            [18.9176, 48.7432],
            [18.9071, 48.7498],
            [18.8899, 48.7515],
            [18.8828, 48.7689],
            [18.8733, 48.7698],
            [18.857, 48.7637],
            [18.8445, 48.7524],
            [18.8292, 48.7478],
            [18.825, 48.7422],
            [18.8231, 48.7571],
            [18.8138, 48.7589],
            [18.8084, 48.77],
            [18.7785, 48.7899],
            [18.7758, 48.7952],
            [18.7782, 48.8123],
            [18.7657, 48.8136],
            [18.7502, 48.827],
            [18.7399, 48.8318],
            [18.728, 48.8319],
            [18.7225, 48.8379],
            [18.7005, 48.8444],
            [18.698, 48.8608],
            [18.7004, 48.8759],
            [18.6955, 48.8799],
            [18.7086, 48.8974],
            [18.693, 48.9104],
            [18.6944, 48.9152],
            [18.7154, 48.9366],
            [18.7151, 48.9434],
            [18.6941, 48.9584],
            [18.6713, 48.9549],
            [18.6256, 48.9633],
            [18.6086, 48.9585],
            [18.5921, 48.9581],
            [18.5759, 48.9704],
            [18.5623, 48.9575],
            [18.5567, 48.9478],
            [18.539, 48.9459],
            [18.5251, 48.9375],
            [18.5035, 48.9401],
            [18.4762, 48.9405],
            [18.463, 48.95],
            [18.4689, 48.9563],
            [18.4867, 48.9631],
            [18.4981, 48.9639],
            [18.5087, 48.975],
            [18.5077, 48.9804],
            [18.5302, 48.9906],
            [18.5604, 48.9936],
            [18.5697, 48.9982],
            [18.5849, 49.0109],
            [18.588, 49.019],
            [18.5993, 49.0233],
            [18.6008, 49.0394],
            [18.5961, 49.046],
            [18.6031, 49.0545],
            [18.6034, 49.0656],
            [18.5887, 49.0809],
            [18.5583, 49.0874],
            [18.5661, 49.0967],
            [18.5801, 49.1021],
            [18.5895, 49.1105],
            [18.5871, 49.1195],
            [18.577, 49.1234],
            [18.5745, 49.1405],
            [18.544, 49.1473],
            [18.5431, 49.1574],
            [18.5284, 49.1594],
            [18.5076, 49.1681],
            [18.4805, 49.1854],
            [18.468, 49.1889],
            [18.4651, 49.2029],
            [18.4489, 49.2096],
            [18.4405, 49.2243],
            [18.4438, 49.232],
            [18.4357, 49.2428],
            [18.4267, 49.2475],
            [18.4276, 49.2581],
            [18.4123, 49.2682],
            [18.3928, 49.2922],
            [18.3764, 49.3029],
            [18.377, 49.3162],
            [18.3571, 49.3169],
            [18.3342, 49.3131],
            [18.3269, 49.3155],
            [18.3665, 49.3234],
            [18.3792, 49.3309],
            [18.3908, 49.3454],
            [18.4045, 49.3503],
            [18.3997, 49.3557],
            [18.4142, 49.3655],
            [18.4164, 49.3705],
            [18.4056, 49.3971],
            [18.4121, 49.4006],
            [18.4347, 49.3915],
            [18.4484, 49.3936],
            [18.4551, 49.4014],
            [18.4764, 49.4088],
            [18.4774, 49.4185],
            [18.4836, 49.4267],
            [18.5032, 49.4368],
            [18.5063, 49.4435],
            [18.5214, 49.4488],
            [18.5239, 49.4563],
            [18.5461, 49.4664],
            [18.5434, 49.4768],
            [18.5353, 49.4855],
            [18.5374, 49.4925],
            [18.5472, 49.5009],
            [18.5684, 49.508],
            [18.587, 49.5097],
            [18.6025, 49.5079],
            [18.6045, 49.4972],
            [18.6376, 49.4969],
            [18.6462, 49.5025],
            [18.6736, 49.5074],
            [18.6845, 49.5067],
            [18.6907, 49.5005],
            [18.7118, 49.5019],
            [18.7443, 49.4895],
            [18.7567, 49.4897],
            [18.7668, 49.4978],
            [18.7846, 49.5072],
            [18.8009, 49.5095],
            [18.8091, 49.5155],
            [18.835, 49.5203],
            [18.8558, 49.5175],
            [18.8682, 49.5199],
            [18.8744, 49.5161],
            [18.8988, 49.5144],
            [18.9341, 49.5196],
            [18.9643, 49.5082],
            [18.9736, 49.5019],
            [18.9634, 49.4836],
            [18.9699, 49.4774],
            [18.9615, 49.4549],
            [18.9746, 49.4521],
            [18.9791, 49.4413],
            [18.9893, 49.4316],
            [18.9856, 49.4219],
            [18.9708, 49.414],
            [18.9738, 49.4009],
            [18.9807, 49.3954],
            [18.9994, 49.3988],
            [19.0113, 49.3952],
            [19.0362, 49.3958],
            [19.0478, 49.4025],
            [19.0535, 49.4144],
            [19.0717, 49.4128],
            [19.0734, 49.4171],
            [19.0919, 49.4161],
            [19.1109, 49.4041],
            [19.1276, 49.4034],
            [19.1395, 49.4113],
            [19.152, 49.4049],
            [19.1789, 49.4141],
            [19.1918, 49.4107],
            [19.1948, 49.4285],
            [19.1892, 49.4366],
            [19.1995, 49.4474],
            [19.2104, 49.4519],
            [19.2208, 49.4494],
            [19.2302, 49.4644],
            [19.2216, 49.4744],
            [19.234, 49.4871],
            [19.237, 49.4963],
            [19.2366, 49.512],
            [19.2631, 49.528],
            [19.2645, 49.5322],
            [19.2819, 49.5348],
            [19.3015, 49.5337],
            [19.3114, 49.5368],
            [19.3205, 49.53],
            [19.3358, 49.5339],
            [19.3602, 49.5357],
            [19.3666, 49.5391],
            [19.3663, 49.5533],
            [19.3749, 49.5587],
            [19.3732, 49.5679],
            [19.396, 49.5725],
            [19.4096, 49.5816],
            [19.4108, 49.591],
            [19.421, 49.5954],
            [19.4459, 49.5999],
            [19.4435, 49.6075],
            [19.4495, 49.6134],
            [19.4701, 49.6127],
            [19.4765, 49.605],
            [19.4711, 49.5964],
            [19.479, 49.5892],
            [19.4999, 49.5817],
            [19.5101, 49.582],
            [19.5197, 49.5743],
            [19.5312, 49.572],
            [19.5295, 49.5618],
            [19.5307, 49.5378],
            [19.5529, 49.5087],
            [19.555, 49.4902],
            [19.567, 49.4807],
            [19.57, 49.4719],
            [19.5835, 49.4617],
            [19.6038, 49.4555],
            [19.6151, 49.4477],
            [19.6257, 49.4495],
            [19.6409, 49.4572],
            [19.6404, 49.4424],
            [19.6508, 49.4367],
            [19.6332, 49.4103],
            [19.6623, 49.4081],
            [19.6994, 49.3989],
            [19.7204, 49.3866],
            [19.7325, 49.3919],
            [19.7444, 49.4054],
            [19.7699, 49.4135],
            [19.7932, 49.4097],
            [19.7935, 49.3861],
            [19.7962, 49.3777],
            [19.8059, 49.3672],
            [19.805, 49.3568],
            [19.7976, 49.3475],
            [19.8021, 49.3339],
            [19.8112, 49.3262],
            [19.7979, 49.3029],
            [19.8109, 49.2962],
            [19.8194, 49.2878],
            [19.8244, 49.2768],
            [19.8091, 49.2735],
            [19.7943, 49.2666],
            [19.7914, 49.2511],
            [19.7691, 49.2358],
            [19.7722, 49.2306],
            [19.7636, 49.2147],
            [19.7728, 49.2066],
            [19.7858, 49.2008],
            [19.799, 49.2001],
            [19.8101, 49.2062],
            [19.8209, 49.1992],
            [19.8302, 49.2024],
            [19.8407, 49.1974],
            [19.8737, 49.1973],
          ],
        ],
      },
      properties: {
        name: 'Žilinský',
        id: 'SK-ZI',
        NAME_ENG: 'Žilina',
        CNTRY: 'Slovakia',
        TYPE: 'kraj',
        TYPE_ENG: 'Region',
      },
      id: 'SK-ZI',
    },
  ],
}
export default map
