var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [4.9511, 51.4507],
              [4.9392, 51.4459],
              [4.9394, 51.431],
              [4.9279, 51.43],
              [4.9174, 51.4373],
              [4.9236, 51.4466],
              [4.9511, 51.4507],
            ],
          ],
          [
            [
              [5.2382, 51.2601],
              [5.2172, 51.2454],
              [5.2163, 51.2206],
              [5.2175, 51.2104],
              [5.2237, 51.2096],
              [5.2292, 51.203],
              [5.2396, 51.2],
              [5.2615, 51.1827],
              [5.2629, 51.1497],
              [5.252, 51.1409],
              [5.2351, 51.1322],
              [5.2224, 51.1381],
              [5.2068, 51.1389],
              [5.2035, 51.129],
              [5.1945, 51.1217],
              [5.1833, 51.1178],
              [5.1679, 51.1196],
              [5.1521, 51.1196],
              [5.1453, 51.1173],
              [5.1314, 51.1176],
              [5.1279, 51.1079],
              [5.1043, 51.0957],
              [5.0965, 51.0858],
              [5.0849, 51.0828],
              [5.0688, 51.0811],
              [5.0501, 51.0758],
              [5.0322, 51.0731],
              [5.0133, 51.0748],
              [5.0148, 51.0688],
              [5.0109, 51.0572],
              [5.0012, 51.0556],
              [4.9942, 51.0498],
              [4.982, 51.0344],
              [4.9724, 51.0367],
              [4.9665, 51.0453],
              [4.9472, 51.0364],
              [4.9228, 51.036],
              [4.9066, 51.0306],
              [4.9044, 51.0241],
              [4.883, 51.0185],
              [4.8668, 51.0209],
              [4.8598, 51.0159],
              [4.8443, 51.0115],
              [4.8268, 51.0131],
              [4.8179, 51.0109],
              [4.8101, 51.0148],
              [4.8126, 51.0199],
              [4.8079, 51.0255],
              [4.7985, 51.0295],
              [4.7919, 51.0387],
              [4.776, 51.0349],
              [4.7611, 51.0244],
              [4.7389, 51.0277],
              [4.7325, 51.0206],
              [4.7098, 51.0117],
              [4.7058, 51.0013],
              [4.6974, 50.9987],
              [4.7073, 51.0145],
              [4.6918, 51.0164],
              [4.6842, 51.0248],
              [4.6627, 51.0195],
              [4.6554, 51.0207],
              [4.6363, 51.018],
              [4.6299, 51.0107],
              [4.6116, 51.0115],
              [4.6048, 51.0087],
              [4.6016, 50.995],
              [4.5875, 50.9945],
              [4.5718, 50.9977],
              [4.5507, 51.0044],
              [4.5328, 50.9968],
              [4.5305, 50.9926],
              [4.5057, 51.0034],
              [4.4906, 51.0084],
              [4.4855, 51.0048],
              [4.49, 50.997],
              [4.4807, 50.9915],
              [4.464, 50.9932],
              [4.4533, 51.0054],
              [4.4389, 51.0024],
              [4.4198, 50.9962],
              [4.4189, 50.9995],
              [4.4055, 51.0001],
              [4.3956, 50.9953],
              [4.3903, 51.0045],
              [4.3899, 51.0104],
              [4.3735, 51.0138],
              [4.3753, 51.0219],
              [4.3675, 51.0238],
              [4.3613, 51.0168],
              [4.3432, 51.0202],
              [4.3475, 51.0266],
              [4.3237, 51.0273],
              [4.3101, 51.0362],
              [4.2984, 51.0393],
              [4.2837, 51.0397],
              [4.2813, 51.0428],
              [4.2678, 51.042],
              [4.2647, 51.0379],
              [4.251, 51.0344],
              [4.2404, 51.0371],
              [4.2329, 51.0434],
              [4.2202, 51.0353],
              [4.2067, 51.034],
              [4.1955, 51.0404],
              [4.1891, 51.0483],
              [4.1986, 51.0547],
              [4.1949, 51.0604],
              [4.1869, 51.0647],
              [4.1898, 51.0798],
              [4.1858, 51.0852],
              [4.1732, 51.0909],
              [4.1731, 51.0973],
              [4.1778, 51.1029],
              [4.1918, 51.1073],
              [4.2119, 51.1219],
              [4.2212, 51.122],
              [4.244, 51.115],
              [4.2605, 51.1163],
              [4.2789, 51.1228],
              [4.3081, 51.1246],
              [4.3229, 51.13],
              [4.3274, 51.1355],
              [4.3314, 51.1554],
              [4.3291, 51.1739],
              [4.3307, 51.1837],
              [4.3184, 51.1869],
              [4.3194, 51.1971],
              [4.3233, 51.204],
              [4.3073, 51.2025],
              [4.3035, 51.2107],
              [4.3111, 51.2115],
              [4.2994, 51.2322],
              [4.2964, 51.2486],
              [4.2901, 51.2519],
              [4.2913, 51.2598],
              [4.3014, 51.2613],
              [4.3061, 51.2668],
              [4.3186, 51.2753],
              [4.322, 51.2808],
              [4.3161, 51.2887],
              [4.3083, 51.2926],
              [4.2797, 51.3019],
              [4.2737, 51.3088],
              [4.2718, 51.3174],
              [4.2719, 51.3356],
              [4.2656, 51.3449],
              [4.2534, 51.3502],
              [4.2371, 51.3529],
              [4.2235, 51.3611],
              [4.2161, 51.374],
              [4.2723, 51.3761],
              [4.3348, 51.3772],
              [4.3425, 51.3584],
              [4.3662, 51.3575],
              [4.3832, 51.3551],
              [4.3947, 51.3564],
              [4.4223, 51.3651],
              [4.4317, 51.3749],
              [4.3925, 51.4072],
              [4.384, 51.4203],
              [4.3984, 51.4345],
              [4.3924, 51.4446],
              [4.3863, 51.4497],
              [4.4413, 51.4687],
              [4.4629, 51.4711],
              [4.4709, 51.4767],
              [4.4867, 51.4774],
              [4.5349, 51.4823],
              [4.5473, 51.4726],
              [4.5301, 51.4491],
              [4.5355, 51.4231],
              [4.5754, 51.4324],
              [4.6286, 51.4262],
              [4.6396, 51.4227],
              [4.6516, 51.4266],
              [4.6687, 51.4264],
              [4.6669, 51.4445],
              [4.6841, 51.4507],
              [4.6928, 51.4519],
              [4.7031, 51.4671],
              [4.7153, 51.4689],
              [4.7291, 51.4841],
              [4.7452, 51.4894],
              [4.7523, 51.4994],
              [4.7602, 51.5024],
              [4.7806, 51.5035],
              [4.7844, 51.4999],
              [4.7979, 51.4996],
              [4.816, 51.495],
              [4.8196, 51.4863],
              [4.8255, 51.4827],
              [4.8413, 51.4807],
              [4.8359, 51.4629],
              [4.8458, 51.4589],
              [4.8341, 51.4547],
              [4.8245, 51.4477],
              [4.8265, 51.4342],
              [4.8317, 51.4218],
              [4.8404, 51.4214],
              [4.8449, 51.417],
              [4.8045, 51.4273],
              [4.7892, 51.4293],
              [4.7866, 51.4325],
              [4.7724, 51.4313],
              [4.7698, 51.4282],
              [4.7741, 51.415],
              [4.7916, 51.409],
              [4.8388, 51.4149],
              [4.8513, 51.4155],
              [4.8682, 51.4121],
              [4.882, 51.4168],
              [4.9014, 51.4142],
              [4.9113, 51.403],
              [4.9255, 51.399],
              [4.9361, 51.4025],
              [4.9649, 51.4242],
              [5.0042, 51.445],
              [5.0089, 51.4545],
              [5.0063, 51.4657],
              [5.0099, 51.4722],
              [5.0209, 51.4811],
              [5.0336, 51.4874],
              [5.0452, 51.4725],
              [5.0511, 51.4712],
              [5.0792, 51.4707],
              [5.1054, 51.4315],
              [5.094, 51.4198],
              [5.0728, 51.393],
              [5.1141, 51.3641],
              [5.1257, 51.3513],
              [5.133, 51.3474],
              [5.1357, 51.3146],
              [5.1638, 51.3093],
              [5.173, 51.3142],
              [5.2011, 51.3202],
              [5.2414, 51.3035],
              [5.2416, 51.3018],
              [5.2267, 51.2666],
              [5.2382, 51.2601],
            ],
          ],
        ],
      },
      properties: {name: 'Antwerpen', id: 'BE-VAN', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-VAN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.1002, 50.7061],
            [4.1105, 50.7168],
            [4.1157, 50.7131],
            [4.1328, 50.7173],
            [4.1415, 50.7236],
            [4.1427, 50.7286],
            [4.1551, 50.7299],
            [4.1724, 50.7213],
            [4.1796, 50.7127],
            [4.1796, 50.7062],
            [4.2079, 50.7082],
            [4.233, 50.6977],
            [4.2404, 50.6909],
            [4.2476, 50.6886],
            [4.2625, 50.6991],
            [4.279, 50.6986],
            [4.2915, 50.6944],
            [4.3068, 50.6991],
            [4.3148, 50.7197],
            [4.3241, 50.7194],
            [4.3314, 50.7247],
            [4.3259, 50.7292],
            [4.3356, 50.7341],
            [4.3413, 50.7325],
            [4.3523, 50.7167],
            [4.3738, 50.7164],
            [4.3738, 50.7289],
            [4.4013, 50.7337],
            [4.4251, 50.7352],
            [4.44, 50.7414],
            [4.463, 50.7536],
            [4.4868, 50.7516],
            [4.4944, 50.7556],
            [4.4998, 50.7525],
            [4.4929, 50.7443],
            [4.5105, 50.7348],
            [4.5113, 50.731],
            [4.5232, 50.7281],
            [4.5296, 50.7293],
            [4.5353, 50.7387],
            [4.5579, 50.7484],
            [4.5718, 50.7468],
            [4.5806, 50.7534],
            [4.5801, 50.7577],
            [4.5983, 50.7631],
            [4.6016, 50.7544],
            [4.5998, 50.7458],
            [4.6053, 50.7412],
            [4.6265, 50.744],
            [4.6356, 50.7427],
            [4.6478, 50.7482],
            [4.6568, 50.7557],
            [4.6492, 50.7595],
            [4.6388, 50.7724],
            [4.6445, 50.7797],
            [4.6508, 50.7983],
            [4.6668, 50.792],
            [4.684, 50.7931],
            [4.6942, 50.7894],
            [4.7057, 50.7909],
            [4.7129, 50.7958],
            [4.7169, 50.789],
            [4.7281, 50.7972],
            [4.7505, 50.8071],
            [4.7617, 50.8067],
            [4.7853, 50.7992],
            [4.7931, 50.7984],
            [4.8083, 50.7861],
            [4.8145, 50.7768],
            [4.8372, 50.7759],
            [4.8331, 50.7693],
            [4.8397, 50.7637],
            [4.8477, 50.7676],
            [4.8622, 50.7644],
            [4.8777, 50.7664],
            [4.8852, 50.7619],
            [4.8954, 50.7704],
            [4.9083, 50.7651],
            [4.9047, 50.7605],
            [4.9126, 50.7559],
            [4.908, 50.7498],
            [4.9392, 50.7478],
            [4.9544, 50.7509],
            [4.9546, 50.755],
            [4.9701, 50.7625],
            [4.9698, 50.7674],
            [4.9835, 50.7701],
            [5.0046, 50.7656],
            [5.021, 50.7503],
            [5.0173, 50.7432],
            [5.0175, 50.7328],
            [5.0095, 50.7293],
            [4.9973, 50.7275],
            [4.9965, 50.7181],
            [5.0104, 50.7116],
            [5.0179, 50.7032],
            [5.015, 50.6997],
            [5.0032, 50.6962],
            [5.0004, 50.6913],
            [4.998, 50.6699],
            [5.0032, 50.6648],
            [4.9881, 50.6526],
            [4.9883, 50.6441],
            [4.9844, 50.6419],
            [4.9776, 50.6392],
            [4.9652, 50.6476],
            [4.9495, 50.6456],
            [4.9398, 50.6391],
            [4.9283, 50.6267],
            [4.9128, 50.6253],
            [4.8996, 50.6283],
            [4.9011, 50.6214],
            [4.8898, 50.6208],
            [4.8571, 50.6155],
            [4.8331, 50.6077],
            [4.8163, 50.6081],
            [4.7877, 50.6005],
            [4.796, 50.5912],
            [4.7916, 50.5893],
            [4.783, 50.5997],
            [4.7703, 50.6006],
            [4.7509, 50.5914],
            [4.7408, 50.5967],
            [4.746, 50.6],
            [4.7274, 50.6101],
            [4.7135, 50.6072],
            [4.7111, 50.5942],
            [4.6901, 50.5985],
            [4.6725, 50.6073],
            [4.6572, 50.6015],
            [4.6558, 50.5916],
            [4.6617, 50.5761],
            [4.6747, 50.5707],
            [4.6696, 50.5653],
            [4.6589, 50.5637],
            [4.6451, 50.5591],
            [4.6336, 50.5652],
            [4.6199, 50.5625],
            [4.618, 50.5687],
            [4.6014, 50.5682],
            [4.5893, 50.5611],
            [4.5757, 50.5605],
            [4.5745, 50.5548],
            [4.5786, 50.5406],
            [4.513, 50.5251],
            [4.5016, 50.5284],
            [4.502, 50.5393],
            [4.4892, 50.5376],
            [4.4838, 50.5338],
            [4.4764, 50.5366],
            [4.4698, 50.5284],
            [4.4657, 50.5459],
            [4.4723, 50.5526],
            [4.4705, 50.5579],
            [4.4507, 50.5538],
            [4.445, 50.559],
            [4.4245, 50.5579],
            [4.4188, 50.5639],
            [4.4018, 50.5623],
            [4.3831, 50.5628],
            [4.3783, 50.5587],
            [4.3616, 50.5618],
            [4.3587, 50.5566],
            [4.3444, 50.5529],
            [4.3462, 50.5484],
            [4.3391, 50.5432],
            [4.3328, 50.5432],
            [4.3357, 50.5552],
            [4.3323, 50.5601],
            [4.3345, 50.568],
            [4.3036, 50.5671],
            [4.2991, 50.5765],
            [4.2854, 50.5842],
            [4.282, 50.5893],
            [4.2717, 50.5924],
            [4.2513, 50.5923],
            [4.2465, 50.5971],
            [4.252, 50.601],
            [4.2478, 50.6107],
            [4.254, 50.6156],
            [4.2383, 50.6226],
            [4.201, 50.6233],
            [4.2024, 50.6376],
            [4.2054, 50.6461],
            [4.2004, 50.6517],
            [4.1778, 50.6625],
            [4.1696, 50.6571],
            [4.1614, 50.6472],
            [4.1477, 50.6384],
            [4.1466, 50.6334],
            [4.1331, 50.6287],
            [4.1191, 50.6337],
            [4.1064, 50.632],
            [4.0994, 50.638],
            [4.0947, 50.6499],
            [4.1006, 50.6622],
            [4.0917, 50.6685],
            [4.0968, 50.6758],
            [4.0945, 50.6802],
            [4.0996, 50.6917],
            [4.0941, 50.7018],
            [4.1002, 50.7061],
          ],
        ],
      },
      properties: {name: 'Walloon Brabant', id: 'BE-WBR', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-WBR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.4309, 50.8963],
            [4.4285, 50.8895],
            [4.4373, 50.8811],
            [4.4295, 50.8771],
            [4.4233, 50.8682],
            [4.4366, 50.8587],
            [4.4596, 50.853],
            [4.4678, 50.8421],
            [4.4679, 50.8336],
            [4.4757, 50.8232],
            [4.466, 50.8186],
            [4.4579, 50.8181],
            [4.4505, 50.8105],
            [4.4552, 50.8062],
            [4.4711, 50.8051],
            [4.4846, 50.7896],
            [4.4759, 50.7913],
            [4.4629, 50.7878],
            [4.4376, 50.7773],
            [4.4342, 50.7785],
            [4.3847, 50.7647],
            [4.3829, 50.77],
            [4.3683, 50.773],
            [4.3643, 50.7717],
            [4.3473, 50.7748],
            [4.3378, 50.7741],
            [4.3244, 50.7818],
            [4.3181, 50.7956],
            [4.3082, 50.7984],
            [4.3045, 50.8048],
            [4.3074, 50.8098],
            [4.3018, 50.8131],
            [4.2827, 50.8081],
            [4.2746, 50.8115],
            [4.261, 50.8106],
            [4.2574, 50.8163],
            [4.2474, 50.8207],
            [4.2558, 50.8268],
            [4.2557, 50.8331],
            [4.2606, 50.8364],
            [4.2859, 50.8399],
            [4.2818, 50.8673],
            [4.2987, 50.8794],
            [4.2962, 50.8888],
            [4.3158, 50.8931],
            [4.3279, 50.8997],
            [4.342, 50.9024],
            [4.362, 50.9019],
            [4.3758, 50.8985],
            [4.3841, 50.9021],
            [4.3899, 50.91],
            [4.4052, 50.9135],
            [4.4135, 50.9071],
            [4.4237, 50.9027],
            [4.4259, 50.8987],
            [4.4309, 50.8963],
          ],
        ],
      },
      properties: {name: 'Brussels Capital Region', id: 'BE-BRU', CNTRY: 'Belgium', TYPE: 'Region'},
      id: 'BE-BRU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [2.8626, 50.7067],
              [2.8696, 50.7125],
              [2.8601, 50.7216],
              [2.8536, 50.7238],
              [2.8407, 50.735],
              [2.8512, 50.7405],
              [2.8417, 50.7502],
              [2.8564, 50.7576],
              [2.8684, 50.76],
              [2.8703, 50.7551],
              [2.888, 50.7541],
              [2.9213, 50.7571],
              [2.9207, 50.7647],
              [2.9461, 50.7727],
              [2.9608, 50.7738],
              [2.9617, 50.7782],
              [2.9473, 50.7908],
              [2.9474, 50.7946],
              [2.9604, 50.798],
              [2.9658, 50.8022],
              [2.9907, 50.8065],
              [3.0008, 50.8105],
              [3.012, 50.8075],
              [3.0153, 50.8025],
              [3.0091, 50.7955],
              [3.0109, 50.7876],
              [3.0159, 50.7836],
              [3.0299, 50.78],
              [3.0194, 50.773],
              [3.0134, 50.7682],
              [2.9709, 50.749],
              [2.9527, 50.7513],
              [2.9399, 50.7441],
              [2.9399, 50.7388],
              [2.9462, 50.7309],
              [2.9339, 50.7284],
              [2.9301, 50.7086],
              [2.9226, 50.7022],
              [2.91, 50.7009],
              [2.91, 50.6927],
              [2.9013, 50.6922],
              [2.8891, 50.7039],
              [2.882, 50.7055],
              [2.8682, 50.703],
              [2.8626, 50.7067],
            ],
          ],
          [
            [
              [3.4597, 50.7672],
              [3.4784, 50.759],
              [3.4973, 50.757],
              [3.5204, 50.7603],
              [3.5331, 50.7643],
              [3.547, 50.747],
              [3.5451, 50.7381],
              [3.5485, 50.7321],
              [3.5581, 50.7341],
              [3.5678, 50.7274],
              [3.5879, 50.7279],
              [3.5985, 50.7315],
              [3.6101, 50.7314],
              [3.6202, 50.723],
              [3.6303, 50.7204],
              [3.6408, 50.7217],
              [3.6422, 50.7379],
              [3.6591, 50.7463],
              [3.6604, 50.7532],
              [3.6688, 50.7541],
              [3.6702, 50.7635],
              [3.6897, 50.7741],
              [3.7086, 50.7741],
              [3.7147, 50.767],
              [3.731, 50.7708],
              [3.7383, 50.7701],
              [3.7538, 50.7783],
              [3.7543, 50.7706],
              [3.7611, 50.7615],
              [3.7684, 50.7576],
              [3.7757, 50.7471],
              [3.7975, 50.7487],
              [3.8157, 50.7488],
              [3.8192, 50.7436],
              [3.8397, 50.7408],
              [3.846, 50.7449],
              [3.8621, 50.7496],
              [3.8712, 50.7476],
              [3.8862, 50.7488],
              [3.8994, 50.7362],
              [3.8968, 50.7317],
              [3.8978, 50.727],
              [3.8904, 50.7136],
              [3.8909, 50.7091],
              [3.9019, 50.7061],
              [3.9138, 50.6973],
              [3.9128, 50.6901],
              [3.9261, 50.6939],
              [3.9368, 50.6896],
              [3.991, 50.6867],
              [4.0091, 50.6967],
              [4.0202, 50.6935],
              [4.033, 50.6927],
              [4.048, 50.7002],
              [4.0544, 50.6999],
              [4.0601, 50.6934],
              [4.0727, 50.7094],
              [4.0846, 50.7103],
              [4.1002, 50.7061],
              [4.0941, 50.7018],
              [4.0996, 50.6917],
              [4.0945, 50.6802],
              [4.0968, 50.6758],
              [4.0917, 50.6685],
              [4.1006, 50.6622],
              [4.0947, 50.6499],
              [4.0994, 50.638],
              [4.1064, 50.632],
              [4.1191, 50.6337],
              [4.1331, 50.6287],
              [4.1466, 50.6334],
              [4.1477, 50.6384],
              [4.1614, 50.6472],
              [4.1696, 50.6571],
              [4.1778, 50.6625],
              [4.2004, 50.6517],
              [4.2054, 50.6461],
              [4.2024, 50.6376],
              [4.201, 50.6233],
              [4.2383, 50.6226],
              [4.254, 50.6156],
              [4.2478, 50.6107],
              [4.252, 50.601],
              [4.2465, 50.5971],
              [4.2513, 50.5923],
              [4.2717, 50.5924],
              [4.282, 50.5893],
              [4.2854, 50.5842],
              [4.2991, 50.5765],
              [4.3036, 50.5671],
              [4.3345, 50.568],
              [4.3323, 50.5601],
              [4.3357, 50.5552],
              [4.3328, 50.5432],
              [4.3391, 50.5432],
              [4.3462, 50.5484],
              [4.3444, 50.5529],
              [4.3587, 50.5566],
              [4.3616, 50.5618],
              [4.3783, 50.5587],
              [4.3831, 50.5628],
              [4.4018, 50.5623],
              [4.4188, 50.5639],
              [4.4245, 50.5579],
              [4.445, 50.559],
              [4.4507, 50.5538],
              [4.4705, 50.5579],
              [4.4723, 50.5526],
              [4.4657, 50.5459],
              [4.4698, 50.5284],
              [4.4764, 50.5366],
              [4.4838, 50.5338],
              [4.4892, 50.5376],
              [4.502, 50.5393],
              [4.5016, 50.5284],
              [4.513, 50.5251],
              [4.5786, 50.5406],
              [4.5795, 50.5275],
              [4.5575, 50.5051],
              [4.5598, 50.4924],
              [4.5631, 50.4884],
              [4.5793, 50.4984],
              [4.5853, 50.495],
              [4.5896, 50.4861],
              [4.6006, 50.4759],
              [4.5967, 50.4681],
              [4.5879, 50.4622],
              [4.5844, 50.454],
              [4.5759, 50.4538],
              [4.5847, 50.445],
              [4.5765, 50.4367],
              [4.5843, 50.4363],
              [4.5932, 50.4278],
              [4.6063, 50.4263],
              [4.6128, 50.4294],
              [4.6174, 50.4235],
              [4.6103, 50.4216],
              [4.6073, 50.408],
              [4.5929, 50.3982],
              [4.5936, 50.3906],
              [4.603, 50.388],
              [4.6005, 50.3837],
              [4.5891, 50.3799],
              [4.5941, 50.3718],
              [4.5852, 50.3677],
              [4.5812, 50.3546],
              [4.5883, 50.3497],
              [4.5822, 50.3419],
              [4.5936, 50.3398],
              [4.594, 50.3266],
              [4.5884, 50.3211],
              [4.5652, 50.3229],
              [4.5418, 50.3178],
              [4.5248, 50.3159],
              [4.5072, 50.3211],
              [4.5042, 50.3287],
              [4.4753, 50.3275],
              [4.4718, 50.319],
              [4.4647, 50.3135],
              [4.4667, 50.3103],
              [4.4547, 50.3056],
              [4.4336, 50.3096],
              [4.4219, 50.3067],
              [4.4073, 50.3098],
              [4.4055, 50.3042],
              [4.3948, 50.2934],
              [4.3871, 50.29],
              [4.3707, 50.2916],
              [4.369, 50.2848],
              [4.3582, 50.2748],
              [4.3426, 50.2778],
              [4.3274, 50.2743],
              [4.3067, 50.2642],
              [4.2985, 50.263],
              [4.2883, 50.2554],
              [4.2895, 50.2508],
              [4.2977, 50.2446],
              [4.3184, 50.2373],
              [4.322, 50.2338],
              [4.3474, 50.2297],
              [4.3615, 50.2367],
              [4.3675, 50.2424],
              [4.3772, 50.2388],
              [4.387, 50.2388],
              [4.3891, 50.2339],
              [4.4012, 50.2322],
              [4.3922, 50.2113],
              [4.3928, 50.2016],
              [4.3963, 50.1929],
              [4.3951, 50.1817],
              [4.3782, 50.1743],
              [4.3634, 50.1706],
              [4.3669, 50.1569],
              [4.3765, 50.1561],
              [4.3851, 50.1502],
              [4.3892, 50.1426],
              [4.3854, 50.1354],
              [4.3996, 50.1254],
              [4.3968, 50.1168],
              [4.387, 50.1072],
              [4.3926, 50.1012],
              [4.3907, 50.0917],
              [4.3966, 50.0797],
              [4.3922, 50.0667],
              [4.3969, 50.0586],
              [4.3918, 50.0506],
              [4.3954, 50.0466],
              [4.4041, 50.0496],
              [4.4109, 50.0448],
              [4.4082, 50.0384],
              [4.412, 50.03],
              [4.4124, 50.0185],
              [4.4166, 50.0113],
              [4.4154, 49.9987],
              [4.4205, 49.9943],
              [4.423, 49.9856],
              [4.4374, 49.9737],
              [4.4465, 49.9536],
              [4.4336, 49.9421],
              [4.418, 49.9478],
              [4.3898, 49.9491],
              [4.3811, 49.954],
              [4.373, 49.9521],
              [4.3517, 49.9535],
              [4.3368, 49.964],
              [4.322, 49.9641],
              [4.3099, 49.9696],
              [4.2993, 49.9653],
              [4.2718, 49.9657],
              [4.2429, 49.9621],
              [4.2387, 49.9593],
              [4.2072, 49.9587],
              [4.1958, 49.9565],
              [4.197, 49.9686],
              [4.1801, 49.9733],
              [4.1699, 49.9777],
              [4.1547, 49.9755],
              [4.143, 49.981],
              [4.1566, 49.988],
              [4.1637, 49.9966],
              [4.1438, 50.0065],
              [4.1383, 50.0141],
              [4.1383, 50.022],
              [4.1629, 50.049],
              [4.1765, 50.0449],
              [4.1956, 50.0496],
              [4.1994, 50.0567],
              [4.2187, 50.0594],
              [4.2272, 50.0629],
              [4.2314, 50.0691],
              [4.2258, 50.0838],
              [4.2186, 50.0902],
              [4.2028, 50.0999],
              [4.1984, 50.1052],
              [4.2044, 50.1142],
              [4.2039, 50.1229],
              [4.1972, 50.1337],
              [4.178, 50.131],
              [4.1628, 50.1328],
              [4.1557, 50.1263],
              [4.1448, 50.1268],
              [4.1317, 50.1305],
              [4.1294, 50.1335],
              [4.1351, 50.1418],
              [4.1375, 50.1511],
              [4.1549, 50.1605],
              [4.1564, 50.1705],
              [4.1494, 50.1717],
              [4.1563, 50.1814],
              [4.1608, 50.1924],
              [4.1613, 50.2003],
              [4.1541, 50.2048],
              [4.1561, 50.2135],
              [4.1697, 50.2157],
              [4.1766, 50.2204],
              [4.1831, 50.2312],
              [4.1966, 50.2409],
              [4.2051, 50.2405],
              [4.2221, 50.2528],
              [4.2225, 50.2569],
              [4.212, 50.2703],
              [4.2017, 50.2731],
              [4.1871, 50.2734],
              [4.1763, 50.278],
              [4.1764, 50.2848],
              [4.1703, 50.2885],
              [4.1556, 50.2854],
              [4.1514, 50.2738],
              [4.1645, 50.2718],
              [4.1687, 50.2656],
              [4.1681, 50.2583],
              [4.1541, 50.2561],
              [4.1385, 50.258],
              [4.1351, 50.2656],
              [4.1362, 50.2736],
              [4.1256, 50.2731],
              [4.1252, 50.2892],
              [4.1229, 50.2973],
              [4.1171, 50.3015],
              [4.1088, 50.3023],
              [4.097, 50.3139],
              [4.0787, 50.3125],
              [4.0775, 50.3207],
              [4.0693, 50.3247],
              [4.0634, 50.331],
              [4.0552, 50.3331],
              [4.053, 50.3396],
              [4.0374, 50.3432],
              [4.0269, 50.3588],
              [4.0183, 50.3569],
              [4.0114, 50.3502],
              [3.9941, 50.3484],
              [3.9878, 50.3427],
              [3.9684, 50.3485],
              [3.9131, 50.3282],
              [3.9028, 50.327],
              [3.8917, 50.3292],
              [3.8899, 50.3401],
              [3.8778, 50.3372],
              [3.8655, 50.3408],
              [3.8586, 50.3482],
              [3.8509, 50.3521],
              [3.8406, 50.3534],
              [3.822, 50.3444],
              [3.8124, 50.3445],
              [3.8151, 50.3518],
              [3.8068, 50.3542],
              [3.7984, 50.3502],
              [3.7849, 50.3527],
              [3.767, 50.3525],
              [3.7633, 50.3484],
              [3.7482, 50.3512],
              [3.7344, 50.3433],
              [3.7369, 50.3364],
              [3.7295, 50.3306],
              [3.7321, 50.3229],
              [3.7277, 50.3169],
              [3.7095, 50.3206],
              [3.7163, 50.3079],
              [3.7099, 50.3045],
              [3.7023, 50.3118],
              [3.695, 50.3139],
              [3.6944, 50.3224],
              [3.685, 50.3257],
              [3.6741, 50.3347],
              [3.6785, 50.3408],
              [3.6665, 50.348],
              [3.6683, 50.3616],
              [3.6612, 50.3661],
              [3.6596, 50.3738],
              [3.6748, 50.389],
              [3.6754, 50.4029],
              [3.6682, 50.4229],
              [3.6677, 50.4388],
              [3.6585, 50.4443],
              [3.6619, 50.453],
              [3.6527, 50.463],
              [3.6416, 50.4637],
              [3.6299, 50.4781],
              [3.6316, 50.4808],
              [3.6202, 50.486],
              [3.6083, 50.4957],
              [3.5958, 50.4915],
              [3.583, 50.4902],
              [3.5717, 50.4961],
              [3.5478, 50.4971],
              [3.5402, 50.4938],
              [3.5227, 50.495],
              [3.5003, 50.4869],
              [3.4957, 50.4983],
              [3.5042, 50.5077],
              [3.5154, 50.5134],
              [3.5185, 50.5232],
              [3.4987, 50.5296],
              [3.4906, 50.5278],
              [3.4744, 50.5328],
              [3.4541, 50.5188],
              [3.4508, 50.5081],
              [3.447, 50.5061],
              [3.4351, 50.5096],
              [3.429, 50.5041],
              [3.416, 50.5021],
              [3.4076, 50.4983],
              [3.3923, 50.4976],
              [3.3856, 50.4934],
              [3.3699, 50.4939],
              [3.3611, 50.5036],
              [3.3369, 50.508],
              [3.3308, 50.5073],
              [3.3166, 50.5188],
              [3.3063, 50.5194],
              [3.2912, 50.524],
              [3.2867, 50.5316],
              [3.2786, 50.5383],
              [3.2781, 50.5485],
              [3.2837, 50.5594],
              [3.2776, 50.5643],
              [3.2773, 50.5718],
              [3.2816, 50.5761],
              [3.2759, 50.5822],
              [3.2764, 50.5914],
              [3.2719, 50.6076],
              [3.2552, 50.623],
              [3.2585, 50.6292],
              [3.2438, 50.6393],
              [3.2487, 50.6461],
              [3.239, 50.6575],
              [3.2405, 50.6671],
              [3.2614, 50.6779],
              [3.2526, 50.6887],
              [3.2604, 50.6915],
              [3.2604, 50.7011],
              [3.2544, 50.7024],
              [3.2454, 50.7111],
              [3.2346, 50.7121],
              [3.2225, 50.7086],
              [3.2127, 50.7113],
              [3.208, 50.7173],
              [3.1988, 50.7195],
              [3.1909, 50.7248],
              [3.1991, 50.7331],
              [3.1879, 50.7389],
              [3.1855, 50.747],
              [3.1773, 50.7557],
              [3.191, 50.7561],
              [3.1983, 50.7624],
              [3.2117, 50.7624],
              [3.2197, 50.7659],
              [3.2277, 50.7646],
              [3.239, 50.7585],
              [3.2492, 50.7579],
              [3.2621, 50.7516],
              [3.2771, 50.751],
              [3.3058, 50.7551],
              [3.3236, 50.7387],
              [3.3287, 50.7294],
              [3.3245, 50.7213],
              [3.3351, 50.7168],
              [3.3598, 50.7093],
              [3.3644, 50.7188],
              [3.3716, 50.7264],
              [3.393, 50.7282],
              [3.4032, 50.7362],
              [3.4039, 50.7439],
              [3.4108, 50.747],
              [3.4217, 50.7465],
              [3.434, 50.7487],
              [3.4326, 50.7571],
              [3.4546, 50.7588],
              [3.4535, 50.7678],
              [3.4597, 50.7672],
            ],
          ],
        ],
      },
      properties: {name: 'Hainaut', id: 'BE-WHT', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-WHT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.6833, 50.7582],
            [5.6937, 50.7508],
            [5.7057, 50.7504],
            [5.7263, 50.746],
            [5.7336, 50.7552],
            [5.744, 50.7544],
            [5.7494, 50.7456],
            [5.7698, 50.7518],
            [5.786, 50.7464],
            [5.8002, 50.7316],
            [5.8101, 50.7236],
            [5.8134, 50.7164],
            [5.8221, 50.7135],
            [5.8575, 50.7168],
            [5.8659, 50.7161],
            [5.8835, 50.7094],
            [5.8875, 50.7153],
            [5.9115, 50.7348],
            [5.9099, 50.7393],
            [5.8981, 50.7446],
            [5.8932, 50.7557],
            [5.9022, 50.7516],
            [5.9066, 50.755],
            [5.917, 50.7555],
            [5.9254, 50.7527],
            [5.9336, 50.7571],
            [5.9625, 50.763],
            [5.9732, 50.7614],
            [5.9818, 50.7537],
            [6.0018, 50.7533],
            [6.0215, 50.7554],
            [6.0431, 50.7444],
            [6.044, 50.7387],
            [6.037, 50.7331],
            [6.0359, 50.7278],
            [6.0434, 50.7199],
            [6.0472, 50.7283],
            [6.0639, 50.7223],
            [6.0865, 50.7215],
            [6.1009, 50.7237],
            [6.1183, 50.7223],
            [6.1275, 50.7107],
            [6.1262, 50.7069],
            [6.1367, 50.6987],
            [6.1539, 50.6767],
            [6.1614, 50.6726],
            [6.1678, 50.6623],
            [6.182, 50.6545],
            [6.1836, 50.6469],
            [6.1765, 50.6428],
            [6.1855, 50.6322],
            [6.1787, 50.6263],
            [6.1874, 50.6232],
            [6.1993, 50.6329],
            [6.2226, 50.6318],
            [6.2372, 50.6266],
            [6.2649, 50.6282],
            [6.2703, 50.6264],
            [6.2701, 50.6173],
            [6.2598, 50.6083],
            [6.2468, 50.6023],
            [6.2482, 50.5945],
            [6.2395, 50.5874],
            [6.2267, 50.5901],
            [6.2207, 50.5815],
            [6.2073, 50.5759],
            [6.2054, 50.5691],
            [6.1907, 50.5642],
            [6.1775, 50.5575],
            [6.1798, 50.5422],
            [6.1872, 50.5422],
            [6.1975, 50.537],
            [6.1972, 50.5317],
            [6.1899, 50.5267],
            [6.1947, 50.5219],
            [6.2074, 50.5219],
            [6.2216, 50.5063],
            [6.2229, 50.4983],
            [6.2425, 50.5007],
            [6.2526, 50.505],
            [6.264, 50.5034],
            [6.2813, 50.5046],
            [6.2859, 50.5002],
            [6.2977, 50.4991],
            [6.3129, 50.5017],
            [6.3221, 50.4959],
            [6.3334, 50.4951],
            [6.3517, 50.4876],
            [6.3433, 50.4836],
            [6.3389, 50.474],
            [6.3436, 50.4709],
            [6.3416, 50.4623],
            [6.3513, 50.4569],
            [6.3648, 50.4536],
            [6.373, 50.4544],
            [6.3761, 50.4491],
            [6.3774, 50.436],
            [6.3666, 50.4209],
            [6.3696, 50.4091],
            [6.3584, 50.3959],
            [6.3569, 50.391],
            [6.3475, 50.3863],
            [6.3422, 50.3797],
            [6.3609, 50.3706],
            [6.3704, 50.3568],
            [6.3828, 50.3534],
            [6.3995, 50.3445],
            [6.4013, 50.3373],
            [6.4082, 50.3349],
            [6.4052, 50.3221],
            [6.3841, 50.3207],
            [6.3731, 50.312],
            [6.3606, 50.311],
            [6.362, 50.3037],
            [6.3433, 50.3174],
            [6.3291, 50.3232],
            [6.3199, 50.319],
            [6.308, 50.3186],
            [6.309, 50.3104],
            [6.2963, 50.308],
            [6.2988, 50.3037],
            [6.2921, 50.2959],
            [6.2866, 50.2829],
            [6.292, 50.2757],
            [6.2808, 50.267],
            [6.26, 50.2674],
            [6.248, 50.2625],
            [6.2378, 50.2627],
            [6.2187, 50.2565],
            [6.2074, 50.2514],
            [6.201, 50.2397],
            [6.1935, 50.2365],
            [6.1844, 50.2374],
            [6.1751, 50.2328],
            [6.1763, 50.2249],
            [6.1685, 50.2201],
            [6.1786, 50.2175],
            [6.188, 50.201],
            [6.186, 50.1922],
            [6.1918, 50.1828],
            [6.1866, 50.1795],
            [6.166, 50.1786],
            [6.1571, 50.1723],
            [6.1499, 50.1779],
            [6.1428, 50.1689],
            [6.148, 50.1619],
            [6.1443, 50.1569],
            [6.1348, 50.1548],
            [6.1439, 50.1485],
            [6.1525, 50.1499],
            [6.155, 50.1421],
            [6.1491, 50.1369],
            [6.1408, 50.1355],
            [6.1406, 50.1298],
            [6.1333, 50.1293],
            [6.1215, 50.1357],
            [6.1138, 50.1358],
            [6.1163, 50.1438],
            [6.1264, 50.1488],
            [6.115, 50.1553],
            [6.1207, 50.1626],
            [6.1036, 50.1708],
            [6.0966, 50.1688],
            [6.0814, 50.1704],
            [6.081, 50.1561],
            [6.0733, 50.1566],
            [6.0651, 50.1527],
            [6.044, 50.1589],
            [6.0323, 50.1638],
            [6.0313, 50.1717],
            [6.0257, 50.1833],
            [6.0227, 50.1951],
            [6.0352, 50.2059],
            [6.027, 50.2103],
            [6.0188, 50.2066],
            [6.0103, 50.23],
            [6.0098, 50.2392],
            [6.0167, 50.253],
            [6.0105, 50.2705],
            [6.016, 50.2999],
            [6.0112, 50.3143],
            [5.9844, 50.3207],
            [5.9804, 50.3302],
            [5.9626, 50.3281],
            [5.956, 50.3346],
            [5.9394, 50.3389],
            [5.9288, 50.3331],
            [5.9118, 50.336],
            [5.9034, 50.3391],
            [5.8893, 50.3387],
            [5.8637, 50.3473],
            [5.8536, 50.3353],
            [5.8523, 50.3272],
            [5.8646, 50.3256],
            [5.8705, 50.3195],
            [5.8653, 50.304],
            [5.8565, 50.3007],
            [5.8603, 50.2952],
            [5.8542, 50.293],
            [5.8463, 50.2809],
            [5.8667, 50.2826],
            [5.8742, 50.2766],
            [5.8705, 50.2676],
            [5.8623, 50.2672],
            [5.8429, 50.2607],
            [5.8301, 50.2663],
            [5.823, 50.2642],
            [5.8037, 50.2629],
            [5.7932, 50.2647],
            [5.7821, 50.2626],
            [5.7711, 50.2673],
            [5.7541, 50.2654],
            [5.7474, 50.2599],
            [5.7267, 50.261],
            [5.7204, 50.2675],
            [5.7349, 50.2754],
            [5.738, 50.2883],
            [5.7494, 50.295],
            [5.7499, 50.3009],
            [5.7296, 50.3027],
            [5.7266, 50.3012],
            [5.7145, 50.3119],
            [5.7049, 50.3291],
            [5.7139, 50.3305],
            [5.7167, 50.3369],
            [5.7133, 50.3419],
            [5.7227, 50.3524],
            [5.7229, 50.3573],
            [5.7085, 50.3601],
            [5.7013, 50.3569],
            [5.6896, 50.3556],
            [5.6731, 50.3561],
            [5.6776, 50.3681],
            [5.6499, 50.3655],
            [5.6336, 50.3726],
            [5.6382, 50.3747],
            [5.6177, 50.3812],
            [5.5956, 50.3979],
            [5.5875, 50.399],
            [5.5711, 50.3967],
            [5.5548, 50.3888],
            [5.5377, 50.3918],
            [5.5259, 50.4026],
            [5.5211, 50.4109],
            [5.5152, 50.4124],
            [5.5122, 50.4193],
            [5.5047, 50.4229],
            [5.4992, 50.4189],
            [5.4792, 50.4135],
            [5.4692, 50.4067],
            [5.4535, 50.416],
            [5.4362, 50.4231],
            [5.4308, 50.4291],
            [5.4217, 50.4289],
            [5.408, 50.4241],
            [5.4096, 50.4136],
            [5.4234, 50.4119],
            [5.4299, 50.4047],
            [5.4295, 50.3989],
            [5.4111, 50.3906],
            [5.3985, 50.3828],
            [5.3948, 50.3782],
            [5.3837, 50.3857],
            [5.3707, 50.3791],
            [5.3644, 50.3838],
            [5.353, 50.3872],
            [5.3383, 50.3789],
            [5.3203, 50.3716],
            [5.3094, 50.3696],
            [5.2988, 50.364],
            [5.2826, 50.3769],
            [5.281, 50.3869],
            [5.2883, 50.3921],
            [5.2618, 50.4014],
            [5.2559, 50.399],
            [5.2363, 50.4025],
            [5.2229, 50.4156],
            [5.236, 50.4228],
            [5.2368, 50.4373],
            [5.2295, 50.4437],
            [5.2235, 50.444],
            [5.2102, 50.461],
            [5.2035, 50.4733],
            [5.1918, 50.476],
            [5.1791, 50.4761],
            [5.1723, 50.47],
            [5.164, 50.468],
            [5.1571, 50.4785],
            [5.1514, 50.4823],
            [5.1616, 50.4875],
            [5.1562, 50.4902],
            [5.1406, 50.4869],
            [5.1343, 50.4905],
            [5.1354, 50.4962],
            [5.1295, 50.5028],
            [5.1449, 50.5083],
            [5.1421, 50.512],
            [5.1172, 50.5036],
            [5.1049, 50.5072],
            [5.0974, 50.512],
            [5.0907, 50.5261],
            [5.0932, 50.5299],
            [5.0577, 50.5322],
            [5.0362, 50.5325],
            [5.0404, 50.5362],
            [5.0522, 50.5393],
            [5.0532, 50.5424],
            [5.046, 50.5504],
            [5.048, 50.5602],
            [5.0329, 50.5701],
            [5.0272, 50.5839],
            [5.0275, 50.588],
            [5.0177, 50.5902],
            [4.9998, 50.5987],
            [5.0005, 50.6092],
            [4.9815, 50.6159],
            [4.9877, 50.6248],
            [4.9823, 50.6338],
            [4.9895, 50.6361],
            [4.9844, 50.6419],
            [4.9883, 50.6441],
            [4.9881, 50.6526],
            [5.0032, 50.6648],
            [4.998, 50.6699],
            [5.0004, 50.6913],
            [5.0032, 50.6962],
            [5.015, 50.6997],
            [5.0179, 50.7032],
            [5.0104, 50.7116],
            [4.9965, 50.7181],
            [4.9973, 50.7275],
            [5.0095, 50.7293],
            [5.0175, 50.7328],
            [5.0173, 50.7432],
            [5.021, 50.7503],
            [5.0475, 50.74],
            [5.045, 50.7242],
            [5.072, 50.7074],
            [5.0808, 50.709],
            [5.096, 50.7039],
            [5.1016, 50.7091],
            [5.107, 50.7104],
            [5.1272, 50.707],
            [5.1323, 50.7021],
            [5.1485, 50.6961],
            [5.1578, 50.6986],
            [5.1665, 50.6958],
            [5.1818, 50.7046],
            [5.1762, 50.7139],
            [5.1701, 50.714],
            [5.1709, 50.7224],
            [5.1855, 50.7208],
            [5.1958, 50.7171],
            [5.2054, 50.7202],
            [5.2254, 50.7207],
            [5.2313, 50.7178],
            [5.2397, 50.7272],
            [5.2435, 50.7175],
            [5.2597, 50.7156],
            [5.2774, 50.7275],
            [5.2923, 50.725],
            [5.3008, 50.7213],
            [5.3336, 50.7357],
            [5.3501, 50.7461],
            [5.3658, 50.7466],
            [5.3743, 50.7423],
            [5.3881, 50.748],
            [5.3929, 50.7352],
            [5.4006, 50.735],
            [5.4018, 50.7279],
            [5.4189, 50.719],
            [5.4314, 50.7208],
            [5.4544, 50.7215],
            [5.4539, 50.7263],
            [5.4628, 50.7304],
            [5.4622, 50.7385],
            [5.4758, 50.7329],
            [5.4748, 50.7254],
            [5.4901, 50.7278],
            [5.4971, 50.7316],
            [5.5215, 50.7406],
            [5.5254, 50.7435],
            [5.5203, 50.7511],
            [5.5272, 50.7588],
            [5.5473, 50.7583],
            [5.5585, 50.7641],
            [5.5707, 50.7639],
            [5.5745, 50.7715],
            [5.5889, 50.7722],
            [5.6022, 50.7766],
            [5.615, 50.7843],
            [5.6278, 50.7829],
            [5.6481, 50.7947],
            [5.6537, 50.8057],
            [5.6684, 50.805],
            [5.6737, 50.808],
            [5.6876, 50.806],
            [5.6881, 50.8119],
            [5.6937, 50.8118],
            [5.6988, 50.8036],
            [5.6926, 50.7963],
            [5.7017, 50.7824],
            [5.6969, 50.7729],
            [5.6842, 50.7619],
            [5.6833, 50.7582],
          ],
        ],
      },
      properties: {name: 'Liège', id: 'BE-WLG', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-WLG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [5.6881, 50.8119],
              [5.6876, 50.806],
              [5.6737, 50.808],
              [5.6684, 50.805],
              [5.6537, 50.8057],
              [5.6481, 50.7947],
              [5.6278, 50.7829],
              [5.615, 50.7843],
              [5.6022, 50.7766],
              [5.5889, 50.7722],
              [5.5745, 50.7715],
              [5.5707, 50.7639],
              [5.5585, 50.7641],
              [5.5473, 50.7583],
              [5.5272, 50.7588],
              [5.5203, 50.7511],
              [5.5254, 50.7435],
              [5.5215, 50.7406],
              [5.4971, 50.7316],
              [5.4901, 50.7278],
              [5.4748, 50.7254],
              [5.4758, 50.7329],
              [5.4622, 50.7385],
              [5.4628, 50.7304],
              [5.4539, 50.7263],
              [5.4544, 50.7215],
              [5.4314, 50.7208],
              [5.4189, 50.719],
              [5.4018, 50.7279],
              [5.4006, 50.735],
              [5.3929, 50.7352],
              [5.3881, 50.748],
              [5.3743, 50.7423],
              [5.3658, 50.7466],
              [5.3501, 50.7461],
              [5.3336, 50.7357],
              [5.3008, 50.7213],
              [5.2923, 50.725],
              [5.2774, 50.7275],
              [5.2597, 50.7156],
              [5.2435, 50.7175],
              [5.2397, 50.7272],
              [5.2313, 50.7178],
              [5.2254, 50.7207],
              [5.2054, 50.7202],
              [5.1958, 50.7171],
              [5.1855, 50.7208],
              [5.1709, 50.7224],
              [5.1701, 50.714],
              [5.1762, 50.7139],
              [5.1818, 50.7046],
              [5.1665, 50.6958],
              [5.1578, 50.6986],
              [5.1485, 50.6961],
              [5.1323, 50.7021],
              [5.1272, 50.707],
              [5.107, 50.7104],
              [5.1016, 50.7091],
              [5.1062, 50.7124],
              [5.1153, 50.7304],
              [5.1209, 50.7356],
              [5.1058, 50.7415],
              [5.1026, 50.7451],
              [5.109, 50.7572],
              [5.1173, 50.764],
              [5.1078, 50.7792],
              [5.1106, 50.7864],
              [5.1181, 50.793],
              [5.1345, 50.7928],
              [5.1429, 50.8025],
              [5.1415, 50.809],
              [5.1308, 50.8145],
              [5.1382, 50.8245],
              [5.1344, 50.8342],
              [5.1426, 50.8449],
              [5.1369, 50.8481],
              [5.1484, 50.8532],
              [5.1526, 50.8627],
              [5.1552, 50.8786],
              [5.1686, 50.872],
              [5.1794, 50.8805],
              [5.1791, 50.885],
              [5.1859, 50.8889],
              [5.1867, 50.9033],
              [5.1772, 50.9103],
              [5.1678, 50.9132],
              [5.1554, 50.9121],
              [5.1364, 50.9127],
              [5.1333, 50.921],
              [5.1247, 50.9177],
              [5.1054, 50.9144],
              [5.0922, 50.9087],
              [5.0786, 50.9211],
              [5.0657, 50.9164],
              [5.0544, 50.9216],
              [5.0437, 50.9422],
              [5.0531, 50.9447],
              [5.0598, 50.9546],
              [5.0775, 50.9596],
              [5.0783, 50.9653],
              [5.0843, 50.9701],
              [5.0797, 50.9803],
              [5.0808, 50.9853],
              [5.0938, 50.9874],
              [5.1058, 50.995],
              [5.1264, 50.9967],
              [5.1213, 51.0119],
              [5.1429, 51.0178],
              [5.1588, 51.0238],
              [5.1185, 51.0494],
              [5.1082, 51.047],
              [5.0881, 51.0492],
              [5.0805, 51.0464],
              [5.0807, 51.0277],
              [5.0838, 51.0233],
              [5.0671, 51.0163],
              [5.0576, 51.0153],
              [5.0551, 51.0215],
              [5.0445, 51.0242],
              [5.024, 51.0196],
              [5.0193, 51.0279],
              [5.0112, 51.035],
              [5.0006, 51.0347],
              [4.9839, 51.0299],
              [4.982, 51.0344],
              [4.9942, 51.0498],
              [5.0012, 51.0556],
              [5.0109, 51.0572],
              [5.0148, 51.0688],
              [5.0133, 51.0748],
              [5.0322, 51.0731],
              [5.0501, 51.0758],
              [5.0688, 51.0811],
              [5.0849, 51.0828],
              [5.0965, 51.0858],
              [5.1043, 51.0957],
              [5.1279, 51.1079],
              [5.1314, 51.1176],
              [5.1453, 51.1173],
              [5.1521, 51.1196],
              [5.1679, 51.1196],
              [5.1833, 51.1178],
              [5.1945, 51.1217],
              [5.2035, 51.129],
              [5.2068, 51.1389],
              [5.2224, 51.1381],
              [5.2351, 51.1322],
              [5.252, 51.1409],
              [5.2629, 51.1497],
              [5.2615, 51.1827],
              [5.2396, 51.2],
              [5.2292, 51.203],
              [5.2237, 51.2096],
              [5.2175, 51.2104],
              [5.2163, 51.2206],
              [5.2172, 51.2454],
              [5.2382, 51.2601],
              [5.2637, 51.2651],
              [5.2939, 51.2603],
              [5.3142, 51.2619],
              [5.3364, 51.2617],
              [5.3415, 51.2644],
              [5.3463, 51.2739],
              [5.3832, 51.2671],
              [5.4178, 51.2619],
              [5.4429, 51.2819],
              [5.4672, 51.2848],
              [5.4776, 51.2908],
              [5.4864, 51.3],
              [5.5169, 51.295],
              [5.528, 51.2828],
              [5.5455, 51.2709],
              [5.5567, 51.2661],
              [5.5563, 51.2428],
              [5.5619, 51.222],
              [5.6524, 51.1979],
              [5.6526, 51.1916],
              [5.6589, 51.1852],
              [5.6723, 51.1857],
              [5.6775, 51.1827],
              [5.6898, 51.185],
              [5.7099, 51.1806],
              [5.7177, 51.1844],
              [5.7405, 51.1866],
              [5.7657, 51.1838],
              [5.775, 51.1778],
              [5.77, 51.1693],
              [5.7828, 51.1527],
              [5.7913, 51.1572],
              [5.8117, 51.1592],
              [5.8242, 51.1679],
              [5.8372, 51.1567],
              [5.8359, 51.1532],
              [5.8545, 51.1441],
              [5.8459, 51.1408],
              [5.8389, 51.1303],
              [5.8234, 51.1286],
              [5.8092, 51.1176],
              [5.809, 51.1101],
              [5.8248, 51.1082],
              [5.8329, 51.1033],
              [5.8303, 51.0959],
              [5.8236, 51.0922],
              [5.8119, 51.0956],
              [5.8011, 51.0946],
              [5.7954, 51.0911],
              [5.804, 51.0775],
              [5.7965, 51.0707],
              [5.8, 51.0637],
              [5.7972, 51.059],
              [5.7887, 51.0586],
              [5.7792, 51.0622],
              [5.7721, 51.0606],
              [5.7713, 51.0508],
              [5.7579, 51.0324],
              [5.772, 51.0274],
              [5.7754, 51.0205],
              [5.7662, 51.0097],
              [5.7671, 51.0001],
              [5.7514, 50.9838],
              [5.7375, 50.9776],
              [5.734, 50.972],
              [5.7208, 50.9621],
              [5.7217, 50.957],
              [5.7285, 50.9547],
              [5.7441, 50.9603],
              [5.7544, 50.9576],
              [5.7563, 50.949],
              [5.7465, 50.9464],
              [5.7409, 50.9352],
              [5.7314, 50.9282],
              [5.726, 50.9201],
              [5.726, 50.9127],
              [5.7177, 50.9073],
              [5.7073, 50.9111],
              [5.6966, 50.9072],
              [5.6944, 50.8957],
              [5.6821, 50.8875],
              [5.6779, 50.8804],
              [5.6689, 50.8805],
              [5.6449, 50.8713],
              [5.6415, 50.8641],
              [5.6435, 50.8566],
              [5.6402, 50.846],
              [5.6442, 50.8381],
              [5.6512, 50.8325],
              [5.6565, 50.8203],
              [5.6785, 50.8166],
              [5.6881, 50.8119],
            ],
          ],
          [
            [
              [5.8932, 50.7557],
              [5.8981, 50.7446],
              [5.9099, 50.7393],
              [5.9115, 50.7348],
              [5.8875, 50.7153],
              [5.8835, 50.7094],
              [5.8659, 50.7161],
              [5.8575, 50.7168],
              [5.8221, 50.7135],
              [5.8134, 50.7164],
              [5.8101, 50.7236],
              [5.8002, 50.7316],
              [5.786, 50.7464],
              [5.7698, 50.7518],
              [5.7494, 50.7456],
              [5.744, 50.7544],
              [5.7336, 50.7552],
              [5.7263, 50.746],
              [5.7057, 50.7504],
              [5.6937, 50.7508],
              [5.6833, 50.7582],
              [5.6983, 50.7555],
              [5.704, 50.7589],
              [5.7151, 50.7601],
              [5.7204, 50.7643],
              [5.7288, 50.7606],
              [5.7402, 50.7606],
              [5.7429, 50.7646],
              [5.766, 50.7818],
              [5.7765, 50.7828],
              [5.7845, 50.7687],
              [5.7932, 50.771],
              [5.8079, 50.7569],
              [5.8327, 50.7595],
              [5.8459, 50.7649],
              [5.85, 50.7556],
              [5.8625, 50.7618],
              [5.8863, 50.7696],
              [5.8932, 50.7557],
            ],
          ],
        ],
      },
      properties: {name: 'Limburg', id: 'BE-VLI', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-VLI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.3948, 50.3782],
            [5.3985, 50.3828],
            [5.4111, 50.3906],
            [5.4295, 50.3989],
            [5.4299, 50.4047],
            [5.4234, 50.4119],
            [5.4096, 50.4136],
            [5.408, 50.4241],
            [5.4217, 50.4289],
            [5.4308, 50.4291],
            [5.4362, 50.4231],
            [5.4535, 50.416],
            [5.4692, 50.4067],
            [5.4792, 50.4135],
            [5.4992, 50.4189],
            [5.5047, 50.4229],
            [5.5122, 50.4193],
            [5.5152, 50.4124],
            [5.5211, 50.4109],
            [5.5259, 50.4026],
            [5.5377, 50.3918],
            [5.5548, 50.3888],
            [5.5711, 50.3967],
            [5.5875, 50.399],
            [5.5956, 50.3979],
            [5.6177, 50.3812],
            [5.6382, 50.3747],
            [5.6336, 50.3726],
            [5.6499, 50.3655],
            [5.6776, 50.3681],
            [5.6731, 50.3561],
            [5.6896, 50.3556],
            [5.7013, 50.3569],
            [5.7085, 50.3601],
            [5.7229, 50.3573],
            [5.7227, 50.3524],
            [5.7133, 50.3419],
            [5.7167, 50.3369],
            [5.7139, 50.3305],
            [5.7049, 50.3291],
            [5.7145, 50.3119],
            [5.7266, 50.3012],
            [5.7296, 50.3027],
            [5.7499, 50.3009],
            [5.7494, 50.295],
            [5.738, 50.2883],
            [5.7349, 50.2754],
            [5.7204, 50.2675],
            [5.7267, 50.261],
            [5.7474, 50.2599],
            [5.7541, 50.2654],
            [5.7711, 50.2673],
            [5.7821, 50.2626],
            [5.7932, 50.2647],
            [5.8037, 50.2629],
            [5.823, 50.2642],
            [5.8301, 50.2663],
            [5.8429, 50.2607],
            [5.8623, 50.2672],
            [5.8705, 50.2676],
            [5.8742, 50.2766],
            [5.8667, 50.2826],
            [5.8463, 50.2809],
            [5.8542, 50.293],
            [5.8603, 50.2952],
            [5.8565, 50.3007],
            [5.8653, 50.304],
            [5.8705, 50.3195],
            [5.8646, 50.3256],
            [5.8523, 50.3272],
            [5.8536, 50.3353],
            [5.8637, 50.3473],
            [5.8893, 50.3387],
            [5.9034, 50.3391],
            [5.9118, 50.336],
            [5.9288, 50.3331],
            [5.9394, 50.3389],
            [5.956, 50.3346],
            [5.9626, 50.3281],
            [5.9804, 50.3302],
            [5.9844, 50.3207],
            [6.0112, 50.3143],
            [6.016, 50.2999],
            [6.0105, 50.2705],
            [6.0167, 50.253],
            [6.0098, 50.2392],
            [6.0103, 50.23],
            [6.0188, 50.2066],
            [6.027, 50.2103],
            [6.0352, 50.2059],
            [6.0227, 50.1951],
            [6.0257, 50.1833],
            [6.0006, 50.1758],
            [5.973, 50.1754],
            [5.966, 50.1713],
            [5.965, 50.1606],
            [5.9682, 50.1546],
            [5.961, 50.1511],
            [5.9629, 50.1429],
            [5.962, 50.1317],
            [5.9306, 50.1258],
            [5.9034, 50.1159],
            [5.8975, 50.1108],
            [5.8964, 50.101],
            [5.8881, 50.0895],
            [5.8887, 50.078],
            [5.8703, 50.0737],
            [5.8611, 50.0686],
            [5.8556, 50.0592],
            [5.8641, 50.0541],
            [5.8708, 50.0469],
            [5.8583, 50.0364],
            [5.861, 50.029],
            [5.8529, 50.0227],
            [5.8423, 50.0184],
            [5.8344, 50.0181],
            [5.8203, 50.0127],
            [5.8237, 49.9973],
            [5.832, 49.9909],
            [5.8399, 49.9893],
            [5.8394, 49.9834],
            [5.8323, 49.9768],
            [5.8187, 49.9717],
            [5.8123, 49.9717],
            [5.8075, 49.9633],
            [5.797, 49.9661],
            [5.7849, 49.9608],
            [5.7766, 49.9608],
            [5.7711, 49.9485],
            [5.7754, 49.9386],
            [5.7654, 49.9311],
            [5.761, 49.9144],
            [5.7484, 49.9092],
            [5.7362, 49.8991],
            [5.7577, 49.8896],
            [5.7782, 49.8843],
            [5.7849, 49.8787],
            [5.7847, 49.8725],
            [5.7736, 49.8674],
            [5.758, 49.8724],
            [5.7562, 49.8613],
            [5.7612, 49.8577],
            [5.756, 49.8518],
            [5.759, 49.847],
            [5.7492, 49.8433],
            [5.7487, 49.8375],
            [5.742, 49.8332],
            [5.7493, 49.8238],
            [5.7447, 49.819],
            [5.7506, 49.813],
            [5.7551, 49.7907],
            [5.789, 49.795],
            [5.7935, 49.7856],
            [5.8053, 49.7756],
            [5.8101, 49.7652],
            [5.8157, 49.7603],
            [5.8228, 49.7493],
            [5.8307, 49.7469],
            [5.8253, 49.7306],
            [5.8267, 49.7222],
            [5.8352, 49.7233],
            [5.8447, 49.7213],
            [5.8587, 49.7263],
            [5.8685, 49.724],
            [5.8857, 49.7079],
            [5.866, 49.6931],
            [5.8622, 49.681],
            [5.8673, 49.6744],
            [5.8793, 49.6726],
            [5.8863, 49.6673],
            [5.896, 49.6677],
            [5.9022, 49.6638],
            [5.9024, 49.6444],
            [5.9066, 49.6386],
            [5.8868, 49.6348],
            [5.8856, 49.627],
            [5.8753, 49.6195],
            [5.8745, 49.6079],
            [5.8665, 49.6053],
            [5.8483, 49.5954],
            [5.8497, 49.5896],
            [5.8557, 49.5857],
            [5.8703, 49.5873],
            [5.8704, 49.5736],
            [5.8586, 49.566],
            [5.8452, 49.5617],
            [5.841, 49.5528],
            [5.8188, 49.546],
            [5.8068, 49.5505],
            [5.7933, 49.5513],
            [5.774, 49.5629],
            [5.7636, 49.5613],
            [5.7554, 49.5545],
            [5.7564, 49.5424],
            [5.7461, 49.5389],
            [5.7383, 49.5393],
            [5.731, 49.5449],
            [5.722, 49.5408],
            [5.7039, 49.5389],
            [5.69, 49.5455],
            [5.6803, 49.5454],
            [5.662, 49.5531],
            [5.6425, 49.5501],
            [5.6317, 49.5402],
            [5.6227, 49.5355],
            [5.6158, 49.5286],
            [5.6205, 49.5189],
            [5.6115, 49.5073],
            [5.6047, 49.5097],
            [5.6014, 49.5164],
            [5.5882, 49.5225],
            [5.5695, 49.5287],
            [5.5498, 49.5275],
            [5.5416, 49.5168],
            [5.5302, 49.5135],
            [5.5044, 49.5087],
            [5.4855, 49.5089],
            [5.4795, 49.5016],
            [5.4728, 49.4979],
            [5.4662, 49.5031],
            [5.4666, 49.5107],
            [5.4504, 49.5189],
            [5.4674, 49.5276],
            [5.4661, 49.5397],
            [5.4493, 49.5503],
            [5.4471, 49.5549],
            [5.4555, 49.5677],
            [5.4386, 49.5704],
            [5.4297, 49.5891],
            [5.4313, 49.5935],
            [5.4186, 49.6007],
            [5.4116, 49.6088],
            [5.402, 49.6114],
            [5.3925, 49.6178],
            [5.3758, 49.6235],
            [5.3639, 49.6231],
            [5.3481, 49.6309],
            [5.3416, 49.6192],
            [5.3259, 49.6171],
            [5.316, 49.6116],
            [5.3061, 49.625],
            [5.3113, 49.6376],
            [5.3195, 49.6464],
            [5.3304, 49.6505],
            [5.3316, 49.654],
            [5.3071, 49.6722],
            [5.2936, 49.6773],
            [5.2855, 49.6825],
            [5.2807, 49.6891],
            [5.2694, 49.6965],
            [5.2424, 49.6887],
            [5.2282, 49.6907],
            [5.219, 49.6876],
            [5.2071, 49.6955],
            [5.199, 49.6929],
            [5.1936, 49.6952],
            [5.1663, 49.6933],
            [5.1642, 49.6984],
            [5.1655, 49.7118],
            [5.1597, 49.7176],
            [5.1519, 49.7192],
            [5.142, 49.7128],
            [5.1273, 49.7134],
            [5.1237, 49.7182],
            [5.1259, 49.727],
            [5.1203, 49.7374],
            [5.0996, 49.7587],
            [5.0882, 49.7656],
            [5.0742, 49.7617],
            [5.0641, 49.7616],
            [5.0627, 49.7668],
            [5.0501, 49.7705],
            [5.0378, 49.7719],
            [5.008, 49.7823],
            [5.0063, 49.7874],
            [4.9961, 49.7947],
            [4.9953, 49.8001],
            [4.9837, 49.7994],
            [4.9686, 49.8016],
            [4.969, 49.8113],
            [4.9836, 49.8219],
            [4.9801, 49.8429],
            [4.9865, 49.846],
            [4.9893, 49.8531],
            [5.0012, 49.8571],
            [5.011, 49.8576],
            [5.0241, 49.8656],
            [5.0252, 49.8702],
            [5.036, 49.872],
            [5.0575, 49.8832],
            [5.0472, 49.888],
            [5.0434, 49.8928],
            [5.049, 49.898],
            [5.0579, 49.8975],
            [5.0739, 49.9123],
            [5.0809, 49.9123],
            [5.0969, 49.9194],
            [5.1141, 49.9197],
            [5.1114, 49.9252],
            [5.0984, 49.9365],
            [5.1027, 49.9466],
            [5.1002, 49.9609],
            [5.0931, 49.9613],
            [5.0673, 49.9741],
            [5.0665, 49.9818],
            [5.0606, 49.9839],
            [5.0149, 49.9784],
            [5.0061, 49.9828],
            [5.006, 49.9889],
            [5.0113, 49.9965],
            [4.9934, 50.0076],
            [4.9959, 50.0137],
            [4.9853, 50.019],
            [4.9783, 50.0304],
            [4.9889, 50.0268],
            [4.9947, 50.0309],
            [4.9947, 50.0384],
            [5.0005, 50.0436],
            [5.0117, 50.0374],
            [5.0249, 50.0425],
            [5.0293, 50.0492],
            [5.0425, 50.0528],
            [5.043, 50.0618],
            [5.0541, 50.0661],
            [5.0546, 50.0723],
            [5.0605, 50.0787],
            [5.0533, 50.0807],
            [5.0581, 50.1067],
            [5.0664, 50.1221],
            [5.0717, 50.1159],
            [5.0674, 50.1081],
            [5.0695, 50.1012],
            [5.0767, 50.0923],
            [5.1015, 50.0948],
            [5.122, 50.0986],
            [5.1323, 50.0976],
            [5.1428, 50.0937],
            [5.1529, 50.0934],
            [5.1606, 50.0992],
            [5.1902, 50.0991],
            [5.2014, 50.0958],
            [5.2268, 50.1017],
            [5.2277, 50.1064],
            [5.2366, 50.1092],
            [5.2693, 50.1106],
            [5.2701, 50.1166],
            [5.2553, 50.1283],
            [5.2484, 50.1266],
            [5.2433, 50.1329],
            [5.2731, 50.1443],
            [5.2853, 50.1477],
            [5.2802, 50.155],
            [5.2724, 50.1591],
            [5.2679, 50.1668],
            [5.2614, 50.1711],
            [5.2554, 50.1794],
            [5.2453, 50.1867],
            [5.2428, 50.1921],
            [5.2346, 50.1951],
            [5.247, 50.2045],
            [5.2373, 50.2085],
            [5.2337, 50.2134],
            [5.2237, 50.2127],
            [5.2083, 50.2153],
            [5.2293, 50.2256],
            [5.2357, 50.221],
            [5.2583, 50.2261],
            [5.2612, 50.2289],
            [5.2556, 50.2388],
            [5.2681, 50.2401],
            [5.2858, 50.2474],
            [5.2954, 50.2394],
            [5.3135, 50.2453],
            [5.3334, 50.2551],
            [5.3445, 50.2651],
            [5.3544, 50.268],
            [5.3602, 50.2741],
            [5.3733, 50.2765],
            [5.3966, 50.2898],
            [5.3907, 50.3065],
            [5.3929, 50.3099],
            [5.3868, 50.316],
            [5.3806, 50.3134],
            [5.367, 50.317],
            [5.3514, 50.3066],
            [5.344, 50.3094],
            [5.3446, 50.3156],
            [5.3512, 50.3197],
            [5.3772, 50.3252],
            [5.3787, 50.3278],
            [5.3953, 50.335],
            [5.398, 50.3394],
            [5.3862, 50.3436],
            [5.382, 50.3503],
            [5.3939, 50.3598],
            [5.3986, 50.3612],
            [5.4025, 50.3728],
            [5.3948, 50.3782],
          ],
        ],
      },
      properties: {name: 'Luxembourg', id: 'BE-WLX', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-WLX',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.2371, 51.3529],
            [4.2534, 51.3502],
            [4.2656, 51.3449],
            [4.2719, 51.3356],
            [4.2718, 51.3174],
            [4.2737, 51.3088],
            [4.2797, 51.3019],
            [4.3083, 51.2926],
            [4.3161, 51.2887],
            [4.322, 51.2808],
            [4.3186, 51.2753],
            [4.3061, 51.2668],
            [4.3014, 51.2613],
            [4.2913, 51.2598],
            [4.2901, 51.2519],
            [4.2964, 51.2486],
            [4.2994, 51.2322],
            [4.3111, 51.2115],
            [4.3035, 51.2107],
            [4.3073, 51.2025],
            [4.3233, 51.204],
            [4.3194, 51.1971],
            [4.3184, 51.1869],
            [4.3307, 51.1837],
            [4.3291, 51.1739],
            [4.3314, 51.1554],
            [4.3274, 51.1355],
            [4.3229, 51.13],
            [4.3081, 51.1246],
            [4.2789, 51.1228],
            [4.2605, 51.1163],
            [4.244, 51.115],
            [4.2212, 51.122],
            [4.2119, 51.1219],
            [4.1918, 51.1073],
            [4.1778, 51.1029],
            [4.1731, 51.0973],
            [4.1732, 51.0909],
            [4.1858, 51.0852],
            [4.1898, 51.0798],
            [4.1869, 51.0647],
            [4.1949, 51.0604],
            [4.1986, 51.0547],
            [4.1891, 51.0483],
            [4.1955, 51.0404],
            [4.2067, 51.034],
            [4.2202, 51.0353],
            [4.2329, 51.0434],
            [4.2404, 51.0371],
            [4.2367, 51.0185],
            [4.2222, 51.0146],
            [4.2334, 51.0076],
            [4.2331, 51.0015],
            [4.2198, 50.9907],
            [4.2051, 50.9835],
            [4.1877, 50.9831],
            [4.1705, 50.9893],
            [4.1608, 50.9893],
            [4.1561, 50.9845],
            [4.1571, 50.9782],
            [4.1437, 50.9657],
            [4.1562, 50.9553],
            [4.1634, 50.9424],
            [4.1549, 50.939],
            [4.1635, 50.9322],
            [4.1444, 50.9207],
            [4.1295, 50.9217],
            [4.0993, 50.9322],
            [4.0919, 50.9272],
            [4.0922, 50.9158],
            [4.1005, 50.9091],
            [4.0869, 50.9022],
            [4.0767, 50.8998],
            [4.084, 50.8916],
            [4.0907, 50.8894],
            [4.0807, 50.8717],
            [4.0713, 50.8708],
            [4.064, 50.8641],
            [4.0638, 50.8595],
            [4.0572, 50.8543],
            [4.059, 50.8495],
            [4.0508, 50.8422],
            [4.0427, 50.8395],
            [4.0495, 50.8331],
            [4.0678, 50.8354],
            [4.0694, 50.824],
            [4.0754, 50.8179],
            [4.0695, 50.7991],
            [4.057, 50.7863],
            [4.0494, 50.7846],
            [4.0384, 50.7762],
            [4.0239, 50.7782],
            [4.0167, 50.7712],
            [4.0081, 50.773],
            [4.0025, 50.7781],
            [3.9869, 50.7653],
            [3.9754, 50.7662],
            [3.973, 50.7741],
            [3.961, 50.7786],
            [3.9431, 50.7718],
            [3.9328, 50.7582],
            [3.935, 50.7458],
            [3.9464, 50.7492],
            [3.9491, 50.7402],
            [3.9549, 50.7356],
            [3.9449, 50.728],
            [3.9363, 50.7299],
            [3.9238, 50.7286],
            [3.9133, 50.7312],
            [3.9066, 50.7277],
            [3.8968, 50.7317],
            [3.8994, 50.7362],
            [3.8862, 50.7488],
            [3.8712, 50.7476],
            [3.8621, 50.7496],
            [3.846, 50.7449],
            [3.8397, 50.7408],
            [3.8192, 50.7436],
            [3.8157, 50.7488],
            [3.7975, 50.7487],
            [3.7757, 50.7471],
            [3.7684, 50.7576],
            [3.7611, 50.7615],
            [3.7543, 50.7706],
            [3.7538, 50.7783],
            [3.7383, 50.7701],
            [3.731, 50.7708],
            [3.7147, 50.767],
            [3.7086, 50.7741],
            [3.6897, 50.7741],
            [3.6702, 50.7635],
            [3.6688, 50.7541],
            [3.6604, 50.7532],
            [3.6591, 50.7463],
            [3.6422, 50.7379],
            [3.6408, 50.7217],
            [3.6303, 50.7204],
            [3.6202, 50.723],
            [3.6101, 50.7314],
            [3.5985, 50.7315],
            [3.5879, 50.7279],
            [3.5678, 50.7274],
            [3.5581, 50.7341],
            [3.5485, 50.7321],
            [3.5451, 50.7381],
            [3.547, 50.747],
            [3.5331, 50.7643],
            [3.5204, 50.7603],
            [3.4973, 50.757],
            [3.4784, 50.759],
            [3.4597, 50.7672],
            [3.4818, 50.7796],
            [3.4882, 50.7871],
            [3.5016, 50.7963],
            [3.512, 50.7946],
            [3.5217, 50.8055],
            [3.5067, 50.8173],
            [3.5085, 50.8275],
            [3.5137, 50.8316],
            [3.4991, 50.8401],
            [3.4931, 50.8466],
            [3.4903, 50.8545],
            [3.4848, 50.8574],
            [3.4689, 50.8532],
            [3.465, 50.8616],
            [3.4566, 50.8636],
            [3.4585, 50.876],
            [3.4694, 50.8772],
            [3.4749, 50.8838],
            [3.4713, 50.8892],
            [3.4788, 50.8975],
            [3.4706, 50.8998],
            [3.4599, 50.9077],
            [3.4496, 50.9065],
            [3.4448, 50.901],
            [3.4329, 50.8977],
            [3.4239, 50.8996],
            [3.4164, 50.9047],
            [3.4271, 50.9167],
            [3.4341, 50.919],
            [3.4258, 50.9266],
            [3.4338, 50.9335],
            [3.4522, 50.9302],
            [3.4551, 50.9367],
            [3.4433, 50.9377],
            [3.4546, 50.9492],
            [3.4451, 50.9526],
            [3.4223, 50.949],
            [3.4144, 50.9571],
            [3.4414, 50.964],
            [3.4265, 50.9767],
            [3.434, 50.9877],
            [3.4498, 50.9952],
            [3.4457, 50.999],
            [3.4519, 51.0033],
            [3.4485, 51.016],
            [3.4401, 51.0213],
            [3.4266, 51.0392],
            [3.4361, 51.0386],
            [3.4468, 51.0475],
            [3.436, 51.0541],
            [3.4214, 51.0554],
            [3.4161, 51.0637],
            [3.3996, 51.0668],
            [3.3723, 51.0845],
            [3.356, 51.0887],
            [3.3319, 51.0991],
            [3.3414, 51.1048],
            [3.3542, 51.108],
            [3.3561, 51.1123],
            [3.3748, 51.1171],
            [3.3822, 51.1266],
            [3.3902, 51.1294],
            [3.4022, 51.1468],
            [3.4053, 51.1612],
            [3.3872, 51.1811],
            [3.386, 51.1864],
            [3.3746, 51.1906],
            [3.3659, 51.1868],
            [3.3605, 51.1919],
            [3.3645, 51.197],
            [3.3773, 51.1972],
            [3.3846, 51.203],
            [3.3778, 51.2142],
            [3.367, 51.2276],
            [3.398, 51.2371],
            [3.3981, 51.2493],
            [3.3862, 51.2631],
            [3.3818, 51.2746],
            [3.3889, 51.2737],
            [3.3947, 51.2665],
            [3.4053, 51.2623],
            [3.4094, 51.257],
            [3.4166, 51.2599],
            [3.4255, 51.2542],
            [3.4273, 51.2469],
            [3.4466, 51.2417],
            [3.4826, 51.2426],
            [3.5275, 51.2459],
            [3.5274, 51.2497],
            [3.5159, 51.2871],
            [3.5257, 51.2883],
            [3.5326, 51.2838],
            [3.5393, 51.2846],
            [3.5438, 51.2909],
            [3.5551, 51.29],
            [3.5648, 51.2945],
            [3.5762, 51.2883],
            [3.5865, 51.2932],
            [3.5817, 51.2986],
            [3.594, 51.3047],
            [3.6193, 51.2968],
            [3.6411, 51.2887],
            [3.6584, 51.2907],
            [3.6769, 51.2839],
            [3.6901, 51.2811],
            [3.6936, 51.2768],
            [3.7119, 51.2725],
            [3.7245, 51.2735],
            [3.7571, 51.2696],
            [3.7676, 51.2608],
            [3.7736, 51.2645],
            [3.7962, 51.2555],
            [3.7901, 51.2449],
            [3.7891, 51.228],
            [3.7917, 51.2148],
            [3.7994, 51.2112],
            [3.8101, 51.2127],
            [3.8229, 51.2091],
            [3.8367, 51.2135],
            [3.8519, 51.2142],
            [3.8589, 51.2115],
            [3.8879, 51.2234],
            [3.8938, 51.2172],
            [3.8793, 51.208],
            [3.8874, 51.2007],
            [3.9, 51.2027],
            [3.9176, 51.2093],
            [3.9194, 51.217],
            [3.9282, 51.2204],
            [3.936, 51.2126],
            [3.9428, 51.2152],
            [3.9587, 51.2167],
            [3.9645, 51.2248],
            [3.9754, 51.2254],
            [3.9842, 51.2337],
            [4.0153, 51.2456],
            [4.0255, 51.2419],
            [4.0386, 51.2451],
            [4.0431, 51.2417],
            [4.0615, 51.2444],
            [4.0649, 51.2482],
            [4.1002, 51.2624],
            [4.1681, 51.2946],
            [4.2192, 51.336],
            [4.2371, 51.3529],
          ],
        ],
      },
      properties: {name: 'East Flanders', id: 'BE-VOV', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-VOV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.5786, 50.5406],
            [4.5745, 50.5548],
            [4.5757, 50.5605],
            [4.5893, 50.5611],
            [4.6014, 50.5682],
            [4.618, 50.5687],
            [4.6199, 50.5625],
            [4.6336, 50.5652],
            [4.6451, 50.5591],
            [4.6589, 50.5637],
            [4.6696, 50.5653],
            [4.6747, 50.5707],
            [4.6617, 50.5761],
            [4.6558, 50.5916],
            [4.6572, 50.6015],
            [4.6725, 50.6073],
            [4.6901, 50.5985],
            [4.7111, 50.5942],
            [4.7135, 50.6072],
            [4.7274, 50.6101],
            [4.746, 50.6],
            [4.7408, 50.5967],
            [4.7509, 50.5914],
            [4.7703, 50.6006],
            [4.783, 50.5997],
            [4.7916, 50.5893],
            [4.796, 50.5912],
            [4.7877, 50.6005],
            [4.8163, 50.6081],
            [4.8331, 50.6077],
            [4.8571, 50.6155],
            [4.8898, 50.6208],
            [4.9011, 50.6214],
            [4.8996, 50.6283],
            [4.9128, 50.6253],
            [4.9283, 50.6267],
            [4.9398, 50.6391],
            [4.9495, 50.6456],
            [4.9652, 50.6476],
            [4.9776, 50.6392],
            [4.9844, 50.6419],
            [4.9895, 50.6361],
            [4.9823, 50.6338],
            [4.9877, 50.6248],
            [4.9815, 50.6159],
            [5.0005, 50.6092],
            [4.9998, 50.5987],
            [5.0177, 50.5902],
            [5.0275, 50.588],
            [5.0272, 50.5839],
            [5.0329, 50.5701],
            [5.048, 50.5602],
            [5.046, 50.5504],
            [5.0532, 50.5424],
            [5.0522, 50.5393],
            [5.0404, 50.5362],
            [5.0362, 50.5325],
            [5.0577, 50.5322],
            [5.0932, 50.5299],
            [5.0907, 50.5261],
            [5.0974, 50.512],
            [5.1049, 50.5072],
            [5.1172, 50.5036],
            [5.1421, 50.512],
            [5.1449, 50.5083],
            [5.1295, 50.5028],
            [5.1354, 50.4962],
            [5.1343, 50.4905],
            [5.1406, 50.4869],
            [5.1562, 50.4902],
            [5.1616, 50.4875],
            [5.1514, 50.4823],
            [5.1571, 50.4785],
            [5.164, 50.468],
            [5.1723, 50.47],
            [5.1791, 50.4761],
            [5.1918, 50.476],
            [5.2035, 50.4733],
            [5.2102, 50.461],
            [5.2235, 50.444],
            [5.2295, 50.4437],
            [5.2368, 50.4373],
            [5.236, 50.4228],
            [5.2229, 50.4156],
            [5.2363, 50.4025],
            [5.2559, 50.399],
            [5.2618, 50.4014],
            [5.2883, 50.3921],
            [5.281, 50.3869],
            [5.2826, 50.3769],
            [5.2988, 50.364],
            [5.3094, 50.3696],
            [5.3203, 50.3716],
            [5.3383, 50.3789],
            [5.353, 50.3872],
            [5.3644, 50.3838],
            [5.3707, 50.3791],
            [5.3837, 50.3857],
            [5.3948, 50.3782],
            [5.4025, 50.3728],
            [5.3986, 50.3612],
            [5.3939, 50.3598],
            [5.382, 50.3503],
            [5.3862, 50.3436],
            [5.398, 50.3394],
            [5.3953, 50.335],
            [5.3787, 50.3278],
            [5.3772, 50.3252],
            [5.3512, 50.3197],
            [5.3446, 50.3156],
            [5.344, 50.3094],
            [5.3514, 50.3066],
            [5.367, 50.317],
            [5.3806, 50.3134],
            [5.3868, 50.316],
            [5.3929, 50.3099],
            [5.3907, 50.3065],
            [5.3966, 50.2898],
            [5.3733, 50.2765],
            [5.3602, 50.2741],
            [5.3544, 50.268],
            [5.3445, 50.2651],
            [5.3334, 50.2551],
            [5.3135, 50.2453],
            [5.2954, 50.2394],
            [5.2858, 50.2474],
            [5.2681, 50.2401],
            [5.2556, 50.2388],
            [5.2612, 50.2289],
            [5.2583, 50.2261],
            [5.2357, 50.221],
            [5.2293, 50.2256],
            [5.2083, 50.2153],
            [5.2237, 50.2127],
            [5.2337, 50.2134],
            [5.2373, 50.2085],
            [5.247, 50.2045],
            [5.2346, 50.1951],
            [5.2428, 50.1921],
            [5.2453, 50.1867],
            [5.2554, 50.1794],
            [5.2614, 50.1711],
            [5.2679, 50.1668],
            [5.2724, 50.1591],
            [5.2802, 50.155],
            [5.2853, 50.1477],
            [5.2731, 50.1443],
            [5.2433, 50.1329],
            [5.2484, 50.1266],
            [5.2553, 50.1283],
            [5.2701, 50.1166],
            [5.2693, 50.1106],
            [5.2366, 50.1092],
            [5.2277, 50.1064],
            [5.2268, 50.1017],
            [5.2014, 50.0958],
            [5.1902, 50.0991],
            [5.1606, 50.0992],
            [5.1529, 50.0934],
            [5.1428, 50.0937],
            [5.1323, 50.0976],
            [5.122, 50.0986],
            [5.1015, 50.0948],
            [5.0767, 50.0923],
            [5.0695, 50.1012],
            [5.0674, 50.1081],
            [5.0717, 50.1159],
            [5.0664, 50.1221],
            [5.0581, 50.1067],
            [5.0533, 50.0807],
            [5.0605, 50.0787],
            [5.0546, 50.0723],
            [5.0541, 50.0661],
            [5.043, 50.0618],
            [5.0425, 50.0528],
            [5.0293, 50.0492],
            [5.0249, 50.0425],
            [5.0117, 50.0374],
            [5.0005, 50.0436],
            [4.9947, 50.0384],
            [4.9947, 50.0309],
            [4.9889, 50.0268],
            [4.9783, 50.0304],
            [4.9853, 50.019],
            [4.9959, 50.0137],
            [4.9934, 50.0076],
            [5.0113, 49.9965],
            [5.006, 49.9889],
            [5.0061, 49.9828],
            [5.0149, 49.9784],
            [5.0606, 49.9839],
            [5.0665, 49.9818],
            [5.0673, 49.9741],
            [5.0931, 49.9613],
            [5.1002, 49.9609],
            [5.1027, 49.9466],
            [5.0984, 49.9365],
            [5.1114, 49.9252],
            [5.1141, 49.9197],
            [5.0969, 49.9194],
            [5.0809, 49.9123],
            [5.0739, 49.9123],
            [5.0579, 49.8975],
            [5.049, 49.898],
            [5.0434, 49.8928],
            [5.0472, 49.888],
            [5.0575, 49.8832],
            [5.036, 49.872],
            [5.0252, 49.8702],
            [5.0241, 49.8656],
            [5.011, 49.8576],
            [5.0012, 49.8571],
            [4.9893, 49.8531],
            [4.9865, 49.846],
            [4.9801, 49.8429],
            [4.9836, 49.8219],
            [4.969, 49.8113],
            [4.9686, 49.8016],
            [4.9659, 49.7984],
            [4.9545, 49.801],
            [4.9462, 49.7952],
            [4.9288, 49.7872],
            [4.9193, 49.7891],
            [4.9075, 49.786],
            [4.8971, 49.7889],
            [4.8865, 49.7882],
            [4.8781, 49.7927],
            [4.8692, 49.7894],
            [4.8592, 49.7892],
            [4.8512, 49.7932],
            [4.8621, 49.8025],
            [4.8646, 49.8132],
            [4.8743, 49.818],
            [4.8695, 49.8224],
            [4.8662, 49.8412],
            [4.8564, 49.8417],
            [4.8563, 49.8467],
            [4.8506, 49.8531],
            [4.8468, 49.8668],
            [4.8604, 49.8671],
            [4.8585, 49.8717],
            [4.8769, 49.8951],
            [4.8867, 49.8987],
            [4.8879, 49.9096],
            [4.8797, 49.916],
            [4.8801, 49.9219],
            [4.8576, 49.9315],
            [4.847, 49.9483],
            [4.8402, 49.9505],
            [4.8299, 49.9487],
            [4.8128, 49.9541],
            [4.8067, 49.954],
            [4.7906, 49.9582],
            [4.7892, 49.9688],
            [4.7948, 49.9834],
            [4.8045, 49.9835],
            [4.8178, 49.9943],
            [4.8156, 49.9999],
            [4.8201, 50.0107],
            [4.8175, 50.0137],
            [4.8193, 50.0257],
            [4.8408, 50.0381],
            [4.8384, 50.0461],
            [4.8265, 50.0505],
            [4.8283, 50.0568],
            [4.8206, 50.0592],
            [4.8197, 50.065],
            [4.8377, 50.0672],
            [4.8453, 50.0836],
            [4.8384, 50.0931],
            [4.8519, 50.102],
            [4.8615, 50.0978],
            [4.8608, 50.0922],
            [4.871, 50.0878],
            [4.8747, 50.093],
            [4.868, 50.097],
            [4.8719, 50.1082],
            [4.8673, 50.118],
            [4.8703, 50.1247],
            [4.8942, 50.1348],
            [4.8947, 50.1392],
            [4.8832, 50.1402],
            [4.8764, 50.1529],
            [4.85, 50.1511],
            [4.8352, 50.1542],
            [4.8187, 50.1645],
            [4.806, 50.1521],
            [4.7828, 50.143],
            [4.7626, 50.1366],
            [4.75, 50.1117],
            [4.715, 50.1002],
            [4.7124, 50.0966],
            [4.7017, 50.0954],
            [4.6888, 50.079],
            [4.6868, 50.0711],
            [4.6797, 50.0666],
            [4.6858, 50.0563],
            [4.6947, 50.0566],
            [4.6967, 50.05],
            [4.6946, 50.0415],
            [4.6869, 50.0285],
            [4.6836, 50.0072],
            [4.6869, 49.9987],
            [4.6825, 49.9963],
            [4.665, 49.9961],
            [4.6324, 49.9876],
            [4.5978, 49.9871],
            [4.5751, 49.9815],
            [4.5685, 49.9742],
            [4.5588, 49.9707],
            [4.5427, 49.9703],
            [4.5325, 49.963],
            [4.5245, 49.951],
            [4.5118, 49.9477],
            [4.4817, 49.9482],
            [4.4463, 49.9378],
            [4.4336, 49.9421],
            [4.4465, 49.9536],
            [4.4374, 49.9737],
            [4.423, 49.9856],
            [4.4205, 49.9943],
            [4.4154, 49.9987],
            [4.4166, 50.0113],
            [4.4124, 50.0185],
            [4.412, 50.03],
            [4.4082, 50.0384],
            [4.4109, 50.0448],
            [4.4041, 50.0496],
            [4.3954, 50.0466],
            [4.3918, 50.0506],
            [4.3969, 50.0586],
            [4.3922, 50.0667],
            [4.3966, 50.0797],
            [4.3907, 50.0917],
            [4.3926, 50.1012],
            [4.387, 50.1072],
            [4.3968, 50.1168],
            [4.3996, 50.1254],
            [4.3854, 50.1354],
            [4.3892, 50.1426],
            [4.3851, 50.1502],
            [4.3765, 50.1561],
            [4.3669, 50.1569],
            [4.3634, 50.1706],
            [4.3782, 50.1743],
            [4.3951, 50.1817],
            [4.3963, 50.1929],
            [4.3928, 50.2016],
            [4.3922, 50.2113],
            [4.4012, 50.2322],
            [4.3891, 50.2339],
            [4.387, 50.2388],
            [4.3772, 50.2388],
            [4.3675, 50.2424],
            [4.3615, 50.2367],
            [4.3474, 50.2297],
            [4.322, 50.2338],
            [4.3184, 50.2373],
            [4.2977, 50.2446],
            [4.2895, 50.2508],
            [4.2883, 50.2554],
            [4.2985, 50.263],
            [4.3067, 50.2642],
            [4.3274, 50.2743],
            [4.3426, 50.2778],
            [4.3582, 50.2748],
            [4.369, 50.2848],
            [4.3707, 50.2916],
            [4.3871, 50.29],
            [4.3948, 50.2934],
            [4.4055, 50.3042],
            [4.4073, 50.3098],
            [4.4219, 50.3067],
            [4.4336, 50.3096],
            [4.4547, 50.3056],
            [4.4667, 50.3103],
            [4.4647, 50.3135],
            [4.4718, 50.319],
            [4.4753, 50.3275],
            [4.5042, 50.3287],
            [4.5072, 50.3211],
            [4.5248, 50.3159],
            [4.5418, 50.3178],
            [4.5652, 50.3229],
            [4.5884, 50.3211],
            [4.594, 50.3266],
            [4.5936, 50.3398],
            [4.5822, 50.3419],
            [4.5883, 50.3497],
            [4.5812, 50.3546],
            [4.5852, 50.3677],
            [4.5941, 50.3718],
            [4.5891, 50.3799],
            [4.6005, 50.3837],
            [4.603, 50.388],
            [4.5936, 50.3906],
            [4.5929, 50.3982],
            [4.6073, 50.408],
            [4.6103, 50.4216],
            [4.6174, 50.4235],
            [4.6128, 50.4294],
            [4.6063, 50.4263],
            [4.5932, 50.4278],
            [4.5843, 50.4363],
            [4.5765, 50.4367],
            [4.5847, 50.445],
            [4.5759, 50.4538],
            [4.5844, 50.454],
            [4.5879, 50.4622],
            [4.5967, 50.4681],
            [4.6006, 50.4759],
            [4.5896, 50.4861],
            [4.5853, 50.495],
            [4.5793, 50.4984],
            [4.5631, 50.4884],
            [4.5598, 50.4924],
            [4.5575, 50.5051],
            [4.5795, 50.5275],
            [4.5786, 50.5406],
          ],
        ],
      },
      properties: {name: 'Namur', id: 'BE-WNA', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-WNA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.982, 51.0344],
            [4.9839, 51.0299],
            [5.0006, 51.0347],
            [5.0112, 51.035],
            [5.0193, 51.0279],
            [5.024, 51.0196],
            [5.0445, 51.0242],
            [5.0551, 51.0215],
            [5.0576, 51.0153],
            [5.0671, 51.0163],
            [5.0838, 51.0233],
            [5.0807, 51.0277],
            [5.0805, 51.0464],
            [5.0881, 51.0492],
            [5.1082, 51.047],
            [5.1185, 51.0494],
            [5.1588, 51.0238],
            [5.1429, 51.0178],
            [5.1213, 51.0119],
            [5.1264, 50.9967],
            [5.1058, 50.995],
            [5.0938, 50.9874],
            [5.0808, 50.9853],
            [5.0797, 50.9803],
            [5.0843, 50.9701],
            [5.0783, 50.9653],
            [5.0775, 50.9596],
            [5.0598, 50.9546],
            [5.0531, 50.9447],
            [5.0437, 50.9422],
            [5.0544, 50.9216],
            [5.0657, 50.9164],
            [5.0786, 50.9211],
            [5.0922, 50.9087],
            [5.1054, 50.9144],
            [5.1247, 50.9177],
            [5.1333, 50.921],
            [5.1364, 50.9127],
            [5.1554, 50.9121],
            [5.1678, 50.9132],
            [5.1772, 50.9103],
            [5.1867, 50.9033],
            [5.1859, 50.8889],
            [5.1791, 50.885],
            [5.1794, 50.8805],
            [5.1686, 50.872],
            [5.1552, 50.8786],
            [5.1526, 50.8627],
            [5.1484, 50.8532],
            [5.1369, 50.8481],
            [5.1426, 50.8449],
            [5.1344, 50.8342],
            [5.1382, 50.8245],
            [5.1308, 50.8145],
            [5.1415, 50.809],
            [5.1429, 50.8025],
            [5.1345, 50.7928],
            [5.1181, 50.793],
            [5.1106, 50.7864],
            [5.1078, 50.7792],
            [5.1173, 50.764],
            [5.109, 50.7572],
            [5.1026, 50.7451],
            [5.1058, 50.7415],
            [5.1209, 50.7356],
            [5.1153, 50.7304],
            [5.1062, 50.7124],
            [5.1016, 50.7091],
            [5.096, 50.7039],
            [5.0808, 50.709],
            [5.072, 50.7074],
            [5.045, 50.7242],
            [5.0475, 50.74],
            [5.021, 50.7503],
            [5.0046, 50.7656],
            [4.9835, 50.7701],
            [4.9698, 50.7674],
            [4.9701, 50.7625],
            [4.9546, 50.755],
            [4.9544, 50.7509],
            [4.9392, 50.7478],
            [4.908, 50.7498],
            [4.9126, 50.7559],
            [4.9047, 50.7605],
            [4.9083, 50.7651],
            [4.8954, 50.7704],
            [4.8852, 50.7619],
            [4.8777, 50.7664],
            [4.8622, 50.7644],
            [4.8477, 50.7676],
            [4.8397, 50.7637],
            [4.8331, 50.7693],
            [4.8372, 50.7759],
            [4.8145, 50.7768],
            [4.8083, 50.7861],
            [4.7931, 50.7984],
            [4.7853, 50.7992],
            [4.7617, 50.8067],
            [4.7505, 50.8071],
            [4.7281, 50.7972],
            [4.7169, 50.789],
            [4.7129, 50.7958],
            [4.7057, 50.7909],
            [4.6942, 50.7894],
            [4.684, 50.7931],
            [4.6668, 50.792],
            [4.6508, 50.7983],
            [4.6445, 50.7797],
            [4.6388, 50.7724],
            [4.6492, 50.7595],
            [4.6568, 50.7557],
            [4.6478, 50.7482],
            [4.6356, 50.7427],
            [4.6265, 50.744],
            [4.6053, 50.7412],
            [4.5998, 50.7458],
            [4.6016, 50.7544],
            [4.5983, 50.7631],
            [4.5801, 50.7577],
            [4.5806, 50.7534],
            [4.5718, 50.7468],
            [4.5579, 50.7484],
            [4.5353, 50.7387],
            [4.5296, 50.7293],
            [4.5232, 50.7281],
            [4.5113, 50.731],
            [4.5105, 50.7348],
            [4.4929, 50.7443],
            [4.4998, 50.7525],
            [4.4944, 50.7556],
            [4.4868, 50.7516],
            [4.463, 50.7536],
            [4.44, 50.7414],
            [4.4251, 50.7352],
            [4.4013, 50.7337],
            [4.3738, 50.7289],
            [4.3738, 50.7164],
            [4.3523, 50.7167],
            [4.3413, 50.7325],
            [4.3356, 50.7341],
            [4.3259, 50.7292],
            [4.3314, 50.7247],
            [4.3241, 50.7194],
            [4.3148, 50.7197],
            [4.3068, 50.6991],
            [4.2915, 50.6944],
            [4.279, 50.6986],
            [4.2625, 50.6991],
            [4.2476, 50.6886],
            [4.2404, 50.6909],
            [4.233, 50.6977],
            [4.2079, 50.7082],
            [4.1796, 50.7062],
            [4.1796, 50.7127],
            [4.1724, 50.7213],
            [4.1551, 50.7299],
            [4.1427, 50.7286],
            [4.1415, 50.7236],
            [4.1328, 50.7173],
            [4.1157, 50.7131],
            [4.1105, 50.7168],
            [4.1002, 50.7061],
            [4.0846, 50.7103],
            [4.0727, 50.7094],
            [4.0601, 50.6934],
            [4.0544, 50.6999],
            [4.048, 50.7002],
            [4.033, 50.6927],
            [4.0202, 50.6935],
            [4.0091, 50.6967],
            [3.991, 50.6867],
            [3.9368, 50.6896],
            [3.9261, 50.6939],
            [3.9128, 50.6901],
            [3.9138, 50.6973],
            [3.9019, 50.7061],
            [3.8909, 50.7091],
            [3.8904, 50.7136],
            [3.8978, 50.727],
            [3.8968, 50.7317],
            [3.9066, 50.7277],
            [3.9133, 50.7312],
            [3.9238, 50.7286],
            [3.9363, 50.7299],
            [3.9449, 50.728],
            [3.9549, 50.7356],
            [3.9491, 50.7402],
            [3.9464, 50.7492],
            [3.935, 50.7458],
            [3.9328, 50.7582],
            [3.9431, 50.7718],
            [3.961, 50.7786],
            [3.973, 50.7741],
            [3.9754, 50.7662],
            [3.9869, 50.7653],
            [4.0025, 50.7781],
            [4.0081, 50.773],
            [4.0167, 50.7712],
            [4.0239, 50.7782],
            [4.0384, 50.7762],
            [4.0494, 50.7846],
            [4.057, 50.7863],
            [4.0695, 50.7991],
            [4.0754, 50.8179],
            [4.0694, 50.824],
            [4.0678, 50.8354],
            [4.0495, 50.8331],
            [4.0427, 50.8395],
            [4.0508, 50.8422],
            [4.059, 50.8495],
            [4.0572, 50.8543],
            [4.0638, 50.8595],
            [4.064, 50.8641],
            [4.0713, 50.8708],
            [4.0807, 50.8717],
            [4.0907, 50.8894],
            [4.084, 50.8916],
            [4.0767, 50.8998],
            [4.0869, 50.9022],
            [4.1005, 50.9091],
            [4.0922, 50.9158],
            [4.0919, 50.9272],
            [4.0993, 50.9322],
            [4.1295, 50.9217],
            [4.1444, 50.9207],
            [4.1635, 50.9322],
            [4.1549, 50.939],
            [4.1634, 50.9424],
            [4.1562, 50.9553],
            [4.1437, 50.9657],
            [4.1571, 50.9782],
            [4.1561, 50.9845],
            [4.1608, 50.9893],
            [4.1705, 50.9893],
            [4.1877, 50.9831],
            [4.2051, 50.9835],
            [4.2198, 50.9907],
            [4.2331, 51.0015],
            [4.2334, 51.0076],
            [4.2222, 51.0146],
            [4.2367, 51.0185],
            [4.2404, 51.0371],
            [4.251, 51.0344],
            [4.2647, 51.0379],
            [4.2678, 51.042],
            [4.2813, 51.0428],
            [4.2837, 51.0397],
            [4.2984, 51.0393],
            [4.3101, 51.0362],
            [4.3237, 51.0273],
            [4.3475, 51.0266],
            [4.3432, 51.0202],
            [4.3613, 51.0168],
            [4.3675, 51.0238],
            [4.3753, 51.0219],
            [4.3735, 51.0138],
            [4.3899, 51.0104],
            [4.3903, 51.0045],
            [4.3956, 50.9953],
            [4.4055, 51.0001],
            [4.4189, 50.9995],
            [4.4198, 50.9962],
            [4.4389, 51.0024],
            [4.4533, 51.0054],
            [4.464, 50.9932],
            [4.4807, 50.9915],
            [4.49, 50.997],
            [4.4855, 51.0048],
            [4.4906, 51.0084],
            [4.5057, 51.0034],
            [4.5305, 50.9926],
            [4.5328, 50.9968],
            [4.5507, 51.0044],
            [4.5718, 50.9977],
            [4.5875, 50.9945],
            [4.6016, 50.995],
            [4.6048, 51.0087],
            [4.6116, 51.0115],
            [4.6299, 51.0107],
            [4.6363, 51.018],
            [4.6554, 51.0207],
            [4.6627, 51.0195],
            [4.6842, 51.0248],
            [4.6918, 51.0164],
            [4.7073, 51.0145],
            [4.6974, 50.9987],
            [4.7058, 51.0013],
            [4.7098, 51.0117],
            [4.7325, 51.0206],
            [4.7389, 51.0277],
            [4.7611, 51.0244],
            [4.776, 51.0349],
            [4.7919, 51.0387],
            [4.7985, 51.0295],
            [4.8079, 51.0255],
            [4.8126, 51.0199],
            [4.8101, 51.0148],
            [4.8179, 51.0109],
            [4.8268, 51.0131],
            [4.8443, 51.0115],
            [4.8598, 51.0159],
            [4.8668, 51.0209],
            [4.883, 51.0185],
            [4.9044, 51.0241],
            [4.9066, 51.0306],
            [4.9228, 51.036],
            [4.9472, 51.0364],
            [4.9665, 51.0453],
            [4.9724, 51.0367],
            [4.982, 51.0344],
          ],
          [
            [4.4309, 50.8963],
            [4.4259, 50.8987],
            [4.4237, 50.9027],
            [4.4135, 50.9071],
            [4.4052, 50.9135],
            [4.3899, 50.91],
            [4.3841, 50.9021],
            [4.3758, 50.8985],
            [4.362, 50.9019],
            [4.342, 50.9024],
            [4.3279, 50.8997],
            [4.3158, 50.8931],
            [4.2962, 50.8888],
            [4.2987, 50.8794],
            [4.2818, 50.8673],
            [4.2859, 50.8399],
            [4.2606, 50.8364],
            [4.2557, 50.8331],
            [4.2558, 50.8268],
            [4.2474, 50.8207],
            [4.2574, 50.8163],
            [4.261, 50.8106],
            [4.2746, 50.8115],
            [4.2827, 50.8081],
            [4.3018, 50.8131],
            [4.3074, 50.8098],
            [4.3045, 50.8048],
            [4.3082, 50.7984],
            [4.3181, 50.7956],
            [4.3244, 50.7818],
            [4.3378, 50.7741],
            [4.3473, 50.7748],
            [4.3643, 50.7717],
            [4.3683, 50.773],
            [4.3829, 50.77],
            [4.3847, 50.7647],
            [4.4342, 50.7785],
            [4.4376, 50.7773],
            [4.4629, 50.7878],
            [4.4759, 50.7913],
            [4.4846, 50.7896],
            [4.4711, 50.8051],
            [4.4552, 50.8062],
            [4.4505, 50.8105],
            [4.4579, 50.8181],
            [4.466, 50.8186],
            [4.4757, 50.8232],
            [4.4679, 50.8336],
            [4.4678, 50.8421],
            [4.4596, 50.853],
            [4.4366, 50.8587],
            [4.4233, 50.8682],
            [4.4295, 50.8771],
            [4.4373, 50.8811],
            [4.4285, 50.8895],
            [4.4309, 50.8963],
          ],
        ],
      },
      properties: {name: 'Flemish Brabant', id: 'BE-VBR', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-VBR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [3.3818, 51.2746],
            [3.3862, 51.2631],
            [3.3981, 51.2493],
            [3.398, 51.2371],
            [3.367, 51.2276],
            [3.3778, 51.2142],
            [3.3846, 51.203],
            [3.3773, 51.1972],
            [3.3645, 51.197],
            [3.3605, 51.1919],
            [3.3659, 51.1868],
            [3.3746, 51.1906],
            [3.386, 51.1864],
            [3.3872, 51.1811],
            [3.4053, 51.1612],
            [3.4022, 51.1468],
            [3.3902, 51.1294],
            [3.3822, 51.1266],
            [3.3748, 51.1171],
            [3.3561, 51.1123],
            [3.3542, 51.108],
            [3.3414, 51.1048],
            [3.3319, 51.0991],
            [3.356, 51.0887],
            [3.3723, 51.0845],
            [3.3996, 51.0668],
            [3.4161, 51.0637],
            [3.4214, 51.0554],
            [3.436, 51.0541],
            [3.4468, 51.0475],
            [3.4361, 51.0386],
            [3.4266, 51.0392],
            [3.4401, 51.0213],
            [3.4485, 51.016],
            [3.4519, 51.0033],
            [3.4457, 50.999],
            [3.4498, 50.9952],
            [3.434, 50.9877],
            [3.4265, 50.9767],
            [3.4414, 50.964],
            [3.4144, 50.9571],
            [3.4223, 50.949],
            [3.4451, 50.9526],
            [3.4546, 50.9492],
            [3.4433, 50.9377],
            [3.4551, 50.9367],
            [3.4522, 50.9302],
            [3.4338, 50.9335],
            [3.4258, 50.9266],
            [3.4341, 50.919],
            [3.4271, 50.9167],
            [3.4164, 50.9047],
            [3.4239, 50.8996],
            [3.4329, 50.8977],
            [3.4448, 50.901],
            [3.4496, 50.9065],
            [3.4599, 50.9077],
            [3.4706, 50.8998],
            [3.4788, 50.8975],
            [3.4713, 50.8892],
            [3.4749, 50.8838],
            [3.4694, 50.8772],
            [3.4585, 50.876],
            [3.4566, 50.8636],
            [3.465, 50.8616],
            [3.4689, 50.8532],
            [3.4848, 50.8574],
            [3.4903, 50.8545],
            [3.4931, 50.8466],
            [3.4991, 50.8401],
            [3.5137, 50.8316],
            [3.5085, 50.8275],
            [3.5067, 50.8173],
            [3.5217, 50.8055],
            [3.512, 50.7946],
            [3.5016, 50.7963],
            [3.4882, 50.7871],
            [3.4818, 50.7796],
            [3.4597, 50.7672],
            [3.4535, 50.7678],
            [3.4546, 50.7588],
            [3.4326, 50.7571],
            [3.434, 50.7487],
            [3.4217, 50.7465],
            [3.4108, 50.747],
            [3.4039, 50.7439],
            [3.4032, 50.7362],
            [3.393, 50.7282],
            [3.3716, 50.7264],
            [3.3644, 50.7188],
            [3.3598, 50.7093],
            [3.3351, 50.7168],
            [3.3245, 50.7213],
            [3.3287, 50.7294],
            [3.3236, 50.7387],
            [3.3058, 50.7551],
            [3.2771, 50.751],
            [3.2621, 50.7516],
            [3.2492, 50.7579],
            [3.239, 50.7585],
            [3.2277, 50.7646],
            [3.2197, 50.7659],
            [3.2117, 50.7624],
            [3.1983, 50.7624],
            [3.191, 50.7561],
            [3.1773, 50.7557],
            [3.1609, 50.7729],
            [3.1531, 50.7789],
            [3.1501, 50.7896],
            [3.1255, 50.786],
            [3.1245, 50.7889],
            [3.1116, 50.7935],
            [3.1044, 50.7806],
            [3.0888, 50.7728],
            [3.0801, 50.7721],
            [3.0598, 50.7797],
            [3.0416, 50.7747],
            [3.0373, 50.7696],
            [3.0239, 50.7701],
            [3.0194, 50.773],
            [3.0299, 50.78],
            [3.0159, 50.7836],
            [3.0109, 50.7876],
            [3.0091, 50.7955],
            [3.0153, 50.8025],
            [3.012, 50.8075],
            [3.0008, 50.8105],
            [2.9907, 50.8065],
            [2.9658, 50.8022],
            [2.9604, 50.798],
            [2.9474, 50.7946],
            [2.9473, 50.7908],
            [2.9617, 50.7782],
            [2.9608, 50.7738],
            [2.9461, 50.7727],
            [2.9207, 50.7647],
            [2.9213, 50.7571],
            [2.888, 50.7541],
            [2.8703, 50.7551],
            [2.8684, 50.76],
            [2.8564, 50.7576],
            [2.8417, 50.7502],
            [2.8512, 50.7405],
            [2.8407, 50.735],
            [2.8536, 50.7238],
            [2.8601, 50.7216],
            [2.8696, 50.7125],
            [2.8626, 50.7067],
            [2.8469, 50.7222],
            [2.8179, 50.7152],
            [2.7897, 50.7261],
            [2.7803, 50.7432],
            [2.7805, 50.7506],
            [2.7712, 50.7549],
            [2.7642, 50.7545],
            [2.7543, 50.7629],
            [2.7593, 50.771],
            [2.749, 50.7779],
            [2.7349, 50.7831],
            [2.7246, 50.7923],
            [2.7205, 50.8024],
            [2.7234, 50.8099],
            [2.7102, 50.813],
            [2.6783, 50.8132],
            [2.6675, 50.8192],
            [2.6549, 50.8128],
            [2.6358, 50.812],
            [2.6283, 50.8267],
            [2.6258, 50.8359],
            [2.6173, 50.8399],
            [2.6164, 50.8477],
            [2.6002, 50.8533],
            [2.6127, 50.8647],
            [2.6069, 50.8745],
            [2.6103, 50.88],
            [2.6053, 50.8873],
            [2.6095, 50.8954],
            [2.6049, 50.9068],
            [2.6071, 50.913],
            [2.5952, 50.9146],
            [2.5908, 50.9193],
            [2.603, 50.9278],
            [2.6176, 50.9338],
            [2.6184, 50.9421],
            [2.6305, 50.9441],
            [2.6294, 50.9506],
            [2.619, 50.9677],
            [2.6123, 50.9725],
            [2.6077, 50.9899],
            [2.5886, 50.9938],
            [2.5743, 51.0022],
            [2.5725, 51.0123],
            [2.5763, 51.0134],
            [2.5675, 51.0352],
            [2.5616, 51.0548],
            [2.5614, 51.0648],
            [2.5443, 51.0888],
            [2.6191, 51.116],
            [2.6492, 51.1289],
            [2.6599, 51.1306],
            [2.6944, 51.1408],
            [2.7329, 51.1565],
            [2.7647, 51.1664],
            [2.8534, 51.205],
            [2.8919, 51.223],
            [2.9006, 51.2252],
            [2.9118, 51.2321],
            [2.9261, 51.2381],
            [2.9335, 51.2391],
            [2.9776, 51.2563],
            [3.0009, 51.266],
            [3.064, 51.2936],
            [3.079, 51.3027],
            [3.1222, 51.3161],
            [3.1831, 51.3311],
            [3.1772, 51.3376],
            [3.169, 51.3522],
            [3.1738, 51.3556],
            [3.1828, 51.3456],
            [3.1962, 51.3405],
            [3.2047, 51.3419],
            [3.2123, 51.3375],
            [3.2246, 51.3419],
            [3.223, 51.3503],
            [3.2177, 51.358],
            [3.2245, 51.359],
            [3.2279, 51.3414],
            [3.254, 51.3442],
            [3.2813, 51.3517],
            [3.2872, 51.3548],
            [3.3154, 51.3614],
            [3.3452, 51.3671],
            [3.3582, 51.3673],
            [3.3667, 51.3703],
            [3.375, 51.3603],
            [3.3744, 51.3498],
            [3.383, 51.3428],
            [3.3848, 51.3338],
            [3.3598, 51.3163],
            [3.3599, 51.314],
            [3.3767, 51.3017],
            [3.3682, 51.2988],
            [3.3717, 51.2923],
            [3.3823, 51.2867],
            [3.3818, 51.2746],
          ],
        ],
      },
      properties: {name: 'West Flanders', id: 'BE-VWV', CNTRY: 'Belgium', TYPE: 'Province'},
      id: 'BE-VWV',
    },
  ],
}

export default map
