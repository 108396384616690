import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {selectors} from "../../../redux/ReportsRedux";
import {IReportDetails} from "../../../models/Reports";
import React, {useEffect, useState} from "react";
import BasicDataWidget from "../../SeoStatistic/SeoWidgets/BasicDataWidget";
import {monthly} from "../../../models/TikTokReport";

export interface tiktokMonthlyData {
    id: number | string;
    advertiser_id: string;
    month: string;
    cost: number | string;
    impressions: number | string;
    roas: number | string;
    reach: number | string;
    cpm: number | string;
    cpc: number | string;
    clicks: number | string;
    conversions: number | string;
}
const TiktokBasicData = () => {
    const intl = useIntl()
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const [monthlyData, setMonthlyData] = useState<tiktokMonthlyData>()

    useEffect(() => {
        if (reportData && reportData.details && reportData.details.monthly && reportData.details.monthly.length > 0) {
            setMonthlyData({
                id: reportData.details.monthly[0].id,
                advertiser_id: reportData.details.monthly[0].advertiser_id,
                month: reportData.details.monthly[0].month,
                cost: reportData.details.monthly[0].cost,
                impressions: reportData.details.monthly[0].impressions,
                roas: reportData.details.monthly[0].roas,
                reach: reportData.details.monthly[0].reach,
                cpm: reportData.details.monthly[0].cpm,
                cpc: reportData.details.monthly[0].cpc,
                clicks: reportData.details.monthly[0].clicks,
                conversions: reportData.details.monthly[0].conversions
            });
        }
    }, [reportData]);

    return (
        <>

            {monthlyData && (
                <div className='row card-body g-1 justify-content-center'>
                    <div className='col-12'>
                        <div className='row g-0 justify-content-between'>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-129.svg'
                                    title={intl.formatMessage({id: 'CLICKS'})}
                                    value={monthlyData.clicks}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/cost_per_conversion_2.svg'
                                    title={intl.formatMessage({id: 'COST'})}
                                    value={monthlyData.cost}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-159.svg'
                                    title={intl.formatMessage({id: 'REACH'})}
                                    value={monthlyData.reach}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/monitor-with-eye.svg'
                                    title={intl.formatMessage({id: 'IMPRESSIONS'})}
                                    value={monthlyData.impressions}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row g-0 justify-content-between'>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/cost_micros.svg'
                                    title={intl.formatMessage({id: 'CPM'})}
                                    value={monthlyData.cpm}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/conversion_value.svg'
                                    title={intl.formatMessage({id: 'CPC'})}
                                    value={monthlyData.cpc}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/Group-44.svg'
                                    title={intl.formatMessage({id: 'ROAS'})}
                                    value={monthlyData.roas}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                            <div className='col-3'>
                                <BasicDataWidget
                                    icon='/media/sempai-images/icons/conversions.svg'
                                    title={intl.formatMessage({id: 'CONVERSIONS'})}
                                    value={monthlyData.conversions}
                                    valueFSzClass='fs-4'
                                    className='d-flex border-right ps-3 py-5'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default TiktokBasicData