import React, {useEffect, useState} from 'react'
import {Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {ICompanyInfo} from '../auth/models/UserModel'
import {selectors as authSelectors, actions as authActions} from '../auth/redux/AuthRedux'
import {actions as invActions} from '../invoices/redux/InvoiceRedux'
import {useIntl} from 'react-intl'
import ErrorView from '../common/ErrorView/ErrorView'
import Spinner from '../common/Spinner/Spinner'

const DashboardPage: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {currentCompany, companies, loading, error} = useSelector(authSelectors.getAuthState)
  const companyId = useSelector(authSelectors.getCompanyId) as number
  const [dashboardError, setDashboardError] = useState<boolean>(false)
  const [dashboardLoading, setDashboardLoading] = useState<boolean>(true)
  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>()

  useEffect(() => {
    setCompanyInfo(currentCompany)
    setDashboardError(error)
    setDashboardLoading(loading)
  }, [currentCompany, companies, companyId, loading, error])

  useEffect(() => {
    dispatch(authActions.requestCurrentCompany())
    dispatch(invActions.requestUnpaymentedInvoices())
  }, [dispatch, companyId])

  return !dashboardError ? (
    <>
      <div className={dashboardLoading ? 'row overlay overlay-block rounded' : 'row'}>
        {!dashboardLoading ? (
          <>
            <Col xxl={6}>
              <div className={`card card-xl-stretch mb-xl-3 mb-5 shadow-sm`}>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='card-title'>
                        <div className='fw-bold text-gray-400 fs-8'>{`${intl.formatMessage({
                          id: 'COMPANY_DATA',
                        })}:`}</div>
                        <div className='fw-bolder text-dark fs-3'>
                          {companyInfo?.name}
                        </div>
                      </div>

                      <div className='card-title my-4'>
                        <div className='fw-bold text-gray-400 fs-8'>{`${intl.formatMessage({
                          id: 'NIP',
                        })}:`}</div>
                        <div className='fw-bolder text-dark fs-3'>
                          {companyInfo?.nip_prefix} {companyInfo?.nip}
                        </div>
                      </div>
                      <div className='card-title'>
                        <div className='fw-bold text-gray-400 fs-8'>{`${intl.formatMessage({
                          id: 'ADDRESS',
                        })}:`}</div>
                        <p
                          className='text-dark-75 fw-bolder fs-3 m-0'
                          dangerouslySetInnerHTML={{
                            __html: `${companyInfo?.street}`,
                          }}
                        ></p>
                        <p
                          className='text-dark-75 fw-bolder fs-3 m-0'
                          dangerouslySetInnerHTML={{
                            __html: `${companyInfo?.city}, ${companyInfo?.zip_code}`,
                          }}
                        ></p>
                        <p
                          className='text-dark-75 fw-bolder fs-3 m-0'
                          dangerouslySetInnerHTML={{
                            __html: `${companyInfo?.country}`,
                          }}
                        ></p>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='card-title'>
                        {companyInfo?.email && (
                            <div className='card-title'>
                              <div className='fw-bold text-gray-400 fs-8'>{`${intl.formatMessage({
                                id: 'CONTACT_PERSONS',
                              })}:`}</div>
                              <p
                                  className='text-dark fw-bolder fs-4 m-0'
                                  dangerouslySetInnerHTML={{
                                    __html: `${companyInfo?.email}`,
                                  }}
                              ></p>
                            </div>
                        )}
                      </div>
                      {companyInfo?.phone && (
                        <div className='card-title'>
                          <div className='fw-bold text-gray-400 fs-8'>{`${intl.formatMessage({
                            id: 'PHONE',
                          })}:`}</div>
                          <p
                            className='text-dark fw-bolder fs-3 m-0'
                            dangerouslySetInnerHTML={{
                              __html: `${companyInfo?.phone}`,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </>
        ) : (
          <div style={{height: '350px'}}>
            <Spinner />
          </div>
        )}
      </div>
    </>
  ) : (
    <ErrorView />
  )
}

export default DashboardPage
