import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {ICompany, ICompanyInfo, UserModel} from '../models/UserModel'
import {ActionWithPayload, RootState} from '../../../../setup/redux/RootReducer'
import {put, select, takeLatest} from 'redux-saga/effects'
import {getAvailableCompanies, getMyCompany, login} from './AuthCRUD'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const COMPANY_REQUEST = 'COMPANY_REQUEST'
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS'
export const COMPANY_FAIL = 'COMPANY_FAIL'

export const CURRENT_COMPANY_REQUEST = 'CURRENT_COMPANY_REQUEST'
export const CURRENT_COMPANY_SUCCESS = 'CURRENT_COMPANY_SUCCESS'
export const CURRENT_COMPANY_FAIL = 'CURRENT_COMPANY_FAIL'

export const COMPANY_CHANGE = 'COMPANY_CHANGE'

export const LOGOUT = 'LOGOUT'
export const USER_LOAD = 'USER_LOAD'

interface LoginActionType {
  type: string
  payload: {
    email: string
    password: string
  }
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  companyId: undefined,
  companies: undefined,
  currentCompany: undefined,
  loading: false,
  error: false,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  companyId?: number
  companies?: ICompany[]
  currentCompany?: ICompanyInfo
  loading: boolean
  error: boolean
}

export const reducer = persistReducer(
  {storage, key: 'auth-token-data', whitelist: ['user', 'accessToken', 'companyId']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case LOGIN_REQUEST:
        return {...state, loading: true, error: false}

      case LOGIN_SUCCESS:
        const accessToken = action.payload?.accessToken
        const user = action.payload?.user
        const companyId = action.payload?.companyId
        return {...state, loading: false, accessToken, user, companyId}

      case LOGIN_FAIL:
        return {...state, loading: false, error: true}

      case LOGOUT: {
        return {
          user: undefined,
          token: undefined,
          loading: false,
          error: false,
        }
      }

      case COMPANY_CHANGE:
        const changeCompany = action.payload?.companyId
        return {
          ...state,
          companyId: changeCompany,
        }

      case COMPANY_REQUEST:
        return {...state, loading: true}
      case COMPANY_SUCCESS:
        const companies = action.payload?.companies
        return {...state, companies, loading: false}
      case COMPANY_FAIL:
        return {...state, loading: false, error: true}

      case CURRENT_COMPANY_REQUEST:
        return {...state, loading: true}
      case CURRENT_COMPANY_SUCCESS:
        const currentCompany = action.payload?.currentCompany
        return {...state, currentCompany, loading: false}
      case CURRENT_COMPANY_FAIL:
        return {...state, loading: false, error: true}

      case USER_LOAD: {
        const user = action.payload?.user
        return {...state, user}
      }

      default:
        return state
    }
  }
)

export const selectors = {
  getAuthState: (state: RootState) => state.auth,
  getUser: (state: RootState) => state.auth.user,
  getCompanyId: (state: RootState) => state.auth.companyId,
}

export const actions = {
  login: (email: string, password: string) => ({type: LOGIN_REQUEST, payload: {email, password}}),
  loginSuccess: (accessToken: string, user: UserModel, companyId: number) => ({
    type: LOGIN_SUCCESS,
    payload: {accessToken, user, companyId},
  }),
  loginFail: () => ({type: LOGIN_FAIL}),

  logout: () => ({type: LOGOUT}),

  changeCompanyId: (newComapnyId: number) => ({
    type: COMPANY_CHANGE,
    payload: {companyId: newComapnyId},
  }),

  fulfillUser: (user: UserModel) => ({type: USER_LOAD, payload: {user}}),

  requestCompanies: () => ({type: COMPANY_REQUEST}),

  requestCompaniesSuccess: (companies: ICompany[]) => ({
    type: COMPANY_SUCCESS,
    payload: {companies},
  }),

  requestCompaniesFail: () => ({type: COMPANY_FAIL}),

  requestCurrentCompany: () => ({type: CURRENT_COMPANY_REQUEST}),

  requestCurrentCompanySuccess: (currentCompany: ICompanyInfo) => ({
    type: CURRENT_COMPANY_SUCCESS,
    payload: {currentCompany},
  }),

  requestCurrentCompanyFail: () => ({type: CURRENT_COMPANY_FAIL}),
}

export function* saga() {
  yield takeLatest(LOGIN_REQUEST, function* loginSaga(action: LoginActionType) {
    try {
      const {email, password} = action.payload
      const {
        data: {access_token, user, company_id},
      } = yield login(email, password)
      yield put(actions.loginSuccess(access_token, user, company_id.default))
      yield put(actions.requestCompanies())
    } catch (err) {
      yield put(actions.loginFail())
    }
  })

  yield takeLatest(COMPANY_REQUEST, function* companiesSaga() {
    try {
      const {data} = yield getAvailableCompanies()
      yield put(actions.requestCompaniesSuccess(data.items))
    } catch (err) {
      yield put(actions.requestCompaniesFail())
    }
  })

  yield takeLatest(CURRENT_COMPANY_REQUEST, function* companiesSaga() {
    try {
      const companyId: number = yield select(selectors.getCompanyId)
      const {data} = yield getMyCompany(companyId)
      yield put(actions.requestCurrentCompanySuccess(data.company))
    } catch (err) {
      yield put(actions.requestCurrentCompanyFail())
    }
  })
}
