import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {numSpaceFormater} from '../../../../../helpers/formater'
import {FBCampaign, FBCampaignData, FBReportsDetails} from '../../../models/FBReport'
import {selectors} from '../../../redux/ReportsRedux'
import '../../../ReportGeneral.scss';
import CampaignGroupItem from "../../Widgets/CampaignGroupItem";

interface FbAdsCampaignGroupProps {}

const FbAdsCampaignGroup: React.FC<FbAdsCampaignGroupProps> = () => {
  const intl = useIntl()
  const details = useSelector(selectors.getReportDetails) as FBReportsDetails
  const [campaign, setCampaign] = useState<FBCampaignData>()
  const [campaignTitle, setCampaignTitle] = useState<FBCampaign>()
  const [campaignCol, setCampaignCol] = useState<number>(4)
  const [hasCampaignData, setHasCampaignData] = useState(false);

  useEffect(() => {
    if (details && details.fbCampaign) {
      setCampaign(details.fbCampaign as FBCampaignData)
      setCampaignTitle(details.fbCampaign?.titles)
    }
  }, [details])

  useEffect(() => {
    if (campaign && campaign.data && campaign.data.length % 2 === 0) {
      setCampaignCol(6)
    }
    if(campaign && campaign.data && campaign.data.length > 0) {
      setHasCampaignData(true);
    }
  }, [campaign])

  return hasCampaignData ? (
    <>
      <div className='row card-body page-break px-5 py-5 mb-5'>
        <div className='pb-10'>
          <h3 className='fw-bolder text-dark text-center'>{`${intl.formatMessage({
            id: 'REPORT.CAMPAIGN_MAIN',
          })} Facebook Ads`}</h3>
        </div>
      </div>

      {campaign && campaignTitle && (
        <div className='container row' data-testid='campaign-grid'>
          {campaign.data.map((item, index) => {
            return (
              <div key={index} className={`col-xl-${campaignCol} col-12 mb-5 mb-xl-0`}>
                <div className='card card-xl-stretch mb-xl-8 bg-white shadow-sm'>
                  <div className='card-header border-0 py-5 px-7 bg-light-primary campaign-card'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <span className='symbol-label me-3'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                          className='h-25px h-xl-35px align-self-center'
                          alt=''
                        />
                      </span>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder mb-1'>{item.campaign_name}</span>
                        <span className='text-muted fw-bold fs-xl-7 fs-8'>
                          {intl.formatMessage({id: 'CAMPAIGN'})}
                        </span>
                      </h3>
                    </div>
                  </div>

                  <div className='card-body p-0 d-flex flex-column'>
                    <div className='card pt-5 pb-5 px-5 flex-grow-1'>
                      <div className='px-2 py-3 mb-5 border border-gray-200 rounded shadow-sm'>
                        <div className='fw-bolder fs-6 mb-3 text-center'>
                          {intl.formatMessage({id: 'BASIC.DATA'})}
                        </div>
                        <div className='col-12'>
                          <div className='row'>
                            <CampaignGroupItem
                              value={numSpaceFormater(item.clicks)}
                              label={intl.formatMessage({id: 'CLICKS'})}
                              img='/media/icons/duotone/General/Cursor.svg'
                              color='info'
                            />
                            <CampaignGroupItem
                              value={numSpaceFormater(item.spend)}
                              label={intl.formatMessage({id: 'SPEND'})}
                              img='/media/icons/duotone/Shopping/Credit-card.svg'
                              color='danger'
                            />
                            <CampaignGroupItem
                              value={numSpaceFormater(Number(item.ctr).toFixed(2))}
                              label={campaignTitle.ctr}
                              img='/media/icons/duotone/Shopping/Cart3.svg'
                              color='success'
                            />
                            <CampaignGroupItem
                              value={numSpaceFormater(Number(item.cpc).toFixed(4))}
                              label={campaignTitle.cpc}
                              img='/media/icons/duotone/Shopping/Barcode-read.svg'
                              color='primary'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='px-2 py-3 mb-5 border border-gray-200 rounded shadow-sm'>
                        <div className='fw-bolder fs-6 mb-3 text-center'>
                          {intl.formatMessage({id: 'REALISATION'})}
                        </div>
                        <div className='col-12'>
                          <div className='row'>
                            <CampaignGroupItem
                              value={numSpaceFormater(item.impressions)}
                              label={intl.formatMessage({id: 'VIEWS'})}
                              img='/media/icons/duotone/Design/Color-profile.svg'
                              color='info'
                            />
                            <CampaignGroupItem
                              value={numSpaceFormater(item.reach)}
                              label={intl.formatMessage({id: 'COVERAGE'})}
                              img='/media/icons/duotone/Home/Library.svg'
                              color='danger'
                            />

                            <CampaignGroupItem
                              value={numSpaceFormater(Number(item.frequency).toFixed(2))}
                              label={intl.formatMessage({id: 'FREQUENCY'})}
                              img='/media/icons/duotone/Shopping/Cart3.svg'
                              color='success'
                            />
                            <CampaignGroupItem
                              value={numSpaceFormater(Number(item.cpm).toFixed(2))}
                              label={intl.formatMessage({id: 'CPM'})}
                              img='/media/icons/duotone/Shopping/Barcode-read.svg'
                              color='primary'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='px-2 py-3 border border-gray-200 rounded shadow-sm'>
                        <div className='fw-bolder fs-6 mb-3 text-center'>
                          {intl.formatMessage({id: 'ACTIVITY'})}
                        </div>
                        <div className='col-12'>
                          <div className='row'>
                            <CampaignGroupItem
                              value={numSpaceFormater(item.post_engagement)}
                              label={intl.formatMessage({id: 'ACTIONS'})}
                              img='/media/icons/duotone/Design/Color-profile.svg'
                              color='info'
                            />
                            <CampaignGroupItem
                              value={numSpaceFormater(item.post_reaction)}
                              label={intl.formatMessage({id: 'REACTION'})}
                              img='/media/icons/duotone/Home/Library.svg'
                              color='danger'
                            />
                            <CampaignGroupItem
                              value={numSpaceFormater(item.comment)}
                              label={intl.formatMessage({id: 'COMMENTS'})}
                              img='/media/icons/duotone/Shopping/Cart3.svg'
                              color='success'
                            />
                            <CampaignGroupItem
                              value={numSpaceFormater(item.post)}
                              label={intl.formatMessage({id: 'SHARED'})}
                              img='/media/icons/duotone/Shopping/Barcode-read.svg'
                              color='primary'
                            />
                            <CampaignGroupItem
                              value={numSpaceFormater(item.like)}
                              label={intl.formatMessage({id: 'LIKES'})}
                              img='/media/icons/duotone/General/Like.svg'
                              color='info'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  ) : <></>
}

export default FbAdsCampaignGroup
